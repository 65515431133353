import React from "react";
import { Col, Row } from "react-bootstrap";
import Header from "../Header";
import MspAttackSurface from "../MSPv2/MspAttackSurface.js/MspAttackSurface";
import { useSelector } from "react-redux";
import { getMenuItemId } from "../SideNav/SideNav";
import { NoAccessUI } from "../App/App";

const SaasTechnologySpend = () => {
  const isMenuCollapse = useSelector((state) => state?.global?.menuCollapse);

  const menuItemId = getMenuItemId(window.location.pathname.split('/')[1])
  const MenuItem_List = window.localStorage.getItem("object_Data")
    ? JSON.parse(window.localStorage.getItem("object_Data"))?.MenuVisbility?.MenuItem_List : null;
  const menuItemData = MenuItem_List.find(obj => obj?.MenuItemId === parseInt(menuItemId));
  const userPortalRole = window.localStorage.getItem("object_Data")
    ? JSON.parse(window.localStorage.getItem("object_Data"))?.UserDetail?.Role : null;
  const accessState = window.localStorage.getItem("object_Data")
    ? JSON.parse(window.localStorage.getItem("object_Data"))?.accessData : null;


  return (
    <>
      {
        menuItemData?.disableState || (accessState?.Override === true && accessState?.AccessState_TechnologySpend === 0) ? <>
          {
            userPortalRole === 'MSP' ?
              <NoAccessUI /> : <NoAccessUI type={userPortalRole} />
          }
        </> : <>
          <Row className={`trend_data_screen fullHeight m-0 `} fluid>
            <Col
              xs={12}
              className="px-0"
              style={{
                height: "100%",
              }}
            >
              <div
                className="dashboard fullHeight "
                style={{ height: "auto", padding: "0 1em", background: "inherit" }}
              >
                <header
                  className="d-flex justify-content-between navbar header-bottom-shadow"
                  style={{
                    paddingTop: "1em",
                    background: "#FFFFFF",
                    position: "fixed",
                    width: `${isMenuCollapse ? "92.25%" : "83.25%"}`, // "",
                    zIndex: 6,
                    marginLeft: "-0.9rem",
                    paddingLeft: "2rem",
                  }}
                >
                  <h1 className="main_header ml-4 d-flex">Technology Spend</h1>

                  <Header/>
                </header>

                <div className="p-4"> </div>
                <MspAttackSurface setClientData={() => { }} isSaas={true}/>
                <div className="p-4"> </div>
              </div>
            </Col>
          </Row>
        </>
      }
    </>
  );
};

export default SaasTechnologySpend;
