import React, { useState, useEffect } from "react";
import {
  Modal,
  Form,

  Button,

  Spinner,
} from "react-bootstrap";
import { useHistory } from "react-router-dom";
import {
  getAPIData,
  postRequest,
  getScenarioPacks as DropdownApi,

} from "../../Utils/commonApi";
import { useAuth } from "../context/Auth";
// import ConnectionToolModal from "../Integrations/ConnectToolModal";
import { CallToast } from "../PriceCalculator/modals/CustomToast";
import CompanySettingsDropdown from "../settingsV2/companySettings/companysettingsdrodown/CompanySettingsDropdown";
import { GreyLoader } from "../Theme/APILoaders";
import "./drilldownModal.scss";
import KPIpopup from "./KPIpopup/KPIpopup";

import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { setuser } from "../../redux/userState/actions";
import Loader from "../../Components/Common/loader";
import ConnectIntegrationModal from "../MSPv2/integrations/ConnectIntegrationModal";
import UpgradeToPremiumModal from "../Integrationsv2/UpgradeToPremiumModal";
import { viewOnlyAccess } from "../App/App";
import { updateAccessKeyInLocalStorage } from "../../Utils/CommonFunctions";
const TechnicalDrillDownCyberScoreModal = ({
  show,
  hideModal,
  IsSupplier,
  supplierId,
  goToAttackSurfaceTab,
}) => {
  const { authTokens } = useAuth();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const userDetails = useSelector((state) => state?.user?.userDetail);
  const [selectedIntegrationId, setSelectedIntegrationId] = useState('')
  const [showConnectModal, setShowConnectModal] = useState(false)
  const history = useHistory();
  const [newToolName, setNewToolName] = useState("");
  const [modalData, setModalData] = useState([]);
  const [newToolSelected, setNewToolSelected] = useState(false);
  const [addingNewTool, setAddingNewTool] = useState(false);
  const [data, setData] = useState({});
  const [dataRows, setDataRows] = useState([]);
  const [upgradeOptions, setUpgradeOptions] = useState({})
  const [count, setCount] = useState(0);
  const [showUpgradeModal, setShowUpgradeModal] = useState(false)
  const cyberScoreValue = useSelector(
    (state) => state?.user?.userDetail?.cyberScoreValue
  );
  const [dataLoading, setdataLoading] = useState(false)
  // Setup to update Gauge Value only when modal closes:

  const [CyberScore, setCyberScore] = useState(50);

  const accessState = window.localStorage.getItem("object_Data")
    ? JSON.parse(window.localStorage.getItem("object_Data"))?.accessData : null;

  // const [CyberThreat, setCyberThreat] = useState(50);

  // Setup for Connect Tool Modal


  // Connection Tool Modal


  // Connect Tool Modal STOP
  useEffect(() => {
    setCount(0);
    if (show) {
      setLoading(true);
      getPageData();
    }
  }, [show]);
  function getPageData() {
    setdataLoading(true)
    let supplierParams = {
      optionEnum1: supplierId,
      optionEnum2: 4,
    };
    getAPIData(
      IsSupplier ? 433 : 16,
      authTokens,
      IsSupplier ? supplierParams : {}
    )
      .then((response) => {
        setdataLoading(false)
        setLoading(false);
        if (response?.mr?.Success) {
          setData(response);
          if (!IsSupplier) {
            dispatch(
              setuser({
                ScoreAttestation_Icon: response?.ScoreAttestation_Icon,
              })
            );
          }
          setDataRows(response?.dataRows);
          let updated_data = [...response.AttestationRow_List].map((item) => {
            return {
              ...item,
              selected: item?.SelectedTool
                ? { id: 1, category: "Yes" }
                : item?.ShowNoCapability
                  ? { id: 2, category: "No" }
                  : null,
              data: [
                { id: 1, category: "Yes" },
                { id: 2, category: "No" },
              ],
              addTool: false,
              loading: false,
              changingTool: false,
              connectingIntegration: false,
            };
          });
          setModalData(updated_data);
        }
      })
      .catch((err) => {
        setLoading(false);
        setdataLoading(false)
      });
  }

  // Update Row Data or Refresh the data

  function updateRowData(toolIndex, optionIndex = null, parent = null) {
    setdataLoading(true)
    getAPIData(16, authTokens).then((response) => {
      setdataLoading(false)
      if (response?.mr?.Success) {
        setData(response);
        if (!IsSupplier) {
          dispatch(
            setuser({
              ScoreAttestation_Icon: response?.ScoreAttestation_Icon,
            })
          );
        }
        let old_data = { ...modalData[toolIndex] };
        let new_data = {
          ...response.AttestationRow_List[toolIndex],
        };
        let updated_object = { ...old_data, ...new_data };

        let new_list = [...modalData];
        new_list[toolIndex] = updated_object;

        if (optionIndex !== null) {
          new_list[toolIndex].optionList[optionIndex].changingTool = false;
        }
        if (parent) {
          var panel = parent.querySelector(".panel");
          if (panel?.style?.maxHeight) {
            panel.style.maxHeight = null;
          }
        }
        setTimeout(() => {
          setModalData(new_list);
        }, 10);
      }
    }).catch(() => {
      setdataLoading(false)
    })
  }



  function ToggleAccordion(e, work = true) {
    if (!work) return;
    var parent = e.target.closest(".point");
    var panel = parent.querySelector(".panel");
    if (panel?.style?.maxHeight) {
      panel.style.maxHeight = null;
    } else {
      panel.style.maxHeight = panel.scrollHeight + "px";
    }
  }

  function ToggleDropdownApi(pageEnum, optionEnum) {
    return new Promise((resolve, reject) => {
      DropdownApi(authTokens, pageEnum, optionEnum)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  const Breaches = [
    {
      email: "john@example.co.uk",
      number: 5,
    },
    {
      email: "robert@example.co.uk",
      number: 15,
    },
    {
      email: "sarah@example.co.uk",
      number: 20,
    },
  ];

  function openTheDropDown(e, _class) {
    let _row = e.target.closest(_class);

    if (_row) {
      let _dropdown = _row.querySelector(`.dropdown-toggle`);

      if (_dropdown) {
        _dropdown.click();
      }
    }
  }


  function isAnyToolLoading() {
    return modalData?.some(item => item?.loading === true)
  }


  async function showConnectToolModal(integrationId) {
    await getAPIData(`${502}`, authTokens).then((response) => {

      if (response?.SuccessCount === 1) {
        setSelectedIntegrationId(integrationId);
        setShowConnectModal(true);
      } else if (response?.SuccessCount === 0 || response?.SuccessCount === -1) {
        setUpgradeOptions(response?.AccessObjList?.filter((item) => item?.Question === "Integrations Visibility")?.[0]?.UpgradeOptions ?? {})

        setShowUpgradeModal(true)
      } else {
        CallToast(response?.Message, false, false, "v2style");
      }



    }).catch((err) => {
      console.log(err)
      CallToast("Something went wrong", false, false, "v2style");
    })

  }

  return (
    <Modal
      animation={false}
      // show={true}
      show={show}
      dialogClassName="right big-modal"
      className="drilldown-modal "
      aria-labelledby="big-modal"
      onHide={() => {
        if (count > 0) {
          setTimeout(() => {
            dispatch(
              setuser({
                newCyberScoreupdated: true,
                cyberScoreValue: CyberScore,
                // cyberThreatValue: CyberThreat,
              })
            );
          }, 200);

          setCount(0);
        }

        hideModal();
      }}
      centered
      scrollable
    >
      <Modal.Header className=" d-flex align-items-center justify-content-between">
        <div className="d-flex">
          <span
            className="cursor-pointer mr-3 ml-2 pt-1 "
            onClick={() => {
              if (count > 0) {
                setTimeout(() => {
                  dispatch(
                    setuser({
                      newCyberScoreupdated: true,
                      cyberScoreValue: CyberScore,
                      // cyberThreatValue: CyberThreat,
                    })
                  );
                }, 200);

                setCount(0);
              }

              hideModal();
            }}
          >
            <img src="/images/icons/close.svg" alt="" />
          </span>
          <div
            className="w-100 mb-0 f-500 f-18 title"
            style={{ fontWeight: "600" }}
          >
            Technical Drilldown Cyber Score
          </div>
          <span
            className="ml-2"
            onClick={() => {
              let parent = document.querySelector(
                ".technical-cyber-score-attestation-modal"
              );
              let modal = parent.querySelector("#kpi-modal");
              modal.style.display = "block";
            }}
          >
            <img
              alt=""
              src={`/images/attack-surface/${data?.ScoreAttestation_Icon?.toLowerCase()}`}
              className="pointer"
            />
          </span>

          <div
            id="drilldown-kpi-modal-x"
            className="technical-cyber-score-attestation-modal"
          >
            <KPIpopup />
          </div>
        </div>
        <img
          alt=""
          src="/images/attack-surface/attack-surface-button.svg"
          className="pointer"
          onClick={() => {
            if (IsSupplier) {
              hideModal()
              goToAttackSurfaceTab()
              return;
            }
            history.push("/attack_surface");
          }}
        />
      </Modal.Header>

      <Modal.Body className={`p-3    ${dataLoading ? "stop-all-events" : ""}`}>
        {loading ? (
          <div className="d-flex align-items-center justify-content-center w-100 h-100">
            <Loader />
          </div>
        ) : (
          <>
            <div className="header bg-blue f-white mb-3">
              <div>Security Component</div>
              {/* <div>Impact on Cyber Score</div> */}<div></div>
              <div style={{
                textAlign: "center"
              }}>Impact on / Running Cyber Score</div>
              <div>Details</div>
            </div>
            <div
              className={`points-wrapper   ${isAnyToolLoading() ? "progress-cursor" : ""} `}
              style={{
                pointerEvents: `${(IsSupplier || isAnyToolLoading()) ? "none" : "all"}`,

              }}
            >
              {modalData.length &&
                modalData.map((tool, toolIndex) => {
                  return (
                    <>
                      <div
                        className="point"
                        onClick={(e) => ToggleAccordion(e, false)}
                      >
                        {tool?.Visible === false ? (
                          <div className="d-flex align-items-center justify-content-between px-4  py-3 pointer">
                            <div className="d-flex align-items-center w-100">
                              <div className="  d-flex flex-column align-items-start"
                                style={{
                                  width: "96%",
                                  marginLeft: "0.7rem",
                                }}

                              >
                                <div className="f-500">{dataRows?.[toolIndex]?.Component}</div>
                                <div>
                                  {dataRows?.[toolIndex]?.SelectedTool && <span className="d-inline-flex align-items-center"

                                    onClick={() => {
                                      history.push(`/integrations/${dataRows?.[toolIndex]?.SelectedTool?.obj_IntegrationId}`)
                                    }}


                                  >
                                    <img
                                      alt=""
                                      src={`/images/attack-surface/integrations/${dataRows?.[toolIndex]?.SelectedTool?.obj_IntegrationId}.webp`}
                                      className="mr-2"
                                      style={{
                                        width: "16px",
                                        height: "16px",
                                        borderRadius: "50%",
                                      }}
                                      onError={({ currentTarget }) => {
                                        currentTarget.onerror = null; // prevents looping
                                        currentTarget.src =
                                          "/images/attack-surface/generic2.svg";
                                      }}
                                    />
                                    <span className="f-black f-12 d-inline-flex align-items-center">
                                      {dataRows?.[toolIndex]?.SelectedTool?.ToolName}
                                    </span>

                                  </span>}
                                </div>
                              </div>

                              <div className="ml- 5 d-flex align-items-center"

                                style={{
                                  marginLeft: "auto",
                                  marginRight: "2.3rem"
                                }}
                              >
                                {dataRows?.[toolIndex]?.ImpactOnScore}/{dataRows?.[toolIndex]?.RemainingScore}
                              </div>
                              <div style={{ width: "69%", textAlign: "center" }}>{dataRows?.[toolIndex]?.Details}</div>
                            </div>
                            <div className="">
                              <img
                                alt=""
                                src="/images/attack-surface/chevron-down.svg"
                                className="mr-2"
                                style={{
                                  visibility: `${dataRows?.[toolIndex]?.ShowExpandIcon ? "visible" : "hidden"}`
                                }}
                              />
                            </div>
                          </div>
                        ) : (
                          <div className={`
                            d-flex flex-column align-items-center justify-content-between w-100 px-4  py-3
                            ${viewOnlyAccess("AccessState_Dashboard") ? 'cursor-default' : ' pointer'
                            }
                          `}>
                            <div
                              className="d-flex align-items-center justify-content-betwee n w-100 drop_row"
                              onClick={(e) => {
                                openTheDropDown(e, ".drop_row");
                              }}
                            >
                              <div
                                className="d-flex flex-column"
                                // style={{ width: "75%" }}
                                style={{ width: "53.4%" }}
                              >
                                <div className="d-flex align-items-baseline ">
                                  <img
                                    alt=""
                                    src="/images/attack-surface/yellow-dot.svg"
                                    className="mr-1"
                                    style={{
                                      visibility: `${!tool?.ResponseProvided
                                          ? "visible"
                                          : "hidden"
                                        }`,
                                    }}
                                  />
                                  <p className="f-500 m-0">
                                    {tool?.TechnologyTitle}
                                  </p>
                                </div>
                                <p
                                  className="f-12 f-darkgrey "
                                  style={{
                                    marginLeft: "0.9em",
                                  }}
                                >
                                  {tool?.ShowSelectedTool ? (
                                    <span className="d-inline-flex align-items-center">
                                      <img
                                        alt=""
                                        src={`/images/attack-surface/integrations/${tool?.SelectedTool?.obj_IntegrationId}.webp`}
                                        className="mr-2"
                                        style={{
                                          width: "16px",
                                          height: "16px",
                                          borderRadius: "50%",
                                        }}
                                        onError={({ currentTarget }) => {
                                          currentTarget.onerror = null; // prevents looping
                                          currentTarget.src =
                                            "/images/attack-surface/generic2.svg";
                                        }}
                                      />
                                      <span className="f-black d-inline-flex align-items-center">
                                        {tool?.SelectedTool?.ToolName}
                                      </span>
                                      {
                                        viewOnlyAccess("AccessState_Dashboard") ? (
                                          <></>
                                        ) : (
                                          <span
                                            className={`f-blue ${IsSupplier
                                                ? "d-none"
                                                : "d-inline-flex"
                                              } align-items-center mx-2`}
                                            onClick={(e) => {
                                              e.stopPropagation();
                                              var parent =
                                                e.target.closest(".point");

                                              let panel =
                                                parent.querySelector(".panel");
                                              if (panel?.style?.maxHeight) {
                                                return; // panel.style.maxHeight = null;
                                              }
                                              let updated_data = [...modalData];
                                              updated_data[
                                                toolIndex
                                              ].changingTool = true;
                                              setModalData(updated_data);

                                              ToggleDropdownApi(
                                                262,
                                                tool?.TechnologyEnum
                                              ).then((response) => {
                                                let updated_data = [...modalData];
                                                updated_data[
                                                  toolIndex
                                                ].changingTool = false;
                                                setModalData(updated_data);
                                                if (response?.mr?.Success) {
                                                  let updated_data = [...modalData];
                                                  updated_data[
                                                    toolIndex
                                                  ].optionList = [
                                                    ...response?.TechnologyTool_List,
                                                  ].map((item) => {
                                                    return {
                                                      ...item,
                                                      changingTool: false,
                                                    };
                                                  });
                                                  updated_data[
                                                    toolIndex
                                                  ].loading = false;
                                                  setModalData(updated_data);

                                                  var panel =
                                                    parent.querySelector(".panel");

                                                  if (panel?.style?.maxHeight) {
                                                    // panel.style.maxHeight = null;
                                                  } else {
                                                    panel.style.maxHeight =
                                                      panel.scrollHeight + "px";
                                                  }
                                                }
                                              });
                                            }}
                                          >
                                            {tool.changingTool
                                              ? "Please wait.."
                                              : "Change"}
                                          </span>
                                        )
                                      }
                                    </span>
                                  ) : (
                                    tool?.TechnologyQuestion
                                  )}
                                </p>
                              </div>
                              {/* Extra Row added */}
                              <div>  {tool.ImpactOnScore}/{tool?.RemainingScore}  </div>
                              <div
                                style={{ marginLeft: "auto" }}
                                className={`option-dropdown
                                
                                ${"d-flex"}
                                
                                 align-items-center`}
                                onClick={(e) => e.stopPropagation()}
                              >
                                <div>{tool?.loading && <GreyLoader />}</div>
                                {IsSupplier ? (
                                  <>
                                    {tool?.ResponseProvided === true &&
                                      tool?.ShowSelectedTool === false ? (
                                      <div className="f-black f-500 mr-3">
                                        No
                                      </div>
                                    ) : (
                                      <div className="f-black f-500 mr-3">
                                        {tool?.ShowSelectedTool === true &&
                                          "Yes"}
                                      </div>
                                    )}
                                  </>
                                ) : viewOnlyAccess("AccessState_Dashboard") ? (
                                  <div className={` ${tool?.selected ? '' : 'greyText'} `}>
                                    {tool?.selected ? tool?.selected?.category : 'Empty'}
                                  </div>
                                ) : (
                                  <CompanySettingsDropdown
                                    fieldId={"id"}
                                    fieldValue={"category"}
                                    selected={tool.selected}
                                    placeholder="Select"
                                    //  showid={true}
                                    onClick={(id, val, e) => {
                                      if (tool?.selected?.category === val)
                                        return;
                                      let updated_data = [...modalData];

                                      updated_data[toolIndex].selected = {
                                        id,
                                        category: val,
                                      };
                                      updated_data[toolIndex].loading = true;
                                      var parent = e.target.closest(".point");
                                      setModalData(updated_data);
                                      if (val === "No") {
                                        ToggleDropdownApi(
                                          261,
                                          tool?.TechnologyEnum
                                        ).then((response) => {
                                          let updated_data = [...modalData];
                                          updated_data[
                                            toolIndex
                                          ].loading = false;
                                          setModalData(updated_data);

                                          if (response?.mr?.Success) {
                                            setCyberScore(
                                              response?.CommonDashBoardItems
                                                ?.CyberScoreNumber
                                            );
                                            // setCyberThreat(
                                            //   response?.CommonDashBoardItems
                                            //     ?.CyberThreatNumber
                                            // );
                                            dispatch(
                                              setuser({
                                                ScoreAttestation_Icon:
                                                  response?.CommonDashBoardItems
                                                    ?.ScoreAttestation_Icon,

                                                // cyberScoreValue:
                                                //   response?.CommonDashBoardItems
                                                //     ?.CyberScoreNumber,
                                                cyberThreatValue:
                                                  response?.CommonDashBoardItems
                                                    ?.CyberThreatNumber,
                                              })
                                            );
                                            setCount(count + 1);
                                            updateAccessKeyInLocalStorage(
                                                response?.mr?.Message
                                              )
                                          
                                            var panel =
                                              parent.querySelector(".panel");

                                            if (panel?.style?.maxHeight) {
                                              panel.style.maxHeight = null;
                                            } else {
                                              // panel.style.maxHeight =
                                              //   panel.scrollHeight + "px";
                                            }
                                            updateRowData(toolIndex);
                                          } else {
                                            CallToast(
                                              "Something went wrong",
                                              false,
                                              false,
                                              "v2style"
                                            );
                                          }
                                        }).catch(() => {
                                          let updated_data = [...modalData];
                                          updated_data[
                                            toolIndex
                                          ].loading = false;
                                          setModalData(updated_data);
                                        })
                                      }
                                      if (val === "Yes") {
                                        ToggleDropdownApi(
                                          262,
                                          tool?.TechnologyEnum
                                        ).then((response) => {
                                          if (response?.mr?.Success) {
                                            let updated_data = [...modalData];
                                            updated_data[toolIndex].optionList =
                                              [
                                                ...response?.TechnologyTool_List,
                                              ].map((item) => {
                                                return {
                                                  ...item,
                                                  changingTool: false,
                                                };
                                              });
                                            updated_data[
                                              toolIndex
                                            ].loading = false;
                                            setModalData(updated_data);

                                            var panel =
                                              parent.querySelector(".panel");

                                            if (panel?.style?.maxHeight) {
                                              // panel.style.maxHeight = null;
                                            } else {
                                              panel.style.maxHeight =
                                                panel.scrollHeight + "px";
                                            }
                                          } else {
                                            let updated_data = [...modalData];
                                            updated_data[
                                              toolIndex
                                            ].loading = false;
                                            setModalData(updated_data);
                                            CallToast(
                                              "Something went wrong",
                                              false,
                                              false,
                                              "v2style"
                                            );
                                          }
                                        }).catch(() => {
                                          let updated_data = [...modalData];

                                          updated_data[
                                            toolIndex
                                          ].loading = false;
                                          setModalData(updated_data);
                                        })
                                      }
                                    }}
                                    data={tool?.data}
                                  />
                                )}
                              </div>
                            </div>

                            {/* Connect Banner  */}
                            {tool?.ShowConnectButton && !IsSupplier && (
                              <div className="d-flex align-items-center justify-content-between p-2 bg-white m-2 hg-border radius-4 w-100">
                                <div>
                                  <p className="font-weight-bold m-0">
                                    <img
                                      alt=""
                                      src="/images/attack-surface/plug.svg"
                                      className="mr-2"
                                    />
                                    Available Integration, connect it to
                                    HighGround?
                                  </p>
                                  <p className="f-12 f-darkgrey">
                                    Get your data into HighGround to get
                                    verified data
                                  </p>
                                </div>
                                <Button
                                  className="hg-blue-btn"
                                  onClick={() => {
                                    // getIntegrationStateDetail(
                                    //   tool?.SelectedTool?.obj_IntegrationId
                                    // );
                                    showConnectToolModal(tool?.SelectedTool?.obj_IntegrationId)

                                  }}
                                >
                                  Connect
                                </Button>
                              </div>
                            )}
                          </div>
                        )}

                        <div
                          className="panel"
                          onClick={(e) => e.stopPropagation()}
                        >
                          {tool.showBreaches && false ? (
                            <div className="px-4">
                              <hr />
                              <div className="f-12 f-grey mb-2">
                                The following users have Dark Web Breaches in
                                the last 30 days:
                              </div>
                              <div className="w-50">
                                {Breaches.map((breach, breachIndex) => {
                                  return (
                                    <div className="d-flex align-items-center justify-content-between my-3">
                                      <div className="f-violet">
                                        {breach.email}
                                      </div>
                                      <div className="f-grey">
                                        <span className="f-black">
                                          {breach.number}
                                        </span>
                                        Breaches
                                      </div>
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          ) : (
                            <div className="py-2">
                              {/* Choose tool  */}
                              <div className="tool-listing-wrapper ml-2">
                                {tool?.optionList &&
                                  tool?.optionList?.length > 0 && (
                                    <div className="f-12 f-500 f-grey ml-3">
                                      Select all that apply
                                    </div>
                                  )}

                                {tool?.optionList?.map(
                                  (option, optionIndex) => {
                                    const IsToolChanging = modalData[
                                      toolIndex
                                    ].optionList.some(
                                      (item) => item.changingTool == true
                                    );

                                    return (
                                      <div
                                        className="d-flex align-items-center mt-2 pointer"
                                        onClick={(e) => {
                                          if (IsToolChanging) return;
                                          if (
                                            tool?.SelectedTool
                                              ?.obj_IntegrationId ==
                                            option?.obj_IntegrationId
                                          )
                                            return;
                                          let updated_data = [...modalData];
                                          updated_data[toolIndex].SelectedTool =
                                            option;
                                          // setModalData(updated_data);
                                          var parent =
                                            e.target.closest(".point");

                                          updated_data[toolIndex].optionList[
                                            optionIndex
                                          ].changingTool = true;
                                          setModalData(updated_data);

                                          setNewToolSelected(false);
                                          postRequest(
                                            authTokens,
                                            "CreateIntegrationTechnologyTool",
                                            {
                                              Selected: true,

                                              ToolName: option?.ToolName,
                                              ObjectId:
                                                option?.obj_IntegrationId,
                                              accessKey: JSON.parse(
                                                localStorage.getItem(
                                                  "user_MessageObj"
                                                )
                                              ),
                                              IntegrationTechnology:
                                                tool.TechnologyEnum,
                                            }
                                          ).then((response) => {
                                            if (response?.mr?.Success) {
                                              setCyberScore(
                                                response?.CommonDashBoardItems
                                                  ?.CyberScoreNumber
                                              );
                                              // setCyberThreat(
                                              //   response?.CommonDashBoardItems
                                              //     ?.CyberThreatNumber
                                              // );
                                              setCount(count + 1);
                                              dispatch(
                                                setuser({
                                                  ScoreAttestation_Icon:
                                                    response
                                                      ?.CommonDashBoardItems
                                                      ?.ScoreAttestation_Icon,
                                                  // cyberScoreValue:
                                                  //   response
                                                  //     ?.CommonDashBoardItems
                                                  //     ?.CyberScoreNumber,
                                                  cyberThreatValue:
                                                    response
                                                      ?.CommonDashBoardItems
                                                      ?.CyberThreatNumber,
                                                })
                                              );
                                              updateAccessKeyInLocalStorage(
                                                  response?.mr?.Message
                                                )
                                          
                                              updateRowData(
                                                toolIndex,
                                                optionIndex,
                                                parent
                                              );
                                            } else {
                                              let updated_data = [...modalData];
                                              updated_data[
                                                toolIndex
                                              ].optionList[
                                                optionIndex
                                              ].changingTool = false;
                                              setModalData(updated_data);
                                              CallToast(
                                                "Something went wrong",
                                                false,
                                                false,
                                                "v2style"
                                              );
                                            }
                                          });
                                        }}
                                      >
                                        <div className="checkbox-margin ml-3">
                                          <Form.Group
                                            controlId={`card ${toolIndex}${optionIndex}`}
                                            className={`round-checkbox blue-checkbox ${IsToolChanging
                                                ? "cursor-default"
                                                : "radio-shadow"
                                              }`}
                                          >
                                            <Form.Check
                                              type={"radio"}
                                              checked={
                                                tool?.SelectedTool
                                                  ?.obj_IntegrationId ==
                                                option?.obj_IntegrationId
                                              }
                                              name={`check ` + toolIndex}
                                              label=""
                                              disabled={IsToolChanging}
                                              onChange={() => { }}
                                              onClick={(e) => {
                                                return false;
                                              }}
                                            />
                                          </Form.Group>
                                        </div>
                                        <img
                                          alt=""
                                          style={{
                                            width: "24px",
                                            height: "24px",
                                            borderRadius: "50%",
                                          }}
                                          src={`/images/attack-surface/integrations/${option?.obj_IntegrationId}.webp`}
                                          className="ml-3 mr-2"
                                          onError={({ currentTarget }) => {
                                            currentTarget.onerror = null; // prevents looping
                                            currentTarget.src =
                                              "/images/attack-surface/generic2.svg";
                                          }}
                                        />
                                        <div className="f-500">
                                          {option?.ToolName}
                                        </div>
                                        <div>
                                          {option?.changingTool ? (
                                            <Spinner
                                              animation="border"
                                              variant="secondary"
                                              size="sm"
                                              className="mx-2"
                                              style={{
                                                width: "12px",
                                                height: "12px",
                                              }}
                                            />
                                          ) : (
                                            ""
                                          )}
                                        </div>
                                      </div>
                                    );
                                  }
                                )}

                                <>
                                  {!tool.addTool ? (
                                    <div
                                      className="d-flex align-items-center f-grey f-12 ml-4 pointer mt-2 mb-4"
                                      onClick={() => {
                                        setNewToolName("");
                                        let updated_data = [...modalData];
                                        updated_data[toolIndex].addTool = true;
                                        setModalData(updated_data);
                                      }}
                                    >
                                      <img
                                        alt=""
                                        src="/images/attack-surface/add-tool-icon.svg"
                                        className="mr-2"
                                      />
                                      Add
                                    </div>
                                  ) : (
                                    <div className="d-flex align-items-center mb-3 mt-2">
                                      <div className="checkbox-margin ml-3">
                                        <Form.Group
                                          controlId={`card ${toolIndex}  `}
                                          className={`round-checkbox blue-checkbox radio-shadow`}
                                        >
                                          <Form.Check
                                            type={"radio"}
                                            checked={newToolSelected}
                                            name={`check `}
                                            label=""
                                            onChange={() => {
                                              setNewToolSelected(true);
                                            }}
                                          />
                                        </Form.Group>
                                      </div>
                                      <img
                                        alt=""
                                        src="/images/attack-surface/generic2.svg"
                                        width={24}
                                        height={24}
                                        className="ml-3 mr-2"
                                      />
                                      <div className="tool-creator-field d-flex align-items-center ">
                                        <input
                                          type="text"
                                          value={newToolName}
                                          onChange={(e) =>
                                            setNewToolName(e.target.value)
                                          }
                                          autoFocus
                                        />
                                        <Button
                                          className=""
                                          style={{}}
                                          onClick={(e) => {
                                            if (newToolName === "") return;
                                            var parent =
                                              e.target.closest(".point");
                                            setAddingNewTool(true);
                                            postRequest(
                                              authTokens,
                                              "CreateIntegrationTechnologyTool",
                                              {
                                                Selected: newToolSelected,
                                                ObjectId: 0,
                                                ToolName: newToolName,
                                                accessKey: JSON.parse(
                                                  localStorage.getItem(
                                                    "user_MessageObj"
                                                  )
                                                ),
                                                IntegrationTechnology:
                                                  tool.TechnologyEnum,
                                              }
                                            ).then((response) => {
                                              setAddingNewTool(false);
                                              if (response?.mr?.Success) {
                                                setCyberScore(
                                                  response?.CommonDashBoardItems
                                                    ?.CyberScoreNumber
                                                );
                                                // setCyberThreat(
                                                //   response?.CommonDashBoardItems
                                                //     ?.CyberThreatNumber
                                                // );
                                                setCount(count + 1);
                                                dispatch(
                                                  setuser({
                                                    ScoreAttestation_Icon:
                                                      response
                                                        ?.CommonDashBoardItems
                                                        ?.ScoreAttestation_Icon,
                                                    // cyberScoreValue:
                                                    //   response?.CommonDashBoardItems
                                                    //     ?.CyberScoreNumber,
                                                    cyberThreatValue:
                                                      response
                                                        ?.CommonDashBoardItems
                                                        ?.CyberThreatNumber,
                                                  })
                                                );
                                                updateAccessKeyInLocalStorage(
                                                    response?.mr?.Message
                                                  )
                                        
                                                CallToast(
                                                  "Tool added successfully",
                                                  false,
                                                  false,
                                                  "v2style"
                                                );
                                                let updated_data = [
                                                  ...modalData,
                                                ];
                                                updated_data[
                                                  toolIndex
                                                ].addTool = false;
                                                setModalData(updated_data);

                                                ToggleDropdownApi(
                                                  262,
                                                  tool?.TechnologyEnum
                                                ).then((response) => {
                                                  if (response?.mr?.Success) {
                                                    let updated_data = [
                                                      ...modalData,
                                                    ];
                                                    updated_data[
                                                      toolIndex
                                                    ].optionList = [
                                                      ...response?.TechnologyTool_List,
                                                    ].map((item) => {
                                                      return {
                                                        ...item,
                                                        changingTool: false,
                                                      };
                                                    });
                                                    updated_data[
                                                      toolIndex
                                                    ].loading = false;
                                                    setModalData(updated_data);

                                                    if (newToolSelected) {
                                                      updateRowData(
                                                        toolIndex,
                                                        null,
                                                        parent
                                                      );
                                                    } else {
                                                      let panel =
                                                        parent.querySelector(
                                                          ".panel"
                                                        );
                                                      panel.style.maxHeight =
                                                        panel.scrollHeight +
                                                        "px";
                                                    }
                                                  }
                                                });
                                              } else {
                                                CallToast(
                                                  "Something went wrong",
                                                  false,
                                                  false,
                                                  "v2style"
                                                );
                                              }
                                            });
                                          }}
                                        >
                                          {addingNewTool ? (
                                            <span className="d-inline-flex align-items-center">
                                              <Spinner
                                                animation="border"
                                                variant="light"
                                                size="sm"
                                                className="mx-1"
                                                style={{
                                                  width: "10px",
                                                  height: "10px",
                                                }}
                                              />
                                              <span className="d-inline-flex align-items-center">
                                                Add
                                              </span>
                                            </span>
                                          ) : (
                                            "Add"
                                          )}
                                        </Button>
                                      </div>
                                      <img
                                        alt=""
                                        src="/images/attack-surface/small-cross.svg"
                                        className="ml-2 pointer"
                                        onClick={() => {
                                          let updated_data = [...modalData];
                                          updated_data[
                                            toolIndex
                                          ].addTool = false;
                                          setModalData(updated_data);
                                        }}
                                      />
                                    </div>
                                  )}
                                </>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>


                    </>
                  );
                })}
            </div>




            {/* Status Bar for Compliance rows */}
            <div className="w-100 p-4 my-2 f-blue f-12 f-500 radius-4 d-flex align-items-center justify-content-center"
              style={{
                background: "#EBF6FC",
                border: "1px solid #008FD4"
              }}>
              The following are Pre-requisites for HighGround. If not maintained, they can reduce your CyberScore.
            </div>
            <div className="points-wrapper">
              {data &&
                data?.dataRows &&
                data?.dataRows?.slice(10, 20).map((dataRow, dataRowIndex) => {
                  return (
                    <>
                      <div
                        className={`point ${dataRow?.Visible ? "" : "d-none"} `} //
                        onClick={ToggleAccordion}
                      >
                        <div className={`
                          d-flex align-items-center justify-content-between px-4  py-3 
                          ${viewOnlyAccess("AccessState_Dashboard") ? '' : ' pointer'
                          }
                        `}>
                          <div className="d-flex align-items-center w-100">
                            <div className="ml-2 d-flex align-items-center"
                              style={{
                                width: "97%"
                              }}>
                              {dataRow?.Component}
                            </div>
                            {/* <div
                              className="ml-5 d-flex align-items-center"
                              style={
                                {
                                  // color: tool?.imactColor,
                                }
                              }
                            >
                              {dataRow.ImpactOnScore}
                            </div> */}
                            <div className="ml- 5 d-flex align-items-center"
                              style={{
                                marginLeft: "auto",
                                marginRight: "2.3rem"
                              }}>
                              {dataRow.ImpactOnScore}/{dataRow?.RemainingScore}
                            </div>
                            <div style={{ width: "69%", textAlign: "center" }}>{dataRow?.Details}</div>
                          </div>
                          <div className=" ">
                            <img
                              alt=""
                              src="/images/attack-surface/chevron-down.svg"
                              className="mr-2"
                              style={{
                                visibility: `${dataRow?.ShowExpandIcon ? "visible" : "hidden"}`
                              }}
                            />
                          </div>
                        </div>

                        <div
                          className="panel d-none"
                          onClick={(e) => e.stopPropagation()}
                        >
                          <div className="px-4">
                            <hr />
                            <div className="f-12 f-grey mb-2">
                              The following users have Dark Web Breaches in the
                              last 30 days:
                            </div>
                            <div className="w-50">
                              {Breaches.map((breach, breachIndex) => {
                                return (
                                  <div className="d-flex align-items-center justify-content-between my-3">
                                    <div className="f-violet">
                                      {breach.email}
                                    </div>
                                    <div className="f-grey">
                                      <span className="f-black">
                                        {breach.number}
                                      </span>
                                      Breaches
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  );
                })}
            </div>
            <div className="result-bar d-flex align-items-center justify-content-center bg-white radius-4 my-2 p-4">
              <div>
                <span className="f-darkgrey">Your</span>&nbsp;
                <span className="f-black">{data?.ScoreText}</span>
                &nbsp;&nbsp;&nbsp;
                <span className="f-black">{data?.ScoreValue}</span>&nbsp;&nbsp;
                <span className="" style={{ color: data?.ScoreRatingColour }}>
                  {data?.ScoreRating}
                </span>
                &nbsp;
              </div>
            </div>


          </>
        )}
      </Modal.Body>
      <ConnectIntegrationModal
        show={showConnectModal}
        hideModal={() => setShowConnectModal(false)}
        integrationId={selectedIntegrationId}
        refreshData={getPageData}
      />
      <UpgradeToPremiumModal
        show={showUpgradeModal}
        hideModal={() => setShowUpgradeModal(false)}
        upgradeOptions={upgradeOptions}

      />
    </Modal>
  );
};

export default TechnicalDrillDownCyberScoreModal;

