import React, { useEffect, useMemo, useRef, useState } from "react";
import SlidingModal from "../../../CustomModal/SlidingModal";
import { Button, Dropdown, Form, InputGroup, OverlayTrigger, Tooltip } from "react-bootstrap";
import "./mspPriceCalculator.scss";
import MspRadar, { MspSinglePointRadar } from "./MspRadar";
import CompanySettingsDropdown from "../../../settingsV2/companySettings/companysettingsdrodown/CompanySettingsDropdown";
import AddLabourCostDropdown from "./mspCalculatorDropdownAndModals/AddLabourCostDropdown";
import IncludesDropdown from "./mspCalculatorDropdownAndModals/IncludesDropdown";
import AddProductCostDropdown from "./mspCalculatorDropdownAndModals/AddProductCostDropdown";
import LinkToSeriesDropdown from "./mspCalculatorDropdownAndModals/LinkToSeriesDropdown";
import MspServiceModalToolTip from "./mspCalculatorDropdownAndModals/MspServiceModalToolTip";
import { getAPIData, postData } from "../../../../Utils/commonApi";
import { useAuth } from "../../../context/Auth";
import MspScoreModal from "./mspCalculatorDropdownAndModals/MspScoreModal";
import Loader from "../../../Common/loader";
import { CallToast } from "../../../PriceCalculator/modals/CustomToast";
import { removeWhiteSpace } from "../../../../Utils/CommonFunctions";
import {
  AddEditTaxRateModal,
  CurrencyDropdown,
  deleteTaxRate,
  TaxListDropdown,
  TaxRateDropdown,
} from "../../MspAttackSurface.js/addItemInSpendModal/AddItemInSpendModal";
import moment from "moment";
import { servicesIndex } from ".";
import { TrimString } from "../../../../Utils/StringTrimmer";
import AttackSurfaceTooltip from "../../../AttackSurface/AssetsList/AttackSurfaceTooltip";
import {
  CustomDeleteModal,
  DeleteModal,
} from "../../../DeleteModal/DeleteModal";
import AddProductSellDropdown from "./mspCalculatorDropdownAndModals/AddProductSellDropdown";
import ExploreMappingModal from "../../../SecurityAssessments/ExploreMappingModal";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import AddSnapToUnitDropdown from "./mspCalculatorDropdownAndModals/AddSnapToUnitDropdown";
import PriceChangedModal from "../PriceChangedModal";
import MspClientPriceCalculatorRadarChart from "../../mspClientPriceCalculator/MspClientPriceCalculatorRadarChart";
import SelectProductOrLabour from "./SelectProductOrLabour";

const MspAddEditServiceModal = ({
  show,
  hideModal,
  parentID,
  _selectedServiceId, // = 3960898,
  nistPillar,
  refreshData = () => { },
  setNestedProductModalState = () => { },
  nestedProductModalState = {},
  seriesModalData,
  setSeriesModalData = () => { },
  serviceList,
  returnData,
  isAddonService = false,
  setReturnData,
  _setNistIndex = () => { },
}) => {
  // home
  // addProduct
  const [selectedServiceId, setSelectedServiceId] = useState(
    _selectedServiceId ?? 0
  );

  useEffect(() => {
    setIsAddOn(isAddonService);
  }, [isAddonService]);

  const [page] = useState("home");
  const [loading, setLoading] = useState(false);
  const { authTokens } = useAuth();
  const [changed, setChanged] = useState(false);
  const [close, setClose] = useState(0);
  const [deletingService, setDeletingService] = useState(false);
  const [toolTipsData, setToolTipsData] = useState([]);
  const [saving, setSaving] = useState(false);

  // VARIABLES FOR MODAL---START
  const [serviceModalData, setServiceModalData] = useState({});
  // -----MSP SCORE MODAL------
  const [showMspScoreModal, setShowMspScoreModal] = useState(false);
  const [showExploreMappingModal, setShowExploreMappingModal] = useState(false);
  const [headline, setHeadline] = useState("");
  const [selectedNistPillar, setSelectedNistPillar] = useState();
  const [IsAddOn, setIsAddOn] = useState(false);
  const [description, setDescription] = useState("");
  const [selectedSnapToUnit, setSelectedSnapToUnit] = useState({});
  // LINK TO SERIES Variables

  const [selectedLinkList, setSelectedLinkList] = useState([]);
  const [updateModal, setUpdateModal] = useState(false);
  const [fieldErrors, setfieldErrors] = useState(0);
  // INCLUDES Variables
  const [parentIncludesList, setParentIncludesList] = useState([]);
  const [linkedProductsList, setLinkedProductsList] = useState([]);
  const [linkedIncludesList, setLinkedIncludesList] = useState([]);
  const [hideIncludes, setHideIncludes] = useState(false);
  // Billing Period Variables  {
  const [billingPeriodDropdownList, setBillingPeriodDropdownList] = useState(
    []
  );
  const [selectedBillingPeriod, setSelectedBillingPeriod] = useState(null);

  const [showRadar, setShowRadar] = useState(false);

  // TAX
  const [userSelectedCurrency, setUserSelectedCurrency] = useState({});
  const [defaultCurrency, setDefaultCurrency] = useState({});
  const [currencyList, setCurrencyList] = useState([]);
  const [taxList, setTaxList] = useState(allTaxList);
  const [exchangeRateData, setExchangeRateData] = useState({});
  const [serviceTax, setServiceTax] = useState();
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const [selectedTax, setSelectedTax] = useState();
  const [taxRateData, setTaxRateData] = useState({});
  const [showAddEditTaxRateModal, setShowAddEditTaxRateModal] = useState(false);
  const [hideProducts, setHideProducts] = useState({
    headline: "none",
    mode: false,
  });
  const [hideLabour, setHideLabour] = useState({
    headline: "none",
    mode: false,
  });
  const [HideMspSecopsLink, setHideMspSecopsLink] = useState(false);
  const [newServiceName, setNewServiceName] = useState("");
  const [isNameUnique, setIsNameUnique] = useState(true);
  const textareaRef = useRef();
  const [billingError, setBillingError] = useState(false);
  const [billingProductError, setBillingProductError] = useState(false);
  const [warningModalConfig, setWarningModalConfig] = useState({
    showWarningModal: false,
  });
  const [tempIdForlabourCounter, setTempIdForLabourCounter] = useState(1);

  const [servicePrice, setServicePrice] = useState("");
  const [productsList, setProductsList] = useState([]);
  const [labourList, setLabourList] = useState([]);

  const [allProductsList, setAllProductsList] = useState([]);

  const [servicePageErrors, setServicePageErrors] = useState({});

  const [addFeature, setAddFeature] = useState({});
  const [isFirstFocus, setIsFirstFocus] = useState(false);

  const [isHovered, setIsHovered] = useState(0);
  const [priceChanged, setPriceChanged] = useState(false);
  const [showPriceModal, setShowPriceModal] = useState(false);

  const [technologyMappingSelected, setTechnologyMappingSelected] = useState([]);
  const [technologyMappingSearch, setTechnologyMappingSearch] = useState('');
  const [pricedOnDemandSwitch, setPricedOnDemandSwitch] = useState(false);

  const [serviceComponents, setServiceComponents] = useState([]);

  function cleanModal() {
    setServicePageErrors({});
    setPriceChanged(false);
    setProductsList([]);
    setLabourList([]);
    setServiceModalData({});
    setHeadline("");
    setSelectedNistPillar();
    _setNistIndex(0);
    setIsAddOn(false);
    setPricedOnDemandSwitch(false);
    setDescription("");
    setSelectedSnapToUnit({});
    setSelectedBillingPeriod({});
    setServicePrice("");
    setBillingProductError(false);
    setBillingError(false);
    setIsNameUnique(true);
    setNewServiceName("");
    setAddFeature({});
    setIsFirstFocus(false);
    setTechnologyMappingSelected([]);
    setTechnologyMappingSearch('');
  }
  useEffect(() => {
    setSelectedServiceId(_selectedServiceId ?? 0);
    if (show) {
      setChanged(false);
      setLoading(true);
      getServiceData(_selectedServiceId ?? 0);
      // getServiceComponents();
    }
  }, [_selectedServiceId, show]);

  useEffect(() => {
    if (show) {
      cleanModal();
      getToolTipsData();
      // getProductsList();
    }
  }, [show]);
  useEffect(() => {
    if (show) {
    }
  }, [productsList]);

  useEffect(() => {
    if (serviceModalData?.CurrencyCode_Selected?.CurrencyName && (serviceModalData?.CurrencyCode_Selected?.CurrencyName != userSelectedCurrency?.CurrencyName)) {
      if (userSelectedCurrency?.CurrencyName || defaultCurrency?.CurrencyName) {
        updateExchangeRateData(userSelectedCurrency?.CurrencyName ?? defaultCurrency?.CurrencyName ?? "GBP");
      }
    }
  }, [userSelectedCurrency]);

  useEffect(() => {
    if (show) {
      setHeadline(newServiceName);
    }
  }, [show, newServiceName]);

  useEffect(() => {
    if (nestedProductModalState?.selectedProductId && nestedProductModalState?.show == false) {
      getServiceData(_selectedServiceId);
    }
  }, [nestedProductModalState])

  useEffect(() => {
    if (nestedProductModalState?.update) {
      setNestedProductModalState((prev) => ({ ...prev, update: false }));
      setLoading(true);
      getProductsList().then((response) => {
        setLoading(false);
      });
    }
  }, [nestedProductModalState]);

  useEffect(() => {
    if (show) {
      // Check if the default service name already exists
      if (selectedServiceId === 0) {
        const defaultServiceName = "New Service 1";
        let incrementedName = defaultServiceName;
        let counter = 1;

        while (
          serviceList?.some((service) => service.Name == incrementedName)
        ) {
          counter++;
          incrementedName = `New Service ${counter}`;
        }

        // Set the default service name (incremented if necessary)
        setNewServiceName(incrementedName);
        setHeadline(incrementedName);
      } else {
      }
    }
  }, [serviceList, selectedServiceId, show]);

  useEffect(() => {
    if (show && serviceModalData?.Headline && !loading) {
      const textarea = textareaRef.current;
      if (textarea) {
        // Set the initial height based on the scrollHeight
        textarea.style.height = `${textarea.scrollHeight}px`;
      }
    }
  }, [show, serviceModalData, loading]); // Run this effect only once on mount

  function updateExchangeRateData(_currency = "GBP") {
    return new Promise((resolve, reject) => {
      postData(authTokens, "GetCurrencyConverionRate", {
        FromCurrency: _currency,
        ToCurrency: "GBP",
        accessKey: JSON.parse(localStorage.getItem("user_MessageObj")),
      })
        .then((response) => {
          resolve(response);
          setExchangeRateData(response);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  }

  async function getExchangeRateData(_currency = "GBP") {
    let currency = await postData(authTokens, "GetCurrencyConverionRate", {
      FromCurrency: _currency,
      ToCurrency: "GBP",
      accessKey: JSON.parse(localStorage.getItem("user_MessageObj")),
    })
      .then((response) => {
        // setExchangeRateData(response);
        return response;
      })
      .catch((err) => {
        console.log(err);
      });
    return currency;
  }

  const data = useMemo(() => {
    let activeLabels = [false, false, false, false, false];
    let updated_labels = ["", "", "", "", ""];
    let updated_point_radius = [0, 0, 0, 0, 0];
    let updated_data = [0, 0, 0, 0, 0];
    return {
      labels: updated_labels,
      activeLabels: activeLabels,

      datasets: [
        {
          data: updated_data,
          pointRadius: updated_point_radius,
          datalabels: {
            display: false,
          },
          backgroundColor: `${"#008fd4"}30`,
          borderColor: `${"#008fd4"}`, // "rgba(0, 143, 212, 1)",
          pointBackgroundColor: "#008fd4",
          borderWidth: 2,
          pointLabelFontSize: 0,
          scaleShowLabels: false,
          label: "Current posture",
        },
      ],
    };
  }, []);

  const _options = useMemo(() => {
    return {
      responsive: true,
      maintainAspectRatio: false,
      legend: {
        display: false,
      },

      tooltips: {
        enabled: true,
      },
      scale: {
        display: true,
        pointLabels: {
          fontSize: 16,
          fontFamily: "Archivo, sans-serif",
          fontColor: "#101010",
        },
        ticks: {
          beginAtZero: false,
          stepSize: 5,
          max: 35,
          min: 0,
          display: false,
          backdropColor: "rgba(0, 0, 0, 0)",
        },
      },
      elements: {
        point: {
          radius: 2,
          hoverRadius: 4,
        },
      },
    };
    // return opts;
  }, []);

  async function getToolTipsData() {
    await getAPIData(623, authTokens, {
      optionEnum1: 0,
      optionEnum2: 0,
    }).then((response) => {
      setToolTipsData(response?.PackageHelpToolTip_List);
    });
  }

  const [servData, setServData] = useState({});
  // async function getServiceComponents() {
  //   await getAPIData(611, authTokens, {
  //     optionEnum1: 9592011,
  //   }).then((response) => {
  //     setServData(response);
  //     setServiceComponents(response?.ScoreContributionSelected_List);
  //     // setToolTipsData(response?.PackageHelpToolTip_List);
  // });
  // }

  function getToolTip(_enum = 0) {
    if (toolTipsData?.length > 0) {
      let data = [...toolTipsData];
      let itemIndex = data.findIndex(
        (item) => item?.PackageHelpToolTip_Enum === _enum
      );
      if (itemIndex === -1) {
        return {};
      } else {
        return data[itemIndex];
      }
    } else {
      return {};
    }
  }

  async function updateRadarChart() {
    setLoading(true);
    await getAPIData(611, authTokens, {
      optionEnum1: selectedServiceId,
    }).then((response) => {
      setShowRadar(response?.ShowNistGraph);
      setServiceModalData(response);
      setTechnologyMappingSelected(response?.ServiceTechnologiesSelected_List ?? []);
      setServiceComponents(response?.ScoreContributionSelected_List);
      setLoading(false);
    });
  }

  async function getServiceData(objId = 0, fromFeatures = false, oldData) {
    await getAPIData(611, authTokens, {
      optionEnum1: objId,
    }).then((response) => {
      if (response) {
        if (nestedProductModalState?.selectedProductId && nestedProductModalState?.show == false) {
          setProductsList(response?.SecurityPackPSAProduct_List ?? []);
          setLoading(false);
          return;
        }
        setServiceModalData(response);
        setServiceComponents(response?.ScoreContributionSelected_List);
        setShowRadar(response?.ShowNistGraph);
        // setParentIncludesList(response?.SecurityPack_Bullet_List);
        setHeadline(response?.Headline ? response?.Headline : newServiceName);
        console.log(headline, newServiceName);
        if (response?.Headline) {
          setNewServiceName(response?.Headline);
        }
        setDescription(response?.Sku ?? "");
        setServicePrice(response?.MonthlyPrice_Text);
        setSelectedLinkList(response?.SecurityPackLinkSeries_List ?? []);
        setLinkedIncludesList(response?.SecurityPack_Bullet_List ?? []);
        setLabourList(response?.SecurityPackLabour_List ?? []);
        setTechnologyMappingSelected(response?.ServiceTechnologiesSelected_List ?? []);
        if (response?.NistPillar_Selected) {
          setSelectedNistPillar(response?.NistPillar_Selected ?? null);
        } else {
          setSelectedNistPillar(nistPillar ?? "");
          _setNistIndex(nistPillar?.DDLId);
        }
        setBillingPeriodDropdownList(response?.BillingPeriod_List ?? []);
        setProductsList(response?.SecurityPackPSAProduct_List ?? []);
        setIsAddOn(
          isAddonService === true ? isAddonService : response?.IsAddOn
        );
        setPricedOnDemandSwitch(response?.IsOnDemand);
        // Set Billing Period

        setSelectedBillingPeriod(response?.BillingPeriod_Selected ?? null);

        // Set Snap To units
        let selected_snapToUnit = response?.SnapToUnit_List?.filter(
          (item) => item?.DDLText === response?.SelectionType_Text
        )?.[0];
        if (selected_snapToUnit) {
        }
        setSelectedSnapToUnit(
          response?.SnapToUnit_Selected ?? selected_snapToUnit ?? null
        );

        // Tax logic
        setCurrencyList(response?.CurrencyCode_List);
        updateExchangeRateData(
          response?.CurrencyCode_Selected?.CurrencyName ?? response?.CurrencyCode_List?.find(f => f.IsDefault)?.CurrencyName ?? "GBP"
        );
        setDefaultCurrency(response?.CurrencyCode_List?.find(f => f.IsDefault))
        setUserSelectedCurrency(
          response?.CurrencyCode_Selected ??
          (response?.CurrencyCode_List && response?.CurrencyCode_List?.find(f => f.IsDefault))
        );
        setTaxList(response?.TaxRates_List);
        setServiceTax(response?.TaxRates_Selected);
        setSelectedTax(response?.TaxRates_Selected);
        setHideMspSecopsLink(response?.HideMspSecopsLink);
        // Stop Loading
        setLoading(false);
      }
    });
  }

  async function getLinkedData(_linkId) {
    await getAPIData(611, authTokens, {
      optionEnum1: _linkId,
    })
      .then((response) => {
        if (response?.SecurityPack_Bullet_List?.length > 0) {
          let response_include_list = [...response?.SecurityPack_Bullet_List];
          for (let index = 0; index < response_include_list.length; index++) {
            let includes_list = [...linkedIncludesList];

            let includeIndex = includes_list?.findIndex(
              (item) =>
                item?.objId_SecurityPackBullet ===
                response_include_list[index].objId_SecurityPackBullet
            );
            if (includeIndex > -1) {
              includes_list.splice(includeIndex, 1);
            } else {
              includes_list.push({ ...response_include_list[index] });
            }
            setLinkedIncludesList(includes_list ?? []);
          }
        }

        if (response?.SecurityPackPSAProduct_List?.length > 0) {
          let response_product_list = [
            ...response?.SecurityPackPSAProduct_List,
          ];
          for (let index = 0; index < response_product_list.length; index++) {
            let product_list = [...linkedProductsList];

            let includeIndex = product_list?.findIndex(
              (item) =>
                item?.objId_SecurityPackBullet ===
                response_product_list[index].ObjId_PSAProduct
            );
            if (includeIndex > -1) {
              product_list.splice(includeIndex, 1);
            } else {
              product_list.push({ ...response_product_list[index] });
            }
            setLinkedProductsList(product_list ?? []);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function saveButtonDisabled() {
    return false;
  }

  async function createAddService(changeData) {
    return new Promise((resolve, reject) => {
      postData(authTokens, "CreateSecurityStackServices", changeData ? {
        ObjectId: selectedServiceId ?? 0,
        accesskey: JSON.parse(localStorage.getItem("user_MessageObj")),
        Headline: newServiceName, //headline
        Sku: description,
        IsAddOn: IsAddOn,
        IsOnDemand: pricedOnDemandSwitch,
        MonthlyPrice: servicePrice,
        PricingModel: selectedBillingPeriod?.DDLText,
        NistPiller: selectedNistPillar?.DDLText,
        SelectionType: selectedSnapToUnit?.DDLText,
        SnapToUnit_Selected: JSON.stringify(selectedSnapToUnit),
        SecurityPack_Bullet_List: JSON.stringify(
          linkedIncludesList?.sort((a, b) => a.Order - b.Order) ?? []
        ), //linkedIncludesList
        SecurityPack_OptionList: "",
        SecurityPackLabour_List: JSON.stringify(labourList ?? []),
        SecurityPackLinkSeries_List: JSON.stringify(selectedLinkList),
        SecurityPackPSAProduct_List: JSON.stringify(productsList ?? []),
        ServiceTechnologiesSelected_List: JSON.stringify(technologyMappingSelected ?? []),
        ForeignCurrencyAmount: "",
        ForeignCurrencyRate: exchangeRateData?.Rate ?? 1,
        CurrencyCode_Selected: JSON.stringify(userSelectedCurrency),
        TaxRates_Selected: JSON.stringify(serviceTax ?? {}),
        TotalCost: (totalProductsCost() + totalLabourPrice())?.toFixed(2),
        TotalSell: getTotalServiceAmount(),
        PackageServicesOfProductVM: JSON.stringify(changeData),
        ScoreContributionSelected_List: JSON.stringify(serviceComponents ?? [])
      } : {
        ObjectId: selectedServiceId ?? 0,
        accesskey: JSON.parse(localStorage.getItem("user_MessageObj")),
        Headline: newServiceName, //headline
        Sku: description,
        IsAddOn: IsAddOn,
        IsOnDemand: pricedOnDemandSwitch,
        MonthlyPrice: servicePrice,
        PricingModel: selectedBillingPeriod?.DDLText,
        NistPiller: selectedNistPillar?.DDLText,
        SelectionType: selectedSnapToUnit?.DDLText,
        SnapToUnit_Selected: JSON.stringify(selectedSnapToUnit),
        SecurityPack_Bullet_List: JSON.stringify(
          linkedIncludesList?.sort((a, b) => a.Order - b.Order) ?? []
        ), //linkedIncludesList
        SecurityPack_OptionList: "",
        SecurityPackLabour_List: JSON.stringify(labourList ?? []),
        SecurityPackLinkSeries_List: JSON.stringify(selectedLinkList),
        SecurityPackPSAProduct_List: JSON.stringify(productsList ?? []),
        ServiceTechnologiesSelected_List: JSON.stringify(technologyMappingSelected ?? []),
        ForeignCurrencyAmount: "",
        ForeignCurrencyRate: exchangeRateData?.Rate ?? 1,
        CurrencyCode_Selected: JSON.stringify(userSelectedCurrency),
        TaxRates_Selected: JSON.stringify(serviceTax ?? {}),
        TotalCost: (totalProductsCost() + totalLabourPrice())?.toFixed(2),
        TotalSell: getTotalServiceAmount(),
        ScoreContributionSelected_List: JSON.stringify(serviceComponents ?? [])
      })
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          CallToast("Something went wrong", false, false, "v2style");
          reject(err);
          console.log(err);
        });
    });
  }

  const [creatingId, setCreatingId] = useState(false);
  async function createServiceId() {
    setCreatingId(true);
    return new Promise((resolve, reject) => {
      postData(authTokens, "CreateSecurityStackServices", {
        ObjectId: selectedServiceId ?? 0,
        accesskey: JSON.parse(localStorage.getItem("user_MessageObj")),
        Headline: newServiceName, //headline
        Sku: description,
        IsAddOn: IsAddOn,
        IsOnDemand: pricedOnDemandSwitch,
        MonthlyPrice: servicePrice,
        PricingModel: selectedBillingPeriod?.DDLText,
        NistPiller: selectedNistPillar?.DDLText,
        SelectionType: selectedSnapToUnit?.DDLText,
        SnapToUnit_Selected: JSON.stringify(selectedSnapToUnit),
        SecurityPack_Bullet_List: JSON.stringify(
          linkedIncludesList?.sort((a, b) => a.Order - b.Order) ?? []
        ), //linkedIncludesList
        SecurityPack_OptionList: "",
        SecurityPackLabour_List: JSON.stringify(labourList ?? []),
        SecurityPackLinkSeries_List: JSON.stringify(selectedLinkList),
        SecurityPackPSAProduct_List: JSON.stringify(productsList ?? []),
        ServiceTechnologiesSelected_List: JSON.stringify(technologyMappingSelected ?? []),
        ForeignCurrencyAmount: "",
        ForeignCurrencyRate: exchangeRateData?.Rate ?? 1,
        CurrencyCode_Selected: JSON.stringify(userSelectedCurrency),
        TaxRates_Selected: JSON.stringify(serviceTax ?? {}),
        TotalCost: (totalProductsCost() + totalLabourPrice())?.toFixed(2),
        TotalSell: getTotalServiceAmount(),
      })
        .then((response) => {
          setCreatingId(false);
          setSelectedServiceId(response?.EntityId ?? 0);
          resolve(response);
        })
        .catch((err) => {
          CallToast("Something went wrong", false, false, "v2style");
          reject(err);
          console.log(err);
        });
    });
  }

  function getProductsList() {
    return new Promise((resolve, reject) => {
      getAPIData(637, authTokens, {
        // _categoryId,
      })
        .then((response) => {
          if (response?.length > 0) {
            setAllProductsList(response);
            resolve(response);
          } else {
            resolve([]); // Resolving with an empty array if response is not an array or has length <= 0
          }
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  }
  async function deleteLinkedSerie(_seriesId) {
    await getAPIData(640, authTokens, {
      optionEnum1: _seriesId,
      optionEnum2: 0,
    }).then((response) => {
      if (response.Success) {
        CallToast("Deleted Successfully", false, false, "v2style");
        setLoading(true);
        getServiceData(_selectedServiceId);
      } else {
        CallToast("Something went wrong", false, false, "v2style");
      }
      return true;

      // getLinkedSeries(selectedNistPillar?.DDLId);
    });
  }
  async function deleteData(objId, pageEnum) {
    await getAPIData(662, authTokens, {
      optionEnum1: objId,
    })
      .then((response) => {
        if (response?.Success) {
          CallToast("Deleted Successfully", false, false, "v2style");
          hideModal();
          setPriceChanged(false);
          cleanModal();
          refreshData();
        } else {
          CallToast("Something went wrong", false, false, "v2style");
        }

        setDeletingService(false);
      })
      .catch((err) => {
        console.log(err);
        CallToast("Something went wrong", false, false, "v2style");
      });
  }
  function getTaxRateList() {
    return new Promise((resolve, reject) => {
      getAPIData("611", authTokens, {
        // ObjId_Customer: clientId,
        accessKey: JSON.parse(localStorage.getItem("user_MessageObj")),
      })
        .then((response) => {
          resolve(response);
          setTaxList(response?.TaxRates_List ?? []);
          setServiceComponents(response?.ScoreContributionSelected_List);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  }
  function updateTaxList(stoploading = false) {
    if (!stoploading) {
      setLoading(true);
    }
    getTaxRateList()
      .then((response) => {
        setTaxList(response?.TaxRates_List);
        //if selectd tax item not exist in Tax list set to default
        let selectedTaxIndex = response?.TaxRates_List?.findIndex(
          (item) => item?.ObjectId === selectedTax?.ObjectId
        );
        if (selectedTaxIndex === -1) {
          setSelectedTax(response?.TaxRates_List?.[0]);
        }

        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }

  // Function for errors

  async function CheckValidationForServicePage(type) {
    let errors = 0;
    if (type == "align") {
      if (newServiceName === "") {
        // if (headline === "") {
        errors += 1;
        setServicePageErrors((prev) => ({ ...prev, headlineError: true }));
      }
      if (
        selectedServiceId === 0 &&
        serviceList?.some((service) => service.Name === newServiceName.trim())
      ) {
        // if (headline === "") {
        errors += 1;
        setServicePageErrors((prev) => ({ ...prev, headlineExistError: true }));
      }
      // if (!selectedNistPillar) {
      //   errors += 1;
      //   setServicePageErrors((prev) => ({
      //     ...prev,
      //     selectedNistPillarError: true,
      //   }));
      // }
      // if (linkedIncludesList?.length == 0) {
      //   errors += 1;
      //   setServicePageErrors((prev) => ({
      //     ...prev,
      //     selectedIncludesListError: true,
      //   }));
      // }
    } else {
      if (newServiceName === "") {
        // if (headline === "") {
        errors += 1;
        setServicePageErrors((prev) => ({ ...prev, headlineError: true }));
      }
      if (
        selectedServiceId === 0 &&
        serviceList?.some((service) => service.Name === newServiceName.trim())
      ) {
        // if (headline === "") {
        errors += 1;
        setServicePageErrors((prev) => ({ ...prev, headlineExistError: true }));
      }
      // if (!selectedNistPillar) {
      //   errors += 1;
      //   setServicePageErrors((prev) => ({
      //     ...prev,
      //     selectedNistPillarError: true,
      //   }));
      // }

      if (!pricedOnDemandSwitch) {

        if (!selectedBillingPeriod) {
          errors += 1;
          setServicePageErrors((prev) => ({
            ...prev,
            selectedBillingPeriodError: true,
          }));
        }

        if (!selectedSnapToUnit) {
          errors += 1;
          setServicePageErrors((prev) => ({
            ...prev,
            selectedSnapToUnitsError: true,
          }));
        }

        // if (linkedIncludesList?.length == 0) {
        //   errors += 1;
        //   setServicePageErrors((prev) => ({
        //     ...prev,
        //     selectedIncludesListError: true,
        //   }));
        // }

        if (!servicePrice) {
          errors += 1;
          setServicePageErrors((prev) => ({
            ...prev,
            selectedServicePriceError: true,
          }));
        }

        if (!serviceTax) {
          errors += 1;
          setServicePageErrors((prev) => ({
            ...prev,
            selectedTaxError: true,
          }));
        }

        if (productsList?.length == 0 && labourList?.length == 0) {
          errors += 1;
          setServicePageErrors((prev) => ({
            ...prev,
            selectedListError: true,
          }));
        }
      }
    }

    return errors;
  }

  const _actualData = useMemo(() => {
    let activeLabels = [false, false, false, false, false];
    let total_sum = 0;
    let updated_labels = ["", "", "", "", ""];
    let updated_point_radius = [0, 0, 0, 0, 0];
    let updated_data = [35, 35, 35, 35, 35];
    let data_text_array = [
      "Delta_Identity",
      "Delta_Protect",
      "Delta_Detect",
      "Delta_Respond",
      "Delta_Recover",
    ];
    let first_positiveValue_index = data_text_array.findIndex(
      (item) => serviceModalData?.[item] > 0
    );

    const valuesArray = data_text_array.map(key => serviceModalData[key] == 0 ? 35 : serviceModalData[key]);
    // const valuesArray = [10, 35, 20, 35, 4]

    if (first_positiveValue_index > -1) {
      // total_sum =
      //   serviceModalData?.[data_text_array[first_positiveValue_index]];
      // let activeIndex = servicesIndex[selectedNistPillar?.DDLText];

      // updated_data[activeIndex] = total_sum;
      // activeLabels[activeIndex] = true;
      // updated_point_radius[activeIndex] = 3;

      updated_data = valuesArray;
      activeLabels = activeLabels.map((label, index) => valuesArray[index] !== 35 ? true : label);
      updated_point_radius = updated_point_radius.map((label, index) => valuesArray[index] !== 35 ? 3 : label);
    }

    return {
      labels: updated_labels,
      activeLabels: activeLabels,

      datasets: [
        {
          data: updated_data,
          pointRadius: updated_point_radius,
          datalabels: {
            display: false,
          },
          backgroundColor: `${"#008fd4"}30`,
          borderColor: `${"#008fd4"}`, // "rgba(0, 143, 212, 1)",
          pointBackgroundColor: "#008fd4",
          borderWidth: 2,
          pointLabelFontSize: 0,
          scaleShowLabels: false,
          // label: selectedNistPillar?.DDLText ?? "Current posture",
        },
      ],
    };
  }, [/*selectedNistPillar,*/ serviceModalData]);

  const _actualOptions = useMemo(() => {
    return {
      animation: {
        duration: 1, // 4000, // Set the duration of the transition in milliseconds
      },
      responsive: true,
      maintainAspectRatio: false,
      legend: {
        display: false,
      },

      tooltips: {
        enabled: true,
      },
      scale: {
        display: true,
        pointLabels: {
          fontSize: 16,
          fontFamily: "Archivo, sans-serif",
          fontColor: "#101010",
        },
        ticks: {
          beginAtZero: false,
          stepSize: /*5*/10,
          max: /*50*/40,
          min: 0,
          display: false,
          backdropColor: "rgba(0, 0, 0, 0)",
        },
      },
      elements: {
        point: {
          radius: 2,
          hoverRadius: 4,
        },
      },
    };
    // return opts;
  }, []);

  function getTotalServiceAmount() {
    // return Number(
    //   Number(servicePrice ?? 0) * Number(exchangeRateData?.Rate ?? 1) +
    //     Number(((servicePrice ?? 0) * (serviceTax?.TaxRate ?? 0)) / 100 ?? 0)
    // )?.toFixed(2);
    return Number(servicePrice ?? 0)?.toFixed(2);
  }

  function getLabourPrice(labour) {
    let price =
      // (labour?.ForeignCurrencyRate
      //   ? labour?.ForeignCurrencyRate
      //   : exchangeRateData?.Rate) *
      (labour?.LabourRate * labour?.NoOfHours);
    return price ? price : 0;
  }

  function getProductCost(product) {
    let cost = 0;
    if (product?.BillingPeriod_Text?.toLowerCase() === "month") {
      if (selectedBillingPeriod?.DDLColour?.toLowerCase() === "month") {
        cost =
          // Math.ceil(
          (product?.ForeignCurrencyRate ?? 1) *
          (product?.Cost * product?.Quantity);
        // )
      } else if (
        selectedBillingPeriod?.DDLColour?.toLowerCase() === "quarter"
      ) {
        cost =
          // Math.ceil(
          (product?.ForeignCurrencyRate ?? 1) *
          (product?.Cost * product?.Quantity) *
          // )
          3;
      } else if (selectedBillingPeriod?.DDLColour?.toLowerCase() === "year") {
        cost =
          // Math.ceil(
          (product?.ForeignCurrencyRate ?? 1) *
          (product?.Cost * product?.Quantity) *
          // )
          12;
      }
    } else if (product?.BillingPeriod_Text?.toLowerCase() === "quarter") {
      if (selectedBillingPeriod?.DDLColour?.toLowerCase() === "month") {
        cost =
          // Math.ceil(
          ((product?.ForeignCurrencyRate ?? 1) *
            (product?.Cost * product?.Quantity)) /
          // )
          3;
      } else if (
        selectedBillingPeriod?.DDLColour?.toLowerCase() === "quarter"
      ) {
        cost =
          //Math.ceil(
          (product?.ForeignCurrencyRate ?? 1) *
          (product?.Cost * product?.Quantity);
        // )
      } else if (selectedBillingPeriod?.DDLColour?.toLowerCase() === "year") {
        cost =
          // Math.ceil(
          (product?.ForeignCurrencyRate ?? 1) *
          (product?.Cost * product?.Quantity) *
          // )
          4;
      }
    } else if (product?.BillingPeriod_Text?.toLowerCase() === "year") {
      if (selectedBillingPeriod?.DDLColour?.toLowerCase() === "month") {
        cost =
          // Math.ceil(
          ((product?.ForeignCurrencyRate ?? 1) *
            (product?.Cost * product?.Quantity)) /
          // )
          12;
      } else if (
        selectedBillingPeriod?.DDLColour?.toLowerCase() === "quarter"
      ) {
        cost =
          // Math.ceil(
          ((product?.ForeignCurrencyRate ?? 1) *
            (product?.Cost * product?.Quantity)) /
          // )
          4;
      } else if (selectedBillingPeriod?.DDLColour?.toLowerCase() === "year") {
        cost =
          // Math.ceil(
          (product?.ForeignCurrencyRate ?? 1) *
          (product?.Cost * product?.Quantity);
        // )
      }
    }

    return cost ? cost : 0;
  }

  function totalProductsCost() {
    const currentProductTotal = productsList?.reduce((accumulator, product) => {
      return accumulator + getProductCost(product);
    }, 0);

    const prevProductTotal = (selectedLinkList[0]?.SecurityPackVM_List ?? [])
      ?.filter((serv) => serv?.Headline !== headline)
      ?.reduce((accumulator, serv) => {
        if (serv?.SecurityPackPSAProduct_List?.length > 0) {
          const servTotal = (serv?.SecurityPackPSAProduct_List ?? [])?.reduce(
            (productAccumulator, product) => {
              return productAccumulator + getProductCost(product);
            },
            0
          );
          return accumulator + servTotal;
        }
        return accumulator;
      }, 0);

    return isNaN(currentProductTotal + prevProductTotal)
      ? 0
      : currentProductTotal + prevProductTotal;
  }
  function totalLabourPrice() {
    const currentLabour = labourList?.reduce((accumulator, labour) => {
      return accumulator + getLabourPrice(labour);
    }, 0);

    const prevLabour = (selectedLinkList[0]?.SecurityPackVM_List ?? [])
      ?.filter((serv) => serv?.Headline !== headline)
      ?.reduce((accumulator, serv) => {
        if (serv?.SecurityPackLabour_List?.length > 0) {
          const servTotal = (serv?.SecurityPackLabour_List ?? [])?.reduce(
            (productAccumulator, labour) => {
              return productAccumulator + getLabourPrice(labour);
            },
            0
          );
          return accumulator + servTotal;
        }
        return accumulator;
      }, 0);

    return isNaN(currentLabour + prevLabour) ? 0 : currentLabour + prevLabour;
  }


  const handleInputChange = (e) => {
    setChanged(true);
    setServicePageErrors((prev) => ({
      ...prev,
      headlineError: false,
      headlineExistError: false,
    }));
    const newName = e.target.value;
    setNewServiceName(newName);

    // Check if the entered name is unique
    setIsNameUnique(
      !serviceList?.some((service) => service.Name === newName.trim())
    );
  };

  const handleInputBlur = () => {
    // Check uniqueness and set the service name in state
    if (newServiceName.trim() === "") {
      // Optionally handle the case when the name is empty
      setServicePageErrors((prev) => ({ ...prev, headlineError: true }));
    } else if (isNameUnique) {
      // Set the service name in state only if it's unique
      setHeadline(newServiceName.trim());
    } else {
      // Optionally handle the case when the name is not unique
      setServicePageErrors((prev) => ({ ...prev, headlineExistError: true }));
    }
  };

  const [shouldScrollToRow, setShouldScrollToRow] = useState(false);
  const [itemsUpdated, setItemsUpdated] = useState(false);
  const elemsRefs = {
    element1: useRef(null),
    // element2: useRef(null),
    element3: useRef(null),
    element4: useRef(null),
    element5: useRef(null),
    element6: useRef(null),
    element7: useRef(null),
    element8: useRef(null),
    element9: useRef(null),
  };

  useEffect(() => {
    if (shouldScrollToRow) {
      let _modal = document.querySelector(".msp-service-add-edit-modal");
      const firstElement = _modal.querySelector(".input-error");

      if (firstElement) {
        elemsRefs.element1.current = firstElement;
        setTimeout(() => {
          firstElement.scrollIntoView({
            behavior: "smooth",
            block: "center",
          });
        }, 100);
      }
      setItemsUpdated(false);
      setShouldScrollToRow(false);
    }
  }, [shouldScrollToRow]);

  useEffect(() => {
    // Listening for items getting added
    if (itemsUpdated) {
      setShouldScrollToRow(true);
      setItemsUpdated(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemsUpdated]);

  useEffect(() => {
    if (!changed) {
      hideModal();
      setPriceChanged(false);
      cleanModal();
      setChanged(false);
    } else {
      setWarningModalConfig((prev) => ({
        ...prev,
        showWarningModal: true,
      }));
    }
  }, [close]);

  function checkClosing() {
    setClose((prevClose) => prevClose + 1);
    console.log(close);
  }

  function autoAddProduct(product) {
    let updated_product_list = [...productsList];
    let index = updated_product_list.findIndex(
      (item) => item?.ObjId_PSAProduct === product?.ObjId_PSAProduct
    );

    if (index !== -1) {
      updated_product_list[index] = { ...product };
    } else {
      updated_product_list.push({ ...product });
    }

    setProductsList([...updated_product_list]);
    setReturnData({});
  }

  useEffect(() => {
    if (updateModal) {
      createAddService().then(() => {
        getServiceData(selectedServiceId ?? 0);
        setUpdateModal(false);
      });
    }
  }, [updateModal]);

  function linkCurrSerie(selectedSerie) {
    let updated_list = [...selectedLinkList];
    // setUpdateModal(true);
    if (selectedSerie) {
      let isLinkedItemIndex = selectedLinkList?.findIndex(
        (item) =>
          item?.objId_SecurityPackLinkSeries ===
          selectedSerie?.objId_SecurityPackLinkSeries
      );
      if (isLinkedItemIndex > -1) {
        updated_list.splice(isLinkedItemIndex, 1);
      } else {
        updated_list.push({ ...selectedSerie });
      }
      setUpdateModal(true);

      setSelectedLinkList(updated_list);
    } else {
      setUpdateModal(true);

      setSelectedLinkList([]);
      setLinkedProductsList([]);
    }
  }

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  function onDragEndShown(result) {
    if (!result.destination) {
      return;
    }
    // console.log(result.source.index);
    const items = reorder(
      linkedIncludesList,
      result.source.index,
      result.destination.index
    );
    setLinkedIncludesList(
      items.map((item, index) => {
        return {
          ...item,
          Order: index + 1,
        };
      })
    );
  }
  const textareaRef2 = useRef(null);
  const textareaRefAddFeature = useRef(null);

  function moveCaretAtEnd(e) {
    if (!isFirstFocus) {
      setIsFirstFocus(true);
    }

    // var temp_value = e.target.value;
    // e.target.value = "";
    // e.target.value = temp_value;
  }

  useEffect(() => {
    const handleWindowClick = (event) => {
      const targetElement = event.target;
      if (
        !targetElement.closest(".drag-item") &&
        !targetElement.closest(".addfeature")
      ) {
        let newAdd = {
          ...addFeature,
        };
        newAdd.title = "";
        setAddFeature(newAdd);
      }
    };

    window.addEventListener("click", handleWindowClick);

    return () => {
      window.removeEventListener("click", handleWindowClick);
    };
  }, []);

  const textareaRefs = useRef([]);
  const [changedFeature, setChangedFeature] = useState(false);
  const [selectionStart, setselectionStart] = useState();
  const [selectionEnd, setselectionEnd] = useState();

  useEffect(() => {
    if (
      changedFeature &&
      textareaRefs.current.length > 0 &&
      selectionStart !== undefined &&
      selectionEnd !== undefined
    ) {
      textareaRefs.current.forEach((ref) => {
        if (ref && ref.current) {
          ref.current.selectionStart = selectionStart;
          ref.current.selectionEnd = selectionEnd;
        }
      });
      setChangedFeature(false);
    }
  }, [changedFeature, selectionStart, selectionEnd]);

  useEffect(() => {
    textareaRefs.current = Array(linkedIncludesList.length)
      .fill()
      .map((_, i) => textareaRefs.current[i] || React.createRef());
  }, [linkedIncludesList.length]);

  /// add feature
  useEffect(() => {
    adjustTextareaHeightAddFeature();
  }, [addFeature]);

  const adjustTextareaHeightAddFeature = () => {
    if (textareaRefAddFeature.current) {
      textareaRefAddFeature.current.style.height = "auto";
      textareaRefAddFeature.current.style.height =
        textareaRefAddFeature.current.scrollHeight + "px";
    }
  };

  return (
    <SlidingModal
      show={show}
      parentID={parentID}
      id="serviemmodal"
      width={"720px"}
      onHide={() => {
        checkClosing();
      }}
    >
      <SlidingModal.Header
        className={`text-center d-flex p-3 border-bottom  ${nestedProductModalState?.show ? "bg-grey-imp pointer-events-none" : ""
          }`}
      >
        <div
          className="w-100 mb-0 font-bold-14 title"
          style={{ fontWeight: "600" }}
        >
          {selectedServiceId === 0 ? "Add Service" : "Edit Service"}
        </div>

        <span
          className="cursor-pointer"
          onClick={() => {
            if (!changed) {
              hideModal();
              setPriceChanged(false);
              cleanModal();
              setChanged(false);
            } else {
              setWarningModalConfig((prev) => ({
                ...prev,
                showWarningModal: true,
              }));
            }
          }}
        >
          <img src="/images/attack-surface/cross-icon.svg" alt="" />
        </span>
      </SlidingModal.Header>
      <SlidingModal.Body
        className={`msp-service-add-edit-modal    ${page === "home" ? "p-3" : ""
          }`}
      >
        {loading ? (
          <div
            className="d-flex align-items-center justify-content-center w-100 "
            style={{
              height: "85vh",
            }}
          >
            <Loader />
          </div>
        ) : (
          <>
            {page === "home" && (
              <>
                <div className="mb-2">
                  <div
                    // ref={elemRef}
                    ref={elemsRefs.element1}
                    className={`highlight-input-with-grey-border w-100  d-flex align-items-center p-2 radius-4 mt-2   ${servicePageErrors?.headlineError ||
                      servicePageErrors?.headlineExistError
                      ? "input-error"
                      : ""
                      } `}
                  >
                    <div className="f-darkgrey">Name:</div>
                    <input
                      tabIndex={0}
                      type="text"
                      placeholder="Service name"
                      className="flex-grow-1 pl-1 pr-2"
                      value={newServiceName}
                      onChange={handleInputChange}
                      onBlur={handleInputBlur}
                    />
                  </div>
                  {servicePageErrors?.headlineError && (
                    <div className="f-danger f-12 p-1">
                      Service name cannot be empty
                    </div>
                  )}
                  {servicePageErrors?.headlineExistError && (
                    <div className="f-danger f-12 p-1">
                      Service name already exists
                    </div>
                  )}
                </div>

                <div className="mb-2">
                  <div
                    // ref={elemRef}
                    // ref={elemsRefs.element1}
                    className={`highlight-input-with-grey-border w-100  d-flex align-items-center p-2 radius-4 mt-2  ${description.length >= 250 ? "input-error" : ""
                      }`}
                  >
                    <div className="f-darkgrey">Description:</div>
                    <input
                      tabIndex={0}
                      type="text"
                      placeholder="Service description"
                      className="flex-grow-1 pl-1 pr-2"
                      value={description}
                      onChange={(e) => {
                        setChanged(true);
                        if (e.target.value.length > 250) return;
                        setDescription(e.target.value);
                      }}
                    // onBlur={handleInputBlur}
                    />
                  </div>
                  <div className="f-12 f-darkgrey d-flex justify-content-end">
                    {description?.length}/250 characters
                  </div>
                  {description.length >= 250 && (
                    <div className="f-danger f-12 p-1">
                      Maximum description length of 250 characters has been
                      reached
                    </div>
                  )}


                </div>

                {/* <Dropdown className="service-type-dropdown w-100">
                  <Dropdown.Toggle className="w-100">
                    <div
                      ref={elemsRefs.element2}
                      className={`highlight-input-with-grey-border p-2 radius-4 d-flex align-items-center ${
                        servicePageErrors?.selectedNistPillarError
                          ? "input-error"
                          : ""
                      }`}
                    >
                      <div className="f-darkgrey">Type: </div>
                      <input
                        className={`border-none bg-transparent flex-grow-1 ml-1 hide-caret ${
                          selectedServiceId !== 0 ? "f-darkgrey" : "f-black"
                        }`}
                        type="text"
                        disabled={
                          selectedServiceId !== 0 || selectedLinkList.length > 0
                            ? true
                            : false
                        }
                        value={selectedNistPillar?.DDLText}
                        placeholder=" Choose.."
                        onChange={(e) => {}}
                      />
                      {selectedServiceId !== 0 ||
                      selectedLinkList.length > 0 ? (
                        <>
                          {" "}
                          <AttackSurfaceTooltip
                            bottomLeft={true}
                            topLeft={false}
                            content={
                              <span> Service type cannot be changed</span>
                            }
                          >
                            <img
                              width={20}
                              alt=""
                              src="/images/attack-surface/small-lock.svg"
                              className="mx-2"
                            />
                          </AttackSurfaceTooltip>
                        </>
                      ) : (
                        <>
                          <img
                            alt=""
                            src="/images/chevron-down.svg"
                            className="mx-2"
                          />
                        </>
                      )}
                    </div>
                  </Dropdown.Toggle>
                  {selectedServiceId == 0 && selectedLinkList.length == 0 && (
                    <Dropdown.Menu className="w-100 mt-1">
                      {serviceModalData?.NistPillar_List?.map(
                        (item, itemIndex) => {
                          return (
                            <div
                              className="d-flex align-items-center justify-content-between my-2 pointer pl-2"
                              onClick={(e) => {
                                setChanged(true);
                                setShowRadar(false);
                                setSelectedNistPillar(item);
                                _setNistIndex(item?.DDLId);
                                setServicePageErrors((prev) => ({
                                  ...prev,
                                  selectedNistPillarError: false,
                                  typeToSeriesError: false,
                                }));

                                e.target
                                  .closest(".service-type-dropdown")
                                  .click();
                              }}
                            >
                              <div
                                className={`${
                                  item?.DDLId === selectedNistPillar?.DDLId
                                    ? "f-blue"
                                    : ""
                                }`}
                              >
                                <img
                                  alt=""
                                  className={`${
                                    item.selected ? "blue-icon" : ""
                                  } mr-2`}
                                  src={item.image}
                                />
                                {item?.DDLText}
                              </div>
                              <div>
                                {item?.DDLId === selectedNistPillar?.DDLId && (
                                  <img
                                    alt=""
                                    className=" mr-3"
                                    src="/images/msp/blue-tick.svg"
                                  />
                                )}
                              </div>
                            </div>
                          );
                        }
                      )}
                    </Dropdown.Menu>
                  )}
                </Dropdown>
                {servicePageErrors?.typeToSeriesError && (
                  <div className="f-danger f-12 p-1">
                    Service type must be selected before linking or creating a
                    series
                  </div>
                )} */}

                <hr />
                <div className="f-12 f-500 f-darkgrey mb-2">
                  Align to NIST Cybersecurity Framework
                </div>
                {showRadar === false ? (
                  <>
                    {" "}
                    <div className="w-100 d-flex justify-content-center px-5 position-relative align-items-center">
                      <div className="w-50 d-flex">
                        {/* <MspRadar data={data} options={_options} /> */}
                        {/* <MspSinglePointRadar */}
                        <MspClientPriceCalculatorRadarChart
                          data={data}
                          options={_actualOptions}
                        />
                      </div>

                      {/* Overlay for Radar Chart */}
                      <div
                        className="w-50 position-absolute d-flex flex-column align-items-center justify-content-center"
                        style={{ marginTop: "25px" }}
                      >
                        <div
                          className="f-500 f-black w-50"
                          style={{
                            textAlign: "center",
                          }}
                        >
                          Answer questions on this service so we can display it
                          visually
                        </div>
                      </div>
                    </div>
                    <div className="d-flex justify-content-center mt-4">
                      <Button
                        className={` ${servicePageErrors?.selectedAlignNistError
                          ? "input-error hg-red-btn"
                          : "hg-blue-btn"
                          }  w-fit-content`}
                        ref={elemsRefs.element9}
                        onClick={async () => {
                          let errors = await CheckValidationForServicePage(
                            "align"
                          );

                          if (errors > 0) {
                            setItemsUpdated(true);
                          } else {
                            setChanged(true);
                            if (Number(selectedServiceId) === 0) {
                              createServiceId().then(() => {
                                setServicePageErrors((prev) => ({
                                  ...prev,
                                  selectedAlignNistError: false,
                                }));
                                setShowMspScoreModal(true);
                              });
                            } else {
                              setShowMspScoreModal(true);
                            }
                          }
                        }}
                      >
                        {creatingId
                          ? "Please wait..."
                          : "Align to the NIST CSF"}
                      </Button>
                    </div>
                  </>
                ) : (
                  <>
                    {" "}
                    <div
                      className="w-100 d-flex justify-content-center px-5 position-relative"
                      style={{ marginTop: "25px" }}
                    >
                      <div
                        className="w-50 d-flex"
                        onClick={() => {
                          setShowMspScoreModal(true);
                        }}
                      >
                        {" "}
                        <MspSinglePointRadar
                          data={_actualData}
                          options={_actualOptions}
                        />
                      </div>
                    </div>
                    <div className="d-flex justify-content-center mt-4">
                      <Button
                        className="hg-grey-btn w-fit-content"
                        onClick={async () => {
                          let errors = await CheckValidationForServicePage();

                          if (errors > 0) {
                            setItemsUpdated(true);
                          } else {
                            setChanged(true);

                            if (Number(selectedServiceId) === 0) {
                              createServiceId().then(() => {
                                setShowMspScoreModal(true);
                              });
                            } else {
                              setShowMspScoreModal(true);
                            }
                          }
                        }}
                      >
                        {"Align to the NIST CSF"}
                      </Button>
                    </div>
                  </>
                )}

                <div className="f-12 f-500 f-darkgrey my-2">
                  Technology Mapping
                </div>
                <Dropdown className="service-type-dropdown w-100">
                  <Dropdown.Toggle className="w-100">
                    <div className="d-flex flex-wrap highlight-input-with-grey-border   w-100  d-flex align-items-center p-2 radius-4 mt-1">
                      {technologyMappingSelected &&
                        technologyMappingSelected?.map((selected, selectedIndex) => {
                          return (
                            <div
                              className="f-12 f-500 f-grey d-flex align-items-center p-2 mr-1 mb-1  bg-grey"
                              title={selected?.Name}
                            >
                              {
                                selected?.IsLocked ?
                                  <img
                                    alt=""
                                    width={14}
                                    height={14}
                                    className='mr-1 mb-1'
                                    src="/images/settings-v2/locker-dark-grey.svg"
                                  /> : <></>
                              }

                              <span className="d-inline-flex">
                                {TrimString(selected?.Name, 20)}
                              </span>

                              <img
                                alt=""
                                src="/images/attack-surface/grey-cross-icon.svg"
                                className={`ml-1 pointer `}
                                onClick={(e) => {
                                  setChanged(true);
                                  let arr = technologyMappingSelected?.filter(obj => obj?.ObjectId !== selected?.ObjectId);
                                  setTechnologyMappingSelected(arr);
                                  // e.stopPropagation();
                                  // let updated_data = [...selectedOptions];
                                  // let Index = updated_data.findIndex(
                                  //   (item) => item?.AttributeId === selected?.AttributeId
                                  // );
                                  // updated_data.splice(Index, 1);
                                  // setSelectedOptions(updated_data);
                                  // props.onChange(updated_data);
                                }}
                              />
                            </div>
                          );
                        })}
                      <input
                        tabIndex={-1}
                        placeholder="Add"
                        type="text"
                        className={`border-none w-100 h-100 `}
                        value={technologyMappingSearch}
                        onChange={(e) => {
                          setTechnologyMappingSearch(e?.target?.value);
                        }}
                      />
                    </div>
                    {/* <div
                      className={`highlight-input-with-grey-border   w-100  d-flex align-items-center p-2 radius-4 mt-1`}
                    >
                      <input
                        tabIndex={0}
                        type="text"
                        placeholder="Add category"
                        className="flex-grow-1 pl-1 pr-2"
                        // value={category}
                        onChange={(e) => {
                          setCategory(e?.target?.value);
                        }}
                      />
                    </div> */}
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="w-100 mt-1">
                    <div>
                      {
                        serviceModalData?.ServiceTechnologies_List?.filter(obj => obj?.Name?.toLowerCase()?.includes(technologyMappingSearch?.toLowerCase()))?.map((item) => {
                          return (
                            <div
                              className="d-flex align-items-center justify-content-between py-1 px-3 pointer"
                              onClick={() => {
                                setChanged(true);
                                let arr = technologyMappingSelected;
                                const exists = arr?.some(obj => obj?.ObjectId === item?.ObjectId);

                                if (exists) {
                                  arr = arr?.filter(obj => obj?.ObjectId !== item?.ObjectId);
                                } else {
                                  arr.push(item);
                                }
                                setTechnologyMappingSelected(arr);
                              }}
                            >
                              <div
                                className={`f-400 f-14 ${technologyMappingSelected?.some(obj => obj?.ObjectId === item?.ObjectId) ? 'txt-blue' : 'f-grey'}`}
                              >
                                {item?.Name}
                              </div>
                              <div className="f-400 f-14 f-darkgrey">{item?.TechnologyType_Text}</div>
                            </div>
                          )
                        })
                      }
                    </div>
                  </Dropdown.Menu>
                </Dropdown>
                {/* {servicePageErrors?.typeToSeriesError && (
                  <div className="f-danger f-12 p-1">
                    Service type must be selected before linking or creating a
                    series
                  </div>
                )} */}


                <div className="grey-container mt-3">
                  <div className="d-flex align-items-center">
                    <div className="f-500 f-14 f-darkgrey line-height-22 letter-space-2 w-50">Component</div>
                    <div className="f-500 f-14 f-darkgrey line-height-22 letter-space-2 w-50">Products</div>
                  </div>
                  <div>
                    {
                      serviceComponents?.length > 0 ? <div>
                        {
                          serviceComponents?.map((dataItem) => {
                            return (
                              <div className="d-flex align-items-top">
                                <div
                                  className="d-flex justify-content-between mt-1 w-50"
                                  style={{
                                    height: '22px'
                                  }}
                                >
                                  <div className="f-400 f-14 f-black line-height-22 letter-space-2">{dataItem?.ContributionName}</div>
                                  <img
                                    alt=""
                                    id=""
                                    className="mr-3"
                                    width={11}
                                    src="/images/attack-surface/right-arrow.svg"
                                  />
                                </div>

                                {/* <Dropdown
                                  className="category-filter-dropdown m-1 technologySpendModelDatePicker"
                                  id={`date_filterX`}
                                  show={isDateDropdownOpen}
                                  onToggle={(isOpen) => setIsDateDropdownOpen(isOpen)}
                                >
                                  <Dropdown.Toggle className="p-0">
                                    {
                                      dataItem?.List_ScoreProducts?.map((productItem) => {
                                        return (
                                          <div className="d-flex align-items-center bg-greyish radius-2 p-1 mt-1">
                                            <OverlayTrigger
                                              placement="bottom"
                                              trigger={["hover", "focus"]}
                                              delay={{ show: 200, hide: 250 }}
                                              overlay={
                                                <Tooltip id={`tooltip-assets`}>
                                                  {
                                                    productItem?.List_ProductTool?.map((iconItem, iconIndex) => {
                                                      return (
                                                        <div className="d-flex align-items-center">
                                                          <img
                                                            alt=""
                                                            id=""
                                                            width={16}
                                                            className="mr-1"
                                                            src={`/images/attack-surface/integrations/${iconItem?.ToolIcon?.toLowerCase()}`}
                                                          />
                                                          <div className="f-400 f-12 f-white">
                                                            {iconItem?.ToolName}
                                                          </div>
                                                        </div>
                                                      )
                                                    })
                                                  }
                                                </Tooltip>
                                              }
                                            >
                                              {
                                                productItem?.List_ProductTool?.length > 3 ? <div className="d-flex align-items-center">
                                                  {
                                                    productItem?.List_ProductTool?.slice(0, 3)?.map((iconItem, iconIndex) => {
                                                      return (
                                                        <img
                                                          alt=""
                                                          id=""
                                                          width={16}
                                                          className=""
                                                          style={{
                                                            marginLeft: iconIndex != 0 && "-6px"
                                                          }}
                                                          src={`/images/attack-surface/integrations/${iconItem?.ToolIcon?.toLowerCase()}`}
                                                        />
                                                      )
                                                    })
                                                  }
                                                  <div
                                                    className="d-flex align-items-center justify-content-center f-500 f-9 f-black line-height-12 rounded-full bg-dark-greyish"
                                                    style={{
                                                      width: '16px',
                                                      height: '16px',
                                                      marginLeft: '-6px'
                                                    }}
                                                  >
                                                    + {productItem?.List_ProductTool?.length - 3}
                                                  </div>
                                                </div> : <div className="d-flex align-items-center">
                                                  {
                                                    productItem?.List_ProductTool?.map((iconItem, iconIndex) => {
                                                      return (
                                                        <img
                                                          alt=""
                                                          id=""
                                                          width={16}
                                                          className=""
                                                          style={{
                                                            marginLeft: iconIndex != 0 && "-6px"
                                                          }}
                                                          src={`/images/attack-surface/integrations/${iconItem?.ToolIcon?.toLowerCase()}`}
                                                        />
                                                      )
                                                    })
                                                  }
                                                </div>
    
                                              }
                                            </OverlayTrigger>
    
                                            <div className="f-500 f-12 f-black line-height-18 letter-space-2 ml-1">
                                              {productItem?.ProductName}
                                            </div>
                                            <img
                                              alt=""
                                              id=""
                                              width={10}
                                              className="ml-2 pointer"
                                              src="/images/grey-cross.svg"
                                            />
                                          </div>
                                        )
                                      })
                                    }
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu className="p-0">
    
                                  </Dropdown.Menu>
                                </Dropdown> */}


                                <div className="">
                                  {
                                    dataItem?.List_ScoreProducts?.length > 0 ?
                                      <div>
                                        {
                                          dataItem?.List_ScoreProducts?.map((productItem, productIndex) => {
                                            return (
                                              <div className="d-flex">
                                                <div className="d-flex align-items-center bg-greyish radius-2 p-1 mt-1">
                                                  {
                                                    productItem?.IntegrationId ? <img
                                                      alt=""
                                                      id=""
                                                      width={16}
                                                      className="rounded-full"
                                                      src={`/images/attack-surface/integrations/${productItem?.IntegrationId}.webp`}
                                                    /> : <OverlayTrigger
                                                      placement="bottom"
                                                      trigger={["hover", "focus"]}
                                                      delay={{ show: 200, hide: 250 }}
                                                      overlay={
                                                        <Tooltip id={`tooltip-assets`}>
                                                          {
                                                            productItem?.List_ProductTool?.map((iconItem, iconIndex) => {
                                                              return (
                                                                <div className="d-flex align-items-center">
                                                                  <img
                                                                    alt=""
                                                                    id=""
                                                                    width={16}
                                                                    className="mr-1"
                                                                    // src={`/images/attack-surface/integrations/${iconItem?.ToolIcon?.toLowerCase()}`}
                                                                    src={`/images/attack-surface/integrations/${iconItem?.ObjId_Tool}.webp`}
                                                                  />
                                                                  <div className="f-400 f-12 f-white">
                                                                    {iconItem?.ToolName}
                                                                  </div>
                                                                </div>
                                                              )
                                                            })
                                                          }
                                                        </Tooltip>
                                                      }
                                                    >
                                                      {
                                                        productItem?.List_ProductTool?.length > 3 ? <div className="d-flex align-items-center">
                                                          {
                                                            productItem?.List_ProductTool?.slice(0, 3)?.map((iconItem, iconIndex) => {
                                                              console.log('productItem --- ', productItem)
                                                              return (
                                                                <img
                                                                  alt=""
                                                                  id=""
                                                                  width={16}
                                                                  className=""
                                                                  style={{
                                                                    marginLeft: iconIndex != 0 && "-6px"
                                                                  }}
                                                                  // src={`/images/attack-surface/integrations/${iconItem?.ToolIcon?.toLowerCase()}`}
                                                                  src={`/images/attack-surface/integrations/${iconItem?.ObjId_Tool}.webp`}
                                                                />
                                                              )
                                                            })
                                                          }
                                                          <div
                                                            className="d-flex align-items-center justify-content-center f-500 f-9 f-black line-height-12 rounded-full bg-dark-greyish"
                                                            style={{
                                                              width: '16px',
                                                              height: '16px',
                                                              marginLeft: '-6px'
                                                            }}
                                                          >
                                                            + {productItem?.List_ProductTool?.length - 3}
                                                          </div>
                                                        </div> : <div className="d-flex align-items-center">
                                                          {
                                                            productItem?.List_ProductTool?.map((iconItem, iconIndex) => {
                                                              return (
                                                                <img
                                                                  alt=""
                                                                  id=""
                                                                  width={16}
                                                                  className=""
                                                                  style={{
                                                                    marginLeft: iconIndex != 0 && "-6px"
                                                                  }}
                                                                  // src={`/images/attack-surface/integrations/${iconItem?.ToolIcon?.toLowerCase()}`}
                                                                  src={`/images/attack-surface/integrations/${iconItem?.ObjId_Tool}.webp`}
                                                                />
                                                              )
                                                            })
                                                          }
                                                        </div>

                                                      }
                                                    </OverlayTrigger>
                                                  }

                                                  <div className="f-500 f-12 f-black line-height-18 letter-space-2 ml-1">
                                                    {(productItem?.ProductName ?? productItem?.LabourName ?? productItem?.IntegrationName)?.length > 19 ? (productItem?.ProductName ?? productItem?.LabourName ?? productItem?.IntegrationName)?.slice(0, 19) + '...' : (productItem?.ProductName ?? productItem?.LabourName ?? productItem?.IntegrationName)}
                                                  </div>
                                                  <img
                                                    alt=""
                                                    id=""
                                                    width={11}
                                                    className="ml-2 mr-1 pointer"
                                                    src="/images/grey-cross.svg"
                                                    onClick={() => {
                                                      const updated_data = serviceComponents.map(item => {
                                                        if (item.ContributionName === dataItem?.ContributionName) {
                                                          return {
                                                            ...item,
                                                            List_ScoreProducts: item.List_ScoreProducts
                                                              ? item.List_ScoreProducts.filter(item => (item?.objId_Product !== productItem?.objId_Product)
                                                                || (item?.objId_SecurityPackLabour !== productItem?.objId_SecurityPackLabour)
                                                                || (item?.IntegrationId !== productItem?.IntegrationId))
                                                              : [],
                                                          };
                                                        }
                                                        return item;
                                                      });

                                                      setServiceComponents(updated_data);

                                                      if (productItem?.ObjId_PSAProduct) {
                                                        setChanged(true);
                                                        let updated_product_list = [
                                                          ...productsList,
                                                        ];
                                                        let Index = updated_product_list.findIndex(
                                                          (item) =>
                                                            item?.ObjId_PSAProduct ===
                                                            productItem?.ObjId_PSAProduct
                                                        );

                                                        if (Index > -1) {
                                                          updated_product_list.splice(
                                                            Index,
                                                            1
                                                          );
                                                        }
                                                        setProductsList(updated_product_list);
                                                      }
                                                    }}
                                                  />
                                                </div>
                                                {
                                                  (productIndex == (dataItem?.List_ScoreProducts?.length - 1)) &&
                                                  <SelectProductOrLabour
                                                    setPage={(page, name) => {
                                                      setChanged(true);

                                                      if (page === "addProduct") {
                                                        setNestedProductModalState((prev) => ({
                                                          ...prev,
                                                          show: true,
                                                          selectedProductId: 0,
                                                          newProductName: name,
                                                        }));
                                                      }
                                                    }}
                                                    setChanged={setChanged}
                                                    setServicePageErrors={setServicePageErrors}
                                                    productsList={productsList}
                                                    setProductsList={setProductsList}
                                                    setUpdateModal={setUpdateModal}
                                                    contributionName={dataItem?.ContributionName}
                                                    serviceComponents={serviceComponents}
                                                    setServiceComponents={setServiceComponents}
                                                    labourData={labourList}
                                                    contributionType={dataItem?.ContribType}
                                                    children={
                                                      <div className="f-400 f-14 f-darkgrey pointer ml-2 py-1 pr-2">
                                                        Add
                                                      </div>
                                                    }
                                                  />
                                                }
                                              </div>
                                            )
                                          })
                                        }
                                      </div> :
                                      <SelectProductOrLabour
                                        setPage={(page, name) => {
                                          setChanged(true);

                                          if (page === "addProduct") {
                                            setNestedProductModalState((prev) => ({
                                              ...prev,
                                              show: true,
                                              selectedProductId: 0,
                                              newProductName: name,
                                            }));
                                          }
                                        }}
                                        setChanged={setChanged}
                                        setServicePageErrors={setServicePageErrors}
                                        productsList={productsList}
                                        setProductsList={setProductsList}
                                        setUpdateModal={setUpdateModal}
                                        contributionName={dataItem?.ContributionName}
                                        serviceComponents={serviceComponents}
                                        setServiceComponents={setServiceComponents}
                                        labourData={labourList}
                                        contributionType={dataItem?.ContribType}
                                        children={
                                          <div className="d-flex align-items-center pointer">
                                            <img
                                              alt=""
                                              id=""
                                              width={16}
                                              className="mr-2"
                                              src="/images/warningTriangle.svg"
                                            />
                                            <div className="f-400 f-14 f-darkgrey">
                                              Select
                                            </div>
                                          </div>
                                        }
                                      />
                                  }
                                </div>
                              </div>
                            )
                          })
                        }
                      </div> : <div className="w-100 f-400 f-12 f-grey my-3">
                        No data yet
                      </div>
                    }
                  </div>
                </div>


                <hr />
                <div className="f-12 f-500 f-darkgrey mb-2 ">Series</div>
                {/* Add - On - START */}
                {/* <div className="d-flex w-100 mb-3">
                  <div className="w-40">
                    <div className="d-flex f-500">
                      Add-on{" "}
                      <div className="msp-service-tool-tip-parent w-fit-content">
                        <img
                          className="ml-2 pointer"
                          src="/images/msp/ques-info-icon.svg"
                          alt=""
                        />
                        <MspServiceModalToolTip data={getToolTip(0)} />
                      </div>
                    </div>
                  </div>

                  <div
                    className="w-70 "
                    style={{
                      paddingLeft: "4rem",
                    }}
                  >
                    <Form.Group controlId={"check" + "" + 125661} className="">
                      <Form.Check
                        tabIndex={-1}
                        type="switch"
                        id={
                          "switch-ques1" + "" + 12561 + Math.random() * 1000000
                        }
                        style={{
                          transform: "scale(1.3)",
                          paddingLeft: "0.75rem",
                        }}
                        className={`m-0 scale-switch switch-shadow-none ${true}  `}
                        checked={IsAddOn}
                        label=""
                        disabled={false}
                        onChange={(e) => {
                          setChanged(true);
                          setIsAddOn(e.target.checked);
                        }}
                        onClick={(e) => {}}
                      />
                    </Form.Group>
                  </div>
                </div> */}
                {/* Add - On - STOP */}
                {/* Description START */}
                {/* <div className="d-flex flex-column w-100 mb-3">
                  <div className="d-flex w-100 ">
                    <div className="w-30">
                      <div className="d-flex f-500">
                        Description
                        <div className="msp-service-tool-tip-parent w-fit-content">
                          <img
                            className="ml-2 pointer"
                            src="/images/msp/ques-info-icon.svg"
                            alt=""
                          />
                          <MspServiceModalToolTip data={getToolTip(1)} />
                        </div>
                      </div>
                    </div>

                    <div className="w-70  ">
                      <textarea
                        tabIndex={0}
                        className={`border-none radius-4 highlight-input flex-grow-1 w-100 p-2 hide-scrollbar ${
                          description.length >= 250 ? "input-error" : ""
                        }`}
                        placeholder="Type description"
                        rows={5}
                        id="message"
                        name="message"
                        value={description}
                        onChange={(e) => {
                          setChanged(true);
                          if (e.target.value.length > 250) return;
                          setDescription(e.target.value);
                        }}
                      />
                      <div className="f-12 f-darkgrey d-flex justify-content-end">
                        {description?.length}/250 characters
                      </div>
                    </div>
                  </div>
                  {description.length >= 250 && (
                    <div className="f-danger f-12 p-1">
                      Maximum description length of 250 characters has been
                      reached
                    </div>
                  )}
                </div> */}

                {/* Description STOP */}

                {/* Link To Series START */}
                <div className="d-flex w-100 mb-3">
                  <div className="w-30">
                    <div className="d-flex f-500">
                      Link into series
                      <div className="msp-service-tool-tip-parent w-fit-content">
                        <img
                          className="ml-2 pointer"
                          src="/images/msp/ques-info-icon.svg"
                          alt=""
                        />
                        <MspServiceModalToolTip data={getToolTip(2)} />
                      </div>
                    </div>
                  </div>
                  <div className="w-70">
                    {selectedNistPillar &&
                      selectedBillingPeriod &&
                      selectedServiceId > 0 ? (
                      <LinkToSeriesDropdown
                        menuOpen={true}
                        disabling={(error) => {
                          if (error?.length > 0) {
                            error.map((er) => {
                              if (er?.name == "billing") {
                                setServicePageErrors((prev) => ({
                                  ...prev,
                                  billingToSeriesError: er?.state,
                                }));
                              }
                              if (er?.name == "type") {
                                setServicePageErrors((prev) => ({
                                  ...prev,
                                  typeToSeriesError: er?.state,
                                }));
                              }
                              if (er?.name == "serviceId") {
                                setServicePageErrors((prev) => ({
                                  ...prev,
                                  serviceToSeriesError: er?.state,
                                }));
                              }
                            });
                          }
                        }}
                        fieldErrors={fieldErrors}
                        checkAccess={() => {
                          let errors = CheckValidationForServicePage();
                          setfieldErrors(errors);
                          if (errors > 0) {
                            setItemsUpdated(true);
                            return false;
                          } else {
                            return true;
                          }
                        }}
                        onDelete={(id) => {
                          setChanged(true);
                          setSeriesModalData((prev) => ({
                            ...prev,
                            showDeleteModal: true,
                            deleteConfig: { deleteId: id },
                          }));
                        }}
                        onAddEdit={(_id, name) => {
                          setChanged(true);
                          if (_id == 0) {
                            setSeriesModalData((prev) => ({
                              ...prev,
                              show: true,
                              seriesId: _id,
                              serviceId: selectedServiceId,
                              // serviceModalState:
                              //   selectedServiceId > 0 ? "edit" : "add",
                              serviceModalState: _id > 0 ? "edit" : "add",
                              billingPeriod:
                                _id === 0 ? selectedBillingPeriod : "",
                              serviceType: selectedNistPillar,
                              selectedList: selectedLinkList,
                              SeriesName: name,
                              linkCurrSerie: linkCurrSerie,
                              serviceModalData: serviceModalData,
                              updateServiceData: getServiceData,
                            }));
                          } else {
                            setSeriesModalData((prev) => ({
                              ...prev,
                              show: true,
                              seriesId: _id,
                              serviceId: selectedServiceId,
                              serviceModalState: _id > 0 ? "edit" : "add",
                              // serviceModalState:
                              //   selectedServiceId > 0 ? "edit" : "add",
                              billingPeriod: selectedBillingPeriod,
                              serviceType: selectedNistPillar,
                              selectedList: selectedLinkList,
                              linkCurrSerie: linkCurrSerie,
                              updateServiceData: getServiceData,
                              serviceModalData: serviceModalData,
                            }));
                          }
                        }}
                        selectedList={selectedLinkList}
                        selectedNistPillar={selectedNistPillar}
                        selectedBillingPeriod={selectedBillingPeriod}
                        serviceId={selectedServiceId}
                        serviceModalData={serviceModalData}
                        onSelect={(selectedSerie, addOrRemove) => {
                          setChanged(true);
                          setSeriesModalData((prev) => ({
                            ...prev,
                            show: false,
                            selectedSerie: selectedSerie,
                            seriesId:
                              selectedSerie?.objId_SecurityPackLinkSeries,
                            linkCurrSerie: linkCurrSerie,
                          }));
                          // addOrRemove Means boolean to add to list or to remove
                          let updated_list = [...selectedLinkList];

                          if (selectedSerie) {
                            let isLinkedItemIndex = selectedLinkList?.findIndex(
                              (item) =>
                                item?.objId_SecurityPackLinkSeries ===
                                selectedSerie?.objId_SecurityPackLinkSeries
                            );
                            if (isLinkedItemIndex > -1) {
                              updated_list.splice(isLinkedItemIndex, 1);
                            } else {
                              updated_list.push({ ...selectedSerie });
                            }
                            setUpdateModal(true);

                            setSelectedLinkList(updated_list);
                          } else {
                            setUpdateModal(true);

                            setSelectedLinkList([]);
                            setLinkedProductsList([]);
                          }
                        }}
                      />
                    ) : (
                      <LinkToSeriesDropdown
                        menuOpen={false}
                        disabling={(error) => {
                          if (error?.length > 0) {
                            error.map((er) => {
                              if (er?.name == "billing") {
                                setServicePageErrors((prev) => ({
                                  ...prev,
                                  billingToSeriesError: er?.state,
                                }));
                              }
                              if (er?.name == "type") {
                                setServicePageErrors((prev) => ({
                                  ...prev,
                                  typeToSeriesError: er?.state,
                                }));
                              }
                              if (er?.name == "serviceId") {
                                setServicePageErrors((prev) => ({
                                  ...prev,
                                  serviceToSeriesError: er?.state,
                                }));
                              }
                            });
                          }
                        }}
                        fieldErrors={fieldErrors}
                        checkAccess={() => {
                          let errors = CheckValidationForServicePage();
                          setfieldErrors(errors);
                          if (errors > 0) {
                            setItemsUpdated(true);
                            return false;
                          } else {
                            return true;
                          }
                        }}
                        onDelete={(id) => {
                          setChanged(true);
                          setSeriesModalData((prev) => ({
                            ...prev,
                            showDeleteModal: true,
                            deleteConfig: { deleteId: id },
                          }));
                        }}
                        onAddEdit={(_id, name) => {
                          setChanged(true);
                          if (_id == 0) {
                            setSeriesModalData((prev) => ({
                              ...prev,
                              show: true,
                              seriesId: _id,
                              serviceId: selectedServiceId,
                              serviceModalState: _id > 0 ? "edit" : "add",
                              billingPeriod:
                                _id === 0 ? selectedBillingPeriod : "",
                              serviceType: selectedNistPillar,
                              selectedList: selectedLinkList,
                              SeriesName: name,
                              linkCurrSerie: linkCurrSerie,
                              serviceModalData: serviceModalData,
                              updateServiceData: getServiceData,
                            }));
                          } else {
                            setSeriesModalData((prev) => ({
                              ...prev,
                              show: true,
                              seriesId: _id,
                              serviceId: selectedServiceId,
                              serviceModalState:
                                selectedServiceId > 0 ? "edit" : "add",
                              billingPeriod: selectedBillingPeriod,
                              serviceType: selectedNistPillar,
                              selectedList: selectedLinkList,
                              linkCurrSerie: linkCurrSerie,
                              serviceModalData: serviceModalData,
                              updateServiceData: getServiceData,
                            }));
                          }
                        }}
                        selectedList={selectedLinkList}
                        selectedNistPillar={selectedNistPillar}
                        selectedBillingPeriod={selectedBillingPeriod}
                        serviceId={selectedServiceId}
                        serviceModalData={serviceModalData}
                        onSelect={(selectedSerie, addOrRemove) => {
                          setChanged(true);
                          setSeriesModalData((prev) => ({
                            ...prev,
                            show: false,
                            selectedSerie: selectedSerie,
                            seriesId:
                              selectedSerie?.objId_SecurityPackLinkSeries,
                            linkCurrSerie: linkCurrSerie,
                          }));
                          // addOrRemove Means boolean to add to list or to remove
                          let updated_list = [...selectedLinkList];

                          if (selectedSerie) {
                            let isLinkedItemIndex = selectedLinkList?.findIndex(
                              (item) =>
                                item?.objId_SecurityPackLinkSeries ===
                                selectedSerie?.objId_SecurityPackLinkSeries
                            );

                            if (isLinkedItemIndex > -1) {
                              updated_list.splice(isLinkedItemIndex, 1);
                            } else {
                              updated_list.push({ ...selectedSerie });
                            }
                            setUpdateModal(true);

                            setSelectedLinkList(updated_list);
                          } else {
                            setUpdateModal(true);

                            setSelectedLinkList([]);
                            setLinkedProductsList([]);
                          }
                        }}
                      />
                    )}
                    {servicePageErrors?.serviceToSeriesError && (
                      <div className="f-12 f-danger">
                        Service must be saved before linking to a series
                      </div>
                    )}
                  </div>
                </div>
                {/* Link To Series STOP */}

                {/* Includes START */}
                <div className="d-flex w-100 mb-3">
                  <div className="w-30">
                    <div className="d-flex f-500">
                      Features
                      <div className="msp-service-tool-tip-parent w-fit-content">
                        <img
                          className="ml-2 pointer"
                          src="/images/msp/ques-info-icon.svg"
                          alt=""
                        />
                        <MspServiceModalToolTip data={getToolTip(3)} />
                      </div>
                    </div>
                  </div>

                  <div className="w-70  ">
                    {selectedLinkList?.length > 0 &&
                      "linkedIncludesList?.length > 0" && (
                        <>
                          {!hideIncludes && (
                            <>
                              {selectedLinkList?.map(
                                (linkedItem, linkItemIndex) => {
                                  return (
                                    <>
                                      {linkedItem?.SecurityPackVM_List?.filter(
                                        (item) =>
                                          item?.SecurityPack_Bullet_List
                                            ?.length > 0
                                      )?.length > 0 && (
                                          <div className="f-12 f-darkgrey">
                                            {linkedItem?.SeriesName} features
                                          </div>
                                        )}
                                      {linkedItem?.SecurityPackVM_List?.map(
                                        (parent, parentIndex) => {
                                          return (
                                            <>
                                              {parent?.SecurityPack_Bullet_List?.filter(
                                                (item) =>
                                                  headline !== parent?.headline
                                              )?.map((child, childIndex) => {
                                                return (
                                                  <>
                                                    <div className="d-flex align-items-center my-2 f-grey">
                                                      <img
                                                        alt=""
                                                        className="ml-1 mr-2"
                                                        src="/images/msp/grey-circular-tick.svg"
                                                      />
                                                      {child?.Text}
                                                    </div>
                                                  </>
                                                );
                                              })}
                                            </>
                                          );
                                        }
                                      )}
                                    </>
                                  );
                                }
                              )}
                            </>
                          )}
                          <div
                            className="f-12 f-500 f-grey pointer"
                            onClick={(e) => {
                              setHideIncludes(!hideIncludes);
                            }}
                          >
                            <img
                              className={`mr-2 ml-1 chevron-icon ${hideIncludes ? "rotate180" : ""
                                }`}
                              src="/images/msp/small-chevron-down.svg"
                              alt=""
                            />
                            Hide parent features
                          </div>
                        </>
                      )}
                    <div className="point-row">
                      {" "}
                      {linkedIncludesList?.length > 0 && (
                        <DragDropContext onDragEnd={onDragEndShown}>
                          <Droppable
                            direction="vertical"
                            droppableId="droppableSection"
                          >
                            {(provided, snapshot) => (
                              <div
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                              >
                                <div style={{ position: "relative" }}>
                                  <ul>
                                    {linkedIncludesList?.map(
                                      (parent, parentIndex) => (
                                        // return (
                                        <Draggable
                                          key={parent?.Text}
                                          draggableId={parent?.objId_SecurityPackBullet?.toString()}
                                          index={parentIndex}
                                        // isDragDisabled
                                        >
                                          {(provided, snapshot) => (
                                            <li>
                                              <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                className={`${snapshot.isDragging
                                                  ? "dragging-row"
                                                  : "" // Apply a class if the row is being dragged
                                                  } d-flex flex-row thread pl-2`}
                                              // key={parentIndex}
                                              // index={parentIndex}
                                              >
                                                <div
                                                  className={`${addFeature?.title ==
                                                    parent?.objId_SecurityPackBullet
                                                    ? "edit-title"
                                                    : ""
                                                    } d-flex align-items-center justify-content-between my-2 f-black w-100 drag-item`}
                                                  onClick={() => {
                                                    let newAdd = {
                                                      ...addFeature,
                                                    };
                                                    newAdd.title =
                                                      parent?.objId_SecurityPackBullet;
                                                    setAddFeature(newAdd);
                                                  }}
                                                  onMouseEnter={() =>
                                                    setIsHovered(
                                                      parent?.objId_SecurityPackBullet
                                                    )
                                                  }
                                                  onMouseLeave={() =>
                                                    setIsHovered(0)
                                                  }
                                                >
                                                  <div
                                                    className={`d-flex w-100`}
                                                  >
                                                    {isHovered ==
                                                      parent?.objId_SecurityPackBullet ||
                                                      snapshot.isDragging ? (
                                                      <img
                                                        alt=""
                                                        src="/images/supply-chain/drag-n-drop.svg"
                                                        className="ml-1 mr-2"
                                                        width={14}
                                                      />
                                                    ) : (
                                                      <img
                                                        width={14}
                                                        alt=""
                                                        className="ml-1 mr-2"
                                                        src="/images/msp/green-circular-tick.svg"
                                                      />
                                                    )}

                                                    <textarea
                                                      // ref={textareaRef2}
                                                      ref={
                                                        textareaRefs.current[
                                                        parentIndex
                                                        ]
                                                      }
                                                      autoFocus={isFirstFocus}
                                                      onFocus={moveCaretAtEnd}
                                                      type="text"
                                                      className={` adjustableTextarea edit-task w-100 addfeature`}
                                                      style={{
                                                        overflowY: "hidden",
                                                        height: textareaRefs
                                                          .current[parentIndex]
                                                          ?.current
                                                          ? textareaRefs
                                                            .current[
                                                            parentIndex
                                                          ].current
                                                            .scrollHeight +
                                                          "px"
                                                          : "auto",
                                                      }}
                                                      rows={1}
                                                      value={parent?.Text}
                                                      placeholder={"Feature"}
                                                      onChange={(e) => {
                                                        const newText =
                                                          e.target.value;
                                                        setselectionEnd(
                                                          e.target.selectionEnd
                                                        );
                                                        setselectionStart(
                                                          e.target
                                                            .selectionStart
                                                        );
                                                        // const selectionStart =
                                                        //   e.target
                                                        //     .selectionStart; // Получаем текущее положение курсора
                                                        // const selectionEnd =
                                                        //   e.target.selectionEnd;

                                                        const updatedList =
                                                          linkedIncludesList.map(
                                                            (item, index) => {
                                                              if (
                                                                index ===
                                                                parentIndex
                                                              ) {
                                                                return {
                                                                  ...item,
                                                                  Text: newText,
                                                                };
                                                              }
                                                              return item;
                                                            }
                                                          );

                                                        setLinkedIncludesList(
                                                          updatedList
                                                        );
                                                        setChangedFeature(true);
                                                        setChanged(true);
                                                      }}
                                                      onBlur={() => {
                                                        let newAdd = {
                                                          ...addFeature,
                                                        };
                                                        newAdd.title = "";
                                                        setAddFeature(newAdd);
                                                      }}
                                                      onKeyDown={(e) => {
                                                        if (e.key === "Enter") {
                                                          e.preventDefault();
                                                          const newText =
                                                            e.target.value;

                                                          const updatedList =
                                                            linkedIncludesList.map(
                                                              (item, index) => {
                                                                if (
                                                                  index ===
                                                                  parentIndex
                                                                ) {
                                                                  return {
                                                                    ...item,
                                                                    Text: newText,
                                                                  };
                                                                }
                                                                return item;
                                                              }
                                                            );

                                                          setLinkedIncludesList(
                                                            updatedList
                                                          );
                                                          setChanged(true);
                                                          let newAdd = {
                                                            ...addFeature,
                                                          };
                                                          newAdd.title = "";
                                                          setAddFeature(newAdd);
                                                          setIsFirstFocus(
                                                            false
                                                          );
                                                        }
                                                      }}
                                                    />
                                                  </div>

                                                  <div>
                                                    <img
                                                      alt=""
                                                      className="mr-2 pointer"
                                                      src="/images/msp/thin-cross.svg"
                                                      onClick={() => {
                                                        let updated_data = [
                                                          ...linkedIncludesList,
                                                        ];
                                                        updated_data.splice(
                                                          parentIndex,
                                                          1
                                                        );


                                                        setLinkedIncludesList(
                                                          updated_data
                                                        );
                                                        setChanged(true);
                                                      }}
                                                    />
                                                  </div>
                                                </div>
                                              </div>
                                            </li>
                                          )}
                                        </Draggable>
                                      )
                                    )}
                                  </ul>
                                </div>
                                {provided.placeholder}
                              </div>
                            )}
                          </Droppable>
                        </DragDropContext>
                      )}
                      <div
                        ref={elemsRefs.element3}
                        className={`radius-4 ${servicePageErrors?.selectedIncludesListError
                          ? "input-error"
                          : ""
                          } mt-2 w-30 w-100 addfeature`}
                        onClick={() => {
                          if (selectedServiceId > 0) {
                            let newAdd = { ...addFeature };
                            newAdd.title = "feature";
                            setAddFeature(newAdd);
                            setServicePageErrors((prev) => ({
                              ...prev,
                              serviceNotCreated: false,
                            }));
                          } else {
                            setServicePageErrors((prev) => ({
                              ...prev,
                              serviceNotCreated: true,
                            }));
                          }
                        }}
                      >
                        {addFeature?.title == "feature" ? (
                          <textarea
                            ref={textareaRefAddFeature}
                            type="text"
                            // className="border-none bg-transparent"
                            style={{ overflowY: "hidden" }}
                            className={`edit-task  edit-title w-100 addfeature`}
                            autoFocus
                            placeholder="Feature"
                            value={addFeature?.Text}
                            rows={1}
                            onChange={(e) => {
                              let newAdd = { ...addFeature };
                              newAdd.Text = e.target.value;
                              setAddFeature(newAdd);
                            }}
                            onBlur={async () => {
                              if (
                                addFeature?.Text &&
                                addFeature?.Text?.trim() !== ""
                              ) {
                                setChanged(true);

                                setServicePageErrors((prev) => ({
                                  ...prev,
                                  selectedIncludesListError: false,
                                }));
                                let updated_data = [...linkedIncludesList];
                                let savingdata = [
                                  ...linkedIncludesList,
                                  {
                                    Order: 0,
                                    Text: addFeature?.Text,
                                    Icon: "test.svg",
                                  },
                                ];

                                setLinkedIncludesList([
                                  ...updated_data,
                                  {
                                    Order: 0,
                                    Text: addFeature?.Text,
                                    Icon: "test.svg",
                                  },
                                ]);
                                let newAdd = { ...addFeature };
                                newAdd.title = "";
                                newAdd.Text = "";
                                setAddFeature(newAdd);
                                await postData(
                                  authTokens,
                                  "CreateSecurityStackServices",
                                  {
                                    ObjectId: selectedServiceId ?? 0,
                                    accesskey: JSON.parse(
                                      localStorage.getItem("user_MessageObj")
                                    ),
                                    Headline: newServiceName, //headline
                                    Sku: description,
                                    IsAddOn: IsAddOn,
                                    IsOnDemand: pricedOnDemandSwitch,
                                    MonthlyPrice: servicePrice,
                                    PricingModel:
                                      selectedBillingPeriod?.DDLText,
                                    NistPiller: selectedNistPillar?.DDLText,
                                    SelectionType: selectedSnapToUnit?.DDLText,
                                    SnapToUnit_Selected:
                                      JSON.stringify(selectedSnapToUnit),
                                    SecurityPack_Bullet_List: JSON.stringify(
                                      savingdata ?? []
                                    ), //linkedIncludesList
                                    SecurityPack_OptionList: "",
                                    SecurityPackLabour_List: JSON.stringify(
                                      labourList ?? []
                                    ),
                                    SecurityPackLinkSeries_List:
                                      JSON.stringify(selectedLinkList),
                                    SecurityPackPSAProduct_List: JSON.stringify(
                                      productsList ?? []
                                    ),
                                    ServiceTechnologiesSelected_List:
                                      JSON.stringify(technologyMappingSelected ?? []),
                                    ForeignCurrencyAmount: "",
                                    ForeignCurrencyRate:
                                      exchangeRateData?.Rate ?? 1,
                                    CurrencyCode_Selected:
                                      JSON.stringify(userSelectedCurrency),
                                    TaxRates_Selected: JSON.stringify(
                                      serviceTax ?? {}
                                    ),
                                    TotalCost: (
                                      totalProductsCost() + totalLabourPrice()
                                    )?.toFixed(2),
                                    TotalSell: getTotalServiceAmount(),
                                  }
                                )
                                  .then((response) => {
                                    getServiceData(selectedServiceId ?? 0);
                                    setUpdateModal(false);
                                    setChanged(false);
                                  })
                                  .catch((err) => {
                                    CallToast(
                                      "Something went wrong",
                                      false,
                                      false,
                                      "v2style"
                                    );
                                    console.log(err);
                                  });
                              }

                              // createAddService().then(() => {
                              //   getServiceData(
                              //     selectedServiceId ?? 0
                              //   );
                              //   setUpdateModal(false);
                              //   setChanged(false);
                              //   // let newAdd = { ...addFeature };
                              //   // newAdd.title = "";
                              //   // setAddFeature(newAdd);
                              // });
                            }}
                            onKeyDown={async (e) => {
                              if (e.key === "Enter") {
                                e.preventDefault();
                                if (
                                  addFeature?.Text &&
                                  addFeature?.Text?.trim() !== ""
                                ) {
                                  setChanged(true);

                                  setServicePageErrors((prev) => ({
                                    ...prev,
                                    selectedIncludesListError: false,
                                  }));
                                  let updated_data = [...linkedIncludesList];
                                  let savingdata = [
                                    ...linkedIncludesList,
                                    {
                                      Order: 0,
                                      Text: addFeature?.Text,
                                      Icon: "test.svg",
                                    },
                                  ];

                                  setLinkedIncludesList([
                                    ...updated_data,
                                    {
                                      objId_SecurityPackBullet: Math.floor(Math.random() * 1000000),
                                      Order: Math.floor(Math.random() * 1000000),
                                      Text: addFeature?.Text,
                                      Icon: "test.svg",
                                    },
                                  ]);
                                  let newAdd = { ...addFeature };
                                  newAdd.title = "feature";
                                  newAdd.Text = "";
                                  setAddFeature(newAdd);
                                  // await postData(
                                  //   authTokens,
                                  //   "CreateSecurityStackServices",
                                  //   {
                                  //     ObjectId: selectedServiceId ?? 0,
                                  //     accesskey: JSON.parse(
                                  //       localStorage.getItem("user_MessageObj")
                                  //     ),
                                  //     Headline: newServiceName, //headline
                                  //     Sku: description,
                                  //     IsAddOn: IsAddOn,
                                  //     IsOnDemand: pricedOnDemandSwitch,
                                  //     MonthlyPrice: servicePrice,
                                  //     PricingModel:
                                  //       selectedBillingPeriod?.DDLText,
                                  //     NistPiller: selectedNistPillar?.DDLText,
                                  //     SelectionType:
                                  //       selectedSnapToUnit?.DDLText,
                                  //     SnapToUnit_Selected:
                                  //       JSON.stringify(selectedSnapToUnit),
                                  //     SecurityPack_Bullet_List: JSON.stringify(
                                  //       savingdata ?? []
                                  //     ), //linkedIncludesList
                                  //     SecurityPack_OptionList: "",
                                  //     SecurityPackLabour_List: JSON.stringify(
                                  //       labourList ?? []
                                  //     ),
                                  //     SecurityPackLinkSeries_List:
                                  //       JSON.stringify(selectedLinkList),
                                  //     SecurityPackPSAProduct_List:
                                  //       JSON.stringify(productsList ?? []),
                                  //     ServiceTechnologiesSelected_List: 
                                  //       JSON.stringify(technologyMappingSelected ?? []),
                                  //     ForeignCurrencyAmount: "",
                                  //     ForeignCurrencyRate:
                                  //       exchangeRateData?.Rate ?? 1,
                                  //     CurrencyCode_Selected:
                                  //       JSON.stringify(userSelectedCurrency),
                                  //     TaxRates_Selected: JSON.stringify(
                                  //       serviceTax ?? {}
                                  //     ),
                                  //     TotalCost: (
                                  //       totalProductsCost() +
                                  //       totalLabourPrice()
                                  //     )?.toFixed(2),
                                  //     TotalSell: getTotalServiceAmount(),
                                  //   }
                                  // )
                                  //   .then((response) => {
                                  //     // let test = [...linkedIncludesList];
                                  //     // test[test?.length - 1].id = Math.floor(Math.random() * 1000);
                                  //     // setLinkedIncludesList(test);
                                  //     getServiceData(selectedServiceId ?? 0, true, removedFeatures);
                                  //     setUpdateModal(false);
                                  //     setChanged(false);
                                  //   })
                                  //   .catch((err) => {
                                  //     CallToast(
                                  //       "Something went wrong",
                                  //       false,
                                  //       false,
                                  //       "v2style"
                                  //     );
                                  //     console.log(err);
                                  //   });
                                }
                              }
                            }}
                          />
                        ) : (
                          <div className="pointer bg-transparent radius-2 f-grey f-500 w-fit-content  d-flex justify-content-center align-items-center p-2  addfeature">
                            <img
                              src="/images/actioncenter/plusgrey.svg"
                              alt=""
                              className="mr-2"
                            />
                            Add Feature
                          </div>
                        )}
                      </div>
                      {servicePageErrors?.serviceNotCreated && (
                        <div className="f-12 f-danger">
                          Service must be saved before adding a feature
                        </div>
                      )}
                      {/* {servicePageErrors?.selectedIncludesListError && (
                        <div className="f-danger f-12">
                          Atleast 1 feature must be added to this service
                        </div>
                      )} */}
                    </div>
                  </div>
                </div>
                {/* Includes STOP */}

                {/* PRICING BLOCK START */}
                <hr />
                <div className="f-12 f-500 f-darkgrey mb-2">Pricing</div>

                <div className="d-flex flex-column w-100 mb-3">
                  <div className="d-flex w-100">
                    <div className="w-30 f-500">
                      Priced On-Demand{" "}
                    </div>

                    <div
                      className="w-70 "
                      style={{
                        paddingLeft: "6rem",
                      }}
                    >
                      <Form.Group controlId={"check" + "" + 125661} className="">
                        <Form.Check
                          tabIndex={-1}
                          type="switch"
                          id={
                            "switch-ques1" + "" + 12561 + Math.random() * 1000000
                          }
                          style={{
                            transform: "scale(1.3)",
                            paddingLeft: "0.75rem",
                          }}
                          className={`m-0 scale-switch switch-shadow-none ${true}  `}
                          checked={pricedOnDemandSwitch}
                          label=""
                          disabled={false}
                          onChange={(e) => {
                            setChanged(true);
                            setPricedOnDemandSwitch(e.target.checked);
                          }}
                          onClick={(e) => { }}
                        />
                      </Form.Group>
                    </div>
                  </div>
                </div>

                {
                  !pricedOnDemandSwitch ? <div>
                    <div className="d-flex flex-column w-100 mb-3">

                      <div className="d-flex w-100">
                        <div className="w-30">
                          <div className="d-flex f-500">
                            Billing period{" "}
                            <div className="msp-service-tool-tip-parent w-fit-content">
                              <img
                                className="ml-2 pointer"
                                src="/images/msp/ques-info-icon.svg"
                                alt=""
                              />
                              <MspServiceModalToolTip data={getToolTip(4)} />
                            </div>
                          </div>
                        </div>

                        <div className="w-70 " style={{}}>
                          <div
                            ref={!pricedOnDemandSwitch && elemsRefs.element4}
                            className={`msp-service-dropdown w-fit-content radius-4 ${servicePageErrors?.selectedBillingPeriodError
                              ? "input-error"
                              : ""
                              }`}
                          >
                            <CompanySettingsDropdown
                              disabled={
                                selectedServiceId !== 0 ||
                                  selectedLinkList.length > 0
                                  ? true
                                  : false
                              }
                              fieldId={"DDLId"}
                              fieldValue={"DDLText"}
                              disabledIcon={true}
                              selected={selectedBillingPeriod}
                              placeholder="Choose..."
                              //  showid={true}
                              onClick={(id, val, e, selectedItem) => {
                                setChanged(true);
                                setServicePageErrors((prev) => ({
                                  ...prev,
                                  selectedBillingPeriodError: false,
                                  billingToSeriesError: false,
                                }));
                                setBillingError(false);
                                setBillingProductError(false);
                                setSelectedBillingPeriod(selectedItem);
                              }}
                              data={billingPeriodDropdownList}
                            />
                          </div>
                        </div>
                      </div>

                      {servicePageErrors?.billingToSeriesError && (
                        <div className="f-danger f-12 ">
                          Billing period must be selected before linking or creating
                          a series
                        </div>
                      )}
                    </div>
                    <div className="d-flex w-100 mb-3">
                      <div className="w-30">
                        <div className="d-flex f-500">
                          Snap to unit{" "}
                          <div className="msp-service-tool-tip-parent w-fit-content">
                            <img
                              className="ml-2 pointer"
                              src="/images/msp/ques-info-icon.svg"
                              alt=""
                            />
                            <MspServiceModalToolTip data={getToolTip(5)} />
                          </div>
                        </div>
                      </div>

                      <div className="w-70 " style={{}}>
                        <div
                          ref={!pricedOnDemandSwitch && elemsRefs.element5}
                          className={` msp-service-dropdown w-fit-content radius-4 ${servicePageErrors?.selectedSnapToUnitsError
                            ? "input-error"
                            : ""
                            }`}
                        >
                          <AddSnapToUnitDropdown
                            selectionArrowAtLeft
                            disabled={serviceModalData?.IsDisableSnapToUnit}
                            disabledIcon={serviceModalData?.IsDisableSnapToUnit}
                            fieldId={"DDLId"}
                            fieldValue={"DDLText"}
                            selected={selectedSnapToUnit}
                            placeholder="Choose..."
                            onClick={(id, val, e, selectedItem) => {
                              setChanged(true);
                              setServicePageErrors((prev) => ({
                                ...prev,
                                selectedSnapToUnitsError: false,
                              }));

                              setSelectedSnapToUnit(selectedItem);
                            }}
                            data={serviceModalData?.SnapToUnit_List}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="d-flex align-items-center w-100 mb-3">
                      <div className="w-30">
                        <div className="d-flex f-500">Price </div>
                      </div>
                      <div className="d-flex align-items-center d-none">
                        <div
                          ref={!pricedOnDemandSwitch && elemsRefs.element6}
                          className={`${servicePageErrors?.selectedServicePriceError
                            ? "input-error"
                            : ""
                            } w-45 d-flex align-items-center radius-4 highlight-input  p-2  mr-1  `}
                        >
                          <div className="mr-1">
                            {" "}
                            {userSelectedCurrency?.CurrencySymbol ?? defaultCurrency?.CurrencySymbol ?? "£"}{" "}
                          </div>
                          <input
                            type="number"
                            placeholder="0"
                            value={servicePrice == 0 ? "" : servicePrice}
                            onChange={(e) => {
                              setChanged(true);
                              setPriceChanged(true);
                              setServicePageErrors((prev) => ({
                                ...prev,
                                selectedServicePriceError: false,
                              }));

                              let value = e.target.value;

                              const regex = /^-?\d*\.?\d{0,2}$/;

                              if (regex.test(value)) {
                                setServicePrice(value);
                              } else {
                                value = value.slice(0, -1);
                                setServicePrice(value);
                              }
                            }}
                            onBlur={(e) => {
                              const value = e.target.value;
                              if (!isNaN(value)) {
                                if (value.includes(".")) {
                                  const decimalPartLength =
                                    value.split(".")[1].length;

                                  if (decimalPartLength === 1) {
                                    setServicePrice(value + "0");
                                  } else {
                                    setServicePrice(value);
                                  }
                                } else {
                                  setServicePrice(value + ".00");
                                }
                              }
                            }}
                            className="bg-transparent flex-grow-1 border-none black-placeholder"
                          />
                        </div>
                        <div className="mr-2">
                          <CurrencyDropdown
                            currencyList={currencyList}
                            onChange={(data) => {
                              setUserSelectedCurrency(data);
                            }}
                            selected={userSelectedCurrency}
                          />
                        </div>{" "}
                      </div>
                    </div>

                    {/* <div className="f-12 f-500 f-darkgrey mb-2">Tax</div> */}
                    <div className="w-100 py-2 d-flex align-items-center f-black">
                      <div className="w-30">Tax Rate</div>
                      <div className="w-70 d-flex align-items-center justify-content-between">
                        <div
                          style={{ background: "#f6f8fb", padding: "8px 16px" }}
                          ref={!pricedOnDemandSwitch && elemsRefs.element7}
                          className={`radius-4 ${servicePageErrors?.selectedTaxError ? "input-error" : ""
                            }`}
                        >
                          <TaxRateDropdown
                            deleteTaxRate={deleteTaxRate}
                            updateTaxList={updateTaxList}
                            onChange={(data) => {
                              setServicePageErrors((prev) => ({
                                ...prev,
                                selectedTaxError: false,
                              }));
                              setSelectedTax(data);
                              setServiceTax(data);
                            }}
                            taxList={taxList}
                            selected={serviceTax}
                            onClickEditTaxRate={(data) => {
                              setChanged(true);

                              setTaxRateData(data);
                              setShowAddEditTaxRateModal(true);
                            }}
                            onClickAddTaxRate={() => {
                              setChanged(true);

                              setTaxRateData({
                                ObjectId: 0,
                                TaxCode: "",
                                TaxName: "",
                                TaxRate: "",
                              });
                              setShowAddEditTaxRateModal(true);
                            }}
                          />
                        </div>
                        <div className="d-flex align-items-center radius-4 f-grey  p-2  mr-1">
                          {serviceTax?.TaxRate ?? 0}% ={" "}
                          {userSelectedCurrency?.CurrencySymbol}{" "}
                          {Number(
                            ((servicePrice ?? 0) * (serviceTax?.TaxRate ?? 0)) /
                            100 ?? 0
                          )?.toFixed(2)}
                        </div>
                      </div>
                    </div>

                    {/* PRICING BLOCK STOP */}

                    {/* Products Costs BLOCK START */}
                    <hr />

                    {/* Product Costs */}
                    <div className="f-12 f-500 f-darkgrey mb-2">Product costs</div>
                    <div className="border-parent w-100 radius-4">
                      {selectedLinkList?.length > 0 &&
                        linkedProductsList?.length > 0 && (
                          <div className="f-12 f-500 f-darkgrey p-1 border-item product-p1-table">
                            <div className="f-12 f-500 f-darkgrey pl-2">
                              Linked series products
                            </div>
                            <div></div>
                            <div></div>
                          </div>
                        )}

                      {linkedProductsList?.map((product, productIndex) => {
                        return (
                          <div className=" f-500  p-2 border-item product-p1-table">
                            <div className=" f-500 f-black pl-2">
                              {product?.ProductName}
                            </div>
                            <div className="f-darkgrey">
                              £ {product?.Cost} /device /mo
                            </div>
                            <div className="d-flex align-items-center justify-content-end"></div>
                          </div>
                        );
                      })}
                    </div>

                    {selectedLinkList[0]?.SecurityPackVM_List?.length > 0 &&
                      selectedLinkList[0]?.SecurityPackVM_List?.filter(
                        (serv) => serv?.Headline !== headline
                      )?.map((serv, servIndex) => {
                        return (
                          <div className="radius-4 border-parent mb-2">
                            <div className="table-title radius-4 f-500">
                              <div className="img">
                                <img
                                  alt=""
                                  className=" "
                                  src="/images/msp/arrow-enter.svg"
                                />
                              </div>

                              <div className="title f-darkgrey">
                                Inherited from `{serv?.Headline}`{" "}
                              </div>
                              <div
                                className="hide-btn d-flex pointer"
                                onClick={() => {
                                  setHideProducts({
                                    headline: serv?.Headline,
                                    mode: !hideProducts.mode,
                                  });
                                  // let hprod = hideProducts;
                                  // hprod.push({
                                  //   headline: serv?.Headline,
                                  //   mode: !hideProducts.mode,
                                  // });
                                  // // setHideProducts((prev) => ({
                                  // //   ...prev,
                                  // //   hprod,
                                  // // }));
                                  // setHideProducts(hprod);
                                  // const updatedProducts = [...hideProducts];
                                  // const existingProductIndex =
                                  //   updatedProducts.findIndex(
                                  //     (product) =>
                                  //       product.headline === serv?.Headline
                                  //   );

                                  // if (existingProductIndex !== -1) {
                                  //   // Product already exists, update it
                                  //   updatedProducts[existingProductIndex] = {
                                  //     ...updatedProducts[existingProductIndex],
                                  //     mode: !updatedProducts[existingProductIndex]
                                  //       .mode,
                                  //   };
                                  // } else {
                                  //   // Product doesn't exist, add it
                                  //   updatedProducts.push({
                                  //     headline: serv?.Headline,
                                  //     mode: !hideProducts.mode,
                                  //   });
                                  // }

                                  // setHideProducts(updatedProducts);
                                }}
                              >
                                <div className="f-grey">
                                  {
                                    // hideProducts && isProductHidden(serv?.Headline)
                                    hideProducts.headline == serv?.Headline &&
                                      hideProducts.mode
                                      ? "Show"
                                      : "Hide"
                                  }
                                </div>
                                <div className="img">
                                  {hideProducts.headline == serv?.Headline &&
                                    hideProducts.mode ? (
                                    // hideProducts &&
                                    // isProductHidden(serv?.Headline)
                                    <img
                                      alt=""
                                      className=" mr-1"
                                      src="/images/msp/arrow-expandout.svg"
                                    />
                                  ) : (
                                    <img
                                      alt=""
                                      className=" mr-1"
                                      src="/images/msp/arrow-expandin.svg" // src="/images/msp/arrow-expandout.svg"
                                    />
                                  )}
                                </div>
                              </div>
                            </div>{" "}
                            {hideProducts.headline == serv?.Headline &&
                              hideProducts.mode ? (
                              // hideProducts && isProductHidden(serv?.Headline)
                              <></>
                            ) : (
                              <>
                                {serv?.SecurityPackPSAProduct_List.length > 0 ? (
                                  <>
                                    <div className="products-list-summary-table radius-4 border-item py-1">
                                      <div className="f-12 f-500 f-darkgrey">
                                        Product
                                      </div>
                                      <div className="f-12 f-500 f-darkgrey">
                                        Cost
                                      </div>
                                      <div className="f-12 f-500 f-darkgrey">
                                        Qty.
                                      </div>
                                      <div className="f-12 f-500 f-darkgrey">
                                        Total
                                      </div>
                                    </div>

                                    {serv?.SecurityPackPSAProduct_List?.map(
                                      (product, productIndex) => {
                                        return (
                                          <>
                                            <div
                                              className={`mb-2 products-list-summary-table radius-4 align-items-center border-item`}
                                              style={{}}
                                              onClick={(e) => { }}
                                            >
                                              <div title={product?.Title}>
                                                <div className="d-flex flex-column">
                                                  <div className="f-500 d-flex f-darkgrey">
                                                    {product?.ProductName}
                                                  </div>
                                                  <div className="f-12 f-darkgrey">
                                                    /{product?.SnapToUnit_Text}
                                                  </div>
                                                </div>
                                                <div className="f-12 f-darkgrey">
                                                  {product?.Category_Selected
                                                    ?.CategoryName && (
                                                      <>
                                                        {TrimString(
                                                          product?.Category_Selected
                                                            ?.CategoryName,
                                                          20
                                                        )}{" "}
                                                        {">"}
                                                        {TrimString(
                                                          product
                                                            ?.SubCategory_Selected
                                                            ?.CategoryName,
                                                          25
                                                        )}
                                                      </>
                                                    )}
                                                </div>
                                              </div>
                                              <div className=" f-darkgrey">
                                                {/* {product?.CurrencyCode_Selected
                                                  ?.CurrencyName ?? "GBP"}{" "} */}
                                                {product?.CurrencyCode_Selected
                                                  ?.CurrencySymbol ?? defaultCurrency?.CurrencySymbol}{" "}
                                                <span className="mr-1">
                                                  {TrimString(
                                                    // product?.Price?.toFixed(2),
                                                    product?.Cost?.toFixed(2),
                                                    6
                                                  )}
                                                </span>
                                                /{product?.BillingPeriod_Text}
                                              </div>
                                              <div className=" f-darkgrey d-flex">
                                                {TrimString(
                                                  product?.Quantity == 0
                                                    ? 1
                                                    : product?.Quantity,
                                                  4
                                                )}
                                              </div>
                                              <div className="f-500 f-darkgrey d-flex">
                                                <div className="w-100">
                                                  <div>
                                                    {product?.CurrencyCode_Selected
                                                      ?.CurrencySymbol ?? defaultCurrency?.CurrencySymbol}{" "}
                                                    {TrimString(
                                                      // Math.ceil
                                                      (
                                                        // product?.Price *
                                                        product?.Cost *
                                                        product?.Quantity
                                                      ).toFixed(2),
                                                      8
                                                    )}
                                                    {/* {TrimString(
                                                      Math.ceil(
                                                        product?.Price *
                                                          product?.Quantity
                                                      ),
                                                      5
                                                    )}{" "} */}
                                                    {/* /{product?.BillingPeriod_Text} */}
                                                  </div>
                                                  <div
                                                    className={`f-12 f-grey ${product?.CurrencyCode_Selected
                                                      ?.CurrencySymbol === defaultCurrency?.CurrencySymbol ||
                                                      !product
                                                        ?.CurrencyCode_Selected
                                                        ?.CurrencySymbol
                                                      ? "d-none"
                                                      : ""
                                                      }`}
                                                  >
                                                    <AttackSurfaceTooltip
                                                      bottomLeft={true}
                                                      topLeft={false}
                                                      content={
                                                        <span>
                                                          <img
                                                            alt=""
                                                            width={13}
                                                            height={13}
                                                            src="/images/msp/white-exchange-rate-icon.svg"
                                                            className="mr-1"
                                                          />{" "}
                                                          The exchange rate on{" "}
                                                          {moment(
                                                            product?.CreatedDate ??
                                                            ""
                                                          )?.format(
                                                            "DD MMM YYYY"
                                                          )}{" "}
                                                          is 1{" "}
                                                          {
                                                            product
                                                              ?.CurrencyCode_Selected
                                                              ?.CurrencyName
                                                          }{" "}
                                                          ={" "}
                                                          {
                                                            product?.ForeignCurrencyRate
                                                          }{" "}
                                                          GBP
                                                        </span>
                                                      }
                                                    >
                                                      <img
                                                        alt=""
                                                        width={13}
                                                        height={13}
                                                        src="/images/msp/exchange-rate-icon.svg"
                                                        className="mr-1"
                                                      />
                                                    </AttackSurfaceTooltip>
                                                    {defaultCurrency?.CurrencySymbol ?? '£'}
                                                    {TrimString(
                                                      // Math.ceil
                                                      (
                                                        (product?.ForeignCurrencyRate ??
                                                          1) *
                                                        // (product?.Price *
                                                        (product?.Cost *
                                                          product?.Quantity)
                                                      ).toFixed(2),
                                                      8
                                                    )}
                                                  </div>
                                                </div>
                                                {/* set index to the last item of the array */}
                                              </div>
                                            </div>
                                          </>
                                        );
                                      }
                                    )}
                                  </>
                                ) : (
                                  <>
                                    <div className="products-list-summary-table radius-4 f-darkgrey">
                                      No products have been added yet
                                    </div>
                                  </>
                                )}
                              </>
                            )}
                          </div>
                        );
                      })}
                    <div className="radius-4 border-parent mt-2">
                      <div
                        className="table-title radius-4 f-500"
                        style={{ gridTemplateColumns: "92% 8%" }}
                      >
                        <div className="title f-darkgrey">{newServiceName}</div>
                        {/* <div className="title f-darkgrey">{headline}</div> */}
                        <div
                          className="hide-btn d-flex pointer"
                          onClick={() => {
                            setHideProducts({
                              headline: newServiceName, //headline,
                              mode: !hideProducts.mode,
                            });
                            // const updatedProducts = [...hideProducts];
                            // const existingProductIndex = updatedProducts.findIndex(
                            //   (product) => product.headline === headline
                            // );

                            // if (existingProductIndex !== -1) {
                            //   // Product already exists, update it
                            //   updatedProducts[existingProductIndex] = {
                            //     ...updatedProducts[existingProductIndex],
                            //     mode: !updatedProducts[existingProductIndex].mode,
                            //   };
                            // } else {
                            //   // Product doesn't exist, add it
                            //   updatedProducts.push({
                            //     headline: headline,
                            //     mode: !hideProducts.mode,
                            //   });
                            // }

                            // setHideProducts(updatedProducts);
                          }}
                        >
                          <div className="f-grey">
                            {hideProducts.headline == headline && hideProducts.mode
                              ? // hideProducts && isProductHidden(headline)
                              "Show"
                              : "Hide"}
                          </div>
                          <div className="img">
                            {hideProducts.headline == headline &&
                              hideProducts.mode ? (
                              // hideProducts && isProductHidden(headline)
                              <img
                                alt=""
                                className=" mr-1"
                                src="/images/msp/arrow-expandout.svg"
                              />
                            ) : (
                              <img
                                alt=""
                                className=" mr-1"
                                src="/images/msp/arrow-expandin.svg" // src="/images/msp/arrow-expandout.svg"
                              />
                            )}
                          </div>
                        </div>
                      </div>{" "}
                      {hideProducts.headline == headline && hideProducts.mode ? (
                        // hideProducts && isProductHidden(headline)
                        <></>
                      ) : (
                        <>
                          {productsList.length > 0 ? (
                            <>
                              <div className="products-list-summary-table radius-4 border-item py-1">
                                <div className="f-12 f-500 f-darkgrey">Product</div>
                                <div className="f-12 f-500 f-darkgrey">Cost</div>
                                <div className="f-12 f-500 f-darkgrey">Qty.</div>
                                <div className="f-12 f-500 f-darkgrey">Total</div>
                              </div>
                              {productsList?.map((product, productIndex) => {
                                return (
                                  <>
                                    <div
                                      className={`mb-2 products-list-summary-table radius-4 pointer align-items-center border-item`}
                                      style={{}}
                                      onClick={(e) => {
                                        setChanged(true);

                                        e.preventDefault();
                                        setNestedProductModalState((prev) => ({
                                          ...prev,
                                          show: true,
                                          selectedProductId:
                                            product.ObjId_PSAProduct,
                                          // refreshService: getServiceData,
                                          // serviceId: _selectedServiceId,
                                        }));
                                      }}
                                    >
                                      <div title={product?.Title}>
                                        <div className="d-flex flex-column ">
                                          <div className="f-500 d-flex wordSpacing">
                                            {product?.ProductName}
                                          </div>
                                          <div className="f-12 f-darkgrey">
                                            /{product?.SnapToUnit_Text}
                                          </div>
                                        </div>
                                        <div className="f-12 f-darkgrey">
                                          {product?.Category_Selected
                                            ?.CategoryName && (
                                              <>
                                                {TrimString(
                                                  product?.Category_Selected
                                                    ?.CategoryName,
                                                  20
                                                )}{" "}
                                                {">"}
                                                {TrimString(
                                                  product?.SubCategory_Selected
                                                    ?.CategoryName,
                                                  25
                                                )}
                                              </>
                                            )}
                                        </div>
                                      </div>
                                      <div className=" f-darkgrey">
                                        {/* {product?.CurrencyCode_Selected
                                          ?.CurrencyName ?? "GBP"}{" "} */}
                                        {product?.CurrencyCode_Selected
                                          ?.CurrencySymbol ?? defaultCurrency?.CurrencySymbol ?? '£'
                                        }{" "}
                                        <span className="mr-1">
                                          {TrimString(
                                            product?.Cost?.toFixed(2),
                                            6
                                          )}
                                        </span>
                                        /{product?.BillingPeriod_Text}
                                      </div>
                                      <div style={{ marginLeft: "-10px" }}>
                                        <IncreaseDecreaseNumbersComponent
                                          type={"number"}
                                          floatValue={false}
                                          value={product?.Quantity}
                                          onChange={(value) => {
                                            let product_list = [...productsList];
                                            let t = product_list?.map((temp) => {
                                              if (
                                                temp.ObjId_PSAProduct ==
                                                product.ObjId_PSAProduct
                                              ) {
                                                temp.Quantity = value;
                                              }
                                              return temp;
                                            });
                                            setProductsList(product_list);
                                            return true;
                                          }}
                                          onClick={(e) => {
                                            // Prevent the click event from propagating to the parent div
                                            e.stopPropagation();
                                          }}
                                        />
                                      </div>
                                      <div className="f-500 f-darkgrey d-flex">
                                        <div className="w-100">
                                          <div>
                                            {product?.CurrencyCode_Selected
                                              ?.CurrencySymbol ?? defaultCurrency?.CurrencySymbol ?? "£"}{" "}
                                            {TrimString(
                                              // Math.ceil
                                              (
                                                product?.Cost * product?.Quantity
                                              ).toFixed(2),
                                              8
                                            )}{" "}
                                            {/* /{product?.BillingPeriod_Text} */}
                                          </div>
                                          <div
                                            className={`f-12 f-grey ${product?.CurrencyCode_Selected
                                              ?.CurrencySymbol === defaultCurrency?.CurrencySymbol
                                              ||
                                              !product?.CurrencyCode_Selected
                                                ?.CurrencySymbol
                                              ? "d-none"
                                              : ""
                                              }`}
                                          >
                                            <AttackSurfaceTooltip
                                              bottomLeft={true}
                                              topLeft={false}
                                              content={
                                                <span>
                                                  <img
                                                    alt=""
                                                    width={13}
                                                    height={13}
                                                    src="/images/msp/white-exchange-rate-icon.svg"
                                                    className="mr-1"
                                                  />{" "}
                                                  The exchange rate on{" "}
                                                  {moment(
                                                    product?.CreatedDate ?? ""
                                                  )?.format("DD MMM YYYY")}{" "}
                                                  is 1{" "}
                                                  {
                                                    product?.CurrencyCode_Selected
                                                      ?.CurrencyName
                                                  }{" "}
                                                  = {product?.ForeignCurrencyRate}{" "}
                                                  GBP
                                                </span>
                                              }
                                            >
                                              <img
                                                alt=""
                                                width={13}
                                                height={13}
                                                src="/images/msp/exchange-rate-icon.svg"
                                                className="mr-1"
                                              />
                                            </AttackSurfaceTooltip>
                                            {defaultCurrency?.CurrencySymbol ?? '£'}
                                            {TrimString(
                                              // Math.ceil
                                              (
                                                (product?.ForeignCurrencyRate ??
                                                  1) *
                                                (product?.Cost * product?.Quantity)
                                              ).toFixed(2),
                                              8
                                            )}
                                          </div>
                                        </div>
                                        {/* set index to the last item of the array */}

                                        <div className="d-flex align-items-center justify-content-center mr-1">
                                          <img
                                            alt=""
                                            className=" pointer"
                                            src="/images/attack-surface/cross-icon.svg"
                                            onClick={(e) => {
                                              setChanged(true);

                                              e.preventDefault();
                                              e.stopPropagation();
                                              let updated_product_list = [
                                                ...productsList,
                                              ];
                                              updated_product_list.splice(
                                                productIndex,
                                                1
                                              );
                                              setProductsList(updated_product_list);
                                            }}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                );
                              })}
                            </>
                          ) : (
                            <>
                              <div className="products-list-summary-table radius-4 f-darkgrey">
                                No products have been added yet
                              </div>
                            </>
                          )}
                        </>
                      )}
                    </div>

                    <div className={`radius-4  mt-2`}>
                      <AddProductSellDropdown
                        returnData={returnData}
                        errorInput={servicePageErrors?.selectedListError}
                        setPage={(page, name) => {
                          setChanged(true);

                          if (page === "addProduct") {
                            setNestedProductModalState((prev) => ({
                              ...prev,
                              show: true,
                              selectedProductId: 0,
                              newProductName: name,
                            }));
                          }
                        }}
                        selectedList={productsList}
                        data={allProductsList}
                        selectedBillingPeriod={selectedBillingPeriod}
                        setBillingProductError={setBillingProductError}
                        setNestedProductModalState={setNestedProductModalState}
                        onAdd={autoAddProduct}
                        onSelect={(selectedProduct) => {
                          setChanged(true);

                          setServicePageErrors((prev) => ({
                            ...prev,
                            selectedListError: false,
                          }));
                          let updated_product_list = [...productsList];
                          let Index = updated_product_list.findIndex(
                            (item) =>
                              item?.ObjId_PSAProduct ===
                              selectedProduct?.ObjId_PSAProduct
                          );
                          if (Index > -1) {
                            updated_product_list.splice(Index, 1);
                          } else {
                            updated_product_list.push({ ...selectedProduct });
                          }

                          setProductsList([
                            ...updated_product_list,
                            // { ...selectedProduct },
                          ]);
                        }}
                      />
                    </div>
                    {servicePageErrors?.selectedListError && (
                      <div className="f-danger f-12">
                        Atleast 1 product or labour item must be added to this
                        service
                      </div>
                    )}
                    {billingProductError && (
                      <div className="f-danger f-12 p-1">
                        Billing period must be selected before adding product
                      </div>
                    )}

                    <div className="f-12 f-darkgrey mt-3 mb-2">Labour costs</div>
                    {selectedLinkList[0]?.SecurityPackVM_List?.length > 0 &&
                      selectedLinkList[0]?.SecurityPackVM_List?.filter(
                        (serv) => serv?.Headline !== headline
                      )?.map((serv, servIndex) => {
                        return (
                          <div className="radius-4 border-parent mb-2">
                            <div className="table-title radius-4 f-500">
                              <div className="img">
                                <img
                                  alt=""
                                  className=" "
                                  src="/images/msp/arrow-enter.svg"
                                />
                              </div>

                              <div className="title f-darkgrey">
                                Inherited from `{serv?.Headline}`{" "}
                              </div>
                              <div
                                className="hide-btn d-flex pointer"
                                onClick={() => {
                                  setHideLabour({
                                    headline: serv?.Headline,
                                    mode: !hideLabour.mode,
                                  });
                                }}
                              >
                                <div className="f-grey">
                                  {hideLabour.headline == serv?.Headline &&
                                    hideLabour.mode
                                    ? "Show"
                                    : "Hide"}
                                </div>
                                <div className="img">
                                  {hideLabour.headline == serv?.Headline &&
                                    hideLabour.mode ? (
                                    <img
                                      alt=""
                                      className=" mr-1"
                                      src="/images/msp/arrow-expandout.svg"
                                    />
                                  ) : (
                                    <img
                                      alt=""
                                      className=" mr-1"
                                      src="/images/msp/arrow-expandin.svg" // src="/images/msp/arrow-expandout.svg"
                                    />
                                  )}
                                </div>
                              </div>
                            </div>{" "}
                            {hideLabour.headline == serv?.Headline &&
                              hideLabour.mode ? (
                              <></>
                            ) : (
                              <>
                                {" "}
                                {serv?.SecurityPackLabour_List?.length > 0 ? (
                                  <>
                                    <div className="labour-list-summary-table radius-4 border-item py-1">
                                      <div className="f-12 f-500 f-darkgrey pl-3">
                                        Item
                                      </div>
                                      <div className="f-12 f-500 f-darkgrey pl-3">
                                        Qty.
                                      </div>
                                      <div className="f-12 f-500 f-darkgrey pl-3">
                                        Unit type
                                      </div>
                                      <div className="f-12 f-500 f-darkgrey pl-1">
                                        Period
                                      </div>
                                      <div className="f-12 f-500 f-darkgrey pl-3">
                                        Unit rate
                                      </div>
                                      <div className="f-12 f-500 f-darkgrey">
                                        Subtotal
                                      </div>
                                    </div>
                                    <div className="border-parent w-100 radius-4 ">
                                      {serv?.SecurityPackLabour_List?.map(
                                        (labourItem, labourItemIndex) => {
                                          return (
                                            <div className="f-500 border-item labour-cost-table labour-list-summary-table align-items-center">
                                              <div className=" f-500 f-darkgrey pl-2 wordSpacing">
                                                {/* {labourItemIndex + 1}.{" "} */}
                                                {labourItem?.LabourName}
                                              </div>
                                              <div className="f-darkgrey p-2 m-1">
                                                {labourItem?.NoOfHours}{" "}
                                              </div>
                                              <div className="f-darkgrey ml-3">
                                                {labourItem?.Frequency_Text}{" "}
                                              </div>
                                              <div className="f-darkgrey">
                                                /
                                                {selectedBillingPeriod?.DDLColour ??
                                                  "month"}{" "}
                                              </div>
                                              <div className="f-darkgrey d-flex p-2 m-1">
                                                {labourItem?.CurrencyCode_Selected
                                                  ?.CurrencyName ??
                                                  userSelectedCurrency?.CurrencyName}{" "}
                                                {labourItem?.CurrencyCode_Selected
                                                  ?.CurrencySymbol ??
                                                  userSelectedCurrency?.CurrencySymbol}{" "}
                                                {labourItem?.LabourRate}{" "}
                                              </div>

                                              <div className="f-darkgrey d-flex">
                                                <div className="w-100">
                                                  <div>
                                                    {labourItem?.CurrencyCode_Selected
                                                      ? labourItem
                                                        ?.CurrencyCode_Selected
                                                        ?.CurrencySymbol
                                                      : userSelectedCurrency?.CurrencySymbol}{" "}
                                                    {labourItem?.Total
                                                      ? labourItem?.Total?.toFixed(
                                                        2
                                                      )
                                                      : (
                                                        labourItem?.LabourRate *
                                                        labourItem?.NoOfHours
                                                      )?.toFixed(2)}{" "}
                                                    {/* /{labourItem?.BillingPeriod_Text} */}
                                                  </div>
                                                  <div
                                                    className={`f-12 f-grey ${labourItem
                                                      ?.CurrencyCode_Selected
                                                      ?.CurrencySymbol === defaultCurrency?.CurrencySymbol ||
                                                      !labourItem
                                                        ?.CurrencyCode_Selected
                                                        ?.CurrencySymbol
                                                      ? "d-none"
                                                      : ""
                                                      }`}
                                                  >
                                                    <AttackSurfaceTooltip
                                                      bottomLeft={true}
                                                      topLeft={false}
                                                      content={
                                                        <span>
                                                          <img
                                                            alt=""
                                                            width={13}
                                                            height={13}
                                                            src="/images/msp/white-exchange-rate-icon.svg"
                                                            className="mr-1"
                                                          />{" "}
                                                          The exchange rate on{" "}
                                                          {moment(
                                                            labourItem?.CreatedDate ??
                                                            ""
                                                          )?.format(
                                                            "DD MMM YYYY"
                                                          )}{" "}
                                                          is 1{" "}
                                                          {
                                                            labourItem
                                                              ?.CurrencyCode_Selected
                                                              ?.CurrencyName
                                                          }{" "}
                                                          ={" "}
                                                          {
                                                            labourItem?.ForeignCurrencyRate
                                                          }{" "}
                                                          GBP
                                                        </span>
                                                      }
                                                    >
                                                      <img
                                                        alt=""
                                                        width={13}
                                                        height={13}
                                                        src="/images/msp/exchange-rate-icon.svg"
                                                        className="mr-1"
                                                      />
                                                    </AttackSurfaceTooltip>
                                                    {defaultCurrency?.CurrencySymbol ?? '£'}
                                                    {TrimString(
                                                      Math.ceil(
                                                        ((labourItem?.ForeignCurrencyRate
                                                          ? labourItem?.ForeignCurrencyRate
                                                          : exchangeRateData?.Rate) *
                                                          (labourItem?.LabourRate *
                                                            labourItem?.NoOfHours)) /
                                                        100
                                                      ),
                                                      8
                                                    )}
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          );
                                        }
                                      )}
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    <div className="products-list-summary-table radius-4 f-darkgrey">
                                      No labour has been added yet
                                    </div>
                                  </>
                                )}
                              </>
                            )}
                          </div>
                        );
                      })}
                    <div className="radius-4 border-parent mb-2">
                      <div
                        className="table-title radius-4 f-500"
                        style={{ gridTemplateColumns: "92% 8%" }}
                      >
                        <div className="title f-darkgrey">{newServiceName}</div>
                        <div
                          className="hide-btn d-flex pointer"
                          onClick={() => {
                            setHideLabour({
                              headline: newServiceName, //headline,
                              mode: !hideLabour.mode,
                            });
                          }}
                        >
                          <div className="f-grey">
                            {hideLabour.headline == headline && hideLabour.mode
                              ? "Show"
                              : "Hide"}
                          </div>
                          <div className="img">
                            {hideLabour.headline == headline && hideLabour.mode ? (
                              <img
                                alt=""
                                className=" mr-1"
                                src="/images/msp/arrow-expandout.svg"
                              />
                            ) : (
                              <img
                                alt=""
                                className=" mr-1"
                                src="/images/msp/arrow-expandin.svg" // src="/images/msp/arrow-expandout.svg"
                              />
                            )}
                          </div>
                        </div>
                      </div>{" "}
                      {hideLabour.headline == headline && hideLabour.mode ? (
                        <></>
                      ) : (
                        <>
                          {labourList?.length > 0 ? (
                            <>
                              <div className="labour-list-summary-table radius-4 border-item py-1">
                                <div className="f-12 f-500 f-darkgrey pl-3">
                                  Item
                                </div>
                                <div className="f-12 f-500 f-darkgrey pl-3">
                                  Qty.
                                </div>
                                <div className="f-12 f-500 f-darkgrey pl-3">
                                  Unit type
                                </div>
                                <div className="f-12 f-500 f-darkgrey pl-1">
                                  Period
                                </div>
                                <div className="f-12 f-500 f-darkgrey pl-3">
                                  Unit rate
                                </div>
                                <div className="f-12 f-500 f-darkgrey">
                                  Subtotal
                                </div>
                              </div>
                              <div className="border-parent w-100 radius-4">
                                {labourList?.map((labourItem, labourItemIndex) => {
                                  return (
                                    <div className=" f-500 border-item labour-cost-table labour-list-summary-table align-items-center">
                                      <div className=" f-500 f-black">
                                        <div
                                          className={`${false ? "input-error" : ""
                                            } d-flex align-items-center radius-4 highlight-input p-2 wordSpacing hide-scrollbar
                                          ${labourItem?.objId_SecurityPackLabour == 0 ? 'bg-greyish' : ''}`}
                                        >
                                          <textarea
                                            ref={textareaRef}
                                            type="text"
                                            value={
                                              labourItem?.LabourName
                                                ? labourItem?.LabourName
                                                : "Labour"
                                            }
                                            rows={1}
                                            onChange={(e) => {
                                              setChanged(true);

                                              const textarea = textareaRef.current;
                                              textarea.style.height = "auto"; // Reset the height to auto
                                              textarea.style.height =
                                                textarea.scrollHeight + "px"; // Set the height to the scrollHeight

                                              const inputValue = e.target.value;
                                              setLabourList((prevLabourList) => {
                                                return prevLabourList.map(
                                                  (temp) => {
                                                    if (
                                                      temp.objId_SecurityPackLabour ===
                                                      0
                                                    ) {
                                                      if (
                                                        temp.tempId ===
                                                        labourItem.tempId
                                                      ) {
                                                        temp.LabourName =
                                                          inputValue;
                                                      }
                                                    } else {
                                                      if (
                                                        temp.objId_SecurityPackLabour ===
                                                        labourItem.objId_SecurityPackLabour
                                                      ) {
                                                        temp.LabourName =
                                                          inputValue;
                                                      }
                                                    }

                                                    return temp;
                                                  }
                                                );
                                              });
                                            }}
                                            className="bg-transparent border-none w-100 wordSpacing no-resize hide-scrollbar"
                                          />
                                        </div>
                                      </div>
                                      <div className="">
                                        <IncreaseDecreaseNumbersComponent
                                          floatValue={false}
                                          value={labourItem?.NoOfHours ?? 1}
                                          divOnInputClassName={labourItem?.objId_SecurityPackLabour == 0 ? 'w-100 d-flex align-items-center radius-4 highlight-input p-2 m-1 bg-greyish' : ''}
                                          onChange={(value) => {
                                            const regex = /^-?\d*\.?\d{0,2}$/;

                                            if (regex.test(value)) {
                                              setLabourList((prevLabourList) => {
                                                return prevLabourList.map(
                                                  (temp) => {
                                                    if (
                                                      temp.objId_SecurityPackLabour ===
                                                      0
                                                    ) {
                                                      if (
                                                        temp.tempId ===
                                                        labourItem.tempId
                                                      ) {
                                                        temp.NoOfHours = value;
                                                        temp.Total =
                                                          labourItem?.LabourRate *
                                                          value;
                                                        temp.ForeignCurrencyAmount =
                                                          (temp.ForeignCurrencyRate ??
                                                            1) *
                                                          (labourItem?.LabourRate *
                                                            value);
                                                      }
                                                    } else {
                                                      if (
                                                        temp.objId_SecurityPackLabour ===
                                                        labourItem.objId_SecurityPackLabour
                                                      ) {
                                                        temp.NoOfHours = value;
                                                        temp.Total =
                                                          labourItem?.LabourRate *
                                                          value;
                                                        temp.ForeignCurrencyAmount =
                                                          (temp.ForeignCurrencyRate ??
                                                            1) *
                                                          (labourItem?.LabourRate *
                                                            value);
                                                      }
                                                    }

                                                    return temp;
                                                  }
                                                );
                                              });

                                              return true;
                                            } else {
                                              console.log(
                                                value,
                                                labourItem?.LabourRate
                                              );
                                              return false;
                                            }
                                          }}
                                          onClick={(e) => {
                                            e.stopPropagation();
                                          }}
                                        />
                                      </div>
                                      <div className="labour f-darkgrey">
                                        <div className="d-flex">
                                          <CompanySettingsDropdown
                                            fieldId={"DDLId"}
                                            fieldValue={"DDLText"}
                                            // toggleStyle={labourItem?.objId_SecurityPackLabour == 0 ? 'bg-greyish-imp' : ''}
                                            // isGrayToggle={labourItem?.objId_SecurityPackLabour == 0 ? true : false}
                                            selected={{
                                              DDLId: labourItem?.Frequency,
                                              DDLText:
                                                labourItem?.Frequency_Text ??
                                                "Choose",
                                            }}
                                            placeholder="Choose"
                                            onClick={(id, val) => {
                                              setChanged(true);

                                              setLabourList((prevLabourList) => {
                                                return prevLabourList.map(
                                                  (temp) => {
                                                    if (
                                                      temp.objId_SecurityPackLabour ===
                                                      0
                                                    ) {
                                                      if (
                                                        temp.tempId ===
                                                        labourItem.tempId
                                                      ) {
                                                        temp.Frequency = id;
                                                        temp.Frequency_Text = val;
                                                      }
                                                    } else {
                                                      if (
                                                        temp.objId_SecurityPackLabour ===
                                                        labourItem.objId_SecurityPackLabour
                                                      ) {
                                                        temp.Frequency = id;
                                                        temp.Frequency_Text = val;
                                                      }
                                                    }

                                                    return temp;
                                                  }
                                                );
                                              });
                                            }}
                                            data={
                                              serviceModalData?.LabourPeriod_List
                                            }
                                          />
                                        </div>
                                      </div>
                                      <div className="f-darkgrey">
                                        /
                                        {selectedBillingPeriod?.DDLColour ??
                                          "month"}{" "}
                                      </div>
                                      <div className="d-flex">
                                        <IncreaseDecreaseNumbersComponent
                                          floatValue={true}
                                          divOnInputClassName={labourItem?.objId_SecurityPackLabour == 0 ? 'w-100 d-flex align-items-center radius-4 highlight-input p-2 m-1 bg-greyish' : ''}
                                          children={
                                            <>
                                              <div className="f-darkgrey  mr-1 d-flex">
                                                <CurrencyDropdown
                                                  currencyList={currencyList}
                                                  onChange={async (data) => {
                                                    setChanged(true);

                                                    let newRate =
                                                      await getExchangeRateData(
                                                        data?.CurrencyName
                                                      );

                                                    setLabourList(
                                                      (prevLabourList) => {
                                                        return prevLabourList.map(
                                                          (temp) => {
                                                            if (
                                                              temp.objId_SecurityPackLabour ===
                                                              0
                                                            ) {
                                                              if (
                                                                temp.tempId ===
                                                                labourItem.tempId
                                                              ) {
                                                                temp.CurrencyCode_Selected =
                                                                  data;
                                                                temp.ForeignCurrencyRate =
                                                                  newRate.Rate;
                                                                temp.ForeignCurrencyAmount =
                                                                  newRate.Rate *
                                                                  (labourItem?.LabourRate *
                                                                    labourItem?.NoOfHours);
                                                                console.log(temp);
                                                              }
                                                            } else {
                                                              if (
                                                                temp.objId_SecurityPackLabour ===
                                                                labourItem.objId_SecurityPackLabour
                                                              ) {
                                                                temp.CurrencyCode_Selected =
                                                                  data;
                                                                temp.ForeignCurrencyRate =
                                                                  newRate.Rate;
                                                                temp.ForeignCurrencyAmount =
                                                                  newRate.Rate *
                                                                  (labourItem?.LabourRate *
                                                                    labourItem?.NoOfHours);
                                                                console.log(temp);
                                                              }
                                                            }

                                                            return temp;
                                                          }
                                                        );
                                                      }
                                                    );
                                                  }}
                                                  selected={
                                                    labourItem?.CurrencyCode_Selected ??
                                                    userSelectedCurrency
                                                  }
                                                />
                                                <div className="ml-2 d-flex f-black">
                                                  {labourItem?.CurrencyCode_Selected
                                                    ? labourItem
                                                      ?.CurrencyCode_Selected
                                                      ?.CurrencySymbol
                                                    : userSelectedCurrency?.CurrencySymbol}
                                                </div>
                                              </div>
                                            </>
                                          }
                                          value={labourItem?.LabourRate}
                                          onChange={(value) => {
                                            const regex = /^-?\d*\.?\d{0,2}$/;

                                            if (regex.test(value)) {
                                              setLabourList((prevLabourList) => {
                                                return prevLabourList.map(
                                                  (temp) => {
                                                    if (
                                                      temp.objId_SecurityPackLabour ===
                                                      0
                                                    ) {
                                                      if (
                                                        temp.tempId ===
                                                        labourItem.tempId
                                                      ) {
                                                        temp.LabourRate = value;
                                                        temp.Total =
                                                          value * temp?.NoOfHours;

                                                        temp.ForeignCurrencyAmount =
                                                          (temp.ForeignCurrencyRate ??
                                                            1) *
                                                          (value *
                                                            labourItem?.NoOfHours);
                                                      }
                                                    } else {
                                                      if (
                                                        temp.objId_SecurityPackLabour ===
                                                        labourItem.objId_SecurityPackLabour
                                                      ) {
                                                        temp.LabourRate = value;
                                                        temp.Total =
                                                          value * temp?.NoOfHours;

                                                        temp.ForeignCurrencyAmount =
                                                          (temp.ForeignCurrencyRate ??
                                                            1) *
                                                          (value *
                                                            labourItem?.NoOfHours);
                                                      }
                                                    }

                                                    return temp;
                                                  }
                                                );
                                              });
                                              return true;
                                            } else {
                                              console.log(
                                                value,
                                                labourItem?.LabourRate
                                              );
                                              return false;
                                            }
                                          }}
                                          onClick={(e) => {
                                            e.stopPropagation();
                                          }}
                                        />
                                      </div>
                                      <div className="f-500 f-black d-flex">
                                        <div className="w-100">
                                          <div>
                                            {labourItem?.CurrencyCode_Selected
                                              ? labourItem?.CurrencyCode_Selected
                                                ?.CurrencySymbol
                                              : userSelectedCurrency?.CurrencySymbol}{" "}
                                            {labourItem?.Total?.toFixed(2)}{" "}
                                          </div>
                                          <div
                                            className={`f-12 f-grey ${!labourItem?.CurrencyCode_Selected
                                              ?.CurrencySymbol ||
                                              labourItem?.CurrencyCode_Selected
                                                ?.CurrencySymbol == defaultCurrency?.CurrencySymbol

                                              ? "d-none"
                                              : ""
                                              }`}
                                          >
                                            <AttackSurfaceTooltip
                                              bottomLeft={true}
                                              topLeft={false}
                                              content={
                                                <span>
                                                  <img
                                                    alt=""
                                                    width={13}
                                                    height={13}
                                                    src="/images/msp/white-exchange-rate-icon.svg"
                                                    className="mr-1"
                                                  />{" "}
                                                  The exchange rate on{" "}
                                                  {moment(
                                                    labourItem?.CreatedDate ?? ""
                                                  )?.format("DD MMM YYYY")}{" "}
                                                  is 1{" "}
                                                  {
                                                    labourItem
                                                      ?.CurrencyCode_Selected
                                                      ?.CurrencyName
                                                  }{" "}
                                                  ={" "}
                                                  {labourItem?.ForeignCurrencyRate}{" "}
                                                  GBP
                                                </span>
                                              }
                                            >
                                              <img
                                                alt=""
                                                width={13}
                                                height={13}
                                                src="/images/msp/exchange-rate-icon.svg"
                                                className="mr-1"
                                              />
                                            </AttackSurfaceTooltip>
                                            {defaultCurrency?.CurrencySymbol ?? '£'}
                                            {TrimString(
                                              (
                                                labourItem?.ForeignCurrencyRate *
                                                labourItem?.Total
                                              ).toFixed(2),
                                              8
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                      <div className="d-flex align-items-center justify-content-end">
                                        <img
                                          alt=""
                                          width={11}
                                          src="/images/attack-surface/cross-icon.svg"
                                          className=" mr-2 pointer"
                                          onClick={() => {
                                            setChanged(true);

                                            let updated_data = [...labourList];
                                            updated_data.splice(labourItemIndex, 1);
                                            setLabourList(updated_data);
                                          }}
                                        />
                                      </div>
                                    </div>
                                  );
                                })}
                              </div>
                            </>
                          ) : (
                            <>
                              <div className="products-list-summary-table radius-4 f-darkgrey">
                                No labour has been added yet
                              </div>
                            </>
                          )}
                        </>
                      )}
                    </div>

                    <div className="w-100 mt-3">
                      <div
                        ref={!pricedOnDemandSwitch && elemsRefs.element8}
                        className={`radius-4 ${servicePageErrors?.selectedListError ? "input-error" : ""
                          } pointer bg-grey f-grey f-500 w-fit-content  d-flex justify-content-center align-items-center p-2`}
                        onClick={() => {
                          setChanged(true);

                          if (selectedBillingPeriod) {
                            setBillingError(false);
                            setServicePageErrors((prev) => ({
                              ...prev,
                              selectedListError: false,
                            }));
                            let updated_data = [...labourList];
                            setLabourList([
                              ...updated_data,
                              {
                                tempId: tempIdForlabourCounter,
                                objId_SecurityPackLabour: 0,
                                LabourName: "Labour",
                                Frequency: 0,
                                Frequency_Text: "Choose",
                                NoOfHours: 1,
                                LabourRate: "0.00",
                                NoOfHours_Text: `1`,
                                LabourRate_Text: "0.00",
                              },
                            ]);
                            setTempIdForLabourCounter(
                              (prevCounter) => prevCounter + 1
                            );
                          } else {
                            setBillingError(true);
                            // setServicePageErrors((prev) => ({
                            //   ...prev,
                            //   selectedListError: true,
                            // }));
                          }
                        }}
                      >
                        <img
                          alt=""
                          src="/images/actioncenter/plusgrey.svg"
                          className="mr-2"
                        />
                        Add Labour
                      </div>

                      {servicePageErrors?.selectedListError && (
                        <div className="f-danger f-12">
                          Atleast 1 product or labour item must be added to this
                          service
                        </div>
                      )}
                      {billingError && (
                        <div className="f-danger f-12 p-1">
                          Billing period must be selected before adding labour
                        </div>
                      )}
                    </div>

                    {/* Products Costs BLOCK STOP */}

                    {/* SUMMARY BLOCK START*/}
                    <hr />
                    <div className="d-flex align-items-center justify-content-between">
                      <div className="f-12 f-500 f-darkgrey ">Summary</div>
                      <div className="summary-period-dropdown"></div>
                    </div>

                    <div className="mt-2 mb-3 d-flex align-items-center justify-content-between f-black w-100">
                      <div>Sell</div>
                      <div>
                        {userSelectedCurrency?.CurrencySymbol}{" "}
                        {getTotalServiceAmount()}{" "}
                        {selectedSnapToUnit?.DDLColour ?? " /unit"} /
                        {selectedBillingPeriod?.DDLColour ?? "month"}
                      </div>
                    </div>

                    <div className="mt-2 mb-3 d-flex align-items-center justify-content-between f-black w-100">
                      <div>Product cost</div>
                      <div>
                        {userSelectedCurrency?.CurrencySymbol}{" "}
                        {totalProductsCost()?.toFixed(2)}{" "}
                        {selectedSnapToUnit?.DDLColour ?? " /unit"} /
                        {selectedBillingPeriod?.DDLColour ?? "month"}
                      </div>
                    </div>
                    <div className="mt-2 mb-3 d-flex align-items-center justify-content-between f-black w-100">
                      <div>Labour cost</div>
                      <div>
                        {userSelectedCurrency?.CurrencySymbol}{" "}
                        {totalLabourPrice()?.toFixed(2)}{" "}
                        {selectedSnapToUnit?.DDLColour ?? " /unit"} /
                        {selectedBillingPeriod?.DDLColour ?? "month"}
                      </div>
                    </div>
                    <div className="mt-2 mb-3 d-flex align-items-center justify-content-between f-black w-100">
                      <div>Total cost</div>
                      <div>
                        {userSelectedCurrency?.CurrencySymbol}{" "}
                        {(totalProductsCost() + totalLabourPrice())?.toFixed(2)}{" "}
                        {selectedSnapToUnit?.DDLColour ?? " /unit"} /
                        {selectedBillingPeriod?.DDLColour ?? "month"}
                      </div>
                    </div>
                    <div className="mt-2 mb-1 d-flex align-items-center justify-content-between f-black w-100">
                      <div>Margin</div>
                      <div
                        className={`${Number(
                          getTotalServiceAmount() -
                          (totalProductsCost() + totalLabourPrice())
                        )?.toFixed(2) < 0
                          ? "f-danger"
                          : ""
                          }`}
                      >
                        {userSelectedCurrency?.CurrencySymbol}{" "}
                        {Number(
                          getTotalServiceAmount() -
                          (totalProductsCost() + totalLabourPrice())
                        )?.toFixed(2)}{" "}
                        {selectedSnapToUnit?.DDLColour ?? " /unit"} /
                        {selectedBillingPeriod?.DDLColour ?? "month"}
                      </div>
                    </div>

                    {exchangeRateData?.FromCurrency &&
                      exchangeRateData?.FromCurrency !== "GBP" && (
                        <div className="w-100 text-center f-12 f-400 f-darkgrey mb-3">
                          <img
                            alt=""
                            width={13}
                            height={13}
                            src="/images/msp/exchange-rate-icon.svg"
                            className="mr-2"
                          />{" "}
                          The exchange rate on{" "}
                          {moment(exchangeRateData?.Date ?? "")?.format(
                            "DD MMM YYYY"
                          )}{" "}
                          is 1 {exchangeRateData?.FromCurrency} ={" "}
                          {exchangeRateData?.Rate} GBP
                        </div>
                      )}

                  </div> : <div>
                    <div className="f-400 f-14 f-darkgrey mb-5">
                      This service has a variable price based on specific requirements.
                    </div>
                    <div className="f-400 f-14 f-darkgrey">
                      Note: When included within a package, the customer will be notified that this package includes services which are priced on demand.
                    </div>
                  </div>
                }

                {/* SUMMARY BLOCK STOP*/}

                {/* AFFECT ON SCORE BLOCK */}
                {/* <hr />
                <div className="f-12 f-500 f-darkgrey mb-2">
                  Align to NIST Cybersecurity Framework
                </div>
                {showRadar === false ? (
                  <>
                    {" "}
                    <div className="w-100 d-flex justify-content-center px-5 position-relative align-items-center">
                      <div className="w-50 d-flex">
                        <MspSinglePointRadar
                          data={data}
                          options={_actualOptions}
                        />
                      </div>

                      {/* Overlay for Radar Chart 
                      <div
                        className="w-50 position-absolute d-flex flex-column align-items-center justify-content-center"
                        style={{ marginTop: "25px" }}
                      >
                        <div
                          className="f-500 f-black w-50"
                          style={{
                            textAlign: "center",
                          }}
                        >
                          Answer questions on this service so we can display it
                          visually
                        </div>
                      </div>
                    </div>
                    <div className="d-flex justify-content-center mt-4">
                      <Button
                        className={` ${
                          servicePageErrors?.selectedAlignNistError
                            ? "input-error hg-red-btn"
                            : "hg-blue-btn"
                        }  w-fit-content`}
                        ref={elemsRefs.element9}
                        onClick={async () => {
                          let errors = await CheckValidationForServicePage(
                            "align"
                          );

                          if (errors > 0) {
                            setItemsUpdated(true);
                          } else {
                            setChanged(true);
                            if (Number(selectedServiceId) === 0) {
                              createServiceId().then(() => {
                                setServicePageErrors((prev) => ({
                                  ...prev,
                                  selectedAlignNistError: false,
                                }));
                                setShowMspScoreModal(true);
                              });
                            } else {
                              setShowMspScoreModal(true);
                            }
                          }
                        }}
                      >
                        {creatingId
                          ? "Please wait..."
                          : "Align to the NIST CSF"}
                      </Button>
                    </div>
                  </>
                ) : (
                  <>
                    {" "}
                    <div
                      className="w-100 d-flex justify-content-center px-5 position-relative"
                      style={{ marginTop: "25px" }}
                    >
                      <div
                        className="w-50 d-flex"
                        onClick={() => {
                          setShowMspScoreModal(true);
                        }}
                      >
                        {" "}
                        <MspSinglePointRadar
                          data={_actualData}
                          options={_actualOptions}
                        />
                      </div>
                    </div>
                    <div className="d-flex justify-content-center mt-4">
                      <Button
                        className="hg-grey-btn w-fit-content"
                        onClick={async () => {
                          let errors = await CheckValidationForServicePage();

                          if (errors > 0) {
                            setItemsUpdated(true);
                          } else {
                            setChanged(true);

                            if (Number(selectedServiceId) === 0) {
                              createServiceId().then(() => {
                                setShowMspScoreModal(true);
                              });
                            } else {
                              setShowMspScoreModal(true);
                            }
                          }
                        }}
                      >
                        {"Align to the NIST CSF"}
                      </Button>
                    </div>
                  </>
                )} */}
                {serviceModalData?.LastUpdated_Text && (
                  <>
                    <hr />
                    <div className="d-flex f-darkgrey align-items-center mt-3">
                      Last edited by{" "}
                      <img
                        src={serviceModalData?.UserLogo}
                        alt=""
                        width={15}
                        height={15}
                        className="mx-2 rounded-full"
                      />
                      {serviceModalData?.LastUpdated_Text}
                    </div>
                  </>
                )}
              </>
            )}
          </>
        )}
        <MspScoreModal
          show={showMspScoreModal}
          updateRadarChart={updateRadarChart}
          hideModal={() => {
            setShowMspScoreModal(false);
          }}
          serviceId={selectedServiceId}
          selectedNistPiller={selectedNistPillar?.DDLText}
          HideMspSecopsLink={HideMspSecopsLink}
          hideServiceModal={() => {
            hideModal();
            cleanModal();
            setPriceChanged(false);
          }}
          showExploreMappingModal={showExploreMappingModal}
          setShowExploreMappingModal={setShowExploreMappingModal}
        />
        {/* <div>
          {showExploreMappingModal && (
            <ExploreMappingModal
              show={showExploreMappingModal}
              hideModal={() => {
                setShowExploreMappingModal(false);
              }}
            />
          )}
        </div> */}

        <div
          className={`add-edit-tax-rate-modal-wrapper ${showAddEditTaxRateModal ? "show" : ""
            }`}
        >
          <AddEditTaxRateModal
            show={showAddEditTaxRateModal}
            hideModal={() => {
              setShowAddEditTaxRateModal(false);
            }}
            taxRateData={taxRateData}
            updateTaxList={updateTaxList}
            deleteTaxRate={deleteTaxRate}
          />
        </div>

        <CustomDeleteModal
          show={warningModalConfig?.showWarningModal}
          deleteHeaderIcon={"/images/msp/yellow-exclammation.svg"}
          hideModal={() => {
            setWarningModalConfig((prev) => ({
              ...prev,
              showWarningModal: false,
            }));
          }}
          deleteButtonClass={"hg-blue-btn"}
          deleteButtonText={"Close"}
          deleteHeaderText={"Warning"}
          deleteBodyText={"Closing this modal will remove all changes."}
          deleteFooterText={"Are you sure you want to close this modal?"}
          deleteFunction={() => {
            hideModal();
            cleanModal();
            setPriceChanged(false);
          }}
          deleteParams={{}}
          deleteType={"warningClose"}
        />
      </SlidingModal.Body>
      <SlidingModal.Footer className="p-3 position-sticky w-100 border-top">
        <div className="d-flex align-items-center justify-content-between">
          <div>
            {selectedServiceId > 0 && (
              <div
                className="d-flex align-items-center f-500 f-grey pointer"
                onClick={() => {
                  // deleteService(selectedServiceId);
                  setShowDeleteModal(true);
                }}
              >
                <img
                  alt=""
                  className="mx-2"
                  src="/images/actioncenter/delete.svg"
                />
                {deletingService ? "Deleting.." : "Delete"}
              </div>
            )}
          </div>

          <div className="d-flex justify-content-end">
            <Button
              className="hg-cancel-btn mr-2"
              onClick={() => {
                if (!changed) {
                  hideModal();
                  setPriceChanged(false);
                  cleanModal();
                  setChanged(false);
                } else {
                  setWarningModalConfig((prev) => ({
                    ...prev,
                    showWarningModal: true,
                  }));
                }
              }}
            >
              Cancel
            </Button>
            <Button
              disabled={saveButtonDisabled()}
              className="hg-blue-btn"
              onClick={async () => {
                if (page === "home") {
                  let errors = await CheckValidationForServicePage();
                  if (errors > 0) {
                    setItemsUpdated(true);
                  } else if (!showRadar) {
                    setServicePageErrors((prev) => ({
                      ...prev,
                      selectedAlignNistError: true,
                    }));
                    setItemsUpdated(true);
                  } else {
                    if (priceChanged) {
                      setShowPriceModal(true);
                    } else {
                      setSaving(true);
                      createAddService()
                        .then(() => {
                          setSaving(false);
                          setChanged(false);
                          setServicePageErrors((prev) => ({
                            ...prev,
                            serviceToSeriesError: false,
                            serviceNotCreated: false,
                          }));
                          // if (!selectedServiceId) {
                          //   hideModal();
                          // }
                          getServiceData(selectedServiceId ?? 0);
                          CallToast(
                            "Updated successfully",
                            false,
                            false,
                            "v2style"
                          );
                          if (refreshData) refreshData();
                        })
                        .catch((err) => {
                          setSaving(false);
                          CallToast(
                            "Something went wrong",
                            false,
                            false,
                            "v2style"
                          );
                        });
                    }
                  }
                }
              }}
            >
              {saving ? (
                <>
                  {/* <WhiteLoader width={13} /> */}
                  Saving..
                </>
              ) : (
                "Save"
              )}
            </Button>
          </div>
          <DeleteModal
            show={showDeleteModal}
            hideModal={() => setShowDeleteModal(false)}
            deleteMessage={"Are you sure you want to delete this Service? "}
            deleteQuestionnaireTemplate={() => {
              deleteData(selectedServiceId);
            }}
            deleteType={"deleteQuestionnaireTemplate"}
          />
          <PriceChangedModal
            fromProducts={false}
            show={showPriceModal}
            setShowPriceModal={() => {
              setShowPriceModal(false);
            }}
            hideModal={(changeData) => {
              setShowPriceModal(false);
              setSaving(true);
              createAddService(changeData)
                .then(() => {
                  setSaving(false);
                  setChanged(false);
                  setServicePageErrors((prev) => ({
                    ...prev,
                    serviceToSeriesError: false,
                    serviceNotCreated: false,
                  }));
                  // if (!selectedServiceId) {
                  //   hideModal();
                  // }
                  getServiceData(selectedServiceId ?? 0);
                  CallToast(
                    "Updated successfully",
                    false,
                    false,
                    "v2style"
                  );
                  if (refreshData) refreshData();
                })
                .catch((err) => {
                  setSaving(false);
                  CallToast(
                    "Something went wrong",
                    false,
                    false,
                    "v2style"
                  );
                });
            }}
            productId={_selectedServiceId}
          />
        </div>
      </SlidingModal.Footer>
    </SlidingModal>
  );
};

export default MspAddEditServiceModal;

// export const SelectProductOrLabour = ({
//   productsData,
//   labourData,
//   children
// }) => {
//   const [IsDropdownOpen, setIsDropdownOpen] = useState(false);


//   return (
//     <Dropdown
//       onToggle={(e) => {
//         setIsDropdownOpen(e);
//       }}
//     >
//       <Dropdown.Toggle>
//         {children}
//       </Dropdown.Toggle>
//       <Dropdown.Menu>
//         test
//       </Dropdown.Menu>
//     </Dropdown>
//   );
// };

export const IncreaseDecreaseNumbersComponent = ({
  children,
  value,
  onChange,
  onClick,
  floatValue = false,
  inputClassName = '',
  divOnInputClassName = ''
}) => {
  const [count, setCount] = useState(
    value === 0 || value === "0.00" ? "" : value
  );
  const [isFocused, setIsFocused] = useState(false);
  const [changed, setChanged] = useState(false);

  const componentRef = useRef(null);

  useEffect(() => {
    if (changed) onChange(count);
  }, [count, changed]);

  const increaseCount = (e) => {
    e.stopPropagation();
    setCount((prevCount) => {
      const parsedCount = parseFloat(prevCount);
      return !isNaN(parsedCount)
        ? floatValue
          ? (parsedCount + 1).toFixed(2)
          : parsedCount + 1
        : floatValue
          ? "0.00"
          : "0";
    });
    setChanged(true);
  };

  const decreaseCount = (e) => {
    e.stopPropagation();
    setCount((prevCount) => {
      const parsedCount = parseFloat(prevCount);
      return !isNaN(parsedCount) && parsedCount > (floatValue ? 0.1 : 1)
        ? floatValue
          ? (parsedCount - 1).toFixed(2)
          : parsedCount - 1
        : floatValue
          ? "0.00"
          : "0";
    });
    setChanged(true);
  };

  const handleInputChange = (event) => {
    const val = event.target.value;

    const ch = onChange(val);
    if (ch) {
      setCount(val);
      setChanged(true);
    }
  };

  const handleKeyPress = (event) => {
    const allowedKeys = ["ArrowUp", "ArrowDown", "."];
    if (!allowedKeys.includes(event.key) && !/\d|\./.test(event.key)) {
      event.preventDefault(); // Prevent input if the key is not allowed
    }
    if (event.key === "ArrowUp") {
      increaseCount(event);
    } else if (event.key === "ArrowDown") {
      decreaseCount(event);
    }
  };

  const handleClickOutside = (event) => {
    if (componentRef.current && !componentRef.current.contains(event.target)) {
      setIsFocused(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div
      ref={componentRef}
      onClick={onClick}
      className="d-flex align-items-center"
    >
      <div
        className={`${false ? "input-error" : ""
          } ${divOnInputClassName == '' ? 'w-100 d-flex align-items-center radius-4 highlight-input p-2 m-1' : divOnInputClassName}`}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
      >
        {children && children}
        <input
          type="text"
          placeholder="0.00"
          value={count}
          onChange={handleInputChange}
          onKeyPress={handleKeyPress}
          className={`${inputClassName == '' ? 'bg-transparent border-none w-100' : inputClassName}`}
        />
        {isFocused && (
          <div className="ml-1 mr-1" style={{ margin: "-12px 0px" }}>
            <div
              onClick={(e) => increaseCount(e)}
              onMouseDown={(e) => e.preventDefault()}
            >
              <img src="/images/icons/arrowUp.svg" alt="" width={8} />
            </div>
            <div
              onClick={(e) => decreaseCount(e)}
              onMouseDown={(e) => e.preventDefault()}
            >
              <img src="/images/icons/arrowDown.svg" alt="" width={8} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export const allCountryCurrencies = [];

export const allTaxList = [
  {
    ObjectId: 0,
    TaxCode: "Not Taxable",
    TaxName: "Not Taxable",
    TaxRate: 0,
    IsDefault: true,
  },
  {
    ObjectId: 4128742,
    TaxCode: "VAT",
    TaxName: "VAT",
    TaxRate: 55,
    IsDefault: false,
  },
];
