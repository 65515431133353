import React, { useEffect, useState } from "react";
import ContentLoader from "react-content-loader";
import { getAPIData } from "../../../Utils/commonApi";
import { useAuth } from "../../context/Auth";
import { CallToast } from "../../PriceCalculator/modals/CustomToast";

const ComplianceQuestionnaire = ({ activePage }) => {
  const { authTokens } = useAuth();
  const [loading, setLoading] = useState(false);
  const [questionsList, setQuestionsList] = useState([]);
  const [details, setDetails] = useState([])

  const [questionType, setQuestionType] = useState("");

  useEffect(() => {
    document.getElementById("ptt").scrollTo(0, 0);
    setLoading(true);
    getPageData();
  }, [activePage]);

  async function getPageData() {
    await getAPIData(`${activePage?.data?.pageEnum}`, authTokens, {
      optionEnum1: activePage?.data?.entityId, //3187036
      optionEnum2:3,// activePage?.data?.accessLevel,
      // optionEnum3: activePage?.data?.accessLevel,
      // optionEnum2: 1,
      // optionEnum3: activePage?.data?.accessLevel,
    })
      .then((response) => {
        setLoading(false);
        setQuestionType(response?.PageTitle);
        let updated_data = [];
        console.log(response)

        if (response?.mr?.Success) {
          for (
            let i = 0;
            i < response?.AuditSection?.length;
            i++
          ) {
            if(response?.AuditSection[i]?.Title?.toLowerCase()==="detailsxxxx" || i===0){
              setDetails(response?.AuditSection[i]?.Question_List)
              continue;

            }
            for (
              let j = 0;
              j < response?.AuditSection[i]?.Question_List?.length;
              j++
            ) {
              updated_data.push({
                QuestionText:
                  response?.AuditSection?.[i]?.Question_List?.[j]
                    ?.QuestionText,
                AnswerText:
                  response?.AuditSection?.[i]?.Question_List?.[j]
                    ?.AnswerText,
              });
            }
          }
          setQuestionsList(updated_data);
        } else {
          CallToast("Something went wrong", false, false, "v2style");
        }
      })
      .catch(() => {
        CallToast("Something went wrong", false, false, "v2style");
      });
  }

 

  return (
    <div className="d-flex w-100 px-5 pt-4 justify-content-between  " id="ptt">
      {/* Left part */}
      {loading ? (
        <ScreenLoader />
      ) : (
        <>
          <div className="hg-border radius-16 p-4" style={{ width: "67%" }}>
            <div className="mb-4">
              <span className="f-16 f-500">{questionType} Procedure</span>
              &nbsp;&nbsp;&nbsp;
              <span className="f-darkgrey">Items: {questionsList?.length}</span>
            </div>
            <div className="ml-3">
              {/* Questions/Answers List */}
              {questionsList.map((question, questionIndex) => {
                return (
                  <div className="mb-5">
                    {/* Question */}
                    <div className="d-flex align-items-center">
                      <img
                        alt=""
                        className="mr-2"
                        src="/images/supply-chain/question-tick.svg"
                      />
                      <div className="f-14 f-500 mx-1">
                        {" "}
                        {questionIndex + 1}. {question?.QuestionText}
                      </div>
                      <img
                        alt=""
                        className="ml-1"
                        src="/images/supply-chain/grey-info.svg"
                      />
                    </div>
                    {/* Answer */}
                    <div className="ml-5 my-3">
                      <input
                        type="text"
                        disabled
                        className="border-none w-100 bg-transparent-imp"
                        placeholder=" "
                        value={question?.AnswerText}
                        onChange={(e) => {
                          // let updated_data = [...questionList];
                          // updated_data[questionIndex].answer = e.target.value;
                          // setQuestionList(updated_data);
                        }}
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          </div>

          {/* Right part */}
          <div
            className="hg-border radius-16 p-4"
            style={{ width: "32%", height: "fit-content" }}
          >
            <div className="mb-4">
              <span className="f-16 f-500">{questionType} Details</span>
              &nbsp;&nbsp;&nbsp;
              <span className="f-darkgrey">Items: {details?.length}</span>
            </div>

            <div className="ml-3">
              {
                details?.map((detail,detailIndex)=>{
                  return (<div className="mb-3">
                  <span className="f-darkgrey">{detail?.QuestionText}:</span>&nbsp;&nbsp;
                  <span className="f-black f-500">{detail?.AnswerText}</span>
                </div>

                  )
                })
              }
              {/* <div className="mb-3">
                <span className="f-darkgrey">Audit Date:</span>&nbsp;&nbsp;
                <span className="f-black f-500">04/10/2020</span>
              </div>
              <div className="mb-3">
                <span className="f-darkgrey">Audit Frequency:</span>&nbsp;&nbsp;
                <span className="f-black f-500">Annual</span>
              </div>
              <div className="mb-3">
                <span className="f-darkgrey">Cyber Essentials Type:</span>
                &nbsp;&nbsp;
                <span className="f-black f-500">Plus</span>
              </div>
              <div className="mb-3">
                <span className="f-darkgrey">Auditor:</span>&nbsp;&nbsp;
                <span className="f-black f-500">Jane Cooper</span>
              </div> */}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default ComplianceQuestionnaire;

const ScreenLoader = () => {
  return (
    <ContentLoader
      speed={2}
      width={"100%"}
      height={"100%"}
      viewBox="0 0 1500 2000"
      backgroundColor="#F6F8FB"
      foregroundColor="#ecebeb"
    >
      <rect x="2%" y="20" rx="4" ry="4" width="16%" height="20" />

      <rect x="6%" y="75" rx="4" ry="4" width="55%" height="20" />
      <rect x="6%" y="125" rx="4" ry="4" width="30%" height="20" />

      <rect x="6%" y="195" rx="4" ry="4" width="55%" height="20" />
      <rect x="6%" y="245" rx="4" ry="4" width="35%" height="20" />

      <rect x="6%" y="315" rx="4" ry="4" width="55%" height="20" />
      <rect x="6%" y="365" rx="4" ry="4" width="25%" height="20" />

      <rect x="6%" y="435" rx="4" ry="4" width="55%" height="20" />
      <rect x="6%" y="485" rx="4" ry="4" width="45%" height="20" />

      <rect x="6%" y="555" rx="4" ry="4" width="55%" height="20" />
      <rect x="6%" y="605" rx="4" ry="4" width="35%" height="20" />

      {/* Right Part */}

      <rect x="75%" y="20" rx="4" ry="4" width="10%" height="20" />

      <rect x="78%" y="65" rx="4" ry="4" width="15%" height="20" />
      <rect x="78%" y="110" rx="4" ry="4" width="20%" height="20" />

      <rect x="78%" y="155" rx="4" ry="4" width="15%" height="20" />

      <rect x="78%" y="200" rx="4" ry="4" width="20%" height="20" />
    </ContentLoader>
  );
};
