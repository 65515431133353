import { useState, useEffect, useRef } from 'react';

const useDropdown = ({ disableClickOutside }) => {
  const [isOpen, setIsOpen] = useState(false);
  const refMenu = useRef(null);
  const refBtn = useRef(null);

  const handleClickOutside = (event) => {
    if (!refMenu.current || !refBtn.current) return;
    if (!refBtn.current.contains(event.target) && !refMenu.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    if (disableClickOutside) return;
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [disableClickOutside]);

  return {
    isOpen,
    setIsOpen,
    refMenu,
    refBtn,
  };
};

export default useDropdown;
