import React from "react";
import ContentLoader from "react-content-loader";

const BillingSubscriptionLoader = () => {
  return (
    <ContentLoader
      speed={2}
      width={"100%"}
      height={"100%"}
      viewBox="0 0 1500 900"
      backgroundColor="#F6F8FB"
      foregroundColor="#ecebeb"
    >
      <rect x="0" y="0" rx="4" ry="4" width="1500" height="198" />
      <rect x="0" y="203" rx="4" ry="4" width="1500" height="198" />
      <rect x="0" y="403" rx="4" ry="4" width="1500" height="198" />

      <rect x="0" y="603" rx="4" ry="4" width="1500" height="198" />
    </ContentLoader>
  );
};

export default BillingSubscriptionLoader;
