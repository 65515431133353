import React from "react";
import "./mspCalculatorDropdownAndModals.scss";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const MspComponents = ({
  componentsData = []
}) => { 

  return (
    <div className="grey-container mt-3">
      {
        componentsData?.length > 0 ? <div>
          <div className="d-flex align-items-center">
            <div className="f-500 f-14 f-darkgrey line-height-22 letter-space-2 w-50">Component</div>
            <div className="f-500 f-14 f-darkgrey line-height-22 letter-space-2 w-50">Products</div>
          </div>
          <div>
            {
              componentsData?.map((componentDataItem) => {
                return (
                  <div className="d-flex align-items-top">
                    <div
                      className="d-flex justify-content-between mt-1 w-50"
                      style={{
                        height: '22px'
                      }}
                    >
                      <div className="f-400 f-14 f-black line-height-22 letter-space-2">{componentDataItem?.ContributionName}</div>
                      <img
                        alt=""
                        id=""
                        className="mr-3"
                        width={11}
                        src="/images/attack-surface/right-arrow.svg"
                      />
                    </div>
                    <div className="">
                      {
                        componentDataItem?.List_ScoreProducts?.map((productItem, productIndex) => {
                          return (
                            <div className="d-flex align-items-center bg-greyish radius-2 p-1 mt-1 w-fit-content">
                              <OverlayTrigger
                                trigger={["hover", "focus"]}
                                key={productIndex}
                                placement={"bottom"}
                                overlay={
                                  <Tooltip id={`tooltip-1365`}>
                                    {
                                      productItem?.List_ProductTool?.map((tooltip) => {
                                        return (
                                          <div className="d-flex align-items-center">
                                            <img
                                              alt=""
                                              id=""
                                              width={16}
                                              className="mr-2 rounded-full"
                                              // src={tooltip?.ToolIcon}
                                              src={`/images/attack-surface/integrations/${tooltip?.ObjId_Tool}.webp`}
                                            />
                                            <div>{tooltip?.ToolName}</div>
                                          </div>
                                        )
                                      })
                                    }
                                  </Tooltip>
                                }
                              >
                                <div>
                                  {
                                    productItem?.List_ProductTool?.length > 3 ? <div className="d-flex align-items-center">
                                      {
                                        productItem?.List_ProductTool?.slice(0, 3)?.map((toolItem, iconIndex) => {
                                          return (
                                            <img
                                              alt=""
                                              id=""
                                              width={16}
                                              className="rounded-full"
                                              style={{
                                                marginLeft: iconIndex != 0 && "-6px"
                                              }}
                                              // src={toolItem?.ToolIcon}
                                              src={`/images/attack-surface/integrations/${toolItem?.ObjId_Tool}.webp`}
                                            />
                                          )
                                        })
                                      }
                                      <div
                                        className="d-flex align-items-center justify-content-center f-500 f-9 f-black line-height-12 rounded-full bg-dark-greyish"
                                        style={{
                                          width: '16px',
                                          height: '16px',
                                          marginLeft: '-6px'
                                        }}
                                      >
                                        + {productItem?.List_ProductTool?.length - 3}
                                      </div>
                                    </div> : <div className="d-flex align-items-center">
                                      {
                                        productItem?.List_ProductTool?.map((toolItem, iconIndex) => {
                                          return (
                                            <img
                                              alt=""
                                              id=""
                                              width={16}
                                              className="rounded-full"
                                              style={{
                                                marginLeft: iconIndex != 0 && "-6px"
                                              }}
                                              // src={toolItem?.ToolIcon}
                                              src={`/images/attack-surface/integrations/${toolItem?.ObjId_Tool}.webp`}
                                            />
                                          )
                                        })
                                      }
                                    </div>

                                  }
                                </div>
                              </OverlayTrigger>
                              <div className="f-500 f-12 f-black line-height-18 letter-space-2 ml-1">
                                {productItem?.ProductName}
                              </div>
                            </div>
                            // <div className="d-flex align-items-center bg-greyish radius-2 p-1 mt-1 w-fit-content">
                            //   {
                            //     productItem?.List_ProductTool?.length > 3 ? <div className="d-flex align-items-center">
                            //       {
                            //         productItem?.List_ProductTool?.slice(0, 3)?.map((toolItem, iconIndex) => {
                            //           return (
                            //             <img
                            //               alt=""
                            //               id=""
                            //               width={16}
                            //               className=""
                            //               style={{
                            //                 marginLeft: iconIndex != 0 && "-6px"
                            //               }}
                            //               src={toolItem?.ToolIcon}
                            //             />
                            //           )
                            //         })
                            //       }
                            //       <div
                            //         className="d-flex align-items-center justify-content-center f-500 f-9 f-black line-height-12 rounded-full bg-dark-greyish"
                            //         style={{
                            //           width: '16px',
                            //           height: '16px',
                            //           marginLeft: '-6px'
                            //         }}
                            //       >
                            //         + {productItem?.List_ProductTool?.length - 3}
                            //       </div>
                            //     </div> : <div className="d-flex align-items-center">
                            //       {
                            //         productItem?.List_ProductTool?.map((toolItem, iconIndex) => {
                            //           return (
                            //             <img
                            //               alt=""
                            //               id=""
                            //               width={16}
                            //               className=""
                            //               style={{
                            //                 marginLeft: iconIndex != 0 && "-6px"
                            //               }}
                            //               src={toolItem?.ToolIcon}
                            //             />
                            //           )
                            //         })
                            //       }
                            //     </div>

                            //   }
                            //   <div className="f-500 f-12 f-black line-height-18 letter-space-2 ml-1">
                            //     {productItem?.ProductName}
                            //   </div>
                            // </div>
                          )
                        })
                      }
                    </div>
                  </div>
                )
              })
            }
          </div>
        </div> : <div className="d-flex align-items-center justify-content-center w-100 f-400 f-12 f-darkgrey my-3">
          No data for Components yet
        </div>
      }
    </div>
  );
};

export default MspComponents;
