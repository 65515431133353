import { useEffect, useState } from "react";
import useApiActions from "../../hooks/api-actions.hook";

const useMspClientDashboardData = (customerId) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { getClientsDashboardV2 } = useApiActions();

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const data = await getClientsDashboardV2(customerId);
        setData(data.detail);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [customerId]);

  return { data, loading, error };
}

export default useMspClientDashboardData;
