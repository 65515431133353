import React, { useState, useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
 
import { postData } from "../../../Utils/commonApi";
import { useAuth } from "../../context/Auth";
import { CallToast } from "../../PriceCalculator/modals/CustomToast";
import { WhiteLoader } from "../../Theme/APILoaders";
import { AttackSupplierServiceDropdown } from "../AttackComponents/MultiSelectDropdown";
import "./suppliers.scss";

const AddSupplierModal = ({ show, hideModal, refreshData, _supplierName }) => {
  const { authTokens } = useAuth();
  const [processing, setProcessing] = useState(false);
 
  const [supplierData, setSupplierData] = useState({
    supplierName: "",
    supplierEmail: "",
    supplierWebsite: "",
    supplierServicesProvided: [],
    nameError: "",
    emailError: "",
    serviceError: "",
  });

  useEffect(() => {
    if (show) {
      setSupplierData({
        supplierName: _supplierName ?? "",
        supplierEmail: "",
        supplierWebsite: "",
        supplierServicesProvided: [],
        nameError: "",
        emailError: "",
        serviceError: "",
      });
    }
  }, [show]);

  function createPotentialSupplier() {
    setProcessing(true);
    postData(authTokens, "CreatePotentialSupplier", {
      AccessKey: JSON.parse(localStorage.getItem("user_MessageObj")),
      ObjId_PotentialSupplier: 0,
      Email: supplierData?.supplierEmail,
      SupplierName: supplierData?.supplierName,
      Website: supplierData?.supplierWebsite,
      ServicesProvided: JSON.stringify(supplierData?.supplierServicesProvided),
      Type: 1,
    })
      .then((response) => {
        setProcessing(false);
        if (response?.Success) {
          hideModal();
          if (refreshData) refreshData(response?.EntityId);
          CallToast("Supplier created successfully", false, false, "v2style");
        } else {
          CallToast("Something went wrong", false, false, "v2style");
        }
      })
      .catch((err) => {
        CallToast("Something went wrong", false, false, "v2style");
        setProcessing(false);
        console.log(err);
      });
  }

  return (
    <Modal
      animation={false}
      show={show}
      dialogClassName="access-level-modal add-supplier-modal"
      className=" "
      aria-labelledby="access-level-modal"
      centered
      scrollable
      onHide={hideModal}
    >
      <Modal.Header className="text-center">
        {/* <img
          alt=""
          src="/images/supply-chain/chevron-left.svg"
          className="mt-2 pointer"
          onClick={() => {}}
        /> */}

        <div className="w-100 mb-0 f-500 f-black">Add Supplier </div>

        <span className="cursor-pointer" onClick={hideModal}>
          <img
            alt=""
            width={11}
            height={11}
            src="/images/icons/close.svg"
            className="close-icon"
          />
        </span>
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex flex-column w-100">
          {/* <div className="d-flex flex-column align-items-center justify-content-center pt-3">
            <img
              src="/images/attack-surface/add-supplier-ase.svg"
              alt=""
              className=""
            />

            <span className="d-inline-flex align-items-center f-12 f-500 f-grey mt-3 bg-grey radius-2 p-1">
              <img
                alt=""
                src="/images/attack-surface/ase-icon.svg"
                className="mr-1"
              />
              Attack Surface
            </span>
          </div>
          <hr /> */}
          {/* Supplier Name */}
          <div
            className={`supplier-input-wrapper ${
              supplierData?.nameError ? "error-field" : ""
            } bg-grey w-100  d-flex align-items-center p-2 radius-4 mt-2   ${true} `}
          >
            <div className="f-darkgrey">Name:</div>
            <input
              tabIndex={0}
              type="text"
              placeholder="Supplier Name"
              className="flex-grow-1 pl-3 pr-2"
              value={supplierData?.supplierName}
              onChange={(e) => {
                setSupplierData({
                  ...supplierData,
                  supplierName: window.sanitizeHGtext(e.target.value),
                  nameError: "",
                });
              }}
            />
          </div>

          {/* Supplier Email */}
          <div
            className={`supplier-input-wrapper ${
              supplierData?.emailError ? "error-field" : ""
            } bg-grey w-100  d-flex align-items-center p-2 radius-4 mt-2   ${true} `}
          >
            <div className="f-darkgrey">Email:</div>
            <input
              tabIndex={0}
              type="text"
              placeholder="Supplier email"
              className="flex-grow-1 pl-3 pr-2"
              value={supplierData?.supplierEmail}
              onChange={(e) => {
                let value = e.target.value;
                if (
                  value.match(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/)
                ) {
                  setSupplierData((prev) => ({
                    ...prev,
                    supplierWebsite: `www.${value?.split("@")[1]}`,
                    supplierEmail: value,
                    emailError: "",
                  }));
                } else {
                  setSupplierData((prev) => ({
                    ...prev,
                    supplierEmail: value,
                    emailError: "",
                  }));
                }
              }}
            />
          </div>

          {/* Supplier Website: */}
          <div
            className={`supplier-input-wrapper bg-grey w-100  d-flex align-items-center p-2 radius-4 mt-2   ${true} `}
          >
            <div className="f-darkgrey">Website:</div>
            <input
              tabIndex={0}
              type="text"
              placeholder="https://example.com"
              className="flex-grow-1 pl-3 pr-2"
              value={supplierData?.supplierWebsite}
              onChange={(e) => {
                setSupplierData({
                  ...supplierData,
                  supplierWebsite: e.target.value,
                });
              }}
            />
          </div>

          {/* Supplier Name */}
          <div
            className={`supplier-input-wrapper ${
              supplierData?.serviceError ? "error-field" : ""
            } bg-grey w-100  d-flex align-items-center px-2 radius-4 mt-2   ${true} `}
          >
            <div className="f-darkgrey">Services:</div>
            {/* <input
              tabIndex={0}
              type="text"
              placeholder="Add"
              className="flex-grow-1 pl-3 pr-2"
              value={""}
              onChange={(e) => {}}
            /> */}
            <AttackSupplierServiceDropdown
              placeholder={"Add"}
              selected={supplierData?.supplierServicesProvided}
              onChange={(e) => {
                setSupplierData({
                  ...supplierData,
                  supplierServicesProvided: e,
                  serviceError: "",
                });
              }}
              data={[]}
            />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-end">
        <Button className="hg-cancel-btn" onClick={hideModal}>
          Cancel
        </Button>
        <Button
          className="hg-blue-btn ml-2"
          onClick={() => {
            if (supplierData?.supplierName === "") {
              setSupplierData({
                ...supplierData,

                nameError: "error",
              });
              return;
            }
            if (supplierData?.supplierEmail === "") {
              setSupplierData({
                ...supplierData,

                emailError: "error",
              });
              return;
            }
            if (supplierData?.supplierServicesProvided?.length === 0 ) {
              setSupplierData({
                ...supplierData,

                serviceError: "error",
              });
              return;
            }
            createPotentialSupplier();
          }}
        >
          {processing ? (
            <>
              <WhiteLoader width={12} />
              Adding..
            </>
          ) : (
            "Add supplier"
          )}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddSupplierModal;
