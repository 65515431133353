import { useState } from 'react';
import useApiActions from '../../hooks/api-actions.hook';

const useMspTechnologySpendData = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const { getTechnologySpend } = useApiActions();

  const fetchTechnologySpend = async ({ customerId, fromDate, toDate }) => {
    if (!fromDate || !toDate) return;
    setLoading(true);
    getTechnologySpend({
      customerId,
      fromDate,
      toDate,
    }).then((response) => {
      setData(response);
    }).catch((error) => {
      console.error('Error fetching technology spend', error);
    }).finally(() => {
      setLoading(false);
    });

  }

  return { data, loading, fetchTechnologySpend };
}

export default useMspTechnologySpendData;
