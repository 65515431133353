import { createSelector } from "reselect";

/**
 * Select the portion of the root reducer
 */
const globalReducer = () => (state) => state.global;

/**
 * Get List of Ad Spend Metrics
 *
 * @return {Array}
 */
export const getMenuCollapseStatus = () =>
  createSelector(globalReducer(), (state) => state.menuCollapse);
