import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Col, Row } from "react-bootstrap";

import "../../WebFilteringBoxes/WebFilteringBoxes";
import { AttestationPopUp } from "../../AttackSurface/KPIpopup/KPIpopup";

const WebFilteringBoxes = (props) => {
  const [text] = useState(props.mainText || "");
  const [icon] = useState(props.icon || "");
  const [bodyText] = useState(props.bText || "");
  const [percentText] = useState(props.pText || "");
  const [linkText] = useState(props.linkText || "");
  const [linkRoute] = useState(props.linkRoute || "");
  const [statusText] = useState(props.status || "");
  const [statusTextColor] = useState(props.statusColor || "");
  const [packageText] = useState(props.packageText || "");
  const [packageColor] = useState(props.packageColor || "");
  const [packageIcons] = useState(props.packageIcon || "");
  const [backgroundColor] = useState(props.bgColor || "");
  const [isUnderScore] = useState(props.showUnderScore || "");
  const [date] = useState(props.dateText || "");
  const ALIGN = props.align || "right";
  const [bodyTextColor] = useState(props.bTextBolor || "");
  const UNDERLINE = props.underline || false;

  const TextColumn = (
    <Col
      xs={12}
      md={6}
      lg={6}
      className="mx-auto my-auto"
      style={{ paddingLeft: "2em" }}
    >
      <div>
        <div className="d-flex">
          <img alt="" src={packageIcons} style={{ paddingRight: "5px" }}></img>
          <p
            className="mb-0"
            style={{ color: packageColor ? packageColor : "" }}
          >
            {packageText}
          </p>
        </div>
        <div className="heading3 mt-0" style={{ textAlign: "left" }}>
          {text}
        </div>
        {/* {linkText ?
        <p className="link_textCol"><Link to={linkRoute}>{linkText} {ALIGN ? ">" : ""}</Link></p>

        :

        ""} */}
      </div>
    </Col>
  );
  const DataColumn = (
    <Col md={6} style={{ padding: "8px" }}>
      <div className="mx-auto my-auto" style={{ height: "100%" }}>
        <Col
          md={12}
          className="webfiltering_background"
          style={{
            backgroundColor: backgroundColor
              ? backgroundColor.replace(";", "")
              : "",
            padding: percentText !== "" ? "5px 0" : "1em 0",
          }}
        >
          <div className="w-100">
            <span style={{ paddingBottom: "0.5em" }}>
              {" "}
              <img
                alt=""
                src={icon}
                style={{
                  borderRadius: "50%",
                  padding: "5px",
                  background: isUnderScore ? statusTextColor + 20 : "",
                }}
              ></img>
            </span>
            <span
              style={{
                paddingBottom: "0.5em",
                paddingRight: "8px",
              }}
            >
              <h3
                className="drilldownSideText"
                style={{
                  color: bodyTextColor ? bodyTextColor : "black",
                }}
              >
                {bodyText}
              </h3>
            </span>
            <span style={{ paddingBottom: "0.5em" }}>
              {" "}
              {isUnderScore ? (
                <img
                  alt=""
                  src="/images/complianceManager/sepratorIcom.svg"
                ></img>
              ) : (
                ""
              )}
            </span>
            <span>
              {" "}
              <h3
                className="percentText"
                style={{
                  color: statusTextColor ? statusTextColor : "",
                  width: "90%",
                  padding: 0,
                  textAlign: "center",
                }}
              >
                {statusText}
              </h3>
            </span>

            <span>
              <h3
                title={percentText}
                className="percentText  text-center truncateText pt-2"
                style={{ width: "90%", padding: 0, textAlign: "center" }}
              >
                {percentText}
              </h3>
            </span>

            <span>
              <h3
                className="percentText text-center"
                title={date}
                style={{ width: "90%", padding: 0, textAlign: "center" }}
              >
                {window.innerWidth > 1680
                  ? date.slice(0, 90) + (date.length > 90 ? "..." : "")
                  : date.slice(0, 70) + (date.length > 70 ? "..." : "")}
              </h3>
            </span>
          </div>
        </Col>
      </div>
    </Col>
  );

 
  return (
    <Link to={linkRoute}>
      <Row className="webFilteringBox position-relative">
        {ALIGN === "left" ? (
          <React.Fragment>
            {DataColumn}
            {TextColumn}
          </React.Fragment>
        ) : (
          <React.Fragment>
            {TextColumn}
            {DataColumn}
          </React.Fragment>
        )}
        <div className="w-fit-content position-absolute"
        style={{
          left:"15px",
          bottom:"15px"
        }}
        
        >
               <AttestationPopUp
               data={ComplianceAttestationStateData}
                    >
                    <img
                      alt=""
                      src={`${Attestation_Icon_Mappings[props?.data?.CBAttestation]}`}
                      className=""
                    />
                    </AttestationPopUp>

        </div>
      </Row>
    </Link>
  );
};

export default WebFilteringBoxes;



export const ComplianceAttestationStateData = [
  {
    image: "/images/attack-surface/verified.svg",
    title: "Verified",
    subtitle:
      "All required documents have been uploaded and/or reviews/audits have been completed for all.",
  },
  {
    image: "/images/attack-surface/part-verified.svg",
    title: "Part-Verified",
    subtitle:
      "There is a combination of verified and attested data.",
  },
  {
    image: "/images/attack-surface/unverified.svg",
    title: "Attested",
    subtitle:
      "Answers have been attested but documents have not been uploaded or reviews/audits have not been completed yet.",
  },
  {
    image: "/images/attack-surface/initial-verified.svg",
    title: "Initial State",
    subtitle:
      "No information has been uploaded or attested yet.",
  },
];


export const Attestation_Icon_Mappings={
  0:"/images/attack-surface/initial-verified.svg",
  1:"/images/attack-surface/unverified.svg",
  2:"/images/attack-surface/part-verified.svg",
  3:"/images/attack-surface/verified.svg",
  4:"/images/attack-surface/part-verified.svg",
}
export const Attestation_Icon_Text_Mappings={
  'NotAnswered':"/images/attack-surface/initial-verified.svg",     //0
  'NoCapability':"/images/attack-surface/unverified.svg",           // 1
  'UnVerified':"/images/attack-surface/part-verified.svg",         //2
  'Verified':"/images/attack-surface/verified.svg",              //3
  'PartVerified':"/images/attack-surface/part-verified.svg",         //4
}