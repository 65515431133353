import React, { useState, useEffect } from "react";
import { Row, Col, Button } from "react-bootstrap";
import { postData } from "../../../../Utils/commonApi";
import { useAuth } from "../../../context/Auth";
import { CallToast } from "../../../PriceCalculator/modals/CustomToast";

import "../actionCentreModals.scss";
import Loader from "../../../Common/loader";
import { WhiteLoader } from "../../../Theme/APILoaders";
import { TrackUser } from "../../../../Utils/SegmentFunctions";
import { updateAccessKeyInLocalStorage } from "../../../../Utils/CommonFunctions";
const APIconnection = ({ setModalTab, closeModal, toolData }) => {
  const { authTokens } = useAuth();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [modalData, setModalData] = useState([]);
  const [keyValue, setKeyValue] = useState({});

  useEffect(() => {
    let updated_data = [...toolData?.ConnectionStrings].map((item, index) => {
      return {
        ...item,
        error: false,
        value: "",
      };
    });
    setModalData(updated_data);
  }, [toolData]);

  function ConnectTool(params) {
    for (let i = 0; i < modalData.length; i++) {
      if (modalData[i].value === "") {
        let updated_data = [...modalData];
        updated_data[i].error = true;
        setModalData(updated_data);
        return;
      }
    }

    connectIntegration(params);
  }

  async function connectIntegration(keyvalue) {
    setLoading(true);
    await postData(authTokens, "SubmitIntegrationKeys", {
      ...keyvalue,
      IntegrationId: toolData?.toolId,
      TestOrCreate: 3,
    })
      .then((response) => {
        setLoading(false);
        if (response?.mr?.Success) {
          updateAccessKeyInLocalStorage(response?.mr?.Message)
         
          TrackUser(`Integrated Action Center ${toolData?.toolName} Tool`);
          setModalTab();
        } else {
          CallToast(response?.mr?.Message, false, false, "v2style");
        }
      })
      .catch((err) => {
        setLoading(false);
        CallToast("Something went wrong", false, false, "v2style");
      });
  }

  useEffect(() => {}, []);

  return (
    <Row className="m-0 h-100">
      {loading && false && (
        <Col
          md={12}
          className="d-flex align-items-center justify-content-center"
          style={{
            position: "absolute",
            top: "40%",
            left: 0,
            right: 0,
            margin: "auto",
          }}
        >
          <Loader />
        </Col>
      )}
      <Col md={12} className={`p-0 mt-1  ${loading ? "" : ""} `}>
        <div className="f-black f-500 my-3">Sync {toolData?.toolName} task</div>

        {modalData?.map((item, index) => {
          return (
            <div
              className="asset-name-wrapper d-flex align-items-center p-2 radius-4 mb-3"
              style={{
                border: `1px solid ${item?.error ? "#F36060" : "#ebedf1"}`,
              }}
            >
              <div className={`${item?.error ? "f-danger" : "f-darkgrey"} `}>
                {item?.Label}:
              </div>
              <input
                autoComplete="new-password"
                type={`${item?.ShowText ? "text" : "password"}`}
                placeholder=""
                name={`KeyString${item?.KeyNumber}`}
                className="w-75 pl-3"
                style={{ border: "none" }}
                value={item.value}
                onChange={(e) => {
                  let updated_data = [...modalData];
                  updated_data[index].error = false;
                  updated_data[index].value = e.target.value;
                  setModalData(updated_data);

                  setKeyValue({
                    ...keyValue,
                    [e.target.name]: e.target.value,
                  });
                }}
              />
            </div>
          );
        })}

        {error && (
          <div className="d-flex mt-4 align-items-start">
            <img
              src="/images/settings-v2/red-cancel.svg"
              alt=""
              className="mr-3"
            />
            <div>
              <p className="f-danger m-0">
                Failed to connect. Please try again or repeat later
              </p>
              <p className="m-0">
                <span className="f-darkgrey">Response: </span>
                <span className="f-grey">Error 505 - Failed to connect</span>
              </p>
            </div>
          </div>
        )}
      </Col>

      <div className="modal_custom_footer">
        <div className="crsrpoint mt-2">
          <img
            src="/images/actioncenter/reset.svg"
            height="15"
            className="d-none"
            alt=""
          />
          <span className="d-none" style={{ color: "#616778" }}>
            &nbsp; Delete Integration
          </span>
        </div>
        <div>
          <Button
            variant="primary"
            type="submit"
            className="float-right securityBtnmodal"
            onClick={() => {
              // if (error) {
              //   setModalTab();
              // }
              ConnectTool(keyValue);
            }}
          >
            {loading ? (
              <span className="d-inline-flex align-items-center">
                <WhiteLoader width={13} />
                Connecting...
              </span>
            ) : (
              "Connect"
            )}
          </Button>
          <Button
            variant="primary"
            type="submit"
            className="float-right action_cancel_btn mr-2"
            onClick={closeModal}
          >
            Cancel
          </Button>
        </div>
      </div>
    </Row>
  );
};

export default APIconnection;
