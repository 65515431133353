import { useEffect, useState } from "react";
import { BASE_API_PAGE_ENUMS } from "../../constants/apis.constants";
import { useAuth } from "../context/Auth";
import { getAPIData } from "../../Utils/commonApi";

const useMspCyberSecurityPostureData = (customerId) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const { authTokens } = useAuth();

  const getCyberSecurityPosture = async () => {
    setLoading(true);
    return getAPIData(BASE_API_PAGE_ENUMS.CyberSecurityPosture, authTokens, {
      optionEnum1: customerId
    }).then((response) => {
      setData(response);
    });
  }

  useEffect(() => {
    getCyberSecurityPosture().finally(() => {
      setLoading(false);
    });
  } ,[customerId]);

  return {
    data,
    loading,
  }
}

export default useMspCyberSecurityPostureData;
