
import React, { useEffect } from "react";
import { Button, Dropdown } from "react-bootstrap";
import { useState } from "react";
import SlidingModal from "../../../CustomModal/SlidingModal";
import Loader from "../../../Common/loader";
import { useAuth } from "../../../context/Auth";
import { getAPIData, getRequestOptions } from "../../../../Utils/commonApi";
import { CallToast } from "../../../PriceCalculator/modals/CustomToast";
import SwitchButtonMulti from "../../../Theme/switchButtonMulti";
import AddResourceModal from "./AddResource";
import { CustomDeleteModal } from "../../../DeleteModal/DeleteModal";
import './securityBaseline.scss'

const AddEditSecurityBaselineItemModal = ({
  show,
  hideModal,
  parentID,
  questionId,
  setQuestionId = () => { },
  deleteQuestion = () => { },
  passedOptionEnum99
}) => {
  const { authTokens } = useAuth();
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [showAddResourceModal, setShowAddResourceModal] = useState(false);
  const [questionData, setQuestionData] = useState({
    ObjectId: 0,
    Title: '',
    Description: '',
    InternalNote: '',
    IsShowExternalReport: false,
    MSPBaselineQuestionFiles_List: []
  })
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedFileOrLinkId, setSelectedFileOrLinkId] = useState(0);
  const [selectedFileOrLink, setSelectedFileOrLink] = useState(null);
  const [filesAndLinksData, setFilesAndLinksData] = useState([])
  const [changes, setChanges] = useState({
    Title: null,
    Description: null,
    InternalNote: null,
    IsShowExternalReport: null
  })
  const [isPrevSave, setIsPrevSave] = useState(false);
  const [titleErr, setTitleErr] = useState(false);
  const [descriptionErr, setDescriptionErr] = useState(false);

  useEffect(() => {
    if (show) {
      if (questionId != 0) {
        getQuestion(questionId)
      }
    }
  }, [show]);


  async function getQuestion(questionId) {
    await getAPIData("693", authTokens, {
      optionEnum1: questionId
    })
      .then((response) => {
        if (response) {
          if (Object.values(changes).some(value => value !== null)) {
            let data = response;
            Object.entries(changes).forEach(([key, value]) => {
              if (value != null) {
                data[key] = value;
              }
            });
            setQuestionData(data);
          } else {
            setQuestionData(response)
          }
          setFilesAndLinksData(response?.MSPBaselineQuestionFiles_List)
        } else {
          CallToast("Something went wrong", false, false, "v2style");
        }
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        CallToast("Something went wrong", false, false, "v2style");
        setLoading(false);
      });
  }

  async function deleteFileOrLink(id) {
    await getAPIData("696", authTokens, {
      optionEnum1: id,
      optionEnum2: 0
    })
      .then((response) => {
        if (response?.Success) {
          CallToast("Deleted successfully", false, false, "v2style");
          setLoading(false);
        } else {
          CallToast("Something went wrong", false, false, "v2style");
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
        CallToast("Something went wrong", false, false, "v2style");
        setLoading(false);
      });
  }

  async function handleSave(isPrevSaveing = false) {
    const errorsCount = handleValidation();
    if (errorsCount > 0) {
      return;
    } else {
      if (!isPrevSaveing) {
        setSaving(true);
      }
      try {
        const data = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}/CC_API/CreateUpdateMSPBaseLineQuestion`,
          getRequestOptions(JSON.parse(localStorage.getItem("tokens")), "POST", {
            ObjectId: questionId ?? 0,
            Title: questionData?.Title,
            Description: questionData?.Description,
            InternalNote: questionData?.InternalNote,
            IsShowExternalReport: questionData?.IsShowExternalReport ?? false,
            accessKey: JSON.parse(localStorage.getItem("user_MessageObj"))
          })
        )
          .then((response) => response.json());
        if (data?.Success) {
          if (!isPrevSaveing) {
            CallToast("Saved successfully", false, false, "v2style");
            setQuestionData({
              ObjectId: 0,
              Title: '',
              Description: '',
              InternalNote: '',
              IsShowExternalReport: false,
              MSPBaselineQuestionFiles_List: []
            });
            setChanges({
              Title: null,
              Description: null,
              InternalNote: null,
              IsShowExternalReport: null
            })
            setFilesAndLinksData([]);
            setTitleErr(false);
            setDescriptionErr(false);
            hideModal();
          } else {
            setQuestionId(data?.EntityId)
          }
        } else {
          CallToast("Something went wrong", false, false, "v2style");
        }
        setSaving(false);
        if (!isPrevSaveing) {
          setQuestionData({
            ObjectId: 0,
            Title: '',
            Description: '',
            InternalNote: '',
            IsShowExternalReport: false,
            MSPBaselineQuestionFiles_List: []
          });
          setChanges({
            Title: null,
            Description: null,
            InternalNote: null,
            IsShowExternalReport: null
          })
          setFilesAndLinksData([]);
          setTitleErr(false);
          setDescriptionErr(false);
          hideModal();
        }
      } catch (err) {
        console.log(err);
        CallToast("Something went wrong 2", false, false, "v2style");
        setSaving(false);
        setQuestionData({
          ObjectId: 0,
          Title: '',
          Description: '',
          InternalNote: '',
          IsShowExternalReport: false,
          MSPBaselineQuestionFiles_List: []
        });
        setChanges({
          Title: null,
          Description: null,
          InternalNote: null,
          IsShowExternalReport: null
        })
        setFilesAndLinksData([]);
        setTitleErr(false);
        setDescriptionErr(false);
        hideModal();
      };
    }
  }


  function handleValidation() {
    let errors = 0;
    if (!questionData?.Title || questionData?.Title == null || questionData?.Title == '') {
      setTitleErr(true);
      errors += 1;
    }
    if (!questionData?.Description || questionData?.Description == null || questionData?.Description == '') {
      setDescriptionErr(true);
      errors += 1;
    }
    return errors;
  }

  return (
    <SlidingModal
      show={show}
      className="common-big-modal memberAccessModal"
      parentID={parentID}
      id="ounsqxw"
      width={"28%"}
      onHide={() => {
        if (isPrevSave) {
          deleteQuestion(questionId, true);
        }
        setQuestionData({
          ObjectId: 0,
          Title: '',
          Description: '',
          InternalNote: '',
          IsShowExternalReport: false,
          MSPBaselineQuestionFiles_List: []
        });
        setChanges({
          Title: null,
          Description: null,
          InternalNote: null,
          IsShowExternalReport: null
        })
        setFilesAndLinksData([]);
        setTitleErr(false);
        setDescriptionErr(false);
        hideModal();
      }}
    >
      <SlidingModal.Header className="text-center d-flex p-3 border-bottom">
        <div
          className="w-100 mb-0 font-bold-14 title"
          style={{ fontWeight: "600" }}
        >
          {
            questionId == 0 ? 'Add' : 'Edit'
          }
          {" "}Security Baseline Item
        </div>

        <span className="cursor-pointer" onClick={() => {
          if (isPrevSave) {
            deleteQuestion(questionId, true);
          }
          setQuestionData({
            ObjectId: 0,
            Title: '',
            Description: '',
            InternalNote: '',
            IsShowExternalReport: false,
            MSPBaselineQuestionFiles_List: []
          });
          setChanges({
            Title: null,
            Description: null,
            InternalNote: null,
            IsShowExternalReport: null
          })
          setFilesAndLinksData([]);
          setTitleErr(false);
          setDescriptionErr(false);
          hideModal();
        }}>
          <img src="/images/attack-surface/cross-icon.svg" alt="" />
        </span>
      </SlidingModal.Header>
      <SlidingModal.Body className={`msp-service-add-edit-modal    ${true}`}>
        {loading ? (
          <div
            className="d-flex align-items-center justify-content-center w-100 "
            style={{
              height: "85vh",
            }}
          >
            <Loader />
          </div>
        ) : (
          <div className="m-24px">
            <div className={` d-flex align-items-center p-2 radius-4
            ${titleErr ? 'title-input-error' : 'title-input'}
          `}>
              <div className="f-darkgrey">Title:</div>
              <input
                tabIndex={0}
                type="text"
                placeholder={`Untitled baseline `}
                className={`flex-grow-1 ml-2 ${questionData?.Title ? 'f-black' : 'f-darkgrey'}`}
                value={questionData?.Title}
                onChange={(e) => {
                  setQuestionData({
                    ...questionData,
                    Title: e?.target?.value
                  })
                  setChanges({
                    ...changes,
                    Title: e?.target?.value
                  })
                  setTitleErr(false);
                }}
              />
            </div>
            <textarea
              placeholder="Description"
              className={`w-100 p-2 radius-4 mt-2 ${questionData?.Description ? 'f-black' : 'f-darkgrey'}
                ${descriptionErr ? 'textarea-input-error' : 'highlight-input-with-grey-border'}
              `}
              style={{
                height: '120px'
              }}
              rows={9}
              value={questionData?.Description}
              onChange={(e) => {
                setQuestionData({
                  ...questionData,
                  Description: e?.target?.value
                })
                setChanges({
                  ...changes,
                  Description: e?.target?.value
                })
                setDescriptionErr(false);
              }}
            ></textarea>

            <div
              style={{
                marginTop: "-32px",
              }}
            >
              <SwitchButtonMulti
                id={`switch_toggle_button_`}
                label={"Show in the external statement report"}
                value={questionData?.IsShowExternalReport}
                onChange={() => {
                  setQuestionData({
                    ...questionData,
                    IsShowExternalReport: !questionData?.IsShowExternalReport
                  })
                  setChanges({
                    ...changes,
                    IsShowExternalReport: !questionData?.IsShowExternalReport
                  })
                }}
              />
            </div>


            <div className="border-top mt-24px">
              <div className="f-12 f-darkgrey f-500 mt-16px" >Internal note</div>
              <textarea
                placeholder="Type note..."
                className={`w-100 p-2 radius-4 highlight-input-with-grey-border mt-2 ${questionData?.InternalNote ? 'f-black' : 'f-darkgrey'}`}
                style={{
                  height: '120px'
                }}
                rows={9}
                value={questionData?.InternalNote}
                onChange={(e) => {
                  setQuestionData({
                    ...questionData,
                    InternalNote: e?.target?.value
                  })
                  setChanges({
                    ...changes,
                    InternalNote: e?.target?.value
                  })
                }}
              ></textarea>

              <div className="f-darkgrey f-12 f-500 mt-2">Internal Resources</div>
              <div className="f-black f-14 f-400 my-2">Resources</div>

              {
                filesAndLinksData?.length > 0 ? <div className="border-parent radius-8 my-2">
                  {
                    filesAndLinksData?.map((item) => {
                      return (
                        <div className="radius-4 bg-grey py-2 px-3 border-item  d-flex align-items-center justify-content-between hover-on-show-parent">
                          <div className="d-flex align-items-center">
                            <img
                              width={14}
                              height={14}
                              src={item?.IsLink ? '/images/msp/msp-cert-link-icon.svg' : '/images/attack-surface/insights-file-icon.svg'}
                              alt=""
                              className="mr-2"
                            />
                            <div>
                              <div className="f-black f- 14 f-500">
                                {item?.FileNameOrLink}
                              </div>
                              <div className="d-flex align-items-center">
                                <div className="f-darkgrey f-12 f-400">
                                  {item?.FileLabel}
                                </div>
                                {/* <div className="f-darkgrey f-12 f-400 mx-1">
                                      •
                                    </div>
                                    <div className="f-darkgrey f-12 f-400">
                                      {item?.size}
                                    </div> */}
                              </div>
                            </div>
                          </div>

                          <div className="d-flex align-items-center hover-on-show-child">
                            <Dropdown className="transparennt-wrapper-dropdown">
                              <Dropdown.Toggle>
                                <img
                                  alt=""
                                  className="pointer mx-2"
                                  src="/images/settings-v2/horizontal-dots.svg"
                                />
                              </Dropdown.Toggle>
                              <Dropdown.Menu className="p-0">
                                <div
                                  className="greyText2 f-14 f-400 my-2 ml-3 pointer"
                                  onClick={() => {
                                    setShowAddResourceModal(true);
                                    setSelectedFileOrLinkId(item?.ObjectId)
                                    setSelectedFileOrLink(item);
                                  }}
                                >
                                  Edit
                                </div>
                                <div
                                  className="redText f-14 f-400 my-2 ml-3 pointer"
                                  onClick={() => {
                                    setSelectedFileOrLinkId(item?.ObjectId);
                                    setShowDeleteModal(true);
                                  }}
                                >
                                  Delete
                                </div>
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                        </div>
                      )
                    })
                  }</div> : <div className="f-darkgrey f-14 f-400">No resources yet</div>
              }

              <Button
                className="hg-cancel-btn mt-2 mb-4"
                onClick={() => {
                  if (questionId == 0) {
                    setIsPrevSave(true);
                    handleSave(true);
                  }
                  setShowAddResourceModal(true);
                }}
              >
                <img
                  alt=""
                  src="/images/actioncenter/plusgrey.svg"
                  className="pr-2 pl-1"
                />
                Add
              </Button>
            </div>
          </div>
        )}
      </SlidingModal.Body>
      <SlidingModal.Footer className="p-3 position-sticky w-100 border-top">
        <div className="d-flex align-items-center justify-content-between">
          <div className="d-flex justify-content-end">
            <Button className="hg-cancel-btn mr-2" onClick={() => {
              if (isPrevSave) {
                deleteQuestion(questionId, true);
              }
              setQuestionData({
                ObjectId: 0,
                Title: '',
                Description: '',
                InternalNote: '',
                IsShowExternalReport: false,
                MSPBaselineQuestionFiles_List: []
              });
              setChanges({
                Title: null,
                Description: null,
                InternalNote: null,
                IsShowExternalReport: null
              })
              setFilesAndLinksData([]);
              setTitleErr(false);
              setDescriptionErr(false);
              hideModal();
            }}>
              Cancel
            </Button>
            <Button disabled={false} className="hg-blue-btn" onClick={() => { handleSave() }}>
              {saving ? (
                <>
                  Saving..
                </>
              ) : (
                "Save"
              )}
            </Button>
          </div>
        </div>
      </SlidingModal.Footer>
      <AddResourceModal
        show={showAddResourceModal}
        hideModal={() => {
          setLoading(true)
          setShowAddResourceModal(false);
          setSelectedFileOrLink(null);
          setSelectedFileOrLinkId(0);
          getQuestion(questionId);
        }}
        questionId={questionId ?? 0}
        selectedFileOrLinkId={selectedFileOrLinkId ?? 0}
        selectedFileOrLink={selectedFileOrLink}
      />
      <CustomDeleteModal
        show={showDeleteModal}
        deleteHeaderIcon={"/images/msp/yellow-exclammation.svg"}
        hideModal={() => {
          setShowDeleteModal(false);
          setSelectedFileOrLinkId(0);
          // getQuestion(questionId);
        }}
        deleteButtonClass={"hg-blue-btn"}
        deleteButtonText={"Delete"}
        deleteHeaderText={"Warning"}
        deleteBodyText={""}
        deleteFooterText={"Are you sure you want to delete?"}
        deleteFunction={() => {
          deleteFileOrLink(selectedFileOrLinkId).then(() => {
            getQuestion(questionId);
          })
        }}
        deleteParams={{}}
        deleteType={"warningClose"}
      />
    </SlidingModal>
  );
};

export default AddEditSecurityBaselineItemModal;
