import PropTypes from "prop-types";
import React from "react";
import ImageWithInitials from "../../../Utils/ImageWithInitials";
import "../test.scss";
const EntryComponent = ({
  mention,
  theme,
  searchValue, // eslint-disable-line no-unused-vars
  isFocused, // eslint-disable-line no-unused-vars
  ...parentProps
}) => {
  return (
    <div {...parentProps}>
      <div className={theme.mentionSuggestionsEntryContainer}>
        <div
          data-role-id={`${mention.OwnerUserName}`}
          className={theme.mentionSuggestionsEntryContainerRight}
        >
          <div className="d-flex align-items-center ">
            {mention?.OwnerShowInitals === true ? (
              <ImageWithInitials
                initials={mention?.OwnerInitials}
                width={25}
                background={"purple"}
              />
            ) : mention?.OwnerLogo == null ? (
              <img src="/images/settings-v2/highground-dp.svg" />
            ) : (
              <img
                src={mention?.OwnerLogo}
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null; // prevents looping
                  currentTarget.src = "/images/settings-v2/highground-dp.svg";
                }}
                style={{
                  width: "25px",
                  height: "25px",
                  borderRadius: "50%",
                  objectFit: "contain",
                }}
              />
            )}

            {/* <img
              src={mention.OwnerLogo}
              className="mr-2 mention-logo"
              onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping
                currentTarget.src = "/images/settings-v2/highground-dp.svg";
              }}
            /> */}
            <div className={theme.mentionSuggestionsEntryText}>
              {mention.name}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

EntryComponent.propTypes = {
  mention: PropTypes.shape({
    name: PropTypes.string,
    mentionId: PropTypes.string,
  }).isRequired,
  theme: PropTypes.shape({
    mentionSuggestionsEntryContainer: PropTypes.string,
    mentionSuggestionsEntryContainerRight: PropTypes.string,
    mentionSuggestionsEntryText: PropTypes.string,
  }).isRequired,
  // eslint-disable-next-line react/require-default-props
  searchValue: PropTypes.string,
  isFocused: PropTypes.bool,
};

export default EntryComponent;
