import { useEffect, useState } from "react";
import { useAuth } from "../context/Auth";
import ContentLoader from "react-content-loader";
import { getAPIData, postData } from "../../Utils/commonApi";
import "./marketplace.scss"
import { CallToast } from "../PriceCalculator/modals/CustomToast";
import { Dropdown } from "react-bootstrap";

const Marketplace = ({ isMspClientView }) => {
    const { authTokens } = useAuth();
    const clientId = window.location.pathname?.split("/")?.[4] ?? -1;
    const [loading, setLoading] = useState(true);
    const [categories, setCategories] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState({});
    const [selectedSubCategory, setSelectedSubCategory] = useState({});
    const [searchString, setSearchString] = useState('');
    const [allMarketplaceList, setAllMarketplaceList] = useState([]);
    const [allNewestMarketplaceList, setAllNewestMarketplaceList] = useState([]);
    const [isTyping, setIsTyping] = useState('');
    const [isHome, setIsHome] = useState(true);

    useEffect(() => {
        getCategories();
        getMarketplaceData();
    }, [])

    async function getCategories() {
        await getAPIData(976, authTokens)
            .then((response) => {
                if (response?.mr?.Success) {
                    setCategories(response?.MarketplaceCategorySubcategory_List);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }

    function getMarketplaceData() {
        postData(authTokens, "GetMarketPlaceDashboardForSaas", {
            ProductNumber: 0,
            SearchText: searchString ?? '',
            AccessKey: JSON.parse(localStorage.getItem("user_MessageObj")),
            pageSize: 20,
            CategoryId: selectedCategory?.CategoryId ?? 0,
            subCategory: selectedSubCategory?.CategoryId ?? 0
        })
            .then((response) => {
                if (response?.mr?.Success) {
                    setAllMarketplaceList(response?.AllMarketPlace_List)
                    setAllNewestMarketplaceList(response?.AllMarketPlaceNewest_List)
                    setLoading(false);
                }
            })
            .catch((err) => {
                CallToast("Something went wrong", false, false, "v2style");
                console.log(err);
                setLoading(false);
            });
    }

    useEffect(() => {
        if (isTyping === false) {
            getMarketplaceData();
        }
    }, [searchString, isTyping])


    let timer;
    const timeoutVal = 1000;

    function handleKeyPress(e) {
        window.clearTimeout(timer);
        setIsTyping(true);
    }

    function handleKeyUp(e) {
        window.clearTimeout(timer);
        timer = window.setTimeout(() => {
            setIsTyping(false);
        }, timeoutVal);
    }

    return (
        <>
            <div className="msp_client_price_calculator">
                {loading ? (
                    <div className="p-4">
                        <MspClientPriceCalculatorLoader />
                    </div>
                ) : (
                    <div className="marketplace-page d-flex ">
                        <div className="left-block">
                            <div
                                className={`f-500 f-14 line-height-22 letter-space-2 px-2 py-2 pointer ${!selectedCategory?.CategoryId && !selectedSubCategory?.CategoryId ? 'bg-grey f-grey' : 'f-darkgrey'
                                    }`}
                                onClick={() => {
                                    setIsHome(true);
                                    setSelectedCategory({});
                                    setSelectedSubCategory({});
                                    getMarketplaceData();
                                }}
                            >
                                Home
                            </div>
                            <div
                                className="f-500 f-12 f-grey line-height-18 letter-space-2 mx-2 my-1"
                            >
                                Categories
                            </div>
                            {
                                categories?.map((category) => {
                                    return (
                                        <div>

                                            <div className={`d-flex align-items-center px-2 py-1 pointer ${(!selectedSubCategory?.CategoryId && (selectedCategory?.CategoryId == category?.CategoryId)) ? 'bg-grey' : ''
                                                }`}
                                                onClick={() => {
                                                    if (selectedCategory?.CategoryId == category?.CategoryId) {
                                                        setIsHome(true);
                                                        setSelectedCategory({});
                                                        setSelectedSubCategory({});
                                                        getMarketplaceData();
                                                    } else {
                                                        setIsHome(false);
                                                        setSelectedCategory(category);
                                                        setSelectedSubCategory({});
                                                        getMarketplaceData();
                                                    }
                                                }}
                                            >
                                                <img
                                                    alt=""
                                                    width={selectedCategory?.CategoryId == category?.CategoryId ? 8 : 4}
                                                    height={selectedCategory?.CategoryId == category?.CategoryId ? 4 : 8}
                                                    src={`${selectedCategory?.CategoryId == category?.CategoryId ? '/images/chevron-down.svg' : '/images/attack-surface/chevron-right.svg'}`}
                                                />
                                                <div
                                                    className={`f-500 f-14 line-height-22 letter-space-2 ml-2 ${(!selectedSubCategory?.CategoryId && (selectedCategory?.CategoryId == category?.CategoryId)) ? 'f-grey' : 'f-darkgrey'
                                                        }`}
                                                >
                                                    {category?.CategoryName}
                                                </div>
                                            </div>
                                            {
                                                selectedCategory?.CategoryId == category?.CategoryId && <div>
                                                    {
                                                        category?.SellProductCategory_List?.map((subCategory) => {
                                                            return (
                                                                <div
                                                                    className={`f-500 f-14 pointer line-height-22 letter-space-2 pl-4 pr-2 py-1 
                                                                    ${selectedSubCategory?.CategoryId == subCategory?.CategoryId ? 'f-grey bg-grey' : 'f-darkgrey'
                                                                        }`}
                                                                    onClick={() => {
                                                                        // if (selectedSubCategory?.CategoryId) {
                                                                        // setIsHome(true);
                                                                        // setSelectedSubCategory({});
                                                                        // getMarketplaceData();
                                                                        // } else {
                                                                        setIsHome(false);
                                                                        setSelectedSubCategory(subCategory);
                                                                        getMarketplaceData();
                                                                        // }
                                                                    }}
                                                                >
                                                                    {subCategory?.CategoryName}
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            }
                                        </div>
                                    )
                                })
                            }
                        </div>
                        <div className="right-block">
                            {
                                isHome &&
                                <div className={`top-block ${searchString == '' ? 'bg-light-purple' : ''}`}>
                                    {
                                        searchString == '' ?
                                            <div>
                                                <div className="f-600 f-24 f-white line-height-32 letter-space-2">
                                                    Discover Products and Services
                                                </div>
                                                <div className="f-400 f-12 f-white line-height-18 letter-space-2">
                                                    Explore 190 Marketplace
                                                </div>
                                            </div> : <div>
                                                <div className="f-600 f-24 f-black">Search</div>
                                                {
                                                    allNewestMarketplaceList?.length <= 0 && <div className="f-400 f-12 f-grey mt-1">
                                                        0 Results for "{searchString}"
                                                    </div>
                                                }
                                            </div>
                                    }
                                    <div
                                        className={`searchInputFieldWithoutBorder d-flex align-items-center p-2 radius-4 mt-3 ${false ? "input-error" : ""
                                            } `}
                                    >
                                        <div className="f-darkgrey flex-shrink-0">
                                            <img
                                                alt=""
                                                src="/images/attack-surface/search-icon.svg"
                                                className="pointer"
                                            />
                                        </div>
                                        <input
                                            className="pl-1 pr-2 w-100"
                                            placeholder="Search"
                                            value={searchString}
                                            onChange={(e) => {
                                                handleKeyPress(e);
                                                setSearchString(e.target.value);
                                                // setSelectedTag('');
                                            }}
                                            onKeyUp={(e) => handleKeyUp(e)}
                                        />
                                    </div>
                                </div>
                            }

                            <div className="bottom-block">
                                {
                                    (isHome && searchString == '') &&
                                    <div>

                                        <div className="d-flex justify-content-between">
                                            <div
                                                className="d-flex align-items-center bg-light-violet radius-12"
                                                style={{
                                                    height: '224px',
                                                    width: '420px'
                                                }}
                                            >
                                                <div className="p-5">
                                                    <div className="f-500 f-18 f-black">Services developed for HighGround</div>
                                                    <div className="f-400 f-12 f-grey">Ideal for those considering applying for Cyber Essentials certification.</div>
                                                </div>
                                                <img
                                                    // width={32}
                                                    // height={32}
                                                    src="/images/block-right-side.png"
                                                    alt=""
                                                />
                                            </div>

                                            <div
                                                className="d-flex align-items-center bg-light-pink radius-12"
                                                style={{
                                                    height: '224px',
                                                    width: '420px'
                                                }}
                                            >
                                                <div className="p-5">
                                                    <div className="f-500 f-18 f-black">Services developed for HighGround</div>
                                                    <div className="f-400 f-12 f-grey">Ideal for those considering applying for Cyber Essentials certification.</div>
                                                </div>
                                                <img
                                                    // width={32}
                                                    // height={32}
                                                    src="/images/block-right-side.png"
                                                    alt=""
                                                />
                                            </div>

                                        </div>

                                        <div className="f-500 f-16 f-black my-3">Newest</div>
                                        <div>
                                            {
                                                allMarketplaceList?.length > 0 ? <div className="container-block">
                                                    {
                                                        allMarketplaceList?.map((marketplaceItem) => {
                                                            return (
                                                                <div className="d-flex ">
                                                                    <img width={32} height={32} className="mr-2" src="/images/marketplaceImg.png" alt="" />
                                                                    <div>
                                                                        <div className="f-500 f-14 f-black">{marketplaceItem?.ProductName}</div>
                                                                        <div className="f-400 f-12 f-darkgrey">{marketplaceItem?.ProductShortDescription}</div>
                                                                        <div className="f-500 f-14 txt-blue">{marketplaceItem?.Pricing}</div>
                                                                    </div>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div> : <div className="d-flex align-items-center justify-content-center w-100 my-5 f-500 f-darkgrey">
                                                    No data yet
                                                </div>
                                            }
                                        </div>
                                    </div>
                                }

                                {
                                    (isHome && searchString == '') ?
                                        <div
                                            className="f-500 f-16 f-black my-3"
                                        >
                                            All Products and Services
                                        </div> : searchString == '' ? <div>
                                            <div className="d-flex align-items-center my-1">
                                                <img
                                                    width={14}
                                                    height={14}
                                                    className="mr-1 mb-1"
                                                    src="/images/home-icon.svg"
                                                    alt=""
                                                />
                                                <div className="f-500 f-14 f-grey mr-2">Home</div>
                                                <div className="f-500 f-14 f-darkgrey mr-2">/</div>
                                                <div
                                                    className={`f-500 f-14 mr-2 ${selectedSubCategory?.CategoryId ? 'f-grey' : 'f-black'}`}
                                                >
                                                    {selectedCategory?.CategoryName}
                                                </div>
                                                {
                                                    selectedSubCategory?.CategoryId &&
                                                    <div className="d-flex align-items-center ">
                                                        <div className="f-500 f-14 f-darkgrey mr-2">/</div>
                                                        <div className="f-500 f-14 f-black">{selectedSubCategory?.CategoryName}</div>
                                                    </div>
                                                }
                                            </div>
                                            <div className="f-600 f-24 f-black my-3">{selectedSubCategory?.CategoryId ? selectedSubCategory?.CategoryName : selectedCategory?.CategoryName}</div>
                                            <div className="f-500 f-16 f-black mb-3 mt-5">{allNewestMarketplaceList?.length} services</div>
                                        </div> : allNewestMarketplaceList?.length > 0 && <div
                                            className="d-flex justify-content-between align-items-center w-100"
                                            style={{
                                                marginTop: '-60px'
                                            }}
                                        >
                                            <div
                                                className="f-500 f-16 f-black mb-2"
                                            >
                                                {allNewestMarketplaceList?.length} Results for "{searchString}"
                                            </div>
                                            <Dropdown className="transparennt-wrapper-dropdown d-flex align-items-center">
                                                <Dropdown.Toggle className="d-flex align-items-center">
                                                    <div className="f-400 f-14 f-black line-height-18 letter-space-2">
                                                        {selectedCategory?.CategoryName ?? 'All categories'}
                                                    </div>
                                                    <img
                                                        alt=""
                                                        src="/images/chevron-down.svg"
                                                        className="ml-2"
                                                        width={14}
                                                        height={14}
                                                    />
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu className="p-0">
                                                    <div
                                                        className="d-flex align-items-center px-3 py-2 hg-border-bottom"
                                                        onClick={(e) => {
                                                            setSelectedCategory({});
                                                            getMarketplaceData();
                                                            e.target
                                                                .closest(".transparennt-wrapper-dropdown")
                                                                .click();
                                                        }}
                                                    >
                                                        {
                                                            !selectedCategory?.CategoryId &&
                                                            <img
                                                                alt=""
                                                                src="/images/icons/check-gray.svg"
                                                                className="mr-2"
                                                                width={14}
                                                                height={14}
                                                            />
                                                        }
                                                        <div className={`f-400 f-14 f-black line-height-18 letter-space-2 pointer ${selectedCategory?.CategoryId && 'ml-4'
                                                            }`}>
                                                            All categories
                                                        </div>
                                                    </div>
                                                    {categories?.map((categoryItem) => (
                                                        <div
                                                            className="d-flex align-items-center pointer px-3 py-2"
                                                            onClick={(e) => {
                                                                setSelectedCategory(categoryItem);
                                                                getMarketplaceData();
                                                                e.target
                                                                    .closest(".transparennt-wrapper-dropdown")
                                                                    .click();
                                                            }}
                                                        >
                                                            {categoryItem?.CategoryId == selectedCategory?.CategoryId &&
                                                                <img
                                                                    alt=""
                                                                    src="/images/icons/check-gray.svg"
                                                                    className="mr-2"
                                                                    width={14}
                                                                    height={14}
                                                                />
                                                            }
                                                            <div className={`f-400 f-14 f-black line-height-18 letter-space-2 ${categoryItem?.CategoryId != selectedCategory?.CategoryId && 'ml-4'
                                                                }`}>
                                                                {categoryItem?.CategoryName}
                                                            </div>
                                                        </div>
                                                    ))}
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                }

                                <div>
                                    {
                                        allNewestMarketplaceList?.length > 0 ? <div className="container-block-for2">
                                            {
                                                allNewestMarketplaceList?.map((marketplaceItem) => {
                                                    return (
                                                        <div className="marketplace-item-block p-3">
                                                            <div className="d-flex  justify-content-between">
                                                                <img width={32} height={32} className="mr-2" src="/images/marketplaceImg.png" alt="" />
                                                                <div className="f-500 f-14 txt-blue">{marketplaceItem?.Pricing}</div>
                                                            </div>
                                                            <div>
                                                                <div className="f-500 f-14 f-black mt-1">{marketplaceItem?.ProductName}</div>
                                                                <div className="f-400 f-12 f-darkgrey mt-1">{marketplaceItem?.ProductShortDescription}</div>
                                                                <div className="f-400 f-12 f-grey mt-2">{marketplaceItem?.Category}</div>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div> : searchString == '' ? <div className="d-flex align-items-center justify-content-center w-100 my-5 f-500 f-darkgrey">
                                            No data yet
                                        </div> : <div className="d-flex align-items-center justify-content-center w-100">
                                            <div style={{
                                                textAlign: 'center'
                                            }}>
                                                <img
                                                    width={44}
                                                    height={44}
                                                    className="mr-2"
                                                    src="/images/theme/icons/no-search-result-icon.svg"
                                                    alt=""
                                                />
                                                <div
                                                    className="f-500 f-14 f-grey mt-3"
                                                >
                                                    No search results for "{searchString}"
                                                </div>
                                                <div
                                                    className="f-400 f-14 f-darkgrey mt-1"
                                                >
                                                    Try another search request or clear search request
                                                </div>
                                                <div
                                                    className="f-400 f-14 txt-blue mt-3"
                                                    onClick={() => {
                                                        setSearchString('');
                                                    }}
                                                >
                                                    Clear search request
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>

                        </div>
                    </div>
                )}
            </div>
        </>
    );
};

export default Marketplace;


export const MspClientPriceCalculatorLoader = () => {
    return (
        <ContentLoader
            speed={2}
            width={"100%"}
            height={"100%"}
            viewBox="0 0 1500 900"
            backgroundColor="#F6F8FB"
            foregroundColor="#ecebeb"
        >
            <rect x="1%" y="8%" rx="5" ry="5" width="45%" height="80" />
            <rect x="1%" y="20%" rx="5" ry="5" width="45%" height="40" />
            <rect x="1%" y="28%" rx="5" ry="5" width="20%" height="20" />

            <rect x="1%" y="32%" rx="5" ry="5" width="45%" height="70" />
            <rect x="1%" y="362" rx="5" ry="5" width="45%" height="100" />
            <rect x="1%" y="465" rx="5" ry="5" width="45%" height="100" />
            <rect x="1%" y="567" rx="5" ry="5" width="45%" height="100" />
            <rect x="1%" y="669" rx="5" ry="5" width="45%" height="100" />

            <rect x="55%" y="8%" rx="10" ry="10" width="40%" height="450" />

            <rect x="55%" y="60%" rx="10" ry="10" width="40%" height="50" />
            <rect x="55%" y="67%" rx="10" ry="10" width="40%" height="50" />
            <rect x="55%" y="74%" rx="10" ry="10" width="40%" height="50" />
            <rect x="55%" y="81%" rx="10" ry="10" width="40%" height="50" />
        </ContentLoader>
    );
};
