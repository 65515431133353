import React, { useEffect, useState } from "react";
import { useAuth } from "../../context/Auth";
import {
  Row,
  Col,
  Form,
  Dropdown,
  Button,
  InputGroup,
  Spinner,
  ButtonGroup,
  ToggleButton,
} from "react-bootstrap";
import { getAPIData, postData } from "../../../Utils/commonApi";
// import CustomSlider from "../../CustomSlider";
import { Fragment } from "react";
import { FiCheck, FiX } from "react-icons/fi";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CircularProgressBar from "../../CircularProgressBar";
import ContentLoader from "react-content-loader";
import ThresholdSlider from "../../CustomSlider/ThresholdSlider";
import SenstivitySlider from "../../CustomSlider/SensitivitySlider";
import { TrackUser } from "../../../Utils/SegmentFunctions";

const Notifications = (props) => {
  const { authTokens } = useAuth();
  const [notifSetting, setNotifSetting] = useState({});
  const [slideSetting, setSlideSetting] = useState({});
  const [ownerType, setOwnerType] = useState(0);
  const [notifSuppression, setNotifSupression] = useState(5);
  const [threatLabel, setThreatLabel] = useState("Low");
  const [showNotifDropdown, setShowNotifDropdown] = useState(false);
  const [responseStatus, setResponseStatus] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [notifTime, setNotifTime] = useState("");

  const periods = [
    {
      name: "Disabled",
      value: "0",
      message:
        "Reporting is currently suspended. If you want to receive reports, select the frequency of sending.",
    },
    {
      name: "Weekly",
      value: "1",
      message: "If there are fewer days in the week than you specified.",
    },
    {
      name: "Monthly",
      value: "2",
      message:
        "If there are fewer days in the month than you specified, the report will be sent on the last day of the month.",
    },
  ];

  const weekDays = [
    { name: "Mon", value: "0" },
    { name: "Tue", value: "1" },
    { name: "Wed", value: "2" },
    { name: "Thu", value: "3" },
    { name: "Fri", value: "4" },
    { name: "Sat", value: "5" },
    { name: "Sun", value: "6" },
  ];

  const monthDays = [
    { name: "1st", value: "1" },
    { name: "2nd", value: "2" },
    { name: "3rd", value: "3" },
    { name: "4th", value: "4" },
    { name: "5th", value: "5" },
    { name: "6th", value: "6" },
    { name: "7th", value: "7" },
    { name: "8th", value: "8" },
    { name: "9th", value: "9" },
    { name: "10th", value: "10" },
    { name: "11th", value: "11" },
    { name: "12th", value: "12" },
    { name: "13th", value: "13" },
    { name: "14th", value: "14" },
    { name: "15th", value: "15" },
    { name: "16th", value: "16" },
    { name: "17th", value: "17" },
    { name: "18th", value: "18" },
    { name: "19th", value: "19" },
    { name: "20th", value: "20" },
    { name: "21th", value: "21" },
    { name: "22th", value: "22" },
    { name: "23th", value: "23" },
    { name: "24th", value: "24" },
    { name: "25th", value: "25" },
    { name: "26th", value: "26" },
    { name: "27th", value: "27" },
    { name: "28th", value: "28" },
  ];

  const dayTime = [
    { name: "1:00 am", value: "01:00" },
    { name: "2:00 am", value: "02:00" },
    { name: "3:00 am", value: "03:00" },
    { name: "4:00 am", value: "04:00" },
    { name: "5:00 am", value: "05:00" },
    { name: "6:00 am", value: "06:00" },
    { name: "7:00 am", value: "07:00" },
    { name: "8:00 am", value: "08:00" },
    { name: "9:00 am", value: "09:00" },
    { name: "10:00 am", value: "10:00" },
    { name: "11:00 am", value: "11:00" },
    { name: "12:00 pm", value: "12:00" },
    { name: "1:00 pm", value: "13:00" },
    { name: "2:00 pm", value: "14:00" },
    { name: "3:00 pm", value: "15:00" },
    { name: "4:00 pm", value: "16:00" },
    { name: "5:00 pm", value: "17:00" },
    { name: "6:00 pm", value: "18:00" },
    { name: "7:00 pm", value: "19:00" },
    { name: "8:00 pm", value: "20:00" },
    { name: "9:00 pm", value: "21:00" },
    { name: "10:00 pm", value: "22:00" },
    { name: "11:00 pm", value: "23:00" },
    { name: "12:00 pm", value: "24:00" },
  ];

  useEffect(() => {
    if (props.isResponse) {
      getNotificationSettings();
    }
  }, [props.isResponse]);

  useEffect(() => {
    setThreatLabel(
      threatLevel.find(
        (elmt) => elmt.value == slideSetting.Threshold_CyberThreat
      )
        ? threatLevel.find(
            (elmt) => elmt.value == slideSetting.Threshold_CyberThreat
          ).label
        : threatLabel
    );
  }, [slideSetting.Threshold_CyberThreat]);

  async function getNotificationSettings() {
    const response = await getAPIData(props.pageEnum, authTokens);
    if (response.Notification_Settings || response.NotificationSettings) {
      const setting =
        response.Notification_Settings || response.NotificationSettings;
      setSlideSetting({
        ...setting,
        Sensitivity_CyberScore: setting.Sensitivity_CyberScore * 25,
        Sensitivity_CyberThreat: setting.Sensitivity_CyberThreat * 25,
        Sensitivity_CyberCompliance: setting.Sensitivity_CyberCompliance * 25,
        Threshold_CyberThreat: setting.Threshold_CyberThreat * 33,
      });
      setOwnerType(setting.OwnerType);
      setNotifSetting(response);
      setNotifSupression(setting.Notification_Status);
      setNotifTime(setting.NotificationMessage);
    }
  }

  const senstivity = [
    {
      value: 0,
      label: "Disable",
    },
    {
      value: 25,
      label: "Low",
    },
    {
      value: 50,
      label: "Normal",
    },
    {
      value: 75,
      label: "High",
    },
    {
      value: 100,
      label: "Immediate",
    },
  ];

  const thershold = [
    {
      value: 0,
      label: 0,
    },
    {
      value: 20,
      label: 20,
    },
    {
      value: 40,
      label: 40,
    },
    {
      value: 60,
      label: 60,
    },
    {
      value: 80,
      label: 80,
    },
    {
      value: 100,
      label: 100,
    },
  ];

  const notifSuppressionState = [
    {
      value: 0,
      label: "For 1 hour",
      message: "Notifications are disabled for 1 hour",
    },
    {
      value: 1,
      label: "For 2 hours",
      message: "Notifications are disabled for 2 hours",
    },
    {
      value: 2,
      label: "For 1 day",
      message: "Notifications are disabled for 1 day",
    },
    {
      value: 3,
      label: "For 2 days",
      message: "Notifications are disabled for 2 days",
    },
    {
      value: 4,
      label: "Forever",
      message: "Notifications are permanently disabled",
    },
  ];

  const threatLevel = [
    {
      value: 0,
      label: "None",
    },
    {
      value: 33,
      label: "Low",
    },
    {
      value: 66,
      label: "Moderate",
    },
    {
      value: 99,
      label: "High",
    },
  ];

  const handleChange = () => {
    if (notifSuppression == 5) {
      setShowNotifDropdown(true);
    } else {
      setNotifSupression(5);
      handleSave("Notification_Status", 5);
    }
  };

  const handleSelect = (value) => {
    setShowNotifDropdown(false);
    setNotifSupression(value);
    handleSave("Notification_Status", value);
  };

  const handleSlideChange = (slideName, value) => {
    setSlideSetting({ ...slideSetting, [slideName]: value });
  };

  async function handleSave(notifName, notifValue, type = "slide") {
    const setting =
      notifSetting.Notification_Settings || notifSetting.NotificationSettings;
    const inputs = {
      SettingOwner: setting.SettingOwner,
      OwnerType: setting.OwnerType,
      NotificationName:
        type == "switch"
          ? notifName
          : ownerType
          ? `U${notifName}`
          : `SO${notifName}`,
      NotificationValue: notifValue,
    };
    await postData(authTokens, "SubmitNotificationSettings", inputs)
      .then((response) => {
        if (response.Success) {
          if (notifName == "Supress_Notification" && notifValue == 1) {
            TrackUser("Notification suppression enabled");
          }
          toast.dark(
            <span>
              <FiCheck style={{ marginRight: "10px" }} />
              <span style={{ width: "500px" }}>
                Notifications updated successfully
              </span>
              <span className="float-right">
                <CircularProgressBar durationInSec="30" />
              </span>
            </span>
          );
          if (notifName.includes("Sensitivity_CyberScore")) {
            if (notifValue == 0) {
              TrackUser("Alert notification disabled");
            } else {
              TrackUser("Alert notification set");
            }

            setSlideSetting({
              ...slideSetting,
              Sensitivity_CyberScore_Text: response.Message,
            });
          } else if (notifName.includes("Sensitivity_CyberCompliance")) {
            if (notifValue == 0) {
              TrackUser("Alert notification disabled");
            } else {
              TrackUser("Alert notification set");
            }
            setSlideSetting({
              ...slideSetting,
              Sensitivity_CyberCompliance_Text: response.Message,
            });
          } else if (notifName.includes("Sensitivity_CyberThreat")) {
            if (notifValue == 0) {
              TrackUser("Alert notification disabled");
            } else {
              TrackUser("Alert notification set");
            }
            setSlideSetting({
              ...slideSetting,
              Sensitivity_CyberThreat_Text: response.Message,
            });
          }
        } else {
          toast.dark(
            <span>
              <FiX style={{ marginRight: "10px", color: "#f36060" }} />
              <span style={{ width: "500px", color: "#f36060" }}>
                Failed to Update
              </span>
              <span className="float-right">
                <CircularProgressBar durationInSec="30" />
              </span>
            </span>
          );
        }
        setResponseStatus(response.Success);
        getNotificationSettings();
        setTimeout(() => {
          setResponseStatus(false);
        }, 1000);
      })
      .catch((err) => {
        toast.dark(
          <span>
            <FiX style={{ marginRight: "10px", color: "#f36060" }} />
            <span style={{ width: "500px", color: "#f36060" }}>
              Failed to Update
            </span>
            <span className="float-right">
              <CircularProgressBar durationInSec="30" />
            </span>
          </span>
        );
      });
    setShowToast(true);
  }

  async function handleReset(notifName, btnName, name, text, multiplier) {
    setNotifSetting({ ...notifSetting, [btnName]: 2 });
    const setting =
      notifSetting.Notification_Settings || notifSetting.NotificationSettings;
    const inputs = {
      SettingOwner: setting.SettingOwner,
      OwnerType: setting.OwnerType,
      NotificationName: ownerType ? `U${notifName}` : `SO${notifName}`,
      NotificationValue: 1,
    };
    const response = await postData(
      authTokens,
      "SubmitNotificationSettings",
      inputs
    );
    if (response.Success == true) {
      TrackUser("Alert notifications reset");

      setNotifSetting({
        ...notifSetting,
      });
      setSlideSetting({
        ...slideSetting,
        [name]: response.SuccessCount * multiplier,
        [text]: response.Message,
      });
      setNotifSetting({ ...notifSetting, [btnName]: false });
    } else {
      setNotifSetting({ ...notifSetting, [btnName]: false });
    }
  }

  const selectedPeriod = periods.find(
    (data) => data.value == notifSetting.Report_Period
  );
  return Object.keys(notifSetting).length == 0 ? (
    <ContentLoader
      speed={2}
      width={"100%"}
      height={"100%"}
      viewBox="0 0 1500 700"
      backgroundColor="#F6F8FB"
      foregroundColor="#ecebeb"
      {...props}
    >
      <rect x="20" y="5" rx="12" ry="12" width="48%" height="100" />
      <rect x="760" y="5" rx="12" ry="12" width="48%" height="100" />
      <rect x="20" y="130" rx="12" ry="12" width="48%" height="100" />
      <rect x="760" y="130" rx="12" ry="12" width="48%" height="100" />
      <rect x="20" y="250" rx="12" ry="12" width="48%" height="100" />
      <rect x="760" y="250" rx="12" ry="12" width="48%" height="100" />
      <rect x="20" y="370" rx="12" ry="12" width="48%" height="100" />
      <rect x="760" y="370" rx="12" ry="12" width="48%" height="100" />
      <rect x="20" y="490" rx="12" ry="12" width="48%" height="100" />
      <rect x="760" y="490" rx="12" ry="12" width="48%" height="100" />
    </ContentLoader>
  ) : (
    <Fragment>
      {showToast && (
        <ToastContainer
          position="bottom-center"
          autoClose={3000}
          hideProgressBar
        />
      )}
      <div className="settings_container">
        <Row>
          <Col md={6}>
            <h1>Notifications</h1>
            <span className="linkGreyText">
              By default, notification settings configured here at the system
              level will be inherited by all uses in your organisation. Users
              can override these by configuring their own settings, and return
              to these settings by clicking 'Reset' within their Personal
              Profile notification settings.
            </span>
          </Col>
          <Col md={6}>
            <Fragment>
              <div>
                <img
                  src="/images/icons/notificationOff.svg"
                  className="float-left mr-2"
                />
                <h1 className="mt-0">Notification Suppression</h1>
              </div>
              <span className="linkGreyText" style={{ marginTop: "8px" }}>
                Use these settings to disable notifications for a period of time
                or permanently.
              </span>
              <div className="d-flex" style={{ marginTop: "16px" }}>
                <div className="position-relative">
                  {ownerType == 1 ? (
                    <Form.Check
                      type="switch"
                      id="switch7"
                      className="m-0"
                      onClick={(e) => {
                        setNotifSetting({
                          ...notifSetting,
                          Btn_Notification_Allow: e.target.checked,
                        });
                        handleSave(
                          "Supress_Notification",
                          +e.target.checked,
                          "switch"
                        );
                      }}
                    />
                  ) : (
                    <Form.Check
                      type="switch"
                      id="switch"
                      checked={notifSuppression != 5}
                      className="m-0"
                      onClick={(e) => {
                        handleChange();
                      }}
                      onChange={(e) => {
                        if (e.target.checked) {
                          TrackUser("Notification suppression enabled");
                        }
                      }}
                    />
                  )}
                  <Dropdown className="notif-dropdown" show={showNotifDropdown}>
                    <Dropdown.Toggle></Dropdown.Toggle>
                    <Dropdown.Menu>
                      <span className="greyText">Disable notification</span>
                      {notifSuppressionState.map((option, i) => {
                        return notifSuppressionState.length == i + 1 ? (
                          <Fragment>
                            <Dropdown.Divider />
                            <Dropdown.Item
                              eventKey={i}
                              onClick={() => handleSelect(option.value)}
                            >
                              <img
                                style={{ marginRight: "10px" }}
                                src="images/icons/bell-dark.svg"
                              />
                              <span className="textBlackBold">
                                {option.label}
                              </span>
                            </Dropdown.Item>
                          </Fragment>
                        ) : (
                          <Dropdown.Item
                            eventKey={i}
                            value={option.value}
                            onClick={() => handleSelect(option.value)}
                          >
                            <img
                              style={{ marginRight: "10px" }}
                              src="images/icons/bell-dark.svg"
                            />
                            <span className="textBlackBold">
                              {option.label}
                            </span>
                          </Dropdown.Item>
                        );
                      })}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
                <p className="textBlack">Enable Notification Suppression</p>
              </div>
              <div
                className="linkGreyText"
                style={{ marginLeft: "20px", marginBottom: "32px" }}
              >
                {ownerType != 1
                  ? notifSuppressionState.find(
                      (elmt) => elmt.value == notifSuppression
                    )
                    ? notifTime !== null
                      ? notifSuppressionState.find(
                          (elmt) => elmt.value == notifSuppression
                        ).message + notifTime
                      : notifSuppressionState.find(
                          (elmt) => elmt.value == notifSuppression
                        ).message
                    : "Notifications are enabled"
                  : notifSetting.Btn_Notification_Allow
                  ? "Notifications are permanently disabled"
                  : "Notifications are enabled"}
              </div>
            </Fragment>
            {ownerType == 1 && (
              <div>
                <h1 className="mt-0">Allow notifications in the browser:</h1>
                <div className="d-flex" style={{ marginTop: "16px" }}>
                  <div className="position-relative">
                    <Form.Check
                      type="switch"
                      id="switch8"
                      defaultChecked={notifSetting.Btn_Browser_Allow}
                      className="m-0"
                      onClick={(e) =>
                        handleSave(
                          "Browser_Notification",
                          +e.target.checked,
                          "switch"
                        )
                      }
                    />
                  </div>
                  <p className="textBlack">Allow</p>
                </div>
              </div>
            )}
          </Col>
        </Row>
        <Row className="mt40px">
          <Col md={6} className="border-right">
            <h1>Sensitivity</h1>
            <span className="linkGreyText">
              Your CyberScore, CyberThreat Level and CyberCompliance KPIs change
              continually. Configure the notification sensitivity to control how
              much change occurs before you receive notifications about your
              score increasing or decreasing.
            </span>
            <div className="d-flex justify-content-between mt40px">
              <div>
                <h1>CyberScore</h1>
                <div className="linkGreyText">
                  {slideSetting.Sensitivity_CyberScore_Text}
                </div>
              </div>
              {notifSetting["Btn_Sensitivity_Score_Enabled"] != undefined && (
                <Button
                  className={`${
                    slideSetting.Sensitivity_CyberScore ==
                    notifSetting.Sensitivity_CyberScore_Global * 25
                      ? "btn-disable"
                      : "light-red-btn"
                  } align-self-center`}
                  id="Sensitivity_CyberScore_Reset"
                  onClick={() =>
                    handleReset(
                      "Sensitivity_CyberScore_Reset",
                      "Btn_Sensitivity_Score_Enabled",
                      "Sensitivity_CyberScore",
                      "Sensitivity_CyberScore_Text",
                      25
                    )
                  }
                  style={{ minWidth: "70px" }}
                >
                  {notifSetting.Btn_Sensitivity_Score_Enabled == 2 ? (
                    <Spinner size="sm" animation="border" />
                  ) : (
                    "Reset"
                  )}
                  {notifSetting.Btn_Sensitivity_Score_Enabled == 3 && (
                    <FiCheck />
                  )}
                </Button>
              )}
            </div>
            <div>
              {Object.keys(slideSetting).length !== 0 && (
                // <CustomSlider
                //   type="senstivity"
                //   value={slideSetting.Sensitivity_CyberScore}
                //   setValue={handleSlideChange}
                //   marks={senstivity}
                //   step={25}
                //   classes={
                //     "senstivity-slider slider-" +
                //     slideSetting.Sensitivity_CyberScore
                //   }
                //   slideName="Sensitivity_CyberScore"
                //   handleSlider={() =>
                //     handleSave(
                //       "Sensitivity_CyberScore",
                //       slideSetting.Sensitivity_CyberScore / 25
                //     )
                //   }
                // />
                <SenstivitySlider
                  defaultValue={slideSetting.Sensitivity_CyberScore}
                  value={slideSetting.Sensitivity_CyberScore}
                  step={25}
                  min={0}
                  max={100}
                  id="Sensitivity_CyberScore"
                  onChange={(e) =>
                    handleSlideChange("Sensitivity_CyberScore", e.target.value)
                  }
                  onMouseUp={() =>
                    handleSave(
                      "Sensitivity_CyberScore",
                      slideSetting.Sensitivity_CyberScore / 25
                    )
                  }
                  labelData={["Disable", "Low", "Normal", "High", "Immediate"]}
                />
              )}
            </div>
            <hr />
            <div className="d-flex justify-content-between mt40px">
              <div>
                <h1 className="mt40px">CyberThreat Level</h1>
                <div className="linkGreyText">
                  {slideSetting.Sensitivity_CyberThreat_Text}
                </div>
              </div>
              {notifSetting["Btn_Sensitivity_Threat_Enabled"] != undefined && (
                <Button
                  className={`${
                    slideSetting.Sensitivity_CyberThreat ==
                    notifSetting.Sensitivity_CyberThreat_Global * 25
                      ? "btn-disable"
                      : "light-red-btn"
                  } align-self-center`}
                  onClick={() =>
                    handleReset(
                      "Sensitivity_CyberThreat_Reset",
                      "Btn_Sensitivity_Threat_Enabled",
                      "Sensitivity_CyberThreat",
                      "Sensitivity_CyberThreat_Text",
                      25
                    )
                  }
                  style={{ minWidth: "70px" }}
                >
                  {notifSetting.Btn_Sensitivity_Threat_Enabled == 2 ? (
                    <Spinner size="sm" animation="border" />
                  ) : (
                    "Reset"
                  )}
                  {notifSetting.Btn_Sensitivity_Threat_Enabled == 3 && (
                    <FiCheck />
                  )}
                </Button>
              )}
            </div>
            <div>
              {Object.keys(slideSetting).length !== 0 && (
                // <CustomSlider
                //   type="senstivity"
                //   value={slideSetting.Sensitivity_CyberThreat}
                //   setValue={handleSlideChange}
                //   marks={senstivity}
                //   step={25}
                //   classes={
                //     "senstivity-slider slider-" +
                //     slideSetting.Sensitivity_CyberThreat
                //   }
                //   slideName="Sensitivity_CyberThreat"
                //   handleSlider={() =>
                //     handleSave(
                //       "Sensitivity_CyberThreat",
                //       slideSetting.Sensitivity_CyberThreat / 25
                //     )
                //   }
                // />
                <SenstivitySlider
                  defaultValue={slideSetting.Sensitivity_CyberThreat}
                  value={slideSetting.Sensitivity_CyberThreat}
                  step={25}
                  min={0}
                  max={100}
                  id="Sensitivity_CyberThreat"
                  onChange={(e) =>
                    handleSlideChange("Sensitivity_CyberThreat", e.target.value)
                  }
                  onMouseUp={() =>
                    handleSave(
                      "Sensitivity_CyberThreat",
                      slideSetting.Sensitivity_CyberThreat / 25
                    )
                  }
                  labelData={["Disable", "Low", "Normal", "High", "Immediate"]}
                />
              )}
            </div>
            <hr />
            <div className="d-flex justify-content-between mt40px">
              <div>
                <h1 className="mt40px">CyberCompliance</h1>
                <div className="linkGreyText">
                  {slideSetting.Sensitivity_CyberCompliance_Text}
                </div>
              </div>
              {notifSetting["Btn_Sensitivity_Compliance_Enabled"] !=
                undefined && (
                <Button
                  className={`${
                    slideSetting.Sensitivity_CyberCompliance ==
                    notifSetting.Sensitivity_CyberThreat_Global * 25
                      ? "btn-disable"
                      : "light-red-btn"
                  } align-self-center`}
                  id="Sensitivity_CyberScore_Reset"
                  onClick={() =>
                    handleReset(
                      "Sensitivity_CyberCompliance_Reset",
                      "Btn_Sensitivity_Compliance_Enabled",
                      "Sensitivity_CyberCompliance",
                      "Sensitivity_CyberCompliance_Text",
                      25
                    )
                  }
                  style={{ minWidth: "70px" }}
                >
                  {notifSetting.Btn_Sensitivity_Compliance_Enabled == 2 ? (
                    <Spinner size="sm" animation="border" />
                  ) : (
                    "Reset"
                  )}
                  {notifSetting.Btn_Sensitivity_Compliance_Enabled == 3 && (
                    <FiCheck />
                  )}
                </Button>
              )}
            </div>
            <div>
              {Object.keys(slideSetting).length !== 0 && (
                // <CustomSlider
                //   type="senstivity"
                //   value={slideSetting.Sensitivity_CyberCompliance}
                //   setValue={handleSlideChange}
                //   marks={senstivity}
                //   step={25}
                //   classes={
                //     "senstivity-slider slider-" +
                //     slideSetting.Sensitivity_CyberCompliance
                //   }
                //   slideName="Sensitivity_CyberCompliance"
                //   handleSlider={() =>
                //     handleSave(
                //       "Sensitivity_CyberCompliance",
                //       slideSetting.Sensitivity_CyberCompliance / 25
                //     )
                //   }
                // />

                <SenstivitySlider
                  defaultValue={slideSetting.Sensitivity_CyberCompliance}
                  value={slideSetting.Sensitivity_CyberCompliance}
                  step={25}
                  min={0}
                  max={100}
                  id="Sensitivity_CyberCompliance"
                  onChange={(e) =>
                    handleSlideChange(
                      "Sensitivity_CyberCompliance",
                      e.target.value
                    )
                  }
                  onMouseUp={() =>
                    handleSave(
                      "Sensitivity_CyberCompliance",
                      slideSetting.Sensitivity_CyberCompliance / 25
                    )
                  }
                  labelData={["Disable", "Low", "Normal", "High", "Immediate"]}
                />
              )}
            </div>
          </Col>
          <Col md={6} style={{ paddingLeft: "40px" }}>
            <h1>Thresholds</h1>
            <span className="linkGreyText">
              Configure your minimum notification thresholds for your
              CyberScore, CyberThreat Level and CyberCompliance KPIs. If your
              scores drop below these threshold values, you will receive a
              notification.
            </span>
            <div className="d-flex justify-content-between mt40px">
              <div>
                <h1>CyberScore</h1>
                <div className="linkGreyText">
                  Min Threshold is {slideSetting.Threshold_CyberScore}
                </div>
              </div>
              {notifSetting["Btn_Threshold_Score_Enabled"] != undefined && (
                <Button
                  className={`${
                    slideSetting.Threshold_CyberScore ==
                    notifSetting.Threshold_CyberScore_Global
                      ? "btn-disable"
                      : "light-red-btn"
                  } align-self-center`}
                  id="Sensitivity_CyberScore_Reset"
                  onClick={() => {
                    handleReset(
                      "Threshold_CyberScore_Reset",
                      "Btn_Threshold_Score_Enabled",
                      "Threshold_CyberScore",
                      null,
                      1
                    );
                  }}
                  style={{ minWidth: "70px" }}
                >
                  {notifSetting.Btn_Threshold_Score_Enabled == 2 ? (
                    <Spinner size="sm" animation="border" />
                  ) : (
                    "Reset"
                  )}
                  {notifSetting.Btn_Threshold_Score_Enabled == 3 && <FiCheck />}
                </Button>
              )}
            </div>
            <div>
              {Object.keys(slideSetting).length !== 0 && (
                // <CustomSlider
                //   type="thershold"
                //   value={slideSetting.Threshold_CyberScore}
                //   setValue={handleSlideChange}
                //   marks={thershold}
                //   step={1}
                //   classes={
                //     "thershold countingSlider-" +
                //     slideSetting.Threshold_CyberScore
                //   }
                //   slideName="Threshold_CyberScore"
                //   handleSlider={() =>
                //     handleSave(
                //       "Threshold_CyberScore",
                //       slideSetting.Threshold_CyberScore
                //     )
                //   }
                // />
                <ThresholdSlider
                  defaultValue={slideSetting.Threshold_CyberScore}
                  value={slideSetting.Threshold_CyberScore}
                  step={1}
                  min={0}
                  max={100}
                  id="Threshold_CyberScore"
                  onChange={(e) =>
                    handleSlideChange("Threshold_CyberScore", e.target.value)
                  }
                  onMouseUp={() => {
                    TrackUser("Alert notifications set");

                    handleSave(
                      "Threshold_CyberScore",
                      slideSetting.Threshold_CyberScore
                    );
                  }}
                  labelData={["0", "20", "40", "60", "80", "100"]}
                />
              )}
              {}
            </div>
            <hr />
            <div className="d-flex justify-content-between mt40px">
              <div>
                <h1 className="mt40px">CyberThreat Level</h1>
                <div className="linkGreyText">
                  Min Threshold is {threatLabel}
                </div>
              </div>
              {notifSetting["Btn_Threshold_Threat_Enabled"] != undefined && (
                <Button
                  className={`${
                    slideSetting.Threshold_CyberThreat ==
                    notifSetting.Threshold_CyberThreat_Global * 33
                      ? "btn-disable"
                      : "light-red-btn"
                  } align-self-center`}
                  id="Sensitivity_CyberScore_Reset"
                  onClick={() => {
                    handleReset(
                      "Threshold_CyberThreat_Reset",
                      "Btn_Threshold_Threat_Enabled",
                      "Threshold_CyberThreat",
                      null,
                      33
                    );
                  }}
                  style={{ minWidth: "70px" }}
                >
                  {notifSetting.Btn_Threshold_Threat_Enabled == 2 ? (
                    <Spinner size="sm" animation="border" />
                  ) : (
                    "Reset"
                  )}
                  {notifSetting.Btn_Threshold_Threat_Enabled == 3 && (
                    <FiCheck />
                  )}
                </Button>
              )}
            </div>
            <div>
              {Object.keys(slideSetting).length !== 0 && (
                // <CustomSlider
                //   type="thershold"
                //   value={slideSetting.Threshold_CyberThreat}
                //   setValue={handleSlideChange}
                //   marks={threatLevel}
                //   step={33}
                //   classes={
                //     "thershold tslider-" + slideSetting.Threshold_CyberThreat
                //   }
                //   valueLabelDisplay="off"
                //   slideName="Threshold_CyberThreat"
                //   handleSlider={() =>
                //     handleSave(
                //       "Threshold_CyberThreat",
                //       slideSetting.Threshold_CyberThreat / 33
                //     )
                //   }
                // />
                <ThresholdSlider
                  defaultValue={slideSetting.Threshold_CyberThreat}
                  value={slideSetting.Threshold_CyberThreat}
                  step={33}
                  min={0}
                  max={100}
                  id="Threshold_CyberThreat"
                  onChange={(e) =>
                    handleSlideChange(
                      "Threshold_CyberThreat",
                      Math.ceil(e.target.value)
                    )
                  }
                  onMouseUp={() => {
                    TrackUser("Alert notifications set");
                    handleSave(
                      "Threshold_CyberThreat",
                      slideSetting.Threshold_CyberThreat / 33
                    );
                  }}
                  labelData={["None", "Low", "Moderate", "High"]}
                />
              )}
            </div>
            <hr />
            <div className="d-flex justify-content-between mt40px">
              <div>
                <h1 className="mt40px">CyberCompliance</h1>
                <div className="linkGreyText">
                  Min Threshold is {slideSetting.Threshold_CyberCompliance}
                </div>
              </div>
              {notifSetting["Btn_Threshold_Compliance_Enabled"] !=
                undefined && (
                <Button
                  className={`${
                    slideSetting.Threshold_CyberCompliance ==
                    notifSetting.Threshold_CyberCompliance_Global
                      ? "btn-disable"
                      : "light-red-btn"
                  } align-self-center`}
                  id="Sensitivity_CyberScore_Reset"
                  onClick={() => {
                    handleReset(
                      "Threshold_CyberCompliance_Reset",
                      "Btn_Threshold_Compliance_Enabled",
                      "Threshold_CyberCompliance",
                      null,
                      1
                    );
                  }}
                  style={{ minWidth: "70px" }}
                >
                  {notifSetting.Btn_Threshold_Compliance_Enabled == 2 ? (
                    <Spinner size="sm" animation="border" />
                  ) : (
                    "Reset"
                  )}
                  {notifSetting.Btn_Threshold_Compliance_Enabled == 3 && (
                    <FiCheck />
                  )}
                </Button>
              )}
            </div>
            <div>
              {Object.keys(slideSetting).length !== 0 && (
                // <CustomSlider
                //   type="thershold"
                //   value={slideSetting.Threshold_CyberCompliance}
                //   setValue={handleSlideChange}
                //   marks={thershold}
                //   step={1}
                //   classes={
                //     "thershold countingSlider-" +
                //     slideSetting.Threshold_CyberCompliance
                //   }
                //   slideName="Threshold_CyberCompliance"
                //   handleSlider={() =>
                //     handleSave(
                //       "Threshold_CyberCompliance",
                //       slideSetting.Threshold_CyberCompliance
                //     )
                //   }
                // />
                <ThresholdSlider
                  defaultValue={slideSetting.Threshold_CyberCompliance}
                  value={slideSetting.Threshold_CyberCompliance}
                  step={1}
                  min={0}
                  max={100}
                  id="Threshold_CyberCompliance"
                  onChange={(e) =>
                    handleSlideChange(
                      "Threshold_CyberCompliance",
                      e.target.value
                    )
                  }
                  onMouseUp={() => {
                    TrackUser("Alert notifications set");

                    handleSave(
                      "Threshold_CyberCompliance",
                      slideSetting.Threshold_CyberCompliance
                    );
                  }}
                  labelData={["0", "20", "40", "60", "80", "100"]}
                />
              )}
            </div>
          </Col>
        </Row>
      </div>
      {ownerType == 1 && (
        <div className="settings_container mt16px mb16px">
          <Row className="m-0">
            <Col md={6}>
              {notifSetting.AllowSchedule ? (
                <Fragment>
                  <h1>Schedule your C-Suite Cyber Report</h1>
                  <div className="linkGreyText">
                    The C-Suite Cyber Report is a snapshot of all your key cyber
                    security data including your:
                  </div>
                </Fragment>
              ) : (
                <Fragment>
                  <h1>Generate Security Report</h1>
                  <div className="greyText mt16px" style={{ width: "280px" }}>
                    You will receive a report to your e-mail address specified
                    during registration mail@site.com
                  </div>
                </Fragment>
              )}
              <div className="mt40px">
                <div>
                  <div className="textBlackBold">
                    My Scheduled Report should include:
                  </div>
                  <div className="d-flex" style={{ marginTop: "16px" }}>
                    <div className="position-relative">
                      <Form.Check
                        type="switch"
                        id="switch2"
                        defaultChecked={notifSetting.Btn_Browser_Allow}
                        className="m-0"
                        onClick={(e) =>
                          handleSave("KPI_Report", +e.target.checked, "switch")
                        }
                        // defaultChecked={notifSetting.KPI_Report}
                      />
                    </div>
                    <span style={{ marginTop: "2px" }} className="linkGreyText">
                      Primary KPIs
                    </span>
                  </div>
                  <div className="d-flex" style={{ marginTop: "16px" }}>
                    <div className="position-relative">
                      <Form.Check
                        type="switch"
                        id="switch11"
                        defaultChecked={notifSetting.Btn_Browser_Allow}
                        className="m-0"
                        onClick={(e) =>
                          handleSave("Role_Report", +e.target.checked, "switch")
                        }
                        // onChange={handleChange}
                        // defaultChecked={notifSetting.Role_Report}
                      />
                    </div>
                    <span style={{ marginTop: "2px" }} className="linkGreyText">
                      {" "}
                      Role Based Cyber Data for all roles
                    </span>
                  </div>
                  <div className="d-flex" style={{ marginTop: "16px" }}>
                    <div className="position-relative">
                      <Form.Check
                        type="switch"
                        id="switch12"
                        defaultChecked={notifSetting.Btn_Browser_Allow}
                        className="m-0"
                        onClick={(e) =>
                          handleSave(
                            "Trend_Report",
                            +e.target.checked,
                            "switch"
                          )
                        }
                        // onChange={handleChange}
                        // defaultChecked={notifSetting.CyberTrend_Report}
                      />
                    </div>
                    <span style={{ marginTop: "2px" }} className="linkGreyText">
                      {" "}
                      Trend Data
                    </span>
                  </div>
                  <div className="d-flex" style={{ marginTop: "16px" }}>
                    <div className="position-relative">
                      <Form.Check
                        type="switch"
                        id="switch13"
                        defaultChecked={notifSetting.Btn_Browser_Allow}
                        className="m-0"
                        onClick={(e) =>
                          handleSave(
                            "Drilldown_Report",
                            +e.target.checked,
                            "switch"
                          )
                        }
                        // onChange={handleChange}
                        // defaultChecked={notifSetting.TechDrillDown_Report}
                      />
                    </div>
                    <span style={{ marginTop: "2px" }} className="linkGreyText">
                      {" "}
                      Technical Drilldown
                    </span>
                  </div>
                  <div className="d-flex" style={{ marginTop: "16px" }}>
                    <div className="position-relative">
                      <Form.Check
                        type="switch"
                        id="switch14"
                        defaultChecked={notifSetting.Btn_Browser_Allow}
                        className="m-0"
                        onClick={(e) =>
                          handleSave(
                            "Compliance_Report",
                            +e.target.checked,
                            "switch"
                          )
                        }
                        // onChange={handleChange}
                        // defaultChecked={notifSetting.CompAndGov_Report}
                      />
                    </div>
                    <span style={{ marginTop: "2px" }} className="linkGreyText">
                      {" "}
                      Compliance Manager summary
                    </span>
                  </div>
                  <ButtonGroup
                    toggle
                    className="mt40px mx-auto w-50 p-0 switchFocus"
                  >
                    {periods.map((radio, idx) => (
                      <ToggleButton
                        key={idx}
                        type="radio"
                        variant="secondary"
                        name="radios"
                        value={radio.value}
                        checked={notifSetting.Report_Period === radio.value}
                        onChange={() => {
                          setNotifSetting({
                            ...notifSetting,
                            Report_Period: radio.value,
                          });
                          handleSave("Report_Period", radio.value, "switch");
                        }}
                        className={`p-1 ${idx == 0 && "border-left-rounded"} ${
                          idx == periods.length - 1 && "border-right-rounded"
                        }`}
                      >
                        {radio.name}
                      </ToggleButton>
                    ))}
                  </ButtonGroup>
                  <div className="d-flex mt24px">
                    {notifSetting.Report_Period == "1" && (
                      <Fragment>
                        <div>
                          {weekDays.map((data) => (
                            <Button
                              className={
                                notifSetting.Report_DayOfWeek == data.value
                                  ? "btn-blue"
                                  : "btn-grey"
                              }
                              style={{ marginRight: "2px" }}
                              onClick={(e) => {
                                setNotifSetting({
                                  ...notifSetting,
                                  Report_DayOfWeek: data.value,
                                });
                                handleSave(
                                  "Report_DayOfWeek",
                                  data.value,
                                  "switch"
                                );
                              }}
                            >
                              {data.name}
                            </Button>
                          ))}
                        </div>
                        <div
                          style={{ borderRight: "1px solid #EBECF1" }}
                          className="ml16px mr16px"
                        ></div>
                      </Fragment>
                    )}
                    <div className="d-flex justify-content-between">
                      {notifSetting.Report_Period == "2" && (
                        <InputGroup
                          className="mr8px small-input"
                          style={{ minWidth: "150px" }}
                        >
                          <InputGroup.Prepend>
                            <InputGroup.Text className="greyText">
                              Day:
                            </InputGroup.Text>
                          </InputGroup.Prepend>
                          <Form.Control
                            as="select"
                            selected={notifSetting.Report_DayTxt}
                            name="TechnologyEnum"
                            onChange={(e) =>
                              handleSave("Report_Day", e.target.value, "switch")
                            }
                          >
                            <option hidden>{notifSetting.Report_DayTxt}</option>
                            {monthDays.map((data) => (
                              <option value={data.value}>{data.name}</option>
                            ))}
                          </Form.Control>
                        </InputGroup>
                      )}
                      {notifSetting.Report_Period != "0" && (
                        <InputGroup
                          className="small-input"
                          style={{ minWidth: "170px" }}
                        >
                          <InputGroup.Prepend>
                            <InputGroup.Text className="greyText">
                              Time:
                            </InputGroup.Text>
                          </InputGroup.Prepend>
                          <Form.Control
                            as="select"
                            defaultValue={notifSetting.Report_TimeTxt}
                            name="TechnologyEnum"
                            onChange={(e) =>
                              handleSave(
                                "Report_Time",
                                e.target.value,
                                "switch"
                              )
                            }
                          >
                            <option hidden>
                              {notifSetting.Report_TimeTxt}
                            </option>
                            {dayTime.map((data) => (
                              <option value={data.value}>{data.name}</option>
                            ))}
                          </Form.Control>
                        </InputGroup>
                      )}
                    </div>
                  </div>
                  {/* <div className="greyText mt16px">
                    {selectedPeriod ? selectedPeriod.message : ""}
                  </div> */}
                </div>
              </div>
            </Col>
            {!notifSetting.AllowSchedule && (
              <Col md={6} className="d-table w-100 report-box">
                <div className="d-table-cell align-middle">
                  <div className="txt-blue heading4">
                    Want to schedule these reports?
                  </div>
                  <div className="mt40px">
                    This feature is available for Premium Subscriptions only.
                    Upgrade your account.
                  </div>
                  <Button className="w-100 submit-btn mt40px">
                    Upgrade Now
                  </Button>
                </div>
              </Col>
            )}
          </Row>
        </div>
      )}
    </Fragment>
  );
};

export default Notifications;
