import useMspTechnologySpendData from "../../msp-technology-spend.data";
import { useEffect, useState, useRef } from "react";
import DatePickerDropdown from "./date-picker/date-picker.dropdown";
import { MspDonutChartForDashboard } from "../../../MSPv2/MspAttackSurface.js/MspDonutChart";
import styles from './technology-spend.styles.module.scss';
import ExternalLinkIcon from "../../../icons/external-link.icon";
import dateUtils from "../../../../Utils/date.utils";
import ContentLoader from "react-content-loader";
import { useHistory } from "react-router-dom/cjs/react-router-dom";

const TechnologySpend = ({ customerId, allCategoriesLink }) => {
  const graphRef = useRef();
  const [changeHappened, setChangeHappened] = useState(false);
  const [dateSelectionValue, setDateSelectionValue] = useState(null);
  const { data, loading, fetchTechnologySpend } = useMspTechnologySpendData();
  const history = useHistory();

  function getSpendDataForGraph() {
    if (data?.TechnologySpend_SummaryList?.length > 0) {
      const dummyColors = ["#FFCA78", "#DF6844", "#EB9E87", "#F36060"];
      let res = [...data?.TechnologySpend_SummaryList]

        .sort((a, b) => a.ColourSortOrder - b.ColourSortOrder)
        .filter((item) => item?.CategoryValue_Total > 0)
        .map((item, itemIndex) => ({
          label: item?.CategoryName,
          value: item?.[`CategoryValue_Total`],
          color:
            item?.CategoryColourHexCode ??
            dummyColors[itemIndex % dummyColors.length],
          colorId: item?.CategoryId,
        }));

      return res;
    } else {
      return [];
    }
  }

  const onAllCategoriesClick = () => {
    if (!allCategoriesLink) return;
    history.push(allCategoriesLink);
  }

  useEffect(() => {
    if (!dateSelectionValue) return;
    fetchTechnologySpend({
      customerId: customerId,
      fromDate: dateSelectionValue?.startDate?.toISOString(),
      toDate: dateSelectionValue?.endDate?.toISOString(),
    })
  }, [customerId, dateSelectionValue]);
  

  return (
    <div className={styles['technology-spend']}>
      <div className="highground-royal-font f-700 f-16 d-flex align-items-center justify-content-between">
        <span>Technology Spend</span>
        <DatePickerDropdown
          setDateSelection={(dateSelection) => {
            setDateSelectionValue(dateSelection);
          }}
        />
      </div>
      {loading ? (
        <div>
          <ContentLoader 
            speed={2}
            width={"100%"}
            height={400}
            viewBox="0 0 1000 400"
            backgroundColor="#f3f3f3"
            foregroundColor="#ecebeb"
          >
            <rect x="0" y="10" rx="5" ry="5" width="100%" height="400" />
          </ContentLoader>
        </div>
      ) : (
        <div className={styles['data-container']}>
          <div className={styles['section']}>
            <h3 className={`${styles['text']} m-0 f-grey ml-1`}>
              Top 10 category spend
            </h3>
            <ul className={styles['list']}>
              {data?.TechnologySpend_SummaryList?.map(
                (spendItem, spendItemIndex) => {
                  return (
                    <li 
                      key={spendItemIndex} className={styles['list-item']}
                      onMouseEnter={() => {
                        setChangeHappened(!changeHappened);
                        graphRef.current.expandSpecificSegement(
                          spendItem.CategoryId
                        );
                      }}
                      onMouseLeave={() => {
                        graphRef.current.expandSpecificSegement(null);
                      }}
                    >
                      <div className="d-flex align-items-center justify-content-between">
                        <div className="d-flex align-items-center f-500 f-grey">
                          <div
                            style={{
                              width: "6px",
                              height: "6px",
                              background: spendItem?.CategoryColourHexCode,
                            }}
                            alt=""
                            className="mr-2 rounded-full"
                          />
                          <span className={`${styles['text']} f-grey`}>
                            {spendItem?.CategoryName}
                          </span>
                        </div>
                        <div className={`${styles['text']} f-black`}>
                          {spendItem[`CategoryValue_Total_Text`]}
                        </div>
                      </div>
                    </li>
                  );
                }
              )}
            </ul>
            <div className="d-flex">
              <div 
                className={`${styles['link-section']} cursor-pointer`}
                onClick={onAllCategoriesClick}
              >
                <ExternalLinkIcon width="12px" height="12px" fill="#616778" />
                <span className={`${styles['text']} f-grey ml-2`}>
                  View all Categories
                </span>
              </div>
            </div>
          </div>
          <div className={styles['section']}>
            <div className={styles['right-section']}>
              <div>
                <div className="d-flex justify-content-between align-items-center">
                  <h3 className={`${styles['text']} m-0 f-grey`}>
                    Spend
                  </h3>
                  {dateSelectionValue && (
                    <span className="f-darkgrey f-12" style={{ lineHeight: '18px' }}>
                      {dateUtils.convertDateFormat(dateSelectionValue?.startDate)} - {dateUtils.convertDateFormat(dateSelectionValue?.endDate)}
                    </span>
                  )}
                </div>
                <div className="f-18 f-500 f-black mt-2">
                  {
                    data?.[
                      `CategoryValue_Total_Text`
                    ]
                  }
                </div>
              </div>
              <div className={styles['chart-container']}>
                <div className="d-flex align-items-center justify-content-center progress_bar_x">
                  <div className="w-fit-content position-relative">
                    <div className="chart w-100">
                      <MspDonutChartForDashboard
                        height={200}
                        graphData={getSpendDataForGraph()}
                        forwardedRef={graphRef}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles['currency-container']}>
                <div
                  className="d-flex align-items-center mt-3"
                  id="currency-tag"
                >
                  <div className={`${styles['text']} f-grey`}>
                    Currency:
                  </div>
                  <div className={`${styles['text']} f-grey ml-1`}>
                    {`${data?.CurrencyCode_Selected?.CurrencyName} - ${data?.CurrencyCode_Selected?.FullCurrencyName}`}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default TechnologySpend;
