import React from "react";
import "./attackSurfaceTooltip.scss";

const AttackSurfaceTooltip = ({
  children,
  content,
  top,
  className,
  disabled,
  topLeft,
  bottomLeft,
  toolTipStyle
}) => {
  return (
    <div
      className={`   ${
        top
          ? "hg-tooltip-top"
          : topLeft
          ? "hg-tooltip-topLeft"
          : bottomLeft
          ? "hg-tooltip-bottomLeft"
          : "hg-tooltip-bottom"
      }  ${className}  `}
    >
      {children}

      <span className={`tooltiptext  ${disabled ? "d-none" : ""}    `}
      
      style={toolTipStyle}
      
      >
        {content}
      </span>
    </div>
  );
};

export default AttackSurfaceTooltip;
