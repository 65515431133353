import React, { useState, useEffect } from "react";
import { Button, Col, Dropdown, Row } from "react-bootstrap";
import { getAPIData } from "../../../Utils/commonApi";
import { TrackUser } from "../../../Utils/SegmentFunctions";
import { useAuth } from "../../context/Auth";
import { CallToast } from "../../PriceCalculator/modals/CustomToast";

import "./active-sessions.scss";
import ActiveSessionsLoader from "./ActiveSessionsLoader";
import TestNotificationModal from "./TestNotificationModal";

const ActiveSessions = () => {
  const { authTokens } = useAuth();
  const [notificationModal, setNotificationModal] = useState(false);
  const [personalDevices, setPersonalDevices] = useState([]);
  const [deviceId, setDeviceId] = useState("");
  const [mainPageLoading, setMainPageLoading] = useState(false);

  const hideNotificationModal = () => {
    setNotificationModal(false);
  };
  const showNotificationModal = () => {
    setNotificationModal(true);
  };
  useEffect(() => {
    setMainPageLoading(true);
    getdeviceData();
  }, []);

  async function getdeviceData() {
      await getAPIData(102, authTokens).then((response) => {
      setMainPageLoading(false);
      if (response?.mr?.Success) {
        setPersonalDevices(response);
      } else {
        CallToast("Something went wrong", false, false, "v2style");
      }
    });
  }

  async function RemoveDevice(enum1) {
    await getAPIData(105, authTokens, {
      optionEnum1: enum1,
    })
      .then((result) => {
        if (result.Success) {
          TrackUser("Device deleted");
          getdeviceData();
          CallToast("Device removed successfully", false, false, "v2style");
        } else {
          CallToast("Unable to update", false, false, "v2style");
        }
      })
      .catch((err) => {
        console.log(err);
        CallToast("Something went wrong", false, false, "v2style");
      });
  }

  return (
    <>
      {mainPageLoading && <ActiveSessionsLoader />}
      <div
        className={`active-sessions-wrapper ${mainPageLoading ? "d-none" : ""}`}
      >
        <h3>Active Sessions</h3>
        <p>Devices</p>

        <Row>
          <div className=" w-100">
            <Col xs={12}>
              {personalDevices.UserDevices &&
                personalDevices.UserDevices.map((userDevice, deviceIndex) => {
                  return (
                    <div className="d-flex justify-content-between device-wrap mb-2 devices-wrapper ">
                      <div className="d-flex align-items-center">
                        <img alt="" src="/images/mobile.svg" className="mr-3" />
                        <div className="device-name">{userDevice.Detail}</div>
                      </div>
                      <div className="d-flex align-items-center">
                        <div className="device-date">
                          {userDevice.DateAdded}
                        </div>
                        {personalDevices?.UserDevices?.[0].DeviceId !== 0 && (
                          <>
                            <Dropdown className="device-dropdown">
                              <Dropdown.Toggle>
                                <img alt="" src="/images/horizontal-dots.svg" />
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                <div>
                                  <Row>
                                    <Col xs={12}>
                                      <div
                                        className="notification-btn bg-hover-grey-light"
                                        onClick={(e) => {
                                          setDeviceId(userDevice?.DeviceId);
                                          showNotificationModal();
                                          e.target
                                            .closest(".device-dropdown")
                                            .click();
                                        }}
                                      >
                                        Send Notifications
                                      </div>
                                    </Col>
                                    <hr
                                      className=" line-divider-light"
                                      style={{
                                        width: "67%",
                                        margin: "auto",

                                        marginTop: "4px",
                                        marginBottom: "4px",
                                      }}
                                    />
                                    <Col xs={12}>
                                      <div
                                        className="remove-btn  bg-hover-grey-light"
                                        onClick={(e) => {
                                          RemoveDevice(userDevice?.DeviceId);
                                          e.target
                                            .closest(".device-dropdown")
                                            .click();
                                        }}
                                      >
                                        Remove
                                      </div>
                                    </Col>
                                  </Row>
                                </div>
                              </Dropdown.Menu>
                            </Dropdown>
                          </>
                        )}
                      </div>
                    </div>
                  );
                })}
            </Col>
          </div>

          {/* Log out Button */}
          <Col xs={12}>
            <div className="d-flex justify-content-end mt-2 logout">
              {personalDevices?.UserDevices?.[0].DeviceId !== 0 && (
                <Button className="logout-btn mr-3">
                  Log out all sessions
                </Button>
              )}
            </div>
          </Col>
        </Row>
        <TestNotificationModal
          show={notificationModal}
          hideModal={hideNotificationModal}
          deviceId={deviceId}
          cyberData={personalDevices}
        />
      </div>
    </>
  );
};

export default ActiveSessions;
