import React from "react";

export const TableElement = (
  heading,
  value,
  headingColour = "#B6B6BF",
  valueColour = ""
) => (
  <div className="tableElement">
    <p style={headingColour !== undefined ? { color: headingColour } : {}}>
      {heading}
    </p>
    <p style={valueColour !== undefined ? { color: valueColour } : {}}>
      {value}
    </p>
  </div>
);

export function isValidEmail(_email) {
  const re =
    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
  return re.test(_email);
}
