import React, { useState } from "react";
import { Modal, Form, Col, Row, Button } from "react-bootstrap";
import { getAPIData, postRequest } from "../../../Utils/commonApi";
import { useAuth } from "../../context/Auth";
import { CallToast } from "./CustomToast";
import "./modals.scss";

function UpdateScenarioModal(props) {
  const { authTokens } = useAuth();
  const {
    closeAssetModal,

    setValues,
    isOpen,
  } = props;
  const [isSaved, setIsSaved] = useState(0);
  const [isConfirm, setIsConfirm] = useState(false);

  const closeModal = () => closeAssetModal();

  const { users, workstations, servers, mobiledevices, domains } =
    setValues.state.assetModalValues;

  const AddEditScenario = async () => {
    await postRequest(authTokens, "UpdateUserSettingsAssetCounts", {
      UpdateTarget: isSaved,
      // Name: setValues.state.tabs[activeTab]?.Name || "Untitled",
      NoOfUsers: users,
      NoOfDomains: domains,
      NoOfWorkstation: workstations,
      NoOfServers: servers,
      NoOfMobileDevices: mobiledevices,

      accessKey: JSON.parse(localStorage.getItem("user_MessageObj")),
    }).then(async (response) => {
      setIsSaved(0);

      if (response.Success === true) {
        closeModal();
        setValues.setState({
          updateScenarioModal: false,
          showAssetModal: false,
        });
        setIsConfirm(false);
        if (isSaved == 1) {
          CallToast(
            "Default quantity of assets was successfuly saved for new scenarios",
            false
          );
        }
        if (isSaved == 2) {
          CallToast(
            "Default quantity of assets was successfuly saved for all existing scenarios",
            false
          );
        }

        const response = await getAPIData(211, authTokens);

        if (response?.mr?.Success) {
          setValues.setState({ scenarioData: response });
        }
      } else {
        CallToast("Something went wrong");
        setValues.setState({
          updateScenarioModal: false,
        });
      }
    });
  };

  return (
    <Modal
      animation={false}
      show={isOpen}
      dialogClassName="modal-50w"
      className="qty-asset-modal integrationModal updatecompanymodal"
      aria-labelledby="asset-modal"
      centered
      scrollable
      onHide={() => {
        closeModal();
      }}
    >
      <Modal.Body>
        {isConfirm ? (
          <Row className="">
            <Col xs={12} className="font-bold-14">
              Are you sure?
            </Col>

            <Col xs={12}>
              <p className="mt-4 clrgry">
                {isSaved == 1 &&
                  "You are about to change the quantities for new scenarios."}
                {isSaved == 2 &&
                  "You are about to change the quantities for all existing scenarios, which will also update the price of those scenarios."}
              </p>
            </Col>
          </Row>
        ) : (
          <Row className="">
            <Col xs={12} className="font-bold-14 clrgry">
              Update Scenarios:
            </Col>

            <Col xs={12}>
              <Form.Group
                controlId={"check"}
                className={`round-checkbox blue-checkbox checkboxcompny mt-3`}
              >
                <Form.Check
                  type={"radio"}
                  label=""
                  value="isSaved"
                  onClick={() => setIsSaved(1)}
                  name="isChecked"
                />
                <span className="ml-4 pl-2">Save for new scenarios only</span>
              </Form.Group>
              <Form.Group
                controlId={"check2"}
                className={`round-checkbox blue-checkbox checkboxcompny mt-3`}
              >
                <Form.Check
                  type={"radio"}
                  value="isUpdate"
                  label=""
                  name="isChecked"
                  onClick={() => setIsSaved(2)}
                />
                <span className="ml-4 pl-2">Update all existing scenarios</span>
              </Form.Group>
            </Col>
          </Row>
        )}
      </Modal.Body>

      <Modal.Footer>
        <Row className="m-0 ">
          {isConfirm ? (
            <Col xs={12} className="p-0 d-flex justify-content-end">
              <Button
                className="cancel-button"
                onClick={() => {
                  // setIsSaved("");
                  setIsConfirm(false);
                  setValues.setState({
                    updateScenarioModal: false,
                    showAssetModal: false,
                  });
                  closeModal();
                }}
              >
                No, Cancel
              </Button>
              <Button
                className="save-button"
                onClick={() => {
                  AddEditScenario();
                }}
              >
                Yes, Update
              </Button>
            </Col>
          ) : (
            <Col xs={12} className="p-0 d-flex justify-content-end">
              <Button
                className="cancel-button"
                onClick={() => {
                  // setIsSaved("");
                  setValues.setState({
                    updateScenarioModal: false,
                    showAssetModal: false,
                  });
                  closeModal();
                }}
              >
                Cancel
              </Button>
              <Button
                className="save-button"
                onClick={() => {
                  if (!isSaved) {
                    CallToast("Please select one option");
                    return;
                  }
                  if (isSaved == 1) {
                    AddEditScenario();
                  }
                  if (isSaved == 2) {
                    setIsConfirm(true);
                  }
                }}
              >
                Save
              </Button>
            </Col>
          )}
        </Row>
      </Modal.Footer>
    </Modal>
  );
}

export default UpdateScenarioModal;
