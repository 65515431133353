import { BASE_API_ROUTES } from "../constants/apis.constants";
import { useAuth } from "../Components/context/Auth";
import { IndependentGetAPIData } from "../Utils/commonApi";
import { BASE_API_PAGE_ENUMS } from "../constants/apis.constants";
import { CallToast } from "../Components/PriceCalculator/modals/CustomToast";
import userUtils from "../Utils/user.utils";
import httpUtils from "../Utils/http.utils";

const useApiActions = () => {
  const { authTokens } = useAuth();

  const onSuccess = () => {
    CallToast("Updated successfully", false, false, "v2style");
  }

  const onError = () => {
    CallToast("Something went wrong", false, false, "v2style");
  }

  const getAllIntegrationsByTechType = async ({ 
    technologyType, 
    showAll = false,
    searchText = ''  
  }) => {
    try {
      const message = userUtils.getUserMessage();
      const formData = new FormData();
      formData.append('TechnologyType', technologyType);
      formData.append('ShowAll', showAll);
      formData.append('Search_text', searchText);
      formData.append('accessKey', message);
      return fetch(
        BASE_API_ROUTES.getAllIntegrationsByTechType,
        {
          method: "POST",
          body: formData,
          headers: {
            Authorization: `Bearer ${authTokens}`,
          },
        }
      ).then((response) => response.json());
    } catch (error) {
      console.error('Error fetching integrations', error);
    }
  }

  const saveIntegrationsInfo = async (integrationInfoList) => {
    try {
      const message = userUtils.getUserMessage();
      const formData = new FormData();
      formData.append('IntegrationInfo_List', JSON.stringify(integrationInfoList));
      formData.append('AccessKey', message);
      return fetch(
        BASE_API_ROUTES.saveIntegrationsInfo,
        {
          method: "POST",
          body: formData,
          headers: {
            Authorization: `Bearer ${authTokens}`,
          },
        }   
      ).then((response) => {
        if (response.status < 400) {
          onSuccess();
        } else {
          onError();
        }
        return response.json();
      });
    } catch (error) {
      console.error('Error saving integrations info', error);
    }
  }

  const renameIntegration = async (_integrationId, newName) => {
    return IndependentGetAPIData(BASE_API_PAGE_ENUMS.RenameCustomIntegrationName, authTokens, {
        optionEnum1: _integrationId,
        optionStrEnum1: newName,
      });
  }
  
  const submitNotificationSettings = async ({ 
    notificationValue, 
    notificationName,
  }) => {
    try {
      const message = userUtils.getUserMessage();
      const formData = new FormData();
      const userInfo = userUtils.getUserInfo();
      formData.append('OwnerType', 0);
      formData.append('NotificationName', notificationName);
      formData.append('NotificationValue', notificationValue);
      formData.append('optionEnum98', userInfo.UserDetail.OrgId);
      formData.append('optionEnum99', 0);
      formData.append('SettingOwner', 0);
      formData.append('AccessKey', message);
      return fetch(
        BASE_API_ROUTES.submitNotificationSettings,
        {
          method: "POST",
          body: formData,
          headers: {
            Authorization: `Bearer ${authTokens}`,
          },
        }   
      ).then((response) => {
        if (response.status < 400) {
          onSuccess();
        } else {
          onError();
        }
        return response.json();
      });
    } catch (error) {
      console.error('Error submiting notification settings', error);
    }
  }

  const getClientsDashboardV2 = async (customerId) => {
    try {
      const message = userUtils.getUserMessage();
      const query = httpUtils.generateQueryString({
        ...(customerId ? {
          ObjId_Customer: customerId,
          optionEnum98: customerId,
        } : {
          ObjId_Customer: "0",
        }),
        accessKey: message,
      });
      return fetch(
        `${BASE_API_ROUTES.getClientDashboardV2}${query}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${authTokens}`,
          },
        }   
      ).then((response) => response.json());
    } catch (error) {
      console.error('Error fetching clients dashboard', error);
    }
  }

  const getTechnologySpend = async ({ customerId, fromDate, toDate }) => {
    try {
      const message = userUtils.getUserMessage();
      const query = httpUtils.generateQueryString({
        accessKey: message,
      });
      const formData = new FormData();
      if (customerId) {
        formData.append('ObjId_Customer', customerId);
        formData.append('optionEnum98', customerId);
        formData.append('optionEnum99', 24);
      } else {
        formData.append('ObjId_Customer', 0);
        formData.append('optionEnum99', 32);
      }
      formData.append('FromDate', fromDate);
      formData.append('ToDate', toDate);
      return fetch(
        `${BASE_API_ROUTES.getTechnologySpend}${query}`,
        {
          method: "POST",
          body: formData,
          headers: {
            Authorization: `Bearer ${authTokens}`,
          },
        }   
      ).then((response) => response.json());
    } catch (error) {
      console.error('Error fetching technology spend', error);
    }
  }

  return {
    getAllIntegrationsByTechType,
    saveIntegrationsInfo,
    renameIntegration,
    submitNotificationSettings,
    getClientsDashboardV2,
    getTechnologySpend
  }
}

export default useApiActions
