import React, { useState, useEffect } from "react";
import {
  Button,
  Modal,
  Row,
  Col,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import "./index.scss";
import { getAPIData } from "../../../../Utils/commonApi";
import { useAuth } from "../../../context/Auth";
import Loader from "../../../Common/loader";

const CancelSubscription = (props) => {
  const { data, cancelSubscription, isLoading } = props;
  const { setData } = props;
  const { authTokens, setAuthTokens } = useAuth();



  return (
    <Modal
      animation={false}
      show={props.isOpen}
      dialogClassName="modal-50w"
      className="integrationModal centerModal dashboardModal downgradeModal cancelsubscription"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      scrollable
    >
        {isLoading && <Loader />}
      <Modal.Header className="text-center ">
        <h1 className="w-100 mb-0 mt-0">{ data && data.Title }</h1>
        <span className="cursor-pointer">
          <img
            onClick={() => props.setIsOpen(false)}
            src="/images/icons/close.svg"
            alt=""
          />
        </span>
      </Modal.Header>
      <Modal.Body>
        <div className="margin_center">
          <h1>{ data && data.Description }</h1>
          <div className="premium_center_cancel">
            <div className="img">
              <img src={`/images/ProServices/${data && data.ProService_Icon}`} alt="" />
            </div>

            <div class="content_wrapper">
              <p className="text-left m-0">{ data &&  data.ProService_Name }</p>
              <p className="m-0 text-left txtblue mt-2">{ data && data.ProService_Price }</p>
            </div>
          </div>
          <div className="premium_wrap">
            {data &&  data.DateMessage}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Row className="m-0 align-items-center justify-content-end">
          <div className="mr-4 crsrpoint" onClick={() => props.setIsOpen(false)}>
            Cancel
          </div>
          <Button
            variant="primary"
            type="submit"
            className="float-right redClr"
            onClick={() => cancelSubscription()}
          >
            Cancel Subscription
          </Button>
        </Row>
      </Modal.Footer>
    </Modal>
  );
};

export default CancelSubscription;
