import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import "../membersAccess/membersAccess.scss"
import { getAPIData } from "../../../Utils/commonApi";
import { useAuth } from "../../context/Auth";


const PreviewAccessModal = ({ show, hideModal, userId, isFromMyProfile, passedOptionEnum99 }) => {
    const { authTokens } = useAuth();
    const [effectivePermission, setEffectivePermission] = useState([]);

    useEffect(() => {
        if (show === true) {
            getEffectivePermission();
        }
    }, [show])


    async function getEffectivePermission() {
        await getAPIData(854, authTokens, isFromMyProfile ? {
            accessKey: JSON.parse(localStorage.getItem("user_MessageObj")),
            optionStrEnum1: userId
        } : {
            accessKey: JSON.parse(localStorage.getItem("user_MessageObj")),
            optionStrEnum1: userId,
            optionEnum99: passedOptionEnum99 ? passedOptionEnum99 : 42
        }).then((response) => {
            if (response.mr?.Success) {
                setEffectivePermission(response.detail);
            }
        });
    }

    return (
        <Modal
            animation={false}
            show={show}
            className="previewAccessModal-container"
            dialogClassName="preview-access-modal"
            aria-labelledby=" "
            onHide={hideModal}
            centered
            scrollable
        >
            <Modal.Header className="previewAccessModal-header">
                <div
                    className="previewAccessModalTitle"
                >
                    Preview access
                </div>
                <span className="cursor-pointer" onClick={() => { hideModal(); }}>
                    <img src="/images/user-detail-close.svg" alt="" />
                </span>
            </Modal.Header>
            <Modal.Body className="previewAccessModal-body hide-scrollbar">
                <div className="modalContent">
                    <div className="previewAccessModalBodyHeader">
                        Access to
                    </div>
                    <div
                        style={{
                            maxHeight: '490px',
                            overflowY: 'auto',
                        }}
                    >
                        {
                            effectivePermission.map((permission) => (
                                <div className="d-flex justify-content-between previewAccessModalBodyContent mr-2">
                                    <div>
                                        {permission?.moduleName}
                                    </div>
                                    <div
                                        style={{
                                            color: `${permission?.selected_AccessState?.labelColor}`
                                        }}
                                    >
                                        {permission?.selected_AccessState?.stateName}
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer className="previewAccessModal-footer">
                <div className="modal-footer-block">
                </div>
            </Modal.Footer>
        </Modal>
    );
};

export default PreviewAccessModal;
