import React from "react";
import ContentLoader from "react-content-loader";

const AuditLogsLoader = () => {
  return (
    <ContentLoader
      speed={2}
      width={"100%"}
      height={"100%"}
      viewBox="0 0 1500 900"
      backgroundColor="#F6F8FB"
      foregroundColor="#ecebeb"
    >
      <rect x="0%" y="10" rx="4" ry="4" width="100%" height="80" />
      <rect x="0%" y="93" rx="4" ry="4" width="100%" height="80" />
      <rect x="0%" y="176" rx="4" ry="4" width="100%" height="80" />
      <rect x="0%" y="259" rx="4" ry="4" width="100%" height="80" />
      <rect x="0%" y="342" rx="4" ry="4" width="100%" height="80" />
      <rect x="0%" y="425" rx="4" ry="4" width="100%" height="80" />
      <rect x="0%" y="508" rx="4" ry="4" width="100%" height="80" />
      <rect x="0%" y="591" rx="4" ry="4" width="100%" height="80" />
      <rect x="0%" y="674" rx="4" ry="4" width="100%" height="80" />
    
    </ContentLoader>
  );
};

export default AuditLogsLoader;
