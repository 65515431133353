import React, { useEffect, useState } from "react";
import ComplianceManagerCard from "./ComplianceManagerCard";
import CyberEssentialTable, {
  BCDRPlanTable,
  IncidentResponseTable,
} from "./CyberEssentialTable";
import { getAPIData, postData } from "../../../Utils/commonApi";
import { useAuth } from "../../context/Auth";
import ContentLoader from "react-content-loader";
import { CallToast } from "../../PriceCalculator/modals/CustomToast";
const CyberEssentialScreen = ({ goTo, complianceSectionPageEnumData }) => {
  const { authTokens } = useAuth();
  const [pageData, setPageData] = useState({});
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    getPageData(complianceSectionPageEnumData);
  }, [complianceSectionPageEnumData]);

  const pageEnumMapping = {
    400: "accessCE",
    401: "accessIRP",
    402: "accessRA",
    403: "accessACR",
    404: "accessISP",
    405: "accessBCDR",
  };

  async function getPageData(complianceSectionPageEnumData) {
    await getAPIData(`${complianceSectionPageEnumData?.pageEnum}`, authTokens, {
      optionEnum1: 0,
      optionEnum2: complianceSectionPageEnumData?.supplierId,
      optionEnum3:
        complianceSectionPageEnumData?.[
          pageEnumMapping[complianceSectionPageEnumData?.pageEnum]
        ],
    })
      .then((response) => {
        setLoading(false);
        if (response?.mr?.Success) {
          setPageData(response);
        } else {
          CallToast("Something went wrong", false, false, "v2style");
        }
      })
      .catch(() => {
        CallToast("Something went wrong", false, false, "v2style");
      });
  }

  return (
    <>
      {loading ? (
        <ScreenLoader />
      ) : (
        <div className="pt-4 px-5 w-100">
          <div className="d-flex">
            {/* Screen for Cyber Essentials */}
            {complianceSectionPageEnumData?.pageEnum == 400 && (
              <>
                <ComplianceManagerCard
                  mainIcon={pageData?.FileUpload?.Icon}
                  title={pageData?.FileUpload?.Title}
                  date={pageData?.FileUpload?.Description}
                  statusIcon={pageData?.FileUpload?.StatusIcon}
                  statusColor={pageData?.FileUpload?.StatusColor}
                  status={pageData?.FileUpload?.Status}
                  downloadURL={pageData?.DownloadURL}
                />
              </>
            )}

            {/* Screen for Incident response Plan */}
            {complianceSectionPageEnumData?.pageEnum == 401 && (
              <>
                {pageData?.IRFileBox_List?.map((IRPitem) => {
                  return (
                    <div className="mx-2">
                      <ComplianceManagerCard
                        mainIcon={IRPitem?.Icon}
                        title={IRPitem?.Title}
                        date={IRPitem?.Description}
                        statusIcon={IRPitem?.StatusIcon}
                        statusColor={IRPitem?.StatusColor}
                        status={IRPitem?.Status}
                        downloadURL={IRPitem?.FileDownload}
                      />
                    </div>
                  );
                })}
              </>
            )}

            {/* Screen for BCDR plan */}
            {complianceSectionPageEnumData?.pageEnum == 405 && (
              <ComplianceManagerCard
                mainIcon={pageData?.FileUpload?.Icon}
                title={pageData?.FileUpload?.Title}
                date={pageData?.FileUpload?.Description}
                statusIcon={pageData?.FileUpload?.StatusIcon}
                statusColor={pageData?.FileUpload?.StatusColor}
                status={pageData?.FileUpload?.Status}
                downloadURL={pageData?.FileUpload?.FileDownload}
              />
            )}
          </div>

          {/* Table for Cyber Esssential */}

          {complianceSectionPageEnumData?.pageEnum == 400 &&
            pageData?.AuditList?.length > 0 && (
              <>
                <div className="f-black f-500 mt-5 mb-3">
                  Cyber Essentials Audit Results
                </div>
                <hr style={{ borderTop: "1px solid #EBEDF1" }} />
                <CyberEssentialTable
                  goTo={goTo}
                  data={pageData?.AuditList}
                  accessLevel={
                    complianceSectionPageEnumData?.[
                      pageEnumMapping[complianceSectionPageEnumData?.pageEnum]
                    ]
                  }
                />
              </>
            )}

          {/* Table Incident Response Table */}

          {complianceSectionPageEnumData?.pageEnum == 401 &&
            pageData?.IRReviewList?.length > 0 && (
              <>
                <div className="f-black f-500 mt-5 mb-3">
                  Incident Response Reviews
                </div>
                <hr style={{ borderTop: "1px solid #EBEDF1" }} />
                <IncidentResponseTable
                  goTo={goTo}
                  data={pageData?.IRReviewList}
                  accessLevel={
                    complianceSectionPageEnumData?.[
                      pageEnumMapping[complianceSectionPageEnumData?.pageEnum]
                    ]
                  }
                />
              </>
            )}

          {/* Table for BCDR plan */}
          {complianceSectionPageEnumData?.pageEnum == 405 &&
            pageData?.DataList?.length > 0 && (
              <>
                <div className="f-black f-500 mt-5 mb-3">BCDR Tests</div>
                <hr style={{ borderTop: "1px solid #EBEDF1" }} />
                <BCDRPlanTable
                  goTo={goTo}
                  data={pageData?.DataList}
                  accessLevel={
                    complianceSectionPageEnumData?.[
                      pageEnumMapping[complianceSectionPageEnumData?.pageEnum]
                    ]
                  }
                />
              </>
            )}
        </div>
      )}
    </>
  );
};

export default CyberEssentialScreen;

const ScreenLoader = () => {
  return (
    <ContentLoader
      speed={2}
      width={"100%"}
      height={"100%"}
      viewBox="0 0 1500 2000"
      backgroundColor="#F6F8FB"
      foregroundColor="#ecebeb"
    >
      <rect x="5%" y="40" rx="17" ry="17" width="26%" height="280" />
      <rect x="33%" y="40" rx="17" ry="17" width="26%" height="280" />
      <rect x="61%" y="40" rx="17" ry="17" width="26%" height="280" />

      <rect x="5%" y="370" rx="4" ry="4" width="20%" height="25" />

      <rect x="5%" y="415" rx="5" ry="5" width="93%" height="80" />
      <rect x="5%" y="498" rx="5" ry="5" width="93%" height="80" />
      <rect x="5%" y="581" rx="5" ry="5" width="93%" height="80" />
      <rect x="5%" y="664" rx="5" ry="5" width="93%" height="80" />
      <rect x="5%" y="747" rx="5" ry="5" width="93%" height="80" />
      <rect x="5%" y="830" rx="5" ry="5" width="93%" height="80" />
    </ContentLoader>
  );
};
