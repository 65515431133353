import React, { useEffect } from "react";
import "./custom-slider.scss";
const CustomSlider = ({
  min,
  max,
  step,
  id,
  defaultValue,
  onChange,
  onMouseUp,
}) => {
  useEffect(() => {
    let value = ((defaultValue - min) / (max - min)) * 100;

    const slider = document.getElementById(id);

    slider.style.background =
      "linear-gradient(to right, #008fd4 0%, #008fd4 " +
      value +
      "%, #fff " +
      value +
      "%, white 100%)";
  }, [defaultValue]);

  function progressColor(rangeValue) {
    let value = ((rangeValue - min) / (max - min)) * 100;

    const slider = document.getElementById(id);

    slider.style.background =
      "linear-gradient(to right, #008fd4 0%, #008fd4 " +
      value +
      "%, #fff " +
      value +
      "%, white 100%)";
  }

  return (
    <input
      type="range"
      min={min}
      max={max}
      step={step}
      // value="50"
      defaultValue={defaultValue}
      onChange={(e) => {
        progressColor(e.target.value);
        onChange(e);
      }}
      className="slider"
      id={id}
      onMouseUp={onMouseUp}
    />
  );
};

export default CustomSlider;
