import React from "react";
import { Button, Form, Modal } from "react-bootstrap";

const PlansModal = ({ show, hideModal }) => {
  const PlansList = [
    {
      title: "Vipre",
      subtitle: "Email Filtering",
      img: "/images/settings-v2/vipre.svg",
    },
    {
      title: "ESET",
      subtitle: "Endpoint Protection",
      img: "/images/settings-v2/eset.svg",
    },
    {
      title: "Connectwise Automate",
      subtitle: "Patch Management",
      img: "/images/settings-v2/connectwise.svg",
    },
    {
      title: "WebTitan",
      subtitle: "Web Filtering",
      img: "/images/settings-v2/webtitan.svg",
    },
    {
      title: "KnowBe4",
      subtitle: "Phishing Training	",
      img: "/images/settings-v2/knowbe.svg",
    },
    {
      title: "ID Agent",
      subtitle: "Dark Web Monitoring",
      img: "/images/settings-v2/idagent.svg",
    },
  ];
  return (
    <Modal
      animation={false}
      show={show}
      dialogClassName="plans-modal"
      className=""
      aria-labelledby="plans-modal"
      centered
      scrollable
      onHide={hideModal}
    >
      <Modal.Body>
        <div className="p-3">
          <div className="heading mt-2 mb-3">
            You are going to switch to a Freemium Plan
          </div>
          <div className="option-wrapper d-flex justify-content-between align-items-start">
            <div>
              <p className="title m-0">Freemium</p>
              <p className="subtitle m-0">Free</p>
            </div>
            <div className="title">£0</div>
          </div>
          <p className="title mt-3">
            Your subscription period is still active and can continue to be used
            until 24th April 2021.
          </p>

          <div className="d-flex align-items-start">
            <img
              src="/images/settings-v2/yellow-info.svg"
              className="mr-2 mt-1"
            />
            <div className="yellow-notification">
              You have more than 3 free integrations. If you unsubscribe, some
              integrations will stop working.
            </div>
          </div>
          <div className="heading-info mt-3 mb-2">
            Choose integration which should be kept 3/3
          </div>

          {PlansList.map((item, i) => {
            return (
              <div className="d-flex justify-content-between align-items-center mb-2">
                <div className="d-flex align-items-center">
                  <img src={item.img} className="mr-2" />
                  <div>
                    <p className="title m-0">{item.title} </p>
                    <p className="subtitle">{item.subtitle}</p>
                  </div>
                </div>

                <div className="mb-3">
                  <Form.Group
                    controlId={"check" + i}
                    className={`round-checkbox blue-checkbox checkboxstyle`}
                  >
                    <Form.Check
                      type={"checkbox"}
                      // checked={}
                      name={"check" + i}
                      label=""
                      onClick={() => {}}
                    />
                  </Form.Group>
                </div>
              </div>
            );
          })}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="d-flex justify-content-end">
          <Button className="cancel-btn" onClick={hideModal}>
            Cancel
          </Button>
          <Button className="downgrade-btn" onClick={hideModal}>
            Downgrade
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default PlansModal;
