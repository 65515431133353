import CyberCompliance from "../../../CyberCompliance/CyberCompliance";
import { MspTechSpendScreenRadarChart } from "../../../MSPv2/mspClientPriceCalculator/MspClientPriceCalculatorRadarChart";
import CyberScore2 from "../../../CyberScore/CyberScore2";
import styles from './cyber-security-posture.module.scss';
import BaseQuestionIcon from "../../../icons/base-question.icon";
import { Button } from "react-bootstrap";
import ArrowDownIcon from "../../../icons/arrow-down.icon";

const CyberSecurityPosture = ({ 
  cyberSecurityPostureData,
  setShowTechnicalDrillDowncyberScoreModal = () => {},
  setShowTechnicalDrillDownCyberComplianceModal = () => {},
}) => {
  return (
    <div className={`${styles['cyber-security-posture']}`}>
      <div className={`${styles['section']} flex-1`}>
        <div className={styles['header']}>
          <div className="d-flex justify-content-between align-items-center mb-2">
            <div className="f-14 f-500 f-grey">
              Cyber Score
            </div>
            {/* <BaseQuestionIcon width="14px" height="14px" fill="#9499AA" /> */}
          </div>
          <div className="f-18 f-500">
            {
              cyberSecurityPostureData?.CommonComponents
                ?.CyberScoreNumber ?? 0
            }
          </div>
        </div>
        <div 
          className={`${styles['chart']} client-report-cyber-score cursor-pointer`}
          onClick={() => {
            setShowTechnicalDrillDowncyberScoreModal(true);
          }}
        >
          <CyberScore2
            key={0}
            size="small"
            scoreNumber={
              cyberSecurityPostureData?.CommonComponents
                ?.CyberScoreNumber ?? 0
            }
            dataStroke={18}
            bottomText={false}
            Width="180"
            Height="180"
            hideLabels={false}
          />
        </div>
      </div>
      <div className={`${styles['section']} flex-1`}>
        <div className={styles['header']}>
          <div className="d-flex justify-content-between align-items-center mb-2">
            <div className="f-14 f-500 f-grey">
              Cyber Resilience
            </div>
            {/* <BaseQuestionIcon width="14px" height="14px" fill="#9499AA" /> */}
          </div>
          <div className="mb-2 f-18 f-500">
            {
              cyberSecurityPostureData?.CEOComponents
                ?.CyberComplianceNumber ?? 0
            }
            %
          </div>
        </div>
        <div 
          className={`${styles['chart']} cursor-pointer`}
          onClick={() => {
            setShowTechnicalDrillDownCyberComplianceModal(true);
          }}
        >
          <CyberCompliance
            ccNumber={
              cyberSecurityPostureData?.CEOComponents
                ?.CyberComplianceNumber ?? 0
            }
            dataStroke={20}
            fontSize={22}
            id="cyber-comliancer"
            wh={180}
            whpx="180px"
          />
        </div>
      </div>
      <div className={`${styles['section']} flex-1`}>
        <div className={styles['header']}>
          <div className="f-14 f-500 f-grey mb-2">
            Cyber Capabilities
          </div>
        </div>
        <div 
          className={`${styles['chart']} position-relative mt-5 client-report-nist-graph d-flex align-items-center justify-content-center cursor-pointer`}
          onClick={() => {
            setShowTechnicalDrillDowncyberScoreModal(true);
          }}
        >
          <MspTechSpendScreenRadarChart
            _datasets={[
              {
                data: [
                  cyberSecurityPostureData
                    ?.CurrentPosture?.Identity ?? 0,
                  cyberSecurityPostureData
                    ?.CurrentPosture?.Protect ?? 0,
                  cyberSecurityPostureData
                    ?.CurrentPosture?.Detect ?? 0,
                  cyberSecurityPostureData
                    ?.CurrentPosture?.Response ?? 0,
                  cyberSecurityPostureData
                    ?.CurrentPosture?.Recover ?? 0,
                ],
                pointRadius: [0, 0, 0, 0, 0],
                datalabels: {
                  display: false,
                },
                backgroundColor: `${"#BBBEC9"}`,
                borderColor: `${"#BBBEC9"}`,
                pointBackgroundColor: "#BBBEC9",
                borderWidth: 1,
                pointLabelFontSize: 0,
                scaleShowLabels: false,
                label: "Current Posture",
              },
            ]}
            activeChart={0}
          />
        </div>
        <div className="d-flex justify-content-center" style={{ paddingBottom: '12px' }}>
          <div 
            className={styles['button']}
            onClick={() => { 
              setShowTechnicalDrillDowncyberScoreModal(true);
            }}
          >
            <span>Map posture</span>
            <div className={styles['icon']}>
              <ArrowDownIcon width="13px" height="13px" fill="#fff" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CyberSecurityPosture;
