import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import './priceChangedModal.scss'
import { getAPIData, postData } from "../../../Utils/commonApi";
import { useAuth } from "../../context/Auth";

const PriceChangedModal = ({
  show,
  productId,
  serviceId,
  fromProducts = false,
  setShowPriceModal = () => { },
  hideModal = () => { }
}) => {
  const { authTokens } = useAuth();
  const [services, setServices] = useState([]);
  const [packages, setPackages] = useState([]);
  console.log('fromProducts', fromProducts)


  useEffect(() => {
    if (show) {
      if (fromProducts) {
        getPackagesAndServices();
      } else  {
        getPackagesOfServices();
      }
    }
  }, [show])

  async function getPackagesAndServices() {
    return new Promise((resolve, reject) => {
      getAPIData(962, authTokens, {
        optionEnum1: productId,
      })
        .then((response) => {
          setServices(response?.ServicesOfProduct_List);
          setPackages(response?.PackageOfProduct_List)
        })
        .catch((error) => {
        });
    });
  }

  async function getPackagesOfServices() {
    return new Promise((resolve, reject) => {
      getAPIData(963, authTokens, {
        optionEnum1: productId,
      })
        .then((response) => {
          setPackages(response?.PackageOfProduct_List);
        })
        .catch((error) => {
        });
    });
  }


  return (
    <Modal
      animation={false}
      show={show}
      dialogClassName="priceChangedModal"
      className="priceChangedModal"
      aria-labelledby="priceChangedModal"
      centered
      scrollable
      onHide={hideModal}
    >
      <Modal.Body className="">
        <div>
          <div className="d-flex align-items-center">
            <img
              width={20}
              height={20}
              alt=""
              src="/images/info-blue.svg"
              className="mr-2"
            />
            <div className="f-500 f-16 f-black line-height-28 letter-space-2">
              {fromProducts ? 'Product' : 'Service'} price has changed
            </div>
          </div>

          <div className="f-400 f-14 f-black line-height-22 letter-space-2 mt-2">
            {fromProducts ? 'Would you like to update this products pricing in all packages and services where this product has been included?'
              : 'Would you like to update pricing for all packages where this service has been included?'}
          </div>
          <div className="f-400 f-12 greyText2 line-height-18 letter-space-2 mt-1">
            You can manually update the price in each {fromProducts ? 'service' : 'package'}.
          </div>

          <div className="grey-textarea mt-2">
            {
              fromProducts ? <>
                {
                  services?.length == 0 ?
                    <div className="f-400 f-14 greyText2 line-height-18 letter-space-2 mb-2 mt-1">
                      No Services
                    </div> :
                    <div>
                      <div className="f-400 f-14 greyText2 line-height-18 letter-space-2 mb-2">
                        {services?.length} {services?.length == 1 ? 'Service' : 'Services'}
                      </div>
                      {
                        services?.map((serviceItem) => {
                          return (
                            <div className="d-flex align-items-center mt-1">
                              <img
                                width={10}
                                height={10}
                                alt=""
                                src="/images/other-apps-icon.svg"
                                className="mr-3"
                              />
                              <div className="f-400 f-14 f-black line-height-18 letter-space-2">{serviceItem?.Name}</div>
                            </div>
                          )
                        })
                      }
                    </div>
                }

                {
                  packages?.length == 0 ?
                    <div className="f-400 f-14 greyText2 line-height-18 letter-space-2 mb-2 mt-1">
                      No Packages
                    </div> :
                    <div>
                      <div className="f-400 f-14 greyText2 line-height-18 letter-space-2 mb-2 mt-1">
                        {packages?.length} {packages?.length == 1 ? 'Package' : 'Packages'}
                      </div>
                      {
                        packages?.map((packageItem) => {
                          return (
                            <div className="d-flex align-items-center mt-1">
                              <img
                                width={10}
                                height={10}
                                alt=""
                                src="/images/folder-icon.svg"
                                className="mr-3"
                              />
                              <div className="f-400 f-14 f-black line-height-18 letter-space-2">{packageItem?.Name}</div>
                            </div>
                          )
                        })
                      }
                    </div>
                }
              </>
                :
                <>
                  {
                    packages?.length == 0 ?
                      <div className="f-400 f-14 greyText2 line-height-18 letter-space-2 mb-2 mt-1">
                        No Packages
                      </div> :
                      <div>
                        <div className="f-400 f-14 greyText2 line-height-18 letter-space-2 mb-2">
                          {packages?.length} {packages?.length == 1 ? 'Package' : 'Packages'}
                        </div>
                        {
                          packages?.map((packageItem) => {
                            return (
                              <div className="d-flex align-items-center mt-1">
                                <img
                                  width={10}
                                  height={10}
                                  alt=""
                                  src="/images/folder-icon.svg"
                                  className="mr-3"
                                />
                                <div className="f-400 f-14 f-black line-height-18 letter-space-2">{packageItem?.Name}</div>
                              </div>
                            )
                          })
                        }
                      </div>
                  }
                </>
            }
          </div>

        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="d-flex align-items-center justify-content-between m-3">
          <div>
            <Button
              className="hg-tranparent-grey-btn"
              onClick={() => {
                setShowPriceModal();
              }}
            >
              Cancel
            </Button>
          </div>
          <div>
            <Button
              className="hg-cancel-btn mr-2"
              onClick={() => {
                hideModal();
              }}
            >
              Don’t change
            </Button>
            <Button
              className="hg-blue-btn"
              onClick={() => {
                hideModal({
                  "PackageOfProduct_List": packages
                });
              }}
            >
              Update
            </Button>
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default PriceChangedModal;
