import React, { useEffect, useRef, useState } from "react";
import { Button, Dropdown, Form, Modal, Nav, Tab } from "react-bootstrap";
// import "../secOps.scss";
import "../MSPv2/secOps/secOps.scss";
import HighgroundDatePicker from "../test/TestComponent";
import Loader from "../Common/loader";
import { CallToast } from "../PriceCalculator/modals/CustomToast";
import {
  ContributorGetAPIData,
  ContributorPostData,
  getAPIData,
  postData,
} from "../../Utils/commonApi";
import { useAuth } from "../context/Auth";
const AddProviderModal = ({
  show,
  hideModal,
  refreshData,
  providerId,
  headerName,
  questionId,
  isContributorView,
}) => {
  const { authTokens } = useAuth();
  const [saving, setSaving] = useState(false);

  const [loading, setLoading] = useState(false);

  const [certificateName, setCertificateName] = useState("");
  const [uploadingFileList, setUploadingFileList] = useState([]);

  const [uploadedFile, setUploadedFile] = useState(null);

  const [providerData, setProviderData] = useState({
    ManagedProviderName: "",
    ManageProviderServices: [],
    Website: "",
    PhoneNumber: "",
    Summary: "",
  });
  const [dropdownLoader, setDropdownLoader] = useState({
    typeLoader: false,
    bodyLoader: false,
  });

  async function refreshModalData() {
    setProviderData({
      ManagedProviderName: "",
      ManageProviderServices: [],
      RegisteredAddress: "",
      Website: "",
      PhoneNumber: "",
      Summary: "",
    });
    setDropdownLoader({
      typeLoader: false,
      bodyLoader: false,
    });
    setUploadingFileList([]);
    setUploadedFile(null);
  }

  useEffect(() => {
    if (show) {
      refreshModalData();
      setLoading(true);
      if (providerId) {
        getProviderDetails();
      }
      // getProviderData(providerId);
    }
  }, [show]);
  async function checkValidation() {
    console.log(providerData);
    let errors = 0;
    let updated_data = { ...providerData };
    try {
      if (
        !providerData.ManageProviderServices ||
        providerData.ManageProviderServices.length == 0
      ) {
        errors = errors + 1;

        updated_data.manageProviderServicesError = true;
      }

      if (!providerData.ManagedProviderName.trim()) {
        errors = errors + 1;

        updated_data.ManagedProviderNameError = true;
      }
      if (!providerData.Website.trim()) {
        errors = errors + 1;

        updated_data.WebsiteError = true;
      }
      if (!providerData.RegisteredAddress.trim()) {
        errors = errors + 1;

        updated_data.RegisteredAddressError = true;
      }

      if (
        !providerData.PhoneNumber.trim() ||
        !validateInput(providerData.PhoneNumber)
      ) {
        errors = errors + 1;

        updated_data.PhoneNumberError = true;
      }

      setProviderData(updated_data);
    } catch (err) {
      console.log(err, "errrrr");
    }

    return errors;
  }
  async function getProviderDetails() {
    if (isContributorView) {
      await ContributorGetAPIData("767", authTokens, {
        optionEnum1: providerId ?? 0,
        // optionEnum2: 0,
        // optionEnum3: questionId,
      })
        .then((response) => {
          if (response) {
            setProviderData({
              ...response,
              ManageProviderServices: response?.ProviderServices_Text
                ? JSON.parse(response?.ProviderServices_Text)
                : [],
            });
          } else {
            CallToast("Something went wrong", false, false, "v2style");
          }
        })
        .catch((err) => {
          console.log(err);
          CallToast("Something went wrong", false, false, "v2style");
        });
    } else {
      await getAPIData("767", authTokens, {
        optionEnum1: providerId ?? 0,
        // optionEnum2: 0,
        // optionEnum3: questionId,
      })
        .then((response) => {
          if (response) {
            setProviderData({
              ...response,
              ManageProviderServices: response?.ProviderServices_Text
                ? JSON.parse(response?.ProviderServices_Text)
                : [],
            });
          } else {
            CallToast("Something went wrong", false, false, "v2style");
          }
        })
        .catch((err) => {
          console.log(err);
          CallToast("Something went wrong", false, false, "v2style");
        });
    }
  }
  async function handleSave() {
    setSaving(true);
    if (isContributorView) {
      await ContributorPostData(authTokens, "CreateSupplierProvider", {
        ObjectId: providerId ?? 0,
        CompanyName: providerData?.ManagedProviderName,
        ManageProviderServices: JSON.stringify(
          providerData?.ManageProviderServices
        ),
        Website: providerData?.Website,
        PhoneNumber: providerData?.PhoneNumber,
        Summary: providerData?.Summary,
        RegisteredAddress: providerData?.RegisteredAddress,
        ObjId_SendFormQuestionnaire: questionId ?? 0,
      })
        .then((response) => {
          if (response?.Success) {
            CallToast("Saved successfully", false, false, "v2style");
            hideModal();
            if (refreshData) {
              refreshData();
            }
          } else {
            CallToast("Something went wrong", false, false, "v2style");
          }
          setSaving(false);
        })
        .catch((err) => {
          console.log(err);
          CallToast("Something went wrong", false, false, "v2style");
          setSaving(false);
        });
    } else {
      await postData(authTokens, "CreateSupplierProvider", {
        // accessKey: JSON.parse(localStorage.getItem("user_MessageObj")),
        ObjectId: providerId ?? 0,
        CompanyName: providerData?.ManagedProviderName,
        ManageProviderServices: JSON.stringify(
          providerData?.ManageProviderServices
        ),
        // JSON.stringify([
        //   providerData?.ManageProviderServices,
        // ]),
        Website: providerData?.Website,
        PhoneNumber: providerData?.PhoneNumber,
        Summary: providerData?.Summary,
        RegisteredAddress: providerData?.RegisteredAddress,
        ObjId_SendFormQuestionnaire: questionId ?? 0,
      })
        .then((response) => {
          if (response?.Success) {
            CallToast("Saved successfully", false, false, "v2style");
            hideModal();
            if (refreshData) {
              refreshData();
            }
          } else {
            CallToast("Something went wrong", false, false, "v2style");
          }
          setSaving(false);
        })
        .catch((err) => {
          console.log(err);
          CallToast("Something went wrong", false, false, "v2style");
          setSaving(false);
        });
    }
  }

  function validateInput(input) {
    // Use a regular expression to check if the input contains only numbers
    const pattern = /^[0-9]+$/;
    return pattern.test(input);
  }

  return (
    <Modal
      animation={false}
      show={show}
      dialogClassName="secops-cert-modal "
      className="   "
      aria-labelledby=" "
      onHide={hideModal}
      centered
      scrollable
    >
      <Modal.Header className="text-center">
        <div
          className="w-100 mb-0 font-bold-14 title"
          style={{ fontWeight: "600" }}
        >
          {headerName}
        </div>

        <span
          className="cursor-pointer"
          onClick={() => {
            hideModal();
          }}
        >
          <img width={15} src="/images/attack-surface/cross-icon.svg" alt="" />
        </span>
      </Modal.Header>
      <Modal.Body
        className=" transparent-scroller d-flex flex-column"
        style={{ padding: "16px 24px", gap: "8px" }}
      >
        {
          // loading ? (
          //   <div className="d-flex align-items-center justify-content-center w-100 h-100">
          //     <Loader />
          //   </div>
          // ) :
          <>
            <Dropdown className="transparennt-wrapper-dropdown">
              <Dropdown.Toggle className="w-100">
                <div
                  className={`${
                    providerData.manageProviderServicesError
                      ? "input-error"
                      : ""
                  } d-flex align-items-center radius-4 grey-border p-2  flex-grow-1 mr-1 ${"highlight-input-with-grey-border"}`}
                >
                  <div className="f-darkgrey">Type:</div>

                  <input
                    type="text"
                    className="bg-transparent w-100 border-none pl-2"
                    placeholder="Enter type of managed services provided..."
                    value={
                      providerData?.ManageProviderServices ?? certificateName
                    }
                    onChange={(e) => {
                      setCertificateName(e.target.value);
                      // let tempArr = providerData.ManageProviderServices;
                      let tempArr = [];
                      var inputString = e.target.value.trim();
                      var newArray = inputString.split(",");
                      // .map((item) => item.trim());
                      tempArr.push(...newArray);
                      // tempArr.push(e.target.value);
                      setProviderData({
                        ...providerData,
                        ManageProviderServices: tempArr,
                        manageProviderServicesError: false,
                      });
                    }}
                  />
                  {providerData?.ManageProviderServices.length > 0 && (
                    <img
                      alt="/images/attack-surface/cross-icon.svg"
                      src="/images/attack-surface/cross-icon.svg"
                      className="pointer"
                      onClick={() => {
                        setCertificateName("");
                        setProviderData({
                          ...providerData,
                          ManageProviderServices: [],
                        });
                      }}
                    />
                  )}
                </div>
              </Dropdown.Toggle>
              <Dropdown.Menu
                className="w-100 p-2 transparent-scroller"
                style={{
                  maxHeight: "200px",
                  overflowY: "auto",
                  display: "none!important",
                }}
              >
                {certificateName && (
                  <div
                    className="d-flex align-items-center justify-content-between f-blue my-2 pointer"
                    onClick={(e) => {
                      // createUpdateTypeorBody("type", certificateName);
                      // setDropdownLoader({
                      //   ...dropdownLoader,
                      //   typeLoader: true,
                      // });
                      e.target
                        .closest(".transparennt-wrapper-dropdown")
                        .click();
                    }}
                  >
                    <div className="d-flex align-items-center">
                      <img
                        src="/images/msp/blue-plus.svg"
                        alt=""
                        className="mr-2"
                      />
                      Add "{certificateName}"
                    </div>
                    <div>
                      {dropdownLoader.typeLoader && (
                        <div className="msp-service-update-loader mr-1"></div>
                      )}
                    </div>
                  </div>
                )}
              </Dropdown.Menu>
            </Dropdown>

            <div>
              <div className="f-darkgrey f-500 my-2 mt-4">Details</div>

              <>
                <div className="d-flex align-items-center my-2 w-100">
                  <div className="w-30 f-500 f-grey">Company name</div>
                  <div className="w-70 ">
                    <div
                      className={`${
                        providerData.ManagedProviderNameError
                          ? "input-error"
                          : ""
                      } d-flex align-items-center radius-4 highlight-input py-2 flex-grow-1 mr-1 ${true}`}
                    >
                      <input
                        type="text"
                        className="bg-transparent w-100 border-none pl-2"
                        placeholder="Type"
                        value={providerData?.ManagedProviderName ?? ""}
                        onChange={(e) => {
                          setProviderData({
                            ...providerData,
                            ManagedProviderName: e.target.value,
                            ManagedProviderNameError: false,
                          });
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="d-flex align-items-center my-2 w-100">
                  <div className="w-30 f-500 f-grey">Registered address</div>
                  <div className="w-70 ">
                    <div
                      className={`${
                        providerData.RegisteredAddressError ? "input-error" : ""
                      } d-flex align-items-center radius-4 highlight-input py-2 flex-grow-1 mr-1 ${true}`}
                    >
                      <input
                        type="text"
                        className="bg-transparent w-100 border-none pl-2"
                        placeholder="Type"
                        value={providerData?.RegisteredAddress ?? ""}
                        onChange={(e) => {
                          setProviderData({
                            ...providerData,
                            RegisteredAddress: e.target.value,
                            RegisteredAddressError: false,
                          });
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="d-flex align-items-center my-2 w-100">
                  <div className="w-30 f-500 f-grey">Website</div>
                  <div className="w-70 ">
                    <div
                      className={`${
                        providerData.WebsiteError ? "input-error" : ""
                      } d-flex align-items-center radius-4 highlight-input py-2 flex-grow-1 mr-1 ${true}`}
                    >
                      <input
                        type="text"
                        className="bg-transparent w-100 border-none pl-2"
                        placeholder="Type"
                        value={providerData?.Website ?? ""}
                        onChange={(e) => {
                          setProviderData({
                            ...providerData,
                            Website: e.target.value,
                            WebsiteError: false,
                          });
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="d-flex align-items-center my-2 w-100">
                  <div className="w-30 f-500 f-grey">Phone number</div>
                  <div className="w-70 ">
                    <div
                      className={`${
                        providerData.PhoneNumberError ? "input-error" : ""
                      } d-flex align-items-center radius-4 highlight-input py-2 flex-grow-1 mr-1 ${true}`}
                    >
                      <input
                        type="text"
                        className="bg-transparent w-100 border-none pl-2"
                        placeholder="Type"
                        value={providerData?.PhoneNumber ?? ""}
                        onChange={(e) => {
                          setProviderData({
                            ...providerData,
                            PhoneNumber: e.target.value,
                            PhoneNumberError: false,
                          });
                        }}
                      />
                    </div>
                  </div>
                </div>

                <div className="f-darkgrey f-500 my-2 mt-4">Summary</div>
                <div className="w-100 radius-4 grey-border p-2 ">
                  <textarea
                    className="w-100 radius-4 border-none"
                    placeholder="Provide a description of what this supplier does for you..."
                    rows={6}
                    id="message"
                    name="message"
                    value={providerData?.Summary ?? ""}
                    onChange={(e) => {
                      setProviderData({
                        ...providerData,
                        Summary: e.target.value,
                      });
                    }}
                  />
                </div>

                <div className="p-5" />
              </>
            </div>
          </>
        }
      </Modal.Body>

      <Modal.Footer className="p-2 d-flex align-items-center justify-content-end">
        <Button className="hg-cancel-btn mr-2" onClick={hideModal}>
          Cancel
        </Button>
        <Button
          className="hg-blue-btn"
          onClick={async () => {
            let errors = await checkValidation();

            if (errors > 0) {
              let error_element =
                document.getElementsByClassName(`input-error`);
              if (error_element?.length > 0) {
                error_element[0].scrollIntoView({
                  behavior: "smooth",
                  block: "center",
                  inline: "nearest",
                });
              }

              return;
            } else {
              handleSave();
            }
          }}
        >
          {saving ? "Saving.." : "Save"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddProviderModal;

export function convertDateFormat(dateString) {
  const parts = dateString.split("/");
  if (parts.length > 2) {
    const day = parts[0];
    const month = parts[1];
    const year = parts[2];

    // Rearrange the parts in the desired format
    const formattedDate = `${year}-${month}-${day}`;

    return formattedDate;
  } else {
    return "";
  }
}

function reverseDateFormat(dateString) {
  const parts = dateString.split("-");
  const day = parts[2];
  const month = parts[1];
  const year = parts[0];

  // Rearrange the parts in the desired format
  const formattedDate = `${day}/${month}/${year}`;

  return formattedDate;
}
