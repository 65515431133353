import React, { useEffect, useMemo, useRef, useState } from "react";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { getAPIData } from "../../../Utils/commonApi";
import { useAuth } from "../../context/Auth";
import { CallToast } from "../../PriceCalculator/modals/CustomToast";
import './mspClientPriceCalculator.scss';
import Draggable from "react-draggable";
import SourcesSettingsDropdown from "./sources-settings/sources-settings.dropdown";
import { useScroll } from "../../../hooks/scroll.hook";

const tabsHeaderTitles = {
    'Users': ['Sources', 'FirstName', 'Surname', 'Email', 'Job Title'],
    'Devices': ['Sources', 'HostName', 'Make', 'Model', 'Operating System', 'Serial #', 'IP Address', 'Mac Address'],
    'Mobile Devices': ['Sources', 'HostName', 'Make', 'Model', 'Operating System', 'Serial #', 'IP Address', 'Mac Address'],
    'Servers': ['Sources', 'HostName', 'Make', 'Model', 'Operating System', 'Serial #', 'IP Address', 'Mac Address'],
    'Router/Firewalls': ['Sources', 'Make', 'Model', 'Model', 'Serial #', 'Mac Address'],
    'Network Devices': ['Sources', 'HostName', 'IP Address', 'Mac Address'],
    'Domains': ['Sources', 'Domain Name']
}

const tabsKeysTitles = {
    'Users': ['AssetDeviceSource_List', 'FirstName', 'SurName', 'Email', 'JobTitle'],
    'Devices': ['AssetDeviceSource_List', 'HostName', 'Make', 'Model', 'OperatingSystem', 'Serial', 'IPAddress', 'MacAddress'],
    'Mobile Devices': ['AssetDeviceSource_List', 'HostName', 'Make', 'Model', 'OperatingSystem', 'Serial', 'IPAddress', 'MacAddress'],
    'Servers': ['AssetDeviceSource_List', 'HostName', 'Make', 'Model', 'OperatingSystem', 'Serial', 'IPAddress', 'MacAddress'],
    'Router/Firewalls': ['AssetDeviceSource_List', 'Make', 'Model', 'Model', 'Serial', 'MacAddress'],
    'Network Devices': ['AssetDeviceSource_List', 'HostName', 'IPAddress', 'MacAddress'],
    'Domains': ['AssetDeviceSource_List', 'DomainName']
}

const tabsHeaderState = {}

for (let key in tabsHeaderTitles) {
    tabsHeaderState[key] = tabsHeaderTitles[key].map((header, index) => ({
        Visibility: true,
        FieldKey: header,
        FieldName: header,
        Order: index + 1,
    }));
}

const tabsKeysState = {};

for (let key in tabsKeysTitles) {
    tabsKeysState[key] = tabsKeysTitles[key].map((header, index) => ({
        Visibility: true,
        FieldKey: tabsHeaderTitles[key]?.[index],
        FieldName: header,
        Order: index + 1,
    }));
}

export const SourcesModal = ({
    show,
    hideModal,
    startData,
    tempUserDetailsData,
    _setAssetsFromIntegrations = () => { },
    setTempUserDetailsData = () => { },
    getPageData = () => { },
    saveAssets = () => { },
    clientId
}) => {
    const { authTokens } = useAuth();
    const [assetsListSelected, setAssetsListSelected] = useState({})
    const [assetsList, setAssetsList] = useState([]);
    const [applyLoading, setApplyLoading] = useState(false);
    const [width, setWidth] = useState(0);
    const [tabsHeader, setTabsHeader] = useState(tabsHeaderState);
    const tableHeaderRef = useRef(null);
    const { scrollRef, canScrollBottom, canScrollRight } = useScroll();

    const tabsKeys = useMemo(() => (
        assetsListSelected ? (tabsKeysState[assetsListSelected?.Name] || [])?.map((item) => {
            const headerItem =  tabsHeader[assetsListSelected?.Name]?.find((header) => header.FieldKey === item.FieldKey);
            return ({
                ...item,
                Visibility: headerItem?.Visibility,
                Order: headerItem?.Order
            })
        }) : []
    ), [assetsListSelected, tabsHeader]);

    const showSourceColumn = useMemo(() => (
        tabsHeader[assetsListSelected?.Name]?.find((item) => item.FieldKey === 'Sources')?.Visibility
    ), [assetsListSelected, tabsHeader]);

    useEffect(() => {
        if (show) {
            getSourcesData();
        }
    }, [show]);

    function getSourcesData() {
        return new Promise((resolve, reject) => {
            getAPIData(973, authTokens, {
                optionEnum1: clientId,
            })
                .then((response) => {
                    if (response?.mr?.Success) {
                        let arr = response?.AssetSource_List
                        arr = arr.map(obj => ({
                            ...obj,
                            isApplied: false
                        }));
                        setAssetsList(/*response?.AssetSource_List*/arr);
                        _setAssetsFromIntegrations(response?.AssetSource_List);
                        setAssetsListSelected(response?.AssetSource_List[0]);
                    } else {
                        CallToast("Something went wrong", false, false, "v2style");
                    }
                })
                .catch((err) => {
                    console.log(err);
                    CallToast("Something went wrong", false, false, "v2style");
                });
        });
    }

    function colWidthByFieldKey(fieldKey) {
        if (fieldKey === 'Sources') {
            return '70px';
        }
        return '160px';
    }

    return (
        <Draggable defaultPosition={{ x: 0, y: 0 }} handle=".dragger-icon">
            <div
                id="sources-modal"
                className="sources-modal"
            >
                <div className="sources-modal-header">
                    <div className="d-flex align-items-center justify-content-between w-100">
                        <div
                            className="dragger-icon"
                            style={{
                                position: "absolute",
                                background: "transparent",
                                width: "95%",
                                height: "30px",
                            }}
                        ></div>
                        <div className="f-400 f-12 greyText2 line-height-18 letter-space-2">Sources</div>
                        <img
                            alt=""
                            height={9}
                            width={9}
                            src="/images/attack-surface/cross-icon.svg"
                            onClick={() => {
                                hideModal();
                            }}
                        />
                    </div>
                </div>

                <div className="sources-modal-body">
                    <div className="modal-block d-flex align-items-center justify-content-between">
                        <div className="left-block">
                            <div className="f-500 f-12 f-darkgrey line-height-18 letter-space-2 px-3 py-2">Assets</div>
                            {
                                assetsList?.map((assetsItem) => {
                                    return (
                                        <div className={`d-flex align-items-center justify-content-between px-3 py-1 ${assetsListSelected?.ObjectDefId == assetsItem?.ObjectDefId && 'bg-grey'}`}>
                                            <div
                                                className="d-flex align-items-center justify-content-between pointer"
                                                style={{ width: '150px' }}
                                                onClick={() => {
                                                    setAssetsListSelected(assetsItem);
                                                    setWidth(100 / Number((tabsHeader[assetsItem?.Name])?.count));
                                                }}
                                            >
                                                <div className="f-400 f-14 f-grey line-height-22 letter-space-2">
                                                    {assetsItem?.Name}
                                                </div>
                                                <div
                                                    className="f-400 f-14 f-black line-height-22 letter-space-2"
                                                    style={{
                                                        textAlign: 'right'
                                                    }}
                                                >
                                                    {assetsItem?.Count}
                                                </div>
                                            </div>
                                            <div>
                                                {
                                                    assetsListSelected?.ObjectDefId == assetsItem?.ObjectDefId ?
                                                        <div
                                                            className="f-500 f-14 txt-blue line-height-22 letter-space-2 pointer"
                                                            onClick={() => {
                                                                const updated_data = assetsList.map(
                                                                    (dataItem) => {
                                                                        if (dataItem?.ObjectDefId == assetsItem?.ObjectDefId) {
                                                                            return {
                                                                                ...dataItem,
                                                                                isApplied: true,
                                                                            };
                                                                        }
                                                                        return dataItem;
                                                                    }
                                                                );
                                                                setAssetsList(updated_data);

                                                                setApplyLoading(true);
                                                                const updatedData = tempUserDetailsData.map(
                                                                    (dataItem) => {
                                                                        if (dataItem?.Name?.replace("Per ", "") === assetsListSelected?.Name?.slice(0, -1)) {
                                                                            return {
                                                                                ...dataItem,
                                                                                Qty: assetsListSelected?.Count,
                                                                                IsUpdatedByAsset: true
                                                                            };
                                                                        }
                                                                        return dataItem;
                                                                    }
                                                                );
                                                                setTempUserDetailsData(updatedData);
                                                                setApplyLoading(false);
                                                                CallToast("Applied successfully", false, false, "v2style");
                                                            }}
                                                        >
                                                            {
                                                                assetsItem?.isApplied ? 'Applied' : 'Apply'
                                                            }
                                                        </div> : <div style={{ width: '38px', height: '22px' }}></div>
                                                }
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>

                        <div className="right-block">
                            <div className="radius-4" id="assetTable">
                                <div className="top-block">
                                    <div className="hg-border d-flex align-items-center justify-content-between bg-grey px-3 py-1">
                                        <div className="d-flex align-items-center">
                                            <img
                                                alt=""
                                                height={12}
                                                width={12}
                                                src="/images/supply-chain/view-icon.svg"
                                                className="mr-2"
                                            />
                                            <div className="f-400 f-12 greyText2 line-height-18 letter-space-2">Devices preview</div>
                                        </div>
                                        <SourcesSettingsDropdown
                                            columns={tabsHeader[assetsListSelected?.Name]} 
                                            showSourceColumn={showSourceColumn}
                                            setShowSourceColumn={(value) => {
                                                const newColumns = {...tabsHeader};
                                                newColumns[assetsListSelected?.Name] = newColumns[assetsListSelected?.Name].map((item) => {
                                                    if (item.FieldKey === 'Sources') {
                                                        return {
                                                            ...item,
                                                            Visibility: value
                                                        }
                                                    }
                                                    return item;
                                                });
                                                setTabsHeader(newColumns);
                                            }}
                                            setColumns={(updatedColumns) => {
                                                const newColumns = {...tabsHeader};
                                                newColumns[assetsListSelected?.Name] = updatedColumns;
                                                setTabsHeader(newColumns);
                                            }}
                                        />
                                    </div>
                                    <div
                                        className="bg-white d-flex align-items-stretch"
                                        style={{ overflow: 'hidden' }}
                                        ref={tableHeaderRef}
                                    >
                                        {tabsHeader[assetsListSelected?.Name]
                                        ?.filter((item) => item.Visibility)
                                        ?.sort((a, b) => a.Order - b.Order)
                                        ?.map((item) => {
                                            const colWidth = colWidthByFieldKey(item.FieldKey);
                                            return (
                                                <div
                                                    className="hg-border flex-1 f-500 f-12 f-darkgrey line-height-18 letter-space-2"
                                                    style={{
                                                        maxWidth: colWidth,
                                                        minWidth: colWidth,
                                                        width: colWidth,
                                                        minHeight: '100%',
                                                        wordWrap: 'break-word',
                                                        overflowWrap: 'break-word',
                                                        padding: '4px 8px',
                                                    }}
                                                >
                                                    {item.FieldName}
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                                {canScrollRight && <div className="right-fade" />}
                                <div className="bottom-fade" />
                                <div 
                                    className="main-data-block" style={{ overflow: 'auto' }}
                                    onScroll={(e) => {
                                        tableHeaderRef.current.scrollLeft = e.target.scrollLeft;
                                    }}
                                    ref={scrollRef}
                                >
                                    {
                                        assetsListSelected?.AssetDevicePreview_List?.length != 0 ? <>
                                            {
                                                assetsListSelected?.AssetDevicePreview_List?.map((itemBlock) => {
                                                    const sources = itemBlock?.AssetDeviceSource_List 
                                                        ? [...itemBlock?.AssetDeviceSource_List]?.splice(0, 3) 
                                                        : [];
                                                    return (
                                                        <div className="d-flex align-items-stretch" style={{ height: '100%' }}>
                                                            {tabsKeys
                                                            ?.filter((item) => item.Visibility)
                                                            ?.sort((a, b) => a.Order - b.Order)
                                                            ?.map((headerItem, headerIndex) => {
                                                                const colWidth = colWidthByFieldKey(headerItem.FieldKey);
                                                                return (
                                                                    <div
                                                                        style={{
                                                                            maxWidth: colWidth,
                                                                            minWidth: colWidth,
                                                                            width: colWidth,
                                                                            display: 'flex',
                                                                            flexDirection: 'column',
                                                                            minHeight: '100%',
                                                                        }}
                                                                    >
                                                                        {itemBlock[headerItem.FieldName] != null ? (
                                                                            // 
                                                                            <div
                                                                                className="hg-border f-400 f-14 greyText2 line-height-22 letter-space-2 px-3 py-1"
                                                                                style={{
                                                                                    flexGrow: 1,
                                                                                    display: 'flex',
                                                                                    flexDirection: 'column',
                                                                                    justifyContent: 'center',
                                                                                    wordWrap: 'break-word',
                                                                                    overflowWrap: 'break-word',
                                                                                    height: '100%',
                                                                                }}
                                                                            >
                                                                                <div
                                                                                    className="d-flex"
                                                                                    style={{
                                                                                        flexGrow: 1,
                                                                                        overflow: 'hidden',
                                                                                        textOverflow: 'ellipsis',
                                                                                        whiteSpace: 'nowrap',
                                                                                        height: '100%',
                                                                                    }}
                                                                                >
                                                                                    {headerItem.FieldName === 'AssetDeviceSource_List' ? (
                                                                                        <OverlayTrigger
                                                                                            placement="bottom"
                                                                                            trigger={["hover", "focus"]}
                                                                                            delay={{ show: 200, hide: 250 }}
                                                                                            className="fix-tooltip"
                                                                                            overlay={
                                                                                                <Tooltip
                                                                                                    id={`tooltip-assets`} 
                                                                                                    style={{ position: 'relative', zIndex: 10000,  }}
                                                                                                >
                                                                                                    <div className="d-flex flex-column" style={{padding: '4px', gap: '4px' }}>
                                                                                                        {
                                                                                                            itemBlock?.AssetDeviceSource_List?.map((hoverItem, index) => {
                                                                                                                return (
                                                                                                                    <div className="d-flex">
                                                                                                                        <img
                                                                                                                            alt=""
                                                                                                                            height={16}
                                                                                                                            width={16}
                                                                                                                            src={`/images/attack-surface/integrations/${hoverItem?.AssetSourceLogo}.webp`}
                                                                                                                            className="mr-1"
                                                                                                                            style={{ borderRadius: '2px' }}
                                                                                                                            onError={({ currentTarget }) => {
                                                                                                                                currentTarget.onerror = null;
                                                                                                                                currentTarget.src =
                                                                                                                                "/images/attack-surface/integrations/generic.svg";
                                                                                                                            }}
                                                                                                                        />
                                                                                                                        <div
                                                                                                                            className="f-400 f-12 f-white line-height-18 letter-space-2"
                                                                                                                        >
                                                                                                                            {hoverItem?.AssetSourceName}
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                )
                                                                                                            })
                                                                                                        }
                                                                                                    </div>
                                                                                                </Tooltip>
                                                                                            }
                                                                                        >
                                                                                            <div className="d-flex align-items-center sources-icons-pair">
                                                                                                {sources.map((item, index) => {
                                                                                                    const showMore = index === 2 && itemBlock?.AssetDeviceSource_List?.length > 3;
                                                                                                    const moreCount = itemBlock?.AssetDeviceSource_List?.length - 3;
                                                                                                    return (
                                                                                                        <div className="my-1" style={{
                                                                                                            padding: '1px',
                                                                                                            backgroundColor: '#fff',
                                                                                                            borderRadius: '100%',
                                                                                                            marginLeft: index !== 0 ? '-8px' : 0,
                                                                                                            display: 'flex',
                                                                                                            alignSelf: 'center'
                                                                                                        }}>
                                                                                                            {showMore ? (
                                                                                                                <div 
                                                                                                                    className="d-flex align-items-center justify-content-center"
                                                                                                                    style={{
                                                                                                                        backgroundColor: '#D4D6DD',
                                                                                                                        borderRadius: '100%',
                                                                                                                        width: '16px',
                                                                                                                        height: '16px',
                                                                                                                        fontSize: '8px'
                                                                                                                    }}
                                                                                                                >
                                                                                                                    <span className="f-grey f-500">
                                                                                                                        {`+${moreCount}`}
                                                                                                                    </span>
                                                                                                                </div>
                                                                                                            ) : (
                                                                                                                <img 
                                                                                                                    alt=""
                                                                                                                    height={16}
                                                                                                                    width={16}
                                                                                                                    src={`/images/attack-surface/integrations/${item?.AssetSourceLogo}.webp`}
                                                                                                                    onError={({ currentTarget }) => {
                                                                                                                        currentTarget.onerror = null;
                                                                                                                        currentTarget.src =
                                                                                                                        "/images/attack-surface/integrations/generic.svg";
                                                                                                                    }}
                                                                                                                    style={{ 
                                                                                                                        borderRadius: '100%',
                                                                                                                    }}
                                                                                                                />
                                                                                                            )}
                                                                                                        </div>
                                                                                                    );
                                                                                                })}
                                                                                            </div>
                                                                                        </OverlayTrigger>
                                                                                    ) : (
                                                                                        <OverlayTrigger
                                                                                            placement="bottom"
                                                                                            trigger={["hover", "focus"]}
                                                                                            delay={{ show: 200, hide: 250 }}
                                                                                            className="fix-tooltip"
                                                                                            overlay={
                                                                                                <Tooltip
                                                                                                    id={`tooltip-assets`} 
                                                                                                    style={{ position: 'relative', zIndex: 10000,  }}
                                                                                                >
                                                                                                    <span>
                                                                                                    {itemBlock[headerItem.FieldName]}
                                                                                                    </span>
                                                                                                </Tooltip>
                                                                                            }
                                                                                        >
                                                                                            <div
                                                                                                className=""
                                                                                                style={{
                                                                                                flex: "1 1 100%",
                                                                                                minWidth: 0,
                                                                                                }}
                                                                                            >
                                                                                                <div
                                                                                                    style={{
                                                                                                        overflow: "hidden",
                                                                                                        textOverflow: "ellipsis",
                                                                                                        whiteSpace: "nowrap",
                                                                                                    }}
                                                                                                    >
                                                                                                {itemBlock[headerItem.FieldName]}
                                                                                                </div>
                                                                                            </div>
                                                                                        </OverlayTrigger>
                                                                                    )}
                                                                                </div>
                                                                            </div>
                                                                        ) : (
                                                                            <div
                                                                                className="hg-border f-400 f-14 greyText2 line-height-22 letter-space-2 px-3 py-1"
                                                                                style={{
                                                                                    flexGrow: 1,
                                                                                    display: 'block',
                                                                                    overflow: 'hidden',
                                                                                    textOverflow: 'ellipsis',
                                                                                    whiteSpace: 'nowrap',
                                                                                    height: '100%',
                                                                                }}
                                                                            >
                                                                                {" "}
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                );
                                                            })}
                                                        </div>
                                                    )
                                                })
                                            }
                                        </> : <div className="d-flex align-items-center justify-content-center f-400 f-14 greyText2 line-height-22 letter-space-2  p-4">
                                            No data here yet
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="sources-modal-footer d-flex align-items-center justify-content-end">
                    <div className="d-flex align-items-center justify-content-end "
                    >
                        <Button
                            className="hg-cancel-btn mr-2"
                            onClick={() => {
                                hideModal();
                                setTempUserDetailsData(startData);
                            }}
                        >
                            Cancel
                        </Button>
                        <Button
                            className="hg-blue-btn"
                            onClick={() => {
                                const updatedArray = tempUserDetailsData.map(item1 => {
                                    const item2 = assetsList.find(item2 => item2?.Name?.slice(0, -1) === item1?.Name?.replace("Per ", ""));
                                    return item2 ? { ...item1, Qty: item2.Count, IsUpdatedByAsset: true } : item1;
                                });

                                setTempUserDetailsData(updatedArray);
                                CallToast("Applied successfully", false, false, "v2style");
                                hideModal();
                            }}
                        >
                            Apply all
                        </Button>
                    </div>
                </div>
            </div>
        </Draggable>
    );
};

export default SourcesModal;
