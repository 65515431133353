import React, { useEffect, useState } from "react";
import { Button, Dropdown, Form } from "react-bootstrap";
import "./mspIntegrations.scss";
import { TrimString } from "../../../Utils/StringTrimmer";
import ImportedClientDetailModal from "./ImportedClientDetailModal";
import MspMappingDropdownSearchInput from "./mspMappingScreens/mspMappingComponents/mspMappingDropdownSearchInput";
import ContentLoader from "react-content-loader";

import { GreyLoader } from "../../Theme/APILoaders";
import DotsLoader from "../../../Utils/dotsLoader";
import { parseIntegrationName } from "./mspMappingScreens/MSPMappings";
import { HGHighlightText } from "../../App/App";
import ScrollableDivWithShadow from "../MspAttackSurface.js/ScrollableDivWithShadow";
const MapMembers = ({
  memberPageData,
  setMemberPageData,
  getMembersListData,
  membersMappingData,
  getMembersMappingListData,
  updateMemberData,
  updateImportStatus,
}) => {
  const [loading, setLoading] = useState(false);
  const [fetchingNewMembers, setFetchingNewMembers] = useState(false);
  const [memberSearchString, setMemberSearchString] = useState("");

  const [automaticallyImportStatus, setAutomaticallyImportStatus] =
    useState(false);
  const [importStatus, setImportStatus] = useState(false);

  useEffect(() => {
    // if (memberPageData?.ObjectList?.length > 0) {
    // } else {
    setLoading(true);
    Promise.all([
      getMembersListData({
        NextPageNo: 1,
        searchString: "",
      }),
      getMembersMappingListData({
        NextPageNo: 1,
        searchString: "",
      }),
    ])
      .then((response) => {
        setLoading(false);
        setAutomaticallyImportStatus(response?.[0]?.AutoImport);
        setImportStatus(response?.[0]?.Import);
      })
      .catch((err) => {
        setLoading(false);
      });
    // }
  }, []);

  async function getMoreMembers(e) {
    try {
      if (
        e.target.clientHeight + e.target.scrollTop + 20 >=
        e.target.scrollHeight
      ) {
        if (memberPageData?.NextPage === false) return;
        let element = document.getElementById("mainBody");
        element.removeEventListener("scroll", getMoreMembers);

        setFetchingNewMembers(true);
        getMembersListData({
          NextPageNo: null,
          searchString: memberSearchString,
        }).then(() => {
          setFetchingNewMembers(false);
        });

        console.log("You've reached the end of the screen!");
      }
    } catch (error) {
      console.log("errror aa gya", error);
    }
  }

  useEffect(() => {
    var element = document.getElementById("mainBody");
    element.addEventListener("scroll", getMoreMembers);

    return () => {
      element.removeEventListener("scroll", getMoreMembers);
    };
  }, [memberPageData]);

  return (
    <>
      {loading ? (
        <MapMembersLoader />
      ) : (
        <div
          id="mapped-client-inegtration-modal-parent"
          className="map-clients-screen  "
        >
          <div className="d-flex align-items-center pt-4 justify-content-between">
            <div className="d-flex align-items-center">
            <img
              alt=""
              width={42}
              height={42}
              src={`/images/attack-surface/integrations/${parseIntegrationName("importedIntegrtionId")}.webp`}
              className="mr-3 radius-4"
              style={{
                border:"1px solid #ebecf180"
              }}
            />
              <div>
                <p className="f-black f-600 f-15 m-0">Import your employees ({Number(parseIntegrationName("importedIntegrtionId"))===3906771?"agents":"members"})</p>
                <p className="f-grey f-14">
                  Import and map your employees ({Number(parseIntegrationName("importedIntegrtionId"))===3906771?"agents":"members"}) from{" "}{parseIntegrationName("importedIntegrtionName")}
                </p>
              </div>
            </div>
            <div className="">
              <Form.Check
                style={{
                  scale: "1.5",
                }}
                type="switch"
                id={`switchnitification1`}
                checked={importStatus}
                className={`m-0`}
                onClick={() => {}}
                onChange={(e) => {
                  updateImportStatus(6, Number(e.target.checked));
                  setImportStatus(e.target.checked);
                }}
              />
            </div>
          </div>

          {importStatus && (
            <>

<div className="radius-8 bg-grey p-3 d-flex  align-items-start justify-content-between my-3">
            <div className="d-flex align-items-start">
              <img
                alt=""
                src="/images/msp/msp-info-icon.svg"
                className="mr-2 mt-1"
              />
              <div>
              Only active users are available - disabled, deactivated and api{" "}{Number(parseIntegrationName("importedIntegrtionId"))===3906771?"agents":"members"}{" "}are not synced
              </div>
            </div>
            <img
              alt=""
              className="pointer d-none"
              src="/images/small-cross-icon.svg"
            />
          </div>
              <div className="mt-3 mb-2 f-500">Import</div>
              <div className="d-flex align-items-center justify-content-between bg-grey radius-8 p-3">
                <div className="f-black f-500">
                  Automatically import new{" "}{Number(parseIntegrationName("importedIntegrtionId"))===3906771?"agents":"members"}
                </div>
                <div className="">
                  <Form.Check
                    style={{
                      scale: "1.5",
                    }}
                    type="switch"
                    id={`switchnitification`}
                    checked={automaticallyImportStatus}
                    className={`m-0`}
                    onClick={() => {}}
                    onChange={(e) => {
                      updateImportStatus(7, Number(e.target.checked));
                      setAutomaticallyImportStatus(e.target.checked);
                    }}
                  />
                </div>
              </div>
              <div className="d-flex align-items-center justify-content-between pt-5 pb-2">
                <div className="f-black f-16 f-600 ">{Number(parseIntegrationName("importedIntegrtionId"))===3906771?"Agents":"Members"}</div>
                <div className="mx-2 d-flex align-items-center">
                  <input
                    type="text"
                    placeholder="Search.."
                    value={memberSearchString}
                    onChange={(e) => {
                      // let value = e.target.value;
                      setMemberSearchString(e.target.value);
                      // getMembersListData({
                      //   NextPageNo: 1,
                      //   searchString: value,
                      // });
                    }}
                    onBlur={() => {}}
                    className={`border-none bg-tranparent pl-2`}
                  />
                  <img
                    alt=""
                    src="/images/attack-surface/search-icon.svg"
                    className="pointer"
                    onClick={() => {}}
                  />
                </div>
              </div>
              <div className="border-parent bg-grey radius-8">
                <MapMembersBlock
                  memberPageData={memberPageData}
                  setMemberPageData={setMemberPageData}
                  getMembersListData={getMembersListData}
                  membersMappingData={membersMappingData}
                  getMembersMappingListData={getMembersMappingListData}
                  memberSearchString={ memberSearchString ?? ""}
                  updateMemberData={updateMemberData}
                />
              </div>
              {fetchingNewMembers && (
                <div className="d-flex align-items-center f-darkgrey">
                  <GreyLoader width={12} />
                  Loading...
                </div>
              )}

              <div className="p-3"></div>
            </>
          )}
        </div>
      )}
    </>
  );
};

export default MapMembers;

export const MapMembersBlock = ({
  memberPageData,
  setMemberPageData,
  getMembersListData,
  membersMappingData,
  getMembersMappingListData,
  memberSearchString,
  updateMemberData,
}) => {
  const [showMappedMemberDetailModal, setShowMappedMemberDetailModal] =
    useState(false);

  const [memberDetails, setMemberDetails] = useState([]);
  return (
    <>
      <ImportedClientDetailModal
        show={showMappedMemberDetailModal}
        hideModal={() => setShowMappedMemberDetailModal(false)}
        data={memberDetails}
        parentID={"mapped-client-inegtration-modal-parent"}
      />
      {/* Header */}
      <div className="border-item d-flex align-items-center justify-content-between">
        <div className="f-12 f-500 f-grey py-2 pl-4 pr-5 w-100 d-flex align-items-center justify-content-between">
          <div> {parseIntegrationName("importedIntegrtionName")}</div>
          <div> {memberPageData?.TotalCount}</div>
        </div>
        <div className="f-12 f-500 f-grey py-2 pl-2 w-100">HighGround</div>
      </div>

      {/* Firts row */}
      {memberPageData?.ObjectList?.filter((item) =>
        item?.name?.toLowerCase().includes(memberSearchString?.toLowerCase())
      )?.map((member, memberIndex) => {
        return (
          <div className="border-item d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center justify-content-between w-100 pl-4 py-3 pr-3">
              <div className="d-flex align-items-center justify-content-between hover-on-show-parent w-100 position-relative">
              {member?.state === "Not_Selected" && (
                 <span className="blue-dot" />
                )}
                <div
                  className="d-flex align-items-center f-500 f-black"
                  title={member?.name}
                >
                  <img
                    src="/images/msp/integrations/msp-integration-user-icon.svg"
                    alt=""
                    className="mr-2"
                  />

                  
                  <span dangerouslySetInnerHTML={{ __html: HGHighlightText(TrimString(member?.name, 30), memberSearchString) }} />
    
                  
                </div>
                <div
                  className="f-12 f-darkgrey hover-on-show-child mr-2 pointer"
                  onClick={() => {
                    setMemberDetails(member.details);
                    setShowMappedMemberDetailModal(true);
                  }}
                >
                  Details
                </div>
              </div>
              <div>
                {member?.state === "Ignored" && (
                  <img
                    src="/images/msp/integrations/map-client-cancel-icon.svg"
                    alt=""
                    className="mr-2 pointer"
                    onClick={()=>{
                      updateMemberData([
                        {
                          id: member?.id,
                          state: "Provisional_Import",
                          ParentObjId: 0,
                          ImportName: "",
                        },
                      ]);
                      let member_page_data = { ...memberPageData };
                      let _memberIndex = member_page_data.ObjectList.findIndex(
                        (item) => item.id === member.id
                      );
                      member_page_data.ObjectList[_memberIndex].state = "Provisional_Import";
                      member_page_data.ObjectList[_memberIndex].ParentObjId = 0;
                      member_page_data.ObjectList[_memberIndex].ImportName = "";
                      setMemberPageData(member_page_data);
                    }}
                  />
                )}
                {member?.state === "Imported" && (
                  <img
                    // src="/images/msp/right-blue-mark.svg"
                    src="/images/msp/twoway-blue-mark.svg"
                    alt=""
                    className="mr-2 pointer"
                  />
                )}
                  {member?.state === "Provisional_Import" && (
                  <img
                    src="/images/msp/right-blue-mark.svg"
                    // src="/images/msp/twoway-blue-mark.svg"
                    alt=""
                    className="mr-2 pointer"
                  />
                )}
                {member?.state === "Mapped" && (
                  <img
                    src="/images/msp/twoway-blue-mark.svg"
                    alt=""
                    className="mr-2 pointer"
                  />
                )}
                {member?.state === "Auto_Imported" && (
                  <img
                    src="/images/msp/right-blue-mark.svg"
                    alt=""
                    className="mr-2 pointer"
                  />
                )}
                {member?.state === "Deleted" && (
                  <img
                    src="/images/msp/red-cross-circle.svg"
                    alt=""
                    className="mr-2 pointer"
                  />
                )}
                 {member?.state === "Not_Selected" && (
                  <img
                    src="/images/msp/choose-action-icon.svg"
                    alt=""
                    className="mr-2 pointer"
                  />
                )}
              </div>
            </div>
            <div className=" w-100">
              <MapMembersDropdown
                memberDetail={member}
                onStateChange={(newState, mappingData) => {
                  updateMemberData([
                    {
                      id: member?.id,
                      state: newState,
                      ParentObjId: mappingData?.id ?? 0,
                      ImportName: mappingData?.ImportName,
                    },
                  ]);

                  let member_page_data = { ...memberPageData };
                  let _memberIndex = member_page_data.ObjectList.findIndex(
                    (item) => item.id === member.id
                  );
                  member_page_data.ObjectList[_memberIndex].state = newState;
                  if (newState === "Ignored") {
                    member_page_data.ObjectList[_memberIndex].ParentObjId = 0;
                  } else if (newState === "Provisional_Import") {
                    member_page_data.ObjectList[_memberIndex].ParentObjId = 0;
                    member_page_data.ObjectList[_memberIndex].ImportName =
                      mappingData?.ImportName;
                  } else if (newState === "Mapped") {
                    member_page_data.ObjectList[_memberIndex].ParentObjId =
                      mappingData.id;
                  }
                  setMemberPageData(member_page_data);
                }}
                membersMappingData={membersMappingData}
                getMembersMappingListData={getMembersMappingListData}
              />
            </div>
          </div>
        );
      })}
    </>
  );
};

//
const MapMembersDropdown = ({
  memberDetail,
  membersMappingData,
  getMembersMappingListData,
  onStateChange,
}) => {
  function closeDropdown(e) {
    e.target.closest(".map-clients-dropdown").click();
  }
  const [fetchingMapping, setFetchingMapping] = useState(false);
  const [search, setSearch] = useState("");
  const [selected, setSelected] = useState();
  const [page, setPage] = useState("home");
  const [loadingMappingData, setLoadingMappingData] = useState(false)

  useEffect(() => {
    if (memberDetail?.state === "Ignored") {
      setSelected();
    } else if (memberDetail?.state === "Imported") {
      setSelected();
    } else if (memberDetail?.state === "Not_Selected") {
      setSelected();
    } else if (memberDetail?.state === "Mapped") {
      let mapped_list = [...membersMappingData?.ObjectList];
      if (memberDetail?.ParentObjId > 0) {
        let selected = mapped_list.filter(
          (item) => item?.id === memberDetail?.ParentObjId
        )[0];
        setSelected(selected);
      }
    }
  }, [memberDetail]);

  const [timeoutId, setTimeoutId] = useState();

  const handleInputChange = (event) => {
    const { value } = event.target;
    setSearch(value);
     
    
    // Clear the previous timeout
    clearTimeout(timeoutId);

    // Set a new timeout to call the API after 1 second
    let _timeoutId = setTimeout(() => {
      setLoadingMappingData(true);
      getMembersMappingListData({
        NextPageNo: 1,
        searchString: value,
      }).then(() => {
        setLoadingMappingData(false);
      });
    }, 700);
    setTimeoutId(_timeoutId);
  };

  return (
    <Dropdown
      className="map-clients-dropdown"
      onToggle={(e) => {
        if (e) {
        } else {
          setPage("home");
          setSearch("");
          getMembersMappingListData({
            NextPageNo: 1,
            searchString: "",
          });
        }
      }}
    >
      <Dropdown.Toggle className={`d-flex align-items-center w-100`}>
        {memberDetail?.state === "Ignored" && (
          <div className="d-flex w-100 align-items-center justify-content-between">
            <div className="w-100 bg-grey f-black d-flex">Ignore</div>
            <img
              alt=""
              className="mr-2"
              src="/images/msp/integrations/chevron-down-msp.svg"
            />
          </div>
        )}

        {memberDetail?.state === "Not_Selected" && (
          <div className="d-flex w-100 align-items-center justify-content-between">
            <div className="w-100 bg-grey f-darkgrey d-flex">Choose action</div>
            <img
              alt=""
              className="mr-2"
              src="/images/msp/integrations/chevron-down-msp.svg"
            />
          </div>
        )}

        {memberDetail?.state === "Imported" && (
          <div className="d-flex w-100 align-items-center justify-content-between">
            <div className="w-100 bg-grey f-black d-flex">
              {memberDetail?.ImportName ? (
                <>Import as "{TrimString(memberDetail.ImportName, 20)}"</>
              ) : (
                <>Import "{TrimString(memberDetail?.name, 20)}"</>
              )}
            </div>
            <img
              alt=""
              className="mr-2"
              src="/images/msp/integrations/chevron-down-msp.svg"
            />
          </div>
        )}
        {memberDetail?.state === "Provisional_Import" && (
          <div className="d-flex w-100 align-items-center justify-content-between">
            <div className="w-100 bg-grey f-black d-flex">
              {memberDetail?.ImportName ? (
                <>Import as "{TrimString(memberDetail.ImportName, 20)}"</>
              ) : (
                <>Import "{TrimString(memberDetail?.name, 20)}"</>
              )}
            </div>
            <img
              alt=""
              className="mr-2"
              src="/images/msp/integrations/chevron-down-msp.svg"
            />
          </div>
        )}

        {memberDetail?.state === "Auto_Imported" && (
          <div className="d-flex w-100 align-items-center justify-content-between">
            <div className="w-100 bg-grey f-black d-flex">Auto-Imported</div>
            <img
              alt=""
              className="mr-2"
              src="/images/msp/integrations/chevron-down-msp.svg"
            />
          </div>
        )}
        {memberDetail?.state === "Deleted" && (
          <div className="d-flex w-100 align-items-center justify-content-between">
            <div className="w-100 bg-grey f-black d-flex">Deleted</div>
            <img
              alt=""
              className="mr-2"
              src="/images/msp/integrations/chevron-down-msp.svg"
            />
          </div>
        )}
        {memberDetail?.state === "Mapped" && (
          <div className="d-flex w-100 align-items-center justify-content-between">
            <div className="d-flex  align-items-center f-black">
              <img
                alt=""
                width={16}
                height={16}
                className=" mr-2"
                src={"/images/user-logo.svg"}
              />

              {TrimString(
                selected?.name ?? memberDetail?.relationalObjectDetails?.name,
                25
              )}
            </div>
            <div>
              <img
                alt=""
                className="mr-2"
                src="/images/msp/integrations/chevron-down-msp.svg"
              />
            </div>
          </div>
        )}
      </Dropdown.Toggle>
      <Dropdown.Menu className="no-border-shadow-dropdown">
        {page === "home" && (
          <>
            <div className="d-flex align-items-center mb-2">
              <MspMappingDropdownSearchInput
              loading={loadingMappingData}
                placeholder="Find or import as..."
                value={search}
                onChange={(e) => {
                  handleInputChange(e)
                }}
              />
            </div>
            <div className="f-12 f-400 f-darkgrey my-1 pl-2">Map with</div>

            <ScrollableDivWithShadow
              className="transparent-scroller  member-right-list-scroller pl-2"
              style={{
                maxHeight: "300px",
                overflowY: "scroll",
              }}
            >
              {membersMappingData?.ObjectList

                // ?.filter((item) =>
                //   item?.name?.toLowerCase()?.includes(search.toLowerCase())
                // )

                ?.map((member, memberIndex) => {
                  return (
                    <div
                      className="d-flex align-items-center f-grey f-14 f-500 w-100 contextmenu-grey-item pointer "
                      title={member?.name}
                      onClick={(e) => {
                        closeDropdown(e);
                        setSearch("");
                        onStateChange("Mapped", member);
                        setSelected(member);
                      }}
                    >
                      <img
                        alt=""
                        width={16}
                        height={16}
                        className="mx-2"
                        src={"/images/user-logo.svg"}
                      />
                      
                      <span dangerouslySetInnerHTML={{ __html: HGHighlightText(TrimString(member?.name, 25), search) }} />
    
                    </div>
                  );
                })}
            </ScrollableDivWithShadow>

            {membersMappingData?.ObjectList?.length === 0 && search === "" && (
              <div className="f-darkgrey d-flex align-items-center justify-content-start p-2 pl-3">
                No{" "}{Number(parseIntegrationName("importedIntegrtionId"))===3906771?"agents":"members"}{" "}have been imported yet
              </div>
            )}
            {membersMappingData?.ObjectList?.length === 0 && search !== "" && (
              <div className="f-darkgrey d-flex align-items-center justify-content-start p-2 pl-3">
                No results...
              </div>
            )}

            {search !== "" && (
              <div
                className="f-blue pointer d-flex align-items-center pl-3"
                onClick={(e) => {
                  onStateChange("Provisional_Import", { ImportName: search });
                  closeDropdown(e);
                  setSearch("");
                }}
              >
                <img alt="" className="mr-2" src="/images/msp/blue-plus.svg" />
                Import as “{TrimString(search, 20)}”
              </div>
            )}
            {membersMappingData?.NextPage === true && (
              <div
                className="d-flex pointer align-items-center pl-3 mt-2"
                onClick={() => {
                  if (fetchingMapping) return;
                  setFetchingMapping(true);
                  getMembersMappingListData({
                    NextPageNo: null,
                    searchString: search,
                    skip: membersMappingData?.ObjectList?.length,
                    count: 1000,
                  })
                    .then(() => {
                      setFetchingMapping(false);
                      const elementToScroll = document.querySelector(
                        ".member-right-list-scroller"
                      );
                      elementToScroll.scrollTo({
                        top: 10000000,
                        behavior: "smooth", // You can use 'auto' for instant scrolling
                      });
                    })
                    .catch(() => {
                      setFetchingMapping(false);
                    });
                }}
              >
                <DotsLoader animate={fetchingMapping} />
                <div className="f-darkgrey ml-2">Show more</div>
              </div>
            )}
            <hr className="hg-border mx-auto px-3" style={{ width: "80%" }} />
            <div className="mx-2  ">
              <div
                className="f-grey   pointer d-flex align-items-center contextmenu-grey-item"
                onClick={(e) => {
                  setSelected();
                  closeDropdown(e);
                  setSearch("");
                  onStateChange("Provisional_Import");
                }}
              >
                <img
                  src="/images/attack-surface/right-arrow.svg"
                  alt=""
                  className="mr-2"
                />
                Import "{TrimString(memberDetail?.name, 20)}"
              </div>
              <div
                className="f-grey  pointer d-flex align-items-center contextmenu-grey-item"
                onClick={(e) => {
                  setPage("importAs");
                }}
              >
                <img
                  src="/images/attack-surface/right-arrow.svg"
                  alt=""
                  className="mr-2"
                />
                Import as ...
              </div>
              <div
                className="f-grey   pointer d-flex align-items-center contextmenu-grey-item"
                onClick={(e) => {
                  setSelected();
                  closeDropdown(e);
                  setSearch("");
                  onStateChange("Ignored");
                }}
              >
                <img
                  src="/images/msp/integrations/cross-icon-msp.svg"
                  alt=""
                  className="mr-2"
                />
                Ignore
              </div>
            </div>
          </>
        )}
        {page === "importAs" && (
          <div className="w-100 p-2">
            <div className="f-12 f-darkgrey mb-2 pl-1"> Import as </div>

            <div className="p-1 bg-grey radius-4 highlight-input-with-grey-border">
              <input
                type="text"
                className="border-none bg-transparent w-100"
                value={search}
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
              />
            </div>
            <div className="mt-2 d-flex align-items-center justify-content-end">
              <Button
                className="hg-cancel-btn mr-2"
                onClick={() => {
                  setPage("home");
                  setSearch("");
                }}
              >
                Cancel
              </Button>
              <Button
                className="hg-blue-btn"
                disabled={search === ""}
                onClick={(e) => {
                  onStateChange("Provisional_Import", { ImportName: search });
                  closeDropdown(e);
                  setSearch("");
                }}
              >
                Save
              </Button>
            </div>
          </div>
        )}
      </Dropdown.Menu>
    </Dropdown>
  );
};

const MapMembersLoader = () => {
  return (
    <ContentLoader
      speed={2}
      width={"100%"}
      height={"100%"}
      viewBox="0 0 1500 900"
      backgroundColor="#F6F8FB"
      foregroundColor="#ecebeb"
    >
      <rect x="27%" y="20" rx="8" ry="8" width="4.5%" height="60" />
      <rect x="33%" y="22" rx="6" ry="6" width="10%" height="20" />
      <rect x="33%" y="52" rx="6" ry="6" width="25%" height="20" />

      <rect x="27%" y="100" rx="7" ry="7" width="45%" height="60" />

      <rect x="27%" y="200" rx="4" ry="4" width="6%" height="25" />
      <rect x="62%" y="200" rx="4" ry="4" width="10%" height="25" />

      <rect x="27%" y="230" rx="4" ry="4" width="45%" height="30" />

      <rect x="27%" y="262" rx="4" ry="4" width="45%" height="60" />
      <rect x="27%" y="324" rx="4" ry="4" width="45%" height="60" />
      <rect x="27%" y="386" rx="4" ry="4" width="45%" height="60" />
      <rect x="27%" y="448" rx="4" ry="4" width="45%" height="60" />
      <rect x="27%" y="510" rx="4" ry="4" width="45%" height="60" />
      <rect x="27%" y="572" rx="4" ry="4" width="45%" height="60" />
      <rect x="27%" y="634" rx="4" ry="4" width="45%" height="60" />
    </ContentLoader>
  );
};
