import React, { useState, useEffect, useRef } from "react";
import {
  Button,
  Col,
  Dropdown,
  Form,
  Modal,
  OverlayTrigger,
  Row,
  Tooltip,
} from "react-bootstrap";

import "./questionnairetab.scss";
import { useAuth } from "../../context/Auth";
import {
  getAPIData,
  postData,
  SubmitQuestionaire,
} from "../../../Utils/commonApi";
import { CallToast } from "../../PriceCalculator/modals/CustomToast";
import { TrimString } from "../../../Utils/StringTrimmer";
import Loader from "../../Common/loader";
import { WhiteLoader } from "../../Theme/APILoaders";
import moment from "moment";
import ContentLoader from "react-content-loader";
import HighgroundDatePicker from "../../test/TestComponent";
import PostEditor from "../../ActionCenter/PostEditor";
import { getHTMLfromRawState } from "../../ActionCenter/PostEditor/postEditor";
import { viewOnlyAccess } from "../../App/App";

const mentionList = [
  {
    name: "Howard Schultz",
    OwnerLogo: "No URL",
    OwnerInitials: "HS",
    OwnerShowInitals: true,
    OwnerUserName: "howy@starbuckscorp.com",
  },
  {
    name: "Test Supplier",
    OwnerLogo: "No URL",
    OwnerInitials: "TS",
    OwnerShowInitals: true,
    OwnerUserName: "test@starbuckscorp.com",
  },
  {
    name: "Test Supplier 2",
    OwnerLogo: "No URL",
    OwnerInitials: "AM",
    OwnerShowInitals: true,
    OwnerUserName: "taarn@starbuckscorp.com",
  },
];

const ReceivedQuestionAnswers = ({ setPage, pageData }) => {
  const { authTokens } = useAuth();
  const userCommentRef = useRef();
  const [loading, setLoading] = useState(false);
  const [loadingTemplateList, setLoadingTemplateList] = useState(false);
  const [saveExitButtonLoading, setSaveExitButtonLoading] = useState(false);
  const [newDueDate, setNewDueDate] = useState(null);
  const [renameTemplate, setRenameTemplate] = useState(false);
  const [mentionList, setMentionList] = useState([]);
  // Rendering Template working
  const [templateData, setTemplateData] = useState(template);
  const [selectedSectionQuestionList, setSelectedSectionQuestionList] =
    useState(0);

  const [isEditTemplate, setIsEditTemplate] = useState(false);

  // temporary states
  const [tempName, setTempName] = useState("");

  // Setup for Receievd Questions Answers
  const [questionsData, setQuestionsData] = useState([
    ...template.QuestionnaireSection_List,
  ]);

  const [saving, setSaving] = useState(false);
  const [sending, setSending] = useState(true);
  const [message, setMessage] = useState("");
  const [editMessageData, setEditMessageData] = useState("");
  const [data, setData] = useState({});

  // Setup to Add Prompt
  const [changesHappen, setChangesHappen] = useState(false);
  const [showPromptModal, setShowPromptModal] = useState(false);
  const [savingTemplateFromPromptModal, setSavingTemplateFromPromptModal] =
    useState(false);
  useEffect(() => {
    const queryString = window.location.search;
    const parameters = new URLSearchParams(queryString);
    const sentReceivedQuestionnaireId = parameters.get(
      "sentReceivedQuestionnaireId"
    );
    setLoading(true);
    setLoadingTemplateList(true);
    // getQuestionsData();
    setChangesHappen(false);
    getPageData(sentReceivedQuestionnaireId);
    getSupplierList();
    getAllMentionsList();
  }, []);

  useEffect(() => {
    if (data?.type) {
      getQuestionsData();
    }
  }, [data]);

  async function getAllMentionsList() {
    let queryString = window.location.search;
    let parameters = new URLSearchParams(queryString);
    let sentReceivedQuestionnaireId = parameters.get(
      "sentReceivedQuestionnaireId"
    );

    await getAPIData("335", authTokens, {
      optionEnum1:
        sentReceivedQuestionnaireId ??
        pageData?.questionnaireData?.questionnaireId,
    })
      .then((response) => {
        if (response?.mr?.Success) {
          let mentions = response?.UserList?.map(
            ({ OwnerName: name, ...rest }) => ({
              name,
              ...rest,
            })
          );
          setMentionList(mentions ?? []);
        }
      })
      .catch(() => {});
  }

  async function getSupplierList() {
    return;
    // await getAPIData(`${305}`, authTokens, {}).then((response) => {
    //   if (response?.mr?.Success) {
    //     if (response?.Suppliers?.length > 0) {
    //       let updated_supplier_list=response?.Suppliers?.map((supplier,index)=>{
    //         return{

    //             "name": supplier?.SupplierName,
    //             "OwnerLogo":supplier?.SupplierLogo ?? "No URL",
    //             "OwnerInitials": getInitials(supplier?.SupplierName),
    //             "OwnerShowInitals":supplier?.SupplierLogo ? false: true,
    //             "OwnerUserName": `test${index}@gmail.com`,
    //              objId_Customer:supplier?.objId_Customer
    //         }
    //       })
    //       setMentionList(updated_supplier_list ?? [])
    //     }
    //   }
    // });
  }
  // console.log(mentionList)

  async function getPageData(id) {
    await getAPIData(470, authTokens, {
      optionEnum1: id ?? pageData?.questionnaireData?.questionnaireId,
    })
      .then((response) => {
        window.history.pushState({}, "", `/supply`);
        if (response?.mr?.Success) {
          let question = response?.SCMQuestionnaires_List[0];
          setData({
            questionnaireData: {
              supplierId: question?.Supplier?.objId_Customer,
              templateId: question?.FormTemplateId,
              supplierName: question?.Supplier?.SupplierName,
              supplierLogo: question?.Supplier?.SupplierLogo,
              dueDate: question?.Duedate,
              templateName: question?.Title,
              questionnaireId: question?.ObjectId,
            },
            QuestionType: response?.IsSent ? "sent" : "received", //
            type: "questionAnswers",
            name: `${question?.Title}`,
            isSent: response?.IsSent,
            isSupplierPage: pageData?.isSupplierPage ?? false,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  async function getQuestionsData() {
    await getAPIData(431, authTokens, {
      optionEnum1: 0,
      optionEnum2: data?.questionnaireData?.templateId,
      optionEnum3: !data?.isSent ? 0 : data?.questionnaireData?.supplierId,
      optionEnum4: data?.questionnaireData?.questionnaireId,
    })
      .then((response) => {
        setLoading(false);
        if (response?.mr?.Success) {
          setQuestionsData(response?.QuestionnaireSection_List || []);
          // console.log(response?.QuestionnaireSection_List);
        } else {
          setQuestionsData(response?.QuestionnaireSection_List || []);
          CallToast("Something went wrong", false, false, "v2style");
        }
      })
      .catch((err) => {
        setQuestionsData([]);
        CallToast("Something went wrong", false, false, "v2style");
        setLoading(false);
        console.log(err);
      });
  }
  // function to send message and update chat
  async function updateChat() {
    await getAPIData(431, authTokens, {
      optionEnum1: 0,
      optionEnum2: data?.questionnaireData?.templateId,
      optionEnum3: data?.questionnaireData?.supplierId,
      optionEnum4: data?.questionnaireData?.questionnaireId,
    })
      .then((response) => {
        if (response?.mr?.Success) {
          let old_questions_data = [...questionsData];
          let new_questions_data = [...response?.QuestionnaireSection_List];
          for (let i = 0; i < new_questions_data?.length; i++) {
            for (
              let j = 0;
              j < new_questions_data[i]?.Question_List.length;
              j++
            ) {
              if (
                new_questions_data[i]?.Question_List[j].ResponseComment_List !==
                null
              ) {
                old_questions_data[i].Question_List[j].ResponseComment_List = [
                  ...new_questions_data[i]?.Question_List[j]
                    .ResponseComment_List,
                ];
              } else {
                old_questions_data[i].Question_List[j].ResponseComment_List =
                  [];
              }
            }
          }
          setQuestionsData(old_questions_data);
        } else {
          CallToast("Something went wrong", false, false, "v2style");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  async function sendMessage(message, questionId, commentId = 0) {
    setSending(true);
    await postData(authTokens, "CreateQuestionnaireResponseComment", {
      ObjId_Supplier: data?.questionnaireData?.supplierId,
      Message: message,
      QuestionId: questionId,
      ObjectId: commentId,
      accesskey: JSON.parse(localStorage.getItem("user_MessageObj")),
      FormTemplateId: data?.questionnaireData?.templateId,
      SendQuestionnaireId: data?.questionnaireData?.questionnaireId,
    })
      .then((response) => {
        if (response?.Success) {
          updateChat();
          setEditMessageData("");
          setSending(false);
          setMessage("");
          userCommentRef.current.reset();
          // CallToast("Something went wrong", false, false, "v2style");
        }
      })
      .catch((err) => {
        setSending(false);
        CallToast("Something went wrong", false, false, "v2style");
      });
  }

  // Function to delete message

  async function deleteComment(commentId) {
    await getAPIData(432, authTokens, {
      optionEnum1: commentId,
    })
      .then((response) => {
        if (response?.Success) {
          updateChat();
          CallToast("Comment deleted successfully", false, false, "v2style");
        } else {
          CallToast("Something went wrong", false, false, "v2style");
        }
      })
      .catch((err) => {
        console.log(err);
        CallToast("Something went wrong", false, false, "v2style");
      });
  }

  function answeredQuestionsData(questionsData) {
    try {
      let output = [];
      let data = [...questionsData];
      for (let i = 0; i < data.length; i++) {
        for (let j = 0; j < data[i].Question_List?.length; j++) {
          data[i].Question_List[j].QuestionStatus = 3;
          data[i].Question_List[j].QuestionStatusText = "Answered";
        }
        output.push(...data[i].Question_List);
      }
      return output;
    } catch (err) {
      console.log(err);
    }
  }

  let QuestionData = {
    ObjectId: 0,
    QuestionnaireTypeEnum: 10,
    QuestionList: JSON.stringify(answeredQuestionsData(questionsData)),
    accesskey: JSON.parse(localStorage.getItem("user_MessageObj")),
    ObjId_Supplier: 0, // data?.questionnaireData?.supplierId,
    objId_SendFormQuestionnaire: data?.questionnaireData?.questionnaireId,
  };

  async function SendQuestionnaire(closeAfterSave = false) {
    await SubmitQuestionaire(authTokens, "POST", QuestionData)
      .then((response) => {
        setSaveExitButtonLoading(false);
        setSaving(false);
        setSavingTemplateFromPromptModal(false);
        setChangesHappen(false);
        if (response?.Success) {
          CallToast("Updated successfully", false, false, "v2style");
        } else {
          CallToast("Something went wrong", false, false, "v2style");
        }

        if ((!data?.isSent && changesHappen) || closeAfterSave) {
          goBackScreen();
        }
      })
      .catch((err) => {
        setSaveExitButtonLoading(false);
        setSavingTemplateFromPromptModal(false);
        setSaving(false);
        CallToast("Something went wrong", false, false, "v2style");
      });
  }

  // Function to get single template Data
  async function getTemplate(ObjectId, isEdit = false) {
    if (ObjectId === 0) {
      setIsEditTemplate(true);
      return;
    }
    setLoading(true);
    await getAPIData(302, authTokens, {
      optionEnum1: ObjectId,
    })
      .then((response) => {
        setLoading(false);
        if (response.mr.Success) {
          setTemplateData(response);
          if (isEdit) {
            setIsEditTemplate(true);
          }
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        CallToast("Something went wrong", false, false, "v2style");
      });
  }

  // Funtion to rename the template
  async function renameTemplateFunc(ObjectId, Title) {
    if (ObjectId == 0) return;
    await postData(authTokens, "RenameSCMTemplateName", {
      ObjectId,
      Title,
    })
      .then((response) => {
        if (response.Success) {
          CallToast("Updated successfully", false, false, "v2style");
          getQuestionsData();
          if (ObjectId === templateData?.ObjectId) {
            getTemplate(ObjectId);
          }
        }
      })
      .catch((err) => {
        console.log(err);
        CallToast("Something went wrong", false, false, "v2style");
      });
  }

  // Function to duplicate the template
  async function createDuplicateTemplate(ObjectId) {
    await getAPIData(303, authTokens, {
      optionEnum1: ObjectId,
    })
      .then((response) => {
        if (response.Success) {
          CallToast("Created successfully", false, false, "v2style");
          getQuestionsData();
        }
      })
      .catch((err) => {
        console.log(err);
        CallToast("Something went wrong", false, false, "v2style");
      });
  }

  const totalQuestions = questionsData.reduce((prev, current) => {
    return prev + current?.Question_List?.length;
  }, 0);
  const answeredQuestions = questionsData.reduce((prev, current) => {
    return (
      prev +
      current?.Question_List?.filter(
        (item) =>
          (item?.AnswerText !== null && item?.AnswerText !== "") ||
          item?.AnswerBool !== null ||
          (item?.NoteAnswerText !== null && item?.NoteAnswerText !== "")
      )?.length
    );
  }, 0);

  function goBackScreen() {
    setPage({ type: "home", name: "" });
  }

  function parseDateForMoment(_newDueDate) {
    if (_newDueDate?.split("/")?.length === 3) {
      return `${_newDueDate?.split("/")[2]}-${_newDueDate?.split("/")[1]}-${
        _newDueDate?.split("/")[0]?.length === 1
          ? `0${_newDueDate?.split("/")[0]}`
          : _newDueDate?.split("/")[0]
      }T00:00:00`;
    } else {
      return null;
    }
  }
  const [dueDateChanging, setDueDateChanging] = useState(false);
  async function updateDueDate(date) {
    setDueDateChanging(true);
    if (dueDateChanging) return;
    await getAPIData(469, authTokens, {
      optionEnum1: data?.questionnaireData?.questionnaireId,
      optionStrEnum1: date,
    })
      .then((response) => {
        setDueDateChanging(false);
        if (response?.Success) {
          CallToast("Updated Successfully", false, false, "v2style");
        }
      })
      .catch((err) => {
        setDueDateChanging(false);
        console.log(err);
        CallToast("Something went wrong", false, false, "v2style");
      });
  }

  return (
    <div className="questionnaire-big-modal w-100">
      <div className="m-0 h-100 w-100 d-flex">
        {/* -----------------Right Part ------------*/}
        <div
          className="questionnaire-right-side-wrapper"
          style={{
            width: "100%",
          }}
        >
          <div
            className={` align-items-center justify-content-between py-2 px-3 border-bottom position-sticky top-0`}
            style={{
              display: `${data?.isSupplierPage || loading ? "none" : "flex"}`,
            }}
          >
            <div className="d-flex align-items-center">
              <div
                className="d-flex align-items-center pointer mr-3"
                onClick={() => {
                  if (!data?.isSent && changesHappen) {
                    setShowPromptModal(true);
                  } else {
                    setPage({ type: "home", name: "" });
                    window.history.pushState({}, "", `/supply`);
                  }
                }}
              >
                <img
                  alt=""
                  src="/images/icons/arrowLeft.svg"
                  className="pointer ml-3"
                />
                <div className="f-grey f-500 ml-3 pointer">Back</div>
              </div>
              <div className="f-16 f-500  p-1 radius-2">
                {data?.questionnaireData?.templateName}
              </div>
            </div>

            <div className="d-flex align-items-center">
              {
                <>
                  <Button
                    className="hg-tranparent-grey-btn mr-2 d-none"
                    onClick={() => {}}
                  >
                    Cancel
                  </Button>
                  <Button
                    className={`hg-blue-btn mr-3 ${
                      data?.isSent ? "d-none" : " "
                    }  `}
                    onClick={() => {
                      setSaveExitButtonLoading(true);
                      SendQuestionnaire(true);
                    }}
                  >
                    {saveExitButtonLoading ? (
                      <div className="d-flex align-items-center">
                        <WhiteLoader width={13} /> Saving..
                      </div>
                    ) : (
                      "Save and Exit"
                    )}
                  </Button>
                </>
              }

              <div className="template-item-dropdown visibility-hidden d-none">
                <Dropdown
                  onToggle={() => {
                    setRenameTemplate(false);
                    setTempName("");
                  }}
                  className={`  device-dropdown ${
                    renameTemplate ? "white-side-dropdown" : "black-dropdown"
                  }`}
                >
                  <Dropdown.Toggle>
                    <img alt="" src="/images/horizontal-dots.svg" />
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <div>
                      {renameTemplate ? (
                        <div className="d-flex align-items-center hg-border mx-1 border-4 p-1">
                          <input
                            className=""
                            value={tempName}
                            onChange={(e) => {
                              setTempName(e.target.value);
                            }}
                            autoFocus
                          />
                          <Button
                            className="hg-blue-btn"
                            onClick={(e) => {
                              renameTemplateFunc(
                                templateData?.ObjectId,
                                tempName
                              );
                              e.target.closest(".device-dropdown").click();
                            }}
                          >
                            Save
                          </Button>
                        </div>
                      ) : (
                        <Row>
                          <Col xs={12} className=" pointer ">
                            <div
                              className="drop-item"
                              onClick={(e) => {
                                getTemplate(templateData?.ObjectId, true);
                                e.target.closest(".device-dropdown").click();
                              }}
                            >
                              Edit
                            </div>
                          </Col>
                          <Col xs={12} className="  pointer ">
                            <div
                              className="drop-item"
                              onClick={(e) => {
                                // e.target
                                //   .closest(".device-dropdown")
                                //   .click();
                                setTempName(templateData?.Title);
                                setRenameTemplate(true);
                              }}
                            >
                              Rename
                            </div>
                          </Col>

                          <Col xs={12} className=" pointer ">
                            <div
                              className="drop-item"
                              onClick={(e) => {
                                createDuplicateTemplate(templateData?.ObjectId);
                                e.target.closest(".device-dropdown").click();
                              }}
                            >
                              Duplicate
                            </div>
                          </Col>
                          <Col xs={12} className=" pointer ">
                            <div
                              className="drop-item"
                              onClick={(e) => {
                                e.target.closest(".device-dropdown").click();
                              }}
                            >
                              Delete
                            </div>
                          </Col>
                        </Row>
                      )}
                    </div>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          </div>
          {/*  Body -----------*/}
          {loading ? (
            <ScreenLoader />
          ) : (
            <div className="w-100 d-flex h-100">
              {loading && false ? (
                <></>
              ) : (
                // <div
                //   className="loader d-flex align-items-center justify-content-center h-100"
                //   style={{
                //     width: "70%",
                //   }}
                // >
                //   <Loader />
                // </div>
                <div
                  className="px-5 pt-3 hide-scrollbar"
                  style={{
                    width: "75%",
                    overflowY: "scroll",
                    height: "100vh",
                  }}
                >
                  {/** Question data  */}
                  <div className="mx-auto" style={{ width: "80%" }}>
                    {/* Section name */}
                    <div className="f-16 f-600 bg-hov er-grey radius-2 p-1 py-2">
                      {questionsData?.[selectedSectionQuestionList]?.Title}
                    </div>

                    {questionsData?.[
                      selectedSectionQuestionList
                    ]?.Question_List.map((question, questionIndex) => {
                      function isCheckBoxSelected(option) {
                        let answer = question?.AnswerText;
                        if (answer !== null) {
                          let options = [...answer.split(",")];
                          let Index = options.findIndex(
                            (item) => item === option
                          );
                          if (Index !== -1) {
                            return true;
                          } else {
                            return false;
                          }
                        } else {
                          return false;
                        }
                      }
                      function toggleCheckbox(option) {
                        let question_data = [...questionsData];
                        let answer =
                          question_data?.[selectedSectionQuestionList]
                            ?.Question_List?.[questionIndex].AnswerText;
                        if (answer === null) {
                          question_data[
                            selectedSectionQuestionList
                          ].Question_List[questionIndex].AnswerText = option;
                          setQuestionsData(question_data);
                        } else {
                          let answer_list = [...answer.split(",")];
                          let Index = answer_list.findIndex(
                            (item) => item === option
                          );
                          if (Index === -1) {
                            answer_list.push(option);
                            question_data[
                              selectedSectionQuestionList
                            ].Question_List[questionIndex].AnswerText =
                              answer_list.join(",");
                            setQuestionsData(question_data);
                          } else {
                            answer_list.splice(Index, 1);
                            question_data[
                              selectedSectionQuestionList
                            ].Question_List[questionIndex].AnswerText =
                              answer_list.join(",");
                            setQuestionsData(question_data);
                          }
                        }
                      }

                      return (
                        <div className="mt-5 hover-on-show-parent">
                          <div className="d-flex align-items-center justify-content-between">
                            <div>
                              <div className=" f-500  p-1 radius-2">
                                {questionIndex + 1}. {question?.QuestionText}
                              </div>
                            </div>
                            <div>
                              <div
                                className="w-100 d-flex align-items-center"
                                style={{
                                  pointerEvents: viewOnlyAccess(
                                    "AccessState_SCM"
                                  )
                                    ? "none"
                                    : "all",
                                }}
                              >
                                {/* Chat  drop down in questions */}
                                {viewOnlyAccess("AccessState_SCM") ? (
                                  <></>
                                ) : (
                                  <div className="question-chat-dropdown mx-2">
                                    <Dropdown
                                      className="chat-dropdown"
                                      onToggle={(e) => {
                                        setSending(false);
                                        setMessage("");
                                        setEditMessageData("");
                                        // console.log(e)
                                        if (e) {
                                          setTimeout(() => {
                                            userCommentRef.current.reset();
                                          }, 200);
                                        }
                                      }}
                                    >
                                      <Dropdown.Toggle
                                        className="p-0 position-relative"
                                        style={{ zIndex: 1 }}
                                      >
                                        <div
                                          onMouseEnter={(event) => {
                                            event.target.click();
                                          }}
                                          className={`d-flex align-items-center f-12 f-500 f-grey  ${
                                            question?.ResponseComment_List
                                              ?.length > 0
                                              ? ""
                                              : "hover-on-show-child"
                                          } `}
                                        >
                                          {question?.ResponseComment_List
                                            ?.length > 0 ? (
                                            <img
                                              alt=""
                                              className="mr-1"
                                              src="/images/supply-chain/chat-icon.svg"
                                            />
                                          ) : (
                                            <img
                                              alt=""
                                              className="mr-1"
                                              src="/images/attack-surface/no-comments-icon.svg"
                                            />
                                          )}
                                          {question?.ResponseComment_List
                                            ?.length || ""}
                                        </div>
                                      </Dropdown.Toggle>

                                      <Dropdown.Menu>
                                        <div>
                                          {/* Header */}
                                          <div className="chat-box-head px-2 pb-2 border-bottom">
                                            <div className="d-flex align-items-center justify-content-between">
                                              <div className="f-12 f-darkgrey">
                                                Question ID:{" "}
                                                {question?.QuestionId}
                                              </div>
                                              <div>
                                                <img
                                                  alt=""
                                                  className="pointer"
                                                  src="/images/supply-chain/chat-box-cross.svg"
                                                  onClick={(e) => {
                                                    e.target
                                                      .closest(".chat-dropdown")
                                                      .click();
                                                  }}
                                                />
                                              </div>
                                            </div>
                                          </div>
                                          {/* Body */}
                                          <div className="py-3 hide-scrollba r chat-bod y">
                                            {
                                              // question?.SCMFormComment_List

                                              question?.ResponseComment_List?.map(
                                                (comment, commentIndex) => {
                                                  return (
                                                    <div className=" d-flex align-items-start justify-content-between mx-3 mb-3">
                                                      <div className="d-flex">
                                                        <img
                                                          alt=""
                                                          className="rounded-full mr-2"
                                                          width={24}
                                                          height={24}
                                                          src={
                                                            comment?.ImageURL
                                                          }
                                                          onError={({
                                                            currentTarget,
                                                          }) => {
                                                            currentTarget.onerror =
                                                              null; // prevents looping
                                                            currentTarget.src =
                                                              "/images/settings-v2/highground-dp.svg";
                                                          }}
                                                        />

                                                        <div>
                                                          <p className="m-0 f-500 f-black">
                                                            {TrimString(
                                                              comment?.FullName,
                                                              22
                                                            )}
                                                          </p>
                                                          {/* <p className=" f-black">
                                                          {comment?.Message}
                                                        </p> */}
                                                          <span
                                                            dangerouslySetInnerHTML={{
                                                              __html:
                                                                getHTMLfromRawState(
                                                                  comment?.Message,
                                                                  mentionList
                                                                ),
                                                            }}
                                                          />
                                                        </div>
                                                      </div>
                                                      <div>
                                                        <div
                                                          className={`template-item-dropdown  ${
                                                            comment?.CanUpdateDelete
                                                              ? ""
                                                              : "d-none"
                                                          }  `}
                                                        >
                                                          <Dropdown
                                                            onToggle={() => {}}
                                                            className={`  device-dropdown ${
                                                              renameTemplate
                                                                ? "white-side-dropdown"
                                                                : "black-dropdown"
                                                            }`}
                                                          >
                                                            <Dropdown.Toggle>
                                                              <img
                                                                alt=""
                                                                src="/images/horizontal-dots.svg"
                                                              />
                                                            </Dropdown.Toggle>
                                                            <Dropdown.Menu>
                                                              <div>
                                                                <Row>
                                                                  <Col
                                                                    xs={12}
                                                                    className=" pointer "
                                                                  >
                                                                    <div
                                                                      className="drop-item"
                                                                      onClick={(
                                                                        e
                                                                      ) => {
                                                                        setEditMessageData(
                                                                          {
                                                                            username:
                                                                              comment?.FullName,
                                                                            messageId:
                                                                              comment?.ObjectId,
                                                                          }
                                                                        );
                                                                        // setMessage(
                                                                        //   comment?.Message
                                                                        // );
                                                                        userCommentRef.current.setValue(
                                                                          JSON.parse(
                                                                            comment?.Message
                                                                          )
                                                                        );
                                                                        e.target
                                                                          .closest(
                                                                            ".device-dropdown"
                                                                          )
                                                                          .click();
                                                                      }}
                                                                    >
                                                                      Edit
                                                                    </div>
                                                                  </Col>

                                                                  <Col
                                                                    xs={12}
                                                                    className=" pointer "
                                                                  >
                                                                    <div
                                                                      className="drop-item"
                                                                      onClick={(
                                                                        e
                                                                      ) => {
                                                                        deleteComment(
                                                                          comment?.ObjectId
                                                                        );
                                                                        e.target
                                                                          .closest(
                                                                            ".device-dropdown"
                                                                          )
                                                                          .click();
                                                                      }}
                                                                    >
                                                                      Delete
                                                                    </div>
                                                                  </Col>
                                                                </Row>
                                                              </div>
                                                            </Dropdown.Menu>
                                                          </Dropdown>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  );
                                                }
                                              )
                                            }
                                          </div>
                                          {/* footer */}
                                          <div>
                                            {editMessageData ? (
                                              <div
                                                className="d-flex align-items-center justify-content-between pt-1 px-2"
                                                style={{
                                                  borderTop:
                                                    "1px solid #ebecf1",
                                                }}
                                              >
                                                <div className="f-black f-500">
                                                  {editMessageData?.username}
                                                </div>
                                                <img
                                                  alt=""
                                                  className="pointer"
                                                  src="/images/attack-surface/small-cross.svg"
                                                  onClick={() => {
                                                    setEditMessageData("");
                                                    setMessage("");
                                                    userCommentRef.current.reset();
                                                  }}
                                                />
                                              </div>
                                            ) : null}

                                            <div className="message-typer d-flex align-items-center mx-1 mt-2">
                                              <div
                                                className="w-100"
                                                style={{
                                                  paddingLeft: "3px",
                                                }}
                                              >
                                                <PostEditor
                                                  className="PostEditor__input"
                                                  placeholder={"Add a comment"}
                                                  getValue={(e) => {
                                                    setMessage(
                                                      JSON.stringify(e)
                                                    );
                                                  }}
                                                  mentions={mentionList}
                                                  ref={userCommentRef}
                                                />
                                              </div>

                                              <Button
                                                className=""
                                                onClick={() => {
                                                  if (
                                                    JSON.parse(message)
                                                      ?.blocks?.[0]?.text ===
                                                      "" ||
                                                    !JSON.parse(message)
                                                      ?.blocks?.[0]?.text
                                                  )
                                                    return;

                                                  if (
                                                    message !== "" &&
                                                    !editMessageData?.messageId
                                                  ) {
                                                    sendMessage(
                                                      message,
                                                      question?.QuestionId
                                                    );
                                                  }
                                                  if (
                                                    message !== "" &&
                                                    editMessageData?.messageId
                                                  ) {
                                                    sendMessage(
                                                      message,
                                                      question?.QuestionId,
                                                      editMessageData?.messageId
                                                    );
                                                  }
                                                }}
                                              >
                                                {sending ? (
                                                  <WhiteLoader width={11} />
                                                ) : (
                                                  <img
                                                    alt=""
                                                    width={12}
                                                    height={12}
                                                    src="/images/settings-v2/white-plane.svg"
                                                    className=""
                                                    style={{ width: "20px" }}
                                                  />
                                                )}
                                              </Button>
                                            </div>
                                          </div>
                                        </div>
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                          <div
                            className={`${
                              data?.isSent ? "disable-sent-questionnaire" : ""
                            }`}
                            style={{
                              pointerEvents: `${data?.isSent ? "none" : "all"}`,
                            }}
                          >
                            {/* For Check Box  Question Answers*/}

                            {(question?.QuestionTypeEnum === 16 ||
                              question?.QuestionTypeEnum === 17) && (
                              <>
                                <div className="pt-3">
                                  {question?.QuestionDropdownListMembers?.map(
                                    (option, optionIndex) => {
                                      return (
                                        <div className="d-flex align-items-center my-1">
                                          <div className="mb-3">
                                            <Form.Group
                                              controlId={
                                                "check" +
                                                questionIndex +
                                                "" +
                                                optionIndex
                                              }
                                              className="round-checkbox blue-checkbox checkboxstyle mb-1"
                                            >
                                              <Form.Check
                                                type="checkbox"
                                                name={"check" + optionIndex}
                                                label=""
                                                checked={isCheckBoxSelected(
                                                  option
                                                )}
                                                onChange={(e) => {
                                                  toggleCheckbox(option);
                                                  setChangesHappen(true);
                                                }}
                                              />
                                            </Form.Group>
                                          </div>
                                          <div className=" f-500 f-black  p-1 radius-2 ml-3">
                                            {option}
                                          </div>
                                        </div>
                                      );
                                    }
                                  )}
                                </div>
                              </>
                            )}

                            {/* For Radio TYpe Question Answers */}

                            {(question?.QuestionTypeEnum === 1 ||
                              question?.QuestionTypeEnum === 5) && (
                              <>
                                <div className="pt-3">
                                  {question?.QuestionDropdownListMembers?.map(
                                    (option, optionIndex) => {
                                      return (
                                        <div className="d-flex align-items-center">
                                          <div className="mb-3">
                                            <Form.Group
                                              controlId={
                                                "check" +
                                                questionIndex +
                                                "" +
                                                optionIndex
                                              }
                                              className="round-checkbox round blue-checkbox  mb-1"
                                            >
                                              <Form.Check
                                                type="radio"
                                                name={"check" + questionIndex}
                                                label=""
                                                checked={
                                                  question?.AnswerText ===
                                                  option
                                                }
                                                onChange={(e) => {
                                                  let updated_data = [
                                                    ...questionsData,
                                                  ];

                                                  updated_data[
                                                    selectedSectionQuestionList
                                                  ]["Question_List"][
                                                    questionIndex
                                                  ].AnswerText = option;

                                                  setQuestionsData(
                                                    updated_data
                                                  );
                                                  setChangesHappen(true);
                                                }}
                                              />
                                            </Form.Group>
                                          </div>
                                          <div className=" f-500  p-1 radius-2 ml-3">
                                            {option}
                                          </div>
                                        </div>
                                      );
                                    }
                                  )}
                                </div>
                              </>
                            )}

                            {/* For Yes and No  Question Answers*/}

                            {(question?.QuestionTypeEnum === 2 ||
                              question?.QuestionTypeEnum === 6) && (
                              <>
                                {" "}
                                <div className="pt-3">
                                  <div className="d-flex align-items-center ml-2">
                                    <Button
                                      onClick={() => {
                                        let updated_data = [...questionsData];

                                        updated_data[
                                          selectedSectionQuestionList
                                        ]["Question_List"][
                                          questionIndex
                                        ].AnswerBool = true;

                                        setQuestionsData(updated_data);
                                        setChangesHappen(true);
                                      }}
                                      className={` ${
                                        question?.AnswerBool === true
                                          ? "hg-blue-btn"
                                          : "hg-cancel-btn"
                                      }     mr-2`}
                                    >
                                      Yes
                                    </Button>
                                    <Button
                                      onClick={() => {
                                        let updated_data = [...questionsData];

                                        updated_data[
                                          selectedSectionQuestionList
                                        ]["Question_List"][
                                          questionIndex
                                        ].AnswerBool = false;

                                        setQuestionsData(updated_data);
                                        setChangesHappen(true);
                                      }}
                                      className={` ${
                                        question?.AnswerBool === false
                                          ? "hg-blue-btn"
                                          : "hg-cancel-btn"
                                      }     `}
                                    >
                                      No
                                    </Button>
                                  </div>
                                </div>
                              </>
                            )}

                            {/* For Text Field  Question Answers*/}

                            {question?.QuestionTypeEnum === 0 && (
                              <>
                                <div className="pt-3">
                                  <div className="d-flex align-items-center ml-2">
                                    <div className="d-flex align-items-center w-100 ml-2">
                                      <input
                                        className="p-2 radius-2 w-100 border-none"
                                        placeholder="Type your answer.."
                                        value={
                                          questionsData[
                                            selectedSectionQuestionList
                                          ]["Question_List"][questionIndex]
                                            .AnswerText ?? ""
                                        }
                                        onChange={(e) => {
                                          let updated_data = [...questionsData];

                                          updated_data[
                                            selectedSectionQuestionList
                                          ]["Question_List"][
                                            questionIndex
                                          ].AnswerText = e.target.value;

                                          setQuestionsData(updated_data);
                                          setChangesHappen(true);
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </>
                            )}

                            {/* For Note Field */}
                            {(question?.QuestionTypeEnum === 17 ||
                              question?.QuestionTypeEnum === 6 ||
                              question?.QuestionTypeEnum === 5) && (
                              <textarea
                                placeholder="Note..."
                                className="w-100 p-2 radius-4 border-none mt-3 "
                                value={
                                  questionsData[selectedSectionQuestionList][
                                    "Question_List"
                                  ][questionIndex]?.NoteAnswerText ?? ""
                                }
                                onChange={(e) => {
                                  let updated_data = [...questionsData];

                                  updated_data[selectedSectionQuestionList][
                                    "Question_List"
                                  ][questionIndex].NoteAnswerText =
                                    e.target.value;

                                  setQuestionsData(updated_data);
                                  setChangesHappen(true);
                                }}
                                style={{
                                  height: "80px",
                                  background: "#F6F8FB",
                                }}
                              ></textarea>
                            )}
                          </div>
                        </div>
                      );
                    })}
                    <hr />
                    <div className="d-flex align-items-center justify-content-end">
                      <Button
                        className={`grey-cancel-btn p-3 px-2 mr-2  `}
                        onClick={() => {
                          setSelectedSectionQuestionList(
                            selectedSectionQuestionList - 1
                          );
                        }}
                        disabled={selectedSectionQuestionList === 0}
                      >
                        Previous
                      </Button>
                      {/* For Sent Questionnaire Section */}

                      {data?.isSent && (
                        <Button
                          className={`blue-next-btn p-3 px-2  `}
                          onClick={() => {
                            setSelectedSectionQuestionList(
                              selectedSectionQuestionList + 1
                            );
                          }}
                          disabled={
                            selectedSectionQuestionList ===
                            questionsData?.length - 1
                          }
                        >
                          Next
                        </Button>
                      )}
                      {/* for Received Questionnaire Section */}
                      {!data?.isSent && (
                        <Button
                          className={`blue-next-btn p-3 px-2  `}
                          onClick={() => {
                            if (
                              selectedSectionQuestionList ===
                              questionsData?.length - 1
                            ) {
                              setSaving(true);
                              setChangesHappen(false);
                              SendQuestionnaire(false);
                            } else {
                              setSelectedSectionQuestionList(
                                selectedSectionQuestionList + 1
                              );
                            }
                          }}
                        >
                          {selectedSectionQuestionList <
                            questionsData?.length - 1 && "Next"}

                          {selectedSectionQuestionList ===
                            questionsData?.length - 1 && (
                            <>{saving ? "Saving.." : "Save"}</>
                          )}
                        </Button>
                      )}
                    </div>

                    <div className="" style={{ padding: "50px" }}></div>
                  </div>
                </div>
              )}

              {/* Side bar */}
              <div
                className="border-left  h-100 pl-2 pr-4 pt-4 hide-scrollbar"
                style={{
                  width: "25%",
                  overflowY: "auto",
                }}
              >
                <div className="mx-auto" style={{ width: "90%" }}>
                  <div className="d-flex align-items-center f-black f-500 mb-2">
                    <img
                      alt=""
                      width={32}
                      height={32}
                      className="mr-3 rounded-full"
                      src={
                        data?.questionnaireData?.supplierLogo ||
                        "/images/settings-v2/highground-dp.svg"
                      }
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src =
                          "/images/settings-v2/highground-dp.svg";
                      }}
                    />
                    {data?.questionnaireData?.supplierName}
                  </div>
                  <div className="d-flex align-items-center mb-2">
                    <div
                      className="flex align-items-center"
                      style={{ width: "100%" }}
                    >
                      <progress
                        min="0"
                        value={(answeredQuestions / totalQuestions) * 100}
                        max="100"
                      />
                    </div>
                    <div className="f-500 f-darkgrey ml-2">
                      {Math.floor((answeredQuestions / totalQuestions) * 100)}%
                    </div>
                  </div>
                  <div className="w-100 d-flex align-items-center justify-content-between">
                    <div className="f-darkgrey d-flex align-items-center">
                      <img
                        alt=""
                        className="mr-2"
                        src="/images/supply-chain/calender-icon.svg"
                      />
                      {moment(
                        parseDateForMoment(newDueDate) ??
                          data?.questionnaireData?.dueDate
                      ).format("MMMM Do YYYY")}
                    </div>
                    <OverlayTrigger
                      placement="bottom"
                      trigger={
                        viewOnlyAccess("AccessState_SCM")
                          ? ["hover", "focus"]
                          : []
                      }
                      delay={{ show: 200, hide: 250 }}
                      overlay={
                        <Tooltip
                          id={`tooltip-1365`}
                          className="custom_tooltip_access"
                        >
                          This feature is managed by your service provider
                        </Tooltip>
                      }
                    >
                      <div>
                        <div
                          className="change-due-date-picker"
                          style={{
                            pointerEvents: viewOnlyAccess("AccessState_SCM")
                              ? "none"
                              : "all",
                          }}
                        >
                          {data?.isSent && (
                            <HighgroundDatePicker
                              selected={
                                newDueDate ??
                                moment(data?.questionnaireData?.dueDate)
                                  ?.format("DD/MM/YYYY")
                                  ?.split("/")?.length === 3
                                  ? moment(
                                      data?.questionnaireData?.dueDate
                                    )?.format("DD/MM/YYYY")
                                  : ""
                              }
                              placeholder={"Choose due date"}
                              // icon={"/images/attack-surface/date-icon.svg"}
                              onChange={(e) => {
                                setNewDueDate(e);
                                updateDueDate(e);
                              }}
                              id={"cbhfgsvwmv"}
                              isError={false}
                            >
                              <div
                                className={`${
                                  viewOnlyAccess("AccessState_SCM")
                                    ? "f-darkgrey"
                                    : "f-blue"
                                }  f-500 d-flex`}
                              >
                                {viewOnlyAccess("AccessState_SCM") ? (
                                  <img
                                    alt=""
                                    src="/images/attack-surface/small-lock.svg"
                                    className="mr-2"
                                  />
                                ) : (
                                  <></>
                                )}
                                Change
                              </div>
                            </HighgroundDatePicker>
                          )}
                        </div>
                      </div>
                    </OverlayTrigger>
                  </div>
                  <hr className="hg-border  mx-auto" />
                </div>
                {/* section List */}
                {questionsData?.map((section, sectionIndex) => {
                  return (
                    <div
                      className={`d-flex align-items-center bg-hover-grey p-3 pointer radius-4 mb-1 ${
                        selectedSectionQuestionList === sectionIndex
                          ? "bg-grey"
                          : ""
                      }  `}
                      onClick={() => {
                        setSelectedSectionQuestionList(sectionIndex);
                      }}
                    >
                      {section?.Question_List?.length ===
                      answeredQuestionsInSections(section) ? (
                        <img
                          alt=""
                          className="mr-2"
                          width={31}
                          height={31}
                          src="/images/supply-chain/completed-answers.svg"
                        />
                      ) : (
                        <div
                          className="mr-2 d-flex align-items-center justify-content-center position-relative"
                          style={{
                            width: "32px",
                            height: "32px",
                            borderRadius: "50%",
                            //   padding: "5px",
                            background: "#EBEDF1",
                            color: "#616778",
                          }}
                        >
                          <span
                            className="position-absolute"
                            style={{
                              top: "50%",
                              left: "50%",
                              transform: "translate(-50%,-50%)",
                            }}
                          >
                            {sectionIndex + 1}
                            {/* {answeredQuestionsInSections(section)} */}
                          </span>
                          <svg
                            height="32"
                            width="32"
                            viewBox="0 0 34 34"
                            className="percentage-check"
                          >
                            <circle
                              cx="17"
                              cy="17"
                              r="16"
                              stroke="#008FD4"
                              stroke-width="1.5"
                              fill="transparent"
                              pathLength={section?.Question_List?.length}
                              strokeDasharray={section?.Question_List?.length}
                              strokeDashoffset={
                                section?.Question_List?.length -
                                answeredQuestionsInSections(section)
                              }
                            />
                          </svg>
                        </div>
                      )}

                      <div>
                        <p className="m-0 f-500 f-black">
                          {TrimString(section?.Title, 20)}
                        </p>
                        <p className="m-0 f-darkgrey f-12">
                          {section?.Question_List?.length} questions
                        </p>
                      </div>
                    </div>
                  );
                })}

                <div className="" style={{ padding: "40px" }}></div>
              </div>
            </div>
          )}
        </div>
      </div>
      <ResetSettingsModal
        show={showPromptModal}
        hideModal={() => setShowPromptModal(false)}
        saveTemplate={SendQuestionnaire}
        templateData={templateData}
        setSavingTemplateFromPromptModal={setSavingTemplateFromPromptModal}
        savingTemplateFromPromptModal={savingTemplateFromPromptModal}
        goBack={goBackScreen}
      />
    </div>
  );
};

export default ReceivedQuestionAnswers;

function answeredQuestionsInSections(wholeSection) {
  return wholeSection?.Question_List?.filter(
    (item) =>
      (item?.AnswerText !== null && item?.AnswerText !== "") ||
      item?.AnswerBool !== null ||
      (item?.NoteAnswerText !== null && item?.NoteAnswerText !== "")
  )?.length;
}

const ResetSettingsModal = ({
  show,
  hideModal,
  saveTemplate,
  templateData,
  setSavingTemplateFromPromptModal,
  savingTemplateFromPromptModal,
  goBack,
}) => {
  let modalBackdrop = document.getElementsByClassName("modal")[0];

  useEffect(() => {
    setTimeout(() => {
      if (!modalBackdrop) return;
      if (show) {
        // alert("ran")
        document.getElementsByClassName("modal")[0].style.zIndex = 1040;
      } else {
        // document.getElementsByClassName("modal")[0].style.zIndex=1050
      }
    }, 100);
  }, [show]);

  return (
    <Modal
      animation={false}
      show={show}
      dialogClassName="reset-settings-modal modal-450px"
      className="dark-backdrop"
      aria-labelledby="reset-settings-modal-title-vcenter"
      centered
      scrollable
      onHide={hideModal}
    >
      <Modal.Body>
        <div>
          <p className="modal-title ml-2 mt-2">
            You are about to leave this page.
          </p>
          <p className="modal-subtitle mx-2 mt-3">
            Would you like to save your answers?
          </p>
        </div>
      </Modal.Body>

      <Modal.Footer>
        <div className="footer d-flex justify-content-end">
          <Button className="cancel-btn pointer" onClick={goBack}>
            Don't Save
          </Button>
          <Button
            className="hg-blue-btn"
            onClick={() => {
              setSavingTemplateFromPromptModal(true);

              saveTemplate();
            }}
          >
            {savingTemplateFromPromptModal ? (
              <div className="d-flex align-items-center">
                <WhiteLoader width={13} /> Saving..
              </div>
            ) : (
              "Save"
            )}
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export const template = {
  ObjectId: 0,
  QuestionnaireTypeEnum: 0,
  QuestionnaireTypeString: null,
  QuestionnaireSection_List: [
    {
      Title: "Test Section111",
      SortOrder: 1,
      EnumerateQuestions: true,
      Progress: 2,
      ProgressText: "Complete",
      PrePopulatedProgressText: null,
      Question_List: [
        {
          QuestionId: 3381901,
          QuestionAllowEdit: true,
          QuestionOrder: 1,
          QuestionScore: 0,
          QuestionDescription: "Test Note",
          QuestionDropdownListMembers: ["checkbox3", "checkbox4"],
          QuestionSectionNo: 1,
          QuestionStatus: 5,
          QuestionStatusText: "LoadedForEdit",
          QuestionText: "Test Question12",
          QuestionTypeEnum: 16,
          QuestionTypeText: "MultiSelectCheckBox",
          QuestionCheckBoxLabel: "na",
          QuestionUserList: null,
          AnswerId: 3382205,
          AnswerBool: null,
          AnswerDate: "2022-12-09T13:43:08.883616+05:30",
          AnswerText: "checkbox3",
          AnswserSelectedDropdownItems: null,
          AnswerSelectedQuestionDDLObjId: 0,
          AnswerSelectedUserNames: null,
          DateTimeAnswer: null,
          Label: null,
          QuestionParentId: 0,
          ChildQuestions: null,
          ResponseComment_List: [
            {
              ObjectId: 3383141,
              QuestionId: 3381901,
              Message: "test comment by ravish Second Comment",
              FullName: "Testing Ravish 422",
              CanUpdateDelete: true,
              CreatedDate: "09/12/2022 07:29:34",
            },
          ],
        },
        {
          QuestionId: 3381919,
          QuestionAllowEdit: true,
          QuestionOrder: 2,
          QuestionScore: 0,
          QuestionDescription: "Test Note",
          QuestionDropdownListMembers: ["dropdown7", "dropdown5"],
          QuestionSectionNo: 1,
          QuestionStatus: 5,
          QuestionStatusText: "LoadedForEdit",
          QuestionText: "Test Question2",
          QuestionTypeEnum: 3,
          QuestionTypeText: "DropdownList",
          QuestionCheckBoxLabel: "na",
          QuestionUserList: null,
          AnswerId: 3382206,
          AnswerBool: null,
          AnswerDate: "2022-12-09T13:43:09.0916185+05:30",
          AnswerText: "dropdown5",
          AnswserSelectedDropdownItems: null,
          AnswerSelectedQuestionDDLObjId: 0,
          AnswerSelectedUserNames: null,
          DateTimeAnswer: null,
          Label: null,
          QuestionParentId: 0,
          ChildQuestions: null,
          ResponseComment_List: null,
        },
      ],
    },
    {
      Title: "Test Section4",
      SortOrder: 1,
      EnumerateQuestions: true,
      Progress: 2,
      ProgressText: "Complete",
      PrePopulatedProgressText: null,
      Question_List: [
        {
          QuestionId: 3381923,
          QuestionAllowEdit: true,
          QuestionOrder: 1,
          QuestionScore: 0,
          QuestionDescription: "Test Note",
          QuestionDropdownListMembers: ["checkbox1", "checkbox2"],
          QuestionSectionNo: 1,
          QuestionStatus: 5,
          QuestionStatusText: "LoadedForEdit",
          QuestionText: "Test Question1",
          QuestionTypeEnum: 16,
          QuestionTypeText: "MultiSelectCheckBox",
          QuestionCheckBoxLabel: "na",
          QuestionUserList: null,
          AnswerId: 3382207,
          AnswerBool: null,
          AnswerDate: "2022-12-09T13:43:09.6016178+05:30",
          AnswerText: "checkbox2",
          AnswserSelectedDropdownItems: null,
          AnswerSelectedQuestionDDLObjId: 0,
          AnswerSelectedUserNames: null,
          DateTimeAnswer: null,
          Label: null,
          QuestionParentId: 0,
          ChildQuestions: null,
          ResponseComment_List: null,
        },
        {
          QuestionId: 3381926,
          QuestionAllowEdit: true,
          QuestionOrder: 2,
          QuestionScore: 0,
          QuestionDescription: "Test Note",
          QuestionDropdownListMembers: ["dropdown12", "dropdown2"],
          QuestionSectionNo: 1,
          QuestionStatus: 5,
          QuestionStatusText: "LoadedForEdit",
          QuestionText: "Test Question2",
          QuestionTypeEnum: 3,
          QuestionTypeText: "DropdownList",
          QuestionCheckBoxLabel: "na",
          QuestionUserList: null,
          AnswerId: 3382208,
          AnswerBool: null,
          AnswerDate: "2022-12-09T13:43:09.7846164+05:30",
          AnswerText: "dropdown12",
          AnswserSelectedDropdownItems: null,
          AnswerSelectedQuestionDDLObjId: 0,
          AnswerSelectedUserNames: null,
          DateTimeAnswer: null,
          Label: null,
          QuestionParentId: 0,
          ChildQuestions: null,
          ResponseComment_List: null,
        },
      ],
    },
  ],
  CurrentPostureExists: false,
  mr: {
    Success: true,
    ExThrown: false,
    EntityId: 0,
    Message:
      "Success for:cl_CyberCareAPILogic\\GetPricingCalcQuestionnaireResponse_List User: HG_Test_Ravish422@mailinator.com Org: Highground",
    ExMessage: null,
    ExInnerMessage: null,
    SuccessCount: 0,
    PartialCount: 0,
    FaliureCount: 0,
    PageEnumCalled: 0,
    ObjectList: [
      {
        Id: 3336608,
        OrgId: 237003,
        DefinitionId: 1838,
        BoolRef1: true,
        BoolRef2: null,
        BoolRef3: null,
        IntRef1: 445562,
        IntRef2: null,
        IntRef3: 1.0,
        TextRef1: "Buyer B",
        TextRef2: "Buyer B Co",
        TextRef3: "ny45fkcmvb6w",
        DateRef1: null,
        DateRef2: null,
        DateRef3: null,
        DDLRef1: 11890,
        DDLRef2: 12941,
        DDLRef3: 0,
        DDLText1: "CEO",
        DDLText2: "Denied",
        DDLText3: null,
        DDLColour1: null,
        DDLColour2: null,
        DDLColour3: null,
        DDLTextColour1: null,
        DDLTextColour2: null,
        DDLTextColour3: null,
        EmailAddress: "HG_Test_BuyerB@mailinator.com",
        MobileNo: null,
        OrigObjectPk: null,
        Archived: false,
        Deleted: false,
        Private: null,
        Created: "2022-10-30T13:03:13.26",
        CreatedBy: "HG_Test_BuyerB@mailinator.com",
        ObjImage: null,
        ObjectJsonData: null,
        Definition: null,
        bizObjAttributeLink: [
          {
            Id: 2295434,
            BizObjectId: 3336608,
            OrgId: 0,
            DefinitionId: 3636,
            AttributeId: 2953028,
            AttributeText: "50",
            AttributeName: "Sensitivity Compliance",
            attrVal: null,
            Definition: null,
          },
          {
            Id: 2295435,
            BizObjectId: 3336608,
            OrgId: 0,
            DefinitionId: 3634,
            AttributeId: 2953029,
            AttributeText: "50",
            AttributeName: "Sensitivity Score",
            attrVal: null,
            Definition: null,
          },
          {
            Id: 2295436,
            BizObjectId: 3336608,
            OrgId: 0,
            DefinitionId: 3635,
            AttributeId: 2953030,
            AttributeText: "2",
            AttributeName: "Sensitivity Threat",
            attrVal: null,
            Definition: null,
          },
          {
            Id: 2295437,
            BizObjectId: 3336608,
            OrgId: 0,
            DefinitionId: 3639,
            AttributeId: 2953031,
            AttributeText: "2",
            AttributeName: "Threshold Compliance",
            attrVal: null,
            Definition: null,
          },
          {
            Id: 2295438,
            BizObjectId: 3336608,
            OrgId: 0,
            DefinitionId: 3637,
            AttributeId: 2953032,
            AttributeText: "2",
            AttributeName: "Threshold Score",
            attrVal: null,
            Definition: null,
          },
          {
            Id: 2295439,
            BizObjectId: 3336608,
            OrgId: 0,
            DefinitionId: 3638,
            AttributeId: 2953033,
            AttributeText: "2",
            AttributeName: "Threshold Threat",
            attrVal: null,
            Definition: null,
          },
          {
            Id: 2295440,
            BizObjectId: 3336608,
            OrgId: 0,
            DefinitionId: 3640,
            AttributeId: 2953034,
            AttributeText: "0",
            AttributeName: "Browser Allow",
            attrVal: null,
            Definition: null,
          },
          {
            Id: 2295441,
            BizObjectId: 3336608,
            OrgId: 0,
            DefinitionId: 3645,
            AttributeId: 2953035,
            AttributeText: "0",
            AttributeName: "CompAndGov Report",
            attrVal: null,
            Definition: null,
          },
          {
            Id: 2295442,
            BizObjectId: 3336608,
            OrgId: 0,
            DefinitionId: 3643,
            AttributeId: 2953036,
            AttributeText: "0",
            AttributeName: "Cyber Trend Report",
            attrVal: null,
            Definition: null,
          },
          {
            Id: 2295443,
            BizObjectId: 3336608,
            OrgId: 0,
            DefinitionId: 3641,
            AttributeId: 2953037,
            AttributeText: "0",
            AttributeName: "KPI Report",
            attrVal: null,
            Definition: null,
          },
          {
            Id: 2295444,
            BizObjectId: 3336608,
            OrgId: 0,
            DefinitionId: 3642,
            AttributeId: 2953038,
            AttributeText: "0",
            AttributeName: "Role Report",
            attrVal: null,
            Definition: null,
          },
          {
            Id: 2295445,
            BizObjectId: 3336608,
            OrgId: 0,
            DefinitionId: 3644,
            AttributeId: 2953039,
            AttributeText: "0",
            AttributeName: "Tech DrillDown Report",
            attrVal: null,
            Definition: null,
          },
          {
            Id: 2295446,
            BizObjectId: 3336608,
            OrgId: 0,
            DefinitionId: 3647,
            AttributeId: 2953040,
            AttributeText: "0",
            AttributeName: "Report Day",
            attrVal: null,
            Definition: null,
          },
          {
            Id: 2295447,
            BizObjectId: 3336608,
            OrgId: 0,
            DefinitionId: 3650,
            AttributeId: 2953041,
            AttributeText: "30/10/2022 08:00:00",
            AttributeName: "Report Time",
            attrVal: null,
            Definition: null,
          },
          {
            Id: 2295448,
            BizObjectId: 3336608,
            OrgId: 0,
            DefinitionId: 3655,
            AttributeId: 2953042,
            AttributeText: "0",
            AttributeName: "Report Day of Week",
            attrVal: null,
            Definition: null,
          },
          {
            Id: 2295449,
            BizObjectId: 3336608,
            OrgId: 0,
            DefinitionId: 3654,
            AttributeId: 2953043,
            AttributeText: "0",
            AttributeName: "Report Period",
            attrVal: null,
            Definition: null,
          },
          {
            Id: 2295450,
            BizObjectId: 3336608,
            OrgId: 0,
            DefinitionId: 3656,
            AttributeId: 2953044,
            AttributeText: "0",
            AttributeName: "Notification Supress",
            attrVal: null,
            Definition: null,
          },
          {
            Id: 2295451,
            BizObjectId: 3336608,
            OrgId: 0,
            DefinitionId: 3689,
            AttributeId: 2953045,
            AttributeText: "0",
            AttributeName: "Primary User",
            attrVal: null,
            Definition: null,
          },
          {
            Id: 2295452,
            BizObjectId: 3336608,
            OrgId: 237003,
            DefinitionId: 5648,
            AttributeId: 2953046,
            AttributeText: "",
            AttributeName: "Role",
            attrVal: null,
            Definition: null,
          },
          {
            Id: 2302344,
            BizObjectId: 3336608,
            OrgId: 230853,
            DefinitionId: 3761,
            AttributeId: 2960861,
            AttributeText: "01/12/2022 20:25:25",
            AttributeName: "EmailVerificationCompletedon_Date",
            attrVal: null,
            Definition: null,
          },
          {
            Id: 2302345,
            BizObjectId: 3336608,
            OrgId: 230853,
            DefinitionId: 3761,
            AttributeId: 2960862,
            AttributeText: "01/12/2022 20:30:15",
            AttributeName: "EmailVerificationCompletedon_Date",
            attrVal: null,
            Definition: null,
          },
          {
            Id: 2303011,
            BizObjectId: 3336608,
            OrgId: 237003,
            DefinitionId: 5669,
            AttributeId: 2961993,
            AttributeText: "False",
            AttributeName: "Laptop Disable",
            attrVal: null,
            Definition: null,
          },
          {
            Id: 2303431,
            BizObjectId: 3336608,
            OrgId: 237003,
            DefinitionId: 7021,
            AttributeId: 2962696,
            AttributeText:
              '[{"SortOrder":1,"FieldName":"Department Name","FieldKey":"department_name","Visibility":true},{"SortOrder":2,"FieldName":"Department Function","FieldKey":"department_function","Visibility":true},{"SortOrder":3,"FieldName":"Locations","FieldKey":"locations","Visibility":true},{"SortOrder":4,"FieldName":"Head of Department","FieldKey":"head_of_department","Visibility":true}]',
            AttributeName: "Orgs_Department Visibility",
            attrVal: null,
            Definition: null,
          },
          {
            Id: 2303434,
            BizObjectId: 3336608,
            OrgId: 237003,
            DefinitionId: 5668,
            AttributeId: 2962767,
            AttributeText: "False",
            AttributeName: "Workstation Disable",
            attrVal: null,
            Definition: null,
          },
          {
            Id: 2303435,
            BizObjectId: 3336608,
            OrgId: 237003,
            DefinitionId: 3725,
            AttributeId: 2962768,
            AttributeText: "0",
            AttributeName: "Identity",
            attrVal: null,
            Definition: null,
          },
          {
            Id: 2303436,
            BizObjectId: 3336608,
            OrgId: 237003,
            DefinitionId: 3726,
            AttributeId: 2962769,
            AttributeText: "0",
            AttributeName: "Protect",
            attrVal: null,
            Definition: null,
          },
          {
            Id: 2303437,
            BizObjectId: 3336608,
            OrgId: 237003,
            DefinitionId: 3727,
            AttributeId: 2962770,
            AttributeText: "0",
            AttributeName: "Detect",
            attrVal: null,
            Definition: null,
          },
          {
            Id: 2303438,
            BizObjectId: 3336608,
            OrgId: 237003,
            DefinitionId: 3728,
            AttributeId: 2962771,
            AttributeText: "0",
            AttributeName: "Respond",
            attrVal: null,
            Definition: null,
          },
          {
            Id: 2303439,
            BizObjectId: 3336608,
            OrgId: 237003,
            DefinitionId: 3729,
            AttributeId: 2962772,
            AttributeText: "0",
            AttributeName: "Recover",
            attrVal: null,
            Definition: null,
          },
          {
            Id: 2303513,
            BizObjectId: 3336608,
            OrgId: 237003,
            DefinitionId: 4948,
            AttributeId: 2962860,
            AttributeText: "True",
            AttributeName: "NotificationActionCentre",
            attrVal: null,
            Definition: null,
          },
          {
            Id: 2303514,
            BizObjectId: 3336608,
            OrgId: 237003,
            DefinitionId: 4949,
            AttributeId: 2962861,
            AttributeText: "App and Email",
            AttributeName: "New Tasks",
            attrVal: null,
            Definition: null,
          },
          {
            Id: 2303515,
            BizObjectId: 3336608,
            OrgId: 237003,
            DefinitionId: 4950,
            AttributeId: 2962862,
            AttributeText: "App and Email",
            AttributeName: "Task Overdue",
            attrVal: null,
            Definition: null,
          },
          {
            Id: 2303516,
            BizObjectId: 3336608,
            OrgId: 237003,
            DefinitionId: 4951,
            AttributeId: 2962863,
            AttributeText: "App and Email",
            AttributeName: "Task Status Change",
            attrVal: null,
            Definition: null,
          },
          {
            Id: 2303517,
            BizObjectId: 3336608,
            OrgId: 237003,
            DefinitionId: 4952,
            AttributeId: 2962864,
            AttributeText: "App and Email",
            AttributeName: "Task Closed",
            attrVal: null,
            Definition: null,
          },
          {
            Id: 2303528,
            BizObjectId: 3336608,
            OrgId: 237003,
            DefinitionId: 7396,
            AttributeId: 2962875,
            AttributeText: "True",
            AttributeName: "Internal Network Disable",
            attrVal: null,
            Definition: null,
          },
          {
            Id: 2303529,
            BizObjectId: 3336608,
            OrgId: 237003,
            DefinitionId: 7401,
            AttributeId: 2962876,
            AttributeText: "False",
            AttributeName: "AzureAd Disable",
            attrVal: null,
            Definition: null,
          },
        ],
        childRelationships: null,
        parentRelationships: null,
        diaryEntries: null,
        linkedUsers: null,
        linkedForms: null,
        ObjDDLMultiSelections: null,
        Location: null,
      },
    ],
  },
};

const ScreenLoader = () => {
  return (
    <ContentLoader
      speed={2}
      width={"100%"}
      height={"100%"}
      viewBox="0 0 1500 2000"
      backgroundColor="#F6F8FB"
      foregroundColor="#ecebeb"
    >
      <rect x="2%" y="20" rx="4" ry="4" width="16%" height="20" />

      <rect x="6%" y="75" rx="4" ry="4" width="55%" height="20" />
      <rect x="6%" y="125" rx="4" ry="4" width="30%" height="20" />

      <rect x="6%" y="195" rx="4" ry="4" width="55%" height="20" />
      <rect x="6%" y="245" rx="4" ry="4" width="35%" height="20" />

      <rect x="6%" y="315" rx="4" ry="4" width="55%" height="20" />
      <rect x="6%" y="365" rx="4" ry="4" width="25%" height="20" />

      <rect x="6%" y="435" rx="4" ry="4" width="55%" height="20" />
      <rect x="6%" y="485" rx="4" ry="4" width="45%" height="20" />

      <rect x="6%" y="555" rx="4" ry="4" width="55%" height="20" />
      <rect x="6%" y="605" rx="4" ry="4" width="35%" height="20" />

      {/* Right Part */}

      <rect x="75%" y="20" rx="4" ry="4" width="10%" height="20" />

      <rect x="78%" y="65" rx="4" ry="4" width="15%" height="20" />
      <rect x="78%" y="110" rx="4" ry="4" width="20%" height="20" />

      <rect x="78%" y="155" rx="4" ry="4" width="15%" height="20" />

      <rect x="78%" y="200" rx="4" ry="4" width="20%" height="20" />
    </ContentLoader>
  );
};
