const searchTitle = (title, searchValue) => {
  const escapedSearchValue = searchValue.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');

  const parts = title.split(new RegExp(`(${escapedSearchValue})`, 'gi'));

  let hasMatch = false;
  const result = parts.reduce((acc, part) => {
    const isMatched = part.toLowerCase() === searchValue.toLowerCase();
    acc.push({ value: part, isMatched });
    if (isMatched) {
      hasMatch = true;
    }
    return acc;
  }, []);

  if (!hasMatch) return false;
  return result;
}

const isValidDate = (dateString) => {
  return !isNaN(Date.parse(dateString));
}

const isValidUrl = (url) => {
  try {
    new URL(url);
    return true;
  } catch (err) {
    return false;
  }
}

const stringUtils = {
  searchTitle,
  isValidUrl,
  isValidDate
};

export default stringUtils;
