import { HGHighlightText } from "../../App/App";
import ImageLoader from "../../Common/ImageLoader";

const DeviceIssuesTable = ({ deviceIssues, noDataComponent, className, searchString = "" }) => {
  return (
    <div className={className}>
      {deviceIssues?.length > 0 ? (
        <>
          <div className="wrapper-header">
            <div className="f-500 cell">Device</div>
            <div className="f-500 cell">
              Operating System
            </div>
            <div className="f-500 cell">
              Installed Date
            </div>
            <div className="f-500 cell">
              Warranty Expiry
            </div>
            <div className="f-500 cell">
              End of Life Date
            </div>
          </div>

          <div className="wrapper-row">
            {deviceIssues?.map((item) => {
              return (
                <div className="line-row">
                  <div className="cell d-flex" style={{ gap: '8px' }}>
                    <div>
                      {item?.DeviceLogo && (
                        <ImageLoader
                          src={
                            item?.DeviceLogo
                          }
                          width={20}
                          height={20}
                          alt=""
                          className="mr-2"
                          noImageComponent={(
                            <div />
                          )}
                        />
                      )}
                      <span
                        dangerouslySetInnerHTML={{
                          __html: HGHighlightText(item?.DeviceName, searchString),
                        }}
                      />
                    </div>
                    <div className="d-flex flex-column" style={{ gap: '4px' }}>
                      {[...new Array(1)].map((_, i) => ( // MOCK DATA
                        <div key={`pl-${i}`} className="period-label">
                          <img
                            src={
                              '/images/msp/circular-green-tick.svg' // MOCK DATA
                            }
                            width={14}
                            height={12}
                            alt=""
                            className="icon"
                          />
                          <span className="text">
                            {"No data"}
                          </span>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="cell f-grey">
                    {item?.OperatingSystemLogo && (
                      <img
                        src={
                          item?.OperatingSystemLogo
                        }
                        width={13}
                        height={13}
                        alt=""
                        className="mr-2"
                      />
                    )}
                    {item?.OperatingSystem}
                  </div>
                  <div className="f-grey cell"> {/* MOCK DATA */}
                    {"No data"}
                  </div>
                  <div className="f-grey cell"> {/* MOCK DATA */}
                    {"No data"}
                  </div>
                  <div className="f-grey cell">
                    {item?.EndOfLifeDate}
                  </div>
                </div>
              );
            })}
          </div>
        </>
      ) : (
        noDataComponent
      )}
    </div>
  );
}

export default DeviceIssuesTable;
