import React, { useState, useEffect } from "react";
import CompanySettingsDropdown from "../../settingsV2/companySettings/companysettingsdrodown/CompanySettingsDropdown";
import DatePicker from "../../Theme/datepicker";
import "./cdsa.scss";
import moment from "moment";
import { getAPIData, SubmitQuestionaire } from "../../../Utils/commonApi";
import { useAuth } from "../../context/Auth";
import IncidentResponseLoader from "../AttackSurfaceLoaders/IncidentResponseLoader";
import { CallToast } from "../../PriceCalculator/modals/CustomToast";
import { AttackSurfaceResetModal } from "../AttackSurfaceResetModal";
import { AttackSurfaceAccessUI } from "../../supplyChainMonitoring/SupplyCompany/SupplyCompany";
import HighgroundDatePicker from "../../test/TestComponent";
const IncidentResponse = ({ refreshMenuItems, supplierId, accessLevel }) => {
  const [selectedTaskDate, setSelectedTaskDate] = useState("");
  const { authTokens } = useAuth();
  const [questionList, setQuestionList] = useState([]);
  const [mainPageLoading, setMainPageLoading] = useState(false);
  const [originalData, setOriginalData] = useState([]);
  const [showResetModal, setShowResetModal] = useState(false);

  useEffect(() => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    setMainPageLoading(true);
    getPageData();
  }, []);
  function getPageData(success = false) {
    getAPIData(440, authTokens, {
      optionEmun1: 0,
      optionEnum2: accessLevel,
      optionEnum3: supplierId,
    }).then((response) => {
      setMainPageLoading(false);
      setSelectedTaskDate("");
      if (response?.mr?.Success) {
        setOriginalData(
          response?.QuestionnaireSection_List?.[2]?.Question_List
        );
        if (success) {
          let updated_data = [...questionList].map((item, index) => {
            return {
              ...item,
              AnswerId:
                response?.QuestionnaireSection_List?.[2]?.Question_List[index]
                  .AnswerId,
            };
          });

          setQuestionList(updated_data);
        } else {
          if (
            response?.QuestionnaireSection_List?.[2]?.Question_List[3]
              .AnswerText
          ) {
            setTimeout(() => {
              setSelectedTaskDate(
                response?.QuestionnaireSection_List?.[2]?.Question_List[3]
                  .AnswerText
              );
            }, 50);
          }

          let updated_data = [
            ...response?.QuestionnaireSection_List?.[2]?.Question_List,
          ].map((item) => {
            return {
              ...item,
              selectedDropdownValue:
                item?.AnswerText && item?.QuestionTypeText == "DropdownList"
                  ? item?.QuestionDropdownListMembers.map((listItem, index) => {
                      return {
                        id: index + 1,
                        category: listItem,
                      };
                    }).filter(
                      (filterItem) => filterItem.category === item?.AnswerText
                    )[0]
                  : null,
              dropDowndata: item?.QuestionDropdownListMembers
                ? item?.QuestionDropdownListMembers.map((listItem, index) => {
                    return {
                      id: index + 1,
                      category: listItem,
                    };
                  })
                : [],
              loading: false,
            };
          });

          setQuestionList(updated_data);
        }
      }
    });
  }

  // console.log(moment("09/07/2022"));
  return (
    <>
      {mainPageLoading ? (
        <IncidentResponseLoader />
      ) : (
        <div className="cdsa-wrapper">
          <div className="mt-2">
            <AttackSurfaceAccessUI />
          </div>
          {/* Vulnerbility Management */}
          <div className="font-weight-bold mt-3 mb-2">Incident Response</div>
          <div className="bg-grey radius-8 border-parent">
            {questionList.map((question, questionIndex) => {
              return (
                <div
                  className="d-flex flex-column border-item"
                  onClick={() => {
                    // goTo(vulnerbility.name);
                  }}
                >
                  <div className="d-flex align-items-center justify-content-between w-100  px-4  py-3">
                    <div
                      className="d-flex align-align-items-baseline "
                      style={{ width: "75%" }}
                    >
                      <div>
                        <img
                          alt=""
                          src="/images/attack-surface/yellow-dot.svg"
                          className="mr-1"
                          style={{
                            visibility: `${"hidden"}`,
                          }}
                        />
                      </div>
                      <p className="f-500 m-0">{question?.QuestionText}</p>
                      {/* <p className="f-12 f-darkgrey"> {service.noemptyStatus} </p> */}
                    </div>
                    <div
                      className="disabled-cdsa-question-dropdown"
                      style={{
                        pointerEvents: "none",
                      }}
                    >
                      {question.QuestionTypeText === "Date" ? (

                      
                        <div className="attack-date-picker disabled-datepicker d-flex justify-content-end">
                         { !question?.AnswerText? <span className="f-grey f-500">Not answered</span>  : <HighgroundDatePicker
                            selected={selectedTaskDate}
                            placeholder={"Not answered"}
                            // icon={"/images/attack-surface/date-icon.svg"}
                            onChange={(e) => {}}
                            id={"cbhfgvmv"}
                            isError={false}
                          />}
                       
                        </div>
                      ) : (
                        <CompanySettingsDropdown
                          fieldId={"id"}
                          fieldValue={"category"}
                          selected={question?.selectedDropdownValue}
                          placeholder="Not answered"
                          //  showid={true}
                          onClick={(id, val) => {}}
                          data={question?.dropDowndata}
                        />
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          {/* Reset button */}
        </div>
      )}
      <AttackSurfaceResetModal
        refreshData={getPageData}
        refreshMenuItems={refreshMenuItems}
        resetPageEnum={269}
        optionEnum1={9}
        show={showResetModal}
        hideModal={() => {
          setShowResetModal(false);
        }}
      />
    </>
  );
};

export default IncidentResponse;
