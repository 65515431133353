import { useEffect, useRef, useState } from "react";

export const useScroll = () => {
  const [scrollTop, setScrollTop] = useState(0);
  const scrollRef = useRef(null);
  const [canScrollLeft, setCanScrollLeft] = useState(false);
  const [canScrollRight, setCanScrollRight] = useState(false);
  const [canScrollTop, setCanScrollTop] = useState(false);
  const [canScrollBottom, setCanScrollBottom] = useState(false);

  const handleScroll = () => {
    if (scrollRef.current) {
      setScrollTop(scrollRef.current.scrollTop);
      const container = scrollRef.current;
      if (container.scrollLeft > 0) {
        setCanScrollLeft(true);
      } else {
        setCanScrollLeft(false);
      }

      if (container.scrollWidth > container.clientWidth && container.scrollLeft < (container.scrollWidth - container.clientWidth)) {
        setCanScrollRight(true);
      } else {
        setCanScrollRight(false);
      }

      if (container.scrollTop > 0) {
        setCanScrollTop(true);
      } else {
        setCanScrollTop(false);
      }
      if (container.scrollHeight > container.clientHeight && container.scrollTop < (container.scrollHeight - container.clientHeight)) {
        setCanScrollBottom(true);
      } else {
        setCanScrollBottom(false);
      }
    }
  };

  useEffect(() => {
    const container = scrollRef.current;
    if (container) {
      container.addEventListener('scroll', handleScroll);
      const observer = new MutationObserver(handleScroll);
      observer.observe(container, { childList: true, subtree: true });
      return () => {
        container.removeEventListener('scroll', handleScroll);
        observer.disconnect();
      };
    }
  }, [scrollRef]);

  return { scrollTop, scrollRef, canScrollLeft, canScrollRight, canScrollTop, canScrollBottom };
}