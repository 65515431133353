import React from "react";
import { Modal, Button, Form } from "react-bootstrap";
import "./mspIntegrations.scss";

const SetDefaultIntegrationModal = ({ show, hideModal }) => {
  return (
    <Modal
      animation={false}
      show={show}
      dialogClassName="reset-settings-modal  set-default-integration-modal"
      className=""
      aria-labelledby="reset-settings-modal-title-vcenter"
      centered
      scrollable
      onHide={hideModal}
    >
      <Modal.Body className="p-3">
        <div className="d-flex align-items-center justify-content-center">
          <img
            width={48}
            height={48}
            alt=""
            className="mt-2 mb-3"
            src="/images/msp/integrations/ConnectwiseAutomate.svg"
          />
        </div>
        <div className="text-center f-16 f-black mb-2">
          Set Connectwise Automate as default Patch Management
        </div>
        <div className="text-center f-16 f-darkgrey">
          Connectwise Automate will be used as default integration fro your
          clients
        </div>

        <div className="mx-2">
          <div className="f-12 f-500 f-darkgrey mt-3 mb-3">Technologies</div>

          <div className="d-flex align-items-center mb-2">
            <div className="mr-3 mb-3">
              <Form.Group
                controlId={"check2"}
                className="checkboxstyle mb-1 blue-checkbox  round-checkbox"
              >
                <Form.Check
                  type="checkbox"
                  name={"check"}
                  label=""
                  //   checked={true}
                  onChange={(e) => {}}
                />
              </Form.Group>
            </div>
            <div className="f-black f-15">Patch Management</div>
          </div>
          <div className="d-flex align-items-center">
            <div className="mr-3 mb-3">
              <Form.Group
                controlId={"check3"}
                className="checkboxstyle mb-1 blue-checkbox  round-checkbox"
              >
                <Form.Check
                  type="checkbox"
                  name={"check"}
                  label=""
                  //   checked={true}
                  onChange={(e) => {}}
                />
              </Form.Group>
            </div>
            <div className="f-black f-15">RMM</div>
          </div>
        </div>
        {/* Options */}

        <div className="mx-2">
          <div className="f-12 f-500 f-darkgrey mt-3 mb-3">Options</div>
          <div className="d-flex align-items-center my-2">
            <div className="mb- ml-2">
              <Form.Group
                controlId={`card  `}
                className={`round blue-checkbox mb- radio-shado    ${true}  `}
              >
                <Form.Check
                  style={{ transform: "scale(1.7)" }}
                  type={"radio"}
                  name={`check`}
                  label=""
                  onChange={() => {}}
                  onClick={(e) => {
                    return false;
                  }}
                />
              </Form.Group>
            </div>
            <div className={`${"f-black"} f-15 f-500 ml-2`}>
              Use for future clients
            </div>
          </div>
          <div className="d-flex align-items-center my-2">
            <div className="mb- ml-2">
              <Form.Group
                controlId={`card 2 `}
                className={`round blue-checkbox mb- radio-shado    ${true}  `}
              >
                <Form.Check
                  style={{ transform: "scale(1.7)" }}
                  type={"radio"}
                  name={`check`}
                  label=""
                  onChange={() => {}}
                  onClick={(e) => {
                    return false;
                  }}
                />
              </Form.Group>
            </div>
            <div className={`${"f-black"} f-15 f-500 ml-2`}>
              Update for all existing 26 clients
            </div>
          </div>
        </div>

        {/* {[].map((supplier, supplierIndex) => {
          return (
            <div className="d-flex align-items-center my-2">
              <div className="mb-3 ml-2">
                <Form.Group
                  controlId={`card  ` + supplierIndex}
                  className={`round-checkbox blue-checkbox mb-1 radio-shadow    ${
                    supplierIndex === 0 ? "disabled-radio" : ""
                  }  `}
                >
                  <Form.Check
                    type={"radio"}
                    // checked={

                    // }
                    name={`check `}
                    label=""
                    // disabled={supplierIndex === 0}
                    // checked={}
                    onChange={() => {}}
                    onClick={(e) => {
                      return false;
                    }}
                  />
                </Form.Group>
              </div>
              <div className={`${"f-black"} f-14 f-500 ml-3`}>{supplier}</div>
            </div>
          );
        })} */}
      </Modal.Body>

      <Modal.Footer>
        <div className="footer d-flex justify-content-end">
          <Button className="hg-cancel-btn f-500" onClick={hideModal}>
            Cancel
          </Button>
          <Button
            className={`${"hg-blue-btn "} f-500 ml-2`}
            onClick={hideModal}
            disabled={false}
          >
            Set as default
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default SetDefaultIntegrationModal;
