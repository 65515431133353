import React, { useState, useEffect } from "react";
import {
  Button,
  Modal,
  Row,
  Col,
  InputGroup,
  Form,
  OverlayTrigger,
  FormControl,
  Tooltip,
} from "react-bootstrap";
import "./index.scss";
import { useAuth } from "../../../context/Auth";
import { getAPIData } from "../../../../Utils/commonApi";
import Loader from "../../../Common/loader";
import Response from "./PaymentResponse";
import { TrackUser } from "../../../../Utils/SegmentFunctions";

const AddPaymentMethod = (props) => {
  const [saveMethod, setSaveMethod] = useState(false);
  const [isSelectedMethod, setIsSelectedMethod] = useState("");
  const [applyCredit, setApplyCredit] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isResponse, setIsResponse] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [showErr, setShowErr] = useState(false);
  const [showrErr, setShowrErr] = useState(false);
  const [responseFields, setResponseFields] = useState("");
  const { authTokens, setAuthTokens } = useAuth();
  const [checkYear, setcheckYear] = useState(false);
  const cardDetails = {
    cname: "",
    caddress: "",
    ccountry: "GB",
    creg: "",
    cvat: "",
    cardnumber: "",
    monthyear: "",
    cvc: "",
    cardname: "",
  };
  const [billingDetails, setBillingDetails] = useState(cardDetails);
  const [error, setErrors] = useState({
    cname: "Required field",
    caddress: "Required field",
    creg: "Required field",
    cvat: "Required field",
    cardnumber: "Required field",
    monthyear: "Required field",
    cvc: "Required field",
    cardname: "Required field",
  });

  const maskDate = (value) => {
    let v = value.replace(/(\w)\//g, function (str, match) {
      return match[0];
    });
    if (v.length >= 3) {
      return `${v.slice(0, 2)}/${v.slice(2)}`;
    }
    return value;
  };

  const submit = async () => {
    const err = handleError(billingDetails);

    if (!err) {
      setIsLoading(true);
      const month = billingDetails.monthyear.split("/");
      const data = {
        CardName: billingDetails.cname,
        CardNumber: billingDetails.cardnumber,
        ExpMonth: month[0],
        ExpYear: month[1],
        Cvc: billingDetails.cvc,
        CompanyName: billingDetails.cname,
        CompanyAddress: billingDetails.caddress,
        Country: billingDetails.ccountry,
        CompanyRegistration: billingDetails.creg,
        VAT: billingDetails.cvat,
      };

      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/CC_API/AddPaymentMethod`,
        {
          method: "POST",
          headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${authTokens}`, // notice the Bearer before your token
          },
          body: JSON.stringify(data),
        }
      )
        .then((response) => response.json())
        .then((res) => {
          if (!res.mr.Success) {
            // props.setToastMsg({ show: true, msg: res.ResultText  })
            setResponseFields(res);
            setIsResponse(true);
          } else {
            setResponseFields(res);
            props.billingApi();
            setBillingDetails("");
            setIsResponse(true);
          }

          let demo = 0;
          const interval = setInterval(function () {
            demo = demo + 1;
            if (demo >= 95) {
              clearInterval(interval);
              props.setToastMsg({
                show: false,
                msg: "Removed Successfully",
              });
            }
          }, 40);
        });
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (props.isEdit) {
      getCard();
    }
  }, [props.isEdit]);

  const renderTooltip = (prop) => (
    <Tooltip className="custom_tooltip proService_tooltip">
      <p className="redText">{prop}</p>
    </Tooltip>
  );

  const getCard = async () => {
    const getCard = await getAPIData(195, authTokens, {
      optionEnum1: "3142922",
    });
  };

  const handleError = (fields) => {
    setIsSubmit(true);
    let err = 0;
    for (var key in fields) {
      if (fields.hasOwnProperty(key)) {
        if (fields[key] == "") {
          err = err + 1;
        }

        if (key == "cardnumber") {
          if (fields[key].length != 16) {
            err = err + 1;
          }
        }
        if (key == "cvc") {
          if (fields[key].length != 3) {
            err = err + 1;
          }
        }

        if (key == "monthyear") {
          if (fields[key] != "") {
            const mnth = fields[key].split("/");
            var strDate = new Date();
            var shortYear = strDate.getFullYear();
            var twoDigitYear = shortYear.toString().substr(-2);
            if (mnth[mnth.length - 1] < twoDigitYear) {
              err = err + 1;
              setcheckYear(true);
            } else {
              setcheckYear(false);
            }
          }
        }
      }
    }

    if (!err) {
      setShowErr(true);
      setIsSubmit(false);
    } else {
      setShowErr(false);
    }
    return err;
  };

  return (
    <Modal
      animation={false}
      show={props.isOpen}
      dialogClassName="modal-50w"
      className="integrationModal centerModal dashboardModal upgradePremium"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      scrollable
    >
      {isLoading && <Loader />}
      <Modal.Header>
        <h1 className="w-100 mb-0 mt-0 text-center">
          {" "}
          {props.isEdit ? "Edit" : "Add"} Payment Method
        </h1>
        <span className="cursor-pointer">
          <img
            src="/images/icons/close.svg"
            alt=""
            onClick={() => {
              props.setIsOpen(false);
              setIsResponse(false);
              setShowErr(false);
              setIsSubmit(false);
              setBillingDetails(cardDetails);
            }}
          />
        </span>
      </Modal.Header>
      <Modal.Body>
        <Row className="m-0">
          {isResponse ? (
            <Response
              data={responseFields}
              setFields={() => {
                setIsResponse(false);
                setBillingDetails(cardDetails);
                setShowErr(false);
                setIsSubmit(false);
              }}
              isClose={() => {
                props.setIsOpen(false);
                setIsResponse(false);
                setBillingDetails(cardDetails);
                setShowErr(false);
                setIsSubmit(false);
              }}
            />
          ) : (
            <>
              <Col md={12} className="p-0 billingInfo">
                <div className="bill_wrapper">
                  <div className="d24" />

                  <div className="d24" />

                  {!props.isEdit && (
                    <p>
                      You can use saved card for future payments without having
                      to enter your details every time.
                    </p>
                  )}

                  <div className="d24" />
                  <p className="m-0">Billing Address</p>
                  {/* <CardDetails /> */}
                  <div className="d24" />
                  <Form.Group>
                    <InputGroup className="grey-input">
                      <FormControl
                        name="name"
                        value={billingDetails.cname}
                        onChange={(e) =>
                          setBillingDetails({
                            ...billingDetails,
                            cname: e.target.value,
                          })
                        }
                        className={`${
                          isSubmit &&
                          !showErr &&
                          !billingDetails.cname &&
                          "bgred"
                        }`}
                        placeholder="Company name"
                      />
                      {isSubmit && !showErr && !billingDetails.cname && (
                        <OverlayTrigger
                          placement="bottom"
                          overlay={renderTooltip(error.cname)}
                        >
                          <InputGroup.Text
                            style={{ height: "32px" }}
                            className="bgred"
                          >
                            <img src="/images/card_error.svg" />
                          </InputGroup.Text>
                        </OverlayTrigger>
                      )}
                    </InputGroup>
                  </Form.Group>
                  <Form.Group>
                    <InputGroup className="grey-input mt-10px">
                      <FormControl
                        name="name"
                        value={billingDetails.caddress}
                        onChange={(e) =>
                          setBillingDetails({
                            ...billingDetails,
                            caddress: e.target.value,
                          })
                        }
                        className={`${
                          isSubmit &&
                          !showErr &&
                          !billingDetails.caddress &&
                          "bgred"
                        }`}
                        placeholder="Company address"
                      />
                      {isSubmit && !showErr && !billingDetails.caddress && (
                        <OverlayTrigger
                          placement="bottom"
                          overlay={renderTooltip(error.caddress)}
                        >
                          <InputGroup.Text
                            style={{ height: "32px" }}
                            className="bgred"
                          >
                            <img src="/images/card_error.svg" />
                          </InputGroup.Text>
                        </OverlayTrigger>
                      )}
                    </InputGroup>
                  </Form.Group>
                  {props.countryList && (
                    <Form.Group>
                      <InputGroup className="mt-10px grey-select customerStyle">
                        <Form.Control
                          style={{ height: "32px" }}
                          as="select"
                          className={"pt-0 pb-0"}
                          defaultValue={"GB"}
                          onChange={(e) =>
                            setBillingDetails({
                              ...billingDetails,
                              ccountry: e.target.value,
                            })
                          }
                        >
                          {props.countryList.map((val, i) => (
                            <option key={i} value={val.code}>
                              {val.country}
                            </option>
                          ))}
                        </Form.Control>
                      </InputGroup>
                    </Form.Group>
                  )}
                  <Form.Group>
                    <InputGroup className="grey-input mt-10px">
                      <FormControl
                        name="name"
                        value={billingDetails.creg}
                        onChange={(e) =>
                          setBillingDetails({
                            ...billingDetails,
                            creg: e.target.value,
                          })
                        }
                        className={`${
                          isSubmit &&
                          !showErr &&
                          !billingDetails.creg &&
                          "bgred"
                        }`}
                        placeholder="Company registration"
                      />
                      {isSubmit && !showErr && !billingDetails.creg && (
                        <OverlayTrigger
                          placement="bottom"
                          overlay={renderTooltip(error.creg)}
                        >
                          <InputGroup.Text
                            style={{ height: "32px" }}
                            className="bgred"
                          >
                            <img src="/images/card_error.svg" />
                          </InputGroup.Text>
                        </OverlayTrigger>
                      )}
                    </InputGroup>
                  </Form.Group>
                  <Form.Group>
                    <InputGroup className="grey-input mt-10px">
                      <FormControl
                        name="name"
                        value={billingDetails.cvat}
                        onChange={(e) =>
                          setBillingDetails({
                            ...billingDetails,
                            cvat: e.target.value,
                          })
                        }
                        className={`${
                          isSubmit &&
                          !showErr &&
                          !billingDetails.cvat &&
                          "bgred"
                        }`}
                        placeholder="VAT"
                      />
                      {isSubmit && !showErr && !billingDetails.cvat && (
                        <OverlayTrigger
                          placement="bottom"
                          overlay={renderTooltip(error.cvat)}
                        >
                          <InputGroup.Text
                            style={{ height: "32px" }}
                            className="bgred"
                          >
                            <img src="/images/card_error.svg" />
                          </InputGroup.Text>
                        </OverlayTrigger>
                      )}
                    </InputGroup>
                  </Form.Group>

                  <div className="d24" />
                  <hr />
                  <div className="d24" />
                  <p className="m-0">Card Information</p>
                  <div className="d24" />
                  <Form.Group className="card_number_wrapper">
                    <InputGroup className="grey-input cardnumber">
                      <FormControl
                        name="name"
                        value={billingDetails.cardnumber}
                        type="number"
                        onChange={(e) => {
                          if (e.target.value.length == 17) return false;
                          setBillingDetails({
                            ...billingDetails,
                            cardnumber: e.target.value,
                          });
                        }}
                        onBlur={(e) => {
                          TrackUser("Payment info entered");
                        }}
                        className={`${
                          isSubmit &&
                          !showErr &&
                          (!billingDetails.cardnumber ||
                            billingDetails.cardnumber.length != 16)
                            ? "carderr1"
                            : "cardicon"
                        }`}
                        placeholder="Card number"
                      />
                      {isSubmit &&
                        !showErr &&
                        (!billingDetails.cardnumber ||
                          billingDetails.cardnumber.length != 16) && (
                          <OverlayTrigger
                            placement="bottom"
                            overlay={renderTooltip(
                              !billingDetails.cardnumber
                                ? "Required Field"
                                : billingDetails.cardnumber.length != 16
                                ? "Invalid Card Number"
                                : ""
                            )}
                          >
                            <InputGroup.Text className="bgred toltip2">
                              <img src="/images/card_error.svg" />
                            </InputGroup.Text>
                          </OverlayTrigger>
                        )}
                    </InputGroup>
                    <InputGroup className="grey-input datemonth">
                      <FormControl
                        name="name"
                        type="text"
                        value={billingDetails.monthyear}
                        onChange={(e) => {
                          if (e.target.value.length == 6) return false;
                          e.target.value = e.target.value
                            .replace(
                              /[^0-9]/g,
                              "" // To allow only numbers
                            )
                            .replace(
                              /^([2-9])$/g,
                              "0$1" // To handle 3 > 03
                            )
                            .replace(
                              /^(1{1})([3-9]{1})$/g,
                              "0$1/$2" // 13 > 01/3
                            )
                            .replace(
                              /^0{1,}/g,
                              "0" // To handle 00 > 0
                            )
                            .replace(
                              /^([0-1]{1}[0-9]{1})([0-9]{1,2}).*/g,
                              "$1/$2" // To handle 113 > 11/3
                            );

                          setBillingDetails({
                            ...billingDetails,
                            monthyear: maskDate(e.target.value),
                          });
                          setcheckYear(false);
                        }}
                        className={`${
                          isSubmit &&
                          !showErr &&
                          (!billingDetails.monthyear ||
                            checkYear ||
                            billingDetails.monthyear.length != 5)
                            ? "carderr1"
                            : "cardicon2"
                        }`}
                        placeholder="MM/YY"
                      />

                      {isSubmit &&
                        !showErr &&
                        (!billingDetails.monthyear ||
                          checkYear ||
                          billingDetails.monthyear.length != 5) && (
                          <OverlayTrigger
                            placement="bottom"
                            overlay={renderTooltip(
                              !billingDetails.monthyear
                                ? "Required Field"
                                : checkYear
                                ? "Invalid Date"
                                : billingDetails.monthyear.length != 5
                                ? "Invalid Date"
                                : ""
                            )}
                          >
                            <InputGroup.Text className="bgred toltip2">
                              <img src="/images/card_error.svg" />
                            </InputGroup.Text>
                          </OverlayTrigger>
                        )}
                    </InputGroup>
                    <InputGroup className="grey-input csvnumber">
                      <FormControl
                        name="name"
                        type="number"
                        value={billingDetails.cvc}
                        onChange={(e) => {
                          if (e.target.value.length == 4) return false;
                          setBillingDetails({
                            ...billingDetails,
                            cvc: e.target.value,
                          });
                        }}
                        className={`${
                          isSubmit &&
                          !showErr &&
                          (!billingDetails.cvc ||
                            billingDetails.cvc.length != 3) &&
                          "carderr1"
                        }`}
                        placeholder="CVC"
                      />
                      {isSubmit &&
                        !showErr &&
                        (!billingDetails.cvc ||
                          billingDetails.cvc.length != 3) && (
                          <OverlayTrigger
                            placement="bottom"
                            overlay={renderTooltip(
                              !billingDetails.cvc
                                ? "Required Field"
                                : billingDetails.cvc.length != 3
                                ? "Invalid CVC"
                                : ""
                            )}
                          >
                            <InputGroup.Text className="bgred toltip2">
                              <img src="/images/card_error.svg" />
                            </InputGroup.Text>
                          </OverlayTrigger>
                        )}
                    </InputGroup>
                  </Form.Group>
                  <Form.Group>
                    <InputGroup className="grey-input">
                      <FormControl
                        name="name"
                        value={billingDetails.cardname}
                        onChange={(e) =>
                          setBillingDetails({
                            ...billingDetails,
                            cardname: e.target.value,
                          })
                        }
                        className={`${
                          isSubmit &&
                          !showErr &&
                          !billingDetails.cardname &&
                          "bgred"
                        }`}
                        placeholder="Name on card"
                      />
                      {isSubmit && !showErr && !billingDetails.cardname && (
                        <OverlayTrigger
                          placement="bottom"
                          overlay={renderTooltip(error.cardname)}
                        >
                          <InputGroup.Text
                            style={{ height: "32px" }}
                            className="bgred"
                          >
                            <img src="/images/card_error.svg" />
                          </InputGroup.Text>
                        </OverlayTrigger>
                      )}
                    </InputGroup>
                  </Form.Group>

                  <div className="d24" />

                  <div className="mt40" />
                </div>
              </Col>
              <Button
                onClick={() => submit()}
                className="w-100 blubtn"
                style={{ height: "47px" }}
              >
                Save
              </Button>
              <div className="d24" />
            </>
          )}
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default AddPaymentMethod;
