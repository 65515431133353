import React, { useEffect, useRef, useState } from "react";
import "./hgpopupModal.scss";

// const HGPopUpModal = ({
//   children,
//   show,
//   id,
//   width,
//   onHide,
//   className,
//   dialogClassName,
//   backdropZindex,
//   isDraggableParent,

// }) => {
//   const [modalClasses, setModalClasses] = useState("hg-popup-modal");
//   function toCloseModal() {
//     if (onHide) {
//       onHide();
//     }
//   }

//   useEffect(() => {
//     try {
//       if (show === true) {
//         setTimeout(() => {
//           setModalClasses("hg-popup-modal open");
//         }, 10);

//         document.body.insertAdjacentHTML(
//           "beforeend",
//           `<div id="sliding-modal-backdrop" style="z-index: ${backdropZindex};"></div>`
//         );

//         try {
//           let backdrops = document.body.querySelectorAll(
//             "#sliding-modal-backdrop"
//           );
//           if (backdrops.length > 0) {
//             let lastBackdrop = backdrops[backdrops.length - 1];
//             lastBackdrop.addEventListener("click", toCloseModal);
//           }
//         } catch (e) {
//           console.log(e, "backdrops");
//         }

//         return () => {
//           console.log("clean up done");
//         };
//       }
//       if (show === false) {
//         setModalClasses("hg-popup-modal");

//         let backdrops = document.body.querySelectorAll(
//           "#sliding-modal-backdrop"
//         );
//         if (backdrops.length > 0) {
//           let lastBackdrop = backdrops[backdrops.length - 1];
//           lastBackdrop.remove();
//         }
//       }
//     } catch (e) {
//       console.log(e);
//     }
//   }, [show]);

//   if (!show) {
//     return <></>;
//   }

//   return (
//     <div
//       className={`${modalClasses} hide-scrollbar ${className} ${dialogClassName} modal-content   ${
//         isDraggableParent ? "hg-popup-modal-draggable-parent" : ""
//       }`}
//       id={id}
//       style={{
//         width: width ?? "500px",
//       }}
//     >
//       <div
//         className="w-100 h-100 position-relative d-flex flex-column hide-scrollbar modal-content"
//         style={{
//           overflowX: "hidden",
//         }}
//       >
//         {children}
//       </div>
//     </div>
//   );
// };

// HGPopUpModal.Header = (props) => (
//   <div
//     className={`${props.className} modal-header`}
//   >
//     {props.children}
//   </div>
// );
// HGPopUpModal.Body = (props) => (
//   <div className={`${props.className} modal-body`}>{props.children}</div>
// );
// HGPopUpModal.Footer = (props) => (
//   <div
//     className={`${props.className}  modal-footer`}
//     style={{ marginTop: "auto" }}
//   >
//     {props.children}
//   </div>
// );

// export default HGPopUpModal;

// **************************

const HGPopUpModal = ({
  show,
  onHide,

  className,
  dialogClassName,
  children,
}) => {
  const [display, setDisplay] = useState(show);
  const modalRef = useRef(null);
  const disableAnimationRef = useRef(false);
  const animateModalOpen = (event) => {
    if (disableAnimationRef.current) return;
    const modal = modalRef.current;
    if (!modal) return;
    const { top, left } = modal.getBoundingClientRect();
    const { clientX, clientY } = event;
    const translateX = clientX - left - modal.offsetWidth / 2;
    const translateY = clientY - top - modal.offsetHeight / 2;
    modal.style.transform = `translate(${translateX}px, ${translateY}px) scale(0)`;
    modal.style.transition = "none";
    setTimeout(() => {
      modal.style.transform = "translate(0, 0) scale(1)";
      modal.style.transition = "transform 0.6s ease-in-out";
    }, 20);
  };
  useEffect(() => {
    if (show) {
      setDisplay(true);
      setTimeout(() => {
        disableAnimationRef.current = true;
      }, 50);
    } else {
      disableAnimationRef.current = false;
    }
  }, [show]);
  const closeModal = () => {
    onHide();
  };
  const onOverlayClick = (e) => {
    if (
      e.target.classList.contains("overlay") ||
      e.target.classList.contains("modal-container")
    ) {
      onHide();
    }
  };
  const onTransitionEnd = () => {
    if (!show) {
      setDisplay(false);
    }
  };
  if (!display|| !show) {
    return null;
  }
  return (
    <div
      className={`overlay ${
        show ? "open" : "close"
      } ${className} ${dialogClassName} `}
      onClick={onOverlayClick}
      onTransitionEnd={onTransitionEnd}
      onMouseEnter={animateModalOpen}
    >
      <div className="modal-container">
        <div className="modal" ref={modalRef}>
          {children}
        </div>
      </div>
    </div>
  );
};

HGPopUpModal.Header = (props) => (
  <div className={`${props.className} modal-header`}>{props.children}</div>
);
HGPopUpModal.Body = (props) => (
  <div className={`${props.className} modal-body transparent-scroller`}>{props.children}</div>
);
HGPopUpModal.Footer = (props) => (
  <div
    className={`${props.className}  modal-footer`}
    style={{ marginTop: "auto" }}
  >
    {props.children}
  </div>
);
export default HGPopUpModal;
