import React from "react";
import { Dropdown } from "react-bootstrap";
import "./SupplyPopUp.scss";

const SupplyPopUp = ({ children, dataList = [], header }) => {
  return (
    <div className={`supply-chain-pop-up`}>
      <Dropdown className="supply-pop-up">
        <Dropdown.Toggle>{children}</Dropdown.Toggle>
        <Dropdown.Menu>
          <div className="kpi-moda l " id="kpi-moda l">
            <header className="d-flex align-items-center bg-grey p-2 justify-content-between">
              <div className="f-grey">
                <img
                  alt=""
                  src="/images/attack-surface/info-icon.svg"
                  className="mr-2 pointer"
                />
                {header}
              </div>
              <div className="d-flex align-items-center pointer">
                <img
                  alt=""
                  src="/images/attack-surface/expand-icon.svg"
                  className="mx-2"
                />
                <img
                  alt=""
                  src="/images/attack-surface/cross-icon.svg"
                  className="mx-2 mr-2"
                  onClick={(e) => {
                    e.stopPropagation();
                    e.target.closest(".supply-pop-up").click();
                  }}
                />
              </div>
            </header>
            <div className="bg-white">
              <section className="px-3 bg-white">
                {dataList?.map((dataItem, dataItemIndex) => {
                  return (
                    <div className="d-flex align-items-start my-3">
                      <div>
                        <p className="font-weight-bold m-0">
                          {dataItem?.title}
                        </p>
                        <p className="f-black">{dataItem?.subtitle}</p>
                      </div>
                    </div>
                  );
                })}
              </section>

              {/* FOOTER  FOOTER FOOTER FOOTER FOOTER FOOTER FOOTER */}
              {/* FOOTER  FOOTER FOOTER FOOTER FOOTER FOOTER FOOTER */}
              {/* FOOTER  FOOTER FOOTER FOOTER FOOTER FOOTER FOOTER */}
              {/* FOOTER  FOOTER FOOTER FOOTER FOOTER FOOTER FOOTER */}
              {/* FOOTER  FOOTER FOOTER FOOTER FOOTER FOOTER FOOTER */}
              {/* FOOTER  FOOTER FOOTER FOOTER FOOTER FOOTER FOOTER */}
              {/* FOOTER  FOOTER FOOTER FOOTER FOOTER FOOTER FOOTER */}
              {/* FOOTER  FOOTER FOOTER FOOTER FOOTER FOOTER FOOTER */}
              {/* FOOTER  FOOTER FOOTER FOOTER FOOTER FOOTER FOOTER */}
              {/* FOOTER  FOOTER FOOTER FOOTER FOOTER FOOTER FOOTER */}
              {/* FOOTER  FOOTER FOOTER FOOTER FOOTER FOOTER FOOTER */}
              <hr className="m-0 dividerx " />
              <footer className=" bg-white ">
                <div
                  className="d-fle x d-none align-items-center pointer mt-2"
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  <img
                    alt=""
                    src="/images/attack-surface/attackSurfaceIcon.svg"
                    className="mr-2"
                  />
                  <div className="f-blue">Open Attack Surface</div>
                </div>
                <div
                  className="d-flex align-items-center p-2 px-3 pointer"
                  onClick={(e) => {
                    e.stopPropagation();
                    window.open("https://kb.highground.io");
                  }}
                >
                  <img
                    alt=""
                    src="/images/settings-v2/knowledgebase.svg"
                    className="mr-2"
                  />
                  <div className="f-grey">Learn more in our Knowlege base</div>
                  <img
                    alt=""
                    src="/images/attack-surface/goto.svg"
                    className="ml-2"
                  />
                </div>
              </footer>
            </div>
          </div>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

export default SupplyPopUp;
