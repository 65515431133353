import React from "react";
import "./table.scss";

const CollapseTable = (props) => {
  const toggleAccordian = () => {
    if(props.disabled===true){
      return;
    }
    var acc = document.querySelector(".custom-accordian-wrapper");
    acc.classList.toggle("active");
    var panel = acc.nextElementSibling;
    if (panel.style.display === "block") {
      panel.style.display = "none";
    } else {
      panel.style.display = "block";
    }
  };
  return (
    <div className={`custom-collapse-table`}>
      <div
        onClick={() => toggleAccordian()}
        className="w-100 custom-accordian-wrapper"
      >
        <div className={`custom-collapse-table_inner`}>
          <div className="left_text">  {props.header}  </div>
          <div className="right_text">
            {/* Items: 3 */}
            <span className="ml-5 arrow_image">
        {props.hideArrow ? "" :     <img alt="" src="/images/arrow_down.svg" />}
            </span>
          </div>
        </div>
      </div>
      <div className="w-100 mt-5 custom_table_outer" style={{ display: "block" }}>
        <div className="custom_table w-100">
          { props.children }
        </div>
      </div>
    </div>
  );
};

export default CollapseTable;
