import ConnectedToolItem from '../../../MSPv2/securityStack/connected-tool-item.component';
import './technologies-practices.styles.scss';

const ListItem = ({ spendItem }) => (
  <li className={'list-item'}>
    <div className="d-flex align-items-center justify-content-between">
      <div className="d-flex align-items-center f-500 f-grey">
        <img 
          width="13px"
          height="13px"
          src={spendItem?.icon}
          className='mr-2'
        />
        <span className={`${'text'} f-grey`}>
          {spendItem?.name}
        </span>
      </div>
      <div className={`${'text'} f-black`}>
        {spendItem?.IntegrationList?.length > 0 ? (
          <ConnectedToolItem 
            tool={{
              obj_IntegrationId: spendItem?.IntegrationList[0]?.logo,
              ToolName: spendItem?.IntegrationList[0]?.name
            }} 
          /> 
        ) : (spendItem?.StatusIcon || spendItem?.StatusName) ? (
          <span className={`${'incomplete-missing-txt'} f-darkgrey d-flex align-items-center`}>
            {spendItem?.StatusIcon ? (
              <img 
                width="13px"
                height="13px"
                src={spendItem?.StatusIcon}
                className='mr-1'
                style={{ minWidth: '13px' }}
              />
            ) : null}
            <span className='status-value f-black'>
              {spendItem?.StatusValue}
            </span>
          </span>
        ) : spendItem?.isMissing ? (
          <span className={`${'incomplete-missing-txt'} f-darkgrey`}>Missing</span>
        ) : spendItem?.isinComplete ? (
          <span className={`${'incomplete-missing-txt'} f-darkgrey`}>Incomplete</span>
        ) : null}
      </div>
    </div>
  </li>
)

const TechnologiesPractices = ({ technologiesandPractices }) => {
  return (
    <div className={'technologies-ptractices'}>
      <div className="highground-royal-font f-700 f-16 d-flex align-items-center justify-content-between">
        <span>Technologies and Practices</span>
      </div>
      <div className={'data-container'}>
        <div className={'section'}>
          <h3 className={`${'text'} m-0 f-grey ml-1`}>
            Technologies
          </h3>
          <ul className={'list'}>
            {technologiesandPractices?.Technologies?.map(
              (spendItem, spendItemIndex) => {
                return (
                  <ListItem 
                    key={spendItemIndex}
                    spendItem={{
                      ...spendItem,
                      name: spendItem?.TechnologyName,
                    }}
                  />
                );
              }
            )}
          </ul>
        </div>
        <div className={'section'}>
          <h3 className={`${'text'} m-0 f-grey ml-1`}>
            Practices
          </h3>
          <ul className={'list'}>
            {technologiesandPractices?.Practices?.map(
              (spendItem, spendItemIndex) => {
                return (
                  <ListItem 
                    key={spendItemIndex}
                    spendItem={{
                      ...spendItem,
                      name: spendItem?.PracticeName,
                      icon: spendItem?.PracticeIcon
                    }}
                  />
                );
              }
            )}
          </ul>
        </div>
      </div>
    </div>
  );
}

export default TechnologiesPractices;
