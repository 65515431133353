import React, { useEffect, useState } from "react";
import { Button, Dropdown, Form } from "react-bootstrap";
import "./mspIntegrations.scss";
import { TrimString } from "../../../Utils/StringTrimmer";
import ImportedClientDetailModal from "./ImportedClientDetailModal";
import MspMappingDropdownSearchInput from "./mspMappingScreens/mspMappingComponents/mspMappingDropdownSearchInput";
import ContentLoader from "react-content-loader";

import { GreyLoader } from "../../Theme/APILoaders";
import DotsLoader from "../../../Utils/dotsLoader";
import { parseIntegrationName } from "./mspMappingScreens/MSPMappings";
import { HGHighlightText } from "../../App/App";
import ScrollableDivWithShadow from "../MspAttackSurface.js/ScrollableDivWithShadow";
import SlidingModal from "../../CustomModal/SlidingModal";
import { getRequestOptions } from "../../../Utils/commonApi";
import { useAuth } from "../../context/Auth";
const MapAssets = ({
  memberPageData,
  setMemberPageData,
  getMembersListData,
  membersMappingData,
  getMembersMappingListData,
  updateMemberData,
  updateImportStatus,
  clientPageData,
  IntegrationId
}) => {
  const [loading, setLoading] = useState(false);
  const [fetchingNewMembers, setFetchingNewMembers] = useState(false);
  const [memberSearchString, setMemberSearchString] = useState("");

  const [automaticallyImportStatus, setAutomaticallyImportStatus] =
    useState(false);
  const [importStatus, setImportStatus] = useState(false);
  const [assetsSearchString, setAssetsSearchString] = useState("");
  const [assetsData, setAssetsData] = useState();
  const { authTokens } = useAuth();

  useEffect(() => {
    setLoading(true);
    Promise.all([
      getAssetsMappingListData({
        NextPageNo: 1,
        searchString: assetsSearchString ?? "",
      })
    ])
      .then((response) => {
        setLoading(false);
        setAutomaticallyImportStatus(response?.[0]?.AutoImport);
        setImportStatus(response?.[0]?.Import);
      })
      .catch((err) => {
        setLoading(false);
      });
    // }
  }, []);

  async function getMoreAssetsData(e) {
    try {
      if (
        e.target.clientHeight + e.target.scrollTop + 20 >=
        e.target.scrollHeight
      ) {
        if (assetsData?.NextPage === false) {
          return
        } else {
          let element = document.getElementById("mainBody");
          element.removeEventListener("scroll", getMoreAssetsData);

          setFetchingNewMembers(true);
          getAssetsMappingListData({
            NextPageNo: assetsData?.NextPageNo,
            searchString: assetsSearchString,
          }).then(() => {
            setFetchingNewMembers(false);
          });
        }

        console.log("You've reached the end of the screen!");
      }
    } catch (error) {
      console.log("errror aa gya", error);
    }
  }

  async function getAssetsMappingListData(kwargs) {
    const data = await fetch(
      `${process.env.REACT_APP_API_BASE_URL}/CC_API/GetPSAConfigurationMapping?optionEnum1=${6}&optionEnum2=${kwargs?.NextPageNo !== null && kwargs?.NextPageNo !== undefined
        ? kwargs?.NextPageNo
        : clientPageData?.NextPageNo !== null &&
          clientPageData?.NextPageNo !== undefined
          ? clientPageData?.NextPageNo
          : 1}&optionEnum3=${30}&optionEnum4=${IntegrationId}&optionEnum5=${kwargs?.hideIgnored ?? 0}&optionStrEnum1=${kwargs?.searchString ?? ""}&optionEnum99=${1}&accessKey=${JSON.parse(localStorage.getItem("user_MessageObj"))}`,
      getRequestOptions(authTokens)
    ).then((response) => response.json());
    if (data?.mr?.Success) {
      if (assetsData?.ObjectList && assetsData?.ObjectList?.length > 0) {
        let newAssetsData = [...assetsData?.ObjectList];
        newAssetsData.push(...data?.detail?.ObjectList);

        let updated_data = {
          ...data?.detail,
          ObjectList: newAssetsData,
        }

        setAssetsData(updated_data);
      } else {
        setAssetsData(data?.detail);
      }
    }
  }

  async function updateConfigurationMapping(recordId, ASEObject, SellObject) {
    const data = await fetch(
      `${process.env.REACT_APP_API_BASE_URL}/CC_API/UpdateConfigurationMapping?RecordId=${recordId}&ASEObjectVm=${ASEObject}&SellObjectVm=${SellObject}&accessKey=${JSON.parse(localStorage.getItem("user_MessageObj"))}`,
      getRequestOptions(authTokens, "POST")
    ).then((response) => response.json());
    if (data?.mr?.Success) {
      getAssetsMappingListData({
        NextPageNo: 1,
        searchString: assetsSearchString ?? "",
      })
    }
  }

  useEffect(() => {
    var element = document.getElementById("mainBody");
    element.addEventListener("scroll", getMoreAssetsData);

    return () => {
      element.removeEventListener("scroll", getMoreAssetsData);
    };
  }, [assetsData]);

  return (
    <>
      {loading ? (
        <MapMembersLoader />
      ) : (
        <div
          id="mapped-client-inegtration-modal-parent"
          className="map-clients-screen  "
        >
          <div className="d-flex align-items-center pt-4 justify-content-between">
            <div className="d-flex align-items-center">
              <img
                alt=""
                width={42}
                height={42}
                src={`/images/attack-surface/integrations/${parseIntegrationName("importedIntegrtionId")}.webp`}
                className="mr-3 radius-4"
                style={{
                  border: "1px solid #ebecf180"
                }}
              />
              <div>
                <p className="f-black f-600 f-15 m-0">Import Assets</p>
                <p className="f-grey f-14">Import and map assets from Connectwise Manage</p>
              </div>
            </div>
            <div className="">
              <Form.Check
                style={{
                  scale: "1.5",
                }}
                type="switch"
                id={`switchnitification1`}
                checked={importStatus}
                className={`m-0`}
                onClick={() => { }}
                onChange={(e) => {
                  updateImportStatus(6, Number(e.target.checked));
                  setImportStatus(e.target.checked);
                }}
              />
            </div>
          </div>


          <div className="d-flex align-items-center justify-content-between pt-5 pb-2">
            <div className="f-black f-16 f-600 ">Assets</div>
            <div className="mx-2 d-flex align-items-center">
              <input
                type="text"
                placeholder="Search.."
                value={assetsSearchString}
                onChange={(e) => {
                  // let value = e.target.value;
                  setAssetsSearchString(e.target.value);
                  getAssetsMappingListData({
                    NextPageNo: 1,
                    searchString: e.target.value,
                  });
                }}
                onBlur={() => { }}
                className={`border-none bg-tranparent pl-2`}
              />
              <img
                alt=""
                src="/images/attack-surface/search-icon.svg"
                className="pointer"
                onClick={() => { }}
              />
            </div>
          </div>
          <div className="border-parent bg-grey radius-8">
            <MapAssetsBlock
              dataToMap={assetsData?.ObjectList}
              memberPageData={memberPageData}
              setMemberPageData={setMemberPageData}
              getMembersListData={getMembersListData}
              membersMappingData={membersMappingData}
              getMembersMappingListData={getMembersMappingListData}
              memberSearchString={memberSearchString ?? ""}
              updateMemberData={updateMemberData}
              updateConfigurationMapping={updateConfigurationMapping}
              assetsData={assetsData}
            />
          </div>
          {fetchingNewMembers && (
            <div className="d-flex align-items-center f-darkgrey">
              <GreyLoader width={12} />
              Loading...
            </div>
          )}

          <div className="p-3"></div>
        </div>
      )}
    </>
  );
};

export default MapAssets;

export const MapAssetsBlock = ({
  memberPageData,
  setMemberPageData,
  getMembersListData,
  membersMappingData,
  getMembersMappingListData,
  memberSearchString,
  updateMemberData,
  dataToMap,
  assetsData,
  updateConfigurationMapping = () => { }
}) => {
  const [showMappedMemberDetailModal, setShowMappedMemberDetailModal] =
    useState(false);
  const [sideModal, setSideModal] = useState(false);
  const [memberDetails, setMemberDetails] = useState([]);


  const [configurationFieldsData, setConfigurationFieldsData] = useState([])
  const [selectedClientData, setSelectedClientData] = useState({});

  return (
    <>
      <ImportedClientDetailModal
        show={showMappedMemberDetailModal}
        hideModal={() => setShowMappedMemberDetailModal(false)}
        data={memberDetails}
        parentID={"mapped-client-inegtration-modal-parent"}
      />
      {/* Header */}
      <div className="border-item map-assets-header">
        <div className="f-12 f-500 f-grey py-2 pl-4 pr-3 w-100 d-flex align-items-center justify-content-between">
          <div>Connectwise Manage</div>
          <div>{assetsData?.Count}</div>
        </div>
        <div className="f-12 f-500 f-grey py-2 pl-2 w-100">Managed security</div>
        <div className="f-12 f-500 f-grey py-2 pl-2 w-100">Attack Surface</div>
      </div>

      {/* Firts row */}
      {
        dataToMap?.map((dataItem) => {
          return (
            <div className="px-3 py-1 map-assets align-items-center border-bottom-gray">
              <div>
                <div className="f-500 f-14 f-black line-height-22 letter-space-2">{dataItem?.name}</div>
                <div className="d-flex align-items-center">
                  <img
                    alt=""
                    className="mr-2"
                    src={dataItem?.MappingStatusIcon}
                  />
                  <div className="f-400 f-12 greyText2 line-height-18 letter-space-2">{dataItem?.MappingStatus}</div>
                </div>
              </div>
              <div className="d-flex justify-content-between align-items-center">
                {dataItem?.state === "Imported" && (
                  <img
                    // src="/images/msp/right-blue-mark.svg"
                    src="/images/msp/twoway-blue-mark.svg"
                    alt=""
                    className="mr-2 pointer"
                  />
                )}
                {dataItem?.state == "Auto_Imported" && (
                  <img
                    src="/images/msp/right-blue-mark.svg"
                    alt=""
                    className="mr-2 pointer"
                  />
                )}
                {dataItem?.state == "Mapped" && (
                  <img
                    src="/images/msp/twoway-blue-mark.svg"
                    alt=""
                    className="mr-2 pointer"
                  />
                )}
                {dataItem?.state == "Deleted" && (
                  <img
                    src="/images/msp/red-cross-circle.svg"
                    alt=""
                    className="mr-2 pointer"
                  />
                )}
                {dataItem?.state == "Not Selected" && (dataItem?.ManageSecurityObjects?.name != 'Ignore' && dataItem?.ASEObjects?.name != 'Ignore') && (
                  <img
                    src="/images/msp/choose-action-icon.svg"
                    alt=""
                    className="mr-2 pointer"
                  />
                )}
                {dataItem?.state == "Provisional_Import" && (
                  <img
                    src="/images/msp/right-blue-mark.svg"
                    // src="/images/msp/twoway-blue-mark.svg"
                    alt=""
                    className="mr-2 pointer"
                  />
                )}
                {(dataItem?.ManageSecurityObjects?.name == 'Ignore' && dataItem?.ASEObjects?.name == 'Ignore') && (
                  <img
                    src="/images/msp/cros-action-icon.svg"
                    alt=""
                    className="mr-2 pointer"
                  />
                )}
                <Dropdown className="map-clients-dropdown w-100">
                  <Dropdown.Toggle className="w-100">
                    <div className="d-flex w-100 align-items-center justify-content-between">
                      <div className="w-100 bg-grey f-black d-flex pr-4">{dataItem?.ManageSecurityObjects?.name}</div>
                      <img
                        alt=""
                        className="mr-2"
                        src="/images/msp/integrations/chevron-down-msp.svg"
                      />
                    </div>
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <div>
                      <div className="f-400 f-12 f-darkgrey line-height-18 letter-space-2 px-2 py-1">Assets</div>
                      {
                        dataItem?.ManageSecurityObjects?.EntityList?.map((assetItem) => {
                          return (
                            <div
                              className="f-400 f-14 f-grey line-height-22 letter-space-2 px-2 py-1"
                              onClick={(e) => {
                                updateConfigurationMapping(dataItem?.id, JSON.stringify({
                                  id: dataItem?.ASEObjects?.id,
                                  name: dataItem?.ASEObjects?.name,
                                  Fields_details: null
                                } ?? {}), JSON.stringify(assetItem))
                                e.target
                                  .closest(".map-clients-dropdown")
                                  .click();
                              }}
                            >
                              {assetItem?.name}
                            </div>
                          )
                        })
                      }
                      <div className="d-flex align-items-center px-2 py-1">
                        <img
                          width={12}
                          alt=""
                          className="mr-1"
                          src="/images/horizontal-dots.svg"
                        />
                        <div className="f-400 f-14 f-darkgrey line-height-22 letter-space-2 px-2 py-1">Show more</div>
                      </div>
                    </div>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              <Dropdown className="map-clients-dropdown w-100">
                <Dropdown.Toggle className="w-100">
                  <div className="d-flex w-100 align-items-center justify-content-between">
                    <div className="w-100 bg-grey f-black d-flex pr-4">{dataItem?.ASEObjects?.name}</div>
                    <img
                      alt=""
                      className="mr-2"
                      src="/images/msp/integrations/chevron-down-msp.svg"
                    />
                  </div>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <div>
                    <div className="f-400 f-12 f-darkgrey line-height-18 letter-space-2 px-2 py-1">Unit type</div>
                    {
                      dataItem?.ASEObjects?.EntityList?.map((assetItem) => {
                        return (
                          <div
                            className="f-400 f-14 f-grey line-height-22 letter-space-2 px-2 py-1"
                            onClick={(e) => {
                              updateConfigurationMapping(dataItem?.id, JSON.stringify(assetItem), JSON.stringify({
                                id: dataItem?.ManageSecurityObjects?.id,
                                name: dataItem?.ManageSecurityObjects?.name,
                                Fields_details: null
                              } ?? {}))
                              e.target
                                .closest(".map-clients-dropdown")
                                .click();
                            }}
                          >
                            {assetItem?.name}
                          </div>
                        )
                      })
                    }
                    <div className="d-flex align-items-center px-2 py-1">
                      <img
                        width={12}
                        alt=""
                        className="mr-1"
                        src="/images/horizontal-dots.svg"
                      />
                      <div className="f-400 f-14 f-darkgrey line-height-22 letter-space-2 px-2 py-1">Show more</div>
                    </div>
                  </div>
                </Dropdown.Menu>
              </Dropdown>

              {
                (!dataItem?.MappingStatus && !dataItem?.MappingStatusIcon && dataItem?.MappingStatus != "" && dataItem?.MappingStatusIcon != "") ? <div
                  className={`f-500 f-12 line-height-18 letter-space-2 pointer ${dataItem?.MappingStatus != 'Field Mapping Issue' ? 'greyText2' : 'txt-blue'}`}
                  onClick={() => {
                    setConfigurationFieldsData(dataItem?.Configuration_Fields_details);
                    setSelectedClientData(dataItem);
                    setSideModal(true);
                  }}
                >
                  Map Details
                </div> : <div></div>
              }
            </div>
          )
        })
      }

      <SideModal
        show={sideModal}
        hideModal={() => {
          setSideModal(false);
        }}
        configurationFieldsData={configurationFieldsData}
        selectedClientData={selectedClientData}
        setConfigurationFieldsData={setConfigurationFieldsData}
      />
    </>
  );
};


const SideModal = ({
  show,
  hideModal,
  configurationFieldsData,
  selectedClientData,
  setConfigurationFieldsData = () => { }
}) => {

  const attackSurfaceDropdownData = [
    {
      id: 0,
      label: 'Laptop details',
      items: [
        {
          itemId: 0,
          name: 'Operating System'
        },
        {
          itemId: 1,
          name: 'Model'
        },
        {
          itemId: 2,
          name: 'IP Address'
        }
      ]
    },
    {
      id: 1,
      label: 'Management',
      items: [
        {
          itemId: 3,
          name: 'Date Installed'
        },
        {
          itemId: 4,
          name: 'Warranty Expiry'
        },
        {
          itemId: 5,
          name: 'Location'
        },
        {
          itemId: 6,
          name: 'Managed by'
        },
        {
          itemId: 7,
          name: 'Security / Protection'
        },
        {
          itemId: 68,
          name: 'Active Directory Joined'
        }
      ]
    }
  ];

  return (
    <SlidingModal
      show={show}
      className="common-big-modal memberAccessModal"
      id="ounsqxw"
      width={"28%"}
      onHide={() => {
        hideModal();
      }}
    >
      <SlidingModal.Header className="text-center d-flex p-3 border-bottom">
        <div className="f-500 f-14 f-black line-height-22 letter-space-2 w-100">{selectedClientData?.name}</div>
      </SlidingModal.Header>
      <SlidingModal.Body className={`msp-service-add-edit-modal p-3 ${true}`}>
        <div>
          <div className="d-flex align-items-center">
            <div className="f-400 f-14 greyText2 line-height-22 letter-space-2 w-50">Name:</div>
            <div className="f-400 f-14 f-black line-height-22 letter-space-2 w-50">{selectedClientData?.name}</div>
          </div>
          <div className="d-flex align-items-center mt-1">
            <div className="f-400 f-14 greyText2 line-height-22 letter-space-2 w-50">Qty. assets:</div>
            <div className="f-400 f-14 f-black line-height-22 letter-space-2 w-50">157</div>
          </div>
          <div className="d-flex align-items-center mt-1">
            <div className="f-400 f-14 greyText2 line-height-22 letter-space-2 w-50">Integration:</div>
            <div className="d-flex align-items-center w-50">
              <img
                alt=""
                width={20}
                height={20}
                src="/images/attack-surface/integrations/3930640.webp"
                className="mr-2"
              />
              <div className="f-400 f-14 f-black line-height-22 letter-space-2">Connectwise Manage</div>
            </div>
          </div>
          <div className="my-3 hg-border-bottom"></div>

          <div className="f-500 f-14 f-black line-height-22 letter-space-2 ">Fields</div>
          <div className="f-400 f-14 greyText2 line-height-22 letter-space-2">Map fields from ConnectWise Manage to HighGround Attack Surface</div>

          <div className="d-flex align-items-center my-1">
            <div className="f-500 f-14 f-darkgrey line-height-22 letter-space-2 w-50">Connectwise Manage</div>
            <div className="f-500 f-14 f-darkgrey line-height-22 letter-space-2 w-50">Attack Surface</div>
          </div>

          {
            !configurationFieldsData || configurationFieldsData?.length <= 0 ? <div
              className="d-flex align-items-center justify-content-center my-5 f-500 f-14 f-darkgrey line-height-22 letter-space-2 w-100"
            >
              No data yet
            </div> : <div>
              {
                configurationFieldsData?.map((item) => {
                  return (
                    <div className="d-flex align-items-center mt-1">
                      <div className="d-flex align-items-center justify-content-between w-50">
                        <div className="f-400 f-14 f-black line-height-22 letter-space-2">{item?.label}{item?.required && '*'}</div>
                        <img
                          alt=""
                          width={11}
                          height={11}
                          className="mr-2"
                          src='/images/attack-surface/right-arrow.svg'
                        />
                      </div>
                      <Dropdown className="map-clients-dropdown  w-50">
                        <Dropdown.Toggle>
                          {
                            item?.ignore ? <div className="d-flex align-items-center p-1">
                              <div className="f-500 f-12 f-grey line-height-18 letter-space-2">Ignore</div>
                              <img
                                alt=""
                                width={9}
                                height={9}
                                className="ml-2"
                                src='/images/grey-cross.svg'
                                onClick={() => {
                                  const updated_data = configurationFieldsData.map(mapItem => {
                                    if (mapItem?.label === item?.label) {
                                      return {
                                        ...mapItem,
                                        ignore: false,
                                      };
                                    }
                                    return item;
                                  });
                                  setConfigurationFieldsData(updated_data);
                                }}
                              />
                            </div> : item?.selectedAttackSurface == null ? <div className="d-flex align-items-center">
                              <img
                                alt=""
                                className="mr-2"
                                src='/images/mdi_warning.svg'
                              />
                              <div className="f-500 f-12 f-grey line-height-18 letter-space-2">Select field</div>
                            </div> :
                              <div className="d-flex align-items-center bg-greyish p-1">
                                <div className="f-500 f-12 f-grey line-height-18 letter-space-2">{item?.selectedAttackSurface?.name}</div>
                                <img
                                  alt=""
                                  width={9}
                                  height={9}
                                  className="ml-2"
                                  src='/images/grey-cross.svg'
                                />
                              </div>
                          }
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <div>
                            {
                              attackSurfaceDropdownData?.map((attackSurfaceItem) => {
                                return (
                                  <div className="px-2">
                                    <div className="f-400 f-12 f-darkgrey line-height-18 letter-space-2">{attackSurfaceItem?.label}</div>
                                    {
                                      attackSurfaceItem?.items?.map((item) => {
                                        return (
                                          <div className="f-400 f-14 f-grey line-height-22 letter-space-2 py-1 pointer">{item?.name}</div>
                                        )
                                      })
                                    }
                                  </div>
                                )
                              })
                            }
                            <div className="px-2 d-flex align-items-center hg-border-top pointer">
                              <img
                                alt=""
                                width={9}
                                height={9}
                                className="mr-2"
                                src='/images/grey-cross.svg'
                              />
                              <div className="f-400 f-14 f-grey line-height-22 letter-space-2 py-1">Ignore</div>
                            </div>
                          </div>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  )
                })
              }
            </div>
          }


          <div className="f-400 f-12 f-grey line-height-18 letter-space-2">* - Mandatory fields</div>

          {/* <div className="d-flex align-items-center mt-1">
            <div className="d-flex align-items-center justify-content-between w-50">
              <div className="f-500 f-14 f-darkgrey line-height-22 letter-space-2">Name*</div>
              <img
                alt=""
                width={11}
                height={11}
                className="mr-2"
                src='/images/attack-surface/right-arrow.svg'
              />
            </div>
            <Dropdown className="map-clients-dropdown  w-50">
              <Dropdown.Toggle>
                <div className="d-flex align-items-center bg-greyish p-1">
                  <div className="f-500 f-14 f-darkgrey line-height-22 letter-space-2">Name</div>
                  <img
                    alt=""
                    width={11}
                    height={11}
                    className="ml-2"
                    src='/images/grey-cross.svg'
                  />
                </div>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <>test</>
              </Dropdown.Menu>
            </Dropdown>
          </div> */}
        </div>
      </SlidingModal.Body>
      <SlidingModal.Footer className="p-3 position-sticky w-100 border-top">

      </SlidingModal.Footer>

    </SlidingModal>
  );
};


//
// const MapMembersDropdown = ({
//   memberDetail,
//   membersMappingData,
//   getMembersMappingListData,
//   onStateChange,
// }) => {
//   function closeDropdown(e) {
//     e.target.closest(".map-clients-dropdown").click();
//   }
//   const [fetchingMapping, setFetchingMapping] = useState(false);
//   const [search, setSearch] = useState("");
//   const [selected, setSelected] = useState();
//   const [page, setPage] = useState("home");
//   const [loadingMappingData, setLoadingMappingData] = useState(false)

//   useEffect(() => {
//     if (memberDetail?.state === "Ignored") {
//       setSelected();
//     } else if (memberDetail?.state === "Imported") {
//       setSelected();
//     } else if (memberDetail?.state === "Not_Selected") {
//       setSelected();
//     } else if (memberDetail?.state === "Mapped") {
//       let mapped_list = [...membersMappingData?.ObjectList];
//       if (memberDetail?.ParentObjId > 0) {
//         let selected = mapped_list.filter(
//           (item) => item?.id === memberDetail?.ParentObjId
//         )[0];
//         setSelected(selected);
//       }
//     }
//   }, [memberDetail]);

//   const [timeoutId, setTimeoutId] = useState();

//   const handleInputChange = (event) => {
//     const { value } = event.target;
//     setSearch(value);


//     // Clear the previous timeout
//     clearTimeout(timeoutId);

//     // Set a new timeout to call the API after 1 second
//     let _timeoutId = setTimeout(() => {
//       setLoadingMappingData(true);
//       getMembersMappingListData({
//         NextPageNo: 1,
//         searchString: value,
//       }).then(() => {
//         setLoadingMappingData(false);
//       });
//     }, 700);
//     setTimeoutId(_timeoutId);
//   };

//   return (
//     <Dropdown
//       className="map-clients-dropdown"
//       onToggle={(e) => {
//         if (e) {
//         } else {
//           setPage("home");
//           setSearch("");
//           getMembersMappingListData({
//             NextPageNo: 1,
//             searchString: "",
//           });
//         }
//       }}
//     >
//       <Dropdown.Toggle className={`d-flex align-items-center w-100`}>
//         {memberDetail?.state === "Ignored" && (
//           <div className="d-flex w-100 align-items-center justify-content-between">
//             <div className="w-100 bg-grey f-black d-flex">Ignore</div>
//             <img
//               alt=""
//               className="mr-2"
//               src="/images/msp/integrations/chevron-down-msp.svg"
//             />
//           </div>
//         )}

//         {memberDetail?.state === "Not_Selected" && (
//           <div className="d-flex w-100 align-items-center justify-content-between">
//             <div className="w-100 bg-grey f-darkgrey d-flex">Choose action</div>
//             <img
//               alt=""
//               className="mr-2"
//               src="/images/msp/integrations/chevron-down-msp.svg"
//             />
//           </div>
//         )}

//         {memberDetail?.state === "Imported" && (
//           <div className="d-flex w-100 align-items-center justify-content-between">
//             <div className="w-100 bg-grey f-black d-flex">
//               {memberDetail?.ImportName ? (
//                 <>Import as "{TrimString(memberDetail.ImportName, 20)}"</>
//               ) : (
//                 <>Import "{TrimString(memberDetail?.name, 20)}"</>
//               )}
//             </div>
//             <img
//               alt=""
//               className="mr-2"
//               src="/images/msp/integrations/chevron-down-msp.svg"
//             />
//           </div>
//         )}
//         {memberDetail?.state === "Provisional_Import" && (
//           <div className="d-flex w-100 align-items-center justify-content-between">
//             <div className="w-100 bg-grey f-black d-flex">
//               {memberDetail?.ImportName ? (
//                 <>Import as "{TrimString(memberDetail.ImportName, 20)}"</>
//               ) : (
//                 <>Import "{TrimString(memberDetail?.name, 20)}"</>
//               )}
//             </div>
//             <img
//               alt=""
//               className="mr-2"
//               src="/images/msp/integrations/chevron-down-msp.svg"
//             />
//           </div>
//         )}

//         {memberDetail?.state === "Auto_Imported" && (
//           <div className="d-flex w-100 align-items-center justify-content-between">
//             <div className="w-100 bg-grey f-black d-flex">Auto-Imported</div>
//             <img
//               alt=""
//               className="mr-2"
//               src="/images/msp/integrations/chevron-down-msp.svg"
//             />
//           </div>
//         )}
//         {memberDetail?.state === "Deleted" && (
//           <div className="d-flex w-100 align-items-center justify-content-between">
//             <div className="w-100 bg-grey f-black d-flex">Deleted</div>
//             <img
//               alt=""
//               className="mr-2"
//               src="/images/msp/integrations/chevron-down-msp.svg"
//             />
//           </div>
//         )}
//         {memberDetail?.state === "Mapped" && (
//           <div className="d-flex w-100 align-items-center justify-content-between">
//             <div className="d-flex  align-items-center f-black">
//               <img
//                 alt=""
//                 width={16}
//                 height={16}
//                 className=" mr-2"
//                 src={"/images/user-logo.svg"}
//               />

//               {TrimString(
//                 selected?.name ?? memberDetail?.relationalObjectDetails?.name,
//                 25
//               )}
//             </div>
//             <div>
//               <img
//                 alt=""
//                 className="mr-2"
//                 src="/images/msp/integrations/chevron-down-msp.svg"
//               />
//             </div>
//           </div>
//         )}
//       </Dropdown.Toggle>
//       <Dropdown.Menu className="no-border-shadow-dropdown">
//         {page === "home" && (
//           <>
//             <div className="d-flex align-items-center mb-2">
//               <MspMappingDropdownSearchInput
//                 loading={loadingMappingData}
//                 placeholder="Find or import as..."
//                 value={search}
//                 onChange={(e) => {
//                   handleInputChange(e)
//                 }}
//               />
//             </div>
//             <div className="f-12 f-400 f-darkgrey my-1 pl-2">Map with</div>

//             <ScrollableDivWithShadow
//               className="transparent-scroller  member-right-list-scroller pl-2"
//               style={{
//                 maxHeight: "300px",
//                 overflowY: "scroll",
//               }}
//             >
//               {membersMappingData?.ObjectList

//                 // ?.filter((item) =>
//                 //   item?.name?.toLowerCase()?.includes(search.toLowerCase())
//                 // )

//                 ?.map((member, memberIndex) => {
//                   return (
//                     <div
//                       className="d-flex align-items-center f-grey f-14 f-500 w-100 contextmenu-grey-item pointer "
//                       title={member?.name}
//                       onClick={(e) => {
//                         closeDropdown(e);
//                         setSearch("");
//                         onStateChange("Mapped", member);
//                         setSelected(member);
//                       }}
//                     >
//                       <img
//                         alt=""
//                         width={16}
//                         height={16}
//                         className="mx-2"
//                         src={"/images/user-logo.svg"}
//                       />

//                       <span dangerouslySetInnerHTML={{ __html: HGHighlightText(TrimString(member?.name, 25), search) }} />

//                     </div>
//                   );
//                 })}
//             </ScrollableDivWithShadow>

//             {membersMappingData?.ObjectList?.length === 0 && search === "" && (
//               <div className="f-darkgrey d-flex align-items-center justify-content-start p-2 pl-3">
//                 No{" "}{Number(parseIntegrationName("importedIntegrtionId")) === 3906771 ? "agents" : "members"}{" "}have been imported yet
//               </div>
//             )}
//             {membersMappingData?.ObjectList?.length === 0 && search !== "" && (
//               <div className="f-darkgrey d-flex align-items-center justify-content-start p-2 pl-3">
//                 No results...
//               </div>
//             )}

//             {search !== "" && (
//               <div
//                 className="f-blue pointer d-flex align-items-center pl-3"
//                 onClick={(e) => {
//                   onStateChange("Provisional_Import", { ImportName: search });
//                   closeDropdown(e);
//                   setSearch("");
//                 }}
//               >
//                 <img alt="" className="mr-2" src="/images/msp/blue-plus.svg" />
//                 Import as “{TrimString(search, 20)}”
//               </div>
//             )}
//             {membersMappingData?.NextPage === true && (
//               <div
//                 className="d-flex pointer align-items-center pl-3 mt-2"
//                 onClick={() => {
//                   if (fetchingMapping) return;
//                   setFetchingMapping(true);
//                   getMembersMappingListData({
//                     NextPageNo: null,
//                     searchString: search,
//                     skip: membersMappingData?.ObjectList?.length,
//                     count: 1000,
//                   })
//                     .then(() => {
//                       setFetchingMapping(false);
//                       const elementToScroll = document.querySelector(
//                         ".member-right-list-scroller"
//                       );
//                       elementToScroll.scrollTo({
//                         top: 10000000,
//                         behavior: "smooth", // You can use 'auto' for instant scrolling
//                       });
//                     })
//                     .catch(() => {
//                       setFetchingMapping(false);
//                     });
//                 }}
//               >
//                 <DotsLoader animate={fetchingMapping} />
//                 <div className="f-darkgrey ml-2">Show more</div>
//               </div>
//             )}
//             <hr className="hg-border mx-auto px-3" style={{ width: "80%" }} />
//             <div className="mx-2  ">
//               <div
//                 className="f-grey   pointer d-flex align-items-center contextmenu-grey-item"
//                 onClick={(e) => {
//                   setSelected();
//                   closeDropdown(e);
//                   setSearch("");
//                   onStateChange("Provisional_Import");
//                 }}
//               >
//                 <img
//                   src="/images/attack-surface/right-arrow.svg"
//                   alt=""
//                   className="mr-2"
//                 />
//                 Import "{TrimString(memberDetail?.name, 20)}"
//               </div>
//               <div
//                 className="f-grey  pointer d-flex align-items-center contextmenu-grey-item"
//                 onClick={(e) => {
//                   setPage("importAs");
//                 }}
//               >
//                 <img
//                   src="/images/attack-surface/right-arrow.svg"
//                   alt=""
//                   className="mr-2"
//                 />
//                 Import as ...
//               </div>
//               <div
//                 className="f-grey   pointer d-flex align-items-center contextmenu-grey-item"
//                 onClick={(e) => {
//                   setSelected();
//                   closeDropdown(e);
//                   setSearch("");
//                   onStateChange("Ignored");
//                 }}
//               >
//                 <img
//                   src="/images/msp/integrations/cross-icon-msp.svg"
//                   alt=""
//                   className="mr-2"
//                 />
//                 Ignore
//               </div>
//             </div>
//           </>
//         )}
//         {page === "importAs" && (
//           <div className="w-100 p-2">
//             <div className="f-12 f-darkgrey mb-2 pl-1"> Import as </div>

//             <div className="p-1 bg-grey radius-4 highlight-input-with-grey-border">
//               <input
//                 type="text"
//                 className="border-none bg-transparent w-100"
//                 value={search}
//                 onChange={(e) => {
//                   setSearch(e.target.value);
//                 }}
//               />
//             </div>
//             <div className="mt-2 d-flex align-items-center justify-content-end">
//               <Button
//                 className="hg-cancel-btn mr-2"
//                 onClick={() => {
//                   setPage("home");
//                   setSearch("");
//                 }}
//               >
//                 Cancel
//               </Button>
//               <Button
//                 className="hg-blue-btn"
//                 disabled={search === ""}
//                 onClick={(e) => {
//                   onStateChange("Provisional_Import", { ImportName: search });
//                   closeDropdown(e);
//                   setSearch("");
//                 }}
//               >
//                 Save
//               </Button>
//             </div>
//           </div>
//         )}
//       </Dropdown.Menu>
//     </Dropdown>
//   );
// };


const MapMembersLoader = () => {
  return (
    <ContentLoader
      speed={2}
      width={"100%"}
      height={"100%"}
      viewBox="0 0 1500 900"
      backgroundColor="#F6F8FB"
      foregroundColor="#ecebeb"
    >
      <rect x="27%" y="20" rx="8" ry="8" width="4.5%" height="60" />
      <rect x="33%" y="22" rx="6" ry="6" width="10%" height="20" />
      <rect x="33%" y="52" rx="6" ry="6" width="25%" height="20" />

      <rect x="27%" y="100" rx="7" ry="7" width="45%" height="60" />

      <rect x="27%" y="200" rx="4" ry="4" width="6%" height="25" />
      <rect x="62%" y="200" rx="4" ry="4" width="10%" height="25" />

      <rect x="27%" y="230" rx="4" ry="4" width="45%" height="30" />

      <rect x="27%" y="262" rx="4" ry="4" width="45%" height="60" />
      <rect x="27%" y="324" rx="4" ry="4" width="45%" height="60" />
      <rect x="27%" y="386" rx="4" ry="4" width="45%" height="60" />
      <rect x="27%" y="448" rx="4" ry="4" width="45%" height="60" />
      <rect x="27%" y="510" rx="4" ry="4" width="45%" height="60" />
      <rect x="27%" y="572" rx="4" ry="4" width="45%" height="60" />
      <rect x="27%" y="634" rx="4" ry="4" width="45%" height="60" />
    </ContentLoader>
  );
};

