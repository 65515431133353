import React, { useState, useEffect } from "react";
import {
  Button,
  Modal,
  Row,
  Col,
  InputGroup,
  Form,
  OverlayTrigger,
  FormControl,
  Tooltip,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import "./index.scss";

const PaymentResponse = (props) => {
  const { data} = props;
  return (
    <div className="w-100 mt-5">
      <div className="d24" />
      <div className="d24" />
      <div className="d24" />

      <>
        <div className="text-center">
          {data && !data.Success  && (
            <img src="/images/rfail.svg" alt="" />
          )}

        {data && data.Success && (
            <img src="/images/rsuccess.svg" alt="" />
          )}

         {data && data.Success &&  <p
            className="txtfail mt-4"
            style={{ color: "#40d88e" }}
          >
         {data && data.Message }
          </p> }

        
          {data && !data.Success &&  <p
            className="txtfail mt-4"
          >
            Something went wrong!
          </p> }
         
          <Button onClick={() => {
            if( data && data.Success  ){
              props.closeModal()
              props.isClose() 
            }else{
              props.isClose() 
            }
          }} className="w-100 blubtn mt-4">
           {data && data.Success
              ? "Close"
              : "Try Again"}
          </Button>
          <div className="d24" />
          <div className="d24" />
          <div className="d24" />
          <div className="d24" />
        </div>
      </>

    </div>
  );
};

export default PaymentResponse;
