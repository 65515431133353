import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { getAPIData } from "../../Utils/commonApi";
import { useAuth } from "../context/Auth";
import { CallToast } from "../PriceCalculator/modals/CustomToast";
import { WhiteLoader } from "../Theme/APILoaders";

import "./attackSurface.scss";
import { updateAccessKeyInLocalStorage } from "../../Utils/CommonFunctions";

export const AttackSurfaceResetModal = ({
  show,
  hideModal,
  refreshData,
  resetPageEnum,
  refreshMenuItems,
  optionEnum1
}) => {
  const [loading, setLoading] = useState(false);
  const { authTokens } = useAuth();

  async function ResetSettings() {
    setLoading(true);
    await getAPIData(
      resetPageEnum,
      authTokens).then((response) => {
      setLoading(false);
      if (
        response?.Success &&
        response?.Message &&
        (resetPageEnum === 267 || resetPageEnum === 269)
      ) {
        updateAccessKeyInLocalStorage(response?.Message)
       
      }
      if (response?.Success) {
        refreshData();
        if (refreshMenuItems) {
          refreshMenuItems();
        }
        hideModal();
        CallToast("Answers updated successfully", false, false, "v2style");
        if (refreshMenuItems) {
          refreshMenuItems();
        }
      } else {
        setLoading(false);
        CallToast("Something went wrong", false, false, "v2style");
      }
    });
  }
  return (
    <Modal
      animation={false}
      show={show}
      dialogClassName="attack-reset-settings-modal"
      className=""
      aria-labelledby="reset-settings-modal-title-vcenter"
      centered
      scrollable
      onHide={hideModal}
    >
      <Modal.Body>
        <div>
          <p className="modal-title ml-2 mt-2">Reset answers?</p>
          <p className="modal-subtitle mx-2 mt-3">
            Your about to reset all answers in this section. Are you sure?
          </p>
        </div>
      </Modal.Body>

      <Modal.Footer>
        <div className="footer d-flex justify-content-end">
          <Button className="cancel-btn" onClick={hideModal}>
            Cancel
          </Button>
          <Button
            className="reset-btn"
            onClick={ResetSettings}
            disabled={loading}
          >
            {loading ? (
              <span>
                <WhiteLoader width={12} />
                Resetting..
              </span>
            ) : (
              "Reset"
            )}
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};
