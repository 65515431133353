import React from "react";
import { Route } from "react-router-dom/cjs/react-router-dom";
import SupplierProfilerHomeScreen from "./SupplierProfilerHomeScreen";

const SupplierProfiler = () => {
  return (
    <>
      <Route
        exact
        path={"/supplier-profiler/profiling"}
        component={SupplierProfilerHomeScreen}
      />
    </>
  );
};

export default SupplierProfiler;
