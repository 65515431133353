import React, { useState, useEffect } from "react";
import { Row, Col, Spinner } from "react-bootstrap";

import "../actionCentreModals.scss";

const Loader = ({ setModalTab, modalTab }) => {
  const [isConnect, setIsConnect] = useState(false);
  useEffect(() => {
    if (modalTab === "loading") {
      setTimeout(() => {
        setIsConnect("error");
      }, 1);
    }
  }, [modalTab]);

  useEffect(() => {
    if (isConnect === "success") {
      setTimeout(() => {
        setModalTab("connections");
      }, 1);
    }
  });

  return (
    <Row className="m-0 h-100">
      <Col md={12} className="p-0">
        {!isConnect && (
          <div className="text-center spinner_wrapper">
            <Spinner
              animation="border"
              role="status"
              className="spinner_loader"
            />
            <p className="loadertxt mt-2">Connecting...</p>
          </div>
        )}

        {isConnect === "success" && (
          <div className="text-center spinner_wrapper">
            <img src="/images/actioncenter/connected.svg" alt="" />
            <p className="mt-2">Connected</p>
          </div>
        )}

        {isConnect === "error" && (
          <div className="text-center spinner_wrapper">
            <img src="/images/actioncenter/redcross.svg" alt="" />
            <p className="mt-3 pl-2 pr-2">
              Failed to connect. Please try again or repeat later
            </p>
            <p className="mt-2 mb-1 loadertxt">Response:</p>
            <p className="loadertxt">Error 505 - Failed to connect</p>
            <button
              className="replotposture mt-2"
              onClick={() => setIsConnect("success")}
            >
              Try again
            </button>
          </div>
        )}
      </Col>
    </Row>
  );
};

export default Loader;
