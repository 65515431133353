import React, { useState } from "react";
import { ResponsiveContainer } from "recharts";

import { Line } from "react-chartjs-2";

const AreaCharts = (props) => {
  const hexRgb = require("hex-rgb");
  const [data] = useState(props.data || "");
  const [colour] = useState(props.color || "");
  const [background] = useState(props.background || "");
  const [hexConversion, setHexConversion] = useState("");
  const [height] = useState(props.height || "90%");
  const maxCount = props.maxCount || null;

  const mulitipleData = typeof data.values[0] == "object";

  if (colour && hexConversion == "")
    setHexConversion(hexRgb(colour, { format: "object" }));

  function generateDatasetArray(gradient) {
    var dataset = [];

    if (typeof data.values[0] == "object") {
      var dataMainArray = [];
      for (var i = 0; i < Object.keys(data.values).length; i++) {
        dataset.push(
          datasetObject(null, data.colours[i], data.values[i], `Test${i}`)
        );
      }
      console.log("dataMainArray", dataMainArray);
    } else {
      dataset.push(datasetObject(gradient, colour, data.values, `Test0`));
    }

    return dataset;
  }

  const datasetObject = (gradient, colour, values, name) => {
    return {
      label: name,
      fill: "end",
      backgroundColor: background
        ? background
        : gradient !== null
        ? gradient
        : "inherit",
      lineTension: 0.1,
      borderColor: colour,
      borderCapStyle: "butt",
      borderDash: [],
      borderDashOffset: 0.0,
      borderJoinStyle: "miter",
      pointBorderColor: colour,
      pointBackgroundColor: "#fff",
      pointBorderWidth: 1,
      pointHoverRadius: 5,
      pointHoverBackgroundColor: "rgba(75,192,192,1)",
      pointHoverBorderColor: "rgba(220,220,220,1)",
      pointHoverBorderWidth: 2,
      pointRadius: 1,
      pointHitRadius: 10,
      data: values,
      datalabels: {
        display: false,
      },
    };
  };

  const options = {
    legend: {
      display: false,
    },
    scales: {
      xAxes: [
        {
          gridLines: {
            display: false,
          },
          ticks: {
            fontColor: "#B6B6BF",
            autoSkip: true,
            maxTicksLimit: 10,
            maxRotation: 0,
            minRotation: 0,
          },
        },
      ],
      yAxes: [
        {
          gridLines: {
            display: false,
          },
          position: "right",
          ticks: {
            fontColor: "#B6B6BF",
            autoSkip: true,
            maxTicksLimit: 6,
            suggestedMax: maxCount || 50,
          },
        },
      ],
    },
    animation: {
      easing: "linear",
    },
    responsive: true,
    maintainAspectRatio: false,
  };
  const data2 = (canvas) => {
    const ctx = canvas.getContext("2d");
    const gradient = ctx.createLinearGradient(0, 0, 0, 700);
    gradient.addColorStop(
      0,
      hexConversion != ""
        ? `rgba(${hexConversion.red}, ${hexConversion.green}, ${hexConversion.blue}, 0)`
        : ""
    );
    gradient.addColorStop(
      1,
      hexConversion != ""
        ? `rgba(${hexConversion.red}, ${hexConversion.green}, ${hexConversion.blue}, 1)`
        : ""
    );

    if (typeof data.values[0] == "object") {
      var dataMainArray = [];
      for (var i = 0; i < Object.keys(data.values).length; i++) {
        dataMainArray.push(data.values[i]);
      }
      console.log("dataMainArray", dataMainArray);
    }

    return {
      labels: data.labels,
      options: {
        legend: {
          display: false,
        },
        scales: {
          xAxes: [
            {
              gridLines: {
                display: false,
              },
              ticks: {
                fontColor: "#B6B6BF",
              },
            },
          ],
          yAxes: [
            {
              gridLines: {
                display: false,
              },
              ticks: {
                fontColor: "#B6B6BF",
              },
            },
          ],
        },
        animation: {
          easing: "linear",
        },
      },

      datasets: generateDatasetArray(gradient),
    };
  };

  return (
    <div style={{ width: "100%", height: height, position: "relative" }}>
      <ResponsiveContainer className="pl-3 pr-3" width="100%" height="100%">
        <Line
          data={data2}
          legend={data2.legend}
          options={options}
          redraw={false}
        />
      </ResponsiveContainer>
      {mulitipleData ? (
        <React.Fragment>
          {/* <div className="canvas-lable" style={{ background: data.colours[0], bottom: calculateValueLabelPosition(data.values[0], data.values[0][data.values[0].length - 1]) }}>{data.values[0][data.values[0].length - 1]}%</div>
                    <div className="canvas-lable" style={{ background: data.colours[1], bottom: calculateValueLabelPosition(data.values[1], data.values[1][data.values[1].length - 1]) }}>{data.values[1][data.values[1].length - 1]}%</div> */}
        </React.Fragment>
      ) : (
        // <div className="canvas-lable" style={{ background: colour, bottom: calculateValueLabelPosition(data.values, data.values[data.values.length - 1]) }}>{data.values[data.values.length - 1]}%</div>
        ""
      )}
    </div>
  );
};

export default AreaCharts;
