import React, { useState, useEffect } from "react";
import { Button, Dropdown, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { useDb } from "../context/db";
import TicketsView from "../Support/TicketsView/TicketsView";
import ParentChildModal from "../ModalDynamic/ParentChildModal";
import IncidentResponseForm from "../IncidentResponseForm/IncidentResponseForm";
import CreditModal from "../ProServices/CreditModal";
import "./header-v2.scss";
import ModalDynamic from "../ModalDynamic/ModalDynamic";
import CreateTaskModal from "../PriceCalculator/modals/CreateTaskModal";
import { useDispatch, useSelector } from "react-redux";
import { TrimString } from "../../Utils/StringTrimmer";
import { TrackUser } from "../../Utils/SegmentFunctions";
import SupportTicketV2 from "../settingsV2/supportTicketV2/SupportTicketV2";
import RewardsDashboardDropdown from "../settingsV2/RewardsDashboardDropdown";

import { setactioncentredata } from "../../redux/actionCentre/actions";
import ImageWithInitials from "../../Utils/ImageWithInitials";
import { useAuth } from "../context/Auth";
import { getAPIData } from "../../Utils/commonApi";
import UpgradeToPremiumModal, { BasicChip, BasicSubscriptionDropDown } from "../Integrationsv2/UpgradeToPremiumModal";
import { setuser } from "../../redux/userState/actions";
import { UserSystemSettingsBlocked, accessAllowed, viewOnlyAccess } from "../App/App";
const Header = (props) => {
  const history = useHistory();
  const { dbObj } = useDb();

  const { setAuth,authTokens } = useAuth();
  const [supportCounter, setSupportCounter] = useState(0);
  const [showCreditModal, setShowCreditModal] = useState(false);
  const [supportTicketV2Modal, setSupportTicketV2Modal] = useState(false);
  const [subscriptionLoading, setSubscriptionLoading] = useState(false);
const [showUpgradeToPremiumModal, setShowUpgradeToPremiumModal] = useState(false)
  const [supportModalOpen, setSupportModalOpen] = useState(false);
  const [securityIncidentModal, setsecurityIncidentModal] = useState(false);
  const [upgradeOptions, setUpgradeOptions] = useState({})
  const userDetails = useSelector((state) => state?.user?.userDetail);
  const [allowDrillDownActionCentre, setAllowDrillDownActionCentre] = useState(true);
  const [allowDrillDownSecurityIncident, setAllowDrillDownSecurityIncident] = useState(true)
  const dispatch = useDispatch();
  useEffect(() => {
    if (supportCounter > 1) {
      setSupportModalOpen(false);
      setInterval(() => {
        setSupportModalOpen(true);
      }, 100);
    }
  }, [supportCounter]);
useEffect(() => {
  getSubscriptionData()
}, [])

 

  const hideSupportTicketV2Modal = () => {
    setSupportTicketV2Modal(false);
  };

  function setSupportState(event) {
    event.preventDefault();
    setSupportModalOpen(true);
  }
  const handleCreditModal = () => {
    setShowCreditModal(true);
  };
 
  async function getSubscriptionData(){
    setSubscriptionLoading(true)
    await getAPIData(503, authTokens).then((response)=>{
      setSubscriptionLoading(false)
      if(response?.Success){
 

        dispatch(
          setuser({
            AccessObjList: response?.AccessObjList,
          })
        );

        setAllowDrillDownActionCentre(response?.AccessObjList?.filter((item)=>item?.Question==="Action Centre Visibility")[0])
        setAllowDrillDownSecurityIncident(response?.AccessObjList?.filter((item)=>item?.Question==="Security Incident Reporting Visibility")[0])

      }

    }).catch((err)=>{
      setSubscriptionLoading(false)
      console.log(err)

    })
  }


  
  return (
    <div
      style={{
        display: "flex",
        pointerEvents: `${subscriptionLoading ? "none" : "all"}`,

        marginTop: props.margin ? props.margin : "",
        cursor: "pointer",
      }}
      className="header-v2"
    >
      <div>
        {props.title && (
          <h2>
            {props.title}
          </h2>
        )}
      </div>
      <div
        className="notificationIcon position-relative pointer d-none"
        // onClick={() => {
        //   handleCreditModal();
        //   TrackUser("Rewards button clicked");
        // }}
        style={{ marginRight: "12px" }}
      >
        <RewardsDashboardDropdown >
          {dbObj?.SubscriptionDetail &&
          dbObj?.SubscriptionDetail?.ActionsWithReward_Available ? (
            <img
              alt=""
              src="/images/activeNotification.svg"
              className="position-absolute gifts-icon"
              style={{ top: "5px", left: "27px" }}
            ></img>
          ) : (
            ""
          )}
          <a>
            <img alt="" src="/images/notificationIcon.svg"></img>
          </a>
        </RewardsDashboardDropdown>
      </div>

      {supportModalOpen ? (
        <ParentChildModal
          ParentComponent={<TicketsView />}
          ChildComponent={<IncidentResponseForm type={"support"} />}
          buttonText={"+ Create Ticket"}
          modalHeader={"Support Tickets"}
          childHeader={"Create Ticket"}
          setShow={true}
          showButton={true}
        />
      ) : (
        ""
      )}
      {showCreditModal ? (
        <CreditModal
          show={showCreditModal}
          closeModal={() => setShowCreditModal(false)}
          
        />
      ) : (
        ""
      )}

      <div className="d-flex align-items-center position-relative">
        
        {/* <img
          alt=""
          src="/images/settings-v2/create-task-icon.svg"
          className="mr-1 pointer"
          // onClick={() => history.push("action_centre")}
          onClick={() => {
            if(viewOnlyAccess("AccessState_ActionCentre")){

            }else if (accessAllowed("AccessState_ActionCentre") ) {
              if (allowDrillDownActionCentre?.Option === true) {
                dispatch(
                  setactioncentredata({
                    refreshCreateTaskModalModule: true,
                  })
                );
                const drag = document.getElementById("create-task-modal");
                // drag.style.transform = "translate(0px, 0px) ";
                drag.style.display = "block";
              } else {
                setUpgradeOptions(allowDrillDownActionCentre?.UpgradeOptions);
                setShowUpgradeToPremiumModal(true);
              }
            }
          }}
        /> */}
         <OverlayTrigger
          placement="bottom"
          trigger={
            viewOnlyAccess("AccessState_ActionCentre") || !accessAllowed("AccessState_ActionCentre")
              ? ["hover", "focus"]
              : []
          }
          delay={{ show: 200, hide: 250 }}
          overlay={
            <Tooltip id={`tooltip-1365`}>
              This feature is managed by your service provider
            </Tooltip>
          }
        >
        <img
          alt=""
          src={viewOnlyAccess("AccessState_ActionCentre") || !accessAllowed("AccessState_ActionCentre") ?'/images/settings-v2/locker-grey.svg' : "/images/settings-v2/create-task-icon.svg"}
          className=' mr-2'
          // onClick={() => history.push("action_centre")}
          onClick={() => {
            if(viewOnlyAccess("AccessState_ActionCentre")){

            }else if (accessAllowed("AccessState_ActionCentre") ) {
              if (allowDrillDownActionCentre?.Option === true) {
                dispatch(
                  setactioncentredata({
                    refreshCreateTaskModalModule: true,
                  })
                );
                const drag = document.getElementById("create-task-modal");
                // drag.style.transform = "translate(0px, 0px) ";
                drag.style.display = "block";
              } else {
                setUpgradeOptions(allowDrillDownActionCentre?.UpgradeOptions);
                setShowUpgradeToPremiumModal(true);
              }
            }
          }}
        />
        </OverlayTrigger>
        {/* <BasicSubscriptionDropDown disabled={allowDrillDownActionCentre}> */}
        <OverlayTrigger
          placement="bottom"
          trigger={
            !accessAllowed("AccessState_ActionCentre")
              ? ["hover", "focus"]
              : []
          }
          delay={{ show: 200, hide: 250 }}
          overlay={
            <Tooltip id={`tooltip-1365`}>
              This feature is managed by your service provider
            </Tooltip>
          }
        >
          <Button
            className={`hg-cancel-btn d-flex align-items-center ${!accessAllowed("AccessState_ActionCentre") && 'cursor-default'} `}
            onClick={() => {
              if (accessAllowed("AccessState_ActionCentre")) {
                history.push("/action_centre");
                if (allowDrillDownActionCentre) {
                }
              }
            }}
          >
            {
              !accessAllowed("AccessState_ActionCentre") &&
              <img
                alt=''
                className='lockerDarkGreyImage mr-2'
                src='/images/settings-v2/locker-grey.svg'
              />
            }
            Action Centre
            {/* {!allowDrillDownActionCentre && <BasicChip className="ml-1" />} */}
          </Button>
        </OverlayTrigger>
        {/* </BasicSubscriptionDropDown> */}
      </div>

      <div
        style={{ marginLeft: "0.3em", marginTop: "0.3em" }}
        className="sideBarDropdown supportDropdown"
      >
        <Dropdown
          onToggle={(isOpen) => {
            if (isOpen) {
              setsecurityIncidentModal(false);
            }
          }}
        >
          <Dropdown.Toggle className="test-userflow">
            <img alt="" src="/images/settings-v2/horizontal-dots.svg" />
          </Dropdown.Toggle>

          <Dropdown.Menu
          style={{
            width:"auto"
          }}
          
          >
            <Dropdown.Item
              href="https://kb.highground.io"
              target="_blank"
              onClick={() => TrackUser("Knowledge Base clicked")}
            >
              <img
                alt=""
                src="/images/settings-v2/knowledgebase.svg"
                className="mr-2 v2-item-color"
                width={16}
                height={17}
              />{" "}
              <span className="f-grey-imp">Knowledgebase</span>
            </Dropdown.Item>
            <Dropdown.Item
              type="button"
              onClick={(event) => {
                TrackUser("Support Ticket Clicked");
                // setSupportTicketV2Modal(true);
                window.Intercom('showNewMessage');
              }}
            >
              <img
                alt=""
                src="/images/settings-v2/contactsupport.svg"
                className="mr-2 v2-item-color"
                width={16}
                height={17}
              />{" "}
              <span className="f-grey-imp">Contact Support</span>
            </Dropdown.Item>
            <Dropdown.Item
              type="button"
              onClick={(event) => {
                // setSupportState(event);
                // setSupportCounter(supportCounter + 1);
                TrackUser("Join Slack community clicked");
                // window.open(
                //   "https://join.slack.com/t/highgroundio/shared_invite/zt-19tfj8ujn-_rHexr3mFsH~aI~q7Y47Sg",
                //   "_blank"
                // );
                window.open(
                  "https://chat.whatsapp.com/C0W3c8N0owfCQeof5auWjG",
                  "_blank"
                );
              }}
            >
              <img
                alt=""
                src="/images/settings-v2/askcommunity.svg"
                className="mr-2 v2-item-color"
                width={16}
                height={17}
              />{" "}
              <span className="f-grey-imp">Join our MSP Community</span>
            </Dropdown.Item>
            {/* <hr className="mb-1 m-0" />
            <Dropdown.Item
              onClick={() => {
                if (allowDrillDownSecurityIncident?.Option === true) {
                  setsecurityIncidentModal(true);
                } else {
                  setUpgradeOptions(
                    allowDrillDownSecurityIncident?.UpgradeOptions
                  );
                  setShowUpgradeToPremiumModal(true);
                }
              }}
            >
              <img
                alt=""
                src="/images/settings-v2/securityincident.svg"
                className="mr-2 security-incident"
                width={16}
                height={17}
              />
              <span
                className="f-grey-imp"
                style={{
                  marginLeft: "3px",
                }}
              >
                Security Incident?
              </span>
            </Dropdown.Item> */}
            <hr className="mt-1 m-0 mb-1" />
            <Dropdown.Item
              disabled={UserSystemSettingsBlocked()}
              className=""
              onClick={() => {
                history.push("/settings/v2/company-settings");
              }}
            >
              <img
                alt=""
                src={
                  UserSystemSettingsBlocked()
                    ? "/images/msp/lock-icon.svg"
                    : "/images/settings-v2/settingwheel.svg"
                }
                className="mr-2 v2-item-color"
                width={16}
                height={17}
              />
              <span
                className="f-grey-imp"
                style={{
                  marginLeft: "3px",
                }}
              >
                System Settings
              </span>
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>

      {securityIncidentModal ? (
        <ModalDynamic
          setShow={true}
          modalData={<IncidentResponseForm  />}
          modalHeader={"Report an Incident"}
          
        />
      ) : (
        ""
      )}

      {/* User logo with drop down */}

      <div
        style={{ marginLeft: "" }}
        className="sideBarDropdown supportDropdown  header-logo-dropdown"
      >
        <Dropdown>
          <Dropdown.Toggle>
            {userDetails.Avatar == "No URL" ? (
              <ImageWithInitials
                width={31}
                background="purple"
                initials={userDetails?.Initials}
              />
            ) : (
              <img alt="" src={userDetails?.Avatar} className="thumbnail-pic" />
            )}
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item disabled={true}>
              <div className="d-flex justify-content-between align-items-center ">
                <div className="d-flex align-items-center">
                  {userDetails.Avatar == "No URL" ? (
                    <ImageWithInitials
                      width={31}
                      background="purple"
                      initials={userDetails?.Initials}
                    />
                  ) : (
                    <img
                      alt=""
                      src={userDetails?.Avatar}
                      className="thumbnail-pic"
                    />
                  )}

                  <div className="ml-2">
                    <p className="title m-0">
                      {TrimString(userDetails.FullName, 17)}
                    </p>
                    <p className="subtitle m-0">
                      {TrimString(userDetails.UserName, 17)}
                    </p>
                  </div>
                </div>
                <div>
                  {/* <img alt="" src="/images/chevron-right.svg" /> */}
                </div>
              </div>
            </Dropdown.Item>
            <hr className="m-0" />
            <Dropdown.Item
              type="button"
              className="mt-1 mb-1"
              onClick={() => history.push("/settings/v2/my_profile")}
            >
              <img
                alt=""
                src="/images/settings-v2/settingwheel.svg"
                className="mr-2 v2-item-color"
              />
              <span className="f-grey-imp" style={{ paddingTop: "2px" }}>
                {" "}
                Personal Settings
              </span>
            </Dropdown.Item>
            <Dropdown.Item
              type="button"
              onClick={() => {
                TrackUser("User Signed out");

                window.localStorage.clear();
                history.push({
                  pathname: "/login",
                });
                setAuth(false);
              }}
            >
              <img
                alt=""
                src="/images/settings-v2/logout.svg"
                className="mr-2 v2-item-color"
              />
              <span className="f-grey-imp"> Logout</span>
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
      <CreateTaskModal />
      <SupportTicketV2
        show={supportTicketV2Modal}
        hideModal={hideSupportTicketV2Modal}
        
      />
      <UpgradeToPremiumModal
        show={showUpgradeToPremiumModal}
        hideModal={() => setShowUpgradeToPremiumModal(false)}
        upgradeOptions={upgradeOptions}
      />
    </div>
  );
};

export default Header;
