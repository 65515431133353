import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import "./mspCalculatorDropdownAndModals.scss";

import Loader from "../../../../Common/loader";

const MspFeaturesModal = ({
  show,
  hideModal,
  packageId,
  identify,
  protect,
  detect,
  respond,
  recover,
}) => {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (show) {
    }
  }, [show]);

  return (
    <Modal
      animation={false}
      show={show}
      dialogClassName={`msp-features-modal`}
      className={`msp-features-modal`}
      aria-labelledby="msp-features-modal"
      centered
      scrollable
      onHide={hideModal}
    >
      <Modal.Header className="d-flex align-items-center p-2">
        <div className=" text-align-center w-100 mb-0 f-600 f-black ml-3">
          Features
        </div>

        <div className="d-flex align-items-center">
          <span
            className="cursor-pointer d-inline-flex mx-2"
            onClick={hideModal}
          >
            <img
              alt=""
              width={11}
              height={11}
              src="/images/icons/close.svg"
              className="close-icon"
            />
          </span>
        </div>
      </Modal.Header>
      <Modal.Body className="hide-scrollbar px-3 py-0">
        {loading ? (
          <div
            className="d-flex align-items-center justify-content-center w-100 "
            style={{
              height: "85vh",
            }}
          >
            <Loader />
          </div>
        ) : (
          <>
            <div className="d-flex flex-column w-100">
              <div className="border-bottom p-4">
                <div className="f-darkgrey f-12 f-500 mb-2">Identify</div>

                <div>
                  {identify?.map((iden) => {
                    return (
                      <>
                        <div className="">{iden?.Headline}</div>
                        {iden?.SecurityPack_Bullet_List?.length > 0 ? (
                          <>
                            {iden?.SecurityPack_Bullet_List?.map((item) => {
                              return (
                                <div className="d-flex align-items-center my-2 f-12">
                                  <img
                                    alt=""
                                    className="ml-1 mr-2"
                                    src="/images/msp/grey-circular-tick.svg"
                                  />
                                  {item?.Text}
                                </div>
                              );
                            })}
                          </>
                        ) : (
                          <div className="d-flex align-items-center my-2 f-grey f-12">
                            <img
                              alt=""
                              className="ml-1 mr-2"
                              src="/images/msp/cross-circular-tick.svg"
                            />{" "}
                            This service has no features
                          </div>
                        )}
                      </>
                    );
                  })}
                  {!identify ||
                    (identify?.length == 0 && (
                      <div className="f-darkgrey ">No services</div>
                    ))}
                </div>
              </div>
              <div className="border-bottom p-4">
                <div className="f-darkgrey f-12 f-500 mb-2">Protect</div>

                <div>
                  {protect?.map((iden) => {
                    return (
                      <>
                        <div className="">{iden?.Headline}</div>
                        {iden?.SecurityPack_Bullet_List?.length > 0 ? (
                          <>
                            {iden?.SecurityPack_Bullet_List?.map((item) => {
                              return (
                                <div className="d-flex align-items-center my-2 f-grey f-12">
                                  <img
                                    alt=""
                                    className="ml-1 mr-2"
                                    src="/images/msp/grey-circular-tick.svg"
                                  />
                                  {item?.Text}
                                </div>
                              );
                            })}
                          </>
                        ) : (
                          <div className="d-flex align-items-center my-2 f-grey f-12">
                            <img
                              alt=""
                              className="ml-1 mr-2"
                              src="/images/msp/cross-circular-tick.svg"
                            />{" "}
                            This service has no features
                          </div>
                        )}
                      </>
                    );
                  })}
                  {!protect ||
                    (protect?.length == 0 && (
                      <div className="f-darkgrey ">No services</div>
                    ))}
                </div>
              </div>
              <div className="border-bottom p-4">
                <div className="f-darkgrey f-12 f-500 mb-2">Detect</div>

                <div>
                  {detect?.map((iden) => {
                    return (
                      <>
                        <div className="">{iden?.Headline}</div>
                        {iden?.SecurityPack_Bullet_List?.length > 0 ? (
                          <>
                            {iden?.SecurityPack_Bullet_List?.map((item) => {
                              return (
                                <div className="d-flex align-items-center my-2 f-grey f-12">
                                  <img
                                    alt=""
                                    className="ml-1 mr-2"
                                    src="/images/msp/grey-circular-tick.svg"
                                  />
                                  {item?.Text}
                                </div>
                              );
                            })}
                          </>
                        ) : (
                          <div className="d-flex align-items-center my-2 f-grey f-12">
                            <img
                              alt=""
                              className="ml-1 mr-2"
                              src="/images/msp/cross-circular-tick.svg"
                            />{" "}
                            This service has no features
                          </div>
                        )}
                      </>
                    );
                  })}
                  {!detect ||
                    (detect?.length == 0 && (
                      <div className="f-darkgrey ">No services</div>
                    ))}
                </div>
              </div>
              <div className="border-bottom p-4">
                <div className="f-darkgrey f-12 f-500 mb-2">Respond</div>

                <div>
                  {respond?.map((iden) => {
                    return (
                      <>
                        <div className="">{iden?.Headline}</div>
                        {iden?.SecurityPack_Bullet_List?.length > 0 ? (
                          <>
                            {iden?.SecurityPack_Bullet_List?.map((item) => {
                              return (
                                <div className="d-flex align-items-center my-2 f-grey f-12">
                                  <img
                                    alt=""
                                    className="ml-1 mr-2"
                                    src="/images/msp/grey-circular-tick.svg"
                                  />
                                  {item?.Text}
                                </div>
                              );
                            })}
                          </>
                        ) : (
                          <div className="d-flex align-items-center my-2 f-grey f-12">
                            <img
                              alt=""
                              className="ml-1 mr-2"
                              src="/images/msp/cross-circular-tick.svg"
                            />{" "}
                            This service has no features
                          </div>
                        )}
                      </>
                    );
                  })}
                  {!respond ||
                    (respond?.length == 0 && (
                      <div className="f-darkgrey ">No services</div>
                    ))}
                </div>
              </div>
              <div className="border-bottom p-4">
                <div className="f-darkgrey f-12 f-500 mb-2">Recover</div>

                <div>
                  {recover?.map((iden) => {
                    return (
                      <>
                        <div className="">{iden?.Headline}</div>
                        {iden?.SecurityPack_Bullet_List?.length > 0 ? (
                          <>
                            {iden?.SecurityPack_Bullet_List?.map((item) => {
                              return (
                                <div className="d-flex align-items-center my-2 f-grey f-12">
                                  <img
                                    alt=""
                                    className="ml-1 mr-2"
                                    src="/images/msp/grey-circular-tick.svg"
                                  />
                                  {item?.Text}
                                </div>
                              );
                            })}
                          </>
                        ) : (
                          <div className="d-flex align-items-center my-2 f-grey f-12">
                            <img
                              alt=""
                              className="ml-1 mr-2"
                              src="/images/msp/cross-circular-tick.svg"
                            />{" "}
                            This service has no features
                          </div>
                        )}
                      </>
                    );
                  })}
                  {!recover ||
                    (recover?.length == 0 && (
                      <div className="f-darkgrey ">No services</div>
                    ))}
                </div>
              </div>
            </div>
          </>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default MspFeaturesModal;
