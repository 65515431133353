import { useState } from 'react';
import './note-section.styles.scss';
import ImageLoader from "../../../Common/ImageLoader";
import ImageWithInitials from "../../../../Utils/ImageWithInitials";
import ResizerIcon from '../../../icons/resizer.icon';
import useResize from '../../../../hooks/resize.hook';

const mockupUser = {
  initials: 'ML',
  fullName: 'Mark Lamb'
};

const NoteSection = ({ onResize }) => {
  const [noteValue, setNoteValue] = useState('');
  const textareaRef = useResize({ 
    onResize
  });
  
  return (
    <div className="note-section">
      <h3 className='f-12 f-400-imp f-grey'>Note</h3>
      <div className='position-relative'>
        <textarea
          ref={textareaRef} 
          value={noteValue} 
          placeholder="Add note" 
          onChange={e => {
            setNoteValue(e.target.value);
            onResize(e.target);
          }} 
        />
        <ResizerIcon className="resizer" />
      </div>
      {noteValue && (
        <div className='last-updated d-flex align-items-center'>
          <span>Last updated by </span>
          <div className='user-logo'>
            <ImageLoader 
              // src={userLogo}
              alt=""
              className="ml-2 mr-1 rounded-full"
              width={16}
              height={16}
              noImageComponent={(
                <div className="ml-2 mr-1 rounded-full">
                  <ImageWithInitials
                    initials={mockupUser.initials}
                    width={16}
                    height={16}
                    background={"purple"}
                  />
                </div>
              )}
            />
          </div>
          <span className='mr-2 f-500'>{mockupUser.fullName}</span>
          <span>at</span>
          <span className='ml-2'>20 Jul 2024</span>
          <span className='ml-2'>18:29</span>
        </div>
      )}
    </div>
  );
}

export default NoteSection;
