import React, { useEffect, useState } from "react";
import { Dropdown, Form } from "react-bootstrap";
import "./mspIntegrations.scss";
import { TrimString } from "../../../Utils/StringTrimmer";
import ImportedClientDetailModal from "./ImportedClientDetailModal";
import { GreyLoader } from "../../Theme/APILoaders";
import { MapClientsBlock } from "./MapClients";
import { MapContactsBlock } from "./MapContacts";
import { MapCategoryBlock, MapProductBlock } from "./MapProducts";
import { MapMembersBlock } from "./MapMembers";
import ContentLoader from "react-content-loader";
const MapSummary = ({
  //FOR CLIENTS
  getClientsListData,
  clientPageData,
  setClientPageData,
  clientsMappingData,
  getClientsMappingListData,
  updateClientData,
  updateImportStatus,
  // FOR CONTACTS
  getContactsListData,
  contactPageData,
  setContactPageData,
  updateContactData,

  // FOR PRODUCTS AND CATEGORIES
  productCategoryPageData,
  setProductCategoryPageData,
  getproductCategoryListData,
  productCategoryMappingData,
  setProductCategoryMappingData,
  getProductsCategoryMappingListData,
  updateProductData,
  updateCategoryData,

  // FOR MEMBERS
  memberPageData,
  setMemberPageData,
  getMembersListData,
  membersMappingData,
  getMembersMappingListData,
  updateMemberData,
}) => {
  const [fetchingNewClients, setFetchingNewClients] = useState(false);
  const [fetchingNewContacts, setFetchingNewContacts] = useState(false);
  const [fetchingNewMembers, setFetchingNewMembers] = useState(false);
  const [fetchingNewproductCategory, setFetchingNewproductCategory] =
    useState(false);

  const [loading, setLoading] = useState(false);
  useEffect(() => {
    // if (clientPageData?.ObjectList?.length > 0) {
    // } else {
    setLoading(true);
    Promise.all([
      // FOR CLIENTS
      getClientsListData({
        NextPageNo: 1,
        searchString: "",
        hideIgnored: 1,
      }),
      getClientsMappingListData({
        NextPageNo: 1,
        searchString: "",
      }),
      // FOR CONTACTS
      getContactsListData({
        NextPageNo: 1,
        searchString: "",
        hideIgnored: 1,
      }),

      // FOR PRODUCTS AND CATEGORIES
      getproductCategoryListData({
        NextPageNo: 1,
        searchString: "",
        hideIgnored: 1,
        itemType: 2,
      }),
      getProductsCategoryMappingListData({
        NextPageNo: 1,
        searchString: "",
        itemType: 2,
      }),

      // FOR MEMBERS
      getMembersListData({
        NextPageNo: 1,
        searchString: "",
        hideIgnored: 1,
      }),
      getMembersMappingListData({
        NextPageNo: 1,
        searchString: "",
      }),
    ])
      .then(() => {
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
    // }
  }, []);

  

  return (
    <>
      {loading ? (
        <MapSummaryLoader />
      ) : (
        <div
          id="mapped-client-inegtration-modal-parent"
          className="map-clients-screen "
        >
          <div className="d-flex align-items-center pt-4 mb-4">
            <img
              alt=""
              src="/images/msp/import-clients-icon.svg"
              className="mr-3"
            />
            <div>
              <p className="f-black f-600 f-15 m-0">
                Summary of Import & Mapping from Connectwise Manage
              </p>
              <p className="f-grey f-14">
                Check the data and import to HighGround
              </p>
            </div>
          </div>

          <div className="d-flex align-items-stretch justify-content-between w-100 bg-grey radius-8 border-parent-right">
            <div className="border-item" style={{ flex: "1 1 0" }}>
              <div className="my-3 pl-3">
                <p className="f-12 f-500 f-grey">Clients</p>
                <p className="f-18 f-500">{clientPageData?.TotalCount}</p>
              </div>
            </div>
            <div className="border-item" style={{ flex: "1 1 0" }}>
              <div className="my-3 pl-3">
                <p className="f-12 f-500 f-grey">Contact Types</p>
                <p className="f-18 f-500">{contactPageData?.TotalCount}</p>
              </div>
            </div>
            <div className="border-item" style={{ flex: "1 1 0" }}>
              <div className="my-3 pl-3">
                <p className="f-12 f-500 f-grey">Product & Categories</p>
                <p className="f-18 f-500">{productCategoryPageData?.TotalCount}</p>
              </div>
            </div>
            <div className="border-item" style={{ flex: "1 1 0" }}>
              <div className="my-3 pl-3">
                <p className="f-12 f-500 f-grey">Members</p>
                <p className="f-18 f-500">{memberPageData?.TotalCount}</p>
              </div>
            </div>
          </div>

          {/* Clients Section */}
          {clientPageData?.ObjectList?.length > 0 && (
            <>
              <div className="f-16 f-600 mt-4 mb-2">Clients</div>
              <div className="border-parent bg-grey radius-8">
                <MapClientsBlock
                  clientPageData={clientPageData}
                  setClientPageData={setClientPageData}
                  clientsMappingData={clientsMappingData}
                  getClientsMappingListData={getClientsMappingListData}
                  clientSearchString={""}
                  updateClientData={updateClientData}
                />
                {clientPageData?.NextPage === true && (
                  <div
                    className="border-item f-500 f-grey p-2 px-3 pointer"
                    onClick={() => {
                      setFetchingNewClients(true);
                      getClientsListData({
                        NextPageNo: null,
                        searchString: "",
                        hideIgnored: 1,
                      }).then(() => {
                        setFetchingNewClients(false);
                      });
                    }}
                  >
                    {fetchingNewClients ? (
                      <>
                        {" "}
                        <GreyLoader width={12} /> Loading...
                      </>
                    ) : (
                      <>
                        <img
                          alt=""
                          src="/images/msp/msp-arrow-down.svg"
                          className="mx-2"
                        />
                        Load more
                      </>
                    )}
                  </div>
                )}
              </div>
            </>
          )}

          {/* Contacts Section */}
          {contactPageData?.ObjectList?.length > 0 && (
            <>
              <div className="f-16 f-600 mt-4 mb-2">Contact Types</div>
              <div className="border-parent bg-grey radius-8">
                <MapContactsBlock
                  getContactsListData={getContactsListData}
                  contactPageData={contactPageData}
                  setContactPageData={setContactPageData}
                  updateContactData={updateContactData}
                  contactSearchString={""}
                />
                {contactPageData?.NextPage === true && (
                  <div
                    className="border-item f-500 f-grey p-2 px-3 pointer"
                    onClick={() => {
                      setFetchingNewContacts(true);
                      getContactsListData({
                        NextPageNo: null,
                        searchString: "",
                        hideIgnored: 1,
                      }).then(() => {
                        setFetchingNewContacts(false);
                      });
                    }}
                  >
                    {fetchingNewContacts ? (
                      <>
                        {" "}
                        <GreyLoader width={12} /> Loading...
                      </>
                    ) : (
                      <>
                        <img
                          alt=""
                          src="/images/msp/msp-arrow-down.svg"
                          className="mx-2"
                        />
                        Load more
                      </>
                    )}
                  </div>
                )}
              </div>
            </>
          )}

          {/* Products Section */}

   { productCategoryPageData?.ObjectList?.length >0 &&      <>
          <div className="f-16 f-600 mt-4 mb-2">Product & Categories</div>
          <div className="border-parent bg-grey radius-8">
            {productCategoryPageData?.ImportByProducts ? (
              <MapProductBlock
                productCategoryPageData={productCategoryPageData}
                setProductCategoryPageData={setProductCategoryPageData}
                productCategoryMappingData={productCategoryMappingData}
                getProductsCategoryMappingListData={
                  getProductsCategoryMappingListData
                }
                updateProductData={updateProductData}
              />
            ) : (
              <MapCategoryBlock
                productCategoryPageData={productCategoryPageData}
                setProductCategoryPageData={setProductCategoryPageData}
                productCategoryMappingData={productCategoryMappingData}
                getProductsCategoryMappingListData={
                  getProductsCategoryMappingListData
                }
                updateCategoryData={updateCategoryData}
              />
            )}
            {productCategoryPageData?.NextPage === true && (
              <div
                className="border-item f-500 f-grey p-2 px-3 pointer"
                onClick={() => {
                  setFetchingNewproductCategory(true);
                  getproductCategoryListData({
                    NextPageNo: null,
                    searchString: "",
                    hideIgnored: 1,
                    itemType: 2,
                  }).then(() => {
                    setFetchingNewproductCategory(false);
                  });
                }}
              >
                {fetchingNewproductCategory ? (
                  <>
                    {" "}
                    <GreyLoader width={12} /> Loading...
                  </>
                ) : (
                  <>
                    <img
                      alt=""
                      src="/images/msp/msp-arrow-down.svg"
                      className="mx-2"
                    />
                    Load more
                  </>
                )}
              </div>
            )}
          </div>
</>}
          {/* MEMBERS Section */}
          {memberPageData?.ObjectList?.length > 0 && (
            <>
              <div className="f-16 f-600 mt-4 mb-2">Members</div>
              <div className="border-parent bg-grey radius-8">
                <MapMembersBlock
                  memberPageData={memberPageData}
                  setMemberPageData={setMemberPageData}
                  getMembersListData={getMembersListData}
                  membersMappingData={membersMappingData}
                  getMembersMappingListData={getMembersMappingListData}
                  updateMemberData={updateMemberData}
                  memberSearchString={""}
                />
                {memberPageData?.NextPage === true && (
                  <div
                    className="border-item f-500 f-grey p-2 px-3 pointer"
                    onClick={() => {
                      setFetchingNewMembers(true);
                      getMembersListData({
                        NextPageNo: null,
                        searchString: "",
                        hideIgnored: 1,
                      }).then(() => {
                        setFetchingNewMembers(false);
                      });
                    }}
                  >
                    {fetchingNewMembers ? (
                      <>
                        {" "}
                        <GreyLoader width={12} /> Loading...
                      </>
                    ) : (
                      <>
                        <img
                          alt=""
                          src="/images/msp/msp-arrow-down.svg"
                          className="mx-2"
                        />
                        Load more
                      </>
                    )}
                  </div>
                )}
              </div>
            </>
          )}

          <div className="p-3"></div>
        </div>
      )}
    </>
  );
};

export default MapSummary;
//

const MapSummaryLoader = () => {
  return (
    <ContentLoader
      speed={2}
      width={"100%"}
      height={"100%"}
      viewBox="0 0 1500 900"
      backgroundColor="#F6F8FB"
      foregroundColor="#ecebeb"
    >
      <rect x="27%" y="20" rx="8" ry="8" width="4.5%" height="60" />
      <rect x="33%" y="22" rx="6" ry="6" width="10%" height="20" />
      <rect x="33%" y="52" rx="6" ry="6" width="25%" height="20" />

      <rect x="27%" y="100" rx="7" ry="7" width="45%" height="60" />

      <rect x="27%" y="200" rx="4" ry="4" width="6%" height="25" />
      <rect x="62%" y="200" rx="4" ry="4" width="10%" height="25" />

      <rect x="27%" y="230" rx="4" ry="4" width="45%" height="30" />

      <rect x="27%" y="262" rx="4" ry="4" width="45%" height="60" />
      <rect x="27%" y="324" rx="4" ry="4" width="45%" height="60" />
      <rect x="27%" y="386" rx="4" ry="4" width="45%" height="60" />
      <rect x="27%" y="448" rx="4" ry="4" width="45%" height="60" />
      <rect x="27%" y="510" rx="4" ry="4" width="45%" height="60" />
      <rect x="27%" y="572" rx="4" ry="4" width="45%" height="60" />
      <rect x="27%" y="634" rx="4" ry="4" width="45%" height="60" />
    </ContentLoader>
  );
};
