import React, { useState, useRef, lazy, Suspense } from "react";
import { useEffect } from "react";
import {
  Col,
  Row,
  Tab,
  Nav,
  Dropdown,
  Button,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import ContentLoader from "react-content-loader";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { getAPIData, postData } from "../../Utils/commonApi";
import AttackSurfaceTooltip from "../AttackSurface/AssetsList/AttackSurfaceTooltip";
import { useAuth } from "../context/Auth";
import Header from "../Header";
import UpgradeToPremiumModal from "../Integrationsv2/UpgradeToPremiumModal";
import {
  CallToast,
  CustomRightSideToastForReport,
} from "../PriceCalculator/modals/CustomToast";
import SupplySmallChatModal from "../settingsV2/supportTicketV2/SupplySmallChatModal";
import AccessLevelModal from "./AccessLevelModal/AccessLevelModal";
import DropDownFilter from "./filterDropDown/dropdown";
import QuestionAnswers from "./QuestionnaireTab/QuestionAnswers";
import QuestionnaireBigModal from "./QuestionnaireTab/QuestionnaireBigModal";
import QuestionnaireTab from "./QuestionnaireTab/QuestionnaireTab";
import ReceivedQuestionAnswers from "./QuestionnaireTab/ReceivedQuestionAnswers";
import SendQuestionnaireModal from "./QuestionnaireTab/SendQuestionnaireModal";
import ClientsModal from "./RequestsModal/ClientsModal";
// import RequestsModal from "./RequestsModal/Index";
import SuppliersAndComplianceProfilesTabs from "./SuppliersAndComplianceProfiles/Index";
import SupplierTable from "./SuppliersAndComplianceProfiles/SupplierTable";
import "./supplyChainMonitoring.scss";
import { downloadPDF } from "./SupplyCompany/SupplyCompany";
import UploadTemplateModal from "../supplierProfiler/uploadTemplateModal/UploadTemplateModal";
import AddSupplierModal from "../AttackSurface/Suppliers/AddSupplierModal";
import RiskMatrix from "../supplierProfiler/riskMatrix/RiskMatrix";
import ProfilingTab from "./profilingTab/ProfilingTab";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import ProfilingModal from "../supplierProfiler/ProfilingModal";
import ChooseSupplierModal from "../supplierProfiler/ChooseSupplierModal";
import { NoAccessUI, viewOnlyAccess } from "../App/App";
import ComingSoonDropdown from "./ComingSoonDropdown";
import { getMenuItemId } from "../SideNav/SideNav";

const RequestsModal = lazy(() => import("./RequestsModal/Index"));

const SupplyChainMonitoring = () => {
  const { authTokens, setAuth } = useAuth();
  const SCMFilterDropdown = useRef(null);
  const history = useHistory();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const selectedTab = searchParams.get("selectedTab");

  const [supplierSearchText, setSupplierSearchText] = useState("");
  const [upgradeOptions, setUpgradeOptions] = useState({});
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);
  const [defaultSelectedTab, setDefaultSelectedTab] = useState(
    selectedTab ?? "suppliers"
  );
  const [allowDrillDownQuestionnaire, setAllowDrillDownQuestionnaire] =
    useState({});
  const [questionnaireTabSelectedTab, setQuestionnaireTabSelectedTab] =
    useState("Received");
  const [profilingTabSelectedTab, setProfilingTabSelectedTab] = useState(
    location?.state?.isSent ? "Sent" : "Received"
  );
  const userEmail = useSelector((state) => state.user?.userDetail?.UserName);
  const [showPotentialSupplierPage, setShowPotentialSupplierPage] =
    useState(false);
  const [supplierComplainceSelectedTab, setSupplierComplainceSelectedTab] =
    useState("suppliers"); //suppliers//complianceProfiles

  const menuItemId = getMenuItemId(window.location.pathname)
  const MenuItem_List = window.localStorage.getItem("object_Data")
    ? JSON.parse(window.localStorage.getItem("object_Data"))?.MenuVisbility?.MenuItem_List : null;
  const menuItemData = MenuItem_List.find(obj => obj?.MenuItemId === parseInt(menuItemId));
  const userPortalRole = window.localStorage.getItem("object_Data")
  ? JSON.parse(window.localStorage.getItem("object_Data"))?.UserDetail?.Role : null;
  
  useEffect(() => {
    // Functionality to direct to specific
    let actionType = new URLSearchParams(window.location.search).get(
      "actionType"
    );
    let emailto = new URLSearchParams(window.location.search).get("emailto");
    let sentReceivedQuestionnaireId = new URLSearchParams(
      window.location.search
    ).get("sentReceivedQuestionnaireId");
    if (sentReceivedQuestionnaireId) {
      setTimeout(() => {
        let QuestionnaireTab = document.querySelector(
          "#questionnaire-tabMenu a"
        );
        if (QuestionnaireTab) QuestionnaireTab.click();
        // new URLSearchParams(window.location.search).delete("actionType");
        // window.history.pushState({}, "", `/supply?sentReceivedQuestionnaireId=${""}`);
        setPage({
          questionnaireData: {},
          type: "questionAnswers",
          name: ` `,
          QuestionType: "received",
          isSupplierPage: false,
        });
      }, 1000);
    }

    // if (actionType === "received") {
    //   if (userEmail === emailto) {
    //     setTimeout(() => {
    //       let QuestionnaireTab = document.querySelector(
    //         "#questionnaire-tabMenu a"
    //       );
    //       if (QuestionnaireTab) QuestionnaireTab.click();
    //       new URLSearchParams(window.location.search).delete("actionType");
    //       window.history.pushState({}, "", "/supply");

    //     }, 1000);
    //   } else {
    //     // window.localStorage.setItem("tokens", JSON.stringify("xxx"));
    //     // window.localStorage.setItem("user_MessageObj", JSON.stringify("xxx"));
    //     if (!window.localStorage.getItem("tokens")) return;
    //     history.replace({
    //       pathname: "/login",
    //       state: {
    //         redirectUser: true,
    //         redirectUrl: `${window.location.pathname}${window.location.search}`,
    //       },
    //     });
    //     setAuth(false);
    //   }
    // }
  }, []);

  const questionnaireTab = useRef(null);
  const profilingTab = useRef(null);
  const [page, setPage] = useState({
    type: "home",
    name: "",
    data: [],
    questionnaireData: {},
  });
  const [showRequestsModal, setShowRequestsModal] = useState(false);
  const [showAccessLevelModal, setShowAccessLevelModal] = useState(false);
  const [smallChatModal, setSmallChatModal] = useState(false);
  const isMenuCollapse = useSelector((state) => state?.global?.menuCollapse);
  const [isQuestionnaireTab, setIsQuestionnaireTab] = useState(false);
  const [showRiskMatrixModal, setShowRiskMatrixModal] = useState(false);
  const [showSendQuestionnaireModal, setShowSendQuestionnaireModal] =
    useState(false);
  const [showClientsModal, setShowClientsModal] = useState(false);
  const userDetails = useSelector((state) => state.user?.userDetail);
  const [showUploadFileModal, setShowUploadFileModal] = useState(false);
  const [showAddSupplierModal, setShowAddSupplierModal] = useState(false);
  const [showProfilingModal, setShowProfilingModal] = useState(false);
  const [showSupplierModal, setShowSupplierModal] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState([]);
  const [titleTemplate, setTitleTemplate] = useState([]);

  //questionnaires
  //suppliers

  // API SETUP====
  const [mainPageLoading, setMainPageLoading] = useState(true);
  const [pageData, setPageData] = useState({});

  const [complianceProfilesList, setComplianceProfilesList] = useState([]);
  const [showUpgrade, setShowUpgrade] = useState(false);

  const [showQuestionnaireModal, setShowQuestionnaireModal] = useState(false);

  // Supplier Data for filter, pagination, sorting
  const [supplierNextPage, setSupplierNextPage] = useState(false);
  const [totalSupplierCount, setTotalSupplierCount] = useState("");
  const [supplierList, setSupplierList] = useState([]);
  const filtersInlocalStorage = window.localStorage.getItem("supplier-filters");
  const supplierFilter = {
    CyberScore: "",
    CyberThreat: "",
    CyberCompliance: "",
    SupplierRisk: "",
    SupplierName: "",
    SupplierStatus: "",
    SupplierAnnualSpend: "",
    SupplierImportance: "",
    SupplierIndustry: "",
    SupplierServicesProvided: "",
    SupplierType: "",
    ComplianceProfile_List: "",
  };
  const [supplierFilters, setSupplierFilters] = useState(
    filtersInlocalStorage || JSON.stringify(supplierFilter)
  );
  const [sortItem, setSortItem] = useState({
    type: "Supplier Name",
    order: 0,
  });
  const [pageNumber, setPageNumber] = useState(5);
  const [getSupplierData, setGetSupplierData] = useState(false);
  const [nextPageLoading, setNextPageLoading] = useState(false);
  const [columnSortingLoading, setColumnSortingLoading] = useState(false);
  const [openSupplierDetailScreen, setOpenSupplierDetailScreen] =
    useState(null);
  const [potentialSupplierList, setPotentialSupplierList] = useState([]);
  function SortSupplierColumn(type) {
    setColumnSortingLoading(true);
    setSortItem((prevSort) => ({
      type: type,
      order: Number(!prevSort.order),
    }));
  }
  function openSupplierDetailScreenInRequestsModal(
    ObjIdCustomer,
    relIdRequest,
    objId_SendFormQuestionnaire
  ) {
    setOpenSupplierDetailScreen({
      ObjId_Customer: ObjIdCustomer,
      relId_Request: relIdRequest,
      objId_SendFormQuestionnaire: objId_SendFormQuestionnaire,
    });
    setShowRequestsModal(true);
    setTimeout(() => {
      setOpenSupplierDetailScreen();
    }, 2000);
  }

  function addQueryParamsToURL(key, value) {
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has(key)) {
      urlParams.delete(key);
    }

    urlParams.set(key, value);

    const updatedURL = `${window.location.pathname}?${urlParams.toString()}`;

    history.push(updatedURL);
  }

  async function getPotentialSuppliers(filter = 0) {
    await getAPIData(`${452}`, authTokens, {
      optionEnum1: filter,
    }).then((response) => {
      if (response?.mr?.Success) {
        setPotentialSupplierList(response?.PotentialSupplierDetail_List ?? []);
      }
    });
  }

  function openPotentialSupplierModal() {
    setShowRequestsModal(true);
    setShowPotentialSupplierPage(true);
    setTimeout(() => {
      setShowPotentialSupplierPage(false);
    }, 2000);
  }

  function getSupplierList() {
    setGetSupplierData(!getSupplierData);
  }
  function getNextPage() {
    setNextPageLoading(true);
    setPageNumber(pageNumber + 5);
  }

  function openRequestsModal() {
    setShowRequestsModal(true);
  }

  useEffect(() => {
    if (defaultSelectedTab == "suppliers") {
      setMainPageLoading(true);

      getComplianceProfiles();
      getPotentialSuppliers();
    }
  }, [defaultSelectedTab]);

  useEffect(() => {
    if (defaultSelectedTab == "suppliers") {
      postData(authTokens, "GetSupplierList", {
        supplierListFilters: supplierFilters,
        sortBy: sortItem.type,
        sortDir: sortItem.order,
        pageNumber: 1,
        pageSize: pageNumber,
      }).then((response) => {
        setMainPageLoading(false);
        setNextPageLoading(false);
        setColumnSortingLoading(false);
        if (response?.mr?.Success) {
          setUpgradeOptions(response?.UpgradeBoxOptions);
          setPageData(response);
          setSupplierList(response?.SupplierList);
          setTotalSupplierCount(response?.TotalSupplierCount);
          setSupplierNextPage(response?.NextPage);
          setShowUpgrade(response?.Upgrade_ShowMessage);
        } else {
          // CallToast("Something went wrong", false, false, "v2style");
        }
      });
    }
  }, [
    supplierFilters,
    sortItem,
    authTokens,
    pageNumber,
    getSupplierData,
    defaultSelectedTab,
  ]);

  async function getComplianceProfiles(order = 0) {
    await getAPIData(`${317}`, authTokens, {
      optionEnum1: order,
    })
      .then((response) => {
        // setMainPageLoading(false);
        if (response?.mr?.Success) {
          setComplianceProfilesList(response?.ComplianceProfile_List);
        }
      })
      .catch((err) => {
        console.log(err);
        // CallToast("Something went wrong", false, false, "v2style");
      });
  }

  useEffect(() => {
    function FilterDropDownCloser(e) {
      const element = document.getElementById("filter-task-dropdown");
      const datepicker = null; //e.target.closest("tr");

      if (
        e.target !== element &&
        !element?.contains(e.target) &&
        e.target.id !== "restrict"
      ) {
        if (datepicker) {
          return null;
        } else {
          setshowFilter(false);
        }
      }
    }

    document.addEventListener("click", FilterDropDownCloser);

    return () => {
      document.removeEventListener("click", FilterDropDownCloser);
    };
  }, []);

  const [showFilter, setshowFilter] = useState(false);
  const toggleFilter = (e) => {
    e.stopPropagation();
    setshowFilter(!showFilter);
  };

  async function printSupplierReport(event) {
    // CallToast("Report will be downloaded shortly.", false, false, "v2style");
    CustomRightSideToastForReport(
      "Report is being created and will be sent to you by email shortly."
    );
    await getAPIData(`${455}`, authTokens, {})
      .then((response) => {
        if (response?.mr?.Success) {
          CallToast(
            "Report has been sent successfully",
            false,
            false,
            "v2style"
          );
          // downloadPDF(response?.FileURL);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return (
    <>
    {
      menuItemData?.disableState ? <>
      {
        userPortalRole === 'MSP' ? 
          <NoAccessUI/> : <NoAccessUI type={userPortalRole}/>
      }
      </> : <>
        <Row className="supplyChainWrapper " style={{ height: "max-content" }}>
          <Col xs={12}>
            <div>
              <header
                className="d-flex justify-content-between navbar sticky-top pb-0"
                style={{
                  paddingTop: ` ${
                     "0.8em"
                  } `,
                  background: "#FFFFFF",
                  position: "fixed",
                  width: `${isMenuCollapse ? "92.25%" : "83.25%"}`,
                  zIndex: 6,
                  // marginLeft: "-0.9rem",
                  paddingLeft: "2rem",
                  // borderBottom: `1px solid #EBEDF1`,
                  paddingBottom: "8px",
                }}
              >
                <h3 className="f-16 f-600">Supply Chain Monitoring</h3>

                <Header />
              </header>
            </div>
            <div style={{ paddingTop: "5%" }}></div>
            <Tab.Container
              id="left-tabs-example"
              defaultActiveKey={defaultSelectedTab}
            >
              <Row>
                <Col
                  sm={12}
                  className={`tab-header  justify-content-between align-items-center  ${
                    page.type === "questionAnswers" ? "d-none" : "d-flex"
                  }  `}
                >
                  {/* Home screen left part */}
                  <div
                    className={`${page.type !== "home" ? "d-none" : ""}`}
                    style={{ marginLeft: "15px" }}
                  >
                    <Nav
                      variant="pills"
                      className="flex-row"
                      onSelect={(e) => {
                        setDefaultSelectedTab(e);
                        addQueryParamsToURL("selectedTab", e);
                      }}
                    >
                      <Nav.Item>
                        <Nav.Link
                          eventKey="suppliers"
                          onClick={() => {
                            setIsQuestionnaireTab(false);
                          }}
                        >
                          All Suppliers
                        </Nav.Link>
                      </Nav.Item>

                      <Nav.Item id="questionnaire-tabMenu">
                        <Nav.Link
                          eventKey="questionnaires"
                          onClick={() => {
                            setIsQuestionnaireTab(true);
                          }}
                        >
                          Questionnaires
                        </Nav.Link>
                      </Nav.Item>

                      <Nav.Item id="profiling-tabMenu">
                        <Nav.Link
                          eventKey="profiling"
                          onClick={() => {
                            setIsQuestionnaireTab(false);
                          }}
                        >
                          Profiling
                        </Nav.Link>
                      </Nav.Item>
                    </Nav>
                  </div>
                  {page.type === "complianceSupplierList" && (
                    <div className="d-flex align-items-center">
                      <div
                        className="d-flex align-items-center pointer"
                        onClick={() => {
                          setPage({ type: "home", name: "" });
                        }}
                      >
                        <img
                          alt=""
                          src="/images/icons/arrowLeft.svg"
                          className="pointer ml-3"
                        />
                        <div className="f-grey f-500 ml-3 pointer">Back</div>
                      </div>

                      <div className="ml-4 f-16 f-500 f-black">{page.name}</div>
                    </div>
                  )}
                  {page.type === "archivedQuestionnaire" && (
                    <div className="d-flex align-items-center">
                      <div
                        className="d-flex align-items-center pointer"
                        onClick={() => {
                          setPage({ type: "home", name: "" });
                          questionnaireTab.current.getArchivedTask(false);
                        }}
                      >
                        <img
                          alt=""
                          src="/images/icons/arrowLeft.svg"
                          className="pointer ml-3"
                        />
                        <div className="f-grey f-500 ml-3 pointer">Back</div>
                      </div>

                      <div className="ml-4 f-16 f-500 f-black">{page.name}</div>
                    </div>
                  )}

                  {/* Right side items at top bar */}
                  {defaultSelectedTab === "suppliers" && (
                    <div className="d-flex align-items-center mr-3">
                      <div className="d-flex align-items-center">
                        <img
                          alt=""
                          className=""
                          src="/images/calculator/search.svg"
                        />

                        <input
                          type="text"
                          value={supplierSearchText}
                          onChange={(e) => {
                            if (typeof supplierFilters === "string") {
                              let _filters = JSON.parse(supplierFilters);
                              setSupplierFilters(
                                JSON.stringify({
                                  ..._filters,
                                  SupplierName: e.target.value,
                                })
                              );
                            } else {
                              setSupplierFilters(
                                JSON.stringify({
                                  ...supplierFilters,
                                  SupplierName: e.target.value,
                                })
                              );
                            }

                            setSupplierSearchText(e.target.value);
                          }}
                          placeholder="Search.."
                          className="border-none bg-transparent pl-1"
                        />

                        <img
                          alt=""
                          onClick={() => {
                            let _filters = JSON.parse(supplierFilters);
                            setSupplierFilters(
                              JSON.stringify({ ..._filters, SupplierName: "" })
                            );
                            setSupplierSearchText("");
                          }}
                          className="pointer mr-3"
                          src="/images/settings-v2/header-search-cross.svg"
                          style={{
                            visibility: `${
                              supplierSearchText === "" ? "hidden" : "visible"
                            }`,
                          }}
                        />
                      </div>

                      <div
                        className={`d-flex align-items-center ${
                          filtersInlocalStorage
                            ? "supplier-active-filter"
                            : "supplier-inactive-filter"
                        }`}
                        id="filter-task-dropdown"
                      >
                        <DropDownFilter
                          ref={SCMFilterDropdown}
                          show={showFilter}
                          toggleFilter={toggleFilter}
                          setSupplierFilters={setSupplierFilters}
                          supplierFilters={supplierFilters}
                          intialFilters={JSON.stringify(supplierFilter)}
                        >
                          <img alt="" src="/images/actioncenter/filter.svg" />
                        </DropDownFilter>
                      </div>
                      <div className=" message-dot position-relative">
                        {/* <img
                          alt=""
                          src="/images/supply-chain/grey-plane.svg"
                          className="mr-3 pointer"
                          onClick={() => {
                            setSmallChatModal(true);
                          }}
                        /> */}
                        <ComingSoonDropdown />
                      </div>
                      <OverlayTrigger
                        placement="bottom"
                        trigger={
                          viewOnlyAccess("AccessState_SCM")
                            ? ["hover", "focus"]
                            : []
                        }
                        delay={{ show: 200, hide: 250 }}
                        overlay={
                          <Tooltip
                            id={`tooltip-1365`}
                            className="custom_tooltip_access-integr"
                          >
                            This feature is managed by your service provider
                          </Tooltip>
                        }
                      >
                        <div>
                          <img
                            alt=""
                            src="/images/supply-chain/user-icon.svg"
                            className="mr-3 pointer"
                            style={{
                              pointerEvents: viewOnlyAccess("AccessState_SCM")
                                ? "none"
                                : "all",
                            }}
                            onClick={() => {
                              setShowClientsModal(true);
                            }}
                          />
                        </div>
                      </OverlayTrigger>
                      <OverlayTrigger
                        placement="bottom"
                        trigger={
                          viewOnlyAccess("AccessState_SCM")
                            ? ["hover", "focus"]
                            : []
                        }
                        delay={{ show: 200, hide: 250 }}
                        overlay={
                          <Tooltip
                            id={`tooltip-1365`}
                            className="custom_tooltip_access-integr"
                          >
                            This feature is managed by your service provider
                          </Tooltip>
                        }
                      >
                        <div>
                          <div
                            className={`f-500 mr-2 ${
                              pageData?.ShowRedDot ? "red-dot" : ""
                            } position-relative pointer`}
                            style={{
                              pointerEvents: viewOnlyAccess("AccessState_SCM")
                                ? "none"
                                : "all",
                              color: "#9499aa",
                            }}
                            onClick={() => {
                              setShowRequestsModal(true);
                            }}
                          >
                            {viewOnlyAccess("AccessState_SCM") ? (
                              <img
                                alt=""
                                src="/images/attack-surface/small-lock.svg"
                                className="mr-2"
                              />
                            ) : (
                              <></>
                            )}
                            Requests
                          </div>
                        </div>
                      </OverlayTrigger>
                      <OverlayTrigger
                        placement="bottom"
                        trigger={
                          viewOnlyAccess("AccessState_SCM")
                            ? ["hover", "focus"]
                            : []
                        }
                        delay={{ show: 200, hide: 250 }}
                        overlay={
                          <Tooltip
                            id={`tooltip-1365`}
                            className="custom_tooltip_access-integr"
                          >
                            This feature is managed by your service provider
                          </Tooltip>
                        }
                      >
                        <div>
                          <div className=" d-flex align-items-center pointer">
                            <div
                              className={` ${
                                viewOnlyAccess("AccessState_SCM")
                                  ? "btn-disable"
                                  : "addSupplierBtn"
                              } f-white f-500`}
                              style={{
                                borderRight: "1px solid #0083c3",
                                borderTopLeftRadius: "2px",
                                borderBottomLeftRadius: "2px",
                                padding: "5px 7px",
                              }}
                              onClick={() => {
                                setShowAddSupplierModal(true);
                              }}
                            >
                              Add supplier
                              {viewOnlyAccess("AccessState_SCM") ? (
                                <img
                                  alt=""
                                  src="/images/attack-surface/small-lock.svg"
                                  className="ml-2"
                                />
                              ) : (
                                <></>
                              )}
                            </div>
                            {viewOnlyAccess("AccessState_SCM") ? (
                              <></>
                            ) : (
                              <Dropdown className="transparennt-wrapper-dropdown">
                                <Dropdown.Toggle className="w-100 d-flex align-items-center justify-content-between">
                                  <div
                                    className=""
                                    style={{
                                      borderTopRightRadius: "2px",
                                      borderBottomRightRadius: "2px",
                                      background: "#008fd4",
                                      padding: "4px 7px",
                                      borderTop: "1px solid #008fd4",
                                    }}
                                  >
                                    <img
                                      alt=""
                                      src="/images/big-chevron-down.svg"
                                      style={{
                                        filter: "brightness(5)",
                                      }}
                                    />
                                  </div>
                                </Dropdown.Toggle>
                                <Dropdown.Menu
                                  className="p-3  d-flex align-items-start upload-template-drop"
                                  style={{
                                    width: "200px",
                                    inset: "auto 0px auto auto",
                                    transform: "translate(0px, 0px)",
                                  }}
                                  onClick={() => {
                                    setShowUploadFileModal(true);
                                  }}
                                >
                                  <img
                                    alt=""
                                    src="/images/msp/export-supplier-icon.svg"
                                    className="mr-2 mt-1"
                                  />
                                  <div>
                                    <div className="f-grey mb-1">
                                      Import suppliers
                                    </div>
                                    <div className="f-darkgrey f-12">
                                      Import from file .csv, .xlsx
                                    </div>
                                  </div>
                                </Dropdown.Menu>
                              </Dropdown>
                            )}
                          </div>
                        </div>
                      </OverlayTrigger>
                      <OverlayTrigger
                        placement="bottom"
                        trigger={
                          viewOnlyAccess("AccessState_SCM")
                            ? ["hover", "focus"]
                            : []
                        }
                        delay={{ show: 200, hide: 250 }}
                        overlay={
                          <Tooltip
                            id={`tooltip-1365`}
                            className="custom_tooltip_access_rightside"
                          >
                            This feature is managed by your service provider
                          </Tooltip>
                        }
                      >
                        <div>
                          <div
                            className="menu-dropdown header-menu-dropdown"
                            style={{
                              pointerEvents: viewOnlyAccess("AccessState_SCM")
                                ? "none"
                                : "all",
                            }}
                          >
                            <Dropdown className="device-dropdown">
                              <Dropdown.Toggle>
                                <img alt="" src="/images/horizontal-dots.svg" />
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                <div>
                                  {page.type === "complianceSupplierList" ? (
                                    <Row>
                                      <Col xs={12} className="  pointer">
                                        <div
                                          className="drop-item"
                                          onClick={(e) => {
                                            e.target
                                              .closest(".device-dropdown")
                                              .click();
                                          }}
                                        >
                                          Edit
                                        </div>
                                      </Col>

                                      <Col xs={12} className=" pointer">
                                        <div
                                          className="drop-item"
                                          onClick={(e) => {
                                            e.target
                                              .closest(".device-dropdown")
                                              .click();
                                          }}
                                        >
                                          Duplicate
                                        </div>
                                      </Col>
                                      <Col xs={12} className="pointer">
                                        <div
                                          className="d-flex align-items-center justify-content-between drop-item"
                                          onClick={(e) => {
                                            e.target
                                              .closest(".device-dropdown")
                                              .click();
                                          }}
                                        >
                                          Delete
                                        </div>
                                      </Col>
                                    </Row>
                                  ) : (
                                    <Row>
                                      <Col xs={12} className=" pointer">
                                        <div
                                          className="drop-item"
                                          onClick={(e) => {
                                            e.target
                                              .closest(".device-dropdown")
                                              .click();
                                            setShowAccessLevelModal(true);
                                          }}
                                        >
                                          Access Levels
                                        </div>
                                      </Col>
                                      <Col xs={12} className=" pointer">
                                        <div
                                          className="drop-item"
                                          onClick={(e) => {
                                            setSupplierComplainceSelectedTab(
                                              "complianceProfiles"
                                            );
                                            setPage({ type: "home", name: "", update: true});
                                            e.target
                                              .closest(".device-dropdown")
                                              .click();
                                          }}
                                        >
                                          Compliance profiles
                                        </div>
                                      </Col>
                                      <hr
                                        className="m-0 mt-2 mb-2 w-75 mx-auto menu-hr "
                                        align="center"
                                      />
                                      <Col xs={12} className=" pointer  ">
                                        <div
                                          className="drop-item"
                                          onClick={(e) => {
                                            printSupplierReport();
                                            e.target
                                              .closest(".device-dropdown")
                                              .click();
                                          }}
                                        >
                                          {/* Print Report */}
                                          Print Supply Chain Summary Report
                                        </div>
                                      </Col>
                                      <Col xs={12} className="pointer">
                                        <div
                                          className="d-flex align-items-center justify-content-between drop-item"
                                          onClick={(e) => {
                                            e.target
                                              .closest(".device-dropdown")
                                              .click();
                                          }}
                                        >
                                          <div>Notification</div>
                                          <img
                                            alt=""
                                            src="/images/settings-v2/sideArrow.svg"
                                          />
                                        </div>
                                      </Col>
                                    </Row>
                                  )}
                                </div>
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                        </div>
                      </OverlayTrigger>
                    </div>
                  )}
                  {defaultSelectedTab === "questionnaires" && (
                    <div className="d-flex align-items-center mr-3 ">
                      <Button
                        className="hg-blue-btn mb-2px mr-3 "
                        style={{
                          pointerEvents: "none",
                          visibility: "hidden",
                        }}
                        onClick={() => {
                          setShowSendQuestionnaireModal(true);
                        }}
                      >
                        Send Questionnaire
                      </Button>
                      {questionnaireTabSelectedTab?.toLowerCase() === "sent" && (
                        <OverlayTrigger
                          placement="left"
                          trigger={
                            viewOnlyAccess("AccessState_SCM")
                              ? ["hover", "focus"]
                              : []
                          }
                          delay={{
                            show: 200,
                            hide: 250,
                          }}
                          overlay={
                            <Tooltip
                              id={`tooltip-1365`}
                              className="custom_tooltip_access"
                            >
                              This feature is managed by your service provider
                            </Tooltip>
                          }
                        >
                          <div>
                            <div
                              className="f-500 f-grey mr-2 pointer "
                              style={{
                                pointerEvents: viewOnlyAccess("AccessState_SCM")
                                  ? "none"
                                  : "all",
                              }}
                              onClick={() => {
                                setShowQuestionnaireModal(true);
                              }}
                            >
                              {viewOnlyAccess("AccessState_SCM") ? (
                                <img
                                  alt=""
                                  src="/images/attack-surface/small-lock.svg"
                                  className="mr-2"
                                />
                              ) : (
                                <></>
                              )}
                              Templates
                            </div>
                          </div>
                        </OverlayTrigger>
                      )}
                      <div>
                        <img
                          alt=""
                          className="mx-2 pointer"
                          src="/images/actioncenter/folder.svg"
                          onClick={() => {
                            if (allowDrillDownQuestionnaire?.Option === false) {
                              setShowUpgradeModal(true);
                              return;
                            }
                            questionnaireTab.current.getArchivedTask();
                            setPage({
                              type: "archivedQuestionnaire",
                              name: "Archived Questionnaire",
                            });
                          }}
                        />
                      </div>

                      <div className="pointer">
                        {/* <img alt="" src="/images/horizontal-dots.svg" /> */}
                      </div>
                    </div>
                  )}
                  {defaultSelectedTab === "profiling" && (
                    <>
                      <div className="d-flex align-items-end mr-3">
                        <div className=" pb-1">
                          <OverlayTrigger
                            placement="left"
                            trigger={
                              viewOnlyAccess("AccessState_SCM")
                                ? ["hover", "focus"]
                                : []
                            }
                            delay={{
                              show: 200,
                              hide: 250,
                            }}
                            overlay={
                              <Tooltip
                                id={`tooltip-1365`}
                                className="custom_tooltip_access"
                              >
                                This feature is managed by your service provider
                              </Tooltip>
                            }
                          >
                            <div>
                              <Button
                                className={`radius-2 mr-2 ${
                                  viewOnlyAccess("AccessState_SCM")
                                    ? "btn-disable"
                                    : "hg-blue-btn "
                                } `}
                                onClick={() => {
                                  setShowProfilingModal(true);

                                  // history.push("/supplier-profiler/profiling", {
                                  //   backUrl: `${
                                  //     window.location.pathname
                                  //   }?${new URLSearchParams(
                                  //     window.location.search
                                  //   ).toString()}`,
                                  //   isContributorView: false,
                                  // });
                                }}
                              >
                                {viewOnlyAccess("AccessState_SCM") ? (
                                  <img
                                    alt=""
                                    src="/images/attack-surface/small-lock.svg"
                                    className="mr-2"
                                  />
                                ) : (
                                  <></>
                                )}
                                Start Profiling
                              </Button>
                            </div>
                          </OverlayTrigger>
                        </div>

                        <Dropdown className="transparennt-wrapper-dropdown">
                          <Dropdown.Toggle className="w-100 d-flex align-items-center justify-content-between">
                            <div className="h-100 p-2">
                              <img
                                alt=""
                                className="mx-2 pointer"
                                src="/images/horizontal-dots.svg"
                              />
                            </div>
                          </Dropdown.Toggle>
                          <Dropdown.Menu
                            className="p-2 upload-template-drop f-grey pointer"
                            style={{
                              width: "200px",
                            }}
                            onClick={(e) => {
                              e.target
                                .closest(".transparennt-wrapper-dropdown")
                                .click();
                            }}
                          >
                            <OverlayTrigger
                              placement="left"
                              trigger={
                                viewOnlyAccess("AccessState_SCM")
                                  ? ["hover", "focus"]
                                  : []
                              }
                              delay={{
                                show: 200,
                                hide: 250,
                              }}
                              overlay={
                                <Tooltip
                                  id={`tooltip-1365`}
                                  className="custom_tooltip_access"
                                >
                                  This feature is managed by your service provider
                                </Tooltip>
                              }
                            >
                              <div>
                                <div
                                  className="p-1"
                                  style={{
                                    pointerEvents: viewOnlyAccess("AccessState_SCM")
                                      ? "none"
                                      : "all",
                                  }}
                                  onClick={() => {
                                    setShowUploadFileModal(true);
                                  }}
                                >
                                  {viewOnlyAccess("AccessState_SCM") ? (
                                    <img
                                      alt=""
                                      src="/images/attack-surface/small-lock.svg"
                                      className="mr-2"
                                    />
                                  ) : (
                                    <></>
                                  )}
                                  Profiling Templates
                                </div>
                              </div>
                            </OverlayTrigger>
                            <div
                              className="p-1"
                              onClick={() => {
                                setShowRiskMatrixModal(true);
                              }}
                            >
                              Risk Matrix
                            </div>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </>
                  )}
                </Col>
                <Col sm={12}>
                  <Tab.Content>
                    {/* Suppliers */}
                    <Tab.Pane eventKey="suppliers">
                      {/* Top banner */}
                      {mainPageLoading ? (
                        <SupplierChainPageLoader />
                      ) : (
                        <>
                          {page.type === "complianceSupplierList" ? (
                            <>
                              {/* Compliance Section Banner */}

                              <div className="d-flex  banner">
                                {/*  */}

                                <div
                                  className={`d-flex justify-content-between align-items-start banner-element banner-element-border `}
                                >
                                  <div>
                                    <p className="banner-element-name mb-2">
                                      Compliant Suppliers
                                    </p>
                                    <p className="banner-element-value">
                                      {page?.data?.HealthySupplierCount} of&nbsp;
                                      {page?.data?.TotalSupplierCount}
                                    </p>
                                  </div>
                                  <div className="pointer">
                                    <AttackSurfaceTooltip
                                      toolTipStyle={{
                                        right: "-58%",
                                        textAlign: "justify",
                                      }}
                                      bottomLeft
                                      content={
                                        <span>
                                          Suppliers who meet all of the requirements
                                          defined under the Compliance Profile you
                                          have assigned them to.
                                        </span>
                                      }
                                    >
                                      <img
                                        alt=""
                                        src={"/images/supply-chain/grey-info.svg"}
                                      />
                                    </AttackSurfaceTooltip>
                                  </div>
                                </div>
                                <div
                                  className={`d-flex justify-content-between align-items-start banner-element banner-element-border `}
                                >
                                  <div>
                                    <p className="banner-element-name mb-2">
                                      Risky Suppliers
                                    </p>
                                    <p className="banner-element-value">
                                      {page?.data?.RiskySupplierCount} of&nbsp;
                                      {page?.data?.TotalSupplierCount}
                                    </p>
                                  </div>
                                  <div className="pointer">
                                    <AttackSurfaceTooltip
                                      toolTipStyle={{
                                        right: "-58%",
                                        textAlign: "justify",
                                      }}
                                      bottomLeft
                                      content={
                                        <span>
                                          Suppliers who do not meet all of the
                                          requirements defined under the Compliance
                                          Profile you have assigned them to.
                                        </span>
                                      }
                                    >
                                      <img
                                        alt=""
                                        src={"/images/supply-chain/grey-info.svg"}
                                      />
                                    </AttackSurfaceTooltip>
                                  </div>
                                </div>
                                <div
                                  className={`d-flex justify-content-between align-items-start banner-element banner-element-border- `}
                                >
                                  <div>
                                    <p className="banner-element-name mb-2">
                                      Status
                                    </p>
                                    <p className="banner-element-value">
                                      {page?.data?.PercentageCompliant}
                                    </p>
                                  </div>
                                  <div className="pointer">
                                    <AttackSurfaceTooltip
                                      toolTipStyle={{ right: "-58%" }}
                                      bottomLeft
                                      content={
                                        // Overall health of this compliance profile, based on the compliance of all suppliers assign to it.
                                        <span>
                                          Overall health of this Compliance Profile,
                                          based on the compliance of all suppliers
                                          assign to it.
                                        </span>
                                      }
                                    >
                                      <img
                                        alt=""
                                        src={"/images/supply-chain/grey-info.svg"}
                                      />
                                    </AttackSurfaceTooltip>
                                  </div>
                                </div>
                              </div>
                            </>
                          ) : (
                            <div className="d-fle x  banner">
                              {/*  */}
                              <div className="d-flex">
                                <div
                                  className={`d-flex justify-content-between align-items-start banner-element banner-element-border border-bottom`}
                                >
                                  <div>
                                    <p className="banner-element-name mb-2">
                                      Qty Suppliers
                                    </p>
                                    <p className="banner-element-value">
                                      {pageData?.SubscriptionMaxSupplier ?? 0}
                                    </p>
                                  </div>
                                  <div className="pointer">
                                    <AttackSurfaceTooltip
                                      toolTipStyle={{
                                        right: "-58%",
                                        textAlign: "justify",
                                      }}
                                      bottomLeft
                                      content={
                                        <span>
                                          Total number of managed and unmanaged
                                          suppliers you have added to your
                                          HighGround account. This does not include
                                          potential suppliers.
                                        </span>
                                      }
                                    >
                                      <img
                                        alt=""
                                        src={"/images/supply-chain/grey-info.svg"}
                                      />
                                    </AttackSurfaceTooltip>
                                  </div>
                                </div>
                                <div
                                  className={`d-flex justify-content-between align-items-start banner-element banner-element-border border-bottom`}
                                >
                                  <div>
                                    <p className="banner-element-name mb-2">
                                      Qty Clients{" "}
                                      {/* <BlueTag
                                        text={"+2 REQUESTS"}
                                        className={"ml-2"}
                                      /> */}
                                    </p>
                                    <p className="banner-element-value">
                                      {pageData?.ClientCount}{" "}
                                    </p>
                                  </div>
                                  <div className="pointer">
                                    {/* <AttackSurfaceTooltip
                                    toolTipStyle={{
                                      right: "-58%",
                                      textAlign: "justify",
                                    }}
                                    bottomLeft
                                    content={
                                      <span>
                                        Suppliers who meet all of the requirements
                                        defined under the Compliance Profile you
                                        have assigned them to.
                                      </span>
                                    }
                                  > */}
                                    <img
                                      alt=""
                                      src={"/images/supply-chain/redirect-icon.svg"}
                                    />
                                    {/* </AttackSurfaceTooltip> */}
                                  </div>
                                </div>
                                <div
                                  className={`d-flex justify-content-between align-items-start banner-element banner-element-border border-bottom`}
                                >
                                  <div>
                                    <p className="banner-element-name mb-2">
                                      Supply Chain Risk Score
                                    </p>
                                    <p className="banner-element-value">
                                      {pageData?.SupplyChainRisk}{" "}
                                    </p>
                                  </div>
                                  <div className="pointer">
                                    <GreyTag text={"COMING SOON"} />
                                    {/* <AttackSurfaceTooltip
                                    toolTipStyle={{
                                      right: "-58%",
                                      textAlign: "justify",
                                    }}
                                    bottomLeft
                                    content={
                                      <span>
                                        Suppliers who meet all of the requirements
                                        defined under the Compliance Profile you
                                        have assigned them to.
                                      </span>
                                    }
                                  >
                                    <img
                                      alt=""
                                      src={"/images/supply-chain/grey-info.svg"}
                                    />
                                  </AttackSurfaceTooltip> */}
                                  </div>
                                </div>
                                <div
                                  className={`d-flex justify-content-between align-items-start banner-element  border-bottom `}
                                >
                                  <div>
                                    <p className="banner-element-name mb-2">
                                      Your Supply Chain ID
                                    </p>
                                    <p className="banner-element-value">
                                      {pageData?.SupplyChainId}
                                    </p>
                                  </div>
                                  <div
                                    className="pointer"
                                    onClick={() => {
                                      navigator.clipboard.writeText(
                                        pageData?.SupplyChainId
                                      );
                                      CallToast(
                                        "Supply Chain Id Copied",
                                        false,
                                        false,
                                        "v2style"
                                      );
                                    }}
                                  >
                                    {/* <AttackSurfaceTooltip
                                    toolTipStyle={{
                                      right: "-58%",
                                      textAlign: "justify",
                                    }}
                                    bottomLeft
                                    content={
                                      <span>
                                        Suppliers who meet all of the requirements
                                        defined under the Compliance Profile you
                                        have assigned them to.
                                      </span>
                                    }
                                  > */}
                                    <img
                                      alt=""
                                      src={"/images/supply-chain/copy-icon.svg"}
                                    />
                                    {/* </AttackSurfaceTooltip> */}
                                  </div>
                                </div>
                              </div>

                              {/* Bottom grid */}
                              <div className="d-flex">
                                <div
                                  className={`d-flex justify-content-between align-items-start banner-element banner-element-border `}
                                >
                                  <div>
                                    <p className="banner-element-name mb-2">
                                      Healthy Suppliers
                                    </p>
                                    <p className="banner-element-value">
                                      {pageData?.HealthySupplierCount ?? 0}
                                    </p>
                                  </div>
                                  <div className="pointer">
                                    <AttackSurfaceTooltip
                                      toolTipStyle={{
                                        right: "-58%",
                                        textAlign: "justify",
                                      }}
                                      bottomLeft
                                      content={
                                        <span>
                                          Qty of Suppliers which you are managing
                                          and that are compliant with the security
                                          requirements of the compliance profile you
                                          have associated them with.
                                        </span>
                                      }
                                    >
                                      <img
                                        alt=""
                                        src={"/images/supply-chain/grey-info.svg"}
                                      />
                                    </AttackSurfaceTooltip>
                                  </div>
                                </div>
                                <div
                                  className={`d-flex justify-content-between align-items-start banner-element banner-element-border `}
                                >
                                  <div>
                                    <p className="banner-element-name mb-2">
                                      Suppliers Profiled
                                    </p>
                                    <p className="banner-element-value">
                                      {pageData?.SupplierProfiled ?? 0}
                                    </p>
                                  </div>
                                  <div className="pointer">
                                    <AttackSurfaceTooltip
                                      toolTipStyle={{
                                        right: "-58%",
                                        textAlign: "justify",
                                      }}
                                      bottomLeft
                                      content={
                                        <span>
                                          Qty of Suppliers that you have profiled /
                                          are profiling.
                                        </span>
                                      }
                                    >
                                      <img
                                        alt=""
                                        src={"/images/supply-chain/grey-info.svg"}
                                      />
                                    </AttackSurfaceTooltip>
                                  </div>
                                </div>
                                <div
                                  className={`d-flex justify-content-between align-items-start banner-element banner-element-border `}
                                >
                                  <div>
                                    <p className="banner-element-name mb-2">
                                      Managed Suppliers
                                    </p>
                                    <p className="banner-element-value">
                                      {pageData?.ManagedSupplier ?? 0}
                                    </p>
                                  </div>
                                  <div className="pointer">
                                    {/* <AttackSurfaceTooltip
                                    toolTipStyle={{
                                      right: "-58%",
                                      textAlign: "justify",
                                    }}
                                    bottomLeft
                                    content={
                                      <span>
                                        Suppliers who meet all of the requirements
                                        defined under the Compliance Profile you
                                        have assigned them to.
                                      </span>
                                    }
                                  >
                                    <img
                                      alt=""
                                      src={"/images/supply-chain/grey-info.svg"}
                                    />
                                  </AttackSurfaceTooltip> */}
                                  </div>
                                </div>
                                <div
                                  className={`d-flex justify-content-between align-items-start banner-element   `}
                                >
                                  <div>
                                    <p className="banner-element-name mb-2">
                                      Unmanaged Suppliers
                                    </p>
                                    <p className="banner-element-value">
                                      {pageData?.UnmanagedSupplier ?? 0}
                                    </p>
                                  </div>
                                  <div className="pointer">
                                    {/* <AttackSurfaceTooltip
                                    toolTipStyle={{
                                      right: "-58%",
                                      textAlign: "justify",
                                    }}
                                    bottomLeft
                                    content={
                                      <span>
                                        Suppliers who meet all of the requirements
                                        defined under the Compliance Profile you
                                        have assigned them to.
                                      </span>
                                    }
                                  > */}
                                    <img
                                      alt=""
                                      src={"/images/supply-chain/redirect-icon.svg"}
                                    />
                                    {/* </AttackSurfaceTooltip> */}
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                          <div
                            className={` p-4 ${page !== "home" ? "d-none" : ""}`}
                          />
                          {page.type === "home" && (
                            <SuppliersAndComplianceProfilesTabs
                              openRequestsModal={openRequestsModal}
                              SCMFilterDropdown={SCMFilterDropdown}
                              potentialSupplierList={potentialSupplierList?.filter(
                                (item) => item?.Type === "Potential"
                              )}
                              unManagedSuppliers={potentialSupplierList?.filter(
                                (item) => item?.Type === "Unmanaged"
                              )}
                              openPotentialSupplierModal={
                                openPotentialSupplierModal
                              }
                              setPage={setPage}
                              supplierList={supplierList}
                              complianceProfilesList={complianceProfilesList}
                              showUpgrade={showUpgrade}
                              refreshData={() => {
                                getSupplierList();
                                getPotentialSuppliers();
                              }}
                              getComplianceProfiles={getComplianceProfiles}
                              totalSupplierCount={totalSupplierCount}
                              supplierNextPage={supplierNextPage}
                              pageNumber={pageNumber}
                              getNextPage={getNextPage}
                              nextPageLoading={nextPageLoading}
                              SortSupplierColumn={SortSupplierColumn}
                              columnSortingLoading={columnSortingLoading}
                              sortItem={sortItem}
                              activeTab={supplierComplainceSelectedTab}
                              setActiveTab={setSupplierComplainceSelectedTab}
                              upgradeOptions={upgradeOptions}
                              openSupplierDetailScreenInRequestsModal={
                                openSupplierDetailScreenInRequestsModal
                              }
                            />
                          )}
                        </>
                      )}
                    </Tab.Pane>

                    {/* Clients */}

                    <Tab.Pane eventKey="questionnaires">
                      {page.type === "questionAnswers" ? (
                        <>
                          {page?.QuestionType === "sent" && (
                            <ReceivedQuestionAnswers
                              setPage={setPage}
                              pageData={page}
                            />
                            // <QuestionAnswers setPage={setPage} pageData={page} />
                          )}
                          {page?.QuestionType === "received" && (
                            <ReceivedQuestionAnswers
                              setPage={setPage}
                              pageData={page}
                            />
                          )}
                        </>
                      ) : (
                        <QuestionnaireTab
                          setAllowDrillDownQuestionnaire={
                            setAllowDrillDownQuestionnaire
                          }
                          allowDrillDownQuestionnaire={allowDrillDownQuestionnaire}
                          setPage={setPage}
                          ref={questionnaireTab}
                          questionnaireTabSelectedTab={questionnaireTabSelectedTab}
                          setQuestionnaireTabSelectedTab={
                            setQuestionnaireTabSelectedTab
                          }
                        />
                      )}
                    </Tab.Pane>

                    <Tab.Pane eventKey="profiling">
                      <ProfilingTab
                        setAllowDrillDownQuestionnaire={
                          setAllowDrillDownQuestionnaire
                        }
                        allowDrillDownQuestionnaire={allowDrillDownQuestionnaire}
                        setPage={setPage}
                        ref={profilingTab}
                        profilingTabSelectedTab={profilingTabSelectedTab}
                        setProfilingTabSelectedTab={setProfilingTabSelectedTab}
                        showUploadFileModal={showUploadFileModal}
                      />
                    </Tab.Pane>
                    {page.type === "complianceSupplierList" && (
                      <SupplierTable data={page?.data?.SupplierList ?? []} />
                    )}
                  </Tab.Content>
                </Col>
              </Row>
            </Tab.Container>
          </Col>
          <Suspense fallback={<div>Loading...</div>}>
            <RequestsModal
              show={showRequestsModal}
              openSupplierDetailScreen={openSupplierDetailScreen}
              showPotentialSupplierPage={showPotentialSupplierPage}
              hideModal={() => setShowRequestsModal(false)}
              refreshData={() => {
                const filtersInlocalStorage =
                  window.localStorage.getItem("supplier-filters");
                if (filtersInlocalStorage) {
                  getSupplierList(filtersInlocalStorage);
                } else {
                  getSupplierList();
                }
                getComplianceProfiles();
                getPotentialSuppliers();
              }}
              refreshPotentialSuppliers={() => {
                getPotentialSuppliers();
              }}
              // ObjId_SendQuestionnaire={}
            />
          </Suspense>
          <AccessLevelModal
            show={showAccessLevelModal}
            hideModal={() => setShowAccessLevelModal(false)}
          />
          <SupplySmallChatModal
            show={smallChatModal}
            hideModal={() => setSmallChatModal(false)}
          />
          <SendQuestionnaireModal
            show={showSendQuestionnaireModal}
            hideModal={() => setShowSendQuestionnaireModal(false)}
          />

          <QuestionnaireBigModal
            show={showQuestionnaireModal}
            hideModal={() => setShowQuestionnaireModal(false)}
            refreshData={() =>
              questionnaireTab.current.refreshReceivedsentQuestionnaire()
            }
          />
          <ClientsModal
            show={showClientsModal}
            hideModal={() => setShowClientsModal(false)}
            refreshData={() => {
              const filtersInlocalStorage =
                window.localStorage.getItem("supplier-filters");
              if (filtersInlocalStorage) {
                getSupplierList(filtersInlocalStorage);
              } else {
                getSupplierList();
              }
              getComplianceProfiles();
            }}
          />
          <UpgradeToPremiumModal
            show={showUpgradeModal}
            hideModal={() => setShowUpgradeModal(false)}
            upgradeOptions={allowDrillDownQuestionnaire?.UpgradeOptions}
          />

          <UploadTemplateModal
            show={showUploadFileModal}
            hideModal={() => setShowUploadFileModal(false)}
          />
          <AddSupplierModal
            show={showAddSupplierModal}
            hideModal={() => setShowAddSupplierModal(false)}
            refreshData={() => {
              getSupplierList();
            }}
          />

          <RiskMatrix
            show={showRiskMatrixModal}
            hideModal={() => setShowRiskMatrixModal(false)}
          />
          <ProfilingModal
            show={showProfilingModal}
            hideModal={() => setShowProfilingModal(false)}
            setSelectedTemplate={setSelectedTemplate}
            selectedTemplate={selectedTemplate}
            showSupplierModal={showSupplierModal}
        setShowSupplierModal={setShowSupplierModal}
        setTitleTemplate={setTitleTemplate}
          />
          <ChooseSupplierModal
            show={showSupplierModal}
            hideModal={() => setShowSupplierModal(false)}
            selectedTemplate={selectedTemplate}
            titleTemplate={titleTemplate}
          />
        </Row>
      </>
      }
    </>
  );
};

export default SupplyChainMonitoring;

const SupplierChainPageLoader = () => {
  return (
    <ContentLoader
      speed={2}
      width={"100%"}
      height={"100%"}
      viewBox="0 0 1500 900"
      backgroundColor="#F6F8FB"
      foregroundColor="#ecebeb"
    >
      <rect x="3%" y="25" rx="8" ry="8" width="95%" height="100" />

      <rect x="3%" y="160" rx="4" ry="4" width="8%" height="25" />
      <rect x="14%" y="160" rx="4" ry="4" width="11%" height="25" />

      <rect x="3%" y="230" rx="4" ry="4" width="95%" height="80" />
      <rect x="3%" y="312" rx="4" ry="4" width="100%" height="80" />
      <rect x="3%" y="394" rx="4" ry="4" width="100%" height="80" />
      <rect x="3%" y="476" rx="4" ry="4" width="100%" height="80" />
      <rect x="3%" y="558" rx="4" ry="4" width="100%" height="80" />
      <rect x="3%" y="640" rx="4" ry="4" width="100%" height="80" />
    </ContentLoader>
  );
};

export const BlueComingSoonTag = () => {
  return (
    <div
      className="d-flex align-items-center justify-content-center rounded-full w-fit-content f-9"
      style={{
        background: "#D6EDF8",
        color: "#008FD4",
        padding: "0px 6px",
      }}
    >
      Coming Soon
    </div>
  );
};
export const BlueTag = ({ text, className }) => {
  return (
    <div
      className={`d-inline-flex align-items-center justify-content-center rounded-full w-fit-content f-9 f-600 ${className}`}
      style={{
        background: "#D6EDF8",
        color: "#008FD4",
        padding: "0px 6px",
      }}
    >
      {text}
    </div>
  );
};
export const GreyTag = ({ text, className }) => {
  return (
    <div
      className={`d-inline-flex align-items-center justify-content-center rounded-full w-fit-content f-9 f-600     ${className}}`}
      style={{
        background: "#EBEDF1",
        color: "#616778",
        padding: "0px 6px",
      }}
    >
      {text}
    </div>
  );
};
