import React, { useState, useEffect, Fragment } from "react";
import {
  Button,
  Modal,
  Row,
  Col,
  InputGroup,
  Form,
  OverlayTrigger,
  FormControl,
  Tooltip,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import "./style.scss";
import Loader from "./loader";
import SendSystemContent from "./SendSystemContent";
import PaymentsContent from "./PaymentsContent";

const SendSystemEmail = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isResponse, setIsResponse] = useState(false);
  const [activeModal, setActiveModal] = useState("");
  const [emailCount, setEmailCount] = useState("");
  const [failCount, setFailCount] = useState(0);
  const [msgRes, setMsgRes] = useState("");
  

  const backButton = () => {
    setIsResponse(false)
  }

  const data = [
    {
      title: "Cyber Essential Status",
      data: [
        {
          title: "Cyber Essentials Certification is due for Renewal",
          checked: false,
          id: 25,
        },
        {
          title: "Time to perform a Cyber Essentials Audit",
          checked: false,
          id: 26,
        },
        {
          title: "Cyber Essentials Certification has Expired",
          checked: false,
          id: 27,
        },
        {
          title: "Overdue a Cyber Essentials Audit",
          checked: false,
          id: 28,
        },
      ],
    },
    {
      title: "IR Plan Status",
      data: [
        {
          title: "Missing important Incident Response Documents",
          checked: false,
          id: 29,
        },
        {
          title: "Time to perform an Incident Response Review",
          checked: false,
          id: 30,
        },
        {
          title: "Overdue an Incident Response Review",
          checked: false,
          id: 31,
        },
      ],
    },
    {
      title: "Information Security Policies",
      data: [
        {
          title: "Missing 1 or more Information Security Policies",
          checked: false,
          id: 32,
        },
        {
          title: "Significant problems with Information Security Policies",
          checked: false,
          id: 33,
        },
        {
          title: "Overdue an Incident Response Review",
          checked: false,
          id: 0,
        },
      ],
    },
    {
      title: "Risk Assessment",
      data: [
        {
          title: "Time to Perform another Risk Assessment",
          checked: false,
          id: 34,
        },
        {
          title: "Complete open Risk Assessment",
          checked: false,
          id: 35,
        },
        {
          title: "Perform Risk Assessment",
          checked: false,
          id: 36,
        },
      ],
    },
    {
      title: "Annual Cyber Review",
      data: [
        {
          title: "Time to Perform an Annual Cyber Review with your Board",
          checked: false,
          id: 37,
        },
        {
          title: "Complete open Annual Cyber Review with your Board",
          checked: false,
          id: 38,
        },
        {
          title: "Overdue an Annual Cyber Review with your Board",
          checked: false,
          id: 39,
        },
      ],
    },
    {
      title: "BCDR Plan",
      data: [
        {
          title: "Time to Perform a BCDR Test Recovery Drill",
          checked: false,
          id: 40,
        },
        {
          title: "BCDR Plan is Missing",
          checked: false,
          id: 41,
        },
        {
          title: "Complete open BCDR Test Recovery Drill",
          checked: false,
          id: 42,
        },
        {
          title: "Overdue a BCDR Test Recovery Drill",
          checked: false,
          id: 43,
        },
      ],
    },
  ];

  const data2 = [
    {
      title: "General",
      data: [
        {
          title: "Integration has been connected successfully. ",
          checked: false,
          id: 47,
        },
        {
          title: "Integration has been disconnected",
          checked: false,
          id: 48,
        },
        {
          title: "Integration was successfully updated",
          checked: false,
          id: 49,
        },
      ],
    },
    {
      title: "Statuses",
      data: [
        {
          title: "Integration is Synchronized and Healthy",
          checked: false,
          id: 44,
        },
        {
          title: "Integration is in a Warning State",
          checked: false,
          id: 45,
        },
        {
          title: "Integration is in a Failed State",
          checked: false,
          id: 46,
        },
      ],
    },
  ];

  const data3 = [
    {
      title: "C-Suite Cyber Report",
      data: [
        {
          title: "C-Suite Cyber Report (Freemium)",
          checked: false,
          id: 51,
        },
        {
          title: "Scheduled C-Suite Cyber Report (Premium)",
          checked: false,
          id: 52,
        },
        {
          title: "Send a report to your CXO ",
          checked: false,
          id: 53,
        },
      ],
    },
    {
      title: "Pricing Calculator",
      data: [
        {
          title: "Pricing Calculator Scenario",
          checked: false,
          id: 50,
        },
      ],
    },
  ];

  const data4 = [
    {
      title: "Payment Methods",
      checked: false,
      id: 70,
      data: [
        {
          title: "Payment method added",
          checked: false,
          id: 71,
        },
        {
          title: "Payment method removed",
          checked: false,
          id: 72,
        },
        {
          title: "Subscription payment method updated",
          checked: false,
          id: 73,
        },
        {
          title: "Integration Pack payment method updated",
          checked: false,
          id: 74,
        },
        {
          title: "Pro Service payment method updated",
          checked: false,
          id: 75,
        },
      ],
    },
    {
      title: "Premium Subscription",
      checked: false,
      id: 76,
      data: [
        {
          title: "Upgrade to Premium",
          checked: false,
          id: 77,
        },
        {
          title: "Downgrade to Freemium",
          checked: false,
          id: 78,
        },
      ],
    },
    {
      title: "Failed to pay Premium Subscription",
      checked: false,
      id: 79,
      data: [
        {
          title: "14 days",
          checked: false,
          id: 80,
        },
        {
          title: "10 days",
          checked: false,
          id: 81,
        },
        {
          title: "7 days",
          checked: false,
          id: 82,
        },
        {
          title: "3 days",
          checked: false,
          id: 83,
        },
        {
          title: "Downgraded",
          checked: false,
          id: 84,
        },
      ],
    },
    {
      title: "Integrations Packs",
      checked: false,
      id: 85,
      data: [
        {
          title: "Integration Pack purchased",
          checked: false,
          id: 86,
        },
        {
          title: "Integration Pack downgraded",
          checked: false,
          id: 87,
        },
      ],
    },
    {
      title: "Failed to Pay Integrations Packs",
      checked: false,
      id: 88,
      data: [
        {
          title: "14 days",
          checked: false,
          id: 89,
        },
        {
          title: "10 days",
          checked: false,
          id: 90,
        },
        {
          title: "7 days",
          checked: false,
          id: 91,
        },
        {
          title: "3 days",
          checked: false,
          id: 92,
        },
        {
          title: "Downgraded",
          checked: false,
          id: 93,
        },
      ],
    },
    {
      title: "Pro Service",
      checked: false,
      id: 94,
      data: [
        {
          title: "Pro Service purchased",
          checked: false,
          id: 95,
        },
        {
          title: "Pro Service cancelled",
          checked: false,
          id: 96,
        },
      ],
    },
    {
      title: "Failed to Pay Pro Services",
      checked: false,
      id: 97,
      data: [
        {
          title: "14 days",
          checked: false,
          id: 98,
        },
        {
          title: "10 days",
          checked: false,
          id: 99,
        },
        {
          title: "7 days",
          checked: false,
          id: 100,
        },
        {
          title: "3 days",
          checked: false,
          id: 101,
        },
        {
          title: "Cancelled",
          checked: false,
          id: 102,
        },
      ],
    },
  ];

  return (
    <div className="w-100">
      {/* Compliance Manager Statuses */}
      <div>
        <div
          className="d-flex align-items-center pb-3 crsrpoint"
          onClick={() => setActiveModal("compliance")}
        >
          <div>
            <img src="/images/icons/greycheck.svg" className="pr-1" />
          </div>

          <div className="arricon w-100">
            <p className="m-0 titletxt"> Compliance Manager Statuses</p>
            <p className="m-0 titlesm"> Emails from Compliance Manager</p>
          </div>
        </div>
        <Col
          xs={12}
          className={`collapsabileContainer companyModal sendNotificationWapper ${
            activeModal != "compliance" && "d-none"
          }`}
        >
          {isLoading && <Loader isLoading={isLoading} emailCount={emailCount} />}
          {isResponse && <Loader backScreen={backButton} isResponse={isResponse} emailCount={emailCount} failCounts={failCount} message={msgRes} />}

          <div>
            <p className="m-0 titletxt mb-5">
              <img
                src="/images/backarw.svg"
                className="pr-3 crsrpoint"
                onClick={() => setActiveModal("")}
              />
              Compliance Manager Statuses
            </p>
            <SendSystemContent
              data={data}
              setResponse={setIsResponse}
              setLoading={setIsLoading}
              setCount={setEmailCount}
              failCount={setFailCount}
              msgRes={setMsgRes}
            />
          </div>
        </Col>
      </div>

      {/* Integration Statuses */}
      <div>
        <div
          className="d-flex align-items-center brdtb pb-3 pt-3 crsrpoint"
          onClick={() => setActiveModal("integration")}
        >
          <div>
            <img src="/images/icons/plug.svg" className="pr-1" />
          </div>
          <div className="arricon w-100">
            <p className="m-0 titletxt"> Integration Statuses</p>
            <p className="m-0 titlesm"> Emails from Integration</p>
          </div>
        </div>
        <Col
          xs={12}
          className={`collapsabileContainer companyModal sendNotificationWapper ${
            activeModal != "integration" && "d-none"
          }`}
        >
          {isLoading && <Loader isLoading={isLoading} emailCount={emailCount} />}
          {isResponse && <Loader backScreen={backButton} isResponse={isResponse} emailCount={emailCount} failCounts={failCount} message={msgRes}/>}

          <div>
            <p className="m-0 titletxt mb-5">
              <img
                src="/images/backarw.svg"
                className="pr-3 crsrpoint"
                onClick={() => setActiveModal("")}
              />
              Integration Statuses
            </p>
            <SendSystemContent
              data={data2}
              setResponse={setIsResponse}
              setLoading={setIsLoading}
              setCount={setEmailCount}
              failCount={setFailCount}
              msgRes={setMsgRes}

            />
          </div>
        </Col>
      </div>

       {/* Payment */}
       <div>
        <div
          className="d-flex align-items-center brdtb pb-3 pt-3 crsrpoint"
          onClick={() => setActiveModal("payments")}
        >
          <div>
            <img src="/images/icons/dollar.svg" className="pr-1" />
          </div>
          <div className="arricon w-100">
            <p className="m-0 titletxt"> Payments</p>
            <p className="m-0 titlesm">
              Emails payment methods and subscription statuses
            </p>
          </div>
        </div>
        <Col
          xs={12}
          className={`collapsabileContainer companyModal sendNotificationWapper ${
            activeModal != "payments" && "d-none"
          }`}
        >
          {isLoading && <Loader isLoading={isLoading} emailCount={emailCount}  />}
          {isResponse && <Loader backScreen={backButton} isResponse={isResponse} emailCount={emailCount} failCounts={failCount} message={msgRes}/>}

          <div>
            <p className="m-0 titletxt mb-5">
              <img
                src="/images/backarw.svg"
                className="pr-3 crsrpoint"
                onClick={() => setActiveModal("")}
              />
              Payments
            </p>
            <PaymentsContent
              data={data4}
              setResponse={setIsResponse}
              setLoading={setIsLoading}
              setCount={setEmailCount}
              failCount={setFailCount}
              msgRes={setMsgRes}

            />
          </div>
        </Col>
      </div>

      {/* Other Statuses */}
      <div>
        <div
          className="d-flex align-items-center pb-3 pt-3 crsrpoint"
          onClick={() => setActiveModal("other")}
        >
          <div>
            <img src="/images/icons/plug.svg" className="pr-1" />
          </div>
          <div className="arricon w-100">
            <p className="m-0 titletxt"> Other</p>
            <p className="m-0 titlesm">
              C-Suite Cyber Report, Pricing Calculator...
            </p>
          </div>
        </div>
        <Col
          xs={12}
          className={`collapsabileContainer companyModal sendNotificationWapper ${
            activeModal != "other" && "d-none"
          }`}
        >
          {isLoading && <Loader isLoading={isLoading} emailCount={emailCount}  />}
          {isResponse && <Loader backScreen={backButton} isResponse={isResponse} emailCount={emailCount} failCounts={failCount} message={msgRes}/>}

          <div>
            <p className="m-0 titletxt mb-5">
              <img
                src="/images/backarw.svg"
                className="pr-3 crsrpoint"
                onClick={() => setActiveModal("")}
              />
              Other
            </p>
            <SendSystemContent
              data={data3}
              setResponse={setIsResponse}
              setLoading={setIsLoading}
              setCount={setEmailCount}
              failCount={setFailCount}
              msgRes={setMsgRes}

            />
          </div>
        </Col>
      </div>
    </div>
  );
};

export default SendSystemEmail;
