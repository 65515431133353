import React, { useState } from "react";
import CyberCompliance from "../CyberCompliance/CyberCompliance";
import { Line } from "react-chartjs-2";
import MSPCatSubcatSortFilters from "../MSPv2/clients/MSPCatSubcatSortFilters";
import { Col, Row } from "react-bootstrap";
import { AttestationPopUp } from "../AttackSurface/KPIpopup/KPIpopup";
import { Attestation_Icon_Mappings } from "../ComplianceManager/WebFilteringBoxes/WebFilteringBoxes";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import ExploreMappingModal from "../SecurityAssessments/ExploreMappingModal";
import { downloadFileByLink } from "../../Utils/dowloadFileByLink";
import CyberGroupModal from "../AttackSurface/CyberGroupModal";

const GovernaceHomePage = ({pageData, getComplianceData,clientId}) => {


const history = useHistory()

const [showExploreMappingModal, setShowExploreMappingModal] = useState(false)
const [showCyberGroupModal, setShowCyberGroupModal] = useState(false)


  const data = {
    labels: ["Jan", "Feb", "March", "April", "May", "June", "July"],
    datasets: [
      {
        data: [15, 40, 5, 45, 60, 70, 20],
        label: "Africa",
        borderColor: "#008fd4",
        backgroundColor: "#008fd409",
        fill: true,
        pointRadius: 0,
        borderWidth: 2,
      },
    ],
  };
  function average(ctx) {
    const values = ctx.chart.data.datasets[0].data;
    return values.reduce((a, b) => a + b, 0) / values.length;
  }
  const _annotation = {
    type: "line",
    borderColor: "black",
    borderDash: [6, 6],
    borderDashOffset: 0,
    borderWidth: 3,
    label: {
      enabled: true,
      content: (ctx) => "Average: " + average(ctx).toFixed(2),
      position: "end",
    },
    scaleID: "y",
    value: (ctx) => {
      console.log(ctx);
      return average(ctx);
    },
  };
  const options = {
        legend: {
      display: false,
    },
    annotation: {
      annotations: [
        {
          type: "line",
          borderColor: "red",
          borderDash: [1, 1],
          borderDashOffset: 0,
          borderWidth: 1,
          label: {
            enabled: true,
            content: (ctx) => "Average: " + average(ctx).toFixed(2),
            // position: "end",
          },
          scaleID: "x",
          value: (ctx) => {
            console.log(ctx);
            return average(ctx);
          },
        },
      ],
    },
    datalabels: {
      display: false,
    },
    style: {
      strokewidth: 1,
    },
    scales: {
      xAxes: [
        {
          gridLines: {
            display: false,
          },
        },
      ],
      yAxes: [
        {
          gridLines: {
            display: false,
          },
          ticks: {
            display: false,
          },
        },
      ],
    },
    title: {
      display: false,
      //   text: "World population per region (in millions)",
    },

    // tooltips: {
    //   callbacks: {
    //     label: function (tooltipItem) {
    //       return "xcc";
    //     },
    //   },
    // },
    plugins: {

            legend: {
              display: false
            },
          
      autocolors: true,
      annotation: {
        annotations: {
          _annotation,
          // line1: {
          //   type: "line",
          //   yMin: 60,
          //   yMax: 60,
          //   borderColor: "rgb(255, 99, 132)",
          //   borderWidth: 2,
          // },
        },
      },
      //   zoom: {
      //     zoom: {
      //       wheel: {
      //         enabled: true,
      //       },
      //       pinch: {
      //         enabled: true,
      //       },
      //       mode: "y",
      //     },
      //   },
      datalabels: {
        backgroundColor: "transparent",
        // backgroundColor: function (context) {
        //   return context.dataset.backgroundColor;
        // },
        // borderRadius: 4,
        color: "transparent",
        // font: {
        //   weight: "bold",
        // },
        // formatter: Math.round,
        // padding: 6,
      },
    },
  };
 
  return (
    <div className="px-2">
      {/* Graphical Banner */}
      <div
        className=" bg-grey radius-8"
        style={{
          display: "grid",
          gridTemplateColumns: "24% 48% 28%",
        }}
      >
        {/* First block */}
        <div className="border-right  position-relative cyber_pre_data_popover_parent px-5 py-5">
       {pageData?.ComplianceScore===0 &&   <div className="position-absolute cyber_pre_data_popover radius-8">
            <div className="mx-2 f-black mt-4">
              Answer some quick questions to map your clients cyber security
              posture
            </div>
            <div
              className="f-blue mt-3 ml-2 pointer"
              onClick={() => {
                  setShowCyberGroupModal(true)
              }}
            >
              Get started
              <img
                src="/images/msp/blue-right-icon.svg"
                alt=""
                className="ml-2"
              />
            </div>
          </div>}
          <div className="graph complianceGraph z-Index-0 position-relative">
            <CyberCompliance
              ccNumber={pageData?.ComplianceScore}
              dataStroke={20}
              fontSize={22}
              id="governnace-cyber-comliance"
              wh={150}
              whpx="150px"
            />
          </div>
          <div className="pointer f-500 f-darkgrey mt-3 text-center" onClick={() => {
                  setShowCyberGroupModal(true)
              }}>
            View Details{" "}
            <img src="/images/chevron-right.svg" alt="" className="ml-2" />
          </div>
        </div>
        {/* Second Block */}
        <div className="border-right zoom-independent position-relative">
        {
            pageData?.Plot_HistoryChart===false &&
            <>
            
            <div className="position-absolute top-0 right-0 bottom-0 left-0 text-center p-5 f-600 f-16 f-grey d-flex align-items-center justify-content-center"
            style={{
              zIndex:5
            }}
            
            >
{pageData?.HistoryChart_NoPlotMessage}
            </div>
            
            <div className="position-absolute top-0 right-0 bottom-0 left-0"
            style={{
              background:"#ffffffbf"
            }}
            >

            </div>
            </>
          }
          <div className="p-3">
            <div className="d-flex al;ign-items-center justify-content-between">
              <div className="f-500 f-grey">Governance & Resilience</div>
              <div>
                <img
                  alt=""
                  src="/images/attack-surface/verified.svg"
                  className=""
                />
              </div>
            </div>
            <div className="f-18 f-500 f-black mt-1"> {pageData?.ComplianceScore}%</div>
          </div>
          <div>
            {/* <canvas id="line-chart" width="150" height="70"></canvas> */}
            <Line redraw={false} data={data} options={options} height={90} />
          </div>
        </div>

        {/* Third Block */}

        <div className="p-3">
          <div className="d-flex align-items-center mb-2">
            <img
              src="/images/msp/governance-resources-icon.svg"
              alt=""
              className="mr-2"
            />
            <div className="f-500 f-grey">Resources</div>
            <div className="radius-4 f-white px-2 ml-2 bg-blue f-9 ">
              FREE
            </div>
          </div>

          {pageData?.ResourcesComponent_List?.map((item, intemIndex) => {
            return (
              <div className="p-2 bg-hover-dark-grey d-flex align-items-center justify-content-between hover-on-show-parent pointer radius-4">
                <div className="f-500 f-black text-ellipsis w-90">
                  <img
                    src="/images/msp/governance-file-icon.svg"
                    alt=""
                    className="mr-2"
                  />
                  {item.Title}
                </div>

                <div className="pointer" onClick={()=>{
                  downloadFileByLink(item.FileUrl,item.Title)
                }}>
                  <img
                    src="/images/msp/governance-cloud-icon.svg"
                    alt=""
                    className="mr-2 hover-on-show-child"
                  />
                </div>
              </div>
            );
          })}
        </div>
      </div>

      <div className="d-flex align-items-center justify-content-between mx-2 mt-3">
        <div className="f-600 f-18 f-black">Components</div>
        <div className="d-flex align-items-center">
          <div className="d-flex align-items-center f-500 f-grey mr-2 pointer"
          onClick={()=>{
            setShowExploreMappingModal(true)
          }}
          
          
          >
            <img
              src="/images/security-assessment/explore-mapping-icon.svg"
              className="mr-2"
              alt=""
            />
            Explore Frameworks
          </div>
          <div>
            <MSPCatSubcatSortFilters
              headersList={pageData?.GovernanceResilienceSortVisibility_List}
              id="oreo"
              NoDataStateText={"No Items !!"}
              updatedHeaderList={(e) => {
                console.log(e,"GovernanceResilienceSortVisibility_List")

                getComplianceData({
                  optionStrEnum1:JSON.stringify(e)
                })
              }}
            >
              <div className=" py-2 px-3 radius-4">
                <img
                  src="/images/settings-v2/horizontal-dots.svg"
                  className=""
                  alt=""
                />
              </div>
            </MSPCatSubcatSortFilters>
          </div>
        </div>
      </div>

      <Row className="m-0">
        {pageData?.ComplanceBoxList?.sort((a, b) => a?.SortOrder - b?.SortOrder)?.map((box, itemIndex) => {
          return (
            <Col
            onClick={()=>{
              if(box?.AllowDrillThrough){
                 
                history.push(`/Compliance_Manager/` + box.BoxLink)
                

              }
            }}
            
            
            className={`bg-grey bg-hover-dark-grey pointer p-3 flex flex-column
            ${itemIndex % 3 === 2 ? "" : "border-right"}

            ${itemIndex > pageData?.ComplanceBoxList?.length -4 ? "" : "border-bottom"}
            ` }xs={4}>
              <div className="d-flex align-items-center justify-content-between mb-2">
                <div className="d-flex align-items-center">
                  <div className="mr-2">
                    <AttestationPopUp>
                      <img
                        alt=""
                        src={
                          Attestation_Icon_Mappings[
                            box?.CBv2Data?.CBAttestation
                          ]
                        }
                        className=""
                      />
                    </AttestationPopUp>
                  </div>
                  <div className="f-500 f-black">{box?.CBoxTitle}</div>
                </div>

                <div
                  className="d-flex align-items-center "
                  style={{
                    color: box?.CBv2Data?.CBTitle_Right_Colour,
                  }}
                >
                  {box?.CBv2Data?.CBTitle_Right_Icon && (
                    <img
                      src={box?.CBv2Data?.CBTitle_Right_Icon}
                      width={14}
                      height={14}
                      alt=""
                      className="mr-2"
                    />
                  )}
                  {box?.CBv2Data?.CBTitle_Right}
                </div>
              </div>

              {box.CBv2Data?.CBAttestation === 0 ? (
                <div
                  className="d-flex align-items-center justify-content-center radius-4 f-500 f-darkgrey flex-1"
                  style={{
                    border: "1px dashed #d5dfd6",
                    minHeight: "150px",
                  }}
                >
                  <img
                    src="/images/msp/governance-plus-icon.svg"
                    alt=""
                    className="mr-2"
                  />
                  Add Data
                </div>
              ) : (
                <>
                  <div className="d-flex align-items-center f-12 f-500 f-black mt-2 mb-2">
                    <img src={box?.CBv2Data?.CBTitle_Summary_Icon} alt="" className="mr-2" />
                    {box?.CBv2Data?.CBTitle_Summary}
                  </div>
                  

                  {box?.CBoxItems?.map((item) => {
                    return (
                      <div className="d-flex align-items-center justify-content-between mb-1 f-grey">
                        <div>{item?.item} </div>
                        <div>
                          {" "}
                         {item?.icon && <img
                            src={item?.icon}
                            alt=""
                            width={13}
                            height={13}
                            className="mr-1"
                          />}{" "}
                          {item?.answer}{" "}
                        </div>
                      </div>
                    );
                  })}
                  {box?.CBoxItems?.length > 3 && (
                    <div className="f-12 f-darkgrey">
                      + {box?.CBoxItems?.length - 3} more frameworks
                    </div>
                  )}
                  {/* {box?.CBoxAuditStatusText && (
                    <div
                      className="d-flex align-items-center justify-content-between mb-2"
                      style={{
                        color: box?.CBoxAuditStatusColour,
                      }}
                    >
                      <div>{box?.CBoxAuditStatusText} </div>
                    </div>
                  )} */}
                  {/* {box?.CBoxDateTxt && (
                    <div
                      className="d-flex align-items-center justify-content-between mb-2"
                      style={
                        {
                          // color:box?.CBoxAuditStatusColour
                        }
                      }
                    >
                      <div>{box?.CBoxDateTxt} </div>
                    </div>
                  )} */}
                  {/* );
                  })} */}
                  {/* <div className="f-12 f-darkgrey">+ 3 more frameworks</div> */}

                  {(box?.CBv2Data?.CBFooter_Icon || box?.CBv2Data?.CBFooter) && (
                    <div className="f-grey f-12 f-500">
                    {box?.CBv2Data?.CBFooter_Icon&&  <img
                        src={box?.CBv2Data?.CBFooter_Icon}
                        className="mr-2"
                        alt=""
                       
                      />}
                      {box?.CBv2Data?.CBFooter}
                    </div>
                  )}
                </>
              )}
            </Col>
          );
        })}
      </Row>
      <ExploreMappingModal
        show={showExploreMappingModal}
        hideModal={() => {
          setShowExploreMappingModal(false);
        }}
      />

      <div className="p-5"></div>
      <CyberGroupModal
      isSaas={true}
        show={showCyberGroupModal}
        hideModal={() => {
          setShowCyberGroupModal(false);
          getComplianceData();
        }}
        clientId={clientId}
        parentID={"mainBody"}
        defaultTab={"cyberCompliance"}
        AccessStateLabel={"AccessState_ComplianceManager"}
      />
    </div>
  );
};

export default GovernaceHomePage;
const dummyData=[
    {
      "FieldKey": '12154',
      "FieldName": "Cyber Essentials",
      "CategoryColour": "Purple",
      "CategoryColourHexCode": "#5244B3",
      "CategoryValue_3Months": 0,
      "CategoryValue_6Months": 0,
      "CategoryValue_12Months": 0,
      "CategoryValue_3Months_Text": "£0",
      "CategoryValue_6Months_Text": "£0",
      "CategoryValue_12Months_Text": "£0",
      "CategoryValue_Total": 50,
      "CategoryValue_Total_Text": "£50.00",
      "Visibility": true,
      "IsEditable": true,
      "IsDefault": true,
      "IsManual": false,
      "ColourSortOrder": 5,
      "CategorySortOrder": 0,
      "CategoryValue_3MonthsTotal": 0,
      "CategoryValue_6MonthsTotal": 0,
      "CategoryValue_12MonthsTotal": 0,
      "CategoryValue_3MonthsTotal_Text": null,
      "CategoryValue_6MonthsTotal_Text": null,
      "CategoryValue_12MonthsTotal_Text": null,
      "CurrencyCode": null,
      "ShowTax": false,
      "TechnologySpend_SummaryList": null,
      "mr": null,
      "ShowBanner": false,
      "PSAImportConfiguredInProccess": false,
      "PSAImportFinished": false,
      "IntegrationDetails": null
    },
    {
      "FieldKey": '12155',
      "FieldName": "Incident Response Plan",
      "CategoryColour": "Violet",
      "CategoryColourHexCode": "#3D42DE",
      "CategoryValue_3Months": 0,
      "CategoryValue_6Months": 0,
      "CategoryValue_12Months": 0,
      "CategoryValue_3Months_Text": "£0",
      "CategoryValue_6Months_Text": "£0",
      "CategoryValue_12Months_Text": "£0",
      "CategoryValue_Total": 0,
      "CategoryValue_Total_Text": "£0",
      "Visibility": true,
      "IsEditable": true,
      "IsDefault": true,
      "IsManual": false,
      "ColourSortOrder": 15,
      "CategorySortOrder": 0,
      "CategoryValue_3MonthsTotal": 0,
      "CategoryValue_6MonthsTotal": 0,
      "CategoryValue_12MonthsTotal": 0,
      "CategoryValue_3MonthsTotal_Text": null,
      "CategoryValue_6MonthsTotal_Text": null,
      "CategoryValue_12MonthsTotal_Text": null,
      "CurrencyCode": null,
      "ShowTax": false,
      "TechnologySpend_SummaryList": null,
      "mr": null,
      "ShowBanner": false,
      "PSAImportConfiguredInProccess": false,
      "PSAImportFinished": false,
      "IntegrationDetails": null
    },
    {
      "FieldKey": '12156',
      "FieldName": "Information Security Policies",
      "CategoryColour": "Blue",
      "CategoryColourHexCode": "#008FD4",
      "CategoryValue_3Months": 0,
      "CategoryValue_6Months": 0,
      "CategoryValue_12Months": 0,
      "CategoryValue_3Months_Text": "£0",
      "CategoryValue_6Months_Text": "£0",
      "CategoryValue_12Months_Text": "£0",
      "CategoryValue_Total": 0,
      "CategoryValue_Total_Text": "£0",
      "Visibility": true,
      "IsEditable": true,
      "IsDefault": true,
      "IsManual": false,
      "ColourSortOrder": 25,
      "CategorySortOrder": 0,
      "CategoryValue_3MonthsTotal": 0,
      "CategoryValue_6MonthsTotal": 0,
      "CategoryValue_12MonthsTotal": 0,
      "CategoryValue_3MonthsTotal_Text": null,
      "CategoryValue_6MonthsTotal_Text": null,
      "CategoryValue_12MonthsTotal_Text": null,
      "CurrencyCode": null,
      "ShowTax": false,
      "TechnologySpend_SummaryList": null,
      "mr": null,
      "ShowBanner": false,
      "PSAImportConfiguredInProccess": false,
      "PSAImportFinished": false,
      "IntegrationDetails": null
    },
    {
      "FieldKey": '12157',
      "FieldName": "Annual Cyber Board Review",
      "CategoryColour": "Green",
      "CategoryColourHexCode": "#40D88E",
      "CategoryValue_3Months": 0,
      "CategoryValue_6Months": 0,
      "CategoryValue_12Months": 0,
      "CategoryValue_3Months_Text": "£0",
      "CategoryValue_6Months_Text": "£0",
      "CategoryValue_12Months_Text": "£0",
      "CategoryValue_Total": 0,
      "CategoryValue_Total_Text": "£0",
      "Visibility": true,
      "IsEditable": true,
      "IsDefault": true,
      "IsManual": false,
      "ColourSortOrder": 35,
      "CategorySortOrder": 0,
      "CategoryValue_3MonthsTotal": 0,
      "CategoryValue_6MonthsTotal": 0,
      "CategoryValue_12MonthsTotal": 0,
      "CategoryValue_3MonthsTotal_Text": null,
      "CategoryValue_6MonthsTotal_Text": null,
      "CategoryValue_12MonthsTotal_Text": null,
      "CurrencyCode": null,
      "ShowTax": false,
      "TechnologySpend_SummaryList": null,
      "mr": null,
      "ShowBanner": false,
      "PSAImportConfiguredInProccess": false,
      "PSAImportFinished": false,
      "IntegrationDetails": null
    },
    {
      "FieldKey": '12158',
      "FieldName": "BCDR Plan",
      "CategoryColour": "Yellow",
      "CategoryColourHexCode": "#FFAC2C",
      "CategoryValue_3Months": 0,
      "CategoryValue_6Months": 0,
      "CategoryValue_12Months": 0,
      "CategoryValue_3Months_Text": "£0",
      "CategoryValue_6Months_Text": "£0",
      "CategoryValue_12Months_Text": "£0",
      "CategoryValue_Total": 0,
      "CategoryValue_Total_Text": "£0",
      "Visibility": true,
      "IsEditable": true,
      "IsDefault": true,
      "IsManual": false,
      "ColourSortOrder": 45,
      "CategorySortOrder": 0,
      "CategoryValue_3MonthsTotal": 0,
      "CategoryValue_6MonthsTotal": 0,
      "CategoryValue_12MonthsTotal": 0,
      "CategoryValue_3MonthsTotal_Text": null,
      "CategoryValue_6MonthsTotal_Text": null,
      "CategoryValue_12MonthsTotal_Text": null,
      "CurrencyCode": null,
      "ShowTax": false,
      "TechnologySpend_SummaryList": null,
      "mr": null,
      "ShowBanner": false,
      "PSAImportConfiguredInProccess": false,
      "PSAImportFinished": false,
      "IntegrationDetails": null
    },
    {
      "FieldKey": '12159',
      "FieldName": "Security Frameworks",
      "CategoryColour": "Orange",
      "CategoryColourHexCode": "#DF6844",
      "CategoryValue_3Months": 0,
      "CategoryValue_6Months": 0,
      "CategoryValue_12Months": 0,
      "CategoryValue_3Months_Text": "£0",
      "CategoryValue_6Months_Text": "£0",
      "CategoryValue_12Months_Text": "£0",
      "CategoryValue_Total": 0,
      "CategoryValue_Total_Text": "£0",
      "Visibility": true,
      "IsEditable": true,
      "IsDefault": true,
      "IsManual": false,
      "ColourSortOrder": 55,
      "CategorySortOrder": 0,
      "CategoryValue_3MonthsTotal": 0,
      "CategoryValue_6MonthsTotal": 0,
      "CategoryValue_12MonthsTotal": 0,
      "CategoryValue_3MonthsTotal_Text": null,
      "CategoryValue_6MonthsTotal_Text": null,
      "CategoryValue_12MonthsTotal_Text": null,
      "CurrencyCode": null,
      "ShowTax": false,
      "TechnologySpend_SummaryList": null,
      "mr": null,
      "ShowBanner": false,
      "PSAImportConfiguredInProccess": false,
      "PSAImportFinished": false,
      "IntegrationDetails": null
    },
     
  ]


  const ComponentsData=[
    {
        name:"Cyber Essentials",
        preData:true
    },
    {
        name:"Risk Assessment",
        preData:false
    },
    {
        name:"Cyber Essentials",
        preData:true
    },
    {
        name:"Cyber Essentials",
        preData:true
    },
    {
        name:"Risk Assessment",
        preData:false
    },
    {
        name:"Cyber Essentials",
        preData:true
    },
    {
        name:"Cyber Essentials",
        preData:true
    },
    {
        name:"Risk Assessment",
        preData:false
    },
    {
        name:"Cyber Essentials",
        preData:true
    },
  ]