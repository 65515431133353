import React from "react";
import { Row } from "react-bootstrap";
import ContentLoader from "react-content-loader";


const TechnicalDrillLoader = (props) => {
  return (
    <Row style={{ paddingTop: "2%" }}>            
        <ContentLoader
        speed={2}
        width={1500}
        height={600}
        viewBox="0 0 1500 600"
        backgroundColor="#F6F8FB"
        foregroundColor="#ecebeb"
        {...props}
        >
        <rect x="9" y="36" rx="12" ry="12" width="720" height="86" />
        <rect x="760" y="36" rx="12" ry="12" width="720" height="86" />

        <rect x="11" y="143" rx="12" ry="12" width="720" height="86" />
        <rect x="760" y="143" rx="12" ry="12" width="720" height="86" />

        <rect x="13" y="250" rx="12" ry="12" width="720" height="86" />
        <rect x="760" y="250" rx="12" ry="12" width="720" height="86" />

        <rect x="15" y="359" rx="12" ry="12" width="720" height="86" />
        <rect x="760" y="359" rx="12" ry="12" width="720" height="86" />

        <rect x="17" y="468" rx="12" ry="12" width="720" height="86" />
        <rect x="760" y="468" rx="12" ry="12" width="720" height="86" />
        </ContentLoader>            
    </Row>
  );
};

export default TechnicalDrillLoader;
