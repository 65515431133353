export const tabnames = [
  {
    title: "Custom Section",
    tabledata: [
      {
        checkbox: false,
        title: "Dask Title",
        date: "21/06/2020",
        owner: "Jane Cooper",
        img: "images/actioncenter/avatar.svg",
        module: "Compliance Cooper",
        status: "New",
        statusclr: "#616778",
        statusbg: "#EBEDF1",
        details: "Details",
      },
      {
        checkbox: false,
        title: "Task Title2",
        date: "21/06/2020",
        img: "images/actioncenter/avatar.svg",
        owner: "Jane Cooper",
        module: "Compliance Cooper",
        status: "In Progress",
        statusclr: "#008FD4",
        statusbg: "#D6EDF8",
        details: "Details",
      },
      {
        checkbox: false,
        title: "Task Title3",
        date: "21/06/2020",
        img: "images/actioncenter/avatar.svg",
        owner: "Jane Cooper",
        module: "Compliance Cooper",
        status: "In Progress",
        statusclr: "#008FD4",
        statusbg: "#D6EDF8",
        details: "Details",
      },
      {
        checkbox: false,
        title: "Task Title4",
        date: "21/06/2020",
        img: "images/actioncenter/avatar.svg",
        owner: "Jane Cooper",
        module: "Compliance Cooper",
        status: "In Progress",
        statusclr: "#008FD4",
        statusbg: "#D6EDF8",
        details: "Details",
      },
      {
        checkbox: false,
        title: "Task Title",
        date: "21/06/2020",
        img: "images/actioncenter/avatar.svg",
        owner: "Jane Cooper",
        module: "Compliance Cooper",
        status: "In Review",
        statusclr: "#FFAC2C",
        statusbg: "#FFF2DD",
        details: "Details",
      },
      {
        checkbox: false,
        title: "Task Title",
        date: "21/06/2020",
        img: "images/actioncenter/avatar.svg",
        owner: "Jane Cooper",
        module: "Compliance Cooper",
        status: "In Review",
        statusclr: "#FFAC2C",
        statusbg: "#FFF2DD",
        details: "Details",
      },
      {
        checkbox: false,
        title: "Task Title",
        date: "21/06/2020",
        img: "images/actioncenter/avatar.svg",
        owner: "Jane Cooper",
        module: "Compliance Cooper",
        status: "In Review",
        statusclr: "#FFAC2C",
        statusbg: "#FFF2DD",
        details: "Details",
      },
      {
        checkbox: false,
        title: "Task Title",
        date: "21/06/2020",
        img: "images/actioncenter/avatar.svg",
        owner: "Jane Cooper",
        module: "Compliance Cooper",
        status: "In Review",
        statusclr: "#FFAC2C",
        statusbg: "#FFF2DD",
        details: "Details",
      },

      {
        checkbox: true,
        title: "Task Title",
        date: "21/06/2020",
        img: "images/actioncenter/avatar.svg",
        owner: "Jane Cooper",
        module: "Compliance Cooper",
        status: "Completed",
        statusclr: "#40D88E",
        statusbg: "#E0F9ED",
        details: "Details",
      },
      {
        checkbox: true,
        title: "Task Title",
        date: "21/06/2020",
        img: "images/actioncenter/placeholder.svg",
        owner: "HignGround",
        module: "Compliance Cooper",
        status: "Completed",
        statusclr: "#40D88E",
        statusbg: "#E0F9ED",
        details: "Details",
      },
      {
        checkbox: true,
        title: "Task Title",
        date: "21/06/2020",
        img: "images/actioncenter/placeholder.svg",
        owner: "HignGround",
        module: "Compliance Cooper",
        status: "Completed",
        statusclr: "#40D88E",
        statusbg: "#E0F9ED",
        details: "Details",
      },
      {
        checkbox: true,
        title: "Task Title",
        date: "21/06/2020",
        img: "images/actioncenter/placeholder.svg",
        owner: "HignGround",
        module: "Compliance Cooper",
        status: "Completed",
        statusclr: "#40D88E",
        statusbg: "#E0F9ED",
        details: "Details",
      },
      {
        checkbox: true,
        title: "Task Title",
        date: "21/06/2020",
        img: "images/actioncenter/placeholder.svg",
        owner: "HignGround",
        module: "Compliance Cooper",
        status: "Completed",
        statusclr: "#40D88E",
        statusbg: "#E0F9ED",
        details: "Details",
      },
    ],
  },
  {
    title: "Nulla",
    tabledata: [
      {
        checkbox: false,
        title: "Task Title",
        date: "21/06/2020",
        owner: "Jane Cooper",
        img: "images/actioncenter/avatar.svg",
        module: "Compliance Cooper",
        status: "New",
        statusclr: "#616778",
        statusbg: "#EBEDF1",
        details: "Details",
      },
      {
        checkbox: false,
        title: "Task Title2",
        date: "21/06/2020",
        img: "images/actioncenter/avatar.svg",
        owner: "Jane Cooper",
        module: "Compliance Cooper",
        status: "In Progress",
        statusclr: "#008FD4",
        statusbg: "#D6EDF8",
        details: "Details",
      },
      {
        checkbox: false,
        title: "Task Title3",
        date: "21/06/2020",
        img: "images/actioncenter/avatar.svg",
        owner: "Jane Cooper",
        module: "Compliance Cooper",
        status: "In Progress",
        statusclr: "#008FD4",
        statusbg: "#D6EDF8",
        details: "Details",
      },
      {
        checkbox: false,
        title: "Task Title4",
        date: "21/06/2020",
        img: "images/actioncenter/avatar.svg",
        owner: "Jane Cooper",
        module: "Compliance Cooper",
        status: "In Progress",
        statusclr: "#008FD4",
        statusbg: "#D6EDF8",
        details: "Details",
      },
      {
        checkbox: false,
        title: "Task Title",
        date: "21/06/2020",
        img: "images/actioncenter/avatar.svg",
        owner: "Jane Cooper",
        module: "Compliance Cooper",
        status: "In Review",
        statusclr: "#FFAC2C",
        statusbg: "#FFF2DD",
        details: "Details",
      },
      {
        checkbox: false,
        title: "Task Title",
        date: "21/06/2020",
        img: "images/actioncenter/avatar.svg",
        owner: "Jane Cooper",
        module: "Compliance Cooper",
        status: "In Review",
        statusclr: "#FFAC2C",
        statusbg: "#FFF2DD",
        details: "Details",
      },
      {
        checkbox: false,
        title: "Task Title",
        date: "21/06/2020",
        img: "images/actioncenter/avatar.svg",
        owner: "Jane Cooper",
        module: "Compliance Cooper",
        status: "In Review",
        statusclr: "#FFAC2C",
        statusbg: "#FFF2DD",
        details: "Details",
      },
      {
        checkbox: false,
        title: "Task Title",
        date: "21/06/2020",
        img: "images/actioncenter/avatar.svg",
        owner: "Jane Cooper",
        module: "Compliance Cooper",
        status: "In Review",
        statusclr: "#FFAC2C",
        statusbg: "#FFF2DD",
        details: "Details",
      },

      {
        checkbox: true,
        title: "Task Title",
        date: "21/06/2020",
        img: "images/actioncenter/avatar.svg",
        owner: "Jane Cooper",
        module: "Compliance Cooper",
        status: "Completed",
        statusclr: "#40D88E",
        statusbg: "#E0F9ED",
        details: "Details",
      },
      {
        checkbox: true,
        title: "Task Title",
        date: "21/06/2020",
        img: "images/actioncenter/placeholder.svg",
        owner: "HignGround",
        module: "Compliance Cooper",
        status: "Completed",
        statusclr: "#40D88E",
        statusbg: "#E0F9ED",
        details: "Details",
      },
      {
        checkbox: true,
        title: "Task Title",
        date: "21/06/2020",
        img: "images/actioncenter/placeholder.svg",
        owner: "HignGround",
        module: "Compliance Cooper",
        status: "Completed",
        statusclr: "#40D88E",
        statusbg: "#E0F9ED",
        details: "Details",
      },
      {
        checkbox: true,
        title: "Task Title",
        date: "21/06/2020",
        img: "images/actioncenter/placeholder.svg",
        owner: "HignGround",
        module: "Compliance Cooper",
        status: "Completed",
        statusclr: "#40D88E",
        statusbg: "#E0F9ED",
        details: "Details",
      },
      {
        checkbox: true,
        title: "Task Title",
        date: "21/06/2020",
        img: "images/actioncenter/placeholder.svg",
        owner: "HignGround",
        module: "Compliance Cooper",
        status: "Completed",
        statusclr: "#40D88E",
        statusbg: "#E0F9ED",
        details: "Details",
      },
    ],
  },
  {
    title: "Quisque",
    tabledata: [
      {
        checkbox: false,
        title: "Task Title",
        date: "21/06/2020",
        img: "images/actioncenter/avatar.svg",
        owner: "Jane Cooper",
        module: "Compliance Cooper",
        status: "In Review",
        statusclr: "#FFAC2C",
        statusbg: "#FFF2DD",
        details: "Details",
      },

      {
        checkbox: true,
        title: "Task Title",
        date: "21/06/2020",
        img: "images/actioncenter/avatar.svg",
        owner: "Jane Cooper",
        module: "Compliance Cooper",
        status: "Completed",
        statusclr: "#40D88E",
        statusbg: "#E0F9ED",
        details: "Details",
      },
      {
        checkbox: true,
        title: "Task Title",
        date: "21/06/2020",
        img: "images/actioncenter/placeholder.svg",
        owner: "HignGround",
        module: "Compliance Cooper",
        status: "Completed",
        statusclr: "#40D88E",
        statusbg: "#E0F9ED",
        details: "Details",
      },
    ],
  },
  {
    title: "Nunv",
    tabledata: [
      {
        checkbox: false,
        title: "Task Title",
        date: "21/06/2020",
        img: "images/actioncenter/avatar.svg",
        owner: "Jane Cooper",
        module: "Compliance Cooper",
        status: "In Review",
        statusclr: "#FFAC2C",
        statusbg: "#FFF2DD",
        details: "Details",
      },

      {
        checkbox: true,
        title: "Task Title",
        date: "21/06/2020",
        img: "images/actioncenter/avatar.svg",
        owner: "Jane Cooper",
        module: "Compliance Cooper",
        status: "Completed",
        statusclr: "#40D88E",
        statusbg: "#E0F9ED",
        details: "Details",
      },
      {
        checkbox: false,
        title: "Task Title",
        date: "21/06/2020",
        img: "images/actioncenter/avatar.svg",
        owner: "Jane Cooper",
        module: "Compliance Cooper",
        status: "In Review",
        statusclr: "#FFAC2C",
        statusbg: "#FFF2DD",
        details: "Details",
      },
    ],
  },
];
