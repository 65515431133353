import { useEffect, useState } from "react";

const useToggleRow = ({ multiple = false, defaultOpen = false, rowRef }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleRow = () => {
    const parent = rowRef.current;
    if (!parent) return;
    const panel = parent?.querySelector(".panel");
    if (!multiple) {
      const allPoints = document?.querySelectorAll(".point");
      allPoints?.forEach(function (point) {
        const otherPanel = point.querySelector(".panel");
        if (otherPanel !== panel && otherPanel.style.maxHeight) {
          otherPanel.style.maxHeight = null;
        }
      });
    }
    if (panel?.style?.maxHeight) {
      panel.style.maxHeight = null;
      setIsOpen(false);
    } else {
      panel.style.maxHeight = panel.scrollHeight + "px";
      setIsOpen(true);
    }
  }

  const refreshState = () => {
    const parent = rowRef.current;
    if (!parent) return;
    const panel = parent?.querySelector(".panel");
    if (panel?.style?.maxHeight) {
      panel.style.maxHeight = panel.scrollHeight + "px";
    }
  }

  useEffect(() => {
    if (!defaultOpen) return;
    const parent = rowRef.current;
    const panel = parent?.querySelector(".panel");
    if (!panel) return;
    const originalTransition = panel.style.transition;
    panel.style.transition = 'none';
    toggleRow();
    setTimeout(() => {
      panel.style.transition = originalTransition;
    }, 0);
  }, [defaultOpen]);

  return { toggleRow, refreshState, isOpen };
}

export default useToggleRow;
