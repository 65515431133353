import React, { useEffect, useState } from "react";
import CompanySettingsDropdown from "../../../settingsV2/companySettings/companysettingsdrodown/CompanySettingsDropdown";
import CyberEssentialAuditDropdown, {
  getDateFormatForMoment,
} from "./createClientComplianceDropdowns/CyberEssentialAuditDropdown";
import SecurityPoliciesDropdown from "./createClientComplianceDropdowns/SecurityPoliciesDropdown";
import IncidentResponsePlanDropdown from "./createClientComplianceDropdowns/IncidentResponsePlanDropdown";
import AnnualCyberReviewDropdown from "./createClientComplianceDropdowns/AnnualCyberReviewDropdown";
import { useAuth } from "../../../context/Auth";
import {
  IndependentGetAPIData,
  IndependentSubmitQuestionaire,
  SubmitQuestionaire,
  getAPIData,
} from "../../../../Utils/commonApi";
import { CallToast } from "../../../PriceCalculator/modals/CustomToast";
import ContentLoader from "react-content-loader";
import { handleDateForInvoice } from "../../MspAttackSurface.js/technologySpendModal/TechnologySpendModal";
import { Dropdown, Form } from "react-bootstrap";
import CyberRiskAssessmentAuditDropdown from "./createClientComplianceDropdowns/CyberRiskAssessmentAuditDropdown";
import CyberEssentialsCertificationDropdown from "./createClientComplianceDropdowns/CyberEssentialsCertificationDropdown";
import { AttestationPopUp } from "../../../AttackSurface/KPIpopup/KPIpopup";
import {
  Attestation_Icon_Mappings,
  Attestation_Icon_Text_Mappings,
} from "../../../ComplianceManager/WebFilteringBoxes/WebFilteringBoxes";
import CyberComplianceDateDropdown from "./createClientComplianceDropdowns/CyberComplianceDateDropdown";
import "../../../AttackSurface/drilldownModal.scss";
import CustomInputField from "../../../Theme/CustomInputField";
import { HGHighlightText, viewOnlyAccess } from "../../../App/App";
import { TrimString } from "../../../../Utils/StringTrimmer";
import Loader from "../../../Common/loader";
import { ShowNeverExplanationList } from '../../../../constants/kpi-drilldown.constants';
import SearchInput from "../../../Common/search-input/search-input.component";
import SearchTitle from '../../../Common/search-title.component';
import useDropdown from "../../../../hooks/dropdown.hook";
import KPISettingsDropdown from "../../../../Components/AttackSurface/kpi-settings-dropdown/kpi-settings.dropdown";

const CyberComplianceForGroupModal = ({
  clientId,
  AccessStateLabel,
  passedOptionEnum99,
  showAttestationIcons,
  setShowAttestationIcons
}) => {
  const { authTokens } = useAuth();
  const [searchComponent, setSearchComponent] = useState('');
  const [questionList, setQuestionList] = useState([]);
  const [mainPageLoading, setMainPageLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [originalData, setOriginalData] = useState([]);
  const [pageData, setPageData] = useState({});
  useEffect(() => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    setMainPageLoading(true);
    getPageData();
  }, []);

  function ToggleAccordion(e, work = true) {
    if (!work) return;
    e.preventDefault();
    var parent = e.target.closest(".point");
    var panel = parent.querySelector(".panel");
    // Get all elements with class "point" and close their panels
    var allPoints = document.querySelectorAll(".point");
    allPoints.forEach(function (point) {
      let otherPanel = point.querySelector(".panel");
      let down_arrow_icon = point.querySelector(".rowDownArrow");
      if (otherPanel !== panel && otherPanel.style.maxHeight) {
        otherPanel.style.maxHeight = null;
        otherPanel.style.overflow = "hidden";
        if (down_arrow_icon) {
          down_arrow_icon.classList.remove("rotate180");
        }
      }
    });
    let down_arrow = parent.querySelector(".rowDownArrow");

    if (panel?.style?.maxHeight) {
      panel.style.maxHeight = null;
      panel.style.overflow = "hidden";
      if (down_arrow) {
        down_arrow.classList.remove("rotate180");
      }
    } else {
      panel.style.maxHeight = panel.scrollHeight + "px";
      panel.style.overflow = "visible";
      if (down_arrow) {
        down_arrow.classList.add("rotate180");
      }
    }
  }

  function getPageData(success = false) {
    if (passedOptionEnum99) {
      IndependentGetAPIData(680, authTokens, {
        optionEnum1: 0,
        optionEnum3: clientId ?? 0,
        optionEnum99: passedOptionEnum99,
      }).then((response) => {
        setMainPageLoading(false);
        setLoading(false);
        if (response?.mr?.Success) {
          setPageData(response);
          setOriginalData(response?.QuestionnaireSection_List);
          if (success) {
            let updated_data = [...questionList].map((item, index) => {
              return {
                ...item,
                AnswerId:
                  response?.QuestionnaireSection_List?.[1]?.Question_List[index]
                    .AnswerId,
              };
            });

            setQuestionList(updated_data);
          } else {
            let updated_data = [...response?.QuestionnaireSection_List].map(
              (section) => ({
                ...section,
                Question_List: section.Question_List.map((item) => {
                  return {
                    ...item,
                    selectedDropdownValue: item?.AnswerText
                      ? item?.QuestionDropdownListMembers?.map(
                          (listItem, index) => {
                            return {
                              id: index + 1,
                              category: listItem,
                            };
                          }
                        ).filter((filteritem) => {
                          return filteritem.category === item?.AnswerText;
                        })[0]
                      : null,
                    dropDowndata: item?.QuestionDropdownListMembers
                      ? item?.QuestionDropdownListMembers?.map(
                          (listItem, index) => {
                            return {
                              id: index + 1,
                              category: listItem,
                            };
                          }
                        )
                      : [],
                    loading: false,
                    AnswerJson:
                      item.QuestionTypeText === "CurrencyDropdown" &&
                      !item.AnswerJson
                        ? item.CurrencyCode_List.find(
                            (item) => item?.IsDefault === true
                          )
                        : item.AnswerJson,
                  };
                }),
              })
            );

            setQuestionList(updated_data);
          }
        }
      });
    } else {
      getAPIData(680, authTokens, {
        optionEnum1: 0,
        optionEnum3: clientId ?? 0,
      }).then((response) => {
        setMainPageLoading(false);
        setLoading(false);
        if (response?.mr?.Success) {
          setPageData(response);
          setOriginalData(response?.QuestionnaireSection_List);
          if (success) {
            let updated_data = [...questionList].map((item, index) => {
              return {
                ...item,
                AnswerId:
                  response?.QuestionnaireSection_List?.[1]?.Question_List[index]
                    .AnswerId,
              };
            });

            setQuestionList(updated_data);
          } else {
            let updated_data = [...response?.QuestionnaireSection_List].map(
              (section) => ({
                ...section,
                Question_List: section.Question_List.map((item) => {
                  return {
                    ...item,
                    selectedDropdownValue: item?.AnswerText
                      ? item?.QuestionDropdownListMembers?.map(
                          (listItem, index) => {
                            return {
                              id: index + 1,
                              category: listItem,
                            };
                          }
                        ).filter((filteritem) => {
                          return filteritem.category === item?.AnswerText;
                        })[0]
                      : null,
                    dropDowndata: item?.QuestionDropdownListMembers
                      ? item?.QuestionDropdownListMembers?.map(
                          (listItem, index) => {
                            return {
                              id: index + 1,
                              category: listItem,
                            };
                          }
                        )
                      : [],
                    loading: false,
                    AnswerJson:
                      item.QuestionTypeText === "CurrencyDropdown" &&
                      !item.AnswerJson
                        ? item.CurrencyCode_List.find(
                            (item) => item?.IsDefault === true
                          )
                        : item.AnswerJson,
                  };
                }),
              })
            );

            setQuestionList(updated_data);
          }
        }
      });
    }
  }
  const SubmitQuestions = async (data_to_send) => {
    setLoading(true);
    if (passedOptionEnum99) {
      await IndependentSubmitQuestionaire(authTokens, "POST", {
        ObjectId: 0,
        QuestionnaireTypeEnum: 30,
        ObjId_Supplier: clientId,
        objId_SendFormQuestionnaire: 0,
        QuestionList: JSON.stringify(data_to_send),
        objId_Service: 0,
        accesskey: JSON.parse(localStorage.getItem("user_MessageObj")),
        optionEnum99: passedOptionEnum99,
      }).then((response) => {
        getPageData();
        if (response?.Success) {
          // CallToast("Updated successfully", false, false, "v2style");
        } else {
          CallToast("Something went wrong", false, false, "v2style");
        }
      });
    } else {
      await SubmitQuestionaire(authTokens, "POST", {
        ObjectId: 0,
        QuestionnaireTypeEnum: 30,
        ObjId_Supplier: clientId,
        objId_SendFormQuestionnaire: 0,
        QuestionList: JSON.stringify(data_to_send),
        objId_Service: 0,
        accesskey: JSON.parse(localStorage.getItem("user_MessageObj")),
      }).then((response) => {
        getPageData();
        if (response?.Success) {
          // CallToast("Updated successfully", false, false, "v2style");
        } else {
          CallToast("Something went wrong", false, false, "v2style");
        }
      });
    }
  };

  return (
    <>
      {mainPageLoading ? (
        <CreateClientComplianceLoader />
      ) : (
        <>
          <div className="search-container">
            <SearchInput 
              placeholder="Search" 
              value={searchComponent} 
              onChange={(value) => setSearchComponent(value)} 
            />
            <KPISettingsDropdown 
              showAttestationIcons={showAttestationIcons} 
              setShowAttestationIcons={setShowAttestationIcons}
              switchId="showAttestationIcons2"
            />
          </div>
          <div className="position-relative">
            {loading && (
              <div
                className="position-absolute"
                style={{
                  top: "0px",
                  left: "0px",
                  bottom: "0px",
                  right: "0px",
                  opacity: 0.5,
                  background: "#fff",
                  zIndex: 3,
                }}
              ></div>
            )}

            {/* <div className="f-16 f-600 mt-3 mb-2">Compliance</div> */}
            <div className="bg-grey radius-8 border-parent">
              <div className="border-item f-12 f-500 f-grey cyber-score-table-row header-section">
                <div>Component</div>
                <div>Details</div>
                <div>Impact on Score</div>
              </div>
              {questionList.map((question, questionIndex) => {
                const questionTitle = SearchTitle({ title: question.Title, searchValue: searchComponent });
                if (!questionTitle) return null;
                return (
                  <div
                    className=" border-item point"
                    onClick={(e) => {
                      if (
                        !viewOnlyAccess(
                          AccessStateLabel ?? "AccessState_Dashboard"
                        )
                      ) {
                        ToggleAccordion(e, true);
                      }
                    }}
                  >
                    <div
                      className={` align-items-center bg-hover-row w-100 row-section py-3 cyber-score-table-row ${
                        viewOnlyAccess(
                          AccessStateLabel ?? "AccessState_Dashboard"
                        )
                          ? "cursor-default"
                          : "pointer"
                      }`}
                    >
                      <div className="d-flex  align-items-center w-100">
                        {showAttestationIcons && (
                          <div className="mr-2">
                            <AttestationPopUp>
                              <img
                                alt=""
                                src={
                                  Attestation_Icon_Mappings[
                                    question?.SectionAttestation
                                  ]
                                }
                                className=""
                              />
                            </AttestationPopUp>
                          </div>
                        )}
                        <div>
                          <p className="f-500 m-0">{questionTitle}</p>
                          {/* <div className="f-12 f-darkgrey">
                            {" "}
                            {question?.QuestionDescription}{" "}
                          </div> */}
                        </div>
                      </div>
                      {/* Second Column */}
                      <div className="f-black">{question?.Description}</div>

                      {/* Thhird column */}
                      <div className="text-align-end">
                        <span
                          className="px-2 py-1 f-500 "
                          style={{
                            borderRadius: "50px",
                            background: `${question?.SectionScoreColour}50`,
                            color: `${question?.SectionScoreColour}`,
                          }}
                        >
                          {question?.SectionScore} of {question?.SectionMaxScore}
                        </span>
                        {viewOnlyAccess(
                          AccessStateLabel ?? "AccessState_Dashboard"
                        ) ? (
                          <img
                            alt=""
                            className="lockerDarkGreyImage mx-2 mb-1"
                            src="/images/settings-v2/locker-dark-grey.svg"
                          />
                        ) : (
                          <img
                            src="/images/big-chevron-down.svg"
                            alt=""
                            className="rowDownArrow mr-2 ml-2"
                          />
                        )}
                      </div>
                    </div>
                    <div className="panel" onClick={(e) => e.stopPropagation()}>
                      {question?.SubTitle && (
                        <div className="f-12 f-500 f-grey my-2 ml-4">
                          {question?.SubTitle}{" "}
                        </div>
                      )}
                      {question?.Question_List?.map((item, itemIndex) => {
                        const hasCheckbox = item?.QuestionTypeText === "DateAndCheckBox";
                        const isCheckBoxActive = hasCheckbox && item.AnswerBool;
                        const isAnswered = hasCheckbox
                          ? item.AnswerDate
                          : item.QuestionDrivedStatus !== 0 || item.QuestionDrivedStatus > 0;
                        const addBorder = hasCheckbox ? isCheckBoxActive && !isAnswered : !isAnswered;

                        const hasExpiredDate = item.QuestionText === "Cyber Essential Certificate";
                        const addRequiredBorder = hasExpiredDate && !item.AnswerDate;
                        const showNever = ShowNeverExplanationList.includes(item.Explanation);
                        return (
                          <div
                            className={`p-2 align-items-center justify-content-between bg-hover-row radius-4 mx-4 ${addBorder ? "bordered-option" : ''}`}
                            style={{
                              display: "grid",
                              gridTemplateColumns: "39% 51% 10%",
                            }}
                          >
                            <div className="d-flex">
                              {hasCheckbox ? (
                                <div className="mb-4 mr-3">
                                  <Form.Group
                                    controlId={
                                      "check1" + questionIndex + itemIndex
                                    }
                                    className={`${
                                      item?.QuestionStatusText?.toLowerCase() ===
                                      "verified"
                                        ? "disabled-checkbox"
                                        : ""
                                    } round-checkbox blue-checkbox checkboxstyle`}
                                  >
                                    <Form.Check
                                      type="checkbox"
                                      disabled={
                                        item?.QuestionStatusText?.toLowerCase() ===
                                        "verified"
                                      }
                                      name={"check" + itemIndex + 1}
                                      label=""
                                      checked={item?.AnswerBool}
                                      onChange={(e) => {
                                        let data_to_send = [...originalData];
                                        let updated_data = [...questionList];
                                        data_to_send[questionIndex][
                                          "Question_List"
                                        ][itemIndex].QuestionStatus = 3;
                                        data_to_send[questionIndex][
                                          "Question_List"
                                        ][itemIndex].QuestionStatusText =
                                          "Answered";
                                        data_to_send[questionIndex][
                                          "Question_List"
                                        ][itemIndex].AnswerBool =
                                          e.target.checked;
                                        updated_data[questionIndex][
                                          "Question_List"
                                        ][itemIndex].AnswerId = 123;
                                        updated_data[questionIndex][
                                          "Question_List"
                                        ][itemIndex].AnswerBool =
                                          e.target.checked;
                                        setOriginalData(data_to_send);
                                        SubmitQuestions([
                                          data_to_send[questionIndex][
                                            "Question_List"
                                          ][itemIndex],
                                        ]);
                                        setQuestionList(updated_data);
                                      }}
                                      // defaultChecked={elmt.StripeId}
                                    />
                                  </Form.Group>
                                </div>
                              ) : showAttestationIcons ? (
                                <AttestationPopUp>
                                  <img
                                    alt=""
                                    src={
                                      Attestation_Icon_Text_Mappings[
                                        item?.QuestionStatusText
                                      ]
                                    }
                                    className=" mr-2"
                                  />
                                </AttestationPopUp>
                              ) : null}

                              <div className="text-ellipsis">
                                {item?.QuestionText}
                              </div>
                            </div>

                            <div className="d-flex justify-content-end">
                              {(item?.QuestionTypeText === "DropdownList" ||
                                item?.QuestionDropdownListMembers?.length >
                                  1) && (
                                <div className="d-flex align-items-center w-60">
                                  <div className="f-darkgrey mr-3 white-space-no-wrap">
                                    {item.QuestionText ===
                                    "Cyber Essential Certificate"
                                      ? "Type"
                                      : null}
                                  </div>
                                  {viewOnlyAccess(
                                    AccessStateLabel ?? "AccessState_Dashboard"
                                  ) ? (
                                    <div className="f-darkgrey">
                                      {item?.selectedDropdownValue?.category ??
                                        "No Selected"}
                                    </div>
                                  ) : (
                                    <CustomHGDropdown
                                      minWidth={"200px"}
                                      disabled={
                                        item?.QuestionStatusText?.toLowerCase() ===
                                        "verified"
                                      }
                                      question={item?.QuestionDescription}
                                      fieldId={"id"}
                                      fieldValue={"category"}
                                      selected={item.selectedDropdownValue}
                                      placeholder="Choose"
                                      onClick={(id, val) => {
                                        //   To send Data to API
                                        let data_to_send = [...originalData];
                                        data_to_send[questionIndex][
                                          "Question_List"
                                        ][
                                          itemIndex
                                        ].AnswserSelectedDropdownItems = [val];
                                        data_to_send[questionIndex][
                                          "Question_List"
                                        ][itemIndex].AnswerText = val;
                                        data_to_send[questionIndex][
                                          "Question_List"
                                        ][itemIndex].QuestionStatus = 3;
                                        data_to_send[questionIndex][
                                          "Question_List"
                                        ][itemIndex].QuestionStatusText =
                                          "Answered";
                                        setOriginalData(data_to_send);
                                        SubmitQuestions([
                                          data_to_send[questionIndex][
                                            "Question_List"
                                          ][itemIndex],
                                        ]);

                                        // To Update the drop down value
                                        let updated_data = [...questionList];
                                        updated_data[questionIndex][
                                          "Question_List"
                                        ][itemIndex].selectedDropdownValue = {
                                          id,
                                          category: val,
                                        };
                                        updated_data[
                                          questionIndex
                                        ].AnswerId = 123;
                                        setQuestionList(updated_data);
                                      }}
                                      data={item?.dropDowndata}
                                    />
                                  )}
                                </div>
                              )}
                              {item?.QuestionTypeText === "Date" && (
                                <div className="ml-4 d-flex align-items-center w-60">
                                  <div className="f-darkgrey mr-3 white-space-no-wrap">
                                    {item.QuestionText ===
                                    "Cyber Essential Certificate"
                                      ? ""
                                      : item?.Explanation}
                                  </div>
                                  {viewOnlyAccess(
                                    AccessStateLabel ?? "AccessState_Dashboard"
                                  ) ? (
                                    <div className="f-darkgrey">
                                      {handleDateForInvoice(item.AnswerDate) ??
                                        "No Selected"}
                                    </div>
                                  ) : (
                                    <CyberComplianceDateDropdown
                                      disabled={
                                        item?.QuestionStatusText?.toLowerCase() ===
                                        "verified"
                                      }
                                      placeholder={
                                        item.QuestionText ===
                                        "Cyber Essential Certificate"
                                          ? "Expiry Date"
                                          : null
                                      }
                                      id={
                                        "powr" +
                                        questionIndex +
                                        "dfxd" +
                                        itemIndex +
                                        "xub"
                                      }
                                      question={item}
                                      screenType={"calender"}
                                      showNever={showNever}
                                      selected={
                                        handleDateForInvoice(item.AnswerDate) ??
                                        null
                                        // getDateFormatForMoment(question.AnswerDate) ?? null
                                      }
                                      {...(addRequiredBorder && {
                                        className: "required-option"
                                      })}
                                      onChange={(e, type) => {
                                        //   To send Data to API
                                        let data_to_send = [...originalData];
                                        let updated_data = [...questionList];

                                        data_to_send[questionIndex][
                                          "Question_List"
                                        ][itemIndex].QuestionStatus = 3;
                                        data_to_send[questionIndex][
                                          "Question_List"
                                        ][itemIndex].QuestionStatusText =
                                          "Answered";

                                        // To Update the drop down value

                                        if (type === "text") {
                                          data_to_send[questionIndex][
                                            "Question_List"
                                          ][itemIndex].AnswerText = e;

                                          updated_data[questionIndex][
                                            "Question_List"
                                          ][itemIndex].AnswerText = e;

                                          updated_data[questionIndex][
                                            "Question_List"
                                          ][itemIndex].AnswerId = 123;
                                        }
                                        if (type === "date") {
                                          data_to_send[questionIndex][
                                            "Question_List"
                                          ][itemIndex].AnswerDate =
                                            getDateFormatForMoment(e);

                                          updated_data[questionIndex][
                                            "Question_List"
                                          ][itemIndex].AnswerDate =
                                            getDateFormatForMoment(e);

                                          updated_data[questionIndex][
                                            "Question_List"
                                          ][itemIndex].AnswerId = 123;
                                        }

                                        if (!type && e == "") {
                                          data_to_send[questionIndex][
                                            "Question_List"
                                          ][itemIndex].AnswerDate = null;

                                          updated_data[questionIndex][
                                            "Question_List"
                                          ][itemIndex].AnswerDate = null;

                                          updated_data[questionIndex][
                                            "Question_List"
                                          ][itemIndex].AnswerId = 123;
                                        }

                                        setOriginalData(data_to_send);
                                        SubmitQuestions([
                                          data_to_send[questionIndex][
                                            "Question_List"
                                          ][itemIndex],
                                        ]);
                                        setQuestionList(updated_data);
                                      }}
                                    />
                                  )}
                                </div>
                              )}
                              {item?.QuestionTypeText === "DateAndCheckBox" && (
                                <div
                                  className="ml-4 d-flex align-items-center w-60"
                                  style={{
                                    visibility: item?.AnswerBool
                                      ? "visible"
                                      : "hidden",
                                  }}
                                >
                                  <div className="f-darkgrey mr-3 white-space-no-wrap">
                                    {item?.Explanation}
                                  </div>
                                  {viewOnlyAccess(
                                    AccessStateLabel ?? "AccessState_Dashboard"
                                  ) ? (
                                    <div className="f-darkgrey">
                                      {handleDateForInvoice(item.AnswerDate) ??
                                        "No Selected"}
                                    </div>
                                  ) : (
                                    <CyberComplianceDateDropdown
                                      disabled={
                                        item?.QuestionStatusText?.toLowerCase() ===
                                        "verified"
                                      }
                                      id={
                                        "powr" +
                                        questionIndex +
                                        "dfxd" +
                                        itemIndex +
                                        "xub"
                                      }
                                      question={item}
                                      screenType={"calender"}
                                      showNever={showNever}
                                      selected={
                                        handleDateForInvoice(item.AnswerDate) ??
                                        null
                                        // getDateFormatForMoment(question.AnswerDate) ?? null
                                      }
                                      onChange={(e, type) => {
                                        //   To send Data to API
                                        let data_to_send = [...originalData];
                                        let updated_data = [...questionList];

                                        data_to_send[questionIndex][
                                          "Question_List"
                                        ][itemIndex].QuestionStatus = 3;
                                        data_to_send[questionIndex][
                                          "Question_List"
                                        ][itemIndex].QuestionStatusText =
                                          "Answered";

                                        // To Update the drop down value

                                        if (type === "text") {
                                          data_to_send[questionIndex][
                                            "Question_List"
                                          ][itemIndex].AnswerText = e;

                                          updated_data[questionIndex][
                                            "Question_List"
                                          ][itemIndex].AnswerText = e;

                                          updated_data[questionIndex][
                                            "Question_List"
                                          ][itemIndex].AnswerId = 123;
                                        }
                                        if (type === "date") {
                                          data_to_send[questionIndex][
                                            "Question_List"
                                          ][itemIndex].AnswerDate =
                                            getDateFormatForMoment(e);

                                          updated_data[questionIndex][
                                            "Question_List"
                                          ][itemIndex].AnswerDate =
                                            getDateFormatForMoment(e);

                                          updated_data[questionIndex][
                                            "Question_List"
                                          ][itemIndex].AnswerId = 123;
                                        }

                                        setOriginalData(data_to_send);
                                        SubmitQuestions([
                                          data_to_send[questionIndex][
                                            "Question_List"
                                          ][itemIndex],
                                        ]);
                                        setQuestionList(updated_data);
                                      }}
                                    />
                                  )}
                                </div>
                              )}

                              {item?.QuestionTypeText === "CurrencyDropdown" && (
                                <div className="d-flex align-items-center w-60">
                                  <div className="mr-1">
                                    {viewOnlyAccess(
                                      AccessStateLabel ?? "AccessState_Dashboard"
                                    ) ? (
                                      <div className="f-darkgrey">
                                        {item?.AnswerJson?.FullCurrencyName ??
                                          "No Selected"}
                                      </div>
                                    ) : (
                                      <CustomHGDropdown
                                        disabled={
                                          item?.QuestionStatusText?.toLowerCase() ===
                                          "verified"
                                        }
                                        showSearch={true}
                                        greyBackground
                                        fieldId={"ObjectId"}
                                        fieldValue={"FullCurrencyName"}
                                        placeholder="Select"
                                        selected={item?.AnswerJson}
                                        onClick={(id, val, _item) => {
                                          //   To send Data to API
                                          let data_to_send = [...originalData];
                                          data_to_send[questionIndex][
                                            "Question_List"
                                          ][itemIndex].AnswerJson = _item;
                                          // data_to_send[questionIndex][
                                          //   "Question_List"
                                          // ][itemIndex].AnswerText = val;
                                          data_to_send[questionIndex][
                                            "Question_List"
                                          ][itemIndex].QuestionStatus = 3;
                                          data_to_send[questionIndex][
                                            "Question_List"
                                          ][itemIndex].QuestionStatusText =
                                            "Answered";
                                          setOriginalData(data_to_send);
                                          SubmitQuestions([
                                            data_to_send[questionIndex][
                                              "Question_List"
                                            ][itemIndex],
                                          ]);

                                          // To Update the drop down value
                                          let updated_data = [...questionList];

                                          updated_data[
                                            questionIndex
                                          ].AnswerId = 123;
                                          setQuestionList(updated_data);
                                        }}
                                        data={item?.CurrencyCode_List}
                                      />
                                    )}
                                  </div>
                                  <CustomInputField
                                    disabled={
                                      item?.QuestionStatusText?.toLowerCase() ===
                                      "verified"
                                    }
                                    prefix={
                                      item?.AnswerJson?.CurrencySymbol ?? ""
                                    }
                                    greyBorder
                                    placeholder={"0.00"}
                                    type={"number"}
                                    value={item?.AnswerText ?? ""}
                                    onChange={(e) => {
                                      let data_to_send = [...originalData];
                                      data_to_send[questionIndex][
                                        "Question_List"
                                      ][itemIndex].AnswerText = e.target.value;
                                      // data_to_send[questionIndex][
                                      //   "Question_List"
                                      // ][itemIndex].AnswerText = val;
                                      data_to_send[questionIndex][
                                        "Question_List"
                                      ][itemIndex].QuestionStatus = 3;
                                      data_to_send[questionIndex][
                                        "Question_List"
                                      ][itemIndex].QuestionStatusText =
                                        "Answered";
                                      setOriginalData(data_to_send);

                                      // To Update the drop down value
                                      let updated_data = [...questionList];
                                      updated_data[questionIndex][
                                        "Question_List"
                                      ][itemIndex].AnswerText = e.target.value;

                                      updated_data[questionIndex].AnswerId = 123;
                                      setQuestionList(updated_data);
                                    }}
                                    onBlur={() => {
                                      let data_to_send = [...originalData];

                                      if (
                                        data_to_send[questionIndex][
                                          "Question_List"
                                        ][itemIndex].AnswerText
                                      ) {
                                        SubmitQuestions([
                                          data_to_send[questionIndex][
                                            "Question_List"
                                          ][itemIndex],
                                        ]);
                                      }

                                      // To Update the drop down value
                                      // let updated_data = [...questionList];

                                      // updated_data[questionIndex].AnswerId = 123;
                                      // setQuestionList(updated_data);
                                    }}
                                    isError={question?.error}
                                    errorMsg={"This field must be valid."}
                                  />
                                </div>
                              )}
                            </div>

                            <div className="d-flex justify-content-end">
                              <img
                                alt=""
                                src={item?.QuestionDrivedStatus_Logo}
                                width={14}
                                height={14}
                                className="mr-2"
                              />
                            </div>
                          </div>
                        );
                      })}

                      {false && (
                        <div className="">
                          {questionIndex === 0 && (
                            <CyberEssentialsCertificationDropdown
                              id="powrdfxdxsiujnub"
                              question={test1}
                              selected={
                                handleDateForInvoice(test1.AnswerDate) ?? null
                                // getDateFormatForMoment(question.AnswerDate) ?? null
                              }
                              onChange={(e, type) => {
                                return;
                                //   To send Data to API
                                let data_to_send = [...originalData];
                                let updated_data = [...questionList];

                                data_to_send[questionIndex].QuestionStatus = 3;
                                data_to_send[questionIndex].QuestionStatusText =
                                  "Answered";

                                // To Update the drop down value

                                if (type === "text") {
                                  data_to_send[questionIndex].AnswerText = e;
                                  data_to_send[questionIndex].AnswerDate = null;

                                  updated_data[questionIndex].AnswerText = e;
                                  updated_data[questionIndex].AnswerDate = null;

                                  updated_data[questionIndex].AnswerId = 123;
                                }
                                if (type === "date") {
                                  data_to_send[questionIndex].AnswerText = "";
                                  data_to_send[questionIndex].AnswerDate =
                                    getDateFormatForMoment(e);

                                  updated_data[questionIndex].AnswerText = "";
                                  updated_data[questionIndex].AnswerDate =
                                    getDateFormatForMoment(e);

                                  updated_data[questionIndex].AnswerId = 123;
                                }

                                setOriginalData(data_to_send);
                                SubmitQuestions(data_to_send);
                                setQuestionList(updated_data);
                              }}
                            />
                          )}

                          {questionIndex === 1 && (
                            <CyberEssentialAuditDropdown
                              id="poiujnub"
                              question={question}
                              selected={
                                handleDateForInvoice(question.AnswerDate) ?? null
                              }
                              onChange={(e, type) => {
                                //   To send Data to API
                                let data_to_send = [...originalData];
                                let updated_data = [...questionList];

                                data_to_send[questionIndex].QuestionStatus = 3;
                                data_to_send[questionIndex].QuestionStatusText =
                                  "Answered";

                                // To Update the drop down value

                                if (type === "text") {
                                  data_to_send[questionIndex].AnswerText = e;
                                  data_to_send[questionIndex].AnswerDate = null;

                                  updated_data[questionIndex].AnswerText = e;
                                  updated_data[questionIndex].AnswerDate = null;

                                  updated_data[questionIndex].AnswerId = 123;
                                }
                                if (type === "date") {
                                  data_to_send[questionIndex].AnswerText = "";
                                  data_to_send[questionIndex].AnswerDate =
                                    getDateFormatForMoment(e);

                                  updated_data[questionIndex].AnswerText = "";
                                  updated_data[questionIndex].AnswerDate =
                                    getDateFormatForMoment(e);

                                  updated_data[questionIndex].AnswerId = 123;
                                }

                                setOriginalData(data_to_send);
                                SubmitQuestions(data_to_send);
                                setQuestionList(updated_data);
                              }}
                            />
                          )}
                          {questionIndex === 2 && (
                            <IncidentResponsePlanDropdown
                              id="powrfiujnub"
                              question={question}
                              selected={
                                handleDateForInvoice(question.AnswerDate) ?? null
                                // getDateFormatForMoment(question.AnswerDate) ?? null
                              }
                              onChange={(e, type) => {
                                console.log(e, type);
                                //   To send Data to API
                                let data_to_send = [...originalData];
                                let updated_data = [...questionList];

                                data_to_send[questionIndex].QuestionStatus = 3;
                                data_to_send[questionIndex].QuestionStatusText =
                                  "Answered";

                                // To Update the drop down value

                                if (type === "text") {
                                  data_to_send[questionIndex].AnswerText = e;
                                  data_to_send[questionIndex].AnswerDate = null;

                                  updated_data[questionIndex].AnswerText = e;
                                  updated_data[questionIndex].AnswerDate = null;

                                  updated_data[questionIndex].AnswerId = 123;
                                }
                                if (type === "date") {
                                  data_to_send[questionIndex].AnswerText = "";
                                  data_to_send[questionIndex].AnswerDate =
                                    getDateFormatForMoment(e);

                                  updated_data[questionIndex].AnswerText = "";
                                  updated_data[questionIndex].AnswerDate =
                                    getDateFormatForMoment(e);

                                  updated_data[questionIndex].AnswerId = 123;
                                }

                                setOriginalData(data_to_send);
                                SubmitQuestions(data_to_send);
                                setQuestionList(updated_data);
                              }}
                            />
                          )}

                          {questionIndex === 3 && (
                            <SecurityPoliciesDropdown
                              question={question}
                              onChange={(items = [], selection = true) => {
                                //   To send Data to API
                                let data_to_send = [...originalData];
                                let updated_data = [...questionList];

                                data_to_send[questionIndex].QuestionStatus = 3;
                                data_to_send[questionIndex].QuestionStatusText =
                                  "Answered";

                                // To Update the drop down value

                                if (selection === false) {
                                  data_to_send[questionIndex].AnswerBool = false;
                                  data_to_send[
                                    questionIndex
                                  ].AnswserSelectedDropdownItems = null;

                                  updated_data[questionIndex].AnswerBool = false;
                                  updated_data[
                                    questionIndex
                                  ].AnswserSelectedDropdownItems = null;

                                  updated_data[questionIndex].AnswerId = 123;
                                }
                                if (selection === true) {
                                  data_to_send[questionIndex].AnswerBool = true;
                                  data_to_send[
                                    questionIndex
                                  ].AnswserSelectedDropdownItems = items;

                                  updated_data[questionIndex].AnswerBool = true;
                                  updated_data[
                                    questionIndex
                                  ].AnswserSelectedDropdownItems = items;

                                  updated_data[questionIndex].AnswerId = 123;
                                }

                                setOriginalData(data_to_send);
                                SubmitQuestions(data_to_send);
                                setQuestionList(updated_data);
                              }}
                            />
                          )}
                          {questionIndex === 4 && (
                            <AnnualCyberReviewDropdown
                              id="powrfxsiujnub"
                              question={question}
                              selected={
                                handleDateForInvoice(question.AnswerDate) ?? null
                                // getDateFormatForMoment(question.AnswerDate) ?? null
                              }
                              onChange={(e, type) => {
                                //   To send Data to API
                                let data_to_send = [...originalData];
                                let updated_data = [...questionList];

                                data_to_send[questionIndex].QuestionStatus = 3;
                                data_to_send[questionIndex].QuestionStatusText =
                                  "Answered";

                                // To Update the drop down value

                                if (type === "text") {
                                  data_to_send[questionIndex].AnswerText = e;
                                  data_to_send[questionIndex].AnswerDate = null;

                                  updated_data[questionIndex].AnswerText = e;
                                  updated_data[questionIndex].AnswerDate = null;

                                  updated_data[questionIndex].AnswerId = 123;
                                }
                                if (type === "date") {
                                  data_to_send[questionIndex].AnswerText = "";
                                  data_to_send[questionIndex].AnswerDate =
                                    getDateFormatForMoment(e);

                                  updated_data[questionIndex].AnswerText = "";
                                  updated_data[questionIndex].AnswerDate =
                                    getDateFormatForMoment(e);

                                  updated_data[questionIndex].AnswerId = 123;
                                }

                                setOriginalData(data_to_send);
                                SubmitQuestions(data_to_send);
                                setQuestionList(updated_data);
                              }}
                            />
                          )}
                          {questionIndex === 5 && (
                            <CyberRiskAssessmentAuditDropdown
                              id="powrdfxsiujnub"
                              question={question}
                              selected={
                                handleDateForInvoice(question.AnswerDate) ?? null
                                // getDateFormatForMoment(question.AnswerDate) ?? null
                              }
                              onChange={(e, type) => {
                                //   To send Data to API
                                let data_to_send = [...originalData];
                                let updated_data = [...questionList];

                                data_to_send[questionIndex].QuestionStatus = 3;
                                data_to_send[questionIndex].QuestionStatusText =
                                  "Answered";

                                // To Update the drop down value

                                if (type === "text") {
                                  data_to_send[questionIndex].AnswerText = e;
                                  data_to_send[questionIndex].AnswerDate = null;

                                  updated_data[questionIndex].AnswerText = e;
                                  updated_data[questionIndex].AnswerDate = null;

                                  updated_data[questionIndex].AnswerId = 123;
                                }
                                if (type === "date") {
                                  data_to_send[questionIndex].AnswerText = "";
                                  data_to_send[questionIndex].AnswerDate =
                                    getDateFormatForMoment(e);

                                  updated_data[questionIndex].AnswerText = "";
                                  updated_data[questionIndex].AnswerDate =
                                    getDateFormatForMoment(e);

                                  updated_data[questionIndex].AnswerId = 123;
                                }

                                setOriginalData(data_to_send);
                                SubmitQuestions(data_to_send);
                                setQuestionList(updated_data);
                              }}
                            />
                          )}
                        </div>
                      )}
                      <div className="p-1" />
                    </div>
                  </div>
                );
              })}

              <div className="p-2 text-align-end">
                <span className="f-grey">Your CyberCompliance Score is</span>
                <span className="f-black f-500 mx-2">
                  {pageData?.TotalPercentageAnwered ?? 0}
                </span>
                <span
                  className=""
                  style={{
                    color: "#F36060",
                  }}
                >
                  High
                </span>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default CyberComplianceForGroupModal;

export const CreateClientComplianceLoader = () => {
  return (
    <ContentLoader
      speed={2}
      width={"100%"}
      height={"100%"}
      viewBox="0 0 1500 900"
      backgroundColor="#F6F8FB"
      foregroundColor="#ecebeb"
    >
      <rect x="0%" y="70" rx="8" ry="8" width="100%" height="140" />
      <rect x="0%" y="212" rx="8" ry="8" width="100%" height="140" />
      <rect x="0%" y="354" rx="8" ry="8" width="100%" height="140" />
      <rect x="0%" y="496" rx="8" ry="8" width="100%" height="140" />
      <rect x="0%" y="638" rx="8" ry="8" width="100%" height="140" />
      {/* <rect x="0%" y="780" rx="8" ry="8" width="100%" height="140" />
      <rect x="0%" y="922" rx="8" ry="8" width="100%" height="140" /> */}
    </ContentLoader>
  );
};

export const CustomHGDropdown = ({
  fieldId,
  fieldValue,
  selected,
  onClick,
  data = [],
  placeholder = "Select",
  placeholderClass,
  selectedItemClass,
  greyBackground,
  dropDownMenuClass,
  selectedColorBlack,
  dropDownToggleClass,
  placeDropDownAtTopRight,
  dataLoading,
  question,
  disabled,
  minWidth,
  selectedTextTrimLength,
  maxContentWidth,
  showSearch,
  prefix,
  contentwidth,
  putMenuAtRight,
  redBorder,
}) => {
  const [searchString, setSearchString] = useState("");

  return (
    <Dropdown className="transparennt-wrapper-dropdown ">
      <Dropdown.Toggle
        className={`p-0 ${
          greyBackground ? "bg-grey-imp" : ""
        }   ${dropDownToggleClass}  ${redBorder ? "input-error" : ""} `}
      >
        <div className="d-flex align-items-center  f-500 f-grey  px-2 p-2 w-100">
          {selected ? (
            <span
              className={`${selectedItemClass ?? ""} ${
                selectedColorBlack ? "f-black" : "f-grey"
              } f-500`}
            >
              {prefix && <span className="f-grey-imp">{prefix}: </span>}{" "}
              {TrimString(selected[fieldValue], selectedTextTrimLength ?? 40)}{" "}
            </span>
          ) : (
            <span className={`${placeholderClass ?? " f-darkgrey "}  `}>
              {prefix && <span className="f-grey-imp">{prefix}: </span>}{" "}
              {placeholder}{" "}
            </span>
          )}

          {!disabled && (
            <img
              src="/images/big-chevron-down.svg"
              alt=""
              className={placeDropDownAtTopRight ? "ml-auto" : "ml-2"}
            />
          )}
        </div>
      </Dropdown.Toggle>
      {!disabled && (
        <Dropdown.Menu
          className={`${dropDownMenuClass}  p-1 ${
            putMenuAtRight && "menu-inset-right-corner"
          }`}
          style={{
            // width: "200px",
            minWidth: minWidth ?? undefined,
          }}
        >
          {dataLoading ? (
            <div
              className="d-flex align-items-center justify-content-center w-100 "
              style={{
                height: "250px",
              }}
            >
              <Loader />
            </div>
          ) : (
            <>
              {question && (
                <div className="f-12 f-400 f-darkgrey mb-2">{question}</div>
              )}
              {showSearch && (
                <div className="d-flex align-items-center px-2 py-2 radius-4 mb-1"
                style={{
                  border:"1px solid #c8c8c8"
                }}
                
                >
                  <img
                    src="/images/attack-surface/search-icon.svg"
                    className="mr-1"
                    alt=""
                  />
                  <input
                    type="text"
                    className="flex-1 bg-transparent border-none pl-1"
                    placeholder="Search"
                    value={searchString}
                    onChange={(e) => {
                      setSearchString(e.target.value);
                    }}
                    autoFocus
                  />
                  <img
                    src="/images/settings-v2/header-search-cross.svg"
                    alt=""
                    className="mx-1 pointer"
                    style={{
                      visibility: searchString ? "visible" : "hidden",
                    }}
                    onClick={() => {
                      setSearchString("");
                    }}
                  />
                </div>
              )}

              {data?.filter((item) =>
                item?.[fieldValue]
                  .toLowerCase()
                  ?.includes(searchString?.toLowerCase())
              )?.length === 0 && (
                <div className="my-2 f-darkgrey w-100 pl-4">No data found</div>
              )}
              <div
                className="transparent-scroller"
                style={{
                  maxHeight: "250px",
                  overflowY: "auto",
                  width: contentwidth ?? "max-content",
                  maxWidth: maxContentWidth ?? "200px",
                }}
              >
                {data
                  ?.filter((item) =>
                    item?.[fieldValue]
                      .toLowerCase()
                      ?.includes(searchString?.toLowerCase())
                  )
                  ?.map((item, index) => {
                    return (
                      <div
                        className="d-flex align-items-center f-500 f-grey pointer radius-4 bg-hover-little-grey"
                        style={{
                          padding:"0.4rem"
                        }}
                        onClick={(e) => {
                          onClick(item[fieldId], item[fieldValue], item);
                          e.target
                            .closest(".transparennt-wrapper-dropdown")
                            .click();
                        }}
                      >
                        <img
                          src="/images/msp/dropdown-grey-tick.svg"
                          alt=""
                          className="mr-2"
                          style={{
                            visibility:
                              selected && selected[fieldId] === item[fieldId]
                                ? "visible"
                                : "hidden",
                          }}
                        />
                        <span
                      dangerouslySetInnerHTML={{
                        __html: HGHighlightText(
                          item[fieldValue],
                          searchString
                        ),
                      }}
                    />
                        
                      </div>
                    );
                  })}
              </div>
            </>
          )}
        </Dropdown.Menu>
      )}
    </Dropdown>
  );
};
export const CustomStringHGDropdown = ({
  showSearch,
  selected,
  onClick,
  data = [],
  placeholder = "Select",
  placeholderClass,
  selectedItemClass,
  greyBackground,
  disabled,
  setIsOpenState
}) => {
  const [searchString, setSearchString] = useState("");
  const { isOpen, refBtn, refMenu, setIsOpen } = useDropdown({ disableClickOutside: false });
  
  useEffect(() => {
    if (!setIsOpenState) return;
    setIsOpenState(isOpen);
  }, [isOpen]);
  
  return (
    <Dropdown
      show={isOpen}
      className="transparennt-wrapper-dropdown "
      onToggle={(e) => {
        if (e) {
          setSearchString("");
        }
        setIsOpen(prev => !prev);
      }}
    >
      <Dropdown.Toggle ref={refBtn} className={`p-0 ${greyBackground ? "bg-grey-imp" : ""}`}>
        <div className="d-flex align-items-center  f-500 f-grey  px-2 p-2">
          {selected ? (
            <span className={`${selectedItemClass ?? ""} f-grey f-500`}>
              {selected}{" "}
            </span>
          ) : (
            <span className={`${placeholderClass ?? " f-grey f-500"}  `}>
              {placeholder}{" "}
            </span>
          )}

          {!disabled && (
            <img src="/images/big-chevron-down.svg" alt="" className="ml-2" />
          )}
        </div>
      </Dropdown.Toggle>
      {!disabled && (
        <Dropdown.Menu
          ref={refMenu}
          className="p-2"
          style={{
            width: "auto",
            minWidth: "120px",
            maxWidth: "500px",
            overflowX: "hidden",
            height: "auto",
          }}
        >
          {" "}
          {showSearch && (
            <div className="d-flex align-items-center mx-2 mt-1 mb-2">
              <img
                src="/images/attack-surface/search-icon.svg"
                className="mr-1"
                alt=""
              />
              <input
                type="text"
                className="flex-1 bg-transparent border-none pl-1"
                placeholder="Search"
                value={searchString}
                onChange={(e) => {
                  setSearchString(e.target.value);
                }}
                autoFocus
              />
              <img
                src="/images/settings-v2/header-search-cross.svg"
                alt=""
                className="mx-1 pointer"
                style={{
                  visibility: searchString ? "visible" : "hidden",
                }}
                onClick={() => {
                  setSearchString("");
                }}
              />
            </div>
          )}
          {data?.filter((item) =>
            item?.toLowerCase()?.includes(searchString?.toLowerCase())
          )?.length === 0 && (
            <div className="my-2 f-darkgrey w-100 pl-4">No data found</div>
          )}
          <div
            className="transparent-scroller"
            style={{
              maxHeight: "200px",
              overflowY: "auto",
            }}
          >
            {data
              ?.filter((item) =>
                item?.toLowerCase()?.includes(searchString?.toLowerCase())
              )
              .map((item, index) => {
                return (
                  <div
                    className="d-flex align-items-center mb-2 f-500 f-grey pointer"
                    onClick={(e) => {
                      onClick(item, index);
                      setIsOpen(false);
                      e.target
                        .closest(".transparennt-wrapper-dropdown")
                        .click();
                    }}
                  >
                    <img
                      src="/images/msp/dropdown-grey-tick.svg"
                      alt=""
                      className="mr-2"
                      style={{
                        visibility:
                          selected && selected === item ? "visible" : "hidden",
                      }}
                    />
                    {item}
                  </div>
                );
              })}
          </div>
        </Dropdown.Menu>
      )}
    </Dropdown>
  );
};

const test1 = {
  QuestionId: 3917390,
  QuestionAllowEdit: true,
  QuestionOrder: 0,
  QuestionScore: 0,
  QuestionDescription: "Are you Cyber Essentials certified?",
  QuestionDropdownListMembers: ["No", "Basic", "Plus"],
  QuestionSectionNo: 2,
  QuestionStatus: 5,
  QuestionStatusText: "LoadedForEdit",
  QuestionText: "Cyber Essentials Certification",
  QuestionTypeEnum: 4,
  QuestionTypeText: "Date",
  QuestionCheckBoxLabel: "na",
  QuestionUserList: null,
  AnswerId: 0,
  AnswerBool: null,
  AnswerDate: null,
  AnswerText: null,
  AnswserSelectedDropdownItems: null,
  AnswerSelectedQuestionDDLObjId: 0,
  AnswerSelectedUserNames: null,
  DateTimeAnswer: null,
  Label: null,
  QuestionParentId: 0,
  ChildQuestions: null,
  ResponseComment_List: null,
  NISTAssetClassification_List: null,
  NoteAnswerText: null,
  Explanation: null,
  Statement: null,
  InheritedText: null,
  AnswerArrayText: null,
  ActivityTrackingContributorVM: null,
  UploadedFile: null,
  IsDisabled: false,
  selectedDropdownValue: null,
  dropDowndata: [
    {
      id: 1,
      category: "No",
    },
    {
      id: 2,
      category: "Basic",
    },
    {
      id: 3,
      category: "Plus",
    },
  ],
  loading: false,
};
