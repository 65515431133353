import React, { useState, useEffect } from "react";

const SlidingModal = ({
  children,
  show,
  id,
  width,
  parentID,
  onHide,
  className,
  height,
  marginTop,
  top,
  right,
  modalZindex,
  backdropZindex,
  isDraggableParent,
  style
}) => {
  //  assets-list parent = assets-list-full-screen-modal-parent
  const [modalClasses, setModalClasses] = useState("hg-sliding-modal");
  function toCloseModal() {
    if (onHide) {
      onHide();
    }
  }

  useEffect(() => {
    try {
      if (show === true) {
        document.body.insertAdjacentHTML(
          "beforeend",
          `<div id="sliding-modal-backdrop" style="z-index: ${backdropZindex};"></div>`
        );

        setTimeout(() => {
          setModalClasses("hg-sliding-modal open");
        }, 10);

        try {
          let backdrops = document.body.querySelectorAll(
            "#sliding-modal-backdrop"
          );
          if (backdrops.length > 0) {
            let lastBackdrop = backdrops[backdrops.length - 1];
            lastBackdrop.addEventListener("click", toCloseModal);
          }
        } catch (e) {
          console.log(e, "backdrops");
        }

        return () => {
          console.log("clean up done");
        };
      }
      if (show === false) {
        setModalClasses("hg-sliding-modal");

        let backdrops = document.body.querySelectorAll(
          "#sliding-modal-backdrop"
        );
        if (backdrops.length > 0) {
          let lastBackdrop = backdrops[backdrops.length - 1];
          lastBackdrop.remove();
        }
      }
    } catch (e) {
      console.log(e);
    }
  }, [show]);

  if (!show) {
    return <></>;
  }

  return (
    <div
      className={`${modalClasses} hide-scrollbar ${className}     ${
        isDraggableParent ? "hg-sliding-modal-draggable-parent" : ""
      }`}
      id={id}
      style={{
        width: width,
        ...(style || {}),
      }}
    >
      <div
        className="w-100 h-100 position-relative d-flex flex-column hide-scrollbar"
        style={{
          overflowX: "hidden",
        }}
      >
        {children}
      </div>
    </div>
  );
};

SlidingModal.Header = (props) => (
  <div
    className={`${props.className} position-sticky top-0 bg-white z-Index-1000`}
  >
    {props.children}
  </div>
);
SlidingModal.Body = (props) => (
  <div className={props.className}>{props.children}</div>
);
SlidingModal.Footer = (props) => (
  <div
    className={`${props.className}  bottom-0 bg-white z-Index-100 sliding-modal-footer`}
    style={{ marginTop: "auto" }}
  >
    {props.children}
  </div>
);

export default SlidingModal;




// import React, { useState, useEffect, useRef } from "react";

// const SlidingModal = ({
//   children,
//   show,
//   id,
//   width,
//   parentID,
//   onHide,
//   className,
//   height,
//   marginTop,
//   top,
//   right,
//   modalZindex,
//   backdropZindex,
//   isDraggableParent,
// }) => {
//   const [modalClasses, setModalClasses] = useState("hg-sliding-modal");
//   const modalRef = useRef(null);
//   const disableAnimationRef = useRef(false);
//   const [animationFinished, setAnimationFinished] = useState(false)

 

//   const animateModalOpen = (event) => {
//     if (disableAnimationRef.current) return;
//     if (animationFinished) return;
//     const modal = modalRef.current;
//     if (!modal) return;
//     const { top, left } = modal.getBoundingClientRect();
//     const { clientX, clientY } = event;
//     const translateX = clientX - left - modal.offsetWidth / 2;
//     const translateY = clientY - top - modal.offsetHeight / 2;
//     modal.style.transform = `translate(${translateX}px, ${translateY}px) scale(0)`;
//     modal.style.transition = "none";
    
//     setTimeout(() => {
//       modal.style.transform = "translate(0, 0) scale(1)";
//       modal.style.transition = "transform 0.7s ease-in-out";
//       // modal.style.right= "0"
//     }, 20);
//     setAnimationFinished(true)
//   };

//   useEffect(() => {
//     try {
//       if (show === true) {
       

//         setTimeout(() => {
//           setModalClasses("hg-sliding-modal open");
//           disableAnimationRef.current = true;
//         }, 10);

//         try {
         
//         } catch (e) {
//           console.log(e, "backdrops");
//         }
//       }

//       if (show === false) {
//         setModalClasses("hg-sliding-modal");
//         const modal = modalRef.current;
//     modal.style.transform = `undefined`;
         
//       }
//     } catch (e) {
//       console.log(e);
//     }
//   }, [show]);

 
  

//   useEffect(() => {
//     if (show) {
//       setTimeout(() => {
//         disableAnimationRef.current = false;
//       }, 50);
//     } else {
//       disableAnimationRef.current = false;
//       setAnimationFinished(false)
//     }
//   }, [show]);
//   const onOverlayClick = (e) => {
//     if (
//       e.target.classList.contains("overlay") ||
//       e.target.classList.contains("modal-container")
//     ) {
//       onHide();
//     }
//   };

//   if (!show) {
//     return null;
//   }

//   return (
//     <div id="sliding-modal-backdrop" className="overlay" onMouseEnter={animateModalOpen} onClick={onOverlayClick}>
//     <div
//       className={`${modalClasses} hide-scrollbar ${className} ${
//         isDraggableParent ? "hg-sliding-modal-draggable-parent" : ""
//       }`}
//       id={id}
//       ref={modalRef}
//       style={{ width }}
      
//     >
//       <div
//         className="w-100 h-100 position-relative d-flex flex-column hide-scrollbar"
//         style={{ overflowX: "hidden" }}
//       >
//         {children}
//       </div>
//     </div>
//   </div>
//   );
// };

// SlidingModal.Header = (props) => (
//   <div
//     className={`${props.className} position-sticky top-0 bg-white z-Index-1000`}
//   >
//     {props.children}
//   </div>
// );

// SlidingModal.Body = (props) => (
//   <div className={props.className}>{props.children}</div>
// );

// SlidingModal.Footer = (props) => (
//   <div
//     className={`${props.className} bottom-0 bg-white z-Index-100 sliding-modal-footer`}
//     style={{ marginTop: "auto" }}
//   >
//     {props.children}
//   </div>
// );

// export default SlidingModal;
