import React from "react";
import "./mspMembersAccess/mspMembersAccess.scss";
const MspAccessModalToolTip = ({ children ,data}) => {
   
  return (
    <div
      className="memberAccessModal radius-8 "
      style={{
        maxWidth: "230px",
        border: "1px solid #EBEDF1",
        overflow: "hidden",
      }}
    >
      <div className="bg-white p-3">
        <div className=" f-600 f-black mb-2">{data?.Name} </div>
        <div className="f-grey ">{data?.Description}</div>
      </div>
    </div>
  );
};

export default MspAccessModalToolTip;
