import React, { useState, useEffect, Fragment } from "react";
import { useAuth } from "../context/Auth";
import { Row, Col, Button, Dropdown } from "react-bootstrap";
import { getAPIData } from "../../Utils/commonApi";
import "../../main.scss";
import TicketsView from "../Support/TicketsView/TicketsView";
import ParentChildModal from "../ModalDynamic/ParentChildModal";
import IncidentResponseForm from "../IncidentResponseForm/IncidentResponseForm";
import "../../main.scss";
import Header from "../Header";
import { useDb } from "../context/db";
import ContentLoader from "react-content-loader";
import {
  FiCheck,
  FiChevronRight,
  FiLinkedin,
  FiMail,
  FiUsers,
} from "react-icons/fi";
import { NavLink } from "react-router-dom";

const ErrorPage = (props) => {
    const currentUrl = JSON.parse(localStorage.getItem("currentUrl"));

  return (
    <React.Fragment>
      <Row className="fullHeight">
        <Col xs={12} className="px-0">
          <div className="dashboard fullHeight pt-0">
            <header
              className="d-flex justify-content-between navbar sticky-top"
              style={{ paddingTop: "1em", background: "#FFFFFF" }}
            >
              <h1>{currentUrl.name}</h1>
              <Header />
            </header>

            <div className="settings_container globalError">
              <div className="text-center">
                <img src="/images/cross_icon.svg" />
                <h2 className="heading3" style={{ color: "#000000DE" }}>
                  Opps! Something went wrong..
                </h2>
                <p class="greyText mt8px">
                  Please try to refresh the page and check your <br /> internet
                  connection. If the issue persists, please try <br />
                  again later.
                </p>
                 <NavLink exact to={currentUrl.url} activeClassName="active">
                  <p className="txt-blue"><img src="/images/refresh.svg" /> &nbsp; Refresh Page</p>
                </NavLink>
                
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default ErrorPage;
