import React, { useState, useEffect } from "react";
import { Button, Col, Row, Dropdown, Form, Spinner } from "react-bootstrap";
import { CallToast } from "../../PriceCalculator/modals/CustomToast";
import { ToastsV2 } from "../ToastsV2";
import { useSelector } from "react-redux";
import "./c-suite.scss";
import {
  getAPIData,
  getRequestOptions,
  postData,
} from "../../../Utils/commonApi";
import { useAuth } from "../../context/Auth";
import CSuitePageLoader from "./CSuitePageLoader";
import { useHistory } from "react-router-dom";
import { ResetSettingsModal } from "../resetSettings/ResetSettings";
import { getAccessStateValue } from "../../App/App";

const CSuite = () => {
  const history = useHistory();
  const { authTokens } = useAuth();
  const [frequency, setFrequency] = useState({ id: 2, name: "Weekly" });
  const [daysOfWeek] = useState([]);
  const [selectedDayOfWeek, setSelectedDayOfWeek] = useState(0);
  const [selectedDaysOfWeek, setSelectedDaysOfWeek] = useState([]);
  const [selectedtime, setSelectedtime] = useState({
    id: "01:00",
    time: "01:00 AM",
  });
  const [selecteddate, setSelecteddate] = useState({ id: 0, date: "1st" });

  ///SCM
  const [frequencySCM, setFrequencySCM] = useState({ id: 2, name: "Weekly" });
  const [daysOfWeekSCM] = useState([]);
  const [selectedDaysOfWeekSCM, setSelectedDaysOfWeekSCM] = useState([]);
  const [selectedtimeSCM, setSelectedtimeSCM] = useState({
    id: "01:00",
    time: "01:00 AM",
  });
  const [selecteddateSCM, setSelecteddateSCM] = useState({
    id: 0,
    date: "1st",
  });

  const [isPremium, setIsPremium] = useState(true);
  const [done, setDone] = useState(true);
  const [toast, setToast] = useState(false);
  const [mainPageLoading, setMainPageLoading] = useState(false);
  const userDetails = useSelector((state) => state?.user?.userDetail);
  const [sendingReport, setSendingReport] = useState(false);
  const [showResetModal, setShowResetModal] = useState(false);
  const [sendingSCMReport, setSendingSCMReport] = useState(false);
  const [allowDrillDownStatus, setAllowDrillDownStatus] = useState("");
  const [showSCMSection, setShowSCMSection] = useState(true);
  useEffect(() => {
    setMainPageLoading(true);
    getPageData();
  }, []);

  function getPageData() {
    getAPIData("193", authTokens).then((response) => {
      if (response?.mr?.Success) {
        setIsPremium(!response?.ShowIntegrations);
        getNotificationSettings();
      }
    });
  }

  const [ownerType, setOwnerType] = useState(0);

  const [settingOwner, setSettingOwner] = useState("");
  const [data, setData] = useState({});

  async function getNotificationSettings() {
    await getAPIData(103, authTokens)
      .then((response) => {
        if (response.Notification_Settings || response.NotificationSettings) {
          const setting =
            response.Notification_Settings || response.NotificationSettings;

          setOwnerType(setting?.OwnerType);

          setSettingOwner(setting?.SettingOwner);
        }
        setData(response);
        if (response?.NotificationSettings?.Report_Period !== null) {
          setFrequency(
            frequencyData.filter(
              (item) => item.id == response?.NotificationSettings?.Report_Period
            )[0]
          );
        }
        if (response?.NotificationSettings?.Report_Day !== null) {
          setSelecteddate(
            monthData.filter(
              (item) => item.id == response?.NotificationSettings?.Report_Day
            )[0]
          );
        }
        if (response?.Report_DayOfWeek !== null) {
          if (
            typeof response?.Report_DayOfWeek === "string" &&
            /^\[.*\]$/.test(response?.Report_DayOfWeek)
          ) {
            setSelectedDaysOfWeek(JSON.parse(response?.Report_DayOfWeek));
          } else {
            setSelectedDaysOfWeek([parseInt(response?.Report_DayOfWeek, 10)]);
          }
          // setSelectedDaysOfWeek(JSON.parse(response?.Report_DayOfWeek));
        }

        if (response?.Report_TimeTxt !== null) {
          setSelectedtime(
            timeData.filter((item) => item.id == response?.Report_TimeTxt)[0]
          );
        }

        /// SCM
        if (response?.Report_Period_SCM !== null) {
          setFrequencySCM(
            frequencyData.filter(
              (item) => item.id == response?.Report_Period_SCM
            )[0]
          );
        }
        if (response?.Report_DayTxt_SCM !== null) {
          setSelecteddateSCM(
            monthData.filter(
              (item) => item.id == response?.Report_DayTxt_SCM
            )[0]
          );
        }
        if (response?.Report_DayOfWeek_SCM !== null) {
          if (
            typeof response?.Report_DayOfWeek_SCM === "string" &&
            /^\[.*\]$/.test(response?.Report_DayOfWeek_SCM)
          ) {
            setSelectedDaysOfWeekSCM(
              JSON.parse(response?.Report_DayOfWeek_SCM)
            );
          } else {
            setSelectedDaysOfWeekSCM([
              parseInt(response?.Report_DayOfWeek_SCM, 10),
            ]);
          }
        }

        if (response?.Report_TimeTxt_SCM !== null) {
          setSelectedtimeSCM(
            timeData.filter(
              (item) => item.id == response?.Report_TimeTxt_SCM
            )[0]
          );
        }
        setMainPageLoading(false);

        // Subscription Restriction Setting
        setAllowDrillDownStatus(
          response?.mr?.AccessObjList?.filter(
            (item) => item?.Question === "Cyber Reports Provision"
          )?.[0]?.OtherValue
        );
        setShowSCMSection(
          response?.mr?.AccessObjList?.filter(
            (item) => item?.Question === "Supply Chain Monitoring Visibility"
          )?.[0]?.Option
        );
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const frequencyData = [
    { id: 0, name: "Disabled" },
    { id: 1, name: "Monthly" },
    { id: 2, name: "Weekly" },
  ];

  const weekData = [
    { id: 0, day: "Mon" },
    { id: 1, day: "Tue" },
    { id: 2, day: "Wed" },
    { id: 3, day: "Thu" },
    { id: 4, day: "Fri" },
    { id: 5, day: "Sat" },
    { id: 6, day: "Sun" },
  ];

  const monthData = [
    { id: 1, date: "1st" },
    { id: 2, date: "2nd" },
    { id: 3, date: "3rd" },
    { id: 4, date: "4th" },
    { id: 5, date: "5th" },
    { id: 6, date: "6th" },
    { id: 7, date: "7th" },
    { id: 8, date: "8th" },
    { id: 9, date: "9th" },
    { id: 10, date: "10th" },
    { id: 11, date: "11th" },
    { id: 12, date: "12th" },
    { id: 13, date: "13th" },
    { id: 14, date: "14th" },
    { id: 15, date: "15th" },
    { id: 16, date: "16th" },
    { id: 17, date: "17th" },
    { id: 18, date: "18th" },
    { id: 19, date: "19th" },
    { id: 20, date: "20th" },
    { id: 21, date: "21th" },
    { id: 22, date: "22th" },
    { id: 23, date: "23th" },
    { id: 24, date: "24th" },
    { id: 25, date: "25th" },
    { id: 26, date: "26th" },
    { id: 27, date: "27th" },
    { id: 28, date: "28th" },
    { id: 29, date: "29th" },
    { id: 30, date: "30th" },
    { id: 31, date: "31st" },
  ];

  const timeData = [
    { id: "01:00", time: "01:00 AM" },
    { id: "02:00", time: "02:00 AM" },
    { id: "03:00", time: "03:00 AM" },
    { id: "04:00", time: "04:00 AM" },
    { id: "05:00", time: "05:00 AM" },
    { id: "06:00", time: "06:00 AM" },
    { id: "07:00", time: "07:00 AM" },
    { id: "08:00", time: "08:00 AM" },
    { id: "09:00", time: "09:00 AM" },
    { id: "10:00", time: "10:00 AM" },
    { id: "11:00", time: "11:00 AM" },
    { id: "12:00", time: "12:00 PM" },
    { id: "13:00", time: "01:00 PM" },
    { id: "14:00", time: "02:00 PM" },
    { id: "15:00", time: "03:00 PM" },
    { id: "16:00", time: "04:00 PM" },
    { id: "17:00", time: "05:00 PM" },
    { id: "18:00", time: "06:00 PM" },
    { id: "19:00", time: "07:00 PM" },
    { id: "20:00", time: "08:00 PM" },
    { id: "21:00", time: "09:00 PM" },
    { id: "22:00", time: "10:00 PM" },
    { id: "23:00", time: "11:00 PM" },
    { id: "24:00", time: "12:00 PM" },
  ];

  const getDateData = (period, scm) => {
    if (scm) {
      if (period == 1) {
        return `Monthly: ${
          monthData[
            data?.Report_DayTxt_SCM - 1 < 0
              ? data?.Report_DayTxt_SCM
              : data?.Report_DayTxt_SCM - 1
          ]?.date
        } of month at ${data?.Report_TimeTxt_SCM}`;
      }
      if (period == 2) {
        return `Weekly: Every ${
          // weekData[data?.Report_DayOfWeek_SCM]?.day

          selectedDaysOfWeekSCM.length > 0
            ? selectedDaysOfWeekSCM
                .map((id) => weekData.find((item) => item.id === id)?.day)
                .join(", ")
            : ""
        }, at ${data?.Report_TimeTxt_SCM}`;
      } else {
        return "";
      }
    } else {
      if (period == 1) {
        return `Monthly: ${
          monthData[
            data?.NotificationSettings?.Report_Day - 1 < 0
              ? data?.NotificationSettings?.Report_Day
              : data?.NotificationSettings?.Report_Day - 1
          ]?.date
        } of month at ${data?.Report_TimeTxt}`;
      }
      if (period == 2) {
        return `Weekly: Every ${
          selectedDaysOfWeek.length > 0
            ? (selectedDaysOfWeek
                .map((id) => weekData.find((item) => item.id === id)?.day)
                .join(", "))
            : ""
          // weekData[data?.NotificationSettings?.Report_DayOfWeek]?.day
        }, at ${data?.Report_TimeTxt}`;
      } else {
        return "";
      }
    }
  };
  function showDays() {
    if (daysOfWeek?.length > 2) {
      let daystoshow = daysOfWeek
        ?.map((item) => item.day)
        ?.map((day) => day.substring(0, 2))
        ?.slice(0, 2)
        ?.join(",");
      return `${daystoshow},+${daysOfWeek?.length - 2}`;
    } else {
      return daysOfWeek
        ?.map((item) => item.day)
        ?.map((day) => day.substring(0, 2))
        ?.join(",");
    }
  }

  const inputs = {
    SettingOwner: settingOwner,
    OwnerType: ownerType,
  };

  async function handleSave(notifName, notifValue) {
    await postData(authTokens, "SubmitNotificationSettings", {
      ...inputs,
      NotificationName: notifName,
      NotificationValue:
        notifName == "Report_Time" || notifName == "Report_Time_SCM"
          ? notifValue ?? ""
          : JSON.stringify(notifValue ?? []),
    })
      .then((response) => {
        if (response.Success) {
          getNotificationSettings();
          CallToast("Updated successfully", false, false, "v2style");
        }
      })
      .catch((err) => {});
  }

  async function SendReport() {
    setSendingReport(true);
    await fetch(
      `${process.env.REACT_APP_API_BASE_URL}/CC_API/SendCSuiteReportPDF?emailReportTypeInt=1`,
      getRequestOptions(authTokens, "GET")
    )
      .then((response) => response.json())
      .then((data) => {
        setSendingReport(false);
        if (data.Success === true) {
          CallToast(
            `Report is successfully sent to ${userDetails?.UserName}`,
            false,
            false,
            "v2style"
          );
          // setToast(true);
          // setTimeout(() => {
          //   setToast(false);
          // }, 2300);
        } else {
          CallToast("Something went wrong", false, false, "v2style");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async function sendSCMReport() {
    setSendingSCMReport(true);
    await getAPIData(`${467}`, authTokens, {
      optionStrEnum1: userDetails?.UserName,
    })
      .then((data) => {
        setSendingSCMReport(false);
        if (data.Success === true) {
          CallToast("Report sent successfully", false, false, "v2style");
          // setToast(true);
          // setTimeout(() => {
          //   setToast(false);
          // }, 2300);
        } else {
          CallToast("Something went wrong", false, false, "v2style");
        }
      })
      .catch((error) => {
        setSendingSCMReport(false);
        console.log(error);
      });
  }

  return (
    <>
      {mainPageLoading && <CSuitePageLoader />}
      <div className={`c-suite-wrapper ${mainPageLoading ? "d-none" : ""} `}>
        <div className="heading mb-3">Schedule C-suite report</div>
        <div>
          <p className="details m-0">
            The C-Suite Cyber Report is a snapshot of all your key cyber
            security data including your:
          </p>
          <p className="details m-0 mb-1">
            <img
              alt=""
              src="/images/settings-v2/yes-tick.svg"
              className="mr-2"
            />
            Primary KPIs
          </p>
          <p className="details m-0 mb-1">
            <img
              alt=""
              src="/images/settings-v2/yes-tick.svg"
              className="mr-2"
            />
            Role Based Cyber Data for all roles
          </p>
          <p className="details m-0 mb-1">
            <img
              alt=""
              src="/images/settings-v2/yes-tick.svg"
              className="mr-2"
            />
            Trend Data
          </p>
          <p className="details m-0 mb-1">
            <img
              alt=""
              src="/images/settings-v2/yes-tick.svg"
              className="mr-2"
            />
            Technical Drilldown
          </p>
          <p className="details m-0 mb-1">
            <img
              alt=""
              src="/images/settings-v2/yes-tick.svg"
              className="mr-2"
            />
            Compliance Manager summary
          </p>
        </div>
        {/* Main Wrapper */}
        <div className="c-suite-settings mt-4">
          <Row>
            <Col xs={12}>
              <div className="d-flex justify-content-between align-items-center content-div">
                <div>
                  <p className="title m-0 d-flex align-items-center">
                    C-Suite Schedule report
                    {/* {!isPremium && <PremiumTag />} */}
                  </p>
                  <p className="subtitle m-0">
                    {allowDrillDownStatus !== "Scheduled" &&
                      "Set frequency of sending your C-suite report"}
                    {allowDrillDownStatus === "Scheduled" &&
                      data?.NotificationSettings?.Report_Period == 0 &&
                      "Disabled"}
                    {getDateData(
                      data?.NotificationSettings?.Report_Period,
                      false
                    )}
                  </p>
                </div>

                {allowDrillDownStatus !== "Scheduled" && (
                  <Button
                    className="transparent-btn f-blue f-500"
                    onClick={() => {
                      if (
                        getAccessStateValue("AccessState_SystemSettings") >= 3
                      ) {
                        history.push("/settings/v2/subscriptions");
                        setIsPremium(true);
                      }
                    }}
                  >
                    <img
                      alt=""
                      className="mr-1"
                      src="/images/attack-surface/blue-bell.svg"
                    />{" "}
                    Upgrade to Premium
                  </Button>
                )}

                {allowDrillDownStatus === "Scheduled" && (
                  <div>
                    {" "}
                    <div className="test-drop-down ">
                      <div className="c-suite-dropdown">
                        <Dropdown>
                          <Dropdown.Toggle
                            variant="success"
                            id="parent-dropdown"
                            className={`${
                              getAccessStateValue(
                                "AccessState_SystemSettings"
                              ) < 3
                                ? "locked"
                                : ""
                            } d-flex align-items-center`}
                            style={{
                              pointerEvents:
                                getAccessStateValue(
                                  "AccessState_SystemSettings"
                                ) < 3
                                  ? "none"
                                  : "all",
                            }}
                          >
                            {frequency?.name}
                            {getAccessStateValue("AccessState_SystemSettings") <
                            3 ? (
                              <>
                                {" "}
                                <img
                                  className="ml-2"
                                  width={14}
                                  height={14}
                                  alt=""
                                  src="/images/attack-surface/small-lock.svg"
                                />
                              </>
                            ) : (
                              <></>
                            )}
                          </Dropdown.Toggle>
                          {getAccessStateValue("AccessState_SystemSettings") >=
                            3 && (
                            <Dropdown.Menu>
                              <div className="">
                                <Row>
                                  {/* frequency */}
                                  <Col
                                    xs={6}
                                    className="label my-1 d-flex align-items-center"
                                  >
                                    Frequency
                                  </Col>
                                  <Col
                                    xs={6}
                                    className="frequency-dropdown d-flex justify-content-end my-1"
                                  >
                                    {/* Frequency DropDown */}
                                    <Dropdown>
                                      <Dropdown.Toggle
                                        variant="success"
                                        id="child-frequency-dropdown"
                                      >
                                        {frequency?.name}
                                      </Dropdown.Toggle>

                                      <Dropdown.Menu>
                                        <div className="monthly-weekly">
                                          {frequencyData.map((data, index) => {
                                            return (
                                              <div
                                                className={`d-flex justify-content-between align-items-center my-2 cursor-pointer  ${
                                                  frequency.id === index
                                                    ? "selected-item"
                                                    : ""
                                                }`}
                                                onClick={() => {
                                                  handleSave(
                                                    "Report_Period",
                                                    data.id
                                                  );
                                                  setFrequency(
                                                    frequencyData[index]
                                                  );
                                                }}
                                              >
                                                <div className="drop-item">
                                                  {data.name}
                                                </div>

                                                {frequency.id === index && (
                                                  <img
                                                    alt=""
                                                    src="/images/settings-v2/black-yes-tick.svg"
                                                    className="mb-2"
                                                  />
                                                )}
                                              </div>
                                            );
                                          })}
                                        </div>
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </Col>
                                  {/* days */}

                                  {/* To hide when its disabled start */}
                                  {frequency.name !== "Disabled" && (
                                    <>
                                      <Col
                                        xs={6}
                                        className="label my-1 d-flex align-items-center"
                                      >
                                        {frequency.name == "Weekly" &&
                                          "Days of week"}
                                        {frequency.name == "Monthly" &&
                                          "Day of Month"}
                                      </Col>
                                      <Col
                                        xs={6}
                                        className="month-week-dropdown d-flex justify-content-end my-1"
                                      >
                                        {/* Weekly or monthly dropdown */}

                                        {/* Days of Week Drop down */}
                                        {frequency.name == "Weekly" && (
                                          <Dropdown>
                                            <Dropdown.Toggle
                                              variant="success"
                                              id="child-weekday-dropdown"
                                            >
                                              {/* {daysOfWeek?.length > 0
                                            ? showDays()
                                            : "Choose day"} */}

                                              {weekData.filter(
                                                (item) =>
                                                  item.id == selectedDayOfWeek
                                              )[0].day || "Choose day"}
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                              <div className="week-data">
                                                {weekData.map((data, index) => {
                                                  // const IDexist =
                                                  //   daysOfWeek.filter(
                                                  //     (item) => item.id === index
                                                  //   ).length > 0;

                                                  return (
                                                    <div
                                                      className={`d-flex justify-content-between align-items-center my-1 cursor-pointer  ${
                                                        true // frequency.id === index ? "selected-item" : ""
                                                      }`}
                                                      onClick={() => {}}
                                                    >
                                                      <div className="week-day">
                                                        {data.day}
                                                      </div>

                                                      <div className="check-btn">
                                                        <Form.Group
                                                          controlId={
                                                            "check" + index
                                                          }
                                                          className={`round-checkbox blue-checkbox checkboxstyle `}
                                                        >
                                                          <Form.Check
                                                            type={"radio"}
                                                            checked={
                                                              selectedDayOfWeek ==
                                                              data.id
                                                            } //{IDexist}
                                                            name={index}
                                                            label=""
                                                            onClick={(e) => {
                                                              setSelectedDayOfWeek(
                                                                data.id
                                                              );
                                                              handleSave(
                                                                "Report_DayOfWeek",
                                                                data.id
                                                              );
                                                              // if (IDexist) {
                                                              //   let new_data = [
                                                              //     ...daysOfWeek,
                                                              //   ];

                                                              //   let INDEX =
                                                              //     new_data.findIndex(
                                                              //       (item) =>
                                                              //         item.id ===
                                                              //         index
                                                              //     );
                                                              //   new_data.splice(
                                                              //     INDEX,
                                                              //     1
                                                              //   );
                                                              //   setDaysOfWeek(
                                                              //     new_data
                                                              //   );
                                                              // } else {
                                                              //   let new_data = [
                                                              //     ...daysOfWeek,
                                                              //   ];
                                                              //   new_data.push(
                                                              //     weekData[index]
                                                              //   );
                                                              //   setDaysOfWeek(
                                                              //     new_data
                                                              //   );
                                                              // }
                                                            }}
                                                          />
                                                        </Form.Group>
                                                      </div>
                                                    </div>
                                                  );
                                                })}
                                              </div>
                                            </Dropdown.Menu>
                                          </Dropdown>
                                        )}

                                        {/* days of Month Drop Down */}
                                        {frequency.name == "Monthly" && (
                                          <Dropdown>
                                            <Dropdown.Toggle
                                              variant="success"
                                              id="child-month-dropdown"
                                            >
                                              {selecteddate?.date}
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu
                                              className="hide-scrollbar"
                                              style={{
                                                maxHeight: "275px",
                                                overflowY: "auto",
                                              }}
                                            >
                                              <div className="monthly-weekly">
                                                {monthData.map(
                                                  (data, index) => {
                                                    return (
                                                      <div
                                                        className={`d-flex justify-content-between align-items-center my-1 cursor-pointer  ${
                                                          selecteddate?.id ===
                                                          index + 1
                                                            ? "selected-item"
                                                            : ""
                                                        }`}
                                                        onClick={() => {
                                                          handleSave(
                                                            "Report_Day",
                                                            data?.id
                                                          );
                                                          setSelecteddate(
                                                            monthData[index]
                                                          );
                                                        }}
                                                      >
                                                        <div className="drop-item">
                                                          {data?.date}
                                                        </div>

                                                        {selecteddate?.id ===
                                                          index + 1 && (
                                                          <img
                                                            alt=""
                                                            src="/images/settings-v2/black-yes-tick.svg"
                                                            className="mb-2"
                                                          />
                                                        )}
                                                      </div>
                                                    );
                                                  }
                                                )}
                                              </div>
                                            </Dropdown.Menu>
                                          </Dropdown>
                                        )}
                                      </Col>
                                      {/* time */}
                                      <Col
                                        xs={6}
                                        className="label d-flex align-items-center mt-2 last-div"
                                      >
                                        Time
                                      </Col>
                                      <Col
                                        xs={6}
                                        className="time-dropdown d-flex justify-content-end my-1"
                                      >
                                        {/* Time Drop Down */}

                                        <Dropdown>
                                          <Dropdown.Toggle
                                            variant="success"
                                            id="child-time-dropdown"
                                          >
                                            {selectedtime?.time}
                                          </Dropdown.Toggle>

                                          <Dropdown.Menu
                                            className="hide-scrollbar"
                                            style={{
                                              maxHeight: "275px",
                                              overflowY: "auto",
                                            }}
                                          >
                                            <div className="monthly-weekly">
                                              <div className="f-darkgrey f-12 my-1">
                                                Before midday
                                              </div>
                                              {timeData.map((data, index) => {
                                                return (
                                                  <>
                                                    {index == 11 ? (
                                                      <div className="f-darkgrey f-12 my-1">
                                                        After midday
                                                      </div>
                                                    ) : (
                                                      <div
                                                        className={`d-flex justify-content-between align-items-center my-1 cursor-pointer  ${
                                                          selectedtime?.id ===
                                                          index
                                                            ? "selected-item"
                                                            : ""
                                                        }`}
                                                        onClick={() => {
                                                          handleSave(
                                                            "Report_Time",
                                                            data.id
                                                          );

                                                          setSelectedtime(
                                                            timeData[index]
                                                          );
                                                        }}
                                                      >
                                                        <div className="drop-item">
                                                          {data?.time}
                                                        </div>

                                                        {selectedtime?.id ===
                                                          data.id && (
                                                          <img
                                                            alt=""
                                                            src="/images/settings-v2/black-yes-tick.svg"
                                                            className="mb-2"
                                                          />
                                                        )}
                                                      </div>
                                                    )}
                                                  </>
                                                );
                                              })}
                                            </div>
                                          </Dropdown.Menu>
                                        </Dropdown>
                                      </Col>
                                    </>
                                  )}
                                  {/* To hide when its disabled end */}
                                </Row>
                              </div>

                              <div className="status-wrapper d-flex align-items-start">
                                <img
                                  alt=""
                                  src="/images/settings-v2/light-info-icon.svg"
                                  className="mt-1 mr-1"
                                />
                                <p className="m-0">
                                  {frequency.name == "Disabled" &&
                                    "Reporting is currently suspended. If you want to receive reports, select the frequency of sending."}
                                  {frequency.name == "Monthly" &&
                                    "If there are fewer days in the month than you specified, the report will be sent on the last day of the month."}
                                  {frequency.name == "Weekly" &&
                                    "If there are fewer days in the week than you specified, the report will be sent on the last day of the month."}
                                </p>
                              </div>
                            </Dropdown.Menu>
                          )}
                        </Dropdown>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <hr className="m-0" />
            </Col>

            <Col xs={12}>
              <div className="d-flex justify-content-between align-items-center content-div ">
                <div>
                  <p className="title m-0">Send Schedule report manually</p>
                  <p className="subtitle m-0">
                    Report will be sent to{" "}
                    <strong>{userDetails?.UserName}</strong>
                  </p>
                </div>
                <div>
                  <Button
                    disabled={allowDrillDownStatus === "None" || sendingReport}
                    className={` ${
                      allowDrillDownStatus === "None" ||
                      getAccessStateValue("AccessState_SystemSettings") < 3
                        ? "btn-disable" //"hg-cancel-btn "
                        : "send-report-btn"
                    }`}
                    onClick={() => {
                      if (
                        getAccessStateValue("AccessState_SystemSettings") >= 3
                      ) {
                        SendReport();
                      }
                    }}
                  >
                    {sendingReport ? (
                      <>
                        <Spinner
                          animation="border"
                          variant="light"
                          size="sm"
                          className="mx-2"
                        />
                        Sending..
                      </>
                    ) : (
                      "Send Report"
                    )}
                    {getAccessStateValue("AccessState_SystemSettings") < 3 ? (
                      <>
                        {" "}
                        <img
                          className="ml-2"
                          width={14}
                          height={14}
                          alt=""
                          src="/images/attack-surface/small-lock.svg"
                        />
                      </>
                    ) : (
                      <></>
                    )}
                  </Button>
                </div>
              </div>
            </Col>
          </Row>
        </div>

        {/* FOR SUPPLY CHAIN NOTIFICATION REPORT SETTING */}
        {/* FOR SUPPLY CHAIN NOTIFICATION REPORT SETTING */}
        {/* FOR SUPPLY CHAIN NOTIFICATION REPORT SETTING */}
        {/* FOR SUPPLY CHAIN NOTIFICATION REPORT SETTING */}
        {/* FOR SUPPLY CHAIN NOTIFICATION REPORT SETTING */}
        {/* FOR SUPPLY CHAIN NOTIFICATION REPORT SETTING */}
        {/* FOR SUPPLY CHAIN NOTIFICATION REPORT SETTING */}
        {/* FOR SUPPLY CHAIN NOTIFICATION REPORT SETTING */}
        {/* FOR SUPPLY CHAIN NOTIFICATION REPORT SETTING */}
        {/* FOR SUPPLY CHAIN NOTIFICATION REPORT SETTING */}
        {/* FOR SUPPLY CHAIN NOTIFICATION REPORT SETTING */}
        {/* FOR SUPPLY CHAIN NOTIFICATION REPORT SETTING */}
        {showSCMSection && (
          <>
            <div className="pt-4 pb-2 f-16 f-black f-600">
              Supply Chain Report
            </div>
            <div className="c-suite-settings mt-2">
              <Row>
                <Col xs={12}>
                  <div className="d-flex justify-content-between align-items-center content-div">
                    <div>
                      <p className="title m-0 d-flex align-items-center">
                        Supply Chain Schedule report{" "}
                        {!isPremium && <PremiumTag />}
                      </p>
                      <p className="subtitle m-0">
                        {!isPremium &&
                          "Set frequency of sending your Supply Chain report"}
                        {isPremium &&
                          data?.Report_Period_SCM == 0 &&
                          "Disabled"}
                        {getDateData(data?.Report_Period_SCM, true)}
                      </p>
                    </div>

                    {allowDrillDownStatus !== "Scheduled" && (
                      <Button
                        className="transparent-btn f-blue f-500"
                        onClick={() => {
                          history.push("/settings/v2/subscriptions");
                          setIsPremium(true);
                        }}
                      >
                        <img
                          alt=""
                          className="mr-1"
                          src="/images/attack-surface/blue-bell.svg"
                        />{" "}
                        Upgrade to Premium
                      </Button>
                    )}

                    {allowDrillDownStatus === "Scheduled" && (
                      <div>
                        {" "}
                        <div className="test-drop-down ">
                          <div className="c-suite-dropdown">
                            <Dropdown>
                              <Dropdown.Toggle
                                variant="success"
                                id="parent-dropdown"
                                className={`${
                                  getAccessStateValue(
                                    "AccessState_SystemSettings"
                                  ) < 3
                                    ? "locked"
                                    : ""
                                } d-flex align-items-center`}
                                style={{
                                  pointerEvents:
                                    getAccessStateValue(
                                      "AccessState_SystemSettings"
                                    ) < 3
                                      ? "none"
                                      : "all",
                                }}
                              >
                                {frequency?.name}
                                {getAccessStateValue(
                                  "AccessState_SystemSettings"
                                ) < 3 ? (
                                  <>
                                    {" "}
                                    <img
                                      className="ml-2"
                                      width={14}
                                      height={14}
                                      alt=""
                                      src="/images/attack-surface/small-lock.svg"
                                    />
                                  </>
                                ) : (
                                  <></>
                                )}
                              </Dropdown.Toggle>
                              {getAccessStateValue(
                                "AccessState_SystemSettings"
                              ) >= 3 && (
                                <Dropdown.Menu>
                                  <div className="">
                                    <Row>
                                      {/* frequency */}
                                      <Col
                                        xs={6}
                                        className="label my-1 d-flex align-items-center"
                                      >
                                        Frequency
                                      </Col>
                                      <Col
                                        xs={6}
                                        className="frequency-dropdown d-flex justify-content-end my-1"
                                      >
                                        {/* Frequency DropDown */}
                                        <Dropdown>
                                          <Dropdown.Toggle
                                            variant="success"
                                            id="child-frequency-dropdown"
                                          >
                                            {frequency?.name}
                                          </Dropdown.Toggle>

                                          <Dropdown.Menu>
                                            <div className="monthly-weekly">
                                              {frequencyData.map(
                                                (data, index) => {
                                                  return (
                                                    <div
                                                      className={`d-flex justify-content-between align-items-center my-2 cursor-pointer  ${
                                                        frequency.id === index
                                                          ? "selected-item"
                                                          : ""
                                                      }`}
                                                      onClick={() => {
                                                        handleSave(
                                                          "Report_Period",
                                                          data.id
                                                        );
                                                        setFrequency(
                                                          frequencyData[index]
                                                        );
                                                      }}
                                                    >
                                                      <div className="drop-item">
                                                        {data.name}
                                                      </div>

                                                      {frequency.id ===
                                                        index && (
                                                        <img
                                                          alt=""
                                                          src="/images/settings-v2/black-yes-tick.svg"
                                                          className="mb-2"
                                                        />
                                                      )}
                                                    </div>
                                                  );
                                                }
                                              )}
                                            </div>
                                          </Dropdown.Menu>
                                        </Dropdown>
                                      </Col>
                                      {/* days */}

                                      {/* To hide when its disabled start */}
                                      {frequency.name !== "Disabled" && (
                                        <>
                                          <Col
                                            xs={6}
                                            className="label my-1 d-flex align-items-center"
                                          >
                                            {frequency.name == "Weekly" &&
                                              "Days of week"}
                                            {frequency.name == "Monthly" &&
                                              "Day of Month"}
                                          </Col>
                                          <Col
                                            xs={6}
                                            className="month-week-dropdown d-flex justify-content-end my-1"
                                          >
                                            {/* Weekly or monthly dropdown */}

                                            {/* Days of Week Drop down */}
                                            {frequency.name == "Weekly" && (
                                              <Dropdown>
                                                <Dropdown.Toggle
                                                  variant="success"
                                                  id="child-weekday-dropdown"
                                                >
                                                  {/* {daysOfWeek?.length > 0
                                            ? showDays()
                                            : "Choose day"} */}

                                                  {weekData.filter(
                                                    (item) =>
                                                      item.id ==
                                                      selectedDayOfWeek
                                                  )[0].day || "Choose day"}
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu>
                                                  <div className="week-data">
                                                    {weekData.map(
                                                      (data, index) => {
                                                        // const IDexist =
                                                        //   daysOfWeek.filter(
                                                        //     (item) => item.id === index
                                                        //   ).length > 0;

                                                        return (
                                                          <div
                                                            className={`d-flex justify-content-between align-items-center my-1 cursor-pointer  ${
                                                              true // frequency.id === index ? "selected-item" : ""
                                                            }`}
                                                            onClick={() => {}}
                                                          >
                                                            <div className="week-day">
                                                              {data.day}
                                                            </div>

                                                            <div className="check-btn">
                                                              <Form.Group
                                                                controlId={
                                                                  "check" +
                                                                  index
                                                                }
                                                                className={`round-checkbox blue-checkbox checkboxstyle `}
                                                              >
                                                                <Form.Check
                                                                  type={"radio"}
                                                                  checked={
                                                                    selectedDayOfWeek ==
                                                                    data.id
                                                                  } //{IDexist}
                                                                  name={index}
                                                                  label=""
                                                                  onClick={(
                                                                    e
                                                                  ) => {
                                                                    setSelectedDayOfWeek(
                                                                      data.id
                                                                    );
                                                                    handleSave(
                                                                      "Report_DayOfWeek",
                                                                      data.id
                                                                    );
                                                                    // if (IDexist) {
                                                                    //   let new_data = [
                                                                    //     ...daysOfWeek,
                                                                    //   ];

                                                                    //   let INDEX =
                                                                    //     new_data.findIndex(
                                                                    //       (item) =>
                                                                    //         item.id ===
                                                                    //         index
                                                                    //     );
                                                                    //   new_data.splice(
                                                                    //     INDEX,
                                                                    //     1
                                                                    //   );
                                                                    //   setDaysOfWeek(
                                                                    //     new_data
                                                                    //   );
                                                                    // } else {
                                                                    //   let new_data = [
                                                                    //     ...daysOfWeek,
                                                                    //   ];
                                                                    //   new_data.push(
                                                                    //     weekData[index]
                                                                    //   );
                                                                    //   setDaysOfWeek(
                                                                    //     new_data
                                                                    //   );
                                                                    // }
                                                                  }}
                                                                />
                                                              </Form.Group>
                                                            </div>
                                                          </div>
                                                        );
                                                      }
                                                    )}
                                                  </div>
                                                </Dropdown.Menu>
                                              </Dropdown>
                                            )}

                                            {/* days of Month Drop Down */}
                                            {frequency.name == "Monthly" && (
                                              <Dropdown>
                                                <Dropdown.Toggle
                                                  variant="success"
                                                  id="child-month-dropdown"
                                                >
                                                  {selecteddate?.date}
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu
                                                  className="hide-scrollbar"
                                                  style={{
                                                    maxHeight: "275px",
                                                    overflowY: "auto",
                                                  }}
                                                >
                                                  <div className="monthly-weekly">
                                                    {monthData.map(
                                                      (data, index) => {
                                                        return (
                                                          <div
                                                            className={`d-flex justify-content-between align-items-center my-1 cursor-pointer  ${
                                                              selecteddate?.id ===
                                                              index + 1
                                                                ? "selected-item"
                                                                : ""
                                                            }`}
                                                            onClick={() => {
                                                              handleSave(
                                                                "Report_Day",
                                                                data?.id
                                                              );
                                                              setSelecteddate(
                                                                monthData[index]
                                                              );
                                                            }}
                                                          >
                                                            <div className="drop-item">
                                                              {data?.date}
                                                            </div>

                                                            {selecteddate?.id ===
                                                              index + 1 && (
                                                              <img
                                                                alt=""
                                                                src="/images/settings-v2/black-yes-tick.svg"
                                                                className="mb-2"
                                                              />
                                                            )}
                                                          </div>
                                                        );
                                                      }
                                                    )}
                                                  </div>
                                                </Dropdown.Menu>
                                              </Dropdown>
                                            )}
                                          </Col>
                                          {/* time */}
                                          <Col
                                            xs={6}
                                            className="label d-flex align-items-center mt-2 last-div"
                                          >
                                            Time
                                          </Col>
                                          <Col
                                            xs={6}
                                            className="time-dropdown d-flex justify-content-end my-1"
                                          >
                                            {/* Time Drop Down */}

                                            <Dropdown>
                                              <Dropdown.Toggle
                                                variant="success"
                                                id="child-time-dropdown"
                                              >
                                                {selectedtime?.time}
                                              </Dropdown.Toggle>

                                              <Dropdown.Menu
                                                className="hide-scrollbar"
                                                style={{
                                                  maxHeight: "275px",
                                                  overflowY: "auto",
                                                }}
                                              >
                                                <div className="monthly-weekly">
                                                  {timeData.map(
                                                    (data, index) => {
                                                      return (
                                                        <div
                                                          className={`d-flex justify-content-between align-items-center my-1 cursor-pointer  ${
                                                            selectedtime?.id ===
                                                            index
                                                              ? "selected-item"
                                                              : ""
                                                          }`}
                                                          onClick={() => {
                                                            handleSave(
                                                              "Report_Time",
                                                              data.id
                                                            );

                                                            setSelectedtime(
                                                              timeData[index]
                                                            );
                                                          }}
                                                        >
                                                          <div className="drop-item">
                                                            {data?.time}
                                                          </div>

                                                          {selectedtime?.id ===
                                                            data.id && (
                                                            <img
                                                              alt=""
                                                              src="/images/settings-v2/black-yes-tick.svg"
                                                              className="mb-2"
                                                            />
                                                          )}
                                                        </div>
                                                      );
                                                    }
                                                  )}
                                                </div>
                                              </Dropdown.Menu>
                                            </Dropdown>
                                          </Col>
                                        </>
                                      )}
                                      {/* To hide when its disabled end */}
                                    </Row>
                                  </div>

                                  <div className="status-wrapper d-flex align-items-start">
                                    <img
                                      alt=""
                                      src="/images/settings-v2/light-info-icon.svg"
                                      className="mt-1 mr-1"
                                    />
                                    <p className="m-0">
                                      {frequency.name == "Disabled" &&
                                        "Reporting is currently suspended. If you want to receive reports, select the frequency of sending."}
                                      {frequency.name == "Monthly" &&
                                        "If there are fewer days in the month than you specified, the report will be sent on the last day of the month."}
                                      {frequency.name == "Weekly" &&
                                        "If there are fewer days in the week than you specified, the report will be sent on the last day of the month."}
                                    </p>
                                  </div>
                                </Dropdown.Menu>
                              )}
                            </Dropdown>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  <hr className="m-0" />
                </Col>

                <Col xs={12}>
                  <div className="d-flex justify-content-between align-items-center content-div ">
                    <div>
                      <p className="title m-0">
                        Send Supply Chain report manually
                      </p>
                      <p className="subtitle m-0">
                        Report will be sent to{" "}
                        <strong>{userDetails?.UserName}</strong>
                      </p>
                    </div>
                    <div>
                      <Button
                        disabled={
                          allowDrillDownStatus === "None" || sendingSCMReport
                        }
                        className={` ${
                          allowDrillDownStatus === "None" ||
                          getAccessStateValue("AccessState_SystemSettings") < 3
                            ? "btn-disable" //"hg-cancel-btn "
                            : "send-report-btn"
                        }`}
                        onClick={() => {
                          if (
                            getAccessStateValue("AccessState_SystemSettings") >=
                            3
                          ) {
                            sendSCMReport();
                          }
                        }}
                      >
                        {sendingSCMReport ? (
                          <>
                            <Spinner
                              animation="border"
                              variant="light"
                              size="sm"
                              className="mx-2"
                            />
                            Sending..
                          </>
                        ) : (
                          "Send Report"
                        )}
                        {getAccessStateValue("AccessState_SystemSettings") <
                        3 ? (
                          <>
                            {" "}
                            <img
                              className="ml-2"
                              width={14}
                              height={14}
                              alt=""
                              src="/images/attack-surface/small-lock.svg"
                            />
                          </>
                        ) : (
                          <></>
                        )}
                      </Button>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </>
        )}
        {getAccessStateValue("AccessState_SystemSettings") >= 3 && (
          <>
            {allowDrillDownStatus === "Scheduled" && (
              <div
                className="f-grey  mt-4 ml-2 pointer"
                onClick={() => {
                  setShowResetModal(true);
                }}
              >
                <img
                  alt=""
                  src="/images/attack-surface/loader-icon.svg"
                  className="mr-2"
                />
                Reset
              </div>
            )}
          </>
        )}

        <ToastsV2
          done={done}
          showToast={toast}
          successText={`Report successfully sent to ${userDetails?.UserName}`}
        />
        <ResetSettingsModal
          showResetModal={showResetModal}
          hideModal={() => setShowResetModal(false)}
          pageEnum={291}
          refreshData={getPageData}
        />
      </div>
    </>
  );
};

export default CSuite;

const PremiumTag = () => {
  return (
    <div className="premium-tag d-flex align-items-center ml-2">
      <img
        alt=""
        src="/images/settings-v2/white-lock.svg"
        className="mr-1 mb-1"
      />
      <div className="premium-text">ONLY PREMIUM</div>
    </div>
  );
};
