import React, { useState, useEffect, Fragment } from "react";
import { useAuth } from "../../context/Auth";
import { postData } from "../../../Utils/commonApi";
import {
  Button,
  Modal,
  Form,
  FormControl,
  InputGroup,
  Spinner,
} from "react-bootstrap";
import SuccessDialog from "../../Spinner/SuccessDialog";
import "./UiFix.scss";

const SuggestIntegrationModal = (props) => {
  const { authTokens } = useAuth();
  const [integrationsInfo, setIntegrationsInfo] = useState(props.data);
  const [isSubmit, setIsSubmit] = useState(false);
  const [isRequestSend, setIsRequestSend] = useState(false);
  const [submitResponse, setSubmitResponse] = useState(false);
  const [slideHideClass, setSlideHideClass] = useState("");
  const [inputs, setInputs] = useState({
    TicketDetails: {
      ContactName: props.data.CompanyName,
      ContactEmail: props.data.UserName,
    },
    TicketNoteDetails: {
      Technology: props.data.TechnologyTitle,
      TechnologyId: props.data.TechnologyEnum,
    },
    TicketType: 3,
  });

  useEffect(() => {
    setInputs({
      TicketDetails: {
        ContactName: props.data.CompanyName,
        ContactEmail: props.data.UserName,
      },
      TicketNoteDetails: {
        Technology: props.data.TechnologyTitle,
        TechnologyId: props.data.TechnologyEnum,
      },
      TicketType: 3,
    });
    setIntegrationsInfo(props.data);
  }, [props.data]);

  const handleClose = () => {
    setSlideHideClass("slideHide");
    setTimeout(() => {
      props.onHide();
      setSlideHideClass("");
    }, 300);
  };

  const handleInputChange = (event) => {
    event.persist();
    inputs.TicketNoteDetails[event.target.name] = event.target.value;
    if (event.target.name == "TechnologyId") {
      inputs.TicketNoteDetails.Technology = integrationsInfo.DDLValues.find(
        (elmt) => elmt.Value == event.target.value
      ).Text;
    }
    setInputs({ ...inputs });
  };

  async function handleSave() {
    setIsSubmit(true);
    const { is_error } = handleValidations(true);
    if (is_error) return;
    setIsRequestSend(true);
    const response = await postData(authTokens, "CreateConnectwiseTicket", {
      TicketDataJSON: JSON.stringify(inputs),
    });
    if (response.ExThrown == false) {
      setSubmitResponse(response.Message);
      setTimeout(() => {
        setSubmitResponse(false);
        props.onHide();
      }, 3000);
      setIsSubmit(false);
    }
    setIsRequestSend(false);
  }

  /**
   * Handle validations
   */
  const handleValidations = (submit) => {
    const { ToolName, LinkToSite, TechnologyId, Description } =
      inputs.TicketNoteDetails;
    let error_structure = {
      eIntegrationName: false,
      eIntegrationURL: false,
      eTechnologyEnum: false,
      eIntegrationComment: false,
    };
    if (!submit) return error_structure;
    if (!ToolName) {
      error_structure.eIntegrationName = true;
    }
    if (!LinkToSite) {
      error_structure.eIntegrationURL = true;
    }
    if (TechnologyId <= -1) {
      error_structure.eTechnologyEnum = true;
    }
    if (!Description) {
      error_structure.eIntegrationComment = true;
    }
    const {
      eIntegrationName,
      eIntegrationURL,
      eTechnologyEnum,
      eIntegrationComment,
    } = error_structure;

    if (
      eIntegrationName ||
      eIntegrationURL ||
      eTechnologyEnum ||
      eIntegrationComment
    ) {
      error_structure.is_error = true;
    }
    return error_structure;
  };

  const {
    eIntegrationName,
    eTechnologyEnum,
    eIntegrationURL,
    eIntegrationComment,
  } = handleValidations(isSubmit);
  return (
    integrationsInfo && (
      <Modal
        animation={false}
        show={props.show}
        className={`suggestIntegrationModal suggestmodalresolvezoomissue integrationModal rightModal modal-medium right ${slideHideClass}`}
        aria-labelledby="contained-modal-title-vcenter"
        onHide={handleClose}
      >
        <Modal.Header>
          <span className="cursor-pointer" onClick={handleClose}>
            <img src="/images/icons/close.svg" alt="" />
          </span>
          <h1 className="w-100 mb-0 mt-0 ml-4">Suggest Integration</h1>
        </Modal.Header>
        <Modal.Body>
          <div>
            {submitResponse && (
              <SuccessDialog text={submitResponse} ContWidth="85%" />
            )}
            <h2>
              What product would you like to integrate with your HighGround
              account?
            </h2>
            <p className="txt-grey">
              Can't see the tool you're looking for? Let us know by suggesting
              an integration below for our development team to review. Note: we
              can perform custom integrations on special request but these will
              be chargeable.
            </p>
            <p className="txt-grey">
              Suggestion will be puplished by:
              <br />
              Name: <b className="text-dark">{integrationsInfo.UserName}</b>
              <br />
              Company:{" "}
              <b className="text-dark">{integrationsInfo.CompanyName}</b>
            </p>
            <Form>
              <Form.Group>
                <InputGroup>
                  <InputGroup.Prepend>
                    <InputGroup.Text
                      className={`txt-grey ${
                        eIntegrationName && "border-danger"
                      }`}
                    >
                      Tool Name:
                    </InputGroup.Text>
                  </InputGroup.Prepend>
                  <FormControl
                    name="ToolName"
                    onChange={(e) => handleInputChange(e)}
                    className={eIntegrationName && "border-danger"}
                  />
                </InputGroup>
                <InputGroup className="mt-16px">
                  <InputGroup.Prepend>
                    <InputGroup.Text
                      className={`txt-grey ${
                        eIntegrationURL && "border-danger"
                      }`}
                    >
                      Link To Site:
                    </InputGroup.Text>
                  </InputGroup.Prepend>
                  <FormControl
                    name="LinkToSite"
                    onChange={(e) => handleInputChange(e)}
                    className={eIntegrationURL && "border-danger"}
                  />
                </InputGroup>
                <InputGroup className="mt-16px">
                  <InputGroup.Prepend>
                    <InputGroup.Text
                      className={`txt-grey ${
                        eTechnologyEnum && "border-danger"
                      }`}
                    >
                      Technology*:
                    </InputGroup.Text>
                  </InputGroup.Prepend>
                  <Form.Control
                    as="select"
                    defaultValue={integrationsInfo.TechnologyEnum}
                    name="TechnologyId"
                    className={eTechnologyEnum && "border-danger"}
                    onChange={(e) => handleInputChange(e)}
                  >
                    {integrationsInfo.DDLValues.map((option) => (
                      <option value={option.Value}>{option.Text}</option>
                    ))}
                  </Form.Control>
                </InputGroup>
              </Form.Group>
              <Form.Group className="mt-16px">
                <Form.Control
                  name="Description"
                  placeholder="Comment"
                  as="textarea"
                  rows={5}
                  onChange={(e) => handleInputChange(e)}
                  style={{ height: "72px" }}
                  className={eIntegrationComment && "border-danger"}
                />
              </Form.Group>
              <Button className="w-100 submit-btn mt-16px" onClick={handleSave}>
                {isRequestSend ? (
                  <span>
                    <Spinner animation="border" variant="light" size="sm" />{" "}
                    Submitting
                  </span>
                ) : (
                  "Submit"
                )}
              </Button>
            </Form>
          </div>
        </Modal.Body>
      </Modal>
    )
  );
};

export default SuggestIntegrationModal;
