import React, { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import Search from "./searchinput";
import "./dropdown.scss";

const DropdownWithMultiSelect = (props) => {
  const [data, setData] = useState([]);
  const [searchString, setSearchString] = useState("");

  useEffect(() => {
    if (props.data?.length > 0) {
      setData(props.data);
      setSearchString("");
    }
  }, [props.data]);

  // let getInitials = function (string) {
  //   let names = string.split(" "),
  //     initials = names[0].substring(0, 1).toUpperCase();

  //   if (names.length > 1) {
  //     initials += names[names.length - 1].substring(0, 1).toUpperCase();
  //   }
  //   return initials;
  // };
  return (
    <Dropdown className="custom_drop-down-multi">
      <Dropdown.Toggle
        className={`${
          props.selected.length > 0 ? "selected" : ""
        } d-flex align-items-center`}
      >
        {/* <span className="select_label">{props.label}</span> */}
        <img
          alt=""
          src="/images/supply-chain/user-group.svg"
          className="mr-2"
        />
        {props.selected.length > 0
          ? props.selected.map((val, i) => (
              <div
                className={`selected_label_inner2 ${i > 1 ? "d-none" : ""} `}
                key={i}
              >
                <div
                  className="d-flex align-items-center "
                  style={{
                    border: "1px solid #bfbfbf",
                    borderRadius: "30px",
                    padding: "4px",
                  }}
                >
                  <img
                    alt=""
                    width={16}
                    height={16}
                    className="mr-2 rounded-full"
                    src={val[props.iconKey]}
                  />{" "}
                  <span className="">{val[props.fieldValue]}</span>
                  <img
                    alt=""
                    className="ml-3 mx-1"
                    src="/images/supply-chain/send-questionaire-cross.svg"
                    onClick={(e) => {
                      e.stopPropagation();
                      props.onRemove(val[props.fieldId]);
                    }}
                  />
                </div>
              </div>
            ))
          : props.placeholder}
        {props.selected.length > 2 && (
          <span className={`labelcount ${true}`}>
            +{props.selected.length - 2}
          </span>
        )}
      </Dropdown.Toggle>
      <Dropdown.Menu className="">
        <div className="search_input">
          <div className="d-block">
            <div className="selected_label">
              {/* {props.selected.map((val, i) => val[props.fieldValue])} */}
              {props.selected.map((val, i) => (
                <div className="selected_label_inner--- mr-2 mb-2" key={i}>
                  <div
                    className="d-flex align-items-center "
                    style={{
                      border: "1px solid #bfbfbf",
                      borderRadius: "30px",
                      padding: "3px",
                    }}
                  >
                    <img
                      alt=""
                      width={16}
                      height={16}
                      className="mr-2 rounded-full"
                      src={val[props.iconKey]}
                    />{" "}
                    <span className="">{val[props.fieldValue]}</span>
                    <img
                      alt=""
                      className="ml-3 mx-1 pointer"
                      src="/images/supply-chain/send-questionaire-cross.svg"
                      onClick={(e) => {
                        e.stopPropagation();
                        props.onRemove(val[props.fieldId]);
                      }}
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
          <Search
            onChange={(e) => setSearchString(e.target.value)}
            type="text"
            value={searchString}
            placeholder="Search..."
            className="test"
          />
        </div>
        <div
          className="hide-scrollbar "
          style={{
            overflowY: "scroll",
            maxHeight: "250px",
          }}
        >
          {data
            .filter((item) => item[props.fieldValue].includes(searchString))
            .map((val) => (
              <div
                className={`dropdown_item ${
                  props.selected &&
                  Object.keys(
                    props.selected.filter(
                      (value) => value[props.fieldId] === val[props.fieldId]
                    )
                  ).length !== 0
                    ? "selected"
                    : ""
                }`}
                onClick={(e) => {
                  let Index = props.selected.findIndex(
                    (item) => item[props.fieldId] === val[props.fieldId]
                  );

                  if (Index === -1) {
                    props.onClick(val[props.fieldId]);
                  }
                }}
                key={val[props.fieldId]}
                data-id={val[props.fieldId]}
                data-value={val[props.fieldValue]}
              >
                <img
                  alt=""
                  width={32}
                  height={32}
                  className="mr-2 rounded-full"
                  src={val[props.iconKey]}
                />
                {val[props.fieldValue]}
                {props.selected &&
                Object.keys(
                  props.selected.filter(
                    (value) => value[props.fieldId] === val[props.fieldId]
                  )
                ).length !== 0 ? (
                  <img
                    src={"/images/theme/hovericons/tick.svg"}
                    className="float-right tick"
                    alt=""
                  />
                ) : (
                  ""
                )}
              </div>
            ))}
        </div>
        <div
          className="position-absolute bottom-0"
          style={{
            height: "5px",
            background: "white",
            width: "100%",
            filter: "blur(5px)",
            bottom: "9px",
          }}
        ></div>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default DropdownWithMultiSelect;



export const DropdownWithMultiSelectForSupplier = (props) => {
  const [data, setData] = useState([]);
  const [searchString, setSearchString] = useState("");

  useEffect(() => {
    if (props.data?.length > 0) {
      setData(props.data);
      setSearchString("");
    }
  }, [props.data]);
 
  return (
    <Dropdown className="custom_drop-down-multi fix-menu-bottom">
      <Dropdown.Toggle
        className={`${
          props.selected.length > 0 ? "selected" : ""
        } d-flex align-items-center flex-wrap h-auto`}
      >
         
        <img
          alt=""
          src="/images/supply-chain/user-group.svg"
          className="mr-2"
        />
        {props.selected.length > 0
          ? props.selected.map((val, i) => (
              <div
                className={`selected_label_inner2 ${i > 1 ? "d-non e" : ""} m-1`}
                key={i}
              >
                <div
                  className="d-flex align-items-center "
                  style={{
                    border: "1px solid #bfbfbf",
                    borderRadius: "30px",
                    padding: "4px",
                  }}
                >
                  <img
                    alt=""
                    width={16}
                    height={16}
                    className="mr-2 rounded-full"
                    src={val[props.iconKey]}
                  />{" "}
                  <span className="">{val[props.fieldValue]}</span>
                  <img
                    alt=""
                    className="ml-3 mx-1"
                    src="/images/supply-chain/send-questionaire-cross.svg"
                    onClick={(e) => {
                      e.stopPropagation();
                      props.onRemove(val[props.fieldId]);
                    }}
                  />
                </div>
              </div>
            ))
          :""
          //  props.placeholder
          }
        {/* {props.selected.length > 2 && (
          <span className={`labelcount ${true}`}>
            +{props.selected.length - 2}
          </span>
        )} */}
        <input className="border-none bg-transparent m-1" 
            onChange={(e) => setSearchString(e.target.value)}
            type="text"
            value={searchString}
            placeholder={props.placeholder}
        
        />
      </Dropdown.Toggle>
      <Dropdown.Menu className="">
      {  props.selected?.length>0 && false &&<div className="search_input">
          <div className="d-block">
         <div className="selected_label">
              {props.selected.map((val, i) => (
                <div className="selected_label_inner--- mr-2 mb-2" key={i}>
                  <div
                    className="d-flex align-items-center "
                    style={{
                      border: "1px solid #bfbfbf",
                      borderRadius: "30px",
                      padding: "3px",
                    }}
                  >
                    <img
                      alt=""
                      width={16}
                      height={16}
                      className="mr-2 rounded-full"
                      src={val[props.iconKey]}
                    />{" "}
                    <span className="">{val[props.fieldValue]}</span>
                    <img
                      alt=""
                      className="ml-3 mx-1 pointer"
                      src="/images/supply-chain/send-questionaire-cross.svg"
                      onClick={(e) => {
                        e.stopPropagation();
                        props.onRemove(val[props.fieldId]);
                      }}
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
          {/* <Search
            onChange={(e) => setSearchString(e.target.value)}
            type="text"
            value={searchString}
            placeholder="Search..."
            className="test"
          /> */}
        </div>}
        <div
          className="hide-scrollbar "
          style={{
            overflowY: "scroll",
            maxHeight: "250px",
          }}
        >
          {data
            .filter((item) => item[props.fieldValue]?.toLowerCase()?.includes(searchString.toLowerCase()))
            .map((val) => (
              <div
                className={`dropdown_item ${
                  props.selected &&
                  Object.keys(
                    props.selected.filter(
                      (value) => value[props.fieldId] === val[props.fieldId]
                    )
                  ).length !== 0
                    ? "selected"
                    : ""
                }`}
                onClick={(e) => {
                  let Index = props.selected.findIndex(
                    (item) => item[props.fieldId] === val[props.fieldId]
                  );

                  if (Index === -1) {
                    props.onClick(val[props.fieldId]);
                  }
                  setSearchString("")
                }}
                key={val[props.fieldId]}
                data-id={val[props.fieldId]}
                data-value={val[props.fieldValue]}
              >
                <img
                  alt=""
                  width={32}
                  height={32}
                  className="mr-2 rounded-full"
                  src={val[props.iconKey]}
                />
                {val[props.fieldValue]}
                {props.selected &&
                Object.keys(
                  props.selected.filter(
                    (value) => value[props.fieldId] === val[props.fieldId]
                  )
                ).length !== 0 ? (
                  <img
                    src={"/images/theme/hovericons/tick.svg"}
                    className="float-right tick"
                    alt=""
                  />
                ) : (
                  ""
                )}
              </div>
            ))}
        </div>
        <div
          className="position-absolute bottom-0"
          style={{
            height: "5px",
            background: "white",
            width: "100%",
            filter: "blur(5px)",
            bottom: "9px",
          }}
        ></div>
      </Dropdown.Menu>
    </Dropdown>
  );
};
