import React, { Fragment, useState, useEffect, useRef } from "react";
import { Table, Button, Form } from "react-bootstrap";
import {
  postData,
  getAPIData,
  addPaymentMethodStripe,
  subscriptionStripe,
  attachPaymentMethodStripe,
  updateCustomer,
} from "../../../Utils/commonApi";
import { useAuth } from "../../context/Auth";
import DowngradeModal from "./Downgrade/index";
// import UpgradePremium from "./UpgradePremium/index";
import Modal from "../../Common/Modal";
import AddCardForm from "../../Common/AddCardFormPacks";
import PaymentResponse from "../../Common/PaymentResponsepacks";
import Loader from "../../Common/loader";
import ToastMessage from "../../ToastMessage";
import ContentLoader from "react-content-loader";
import EnquiryModal from "./EnquiryModal";
import queryString from "query-string";
import { TrackUser } from "../../../Utils/SegmentFunctions";

const Subscriptions = () => {
  const params = queryString.parse(window.location.search);
  const rowElement = useRef(null);
  const { authTokens } = useAuth();
  const [subscriptionPlans, setSubscriptionPlans] = useState(0);
  const [isPlanMonthly, setIsPlanMonthly] = useState(false);
  const [isIntegrationMonthly, setIsIntegrationMonthly] = useState(false);
  const [downgradeModalData, setDowngradeModalData] = useState({
    show: false,
    price: "",
    planName: isPlanMonthly,
  });
  const [paymentSuccessData, setPaymentSuccessData] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [reponseModal, setReponseModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoading2, setIsLoading2] = useState(true);
  const [subscriptionsPlans, setSubscriptionsPlans] = useState({});
  const [packageModalData, setPackageModalData] = useState({});
  const [stripeError, setStripeError] = useState(false);
  const [proService, setProService] = useState(false);
  const [isPackage, setIsPackage] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [isEnquiry, setIsEnquiry] = useState(false);
  const [toastMsg, setToastMsg] = useState({
    show: false,
    msg: false,
  });

  useEffect(() => {
    getSubscriptionPlans();
    getSubscriptionsPlans();
  }, []);

  async function getSubscriptionPlans() {
    const result = await getAPIData("90", authTokens);
    setSubscriptionPlans(result);
  }

  async function getSubscriptionsPlans() {
    const result = await getAPIData("193", authTokens);
    setSubscriptionsPlans(result);
    if (result.mr.Success) {
      if (params.scroll) {
        if (rowElement.current) {
          setTimeout(() => rowElement.current.scrollIntoView(), 100);
        }
      }
    }
    setIsLoading2(false);
  }

  async function getPackage(priceid) {
    setIsLoading(true);
    const response = await getAPIData("191", authTokens, {
      OptionEnum1: priceid,
    });
    if (response.mr.Success == true) {
      setPackageModalData(response);
      setIsLoading(false);
    } else {
      setShowToast(true);
    }
  }

  async function subsciptionStatus() {
    setIsLoading(true);
    if (!isPackage) {
      const response = await getAPIData("199", authTokens);
    }
  }

  const changePlanType = (e) => {
    if (e.target.name == "integration_pack") {
      setIsIntegrationMonthly(!isIntegrationMonthly);
    } else {
      setIsPlanMonthly(!isPlanMonthly);
    }
  };

  async function subscriptionPayment(
    customerId,
    priceId,
    paymentMethod = false,
    taxRateId
  ) {
    setIsLoading(true);
    if (!paymentMethod) {
      subsciptionStatus();
      const data = await subscriptionStripe(customerId, priceId, taxRateId);
      if (data.error) {
        setStripeError(true);
      } else {
        setProService(data);
      }
      setIsLoading(false);
      setReponseModal(true);
    } else {
      updateCustomer(customerId, paymentMethod).then((res) => {
        if (res.error) {
          setStripeError(true);
          setProService("");
          setReponseModal(true);
          setIsLoading(false);
        } else {
          subsciptionStatus();
          const data = subscriptionStripe(customerId, priceId, taxRateId).then(
            (res) => {
              if (res.error) {
                setStripeError(true);
                setReponseModal(true);
                setIsLoading(false);
              } else {
                setStripeError(false);
                setProService(res);
                updateSubscription(res.id, paymentMethod);
                getSubscriptionsPlans();
                setReponseModal(true);
                setIsLoading(false);
              }
            }
          );
        }
      });
    }
  }
  async function updateSubscription(subscritionId, paymentmethodId) {
    const response = await fetch(
      `${process.env.REACT_APP_API_BASE_URL}/CC_API/UpdateSubscription?StripeSubscriptionId=${subscritionId}&StripePaymentMethodID=${paymentmethodId}`,
      {
        method: "POST",
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${authTokens}`, // notice the Bearer before your token
        },
      }
    ).then((response) => response.json());
    if (response.AccessKey !== undefined) {
      localStorage.setItem(
        "user_MessageObj",
        JSON.stringify(response.AccessKey)
      );
    }
    return response;
  }

  // add card stripe
  async function addCardStripe(
    billingDetails,
    cardDetails,
    customerId,
    priceId,
    taxRateId
  ) {
    setIsLoading(true);

    const month = cardDetails.monthyear.split("/");
    const data = {
      CardName: cardDetails.cardname,
      CardNumber: cardDetails.cardnumber,
      ExpMonth: month[0],
      ExpYear: month[1],
      Cvc: cardDetails.cvc,
      CompanyName: billingDetails.cname,
      CompanyAddress: billingDetails.caddress,
      Country: billingDetails.ccountry,
      CompanyRegistration: billingDetails.creg,
      VAT: billingDetails.cvat,
    };

    const response = await fetch(
      `${process.env.REACT_APP_API_BASE_URL}/CC_API/AddPaymentMethod`,
      {
        method: "POST",
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${authTokens}`, // notice the Bearer before your token
        },
        body: JSON.stringify(data),
      }
    )
      .then((response) => response.json())
      .then((res) => {
        if (res.mr.Success) {
          updateCustomer(customerId, res.PaymentMethodId).then((res) => {
            if (res.error) {
              setStripeError(true);
              setIsLoading(false);
              setProService("");
              setReponseModal(true);
            } else {
              subscriptionPayment(customerId, priceId, false, taxRateId);
            }
          });
        } else {
          setProService(res);
          setReponseModal(true);
          setIsLoading(false);
        }
      });
  }
  async function requestHandler(priceId, flag = false) {
    setModalOpen(true);
    if (flag) {
      setIsPackage(true);
    } else {
      setIsPackage(false);
    }
    await getPackage(priceId);
  }
  const Editions = {
    0: "Freemium",
    1: "Premium",
    2: "Enterprise",
  };

  return (
    <Fragment>
      <img
        src={`/images/visa.svg`}
        style={{ position: "absolute", opacity: 0 }}
        alt=""
      />
      <EnquiryModal
        show={isEnquiry}
        title="Enquiry"
        closeModal={setIsEnquiry}
      />
      {showToast && <ToastMessage message={"Something went wrong"} />}
      {isLoading2 && (
        <ContentLoader
          style={{ marginTop: "-80px" }}
          speed={2}
          height={"100%"}
          width={"100%"}
          viewBox="0 -80 1500 1000"
          backgroundColor="#f1f2f7e8"
          foregroundColor="#ebecf1"
        >
          <rect
            x="15"
            y="0"
            rx="15"
            ry="15"
            height={600}
            width={350}
            style={{ height: "660", width: "25%" }}
          />

          <rect
            x="405"
            y="0"
            rx="15"
            ry="15"
            height={600}
            width={350}
            style={{ height: "660", width: "22%" }}
          />
          <rect
            x="750"
            y="0"
            rx="15"
            ry="15"
            height={600}
            width={350}
            style={{ height: "660", width: "22%" }}
          />

          <rect
            x="1095"
            y="0"
            rx="15"
            ry="15"
            height={600}
            width={350}
            style={{ height: "660", width: "22%" }}
          />
        </ContentLoader>
      )}

      <div className={`settings_container ${!isLoading2 ? "" : "d-none"} `}>
        {subscriptionsPlans && (
          <Table className="settings_table cybercare-table">
            <thead>
              <tr>
                <th className="border-0 pl-0" style={{ width: "350px" }}>
                  <h1>{subscriptionsPlans.SubscriptionTitle}</h1>
                  <span className="linkGreyText">
                    {subscriptionsPlans.SubscriptionDescription}
                  </span>
                  <div className="d-flex mt40px">
                    <p className="textBlack pr-2">Pay Anually</p>
                    <Form.Check
                      type="switch"
                      id="custom-switch"
                      name="subscription_plan"
                      className="m-0 toogle-switch"
                      onChange={changePlanType}
                    />
                    <p className="textBlack">Monthly</p>
                  </div>
                </th>
                {subscriptionsPlans.SubscriptionPlan &&
                  subscriptionsPlans.SubscriptionPlan.map((data, index) => (
                    <th>
                      <h1
                        className={`dashHeader ${
                          data.ButtonStatus == 0 ? "txt-blue" : "blackText"
                        }`}
                      >
                        {/* {Editions[subscriptionPlans.MyEdition]} */}
                        {data.Name}
                      </h1>
                      <span
                        className={`main-heading ${
                          index == 2 ? "greyText heading2 mb16px" : "blackText"
                        }`}
                      >
                        {isPlanMonthly ? data.MonthlyCost : data.YearlyCost}
                      </span>
                      <p
                        className="txt-blue"
                        style={{ height: index == 2 ? "32px" : "17px" }}
                      >
                        {data.AnnualDiscount}
                      </p>
                      <Button
                        className={`btn-block ${
                          data.ButtonStatus != 0 ? "btn-blue" : "btn-disable"
                        }`}
                        onClick={() => {
                          if (data.ButtonText == "Upgrade") {
                            TrackUser(
                              "Subscriptions plan Upgrade button clicked"
                            );
                          }

                          (data.ButtonText == "Upgrade" &&
                            requestHandler(data.PriceId)) ||
                            (data.ButtonText == "Downgrade" &&
                              setDowngradeModalData((prevState) => ({
                                ...prevState,
                                show: true,
                                price: data.PriceId,
                                planName: isPlanMonthly,
                              })));
                          data.ButtonStatus == 3 && setIsEnquiry(true);
                        }}
                      >
                        {data.ButtonText}
                      </Button>
                    </th>
                  ))}
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <h1>Includes</h1>
                </td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td className="linkGreyText">Users</td>
                {subscriptionsPlans.SubscriptionPlan &&
                  subscriptionsPlans.SubscriptionPlan.map((data, index) => (
                    <td class>{data.Includes_Users}</td>
                  ))}
              </tr>
              <tr>
                <td className="linkGreyText">Employees</td>
                {subscriptionsPlans.SubscriptionPlan &&
                  subscriptionsPlans.SubscriptionPlan.map((data, index) => (
                    <td class>{data.Includes_Employees}</td>
                  ))}
              </tr>
              <tr>
                <td className="linkGreyText">Integrations</td>
                {subscriptionsPlans.SubscriptionPlan &&
                  subscriptionsPlans.SubscriptionPlan.map((data, index) => (
                    <td class>{data.Includes_Integrations}</td>
                  ))}
              </tr>
              <tr>
                <td className="linkGreyText">API Sync Frequency</td>
                {subscriptionsPlans.SubscriptionPlan &&
                  subscriptionsPlans.SubscriptionPlan.map((data, index) => (
                    <td class>{data.Includes_APISyncFrequency}</td>
                  ))}
              </tr>
              <tr>
                <td className="linkGreyText">Cyber Reports</td>
                {subscriptionsPlans.SubscriptionPlan &&
                  subscriptionsPlans.SubscriptionPlan.map((data, index) => (
                    <td class>{data.Includes_CyberReports}</td>
                  ))}
              </tr>
              <tr>
                <td className="linkGreyText pt-1 pb-1">
                  <div className="d-flex justify-content-between">
                    <span className="align-self-center">Mobile App</span>
                    <span>
                      <img
                        src="/images/icons/apple.svg"
                        height="26"
                        className="pr-2"
                        alt=""
                      />
                      <img
                        src="/images/icons/google-play.svg"
                        height="26"
                        alt=""
                      />
                    </span>
                  </div>
                </td>
                <td>
                  <img src="/images/icons/tickIcon.svg" alt="" />
                </td>
                <td>
                  <img src="/images/icons/tickIcon.svg" alt="" />
                </td>
                <td>
                  <img src="/images/icons/tickIcon.svg" alt="" />
                </td>
              </tr>
              <tr>
                <td className="linkGreyText">Compliance Module</td>
                <td>
                  <img src="/images/icons/tickIcon.svg" alt="" />
                </td>
                <td>
                  <img src="/images/icons/tickIcon.svg" alt="" />
                </td>
                <td>
                  <img src="/images/icons/tickIcon.svg" alt="" />
                </td>
              </tr>
            </tbody>
          </Table>
        )}
      </div>
      {!subscriptionsPlans.ShowIntegrations && <br />}
      {subscriptionsPlans.ShowIntegrations && (
        <div
          className={`settings_container mt16px ${!isLoading2 ? "" : "d-none"}`}
        >
          <Table className="settings_table cybercare-table">
            <thead>
              <tr>
                <th className="border-0 pl-0" style={{ width: "350px" }}>
                  <h1>{subscriptionsPlans.IntegrationTitle}</h1>
                  <span className="linkGreyText">
                    {subscriptionsPlans.IntegrationDescription}
                  </span>
                  <div className="d-flex mt40px">
                    <p className="textBlack pr-2">Pay Anually</p>
                    <Form.Check
                      type="switch"
                      id="custom-switch2"
                      name="integration_pack"
                      className="m-0 toogle-switch"
                      onChange={changePlanType}
                    />
                    <p className="textBlack">Monthly</p>
                  </div>
                </th>
                {subscriptionsPlans.IntegrationPacks &&
                  subscriptionsPlans.IntegrationPacks.map((data, index) => (
                    <th>
                      <h1
                        className={`dashHeader ${
                          data.ButtonStatus == 0 ? "txt-blue" : "blackText"
                        }`}
                      >
                        {" "}
                        {data.Name}
                      </h1>
                      <span className="main-heading">
                        {isIntegrationMonthly
                          ? data.MonthlyCost
                          : data.YearlyCost}
                      </span>
                      <p className="txt-blue" style={{ height: "17px" }}>
                        {" "}
                        {data.AnnualDiscount}
                      </p>
                      <Button
                        ref={rowElement}
                        className={`btn-block ${
                          data.ButtonStatus != 0 ? "btn-blue" : "btn-disable"
                          //  !data.Selected ? "btn-blue" : "btn-disable"
                        }`}
                        onClick={() => {
                          ((data.ButtonText == "Buy" ||
                            data.ButtonText == "Upgrade") &&
                            requestHandler(data.PriceId, true)) ||
                            (data.ButtonText == "Downgrade" &&
                              setDowngradeModalData((prevState) => ({
                                ...prevState,
                                package:
                                  subscriptionsPlans.IntegrationPacks[index],
                                show: true,
                                price: data.PriceId,
                                planName: isIntegrationMonthly,
                              })));
                        }}
                      >
                        {" "}
                        {data.ButtonText}
                      </Button>
                    </th>
                  ))}
              </tr>
            </thead>
          </Table>
        </div>
      )}

      <DowngradeModal
        data={downgradeModalData}
        setData={setDowngradeModalData}
        integration={getSubscriptionsPlans}
        showToast={setShowToast}
      />
      <Modal
        isLoading={isLoading}
        isOpen={modalOpen}
        data={paymentSuccessData}
        isClose={() => {
          setModalOpen(false);
          setReponseModal(false);
          setShowToast(false);
        }}
      >
        {isLoading && <Loader />}

        {!reponseModal ? (
          <>
            <AddCardForm
              planMonthly={setIsPlanMonthly}
              packMonthly={setIsIntegrationMonthly}
              upgradeModal={true}
              isPackage={isPackage}
              isLoading={setIsLoading}
              isResponse={setReponseModal}
              data={packageModalData}
              stripeApi={{
                confirm_payment: subscriptionPayment,
                addCard: addCardStripe,
                isPackMonthly: isIntegrationMonthly,
                isPlanMonthly: isPlanMonthly,
              }}
            />
          </>
        ) : (
          <PaymentResponse
            bulletPoints={packageModalData.BulletPoints}
            data={proService}
            isError={stripeError}
            setPaymentSuccessData={setPaymentSuccessData}
            isIntegrationPack={isPackage}
            closeModal={() => setModalOpen(false)}
            isClose={() => {
              setReponseModal(false);
            }}
          />
        )}
      </Modal>
    </Fragment>
  );
};

export default Subscriptions;
