import React, { useState } from "react";
import { Dropdown } from "react-bootstrap";
import "./device-issues-filter.styles.scss";
import SuccessSignIcon from "../../../icons/success-sign.icon";

const mockFilters = [
  {
    Title: 'Issues',
    Items: [
      { Name: 'Old Devices (5yrs+)' },
      { Name: 'Warranty Expired' },
      { Name: 'End of Life' },
    ]
  },
  {
    Title: 'Filters',
    Items: [
      { Name: 'Operating System' },
      { Name: 'Installed Date' },
      { Name: 'Warranty Expiry' },
      { Name: 'End of Life Date' },
    ]
  },
];

const DeviceIssuesFilter = ({  }) => {
  const [selectedFilters, setSelectedFilters] = useState([]);

  const onSelectItem = (item) => {
    if (selectedFilters.includes(item)) {
      setSelectedFilters(selectedFilters.filter(filter => filter !== item));
    } else {
      setSelectedFilters([...selectedFilters, item]);
    }
  }

  const isItemSelected = (item) => selectedFilters.includes(item);

  return (
    <Dropdown className="my-dropdown device-issues-filter transparennt-wrapper-dropdown">
      <Dropdown.Toggle variant="light" className="toggle px-2">
        Filter
      </Dropdown.Toggle>
      <Dropdown.Menu className="menu">
        {mockFilters.map((filter, index) => {
          const hasSelectedItems = filter.Items.some(item => isItemSelected(item));
          return (
            <div className="section" key={index}>
              <h3 className="title m-0 f-darkgrey">{filter.Title}</h3>
              <div>
                {filter.Items.map((item, index) => {
                  const isSelected = isItemSelected(item);
                  return (
                    <div 
                      className="item" 
                      key={index}
                      onClick={() => onSelectItem(item)}
                    >
                      {(hasSelectedItems || isSelected) && (
                        <SuccessSignIcon width={12} height={9} fill={isSelected ? "#616778" : 'transparent'} />
                      )}
                      <span className="f-grey">{item.Name}</span>
                    </div>
                  );
                })}
              </div>
            </div>
          )
        })}
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default DeviceIssuesFilter;
