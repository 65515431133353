import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import "./integration-list.styles.scss";
import IntegrationItemDropdown from "./integration-item.dropdown";
import CustomInputField from "../../Theme/CustomInputField";

const IntegrationListItem = ({ 
  integrationName, 
  integrationId, 
  isLoading, 
  onRemoveOption,
  onRenameOption, 
  style,
  drop = "down",
  showAddSub = true,
  showRename = true,
  onAddSubscription = () => { },
  setShowResetToolModal = () => { },
  setSelectedToolId = () => { }
}) => {
  const [inputRef, setInputRef] = useState(null);
  const [newIntegrationName, setNewIntegrationName] = useState('');
  const [isRenaming, setIsRenaming] = useState(false);

  const onRenameOptionClick = () => {
    setIsRenaming(true);
    setNewIntegrationName(integrationName);
  }

  const onCancelRename = (e) => {
    e.stopPropagation();
    setIsRenaming(false);
    setNewIntegrationName('');
  }

  const onAcceptRename = (e) => {
    e.stopPropagation();
    setIsRenaming(false);
    onRenameOption(integrationId, newIntegrationName);
  }

  useEffect(() => {
    if (!isRenaming) return;
    inputRef?.current?.focus();
  }, [isRenaming, inputRef?.current]);

  return (
    <div className="d-flex align-items-center justify-content-between integration-list-item" style={style}>
      <div className="d-flex align-items-center">
        <img
          alt=""
          style={{
            width: "16px",
            height: "16px",
            borderRadius: '2px'
          }}
          src={`/images/attack-surface/integrations/${integrationId}.webp`}
          className="mr-2"
          onError={({ currentTarget }) => {
            currentTarget.onerror = null;
            currentTarget.src =
              "/images/msp/no-tool-image.png";
          }}
        />
        {isRenaming ? (
          <div className="d-flex align-items-center">
            <CustomInputField
              placeholder={"Integration name"}
              type={"text"}
              value={newIntegrationName}
              onChange={(e) => {
                setNewIntegrationName(e.target.value);
              }}
              className='rename-input'
              setRef={setInputRef}
            />
          </div>
        ) : (
          <div className="f-500" style={{ padding: '2px 0' }}> {integrationName}</div>
        )}
        <div>
          {isLoading? (
            <Spinner
              animation="border"
              variant="secondary"
              size="sm"
              className="mx-2"
              style={{
                width: "12px",
                height: "12px",
                marginBottom: '2px'
              }}
            />
          ) : (
            ""
          )}
        </div>
      </div>
      {!isRenaming ? (
        <div>
          <IntegrationItemDropdown 
            integrationId={integrationId} 
            onRemoveOption={onRemoveOption} 
            onRenameOption={onRenameOptionClick}
            showAddSub={showAddSub}
            showRename={showRename}
            drop={drop} 
            setShowResetToolModal={setShowResetToolModal}
            setSelectedToolId={setSelectedToolId}
            onAddSubscription={onAddSubscription}
          />
        </div>
      ) : (
        <div className="d-flex align-items-center ml-2" style={{ gap: '8px' }}>
          <button className="apply" onClick={onAcceptRename}>Apply</button>
          <button className="cancel" onClick={onCancelRename}>Cancel</button>
        </div>
      )}
    </div>
  );
}

export default IntegrationListItem;
