import React, { useState, useEffect } from "react";
import { Row, Col, Modal, Form, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { setcompany } from "../../../../redux/companyState/actions";

import "./companySettingsModals.scss";

export const UpdateNameModal = ({ show, hideModal, handleOnChange }) => {
  const [companyName, setCompanyName] = useState("");
  const [error, setError] = useState("");
  const dispatch = useDispatch();
  const companyDetails = useSelector((state) => state?.company?.companyDetail);

  const UpdateName = () => {
    if (companyName.length == 0) {
      setError("Name is required");
      return false;
    }
    // if (companyName.match(/[A-Za-z]+/)) {
    //   setError("Don't use numbers or symbols");
    //   return false;
    // }

    handleOnChange(companyName, "SOcompany").then((res) => {
      if (res.Success) {
        dispatch(setcompany({ CompanyName: companyName }));
        hideModal();
      }
    });
  };
  useEffect(() => {
    if (show) {
      setCompanyName(companyDetails?.CompanyName);
    }
  }, [show]);

  return (
    <Modal
      animation={false}
      // show={true}
      show={show}
      dialogClassName=" company-settings-name-modal"
      className=" "
      aria-labelledby=" company-settings-name-modal"
      centered
      scrollable
      onHide={hideModal}
    >
      <Modal.Header className="text-center">
        <div className="w-100 mb-0 font-bold-14">Change Company Name --- </div>

        <span className="cursor-pointer" onClick={hideModal}>
          <img src="/images/icons/close.svg" alt="" className="close-icon" />
        </span>
      </Modal.Header>
      <Modal.Body>
        <Row>
          {/* Company Name update*/}
          <Col xs={12}>
            <div className="mt-3">
              <p className="label-heading m-0 mb-1">Company Name</p>
              <Form.Group controlId="formBasicInput">
                <Form.Control
                  className={`${error ? "input-error" : ""}`}
                  as="input"
                  type="text"
                  placeholder="Enter company name"
                  value={companyName}
                  onChange={(e) => {
                    setError("");
                    setCompanyName(e.target.value);
                  }}
                ></Form.Control>
              </Form.Group>
              {error && <span className="error-text">{error}</span>}
            </div>
          </Col>
        </Row>
      </Modal.Body>

      <Modal.Footer>
        <div className="d-flex justify-content-end">
          <Button className="cancel-btn" onClick={hideModal}>
            Cancel
          </Button>
          <Button className="save-btn" onClick={UpdateName}>
            Save
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};
