import React, { useState, useEffect, useRef, Fragment } from "react";
import {
  Button,
  Modal,
  Row,
  Col,
  InputGroup,
  Form,
  OverlayTrigger,
  FormControl,
  Tooltip,
} from "react-bootstrap";
import "./index.scss";
import { getAPIData } from "../../../../Utils/commonApi";
import { useAuth } from "../../../context/Auth";
import Loader from "../../../Common/loader";

const RemovePaymentMethod = (props) => {
  const { authTokens, setAuthTokens } = useAuth();
  const [isSelectedMethod, setIsSelectedMethod] = useState();
  const [errMsg, setErrMsg] = useState("");
  const [isLoader, setIsLoader] = useState(true);
  const [isSucces, setIsSuccess] = useState(false);
  const [resData, setResData] = useState({});
  const [isDisabled, setIsDisabled] = useState(true);
  const inptElement = useRef(null);
  const { data, paymentList, setPaymentList, setToastRemove } = props;

  useEffect(() => {
    if (Object.keys(data).length !== 0) {
      setIsLoader(false);
    }
  }, [data]);

  const removeCardHandler = async () => {
    const cardList = paymentList.filter((item) => item.Id !== props.cardId);
    const cardMethod = paymentList.filter((item) => item.Id == props.cardId);
    setIsLoader(true);
    const response = await fetch(
      `${process.env.REACT_APP_API_BASE_URL}/CC_API/DeletePaymentMethod?objId_PaymentMethod=${props.cardId}&StripeId_NewPaymentMethod=${isSelectedMethod}`,
      {
        method: "POST",
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${authTokens}`, // notice the Bearer before your token
        },
        // body: JSON.stringify(data)
      }
    )
      .then((response) => response.json())
      .then((res) => {
        //  console.log(res)
        if (!res.Success) {
          props.setIsOpen(false);
          setErrMsg("Something went wrong!");
          setIsSuccess(false);
        } else {
          props.setIsOpen(false);
          setToastRemove({
            show: true,
            msg: "Removed Successfully",
          });

          let demo = 0;
          const interval = setInterval(function () {
            demo = demo + 1;
            if (demo >= 95) {
              clearInterval(interval);
              setToastRemove({
                show: false,
                msg: "Removed Successfully",
              });
            }
          }, 40);
          setIsSelectedMethod("");

          setPaymentList(cardList);
        }
      });
    setIsLoader(false);
  };

  return (
    <Modal
      animation={false}
      show={props.isOpen}
      dialogClassName="modal-50w"
      className="integrationModal centerModal dashboardModal upgradePremium removePaymentMethod"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      scrollable
    >
      {isLoader && <Loader />}
      <Modal.Header>
        <h1 className="w-100 mb-0 mt-0 text-center">Remove Payment Method</h1>
        <span className="cursor-pointer">
          <img
            src="/images/icons/close.svg"
            onClick={() => {
              props.setIsOpen(false);
              setErrMsg("");
              setIsSuccess(false);
              setIsSelectedMethod("");
              setIsDisabled(true);
              setIsLoader(true);
            }}
            alt=""
          />
        </span>
      </Modal.Header>
      <Modal.Body>
        <Row className="m-0">
          <Col md={12} className="p-0 billingInfo">
            <div className="d24" />
            <div className="d24" />
            <div className="remove_method">
              <p className="fnt16">{data && data?.Title}</p>
              <p>
                {data && data.CardIcon && (
                  <img
                    width="48"
                    src="/images/visa.svg"
                    className="mr-2"
                    alt=""
                  />
                )}
                <span className="crlblu textUpper">
                  {data && data.CardType}
                </span>{" "}
                *{data && data.CardLastFourDigits}
              </p>
            </div>
            <div className="d24" />
            <hr />
            <div className="d24" />
            <p className="m-0 crlblu d-flex align-items-start ">
              <img
                width="15"
                src={data && data.DescriptionIcon}
                className="mr-2 mt-1"
                alt=""
              />
              <span
                style={{
                  color: `${
                    data && data.DescriptionColour ? data.DescriptionColour : ""
                  }`,
                }}
              >
                {data && data.Description}
              </span>
            </p>
            {errMsg != "" && (
              <div>
                <hr className="mt-5 mb-3" />
                <div className="redclrr d-flex pl-3 align-items-start">
                  <img
                    width="12"
                    src="/images/subscription/eicon.svg"
                    className="mr-2 mt5"
                    alt=""
                  />
                  {errMsg}
                </div>
                <div ref={inptElement} />
              </div>
            )}

            {isSucces && (
              <div>
                <hr className="mt-5 mb-3" />
                <div
                  className="redclrr d-flex pl-3 align-items-start"
                  style={{ color: "#40d88e" }}
                >
                  {resData && resData.Message}
                </div>
                <div ref={inptElement} />
              </div>
            )}
            {data && data.ActiveSub_List && (
              <div>
                <div className="d24" />
                {data && data.ActiveSub_List.length > 0 && (
                  <p>
                    <b>Active Subscription</b>
                  </p>
                )}

                {data.ActiveSub_List.map((value, index) => (
                  <div key={index}>
                    <div className="remov_list">
                      <div>
                        <img
                          width="40"
                          src={`/images/ProServices/${value.Icon}`}
                          className="mr-2 mt-1"
                          alt=""
                        />
                      </div>

                      <div>
                        <div className="fwb4">{value.Name}</div>
                        <div className="d-flex list_p">
                          <div className="price_month crlblu">{value.Cost}</div>
                          <div
                            className="crlgrn d-flex pl-3"
                            style={{ color: value.StatusColor }}
                          >
                            <img
                              style={{ height: "11px", marginTop: "3px" }}
                              src={value.StatusIcon}
                              className="mr-2"
                              alt=""
                            />
                            {value.StatusText}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}

                <hr />
                <div className="d24" />

                <div
                  className="redclrr d-flex align-items-start mb-3"
                  style={{ color: data.PaymentMethodTextColour }}
                >
                  <img
                    width="15"
                    src={data.PaymentMethodIcon}
                    className="mr-2 mt5"
                    alt=""
                  />
                  {data.PaymentMethodText}
                </div>
                {data.PaymentMethod_List &&
                  data.PaymentMethod_List.length == 0 && (
                    <div className="d24" />
                  )}

                {data && data.ActiveSub_List.length > 0 && (
                  <div className="payment_method_wrapper">
                    {data.PaymentMethod_List &&
                      data.PaymentMethod_List.map((val, index) => (
                        <Fragment
                          key={index}
                          className="payment_method_wrapper"
                        >
                          <div
                            onClick={() => {
                              setIsSelectedMethod(val.StripeId);
                              setIsDisabled(false);
                            }}
                            className={`crsrpoint greyBorderCont mr8px position-relative  flex-fill w30 mb-2 ${
                              isSelectedMethod == val.StripeId && "brdBlu"
                            }`}
                          >
                            <Form.Group
                              controlId={val.Id}
                              className="round-checkbox blue-checkbox"
                            >
                              <Form.Check
                                type="radio"
                                name="stripeId"
                                value={
                                  isSelectedMethod == val.StripeId
                                    ? true
                                    : false
                                }
                                checked={
                                  isSelectedMethod == val.StripeId
                                    ? true
                                    : false
                                }
                                label=""
                                // defaultChecked={
                                //   isSelectedMethod == val.StripeId
                                //     ? true
                                //     : false
                                // }
                              />
                            </Form.Group>

                            <div className="center-container text-center">
                              <div className="heading3">
                                <img
                                  src={`/images/${val.Icon}`}
                                  width="30"
                                  alt=""
                                />
                              </div>

                              <div className="heading3  txtcardspace textUpper pb-1">
                                {val.CardType}
                              </div>
                              <p className="greyText">{val.CardNumber}</p>
                            </div>
                          </div>
                        </Fragment>
                      ))}
                  </div>
                )}
              </div>
            )}
          </Col>

          <div className="d24" />
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Row className="m-0 align-items-center justify-content-end">
          <div
            className="mr-4 crsrpoint"
            onClick={() => {
              setErrMsg("");
              setIsSuccess(false);
              props.setIsOpen(false);
            }}
          >
            Cancel
          </div>
          {data && data.ActiveSub_List && data.ActiveSub_List.length > 0 ? (
            <Button
              variant="primary"
              type="submit"
              disabled={
                isDisabled ? true : data && !data.ButtonActive ? true : false
              }
              className={`float-right redbtn ${
                data && !data.ButtonActive ? "btndisabled disabled" : ""
              }`}
              onClick={() => removeCardHandler()}
            >
              Remove
            </Button>
          ) : (
            <Button
              variant="primary"
              type="submit"
              className={`float-right redbtn ${
                data && !data.ButtonActive ? "btndisabled disabled" : ""
              }`}
              onClick={() => removeCardHandler()}
            >
              Remove
            </Button>
          )}
        </Row>
      </Modal.Footer>
    </Modal>
  );
};

export default RemovePaymentMethod;
