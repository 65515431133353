import React from "react";
import { Col, Dropdown, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { getAPIData } from "../../../Utils/commonApi";
import { useAuth } from "../../context/Auth";
import { CallToast } from "../../PriceCalculator/modals/CustomToast";
import SupplierDetailsPopover from "./SupplierDetailsPopover";
import "./supppliersAndComplianceProfiles.scss";

const SupplierTable = ({ data }) => {
  const history = useHistory();
  const { authTokens } = useAuth();
  async function updateSupplierStatus(supplierID, status) {
    await getAPIData("345", authTokens, {
      optionEnum1: supplierID,
      optionEnum2: status,
    }).then((response) => {
      if (response?.Success) {
        if (status === 3) {
          CallToast("Supplier removed Successfully", false, false, "v2style");
        }
        if (status === 4) {
          CallToast("Supplier suspended Successfully", false, false, "v2style");
        }
      }
    });
  }
  return (
    <div className="supplierAndComplianceProfile-wrapper pt-2">
      {data?.length ? (
        <div className="suppliers-data mx-5">
          <div className="supplier-list-header mb-2">
            <div className="ml-3">
              Supplier Name
              <img
                alt=""
                src="/images/actioncenter/arrowdown.svg"
                className="ml-2"
              />
            </div>
            <div>Status</div>
            <div>Supplier Risk</div>
            <div>CyberScore</div>
            <div>CyberThreat</div>
            <div>CyberCompliance</div>
            <div></div>
          </div>
          {/* table Data */}
          <div className="table-data-wrapper">
            {[
              ...new Map(
                data?.map((item) => [item["objId_Customer"], item])
              ).values(),
            ].map((supplier, supplierIndex) => {
              return (
                <div
                  className={`table-data-row   ${
                    supplierIndex === data?.length - 1 ? "" : "border-btm-grey"
                  } `}
                  onClick={() => {
                    history.push(`/supply-company/${supplier?.objId_Customer}`);
                  }}
                  style={{
                    pointerEvents: `${"all"}`,
                  }}
                >
                  <div className="d-flex align-items-center">
                    <div>
                      <div className="supplier-popover-parent">
                        <img
                          alt=""
                          width={32}
                          height={32}
                          src={supplier.SupplierLogo}
                          className="mr-2 rounded-full"
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null; // prevents looping
                            currentTarget.src = "/images/no-image.png";
                          }}
                        />
                        <SupplierDetailsPopover
                          Details={supplier?.Details}
                          ServicesProvided={supplier?.ServicesProvided}
                          SupplierProfileName={supplier?.SupplierName}
                          showResumeBtn={supplier?.Show_ResumeButton}
                          SharingSince={supplier?.SharingSince}
                          SupplierLogo={supplier?.SupplierLogo}
                          subtitle={supplier?.SupplierProfileName}
                          RequestStatusText={supplier?.RequestStatusText}
                          supplierId={supplier?.objId_Customer}
                        />
                      </div>
                    </div>
                    <div>
                      <p className="m-0 f-14 f-500">{supplier.SupplierName}</p>
                      <p className="m-0 f-12 f-grey">
                        {supplier.SupplierProfileName}
                      </p>
                    </div>
                  </div>
                  {supplier.IsActive ? (
                    <div className="nested-row ">
                      <div className="d-flex align-items-center">
                        {supplier?.IsChangeRequest ? (
                          <span className="f-darkgrey">
                            {supplier?.RequestStatusText}
                          </span>
                        ) : (
                          <img alt="" width={14} src={supplier.StatusIconUrl} />
                        )}
                      </div>
                      <div className="f-grey d-flex align-items-center">
                        <span className="f-black f-500">
                          {supplier.SupplierRisk}
                        </span>
                        /{supplier.SupplierRiskMax}
                      </div>
                      <div className="f-grey d-flex align-items-center">
                        <span className="f-black f-500">
                          {supplier.SupplierCyberScore}
                        </span>
                        /{supplier.SupplierCyberScoreMax}
                      </div>
                      <div className="f-grey d-flex align-items-center">
                        <span className="f-black f-500">
                          {supplier.SupplierCyberThreat}
                        </span>
                        /{supplier.SupplierCyberThreatMax}
                      </div>
                      <div className="f-grey d-flex align-items-center">
                        <span className="f-black f-500">
                          {supplier.SupplierCyberCompliance}
                        </span>
                        /{supplier.SupplierCyberComplianceMax}
                      </div>
                    </div>
                  ) : (
                    <div className="f-darkgrey d-flex align-items-center">
                      {supplier?.ContextMenu?.contextMenuLabel}
                    </div>
                  )}
                  <div className="d-flex align-items-center justify-content-end mr-4 supplier-list-dropdown">
                    <div
                      className={`menu-dropdown  ${
                        supplier?.ContextMenu?.supplierList_MenuVisible
                          ? ""
                          : "d-none"
                      }`}
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    >
                      <Dropdown className="device-dropdown">
                        <Dropdown.Toggle>
                          <img alt="" src="/images/horizontal-dots.svg" />
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <div>
                            <Row>
                              {supplier?.ContextMenu?.ContextMenuItems.map(
                                (menuItem, menuItemIndex) => {
                                  return (
                                    <Col xs={12} className="  pointer ">
                                      <div
                                        className="drop-item"
                                        onClick={(e) => {
                                          updateSupplierStatus(
                                            supplier?.objId_Customer,
                                            menuItem?.newRequestStatus_Id
                                          );
                                          e.target
                                            .closest(".device-dropdown")
                                            .click();
                                        }}
                                      >
                                        {menuItem?.menuActionLabel}
                                      </div>
                                    </Col>
                                  );
                                }
                              )}
                            </Row>
                          </div>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </div>
                </div>
              );
            })}
            {/* ============================================================================== */}
            {data &&
              false &&
              data.map((supplier, supplierIndex) => {
                return (
                  <div
                    className={`table-data-row   ${
                      supplierIndex === data.length - 1 ? "" : "border-btm-grey"
                    } `}
                    onClick={() => {
                      history.push(
                        `/supply-company/${supplier?.objId_Customer}`
                      );
                    }}
                    style={{
                      pointerEvents: `${supplier.IsActive ? "all" : "none"}`,
                    }}
                  >
                    <div className="d-flex align-items-center">
                      <div className="supplier-popover-parent">
                        <img
                          alt=""
                          width={32}
                          height={32}
                          src={supplier.SupplierLogo}
                          className="mr-2 rounded-full"
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null; // prevents looping
                            currentTarget.src = "/images/no-image.png";
                          }}
                        />

                        <SupplierDetailsPopover
                          Details={supplier?.Details}
                          ServicesProvided={supplier?.ServicesProvided}
                          SupplierProfileName={supplier?.SupplierName}
                          showResumeBtn={supplier?.Show_ResumeButton}
                          SharingSince={supplier?.SharingSince}
                          SupplierLogo={supplier?.SupplierLogo}
                          subtitle={supplier?.SupplierProfileName}
                          RequestStatusText={supplier?.RequestStatusText}
                          supplierId={supplier?.objId_Customer}
                        />
                      </div>
                      <div>
                        <p className="m-0 f-14 f-500">
                          {supplier.SupplierName}
                        </p>
                        <p className="m-0 f-12 f-grey">
                          {supplier.SupplierProfileName}
                        </p>
                      </div>
                    </div>
                    {supplier?.IsActive ? (
                      <div className="nested-row ">
                        <div className="d-flex align-items-center">
                          <img alt="" width={14} src={supplier.StatusIconUrl} />
                        </div>
                        <div className="f-grey d-flex align-items-center">
                          <span className="f-black f-500">
                            {supplier.SupplierRisk}
                          </span>
                          /{supplier.SupplierRiskMax}
                        </div>
                        <div className="f-grey d-flex align-items-center">
                          <span className="f-black f-500">
                            {supplier.SupplierCyberScore}
                          </span>
                          /{supplier.SupplierCyberScoreMax}
                        </div>
                        <div className="f-grey d-flex align-items-center">
                          <span className="f-black f-500">
                            {supplier.SupplierCyberThreat}
                          </span>
                          /{supplier.SupplierCyberThreatMax}
                        </div>
                        <div className="f-grey d-flex align-items-center">
                          <span className="f-black f-500">
                            {supplier.SupplierCyberCompliance}
                          </span>
                          /{supplier.SupplierCyberComplianceMax}
                        </div>
                        <div className="d-flex align-items-center justify-content-end mr-4 supplier-list-dropdown">
                          <div className="menu-dropdown">
                            <Dropdown
                              className="device-dropdown"
                              onClick={(e) => {
                                e.stopPropagation();
                              }}
                            >
                              <Dropdown.Toggle>
                                <img alt="" src="/images/horizontal-dots.svg" />
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                <div>
                                  <Row>
                                    <Col xs={12} className=" pointer ">
                                      <div
                                        className="drop-item"
                                        onClick={(e) => {
                                          e.target
                                            .closest(".device-dropdown")
                                            .click();
                                        }}
                                      >
                                        Print Supplier Report
                                      </div>
                                    </Col>
                                    <Col xs={12} className="  pointer ">
                                      <div
                                        className="drop-item"
                                        onClick={(e) => {
                                          updateSupplierStatus(
                                            supplier?.objId_Customer,
                                            4
                                          );
                                          e.target
                                            .closest(".device-dropdown")
                                            .click();
                                        }}
                                      >
                                        Suspend
                                      </div>
                                    </Col>

                                    <Col xs={12} className=" pointer ">
                                      <div
                                        className="drop-item"
                                        onClick={(e) => {
                                          updateSupplierStatus(
                                            supplier?.objId_Customer,
                                            3
                                          );
                                          e.target
                                            .closest(".device-dropdown")
                                            .click();
                                        }}
                                      >
                                        Remove
                                      </div>
                                    </Col>
                                  </Row>
                                </div>
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="f-grey d-flex align-items-center">
                        {supplier?.RequestStatusText}
                      </div>
                    )}
                  </div>
                );
              })}
          </div>
        </div>
      ) : (
        <div className="d-flex flex-column justify-content-center align-items-center w-50 mt-5 mx-auto">
          <img
            alt=""
            src="/images/supply-chain/no-supplier-icon.svg"
            className="no-supplier-icon mb-3"
          />
          <div className="banner-element-name mb-2">No suppliers</div>
          <p className="banner-subtitle text-center" onClick={() => {}}>
            You haven't added any suppliers yet. To add suppliers, send them a{" "}
            <br /> request to access their HighGround data
          </p>
        </div>
      )}
    </div>
  );
};

export default SupplierTable;
