import React, { useEffect, useState } from "react";
import { Button, Modal, Nav, Tab } from "react-bootstrap";
import "../../../Components/MSPv2/secOps/secOps.scss";
import Loader from "../../Common/loader";
import axios from "axios";
import { UploadFileCircularProgress } from "../../MSPv2/secOps/SecOps";
import { CallToast } from "../../PriceCalculator/modals/CustomToast";
import { useAuth } from "../../context/Auth";
import { getAPIData, postData } from "../../../Utils/commonApi";

const AddFileModal = ({
  show,
  hideModal,
  refreshData,
  selectedFile,
  questionId,
}) => {
  const uploadRef = React.useRef();
  const { authTokens } = useAuth();
  const [selectedTab, setSelectedTab] = useState(0);
  const [saving, setSaving] = useState(false);

  const [loading, setLoading] = useState(false);

  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploadingFileList, setUploadingFileList] = useState([]);

  const [uploadedFile, setUploadedFile] = useState(null);
  const [errorInput, setErrorInput] = useState(false);
  const [errorData, setErrorData] = useState({
    errMessage: "",
    errIcon: "",
  });

  const [fileData, setFileData] = useState({
    accessKey: JSON.parse(localStorage.getItem("user_MessageObj")),
    ObjectId: 0,
    FileLink: "",
    File: "",
    IsLink: "",
    Description: "",
  });

  async function getModalData() {
    await getAPIData(`${760}`, authTokens, {
      optionEnum1: 0,
    })
      .then((response) => {
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  useEffect(() => {
    if (show) {
      setLoading(true);
      getModalData();
      setSelectedTab(0);
      setFileData({
        ObjectId: selectedFile?.ObjectId ?? 0,
        FileLink: selectedFile?.FileURLOrLink ?? "",
        File: selectedFile?.File ?? "",
        FileName: selectedFile?.FileName ?? "",

        IsLink: selectedFile?.IsLink ?? "",
        Description: selectedFile?.Description ?? "",
      });
      if (
        selectedFile?.IsLink === false &&
        (selectedFile?.FileURLOrLink ||
          selectedFile?.ActualFileName ||
          selectedFile?.FileName)
      ) {
        setUploadedFile({
          FileURLOrLink: selectedFile?.FileURLOrLink,
          ActualFileName: selectedFile?.ActualFileName,
          FileSize: selectedFile?.FileSize,
        });
      } else {
        setUploadedFile(null);
      }

      if (selectedFile?.IsLink === true) {
        setSelectedTab(1);
      } else {
        setSelectedTab(0);
      }
    }
  }, [show]);

  const handleUpload = async (event) => {
    event.preventDefault();
    const file = event.target.files[0];
    setUploadingFileList(event.target.files);

    const allowedFileTypes = [
      "application/pdf",
      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      "text/plain",
      "application/csv",
      "application/vnd.ms-excel",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "image/jpeg",
      "image/bmp",
      "image/png",
    ];

    if (file && allowedFileTypes.includes(file.type)) {
      const reader = new FileReader();

      reader.onloadend = async () => {
        const base64Data = reader.result;
        const formData = new FormData();
        formData.append("UploadFile", file);
        formData.append("fileType", 0);
        formData.append(
          "accessKey",
          JSON.parse(localStorage.getItem("user_MessageObj"))
        );

        try {
          const response = await axios.post(
            `${process.env.REACT_APP_API_BASE_URL}/CC_API/UploadSupplierProfilingFile`,
            formData,
            {
              headers: {
                Authorization: `Bearer ${authTokens}`,
              },
              onUploadProgress: (progressEvent) => {
                const percentCompleted = Math.round(
                  (progressEvent.loaded * 100) / progressEvent.total
                );
                setUploadProgress(percentCompleted);
              },
            }
          );

          if (response) {
            CallToast("Uploaded successfully", false, false, "v2style");
            setUploadedFile(response?.data);
            setErrorData({ ...errorData, errMessage: "" });
          } else {
            console.error("Something went wrong");
          }

          setUploadProgress(0);
        } catch (error) {
          console.error("Upload failed:", error);
          if (error?.code === "ERR_NETWORK") {
            setErrorData({
              errMessage:
                "There was a connection error during uploading. Please try again.",
              errIcon: "/images/msp/wifi-error.svg",
            });
          }
        }
      };

      reader.readAsDataURL(file);
    } else {
      console.error(
        "Invalid file type. Please upload a PDF, DOC, DOX, or TXT file."
      );
    }
  };

  function downloadFile(url, filename) {
    const link = document.createElement("a");
    link.href = url;
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  async function handleSave() {
    if (fileData.FileName.trim() == "") {
      setErrorInput(true);
    } else {
      setSaving(true);
      setErrorInput(false);

      await postData(authTokens, "CreateSupplierProfilingFile", {
        accessKey: JSON.parse(localStorage.getItem("user_MessageObj")),
        ObjectId: selectedFile?.ObjectId ?? 0,
        FileLink: uploadedFile?.FileURLOrLink ?? fileData.FileLink,
        // File: fileData.File,
        IsLink: uploadedFile === null,
        optionEnum1: 12,
        Description: fileData.Description,
        FileName: fileData.FileName,
        ActualFileName: uploadedFile?.ActualFileName,
        objId_SendFormQuestionnaire: questionId,
      })
        .then((response) => {
          setSaving(false);
          hideModal();
          refreshData();
          CallToast("File saved successfully", false, false, "v2style");
        })
        .catch((err) => {
          setSaving(false);
          console.log(err);
        });
    }
  }

  return (
    <Modal
      animation={false}
      show={show}
      dialogClassName="secops-cert-modal"
      className="   "
      aria-labelledby=" "
      onHide={hideModal}
      centered
      scrollable
    >
      <Modal.Header className="text-center">
        <div
          className="w-100 mb-0 font-bold-14 title"
          style={{ fontWeight: "600" }}
        >
          {selectedFile?.ObjectId > 0 ? "Edit" : "Add"} file
        </div>

        <span
          className="cursor-pointer"
          onClick={() => {
            hideModal();
          }}
        >
          <img width={15} src="/images/attack-surface/cross-icon.svg" alt="" />
        </span>
      </Modal.Header>
      <Modal.Body className=" pt-3 pl-3 pr-2 transparent-scroller">
        {loading ? (
          <div className="d-flex align-items-center justify-content-center w-100 h-100">
            <Loader />
          </div>
        ) : (
          <>
            <div
              className={`d-flex align-items-center radius-4 grey-border p-2 flex-grow-1  highlight-input-with-grey-border ${
                errorInput ? "input-error" : ""
              }`}
            >
              <div className="f-darkgrey">Name:</div>

              <input
                type="text"
                className={`bg-transparent w-100 border-none pl-2 `}
                placeholder="File name"
                value={fileData.FileName}
                onChange={(e) => {
                  setFileData({ ...fileData, FileName: e.target.value });
                }}
              />
            </div>

            <textarea
              className={`d-flex align-items-center radius-4 grey-border p-2 w-100 mt-2 highlight-input-with-grey-border ${
                false ? "input-error" : ""
              }`}
              placeholder="Description"
              rows={6}
              value={fileData.Description}
              onChange={(e) => {
                setFileData({
                  ...fileData,
                  Description: e.target.value,
                });
              }}
            ></textarea>

            {true && (
              <>
                <Tab.Container
                  onSelect={(v) => {
                    setSelectedTab(Number(v));
                  }}
                  id="left-tabs-example"
                  defaultActiveKey={selectedTab}
                >
                  <div
                    className="scenarioName bg-white d-flex align-items-start    pt-2  "
                    style={{ top: "101px", zIndex: 2 }}
                    id="navHeader"
                  >
                    <Nav.Item key={0} className={"  mt-2"}>
                      <Nav.Link
                        eventKey={0}
                        className={`${selectedTab === 0 && "active"} mt-`}
                        onClick={() => {}}
                        disabled={fileData.IsLink === true}
                      >
                        Upload File
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item key={1} className={"  mt-2"}>
                      <Nav.Link
                        disabled={!(uploadedFile === null)}
                        eventKey={1}
                        className={`${selectedTab === 1 && "active"} mt-`}
                        onClick={() => {}}
                      >
                        Link
                      </Nav.Link>
                    </Nav.Item>
                  </div>

                  <Tab.Content className="mt-4">
                    <Tab.Pane eventKey={0}>
                      {errorData?.errMessage !== "" && (
                        <div
                          onClick={() => {}}
                          className="d-flex align-items-center justify-content-between bg-warning-light p-3 radius-8 mb-2 "
                        >
                          <div className="d-flex align-items-center f-black f-500">
                            <img
                              alt=""
                              src={errorData?.errIcon}
                              className="mr-2"
                            />

                            {errorData?.errMessage}
                          </div>
                          <div
                            className="d-flex align-items-center f-black f-500 pointer mr-2"
                            onClick={() => {
                              setErrorData({ ...errorData, errMessage: "" });
                            }}
                          >
                            Dismiss
                          </div>
                        </div>
                      )}

                      {uploadedFile ? (
                        <div className="radius-4 bg-grey p-3  d-flex align-items-center justify-content-between">
                          <div className="d-flex align-items-center">
                            <img
                              src="/images/msp/upload-icon-placeholder.svg"
                              alt=""
                              className="mr-2"
                            />
                            <div>
                              <div className="f-500">
                                {uploadedFile?.ActualFileName}{" "}
                              </div>
                              <div className="f-darkgrey">
                                {uploadedFile?.FileSize}
                              </div>
                            </div>
                          </div>
                          <div className="d-flex align-item-center">
                            <img
                              src="/images/msp/policy-download-icon.svg"
                              alt=""
                              className="mx-2 pointer"
                              onClick={() => {
                                downloadFile(
                                  uploadedFile?.FileURLOrLink,
                                  uploadedFile?.ActualFileName
                                );
                              }}
                            />
                            <img
                              src="/images/actioncenter/delete.svg"
                              alt=""
                              className="mx-2 pointer"
                              onClick={() => {
                                setUploadedFile(null);
                              }}
                            />
                          </div>
                        </div>
                      ) : (
                        <div className="radius-4 border-dashed d-flex flex-column align-items-center justify-content-center w-100 p-3 mb-3">
                          {uploadProgress > 0 && uploadProgress < 100 ? (
                            <>
                              <UploadFileCircularProgress
                                progress={100 - uploadProgress}
                              />

                              <div className="f-grey my-2 w-fit-content">
                                {uploadingFileList?.[0]?.name}{" "}
                              </div>

                              <div className="f-darkgrey my-2 w-fit-content">
                                Uploading... {uploadProgress}%
                              </div>

                              <Button
                                className="hg-cancel-btn"
                                onClick={() => {
                                  uploadRef.current.click();
                                }}
                              >
                                Cancel
                              </Button>
                            </>
                          ) : (
                            <>
                              <img
                                width={20}
                                src="/images/msp/framework-upload-icon.svg"
                                alt=""
                              />
                              <div className="f-grey mt-2 w-fit-content">
                                Upload file or browse file on your computer
                              </div>
                              <div className="f-darkgrey my-2 w-fit-content">
                                Format pdf, doc, dox, txt
                              </div>
                              <Button
                                className="hg-cancel-btn"
                                onClick={() => {
                                  uploadRef.current.click();
                                }}
                              >
                                Browse file
                              </Button>
                            </>
                          )}
                        </div>
                      )}
                    </Tab.Pane>
                    <Tab.Pane eventKey={1}>
                      <div
                        className={`d-flex radius-4 highlight-input-with-grey-border p-2 flex-grow-1 mr-1 hide-scroll ${true}`}
                        style={{ height: "auto" }}
                      >
                        <div className="f-darkgrey">Link:</div>

                        {/* <textarea
                          type="text"
                          className={`edit-task`}
                          rows={
                            fileData.FileLink
                              ? Math.ceil(fileData.FileLink.length / 60)
                              : 1
                          }
                          // style={{ width: "200px" }}
                          value={fileData.FileLink}
                          placeholder={"Question"}
                          onChange={(e) => {
                            setFileData({
                              ...fileData,
                              FileLink: e.target.value,
                              IsLink: !(e.target.value === ""),
                            });
                          }}
                        /> */}

                        <textarea
                          type="text"
                          rows={Math.ceil(fileData.FileLink.length / 55) ?? 0}
                          placeholder="Enter or paste link here"
                          className="pl-2 bg-transparent border-none flex-grow-1 expand-textarea-link hide-scroll"
                          value={fileData.FileLink}
                          onChange={(e) => {
                            setFileData({
                              ...fileData,
                              FileLink: e.target.value,
                              IsLink: !(e.target.value === ""),
                            });
                          }}
                        />
                      </div>
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </>
            )}
          </>
        )}
        <input
          type="file"
          accept=".pdf,.doc,.dox,.txt,.csv,.xls,.xlsx,.png,.jpeg"
          onChange={(e) => handleUpload(e)}
          className="d-none"
          ref={uploadRef}
          multiple
        />
      </Modal.Body>

      <Modal.Footer className="p-2 d-flex align-items-center justify-content-end">
        <Button className="hg-cancel-btn mr-2" onClick={hideModal}>
          Cancel
        </Button>
        <Button
          className="hg-blue-btn"
          disabled={
            saving ||
            (fileData.FileLink === "" && uploadedFile === null) ||
            fileData.FileName === "" ||
            fileData.Description === ""
          }
          onClick={handleSave}
        >
          {saving
            ? "Saving.."
            : `${selectedFile?.ObjectId > 0 ? "Edit" : "Add"} file`}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddFileModal;

export function downloadFile(url, filename) {
  const link = document.createElement("a");
  link.href = url;
  link.download = filename;
  link.target = "_blank";
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}
