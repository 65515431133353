import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { postData } from "../../../Utils/commonApi";
import { useAuth } from "../../context/Auth";
import { CallToast } from "../../PriceCalculator/modals/CustomToast";
import { WhiteLoader } from "../../Theme/APILoaders";
import "./supply-modals.scss";

const SuspendRemoveCompanyModal = ({
  show,
  hideModal,
  type,
  supplierName,
  supplierLogo,
  supplierId,
  supplierChainId,
  refreshData,
  IsUnmanagedSupplier,
}) => {
  const { authTokens } = useAuth();
  const [message, setMessage] = useState("");
  const [processing, setProcessing] = useState(false);

  useEffect(() => {
    setMessage("");
  }, [show]);
  // Not_Sent, //-------0
  // Sent,//-------1
  // Denied,//-------2
  // Removed_By_Client,//-------3
  // Removed_By_Supplier,//-------4
  // Suspended_By_Client,//-------5,
  // Suspended_By_Supplier,//----6
  // Approved_By_Supplier,//----7
  // Cancelled_By_Client,//----8
  async function changeStatus(status) {
    setProcessing(true);
    postData(authTokens, "SendResponseToSupplier", {
      RequestStatus: status,
      accessKey: JSON.parse(localStorage.getItem("user_MessageObj")),
      Response: message,
      ObjId_Supplier: supplierId,
      IsUnmanagedSupplier: IsUnmanagedSupplier,
    })
      .then((response) => {
        setProcessing(false);
        if (response?.Success) {
          CallToast("Updated successfully", false, false, "v2style");
          hideModal();
          if (refreshData) refreshData();
        }
      })
      .catch(() => {
        CallToast("Something went wrong", false, false, "v2style");
      });
  }

  return (
    <Modal
      animation={false}
      show={show}
      dialogClassName="suspend-remove-modal"
      className=" "
      aria-labelledby="suspend-remove-modal"
      centered
      scrollable
      onHide={hideModal}
    >
      <Modal.Header className="text-center">
        {/* {page !== "homepage" && (
          <img
            alt=""
            src="/images/supply-chain/chevron-left.svg"
            className="mt-2 pointer"
            onClick={() => {
              
            }}
          />
        )} */}
        <div className="w-100 mb-0 f-500 f-black">
          {type} {supplierName}
        </div>

        <span className="cursor-pointer" onClick={hideModal}>
          <img
            alt=""
            width={11}
            height={11}
            src={"/images/icons/close.svg"}
            className="close-icon"
          />
        </span>
      </Modal.Header>
      <Modal.Body className="hide-scrollbar">
        <div className="px-2 pt-3">
          {/* User Details */}
          <div className="d-flex align-items-center">
            <img
              src={supplierLogo}
              alt=""
              className="mr-2 rounded-full"
              width={48}
              height={48}
            />
            <div>
              <p className="f-16 f-600 m-0 mb-1">{supplierName}</p>
              <p className="f-14 f-500 f-darkgrey m-0">{supplierChainId}</p>
            </div>
          </div>
          <p className="f-black f-500 mt-3">
            You are about to {type?.toLowerCase()} the supplier
            <strong> {supplierName}</strong>. Please add a message for the
            supplier.
          </p>
          <div className="f-14 f-500 f-black mt-4">Response</div>
          <textarea
            rows={10}
            className="hg-border radius-4 w-100 mt-2 p-2"
            placeholder={`Why have you ${type?.toLowerCase()} this supplier?`}
            value={message}
            onChange={(e) => {
              setMessage(window.sanitizeHGtext(e.target.value));
            }}
          ></textarea>
        </div>
      </Modal.Body>
      <Modal.Footer className="p-2">
        <div className="d-flex align-items-center justify-content-end">
          <Button className="hg-cancel-btn mr-2" onClick={hideModal}>
            Cancel
          </Button>
          <Button
            className="hg-blue-btn"
            disabled={message === ""}
            onClick={() => {
              if (type === "Suspend") {
                changeStatus(5);
              }
              if (type === "Remove") {
                changeStatus(3);
              }
            }}
          >
            {processing && <WhiteLoader width={12} />} {type}
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default SuspendRemoveCompanyModal;
