import React, { useState, useEffect, Fragment } from "react";
import { Button, Form, Row, Col } from "react-bootstrap";
import { getAPIData, postRequest } from "../../../../Utils/commonApi";
import { useAuth } from "../../../context/Auth";
import Dropdown from "../../../Theme/dropdown2";
import Loader from "../../../Common/loader";
import "../actionCentreModals.scss";
import { CallToast } from "../../../PriceCalculator/modals/CustomToast";

const Notification = ({ closeModal }) => {
  const [loading, setLoading] = useState(false);
  const { authTokens } = useAuth();
  const [data, setData] = useState({});
  const [selectedOption, setSelectedOption] = useState([{}, {}, {}, {}]);
  const [IsVisible, setIsVisible] = useState(false);

  function LoadPageData() {
    getAPIData("241", authTokens).then((response) => {
      setData(response[0]);
      setIsVisible(response[0]?.SettingStatus);
      setLoading(false);
      if (response?.length && response[0]?.ActionCenterSubSettings.length) {
        // response[0]?.ActionCenterSubSettings[0].NotificationSettingsList;
        let updated_option = [...selectedOption];
        for (let i = 0; i < response[0]?.ActionCenterSubSettings.length; i++) {
          let selected_Option = response[0]?.ActionCenterSubSettings[
            i
          ].NotificationSettingsList.filter(
            (item) =>
              item.DDLText ==
              // .substring(0, 3).toLowerCase()

              response[0]?.ActionCenterSubSettings[i].SettingValue

            // .substring(
            //   0,
            //   3
            // ).toLowerCase()
          )[0];

          updated_option[i] = selected_Option;
        }
        setSelectedOption(updated_option);
      }
    });
  }

  useEffect(() => {
    setLoading(true);
    LoadPageData();
  }, []);

  const handleChange = (name, value) => {
    postRequest(authTokens, "SubmitNotificationSettings", {
      SettingOwner: 0,
      OwnerType: 1,
      NotificationName: `${name}`,
      NotificationValue: value,
      accessKey: JSON.parse(localStorage.getItem("user_MessageObj")),
    })
      .then((response) => {
        if (response.Success) {
          CallToast("Updated successfully", false, false, "v2style");
        } else {
          CallToast("Something went wrong", false, false, "v2style");
        }
      })
      .catch((err) => {
        console.log(err);
        CallToast("Something went wrong", false, false, "v2style");
      });
  };

  function ResetNotificationSettings() {
    getAPIData("242", authTokens).then((response) => {
      if (response.Success) {
        LoadPageData();
      } else {
        CallToast("Something went wrong", false, false, "v2style");
      }
    });
  }

  return (
    <Row className="m-0">
      {loading && (
        <Col md={12} className="loader-custom">
          <Loader />
        </Col>
      )}
      <Col md={12} className={`p-0 mt-1  ${loading ? "d-none" : ""}    `}>
        <div className=" mt-3">
          <span className="titletext"> {data?.SettingGroupTitle} </span>
          <p className="titletextgrey mb-4">{data?.SettingSubTitle}</p>

          <div className="d-flex align-items-center justify-content-between mb-3">
            <div className="">
              <span className="titletext ml-2">
                Allow notification from action centre
              </span>
            </div>

            <div className="d-flex align-items-center crsrpoint switchbig">
              <Form.Check
                type="switch"
                id="switchnitification"
                checked={IsVisible}
                className="m-0"
                onClick={() => {
                  setIsVisible(!IsVisible);
                  // setData({ ...data, Hidden: Number(!data?.Hidden) });
                  handleChange("NotificationActionCenter", Number(!IsVisible));
                }}
              />
            </div>
          </div>

          {IsVisible && (
            <div className="pl-3 mt-4 pt-3">
              {data?.ActionCenterSubSettings?.map((task, i) => (
                <Fragment key={i}>
                  <div className="d-flex align-items-center justify-content-between mb-3">
                    <div className="d-flex  w-50">
                      <div className="">
                        <span className="titletext ml-2">
                          {task.SettingName}
                        </span>
                      </div>
                    </div>

                    <div className="w-50 search_dropdown pl-4 text-right">
                      <div className="dropdownnotification float-right score-modal-dropdown">
                        <Dropdown
                          fieldId={"DDLId"}
                          fieldValue={"DDLText"}
                          selected={selectedOption[i]}
                          placeholder="App and Email"
                          //  showid={true}
                          onClick={(id, val) => {
                            let selected_option =
                              task?.NotificationSettingsList.filter(
                                (item) => item.DDLId == id
                              )[0];
                            let all_options = [...selectedOption];
                            all_options[i] = selected_option;
                            setSelectedOption(all_options);
                            // console.log(id, val);

                            handleChange(
                              task.SettingName.replaceAll(" ", ""),
                              val
                            );
                          }}
                          data={task?.NotificationSettingsList}
                        />
                      </div>
                    </div>
                  </div>
                  <hr className="m-0 mb-3" />
                </Fragment>
              ))}
            </div>
          )}
        </div>
      </Col>
      <div className="modal_custom_footer">
        <div className="crsrpoint mt-2" onClick={ResetNotificationSettings}>
          <img alt="" src="/images/actioncenter/reset.svg" height="15" />
          <span style={{ color: "#616778" }}>&nbsp; Reset</span>
        </div>
        <div>
          <Button
            variant="primary"
            type="submit"
            className="float-right securityBtnmodal d-none"
            onClick={closeModal}
          >
            Update
          </Button>
          <Button
            variant="primary"
            type="submit"
            className="float-right action_cancel_btn mr-2 d-none"
            onClick={closeModal}
          >
            Cancel
          </Button>
        </div>
      </div>
    </Row>
  );
};

export default Notification;
