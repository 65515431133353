import React from "react";
import "./preDataCarousel.scss";

const slideWidth = 10;

export const CustomCarousel = () => {
  const _items = [
    {
      player: {
        title: "Add questions",
        desc: "Flexibility in the questions and answer types",
        image: "/images/attack-surface/slide-2.webp",
      },
    },
    {
      player: {
        title: "Organize questions into sections",
        desc: "Create sections to logically group your questions",
        image: "/images/attack-surface/slide-3.webp",
      },
    },
    {
      player: {
        title: "Send them to your suppliers",
        desc: "Set your due dates and measure their progress",
        image: "/images/attack-surface/slide-4.webp",
      },
    },
    {
      player: {
        title: "Communicate with your suppliers",
        desc: "Goodbye messy email threads. Hello cleaner communication.",
        image: "/images/attack-surface/slide-1.webp",
      },
    },
  ];

  const length = _items.length;
  const keys = Array.from(Array(_items.length).keys());
  const [items, setItems] = React.useState(keys);
  const [isTicking, setIsTicking] = React.useState(false);
  const [activeIdx, setActiveIdx] = React.useState(0);
  const bigLength = items.length;
 

  const sleep = (ms = 0) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };

  const prevClick = (jump = 1) => {
    if (!isTicking) {
      setIsTicking(true);
      setItems((prev) => {
        return prev.map((_, i) => prev[(i + jump) % bigLength]);
      });
    }
  };

  const nextClick = (jump = 1) => {
    if (!isTicking) {
      setIsTicking(true);
      setItems((prev) => {
        return prev.map((_, i) => prev[(i - jump + bigLength) % bigLength]);
      });
    }
  };

  const handleDotClick = (idx) => {
    if (idx < activeIdx) prevClick(activeIdx - idx);
    if (idx > activeIdx) nextClick(idx - activeIdx);
  };

  React.useEffect(() => {
    if (isTicking) sleep(300).then(() => setIsTicking(false));
  }, [isTicking]);

  React.useEffect(() => {
    setActiveIdx((length - (items[0] % length)) % length) // prettier-ignore
  }, [items]);

  return (
    <div className="carousel__wrap">
      <div className="carousel__inner">
        <div
          className=" p-2 px-3 radius-4 bg-grey w-fit-content pointer"
          style={{ position: "absolute", top: "25rem", zIndex: 100 }}
          onClick={() => {
          

            prevClick();
          }}
        >
          <img src="/images/attack-surface/left-arrow.svg" alt="" />
        </div>
        <div className="carousel__container">
          <ul className="carousel__slide-list">
            {items.map((pos, i) => (
              <CarouselSlideItem
                key={i}
                idx={i}
                pos={pos}
                activeIdx={activeIdx}
              />
            ))}
          </ul>
        </div>
        <div
          className=" p-2 px-3 radius-4 bg-grey w-fit-content pointer"
          style={{
            position: "absolute",
            right: "1.5rem",
            top: "25rem",
            zIndex: 100,
          }}
          onClick={() => nextClick()}
        >
          <img src="/images/attack-surface/right-arrow.svg" alt="" />
        </div>

        <div className="carousel__dots">
          {items.slice(0, length).map((pos, i) => (
            <button
              key={i}
              onClick={() => handleDotClick(i)}
              className={i === activeIdx ? "dot active" : "dot"}
            />
          ))}
        </div>

 
        {
                <div className=" text-center">
                <div className="f-16 f-500 f-black">{_items[activeIdx].player.title}</div>
                <p className="mt-2 f-grey">{_items[activeIdx].player.desc}</p>
            </div> 
        }
      </div>
    </div>
  );
};

const _items = [
  {
    player: {
      title: "Add questions",
      desc: "Flexibility in the questions and answer types",
      image: "/images/attack-surface/slide-2.webp",
    },
  },
  {
    player: {
      title: "Organize questions into sections",
      desc: "Create sections to logically group your questions",
      image: "/images/attack-surface/slide-3.webp",
    },
  },
  {
    player: {
      title: "Send them to your suppliers",
      desc: "Set your due dates and measure their progress",
      image: "/images/attack-surface/slide-4.webp",
    },
  },
  {
    player: {
      title: "Communicate with your suppliers",
      desc: "Goodbye messy email threads. Hello cleaner communication.",
      image: "/images/attack-surface/slide-1.webp",
    },
  },
];

const length = _items.length;

const CarouselSlideItem = ({ pos, idx, activeIdx }) => {
  const createItem = (position, idx) => {
    const item = {
      styles: {
        transform: `translateX(${position * slideWidth}rem)`,
      },
      player: _items[idx].player,
    };

    switch (position) {
      case length - 1:
      case length + 1:
        item.styles = { ...item.styles };
        break;
      case length:
        break;
      default:
        item.styles = { ...item.styles, opacity: 0 };
        break;
    }

    return item;
  };
  const item = createItem(pos, idx, activeIdx);

  return (
    <li className="carousel__slide-item  " style={item.styles}>
      <div className="carousel__slide-item-img-lin k">
        <img
        width={400}
        height={320}
        className="radius-8 border-1"
          src={item.player.image}
          alt={item.player.title}
          style={{ marginLeft: "9rem" ,    border: '1px solid #ebecf180'}}
        />
      </div>
      {/* <div className="carousel-slide-item__body position-absolute">
                <div className="f-16 f-500 f-black">{item.player.title}</div>
                <p className="mt-2 f-grey">{item.player.desc}</p>
            </div> */}
    </li>
  );
};
