import React from "react";
import ContentLoader from "react-content-loader";

const ActiveSessionsLoader = () => {
  return (
    <ContentLoader
      speed={2}
      width={"100%"}
      height={"100%"}
      viewBox="0 160 1500 900"
      backgroundColor="#F6F8FB"
      foregroundColor="#ecebeb"
    >
      <rect x="17%" y="210" rx="3" ry="3" width="15%" height="26" />
      <rect x="17%" y="260" rx="3" ry="3" width="7%" height="16" />

      <rect x="17%" y="300" rx="4" ry="4" width="67%" height="60" />
      <rect x="17%" y="363" rx="4" ry="4" width="67%" height="60" />
      <rect x="17%" y="426" rx="4" ry="4" width="67%" height="60" />

      <rect x="17%" y="489" rx="4" ry="4" width="67%" height="60" />
      <rect x="17%" y="552" rx="4" ry="4" width="67%" height="60" />
      <rect x="17%" y="615" rx="4" ry="4" width="67%" height="60" />
      <rect x="17%" y="678" rx="4" ry="4" width="67%" height="60" />
      <rect x="17%" y="741" rx="4" ry="4" width="67%" height="60" />
      <rect x="17%" y="804" rx="4" ry="4" width="67%" height="60" />
    </ContentLoader>
  );
};

export default ActiveSessionsLoader;
