import React from "react";
import "./mspCalculatorDropdownAndModals.scss";

const MspFeatures = ({
  identify,
  protect,
  detect,
  respond,
  recover,
}) => {

  return (
    <div className="hide-scrollbar py-0">

      <div className="d-flex flex-column w-100">
        <div className="border-bottom p-4">
          <div className="f-darkgrey f-12 f-500 mb-2">Identify</div>

          <div>
            {identify?.map((iden) => {
              return (
                <>
                  <div className="">{iden?.Headline}</div>
                  {iden?.SecurityPack_Bullet_List?.length > 0 ? (
                    <>
                      {iden?.SecurityPack_Bullet_List?.map((item) => {
                        return (
                          <div className="d-flex align-items-center my-2 f-12">
                            <img
                              alt=""
                              className="ml-1 mr-2"
                              src="/images/msp/grey-circular-tick.svg"
                            />
                            {item?.Text}
                          </div>
                        );
                      })}
                    </>
                  ) : (
                    <div className="d-flex align-items-center my-2 f-grey f-12">
                      <img
                        alt=""
                        className="ml-1 mr-2"
                        src="/images/msp/cross-circular-tick.svg"
                      />{" "}
                      This service has no features
                    </div>
                  )}
                </>
              );
            })}
            {!identify ||
              (identify?.length == 0 && (
                <div className="f-darkgrey ">No services</div>
              ))}
          </div>
        </div>
        <div className="border-bottom p-4">
          <div className="f-darkgrey f-12 f-500 mb-2">Protect</div>

          <div>
            {protect?.map((iden) => {
              return (
                <>
                  <div className="">{iden?.Headline}</div>
                  {iden?.SecurityPack_Bullet_List?.length > 0 ? (
                    <>
                      {iden?.SecurityPack_Bullet_List?.map((item) => {
                        return (
                          <div className="d-flex align-items-center my-2 f-grey f-12">
                            <img
                              alt=""
                              className="ml-1 mr-2"
                              src="/images/msp/grey-circular-tick.svg"
                            />
                            {item?.Text}
                          </div>
                        );
                      })}
                    </>
                  ) : (
                    <div className="d-flex align-items-center my-2 f-grey f-12">
                      <img
                        alt=""
                        className="ml-1 mr-2"
                        src="/images/msp/cross-circular-tick.svg"
                      />{" "}
                      This service has no features
                    </div>
                  )}
                </>
              );
            })}
            {!protect ||
              (protect?.length == 0 && (
                <div className="f-darkgrey ">No services</div>
              ))}
          </div>
        </div>
        <div className="border-bottom p-4">
          <div className="f-darkgrey f-12 f-500 mb-2">Detect</div>

          <div>
            {detect?.map((iden) => {
              return (
                <>
                  <div className="">{iden?.Headline}</div>
                  {iden?.SecurityPack_Bullet_List?.length > 0 ? (
                    <>
                      {iden?.SecurityPack_Bullet_List?.map((item) => {
                        return (
                          <div className="d-flex align-items-center my-2 f-grey f-12">
                            <img
                              alt=""
                              className="ml-1 mr-2"
                              src="/images/msp/grey-circular-tick.svg"
                            />
                            {item?.Text}
                          </div>
                        );
                      })}
                    </>
                  ) : (
                    <div className="d-flex align-items-center my-2 f-grey f-12">
                      <img
                        alt=""
                        className="ml-1 mr-2"
                        src="/images/msp/cross-circular-tick.svg"
                      />{" "}
                      This service has no features
                    </div>
                  )}
                </>
              );
            })}
            {!detect ||
              (detect?.length == 0 && (
                <div className="f-darkgrey ">No services</div>
              ))}
          </div>
        </div>
        <div className="border-bottom p-4">
          <div className="f-darkgrey f-12 f-500 mb-2">Respond</div>

          <div>
            {respond?.map((iden) => {
              return (
                <>
                  <div className="">{iden?.Headline}</div>
                  {iden?.SecurityPack_Bullet_List?.length > 0 ? (
                    <>
                      {iden?.SecurityPack_Bullet_List?.map((item) => {
                        return (
                          <div className="d-flex align-items-center my-2 f-grey f-12">
                            <img
                              alt=""
                              className="ml-1 mr-2"
                              src="/images/msp/grey-circular-tick.svg"
                            />
                            {item?.Text}
                          </div>
                        );
                      })}
                    </>
                  ) : (
                    <div className="d-flex align-items-center my-2 f-grey f-12">
                      <img
                        alt=""
                        className="ml-1 mr-2"
                        src="/images/msp/cross-circular-tick.svg"
                      />{" "}
                      This service has no features
                    </div>
                  )}
                </>
              );
            })}
            {!respond ||
              (respond?.length == 0 && (
                <div className="f-darkgrey ">No services</div>
              ))}
          </div>
        </div>
        <div className="border-bottom p-4">
          <div className="f-darkgrey f-12 f-500 mb-2">Recover</div>

          <div>
            {recover?.map((iden) => {
              return (
                <>
                  <div className="">{iden?.Headline}</div>
                  {iden?.SecurityPack_Bullet_List?.length > 0 ? (
                    <>
                      {iden?.SecurityPack_Bullet_List?.map((item) => {
                        return (
                          <div className="d-flex align-items-center my-2 f-grey f-12">
                            <img
                              alt=""
                              className="ml-1 mr-2"
                              src="/images/msp/grey-circular-tick.svg"
                            />
                            {item?.Text}
                          </div>
                        );
                      })}
                    </>
                  ) : (
                    <div className="d-flex align-items-center my-2 f-grey f-12">
                      <img
                        alt=""
                        className="ml-1 mr-2"
                        src="/images/msp/cross-circular-tick.svg"
                      />{" "}
                      This service has no features
                    </div>
                  )}
                </>
              );
            })}
            {!recover ||
              (recover?.length == 0 && (
                <div className="f-darkgrey ">No services</div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MspFeatures;
