import React, { useEffect, useState } from "react";
import { Button, Dropdown, Modal, } from "react-bootstrap";
import "../secOps.scss";
import CompanySettingsDropdown from "../../../settingsV2/companySettings/companysettingsdrodown/CompanySettingsDropdown";
import { CallToast } from "../../../PriceCalculator/modals/CustomToast";
import { getAPIData, postData, } from "../../../../Utils/commonApi";
import { useAuth } from "../../../context/Auth";
import ImageWithInitials from "../../../../Utils/ImageWithInitials";
import MultiSelectDropdown from "./MultiSelectDropdown";
import MspMemberAccessRoleModal from "../../../MSPSettingsv2/mspMembersAccess/MspMemberAccessRoleModal";
import { TrimString } from "../../../../Utils/StringTrimmer";

const AddMemberModalNew = ({ show, hideModal, showMemberAccessModal, hideCreateOption, refreshData = () => { } }) => {
  const { authTokens } = useAuth();
  const [members, setMembers] = useState([]);
  const [roles, setRoles] = useState([]);
  const [selectedRole, setSelectedRole] = useState();
  const [selectedMembersList, setSelectedMembersList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showAccessLevelModal, setShowAccessLevelModal] = useState(false);
  const [IsDropdownOpen, setIsDropdownOpen] = useState(false)

  useEffect(() => {
    if (show) {
      getMembersDetails();
      // getAccessRolesData();
    }
  }, [show]);
  const [memberLoader, setMemberLoader] = useState(true);

  async function getMembersDetails() {
    await getAPIData("585", authTokens, {
    }).then((response) => {
      if (response.mr.Success) {
        setMembers(response?.users);
        setMemberLoader(false);
        console.log(response.users);
      } else {
        CallToast("Something went wrong", false, false, "v2style");
      }
    });
  }

  async function getAccessRolesData() {
    await getAPIData("564", authTokens, {
      optionEnum1: "1",
    }).then((response) => {
      if (response.mr.Success) {
        setRoles(response.roles);
      }
    });
  }


  async function inviteMember(user, isFromBox) {
    await getAPIData("586", authTokens, isFromBox ? {
      optionStrEnum2: user
    } : (user?.objId_PSAMember > 0 && (user?.userId === '' || !user?.userId)) ? {
      optionEnum1: user?.objId_PSAMember,
    } : (user?.objId_PSAMember === 0 && user?.userId) && {
      optionStrEnum1: user?.userId
    }).then((response) => {
      if (response.Success) {
        console.log(response);
        CallToast("Member invited successfully", false, false, "v2style");
        hideModal();
        getMembersDetails();
        refreshData();
        setEmailErrors({
          emptyEmail: false,
          formatEmail: false
        });
      } else {
        CallToast("Something went wrong", false, false, "v2style");
      }
      setInputValue('')
    });
  }

  const [inputValue, setInputValue] = useState('')
  const [emailErrors, setEmailErrors] = useState({
    emptyEmail: false,
    formatEmail: false
  })
  const [isValidationChecked, setIsvalidationChecked] = useState(false)
  const handleEmailValidations = () => {
    const re =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

    if (inputValue == "") {
      setEmailErrors((prev) => ({
        ...prev,
        emptyEmail: true
      }));
    } else if (inputValue && !re.test(inputValue)) {
      setEmailErrors((prev) => ({
        ...prev,
        formatEmail: true
      }));
    }
    setIsvalidationChecked(true);
  }

  const [isFirstOpenBlocks, setIsFirstOpenBlocks] = useState([true, true])
  function ToggleAccordionBlocks(e, blockId, work = true) {
    if (!work) return;
    var parent = e.target.closest(`.point-row-${blockId}`);
    console.log('e.targeta', e.targeta)
    var panel = parent.querySelector(`.panel-opened`);
    var chevron = parent.querySelector("#chevron");
    if (panel?.style?.maxHeight) {
      chevron.classList.toggle("rotate180");
      panel.style.maxHeight = null;
    } else {
      chevron.classList.toggle("rotate180");
      panel.style.maxHeight = panel.scrollHeight + "px";
    }
    let updData = isFirstOpenBlocks;
    updData[blockId] = !updData[blockId]
    setIsFirstOpenBlocks(updData);
  }

  return (
    <Modal
      animation={false}
      show={show}
      dialogClassName="msp-add-member-modal "
      aria-labelledby=" "
      onHide={hideModal}
      centered
      scrollable
    >
      <Modal.Header className="text-center">
        <div
          className="w-100 mb-0 font-bold-14 title"
          style={{ fontWeight: "600" }}
        >
          Add Member
        </div>

        <span
          className="cursor-pointer"
          onClick={() => {
            setSelectedMembersList([]);
            hideModal();
            setInputValue('')
          }}
        >
          <img width={15} src="/images/attack-surface/cross-icon.svg" alt="" />
        </span>
      </Modal.Header>
      <Modal.Body className="p-2 hide-scrollbar">
        <div className="d-flex ">
          <div
            className={` d-flex align-items-center radius-4 grey-border   flex-grow-1 mr-1 ${"highlight-input-with-grey-border"}`}
          >
            <div
              className="bg-transparent w-100 border-none pl-2"
              tabIndex={-1}
            >
              <input
                placeholder="Enter name or email"
                type="text"
                className={`border-none w-100 h-100 `}
                value={inputValue}
                onChange={(e) => {
                  setEmailErrors({
                    emptyEmail: false,
                    formatEmail: false
                  })
                  setIsvalidationChecked(false)
                  setInputValue(e.target.value);
                }}
                onBlur={(e) => {
                  handleEmailValidations(e.target.value)
                }}
              />
            </div>
          </div>
          <Button
            className={!isValidationChecked && "hg-blue-btn"}
            disabled={loading ? true : !isValidationChecked ? true : (!emailErrors.emptyEmail && !emailErrors.formatEmail) ? false : true}
            style={{ height: "fit-content", alignSelf: "end" }}
            onClick={() => {
              inviteMember(inputValue, true);
            }}
          >
            {loading ? "Adding..." : "Add"}{" "}
          </Button>
        </div>
        {emailErrors?.emptyEmail && (
          <div className="f-danger f-12 p-1">
            Email cannot be empty
          </div>
        )}
        {!emailErrors?.emptyEmail && emailErrors?.formatEmail && (
          <div className="f-danger f-12 p-1">
            Invalid email address format
          </div>
        )}
        <div>
          <div className="point-row-0">
            <div
              className="d-flex align-items-center justify-content-between my-2 mx-1 pointer"
              onClick={(e) => {
                ToggleAccordionBlocks(e, 0);
              }}>

              <div className="f-grey">Existing users</div>
              <img
                alt=""
                id="chevron"
                className="chevron"
                src="/images/attack-surface/chevron-up.svg"
              />
              {/* <div className="w-fit-content dropdown-grey-theme score-question-dropdown xx ml-3 w-70">
                <CompanySettingsDropdown
                  fieldId={"id"}
                  fieldValue={"cat"}
                  selected={{ id: 1, cat: "All" }}
                  placeholder="Select"
                  //  showid={true}
                  onClick={(id, val) => { }}
                  data={[{ id: 1, cat: "All" }]}
                />
              </div> */}
            </div>
            <div
              className="panel-opened"
              style={{
                maxHeight: isFirstOpenBlocks[0] === true && '5000px'
              }}
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              {
                memberLoader ?
                  <div className="d-flex align-items-center justify-content-center my-2 w-100">
                    <div className="member-loader ml-2 mr-1"></div>
                    {/* <div>Please wait...</div> */}
                  </div>
                  :
                  <>
                    {members?.filter(obj => obj.objId_PSAMember === 0 && obj.userId)?.map((item, index) => {
                      // console.log(item);
                      return (
                        <div className="d-flex align-items-center justify-content-between my-1 pointer radius-4 bg-hover-grey hover-on-show-parent p-2 px-3">
                          <div className="d-flex align-items-center  ">
                            {item.showInitials ? (
                              <ImageWithInitials
                                initials={item.initials}
                                width={30}
                                background={"purple"}
                              />
                            ) : (
                              <img
                                alt=""
                                src={`${item.avatar}`}
                                className=""
                                style={{
                                  width: "30px",
                                  borderRadius: "50%",
                                  objectFit: "contain",
                                }}
                              />
                            )}
                            <div className="ml-2">
                              <div className="f-500 f-grey">{item.fullName}</div>
                              <div className="f-12 f-darkgrey">{item.userName}</div>
                            </div>
                          </div>
                          <div
                            className="hover-on-show-child f-grey"
                            onClick={() => {
                              inviteMember(item, false)
                              // let newList = [...selectedMembersList];
                              // const existingObj = newList.find(
                              //   (it) => it.objId_PSAMember === item.objId_PSAMember
                              // );

                              // if (!existingObj) {
                              //   newList.push(item);
                              // }
                              // setSelectedMembersList(newList);
                            }}
                          >
                            <img
                              src="/images/msp/grey-plus-icon.svg"
                              alt=""
                              width={10}
                              className="mr-2"
                            />
                            Add
                          </div>
                        </div>
                      );
                    })}
                    {members?.filter(obj => obj.objId_PSAMember === 0 && obj.userId)?.length == 0 && (
                      <div className="f-grey d-flex justify-content-center align-items-center">
                        No members exist
                      </div>
                    )}
                  </>
              }
            </div>
          </div>

          <div className="point-row-1">
            <div
              className="d-flex align-items-center justify-content-between my-2 mx-1 pointer"
              onClick={(e) => {
                ToggleAccordionBlocks(e, 1);
              }}>
              <div className="f-grey">Imported members</div>
              <img
                alt=""
                id="chevron"
                className="chevron"
                src="/images/attack-surface/chevron-up.svg"
              />
              {/* <div className="w-fit-content dropdown-grey-theme score-question-dropdown xx ml-3 w-70">
                <CompanySettingsDropdown
                  fieldId={"id"}
                  fieldValue={"cat"}
                  selected={{ id: 1, cat: "All" }}
                  placeholder="Select"
                  //  showid={true}
                  onClick={(id, val) => { }}
                  data={[{ id: 1, cat: "All" }]}
                />
              </div> */}
            </div>
            <div
              className="panel-opened"
              style={{
                maxHeight: isFirstOpenBlocks[1] === true && '5000px'
              }}
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              {
                memberLoader ?
                  <div className="d-flex align-items-center justify-content-center my-2 w-100">
                    <div className="member-loader ml-2 mr-1"></div>
                    {/* <div>Please wait...</div> */}
                  </div>
                  :
                  <>
                    {members?.filter(obj => obj.objId_PSAMember > 0 && (obj.userId === '' || !obj.userId))?.map((item, index) => {
                      console.log(item);
                      return (
                        <div className="d-flex align-items-center justify-content-between my-1 pointer radius-4 bg-hover-grey hover-on-show-parent p-2 px-3">
                          <div className="d-flex align-items-center  ">
                            {item.showInitials ? (
                              <ImageWithInitials
                                initials={item.initials}
                                width={30}
                                background={"purple"}
                              />
                            ) : (
                              <img
                                alt=""
                                src={`${item.avatar}`}
                                className=""
                                style={{
                                  width: "30px",
                                  borderRadius: "50%",
                                  objectFit: "contain",
                                }}
                              />
                            )}
                            <div className="ml-2">
                              <div className="f-500 f-grey">{item.fullName}</div>
                              <div className="f-12 f-darkgrey">{item.userName}</div>
                            </div>
                          </div>
                          <div
                            className="hover-on-show-child f-grey"
                            onClick={() => {
                              inviteMember(item, false)
                              // let newList = [...selectedMembersList];
                              // const existingObj = newList.find(
                              //   (it) => it.objId_PSAMember === item.objId_PSAMember
                              // );

                              // if (!existingObj) {
                              //   newList.push(item);
                              // }
                              // setSelectedMembersList(newList);
                            }}
                          >
                            <img
                              src="/images/msp/grey-plus-icon.svg"
                              alt=""
                              width={10}
                              className="mr-2"
                            />
                            Add
                          </div>
                        </div>
                      );
                    })}
                    {members?.filter(obj => obj.objId_PSAMember > 0 && (obj.userId === '' || !obj.userId))?.length == 0 && (
                      <div className="f-grey d-flex justify-content-center align-items-center">
                        No members exist
                      </div>
                    )}
                  </>
              }
            </div>
          </div>
        </div>

        <div className="p-3" />
        {showAccessLevelModal && (
          <MspMemberAccessRoleModal
            show={showAccessLevelModal}
            hideModal={() => {
              setShowAccessLevelModal(false);
            }}
            parentID="msp-main-screen-for-integration-modal"
            refreshData={() => {
              getAccessRolesData();
            }}
          />
        )}
      </Modal.Body>
    </Modal>
  );
};

export default AddMemberModalNew;
