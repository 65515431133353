import moment from 'moment';

const getDuration = (startDate, endDate, format = "DD/MM/YYYY", unit = 'days') => {
  if (typeof startDate === 'string') {
    startDate = moment(startDate, format);
  }
  if (typeof endDate === 'string') {
    endDate = moment(endDate, format);
  }
  return endDate.diff(startDate, unit);
}

const convertDateFormat = (date, to = "DD/MM/YYYY") => {
  return moment(date).format(to);
}

const isDateFormatCorrect = (date, separator) => {
  const regex = new RegExp(`^\\d{2}\\${separator}\\d{2}\\${separator}\\d{4}`);
  return regex.test(date);
}

const dateUtils = {
  getDuration,
  convertDateFormat,
  isDateFormatCorrect
};

export default dateUtils;