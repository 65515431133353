import React, { useState, useRef, useEffect } from "react";
import "./Tooltip.scss";
const TooltipWrapper = ({ children, content, delay = 200, maxWidth ,disabled,textAlign}) => {
  const [visible, setVisible] = useState(false);
  const [position, setPosition] = useState("top");
  const tooltipRef = useRef(null);
  const containerRef = useRef(null);
  const delayTimeoutRef = useRef(null);
  const handlePosition = () => {
    const tooltip = tooltipRef.current;
    const container = containerRef.current;
    if (tooltip && container) {
      const tooltipRect = tooltip.getBoundingClientRect();
      const containerRect = container.getBoundingClientRect(); 
      const spaceTop = containerRect.top;
      const spaceBottom = window.innerHeight - containerRect.bottom;
      const spaceLeft = containerRect.left;
      const spaceRight = window.innerWidth - containerRect.right; 
      let newPosition = "top";
      if (spaceTop >= tooltipRect.height + 10) {
        newPosition = "top";
      } else if (
        spaceBottom >= tooltipRect.height + 10 &&
        spaceBottom > spaceTop
      ) {
        newPosition = "bottom";
      } else if (spaceLeft >= tooltipRect.width + 10) {
        newPosition = "left";
      } else if (spaceRight >= tooltipRect.width + 10) {
        newPosition = "right";
      }
      setPosition(newPosition);
    }
  };
  useEffect(() => {
    if (visible) {
      handlePosition();
    }
    window.addEventListener("resize", handlePosition);
    return () => window.removeEventListener("resize", handlePosition);
  }, [visible]);
  const handleMouseEnter = () => {
    delayTimeoutRef.current = setTimeout(() => {
      setVisible(true);
    }, delay);
  };
  const handleMouseLeave = () => {
    clearTimeout(delayTimeoutRef.current);
    setVisible(false);
  };
  return (
    <div
      className="dynamic-tooltip-container"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      ref={containerRef}
    >
      {children}
      {visible && !disabled && (
        <div
          className={`dynamic-tooltip ${position} w-max-content`}
          ref={tooltipRef}
          style={{
            maxWidth: maxWidth ?? "220px",
            textAlign: textAlign ?? "center",
          }}
        >
          <span
            dangerouslySetInnerHTML={{
              __html: content,
            }}
          />
        </div>
      )}
    </div>
  );
};
export default TooltipWrapper;
