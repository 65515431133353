import React, { useState, useEffect } from "react";
import { Button, Col, Dropdown, Form, Modal, Row } from "react-bootstrap";
import { NormalDropdownForQuestionnaireModal } from "../../settingsV2/companySettings/companysettingsdrodown/NormalDropdown";
import "./questionnairetab.scss";
import { useAuth } from "../../../Components/context/Auth";
import { getAPIData, postData } from "../../../Utils/commonApi";
import { CallToast } from "../../PriceCalculator/modals/CustomToast";
import { TrimString } from "../../../Utils/StringTrimmer";
import Loader from "../../Common/loader";
import { WhiteLoader } from "../../Theme/APILoaders";
import SendQuestionnaireModal from "./SendQuestionnaireModal";
import { CustomCarousel } from "./questionnairePreDataCarousel/questionnairePreDataCarousel";
import { DeleteModal } from "../../DeleteModal/DeleteModal";

const QuestionnaireBigModal = ({
  show,
  hideModal,
  editTemplateId,
  refreshData,
}) => {
  const { authTokens } = useAuth();
  const [showPromptModal, setShowPromptModal] = useState(false);
  const [savingTemplatefromBigModal, setsavingTemplatefromBigModal] =
    useState(false);
  const [savingTemplateFromPromptModal, setSavingTemplateFromPromptModal] =
    useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingTemplateList, setLoadingTemplateList] = useState(false);
  const [templateList, setTemplateList] = useState([]);

  const [preData, setPreData] = useState(true);

  const [renameTemplate, setRenameTemplate] = useState(false);
  const [isEditSectionName, setIsEditSectionName] = useState(false);
  // Rendering Template working
  const [template] = useState({ ..._template });
  const [templateData, setTemplateData] = useState(template);
  const [selectedSectionQuestionList, setSelectedSectionQuestionList] =
    useState(0);

  const [isEditTemplate, setIsEditTemplate] = useState(false);
  const [isEditTemplateName, setIsEditTemplateName] = useState(false);
  const [questionEdit, setQuestionEdit] = useState();
  const [editquestionOption, setEditquestionOption] = useState("");

  // temporary states
  const [tempName, setTempName] = useState("");

  // Send questionnaire modal
  const [showSendQuestionnaireModal, setShowSendQuestionnaireModal] =
    useState(false);

  // Delete Confirmation modal
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteMessage, setDeleteMessage] = useState("");
  const [deleteObjID, setDeleteObjID] = useState("");
  useEffect(() => {
    if (show) {
      setLoadingTemplateList(true);
      getTemplateList();
      if (editTemplateId) {
        setSelectedSectionQuestionList(0);
        getTemplate(editTemplateId, true);
      } else {
        setIsEditTemplate(false);
        setSelectedSectionQuestionList(0);
        // setTemplateData({});
        setTemplateData(template);
      }
    }
  }, [show, editTemplateId]);
  async function getTemplateList() {
    await getAPIData(304, authTokens)
      .then((response) => {
        setLoadingTemplateList(false);
        if (response.mr.Success) {
          setTemplateList(response?.SCMAllFormTemplate_List);
          if (response?.SCMAllFormTemplate_List?.length > 0) {
            setPreData(false);
          } else {
            setPreData(true);
          }
        }
      })
      .catch((err) => {
        setLoadingTemplateList(false);
        console.log(err);
      });
  }
  // Function to get single template Data
  async function getTemplate(ObjectId, isEdit = false) {
    if (ObjectId === 0) {
      setIsEditTemplate(true);
      return;
    }
    setLoading(true);
    await getAPIData(302, authTokens, {
      optionEnum1: ObjectId,
    })
      .then((response) => {
        setLoading(false);
        if (response?.mr?.Success) {
          setTemplateData(response);
          if (isEdit) {
            setIsEditTemplate(true);
          }
        } else {
          CallToast("Something went wrong", false, false, "v2style");
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        CallToast("Something went wrong", false, false, "v2style");
      });
  }

  // Function to Save template

  async function saveTemplate(data) {
    setSavingTemplateFromPromptModal(true);

    await postData(authTokens, "CreateSCMTemplate", {
      SCMFormTemplateVM: JSON.stringify(data),
    })
      .then((response) => {
        setSavingTemplateFromPromptModal(false);
        setsavingTemplatefromBigModal(false);
        if (response?.Success) {
          CallToast("Updated successfully", false, false, "v2style");
          getTemplateList();
          setIsEditTemplate(false);
          if (showPromptModal) {
            setShowPromptModal(false);
            hideModal();
          }
        } else {
          CallToast("Something went wrong", false, false, "v2style");
        }
      })
      .catch((err) => {
        setSavingTemplateFromPromptModal(false);
        setsavingTemplatefromBigModal(false);
        console.log(err);
        CallToast("Something went wrong", false, false, "v2style");
      });
  }

  // Funtion to rename the template
  async function renameTemplateFunc(ObjectId, Title) {
    if (ObjectId == 0) return;
    await postData(authTokens, "RenameSCMTemplateName", {
      ObjectId,
      Title,
    })
      .then((response) => {
        if (response?.Success) {
          CallToast("Updated successfully", false, false, "v2style");
          getTemplateList();
          if (ObjectId === templateData?.ObjectId) {
            getTemplate(ObjectId);
          }
        } else {
          CallToast("Something went wrong", false, false, "v2style");
        }
      })
      .catch((err) => {
        console.log(err);
        CallToast("Something went wrong", false, false, "v2style");
      });
  }

  // Function to duplicate the template
  async function createDuplicateTemplate(ObjectId) {
    await getAPIData(303, authTokens, {
      optionEnum1: ObjectId,
    })
      .then((response) => {
        if (response.Success) {
          CallToast("Created successfully", false, false, "v2style");
          getTemplateList();
        } else {
          CallToast("Something went wrong", false, false, "v2style");
        }
      })
      .catch((err) => {
        console.log(err);
        CallToast("Something went wrong", false, false, "v2style");
      });
  }

  // Function to Delete the template
  async function deleteTemplate(ObjectId) {
    await getAPIData(301, authTokens, {
      optionEnum1: ObjectId,
    })
      .then((response) => {
        if (response.Success) {
          CallToast("Deleted successfully", false, false, "v2style");
          getTemplateList();
        } else {
          CallToast("Something went wrong", false, false, "v2style");
        }
      })
      .catch((err) => {
        console.log(err);
        CallToast("Something went wrong", false, false, "v2style");
      });
  }

  // prompt Modal for unsaved changes

  return (
    <Modal
      animation={false}
      // show={true}
      show={show}
      dialogClassName="modal-90w"
      className="questionnaire-big-modal"
      aria-labelledby="questionnaire-big-modal"
      // backdrop={false}
      centered
      scrollable
      onHide={() => {
        if (isEditTemplate) {
          setShowPromptModal(true);
        } else {
          if (hideModal) {
            hideModal();
          }
        }
      }}
    >
      <Modal.Header className="billingTab py-3">
        <div className="d-flex align-items-center justify-content-between w-100">
          <div></div>
          <div className="f-500 f-16">Templates</div>

          <img
            alt=""
            width={12}
            height={12}
            className="pointer"
            src="/images/attack-surface/cross-icon.svg"
            onClick={() => {
              if (isEditTemplate) {
                setShowPromptModal(true);
              } else {
                if (hideModal) {
                  hideModal();
                }
              }
            }}
          />
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className="m-0 h-100 w-100 d-flex hide-scrollbar">
          {/* Left part */}
          {loadingTemplateList ? (
            <div
              className="loader d-flex align-items-center justify-content-center h-100 border-right"
              style={{
                width: "20%",
              }}
            >
              <Loader />
            </div>
          ) : (
            <div
              className="border-right p-3 hide-scrollbar"
              style={{
                width: "310px",
                // position: "fixed",
                overflowY: "scroll",
                // height: "85%",
                position: "relative",
              }}
            >
              <div className="f-16 f-600 mb-3">Templates</div>
              <div
                className="d-flex align-items-center f-blue ml-4 mt-3 pointer"
                onClick={() => {
                  if (isEditTemplate) {
                    setShowPromptModal(true);
                  } else {
                    setSelectedSectionQuestionList(0);
                    setTemplateData({ ...template });
                    setIsEditTemplate(true);
                    setIsEditTemplateName(true);
                  }
                }}
              >
                <img
                  alt=""
                  className="mr-2"
                  src="/images/actioncenter/plusgrey.svg"
                  style={{
                    filter:
                      "invert(43%) sepia(99%) saturate(2553%) hue-rotate(173deg) brightness(91%) contrast(101%)",
                  }}
                />
                Add template
              </div>
              {templateList?.length == 0 && (
                <div className="ml-4 mt-3 f-darkgrey">No templates yet</div>
              )}

              {templateList?.length > 0 &&
                templateList?.map((item, itemIndex) => {
                  return (
                    <div
                      className={`template-item bg-hover-grey pointer p-2 my-2 d-flex align-items-center justify-content-between radius-4 ${
                        item?.ObjectId === templateData?.ObjectId
                          ? "bg-grey"
                          : ""
                      }`}
                      onClick={() => {
                        if (isEditTemplate) {
                          setShowPromptModal(true);
                        } else {
                          setIsEditTemplate(false);
                          setSelectedSectionQuestionList(0);
                          getTemplate(item?.ObjectId);
                        }
                      }}
                    >
                      <div className="d-flex align-items-center title">
                        <img
                          alt=""
                          className="mr-2"
                          src="/images/supply-chain/template-icon.svg"
                        />
                        <div className="d-flex align-items-center">
                          {TrimString(item?.Title, 20)}
                        </div>
                      </div>
                      <div
                        className="template-item-dropdown"
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                      >
                        <Dropdown
                          onToggle={() => {
                            setRenameTemplate(false);
                            setTempName("");
                          }}
                          className={`  device-dropdown ${
                            renameTemplate ? "white-dropdown" : "black-dropdown"
                          }`}
                        >
                          <Dropdown.Toggle>
                            <img alt="" src="/images/horizontal-dots.svg" />
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <div>
                              {renameTemplate ? (
                                <div className="d-flex align-items-center hg-border mx-1 border-4 p-1">
                                  <input
                                    className=""
                                    value={tempName}
                                    onChange={(e) => {
                                      setTempName(e.target.value);
                                    }}
                                    autoFocus
                                  />
                                  <Button
                                    className="hg-blue-btn"
                                    onClick={(e) => {
                                      renameTemplateFunc(
                                        item?.ObjectId,
                                        tempName
                                      );
                                      e.target
                                        .closest(".device-dropdown")
                                        .click();
                                    }}
                                  >
                                    Save
                                  </Button>
                                </div>
                              ) : (
                                <Row>
                                  <Col xs={12} className=" pointer ">
                                    <div
                                      className="drop-item"
                                      onClick={(e) => {
                                        setSelectedSectionQuestionList(0);
                                        getTemplate(item?.ObjectId, true);
                                        e.target
                                          .closest(".device-dropdown")
                                          .click();
                                      }}
                                    >
                                      Edit
                                    </div>
                                  </Col>
                                  <Col xs={12} className="  pointer ">
                                    <div
                                      className="drop-item"
                                      onClick={(e) => {
                                        // e.target
                                        //   .closest(".device-dropdown")
                                        //   .click();
                                        setTempName(item?.Title);
                                        setRenameTemplate(true);
                                      }}
                                    >
                                      Rename
                                    </div>
                                  </Col>

                                  <Col xs={12} className=" pointer ">
                                    <div
                                      className="drop-item"
                                      onClick={(e) => {
                                        createDuplicateTemplate(item?.ObjectId);
                                        e.target
                                          .closest(".device-dropdown")
                                          .click();
                                      }}
                                    >
                                      Duplicate
                                    </div>
                                  </Col>
                                  <Col xs={12} className=" pointer ">
                                    <div
                                      className="drop-item"
                                      onClick={(e) => {
                                        setDeleteMessage(
                                          "You are about to delete this template."
                                        );
                                        setDeleteObjID(item?.ObjectId);
                                        setShowDeleteModal(true);
                                        e.target
                                          .closest(".device-dropdown")
                                          .click();
                                      }}
                                    >
                                      Delete
                                    </div>
                                  </Col>
                                </Row>
                              )}
                            </div>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </div>
                  );
                })}
            </div>
          )}

          {/* Pre- data State */}

          {
            <div
              className={`      ${
                templateList?.length > 0 || isEditTemplate || !preData
                  ? "d-none"
                  : "d-flex flex-column"
              }`}
              style={{
                width: "80%",
              }}
            >
              {loadingTemplateList ? (
                <div
                  className="loader d-flex align-items-center justify-content-center h-100"
                  style={{
                    width: "100%",
                  }}
                >
                  <Loader />
                </div>
              ) : (
                <>
                  <CustomCarousel />
                  <div
                    className="d-flex justify-content-center"
                    style={{ marginTop: "5rem" }}
                  >
                    <Button
                      className="hg-blue-btn d-flex align-items-center w-fit-content"
                      onClick={() => {
                        setSelectedSectionQuestionList(0);
                        setTemplateData({ ...template });
                        setIsEditTemplate(true);
                        setIsEditTemplateName(true);
                      }}
                    >
                      <img
                        alt=""
                        className="mr-2"
                        src="/images/attack-surface/white-plus.svg"
                      />
                      Create template
                    </Button>
                  </div>
                </>
              )}
            </div>
          }

          {/* -----------------Right Part ------------*/}

          {(templateList?.length > 0 || isEditTemplate || !preData) &&
            !loadingTemplateList && (
              <div
                className={`questionnaire-right-side-wrapper   ${
                  templateData?.ObjectId === null ? "d-none" : ""
                }`}
                style={{
                  width: "80%",
                }}
              >
                <div
                  className="d-flex bg-white align-items-center justify-content-between py-2 px-3 border-bottom position-sticky top-0"
                  style={{
                    zIndex: 5,
                  }}
                >
                  {isEditTemplateName ? (
                    <input
                      type="text"
                      className=" flex-grow-1  f-16 f-500   w-50 p-1 highlight-input mr-2"
                      value={templateData?.Title}
                      placeholder="Title of template"
                      onChange={(e) => {
                        setTemplateData({
                          ...templateData,
                          Title: e.target.value,
                        });
                      }}
                      onBlur={() => {
                        if (templateData?.Title === "") return;
                        setIsEditTemplateName(false);
                      }}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          if (templateData?.Title === "") return;
                          setIsEditTemplateName(false);
                          if (templateData?.ObjectId > 0) {
                            renameTemplateFunc(
                              templateData?.ObjectId,
                              templateData?.Title
                            );
                          }
                        }
                      }}
                      autoFocus
                    />
                  ) : (
                    <div
                      className="f-16 f-500 bg-hover-grey p-1 radius-2 w-100 pr-2"
                      style={{
                        pointerEvents: `${isEditTemplate ? "" : "none"}`,
                      }}
                      onClick={() => {
                        setIsEditTemplateName(true);
                      }}
                    >
                      {templateData?.Title}
                    </div>
                  )}
                  <div className="d-flex align-items-center">
                    {isEditTemplate ? (
                      <>
                        <div
                          className="d-flex f-12 f-500 align-items-center p-1 px-2 rounded-full f-blue w-max-content"
                          style={{ background: "#008fd420" }}
                        >
                          Editor mode
                        </div>
                        <Button
                          className="hg-tranparent-grey-btn mr-2"
                          onClick={() => {
                            setIsEditTemplate(false);
                          }}
                        >
                          Cancel
                        </Button>
                        <Button
                          className="hg-blue-btn mr-2"
                          onClick={() => {
                            setPreData(false);
                            setsavingTemplatefromBigModal(true);
                            saveTemplate(templateData);
                          }}
                        >
                          {savingTemplatefromBigModal ? (
                            <div className="d-flex align-items-center">
                              <WhiteLoader width={13} /> Saving..
                            </div>
                          ) : (
                            "Save"
                          )}
                        </Button>
                      </>
                    ) : (
                      <Button
                        className={`hg-blue-btn mr-2 w-max-content ${
                          templateData?.ObjectId === 0 ? "d-none" : ""
                        }`}
                        onClick={() => {
                          setShowSendQuestionnaireModal(true);
                        }}
                        disabled={templateList?.length === 0}
                      >
                        Send Questionnaire
                      </Button>
                    )}

                    <div
                      className={`template-item-dropdown  ${
                        templateData?.ObjectId === 0 || isEditTemplate
                          ? "d-none"
                          : ""
                      }`}
                    >
                      <Dropdown
                        onToggle={() => {
                          setRenameTemplate(false);
                          setTempName("");
                        }}
                        className={`  device-dropdown ${
                          renameTemplate
                            ? "white-side-dropdown"
                            : "black-dropdown"
                        }`}
                      >
                        <Dropdown.Toggle>
                          <img alt="" src="/images/horizontal-dots.svg" />
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <div>
                            {renameTemplate ? (
                              <div className="d-flex align-items-center hg-border mx-1 border-4 p-1">
                                <input
                                  className=""
                                  value={tempName}
                                  onChange={(e) => {
                                    setTempName(e.target.value);
                                  }}
                                  autoFocus
                                />
                                <Button
                                  className="hg-blue-btn"
                                  onClick={(e) => {
                                    renameTemplateFunc(
                                      templateData?.ObjectId,
                                      tempName
                                    );
                                    e.target
                                      .closest(".device-dropdown")
                                      .click();
                                  }}
                                >
                                  Save
                                </Button>
                              </div>
                            ) : (
                              <Row>
                                <Col xs={12} className=" pointer ">
                                  <div
                                    className="drop-item"
                                    onClick={(e) => {
                                      getTemplate(templateData?.ObjectId, true);
                                      e.target
                                        .closest(".device-dropdown")
                                        .click();
                                    }}
                                  >
                                    Edit
                                  </div>
                                </Col>
                                <Col xs={12} className="  pointer ">
                                  <div
                                    className="drop-item"
                                    onClick={(e) => {
                                      // e.target
                                      //   .closest(".device-dropdown")
                                      //   .click();
                                      setTempName(templateData?.Title);
                                      setRenameTemplate(true);
                                    }}
                                  >
                                    Rename
                                  </div>
                                </Col>

                                <Col xs={12} className=" pointer ">
                                  <div
                                    className="drop-item"
                                    onClick={(e) => {
                                      createDuplicateTemplate(
                                        templateData?.ObjectId
                                      );
                                      e.target
                                        .closest(".device-dropdown")
                                        .click();
                                    }}
                                  >
                                    Duplicate
                                  </div>
                                </Col>
                                {/* <Col xs={12} className=" pointer ">
                                  <div
                                    className="drop-item"
                                    onClick={(e) => {
                                      e.target
                                        .closest(".device-dropdown")
                                        .click();
                                    }}
                                  >
                                    Delete
                                  </div>
                                </Col> */}
                              </Row>
                            )}
                          </div>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </div>
                </div>
                {/*  Body -----------*/}
                <div className="w-100 d-flex h-100">
                  {loading ? (
                    <div
                      className="loader d-flex align-items-center justify-content-center h-100"
                      style={{
                        width: "70%",
                      }}
                    >
                      <Loader />
                    </div>
                  ) : (
                    <div
                      className="px-5 pt-3 hide-scrollbar"
                      style={{
                        width: "70%",
                        overflowY: "scroll",
                      }}
                    >
                      {/** Question data */}

                      {/* Section name */}
                      {isEditSectionName ? (
                        <input
                          type="text"
                          className="f-16 f-600  border-none f-500 border-none w-100 p-1 py-2 highlight-input"
                          value={
                            templateData?.SCMFormSection_List[
                              selectedSectionQuestionList
                            ]?.Name
                          }
                          placeholder="Type section name..."
                          onChange={(e) => {
                            let updated_data = { ...templateData };
                            updated_data.SCMFormSection_List[
                              selectedSectionQuestionList
                            ].Name = e.target.value;
                            setTemplateData(updated_data);
                          }}
                          onBlur={() => {
                            if (
                              templateData.SCMFormSection_List[
                                selectedSectionQuestionList
                              ].Name === ""
                            )
                              return;
                            setIsEditSectionName(false);
                          }}
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              if (
                                templateData.SCMFormSection_List[
                                  selectedSectionQuestionList
                                ].Name === ""
                              )
                                return;
                              setIsEditSectionName(false);
                            }
                          }}
                          autoFocus
                        />
                      ) : (
                        <div
                          className="f-16 f-600 bg-hover-grey radius-2 p-1 py-2"
                          onClick={() => {
                            setIsEditSectionName(true);
                          }}
                          style={{
                            pointerEvents: `${isEditTemplate ? "" : "none"}`,
                          }}
                        >
                          {
                            templateData?.SCMFormSection_List[
                              selectedSectionQuestionList
                            ]?.Name
                          }
                        </div>
                      )}

                      {templateData?.SCMFormSection_List[
                        selectedSectionQuestionList
                      ].SCMFormQuestion_List.map((question, questionIndex) => {
                        return (
                          <>
                            <div className="mt-5 ">
                              <div className="d-flex align-items-center justify-content-between">
                                <div className="w-100 d-flex">
                                  {/* Left side of Question */}
                                  {questionEdit === questionIndex ? (
                                    <input
                                      type="text"
                                      className="  f-500   flex-grow-1 p-1 py-2 highlight-input"
                                      value={
                                        templateData?.SCMFormSection_List[
                                          selectedSectionQuestionList
                                        ]?.SCMFormQuestion_List[questionIndex]
                                          .Question
                                      }
                                      placeholder="Type your question...."
                                      onChange={(e) => {
                                        let updated_data = { ...templateData };
                                        updated_data.SCMFormSection_List[
                                          selectedSectionQuestionList
                                        ].SCMFormQuestion_List[
                                          questionIndex
                                        ].Question = e.target.value;
                                        setTemplateData(updated_data);
                                      }}
                                      onBlur={() => {
                                        if (
                                          templateData?.SCMFormSection_List[
                                            selectedSectionQuestionList
                                          ].SCMFormQuestion_List[questionIndex]
                                            .Question === ""
                                        )
                                          return;
                                        setQuestionEdit("");
                                      }}
                                      onKeyDown={(e) => {
                                        if (e.key === "Enter") {
                                          if (
                                            templateData?.SCMFormSection_List[
                                              selectedSectionQuestionList
                                            ].SCMFormQuestion_List[
                                              questionIndex
                                            ].Question === ""
                                          )
                                            return;
                                          setQuestionEdit("");
                                        }
                                      }}
                                      autoFocus
                                    />
                                  ) : (
                                    <div
                                      className=" f-500 bg-hover-grey p-1 radius-2 w-100"
                                      style={{
                                        pointerEvents: `${
                                          isEditTemplate ? "" : "none"
                                        }`,
                                      }}
                                      onClick={() => {
                                        setQuestionEdit(questionIndex);
                                      }}
                                    >
                                      {
                                        templateData?.SCMFormSection_List[
                                          selectedSectionQuestionList
                                        ].SCMFormQuestion_List[questionIndex]
                                          .Question
                                      }
                                    </div>
                                  )}
                                </div>
                                <div>
                                  {isEditTemplate && (
                                    <div className="w-100 d-flex align-items-center">
                                      <div className="answer-type-selection-dropdown">
                                        <NormalDropdownForQuestionnaireModal
                                          fieldId={"id"}
                                          fieldValue={"category"}
                                          selected={
                                            templateData?.SCMFormSection_List[
                                              selectedSectionQuestionList
                                            ].SCMFormQuestion_List[
                                              questionIndex
                                            ].Type
                                          }
                                          data={[
                                            {
                                              id: [16, 17],
                                              category: "Multiple answers",
                                            },
                                            {
                                              id: [1, 5],
                                              category: "Single answer",
                                            },
                                            {
                                              id: [2, 6],
                                              category: "Yes/No answer",
                                            },
                                            { id: [0], category: "Text input" },
                                          ]}
                                          onClick={(id, val) => {
                                            let updated_data = {
                                              ...templateData,
                                            };

                                            // To not allow user to add more than one Yes/No or Text Input
                                            if (
                                              val === "Text input" ||
                                              val === "Yes/No answer"
                                            ) {
                                              updated_data.SCMFormSection_List[
                                                selectedSectionQuestionList
                                              ].SCMFormQuestion_List[
                                                questionIndex
                                              ].SCMFormDropdownItems_List = [
                                                {
                                                  ObjectId: 0,
                                                  Text: "option1",
                                                  SortOrder: 1,
                                                },
                                              ];
                                            }

                                            let questionType =
                                              changeQuestionnaireType(
                                                val,
                                                updated_data
                                                  .SCMFormSection_List[
                                                  selectedSectionQuestionList
                                                ].SCMFormQuestion_List[
                                                  questionIndex
                                                ]?.IsNote
                                              );
                                            if (questionType !== null) {
                                              updated_data.SCMFormSection_List[
                                                selectedSectionQuestionList
                                              ].SCMFormQuestion_List[
                                                questionIndex
                                              ].Type = questionType;
                                              setTemplateData(updated_data);
                                            }
                                          }}
                                          extraElement={
                                            <>
                                              <hr
                                                className="mx-auto"
                                                style={{
                                                  borderTop:
                                                    "1px solid #EBEDF1",
                                                  width: "90%",
                                                }}
                                              />
                                              <div className="w-100 d-flex align-items-center justify-content-between pl-3 pr-1 pb-1">
                                                <div className="f-black">
                                                  Note Field
                                                </div>
                                                <div>
                                                  <Form.Group
                                                    controlId={
                                                      "check" +
                                                      selectedSectionQuestionList +
                                                      "" +
                                                      questionIndex
                                                    }
                                                    className=""
                                                  >
                                                    <Form.Check
                                                      type="switch"
                                                      id={
                                                        "switch-ques1" +
                                                        selectedSectionQuestionList +
                                                        "" +
                                                        questionIndex
                                                      }
                                                      style={{
                                                        transform: "scale(1.6)",
                                                      }}
                                                      className="m-0 pl-3 scale-switch switch-shadow-none"
                                                      checked={
                                                        templateData
                                                          .SCMFormSection_List[
                                                          selectedSectionQuestionList
                                                        ].SCMFormQuestion_List[
                                                          questionIndex
                                                        ]?.IsNote
                                                      }
                                                      // disabled={true}
                                                      label=""
                                                      onChange={(e) => {
                                                        let updated_data = {
                                                          ...templateData,
                                                        };
                                                        let questionType =
                                                          updated_data
                                                            .SCMFormSection_List[
                                                            selectedSectionQuestionList
                                                          ]
                                                            .SCMFormQuestion_List[
                                                            questionIndex
                                                          ].Type;

                                                        let questionEnumType =
                                                          changeQuesTypeByNote(
                                                            questionType,
                                                            e.target.checked
                                                          );

                                                        if (
                                                          questionEnumType > 0
                                                        ) {
                                                          updated_data.SCMFormSection_List[
                                                            selectedSectionQuestionList
                                                          ].SCMFormQuestion_List[
                                                            questionIndex
                                                          ].Type =
                                                            questionEnumType;

                                                          updated_data.SCMFormSection_List[
                                                            selectedSectionQuestionList
                                                          ].SCMFormQuestion_List[
                                                            questionIndex
                                                          ].IsNote =
                                                            e.target.checked;
                                                          setTemplateData(
                                                            updated_data
                                                          );
                                                        }
                                                      }}
                                                    />
                                                  </Form.Group>
                                                </div>
                                              </div>
                                            </>
                                          }
                                        />
                                      </div>
                                      <img
                                        className="ml-2 pointer d-none"
                                        src="/images/supply-chain/copy-clipboard-icon.svg"
                                        alt=""
                                      />
                                      <img
                                        className="ml-2 pointer"
                                        src="/images/actioncenter/delete.svg"
                                        alt=""
                                        onClick={() => {
                                          let updated_data = {
                                            ...templateData,
                                          };
                                          updated_data.SCMFormSection_List[
                                            selectedSectionQuestionList
                                          ].SCMFormQuestion_List.splice(
                                            questionIndex,
                                            1
                                          );

                                          setTemplateData(updated_data);
                                        }}
                                      />
                                      {/* Chat  drop down in questions */}
                                      <div className="question-chat-dropdown mx-2 d-none">
                                        <Dropdown className="chat-dropdown">
                                          <Dropdown.Toggle
                                            className="p-0 position-relative"
                                            style={{ zIndex: 1 }}
                                          >
                                            <div className="d-flex align-items-center f-12 f-500 f-grey">
                                              <img
                                                alt=""
                                                className="mr-1"
                                                src="/images/supply-chain/chat-icon.svg"
                                              />
                                              {question?.SCMFormComment_List
                                                ?.length || ""}
                                            </div>
                                          </Dropdown.Toggle>

                                          <Dropdown.Menu>
                                            <div>
                                              {/* Header */}
                                              <div className="chat-box-head px-2 pb-2 border-bottom">
                                                <div className="d-flex align-items-center justify-content-between">
                                                  <div className="f-12 f-darkgrey">
                                                    Question ID: QS2.S2.Q1
                                                  </div>
                                                  <div>
                                                    <img
                                                      alt=""
                                                      className="pointer"
                                                      src="/images/supply-chain/chat-box-cross.svg"
                                                      onClick={(e) => {
                                                        e.target
                                                          .closest(
                                                            ".chat-dropdown"
                                                          )
                                                          .click();
                                                      }}
                                                    />
                                                  </div>
                                                </div>
                                              </div>
                                              {/* Body */}
                                              <div className="py-3 hide-scrollbar chat-body">
                                                {
                                                  // question?.SCMFormComment_List

                                                  question?.SCMFormComment_List?.map(
                                                    (comment, commentIndex) => {
                                                      return (
                                                        <div className="mx-3 mb-3">
                                                          <p className="m-0 f-500 f-black">
                                                            {comment?.ByName}
                                                          </p>
                                                          <p className=" f-black">
                                                            {comment?.Comment}
                                                          </p>
                                                        </div>
                                                      );
                                                    }
                                                  )
                                                }
                                              </div>
                                              {/* footer */}
                                              <div className="message-typer d-flex align-items-center mx-1 mt-2">
                                                <input
                                                  type="text"
                                                  placeholder="Type a comment"
                                                  className="pl-2"
                                                  // value={message}
                                                  onChange={(e) => {}}
                                                />
                                                <Button
                                                  className=""
                                                  onClick={() => {}}
                                                >
                                                  <img
                                                    alt=""
                                                    width={12}
                                                    height={12}
                                                    src="/images/settings-v2/white-plane.svg"
                                                    className=""
                                                    style={{ width: "20px" }}
                                                  />
                                                </Button>
                                              </div>
                                            </div>
                                          </Dropdown.Menu>
                                        </Dropdown>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </div>

                              {/* Right side of Question */}
                              {/* <div className="f-500 mb-3">Question without title</div> */}
                              {templateData?.SCMFormSection_List[
                                selectedSectionQuestionList
                              ].SCMFormQuestion_List[
                                questionIndex
                              ].SCMFormDropdownItems_List.map((item, index) => {
                                return (
                                  <>
                                    <div className="d-flex align-items-center justify-content-between my-2  ">
                                      {/* Code for CheckBox====================================================== */}
                                      {[16, 17].indexOf(
                                        templateData?.SCMFormSection_List[
                                          selectedSectionQuestionList
                                        ].SCMFormQuestion_List[questionIndex]
                                          .Type
                                      ) !== -1 && (
                                        <div className="d-flex align-items-center w-100 pr-2">
                                          <div className="mb-3">
                                            <Form.Group
                                              controlId={
                                                "check" +
                                                questionIndex +
                                                "" +
                                                index
                                              }
                                              className="round-checkbox blue-checkbox checkboxstyle mb-1"
                                            >
                                              <Form.Check
                                                disabled={true}
                                                type="checkbox"
                                                name={"check" + index}
                                                label=""
                                                // checked={}
                                                onChange={(e) => {}}
                                              />
                                            </Form.Group>
                                          </div>
                                          {editquestionOption ==
                                          questionIndex + "" + index ? (
                                            <input
                                              type="text"
                                              className="  f-500 flex-grow-1  w-50 p-1 py-2 ml-3 highlight-input"
                                              value={item?.Text}
                                              placeholder="Option text"
                                              onChange={(e) => {
                                                let updated_data = {
                                                  ...templateData,
                                                };
                                                updated_data.SCMFormSection_List[
                                                  selectedSectionQuestionList
                                                ].SCMFormQuestion_List[
                                                  questionIndex
                                                ].SCMFormDropdownItems_List[
                                                  index
                                                ].Text = e.target.value;
                                                setTemplateData(updated_data);
                                              }}
                                              onBlur={() => {
                                                if (
                                                  templateData
                                                    ?.SCMFormSection_List[
                                                    selectedSectionQuestionList
                                                  ].SCMFormQuestion_List[
                                                    questionIndex
                                                  ].SCMFormDropdownItems_List[
                                                    index
                                                  ].Text === ""
                                                )
                                                  return;
                                                setEditquestionOption("");
                                              }}
                                              onKeyDown={(e) => {
                                                if (e.key === "Enter") {
                                                  if (
                                                    templateData
                                                      ?.SCMFormSection_List[
                                                      selectedSectionQuestionList
                                                    ].SCMFormQuestion_List[
                                                      questionIndex
                                                    ].SCMFormDropdownItems_List[
                                                      index
                                                    ].Text === ""
                                                  )
                                                    return;
                                                  setEditquestionOption("");
                                                }
                                              }}
                                              autoFocus
                                            />
                                          ) : (
                                            <div
                                              className="flex-grow-1 f-500 bg-hover-grey p-1 radius-2 ml-3"
                                              style={{
                                                pointerEvents: `${
                                                  isEditTemplate ? "" : "none"
                                                }`,
                                              }}
                                              onClick={() => {
                                                if (editquestionOption !== "")
                                                  return;
                                                setEditquestionOption(
                                                  questionIndex + "" + index
                                                );
                                              }}
                                            >
                                              {item?.Text}
                                            </div>
                                          )}
                                        </div>
                                      )}
                                      {/* for 
                                  Radio
                                  Buttons
                                  ======= 
                                  */}
                                      {[1, 5].indexOf(
                                        templateData?.SCMFormSection_List[
                                          selectedSectionQuestionList
                                        ].SCMFormQuestion_List[questionIndex]
                                          .Type
                                      ) !== -1 && (
                                        <div className="d-flex align-items-center w-100 pr-2">
                                          <div className="mb-3">
                                            <Form.Group
                                              controlId={
                                                "check" +
                                                templateData
                                                  ?.SCMFormSection_List[
                                                  selectedSectionQuestionList
                                                ]?.length -
                                                1 +
                                                "-" +
                                                questionIndex +
                                                "" +
                                                index
                                              }
                                              className="round-checkbox round blue-checkbox  mb-1"
                                            >
                                              <Form.Check
                                                disabled={true}
                                                type="radio"
                                                name={"check"}
                                                label=""
                                                // checked={}
                                                onChange={(e) => {}}
                                              />
                                            </Form.Group>
                                          </div>
                                          {editquestionOption ==
                                          questionIndex + "" + index ? (
                                            <input
                                              type="text"
                                              className="  f-500  flex-grow-1 w-50 p-1 py-2 ml-3 highlight-input"
                                              value={item?.Text}
                                              placeholder="Option text"
                                              onChange={(e) => {
                                                let updated_data = {
                                                  ...templateData,
                                                };
                                                updated_data.SCMFormSection_List[
                                                  selectedSectionQuestionList
                                                ].SCMFormQuestion_List[
                                                  questionIndex
                                                ].SCMFormDropdownItems_List[
                                                  index
                                                ].Text = e.target.value;
                                                setTemplateData(updated_data);
                                              }}
                                              onBlur={() => {
                                                if (
                                                  templateData
                                                    ?.SCMFormSection_List[
                                                    selectedSectionQuestionList
                                                  ].SCMFormQuestion_List[
                                                    questionIndex
                                                  ].SCMFormDropdownItems_List[
                                                    index
                                                  ].Text === ""
                                                )
                                                  return;
                                                setEditquestionOption("");
                                              }}
                                              onKeyDown={(e) => {
                                                if (e.key === "Enter") {
                                                  if (
                                                    templateData
                                                      ?.SCMFormSection_List[
                                                      selectedSectionQuestionList
                                                    ].SCMFormQuestion_List[
                                                      questionIndex
                                                    ].SCMFormDropdownItems_List[
                                                      index
                                                    ].Text === ""
                                                  )
                                                    return;
                                                  setEditquestionOption("");
                                                }
                                              }}
                                              autoFocus
                                            />
                                          ) : (
                                            <div
                                              className="flex-grow-1 f-500 bg-hover-grey p-1 radius-2 ml-3 highlight-input"
                                              style={{
                                                pointerEvents: `${
                                                  isEditTemplate ? "" : "none"
                                                }`,
                                              }}
                                              onClick={() => {
                                                if (editquestionOption !== "")
                                                  return;
                                                setEditquestionOption(
                                                  questionIndex + "" + index
                                                );
                                              }}
                                            >
                                              {item?.Text}
                                            </div>
                                          )}
                                        </div>
                                      )}
                                      {/*  for Yes / No button ================================================================================ */}
                                      {[2, 6].indexOf(
                                        templateData?.SCMFormSection_List[
                                          selectedSectionQuestionList
                                        ].SCMFormQuestion_List[questionIndex]
                                          .Type
                                      ) !== -1 && (
                                        <div className="d-flex align-items-center ml-2">
                                          <Button className="hg-cancel-btn mr-2">
                                            Yes
                                          </Button>
                                          <Button className="hg-cancel-btn">
                                            No
                                          </Button>
                                        </div>
                                      )}
                                      {[0].indexOf(
                                        templateData?.SCMFormSection_List[
                                          selectedSectionQuestionList
                                        ].SCMFormQuestion_List[questionIndex]
                                          .Type
                                      ) !== -1 && (
                                        <div className="d-flex align-items-center ml-2">
                                          <input
                                            className="p-2 radius-2 w-100 border-none"
                                            placeholder="Type your answer.."
                                            disabled={true}
                                          />
                                        </div>
                                      )}

                                      <div>
                                        {isEditTemplate &&
                                          [0, 2, 6].indexOf(question.Type) ===
                                            -1 && (
                                            <img
                                              src="/images/supply-chain/small-cross-modal.svg"
                                              alt=""
                                              className="mr-1 pointer"
                                              onClick={() => {
                                                let updated_data = {
                                                  ...templateData,
                                                };
                                                updated_data.SCMFormSection_List[
                                                  selectedSectionQuestionList
                                                ].SCMFormQuestion_List[
                                                  questionIndex
                                                ].SCMFormDropdownItems_List.splice(
                                                  index,
                                                  1
                                                );
                                                setTemplateData(updated_data);
                                              }}
                                            />
                                          )}
                                      </div>
                                    </div>
                                  </>
                                );
                              })}

                              {isEditTemplate &&
                                [0, 2, 6].indexOf(question.Type) === -1 && (
                                  <div
                                    className="f-500 f-grey d-flex align-items-center ml-1 pointer"
                                    onClick={() => {
                                      let updated_data = { ...templateData };

                                      updated_data.SCMFormSection_List[
                                        selectedSectionQuestionList
                                      ].SCMFormQuestion_List[
                                        questionIndex
                                      ].SCMFormDropdownItems_List.push({
                                        ObjectId: 0,
                                        Text: `option ${
                                          updated_data.SCMFormSection_List[
                                            selectedSectionQuestionList
                                          ].SCMFormQuestion_List[questionIndex]
                                            .SCMFormDropdownItems_List?.length +
                                          1
                                        }`,
                                        SortOrder:
                                          templateData?.SCMFormSection_List[
                                            selectedSectionQuestionList
                                          ].SCMFormQuestion_List[questionIndex]
                                            .SCMFormDropdownItems_List.length +
                                          1,
                                      });
                                      setTemplateData(updated_data);
                                    }}
                                  >
                                    <img
                                      src="/images/actioncenter/plusgrey.svg"
                                      alt=""
                                      className="mr-2"
                                    />
                                    Add option
                                  </div>
                                )}
                            </div>

                            {templateData?.SCMFormSection_List[
                              selectedSectionQuestionList
                            ].SCMFormQuestion_List[questionIndex]?.IsNote && (
                              <textarea
                                disabled={true}
                                placeholder="Note..."
                                className="w-100 p-2 radius-4 border-none mt-3"
                                value={
                                  templateData?.SCMFormSection_List[
                                    selectedSectionQuestionList
                                  ].SCMFormQuestion_List[questionIndex].Note
                                }
                                onChange={(e) => {}}
                                style={{
                                  height: "80px",
                                  background: "#F6F8FB",
                                }}
                              ></textarea>
                            )}
                          </>
                        );
                      })}

                      {isEditTemplate && (
                        <div
                          className="p-2 d-flex align-items-center bg-grey hover-bg-dark-grey pointer f-500 f-grey w-max-content radius-4 mt-4 "
                          onClick={() => {
                            let updated_data = { ...templateData };
                            updated_data.SCMFormSection_List[
                              selectedSectionQuestionList
                            ].SCMFormQuestion_List.push(
                              {
                                ObjectId: 0,
                                Question: `Question ${
                                  updated_data.SCMFormSection_List[
                                    selectedSectionQuestionList
                                  ].SCMFormQuestion_List?.length + 1
                                }`,
                                Description: null,
                                Note: "",
                                Type: 16,
                                IsNote: false,
                                SortOrder: 1,
                                SCMFormDropdownItems_List: [
                                  {
                                    ObjectId: 0,
                                    Text: "option1",
                                    SortOrder: 1,
                                  },
                                  {
                                    ObjectId: 0,
                                    Text: "option2",
                                    SortOrder: 2,
                                  },
                                ],
                              }

                              // {
                              //   ...dummyQuestion,
                              // }
                            );

                            setTemplateData(updated_data);
                          }}
                        >
                          <img
                            alt=""
                            className="mr-2"
                            src="/images/actioncenter/plusgrey.svg"
                          />
                          Add question
                        </div>
                      )}

                      <div className="d-flex align-items-center justify-content-end w-100 mt-4">
                        {selectedSectionQuestionList > 0 && (
                          <Button
                            className="hg-cancel-btn p-3"
                            onClick={() => {
                              setSelectedSectionQuestionList(
                                selectedSectionQuestionList - 1
                              );
                            }}
                          >
                            Previous
                          </Button>
                        )}
                        {templateData?.SCMFormSection_List?.length > 1 &&
                          selectedSectionQuestionList <
                            templateData?.SCMFormSection_List?.length - 1 && (
                            <Button
                              className="hg-blue-btn ml-2 p-3"
                              onClick={() => {
                                setSelectedSectionQuestionList(
                                  selectedSectionQuestionList + 1
                                );
                              }}
                            >
                              Next
                            </Button>
                          )}
                      </div>

                      <div className="" style={{ padding: "50px" }}></div>
                    </div>
                  )}

                  {/* Side bar */}
                  <div
                    className="border-left  h-100 pl-2 pr-4 pt-4 hide-scrollbar"
                    style={{
                      width: "30%",
                      overflowY: "auto",
                    }}
                  >
                    {templateData?.SCMFormSection_List.map(
                      (section, sectionIndex) => {
                        return (
                          <div
                            className={`d-flex align-items-center justify-content-between bg-hover-grey p-3 pointer radius-4 mb-1 hover-on-show-parent ${
                              selectedSectionQuestionList === sectionIndex
                                ? "bg-grey"
                                : ""
                            }  `}
                            onClick={() => {
                              setSelectedSectionQuestionList(sectionIndex);
                            }}
                          >
                            <div className="d-flex align-items-center">
                              <div
                                className="mr-2 d-flex align-items-center justify-content-center position-relative"
                                style={{
                                  width: "32px",
                                  height: "32px",
                                  borderRadius: "50%",
                                  //   padding: "5px",
                                  background: "#EBEDF1",
                                  color: "#616778",
                                }}
                              >
                                <span
                                  className="position-absolute"
                                  style={{
                                    top: "50%",
                                    left: "50%",
                                    transform: "translate(-50%,-50%)",
                                  }}
                                >
                                  {sectionIndex + 1}{" "}
                                  {/* {section.SCMFormQuestion_List.length} */}
                                </span>
                                <svg
                                  height="32"
                                  width="32"
                                  viewBox="0 0 34 34"
                                  className=""
                                >
                                  <circle
                                    cx="17"
                                    cy="17"
                                    r="16"
                                    stroke="#008FD4"
                                    stroke-width="1.5"
                                    fill="transparent"
                                    pathLength={5}
                                    strokeDasharray={5}
                                    strokeDashoffset={5}
                                  />
                                </svg>
                              </div>
                              <div>
                                <p className="m-0 f-500 f-black">
                                  {TrimString(section.Name, 20)}
                                </p>
                                <p className="m-0 f-darkgrey f-12">
                                  {section.SCMFormQuestion_List.length}{" "}
                                  questions
                                </p>
                              </div>
                            </div>
                            <div className="float-right">
                              {sectionIndex > 1 && isEditTemplate && (
                                <img
                                  className="hover-on-show-child"
                                  src="/images/actioncenter/delete.svg"
                                  alt=""
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    if (
                                      selectedSectionQuestionList > 0 &&
                                      selectedSectionQuestionList ===
                                        sectionIndex
                                    ) {
                                      setSelectedSectionQuestionList(
                                        selectedSectionQuestionList - 1
                                      );
                                    }

                                    let updated_data = { ...templateData };
                                    updated_data.SCMFormSection_List.splice(
                                      sectionIndex,
                                      1
                                    );
                                    setTemplateData(updated_data);
                                  }}
                                />
                              )}
                            </div>
                          </div>
                        );
                      }
                    )}
                    {isEditTemplate && (
                      <div
                        className="d-flex align-items-center f-grey ml-4 mt-3 pointer"
                        onClick={() => {
                          let updated_data = { ...templateData };

                          updated_data.SCMFormSection_List = [
                            ...updated_data.SCMFormSection_List,
                            {
                              ObjectId: 0,
                              Name: `Custom Section ${
                                templateData?.SCMFormSection_List?.length - 1
                              }`,
                              Sort: 1,
                              SCMFormQuestion_List: [
                                {
                                  ObjectId: 0,
                                  Question: "Question 1",
                                  Description: null,
                                  Note: "",
                                  Type: 16,
                                  IsNote: false,
                                  SortOrder: 1,
                                  SCMFormDropdownItems_List: [
                                    {
                                      ObjectId: 3480558,
                                      Text: "option 1",
                                      SortOrder: 1,
                                    },
                                    {
                                      ObjectId: 3480559,
                                      Text: "option 2",
                                      SortOrder: 2,
                                    },
                                  ],
                                },
                                {
                                  ObjectId: 0,
                                  Question: "Question 2",
                                  Description: null,
                                  Note: "",
                                  Type: 16,
                                  IsNote: false,
                                  SortOrder: 2,
                                  SCMFormDropdownItems_List: [
                                    {
                                      ObjectId: 0,
                                      Text: "option 1",
                                      SortOrder: 1,
                                    },
                                    {
                                      ObjectId: 0,
                                      Text: "option 2",
                                      SortOrder: 2,
                                    },
                                  ],
                                },
                              ],
                            },
                          ];

                          setTemplateData((prev) => updated_data);
                        }}
                      >
                        <img
                          alt=""
                          className="mr-2"
                          src="/images/actioncenter/plusgrey.svg"
                        />
                        Add section
                      </div>
                    )}
                    <div className="" style={{ padding: "40px" }}></div>
                  </div>
                </div>
              </div>
            )}
        </div>
        <SendQuestionnaireModal
          show={showSendQuestionnaireModal}
          hideModal={() => setShowSendQuestionnaireModal(false)}
          title={templateData?.Title}
          templateId={templateData?.ObjectId}
          refreshData={refreshData}
        />
      </Modal.Body>
      <ResetSettingsModal
        show={showPromptModal}
        hideModal={() => {
          setShowPromptModal(false);
          hideModal();
        }}
        saveTemplate={saveTemplate}
        templateData={templateData}
        setSavingTemplateFromPromptModal={setSavingTemplateFromPromptModal}
        savingTemplateFromPromptModal={savingTemplateFromPromptModal}
      />
      <DeleteModal
        show={showDeleteModal}
        hideModal={() => setShowDeleteModal(false)}
        deleteMessage={deleteMessage}
        deleteQuestionnaireTemplate={() => {
          deleteTemplate(deleteObjID);
        }}
        deleteType={"deleteQuestionnaireTemplate"}
      />
    </Modal>
  );
};

export default QuestionnaireBigModal;
const ResetSettingsModal = ({
  show,
  hideModal,
  saveTemplate,
  templateData,
  setSavingTemplateFromPromptModal,
  savingTemplateFromPromptModal,
}) => {
  let modalBackdrop = document.getElementsByClassName("modal")[0];

  useEffect(() => {
    setTimeout(() => {
      if (!modalBackdrop) return;
      if (show) {
        // alert("ran")
        document.getElementsByClassName("modal")[0].style.zIndex = 1040;
      } else {
        // document.getElementsByClassName("modal")[0].style.zIndex=1050
      }
    }, 100);
  }, [show]);

  return (
    <Modal
      animation={false}
      show={show}
      dialogClassName="reset-settings-modal "
      className="dark-backdrop"
      aria-labelledby="reset-settings-modal-title-vcenter"
      centered
      scrollable
      onHide={() => {}}
    >
      <Modal.Body>
        <div>
          <p className="modal-title ml-2 mt-2">Are you sure?</p>
          <p className="modal-subtitle mx-2 mt-3">
            You have unsaved changes, would you like to save these now?
          </p>
        </div>
      </Modal.Body>

      <Modal.Footer>
        <div className="footer d-flex justify-content-end">
          <Button className="cancel-btn pointer" onClick={hideModal}>
            Dont' Save
          </Button>
          <Button
            className="hg-blue-btn"
            onClick={() => {
              setSavingTemplateFromPromptModal(true);

              saveTemplate(templateData);
            }}
          >
            {savingTemplateFromPromptModal ? (
              <div className="d-flex align-items-center">
                <WhiteLoader width={13} /> Saving..
              </div>
            ) : (
              "Save"
            )}
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

function changeQuestionnaireType(type, isNote) {
  if (type === "Multiple answers" && isNote === true) return 17;
  if (type === "Multiple answers" && isNote === false) return 16;
  if (type === "Single answer" && isNote === true) return 5;
  if (type === "Single answer" && isNote === false) return 1;
  if (type === "Yes/No answer" && isNote === true) return 6;
  if (type === "Yes/No answer" && isNote === false) return 2;
  if (type === "Text input" && isNote === true) return null;
  if (type === "Text input" && isNote === false) return 0;
}
function changeQuesTypeByNote(questionEnumType, isNote) {
  if (questionEnumType === 16 && isNote === true) return 17;
  if (questionEnumType === 17 && isNote === false) return 16;
  if (questionEnumType === 1 && isNote === true) return 5;
  if (questionEnumType === 5 && isNote === false) return 1;
  if (questionEnumType === 2 && isNote === true) return 6;
  if (questionEnumType === 6 && isNote === false) return 2;
  if (questionEnumType === 0) return 0;
}

export const DeleteModalWithBackdrop = ({
  show,
  hideModal,
  onDelete = () => {},
  modalHeader,
  deleteLoading,
}) => {
  let modalBackdrop = document.getElementsByClassName("modal")[0];

  useEffect(() => {
    setTimeout(() => {
      if (!modalBackdrop) return;
      if (show) {
        // alert("ran")
        document.getElementsByClassName("modal")[0].style.zIndex = 1040;
      } else {
        // document.getElementsByClassName("modal")[0].style.zIndex=1050
      }
    }, 100);
  }, [show]);

  return (
    <Modal
      animation={false}
      show={show}
      dialogClassName="reset-settings-modal risk-matrix-delete-modal"
      className="dark-backdrop"
      aria-labelledby="reset-settings-modal-title-vcenter"
      centered
      scrollable
      onHide={() => {}}
    >
      <Modal.Body>
        <div>
          <p className="modal-title ml-2 mt-2">
            {modalHeader ?? "Are you sure?"}
          </p>
          <p className="modal-subtitle mx-2 mt-3">
            Are you sure you want to delete this?{" "}
          </p>
        </div>
      </Modal.Body>

      <Modal.Footer>
        <div className="footer d-flex justify-content-end">
          <Button className="cancel-btn pointer" onClick={hideModal}>
            Cancel
          </Button>
          <Button
            className="hg-red-btn"
            onClick={() => {
              onDelete();
            }}
          >
            {deleteLoading ? "Deleting.." : "Delete"}
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export const _template = {
  ObjectId: 0,
  Title: "Template Title",
  Sort: 2,
  IsCommunityShare: true,
  SCMFormSection_List: [
    {
      ObjectId: 0,
      Name: "Section 1",
      Sort: 1,
      SCMFormQuestion_List: [
        {
          ObjectId: 0,
          Question: "Question 1",
          Description: null,
          Note: "",
          Type: 16,
          IsNote: false,
          SortOrder: 1,
          SCMFormDropdownItems_List: [
            {
              ObjectId: 3480558,
              Text: "option 1",
              SortOrder: 1,
            },
            {
              ObjectId: 3480559,
              Text: "option 2",
              SortOrder: 2,
            },
          ],
        },
        {
          ObjectId: 0,
          Question: "Question 2",
          Description: null,
          Note: "",
          Type: 16,
          IsNote: false,
          SortOrder: 2,
          SCMFormDropdownItems_List: [
            {
              ObjectId: 0,
              Text: "option 1",
              SortOrder: 1,
            },
            {
              ObjectId: 0,
              Text: "option 2",
              SortOrder: 2,
            },
          ],
        },
      ],
    },
    {
      ObjectId: 0,
      Name: "Section 2",
      Sort: 1,
      SCMFormQuestion_List: [
        {
          ObjectId: 0,
          Question: "Question 1",
          Description: null,
          Note: "",
          Type: 16,
          IsNote: false,
          SortOrder: 1,
          SCMFormDropdownItems_List: [
            {
              ObjectId: 0,
              Text: "option 1",
              SortOrder: 1,
            },
            {
              ObjectId: 0,
              Text: "option 2",
              SortOrder: 2,
            },
          ],
        },
        {
          ObjectId: 0,
          Question: "Question 2",
          Description: null,
          Note: "",
          Type: 16,
          IsNote: false,
          SortOrder: 2,
          SCMFormDropdownItems_List: [
            {
              ObjectId: 0,
              Text: "option 1",
              SortOrder: 1,
            },
            {
              ObjectId: 0,
              Text: "option 2",
              SortOrder: 2,
            },
          ],
        },
      ],
    },
  ],
  mr: {
    Success: true,
    ExThrown: false,
    EntityId: 0,
    Message: "Success for:HG_Test_BuyerA@mailinator.com",
    ExMessage: null,
    ExInnerMessage: null,
    SuccessCount: 0,
    PartialCount: 0,
    FaliureCount: 0,
    PageEnumCalled: 0,
    DDLList: null,
    ObjectList: [],
  },
};
