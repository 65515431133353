import React from "react";
import { Button, Modal } from "react-bootstrap";
import "./upload-template-modal.scss";
import { UploadFileCircularProgress } from "../../MSPv2/secOps/SecOps";
import { useState } from "react";
import { CallToast } from "../../PriceCalculator/modals/CustomToast";
import axios from "axios";
import { useAuth } from "../../context/Auth";
import { useEffect } from "react";
import { postData } from "../../../Utils/commonApi";
import { downloadFileByLink } from "../../../Utils/dowloadFileByLink";
import { GreyLoader } from "../../Theme/APILoaders";

const UploadTemplateModal = ({ show = true, hideModal }) => {
  const uploadRef = React.useRef();
  const { authTokens } = useAuth();
  const [uploadProgress, setUploadProgress] = useState(0);
  const [showList, setShowList] = useState(false);
  const [uploadingFileList, setUploadingFileList] = useState([]);
  const [dataToImport, setDataToImport] = useState([]);
  const [tableData, setTableData] = useState({});
  const [errorData, setErrorData] = useState({
    errMessage: "",
    errIcon: "",
  });
  const [importing, setImporting] = useState(false);

  useEffect(() => {
    setShowList(false);
  }, [show]);

  async function ImportModalData() {
    setImporting(true);
    postData(authTokens, "SupplierXLSImport", {
      SupplierObjectList: JSON.stringify(dataToImport),
      // AccessKey: JSON.parse(localStorage.getItem("user_MessageObj")),
    })
      .then((response) => {
        setImporting(false);
        if (response?.Success) {
          CallToast("Updated Successfully", false, false, "v2style");
          hideModal();
        } else {
          CallToast(response?.Message, false, false, "v2style");
        }
        console.log(response);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const handleUpload = async (event) => {
    event.preventDefault();
    setImporting(true);

    const file = event.target.files[0];
    setUploadingFileList(event.target.files);

    const allowedFileTypes = [
      "application/csv",
      "application/vnd.ms-excel",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    ];

    if (file && allowedFileTypes.includes(file.type)) {
      const reader = new FileReader();

      reader.onloadend = async () => {
        // const base64Data = reader.result;
        const formData = new FormData();
        formData.append("supplierxl", file);
        formData.append(
          "AccessKey",
          JSON.parse(localStorage.getItem("user_MessageObj"))
        );
        // formData.append("FileName", file?.name);
        // formData.append("FrameworkAuditId", 22);

        try {
          const response = await axios.post(
            `${process.env.REACT_APP_API_BASE_URL}/CC_API/SupplierXLSUpload`,
            formData,
            {
              headers: {
                Authorization: `Bearer ${authTokens}`,
              },
              onUploadProgress: (progressEvent) => {
                const percentCompleted = Math.round(
                  (progressEvent.loaded * 100) / progressEvent.total
                );
                setUploadProgress(percentCompleted);
              },
            }
          );

          if (response?.data?.Success) {
            setShowList(true);
            setDataToImport(response?.data?.ObjectTemplateList);
            setTableData(response?.data?.SupplierVirtualTable);
            CallToast(response?.data?.Message, false, false, "v2style");
          } else {
            setErrorData({
              errMessage: response?.data?.ExMessage,
              errIcon: "/images/msp/uploading-error-icon.svg",
            });
          }

          // CallToast("Uploaded successfully", false, false, "v2style");

          setUploadProgress(0);
          setImporting(false);
        } catch (error) {
          console.error("Upload failed:", error);
          if (error?.code === "ERR_NETWORK") {
            setErrorData({
              errMessage:
                "There was a connection error during uploading. Please try again.",
              errIcon: "/images/msp/wifi-error.svg",
            });
          }
          setImporting(false);
        }
      };

      reader.readAsDataURL(file);
    } else {
      console.error(
        "Invalid file type. Please upload a CSV, XLS, or XLSX file."
      );
      setErrorData({
        errMessage:
          "Invalid file type. Please upload a CSV, XLS, or XLSX file.",
        errIcon: "/images/msp/uploading-error-icon.svg",
      });
    }
  };
  return (
    <Modal
      animation={false}
      show={show}
      dialogClassName="upload-template-modal"
      className=""
      aria-labelledby=" "
      centered
      scrollable
      onHide={() => {
        hideModal();
      }}
    >
      <Modal.Header>
        <div className="d-flex align-items-center justify-content-between w-100">
          <div className="f-16 f-500">
            {showList ? "Map Columns" : "Upload file"}
          </div>
          <div>
            <Button className="hg-cancel-btn mr-2 radius-2" onClick={hideModal}>
              Cancel
            </Button>
            <Button
              disabled={(showList ? false : true) || importing}
              className={` ${
                showList ? "hg-blue-btn" : "hg-grey-btn"
              }  radius-2 mr-2`}
              onClick={() => {
                ImportModalData();
              }}
            >
              {importing ? "Importing.." : "Import"}
            </Button>

            <img
              alt=""
              src="/images/user-detail-close.svg"
              className=" pointer"
              onClick={hideModal}
            />
          </div>
        </div>
      </Modal.Header>
      <Modal.Body className="hide-scrollbar p-0 position-relative ">
        {importing ? (
          <span className="d-inline-flex align-items-center justify-content-center h-100 w-100">
            <GreyLoader width={30} />
            Importing...
          </span>
        ) : (
          <>
            {!showList && (
              <div className="d-flex flex-column h-100 justify-content-betwee n">
                {/* Error Wrapper */}

                {errorData?.errMessage !== "" && (
                  <div
                    onClick={() => {}}
                    className="d-flex align-items-center justify-content-between bg-warning-light p-3 radius-4 m-3 mt-2"
                  >
                    <div className="d-flex align-items-center f-black f-500">
                      <img alt="" src={errorData?.errIcon} className="mr-2" />

                      {errorData?.errMessage}
                    </div>
                    <div
                      className="d-flex align-items-center f-black f-500 pointer mr-2"
                      onClick={() => {
                        setErrorData({
                          errMessage: "",
                          errIcon: "",
                        });
                      }}
                    >
                      Dismiss
                    </div>
                  </div>
                )}

                {/* Uploading file wrapper UI */}
                <div
                  style={{
                    marginTop: "2rem",
                  }}
                  className="radius-4 border-dashe d  d-flex flex-column align-items-center justify-content-center w-100 p-3 mb-3"
                >
                  {uploadProgress > 0 && uploadProgress < 100 ? (
                    <>
                      <UploadFileCircularProgress
                        progress={100 - uploadProgress}
                      />

                      <div className="f-grey my-2 w-fit-content">
                        {uploadingFileList?.[0]?.name}{" "}
                      </div>

                      {/* {uploadingFileList?.map((file) => {
                                return (
                                  <div className="f-grey my-2 w-fit-content">
                                    {file.name}{" "}
                                  </div>
                                );
                              })} */}
                      <div className="f-darkgrey my-2 w-fit-content">
                        Uploading... {uploadProgress}%
                      </div>

                      <Button
                        className="hg-cancel-btn"
                        onClick={() => {
                          uploadRef.current.click();
                        }}
                      >
                        Cancel
                      </Button>
                    </>
                  ) : (
                    <>
                      <img
                        width={20}
                        src="/images/msp/framework-upload-icon.svg"
                        alt=""
                      />
                      <div className="f-grey mt-2 w-fit-content">
                        Upload file or browse file on your computer
                      </div>
                      <div className="f-darkgrey my-2 w-fit-content">
                        Format .csv, .xls, .xlsx
                      </div>
                      <Button
                        className="hg-cancel-btn"
                        onClick={() => {
                          uploadRef.current.click();
                        }}
                      >
                        Browse file
                      </Button>
                    </>
                  )}
                </div>
                <input
                  type="file"
                  accept=".csv,.xls,.xlsx"
                  onChange={(e) => handleUpload(e)}
                  className="d-none"
                  ref={uploadRef}
                  multiple
                />

                {/* Download template Bottom banner */}

                <div className="d-flex align-items-center justify-content-between bg-grey p-3 radius-4 m-3 mt-auto">
                  <div className="d-flex align-items-center f-black f-500">
                    <img
                      alt=""
                      src="/images/msp/excel-icon.svg"
                      className="mr-2"
                    />
                    Download master template for quick import
                  </div>
                  <div
                    className="d-flex align-items-center f-blue f-500 pointer"
                    onClick={() => {
                      downloadFileByLink(
                        "https://hguksouthinboundreports.blob.core.windows.net/suppliers-document/My_Suppliers_List.xlsx",
                        "Dummy Template"
                      );
                    }}
                  >
                    <img
                      alt=""
                      src="/images/msp/download-icon.svg"
                      className="mr-2"
                    />
                    <div
                      style={{
                        paddingTop: "6px",
                      }}
                    >
                      Download template
                    </div>
                  </div>
                </div>
              </div>
            )}

            {showList && (
              <div className="m-3 bg-grey border-parent radius-8">
                <div className="table-parent-grid border-item p-2">
                  <div className="f-12 f-500 f-grey pl-2">Supplier name</div>
                  <div className="f-12 f-500 f-grey pl-2">Email</div>
                  <div className="f-12 f-500 f-grey pl-2">Website</div>
                  <div className="f-12 f-500 f-grey pl-2">Services</div>
                </div>

                {tableData?.RowData?.map((item, itemIndex) => {
                  return (
                    <div className="table-parent-grid border-item p-2">
                      <div className="f-14 f-500 f-black  pl-2">
                        {item?.Supplier_Name}
                      </div>
                      <div className="f-14 f-500 f-black pl-2">
                        {item?.Email}
                      </div>
                      <div className="f-14 f-500 f-black pl-2">
                        {item?.Website}
                      </div>
                      <div className="f-14 f-500 f-black pl-2">
                        {item?.Services}
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
          </>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default UploadTemplateModal;

const tableData = [
  {
    supplierName: "Apple Inc.",
    email: "it@apple.xom",
    website: "apple.xom",
    services:
      "Some service, Some service, Some service, Some service, Some service",
  },
  {
    supplierName: "Apple Inc.",
    email: "it@apple.xom",
    website: "apple.xom",
    services:
      "Some service, Some service, Some service, Some service, Some service",
  },
  {
    supplierName: "Apple Inc.",
    email: "it@apple.xom",
    website: "apple.xom",
    services:
      "Some service, Some service, Some service, Some service, Some service",
  },
  {
    supplierName: "Apple Inc.",
    email: "it@apple.xom",
    website: "apple.xom",
    services:
      "Some service, Some service, Some service, Some service, Some service",
  },
  {
    supplierName: "Apple Inc.",
    email: "it@apple.xom",
    website: "apple.xom",
    services:
      "Some service, Some service, Some service, Some service, Some service",
  },
  {
    supplierName: "Apple Inc.",
    email: "it@apple.xom",
    website: "apple.xom",
    services:
      "Some service, Some service, Some service, Some service, Some service",
  },
  {
    supplierName: "Apple Inc.",
    email: "it@apple.xom",
    website: "apple.xom",
    services:
      "Some service, Some service, Some service, Some service, Some service",
  },
  {
    supplierName: "Apple Inc.",
    email: "it@apple.xom",
    website: "apple.xom",
    services:
      "Some service, Some service, Some service, Some service, Some service",
  },
  {
    supplierName: "Apple Inc.",
    email: "it@apple.xom",
    website: "apple.xom",
    services:
      "Some service, Some service, Some service, Some service, Some service",
  },
  {
    supplierName: "Apple Inc.",
    email: "it@apple.xom",
    website: "apple.xom",
    services:
      "Some service, Some service, Some service, Some service, Some service",
  },
  {
    supplierName: "Apple Inc.",
    email: "it@apple.xom",
    website: "apple.xom",
    services:
      "Some service, Some service, Some service, Some service, Some service",
  },
];
