export function Design() {
  const theme =  JSON.parse(localStorage.getItem("theme"));

  if (theme) {
    const body = document.querySelectorAll('.fullHeight') 
    body.forEach(el => {
        el.style = `background:${theme.body} !important`;
    });
  }

}
