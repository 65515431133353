import React, { useState, useEffect, Fragment,   } from "react";
import "./CyberSavingsDetails.css";
import { Col, Row,   } from "react-bootstrap"; 
import "../../main.scss"; 
 
import { useAuth } from "../context/Auth";
import TechTable from "../TechTable/TechTable"; 
import { getAPIData } from "../../Utils/commonApi";
import ContentLoader from "react-content-loader";

const CyberSavingsDetails = (props) => {
  const { authTokens, setAuthTokens } = useAuth();
  const [data, setData] = useState("");
  const [totalSavings, settotalSavings] = useState(0);

  async function getDataFromAPI() {
    let result = await getAPIData("18", authTokens, {
      optionEnum1:props.clientId ?? 0
    });
    if (result.authToken !== undefined) {
      setAuthTokens(result.authToken);
      setData();
      result = await getAPIData("18", result.authToken, {
        optionEnum1:props.clientId ?? 0
      });
    } else {
      settotalSavings(result.totalFormatted);

      if (result.dataRows.length > 0) {
        var header = [
          { text: "Technology", width: "20%" },
          { text: "Date" },
          { text: "Event" },
          { text: "Saving", width: "15%" },
        ];
        console.log(result);

        var outerList = [];
        result.dataRows.map((element) => {
          outerList.push([
            { text: element.Technology },
            { text: element.SavingDate },
            { text: element.Description },
            { text: element.SavingFormatted },
          ]);
        });

        setData({
          0: {
            header: header,
          },
          1: {
            data: outerList,
          },
        });
      }
    }
  }

  useEffect(() => {
    getDataFromAPI();
  }, []);

 
  if (data != "") {
    return (
      <Fragment>
        <div className="csd_background fullHeight">
          <Row>
            <Col xs={12} style={{ padding: "1em 2em 0em 2em" }}>
              <TechTable
                bgColor={"#40D88E"}
                hdTextColor={"white"}
                data={data}
              />
            </Col>
          </Row>
          <Row style={{ paddingTop: "1em" }}>
            <Col xs={12} style={{ padding: "0 2em 2em 2em" }}>
              <Col className="bottomCont">
                <div
                  style={{ paddingTop: "1.5em", width: "21%" }}
                  className="float-right text-center"
                >
                  <span className="totalFont">Total: </span>
                  <span>{totalSavings}</span>
                </div>
              </Col>
            </Col>
          </Row>
        </div>
      </Fragment>
    );
  } else {
    return (
      <div className="collapContainer collapContainer50">
        <ContentLoader
          speed={2}
          width={900}
          height={500}
          viewBox="0 0 900 500"
          backgroundColor="#f3f3f3"
          foregroundColor="#ecebeb"
          {...props}
        >
          <rect x="13" y="16" rx="0" ry="0" width="900" height="52" />
          <rect x="13" y="90" rx="0" ry="0" width="900" height="52" />
          <rect x="13" y="164" rx="0" ry="0" width="900" height="52" />
          <rect x="13" y="238" rx="0" ry="0" width="900" height="52" />
          <rect x="13" y="312" rx="0" ry="0" width="900" height="52" />
        </ContentLoader>
      </div>
    );
  }
};

export default CyberSavingsDetails;
