import React from 'react'
import "./threeDots.scss"

const DotsLoader = ({ animate }) => {
  return (
    <div className="dots-loader">
      <div
        className={`dot-inside    ${animate ? "dots-animation" : ""}   `}
      ></div>
      <div
        className={`dot-inside    ${animate ? "dots-animation" : ""}   `}
      ></div>
      <div
        className={`dot-inside    ${animate ? "dots-animation" : ""}   `}
      ></div>
    </div>
  );
};

export default DotsLoader