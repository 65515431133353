import React, { useState, useEffect, useRef } from "react";
import "./draggableModal.scss";
import Draggable from "react-draggable";

import moment from "moment";
import { Row, Dropdown, Col, Badge, Button, Spinner } from "react-bootstrap";
import DatePicker from "../../Theme/datepicker";
import ImageWithInitials from "../../../Utils/ImageWithInitials";
import { useSelector } from "react-redux";
import { useAuth } from "../../context/Auth";
import { CallToast } from "../modals/CustomToast";
import {
  postRequest,
  getScenarioPacks as deleteStatusApi,
  getAPIData,
} from "../../../Utils/commonApi";
import { setactioncentredata } from "../../../redux/actionCentre/actions";
import { useDispatch } from "react-redux";
import { DeleteModal } from "../../DeleteModal/DeleteModal";
import PostEditor from "../../ActionCenter/PostEditor";
import { auditUpdate } from "../../MSPv2/secOps/audit/AuditHeader";
import HighgroundDatePicker from "../../test/TestComponent";
const CreateTaskModal = () => {
  const dispatch = useDispatch();
  const taskDetailRef = useRef();
  const [statusDropdownValue, setStatusDropdownValue] = useState("");
  const [changesHappen, setChangesHappen] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteMessage, setDeleteMessage] = useState("");
  const [deleteType, setDeleteType] = useState("status"); //task,section,status
  const [deleteID, setDeleteID] = useState("");
  const urlPath = window.location.pathname;
  const refreshCreateTaskModalModule = useSelector(
    (state) => state.actioncentre.refreshCreateTaskModalModule
  );
  const [mentionList, setMentionList] = useState([]);
  const userDetails = useSelector((state) => state.user?.userDetail);
  const getMentionList = () => {
    getAPIData("251", authTokens).then((response) => {
      if (response?.mr?.Success) {
        if (response?.TaskOwnerList?.length > 0) {
          let mentions = response?.TaskOwnerList.map(
            ({ OwnerName: name, ...rest }) => ({
              name,
              ...rest,
            })
          );
          setMentionList(mentions);
        }
      }
    });
  };

  const accountVerified =
    !userDetails?.AccountVerified &&
    urlPath.split("/").length > 1 &&
    urlPath.split("/")[2] == "dashboard";

  const hideDeleteModal = () => {
    setShowDeleteModal(false);
  };

  function DeleteStatusFunc(message, statusID) {
    setDeleteMessage(message);
    setDeleteID(statusID);
    setShowDeleteModal(true);
    setDeleteType("status");
  }

  // To delete status
  const DeleteStatus = (statusID) => {
    return new Promise((resolve, reject) => {
      deleteStatusApi(authTokens, 247, statusID).then((response) => {
        if (response?.Success) {
          CallToast("Status deleted successfully", false, false, "v2style");
          // RefreshTaskListData();
          setChangesHappen(!changesHappen);
        } else {
          CallToast("Something went wrong", false, false, "v2style");
        }
      });
    });
  };

  useEffect(() => {
    if (refreshCreateTaskModalModule) {
      getMentionList();
      postRequest(authTokens, "GetActionTaskList", {
        ObjectId: 0,
        TaskNumber: 0,
        TaskSort: window.localStorage.getItem("TaskSort") || "Title",
        TaskSortOrder: window.localStorage.getItem("TaskSortOrder") || 0,
        accessKey: JSON.parse(localStorage.getItem("user_MessageObj")),
      }).then((response) => {
        if (response?.mr?.Success) {
          dispatch(setactioncentredata(response));
          setSelectedStatus(
            response?.StatusList?.filter(
              (item) => item.DDLText.toLowerCase() === "new"
            )[0] || {}
          );
        }
      });
    }
  }, [refreshCreateTaskModalModule, changesHappen]);

  useEffect(() => {
    if (urlPath.split("/").length > 1 && urlPath.split("/")[2] == "dashboard") {
      setSelectedModule({
        ObjId: 0,
        DDLId: 13345,
        DDLText: "Dashboard",
        DDLSort: 0,
        DDLValue: 0,
        DDLColour: "#0C0D11",
        DDLImage: "dashboardIcon.svg",
      });
    }

    if (
      urlPath.split("/").length > 1 &&
      urlPath.split("/")[1] == "trend_data"
    ) {
      setSelectedModule({
        ObjId: 0,
        DDLId: 13346,
        DDLText: "Cyber Trend Data",
        DDLSort: 0,
        DDLValue: 0,
        DDLColour: "#0C0D11",
        DDLImage: "cyberTrendDashboardIcon.svg",
      });
    }

    if (
      urlPath.split("/").length > 1 &&
      urlPath.split("/")[1] == "technical_drilldown"
    ) {
      setSelectedModule({
        ObjId: 0,
        DDLId: 13354,
        DDLText: "Technical DrillDown",
        DDLSort: 0,
        DDLValue: 0,
        DDLColour: "#0C0D11",
        DDLImage: "technicalDrillDownDashboardIcon.svg",
      });
    }

    if (
      urlPath.split("/").length > 1 &&
      urlPath.split("/")[1] == "Compliance_Manager"
    ) {
      setSelectedModule({
        ObjId: 0,
        DDLId: 13355,
        DDLText: "Compliance Manager",
        DDLSort: 0,
        DDLValue: 0,
        DDLColour: "#0C0D11",
        DDLImage: "complianceDashboardIcon.svg",
      });
    }

    if (
      urlPath.split("/").length > 1 &&
      urlPath.split("/")[1] == "pro_services"
    ) {
      setSelectedModule({
        ObjId: 0,
        DDLId: 13356,
        DDLText: "Pro Services",
        DDLSort: 0,
        DDLValue: 0,
        DDLColour: "#0C0D11",
        DDLImage: "proServiceDashboardIcon.svg",
      });
    }

    if (
      urlPath.split("/").length > 1 &&
      urlPath.split("/")[1] == "integrations"
    ) {
      setSelectedModule({
        ObjId: 0,
        DDLId: 13357,
        DDLText: "Integrations",
        DDLSort: 0,
        DDLValue: 0,
        DDLColour: "#0C0D11",
        DDLImage: "intergrationsDashboard.svg",
      });
    }

    if (
      urlPath.split("/").length > 1 &&
      urlPath.split("/")[1] == "system_settings"
    ) {
      setSelectedModule({
        ObjId: 0,
        DDLId: 13358,
        DDLText: "System Settings",
        DDLSort: 0,
        DDLValue: 0,
        DDLColour: "#0C0D11",
        DDLImage: "settingsDashboardIcon.svg",
      });
    }

    if (
      urlPath.split("/").length > 1 &&
      (urlPath.split("/")[1] == "managed_security" ||
        urlPath.split("/")[1] == "price_calculator")
    ) {
      setSelectedModule({
        ObjId: 0,
        DDLId: 13360,
        DDLText: "Managed Security",
        DDLSort: 0,
        DDLValue: 0,
        DDLColour: "#0C0D11",
        DDLImage: "calculator.svg",
      });
    }
    if (
      urlPath.split("/").length > 1 &&
      urlPath.split("/")[1] == "attack_surface"
    ) {
      setSelectedModule({
        ObjId: 0,
        DDLId: 14256,
        DDLText: "Attack Surface",
        DDLSort: 10,
        DDLValue: 0,
        DDLColour: "#0C0D11",
        DDLImage: "attackSurfaceDashboardIcon.svg",
        DDLTextColour: null,
        DDLAllowUserEdit: null,
      });
    }
  }, [refreshCreateTaskModalModule]);

  const { authTokens } = useAuth();
  const [description, setDescription] = useState("");
  const sectionList =
    useSelector((state) => state.actioncentre?.SectionList) || [];
  const ownerList =
    useSelector((state) => state.actioncentre?.TaskOwnerList) || [];
  const moduleList =
    useSelector((state) => state.actioncentre?.ModuleList) || [];
  const statusList =
    useSelector((state) => state.actioncentre?.StatusList) || [];

  const [selectedOwner, setSelectedOwner] = useState({});
  const [selectedStatus, setSelectedStatus] = useState({
    // ObjId: 3205543,
    // DDLId: 0,
    // DDLText: "New",
    // DDLSort: 4,
    // DDLValue: 0,
    // DDLColour: "",
    // DDLImage: "NA",
    // DDLTextColour: null,
  });
  const [selectedModule, setSelectedModule] = useState({});
  const [selectedSection, setSelectedSection] = useState({});
  const [nameEdit, setnameEdit] = useState(false);
  const [taskName, setTaskName] = useState("Untitled");
  const [taskDate, setTaskDate] = useState("");
  const [New, setNew] = useState(true);
  const [creatingNewTask, setCreatingNewTask] = useState(false);
  const RefreshModal = () => {
    setTaskName("Untitled");
    setNew(true);
    setTaskDate("");
    setSelectedOwner({});
    setSelectedStatus({
      // ObjId: 0,
      // DDLId: 13362,
      // DDLText: "New",
      // DDLSort: 0,
      // DDLValue: 3,
      // DDLColour: "#B6B6BF",
      // DDLImage: "NA",
    });
    setSelectedModule({});
    setSelectedSection({});
    setnameEdit(false);
    setDescription("");
    setCreatingNewTask(false);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      setnameEdit(false);
    }
  };

  const NewTaskData = {
    ObjectId: 0,
    TaskName: taskName || "Untitled",
    Description: description || "description",
    StatusId: selectedStatus?.ObjId || 0,
    ModuleId: selectedModule?.DDLId || 13363,
    SectionId: selectedSection?.ObjId || 0,
    DueDate: taskDate,
    Owner: selectedOwner?.OwnerUserName,
    AutoGenerated: false,
    accessKey: JSON.parse(localStorage.getItem("user_MessageObj")),
  };
  // console.log(selectedStatus);
  const CreateTask = () => {
    if (Object.keys(selectedOwner).length == 0) {
      CallToast("Please select an owner", false, false, "v2style");
      return;
    }
    if (Object.keys(selectedStatus).length == 0) {
      CallToast("Please select a status", false, false, "v2style");
      return;
    }
    if (taskDate == "") {
      CallToast("Please select a due date", false, false, "v2style");
      return;
    }

    // if (Object.keys(selectedSection).length == 0) {
    //   CallToast("Please select a section", false, false, "v2style");
    //   return;
    // }

    setCreatingNewTask(true);
    postRequest(authTokens, "CreateActionTask", NewTaskData)
      .then((response) => {
        if (response?.Success) {
          RefreshModal();
          taskDetailRef.current.reset();
          setCreatingNewTask(false);
          dispatch(
            setactioncentredata({
              refreshCreateTaskModalModule: false,
              newTaskCreatedInModal: true,
            })
          );

          setTimeout(() => {
            dispatch(
              setactioncentredata({
                newTaskCreatedInModal: false,
              })
            );
          }, 1000);
          let drag = document.getElementById("create-task-modal");
          drag.style.display = "none";
          CallToast("Task Created Successfully", false, false, "v2style");
        } else {
          setCreatingNewTask(false);
          CallToast("Something went wrong", false, false, "v2style");
        }
      })
      .catch((err) => {
        console.log(err);
        setCreatingNewTask(false);
        CallToast("Something went wrong", false, false, "v2style");
      });
  };

  const Colors = [
    { color: "#616778", name: "Gray" },
    { color: "#FFAC2C", name: "Yellow" },
    { color: "#DF6844", name: "Orange" },
    { color: "#40D88E", name: "Green" },
    { color: "#008FD4", name: "Blue" },
    { color: "#5244B3", name: "Purple" },
  ];

  const [createStatus, setCreateStatus] = useState(false);
  const [newStatus, setNewStatus] = useState("");

  const [editStatusId, setEditStatusId] = useState("");
  const NewStatusData = {
    // ObjectId: 3202495, // selectedTaskId || 0, // 3201454,
    StatusName: newStatus,
    TextColour: "Blue",
    Colour: "Blue",
    accessKey: JSON.parse(localStorage.getItem("user_MessageObj")),
  };
  const CreateUpdateStatus = (status_ObjId, color = "Blue") => {
    postRequest(authTokens, "CreateActionCustomStatus", {
      ...NewStatusData,
      ObjectId: status_ObjId,
      StatusName: status_ObjId == 0 ? newStatus : statusDropdownValue,
      TextColour: color,
      Colour: color,
    }).then((response) => {
      if (response?.Success) {
        setNewStatus("");
        setChangesHappen(!changesHappen);
        {
          status_ObjId == 0 &&
            CallToast("Status created successfully", false, false, "v2style");
        }
      } else {
        setNewStatus("");
        CallToast("Something went wrong", false, false, "v2style");
      }
    });
  };

  // useEffect(() => {
  //   setSelectedStatus(
  //     statusList.filter((item) => item.DDLText.toLowerCase() === "new")[0] || {}
  //   );
  // }, [statusList]);

  return (
    <Draggable defaultPosition={{ x: 0, y: 0 }} handle=".dragger-icon">
      <div
        id="create-task-modal"
        className={`create-task-modal ${accountVerified ? "top13" : "top8"} `}
      >
        <div
          className="dragger-icon"
          style={{
            position: "absolute",
            background: "transparent",
            width: "77%",
            height: "30px",
          }}
        ></div>
        <div className="d-flex align-items-center my-2">
          <div
            className="d-flex justify-content-center my-3 "
            style={{ flexGrow: 1 }}
          >
            <img src="/images/settings-v2/six-dots.svg" className="" />
          </div>
          <img
            src="/images/user-detail-close.svg"
            alt=""
            className="mr-2"
            style={{ width: "15px", cursor: "pointer" }}
            onClick={() => {
              RefreshModal();
              dispatch(
                setactioncentredata({
                  refreshCreateTaskModalModule: false,
                })
              );
              const drag = document.getElementById("create-task-modal");
              drag.style.display = "none";
            }}
          />
        </div>
        <div className="header">
          {nameEdit ? (
            <input
              onKeyDown={handleKeyDown}
              autoFocus
              className="input-header"
              value={taskName}
              onChange={(e) => {
                setTaskName(e.target.value);
              }}
              onBlur={() => {
                setnameEdit(false);
                if (taskName == "") {
                  setTaskName("Untitled");
                }
              }}
            />
          ) : (
            <div
              className={`${New ? "new-name" : "task-name"}`}
              onClick={() => {
                setnameEdit(true);
                setNew(false);
              }}
            >
              {taskName}
            </div>
          )}
          <div className="d-flex align-items-center">
            <div className="mr-2 border-right d-none">
              <img
                alt=""
                src="/images/settings-v2/delete-bin.svg"
                className="delete-icon pointer"
                onClick={RefreshModal}
              />
            </div>
            <Button
              className="submit-btn"
              disabled={creatingNewTask}
              onClick={() => {
                CreateTask();
              }}
            >
              {creatingNewTask ? (
                <Spinner
                  size="sm"
                  // className="mr8px- ml-2 my-2"
                  animation="border"
                  variant="light"
                />
              ) : (
                <img src="/images/settings-v2/yes-tick-task.svg" />
              )}
            </Button>
          </div>
        </div>

        <hr className="divider-task" />

        <Row>
          {/* Owner dropdown */}
          <Col xs={4} className="d-flex align-items-center">
            Task Owner
          </Col>
          <Col xs={8}>
            <Dropdown className="input_custom_dropdown">
              <Dropdown.Toggle
                className="p-0 position-relative"
                style={{ zIndex: 1 }}
              >
                {Object.keys(selectedOwner).length > 0 ? (
                  <div className="dropdown_input drop_border">
                    <Badge className="custombadge d-flex align-items-center">
                      {selectedOwner?.OwnerShowInitals === true ? (
                        <ImageWithInitials
                          initials={selectedOwner?.OwnerInitials}
                          width={20}
                          background={"purple"}
                        />
                      ) : selectedOwner?.OwnerLogo == null ? (
                        <img src="/images/settings-v2/highground-dp.svg" />
                      ) : (
                        <img
                          src={selectedOwner?.OwnerLogo}
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null; // prevents looping
                            currentTarget.src =
                              "/images/settings-v2/highground-dp.svg";
                          }}
                          style={{
                            width: "20px",
                            height: "20px",
                            borderRadius: "10px",
                            objectFit: "contain",
                          }}
                        />
                      )}
                      <div className="ml-1">{selectedOwner?.OwnerName}</div>
                      <img
                        src="/images/actioncenter/cross.svg"
                        className="crossimg"
                        onClick={() => setSelectedOwner({})}
                      />
                    </Badge>
                  </div>
                ) : (
                  <input
                    type="text"
                    className="dropdown_input"
                    value=""
                    placeholder="Select"
                  />
                )}
              </Dropdown.Toggle>

              <Dropdown.Menu
                className="BillingInfo"
                style={{ height: "150px" }}
              >
                {ownerList?.map((owner, i) => (
                  <div
                    className="child_drop d-flex align-items-center"
                    key={i}
                    onClick={(e) => {
                      e.target.closest(".input_custom_dropdown").click();
                      setSelectedOwner(owner);
                    }}
                  >
                    {owner?.OwnerShowInitals === true ? (
                      <ImageWithInitials
                        initials={owner?.OwnerInitials}
                        width={20}
                        background={"purple"}
                      />
                    ) : owner?.OwnerLogo == null ? (
                      <img src="/images/settings-v2/highground-dp.svg" />
                    ) : (
                      <img
                        src={owner?.OwnerLogo}
                        onError={({ currentTarget }) => {
                          currentTarget.onerror = null; // prevents looping
                          currentTarget.src =
                            "/images/settings-v2/highground-dp.svg";
                        }}
                        style={{
                          width: "20px",
                          height: "20px",
                          borderRadius: "10px",
                          objectFit: "contain",
                        }}
                      />
                    )}
                    &nbsp;
                    <div>{owner?.OwnerName}</div>
                  </div>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </Col>
          {/* status */}
          <Col xs={4} className="d-flex align-items-center mt-2">
            Status
          </Col>
          <Col xs={8} className="mt-2">
            <Dropdown className="input_custom_dropdown">
              <Dropdown.Toggle
                className="p-0 position-relative"
                style={{ zIndex: 1 }}
              >
                {Object.keys(selectedStatus).length > 0 ? (
                  <div className="dropdown_input drop_border">
                    <div
                      className={`${true}  tableStatus`}
                      style={{
                        backgroundColor: selectedStatus?.DDLColour
                          ? `${selectedStatus?.DDLColour}40`
                          : "",
                        color: `${selectedStatus?.DDLColour}`,
                      }}
                    >
                      <div
                        className={`tablecircle ${true}`}
                        style={{
                          backgroundColor: `${selectedStatus?.DDLColour}`,
                        }}
                      />
                      {selectedStatus?.DDLText}
                    </div>
                  </div>
                ) : (
                  <input
                    type="text"
                    className="dropdown_input"
                    value=""
                    placeholder="Select"
                  />
                )}
              </Dropdown.Toggle>

              <Dropdown.Menu
                className="BillingInfo"
                style={{ height: "150px" }}
              >
                <div className="tableStatus greyclr2 pl-3">
                  Select or create status:
                </div>
                <div className="tableStatus greyclr2 pl-3 d-block mt-2 crsrpoint">
                  {createStatus ? (
                    <div className="navtabinput">
                      <input
                        type="text"
                        value={newStatus}
                        className="w-100"
                        onBlur={() => {
                          setCreateStatus(false);
                          setNewStatus("");
                        }}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            if (newStatus == "") return;
                            let AlreadyExistTask = statusList.some(
                              (item) =>
                                item.DDLText.toLowerCase() ==
                                newStatus.toLowerCase()
                            );
                            if (AlreadyExistTask) {
                              CallToast(
                                "Status name already exist",
                                false,
                                false,
                                "v2style"
                              );
                              return;
                            }

                            CreateUpdateStatus(0);
                            setCreateStatus(false);
                          }
                        }}
                        onChange={(e) => setNewStatus(e.target.value)}
                        autoFocus
                      />
                    </div>
                  ) : (
                    <div
                      // className="dummy-click"
                      onClick={() => {
                        setCreateStatus(true);
                      }}
                    >
                      <img
                        style={{ top: "-1px", left: "3px" }}
                        src="/images/actioncenter/plusgrey.svg"
                        height="9"
                        className="mr-2 position-relative"
                      />
                      Create
                    </div>
                  )}
                </div>
                {statusList?.length > 0 &&
                  statusList?.map((status, i) => {
                    return (
                      <>
                        {editStatusId == status.ObjId ? (
                          <div className="navtabinput">
                            <input
                              type="text"
                              className="w-100"
                              value={statusDropdownValue}
                              onChange={(e) => {
                                let updated_status_list = [...statusList];

                                let Index = statusList.findIndex(
                                  (item) => item.ObjId === status.ObjId
                                );
                                updated_status_list[Index].DDLText =
                                  e.target.value;
                                // setStatusList(updated_status_list);

                                setStatusDropdownValue(e.target.value);
                              }}
                              onBlur={() => {
                                if (statusDropdownValue.length == 0)
                                  return false;
                                if (selectedStatus.ObjId === status.ObjId) {
                                  setSelectedStatus({
                                    ...selectedStatus,
                                    DDLText: statusDropdownValue,
                                  });
                                }
                                CreateUpdateStatus(status?.ObjId);
                                setEditStatusId("");
                              }}
                              onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                  if (statusDropdownValue.length == 0)
                                    return false;
                                  if (selectedStatus.ObjId === status.ObjId) {
                                    setSelectedStatus({
                                      ...selectedStatus,
                                      DDLText: statusDropdownValue,
                                    });
                                  }

                                  CreateUpdateStatus(status?.ObjId);
                                  setEditStatusId("");
                                }
                              }}
                              autoFocus
                            />
                          </div>
                        ) : (
                          <div
                            className="child_drop d-flex justify-content-between align-items-center less-padding"
                            key={i}
                            onClick={(e) => {
                              e.target
                                .closest(".input_custom_dropdown")
                                .click();

                              setSelectedStatus(status);
                            }}
                          >
                            <div>
                              <div
                                className="tableStatus"
                                style={{
                                  backgroundColor: `${status?.DDLColour}40`,
                                  color: status?.DDLColour,
                                }}
                              >
                                <div
                                  className="tablecircle"
                                  style={{
                                    backgroundColor: status?.DDLColour,
                                  }}
                                />
                                {status?.DDLText}
                              </div>
                            </div>
                            {/* drop down div for status editing--------------------------------------------------------------------------- */}
                            <div
                              className={`color-dropdown hidden-dropdown-
                              
                              ${status?.DDLAllowUserEdit ? "" : "d-none"} 
                              
                              `}
                              onClick={(e) => e.stopPropagation()}
                            >
                              <Dropdown>
                                <Dropdown.Toggle>
                                  <span className="hidden-dropdown">
                                    <img src="/images/settings-v2/horizontal-dots.svg" />
                                  </span>
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                  <div className="choose-color">
                                    Choose Color
                                  </div>
                                  {Colors.map((item) => {
                                    return (
                                      <div
                                        className="d-flex align-items-center my-1 color-wrapper"
                                        onClick={(e) => {
                                          // CreateUpdateStatus(
                                          //   status?.ObjId,
                                          //   item.color
                                          // );

                                          postRequest(
                                            authTokens,
                                            "CreateActionCustomStatus",
                                            {
                                              ObjectId: status?.ObjId,
                                              StatusName: status?.DDLText,
                                              // TextColour: color,
                                              Colour: item.color,
                                              accessKey: JSON.parse(
                                                localStorage.getItem(
                                                  "user_MessageObj"
                                                )
                                              ),
                                            }
                                          ).then((response) => {
                                            if (response?.Success) {
                                              setChangesHappen(!changesHappen);
                                              CallToast(
                                                "Status color updated successfully",
                                                false,
                                                false,
                                                "v2style"
                                              );
                                            } else {
                                              CallToast(
                                                "Something went wrong",
                                                false,
                                                false,
                                                "v2style"
                                              );
                                            }
                                          });

                                          e.target
                                            .closest(".color-dropdown")
                                            .click();
                                        }}
                                      >
                                        <div
                                          className="color-circle"
                                          style={{
                                            background: `${item.color}`,
                                          }}
                                        ></div>
                                        <div className="d-flex justify-content-center align-items-center color-text">
                                          {" "}
                                          {item.name}{" "}
                                        </div>
                                      </div>
                                    );
                                  })}
                                  <hr
                                    className="color-dividerx"
                                    color="#616778"
                                  />
                                  <div
                                    className="color-text my-2 color-wrapper"
                                    onClick={(e) => {
                                      setEditStatusId(status?.ObjId);
                                      e.target
                                        .closest(".color-dropdown")
                                        .click();

                                      let Index = statusList.findIndex(
                                        (item) => item.ObjId === status.ObjId
                                      );

                                      setStatusDropdownValue(
                                        statusList[Index].DDLText
                                      );
                                    }}
                                  >
                                    {" "}
                                    <img
                                      src="/images/settings-v2/edit-icon.svg"
                                      className="mr-2"
                                      // style={{ filter: "invert(1)" }}
                                    />{" "}
                                    Rename
                                  </div>
                                  <div
                                    className="color-text my-2 color-wrapper"
                                    onClick={(e) => {
                                      e.target
                                        .closest(".color-dropdown")
                                        .click();
                                      DeleteStatusFunc(
                                        "Do you want to delete status?",
                                        status.ObjId
                                      );
                                      return;
                                    }}
                                  >
                                    <img
                                      src="/images/settings-v2/bin.svg"
                                      className="mr-2 mb-1"
                                      // style={{ filter: "invert(1)" }}
                                    />
                                    Delete
                                  </div>
                                </Dropdown.Menu>
                              </Dropdown>
                            </div>
                          </div>
                        )}
                      </>
                    );
                  })}
              </Dropdown.Menu>
            </Dropdown>
          </Col>
          {/* date */}
          <Col xs={4} className="d-flex align-items-center mt-2">
            Due Date
          </Col>
          <Col xs={8} className="mt-2 datepicker_cutom">
            {/* <DatePicker
              singleDatePicker={true}
              startDate={moment(new Date()).format("MM/DD/YYYY")}
              placeholder="Choose due date"
              onChange={(e) => {
                setTaskDate(e);
              }}
            /> */}

            <HighgroundDatePicker
              selected={taskDate ?? ''}
              // icon={"/images/attack-surface/date-icon.svg"}
              onChange={(e) => {
                setTaskDate(e);
              }}
              DropdownClassName={'dateForTasksModal'}
              id={"ggffffffff"}
              // isError={row?.error}
            />

            {/* {taskDate && (
              <DatePicker
                singleDatePicker={true}
                startDate={`${moment(taskDate, "DD/MM/YYYY").format(
                  "MM/DD/YYYY"
                )}`}
                // startDate={selectedTaskDate}
                placeholder={taskDate}
                onChange={(e) => {
                  setTaskDate(e);
                }}
                value
              />
            )}

            {taskDate == "" && (
              <DatePicker
                singleDatePicker={true}
                startDate={moment(new Date()).format("MM/DD/YYYY")}
                placeholder="Choose due date"
                onChange={(e) => {
                  setTaskDate(e);
                }}
              />
            )} */}
          </Col>
          {/* Section */}
          <Col xs={4} className="d-flex align-items-center mt-2">
            Section
          </Col>
          <Col
            xs={8}
            className={`mt-2       ${
              sectionList?.length ? "" : "pointer-events-none"
            }    `}
          >
            <Dropdown className="input_custom_dropdown">
              <Dropdown.Toggle
                className="p-0 position-relative"
                style={{ zIndex: 1 }}
              >
                {Object.keys(selectedSection).length > 0 ? (
                  <div className="dropdown_input drop_border">
                    <Badge className="custombadge pl-2">
                      {selectedSection?.DDLText}
                      <img
                        src="/images/actioncenter/cross.svg"
                        className="crossimg"
                        onClick={() => setSelectedSection({})}
                      />
                    </Badge>
                  </div>
                ) : (
                  <input
                    type="text"
                    className="dropdown_input"
                    value=""
                    placeholder={` ${
                      sectionList?.length
                        ? "Add section"
                        : "No section created yet"
                    }    `}
                  />
                )}
              </Dropdown.Toggle>

              <Dropdown.Menu
                className="BillingInfo"
                style={{ height: "150px" }}
              >
                {sectionList?.map((section, i) => (
                  <div
                    className="child_drop"
                    key={i}
                    onClick={(e) => {
                      e.target.closest(".input_custom_dropdown").click();
                      setSelectedSection(section);
                      // UpdateTask({ SectionId: section?.ObjId || 0 });
                    }}
                  >
                    {section?.DDLText}
                  </div>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </Col>
          {/* module */}
          <Col xs={4} className="d-flex align-items-center mt-2">
            Module
          </Col>
          <Col xs={8} className="mt-2">
            <Dropdown className="input_custom_dropdown">
              <Dropdown.Toggle
                className="p-0 position-relative"
                style={{ zIndex: 1 }}
              >
                {Object.keys(selectedModule).length > 0 ? (
                  <div className="dropdown_input drop_border">
                    <Badge
                      className="custombadge2"
                      ref={(el) => {
                        if (el) {
                          el.style.setProperty(
                            "color",
                            `${selectedModule?.DDLColour}`,
                            "important"
                          );
                        }
                      }}
                    >
                      <img
                        src={`/images/actioncentrelive/${selectedModule?.DDLImage}`}
                        style={{ filter: "invert(0.4)" }}
                        className="mr-2"
                      />
                      {selectedModule?.DDLText}
                      <img
                        src="/images/actioncenter/cross.svg"
                        className="d-none" //         "crossimg float-right mt-2"
                        onClick={() => setSelectedModule({})}
                      />
                    </Badge>
                  </div>
                ) : (
                  <input
                    type="text"
                    className="dropdown_input"
                    value={""}
                    placeholder="Choose module"
                    onChange={(e) => {
                      // setModuleSearch(e.target.value);
                    }}
                  />
                )}
              </Dropdown.Toggle>

              <Dropdown.Menu
                className="BillingInfo"
                style={{ height: "150px" }}
              >
                {moduleList
                  .filter(
                    (el) => true
                    // el?.DDLText.toLowerCase().includes(
                    //   moduleSearch.toLowerCase()
                    // )
                  )
                  .map((module, i) => (
                    <div
                      className="child_drop"
                      key={i}
                      ref={(el) => {
                        if (el) {
                          el.style.setProperty(
                            "color",
                            `${module?.DDLColour}`,
                            "important"
                          );
                        }
                      }}
                      onClick={(e) => {
                        e.target.closest(".input_custom_dropdown").click();
                        // selectValues(val, "moduledropdown");
                        setSelectedModule(module);
                        // UpdateTask({ ModuleId: module?.DDLId || 0 });
                        // setModuleSearch("");
                      }}
                    >
                      <img
                        src={`/images/actioncentrelive/${module?.DDLImage}`}
                        className="mr-2"
                        style={{ filter: "invert(0.4)" }}
                      />
                      &nbsp;
                      {module?.DDLText}
                    </div>
                  ))}
              </Dropdown.Menu>
            </Dropdown>
          </Col>
          <Col xs={12}>
            {/* <textarea
              rows="5"
              className="task-description"
              placeholder="Add description to this task"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              autoFocus
            /> */}
            <div
              className="w-100 mt-2 task-desription-part mb-2"
              style={{
                minHeight: "4rem",
              }}
            >
              <PostEditor
                className="PostEditor__input"
                placeholder={"Add a description"}
                getValue={(e) => {
                  setDescription(JSON.stringify(e));
                }}
                mentions={mentionList}
                ref={taskDetailRef}
              />
            </div>
          </Col>{" "}
        </Row>
        <DeleteModal
          show={showDeleteModal}
          hideModal={hideDeleteModal}
          deleteMessage={deleteMessage}
          deleteTask={() => {}}
          // RefreshTaskListData={RefreshTaskListData}
          // setStateVal={setStateVal}
          deleteType={deleteType}
          deleteSection={() => {}}
          deleteStatus={() => DeleteStatus(deleteID)}
        />
      </div>
    </Draggable>
  );
};

export default CreateTaskModal;
