import { COMPANY_DETAIL } from "./constants";

/**
 * Increments in calculator value
 *
 * @param {Object} data
 */

export const setcompany = (data) => {
  return { type: COMPANY_DETAIL, data };
};
