// import { fromJS } from "immutable";

import { CALCULATOR_INCREMENT } from "./constants";

export const initState = {
  value: 0,
};

const calculatorReducer = (state = initState, action) => {
  switch (action.type) {
    case CALCULATOR_INCREMENT:
      return { ...state, value: action.data } 
      // state.set("value", action.data);

    default:
      return state;
  }
};

export default calculatorReducer;
