import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  EditorState,
  getDefaultKeyBinding,
  RichUtils,
  // convertFromHTML,
  // ContentState,
  convertFromRaw,
  convertToRaw,
} from "draft-js";
import Editor from "draft-js-plugins-editor";
import createMentionPlugin, {
  defaultSuggestionsFilter,
} from "draft-js-mention-plugin";
import { stateToHTML } from "draft-js-export-html";
import "draft-js-mention-plugin/lib/plugin.css";
import "draft-js/dist/Draft.css";
import get from "lodash/get";

import MentionComponent from "./mentionComponent";
import EntryComponent from "./EntryComponent";
// import htmlToDraft from "html-to-draftjs";
import { TrimString } from "../../../Utils/StringTrimmer";

const cmdState = {
  handled: "handled",
  notHandled: "not-handled",
};

class PostEditor extends Component {
  constructor(props) {
    super(props);
    this.mentionPlugin = createMentionPlugin({
      entityMutability: "IMMUTABLE",
      mentionComponent: MentionComponent, // since we want to remove the entire name at once.
    });
    this.state = {
      editorState: EditorState.createEmpty(),
      suggestions: this.props.mentions,
    };
  }

  setValue = (sampleMarkup) => {
    // const blocksFromHTML = convertFromHTML(sampleMarkup);
    // const state = ContentState.createFromBlockArray(
    //   blocksFromHTML.contentBlocks,
    //   blocksFromHTML.entityMap
    // );
    // console.log(state);

    this.setState({
      editorState: EditorState.createWithContent(convertFromRaw(sampleMarkup)), //: EditorState.createWithContent(state),
    });
  };

  reset = () => {
    this.setState({
      editorState: EditorState.createEmpty(),
    });
  };

  onChange = (editorState) => {
    this.setState({
      editorState,
    });
    this.props.getValue(convertToRaw(editorState.getCurrentContent()));
  };

  onSearchChange = ({ value }) => {
    this.setState({
      suggestions: defaultSuggestionsFilter(value, this.props.mentions),
    });
  };

  keyBindingFn = (e) => {
    // retrun custom commands on keyPress if required
    return getDefaultKeyBinding(e);
  };

  toHtml = () => {
    const contentState = this.state.editorState.getCurrentContent();

    const options = {
      // eslint-disable-next-line consistent-return
      entityStyleFn: (entity) => {
        const entityType = entity.get("type").toLowerCase();
        if (entityType === "mention") {
          const data = entity.getData();

          return {
            element: "span",
            attributes: {
              "data-mention-id": get(data, "mention.OwnerUserName"),
              class: "mention_class position-relative",
            },
            style: {
              // Put styles here...
            },
          };
        }
      },
    };
    return stateToHTML(contentState, options);
  };

  handleKeyCommand = (command) => {
    // handle custom command here;

    const newState = RichUtils.handleKeyCommand(
      this.state.editorState,
      command
    );
    if (newState) {
      this.onChange(newState);
      return cmdState.handled;
    }
    return cmdState.notHandled;
  };

  render() {
    const { MentionSuggestions } = this.mentionPlugin;
    const plugins = [this.mentionPlugin];
    const { className, style, placeholder } = this.props;

    return (
      <div className={`editor ${className}`} style={style}>
        <Editor
          editorState={this.state.editorState}
          onChange={this.onChange}
          plugins={plugins}
          // keyBindingFn={this.keyBindingFn}
          handleKeyCommand={this.handleKeyCommand}
          placeholder={placeholder}
          ref={(element) => {
            this.editor = element;
          }}
        />

        <MentionSuggestions
          onSearchChange={this.onSearchChange}
          suggestions={this.state.suggestions}
          entryComponent={EntryComponent}
        />
      </div>
    );
  }
}

PostEditor.propTypes = {
  /**
   * mentions {array} - array of names for `@`mentions to work
   */
  // mentions: PropTypes.arrayOf(
  //   PropTypes.shape({
  //     name: PropTypes.string,
  //     id: PropTypes.string,
  //   })
  // ),
  /**
   * className {string} - className applied to top most Wrapper
   */
  className: PropTypes.string,
  /**
   * style {object} - inline style to be applied to top most Wrapper
   */
  style: PropTypes.object,
  /**
   * placeholder {string} - placeholder to display when editor has no text
   */
  placeholder: PropTypes.string,
};

PostEditor.defaultProps = {
  mentions: [],
};

export default PostEditor;

// rawData = editor state that i haev sent to Database
// data is the list of mention users

export const getHTMLfromRawState = (rawData, mentionList = []) => {
  try {
    JSON.parse(rawData);
  } catch (err) {
    if (err) {
      // console.log("raw-data-error", rawData);
      return "";
    }
  }

  const contentState = EditorState.createWithContent(
    convertFromRaw(JSON.parse(rawData))
  ).getCurrentContent();

  const options = {
    // eslint-disable-next-line consistent-return
    entityStyleFn: (entity) => {
      const entityType = entity.get("type").toLowerCase();
      if (entityType === "mention") {
        const data = entity.getData();

        return {
          element: "span",
          attributes: {
            "data-mention-id": get(data, "mention.OwnerUserName"),
            class: "mention_class position-relative",
          },
          style: {
            // Put styles here...
          },
        };
      }
    },
  };

  let test = stateToHTML(contentState, options);
  var doc = new DOMParser().parseFromString(test, "text/html");

  let Comment = doc.body.getElementsByClassName("mention_class");
  let Mentions_length = Comment.length;

  if (Mentions_length > 0) {
    for (var mention = 0; mention < Mentions_length; mention++) {
      let userId = doc
        .getElementsByClassName("mention_class")
        [mention].getAttribute("data-mention-id");

      let user = mentionList.filter((item) => item.OwnerUserName == userId)[0];

      // let POPOVER =
      //   '<span class=" position-absolute align-content-center p-2 popoverx d-none"><img src="/images/settings-v2/profile-pic.svg" class="mr-2" /><span class="d-inline-flex flex-column"><span class="d-inline-flex name">Boris Johnson</span><span class="d-inline-flex detail">User@gmail.com</span></span></span>';

      // if (user?.OwnerShowInitals) {
      //   POPOVER = `<span class=" position-absolute align-content-center p-2 popoverx d-none"><span class="image-initials">${
      //     user?.OwnerInitials
      //   }</span><span class="d-inline-flex flex-column"><span class="d-inline-flex name">${TrimString(
      //     user?.name,
      //     15
      //   )}</span><span class="d-inline-flex detail">${TrimString(
      //     user?.OwnerUserName,
      //     13
      //   )}</span></span></span>`;
      // } else {
      //   POPOVER = `<span class="position-absolute align-content-center p-2 popoverx d-none"><img src=${
      //     user?.OwnerLogo
      //   }  class="popupx-image mr-2 n"/><span class="d-inline-flex flex-column"><span class="d-inline-flex name">${
      //     user?.name //onerror="this.onerror=null;this.src='/images/settings-v2/highground-dp.svg'"
      //   }</span><span class="d-inline-flex detail">${TrimString(
      //     user?.OwnerUserName,
      //     13
      //   )}</span></span></span>`;
      // }

      doc.getElementsByClassName("mention_class")[mention].innerHTML =
        `@${doc.getElementsByClassName("mention_class")[mention].innerHTML}` 
        // +
        // POPOVER;
    }
    return doc.body.innerHTML;
  } else {
    return doc.body.innerHTML;
  }
};
