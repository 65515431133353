import React, { useState } from "react";
import ImageWithInitials from "../../../Utils/ImageWithInitials";
import "../supplier-profiler.scss";

const CertificationBlock = ({ title, setShowCertificateModal }) => {
  // const [updateState, setUpdateState] =useState(false);
  console.log(title);
  return (
    <div
      className="d-flex justify-content-center"
      style={{ width: "1024px", padding: "24px" }}
    >
      <div className="d-flex flex-column justify-content-center align-items-center w-100">
        <div
          className="d-flex flex-row w-100 justify-space-between header mb-2"
          style={{ justifyContent: "space-between" }}
        >
         <div className="title">
            <div className="f-16">{title.Title}</div>
            <div className="f-darkgrey">{title.Description}</div>
          </div>
        </div>

        <div className=" w-100 grid-container">
          {/* <div className="flex-row add-cert-block f-darkgrey" style={{alignItems: 'center !important'}}
                    onClick={()=>{setShowCertificateModal(true); setUpdateState(!updateState)}}>
                  <img
                        src={"/images/supply-chain/darkgrey-add-base-icon.svg"}
                        className="mr-2 text-icon "
                        alt=""
                      />
                      <div className="" >Add Training Certifications</div>
                    </div>  */}
          {title.TrainingCert_UserListVM?.items?.map((f, ind) => {
            return (
              <div className="certificate-block">
                <div className="d-flex flex-row" style={{ gap: "8px" }}>
                  <ImageWithInitials
                    initials={f.initials}
                    width={40}
                    background={"purple"}
                  />
                  <div className="d-flex flex-column">
                    <div>{f.fullName}</div>
                    <div className="f-darkgrey f-12">{f.position}</div>
                  </div>
                </div>
                {f.certificates?.length === 0 && (
                  <div className="f-darkgrey f-12">
                    No Training Certification yet
                  </div>
                )}
                <div className="d-flex flex-column w-100">
                  {f.certificates.map((cert) => {
                    return (
                      <div className="flex-row cert-item">
                        {cert.icon ? (
                          <img
                            width={33}
                            src={`/images/msp/framework-certificates/${cert.icon}`}
                            className="mr-2 rounded-full "
                            alt=""
                          />
                        ) : (
                          <ImageWithInitials
                            initials={cert?.initials}
                            width={30}
                            background={"purple"}
                          />
                        )}
                        <div className="fontBold ml-2">
                          {cert.certificationName}
                        </div>
                        <div
                          style={{
                            color: cert.status?.foreColor,
                          }}
                        >
                          {cert.status?.text}
                        </div>
                      </div>
                    );
                  })}
                  {/* <div
                    className="flex-row cert-item"
                    style={{ marginLeft: "5px" }}
                    onClick={() => {
                      setShowCertificateModal(true);
                    }}
                  >
                    <img
                      src={"/images/supply-chain/add-base-icon.svg"}
                      className=""
                      alt=""
                    />
                    <div className="f-grey">Add training certification</div>
                  </div> */}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default CertificationBlock;
