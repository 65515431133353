import React, { useState, useEffect } from "react";
import "./SideNav.scss";
import { Col, Row, Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { NavLink, Link, useLocation, useHistory } from "react-router-dom";
import { useDb } from "../context/db";
import { useAuth } from "../context/Auth";

import { PopupButton } from "react-calendly";
import {
  generateGravatar,
  GetDbObject,
  getRequestOptions,
} from "../../Utils/commonApi";

import { MdChevronLeft } from "react-icons/md";
import ModalDynamic from "../ModalDynamic/ModalDynamic";
import ChangeCompanyAndRole from "../Popouts/ChangeCompanyAndRole/ChangeCompanyAndRole";
import ResetComplianceForm from "../Popouts/ResetComplianceForm/ResetComplianceForm";
import VerticalNavigationBar from "../MSP/VerticalNavigationBar/VerticalNavigationBar";
import { FiPlusCircle } from "react-icons/fi";
import { IoMdBriefcase } from "react-icons/io";

import { getMenuCollapseStatus } from "../../redux/highgroundGlobalState/selectors";
import { createStructuredSelector } from "reselect";
import { menuToggle } from "../../redux/highgroundGlobalState/actions";
import { getUser } from "../../redux/userState/selectors";
import { setuser } from "../../redux/userState/actions";
import { useDispatch, useSelector } from "react-redux";
import { TrackUser } from "../../Utils/SegmentFunctions";
import { TrimString } from "../../Utils/StringTrimmer";
import { updateAccessKeyInLocalStorage } from "../../Utils/CommonFunctions";
function getNavigationElements() {
  const IMAGE_SIZE = 25;
  return [
    {
      text: "Add Client",
      image: <FiPlusCircle size={IMAGE_SIZE} />,
      url: "/MSP/Clients/Add",
    },
    {
      text: "Clients",
      image: <IoMdBriefcase size={IMAGE_SIZE} />,
      url: "/MSP/Clients",
    },
  ];
}

const SideNav = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const isMenuCollapse = useSelector((state) => state.global.menuCollapse);
  const userDetails = useSelector((state) => state.user?.userDetail);
  const URLpath = window.location.pathname.split("/");
  const showRequestDemo = useSelector(
    (state) => state?.user?.userDetail?.AccessObjList?.[2]?.Option
  );
  const upgradePopupData = useSelector(
    (state) =>
      state?.user?.userDetail?.AccessObjList?.filter(
        (item) => item?.Question === "Request Demo Visibility"
      )?.[0]?.UpgradeOptions
  );
  //States
  const [modal, setModal] = useState(false);
  const { userType, authTokens } = useAuth();

  const { dbObj, setdbObject, impersinatingDetails, setImpersinatingDetails } =
    useDb();
  const userStringType = determineUserRole(userType);
  const userStringActualType = determineUserRole(userType, false);
  const [userGravatarBool, setUserGravatarBool] = useState(false);
  // const [isMenuCollapse, setIsMenuCollapse] = useState(false);
  const [isUpgradeProCollapse, setIsUpgradeProCollapse] = useState(true);
  const selectedUrl = JSON.parse(localStorage.getItem("currentUrl"));
  const MspUser = localStorage.getItem("msp");
  const NAVIGATION_BUTTONS = getNavigationElements();
  if (selectedUrl == null) {
    localStorage.setItem(
      "currentUrl",
      JSON.stringify({ url: `/${userStringType}/dashboard`, name: "Dashboard" })
    );
  }

  useEffect(() => {
    if (dbObj && dbObj.UserDetail)
      generateGravatar(dbObj.UserDetail.Avatar).then((value) =>
        setUserGravatarBool(value ? value : "404")
      );
    else if (userType == 444444 && impersinatingDetails) {
      initaliseDbObject();
    }
  }, [dbObj]);
  const CURRENT_URL = useLocation().pathname;
  //Constants
  const USER_COMPANY =
    dbObj != undefined && dbObj.UserDetail != undefined
      ? dbObj.UserDetail.OrgName
      : "";
  const USER_ROLE =
    dbObj != undefined && dbObj.UserDetail != undefined
      ? dbObj.UserDetail.Role
      : "";
  const MSP_USER = userStringActualType == "MSP" ? true : false;

  function determineUserRole(role, impersinationFlag = true) {
    role = role?.toString();
    switch (role) {
      case "111111":
        return "ceo";
      case "999999":
        return "ceo";
      case "333333":
        return "cfo";
      case "222222":
        return "cto";
      case "ceo":
        return "111111";
      case "cfo":
        return "333333";
      case "cto":
        return "222222";
      default:
        return "ceo";
    }
    if (
      impersinationFlag &&
      role == "444444" &&
      impersinatingDetails &&
      impersinatingDetails.role
    ) {
      return determineUserRole(impersinatingDetails.role.toString());
    }
    return "MSP";
  }

  function toggleModalDisplay(page) {
    setModal(false);
    setTimeout(() => {
      if (page == "refresh") {
        setModal(
          <ModalDynamic
            setShow={true}
            modalHeader={"Reset Compliance Demo Data"}
            modalData={<ResetComplianceForm />}
            backButton={<MdChevronLeft />}
          />
        );
      } else {
        setModal(
          <ModalDynamic
            setShow={true}
            modalHeader={"Sales Demo Controls"}
            modalData={<ChangeCompanyAndRole />}
          />
        );
      }
    }, 10);
  }

  async function initaliseDbObject() {
    console.log("impersinatingDetails", impersinatingDetails.role);
    var dbContext = await GetDbObject(authTokens, impersinatingDetails.role);
    console.log("DB Context", dbContext);
    setdbObject(dbContext);
  }

  const handleCollapse = () => {
    // const elmt = document.getElementById("mainRow");
    // elmt.classList.toggle("smallSideMenu");
    // elmt.classList.toggle("largeSideMenu");
    dispatch(menuToggle(!isMenuCollapse));
  };

  const upgradeProHandler = (event) => {
    setIsUpgradeProCollapse(true);
  };

  async function loginAs(userEvent) {
    var user = userEvent.target.innerText.toLowerCase();

    var newImpersinatingDetails = Object.assign({}, impersinatingDetails);
    newImpersinatingDetails.role = determineUserRole(user);

    setdbObject();
    setImpersinatingDetails(newImpersinatingDetails);

    window.location.replace(`/${user}/dashboard`);
  }

  async function updateuser(role) {
    let olduser = window.localStorage.getItem("recentUser");
    olduser = JSON.parse(olduser);
    const data = await fetch(
      `${process.env.REACT_APP_API_BASE_URL}/CC_API/GetDemoOrgAndRoleAccessToken?orgId=${ 
        olduser.UserDetail.OrgId
      }&roleId=${111111}`,
      getRequestOptions(authTokens)
    ).then((response) => response.json());
    if (data.AccessToken) {
      window.localStorage.clear();
      window.localStorage.setItem("tokens", `"${data.AccessToken}"`);
      window.localStorage.setItem("userType", data.mr.EntityId);
      window.localStorage.setItem("userAuthenticated", true);
      window.localStorage.setItem("activeCompany", olduser.UserDetail.OrgId);
 
      updateAccessKeyInLocalStorage(data?.mr?.Message)
      window.localStorage.setItem("object_Data", JSON.stringify(olduser));
      localStorage.setItem("msp", false);
      window.location.replace("/MSP/Clients");
    }
    return false;
  }

  const DefaultUserNav = () => (
    <div className="sideNav">
      <div className="fullHeight" style={{ position: "relative" }}>
        {modal}
        <Col xs={12}>
          {!MSP_USER ? (
            <Row>
              <Col xs={2} className="px-0 my-auto">
                {/* <img height="24px" width="24px" src="/images/navIcons/Vector-Menu-Item.svg" /> */}
              </Col>
              <Col xs={6} className="px-0">
                <img
                  alt=""
                  className="logo"
                  src="/images/logo/vision/regular.png"
                ></img>
              </Col>
            </Row>
          ) : (
            <div className="CompanyHeader">
              <span>
                <img
                  alt=""
                  src={
                    impersinatingDetails
                      ? impersinatingDetails.orgLogo
                      : "/images/icons/AddSymbol.png"
                  }
                />
              </span>
              <h1>{impersinatingDetails.CustomerName}</h1>
            </div>
          )}
        </Col>

        <Col xs={12} className="px-0 linkMenu">
          <ul className="nav">
            <li>
              <NavLink
                exact
                to={`/${userStringType}/dashboard`}
                activeClassName="active"
              >
                <span>
                  <img alt="" src="/images/navIcons/dashboardIcon.svg"></img>
                </span>
                <p>Dashboard</p>
              </NavLink>
            </li>
            <li>
              <NavLink exact to="/trend_data" activeClassName="active">
                <span>
                  <img
                    alt=""
                    src="/images/navIcons/cyberTrendDashboardIcon.svg"
                  ></img>
                </span>
                <p>Cyber Trend Data</p>
              </NavLink>
            </li>
            <li>
              <NavLink to="/technical_drilldown" activeClassName="active">
                <span>
                  <img
                    alt=""
                    src="/images/navIcons/technicalDrillDownDashboardIcon.svg"
                  ></img>
                </span>
                <p>Technical Drilldown</p>
              </NavLink>
            </li>

            <li>
              <NavLink to="/Compliance_Manager" activeClassName="active">
                <span>
                  <img
                    alt=""
                    src="/images/navIcons/complianceDashboardIcon.svg"
                  ></img>
                </span>
                <p>Compliance Manager</p>
              </NavLink>
            </li>
            {/* <li> <NavLink to="/pro_services" activeClassName="active"><span> <img src="/images/navIcons/proServiceDashboardIcon.svg"></img> </span> <p>Pro Services</p> </NavLink></li> */}
            <li>
              <NavLink to="/integrations" activeClassName="active">
                <span>
                  <img
                    alt=""
                    src="/images/navIcons/intergrationsDashboard.svg"
                  ></img>
                </span>
                <p>Integrations</p>
              </NavLink>
            </li>
            {/* <li> <NavLink to="/system_settings" activeClassName="active"><span> <img src="/images/navIcons/settingsDashboardIcon.svg"></img> </span> <p>System Settings</p> </NavLink></li> */}
            {/* <li> <NavLink to="/Settings" activeClassName="active"><span> <img src="/images/navIcons/settingsDashboardIcon.svg"></img> </span> <p>Settings</p> </NavLink></li> */}
            <li>
              <NavLink to="/Logout" activeClassName="active">
                <span>
                  <img alt="" src="/images/navIcons/dashboardIcon.svg"></img>
                </span>
                <p>Logout</p>
              </NavLink>
            </li>
          </ul>
        </Col>
        <div className="absoulteBottom">
          {MSP_USER ? (
            <div>
              <ul className="mspUserTypeNav">
                <li
                  className={userStringType == "ceo" ? "active" : ""}
                  onClick={loginAs}
                >
                  CEO
                </li>
                <li
                  className={userStringType == "cfo" ? "active" : ""}
                  onClick={loginAs}
                >
                  CFO
                </li>
                <li
                  className={userStringType == "cto" ? "active" : ""}
                  onClick={loginAs}
                >
                  CTO
                </li>
              </ul>
            </div>
          ) : (
            <React.Fragment>
              <Row className="justify-content-end">
                <Col xs={4} style={{ paddingBottom: "1em" }}></Col>
              </Row>
              {dbObj ? (
                <Col xs={11} className="bottomDetails px-0">
                  <Row>
                    <Col xs={2}>
                      {userGravatarBool !== "404" &&
                      dbObj.UserDetail.Avatar !== "No URL" ? (
                        <img
                          className="userAvatar"
                          alt="users imgae"
                          src={dbObj.UserDetail.Avatar}
                        ></img>
                      ) : (
                        <span className="userInitials">
                          <p>
                            {dbObj.UserDetail ? dbObj.UserDetail.Initials : ""}
                          </p>
                        </span>
                      )}
                    </Col>
                    <Col xs={9} className="userDetails padding-clear pl-3">
                      <p>{dbObj.UserDetail ? dbObj.UserDetail.FullName : ""}</p>
                      <p className="mt-2">
                        {dbObj.UserDetail ? dbObj.UserDetail.Role : ""},
                        {dbObj.UserDetail ? dbObj.UserDetail.OrgName : ""}
                      </p>
                    </Col>
                  </Row>
                </Col>
              ) : (
                ""
              )}
            </React.Fragment>
          )}
        </div>
      </div>
    </div>
  );

  const SideBarData = {
    3274188: {
      fullName: "Dashboard",
      collapsedName: "Dash..",
      url: `/${userStringType}/dashboard`,
      lockIcon: "/images/msp/lock-icon.svg",
      menuIcon: "/images/navIcons/dashboardIcon.svg",
      selectedUrl: "dashboard",
    },
    3274189: {
      fullName: "Attack Surface",
      collapsedName: "Attack..",
      url: "/attack_surface",
      lockIcon: "/images/msp/lock-icon.svg",
      menuIcon: "/images/attack-surface/attack_surface_dashboard_icon.svg",
      selectedUrl: "attack_surface",
    },

    3274190: {
      fullName: "Cyber Trend Data",
      collapsedName: "Cyber..",
      url: "/trend_data",
      lockIcon: "/images/msp/lock-icon.svg",
      menuIcon: "/images/navIcons/cyberTrendDashboardIcon.svg",
      selectedUrl: "trend_data",
    },
    3274192: {
      fullName: "Technical Drilldown",
      collapsedName: "Technical..",
      url: "/technical_drilldown",
      lockIcon: "/images/msp/lock-icon.svg",
      menuIcon: "/images/navIcons/technicalDrillDownDashboardIcon.svg",
      selectedUrl: "technical_drilldown",
    },
    3274193: {
      fullName: "Governance & Resilience",
      collapsedName: "Govern...",
      url: "/Compliance_Manager",
      lockIcon: "/images/msp/lock-icon.svg",
      menuIcon: "/images/navIcons/complianceDashboardIcon.svg",
      selectedUrl: "Compliance_Manager",
    },
    3274194: {
      fullName: "Pro Services",
      collapsedName: "Pro Services",
      url: "/pro_services",
      lockIcon: "/images/msp/lock-icon.svg",
      menuIcon: "/images/navIcons/proServiceDashboardIcon.svg",
      selectedUrl: "pro_services",
    },
    3274195: {
      fullName: "Integrations",
      collapsedName: "Integrations",
      url: "/integrations",
      lockIcon: "/images/msp/lock-icon.svg",
      menuIcon: "/images/navIcons/intergrationsDashboard.svg",
      selectedUrl: "integrations",
    },
    3274196: {
      fullName: "Supply Chain Monitoring",
      collapsedName: "Supply Chain",
      url: "/supply",
      lockIcon: "/images/msp/lock-icon.svg",
      menuIcon: "/images/settings-v2/supply-chain.svg",
      selectedUrl: "supply",
    },
    3274197: {
      fullName: "Managed Security",
      collapsedName: "Managed..",
      url: "/managed_security",
      lockIcon: "/images/msp/lock-icon.svg",
      menuIcon: "/images/navIcons/calculator.svg",
      selectedUrl: "managed_security",
    },
    3274198: {
      fullName: "Marketplace",
      collapsedName: "Marketplace",
      url: "/marketplace",
      lockIcon: "/images/msp/lock-icon.svg",
      menuIcon: "/images/marketplace-icon.svg",
      selectedUrl: "marketplace",
    },
    5062724: {
      fullName: "Technology Spend",
      collapsedName: "Tech..",
      url: "/technology-spend",
      lockIcon: "/images/msp/lock-icon.svg",
      menuIcon: "/images/navIcons/calculator.svg",
      selectedUrl: "technology-spend",
    },
    6927495: {
      fullName: "Security Assessments",
      collapsedName: "Secur..",
      url: "/security-assessments",
      lockIcon: "/images/msp/lock-icon.svg",
      menuIcon: "/images/navIcons/security-assessment-icon.svg",
      selectedUrl: "security-assessments",
    },
  };

  const mspDetail = window.localStorage.getItem("object_Data")
    ? JSON.parse(window.localStorage.getItem("object_Data"))?.mspDetail
      ? JSON.parse(window.localStorage.getItem("object_Data"))?.mspDetail
      : null
    : null;

  if (dbObj && dbObj.UserDetail) {
    return (
      <div
        className="fullHeight position-relative test"
        style={{ display: "flex", flexDirection: "column", height: "100%" }}
      >
        {modal}

        <div
          className="d-flex flex-column  img_wrapper"
          style={{ paddingTop: "23px" }}
        >
          <div className="d-flex w-100 align-items-center justify-content-between">
            <div className="px-0">
              <img
                alt=""
                className={`'logo' ${isMenuCollapse ? "" : "d-none"}`}
                style={{ height: "32px", paddingLeft: "24px" }}
                src="/images/logo/highground/short.svg"
              />

              <img
                alt=""
                className={`'logo' ${isMenuCollapse ? "d-none" : ""}`}
                style={{ height: "32px", paddingLeft: "6px", width: "11rem" }}
                src="/images/logo/highground/regular.svg"
              />
            </div>
            <div
              className="px-0 my-auto cursor-pointer"
              onClick={(e) => {
                e.stopPropagation();
                handleCollapse();
              }}
            >
              {isMenuCollapse ? (
                <img
                  alt=""
                  style={{ marginLeft: "19px" }}
                  src={"/images/dashboard/sideNavDoubleArrrow.svg"}
                />
              ) : (
                <img
                  alt=""
                  style={{ width: "10px" }}
                  src={"/images/dashboard/MenuCollapse.svg"}
                />
              )}
            </div>
          </div>
          {/* Request demo div */}
          <div
            className={` radius-4  flex-column align-items-center ${
              showRequestDemo ? "d-flex small-request-demo" : "d-none"
            }`}
            style={{ background: "#EBF6FC" }}
            onClick={() => {
              let btn = document.querySelector(".calendly-button");
              btn.click();
            }}
          >
            <img
              src="/images/request-demo-collapse.svg"
              alt=""
              className="mb-2"
            />
            <div className="f-500 f-blue">Reque...</div>
          </div>
        </div>
        <div
          className="wrapper-container"
          style={{
            flexGrow: "500",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <Col xs={12} className="px-0 linkMenu">
            {CURRENT_URL.includes("MSP") ? (
              <ul className="nav hide-scrollbar">
                {NAVIGATION_BUTTONS.map((element) => {
                  return (
                    <li>
                      <NavLink exact to={element.url} activeClassName="active">
                        <span>{element.image}</span>
                        <p>{element.text}</p>
                      </NavLink>
                    </li>
                  );
                })}
              </ul>
            ) : (
              <>
                {MspUser == "true" && (
                  <div
                    className="crsrpoint"
                    onClick={() => {
                      updateuser();
                    }}
                  >
                    <div className="MSPButton">
                      <div id="branding">
                        {/* Add Inline Chevron and MSP Text */}
                        <span>{`<`}</span>
                        <p>MSP</p>
                      </div>
                      <div id="companyDetails" className="ml-3">
                        <h2>{USER_COMPANY}</h2>
                        <h3>Logged in as {USER_ROLE}</h3>
                        {/* Block elements - one header, one subheader */}
                      </div>
                    </div>
                  </div>
                )}

                <ul className="nav hide-scrollbar align-items-center">
                  {dbObj.MenuVisbility && dbObj.MenuVisbility.show_DashBoard ? (
                    <>
                      {!isMenuCollapse && (
                        <li
                          className={`demo-btn  ${
                            showRequestDemo ? "d-flex" : "d-none"
                          } `}
                        >
                          <Button
                            className="d-flex align-items-center justify-content-between request-demo"
                            onClick={() => {
                              TrackUser("Request Demo Clicked");
                              let btn =
                                document.querySelector(".calendly-button");
                              btn.click();
                            }}
                          >
                            <div className="d-flex align-items-center">
                              <img
                                alt=""
                                src="/images/settings-v2/request-demo.svg"
                                className="mr-3"
                              />
                              <div className="d-flex align-items-center">
                                Request demo
                              </div>
                            </div>

                            <img
                              alt=""
                              src="/images/settings-v2/chevron-right-blue.svg"
                              className="chev"
                            />
                          </Button>
                        </li>
                      )}
                    </>
                  ) : (
                    ""
                  )}

                  {/* Attack Surface Menu option */}

                  {dbObj?.MenuVisbility?.MenuItem_List?.sort((a,b)=>a.SortOrder -b.SortOrder)?.map(
                    (menuItem, menuItemIndex) => {
                      return (
                        <OverlayTrigger
                          trigger={
                            menuItem?.disableState ? ["hover", "focus"] : []
                          }
                          key={menuItem?.MenuItemId}
                          placement={"right"}
                          overlay={
                            <Tooltip id={`tooltip-${menuItem?.MenuItemId}`}>
                              {
                                <>
                                  <img 
                                    alt='' 
                                    className='lockerTooltipImage' 
                                    src='/images/settings-v2/locker.svg'
                                  />
                                  You don't have access to this feature
                                </>
                              }
                            </Tooltip>
                          }
                        >
                          <li
                            className={`
                          ${
                            URLpath[1] ===
                            SideBarData[menuItem?.MenuItemId]?.selectedUrl
                              ? "active-tab"
                              : ""
                          }
                          ${
                            URLpath[2] ===
                            SideBarData[menuItem?.MenuItemId]?.selectedUrl
                              ? "active-tab"
                              : ""
                          }
                          
                          ${menuItem?.showMenuItem ? "" : "d-none"}

                          ${
                            menuItem?.disableState 
                              ? "disabled-main-dashboard-menuItem"
                              : ""
                          }
                          
                          `}
                            onClick={() => {
                              if (menuItem?.disableState) return;
if(menuItem?.MenuItemId===5062724){
  return
}


        TrackUser(
          SideBarData[menuItem?.MenuItemId].fullName +
            " opened"
        );
        localStorage.setItem("redirectTab", false);
        localStorage.setItem(
          "currentUrl",
          JSON.stringify({
            url: SideBarData[menuItem?.MenuItemId]?.url,
            name: SideBarData[menuItem?.MenuItemId]
              .fullName,
          })
        );
        history.push(SideBarData[menuItem?.MenuItemId]?.url); // Use history.push to navigate
      }}
    >
      <NavLink
        onClick={(e) => {
          if (menuItem?.disableState) {
            e.preventDefault();
            e.stopPropagation();
          }
        }}
        to={menuItem?.disableState ? "" : SideBarData[menuItem?.MenuItemId]?.url}
        className={`menumanagesecurity ${
          selectedUrl &&
          selectedUrl?.url === SideBarData[menuItem?.MenuItemId]?.url
            ? "activ e"
            : ""
        }`}
      >
        <span className="menu-icon">
          <img
            alt=""
            src={
              menuItem?.disableState
                ? SideBarData[menuItem?.MenuItemId]?.lockIcon
                : SideBarData[menuItem?.MenuItemId]?.menuIcon
            }
          ></img>
        </span>

        <p className="managedSecurityMenu">
          <span>
            {!isMenuCollapse
              ? menuItem?.ExpandedText
              : menuItem?.CollapsedText}
          </span>
        </p>
        {menuItem?.DevStatus !== "Live" &&
          menuItem?.disableState === false && (
            <span className="beta-tag">
              {menuItem?.DevStatus}
            </span>
          )}
      </NavLink>
    </li>
                      </OverlayTrigger>
                    );
                  })}
                   <OverlayTrigger
                          trigger={
                            []
                          }
                          key={3274198}
                          placement={"right"}
                          overlay={
                            <Tooltip id={`tooltip-3274198`}>
                              {
                                <>
                                  <img 
                                    alt='' 
                                    className='lockerTooltipImage' 
                                    src='/images/settings-v2/locker.svg'
                                  />
                                  You don't have access to this feature
                                </>
                              }
                            </Tooltip>
                          }
                        >
                          <li
                            className={`
                          ${
                            URLpath[1] ===
                            SideBarData[3274198]?.selectedUrl
                              ? "active-tab"
                              : ""
                          }
                          ${
                            URLpath[2] ===
                            SideBarData[3274198]?.selectedUrl
                              ? "active-tab"
                              : ""
                          }
                          
                          
                          `}
                            onClick={() => {


        TrackUser(
          SideBarData[3274198].fullName +
            " opened"
        );
        localStorage.setItem("redirectTab", false);
        localStorage.setItem(
          "currentUrl",
          JSON.stringify({
            url: SideBarData[3274198]?.url,
            name: SideBarData[3274198]
              .fullName,
          })
        );
        history.push(SideBarData[3274198]?.url); // Use history.push to navigate
      }}
    >
      <NavLink
        onClick={(e) => {
          // if (menuItem?.disableState) {
          //   e.preventDefault();
          //   e.stopPropagation();
          // }
        }}
        to={SideBarData[3274198]?.url}
        className={`menumanagesecurity ${
          selectedUrl &&
          selectedUrl?.url === SideBarData[3274198]?.url
            ? "activ e"
            : ""
        }`}
      >
        <span className="menu-icon">
          <img
            alt=""
            src={ SideBarData[3274198]?.menuIcon
            }
          ></img>
        </span>

        <p className="managedSecurityMenu">
          <span>
            { SideBarData[3274198]?.fullName }
          </span>
        </p>
        {/* {menuItem?.DevStatus !== "Live" &&
          menuItem?.disableState === false && (
            <span className="beta-tag">
              {menuItem?.DevStatus}
            </span>
          )} */}
      </NavLink>
    </li>
                      </OverlayTrigger>
                </ul>
              </>
            )}
            <div
              className="test-blur"
              style={{
                width: "100%",
                background: "white",
                height: "24px",
                filter: "blur(6px)",
                zIndex: -1,
                position: "absolute",
                top: "80%",
                display: "none",
              }}
            ></div>
          </Col>
          <div
            className="absoulteBottom"
            style={{
              position: `${isMenuCollapse ? "absolute" : "absolute"}`,
              zIndex: 0,
            }}
          >
            {!isMenuCollapse && !CURRENT_URL.includes("MSP") && (
              <>
                {mspDetail ? (
                  <Row>
                    <Col className="upgradePro border-none-imp">
                      <div className="d-flex align-items-center position-relative parent-visible">
                        <div
                          className="position-absolute radius-4 child-visible"
                          style={{
                            zIndex: 5,
                            background: "#fff",
                            boxShadow: "2px 2px 10px -3px rgba(0,0,0,0.75)",
                            width: "237px",
                            left: "-20px",
                          }}
                        >
                          <div className="d-flex align-items-center border-bottom px-2 py-3">
                            <div className="position-relative mr-3">
                              <img
                                alt=""
                                src={mspDetail?.logo}
                                className=" rounded-full"
                                width={24}
                                height={24}
                              />
                              <img
                                alt=""
                                src="/images/settings-v2/settingwheel.svg"
                                width={13}
                                height={13}
                                className="position-absolute "
                                style={{
                                  top: "95%",
                                  transform: "translate(-67%, -50%)",
                                  zIndex: 2,
                                }}
                              />
                            </div>
                            <div className="f-600">
                              {TrimString(mspDetail?.name, 20)}
                            </div>
                          </div>
                          <div className=" f-12 f-500 f-darkgrey pl-2 pt-1">
                            {" "}
                            Contact details
                          </div>
                          <div className="f-12 px-2">
                            <div className="d-flex mb-2">
                              <div className="w-20 f-grey">Email</div>
                              <div className="w-80">
                                {TrimString(mspDetail?.email, 25)}
                              </div>
                            </div>
                            <div className="d-flex mb-2">
                              <div className="w-20 f-grey">Phone</div>
                              <div className="w-80">
                                {" "}
                                {TrimString(mspDetail?.phoneNumber, 25)}{" "}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="position-relative mr-3">
                          <img
                            alt=""
                            src={mspDetail?.logo}
                            className=" rounded-full"
                            width={24}
                            height={24}
                          />
                          <img
                            alt=""
                            src="/images/settings-v2/settingwheel.svg"
                            width={13}
                            height={13}
                            className="position-absolute "
                            style={{
                              top: "95%",
                              transform: "translate(-67%, -50%)",
                              zIndex: 2,
                            }}
                          />
                        </div>
                        <div>
                          <div className="f-12 f-darkgrey">Managed by</div>
                          <div className="f-500 f-black">
                            {" "}
                            {TrimString(mspDetail?.name, 20)}{" "}
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                ) : (
                  <>
                    {(upgradePopupData && false) ? (
                      <Row>
                        {isUpgradeProCollapse ? (
                          <Col className="upgradePro">
                            <div className="d-flex justify-content-between">
                              <div
                                className="text-center"
                                onClick={() =>
                                  TrackUser("Upgrade Now button clicked")
                                }
                              >
                                <Link
                                  to={upgradePopupData?.SubmitButton?.ButtonUrl}
                                  className="pl-4 ml-1 urgradeTextSidebar new-upgrade"
                                >
                                  <span className="pl-4">
                                    <img
                                      alt=""
                                      src={
                                        upgradePopupData?.SubmitButton
                                          ?.ButtonImage
                                      }
                                      height="20"
                                      className="mr-2"
                                    />
                                  </span>
                                  {upgradePopupData?.SubmitButton?.ButtonText}
                                </Link>
                              </div>
                              <div>
                                <img
                                  alt=""
                                  src="/images/dashboard/upgradeCollapse.svg"
                                  onClick={() => setIsUpgradeProCollapse(false)}
                                />
                              </div>
                            </div>
                          </Col>
                        ) : (
                          <Col className="upgradePro mt-4">
                            <div className="d-flex justify-content-between">
                              {isUpgradeProCollapse ? (
                                <div className="mt16px mb-2 text-center">
                                  <img alt="" src="/images/upgradeBell.svg" />
                                  <Link
                                    // to="/system_settings?tab=subscriptions"
                                    to="/settings/v2/subscriptions"
                                  >
                                    <img
                                      alt=""
                                      src="/images/upgradeNow.svg"
                                      className="upgradeText"
                                    />
                                  </Link>
                                </div>
                              ) : (
                                <div
                                  className="w-fit-content pointer p-1"
                                  onClick={(event) => {
                                    TrackUser("Upgrade button clicked off");
                                    upgradeProHandler(event);
                                  }}
                                >
                                  <img
                                    alt=""
                                    src="/images/attack-surface/chevron-down.svg"
                                    className="closeButton"
                                    onClick={(event) => {
                                      TrackUser("Upgrade button clicked off");
                                      upgradeProHandler(event);
                                    }}
                                  />
                                </div>
                              )}
                            </div>
                            <div>
                              <div className="text-center">
                                <img
                                  alt=""
                                  width={25}
                                  src={upgradePopupData?.LogoImageUrl}
                                  className=""
                                />
                              </div>

                              <p className="text-center my-1 upgrade_pro_title">
                                {upgradePopupData?.Title}
                              </p>
                              {upgradePopupData?.SubTitle && (
                                <div className="f-12 f-500 text-center f-grey">
                                  {upgradePopupData?.SubTitle}
                                </div>
                              )}
                              {upgradePopupData?.FeatureHeader && (
                                <div className="f-12 f-400  ">
                                  {upgradePopupData?.FeatureHeader}
                                </div>
                              )}

                              {upgradePopupData?.Features?.map((_data) => {
                                return (
                                  <div className="d-flex align-self-center mt-2">
                                    <img
                                      width={12}
                                      height={12}
                                      alt=""
                                      src={_data?.IconUrl}
                                    />
                                    <span
                                      className="linkGreyText"
                                      style={{ paddingLeft: "5px" }}
                                    >
                                      {_data?.Feature}
                                    </span>
                                  </div>
                                );
                              })}

                              {upgradePopupData?.FeatureFooter && (
                                <div className="f-12 f-400  mt-2">
                                  {upgradePopupData?.FeatureFooter}
                                </div>
                              )}

                              <div
                                className="mt16px mb-2 text-center"
                                onClick={() =>
                                  TrackUser("Upgrade Now button clicked")
                                }
                              >
                                {/* <img src="/images/upgradeBell.svg" /> */}
                                <Link
                                  // to="/system_settings?tab=subscriptions"
                                  to={upgradePopupData?.SubmitButton?.ButtonUrl}
                                  className="urgradeTextSidebar"
                                >
                                  <img
                                    alt=""
                                    src={
                                      upgradePopupData?.SubmitButton
                                        ?.ButtonImage
                                    }
                                    className="mr-2"
                                    width={20}
                                    height={20}
                                  />

                                  {upgradePopupData?.SubmitButton?.ButtonText}
                                </Link>
                              </div>
                            </div>
                          </Col>
                        )}
                      </Row>
                    ) : (
                      ""
                    )}
                  </>
                )}

                <Row className="justify-content-end">
                  <Col
                    xs={4}
                    style={{ paddingBottom: "1em" }}
                    className={`${CURRENT_URL.includes("MSP") ? "d-none" : ""}`}
                  ></Col>
                </Row>
              </>
            )}

            {dbObj && (
              <>
                {isMenuCollapse &&
                dbObj.SubscriptionDetail.ShowUpgradeOption ? (
                  <Link
                    // to="/system_settings?tab=subscriptions"
                    to="/settings/v2/subscriptions"
                  >
                    <div
                      className="smallUpgrade"
                      style={{ marginBottom: "15px", background: "#fff" }}
                    >
                      <div className="mt16px mb-2 text-center">
                        <div>
                          <img src="/images/upgradeBell.svg" alt="" />
                        </div>
                        <div>
                          <img
                            alt=""
                            src="/images/dashboard/upgradeTextIcon.svg"
                            className="upgradeText"
                          />
                        </div>
                      </div>
                    </div>
                  </Link>
                ) : (
                  ""
                )}
                <Col xs={11} className="bottomDetails px-0 pb-0 d-none">
                  {isMenuCollapse ? (
                    // <>
                    <div className="d-flex">
                      <NavLink to="/personal_settings">
                        {userGravatarBool !== "404" &&
                        dbObj.UserDetail.Avatar !== "No URL" ? (
                          <img
                            className="userAvatar"
                            alt="users imag_e"
                            src={dbObj.UserDetail.Avatar}
                          ></img>
                        ) : (
                          <span className="userInitials">
                            <p>
                              {dbObj.UserDetail
                                ? dbObj.UserDetail.Initials
                                : ""}
                            </p>
                          </span>
                        )}
                      </NavLink>
                      <NavLink
                        to="/logout"
                        className="ml-auto align-self-center"
                      >
                        <img alt="" src="/images/icons/logoutIcon.svg"></img>
                      </NavLink>
                    </div>
                  ) : (
                    // </>
                    <Row
                      onClick={() => {
                        localStorage.setItem(
                          "currentUrl",
                          JSON.stringify({
                            url: "/personal_settings",
                            name: "Personal Setting",
                          })
                        );
                        localStorage.setItem("redirectTab", false);
                      }}
                    >
                      <Col xs={2}>
                        <NavLink
                          to="/personal_settings"
                          activeClassName="active"
                          className={
                            selectedUrl &&
                            selectedUrl?.url === "/personal_settings"
                              ? "active"
                              : ""
                          }
                        >
                          {userGravatarBool !== "404" &&
                          dbObj.UserDetail.Avatar !== "No URL" ? (
                            <img
                              className="userAvatar"
                              alt="users image_"
                              src={dbObj.UserDetail.Avatar}
                            ></img>
                          ) : (
                            <span className="userInitials">
                              <p>
                                {dbObj.UserDetail
                                  ? dbObj.UserDetail.Initials
                                  : ""}
                              </p>
                            </span>
                          )}
                        </NavLink>
                      </Col>
                      <Col xs={8} className="userDetails padding-clear pl-3">
                        <NavLink to="/personal_settings">
                          <p>
                            {dbObj.UserDetail ? dbObj.UserDetail.FullName : ""}
                          </p>
                          <p className="mt-2">
                            {dbObj.UserDetail ? dbObj.UserDetail.Role : ""},
                            {dbObj.UserDetail ? dbObj.UserDetail.OrgName : ""}
                          </p>
                        </NavLink>
                      </Col>
                      <Col xs={2} className="text-center">
                        <NavLink to="/logout">
                          <img alt="" src="/images/icons/logoutIcon.svg"></img>
                        </NavLink>
                      </Col>
                    </Row>
                  )}
                </Col>
              </>
            )}

            {userDetails?.ShowOrgSwitch && (
              <div className="d-flex align-items-start justify-content-end pr-2">
                <div className="w-fit-content p-2 radius-4 demo-pan">
                  <img
                    alt=""
                    className="pointer"
                    onClick={toggleModalDisplay}
                    src="/images/attack-surface/pan-default.svg"
                  />
                </div>
              </div>
            )}
          </div>
        </div>
        {/* Load static image onload managesecutiry  */}
        <img src="/images/coimgsoon.svg" className="d-none" alt="" />
       
      </div>
    );
  } else {
    return "";
  }
};

// const mapStateToProps = createStructuredSelector({
//   isMenuCollapse: getMenuCollapseStatus(),
//   user: getUser(),
// });

// const compose = connect(mapStateToProps, { menuToggle, setuser });

// export default compose(SideNav);
export default SideNav;

const menuIdList = {
  Dashboard: 3274188,
  "Attack Surface": 3274189,
  "Cyber Trend Data": 3274190,
  "Technical Drill down": 3274192,
  "Complinace Manager": 3274193,
  "Pro services": 3274194,
  Integrations: 3274195,
  "Supply Chain": 3274196,
  "Managed Security": 3274197,
};

function getMenuItemData(menuList, itemId) {
  let _obj = menuList.find((o) => o?.MenuItemId === itemId);

  return _obj;
}

export function getMenuItemId(itemUrl) {
  const SideBarData = {
    3274188: {
      fullName: "Dashboard",
      collapsedName: "Dash..",
      // url: `/${userStringType}/dashboard`,
      url: "/dashboard",
      lockIcon: "/images/msp/lock-icon.svg",
      menuIcon: "/images/navIcons/dashboardIcon.svg",
      selectedUrl: "dashboard",
    },
    3274189: {
      fullName: "Attack Surface",
      collapsedName: "Attack..",
      url: "/attack_surface",
      lockIcon: "/images/msp/lock-icon.svg",
      menuIcon: "/images/attack-surface/attack_surface_dashboard_icon.svg",
      selectedUrl: "attack_surface",
    },
    3274189: {
      fullName: "Attack Surface",
      collapsedName: "Attack..",
      url: "/attack-surface",
      lockIcon: "/images/msp/lock-icon.svg",
      menuIcon: "/images/attack-surface/attack_surface_dashboard_icon.svg",
      selectedUrl: "attack_surface",
    },

    3274190: {
      fullName: "Cyber Trend Data",
      collapsedName: "Cyber..",
      url: "/trend_data",
      lockIcon: "/images/msp/lock-icon.svg",
      menuIcon: "/images/navIcons/cyberTrendDashboardIcon.svg",
      selectedUrl: "trend_data",
    },
    3274192: {
      fullName: "Technical Drilldown",
      collapsedName: "Technical..",
      url: "/technical_drilldown",
      lockIcon: "/images/msp/lock-icon.svg",
      menuIcon: "/images/navIcons/technicalDrillDownDashboardIcon.svg",
      selectedUrl: "technical_drilldown",
    },
    3274193: {
      fullName: "Compliance Manager",
      collapsedName: "Compliance..",
      url: "/Compliance_Manager",
      lockIcon: "/images/msp/lock-icon.svg",
      menuIcon: "/images/navIcons/complianceDashboardIcon.svg",
      selectedUrl: "Compliance_Manager",
    },
    3274194: {
      fullName: "Pro Services",
      collapsedName: "Pro Services",
      url: "/pro_services",
      lockIcon: "/images/msp/lock-icon.svg",
      menuIcon: "/images/navIcons/proServiceDashboardIcon.svg",
      selectedUrl: "pro_services",
    },
    3274195: {
      fullName: "Integrations",
      collapsedName: "Integrations",
      url: "/integrations",
      lockIcon: "/images/msp/lock-icon.svg",
      menuIcon: "/images/navIcons/intergrationsDashboard.svg",
      selectedUrl: "integrations",
    },
    3274196: {
      fullName: "Supply Chain Monitoring",
      collapsedName: "Supply Chain",
      url: "/supply",
      lockIcon: "/images/msp/lock-icon.svg",
      menuIcon: "/images/settings-v2/supply-chain.svg",
      selectedUrl: "supply",
    },
    3274197: {
      fullName: "Managed Security",
      collapsedName: "Managed..",
      url: "/managed_security",
      lockIcon: "/images/msp/lock-icon.svg",
      menuIcon: "/images/navIcons/calculator.svg",
      selectedUrl: "managed_security",
    },
    3274197: {
      fullName: "Managed Security",
      collapsedName: "Managed..",
      url: "/managed-security",
      lockIcon: "/images/msp/lock-icon.svg",
      menuIcon: "/images/navIcons/calculator.svg",
      selectedUrl: "managed_security",
    },
    5062724: {
      fullName: "Technology Spend",
      collapsedName: "Tech..",
      url: "/technology-spend",
      lockIcon: "/images/msp/lock-icon.svg",
      menuIcon: "/images/navIcons/calculator.svg",
      selectedUrl: "technology_spend",
    },
    6927495: {
      fullName: "Security Assessment",
      collapsedName: "Security..",
      url: "/security-assessments",
      lockIcon: "/images/msp/lock-icon.svg",
      menuIcon: "/images/navIcons/security-assessment-icon.svg",
      selectedUrl: "security-assessments",
    },
  };
  
  const foundKey = Object.keys(SideBarData).find(key => SideBarData[key].url === itemUrl);

  return foundKey;
}

