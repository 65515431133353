import { Dropdown } from "react-bootstrap";
import IntegrationItem from "./integration-item.component";
import { useMemo } from "react";
import { useHistory } from "react-router-dom";
import { ROUTE_PATHS } from "../../../../constants/app.constants";

const IntegrationsList = ({ integrationList, onOptionAdd }) => (
  <div className="scrollable-div pr-1">
    {integrationList?.map((option, optionIndex) => (
      <Dropdown.Item
        className="px-0 pl-3 py-2 integration-item"
        key={`stio-${option?.ToolName}-${optionIndex}`} 
      >
        <IntegrationItem 
          integrationName={option?.ToolName}
          integrationId={option?.obj_IntegrationId} 
          isLoading={option?.loading} 
          onAdd={() => {
            onOptionAdd(option);
          }} 
        />
      </Dropdown.Item>
    ))}
  </div>
);

const SelectIntegrations = ({ integrationList, onOptionAdd }) => {
  const history = useHistory();
  const mspTools = useMemo(() => (
    integrationList?.filter((option) => option?.IsMSPTool)
  ), [integrationList]);
  const notMspTools = useMemo(() => (
    integrationList?.filter((option) => !option?.IsMSPTool)
  ), [integrationList]);

  const onBuildStack = () => {
    history.push(ROUTE_PATHS.mspv2.securityStack);
  }

  return (
    <div>
      <div className="mb-3">
        <h3 className="px-3 f-12 mb-1">Select a tool from your MSPs stack</h3>
        {mspTools.length === 0 ? (
          <div className="px-3 mt-2">
            <h3 className="f-12 m-0 no-available-title">No tools available</h3>
            <button 
              className="f-12 build-stack-btn"
              onClick={onBuildStack}
            >
              Build your stack
            </button>
          </div>
        ) : (
          <IntegrationsList integrationList={mspTools} onOptionAdd={onOptionAdd} />
        )}
      </div>
      <div>
        <h3 className="px-3 f-12 all-tools-title mb-1">All tools</h3>
        <IntegrationsList integrationList={notMspTools} onOptionAdd={onOptionAdd} />
      </div>
    </div>
  );
}

export default SelectIntegrations;
