import React, { useState, useEffect } from "react";
import { Dropdown } from "react-bootstrap";
import moment from "moment";

import "./test.scss";

const SecurityRatingsDatePicker = (props) => {
  const { disableFutureDates, disablePreviousDates } = props;

  const [selectedDate, setSelectedDate] = useState(
    moment().format("MM/DD/YYYY")
  );
  const [firstLoad, setFirstLoad] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [dropdownOpened, setDropdownOpened] = useState(false);

  useEffect(() => {
    if (!firstLoad) return;
    setFirstLoad(true);
    drawCalender();
  }, [selectedDate]);

  useEffect(() => {
    if (dropdownOpened) {
      drawCalender();
    }
  }, [dropdownOpened]);

  useEffect(() => {
    if (props.selected) {
      setInputValue(props.selected);
    }
    if (props.selected?.split("/").length > 2) {
      setSelectedDate(changeDateFormat(props.selected));
    }
    if (props.selected === "") {
      setSelectedDate(moment().format("MM/DD/YYYY"));
    }
  }, [props.selected]);

  function changeDateFormat(format) {
    return `${format.split("/")[1]}/${format.split("/")[0]}/${
      format.split("/")[2]
    }`;
  }

  function getValuefromLiTag() {
    // let wrapper = document.getElementById(`hg-date-wrapper${props.id}`);
    // const handleLiClick = (e) => {
    const months_list = {
      January: "01",
      February: "02",
      March: "03",
      April: "04",
      May: "05",
      June: "06",
      July: "07",
      August: "08",
      September: "09",
      October: "10",
      November: "11",
      December: "12",
    };
    return (e) => {
      let currentDateData = document.querySelector(`.current-date.${props.id}`);

      if (currentDateData) {
        let month = currentDateData.innerText.split(" ")[0];
        let selected_month = months_list[month];
        let year = currentDateData.innerText.split(" ")[1];

        if (e.target.id === "date-value") {
          setSelectedDate(`${selected_month}/${e.target.value}/${year}`);
          setInputValue(
            changeDateFormat(`${selected_month}/${e.target.value}/${year}`)
          );

          props.onChange(
            changeDateFormat(`${selected_month}/${e.target.value}/${year}`)
          );
          document.getElementById(props.id).click();
        }
      }
    };
  }

  function drawCalender() {
    const daysTag = document.querySelector(`.days.${props.id}`);
    var currentDate = document.querySelector(`.current-date.${props.id}`);
    var prevNextIcon = document.querySelectorAll(".icons span");

    // getting new date, current year and month
    let date = new Date(selectedDate);
    let currYear = date.getFullYear();
    let currMonth = date.getMonth();

    // storing full name of all months in array
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    const renderCalendar = () => {
      let firstDayofMonth = new Date(currYear, currMonth, 1).getDay(); // getting first day of month
      let lastDateofMonth = new Date(currYear, currMonth + 1, 0).getDate(); // getting last date of month
      let lastDayofMonth = new Date(
        currYear,
        currMonth,
        lastDateofMonth
      ).getDay(); // getting last day of month
      let lastDateofLastMonth = new Date(currYear, currMonth, 0).getDate(); // getting last date of previous month
      let liTag = "";

      for (let i = firstDayofMonth; i > 0; i--) {
        // creating li of previous month last days
        liTag += `<li class="inactive">${lastDateofLastMonth - i + 1}</li>`;
      }

      for (let i = 1; i <= lastDateofMonth; i++) {
        // creating li of all days of current month
        // adding active className to li if the current day, month, and year matched
        let isToday =
          i === date.getDate() &&
          currMonth === new Date(selectedDate).getMonth() &&
          currYear === new Date(selectedDate).getFullYear()
            ? "active"
            : "";

        var currentCheckDate = new Date();

        // Get the current date
        var currentCheckDay = currentCheckDate.getDate();

        // Create another Date object representing the previous date
        var previousCheckDate = new Date();
        previousCheckDate.setDate(currentCheckDay - 1);

        if (
          disableFutureDates &&
          new Date(currYear, currMonth, i) > new Date()
        ) {
          liTag += `<li class="inactive">${i}</li>`;
        } else if (
          disablePreviousDates &&
          new Date(currYear, currMonth, i) < previousCheckDate
        ) {
          liTag += `<li class="inactive">${i}</li>`;
        } else {
          liTag += `<li class="${isToday}" id="date-value" value=${i}>${i}</li>`;
        }
        // liTag += `<li class="${isToday}" id="date-value" value=${i}>${i}</li>`;
      }

      for (let i = lastDayofMonth; i < 6; i++) {
        // creating li of next month first days
        liTag += `<li class="inactive">${i - lastDayofMonth + 1}</li>`;
      }

      currentDate.innerText = `${months[currMonth]} ${currYear}`; // passing current mon and yr as currentDate text

      daysTag.innerHTML = liTag;
    };
    renderCalendar();

    prevNextIcon.forEach((icon) => {
      // getting prev and next icons
      icon.addEventListener("click", () => {
        // adding click event on both icons
        // if clicked icon is previous icon then decrement current month by 1 else increment it by 1
        currMonth = icon.id === "prev" ? currMonth - 1 : currMonth + 1;

        if (currMonth < 0 || currMonth > 11) {
          // if current month is less than 0 or greater than 11
          // creating a new date of current year & month and pass it as date value
          date = new Date(currYear, currMonth);
          currYear = date.getFullYear(); // updating current year with new date year
          currMonth = date.getMonth(); // updating current month with new date month
        } else {
          date = new Date(); // pass the current date as date value
        }
        renderCalendar(); // calling renderCalendar function
      });
    });
  }

  return (
    <Dropdown
      className={`highground-datpicker radius-4 ${
        props.isError ? "input-error" : ""
      } `}
      id={props.id}
      tabIndex={-1}
      onToggle={(e) => {
        setDropdownOpened(e);
        if (e) {
          setFirstLoad(true);
          // setTimeout(() => {
          //   // drawCalender();
          //   // getValuefromLiTag();
          // }, 10);
        } else {
        }
      }}
    >
      <Dropdown.Toggle tabIndex={-1} className="datepicker-wrapper">
        <div
          className="d-flex align-items-center hide-focus-outline position-relative"
          tabIndex={0}
        >
          {props.icon && <img alt="" className="mr-1" src={props.icon} />}
          <input
            tabIndex={-1}
            type="text"
            placeholder={`${
              props.placeholder ? props.placeholder : "Choose date"
            }`}
            className={`border-none pl-2 bg-transparent-imp ${
              props.hideCaret ? "hide-caret" : ""
            }`}
            value={
              props.selected?.split("/").length > 2
                ? moment(props.selected, "DD/MM/YYYY").format("DD MMMM YYYY")
                : ""
            }
          />
          {props.children}
          {dropdownOpened === false && props.showHistory && (
            <div className="position-absolute p-2 date-history p-3">
              <div className="f-darkgrey f-12 d-flex mb-1">History</div>
              {[1, 2, 3, 4].map((item, index) => {
                return (
                  <div className="d-flex align-items-center justify-content-between my-2 ">
                    <div className="f-grey">
                      <img
                        alt=""
                        src="/images/msp/date-history-icon.svg"
                        className="mr-2"
                      />
                      4 March 2024
                    </div>
                    <div className="f-black">24</div>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <div
          className="wrapper "
          id={`hg-date-wrapper${props.id}`}
          onClick={getValuefromLiTag()}
        >
          <header className="d-flex align-items-center justify-content-between">
            <span className={`current-date ${props.id}`}></span>
            <div className="icons">
              <span
                id="prev"
                className="material-symbols-rounded d-flex align-items-center justify-content-center"
              >
                <img alt="" src="/images/chevron-left-cal.svg" />
              </span>
              <span
                id="next"
                className="material-symbols-rounded d-flex align-items-center justify-content-center"
              >
                <img alt="" src="/images/chevron-right-cal.svg" />
              </span>
            </div>
          </header>
          <div className="calendar">
            <ul className="weeks">
              <li>Su</li>
              <li>Mo</li>
              <li>Tu</li>
              <li>We</li>
              <li>Th</li>
              <li>Fr</li>
              <li>Sa</li>
            </ul>
            <ul className={`days ${props.id}`}></ul>
          </div>

          <div className="pl-3">
            <div className="f-12 f-500 f-darkgrey my-2 ml-2">Options</div>
            <div
              className="pointer f-grey pb-2 pl-2"
              onClick={() => {
                setSelectedDate(
                  moment().subtract(1, "days").format("MM/DD/YYYY")
                );
                props.onChange(
                  moment().subtract(1, "days").format("DD/MM/YYYY")
                );
                document.getElementById(props.id).click();
              }}
            >
              Yesterday
            </div>
            <div
              className="pointer f-grey pb-2 pl-2 mb-3"
              onClick={() => {
                setSelectedDate(moment().format("MM/DD/YYYY"));
                props.onChange(moment().format("DD/MM/YYYY"));
                document.getElementById(props.id).click();
              }}
            >
              Today
            </div>
          </div>

          <hr className="m-0 hg-border" />
          <div
            className="f-grey pl-4 p-3  pointer w-fit-content"
            onClick={() => {
              setSelectedDate(moment().format("MM/DD/YYYY"));
              props.onChange("");
            }}
          >
            Clear
          </div>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default SecurityRatingsDatePicker;
