import React, { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { getAPIData } from "../../../../Utils/commonApi";
import { useAuth } from "../../../context/Auth";
import { HGHighlightText } from "../../../App/App";
import { CallToast } from "../../../PriceCalculator/modals/CustomToast";

const SelectIntegrationDropdown = ({
  children,
  linkedTools = [],
  setLinkedTools = () => { }
}) => {
  const { authTokens } = useAuth();
  const [IsDropdownOpen, setIsDropdownOpen] = useState(false);
  const [searchStr, setSearchStr] = useState("");
  const [dropdownLoading, setDropdownLoading] = useState(false);
  const [allIntegrationList, setAllIntegrationList] = useState([]);

  useEffect(() => {
    if (IsDropdownOpen) {
      setSearchStr("");
      setDropdownLoading(true);
      getAllIntegrationList();
    }
  }, [IsDropdownOpen])


  async function getAllIntegrationList() {
    await getAPIData(990, authTokens, {
    })
      .then((response) => {
        if (response?.mr?.Success) {
          setAllIntegrationList(response?.Integration_List);
          setDropdownLoading(false);
        } else {
          CallToast("Something went wrong", false, false, "v2style");
          setDropdownLoading(false);
        }
      })
      .catch((err) => {
        CallToast("Something went wrong", false, false, "v2style");
        setDropdownLoading(false);
      });
  }


  return (
    <Dropdown className="select-integration-dropdown mr-4"
      onToggle={(e) => {
        setIsDropdownOpen(e);
      }}
    >
      <Dropdown.Toggle>
        {children}
      </Dropdown.Toggle>
      <Dropdown.Menu className="p-1">
        <div className="drop-header">
          <div className="no-highlight-input w-100 d-flex align-items-center p-2">
            <div>
              <img
                width={14}
                height={14}
                src="/images/attack-surface/search-icon.svg"
                className="m-1"
                alt=""
              />
            </div>

            <input
              className="pl-2 flex-grow-1"
              placeholder="Search"
              type="text"
              value={searchStr}
              onChange={(e) => {
                setSearchStr(e.target.value);
              }}
            />
          </div>
          <div className="ml-2 f-400 f-12 f-darkgrey line-height-18 letter-spice-2 py-1">
            Integration
          </div>
        </div>
        {
          dropdownLoading ? <div className="d-flex align-items-center justify-content-center w-100 my-3">
            <div className="msp-service-update-loader ml-2 mr-1"></div>
            <div className="f-400 f-12 f-darkgrey ml-1">Loading..</div>
          </div> : <div>
            {
              allIntegrationList?.filter((item) =>
                item.IntegrationName.toLowerCase().includes(searchStr.toLowerCase()))?.length > 0 ? <div>
                {
                  allIntegrationList?.filter((item) =>
                    item.IntegrationName.toLowerCase().includes(searchStr.toLowerCase()))?.map((integration) => {
                      return (
                        <div
                          className="d-flex align-items-center bg-hover-grey p-2 pointer"
                          onClick={(e) => {
                            let updated_data = [...linkedTools];
                            let searchIndex = updated_data.findIndex(
                              (item) => item.IntegrationId === integration?.IntegrationId
                            );
                            if (searchIndex !== -1) {
                              updated_data.splice(searchIndex, 1);
                            } else {
                              updated_data.push(integration);
                            }

                            setLinkedTools(updated_data);
                            e.target
                              .closest(".select-integration-dropdown")
                              .click();
                          }}
                        >
                          <img
                            alt=""
                            width={16}
                            height={16}
                            className="mr-2 rounded-full"
                            src={`/images/attack-surface/integrations/${integration?.IntegrationId}.webp`}
                          />
                          <div className={`f-500 f-12 line-height-18 letter-space-2 ${linkedTools?.some(obj => obj?.IntegrationId === integration?.IntegrationId) ? 'txt-blue' : 'f-grey'
                            }`}>
                            <span
                              dangerouslySetInnerHTML={{
                                __html: HGHighlightText(
                                  integration?.IntegrationName,
                                  searchStr
                                ),
                              }}
                            />
                          </div>
                        </div>
                      )
                    })
                }
              </div> : <div className="d-flex align-items-center justify-content-center w-100 f-400 f-12 f-darkgrey my-3">
                {searchStr != "" ? `No results for "${searchStr}"` : 'No integration yet'}
              </div>
            }
          </div>
        }
      </Dropdown.Menu>
    </Dropdown >
  );
};

export default SelectIntegrationDropdown;