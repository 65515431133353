import React, { useState, useEffect } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { getAPIData, postData } from "../../../Utils/commonApi";
import { useAuth } from "../../context/Auth";
import { WhiteLoader } from "../../Theme/APILoaders";
import "./supply-modals.scss";
import Loader from "../../Common/loader";
import { CallToast } from "../../PriceCalculator/modals/CustomToast";

const ReassignSupplierModal = ({
  show,
  hideModal,
  oldComplianceProfileId,
  suppliersList,
  refreshData,
}) => {
  const { authTokens } = useAuth();
  const [loading, setLoading] = useState(false);
  const [supplierList, setSupplierList] = useState([]);
  const [newComplianceId, setNewComplianceId] = useState(0);
  const [removing, setRemoving] = useState(false);
  useEffect(() => {
    setNewComplianceId(0);
    setLoading(true);
    if (show) {
      getModalData();
    }
  }, [show]);

  async function getModalData() {
    await getAPIData("314", authTokens, {
      optionEnum1: oldComplianceProfileId,
    }).then((response) => {
      setLoading(false);
      if (response?.mr?.Success) {
        setSupplierList(response?.Profiles);
      }
    });
  }

  async function removeComplianceProfile() {
    setRemoving(true);
    await postData(authTokens, "DeleteAndReassignComplianceProfile", {
      oldComplianceProfileId: oldComplianceProfileId,
      newComplianceProfileId: newComplianceId,
    }).then((response) => {
      setRemoving(false);
      if (response?.Success) {
        refreshData();
        hideModal();
        CallToast(
          "Compliance profile deleted successfully",
          false,
          false,
          "v2style"
        );
      } else {
        CallToast("Something went wrong", false, false, "v2style");
      }
    });
  }
  return (
    <Modal
      animation={false}
      show={show}
      dialogClassName="reset-settings-modal reassign-supplier-modal"
      className=""
      aria-labelledby="reset-settings-modal-title-vcenter"
      centered
      scrollable
      onHide={hideModal}
    >
      <Modal.Body className="hide-scrollbar">
        <div className="f-14 f-500 text-center">
          Re-assign suppliers to a compliance profile
        </div>

        <div className="d-flex align-items-center justify-content-start">
          <span className="d-inline-flex">
            <div className="image-group d-flex">
              {suppliersList?.slice(0, 3).map((supplier, supplierIndex) => {
                return (
                  <img
                    alt=""
                    width={24}
                    height={24}
                    src={supplier?.SupplierLogo} //"/images/supply-chain/grey-tick.svg"
                    className="mr-2 rounded-full"
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null; // prevents looping
                      currentTarget.src = "/images/no-image.png";
                    }}
                  />
                );
              })}
              {suppliersList?.length > 3 && (
                <div
                  className="bg-grey rounded-full f-black f-12 f-500"
                  style={{
                    background: "#d3d8df",
                    color: "white",
                    marginLeft: "-10px",
                    zIndex: 0,
                    padding: "3px",
                  }}
                >
                  +{suppliersList?.length - 3}
                </div>
              )}
            </div>
          </span>
          <span className="d-inline-flex f-darkgrey">
            {suppliersList?.length} supplier{suppliersList?.length > 1 && "s"}
          </span>
        </div>

        <div className="f-12 f-500 f-darkgrey">Choose compliance profile</div>

        {loading ? (
          <div
            className="d-flex align-items-center justify-content-center w-100 "
            style={{
              minHeight: "200px",
            }}
          >
            <Loader />
          </div>
        ) : (
          supplierList &&
          supplierList.map((supplier, supplierIndex) => {
            return (
              <div className="d-flex align-items-center my-2">
                <div className="mb-3 ml-2">
                  <Form.Group
                    controlId={`card  ` + supplier?.ObjectId}
                    className={`round-checkbox blue-checkbox mb-1    ${
                      oldComplianceProfileId === supplier?.ObjectId
                        ? "disabled-radio"
                        : "radio-shadow "
                    }  `}
                  >
                    <Form.Check
                      type={"radio"}
                      disabled={oldComplianceProfileId === supplier?.ObjectId}
                      // checked={

                      // }
                      name={`check `}
                      label=""
                      // disabled={supplierIndex === 0}
                      checked={supplier?.Selected}
                      onChange={() => {}}
                      onClick={(e) => {
                        let updated_data = [...supplierList].map((el) => ({
                          ...el,
                          Selected: false,
                        }));
                        updated_data[supplierIndex].Selected = true;
                        setSupplierList(updated_data);
                        setNewComplianceId(supplier?.ObjectId);
                        return false;
                      }}
                    />
                  </Form.Group>
                </div>
                <div
                  className={`${
                    oldComplianceProfileId === supplier?.ObjectId
                      ? "f-darkgrey"
                      : "f-black"
                  } f-14 f-500 ml-3`}
                >
                  {supplier?.ProfileName}
                </div>
              </div>
            );
          })
        )}
      </Modal.Body>

      <Modal.Footer>
        <div className="footer d-flex justify-content-end">
          <Button className="hg-cancel-btn f-500" onClick={hideModal}>
            Cancel
          </Button>
          <Button
            className={`${
              newComplianceId === 0 ? "disabled-btn" : "hg-red-btn "
            } f-500 ml-2`}
            onClick={removeComplianceProfile}
            disabled={newComplianceId === 0 ? true : false}
          >
            {removing ? (
              <div className="d-flex align-items-center">
                <WhiteLoader />
                <span className="d-flex align-items-center">Removing...</span>
              </div>
            ) : (
              "Remove"
            )}
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default ReassignSupplierModal;
