export const data_to_new_tabs = {
  tabName: "My Scenario",
  clr: "#40d88e",
  data: [
    {
      selected: [],
      selectedRadio: [],
      name: "Identify",
      lists: [
        {
          id: 1,
          title: "Identify ID1",
          subtitle: "Base Pack for Identifying Cyber Security Risks",
          amt: "£47.50/mo",
          includes: [
            {
              title: "Everything that in Identify ID1",
              icon: "/images/calculator/plussm.svg",
            },
            {
              title: "Semi-Annual Risk Assessment",
              icon: "/images/calculator/ticksm.svg",
            },
            {
              title: "Semi-Annual Cyber Essentials Audit",
              icon: "/images/calculator/ticksm.svg",
            },
            {
              title: "Annual Governance Audit",
              icon: "/images/calculator/ticksm.svg",
            },
          ],
        },
        {
          id: 2,
          title: "Identify ID2",
          subtitle:
            "More Frequent RA Assessments, CE Audits + an Annual Governance Review",
          amt: "£95.50/mo",
          includes: [
            {
              title: "Everything that in Identify ID1",
              icon: "/images/calculator/plussm.svg",
            },
            {
              title: "Semi-Annual Risk Assessment",
              icon: "/images/calculator/ticksm.svg",
            },
            {
              title: "Semi-Annual Cyber Essentials Audit",
              icon: "/images/calculator/ticksm.svg",
            },
            {
              title: "Annual Governance Audit",
              icon: "/images/calculator/ticksm.svg",
            },
          ],
        },
        {
          id: 20,
          title: "Identify ID3",
          subtitle: "More Frequent Governance Audits",
          amt: "£35.50/mo",
          includes: [
            {
              title: "Everything that in Identify ID1",
              icon: "/images/calculator/plussm.svg",
            },
            {
              title: "Semi-Annual Risk Assessment",
              icon: "/images/calculator/ticksm.svg",
            },
            {
              title: "Semi-Annual Cyber Essentials Audit",
              icon: "/images/calculator/ticksm.svg",
            },
            {
              title: "Annual Governance Audit",
              icon: "/images/calculator/ticksm.svg",
            },
          ],
        },
      ],
    },
    {
      selected: [],
      selectedRadio: [],
      name: "Protect",
      lists: [
        {
          id: 3,
          title: "Protect P1",
          subtitle: "Base Pack for adding Active Protection Technologies",
          amt: "£10/mo/user",
        },
        {
          id: 4,
          title: "Protect P2",
          subtitle:
            "Enhanced protection to protect data at rest via encryption for all end user devices, and improved security configuration mgmt.",
          amt: "£5/device",
        },
        {
          id: 31,
          title: "Protect P3",
          subtitle: "Builds on P1+P2 to active manage DLP controls + logs",
          amt: "£10/device",
        },
        {
          id: 32,
          title: "Protect P.Mobile",
          subtitle:
            "More Frequent RA Assessments, CE Audits + an Annual Governance Review",
          amt: "£17/mo/device",
          checkbox: true,
        },
        {
          id: 37,
          title: "Protect P.Server",
          subtitle:
            "Base Pack for adding Active Protection Technologies to Servers",
          amt: "£17/mo/device",
          checkbox: true,
        },
      ],
    },
    {
      selected: [],
      selectedRadio: [],
      name: "Detect",
      lists: [
        {
          id: 41,
          title: "Detect D1",
          subtitle: "Base Pack for adding Active Detection Technologies",
          amt: "£20/mo/user",
          checkbox: true,
        },
        {
          id: 5,
          title: "Detect D1.DWD",
          subtitle: "DarkWeb per Domain Bolt On",
          amt: "£20/mo/user",
          checkbox: true,
        },
        {
          id: 6,
          title: "Detect D1.SIEM",
          subtitle: "SIEM/IDS per User Bolt-On",
          amt: "£20/mo/user",
          checkbox: true,
          includes: [
            {
              title: "Everything that in Identify ID1",
              icon: "/images/calculator/plussm.svg",
            },
          ],
        },
        {
          id: 97,
          title: "Detect D.VULMGM",
          checkbox: true,
          subtitle:
            "Enhanced Vulnerability Management extending to Internal IT Infrastructure",
          amt: "POD",
        },
      ],
    },
    {
      selected: [],
      selectedRadio: [],
      name: "Respond",
      lists: [
        {
          id: 7,
          title: "Response R1",
          subtitle:
            "Base Pack to provide initial Incident Response (Respond + Triage) to determine if Full IR required",
          amt: "£295/mo",
        },
        {
          id: 8,
          title: "Response R2",
          subtitle: "Enhanced SLA for more serious IR requirements",
          amt: "£25/mo",
        },
        {
          id: 53,
          title: "Response R1.LOG",
          subtitle: "Adds Log Collection for improved IR capabilities",
          amt: "£25/mo/user",
          checkbox: true,
        },
        {
          id: 54,
          title: "Response R1.IRH",
          subtitle:
            "Adds IR Hours for rapid + unimpeded IR without engaging insurance",
          amt: "£2600",
          checkbox: true,
        },
      ],
    },
    {
      selected: [],
      selectedRadio: [],
      name: "Recover",
      lists: [
        {
          id: 9,
          title: "Recover RV.MANAGE",
          subtitle:
            "Regular pro-active testing of backups and assisted execution of BCDR Plan",
          amt: "£300/mo",
        },
        {
          id: 10,
          title: "Recover RV.TEST",
          subtitle:
            "Regular pro-active testing of backups and assisted execution of BCDR Plan",
          amt: "£375/mo",
        },
        {
          id: 100,
          title: "Recover RV.O365",
          checkbox: true,
          subtitle:
            "Office 365 Backup for Exchange, SharePoint, and OneDrive (O365)",
          amt: "£300/mo",
        },
        {
          id: 101,
          title: "Recover RV.CLOUD1TB",
          checkbox: true,
          subtitle: "Cloud Backup for Desktops, Laptops & Servers, Per TB",
          amt: "£375/mo",
        },
      ],
    },
  ],
};

export const initial_Tab_Data = [
  {
    tabName: "Your current posture",
    clr: "#ffac2cd9",
    UserAndDeviceCount: {
      NoOfUsers: 0,
      NoOfWorkstation: 0,
      NoOfServices: 0,
      NoOfMobileDevices: 0,
      NoOfDomains: 0,
    },
    data: [
      {
        selected: [],
        selectedRadio: [],
        name: "Identify",
        lists: [
          {
            id: 1,
            title: "Identify ID1",
            subtitle: "Base Pack for Identifying Cyber Security Risks",
            amt: "£47.50/mo",
            includes: [
              {
                title: "Everything that in Identify ID1",
                icon: "/images/calculator/plussm.svg",
              },
              {
                title: "Semi-Annual Risk Assessment",
                icon: "/images/calculator/ticksm.svg",
              },
              {
                title: "Semi-Annual Cyber Essentials Audit",
                icon: "/images/calculator/ticksm.svg",
              },
              {
                title: "Annual Governance Audit",
                icon: "/images/calculator/ticksm.svg",
              },
            ],
          },
          {
            id: 2,
            title: "Identify ID2",
            subtitle:
              "More Frequent RA Assessments, CE Audits + an Annual Governance Review",
            amt: "£95.50/mo",
            includes: [
              {
                title: "Everything that in Identify ID1",
                icon: "/images/calculator/plussm.svg",
              },
              {
                title: "Semi-Annual Risk Assessment",
                icon: "/images/calculator/ticksm.svg",
              },
              {
                title: "Semi-Annual Cyber Essentials Audit",
                icon: "/images/calculator/ticksm.svg",
              },
              {
                title: "Annual Governance Audit",
                icon: "/images/calculator/ticksm.svg",
              },
            ],
          },
          {
            id: 20,
            title: "Identify ID3",
            subtitle: "More Frequent Governance Audits",
            amt: "£35.50/mo",
            includes: [
              {
                title: "Everything that in Identify ID1",
                icon: "/images/calculator/plussm.svg",
              },
              {
                title: "Semi-Annual Risk Assessment",
                icon: "/images/calculator/ticksm.svg",
              },
              {
                title: "Semi-Annual Cyber Essentials Audit",
                icon: "/images/calculator/ticksm.svg",
              },
              {
                title: "Annual Governance Audit",
                icon: "/images/calculator/ticksm.svg",
              },
            ],
          },
        ],
      },
      {
        selected: [],
        selectedRadio: [],
        name: "Protect",
        lists: [
          {
            id: 3,
            title: "Protect P1",
            subtitle: "Base Pack for adding Active Protection Technologies",
            amt: "£10/mo/user",
          },
          {
            id: 4,
            title: "Protect P2",
            subtitle:
              "Enhanced protection to protect data at rest via encryption for all end user devices, and improved security configuration mgmt.",
            amt: "£5/device",
          },
          {
            id: 31,
            title: "Protect P3",
            subtitle: "Builds on P1+P2 to active manage DLP controls + logs",
            amt: "£10/device",
          },
          {
            id: 32,
            title: "Protect P.Mobile",
            subtitle:
              "More Frequent RA Assessments, CE Audits + an Annual Governance Review",
            amt: "£17/mo/device",
            checkbox: true,
          },
          {
            id: 37,
            title: "Protect P.Server",
            subtitle:
              "Base Pack for adding Active Protection Technologies to Servers",
            amt: "£17/mo/device",
            checkbox: true,
          },
        ],
      },
      {
        selected: [],
        selectedRadio: [],
        name: "Detect",
        lists: [
          {
            id: 41,
            title: "Detect D1",
            subtitle: "Base Pack for adding Active Detection Technologies",
            amt: "£20/mo/user",
            checkbox: true,
          },
          {
            id: 5,
            title: "Detect D1.DWD",
            subtitle: "DarkWeb per Domain Bolt On",
            amt: "£20/mo/user",
            checkbox: true,
          },
          {
            id: 6,
            title: "Detect D1.SIEM",
            subtitle: "SIEM/IDS per User Bolt-On",
            amt: "£20/mo/user",
            checkbox: true,
            includes: [
              {
                title: "Everything that in Identify ID1",
                icon: "/images/calculator/plussm.svg",
              },
            ],
          },
          {
            id: 97,
            title: "Detect D.VULMGM",
            checkbox: true,
            subtitle:
              "Enhanced Vulnerability Management extending to Internal IT Infrastructure",
            amt: "POD",
          },
        ],
      },
      {
        selected: [],
        selectedRadio: [],
        name: "Respond",
        lists: [
          {
            id: 7,
            title: "Response R1",
            subtitle:
              "Base Pack to provide initial Incident Response (Respond + Triage) to determine if Full IR required",
            amt: "£295/mo",
          },
          {
            id: 8,
            title: "Response R2",
            subtitle: "Enhanced SLA for more serious IR requirements",
            amt: "£25/mo",
          },
          {
            id: 53,
            title: "Response R1.LOG",
            subtitle: "Adds Log Collection for improved IR capabilities",
            amt: "£25/mo/user",
            checkbox: true,
          },
          {
            id: 54,
            title: "Response R1.IRH",
            subtitle:
              "Adds IR Hours for rapid + unimpeded IR without engaging insurance",
            amt: "£2600",
            checkbox: true,
          },
        ],
      },
      {
        selected: [],
        selectedRadio: [],
        name: "Recover",
        lists: [
          {
            id: 9,
            title: "Recover RV.MANAGE",
            subtitle:
              "Regular pro-active testing of backups and assisted execution of BCDR Plan",
            amt: "£300/mo",
          },
          {
            id: 10,
            title: "Recover RV.TEST",
            subtitle:
              "Regular pro-active testing of backups and assisted execution of BCDR Plan",
            amt: "£375/mo",
          },
          {
            id: 100,
            title: "Recover RV.O365",
            checkbox: true,
            subtitle:
              "Office 365 Backup for Exchange, SharePoint, and OneDrive (O365)",
            amt: "£300/mo",
          },
          {
            id: 101,
            title: "Recover RV.CLOUD1TB",
            checkbox: true,
            subtitle: "Cloud Backup for Desktops, Laptops & Servers, Per TB",
            amt: "£375/mo",
          },
        ],
      },
    ],
  },
];
