import React, { useState } from "react";
import { Form, Modal, Row, Col, InputGroup, Button } from "react-bootstrap";
import "./billing-modals.scss";
const EditPaymentModal = ({ show, hideModal }) => {
  const [page, setPage] = useState("initial");
  const [cardNumber, setCardNumber] = useState("");
  const [monthYear, setMonthYear] = useState("");
  const [CVV, setCVV] = useState("");
  const ActiveSubscriptions = [
    {
      plan: "Premium Plan",
      desc: "£395 / month · Billed Monthly",
      imageURL: "/images/settings-v2/premium-plan-sub.svg",
    },
    {
      plan: "Incident Response Handling",
      desc: "£30 /domain /month · Billed Monthly",
      imageURL: "/images/settings-v2/incident-sub.svg",
    },
    {
      plan: "Incident Response Handling",
      desc: "£360 /month · Billed Monthly",
      imageURL: "/images/settings-v2/premium-sub2.svg",
    },
  ];
  return (
    <Modal
      animation={false}
      // show={true}
      show={show}
      dialogClassName="billing-payment-modal"
      className=""
      aria-labelledby="billing-payment-modal"
      centered
      scrollable
      onHide={hideModal}
    >
      <Modal.Header className="text-center">
        <div className="w-100 mb-0 font-bold-14" style={{ fontWeight: "600" }}>
          Edit Payment Method
        </div>

        <span className="cursor-pointer" onClick={hideModal}>
          <img src="/images/icons/close.svg" alt="" />
        </span>
      </Modal.Header>
      <Modal.Body className="px-4">
        <>
          <div className="title mt-2 mb-2">Payment Information</div>

          <div className="card-details d-flex align-items-center w-100">
            <img src="/images/settings-v2/card.svg" />
            <input
              className="card-number "
              type="number"
              placeholder="Card Number"
              value={cardNumber}
              onChange={(e) => setCardNumber(e.target.value)}
            />
            <input
              className="card-month "
              value={monthYear}
              onChange={(e) => setMonthYear(e.target.value)}
              type="text"
              placeholder="MM/YY"
              autoComplete="new-password"
            />
            <input
              className="card-cvv "
              value={CVV}
              onChange={(e) => setCVV(e.target.value)}
              type="password"
              placeholder="CVV"
              autoComplete="new-password"
            />
          </div>
          <div className="card-details my-1">
            <input type="text" className="w-100" placeholder="Name on card" />
          </div>
          <div className="title mt-3">Billing Information</div>
          <div className="card-details my-1">
            <input type="text" className="w-100" placeholder="Company name" />
          </div>
          <Form.Group>
            <InputGroup className="mt-10px grey-select customerStyle border-4">
              <Form.Control
                style={{ height: "40px" }}
                as="select"
                className={"pt-0 pb-0"}
                defaultValue={"GB"}
                onChange={{}}
              >
                <option>United Kingdom</option>
                <option>Spain</option>
              </Form.Control>
            </InputGroup>
          </Form.Group>
          <div className="card-details my-1">
            <input
              type="text"
              className="w-100"
              placeholder="Company address"
            />
          </div>
          <div className="card-details my-1">
            <input
              type="text"
              className="w-100"
              placeholder="Company registration"
            />
          </div>
          <div className="card-details my-1">
            <input type="text" className="w-100" placeholder="VAT" />
          </div>
        </>
        <hr />
        <div className="title mb-2">Using for subscriptions</div>

        <Row className="option-wrapper m-0">
          {ActiveSubscriptions.map((sub, index) => {
            return (
              <>
                <Col
                  key={index}
                  xs={12}
                  className="d-flex  align-items-center p-3"
                >
                  <img src={sub.imageURL} className="mr-2" />
                  <div>
                    <p className="title m-0">{sub.plan} </p>
                    <p className="subtitle m-0">{sub.desc} </p>
                  </div>
                </Col>
                {index !== ActiveSubscriptions.length - 1 && (
                  <hr className="m-0" />
                )}
              </>
            );
          })}
        </Row>
      </Modal.Body>

      <Modal.Footer>
        <div className="d-flex justify-content-between align-items-center">
          <div className="d-flex align-items-center remove-icon pointer">
            <img src="/images/settings-v2/remove-icon.svg" className="mx-2" />
            Remove
          </div>
          <div>
            <Button className="add-cancel-btn" onClick={hideModal}>
              Cancel
            </Button>
            <Button className="add-paymnt-btn" onClick={hideModal}>
              Update
            </Button>
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default EditPaymentModal;
