// import { fromJS } from "immutable";
import { COMPANY_DETAIL } from "./constants";

export const initState = {
  companyDetail: {},
};

const CompanyReducer = (state = initState, action) => {
  switch (action.type) {
    case COMPANY_DETAIL:
      return {
        ...state,
        companyDetail: { ...state.companyDetail, ...action.data },
      };

    default:
      return state;
  }
};

export default CompanyReducer;
