import React, { useState, useEffect } from "react";
import {
  Button,
  Modal,
  Row,
  Col,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import ContentLoader from "react-content-loader";
import {
  getAPIData,
  getDefaultAssets,
  postRequest as StopWelcomeModalApi,
} from "../../../Utils/commonApi";
import Loader from "../../Common/loader";
import { useAuth } from "../../context/Auth";
import { CallToast } from "./CustomToast";

import "./style.scss";

const SecurityPacksModal = (props) => {
  const { isOpen } = props;
  const [loading, setLoading] = useState(false);
  const { authTokens } = useAuth();
  const [data, setData] = useState([]);
  useEffect(() => {
    if (isOpen) {
      setLoading(true);
      const getPagedata = async () => {
        const response = await getAPIData(219, authTokens)
          .then((response) => {
            if (response?.mr?.Success) {
              setData(response.PageContent);
            } else {
              isCloseModal();
              CallToast("Something went wrong");
            }

            setLoading(false);
          })
          .catch((error) => {
            console.log(error);
            setLoading(false);
            CallToast("Something went wrong");
          });
      };
      getPagedata();
    }
  }, [isOpen]);

  const [activeCount, setActiveCount] = useState(1);

  const StopWelcomeModals = () => {
    StopWelcomeModalApi(authTokens, "SubmitNotificationSettings", {
      SettingOwner: 0,
      OwnerType: 1,
      NotificationName: "ShowPricingModal",
      NotificationValue: 0,
      AccessKey: JSON.parse(localStorage.getItem("user_MessageObj")),
    })
      .then((res) => {
        if (!res.Success) {
          CallToast("Something went wrong");
        }
      })
      .catch((err) => {
        CallToast("Something went wrong");
      });
  };
  const Next = () => {
    const incr = activeCount + 1;
    if (incr <= data?.length) {
      setActiveCount(incr);
    }
  };

  const Back = () => {
    const incr = activeCount - 1;
    if (incr >= 1) {
      setActiveCount(incr);
    }
  };
  const isCloseModal = () => {
    props.isClose();
    setActiveCount(1);
  };
  return (
    <Modal
      animation={false}
      show={props.isOpen}
      dialogClassName="modal-50w"
      className="integrationModal  centerModal dashboardModal"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      scrollable
      onHide={() => {
        isCloseModal();
        props.openSkipModal();
      }}
    >
      <Modal.Header className="text-center ">
        {activeCount > 1 && (
          <span class="cursor-pointer" onClick={() => Back()}>
            <img src="/images/icons/arrowLeft.svg" alt="" />
          </span>
        )}
        <h1 className="w-100 mb-0 mt-0" style={{ fontSize: "16px" }}>
          {" "}
          {data[activeCount - 1]?.PageTitle}
        </h1>
        <span
          className="cursor-pointer"
          onClick={() => {
            props.isClose();
            props.openSkipModal();
          }}
        >
          <img src="/images/icons/close.svg" alt="" />
        </span>
      </Modal.Header>
      <Modal.Body>
        {loading && (
          <div className="security-pack-loader">
            <Loader />
          </div>
        )}
        <Row className="m-0">
          <Col md={12} className="p-0">
            {/* <img src="https://c.tenor.com/64UaxgnTfx0AAAAC/memes-loading.gif" /> */}

            {activeCount === 1 && (
              <div className="image-loader">
                <img
                  src={`/images/managedSecurityModal/${
                    data[activeCount - 1]?.Image1URL
                  }`}
                  alt=""
                  className="welcome-modal-image"
                />
              </div>
            )}
            {activeCount === 2 && (
              <div className="image-loader">
                <img
                  src={`/images/managedSecurityModal/${
                    data[activeCount - 1]?.Image1URL
                  }`}
                  alt=""
                  className="welcome-modal-image"
                />
              </div>
            )}
            {activeCount === 3 && (
              <div className="image-loader">
                <img
                  src={`/images/managedSecurityModal/${
                    data[activeCount - 1]?.Image1URL
                  }`}
                  alt=""
                  className="welcome-modal-image"
                />
              </div>
            )}
            {activeCount === 4 && (
              <div className="image-loader">
                <img
                  src={`/images/managedSecurityModal/${
                    data[activeCount - 1]?.Image1URL
                  }`}
                  alt=""
                  className="welcome-modal-image"
                />
              </div>
            )}

            <h1 className="mt-5">{data[activeCount - 1]?.TextHeading}</h1>

            <div
              dangerouslySetInnerHTML={{
                __html: data[activeCount - 1]?.TextHtml,
              }}
            />
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Row className="m-0" style={{ justifyContent: "space-between" }}>
          <Col
            md={4}
            className="crsrpoint p-0 d-flex align-items-center justify-content-left"
          >
            {activeCount > 0 && (
              <span
                style={{ color: "#616778" }}
                onClick={() => {
                  isCloseModal();
                  props.openSkipModal();
                }}
              >
                Skip
              </span>
            )}
          </Col>
          <Col
            md={4}
            className="p-0 text-center d-flex align-items-center justify-content-center"
          >
            {/* {activeCount}/{data?.length} */}
            {activeCount}/{data[activeCount - 1]?.PageCount || 4}
          </Col>
          <Col md={4} className="p-0 text-right">
            <Button
              variant="primary"
              type="submit"
              className="float-right securityBtnmodal"
              onClick={
                () => {
                  if (activeCount == data?.length) {
                    getDefaultAssets(authTokens, 222);
                    StopWelcomeModals();
                    isCloseModal();
                    props.openAssetModal();
                  } else {
                    Next();
                  }
                }

                // activeCount == data?.length
                //   ? () => isCloseModal()
                //   : () => Next()
              }
            >
              {data[activeCount - 1]?.ButtonText || "Next"}
              {/* {activeCount == data?.length ? "Let's get Started" : "Next"} */}
            </Button>
          </Col>
        </Row>
      </Modal.Footer>
    </Modal>
  );
};

export default SecurityPacksModal;
