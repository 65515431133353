import React from "react";
import { Dropdown } from "react-bootstrap";
import { ContributorGetAPIData, getAPIData } from "../../../Utils/commonApi";
import { useAuth } from "../../context/Auth";
import { CallToast } from "../../PriceCalculator/modals/CustomToast";
import "../supplier-profiler.scss";

const RevokeDropdown = ({
  questionId,
  obj,
  refreshData,
  isContributorView,
}) => {
  const { authTokens } = useAuth();
  async function revoke() {
    if (isContributorView) {
      await ContributorGetAPIData(765, authTokens, {
        optionEnum1: questionId,
        // optionEnum2: obj.ObjectId,
        optionEnum2: obj.SupplierOrContributorId,
      })
        .then((response) => {
          if (response?.Success) {
            refreshData();
          } else {
            CallToast("Something went wrong", false, false, "v2style");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      await getAPIData(765, authTokens, {
        optionEnum1: questionId,
        // optionEnum2: obj.ObjectId,
        optionEnum2: obj.SupplierOrContributorId,
      })
        .then((response) => {
          if (response?.Success) {
            refreshData();
          } else {
            CallToast("Something went wrong", false, false, "v2style");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }
  const handleChildToggle = (e) => {
    e.stopPropagation(); // Предотвращаем всплытие события, чтобы не достигало родительского дропдауна
    // Обработка открытия/закрытия дочернего дропдауна
  };

  return (
    <Dropdown
      className="hg-white-dropdown user-detail-dropdown supplier-menu revoke-drop-position"
      style={{ inset: "auto -24px auto auto !important" }}
      onToggle={(e) => {
        // handleChildToggle();
      }}
      onClick={handleChildToggle} 
    >
      <Dropdown.Toggle
        className="d-flex custom-dropdown-toggle hide-after  f-gray-imp"
        style={{ padding: 0 }}
      >
        Revoke
      </Dropdown.Toggle>
      <Dropdown.Menu style={{ width: " 280px" }}>
        <div className="pointer f-black dropdown-revoke-text">
          Do you want to revoke{" "}
          <span className="font-weight-bold">{obj.ContributorName}</span> access
          to this profiling?
        </div>
        <div className="dropdown-revoke">
          <div
            className=" pointer revoke-btn hg-red-btn "
            onClick={(e) => {
              revoke();
              e.target.closest(".hg-white-dropdown").click();
            }}
          >
            <div>Yes, revoke</div>
          </div>

          <div
            className="pointer f-grey bg-grey revoke-btn"
            onClick={(e) => {
              e.target.closest(".hg-white-dropdown").click();
            }}
          >
            No, cancel
          </div>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default RevokeDropdown;
