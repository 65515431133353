export function clearLocalStorage() {
  window.localStorage.removeItem("ajs_anonymous_id");
  window.localStorage.removeItem("ajs_user_id");
  window.localStorage.removeItem("currentUrl");
  window.localStorage.removeItem("dasboardLoader");
  window.localStorage.removeItem("object_Data");
  window.localStorage.removeItem("persist:Cyber Care");
  window.localStorage.removeItem("redirectTab");
  window.localStorage.removeItem("tokens");
  window.localStorage.removeItem("userAuthenticated");
  window.localStorage.removeItem("userType");
  window.localStorage.removeItem("user_MessageObj");
  window.localStorage.removeItem("token_time");
  window.localStorage.removeItem("openedScenarios")
  console.log("local storage cleared");
  console.log("local storage cleared CAlllled");
}
