import React, { Fragment, useState, useEffect } from "react";
import { Form, InputGroup, FormControl } from "react-bootstrap";
import { useDb } from "../../context/db";
import { getAPIData, postRequest } from "../../../Utils/commonApi";
import ImageUploadModal from "../../SystemSettings/UsersAndDevices/imageUploadModal";
import { useAuth } from "../../context/Auth";
import CircularProgressBar from "../../CircularProgressBar";
import { FiCheck, FiX } from "react-icons/fi";
import { ToastContainer, toast } from "react-toastify";
import ContentLoader from "react-content-loader";

const General = () => {
  useEffect(() => {
    getGeneralInfo();
  }, []);

  const { authTokens } = useAuth();
  const [showUploadModal, setShowUploadModal] = useState(false);
  const { dbObj } = useDb();
  const [generalInfo, setGeneralInfo] = useState([]);
  const [showToast, setShowToast] = useState(false);

  async function getGeneralInfo() {
    const result = await getAPIData("99", authTokens);
    setGeneralInfo(result);
  }

  async function handleOnChange(e, fieldName) {
    const inputs = {
      NotificationName: fieldName,
      NotificationValue: e.target.value,
      SettingOwner: generalInfo.objId_SettingOwner,
      OwnerType: 0,
    };
    setShowToast(true);
    await postRequest(authTokens, "SubmitNotificationSettings", inputs)
      .then((response) => {
        if (response.Success) {
          toast.dark(
            <span>
              <FiCheck style={{ marginRight: "10px" }} />
              <span style={{ width: "500px" }}>Updated successfully</span>
              <span className="float-right">
                <CircularProgressBar durationInSec="30" />
              </span>
            </span>
          );
        } else {
          toast.dark(
            <span>
              <FiX style={{ marginRight: "10px", color: "#f36060" }} />
              <span style={{ width: "500px", color: "#f36060" }}>
                Failed to Update
              </span>
              <span className="float-right">
                <CircularProgressBar durationInSec="30" />
              </span>
            </span>
          );
        }
      })
      .catch(() => {
        toast.dark(
          <span>
            <FiX style={{ marginRight: "10px", color: "#f36060" }} />
            <span style={{ width: "500px", color: "#f36060" }}>
              Unable to update
            </span>
            <span className="float-right">
              <CircularProgressBar durationInSec="30" />
            </span>
          </span>
        );
      });
  }
  return generalInfo.length == 0 ? (
    <ContentLoader
      style={{ marginTop: "-80px" }}
      speed={2}
      width={'100%'}
      height={'100%'}
      viewBox="0 -80 1500 1000"
      backgroundColor="#f1f2f7e8"
      foregroundColor="#ebecf1"
    >
      <rect
        x="0"
        y=""
        rx="15"
        ry="15"
        height={300}
        width={1500}
        style={{ height: "80", width: "80" }}
      />
      <rect
        x="100"
        y="20"
        rx="2"
        ry="2"
        height={300}
        width={1500}
        style={{ height: "40", width: "120" }}
      />
      <rect
        x="0"
        y="120"
        rx="15"
        ry="15"
        height={300}
        width={1500}
        style={{ height: "220", width: "100%" }}
      />

      <rect
        x="0"
        y="360"
        rx="15"
        ry="15"
        height={300}
        width={1500}
        style={{ height: "220", width: "100%" }}
      />

      <rect
        x="0"
        y="600"
        rx="15"
        ry="15"
        height={300}
        width={1500}
        style={{ height: "220", width: "100%" }}
      />
    </ContentLoader>
  ) : (
    <Fragment>
      {showToast && (
        <ToastContainer
          position="bottom-center"
          autoClose={3000}
          hideProgressBar
        />
      )}
      <div className="d-flex mt40px">
        <div className="logo-upload">
          {dbObj && dbObj.OrgLogo ? (
            <img
              className="border-grey camera-icon cursor-pointer"
              src={dbObj.OrgLogo}
              alt=""
              onClick={() => setShowUploadModal(true)}
              width="79"
              style={{ borderRadius: "16px" }}
            />
          ) : (
            <img
              className="border-grey camera-icon cursor-pointer"
              width="32"
              src={`/images/icons/blueCamera.svg`}
              alt=""
              onClick={() => setShowUploadModal(true)}
            />
          )}
        </div>
        <div className="align-self-center ml24px" style={{ width: "223px" }}>
          <div
            className="light-blue-btn"
            style={{ width: "120px" }}
            onClick={() => setShowUploadModal(true)}
          >
            Upload Logo
          </div>
        </div>
      </div>
      <div className="settings_container mt40px">
        <div className="heading3">Company info</div>
        <InputGroup
          className="mt-16px grey-input mb8px"
          style={{ width: "400px" }}
        >
          <InputGroup.Prepend>
            <InputGroup.Text className="greyText">
              Company Name:
            </InputGroup.Text>
          </InputGroup.Prepend>
          <FormControl
            type="text"
            defaultValue={generalInfo.CompanyName}
            onBlur={(e) => handleOnChange(e, "SOcompany")}
          />
        </InputGroup>

        <InputGroup
          className="mt-16px grey-select customerStyle"
          style={{ width: "400px" }}
        >
          <InputGroup.Prepend>
            <InputGroup.Text className="greyText">Industry:</InputGroup.Text>
          </InputGroup.Prepend>
          <Form.Control
            as="select"
            defaultValue={generalInfo.Industry}
            name="industry"
            onBlur={ (e) => handleOnChange(e, "SOindustry")}
            className="customArrowSelect"
          >
            {generalInfo.DDLMembers &&
              generalInfo.DDLMembers.map((val, i) => {
                if (val.DDLName == "Industry") {
                  return val.DDLMemberList.map((data) => (
                    <option value={data.DDLId} key={data.DDLId}>
                      {data.DDLText}
                    </option>
                  ));
                }
              })}
          </Form.Control>
        </InputGroup>

        <InputGroup
          className="mt-16px grey-select customerStyle"
          style={{ width: "400px" }}
        >
          <InputGroup.Prepend>
            <InputGroup.Text className="greyText">
              Qty of Users:
            </InputGroup.Text>
          </InputGroup.Prepend>
          <Form.Control
            as="select"
            onBlur={ (e) => handleOnChange(e, "SOusers")}
            defaultValue={generalInfo.Industry}
            name="qtyusers"
            className="customArrowSelect"
          >
            {generalInfo.DDLMembers &&
              generalInfo.DDLMembers.map((val, i) => {
                if (val.DDLName == "Qty of Users") {
                  return val.DDLMemberList.map((data) => (
                    <option value={data.DDLId} key={data.DDLId}>
                      {" "}
                      {data.DDLText}{" "}
                    </option>
                  ));
                }
              })}
          </Form.Control>
        </InputGroup>
      </div>
      <div className="settings_container mt16px mb16px">
        <div className="heading3">Address</div>
        <InputGroup
          className="mt-16px grey-input mb8px"
          style={{ width: "400px" }}
        >
          <InputGroup.Prepend>
            <InputGroup.Text className="greyText">Address 1:</InputGroup.Text>
          </InputGroup.Prepend>
          <FormControl
            type="text"
            defaultValue={generalInfo.Address1}
            onBlur={(e) => handleOnChange(e, "SOAdd1")}
            placeholder="Enter adress here"
          />
        </InputGroup>
        <InputGroup
          className="mt-16px grey-input mb8px"
          style={{ width: "400px" }}
        >
          <InputGroup.Prepend>
            <InputGroup.Text className="greyText">Address 2:</InputGroup.Text>
          </InputGroup.Prepend>
          <FormControl
            type="text"
            placeholder="Enter adress here"
            onBlur={(e) => handleOnChange(e, "SOAdd2")}
            defaultValue={generalInfo.Address2}
          />
        </InputGroup>
        <InputGroup
          className="mt-16px grey-input mb8px"
          style={{ width: "400px" }}
        >
          <InputGroup.Prepend>
            <InputGroup.Text className="greyText">Address 3:</InputGroup.Text>
          </InputGroup.Prepend>
          <FormControl
            type="text"
            placeholder="Enter adress here"
            onBlur={(e) => handleOnChange(e, "SOAdd3")}
            defaultValue={generalInfo.Address3}
          />
        </InputGroup>
        <InputGroup
          className="mt-16px grey-input mb8px"
          style={{ width: "400px" }}
        >
          <InputGroup.Prepend>
            <InputGroup.Text className="greyText">City:</InputGroup.Text>
          </InputGroup.Prepend>
          <FormControl
            type="text"
            defaultValue={generalInfo.City}
            onBlur={(e) => handleOnChange(e, "SOcity")}
          />
        </InputGroup>
        <div className="d-flex" style={{ width: "400px" }}>
          <InputGroup
            className="mt-16px grey-input mb8px"
            style={{ width: "196px" }}
          >
            <InputGroup.Prepend>
              <InputGroup.Text className="greyText">Postcode:</InputGroup.Text>
            </InputGroup.Prepend>
            <FormControl
              type="text"
              defaultValue={generalInfo.Postcode}
              onBlur={(e) => handleOnChange(e, "SOpostcode")}
            />
          </InputGroup>
          <InputGroup
            className="mt-16px grey-input mb8px ml-auto"
            style={{ width: "196px" }}
          >
            <InputGroup.Prepend>
              <InputGroup.Text className="greyText">County:</InputGroup.Text>
            </InputGroup.Prepend>
            <FormControl
              type="text"
              defaultValue={generalInfo.County}
              onBlur={(e) => handleOnChange(e, "SOcounty")}
            />
          </InputGroup>
        </div>
        <InputGroup
          className="mt-16px grey-input mb8px"
          style={{ width: "400px" }}
        >
          <InputGroup.Prepend>
            <InputGroup.Text className="greyText">Country:</InputGroup.Text>
          </InputGroup.Prepend>
          <FormControl
            type="text"
            defaultValue={generalInfo.Country}
            onBlur={(e) => handleOnChange(e, "SOcountry")}
          />
        </InputGroup>
      </div>
      <div className="settings_container mt16px mb16px">
        <div className="heading3">Regional Settings</div>
        <InputGroup
          className="mt-16px grey-input mb8px"
          style={{ width: "400px" }}
        >
          <InputGroup.Prepend>
            <InputGroup.Text className="greyText">Language:</InputGroup.Text>
          </InputGroup.Prepend>
          <FormControl
            type="text"
            defaultValue={generalInfo.Language}
            onBlur={(e) => handleOnChange(e, "SOlanguage")}
          />
        </InputGroup>
        <InputGroup
          className="mt-16px grey-select customerStyle"
          style={{ width: "400px" }}
        >
          <InputGroup.Prepend>
            <InputGroup.Text className="greyText">Time zone:</InputGroup.Text>
          </InputGroup.Prepend>
          <Form.Control
            as="select"
            defaultValue={generalInfo.Industry}
            name="timezone"
            onBlur={ (e) => handleOnChange(e, "SOtimezone")}
            className="customArrowSelect"
          >
            {generalInfo.DDLMembers &&
              generalInfo.DDLMembers.map((val, i) => {
                if (val.DDLName == "Time Zone") {
                  return val.DDLMemberList.map((data) => (
                    <option value={data.DDLId} key={data.DDLId}>
                      {" "}
                      {data.DDLText}{" "}
                    </option>
                  ));
                }
              })}
          </Form.Control>
        </InputGroup>
      </div>
      <ImageUploadModal
        showModal={showUploadModal}
        hideModal={() => setShowUploadModal(false)}
        heading="Upload Your Logo"
        type="square"
        uploadType={1}
      />
    </Fragment>
  );
};

export default General;
