import React from "react";
import ContentLoader from "react-content-loader";

const AssetsListMainPageLoader = () => {
  return (
    <ContentLoader
      speed={2}
      width={"100%"}
      height={"100%"}
      viewBox="0 0 1500 900"
      backgroundColor="#F6F8FB"
      foregroundColor="#ecebeb"
    >
      <rect x="15%" y="50" rx="8" ry="8" width="70%" height="90" />
      <rect x="15%" y="143" rx="8" ry="8" width="70%" height="90" />
      <rect x="15%" y="236" rx="8" ry="8" width="70%" height="90" />
      <rect x="15%" y="329" rx="8" ry="8" width="70%" height="90" />
      <rect x="15%" y="422" rx="8" ry="8" width="70%" height="90" />
      <rect x="15%" y="515" rx="8" ry="8" width="70%" height="90" />
      <rect x="15%" y="608" rx="8" ry="8" width="70%" height="90" />
      <rect x="15%" y="701" rx="8" ry="8" width="70%" height="90" />
      <rect x="15%" y="794" rx="8" ry="8" width="70%" height="90" />
    </ContentLoader>
  );
};

export default AssetsListMainPageLoader;
