import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import Cropper from "react-easy-crop";
import { useAuth } from "../../context/Auth";
import { useDispatch } from "react-redux";
import { postData } from "../../../Utils/commonApi";
import { TrackUser } from "../../../Utils/SegmentFunctions";
import { CallToast } from "../../PriceCalculator/modals/CustomToast";

function ImageCropperModal({
  show,
  hideModal,
  setProfileImage,
  profileImage,
  uploadType,
  isMemberImage,
  memberId,
  refreshData,
  passedOptionEnum99
}) {
  const dispatch = useDispatch();
  const { authTokens } = useAuth();
  const [loading, setLoading] = useState(false);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [styles] = useState({
    containerStyle: {
      //   position: "absolute",
      //   top: "0",
      width: "calc(100% - 2px)",
      maxHeight: "300px",
      overflow: "hidden",
      //   border: "1px solid red",
      backgroundColor: "white",
    },
    // mediaStyle: { height: "100%", display: "block" },
    cropAreaStyle: {
      //   position: "absolute",
      //   top: "0",
      outline: "50000px solid white",
      width: "100%",
      height: "100%",
      //   backgroundColor: "white",
    },
  });

  const [zoom, setZoom] = useState(1);
  const [image, setImage] = useState(profileImage || "");
  const [cropDims, setCropDims] = useState({});
  const [test, setTest] = useState("");

  const onCropComplete = (e, imageDimensions) => {
    setCropDims(imageDimensions);
  };

  const onCropChange = (crop) => {
    setCrop(crop);
  };
  const getImage = (e) => {
    if (e.target.files[0]) {
      const reader = new FileReader();
      reader.readAsDataURL(e.target.files[0]);

      reader.onload = function () {
        setImage(reader.result);
      };
    }
  };

  const onSubmit = async () => {
    setLoading(true);
    const createImage = (url) =>
      new Promise((resolve, reject) => {
        const image = new Image();
        image.addEventListener("load", () => resolve(image));
        image.addEventListener("error", (error) => reject(error));
        image.setAttribute("crossOrigin", "anonymous");
        image.src = url;
      });

    const Imagee = await createImage(profileImage)
      .then(async (Imagee) => {
        const canvas = document.createElement("canvas");
        canvas.height = cropDims.height;
        canvas.width = cropDims.width;
        const ctx = canvas.getContext("2d");

        ctx.drawImage(
          Imagee,
          cropDims.x,
          cropDims.y,
          cropDims.width,
          cropDims.height,
          0,
          0,
          cropDims.width,
          cropDims.height
        );

          const response = await postData(authTokens, "UploadProfilePhoto", {
            optionEnum1: 0,
            optionStrEnum1: memberId,
            optionEnum99: passedOptionEnum99 ? passedOptionEnum99 : 42,
            File: canvas.toDataURL("image/png"),
          });

          if (response?.mr?.Success == true) {
            TrackUser("Photo uploaded");
            CallToast("Photo uploaded successfully", false, false, "v2style");
            hideModal();
            setLoading(false);
            refreshData();
          }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  function progressColor(rangeValue) {
    let max = 3;
    let min = 1;

    let value = ((rangeValue - min) / (max - min)) * 100;

    const slider = document.getElementById("zoom-slider");

    slider.style.background =
      "linear-gradient(to right, #008fd4 0%, #008fd4 " +
      value +
      "%, #fff " +
      value +
      "%, white 100%)";
  }
  return (
    <Modal
      animation={false}
      show={show}
      dialogClassName="image-crop-modal"
      className=""
      aria-labelledby="cropper-modal-title-vcenter"
      centered
      scrollable
    >
      <Modal.Header className=" "></Modal.Header>

      <Modal.Body>
        <div>
          <Cropper
            image={profileImage}
            crop={crop}
            zoom={zoom}
            aspect={1}
            cropShape="round"
            showGrid={false}
            onCropChange={onCropChange}
            onCropComplete={onCropComplete}
            onZoomChange={() => {}}
            style={styles}
          />
        </div>

        <div className="d-flex slider-wrapper w-80">
          <img src="/images/user-modal/minus.svg" className="mr-3" />
          <input
            type="range"
            min={1}
            max={3}
            step={0.1}
            // value="50"
            defaultValue={1}
            onChange={(e) => {
              setZoom(e.target.value);
              progressColor(e.target.value);
            }}
            className="slider"
            id="zoom-slider"
          />
          <img src="/images/user-modal/plus.svg" className="ml-3" />
        </div>
      </Modal.Body>

      <Modal.Footer>
        <div className="footer d-flex justify-content-end">
          <Button
            disabled={loading}
            className="crop-save-btn"
            onClick={() => {
              onSubmit();
            }}
          >
            {loading ? "Saving..." : "Save"}
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}

export default ImageCropperModal;
