import React from "react";
import { useState } from "react";
import { Button, Dropdown, Form } from "react-bootstrap";
import { Status } from "../../AttackSurface/Index";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { DualProgressBar } from "..";
import AttackSurfaceTooltip from "../../AttackSurface/AssetsList/AttackSurfaceTooltip";
import AssessmentOverview from "./AssessmentOverview";
import "./security-assessments.scss";
import { WrapperDropdwon } from "../../MSPv2/secOps/securityProcesses/SecurityProcesses";
import {
  FrameworksToolTip,
  PackageToolTip,
  QuestionChip,
} from "../../MSPv2/mspSell/mspPriceCalculator/mspCalculatorDropdownAndModals/MspScoreModal";
import DropdownV2 from "../../Theme/DropdownV2";
import SecurityAssessmentAddEditModal from "../SecurityAssessmentAddEditModal";
import AssessmentChatDropDown from "../AssessmentChatDropDown";
import SimilarSearchDropDown from "../SimilarSearchDropdown/SimilarSearchDropDown";
import SimilarSearchModal from "../SimilarSearchDropdown/SimilarSearchModal";

const AssessmentFrameworks = () => {
  const history = useHistory();

  let selectedSideBarTab = new URLSearchParams(window.location.search).get(
    "selectedSideBarTab"
  );

  // Sidebar menu variables
  const [showSidePanel, setShowSidePanel] = useState(true);
  const [sideBarOpen, setSideBarOpen] = useState(true);
  const [IsLockedSidebar, setIsLockedSidebar] = useState(false);

  // Share Dropdown variables

  const [IsRevokeView, setIsRevokeView] = useState(false);
  const [searchString, setSearchString] = useState("");

  // Questions State variables

  const [questions, setQuestions] = useState(dummyData);

  // dummy variables
  const [answer, setAnswer] = useState("");
  const [showAddFileModal, setShowAddFileModal] = useState(false);
  const [showSimilarSearchModal, setShowSimilarSearchModal] = useState(false);
  return (
    <div>
      {/* Header */}
      <div
        className="w-100 position-sticky top-0 d-flex align-items-center justify-content-between border-bottom p-2 px-4 bg-white"
        style={{ zIndex: "2" }}
      >
        <div className="d-flex align-items-center f-500">
          <img
            src="/images/attack-surface/cross-icon.svg"
            alt=""
            width={12}
            className="mr-2 pointer"
          />
          CIS CSC v8
        </div>

        <div className="d-flex align-items-center">
          <Dropdown className="transparennt-wrapper-dropdown">
            <Dropdown.Toggle className="">
              <div className="f-500 f-grey p-2">Share</div>
            </Dropdown.Toggle>
            <Dropdown.Menu
              className="px-2"
              style={{
                width: "400px",
              }}
            >
              <div className="d-flex align-items-center">
                <div className="highlight-input-with-grey-border d-flex align-items-center justify-content-between radius-4 flex-1">
                  <div className="">
                    <input
                      type="text"
                      placeholder="Enter name or email"
                      className="bg-transparent boder-none w-100 p-2"
                      value={searchString}
                      onChange={(e) => {
                        setSearchString(e.target.value);
                      }}
                    />
                  </div>
                  <div>
                    <Dropdown className="transparennt-wrapper-dropdown">
                      <Dropdown.Toggle className=" f-grey">
                        <span className="f-grey">View</span>
                        <img
                          src="/images/theme/icons/dropdownarrow.svg"
                          alt=""
                          className="mx-2"
                        />
                      </Dropdown.Toggle>
                      <Dropdown.Menu className="px-2 pointer">
                        <div className="p-2 f-grey">View</div>
                        <div className="p-2 f-grey">Edit</div>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>
                <Button className="hg-cancel-btn ml-1">Invite</Button>
              </div>

              <textarea
                rows={3}
                placeholder="Message"
                className="highlight-input-with-grey-border p-2 mt-2 w-100 radius-4"
                style={{
                  resize: "none",
                }}
              ></textarea>

              <div>
                <div className="f-darkgrey my-2">
                  {searchString ? "Results" : "People with access"}
                </div>

                {["Dani", "Mia", "Sunny", "Malkova", "Natasha"].map(
                  (item, itemIndex) => {
                    return (
                      <>
                        <div className="d-flex align-items-center justify-content-between p-2 radius-4 bg-hover-grey parent-visible">
                          <div className="d-flex align-items-center">
                            <img
                              src="https://source.unsplash.com/random/100×100"
                              width={30}
                              height={30}
                              alt=""
                              className="rounded-full mr-2"
                            />
                            <div>
                              <div className="f-500 f-black">{item}</div>
                              <div className="f-grey">
                                l.alexander@highground.io
                              </div>
                            </div>
                          </div>
                          <div className="h-fit-content d-flex align-items-center">
                            {searchString ? (
                              <div className="d-flex align-items-center child-visible f-12 f-500 f-grey mr-2 pointer">
                                <img
                                  src="/images/actioncenter/plusgrey.svg"
                                  alt=""
                                  className="mr-2"
                                  width={10}
                                />{" "}
                                Add
                              </div>
                            ) : (
                              <Dropdown
                                className="transparennt-wrapper-dropdown"
                                onToggle={(e) => {
                                  if (e) {
                                    setIsRevokeView(false);
                                  }
                                }}
                              >
                                <Dropdown.Toggle className="f-grey h-fit-content">
                                  <span className="f-grey">View</span>
                                  <img
                                    src="/images/theme/icons/dropdownarrow.svg"
                                    alt=""
                                    className="mx-2"
                                  />
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="px-2 pointer">
                                  {IsRevokeView ? (
                                    <>
                                      <div
                                        className=""
                                        style={{
                                          width: "270px",
                                        }}
                                      >
                                        <div className="f-500 f-black">
                                          Do you want to revoke Janne Copper
                                          access to this framework?
                                        </div>
                                        <div className="mt-3">
                                          <Button className="hg-red-btn w-100 radius-2">
                                            Yes, Revoke
                                          </Button>
                                        </div>
                                        <div>
                                          <Button className="hg-cancel-btn w-100 radius-2">
                                            No, Cancel
                                          </Button>
                                        </div>
                                      </div>
                                    </>
                                  ) : (
                                    <>
                                      {" "}
                                      <div className="p-2 f-grey">View</div>
                                      <div className="p-2 f-grey">Edit</div>
                                      <div
                                        className="mx-auto"
                                        style={{
                                          width: "80%",
                                          borderBottom: "1px solid #EBEDF1",
                                        }}
                                      />
                                      <div
                                        className="f-danger p-2"
                                        onClick={() => {
                                          console.log("revoke clicked");
                                          setIsRevokeView(true);
                                        }}
                                      >
                                        Revoke
                                      </div>
                                    </>
                                  )}
                                </Dropdown.Menu>
                              </Dropdown>
                            )}
                          </div>
                        </div>
                      </>
                    );
                  }
                )}
              </div>
            </Dropdown.Menu>
          </Dropdown>

          <Button className="hg-blue-btn" onClick={() => {}}>
            Save
          </Button>
        </div>
      </div>

      {/* Sidebar */}
      <div
        className={`security-assessments-sidebar
        ${IsLockedSidebar ? "" : "overflow-x-hidden overflow-y-auto"}
        
        
        bg-white  ${
          // showSidePanel === false
          //   ? "bar-expand hide-scrollbar t ransparent-scroller"
          //   :

          sideBarOpen
            ? "bar-expand hide-scrollbar t ransparent-scroller"
            : "bar-collapse hide-scrollbar overflow-hidden"
        }`}
        style={{
          position: "fixed",
          top: "50px",
          height: "calc(100vh + 22px)",
        }}
      >
        <div className="f-500 f-darkgrey d-flex align-items-center justify-content-between position-relative my-2">
          {sideBarOpen && showSidePanel ? (
            <div>CIS CSC v8</div>
          ) : sideBarOpen ? (
            <div>CIS CSC v8</div>
          ) : (
            <></>
          )}
          <div
            className={` ${
              sideBarOpen
                ? "position-absolute right-0"
                : " d-flex align-items-center justify-content-center w-100 mt-2"
            }                `}
          >
            {(showSidePanel || true) && (
              <img
                src="/images/msp/sidebar-expanding-arrow.svg"
                alt=""
                className="trigger-icon pointer z-Index-1"
                onClick={() => {
                  setSideBarOpen(!sideBarOpen);
                }}
              />
            )}
          </div>
        </div>

        {sideBarOpen && (
          <>
            {/* Progress section in sidebar */}
            <div>
              <div className="f-darkgrey pt-4">Progress</div>

              <div className="w-100 d-flex align-items-center">
                <AttackSurfaceTooltip
                  toolTipStyle={{
                    top: "16px",
                    left: "21px",
                  }}
                  className={"w-100"}
                  content={
                    <div>
                      <div className="d-flex align-items-start justify-content-between">
                        <div
                          className="d-flex align-items-start  "
                          style={{
                            color: "#FFFFFFA3",
                          }}
                        >
                          <div
                            className="rounded-full mr-1 mt-1"
                            style={{
                              width: "10px",
                              height: "10px",
                              background: "#008FD4",
                            }}
                          />
                          Completed
                        </div>
                        <div className="f-white">54%</div>
                      </div>
                      <div className="d-flex align-items-start justify-content-between">
                        <div
                          className="d-flex align-items-start  "
                          style={{
                            color: "#FFFFFFA3",
                          }}
                        >
                          <div
                            className="rounded-full mr-1 mt-1"
                            style={{
                              width: "10px",
                              height: "10px",
                              background: "#5244B3",
                            }}
                          />
                          Avaialble similar answers
                        </div>
                        <div className="f-white">+10%</div>
                      </div>
                    </div>
                  }
                >
                  <DualProgressBar barOne={54} barTwo={10} />
                </AttackSurfaceTooltip>
                <div className="d-flex align-items-center">
                  <div className="f-darkgrey mx-2">54%</div>
                  <div className="f-purple">+21%</div>
                </div>
              </div>
            </div>

            {/*  */}

            <div
              className="position-relative my-3"
              style={{
                borderBottom: "1px solid #ebecf1",
                left: "-11px",
                width: "109%",
              }}
            ></div>
            <div className="f-darkgrey">Internal Audit</div>

            <div className="f-darkgrey mt-2">Last: Not performed</div>
            <div className="d-flex align-items-center justify-content-between mt-2">
              <div className="f-darkgrey">Next: Not scheduled</div>
              <div className="f-blue">Set</div>
            </div>

            <div className="my-3 radius-4 border1 d-flex align-items-center justify-content-center p-2 pointer w-100 f-grey f-500">
              Run Audit
            </div>
            <div
              className="position-relative my-3"
              style={{
                borderBottom: "1px solid #ebecf1",
                left: "-11px",
                width: "109%",
              }}
            ></div>

            <div className="d-flex align-items-center justify-content-between">
              <div className="f-darkgrey f-500">Controls</div>
              <div>
                <ConfigurationDropdown>
                  <img
                    src="/images/settings-v2/settingwheel.svg"
                    alt=""
                    className=""
                    onClick={() => {
                      setIsLockedSidebar(!IsLockedSidebar);
                    }}
                  />
                </ConfigurationDropdown>
              </div>
            </div>
          </>
        )}
        <div
          className="d-flex flex-column hide-scrollbar"
          style={{
            height: "calc(56vh)",
            overflowY: "auto",
          }}
        >
          {sideMenu?.map((item, menuIndex) => {
            return (
              <div
                className={` align-items-center justify-content-between px-2 pointer menu-item py-2 mt-1
                      ${
                        item?.DevelpomentStage?.toLowerCase() === "hide"
                          ? "d-none"
                          : "d-flex"
                      }
                      
                      ${
                        Number(selectedSideBarTab ?? 0) ===
                        Number(item?.ObjectId)
                          ? "selected-menu-item"
                          : ""
                      }   `}
                onClick={() => {
                  history.push(
                    `/assessment-frameworks?selectedSideBarTab=${item?.ObjectId}`
                  );
                }}
                title={item?.Title}
              >
                <div className="d-flex align-items-center w-80">
                  <div>
                    {item?.Title?.toLowerCase() === "overview" && (
                      <img
                        alt=""
                        src="/images/security-assessment/open-eye-icon.svg"
                        className="ml-1"
                      />
                    )}

                    {item?.Title?.toLowerCase() !== "overview" &&
                      item?.DevelpomentStage?.toLowerCase() ===
                        "coming soon" && (
                        <img
                          alt=""
                          src="/images/attack-surface/grey-thunder-icon.svg"
                          className="ml-1"
                          style={{ width: "14px", marginRight: "3px" }}
                        />
                      )}

                    {item?.Title?.toLowerCase() !== "overview" &&
                      // item?.DevelpomentStage?.toLowerCase() === "live" &&
                      item?.Percentage === 0 && (
                        <img
                          alt=""
                          src="/images/attack-surface/thunder-icon.svg"
                          className="ml-1"
                          style={{ width: "14px", marginRight: "3px" }}
                        />
                      )}

                    {item?.Title?.toLowerCase() !== "overview" &&
                      // item?.DevelpomentStage?.toLowerCase() === "live" &&
                      item?.Percentage > 0 &&
                      item?.Percentage < 100 && (
                        <Status value={100 - item?.Percentage} />
                      )}

                    {item?.Title?.toLowerCase() !== "overview" &&
                      // item?.DevelpomentStage?.toLowerCase() === "live" &&
                      item?.Percentage === 100 && (
                        <img
                          alt=""
                          src="/images/attack-surface/menu-completed.svg"
                          className="ml-1"
                          style={{ width: "14px", marginRight: "3px" }}
                        />
                      )}
                  </div>

                  <div
                    className="ml-2 d-fle x align-items-center justify-content-center"
                    style={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {item?.Title}
                  </div>
                </div>
                <div className="d-flex align-items-center justify-content-center">
                  {item?.Title?.toLowerCase() !== "overview" && (
                    <>
                      <span className="inline-flex w-max-content">3 of 24</span>

                      <span
                        className="mx-1 inline-flex"
                        style={{
                          background: "#5244B3",
                          width: "7px",
                          height: "7px",
                          borderRadius: "50%",
                        }}
                      />
                    </>
                  )}
                  {/* {item?.DevelpomentStage?.toLowerCase() ===
                        "coming soon" && (
                        <span
                          className="attack-menu-tag"
                          style={{
                            width: `${
                              item?.Title.toLowerCase() == "directory services"
                                ? "51px"
                                : "51px"
                            }`,
                          }}
                        >
                          {item?.Title.toLowerCase() == "directory services"
                            ? "COMING"
                            : "COMING"}
                        </span>
                      )} */}
                </div>
              </div>
            );
          })}
        </div>
      </div>

      {/* Body */}
      {selectedSideBarTab == 10 && <AssessmentOverview />}

      {/* Questionnairre Body */}

      {selectedSideBarTab != 10 && (
        <div
          className="slidable-body position-relative"
          //  ref={(el) => {
          //                                                 if (el) {
          //                                                   el.style.setProperty(
          //                                                     "height",
          //                                                     "calc(100% - 50px)",
          //                                                     "important"
          //                                                   );
          //                                                 }
          //                                               }}
        >
          <div
            style={{
              top: "50px",
              height: "calc(100% - 50px)",
            }}
            className={`main-body hide-scrollbar ${
              showSidePanel ? "panel-open" : "panel-closed"
            }`}
            onClick={() => {
              // setShowSidePanel(true);
            }}
          >
            {!"dataLoaders.detailLoading" ? (
              <></>
            ) : (
              // <SecurityProcessesLoader />
              <>
                <div className="f-16 f-600 my-4">
                  {/* {
                      sideMenu?.find(
                        (_item) =>
                          _item?.ObjectId === Number(selectedSideBarTab)
                      )?.Title
                    } */}
                  CIS Control 2: Inventory and Control of Software Assets
                </div>

                {questions?.map((pillar, pillarIndex) => {
                  return (
                    <>
                      <div className="d-flex align-items-center mb-3">
                        <div
                          className={`f-600 f-black mr-3 ${
                            pillarIndex > 0 ? "mt-3" : ""
                          }`}
                        >
                          {pillar?.Title}
                        </div>
                        <div className="position-relative tooltip-parent d-none">
                          <QuestionChip
                            iconUrl={
                              !"chip.IsFramework"
                                ? "/images/msp/msp-framework-icon.svg"
                                : null
                            }
                            title={"ID.GV"}
                            className={"mr-2"}
                          />
                          {/* {!chip.IsFramework && (
                                    <PackageToolTip content={chip?.Detail} />
                                  )}

                                  {chip.IsFramework && <FrameworksToolTip />} */}
                        </div>
                      </div>
                      {/* Questions List */}
                      <div className="border-parent radius-8 bg-grey">
                        {pillar?.Question_List?.map((item, index) => {
                          return (
                            <>
                              <div
                                className={`
                    position-relative
parent-visible
                    ${
                      !"questionData?.QuestionId === item?.QuestionId"
                        ? "bg-lightblue-imp"
                        : ""
                    }
                            
                    bg-hover-light-grey

                    border-item py-3 pl-1 pr-2 align-items-start justify-content-between pointer`}
                                style={{
                                  display: "grid",
                                  gridTemplateColumns: "86% 14%",
                                }}
                                onClick={() => {
                                  setShowSidePanel(true);
                                  setSideBarOpen(false);
                                }}
                              >
                                <div
                                  className={`chat-box ${""} position-absolute   `}
                                  style={{
                                    left: "100%",
                                  }}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                  }}
                                >
                                  <AssessmentChatDropDown>
                                    <img
                                      alt=""
                                      className="mr-1 child-visible"
                                      src="/images/attack-surface/no-comments-icon.svg"
                                    />
                                  </AssessmentChatDropDown>
                                </div>
                                <div>
                                  <div className="d-flex align-items-start">
                                    <img
                                      src="/images/attack-surface/yellow-dot.svg"
                                      className="mr-2 mt-2"
                                      alt=""
                                      style={{
                                        visibility: `${
                                          item?.AnswerJson?.length == 0
                                            ? "visible"
                                            : "hidden"
                                        }`,
                                      }}
                                    />
                                    <div>
                                      <div className="f-black">
                                        {item?.QuestionText}{" "}
                                      </div>
                                      <div className="f-grey mt-2">
                                        Massa ultricies odio aliquam nibh vel
                                        quam cursus aliquam. Non posuere auctor
                                        dignissim suspendisse in suspendisse.
                                        Bibendum nunc sem commodo pellentesque.
                                      </div>
                                    </div>
                                  </div>
                                  <div className="d-flex flex-wrap ml-3 mt-1">
                                    {item?.NISTAssetClassification_List?.map(
                                      (chip, chipIndex) => {
                                        return (
                                          <div className="d-flex align-items-center position-relative tooltip-parent">
                                            <QuestionChip
                                              iconUrl={
                                                chip.IsFramework
                                                  ? "/images/msp/msp-framework-icon.svg"
                                                  : null
                                              }
                                              title={chip?.Name}
                                              className={"mr-2"}
                                              IsBlue={chip.isBlue}
                                            />
                                            {!chip.IsFramework && (
                                              <PackageToolTip
                                                content={chip?.Detail}
                                              />
                                            )}

                                            {chip.IsFramework && (
                                              <FrameworksToolTip />
                                            )}
                                          </div>
                                        );
                                      }
                                    )}
                                  </div>
                                </div>
                                {item?.AnswerJson && (
                                  <div
                                    className={`mx-2 ${
                                      item?.AnswerJson[0] === "Yes"
                                        ? "f-darkgrey"
                                        : item?.AnswerJson[0] === "No"
                                        ? "f-danger"
                                        : item?.AnswerJson[0] === "In Progress"
                                        ? "f-blue"
                                        : ""
                                    }`}
                                    style={{ justifySelf: "end" }}
                                  >
                                    {item?.AnswerJson && item?.AnswerJson[0]}
                                    {/* {item?.AnswerBool === false && "No"} */}
                                  </div>
                                )}
                              </div>

                              {/* Child Question */}
                              <div
                                className={`
                    
                    ${
                      !"questionData?.QuestionId === item?.QuestionId"
                        ? "bg-lightblue-imp"
                        : ""
                    }
                            
                    bg-hover-light-grey

                    border-item py-3 pl-1 pr-2  d-flex align-items-start justify-content-between pointer`}
                                onClick={() => {
                                  setShowSidePanel(true);
                                  setSideBarOpen(false);
                                }}
                              >
                                <div>
                                  <div className="d-flex align-items-start">
                                    <img
                                      src="/images/security-assessment/enter-icon.svg"
                                      className="mx-3 mt-2"
                                      alt=""
                                      style={{
                                        visibility: `${
                                          item?.AnswerJson === null
                                            ? "visible"
                                            : "hidden"
                                        }`,
                                      }}
                                    />
                                    <div>
                                      <div className="f-black">
                                        {item?.QuestionText}{" "}
                                      </div>
                                      <div className="f-grey mt-2">
                                        Massa ultricies odio aliquam nibh vel
                                        quam cursus aliquam. Non posuere auctor
                                        dignissim suspendisse in suspendisse.
                                        Bibendum nunc sem commodo pellentesque.
                                      </div>

                                      <div className="d-flex flex-wrap  mt-1">
                                        {item?.NISTAssetClassification_List?.map(
                                          (chip, chipIndex) => {
                                            return (
                                              <div className="d-flex align-items-center position-relative tooltip-parent">
                                                <QuestionChip
                                                  iconUrl={
                                                    chip.IsFramework
                                                      ? "/images/msp/msp-framework-icon.svg"
                                                      : null
                                                  }
                                                  title={chip?.Name}
                                                  className={"mr-2"}
                                                  IsBlue={chip.isBlue}
                                                />
                                                {!chip.IsFramework && (
                                                  <PackageToolTip
                                                    content={chip?.Detail}
                                                  />
                                                )}

                                                {chip.IsFramework && (
                                                  <FrameworksToolTip />
                                                )}
                                              </div>
                                            );
                                          }
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                {item?.AnswerJson && (
                                  <div
                                    className={`mx-2 w-15 ${
                                      item?.AnswerJson[0] === "Yes"
                                        ? "f-darkgrey"
                                        : item?.AnswerJson[0] === "No"
                                        ? "f-danger"
                                        : item?.AnswerJson[0] === "In Progress"
                                        ? "f-blue"
                                        : ""
                                    }`}
                                  >
                                    {item?.AnswerJson && item?.AnswerJson[0]}
                                    {/* {item?.AnswerBool === false && "No"} */}
                                  </div>
                                )}
                              </div>
                            </>
                          );
                        })}
                      </div>
                    </>
                  );
                })}
              </>
            )}

            <div className="p-4" />
          </div>

          <div
            style={{
              top: "50px",
            }}
            className={`sidepanel  ${
              showSidePanel ? "panel-open" : "panel-closed"
            } `}
          >
            <div className="panel-header d-flex align-items-center justify-content-between p-3">
              <div className="d-flex align-items-center">
                <div
                  className={`mx-2 pointer ${
                    "isAtTop()" ? "pointer-events-none opacity-40" : ""
                  }`}
                  onClick={() => {
                    "movePrev();";
                  }}
                >
                  <img
                    src="/images/msp/secops-chevron-down.svg"
                    className="rotate180"
                    alt=""
                  />
                </div>
                <div
                  className={`mx-2 pointer ${
                    " isAtBottom()" ? "pointer-events-none opacity-40" : ""
                  }`}
                  onClick={() => {
                    "   moveNext();";
                  }}
                >
                  <img
                    src="/images/msp/secops-chevron-down.svg"
                    className=""
                    alt=""
                  />
                </div>
              </div>
              <div className="d-flex align-items-center">
                <WrapperDropdwon data={"hanji hanji"}>
                  <div className="f-grey f-500 mr-4">
                    <img
                      src="/images/msp/secops-info-icon.svg"
                      alt=""
                      className="mr-2"
                    />
                    More info
                  </div>
                </WrapperDropdwon>
                <div className="f-grey f-500 mr-2 pointer" onClick={() => {}}>
                  <img
                    src="/images/msp/secops-create-task-icon.svg"
                    alt=""
                    className="mr-3"
                  />
                  Create task
                </div>
                <div
                  className="pl-2 border-left pointer"
                  onClick={() => {
                    setShowSidePanel(false);
                  }}
                >
                  <img
                    width={13}
                    src="/images/attack-surface/cross-icon.svg"
                    alt=""
                    className=" "
                  />
                </div>
              </div>
            </div>

            <div
              className="panel-body transparent-scroller p-4"
              style={{
                height: "calc(100% - 28px)",
              }}
            >
              {/* Previus Responses UI */}
              <div className="f-14 f-500 f-black">Question</div>

              <div className="d-flex align-items-center justify-content-between">
                <div>
                  How do you implement and manage a firewall on servers?
                </div>
                <div>
                  <DropdownV2
                    fieldId={"Id"}
                    fieldValue={"Name"}
                    selected={{ Id: "1", Name: "Yes" }}
                    placeholder="Select"
                    onClick={(id, val) => {}}
                    data={[
                      {
                        Id: "1",
                        Name: "Yes",
                      },
                      {
                        Id: "2",
                        Name: "No",
                      },
                      {
                        Id: "3",
                        Name: "Both",
                      },
                    ]}
                  />
                </div>
              </div>
              <div className="w-100 radius-8 position-relative h-auto">
                <textarea
                  type="text"
                  value={answer}
                  onChange={(e) => {
                    setAnswer(e.target.value);
                  }}
                  placeholder="Answer..."
                  className="w-100 radius-4 border1 p-2"
                  rows={4}
                />

                <div
                  className="similar-answer-pop position-absolute"
                  style={{
                    right: "10px",
                    bottom: "14px",
                  }}
                >
                  <SimilarSearchDropDown
                    onClickExpand={() => {
                      setShowSimilarSearchModal(true);
                    }}
                  >
                    <img
                      src="/images/security-assessment/similar-answer-icon.svg"
                      alt=""
                    />
                  </SimilarSearchDropDown>
                </div>
              </div>
              <div className="">
                <AnswerChecker wordLen={answer?.split(" ")?.length - 1} />
              </div>

              <div
                className="d-flex align-items-center"
                style={{
                  paddingLeft: "2rem",
                }}
              >
                <div>
                  <Form.Group controlId={"check" + 2 + "" + 3} className="">
                    <Form.Check
                      tabIndex={-1}
                      type="switch"
                      id={"switch-ques1" + Math.random() * 1000000}
                      style={{
                        transform: "scale(1.3)",
                        paddingLeft: "0.75rem",
                      }}
                      className={`m-0 scale-switch switch-shadow-none ${
                        false ? "attack-switch-error" : ""
                      }  `}
                      label=""
                      disabled={false}
                      onChange={(e) => {}}
                      onClick={(e) => {}}
                    />
                  </Form.Group>
                </div>
                <div className="ml-2 f-black f-12 f-500">Not Applicable</div>
              </div>

              <div
                className="d-flex align-items-center mt-2"
                style={{
                  paddingLeft: "2rem",
                }}
              >
                <div>
                  <Form.Group controlId={"check" + 3 + "" + 3} className="">
                    <Form.Check
                      tabIndex={-1}
                      type="switch"
                      id={"switch-ques2" + Math.random() * 1000000}
                      style={{
                        transform: "scale(1.3)",
                        paddingLeft: "0.75rem",
                      }}
                      className={`m-0 scale-switch switch-shadow-none ${
                        false ? "attack-switch-error" : ""
                      }  `}
                      label=""
                      disabled={false}
                      onChange={(e) => {}}
                      onClick={(e) => {}}
                    />
                  </Form.Group>
                </div>
                <div className="ml-2 f-black f-12 f-500">I’m not Compliant</div>
              </div>

              {/* Evidence Table */}

              <div className=" mt-3">
                <div className="d-flex align-items-center justify-content-between mb-2">
                  <div className="f-500">Evidence</div>
                  <div>
                    <Button
                      className="hg-blue-btn radius-2"
                      onClick={() => {
                        setShowAddFileModal(true);
                      }}
                    >
                      Add File
                    </Button>
                  </div>
                </div>

                <div className="bg-grey radius-8 border-parent">
                  <div
                    className="f-12 f-500 f-grey p-2 border-item"
                    style={{
                      display: "grid",
                      gridTemplateColumns: "40% 25% 25% 10%",
                    }}
                  >
                    <div>
                      File name
                      <img
                        src="/images/actioncenter/arrowdown.svg"
                        alt=""
                        className="ml-2"
                      />
                    </div>
                    <div>Uploaded</div>
                    <div>Uploaded by</div>
                    <div></div>
                  </div>

                  {[1, 2, 3].map((item, index) => {
                    return (
                      <div
                        className="border-item p-3"
                        style={{
                          display: "grid",
                          gridTemplateColumns: "40% 25% 25% 10%",
                        }}
                      >
                        <div className="f-500">
                          <img
                            src={
                              index === 0
                                ? "/images/msp/profiler-link-icon.svg"
                                : "/images/msp/profiler-file-icon.svg"
                            }
                            alt="file"
                            className="mr-2"
                          />
                          File
                        </div>
                        <div className="f-500 f-black">May 20, 2022</div>

                        <div className="d-flex align-items-center">
                          <img
                            src="https://source.unsplash.com/random/50×50"
                            alt=""
                            width={15}
                            height={15}
                            className="mx-2 rounded-full"
                          />
                          Jane Cooper
                        </div>

                        <div></div>
                      </div>
                    );
                  })}
                </div>
              </div>

              <div className="d-flex f-12 f-darkgrey align-items-center mt-3">
                Last edited by{" "}
                <img
                  src="https://source.unsplash.com/random/50×50"
                  alt=""
                  width={15}
                  height={15}
                  className="mx-2 rounded-full"
                />
                Jane Cooper on 20 Jan 2024 6:38 am
              </div>

              <div className="mt-4">
                <div className="f-500 f-black">References</div>

                <div className="d-flex flex-wrap mt-1">
                  {ChipsList?.map((chip, chipIndex) => {
                    return (
                      <div className="d-flex align-items-center position-relative tooltip-parent">
                        <QuestionChip
                          iconUrl={
                            chip.IsFramework
                              ? "/images/msp/msp-framework-icon.svg"
                              : null
                          }
                          title={chip?.Name}
                          className={"mr-2"}
                          IsBlue={chip.isBlue}
                        />
                        {!chip.IsFramework && (
                          <PackageToolTip content={chip?.Detail} />
                        )}

                        {chip.IsFramework && <FrameworksToolTip />}
                      </div>
                    );
                  })}
                </div>
              </div>

              <div className="mt-4">
                <div className="f-500 f-black">Related Controls</div>

                <div className="d-flex flex-wrap mt-1">
                  {RelatedChipsList?.map((chip, chipIndex) => {
                    return (
                      <div className="d-flex align-items-center position-relative tooltip-parent">
                        <QuestionChip
                          iconUrl={
                            chip.IsFramework
                              ? "/images/msp/msp-framework-icon.svg"
                              : null
                          }
                          title={chip?.Name}
                          className={"mr-2"}
                          IsBlue={chip.isBlue}
                        />
                        {!chip.IsFramework && (
                          <PackageToolTip content={chip?.Detail} />
                        )}

                        {chip.IsFramework && <FrameworksToolTip />}
                      </div>
                    );
                  })}
                </div>
              </div>

              {/* Previous Responses */}

              <div className="mt-4">
                <div className="f-500 f-black">Previous responses</div>
              </div>

              {[1, 2, 3, 4, 5].map(() => {
                return (
                  <div>
                    <div className="d-flex align-items-center">
                      <div>
                        <img
                          src="https://source.unsplash.com/random/50×50"
                          alt=""
                          width={25}
                          height={25}
                          className="mx-2 rounded-full"
                        />
                      </div>
                      <div className="f-500 f-black">Jane Cooper (You)</div>
                      <div className="f-darkgrey">Jan 7, 2024</div>
                    </div>

                    <div className="my-2 f-black">
                      Ut nulla sit consequat consequat lectus. Vitae amet massa
                      facilisis eu enim massa commodo est nisl. Egestas in
                      iaculis tristique vel morbi blandit non tempus facilisis.
                      Risus maecenas et libero bibendum nibh ut congue sed
                      feugiat.
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      )}

      <SecurityAssessmentAddEditModal
        show={showAddFileModal}
        hideModal={() => {
          setShowAddFileModal(false);
        }}
      />
      <SimilarSearchModal
        show={showSimilarSearchModal}
        hideModal={() => {
          setShowSimilarSearchModal(false);
        }}
      />
    </div>
  );
};

export default AssessmentFrameworks;

const sideMenu = [
  {
    Title: "Overview",
    Percentage: 50,
    ObjectId: 10,
  },
  {
    Title: "El Lunes",
    Percentage: 50,
    ObjectId: 11,
  },
  {
    Title: "El Domingo",
    Percentage: 50,
    ObjectId: 12,
  },
  {
    Title: "Colega",
    Percentage: 50,
    ObjectId: 13,
  },
  {
    Title: "Sangre",
    Percentage: 50,
    ObjectId: 14,
  },
  {
    Title: "Dumeindo",
    Percentage: 50,
    ObjectId: 15,
  },
  {
    Title: "La Playa",
    Percentage: 50,
    ObjectId: 16,
  },
  {
    Title: "Comida",
    Percentage: 50,
    ObjectId: 17,
  },
  {
    Title: "Besos",
    Percentage: 50,
    ObjectId: 18,
  },
  {
    Title: "Embarazada",
    Percentage: 50,
    ObjectId: 19,
  },
];

const dummyData = [
  {
    ObjectId: 10,
    Title: "Applications",
    Description: null,
    LogoUrl: null,
    SortOrder: 1,
    EnumerateQuestions: true,
    Progress: 0,
    ProgressText: "Not_Started",
    PrePopulatedProgressText: null,
    Question_List: [
      {
        QuestionId: 4010860,
        QuestionAllowEdit: false,
        QuestionOrder: 1,
        QuestionScore: 12,
        QuestionDescription: null,
        QuestionDropdownListMembers: [],
        QuestionSectionNo: 1,
        QuestionStatus: 5,
        QuestionStatusText: "LoadedForEdit",
        QuestionText:
          "Do you explain to your clients which cybersecurity roles and responsibilities are owned by your MSP, and which are owned by them?",
        QuestionTypeEnum: 2,
        QuestionTypeText: "YesOrNo",
        QuestionCheckBoxLabel: "na",
        QuestionUserList: null,
        AnswerId: 0,
        AnswerBool: null,
        AnswerDate: "2024-08-18T10:37:24.9812352+00:00",
        AnswerText: null,
        AnswserSelectedDropdownItems: null,
        AnswerSelectedQuestionDDLObjId: 0,
        AnswerSelectedUserNames: null,
        DateTimeAnswer: null,
        Label: null,
        QuestionParentId: 0,
        ChildQuestions: null,
        ResponseComment_List: null,
        NISTAssetClassification_List: [
          {
            ObjectId: 3995064,
            Name: "ID.GV-2",
            SortOrder: 13,
            Detail:
              "ID.GV-2: Cybersecurity roles and responsibilities are coordinated and aligned with internal roles and external partners\r\n",
            NISTAssetClassificationDetail_List: null,
            IsFramework: false,
          },
        ],
        NoteAnswerText: null,
        Explanation:
          "Accountability is key, determining who own what responsiblity is fundamental to ensuring ownership and delivering an effective security programme.",
        Statement: null,
        AnswerArrayText: null,
        ActivityTrackingContributorVM: null,
      },
      {
        QuestionId: 4010861,
        QuestionAllowEdit: true,
        QuestionOrder: 2,
        QuestionScore: 12,
        QuestionDescription: null,
        QuestionDropdownListMembers: [],
        QuestionSectionNo: 1,
        QuestionStatus: 5,
        QuestionStatusText: "LoadedForEdit",
        QuestionText:
          "Do you understand which Information Security and Cyber Security Frameworks, and the relevant the security and privacy controls of these frameworks, that apply to your clients and how your MSPs security offerings and operating practices relate to these?",
        QuestionTypeEnum: 2,
        QuestionTypeText: "YesOrNo",
        QuestionCheckBoxLabel: "na",
        QuestionUserList: null,
        AnswerId: 0,
        AnswerBool: null,
        AnswerDate: "2024-08-18T10:37:24.9812352+00:00",
        AnswerText: null,
        AnswserSelectedDropdownItems: null,
        AnswerSelectedQuestionDDLObjId: 0,
        AnswerSelectedUserNames: null,
        DateTimeAnswer: null,
        Label: null,
        QuestionParentId: 0,
        ChildQuestions: null,
        ResponseComment_List: null,
        NISTAssetClassification_List: [
          {
            ObjectId: 3995070,
            Name: "ID.GV-4",
            SortOrder: 15,
            Detail:
              "ID.GV-4: Governance and risk management processes address cybersecurity risks",
            NISTAssetClassificationDetail_List: null,
            IsFramework: false,
          },
        ],
        NoteAnswerText: null,
        Explanation:
          "As an MSP, you likely have clients across all different industries. There are countless different information security and data privacy frameworks for different industries and geographies, so ensuring you understand your clients requirements is crucial to supporting them.",
        Statement: null,
        AnswerArrayText: null,
        ActivityTrackingContributorVM: null,
      },
    ],
    SectionTypeEnum: 0,
    SectionTypeText: null,
    AccreditationVM: null,
    TrainingCert_UserListVM: null,
    SecurityFrameworks: null,
    DataPrivacyFrameworks: null,
    SCM_SupplierInfo_List: null,
    ManagedServiceProvider_List: null,
    ActivityTrackingContributorVM: null,
  },
  {
    ObjectId: 4010862,
    Title: "Risk Assessment",
    Description: null,
    LogoUrl: null,
    SortOrder: 2,
    EnumerateQuestions: true,
    Progress: 0,
    ProgressText: "Not_Started",
    PrePopulatedProgressText: null,
    Question_List: [
      {
        QuestionId: 4010863,
        QuestionAllowEdit: true,
        QuestionOrder: 3,
        QuestionScore: 11,
        QuestionDescription: null,
        QuestionDropdownListMembers: [],
        QuestionSectionNo: 2,
        QuestionStatus: 5,
        QuestionStatusText: "LoadedForEdit",
        QuestionText:
          "Does your MSP perform risk assessments which cover the organisations particular vulnerability to MSP-targetted attack vectors and take appropriate action to mitigate these?",
        QuestionTypeEnum: 2,
        QuestionTypeText: "YesOrNo",
        QuestionCheckBoxLabel: "na",
        QuestionUserList: null,
        AnswerId: 0,
        AnswerBool: null,
        AnswerDate: "2024-08-18T10:37:25.059362+00:00",
        AnswerText: null,
        AnswserSelectedDropdownItems: null,
        AnswerSelectedQuestionDDLObjId: 0,
        AnswerSelectedUserNames: null,
        DateTimeAnswer: null,
        Label: null,
        QuestionParentId: 0,
        ChildQuestions: null,
        ResponseComment_List: null,
        NISTAssetClassification_List: [
          {
            ObjectId: 3995073,
            Name: "ID.RA-3",
            SortOrder: 18,
            Detail:
              "ID.RA-3: Threats, both internal and external, are identified and documented",
            NISTAssetClassificationDetail_List: null,
            IsFramework: false,
          },
        ],
        NoteAnswerText: null,
        Explanation:
          "MSPs are a large target for attackers due to our proximity to a large number of SMEs that we support. Stay on your toes by continuously identifying and mitigating against attacks aimed at compromising your MSP to get to your clients.",
        Statement: null,
        AnswerArrayText: null,
        ActivityTrackingContributorVM: null,
      },
    ],
    SectionTypeEnum: 0,
    SectionTypeText: null,
    AccreditationVM: null,
    TrainingCert_UserListVM: null,
    SecurityFrameworks: null,
    DataPrivacyFrameworks: null,
    SCM_SupplierInfo_List: null,
    ManagedServiceProvider_List: null,
    ActivityTrackingContributorVM: null,
  },
];

const AnswerChecker = ({ wordLen = 0 }) => {
  return (
    <div className="d-flex align-items-center answer-checker">
      <div className="f-12 f-darkgrey">{wordLen} </div>
      <div
        className={`tiny-check mx-1 ${wordLen === 0 && "back-grey"} 
      ${wordLen > 0 && wordLen < 9 && "back-red"}
      ${wordLen >= 9 && wordLen < 15 && "back-yellow"}
      ${wordLen >= 15 && "back-green"}
   
      `}
      ></div>

      <div
        className={`tiny-check mr-1 ${wordLen < 9 && "back-grey"} 
        ${wordLen >= 9 && wordLen < 15 && "back-yellow"}
        ${wordLen >= 15 && "back-green"}
      `}
      ></div>

      <div
        className={`tiny-check mr-1 ${wordLen < 25 && "back-grey"} 
      
      ${wordLen >= 25 && "back-green"}
      `}
      ></div>

      <div
        className={`
      ${wordLen > 0 && wordLen <= 9 && "color-red"}
      ${wordLen > 9 && wordLen < 25 && "color-yellow"}
      ${wordLen >= 25 && "color-green"}
      
      
      `}
      >
        {wordLen > 0 &&
          wordLen <= 9 &&
          "Your answer seems too short, consider adding more detail"}
        {wordLen > 9 &&
          wordLen < 25 &&
          "Your answer looks like it could be doing with a bit more detail"}
        {wordLen >= 25 && "Your answer looks detailed, good job!"}
      </div>
    </div>
  );
};

const ChipsList = [
  {
    ObjectId: 3995070,
    Name: "Best Practice",
    SortOrder: 15,
    Detail:
      "ID.GV-4: Governance and risk management processes address cybersecurity risks",
    NISTAssetClassificationDetail_List: null,
    IsFramework: false,
    isBlue: true,
  },
  {
    ObjectId: 3995070,
    Name: "ID.GV-3",
    SortOrder: 15,
    Detail:
      "ID.GV-4: Governance and risk management processes address cybersecurity risks",
    NISTAssetClassificationDetail_List: null,
    IsFramework: false,
  },
  {
    ObjectId: 3995070,
    Name: "ID.GV-4",
    SortOrder: 15,
    Detail:
      "ID.GV-4: Governance and risk management processes address cybersecurity risks",
    NISTAssetClassificationDetail_List: null,
    IsFramework: false,
  },
  {
    ObjectId: 3995070,
    Name: "Framework",
    SortOrder: 15,
    Detail:
      "ID.GV-4: Governance and risk management processes address cybersecurity risks",
    NISTAssetClassificationDetail_List: null,
    IsFramework: true,
  },
];

const RelatedChipsList = [
  {
    ObjectId: 3995070,
    Name: "ID.GV-4",
    SortOrder: 15,
    Detail:
      "ID.GV-4: Governance and risk management processes address cybersecurity risks",
    NISTAssetClassificationDetail_List: null,
    IsFramework: false,
  },
  {
    ObjectId: 3995070,
    Name: "ID.GV-3",
    SortOrder: 15,
    Detail:
      "ID.GV-4: Governance and risk management processes address cybersecurity risks",
    NISTAssetClassificationDetail_List: null,
    IsFramework: false,
  },
  {
    ObjectId: 3995070,
    Name: "ID.GV-4",
    SortOrder: 15,
    Detail:
      "ID.GV-4: Governance and risk management processes address cybersecurity risks",
    NISTAssetClassificationDetail_List: null,
    IsFramework: false,
  },
  {
    ObjectId: 3995070,
    Name: "ID.GV-5",
    SortOrder: 15,
    Detail:
      "ID.GV-4: Governance and risk management processes address cybersecurity risks",
    NISTAssetClassificationDetail_List: null,
    IsFramework: false,
  },
];

const ConfigurationDropdown = ({ children }) => {
  return (
    <Dropdown className="transparennt-wrapper-dropdown">
      <Dropdown.Toggle>{children}</Dropdown.Toggle>
      <Dropdown.Menu
        className="p-0  security-assessment-dropmenu"
        style={{
          width: "320px",
          // translateX:"200px"
        }}
      >
        <div className="d-flex align-items-center justify-content-between border-bottom p-2">
          <div className="d-flex align-items-center f-12 f-grey">
            <img
              src="/images/settings-v2/settingwheel.svg"
              alt=""
              className="mr-2"
              width={10}
            />
            Configuration
          </div>
          <img
            src="/images/attack-surface/cross-icon.svg"
            alt=""
            className=""
          />
        </div>

        <div className="p-3">
          <div className="f-14 f-500">Implementation Groups</div>
          <div className="f-12 f-darkgrey">
            Select which implementation groups you want to assess yourself
            against.
            <u>Learn more</u>
          </div>

          {/*  */}
          <div className="mt-2 drilldown-modal">
            {["Level One", "Level One", "Level One"].map((item, index) => {
              return (
                <div className=" mb-2">
                  <div className="checkbox-ma rgin ml-2">
                    <Form.Group
                      controlId={`card ${index}${1}`}
                      className={`round-checkbox blue-checkbox ${
                        false ? "cursor-default" : "radio-shadow"
                      }`}
                    >
                      <Form.Check
                        type={"radio"}
                        // checked={

                        // }
                        name={`check-33`}
                        label=""
                        disabled={false}
                        onChange={() => {}}
                        onClick={(e) => {}}
                      />
                    </Form.Group>
                  </div>
                  <div className="ml-5">{item}</div>
                </div>
              );
            })}
          </div>

          <div className="f-14 f-500">Choose something else</div>
          <div className="f-12 f-darkgrey">
            Choose something else
            <u>Learn more</u>
          </div>

          <div className="mt-2 checkbox-wrapper">
            {["Level One", "Level One", "Level One"].map((item, index) => {
              return (
                <div className=" mb-2">
                  <div
                    className="checkbox-margin ml-2 mb-"
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  >
                    <Form.Group
                      controlId={`card-${252}${index}`}
                      className={`round-checkbox blue-checkbox checkboxstyle mb-1 ${
                        false ? "cursor-default" : "radio-shadow"
                      }`}
                    >
                      <Form.Check
                        type={"checkbox"}
                        // checked={
                        //   ToolInSelectionIndex > -1 ? true : false
                        // }
                        name={`check ` + 0 + "" + index}
                        label=""
                        disabled={false}
                        onChange={() => {}}
                      />
                    </Form.Group>
                  </div>
                  <div className="ml-5">{item}</div>
                </div>
              );
            })}
          </div>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};
