import React, { useState } from "react";
import { Dropdown } from "react-bootstrap";
import Search from "./searchinput";
import "./dropdown.scss";

const DropdownWithLabel = (props) => {
  const [data, setData] = useState({
    data: props.data,
    txt: ''
  });

  // filter data
  const filterData = (field,prop) => {
    const filtered = props.data.filter((val) => val[field].includes(prop));
    setData({data: filtered, txt: prop});
  }

  let getInitials = function (string) {
    let names = string.split(" "),
      initials = names[0].substring(0, 1).toUpperCase();

    if (names.length > 1) {
      initials += names[names.length - 1].substring(0, 1).toUpperCase();
    }
    return initials;
  };
  return (
    <Dropdown className="custom_drop-down-with-label">
      <Dropdown.Toggle className={`${props.selected ? "selected" : ""}`}>
        <span className="select_label">{props.label}</span>{" "}
        {props.selected
          ? props.showid
            ? props.selected[props.fieldId]
            : props.selected[props.fieldValue]
          : props.placeholder}
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <div className="search_input">
          <Search
            onChange={(e) => filterData(props.fieldValue, e.target.value)}
            type="text"
            value={data.txt}
            placeholder="Search..."
            className="test"
          />
        </div>

        {data.data.map((val) => (
          <div
            className={`dropdown_item ${
              props.selected &&
              props.selected[props.fieldId] == val[props.fieldId]
                ? "selected"
                : ""
            }`}
            onClick={(e) => {
              props.onClick(val[props.fieldId], val[props.fieldValue]);
              setData({data: props.data, txt: ""});
              e.target.closest(".custom_drop-down-with-label").click();
            }}
            key={val[props.fieldId]}
            data-id={val[props.fieldId]}
            data-value={val[props.fieldValue]}
          >
            <span className="circle_div">
              {getInitials(val[props.fieldValue])}
            </span>
            {val[props.fieldValue]}
            {props.selected &&
            props.selected[props.fieldId] == val[props.fieldId] ? (
              <img
                src={"/images/theme/hovericons/tick.svg"}
                className="float-right tick"
                alt=""
              />
            ) : (
              ""
            )}
          </div>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default DropdownWithLabel;
