import React, { useState, useEffect, useRef } from "react";
import { Modal, Button, Form, Spinner } from "react-bootstrap";
import "./support-ticket-v2.scss";
import { TrackUser } from "../../../Utils/SegmentFunctions";
import moment from "moment";
import { CallToast } from "../../PriceCalculator/modals/CustomToast";
import { useSelector } from "react-redux";
import {
  getAPIData,
  getRequestOptions,
  getTicketNotes,
} from "../../../Utils/commonApi";
import { useAuth } from "../../context/Auth";
import { FiPaperclip } from "react-icons/fi";
import ImageWithInitials from "../../../Utils/ImageWithInitials";
import Loader from "../../Common/loader";
import { WhiteLoader } from "../../Theme/APILoaders";
import { sanitizeText } from "../../../Utils/SanitizeText";
const SupportTicketV2 = ({ show, hideModal }) => {
  const fileUpload = useRef();
  const initialFileUpload = useRef();
   
  const { authTokens } = useAuth();
  const [incidentSubjectContent, setIncidentSubjectContent] = useState("Low");
  const [phoneNo, setPhoneNo] = useState();
  const [formType, setFormType] = useState("");
  const [ticketData, setTicketData] = useState([]);
  const [message, setMessage] = useState("");
  const [data, setData] = useState([]);
  const [summaryError, setSummaryError] = useState('')
  const [success, setSuccess] = useState(false);
  const userDetails = useSelector((state) => state.user?.userDetail);
  const [messageImage, setMessageImage] = useState();
  const [description, setDescription] = useState("");
  const [newFile, setNewFile] = useState();
  const [ticketSummary, setTicketSummary] = useState("");
  const [ticketTypes, setTicketTypes] = useState([]);
  const [selectedChat, setSelectedChat] = useState({
    bizObjectId: "",
    ticketId: "",
  });

  const [loading, setLoading] = useState(false);
  const [creatingTicket, setCreatingTicket] = useState(false);
  const [gettingMessageDetail, setGettingMessageDetail] = useState(false);
  const [sendingMessage, setSendingMessage] = useState(false);

  useEffect(() => {
    if (show) {
      resetCreateTicketPage();
      setTicketType("opened");
      setLoading(true);
      getTicketTypes();
      getPageData();
      setMessageImage()
      setMessage("")
    }
  }, [show]);

  async function getPageData() {
    await getAPIData("11", authTokens)
      .then((response) => {
        setTicketData(response);
        setLoading(false);
      })
      .catch((err) => {
        // CallToast("Something went wrong");
      });
  }

  // Pages
  // openClose,createTicket,ticketData
  async function getTicketTypes() {
    await getAPIData("12", authTokens, { optionEnum1: 1 })
      .then((response) => {
        if (response?.mr?.Success) {
          setTicketTypes(response?.CategoryDDList);
          setFormType(response?.CategoryDDList?.[0]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const [page, setPage] = useState("openClose");
  const [ticketType, setTicketType] = useState("opened");

  function resetCreateTicketPage() {
    setTicketSummary("");
    setDescription("");
    setNewFile();
    setPhoneNo("");
    setIncidentSubjectContent("Low");
  }

  async function createTicket() {
    setCreatingTicket(true);

    // var message = JSON.parse(localStorage.getItem("user_MessageObj"));

    const incidentData = {
      TicketDataJSON: JSON.stringify({
        TicketDetails: {
          ContactName: userDetails.FullName,
          ContactEmail: userDetails.UserName,
          ContactPhone: phoneNo,
        },
        TicketNoteDetails: {
          Subject: ticketSummary,
          Type: formType,
          Priority: incidentSubjectContent,
          Description: description,
        },
        TicketType: 1,
      }),
      AccessKey: JSON.parse(localStorage.getItem("user_MessageObj")),
      Files:  newFile?.data,
    };
    await fetch(
      `${process.env.REACT_APP_API_BASE_URL}/CC_API/CreateConnectwiseTicket`,
      getRequestOptions(authTokens, "POST", incidentData)
    )
      .then((response) => response.json())
      .then((response) => {
        
        if (response?.Success) {
          getPageData();
          setFormType(ticketTypes?.[0]);
          resetCreateTicketPage();
          CallToast("Ticket created successfully", false, false, "v2style");
          hideModal()
          setCreatingTicket("");
          setSuccess(true);

          setTimeout(() => {
            setSuccess(false);
            setCreatingTicket(false);
          }, 2000);
        }else{
          setCreatingTicket(false);
          CallToast("Something went wrong", false, false, " ");
        }
      })
      .catch((err) => {
        CallToast("Something went wrong", false, false, " ");
        console.log(err);
        setCreatingTicket(false);
        setSuccess(false);
      });
  }

  async function getMessageDetails(
    BizObjectId = selectedChat?.bizObjectId,
    TicketId = selectedChat?.ticketId
  ) {
    await getTicketNotes(authTokens, BizObjectId, TicketId)
      .then((response) => {
        if (response?.mr?.Success) {
          setData(response?.cwTicketNotes);
          setGettingMessageDetail(false);
          setTimeout(() => {
            const chatbox = document.querySelector(".modal-body");
            chatbox.scrollTo({
              top: chatbox?.scrollHeight,
              behavior: "smooth",
            });
          }, 100);
        }else{
          setGettingMessageDetail(false);
          CallToast("Something went wrong", false, false, " ");

        }
      })
      .catch((err) => {
        setGettingMessageDetail(false);
        CallToast("Something went wrong", false, false, " ");
      });
  }

  async function sendMessage() {
    await fetch(
      `${process.env.REACT_APP_API_BASE_URL}/CC_API/AddNewNoteToTicket`,
      getRequestOptions(authTokens, "POST", {
        ticketId: selectedChat?.ticketId,
        AccessKey: JSON.parse(localStorage.getItem("user_MessageObj")),
        Note: message
          ? "*Username -" + userDetails.FullName + "*" + message
          : "*Username -" + userDetails.FullName + "*",
        Files:  messageImage ,
      })
    )
      .then((response) => response.json())
      .then((response) => {
        if (response?.Success) {
          setSendingMessage(false);
          setMessage();
          setMessageImage();
          getMessageDetails();
        }
      })
      .catch((err) => {
        setSendingMessage(false);
      });
  }
  return (
    <Modal
      animation={false}
      show={show}
      dialogClassName="support-ticket-v2-modal right-side-modal"
      className={``}
      // backdrop={false}
      onHide={() => {
        setPage("openClose");
        hideModal();
      }}
      aria-labelledby="support-ticket-v2-modal"
    >
      <Modal.Header>
        {page === "openClose" && (
          <div
            className="d-flex align-items-center pointer"
            style={{ width: "30%" }}
          >
            <div
              className={`${
                ticketType === "opened" ? "active-tab" : "normal-tab"
              } mr-3`}
              onClick={() => {
                setTicketType("opened");
              }}
            >
              Open
            </div>
            <div
              className={`${
                ticketType === "closed" ? "active-tab" : "normal-tab"
              }`}
              onClick={() => {
                setTicketType("closed");
              }}
            >
              Closed
            </div>
          </div>
        )}
        {page === "createTicket" && (
          <img
            alt=""
            src="/images/settings-v2/chevron-left.svg"
            className="pointer"
            onClick={() => setPage("openClose")}
          />
        )}
        {page === "createTicket" && <div className="title">Create Ticket</div>}

        {page === "ticketData" && (
          <img
            alt=""
            src="/images/settings-v2/chevron-left.svg"
            className="pointer"
            onClick={() => {
              getPageData();
              setPage("openClose");
            }}
          />
        )}
        {page === "ticketData" && (
          <div className="title">Support Ticket #{selectedChat?.ticketId}</div>
        )}

        <img
          alt=""
          src="/images/icons/close.svg"
          className="pointer"
          style={{ width: "11px" }}
          onClick={() => {
            setPage("openClose");
            hideModal();
          }}
        />
      </Modal.Header>
      <Modal.Body className="hide-scrollbar">
        {/* First page- open-close */}
        {page == "openClose" && (
          <>
            {ticketType == "opened" && (
              <>
                {" "}
                <div
                  className="knowledgebase-wrapper"
                  onClick={() => {
                    TrackUser("Knowledge Base clicked");
                    window.open("https://kb.highground.io", "_blank");
                  }}
                >
                  <div className="d-flex align-items-center">
                    <img
                      alt=""
                      src="/images/settings-v2/book.svg"
                      className="mr-3"
                    />
                    <div className="d-flex flex-column">
                      <p className="title m-0">Knowledge Base</p>
                      <p className="subtitle">
                        Find answers to your common question in our
                        <br /> knowledge base.
                      </p>
                    </div>
                  </div>
                  <img alt="" src="/images/chevron-right.svg" />
                </div>
                {ticketData?.ActiveTableData?.length > 0 && (
                  <div className="my-2 mt-4" style={{ color: "#616778" }}>
                    Continue the conversation...
                  </div>
                )}
                {loading ? (
                  <div className="d-flex align-items-center justify-content-center w-100 mt-5">
                    <Loader />
                  </div>
                ) : (
                  <>
                    {(ticketData?.ActiveTableData?.length === 0 ||
                      ticketData?.ActiveTableData === null) && (
                      <div className="mt-5 text-center">
                        You have no open tickets
                      </div>
                    )}
                    {ticketData?.ActiveTableData &&
                      ticketData?.ActiveTableData?.map((open, index) => {
                        return (
                          <div
                            key={index}
                            className="d-flex justify-content-between align-items-center opened-list"
                            onClick={() => {
                              setGettingMessageDetail(true);
                              setSelectedChat({
                                bizObjectId: open?.Col7,
                                ticketId: open?.EntityId,
                              });
                              getMessageDetails(open?.Col7, open?.EntityId);
                              setPage("ticketData");
                            }}
                          >
                            <div>
                              <p className="title m-0">
                                {open?.Col4} #{open?.Col7}
                              </p>
                              <p>
                                <span className="subtitle">
                                  {open?.UserDetails?.FullName}
                                </span>
                                <span className="subtitle mx-2">•</span>

                                <span className="subtitle">{open?.Col5}</span>
                              </p>
                            </div>
                            <div>
                              {open?.UnreadMessageCount > 0 ? (
                                <img
                                  alt=""
                                  src="/images/settings-v2/blue-icon.svg"
                                  className="mr-2"
                                />
                              ) : (
                                <img
                                  alt=""
                                  src="/images/settings-v2/msg-icon.svg"
                                  className="mr-2"
                                />
                              )}
                              <span
                                style={{ position: "relative" }}
                                data-count={open?.UnreadMessageCount}
                                className={`subtitle ${
                                  open?.UnreadMessageCount > 0 ? "unread " : ""
                                } `}
                              >
                                {open?.Col6}
                              </span>
                            </div>
                          </div>
                        );
                      })}
                  </>
                )}
              </>
            )}

            {/* Closed tikets */}

            {ticketType == "closed" && (
              <div>
                <div className="subtitle ml-3 " style={{ color: "#616778" }}>
                  Closed conversations
                </div>
                {/* Pre  Data state for Closed  Tickets */}
                {(ticketData?.ClosedTableData?.length === 0 ||
                  ticketData?.ClosedTableData === null) && (
                  <div className="mt-5 text-center">
                    You have no closed tickets
                  </div>
                )}
                {ticketData?.ClosedTableData &&
                  ticketData?.ClosedTableData?.map((closed, index) => {
                    return (
                      <div
                        key={index}
                        className="d-flex justify-content-between align-items-center opened-list"
                        onClick={() => {
                          setGettingMessageDetail(true);
                          setSelectedChat({
                            bizObjectId: closed?.Col7,
                            ticketId: closed?.EntityId,
                          });
                          getMessageDetails(closed?.Col7, closed?.EntityId);
                          setPage("ticketData");
                        }}
                      >
                        <div>
                          <p className="title m-0" style={{ color: "#616778" }}>
                            {closed?.Col4} #{closed?.Col7}
                            <span className="closed-tag">CLOSED</span>
                          </p>
                          <p>
                            <span className="subtitle">
                              {closed?.UserDetails?.FullName}
                            </span>
                            <span className="subtitle mx-2">•</span>

                            <span className="subtitle">{closed?.Col5}</span>
                          </p>
                        </div>
                        <div>
                          <img
                            alt=""
                            src="/images/settings-v2/msg-icon.svg"
                            className="mr-2"
                          />

                          <span data-count={5} className={`subtitle  `}>
                            12
                          </span>
                        </div>
                      </div>
                    );
                  })}
              </div>
            )}
          </>
        )}

        {page == "createTicket" && (
          <div>
            <div className="title">Create Ticket</div>

            <div
              className="d-flex my-2 align-items-center "
              style={{ marginLeft: "12px" }}
            >
              <div className="mr-2">
                <img alt="" src="/images/user-logo.svg" className="avatar" />
                <img alt="" src="/images/user-logo.svg" className="avatar" />
                <img alt="" src="/images/user-logo.svg" className="avatar" />
              </div>
              <div className="small-subtitle">
                We are here to help you on your journey
              </div>
            </div>

            <div className="title mb-1 mt-4">Phone</div>
            <Form.Control
              id="supportPhone"
              type="number"
              value={phoneNo}
              className="ticket-input"
              placeholder="+xx-xxxxxxxxx"
              onChange={(event) => {
                setPhoneNo(window.sanitizeHGtext(event.target.value));
              }}
            />
            <div className="title mb-1">Summary</div>
            <Form.Control
              id="supportPhone"
              type="text"
              className={`ticket-input  ${summaryError ? "input-error" : ""}`}
              placeholder="Type summary"
              value={ticketSummary}
              onChange={(event) => {
                setTicketSummary(window.sanitizeHGtext(event.target.value));
                setSummaryError("");
              }}
            />
            <div className="title mb-1">Type</div>
            <Form.Control
              id="supportType"
              as="select"
              className="ticket-input pointer"
              //   className="bgNone"
              value={formType}
              onChange={(event) => setFormType(event.target.value)}
            >
              {ticketTypes.map((typeName) => (
                <option value={typeName}>{typeName} </option>
              ))}
            </Form.Control>
            {/* <Form.Control
              id="supportPhone"
              type="text"
              className="ticket-input"
              placeholder="Type"
              value={formType}
              onChange={(event) => {
                setFormType(event.target.value.toString());
              }}
            /> */}
            <div className="title mb-1">Priority</div>

            <Form.Control
              id="supportType"
              as="select"
              className="ticket-input pointer"
              //   className="bgNone"
              value={incidentSubjectContent}
              onChange={(event) =>
                setIncidentSubjectContent(event.target.value)
              }
            >
              <option value={"Low"}>Low</option>
              <option value={"Medium"}>Medium</option>
              <option value={"High"}>High</option>
              <option value={"Critical"}>Critical</option>
            </Form.Control>

            <div className="title mb-1">Description</div>
            <textarea
              rows={5}
              placeholder="Type description about ticket.."
              className="hg-borde r bg-grey border-none radius-4 w-100 mt- 2 p-2"
              value={description ?? ""}
              onChange={(e) => {
                setDescription(window.sanitizeHGtext(e.target.value));
              }}
            ></textarea>

            <input
              type="file"
              className="d-none"
              ref={initialFileUpload}
              accept=".jpg, .jpeg, .png, .gif"
              onChange={(e) => {
                const selectedFile = e.target.files[0];

                if (selectedFile) {
                  const acceptedImageTypes = [
                    "image/jpeg",
                    "image/png",
                    "image/gif",
                  ];

                  // Check if the file type is in the list of accepted image types
                  if (acceptedImageTypes.includes(selectedFile.type)) {
                    setMessageImage(selectedFile);
                    setNewFile({ data: selectedFile, name: selectedFile.name ??"Untitled" });
                    e.target.value = null;
                  } else {
                    CallToast(
                      "Please upload a valid Image",
                      false,
                      false,
                      "v2style"
                    );
                    e.target.value = null; // Clear the input field
                  }
                }
              }}
            />
          </div>
        )}

        {page === "ticketData" && (
          <>
            {gettingMessageDetail ? (
              <div className="d-flex align-items-center justify-content-center w-100 h-100">
                <Loader />
              </div>
            ) : (
              <>
                {(data?.length === 0 || !data) && (
                  <div>
                    <div
                      className="d-flex my-2 align-items-center justify-content-center "
                      style={{ marginLeft: "12px" }}
                    >
                      <div className="mr-2">
                        <img
                          alt=""
                          src="/images/user-logo.svg"
                          className="avatar"
                          style={{ width: "32px", height: "32px" }}
                        />
                        <img
                          alt=""
                          src="/images/user-logo.svg"
                          className="avatar"
                          style={{ width: "32px", height: "32px" }}
                        />
                        <img
                          alt=""
                          src="/images/user-logo.svg"
                          className="avatar"
                          style={{ width: "32px", height: "32px" }}
                        />
                      </div>
                    </div>
                    <div className="title d-flex justify-content-center w-50 mx-auto text-center mt-2">
                      How we can help you?
                    </div>
                    <p className="small-subtitle d-flex justify-content-center w-50 mx-auto text-center mt-1">
                      Please enter your question or describe the problem. Our
                      team will answer as soon as possible
                    </p>
                  </div>
                )}

                {data?.length > 0 &&
                  data?.map((messageData) => {
                    return (
                      <Message data={messageData} loginedUser={userDetails} />
                    );
                  })}
              </>
            )}
          </>
        )}
      </Modal.Body>
      <Modal.Footer className="pt-0">
        {page === "openClose" && ticketType === "opened" && (
          <div className="start-conversation-wrapper">
            <div className="title">Start another conversation</div>

            <div className="d-flex my-2 " style={{ marginLeft: "12px" }}>
              <div className="mr-2">
                <img alt="" src="/images/user-logo.svg" className="avatar" />
                <img alt="" src="/images/user-logo.svg" className="avatar" />
                <img alt="" src="/images/user-logo.svg" className="avatar" />
              </div>
              <div className="small-subtitle">
                We are here to help you on your journey
              </div>
            </div>

            <Button
              className="w-100 create-ticket-btn"
              onClick={() => setPage("createTicket")}
            >
              Create Ticket
              <img
                alt=""
                src="/images/settings-v2/white-plane.svg"
                className="ml-2"
              />
            </Button>
          </div>
        )}

        {page === "createTicket" && (
          <>
            {" "}
            <div className="d-flex align-items-center">
              <div
                className="d-flex align-items-center pointer"
                onClick={() => {
                  initialFileUpload.current.click();
                }}
              >
                <FiPaperclip />
                <div className="f-500 f-black pl-2">Attach file</div>
              </div>
              {newFile?.name && (
                <div className="bg-grey f-black radius-16  px-2 ml-2 w-fit-content">
                  {newFile?.name}&nbsp;
                  <img
                    alt=""
                    width={10}
                    className="pointer"
                    src="/images/attack-surface/grey-cross-icon.svg"
                    onClick={() => {
                      setNewFile();
                    }}
                  />
                </div>
              )}
            </div>
            <Button
              className={`w-100 create-ticket-btn p-3 ${
                success ? "submitted" : ""
              }`}
              disabled={creatingTicket === true || success === true}
              onClick={() => {
                // if (phoneNo === "") {
                //   CallToast(
                //     "Please enter a phone Number.",
                //     false,
                //     false,
                //     "v2style"
                //   );
                //   return;
                // }
                // if (formType === "") {
                //   CallToast(
                //     "Please enter a Ticket type.",
                //     false,
                //     false,
                //     "v2style"
                //   );
                //   return;
                // }
                if (ticketSummary === "") {
                  setSummaryError("poiuyt");
                  return;
                }
                createTicket();
              }}
            >
              {creatingTicket === false && (
                <>
                  Create Ticket
                  <img
                    alt=""
                    src="/images/settings-v2/white-plane.svg"
                    className="ml-2"
                  />
                </>
              )}

              {creatingTicket && (
                <>
                  <Spinner size="sm" className="mr8px" animation="border" />
                  Creating Ticket..
                </>
              )}

              {success && "Ticket Submitted!"}
            </Button>
          </>
        )}
        {page === "ticketData" && ticketType === "opened" && (
          <div className="message-typer d-flex align-items-center">
            <input
              type="file"
              className="d-none"
              ref={fileUpload}
              accept=".jpg, .jpeg, .png, .gif"
              onChange={(e) => {
                const selectedFile = e.target.files[0];

                if (selectedFile) {
                  const acceptedImageTypes = [
                    "image/jpeg",
                    "image/png",
                    "image/gif",
                  ];

                  // Check if the file type is in the list of accepted image types
                  if (acceptedImageTypes.includes(selectedFile.type)) {
                    setMessageImage(selectedFile);
                    e.target.value = null;
                  } else {
                    CallToast(
                      "Please upload a valid Image",
                      false,
                      false,
                      "v2style"
                    );
                    e.target.value = null; // Clear the input field
                  }
                }
              }}
            />
            <div
              className={`w-fit-content   ${
                messageImage ? "media-present" : ""
              }`}
            >
              <img
                alt=""
                src="/images/settings-v2/attach-file.svg"
                className="mx-2 pointer"
                onClick={() => {
                  fileUpload.current.click();
                }}
              />
            </div>
            <input
              type="text"
              placeholder="Write a message..."
              className="pl-2"
              value={message ?? ""}
              onChange={(e) =>
                setMessage(window.sanitizeHGtext(e.target.value))
              }
            />
            <Button
              disabled={!message && !messageImage}
              onClick={() => {
                setSendingMessage(true);
                sendMessage();

                setTimeout(() => {
                  const chatbox = document.querySelector(".modal-body");
                  chatbox.scrollTo({
                    top: chatbox?.scrollHeight,
                    behavior: "smooth",
                  });
                }, 100);
              }}
            >
              {sendingMessage ? (
                <div>
                  <WhiteLoader width={12} />
                </div>
              ) : (
                <img
                  alt=""
                  src="/images/settings-v2/white-plane.svg"
                  className=""
                  style={{ width: "20px" }}
                />
              )}
            </Button>
          </div>
        )}

        {page === "ticketData" && ticketType === "closed" && (
          <Button disabled={true} className="ticket-closed-btn">
            Ticket Closed!
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default SupportTicketV2;

const Message = ({ data, loginedUser }) => {
  return (
    <div className="ticket-message">
      {/* User details */}
      <div className="d-flex align-items-center justify-content-between">
        <div className="d-flex align-items-center ">
          {data?.userDetails?.showInitials ? (
            <ImageWithInitials
              initials={data?.userDetails?.Initials  ??  ""}
              width={30}
              background={"purple"}
            />
          ) : (
            <img
              alt=""
              width={30}
              height={30}
              src={data?.userDetails?.Avatar ?? "/images/user-logo.svg"}
              className="message-avatar"
              onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping
                currentTarget.src = "/images/user-logo.svg";
              }}
            />
          )}
          <div className="title ml-2 mr-1">{data?.userDetails?.FullName}</div>
          <div className="small-subtitle">
            {loginedUser?.UserName === data?.userDetails?.UserName
              ? "(You)"
              : data?.userDetails?.Role}{" "}
          </div>
        </div>
        <div className="small-subtitle">
          {moment(data?.date).format("MMMM Do YYYY, h:mm:ss a")}{" "}
        </div>
      </div>
      {/* User text */}
      <div className="message-text">
        {
          <p
            className="m-0"
            dangerouslySetInnerHTML={{
              __html: data?.text.replaceAll(("<br>", "\n"), "<br>"),
            }}
          />
        }
      </div>
      {/* Image wrapper */}
      {data?.Document_Thumbnails?.map((media) => {
        return (
          <div className="message-image-wrapper">
            <div
              className={`message-image-container   ${
                media?.ThumbnailImage ? "" : "d-none"
              }     `}
              style={{ width: "fit-content", position: "relative" }}
            >
              <img
                alt=""
                src={media?.ThumbnailImage}
                className="message-image"
              />
              <a href={media?.ThumbnailImage ?? ""} download>
                <img
                  alt=""
                  src="/images/settings-v2/download-cloud.svg"
                  className="overlay"
                />
              </a>
            </div>
          </div>
        );
      })}

      {/* Video wrapper */}
      {/* <div className="message-image-wrapper">
        <div
          className="message-image-container"
          style={{ width: "fit-content", position: "relative" }}
        >
          <img alt=""
            src={"/images/settings-v2/chat-video.svg"}
            className="message-image"
          />
          <img alt=""
            src="/images/settings-v2/download-cloud.svg"
            className="overlay"
          />
        </div>
      </div> */}
      {/* File wrapper */}
      {/* <div className="d-flex align-items-center justify-content-start message-file-wrapper">
        <img alt="" src="/images/settings-v2/file-icon.svg" className="mr-3" />
        <div>
          <p className="title m-0">File name.pdf</p>
          <p className="small-subtitle"> 200kb</p>
        </div>
        <img alt="" src="/images/settings-v2/download-cloud.svg" className="overlay" />
      </div> */}
    </div>
  );
};
