export function viewOnlyAttackSurface(_accessSection="AccessState_AttackSurface") {
    let objectData = JSON.parse(localStorage.getItem("object_Data") ?? "{}");
  
  
    if (!objectData || !objectData?.accessData) return false;
    if(!objectData?.accessData.hasOwnProperty(_accessSection))return false
    if (objectData?.accessData?.Override === true) {
      if (objectData?.accessData[_accessSection] === 1) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }