import React, { useState } from "react";
import "../SuppliersAndComplianceProfiles/supppliersAndComplianceProfiles.scss";
import {
  Button,
  Col,
  Dropdown,
  OverlayTrigger,
  Row,
  Tooltip,
} from "react-bootstrap";
import moment from "moment";
import QuestionnaireBigModal from "../QuestionnaireTab/QuestionnaireBigModal";
import { GreyLoader } from "../../Theme/APILoaders";
import QuestionnaireFilterDropdown from "../filterDropDown/QuestionnaireFilterDropdown";
import { getAPIData } from "../../../Utils/commonApi";
import { useAuth } from "../../context/Auth";
import { CallToast } from "../../PriceCalculator/modals/CustomToast";
import { DeleteModal } from "../../DeleteModal/DeleteModal";
import SendQuestionnaireModal from "../QuestionnaireTab/SendQuestionnaireModal";
import { QuestionnairePageLoader } from "../QuestionnaireTab/QuestionnaireTab";
import { viewOnlyAccess } from "../../App/App";
const QuestionnaireTable = ({
  hideHeader,
  setPage,
  data,
  SortQuestionaireColumn,
  columnSortingLoading,
  sortItem,
  clientsQuestions,
  suppliersQuestions,
  restrictOperations,
  isReceived,
  ArchiveOrDeleteQuestions,
  IsArchived,
  isSupplierPage,
  setFilterParameters,
  NoQuestionnaireState,
  refreshData,
  selectedSupplierId,
  loading = false,
}) => {
  const [showQuestionnaireModal, setShowQuestionnaireModal] = useState(false);
  const [editTemplateId, setEditTemplateId] = useState(null);
  const { authTokens } = useAuth();
  const [deleteSentQuestionnaireId, setDeleteSentQuestionnaireId] =
    useState("");
  const [showSendQuestionnaireModal, setShowSendQuestionnaireModal] =
    useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  async function sendReminder(questionnaireId, supplierId) {
    getAPIData(331, authTokens, {
      optionEnum1: questionnaireId,
      optionEnum2: supplierId,
    })
      .then((response) => {
        if (response?.Success) {
          CallToast("Reminder sent successfully", false, false, "v2style");
        } else {
          CallToast("Something went wrong", false, false, "v2style");
        }
      })
      .catch((err) => {
        console.log(err);
        CallToast("Something went wrong", false, false, "v2style");
      });
  }

  return (
    <div className="supplierAndComplianceProfile-wrapper">
      {/* Header line */}
      <div
        className={`justify-content-between w-100 mt-4 px-3 mb-3 ${
          hideHeader ? "d-none" : "d-flex"
        }`}
      >
        <div className="d-flex f-black f-500">Questionnaires</div>
        <div className="d-flex align-items-center">
          <div>
            <OverlayTrigger
              placement="left"
              trigger={
                viewOnlyAccess("AccessState_SCM") ? ["hover", "focus"] : []
              }
              delay={{
                show: 200,
                hide: 250,
              }}
              overlay={
                <Tooltip id={`tooltip-1365`} className="custom_tooltip_access">
                  This feature is managed by your service provider
                </Tooltip>
              }
            >
              <div>
                <Button
                  className={`mr-4 ${
                    viewOnlyAccess("AccessState_SCM")
                      ? "btn-disable"
                      : "hg-blue-btn "
                  } `}
                  onClick={() => {
                    setShowSendQuestionnaireModal(true);
                  }}
                >
                  {viewOnlyAccess("AccessState_SCM") ? (
                    <img
                      alt=""
                      src="/images/attack-surface/small-lock.svg"
                      className="mr-2"
                    />
                  ) : (
                    <></>
                  )}
                  Send Questionnaire
                </Button>
              </div>
            </OverlayTrigger>
          </div>
          <div className="f-darkgrey mr-2 questionnaire-filter">
            <QuestionnaireFilterDropdown
              setFilterParameters={setFilterParameters}
              allowDrillDownQuestionnaire={{ Option: true }}
            >
              <span className="f-black-imp">Filter</span>
            </QuestionnaireFilterDropdown>
          </div>
          <div>
            {/* <img alt="" src="/images/attack-surface/search-icon.svg" /> */}
          </div>
        </div>
      </div>

      {/* Table */}
      <div className="suppliers-data ">
        {/* table Data */}

        {loading && <QuestionnairePageLoader />}

        {!loading && (
          <>
            {data?.length === 0 ? (
              <>
                {NoQuestionnaireState ? (
                  <NoQuestionnaireState refreshData={refreshData} />
                ) : (
                  <div className="d-flex flex-column justify-content-center align-items-center w-50 mt-5 mx-auto">
                    <img
                      alt=""
                      src="/images/supply-chain/question-list-icon.svg"
                      className="no-supplier-icon mb-3"
                    />
                    <div className="banner-element-name mb-2">
                      No Questionnaires
                    </div>
                    <p
                      className="banner-subtitle text-center"
                      onClick={() => {}}
                    >
                      You don't have any questionnaire yet.
                    </p>
                  </div>
                )}
              </>
            ) : (
              <div className="table-data-wrapper">
                <div className="question-list-header  border-btm-grey p-2 pointer">
                  <div
                    className="ml-3"
                    onClick={() => {
                      SortQuestionaireColumn("Title");
                    }}
                  >
                    Title
                    {sortItem?.type === "Title" && (
                      <>
                        {columnSortingLoading ? (
                          <GreyLoader width={11} />
                        ) : (
                          <>
                            {sortItem?.order ? (
                              <img
                                alt=""
                                src="/images/actioncenter/arrowdown.svg"
                                className="ml-2"
                              />
                            ) : (
                              <img
                                alt=""
                                src="/images/actioncenter/arrowup.svg"
                                className="ml-2"
                                style={{ width: "10px" }}
                              />
                            )}
                          </>
                        )}
                      </>
                    )}
                  </div>
                  <div
                    onClick={() => {
                      SortQuestionaireColumn("Supplier");
                    }}
                  >
                    {clientsQuestions && "Supplier"}
                    {suppliersQuestions && "Client"}
                    {sortItem?.type === "Supplier" && (
                      <>
                        {columnSortingLoading ? (
                          <GreyLoader width={11} />
                        ) : (
                          <>
                            {sortItem?.order ? (
                              <img
                                alt=""
                                src="/images/actioncenter/arrowdown.svg"
                                className="ml-2"
                              />
                            ) : (
                              <img
                                alt=""
                                src="/images/actioncenter/arrowup.svg"
                                className="ml-2"
                                style={{ width: "10px" }}
                              />
                            )}
                          </>
                        )}
                      </>
                    )}
                  </div>
                  <div
                    onClick={() => {
                      SortQuestionaireColumn("Due_Date");
                    }}
                  >
                    Due date
                    {sortItem?.type === "Due_Date" && (
                      <>
                        {columnSortingLoading ? (
                          <GreyLoader width={11} />
                        ) : (
                          <>
                            {sortItem?.order ? (
                              <img
                                alt=""
                                src="/images/actioncenter/arrowdown.svg"
                                className="ml-2"
                              />
                            ) : (
                              <img
                                alt=""
                                src="/images/actioncenter/arrowup.svg"
                                className="ml-2"
                                style={{ width: "10px" }}
                              />
                            )}
                          </>
                        )}
                      </>
                    )}
                  </div>
                  <div
                    onClick={() => {
                      SortQuestionaireColumn("LastUpdated");
                    }}
                  >
                    Last edited
                    {sortItem?.type === "LastUpdated" && (
                      <>
                        {columnSortingLoading ? (
                          <GreyLoader width={11} />
                        ) : (
                          <>
                            {sortItem?.order ? (
                              <img
                                alt=""
                                src="/images/actioncenter/arrowdown.svg"
                                className="ml-2"
                              />
                            ) : (
                              <img
                                alt=""
                                src="/images/actioncenter/arrowup.svg"
                                className="ml-2"
                                style={{ width: "10px" }}
                              />
                            )}
                          </>
                        )}
                      </>
                    )}
                  </div>

                  <div></div>
                </div>
                {data?.map((question, questionIndex) => {
                  return (
                    <div
                      className={`question-list-row pointer ${
                        questionIndex === data.length - 1
                          ? ""
                          : "border-btm-grey"
                      } `}
                      onClick={() => {
                        // If its not a supplier page
                        if (setPage && !isSupplierPage) {
                          setPage({
                            questionnaireData: {
                              supplierId: question?.Supplier?.objId_Customer,
                              templateId: question?.FormTemplateId,
                              supplierName: question?.Supplier?.SupplierName,
                              supplierLogo: question?.Supplier?.SupplierLogo,
                              dueDate: question?.Duedate,
                              templateName: question?.Title,
                              questionnaireId: question?.ObjectId,
                            },
                            QuestionType: isReceived ? "received" : "sent",
                            type: "questionAnswers",
                            name: `${question?.Title}`,
                            isSent: isReceived ? false : true,
                            isSupplierPage: false,
                          });
                        }

                        if (setPage && isSupplierPage) {
                          setPage(question?.Title, "questionAnswers", {
                            questionnaireData: {
                              supplierId: question?.Supplier?.objId_Customer,
                              templateId: question?.FormTemplateId,
                              supplierName: question?.Supplier?.SupplierName,
                              supplierLogo: question?.Supplier?.SupplierLogo,
                              dueDate: question?.Duedate,
                              templateName: question?.Title,
                              questionnaireId: question?.ObjectId,
                            },
                            QuestionType: isReceived ? "received" : "sent",
                            type: "questionAnswers",
                            name: `${question?.Title}`,
                            isSent: isReceived ? false : true,
                            isSupplierPage: true,
                          });
                        }
                      }}
                    >
                      <div className="d-flex align-items-center">
                        <div className="supplier-popover-parent">
                          <img
                            alt=""
                            src={"/images/supply-chain/question-list-icon.svg"}
                            className="mr-2"
                          />
                        </div>
                        <div>
                          <p className="m-0 f-14 f-500">{question?.Title}</p>
                          <div className="d-flex align-items-center">
                            <div
                              className="flex align-items-center"
                              style={{ width: "182px" }}
                            >
                              <progress
                                min="0"
                                value={question?.QuestionnairesPercentage}
                                max="100"
                              />
                            </div>
                            <div className="f-500 f-darkgrey ml-2">
                              {question?.QuestionnairesPercentage}%
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* {!supplier.disabled ? ( */}
                      {/* <div className="nested-row "> */}

                      <div className="f-grey d-flex align-items-center f-black f-500">
                        <img
                          alt=""
                          src={
                            question?.Supplier?.SupplierLogo ??
                            "/images/settings-v2/highground-dp.svg"
                          }
                          width={24}
                          height={24}
                          className="mr-2 rounded-full"
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null; // prevents looping
                            currentTarget.src =
                              "/images/settings-v2/highground-dp.svg";
                          }}
                        />
                        {question?.Supplier?.SupplierName}
                      </div>
                      <div className="f-grey d-flex align-items-center f-black f-500">
                        {/* {moment(question?.Duedate).format("MMMM Do YYYY")} */}
                        {moment(question?.Duedate).format("DD/MM/YYYY HH:mm")}
                      </div>
                      <div className="f-grey d-flex align-items-center f-black f-500">
                        {moment(question?.LastUpdated).format(
                          "DD/MM/YYYY HH:mm"
                        )}
                        {/* {moment(question?.LastUpdated).format("MMMM Do YYYY")} */}
                      </div>
                      <div className="d-flex align-items-center justify-content-end mr-4 supplier-list-dropdown">
                        <div
                          className={`menu-dropdown  ${
                            restrictOperations ? "d-none" : ""
                          }  `}
                          onClick={(e) => e.stopPropagation()}
                        >
                          <Dropdown className="device-dropdown">
                            <Dropdown.Toggle>
                              <img alt="" src="/images/horizontal-dots.svg" />
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              <div>
                                <Row>
                                  <Col xs={12} className=" pointer ">
                                    <OverlayTrigger
                                      placement="left"
                                      trigger={
                                        viewOnlyAccess("AccessState_SCM")
                                          ? ["hover", "focus"]
                                          : []
                                      }
                                      delay={{
                                        show: 200,
                                        hide: 250,
                                      }}
                                      overlay={
                                        <Tooltip
                                          id={`tooltip-1365`}
                                          className="custom_tooltip_access"
                                        >
                                          This feature is managed by your
                                          service provider
                                        </Tooltip>
                                      }
                                    >
                                      <div>
                                        <div
                                          className="drop-item"
                                          style={{
                                            pointerEvents: viewOnlyAccess(
                                              "AccessState_SCM"
                                            )
                                              ? "none"
                                              : "all",
                                          }}
                                          onClick={(e) => {
                                            sendReminder(
                                              question?.ObjectId,
                                              question?.Supplier?.objId_Customer
                                            );
                                            e.target
                                              .closest(".device-dropdown")
                                              .click();
                                          }}
                                        >
                                          Send reminder
                                          {viewOnlyAccess("AccessState_SCM") ? (
                                            <img
                                              alt=""
                                              src="/images/attack-surface/small-lock.svg"
                                              className="ml-2"
                                            />
                                          ) : (
                                            <></>
                                          )}
                                        </div>
                                      </div>
                                    </OverlayTrigger>
                                  </Col>
                                  <Col xs={12} className="  pointer ">
                                    <div
                                      className="drop-item"
                                      onClick={(e) => {
                                        // setEditTemplateId(question?.FormTemplateId);
                                        setPage({
                                          questionnaireData: {
                                            supplierId:
                                              question?.Supplier
                                                ?.objId_Customer,
                                            templateId:
                                              question?.FormTemplateId,
                                            supplierName:
                                              question?.Supplier?.SupplierName,
                                            supplierLogo:
                                              question?.Supplier?.SupplierLogo,
                                            dueDate: question?.Duedate,
                                            templateName: question?.Title,
                                            questionnaireId: question?.ObjectId,
                                          },
                                          QuestionType: isReceived
                                            ? "received"
                                            : "sent",
                                          type: "questionAnswers",
                                          name: `${question?.Title}`,
                                          isSent: isReceived ? false : true,
                                          isSupplierPage: false,
                                        });

                                        e.target
                                          .closest(".device-dropdown")
                                          .click();
                                        // setShowQuestionnaireModal(true);
                                      }}
                                    >
                                      View {/* Edit */}
                                    </div>
                                  </Col>

                                  {question?.QuestionnairesPercentage ===
                                    100 && (
                                    <Col xs={12} className=" pointer ">
                                      <div
                                        className="drop-item"
                                        onClick={(e) => {
                                          ArchiveOrDeleteQuestions(
                                            question?.ObjectId,
                                            IsArchived ? 2 : 0
                                          );
                                          e.target
                                            .closest(".device-dropdown")
                                            .click();
                                        }}
                                      >
                                        {IsArchived ? "Unarchive" : "Archive"}
                                      </div>
                                    </Col>
                                  )}
                                  <Col xs={12} className=" pointer ">
                                    <OverlayTrigger
                                      placement="left"
                                      trigger={
                                        viewOnlyAccess("AccessState_SCM")
                                          ? ["hover", "focus"]
                                          : []
                                      }
                                      delay={{
                                        show: 200,
                                        hide: 250,
                                      }}
                                      overlay={
                                        <Tooltip
                                          id={`tooltip-1365`}
                                          className="custom_tooltip_access"
                                        >
                                          This feature is managed by your
                                          service provider
                                        </Tooltip>
                                      }
                                    >
                                      <div>
                                        <div
                                          className="drop-item"
                                          style={{
                                            pointerEvents: viewOnlyAccess(
                                              "AccessState_SCM"
                                            )
                                              ? "none"
                                              : "all",
                                          }}
                                          onClick={(e) => {
                                            setDeleteSentQuestionnaireId(
                                              question?.ObjectId
                                            );
                                            setShowDeleteModal(true);
                                            // ArchiveOrDeleteQuestions(
                                            //   question?.ObjectId,
                                            //   1
                                            // );
                                            e.target
                                              .closest(".device-dropdown")
                                              .click();
                                          }}
                                        >
                                          Delete{" "}
                                          {viewOnlyAccess("AccessState_SCM") ? (
                                            <img
                                              alt=""
                                              src="/images/attack-surface/small-lock.svg"
                                              className="ml-2"
                                            />
                                          ) : (
                                            <></>
                                          )}
                                        </div>
                                      </div>
                                    </OverlayTrigger>
                                  </Col>
                                </Row>
                              </div>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
          </>
        )}
      </div>
      <QuestionnaireBigModal
        show={showQuestionnaireModal}
        hideModal={() => setShowQuestionnaireModal(false)}
        editTemplateId={editTemplateId}
      />

      <SendQuestionnaireModal
        show={showSendQuestionnaireModal}
        hideModal={() => setShowSendQuestionnaireModal(false)}
        // title={templateData?.Title}
        // templateId={templateData?.ObjectId}
        selectedSupplierId={selectedSupplierId}
        refreshData={refreshData}
      />
      <DeleteModal
        show={showDeleteModal}
        hideModal={() => setShowDeleteModal(false)}
        deleteMessage={"You are about to delete a questionnaire."}
        deleteType={"deleteSentQuestionnaire"}
        deleteSentQuestionnaire={() => {
          ArchiveOrDeleteQuestions(deleteSentQuestionnaireId, 1);
        }}
      />
    </div>
  );
};

export default QuestionnaireTable;

export function formatDate(date) {
  let day = date.getDate();
  let month = date.getMonth() + 1;
  let year = date.getFullYear();
  let hours = date.getHours();
  let minutes = date.getMinutes();

  // Add leading zeros to single-digit day, month, hours, and minutes
  if (day < 10) {
    day = "0" + day;
  }
  if (month < 10) {
    month = "0" + month;
  }
  if (hours < 10) {
    hours = "0" + hours;
  }
  if (minutes < 10) {
    minutes = "0" + minutes;
  }

  return `${day}/${month}/${year} ${hours}:${minutes}`;
}
