import { HGHighlightText } from "../../App/App";
import ArrowDownIcon from "../../icons/arrow-down.icon";
import { GreyLoader } from "../../Theme/APILoaders";
import RiskyUserTooltip from "../Overview/risky-user-tooltip/risky-user.tooltip";

const UserIssuesTable = ({ userIssues, overviewPageData, setOverviewPageData, updateMfaStatus, searchString }) => (
  <div className="bg-grey radius-8 border-parent">
    <div className="border-item mfa-table-row header">
      <div className="f-12 f-500 f-grey cell pl-2">Name</div>
      <div className="f-12 f-500 f-grey cell">Email</div>
      <div className="f-12 f-500 f-grey cell">Job title</div>
      <div className="f-12 f-500 f-grey cell">Risky User</div>
      <div className="f-12 f-500 f-grey cell">
        <span>MFA</span>
        <ArrowDownIcon className="ml-2" width={12} height={12} fill="#616778" />
      </div>
    </div>

    {userIssues?.map((item, itemIndex) => {
      return (
        <div className="border-item mfa-table-row row-item">
          <div className="">
            <div className="d-flex align-items-center">
              <img
                src="/images/user-logo.svg"
                alt=""
                className="mr-2 ml-2"
                width={30}
                height={30}
              />
              <span
                dangerouslySetInnerHTML={{
                  __html: HGHighlightText(item?.Name, searchString),
                }}
              />
            </div>
          </div>
          <div className="email">{item?.Email}</div>
          <div className="">{item?.JobTitle}</div>
          <div className="">
            <RiskyUserTooltip item={item}>
              <img
                src="/images/msp/green-tick.svg" /* MOCK DATA */
                alt=""
                className="mr-2"
                width={13}
                height={13}
              />
              <span>Yes</span> {/* MOCK DATA */}
            </RiskyUserTooltip>
          </div>
          <div className="">
            {item?.MFAState === true && (
              <div className="f-black">
                <img
                  src="/images/msp/green-tick.svg"
                  alt=""
                  className="mr-2"
                />
                Enabled
              </div>
            )}
            {item?.MFAState === false && (
              <div
                className="bg-blue f-white pointer w-fit-content p-1 px-2 radius-2"
                onClick={(e) => {
                  e.stopPropagation();
                  let updated_data = { ...overviewPageData };
                  updated_data.MFAStatusList[itemIndex].MFAState =
                    null;
                  setOverviewPageData(updated_data);
                  updateMfaStatus({
                    MFAState:2,
                    UserId:item?.UserId,
                    UserRecordId:item?.UserRecordId
                  })
                }}
              >
                Enable
              </div>
            )}
            {item?.MFAState === null && (
              <div
                className="d-flex align-items-center p-1"
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <GreyLoader className={"mr-2"} width={12} />
                Enabling...
              </div>
            )}
          </div>
        </div>
      );
    })}
  </div>
);

export default UserIssuesTable;
