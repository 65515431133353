import React from "react";
import { Modal } from "react-bootstrap";

const BigModal = ({ show, hideModal }) => {
  const HideModal = () => {
    hideModal();
  };
  return (
    <Modal
      animation={false}
      // show={true}
      show={show}
      dialogClassName="right big-modal"
      className=" "
      aria-labelledby="big-modal"
      onHide={hideModal}
      centered
      scrollable
    >
      <Modal.Header className="text-center">
        <div
          className="w-100 mb-0 font-bold-14 title"
          style={{ fontWeight: "600" }}
        >
          Referral Programme Rules
        </div>

        <span
          className="cursor-pointer"
          onClick={() => {
            HideModal();
          }}
        >
          <img src="/images/icons/close.svg" alt="" />
        </span>
      </Modal.Header>

      <Modal.Body className="p-4 hide-scrollbar">
        <div className="heading mb-3 mt-1">How it Works</div>
        <p className="desc mb-3">
          HighGround users are rewarded with Professional Services Credit for
          referring colleagues, peers or any individual who will use the product
          within their business to improve their cyber security compliance,
          visibility or access services available for purchase through
          HighGround. Users should not refer to friends, family or any
          individual who has no intention of using or reasonable use for the
          product.
        </p>
        <p className="desc">
          Referrals are each rewarded with £5 Professional Services credit, up
          to a maximum of £500 for the lifetime of the account.The user must
          complete signup for a Freemium account using the link in the referral
          email for the referrer to redeem the reward. Rewards are redeemed
          automatically and should be available immediately after the user
          completes signup, however this can take up to 24 hours. If you do not
          have your reward within 24 hours, please contact support from within
          your portal.
        </p>
        <div className="heading mb-3">Restrictions</div>

        <p className="desc m-big">
          Users cannot invite users using free or anonymous email services. Any
          user suspected to be using a free or throwaway email address which is
          not banned during registration, but which is later identified as being
          so, will have the reward associated with this referral revoked, and
          may have their account suspended indefinitely and without notice. Any
          credit consumed to this point for services purchased directly or
          indirectly via HighGround will also be rescinded, and users will be
          liable to repay any credit used up to that point.
        </p>
      </Modal.Body>
    </Modal>
  );
};

export default BigModal;
