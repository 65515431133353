import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { Col, Row, Container, Modal, Dropdown, Table } from "react-bootstrap";
import ContentLoader from "react-content-loader";

// import "./WebFilteringBoxes.css";

const TableContentLoader = (props) => {
  const [text] = useState(props.mainText || "");
  const [arrayData, setarrayData] = useState(props.data || "");
  const [lableName] = useState(props.contentLoaderLabel || 4);
console.log(lableName,"am not here")
  return (
    <div className="table-responsive tech-table" style={{ overflow: "auto" }}>
      <Table striped>
        <thead
          className="tech-header"
          // style={{ backgroundColor: backgroundColor }}
        >
          <tr className="table-row">
            {lableName
              ? lableName.map((item, index) => {
                  return (
                    <th
                      className="tech-header"
                      key={index}
                      style={
                        {
                          // color: headerTextColor,
                          // width: item.width || "initial",
                        }
                      }
                    >
                      {item.text}
                    </th>
                  );
                })
              : ""}
          </tr>
        </thead>
        <tbody>
          {/* content loader */}

          {[...Array(5)].map((elementInArray, index) => (
            <tr className="mt-2">
              {[...Array(lableName.length)].map((elementInArray, index) => (
                <td>
                  <ContentLoader
                    speed={2}
                    width={88}
                    height={25}
                    viewBox="0 0 88 25"
                    {...props}
                  >
                    <rect x="24" y="3" rx="3" ry="3" width="84" height="6" />
                    <rect x="27" y="17" rx="3" ry="3" width="64" height="4" />
                  </ContentLoader>
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default TableContentLoader;
