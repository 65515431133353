export const MINIMAL_DATE = new Date(0);
export const NO_DATE = "0001-01-01T00:00:00";

export const ROUTE_PATHS = {
  mspv2: {
    securityStack: '/mspv2/securitystack',
    clients: {
      technologySpend: (clientId) => `/mspv2/clients/clientsView/${clientId}/technology-spend`,
      userIssues: (clientId) => `/mspv2/clients/clientsView/${clientId}/attack-surface?section=MFA`,
      deviceIssues: (clientId) => `/mspv2/clients/clientsView/${clientId}/attack-surface?section=EOL`,
      license: (clientId) => `/clientmspsettingsv2/${clientId}/company-settings`,
      securityPackage: (clientId) => `/mspv2/clients/clientsView/${clientId}/managed-security`
    },
  },
  saas: {
    technologySpend: '/technology-spend',
    userIssues: '/attack_surface?section=MFA',
    deviceIssues: '/attack_surface?section=EOL',
    license: '/settings/v2/my_profile',
    securityPackage: '/managed_security'
  }
};
