import React, { useState, useEffect } from "react";
import "./CyberScore.css";

import $ from "jquery";

import ModalDynamic from "../ModalDynamic/ModalDynamic";
import CyberScoreDetails from "../CyberScoreDetails/CyberScoreDetails";
import KPIpopup, { AttestationPopUp } from "../AttackSurface/KPIpopup/KPIpopup";
import { useSelector } from "react-redux";
import { setuser } from "../../redux/userState/actions";
import { useDispatch } from "react-redux";
import { Attestation_Icon_Mappings } from "../ComplianceManager/WebFilteringBoxes/WebFilteringBoxes";

const CyberScore = (props) => {
  const dispatch = useDispatch();
  const userDetails = useSelector((state) => state?.user?.userDetail);
  const cyberScoreValue = useSelector(
    (state) => state?.user?.userDetail?.cyberScoreValue
  );
  const newCyberScoreupdated = useSelector(
    (state) => state?.user?.userDetail?.newCyberScoreupdated
  );


  const [showRipple, setShowRipple] = useState(false);
  useEffect(() => {
    if (props.IsTrendDataScreen) return;
    setScoreNumber(cyberScoreValue);
  }, [cyberScoreValue, props.IsTrendDataScreen]);
  function showRippleEffect() {
    setShowRipple(true);
    setTimeout(() => {
      setShowRipple(false);
    }, 5000);
  }

  const [initialRender, setInitialRender] = useState(true);

  useEffect(() => {
    // This function will run every time the 'score' prop changes
    if (!initialRender) {
      showRippleEffect();
    } else {
      // Set initial render to false after the first render
      setInitialRender(false);
    }
  }, [props.scoreNumber]);

  const [loadingComplete, setloadingComplete] = useState(false);
  const [ModalComponent, setModalComponent] = useState();
  const uniqueNum = props.id ? props.id : new Date().getUTCMilliseconds(); //new Date().getUTCMilliseconds();
  const [scoreNumber, setScoreNumber] = useState(props.scoreNumber || "");
  const [width] = useState(props.Width || 240);
  const [height] = useState(props.Height || 240);
  const [showBottomText] = useState(props.bottomText === false ? false : true);
  const [dataStroke] = useState(props.dataStroke || 30);
  const [size] = useState(props.size || "large");
  const hideLabels = props.hideLabels || false;
  const fontSize = props.fontSize || false;
  const position = props.position || false;
  const margin = props.margin || false;
  const displayModal = props.displayModal;

  function indicatorInit(id) {
    var indicator = document.getElementById(id);
    var ctx = indicator.getContext("2d");

    indicator.setAttribute("width", width * 2);
    indicator.setAttribute("height", height * 2);
    indicator.style.width = width + "px";
    indicator.style.height = height + "px";
    ctx.scale(2, 2);

    var percent = indicator.getAttribute("data-percent") / 100;

    var trackStartAngle = 40; // in degrees
    var trackEndAngle = 320; // in degrees
    var strokeWidth = indicator.getAttribute("data-stroke");
    var x = width / 2;
    var y = height / 2;
    var diameter = width > height ? height : width;
    var radius = diameter / 2 - strokeWidth / 2;

    // console.log("radius", radius, "diameter", diameter)

    var progressEndAngle =
      (trackEndAngle - trackStartAngle) * percent + trackStartAngle;
    var currentIteration = 0;

    function easeOutCubic(
      currentIteration,
      startValue,
      changeInValue,
      totalIterations
    ) {
      // console.log(currentIteration, startValue, changeInValue, totalIterations)
      return (
        changeInValue *
        (Math.pow(currentIteration / totalIterations - 1, 3) + 1) +
        startValue
      );
    }

    function degreesToRadians(angle) {
      var orientCircle = 90;
      return (Math.PI / 180) * (angle + orientCircle);
    }

    function draw(timestamp) {
      ctx.clearRect(0, 0, width, height);
      // console.log("width", width, "height", height)

      var startRadians = degreesToRadians(trackStartAngle);
      var endRadians = degreesToRadians(trackEndAngle);
      var lingrad = ctx.createLinearGradient(
        x - diameter / 2.2,
        0,
        x + diameter / 3.3,
        0
      );

      ctx.lineWidth = strokeWidth;

      if (indicator.getAttribute("data-type") === "B") {
        lingrad.addColorStop(0, "rgba(255, 92, 91, .8)");
        lingrad.addColorStop(0.35, "rgba(255, 133, 58, .8)");
        lingrad.addColorStop(0.8, "rgba(255, 209, 29, .8)");
        lingrad.addColorStop(1, "rgba(59, 217, 143, .8)");
      } else {
        lingrad.addColorStop(1, "#60E4F3");
      }

      // track
      ctx.strokeStyle = "#EBECF1";
      ctx.beginPath();
      ctx.arc(x, y, radius, startRadians, endRadians);
      // console.log("TEST", x, y, radius, startRadians, endRadians)
      ctx.stroke();

      // progress
      if (percent > 0) {
        // Calculate the current end angle of the progress using easing function
        var currentProgressEndAngle = easeOutCubic(
          currentIteration++,
          trackStartAngle,
          progressEndAngle,
          100
        );
        // Convert end angle to radians
        endRadians = degreesToRadians(currentProgressEndAngle);
        // Set the stroke style and draw the progress arc
        ctx.strokeStyle = lingrad;
        ctx.beginPath();
        ctx.arc(x, y, radius, startRadians, endRadians);
        ctx.stroke();
      }

      // stop the animation when progress animates to final angle
      if (currentProgressEndAngle <= progressEndAngle) {
        window.requestAnimationFrame(draw);
      }
    }

    if (indicator.getContext) {
      // start progress animation
      window.requestAnimationFrame(draw);
    }
  }

  useEffect(() => {
    indicatorInit(`indicatorB${uniqueNum}`);
    setloadingComplete(!loadingComplete);
  }, [scoreNumber, props.scoreNumber]);

  useEffect(() => {
    function animateColor(remove, add) {
      remove.removeClass("color");
      add.addClass("color");
    }
    function refreshColorsFromAll() {
      $(".status--dangerous").removeClass("color");
      $(".status--poor").removeClass("color");
      $(".status--good").removeClass("color");
      $(".status--excellent").removeClass("color");
    }
    var scorePercent =
      $(`#indicatorB${uniqueNum}`).attr("data-percent") ||
      scoreNumber?.toString();
    $({ countPieChartB: 0 }).animate(
      { countPieChartB: scorePercent },
      {
        duration: 1000,
        easing: "swing",
        step: function (currentCount) {
          if (currentCount !== undefined) {
            // $(`#cyber-score${uniqueNum}`).text(Math.ceil(this.countPieChartB));
            if (currentCount === 0) {
              refreshColorsFromAll();
            } else if (currentCount > 0 && currentCount < 20) {
              refreshColorsFromAll();
              animateColor($(".status--dangerous"), $(".status--dangerous"));
              $("#score__number").addClass("score__number--red-shadow");
            } else if (currentCount >= 20 && currentCount < 60) {
              refreshColorsFromAll();
              animateColor($(".status--dangerous"), $(".status--poor"));
              $("#score__number")
                .removeClass("score__number--red-shadow")
                .addClass("score__number--orange-shadow");
            } else if (currentCount >= 60 && currentCount < 80) {
              refreshColorsFromAll();
              animateColor($(".status--poor"), $(".status--good"));
              $("#score__number")
                .removeClass("score__number--orange-shadow")
                .addClass("score__number--yellow-shadow");
            } else if (currentCount >= 80 && currentCount <= 100) {
              refreshColorsFromAll();
              animateColor($(".status--good"), $(".status--excellent"));
              $("#score__number")
                .removeClass("score__number--yellow-shadow")
                .addClass("score__number--green-shadow");
            }
          }
        },
        complete: function () { },
      }
    );
  }, [loadingComplete]);

  function showModal() {
    setModalComponent(false);
    setTimeout(() => setModalComponent(true), 10);
  }

  return (
    // < !--Start Cyber Score-- >
    // {size == "small" ? "" : }
    <div
      id={`score__wrapper${uniqueNum}`}
      className="score__wrapper"
      style={{
        maxWidth: width,
        position: position ? position : "",
        margin: margin ? margin : "auto",
      }}
    >
      {ModalComponent ? (
        <ModalDynamic
          setShow={true}
          modalHeader={"CyberScore Details"}
          modalData={<CyberScoreDetails />}
        />
      ) : (
        ""
      )}

      {hideLabels ? (
        ""
      ) : (
        <React.Fragment>
          <span
            id={`status--dangerous${uniqueNum}`}
            className="status status--dangerous"
            style={
              size == "small"
                ? { left: "-10%", top: "52%" }
                : { background: "inherit" }
            }
          >
            Dangerous
          </span>
          <span id={`status--poor${uniqueNum}`} className="status status--poor">
            Poor
          </span>
          <span id={`status--good${uniqueNum}`} className="status status--good">
            Good
          </span>
          <span
            id={`status--excellent${uniqueNum}`}
            className="status status--excellent"
            style={
              size == "small"
                ? { right: "-8%", top: "52%" }
                : { background: "inherit" }
            }
          >
            Excellent
          </span>
        </React.Fragment>
      )}
      <div
        id={`score__container${uniqueNum}`}
        className="score__container"
        onClick={displayModal ? showModal : ""}
      >
        <div id={`score${uniqueNum}`} className="score">
          <canvas
            id={`indicatorB${uniqueNum}`}
            data-type="B"
            data-percent={scoreNumber}
            data-stroke={dataStroke}
            width={width}
            height={height}
          ></canvas>

          <span className={`${showRipple ? "request-loader- test-shock" : ""}`}>
            <span
              id={`cyber-score${uniqueNum}`}
              className="score__number cyber-score"
              style={{ fontSize: fontSize ? fontSize : "" }}
            >
              {props.scoreNumber}
            </span>
          </span>
        </div>
      </div>
      {showBottomText != false ? (
        <span
          className=" pt-4  d-flex align-items-center justify-content-center"
          onClick={(e) => { }}
        >
          <span className="score__message w-auto">
            Your CyberScore: <span className="cyber-score"></span>
            {props.scoreNumber}
          </span>

          <span className="ml-2">
            <AttestationPopUp>
              <img
                alt=""
                src={Attestation_Icon_Mappings[props.ScoreAttestation ?? 0]}
                className=""
              />
            </AttestationPopUp>
          </span>

          {/* <span
            className=""
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              let modal = document.getElementById("kpi-modal");
              modal.style.display = "block";
              modal.style.top = "-100px";
              modal.style.width = "100%";
            }}
          >
            <img
              alt=""
              src={`/images/attack-surface/${userDetails?.ScoreAttestation_Icon?.toLowerCase()}`}
              className="ml-2"
              onClick={(e) => {
                // e.stopPropagation();
                // let modal = document.getElementById("kpi-modal");
                // modal.style.display = "block";
                // modal.style.top = "-100px";
                // modal.style.width = "100%";
              }}
            />
          </span> */}
          {/* <span>
            <KPIpopup />
          </span> */}
        </span>
      ) : (
        ""
      )}
    </div>
    // <!--End Cyber Score-- >
  );
};

export default CyberScore;
