import React from 'react'

const WaitingScreen = () => {
  return (
    <div className=' w-100 d-flex justify-content-center'
    style={{
        paddingTop:"3%"
    }}
    >
        <img alt="" src="/images/attack-surface/coming-soon.svg "
        style={{
            width:"65%",
            objectFit:"contain"
            
        }}
        />
    </div>
  )
}

export default WaitingScreen