import React from 'react'; 
import CollapsabileTable from '../CollapsabileTable/CollapsabileTable';

const ActiveThreats = (props) => {
    return (
        <CollapsabileTable
            pageType={"Active Threats"}
            clientId={props.clientId}
        />
    );
}


export default ActiveThreats;