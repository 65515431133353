import React, { useState, useEffect, Fragment } from "react";
import { Button, Modal, Row, Col, Tooltip } from "react-bootstrap";
import "./style.scss";

const Loader = (props) => {
  const [msg, setMsg] = useState(props.message);
  useEffect(() => {
    const timer = setTimeout(() => {
     if(props.message){
      props.backScreen();
     }
    }, 3000);
    return () => clearTimeout(timer);
  }, [props.message]);

  return (
    <Fragment>
      <div className="loader_wrapper2">
        {props.isLoading && (
          <div className="loader2">
            <div className="text-center w-100">
              <div className="loader_circle" />
              <p className="mt-3 fnt12 loader_outer_noti">
                Sending {props.emailCount} emails...
              </p>
            </div>
          </div>
        )}

        {props.isResponse && (
          <div>
            {props.failCounts == props.emailCount ? (
              <div
                className="loader_success mx-auto"
                style={{
                  background: "rgb(243, 96, 96)",
                  border: "1px solid rgb(243, 96, 96)",
                }}
              >
                <div className="text-center w-100">
                  {/* <img src="/images/icons/check.svg" className="mt-2" /> */}
                  <p className="fnt12 clrwht mt-3 loader_outer_noti">
                    {props.message}
                  </p>
                </div>
              </div>
            ) : (
              <div className="loader_success mx-auto">
                <div className="text-center w-100">
                  <img src="/images/icons/check.svg" className="mt-2" />
                  <p className="fnt12 clrwht mt-3 loader_outer_noti">
                    {props.message}
                  </p>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </Fragment>
  );
};

export default Loader;
