import React, { useState, useEffect } from "react";
import {
  Button,
  Modal,
  Row,
  Col,
  InputGroup,
  Form,
  OverlayTrigger,
  FormControl,
  Tooltip,
} from "react-bootstrap";
import "./index.scss";
import { useAuth } from "../../../context/Auth";
import AddCardForm from "./AddCardForm";
import PaymentResponse from "./PaymentResponse";
import { postData, getAPIData , addPaymentMethodStripe, confirmPaymentStripe, attachPaymentMethodStripe} from "../../../../Utils/commonApi";


const UpgradePremium = (props) => {
  const {data} = props;
  const {setData} = props; 
  const [showModal, setShowModal] = useState(data.show);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedService, setSelectedService] = useState({});
  const [packageModalData, setPackageModalData] = useState({});
  const [stripeError, setStripeError] = useState(false);
  const [reponseModal, setReponseModal] = useState(false);
  const [toastMsg, setToastMsg] =  useState({
    show: false,
    msg: false,
  });
   const { authTokens } = useAuth();

  async function confimrPayment(paymentIntent, paymentMethod) {
    setIsLoading(true);
   const data = await confirmPaymentStripe(paymentMethod, paymentIntent)
    if (data.error) {
      setStripeError(true);
       setIsLoading(false);
    }else{
      setIsLoading(false);
    }
    setReponseModal(true);
  }



 // add card stripe
  async function addCardStripe(billingDetails, cardDetails, paymentIntentId) {
    const da  = {CardName:"Mr Card Name",
                CardNumber:"4242424242424242",
                ExpMonth:5,
                ExpYear:2022,
                Cvc:"314",
                CompanyName:"Objectra",
                CompanyAddress:"50 Street",
                Country:"GB",
                CompanyRegistration:"C Reg",
                VAT:"vat"}
    const res = await postData(authTokens, "AddPaymentMethod", da);
    return false;
    setIsLoading(true);
    const data = await addPaymentMethodStripe(billingDetails, cardDetails);
    if (data.error) {
      setToastMsg({
        show: true,
        msg: data.error.message,
      });
    } else {
      const attachPayment = await attachPaymentMethodStripe(
        paymentIntentId,
        data.id
      );
      if (!attachPayment) {
        setToastMsg({
          show: true,
          msg: "Something went wrong",
        });
      } else {
        if (attachPayment.error) {
          setToastMsg({
            show: true,
            msg: attachPayment.error.message,
          });
        }else{
           const confirmPayment = await confirmPaymentStripe(data.id, paymentIntentId)
           if(confirmPayment.error){
             setStripeError(true);
           }
            setReponseModal(true)
        }
      }
    }
    setIsLoading(false);
  }
  return (
    <Modal
      animation={false}
      show={data.show}
      dialogClassName="modal-50w"
      className="integrationModal centerModal dashboardModal upgradePremium"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      scrollable
    >
      <Modal.Header>
        <h1 className="w-100 mb-0 mt-0">
          <img class="logo" src="/images/logo/highground/regular.svg" />
        </h1>
        <span className="cursor-pointer">
          <img
            src="/images/icons/close.svg"
            alt=""
            onClick={() =>
              setData((prevState) => ({ ...prevState, show: false , isResponse: false }))
            }
          />
        </span>
      </Modal.Header>
      <Modal.Body>
        <Row className="m-0">
          {!data.isResponse ? (
            <>
              {" "}
              <AddCardForm data={data} setData={setData} stripeApi={{ confirm_payment: confimrPayment, addCard: addCardStripe }} />
            
            </>
          ) : (
            <PaymentResponse isError={stripeError} setData={setData} />
          )}
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default UpgradePremium;
