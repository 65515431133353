import React, { useState, useEffect } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { useAuth } from "./Components/context/Auth";
import CollapseTable from "./Components/Theme/collapsetable";
import { getAPIData, postRequest } from "./Utils/commonApi";
import "./auditlogs.scss";
import moment from "moment";

import Header from "./Components/Header";
import { useSelector } from "react-redux";
import AuditLogsLoader from "./AuditLogsLoader";
// import { TrimString } from "./Utils/StringTrimmer";
import { CallToast } from "./Components/PriceCalculator/modals/CustomToast";
import { GreyLoader } from "./Components/Theme/APILoaders";
import CompanyTimeZoneDropDown from "./Components/settingsV2/companySettings/companysettingsdrodown/CompanyTimeZoneDropDown";
import HGPopUpModal from "./Components/CustomModal/HGPopUpModal";

const AuditLogs = () => {
  const [viewModal, setViewModal] = useState(false);
  const isMenuCollapse = useSelector((state) => state?.global?.menuCollapse);
  useEffect(() => {
    setLoading(true);
    getPageData(0);
  }, []);
  const [details, setDetails] = useState("");
  const [loading, setLoading] = useState(false);
  const [auditLogs, setAuditLogs] = useState([]);
  const [data, setData] = useState({});
  const [filterList, setFilterList] = useState([]);
  const { authTokens } = useAuth();
  const [selectedFilter, setSelectedFilter] = useState({
    Item1: 100,
    Item2: "Filter",
  });

  const [filterItem, setFilterItem] = useState();
  const [firstLoad, setFirstLoad] = useState(true);

  async function getPageData(optionEnum1) {
    await getAPIData(`${905}`, authTokens, {
      optionEnum1: optionEnum1,
      optionEnum2: filterItem ? filterItem : null,
    }).then((response) => {
      setLoading(false);
      setLoadingList(false);
      if (response?.mr?.Success) {
        setAuditLogs([...auditLogs, ...response?.AuditEntry_List]);
        setData(response);
        if (firstLoad) {
          setFilterList(response?.AuditType_List);
          setFirstLoad(false);
        }
      } else {
      }
    });
  }

  async function filterPageData(optionEnum1, optionEnum2) {
    setLoading(true);
    await getAPIData(`${905}`, authTokens, {
      optionEnum1: optionEnum1,
      optionEnum2: optionEnum2,
    }).then((response) => {
      setLoading(false);
      setLoadingList(false);
      if (response?.mr?.Success) {
        setAuditLogs([...response?.AuditEntry_List]);
        setData(response);
        // setFilterList(response?.AuditType_List || []);
      } else {
      }
    });
  }

  const [loadingList, setLoadingList] = useState(false);
  const ScrollFunction = () => {
    if (loadingList) return false;
    const divv = document?.getElementById("scrollable-table");
    const TotalHeight = divv?.scrollHeight;

    const ScrolledHeight = divv?.scrollTop + divv?.clientHeight + 10;

    if (ScrolledHeight > TotalHeight) {
      if (data?.NextAuditId) {
        setLoadingList(true);
        getPageData(data?.NextAuditId);
      }
    }
  };
  return (
    <>
      <div
        className="---"
        id="scrollable-table"
        style={{
          height: "100%",
          overflowY: "auto",
          overflowX: "hidden",
        }}
        onScroll={() => {
          ScrollFunction();
        }}
      >
        <Row className="fullHeight">
          <Col xs={12} className="px-0">
            <div className="dashboard fullHeight">
              <header
                className="d-flex justify-content-between navbar sticky-top "
                style={{
                  paddingTop: "0.8em",
                  background: "#FFFFFF",
                  position: "fixed",
                  width: `${isMenuCollapse ? "92.25%" : "83.25%"}`,
                  zIndex: 6,
                  marginLeft: "-0.9rem",
                  paddingLeft: "2rem",
                  borderBottom: `1px solid #EBEDF1`,
                  paddingBottom: "8px",
                }}
              >
                <h1>Audit Logs</h1>
                <Header />
              </header>
              <div
                className="w-100 d-flex flex-column"
                style={{ paddingTop: "3%" }}
              >
                <div
                  className="w-100 px-3 bg-white"
                  style={{ height: "max-content", minHeight: "100%" }}
                >
                  <br />
                  <br />
                  <div className="d-flex align-items-center justify-content-between my-3 px-2">
                    <div></div>
                    <div className="filterdropd-wn d-flex align-items-center">
                      {selectedFilter.Item1 !== 100 && (
                        <div
                          className="d-flex align-items-center f-grey mr-2 pointer"
                          onClick={() => {
                            setFilterItem(null);
                            filterPageData(0);
                            setSelectedFilter({
                              Item1: 100,
                              Item2: "Filter",
                            });
                          }}
                        >
                          Reset
                        </div>
                      )}
                      <CompanyTimeZoneDropDown
                        fieldId={"Item1"}
                        fieldValue={"Item2"}
                        selected={selectedFilter}
                        placeholder="Choose..."
                        header={"Choose filter"}
                        onClick={(id, val) => {
                          setFilterItem(id);
                          filterPageData(0, id);
                          setSelectedFilter({
                            Item1: id,
                            Item2: val,
                          });
                        }}
                        data={filterList}
                      />
                    </div>
                  </div>

                  {/* <h3 className="ml-3">Audit Logs</h3> */}
                  <Row>
                    <Col xs={12}>
                      <CollapseTable disabled={true} hideArrow={true} header="">
                        <div>
                          <div>
                            <div className="d-flex align-items-center justify-content-between ">
                              <div
                                className="ml-2 d-flex align-items-center justify-content-center "
                                style={{ width: "25%" }}
                              >
                                Id
                              </div>
                              {/* <div
                                className="ml-2 d-flex align-items-center justify-content-center "
                                style={{ width: "25%" }}
                              >
                                User
                              </div> */}
                              <div
                                className="ml-2 d-flex align-items-center justify-content-center "
                                style={{ width: "25%" }}
                              >
                                Time
                              </div>
                              <div
                                className="ml-2 d-flex align-items-center justify-content-center "
                                style={{ width: "25%" }}
                              >
                                Type
                              </div>
                              <div
                                className="ml-2 d-flex align-items-center justify-content-center "
                                style={{ width: "25%" }}
                              >
                                Detail
                              </div>
                            </div>
                          </div>
                          <div>
                            <hr
                              style={{
                                borderTop: "1px solid #F6F8FB",
                              }}
                            />
                            {loading ? (
                              <AuditLogsLoader />
                            ) : auditLogs.length > 0 ? (
                              auditLogs.map((log, logIndex) => {
                                return (
                                  <div className="d-flex p-2 parentContainer justify-content-between">
                                    <div
                                      className="ml-2 d-flex align-items-center justify-content-center "
                                      style={{ width: "25%" }}
                                    >
                                      {log?.Id}
                                    </div>
                                    {/* <div
                                      className="ml-2 d-flex align-items-center justify-content-center "
                                      style={{ width: "25%" }}
                                    >
                                      {log?.User}
                                    </div> */}
                                    <div
                                      className="ml-2 d-flex align-items-center justify-content-center "
                                      style={{ width: "25%" }}
                                    >
                                      {moment(log?.TimeStamp).format(
                                        "MMMM Do YYYY, h:mm:ss a"
                                      )}
                                    </div>
                                    <div
                                      className="ml-2 d-flex align-items-center justify-content-center "
                                      style={{ width: "25%" }}
                                    >
                                      {log?.Type}({log?.Type_Text})
                                    </div>
                                    <div
                                      className="font14 d-flex align-items-center justify-content-center"
                                      style={{
                                        width: "25%",
                                        overflowWrap: "anywhere",
                                      }}
                                    >
                                      {/* {TrimString(log?.Detail, 25)}{" "} */}
                                      <Button
                                        className="reset-btn ml-3 hg-blue-btn"
                                        onClick={() => {
                                          setViewModal(true);
                                          setDetails(log?.Detail);
                                        }}
                                      >
                                        View
                                      </Button>
                                    </div>
                                  </div>
                                );
                              })
                            ) : (
                              <div className="f-black f-500 d-flex align-items-center justify-content-center mt-5 my-3">
                                No Record Found !
                              </div>
                            )}
                          </div>

                          {loadingList && (
                            <div className="d-flex justify-content-center align-items-center my-2">
                              <GreyLoader />
                              Loading...
                            </div>
                          )}
                        </div>
                      </CollapseTable>
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
      <ViewModal
        show={viewModal}
        hideModal={() => setViewModal(false)}
        data={details}
      />
    </>
  );
};

export default AuditLogs;

const ViewModal = ({ show, hideModal, data }) => {
  return (
    <HGPopUpModal
      show={show}
      dialogClassName="reset-settings-modal  audit-view-modal"
      className=""
      aria-labelledby="reset-settings-modal-title-vcenter"
      centered
      scrollable
      onHide={hideModal}
    >
      <HGPopUpModal.Header className="d-flex align-items-center justify-content-center">
        Details
      </HGPopUpModal.Header>

      <HGPopUpModal.Body>
        <div>
          <p className="modal-title ml-2 mt-2 mb-2">Response data</p>

          <div
            className="bg-grey border-1 radius-8 p-3"
            style={{
              border: "1px solid #f6f8fb",
              wordBreak: "break-word",
            }}
          >
            {data}
          </div>
        </div>
      </HGPopUpModal.Body>

      <HGPopUpModal.Footer className="position-relative">
        <div className="footer d-flex justify-content-between">
          <div
            className="d-flex align-items-center justify-content-center f-black pointer"
            onClick={() => {
              navigator.clipboard.writeText(data);
              CallToast("Text Copied !!", false, false, "v2style");
            }}
          >
            <img
              alt=""
              className="pointer mr-2"
              src="/images/settings-v2/copy-link.svg"
              style={{ filter: "invert(1)" }}
            />
            Copy to clipboard
          </div>

          <Button className="cancel-btn" onClick={hideModal}>
            Close
          </Button>
          {/* <Button className="reset-btn" onClick={() => {}}>
            Save
          </Button> */}
        </div>
      </HGPopUpModal.Footer>
    </HGPopUpModal>
  );
};
