import React, { useState } from "react";
import "./resetSettings.scss";
import { Button, Col, Row, Modal } from "react-bootstrap";
import { WhiteLoader } from "../../Theme/APILoaders";
import { getAPIData } from "../../../Utils/commonApi";
import { useAuth } from "../../context/Auth";
import { CallToast } from "../../PriceCalculator/modals/CustomToast";

const ResetSettings = () => {
  const [showModal, setshowModal] = useState(false);

  const showResetModal = () => {
    setshowModal(true);
  };
  const hideResetModal = () => {
    setshowModal(false);
  };
  return (
    <div className="reset-settings-wrapper">
      <h3>Reset Settings</h3>

      <Row>
        <Col xs={12}>
          <div
            className="d-flex justify-content-between settings-div"
            onClick={showResetModal}
          >
            <div>
              <p className="title m-0">Reset settings</p>
              <p className="subtitle">
                Restore settings to their original defaults
              </p>
            </div>
            <img src="/images/chevron-right.svg" className="mr-2" alt="" />
          </div>
        </Col>
      </Row>
      <ResetSettingsModal
        showResetModal={showModal}
        hideModal={hideResetModal}
      />
    </div>
  );
};

export default ResetSettings;

export const ResetSettingsModal = ({ showResetModal, hideModal,pageEnum ,refreshData }) => {
  const [loading, setLoading] = useState(false);
  const { authTokens } = useAuth();

  async function ResetSettings() {
    setLoading(true);
    await getAPIData(`${pageEnum ? pageEnum : "266"}`, authTokens).then((response) => {
      setLoading(false);
      if (response.Success) {
        if(refreshData){
          refreshData()
        }
        hideModal();
        CallToast("Settings updated successfully", false, false, "v2style");
      } else {
        CallToast("Something went wrong", false, false, "v2style");
      }
    });
  }
  return (
    <Modal
      animation={false}
      show={showResetModal}
      dialogClassName="reset-settings-modal"
      className=""
      aria-labelledby="reset-settings-modal-title-vcenter"
      centered
      scrollable
      onHide={hideModal}
    >
      <Modal.Body>
        <div>
          <p className="modal-title ml-2 mt-2">Reset settings?</p>
          <p className="modal-subtitle mx-2 mt-3">
       This will reset your { pageEnum ==291 ? 'C-Suite' :'Notifications'  } settings. Your personal settings, company setings, subscriptions and payment methods will not be cleared.
          </p>
        </div>
      </Modal.Body>

      <Modal.Footer>
        <div className="footer d-flex justify-content-end">
          <Button className="cancel-btn" onClick={hideModal}>
            Cancel
          </Button>
          <Button
            className="reset-btn"
            onClick={ResetSettings}
            disabled={loading}
          >
            {loading ? (
              <span>
                <WhiteLoader />
                Resetting..
              </span>
            ) : (
              "Reset settings"
            )}
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};
