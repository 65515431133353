import stringUtils from '../../Utils/string.utils';

const SearchTitle = ({ title, searchValue = '' }) => {
  if (!title) return false;
  if (!searchValue) return title;
  const parts = stringUtils.searchTitle(title, searchValue);
  if (!parts) return false;
  return (
    <div>
      {parts.map((part, index) => (
        <span
          key={`title-${index}`}
          style={{
            backgroundColor: part.isMatched ? "#ffdcaf" : "transparent",
          }}
        >
          {part.value}
        </span>
      ))}
    </div>
  );
}

export default SearchTitle;
