import { USER_DETAIL } from "./constants";

/**
 * Increments in calculator value
 *
 * @param {Object} data
 */

export const setuser = (data) => {
  return { type: USER_DETAIL, data };
};
