import React, { useState, useEffect } from "react";
import { Dropdown } from "react-bootstrap";
import { TrimString } from "../../../../Utils/StringTrimmer";
// import { useAuth } from "../../../context/Auth";

// import { useAuth } from "../../context/Auth";
// import { CallToast } from "../../PriceCalculator/modals/CustomToast";
import "./attackComponents.scss";

const CompanyDetailsDropdown = (props) => {
   
  const [uniqueId, setUniqueId] = useState("");
  const [showDropdown, setShowDropdown] = useState(false);
  const [firstRender, setFirstRender] = useState(false);
  useEffect(() => {
    if (props.data) {
      setData(props.data);
    }
  }, [props.data]);

  useEffect(() => {
    if (props.selected) {
      // setInputValue(props.selected);
      setSelectedOption(props.selected);
    }
  }, [props.selected]);

  const [data, setData] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [selectedOption, setSelectedOption] = useState("");

  useEffect(() => {
    let r = randomString();

    if (uniqueId === "") {
      setUniqueId(r);
    }

    if (uniqueId && firstRender === false) {
      setFirstRender(true);
      document.addEventListener("click", handleDropdownVisibility);
    }
    return () =>
      document.removeEventListener("click", handleDropdownVisibility);
  }, [uniqueId]);

  function handleDropdownVisibility(e) {
    let dropDown = document.getElementById(uniqueId);
    let createDiv = dropDown.querySelector("#create-option-div");
    let drop_toggler = document.querySelector(`.dropdown-toggle#${uniqueId}`);

    if (!dropDown.contains(e.target)) {
      setShowDropdown(false);
      setInputValue("");
      return;
    }

    if (drop_toggler.contains(e.target) || createDiv?.contains(e.target)) {
      setShowDropdown(true);
    } else {
      setTimeout(() => {
        setShowDropdown(false);
        setInputValue("");
      });
    }
  }

  return (
    <Dropdown
      className="company-multi-select-dropdown"
      id={uniqueId}
      show={showDropdown}
    >
      <Dropdown.Toggle className={``} id={uniqueId}>
        <div
          className={`d-flex align-items-center justify-content-end  ${
            props.selected ? "toggle-wrapper" : "no-item-selected"
          }  `}
        >
          {props.icon && (
            <img alt="" width={14} height={14} className="" src={props.icon} />
          )}
          <input
            autoFocus
            placeholder={`Search...`}
            type="text"
            className="border-none w-100 h-100 pl-2 py-1"
            value={inputValue}
            onChange={(e) => {
              setInputValue(e.target.value);
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                if (inputValue !== "") {
                  setSelectedOption(inputValue);
                  setInputValue("");
                }
              }
            }}
          />

          {props.selected ? (
            <>
              {" "}
              <div className="selected-value f-500 f-black">
                {TrimString(props.selected,35)}
              </div>
              <img
                alt=""
                className="mx-1"
                src="/images/supply-chain/company-chevron-down.svg"
              />
            </>
          ) : (
            <div className="f-500 f-grey add-btn">+ Add</div>
          )}
        </div>
      </Dropdown.Toggle>
      <Dropdown.Menu id={uniqueId}>
        <div className="px-2">
          {data &&
            data
              .filter((item) =>
                item.toLowerCase().includes(inputValue.toLowerCase())
              )
              .map((item, itemIndex) => {
                return (
                  <div
                    className={`${
                      selectedOption === item ? "f-black" : "f-grey"
                    } f-500 f-15 pointer py-1 ml-2 d-flex align-items-center justify-content-between`}
                    onClick={(e) => {
                      props.onChange(item);
                      // setInputValue(item);
                      setSelectedOption(item);

                      setShowDropdown(false);
                    }}
                  >
                    <span className="d-inline-flex align-items-center">
                      {item}
                    </span>
                    {selectedOption === item && (
                      <img
                        alt=""
                        width={11}
                        className="mb-1 mr-2"
                        src="/images/settings-v2/black-tick.svg"
                      />
                    )}
                  </div>
                );
              })}

          {inputValue && (
            <div
              className={`dropdown_item f-blue f-500 f-15 pointer py-1 ml-2 `}
              id={"create-option-div"}
              onClick={(e) => {
                if (inputValue !== "") {
                  if (props.onAdd) {
                    props.onAdd(inputValue);
                    setTimeout(() => {
                      setInputValue("");
                    }, 100);
                  }
                }
              }}
            >
              Create "{TrimString(inputValue, 13)}"
            </div>
          )}
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default CompanyDetailsDropdown;

export const CompanyServicesProvidedDropdown = (props) => {
  const [uniqueId, setUniqueId] = useState("");
  const [showDropdown, setShowDropdown] = useState(false);
  const [firstRender, setFirstRender] = useState(false);

  useEffect(() => {
    if (props.data) {
      setData(props.data);
    }
  }, [props.data]);

  useEffect(() => {
    if (props.selected) {
      setSelectedOption(props.selected);
    }
  }, [props.selected]);

  const [data, setData] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [selectedOption, setSelectedOption] = useState([]);
  useEffect(() => {
    let r = randomString();

    if (uniqueId === "") {
      setUniqueId(r);
    }

    if (uniqueId && firstRender === false) {
      setFirstRender(true);
      document.addEventListener("click", handleDropdownVisibility);
    }
    return () =>
      document.removeEventListener("click", handleDropdownVisibility);
  }, [uniqueId]);

  function handleDropdownVisibility(e) {
    let dropDown = document.getElementById(uniqueId);
    let createDiv = dropDown.querySelector("#create-option-div");
    let drop_toggler = document.querySelector(`.dropdown-toggle#${uniqueId}`);

    if (!dropDown.contains(e.target)) {
      setShowDropdown(false);
      setInputValue("");
      return;
    }

    if (drop_toggler.contains(e.target) || createDiv?.contains(e.target)) {
      setShowDropdown(true);
    } else {
      setTimeout(() => {
        setShowDropdown(false);
        setInputValue("");
      });
    }
  }

  return (
    <Dropdown
      id={uniqueId}
      show={showDropdown}
      className="company-multi-select-dropdown services-provided-dropdown"
    >
      <Dropdown.Toggle className={``} id={uniqueId}>
        <div
          className={`d-flex align-items-center justify-content-end  ${
            false ? "toggle-wrapper" : "no-item-selected"
          }  `}
        >
          {props.icon && (
            <img alt="" width={14} height={14} className="" src={props.icon} />
          )}
          <input
            placeholder={`Select`}
            type="text"
            className="border-none w-100 h-100 pl-2 py-1"
            value={inputValue}
            onChange={(e) => {
              setInputValue(e.target.value);
            }}
            onKeyDown={(e) => {
              // if (e.key === "Enter") {
              //   if (inputValue !== "") {
              //     setSelectedOption(inputValue);
              //     setInputValue("");
              //   }
              // }
            }}
          />

          <>
            {" "}
            <div className="selected-value f-500 f-black">{inputValue}</div>
            <img
              alt=""
              className="mx-1 down-arrow"
              src="/images/supply-chain/company-chevron-down.svg"
            />
          </>

          <div
            className={`f-500 f-grey add-btn ${
              selectedOption?.length > 0 ? "pt-2" : ""
            }`}
          >
            + Add
          </div>
        </div>
      </Dropdown.Toggle>
      <Dropdown.Menu id={uniqueId}>
        <div className="px-2">
          {data &&
            data
              .filter((item) =>
                item.Text.toLowerCase().includes(inputValue.toLowerCase())
              )
              .map((item, itemIndex) => {
                const isSelected = selectedOption?.findIndex(
                  (elItem) => elItem.Text === item.Text
                );

                return (
                  <div
                    className={`${
                      isSelected !== -1 ? "f-black" : "f-grey"
                    } f-500 f-15 pointer py-1 ml-2 d-flex align-items-center justify-content-between`}
                    onClick={(e) => {
                      props.onChange(item);
                      // setInputValue(item.Text);
                      // setSelectedOption(item.Text);
                    }}
                  >
                    <span className="d-inline-flex align-items-center">
                      {item.Text}
                    </span>
                    {isSelected !== -1 && (
                      <img
                        alt=""
                        width={11}
                        className="mb-1 mr-2"
                        src="/images/settings-v2/black-tick.svg"
                      />
                    )}
                  </div>
                );
              })}

          {inputValue && (
            <div
              className={`dropdown_item f-blue f-500 f-15 pointer py-1 ml-2`}
              id={"create-option-div"}
              onClick={(e) => {
                if (inputValue !== "") {
                  if (props.onAdd) {
                    props.onAdd(inputValue);
                    setTimeout(() => {
                      setInputValue("");
                    }, 100);
                  }
                }
              }}
            >
              Create "{TrimString(inputValue, 13)}"
            </div>
          )}
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export function randomString() {
  let characters = "ABCDEFGHIJKLMNOPQRSTUVWXTZabcdefghiklmnopqrstuvwxyz";

  //specify the length for the new string
  let lenString = 15;
  let randomstring = "";

  //loop to select a new character in each iteration
  for (let i = 0; i < lenString; i++) {
    let rnum = Math.floor(Math.random() * characters.length);
    randomstring += characters.substring(rnum, rnum + 1);
  }
  return randomstring;
}
