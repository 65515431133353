import { BASE_API_ROUTES } from "../constants/apis.constants";

const getUserMessage = () => JSON.parse(localStorage.getItem("user_MessageObj"));
const getUserToken = () => JSON.parse(localStorage.getItem("tokens"));
const getUserInfo = () => JSON.parse(localStorage.getItem("object_Data"));

const validateUser = () => {
  const token = getUserToken();
  const message = getUserMessage();
  if (!message || !token) {
    return false;
  }
  return fetch(
    `${BASE_API_ROUTES.validateUserToken}?AccessKey=${message}`,
    {
      method: "GET",
      headers: {
        Accept: "*/*",

        Connection: "keep-alive",

        Authorization: `Bearer ${token}`,
      },
    }
  );
}

const userUtils = {
  validateUser,
  getUserMessage,
  getUserToken,
  getUserInfo
};

export default userUtils;
