import React from "react";
import { FiX } from "react-icons/fi";
class CircularProgressBar extends React.Component {
  state = {
    percentage: this.props.timer
  };

  componentDidMount() {
    var totalduration = this.props.duration;
    var duration = 0;
    var handle = setInterval(() => {
      this.setState({percentage: parseInt((duration/totalduration)*100)});
      if (duration != totalduration) {
        duration++;
      } else {
        clearInterval(handle);
      }
    }, 100);
  }

  render() {
    // Size of the enclosing square
    const sqSize = this.props.sqSize;
    // SVG centers the stroke width on the radius, subtract out so circle fits in square
    const radius = (this.props.sqSize - this.props.strokeWidth) / 2;
    // Enclose cicle in a circumscribing square
    const viewBox = `0 0 ${sqSize} ${sqSize}`;
    // Arc length at 100% coverage is the circle circumference
    const dashArray = radius * Math.PI * 2;
    // Scale 100% coverage overlay with the actual percent
    const dashOffset = dashArray - (dashArray * this.state.percentage) / 100;

    return (
      <svg
        width={this.props.sqSize}
        height={this.props.sqSize}
        viewBox={viewBox}
      >
        <circle
          className="circle-background"
          cx={this.props.sqSize / 2}
          cy={this.props.sqSize / 2}
          r={radius}
          strokeWidth={`${this.props.strokeWidth}px`}
        />
        <circle
          className="circle-progress"
          cx={this.props.sqSize / 2}
          cy={this.props.sqSize / 2}
          r={radius}
          strokeWidth={`${this.props.strokeWidth}px`}
          // Start progress marker at 12 O'Clock
          transform={`rotate(-90 ${this.props.sqSize / 2} ${
            this.props.sqSize / 2
          })`}
          style={{
            strokeDasharray: dashArray,
            strokeDashoffset: dashOffset,
          }}
        />
        <FiX x="14%" y="14%" textAnchor="middle" color="white" />
      </svg>
    );
  }
}

CircularProgressBar.defaultProps = {
  sqSize: 20,
  strokeWidth: 1,
  duration: 30,
};

export default CircularProgressBar;
