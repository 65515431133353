import React, { useState, useEffect } from "react";
import { Dropdown } from "react-bootstrap";
import { AttackSurfacePostApi } from "../../../Utils/commonApi";
import { TrimString } from "../../../Utils/StringTrimmer";
import { useAuth } from "../../context/Auth";
import { CallToast } from "../../PriceCalculator/modals/CustomToast";
import AttackSurfaceTooltip from "../AssetsList/AttackSurfaceTooltip";
import SmallWorkstationModal from "../AssetsList/WorkStations/SmallWorkstationModal";
import { RenderImagesGroup } from "../AssetsList/WorkStations/WorkstationModal";
import { cleanText } from "../Index";
import "./attackComponents.scss";
import { changeVaribleToName } from "./AttackLocationDropdown";

const MultiSelectDropdown = (props) => {
  const { locked, assetKey, assetId ,disabled} = props;
  const { authTokens } = useAuth();
  const [data, setData] = useState({ 1: [] });
  const [inputValue, setInputValue] = useState("");
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [showSmallModal, setShowSmallModal] = useState(false);
  const [newAssetId, setNewAssetId] = useState("");

  const [intialInputValuetoNestedModal, setIntialInputValuetoNestedModal] =
    useState("");

  const [assetsKey, setAssetsKey] = useState("");

  useEffect(() => {
    if (props.data) {
      setData(props.data);
    }
  }, []);

  useEffect(() => {
    if (props.data !== null && Object.keys(props.data)?.[0] !== "") {
      setItemKey(Object.keys(props.data)?.[0]);
    }
  }, [props.data]);

  useEffect(() => {
    setSelectedOptions(
      props.selected === null || props.selected === "" ? [] : props.selected
    );
    if (typeof props.selected === "object" && props.selected?.length === 0) {
      setInputValue("");
    }
  }, [props.selected]);

  const [itemKey, setItemKey] = useState();
  function addValueToDropdownList(value, key = itemKey) {
    let updated_data = { ...data };
    updated_data[key].push({
      ...value,
    });
    setData(updated_data);
    // createNewOption(props.objectID, inputValue);

    // ////////ADD THE ITEM TO THE SELECTED VALUE

    let selected_list = [...selectedOptions];
    selected_list.push(value);
    setSelectedOptions(selected_list);
    props.onChange(selected_list);

    setInputValue("");
  }

  //  function to create new record by typing into Input and Pressing enter

  async function createNewValue(val) {
    try {
      await AttackSurfacePostApi(
        authTokens,
        `AttackSurfaceAPI/ExtendDDLList?ObjDefId=${assetId}&AttrKey=${assetKey}&AttrValue=${val}`,
        {
          // ObjDefId: assetId,
          // AttrKey: assetKey,
          // AttrValue: val,
        }
      ).then((response) => {
        if (response?.Success) {
          let created_value = { ...response?.ObjectResp };
          if ("RelatedObjectDetailsVM" in created_value) {
            delete created_value.RelatedObjectDetailsVM;
          }
          let key = Object.keys(data)[0];
          if (key) {
            addValueToDropdownList(created_value, key);
          }
        } else {
          CallToast("Something went wrong", false, false, "v2style");
        }
      });
    } catch (err) {
      CallToast("Something went wrong", false, false, "v2style");
      console.log(err);
    }
  }

  return (
    <Dropdown
      className="attack-multi-select-dropdown"
      disabled={locked}
      style={{
        pointerEvents: `${(locked ||disabled) ? "none" : "all"}`,
      }}
    >
      <Dropdown.Toggle className={`${props.isError ? "input-error" : ""}`}>
        <div className="d-flex flex-wrap">
          {selectedOptions &&
            selectedOptions?.map((selected, selectedIndex) => {
              return (
                <div
                  className="f-12 f-500 f-grey d-flex align-items-center p-2 mr-1 mb-1  bg-grey"
                  title={selected?.AttributeText}
                >
                  {locked && (
                    <span className="d-inline-flex">
                      <AttackSurfaceTooltip
                        className={""}
                        disabled
                        content={
                          <span>
                            Auto-generated cannot be edited
                            <RenderImagesGroup sourceName={"webroot"} />
                          </span>
                        }
                      >
                        <img
                          alt=""
                          src="/images/attack-surface/small-lock.svg"
                          className="mr-1"
                        />
                      </AttackSurfaceTooltip>
                    </span>
                  )}

                  <span className="d-inline-flex">
                    {TrimString(selected?.AttributeText, 20)}
                  </span>

                  <img
                    alt=""
                    src="/images/attack-surface/grey-cross-icon.svg"
                    className={`ml-1 pointer ${locked ? "d-none" : ""}`}
                    onClick={(e) => {
                      e.stopPropagation();
                      let updated_data = [...selectedOptions];
                      let Index = updated_data.findIndex(
                        (item) => item?.AttributeId === selected?.AttributeId
                      );
                      updated_data.splice(Index, 1);
                      setSelectedOptions(updated_data);
                      props.onChange(updated_data);
                    }}
                  />
                </div>
              );
            })}
          <input
            tabIndex={-1}
            placeholder="Add"
            type="text"
            className={`border-none w-100 h-100 ${locked ? "d-none-" : ""} `}
            style={{
              pointerEvents: `${locked ? "none" : "all"}`,
            }}
            value={inputValue}
            onChange={(e) => {
              setInputValue(e.target.value);
              // e.preventDefault();
              e.stopPropagation();
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                if (inputValue !== "" && props.groups === "") {
                  createNewValue(inputValue);
                }
              }
              // if (e.keyCode === 32) {
              //   e.target.click();
              // }
              // e.preventDefault();
              e.stopPropagation();
            }}
          />
        </div>
      </Dropdown.Toggle>
      <Dropdown.Menu
        className="hide-scrollbar"
        style={{ maxHeight: "300px", overflow: "auto" }}
      >
        <div className="px-2">
          {data &&
            Object.keys(data)?.map((key, keyIndex) => {
              return (
                <>
                  <div className="f-12 f-500 f-darkgrey">
                    {props.groups === ""
                      ? TrimString(
                          cleanText(
                            changeVaribleToName(key?.replaceAll(/\d+/g, ""))
                          ),
                          30
                        )
                      : // TrimString(
                        //     cleanText(
                        //       changeVaribleToName(key?.replaceAll(/\d+/g, ""))
                        //     ),
                        //     30
                        //   )

                        TrimString(
                          changeVaribleToName(
                            cleanText(
                              props.groups
                                ?.split(",")
                                [keyIndex].split("-")[0]
                                ?.replaceAll(" ", " > ")
                                ?.replaceAll("AS_Supplier", "Suppliers")
                                ?.replaceAll("AS_Assets", "Assets")
                                ?.replaceAll("AS_Corporate", "Corporate Social")
                                ?.replaceAll("AS_Web", "Web Services")
                                ?.replaceAll(
                                  "AS_Authentication",
                                  "Authentication"
                                )
                                ?.replaceAll("AS_Orgs", "Organisation")
                                ?.replaceAll(
                                  "AS_DataProtection",
                                  "Data Protection"
                                )
                                ?.replaceAll(
                                  "AS_Communications",
                                  "Communication"
                                )
                                ?.replaceAll("AS_Clouds", "Cloud Services")
                                ?.replaceAll("AS_FS", "File Sharing")
                                ?.replaceAll("AS_Emails", "Emails")
                                ?.replaceAll("AS_Networks", "Networks")
                                ?.replaceAll(
                                  "AS_Directory",
                                  "Directory Services"
                                )
                            )
                          ),
                          35
                        )}
                  </div>

                  {data[key]
                    .filter((filterItem) =>
                      filterItem?.AttributeText?.toLowerCase().includes(
                        inputValue?.toLowerCase()
                      )
                    )
                    ?.map((item, itemIndex) => {
                      return (
                        <div
                          className={`${
                            selectedOptions?.filter(
                              (Item) => Item?.AttributeId === item.AttributeId
                            )?.length > 0
                              ? "f-black"
                              : "f-grey"
                          } 
                        
                        ${item.AttributeText === "" ? "d-none" : "d-flex"}
                        
                        f-500 f-15 pointer py-1 ml-2  align-items-center justify-content-between`}
                          onClick={(e) => {
                            if (
                              selectedOptions?.filter(
                                (Item) => Item?.AttributeId === item.AttributeId
                              )?.length === 0
                            ) {
                              let selected_list = [...selectedOptions];
                              selected_list.push(item);
                              setSelectedOptions(selected_list);
                              props.onChange(selected_list);
                            } else {
                              let selected_list = [...selectedOptions];
                              let Index = selected_list.findIndex(
                                (Item) =>
                                  Item?.AttributeId === item?.AttributeId
                              );
                              if (Index === -1) {
                                selected_list.push(item);
                                setSelectedOptions(selected_list);
                                props.onChange(selected_list);
                              }
                            }
                          }}
                        >
                          <span className="d-inline-flex align-items-center">
                            {item?.AttributeText}
                          </span>
                          {selectedOptions?.filter(
                            (Item) => Item?.AttributeId === item.AttributeId
                          )?.length > 0 && (
                            <img
                              alt=""
                              width={11}
                              className="mb-1 mr-2"
                              src="/images/settings-v2/black-tick.svg"
                            />
                          )}
                        </div>
                      );
                    })}
                  {/* Create option with user type anything */}
                  {inputValue && props.groups === "" && (
                    <div
                      className={`dropdown_item f-blue f-500 f-15 pointer py-1 ml-2`}
                      onClick={(e) => {
                        createNewValue(inputValue);
                      }}
                    >
                      Create "
                      {TrimString(
                        cleanText(changeVaribleToName(inputValue)),
                        10
                      )}
                      "
                    </div>
                  )}

                  {props.groups !== "" &&
                    props.refId &&
                    props.refId?.length > 0 &&
                    props.refId[keyIndex]?.split("-")[0] > 0 && (
                      <div
                        className={`dropdown_item f-blue f-500 f-15 pointer py-1 ml-2`}
                        onClick={(e) => {
                          if (inputValue !== "" || true) {
                            // setData([...data, inputValue]);
                            // setInputValue(inputValue);
                            setItemKey(key);
                            if (props.nestedModal) {
                              if (props.retainPreviousStateFunc) {
                                props.retainPreviousStateFunc();
                              }
                              if (props.onAddClick) {
                                if (
                                  props.refId &&
                                  props.refId?.length > 0 &&
                                  props.refId[keyIndex]?.split("-")[0] > 0
                                ) {
                                  props.onAddClick(
                                    props.refId[keyIndex]?.split("-")[0],
                                    props.refId[keyIndex]?.split("-")?.[2],
                                    inputValue
                                  );
                                }
                              }
                            } else {
                              if (
                                props.refId &&
                                props.refId?.length > 0 &&
                                props.refId[keyIndex]?.split("-")[0] > 0
                              ) {
                                setNewAssetId(
                                  props.refId[keyIndex]?.split("-")[0]
                                );
                                setAssetsKey(
                                  props.refId[keyIndex]?.split("-")?.[2]
                                );
                                setIntialInputValuetoNestedModal(inputValue);
                                setShowSmallModal(true);
                              }
                            }
                          }
                        }}
                      >
                        Add{" "}
                        {inputValue && ` "${TrimString(inputValue, 8)}" to `}
                        {TrimString(
                          cleanText(
                            changeVaribleToName(
                              props.refId[keyIndex]?.split("-")[1]
                            )
                          ),
                          100
                        )}
                      </div>
                    )}
                </>
              );
            })}
        </div>
      </Dropdown.Menu>
      <SmallWorkstationModal
        firstAssetId={newAssetId}
        hideModal={() => setShowSmallModal(false)}
        show={showSmallModal}
        onFinish={addValueToDropdownList}
        refreshMenuItems={props.refreshMenuItems}
        assetkey={assetsKey}
        intialValue={intialInputValuetoNestedModal}
      />
    </Dropdown>
  );
};

export default MultiSelectDropdown;

export const AttackSupplierServiceDropdown = (props) => {
  const [data, setData] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [selectedOptions, setSelectedOptions] = useState([]);
  let alreadyExist =
    data.filter((item) => item?.ServiceName === inputValue)?.length > 0;
  useEffect(() => {
    if (props.data) {
      setData(props.data);
    }
  }, []);
  useEffect(() => {
    if (props.selected) {
      setSelectedOptions(props.selected ?? []);
    }
  }, [props.selected]);

  return (
    <Dropdown
      className="attack-multi-select-dropdown"
      style={
        {
          // pointerEvents: `${locked ? "none" : "all"}`,
        }
      }
    >
      <Dropdown.Toggle className={`${props.isError ? "input-error" : ""}`}>
        <div className="d-flex flex-wrap">
          {selectedOptions &&
            selectedOptions?.map((selected, selectedIndex) => {
              return (
                <div
                  className="f-12 f-500 f-grey d-flex align-items-center p-2 mr-1 mb-1  "
                  title={selected?.ServiceName}
                  style={{
                    background: "#ebecef",
                  }}
                >
                  <span className="d-inline-flex">
                    {TrimString(selected?.ServiceName, 20)}
                  </span>

                  <img
                    alt=""
                    src="/images/attack-surface/grey-cross-icon.svg"
                    className={`ml-1 pointer `}
                    onClick={(e) => {
                      e.stopPropagation();
                      let updated_data = [...selectedOptions];
                      let Index = updated_data.findIndex(
                        (item) => item?.ServiceName === selected?.ServiceName
                      );
                      updated_data.splice(Index, 1);
                      setSelectedOptions(updated_data);
                      props.onChange(updated_data);
                    }}
                  />
                </div>
              );
            })}
          <input
            tabIndex={-1}
            placeholder={`${props.placeholder ?? "Add"}`}
            type="text"
            className={`border-none w-100 h-100  `}
            style={
              {
                // pointerEvents: `${locked ? "none" : "all"}`,
              }
            }
            value={inputValue}
            onChange={(e) => {
              setInputValue(e.target.value);
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                if (inputValue !== "") {
                  let selected_list = [...selectedOptions];
                  selected_list.push({ ServiceId: 0, ServiceName: inputValue });
                  setSelectedOptions(selected_list);
                  props.onChange(selected_list);
                  setInputValue("");
                }
              }

              e.stopPropagation();
            }}

            onBlur={()=>{
              if (inputValue !== "") {
                let selected_list = [...selectedOptions];
                selected_list.push({ ServiceId: 0, ServiceName: inputValue });
                setSelectedOptions(selected_list);
                props.onChange(selected_list);
                setInputValue("");
              }
            }}
          />
        </div>
      </Dropdown.Toggle>
      <Dropdown.Menu
        className="hide-scrollbar d-none"
        style={{ maxHeight: "300px", overflow: "auto" }}
      >
        <div className="px-2">
          <div className="f-12 f-500 f-darkgrey">Services</div>
          {data &&
            data?.map((key, keyIndex) => {
              return (
                <>
                  {data
                    .filter((filterItem) =>
                      filterItem?.ServiceName?.toLowerCase().includes(
                        inputValue?.toLowerCase()
                      )
                    )
                    ?.map((item, itemIndex) => {
                      return (
                        <div
                          className={`${
                            selectedOptions?.filter(
                              (Item) => Item?.ServiceName === item.ServiceName
                            )?.length > 0
                              ? "f-black"
                              : "f-grey"
                          } 
                        
                        ${item.ServiceName === "" ? "d-none" : "d-flex"}
                        
                        f-500 f-15 pointer py-1 ml-2  align-items-center justify-content-between`}
                          onClick={(e) => {
                            if (
                              selectedOptions?.filter(
                                (Item) => Item?.ServiceName === item.ServiceName
                              )?.length === 0
                            ) {
                              let selected_list = [...selectedOptions];
                              selected_list.push(item);
                              setSelectedOptions(selected_list);
                              props.onChange(selected_list);
                            } else {
                              let selected_list = [...selectedOptions];
                              let Index = selected_list.findIndex(
                                (Item) =>
                                  Item?.ServiceName === item?.ServiceName
                              );
                              if (Index === -1) {
                                selected_list.push(item);
                                setSelectedOptions(selected_list);
                                props.onChange(selected_list);
                              }
                            }
                          }}
                        >
                          <span className="d-inline-flex align-items-center">
                            {item?.ServiceName}
                          </span>
                          {selectedOptions?.filter(
                            (Item) => Item?.ServiceName === item.ServiceName
                          )?.length > 0 && (
                            <img
                              alt=""
                              width={11}
                              className="mb-1 mr-2"
                              src="/images/settings-v2/black-tick.svg"
                            />
                          )}
                        </div>
                      );
                    })}
                  {/* Create option with user type anything */}
                </>
              );
            })}
          {inputValue && !alreadyExist && (
            <div
              className={`dropdown_item f-blue f-500 f-15 pointer py-1 ml-2`}
              onClick={(e) => {
                let selected_list = [...selectedOptions];
                selected_list.push({ ServiceId: 0, ServiceName: inputValue });
                setSelectedOptions(selected_list);
                props.onChange(selected_list);
                setInputValue("");
              }}
            >
              Create "{TrimString(cleanText(inputValue), 10)}"
            </div>
          )}
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};
