import React, { useState, useRef, useEffect } from "react";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import "./datepicker.scss";

const DatePicker = (props) => {
  const [singleDate, setSingleDate] = useState(props.startDate || "");
  const [multiDate, setMultiDate] = useState({
    startDate: props.startDate || "",
    endDate: props.endDate || "",
  });
  const [placeholder, setPlaceholder] = useState(props?.placeholder);
  // const handleEvent = (event, picker) => {
  //   console.log(picker);
  // };
  function ChangeDateFormat(date) {
    let new_date = date;
    console.log(
      `${props.startDate.split("/")[1]}/${props.startDate.split("/")[0]}/${
        props.startDate.split("/")[2]
      }`
    );
  }
  // ChangeDateFormat("24/07/1958");

  const handleCallback = (start, end, label) => {
    setSingleDate(start.format("DD/MM/YYYY"));
    setMultiDate({
      startDate: start.format("DD/MM/YYYY"),
      endDate: end.format("DD/MM/YYYY"),
    });
    setPlaceholder("");
    props.onChange(start.format("DD/MM/YYYY"));
  };

  return (
    <DateRangePicker
      locale={{ format: "DD/MM/YYYY" }}
      initialSettings={{
        singleDatePicker: props.singleDatePicker ? true : false,
        showDropdowns: true,
        autoApply: true,
        startDate: props.startDate,
        endDate: props.endDate,
      }}
      onApply={(e) => {
        if (placeholder !== "") {
          props.onChange(
            `${props.startDate.split("/")[1]}/${
              props.startDate.split("/")[0]
            }/${props.startDate.split("/")[2]}`
          );
        }
        e.preventDefault();
        e.stopPropagation();
        setTimeout(() => setPlaceholder(""), 100);
      }}
      onEvent={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
      // onEvent={() => alert("hghcgc")}
      onCallback={handleCallback}
    >
      <div className="custom_date_picker">
        {props.label && <div className="input-label">{props.label}</div>}
        {placeholder ? (
          <input
            type={"text"}
            className={`custom_date_picker_input ${
              props.value ? "date_picker_input" : "date_picker_placeholder"
            }`}
            value={`${placeholder}`}
          />
        ) : (
          <input
            type={"text"}
            className={`custom_date_picker_input`}
            value={
              props.singleDatePicker
                ? singleDate
                : `${multiDate.startDate} - ${multiDate.endDate}`
            }
          />
        )}
      </div>
    </DateRangePicker>
  );
};

export default DatePicker;
