export function TrimString(_string, NoOfWords) {
  let string;
  if (typeof _string === "number") {
    string = _string.toString();
  } else {
    string = _string;
  }
  try {
    if (!string) return false;
    if(!NoOfWords) return _string
    let length_of_string = string?.length;
    if (length_of_string === 0) {
      return "";
    } else if (length_of_string <= NoOfWords) {
      return string;
    } else {
      return `${string?.substring(0, NoOfWords)}...`;
    }
  } catch (err) {
    console.log(string);
    return "";
  }
}
