import React, { useState } from "react";
import { Modal, Col, Row, Button } from "react-bootstrap";
import { getAPIData } from "../../../Utils/commonApi";
import { useAuth } from "../../context/Auth";
import { CallToast } from "../../PriceCalculator/modals/CustomToast";
import { WhiteLoader } from "../../Theme/APILoaders";

function ResetModal({ isOpen, setIsOpen }) {
  const [loading, setLoading] = useState(false);
  const { authTokens } = useAuth();

  async function ResetSettings() {
    setLoading(true);
    await getAPIData("242", authTokens).then((response) => {
      setLoading(false);
      if (response.Success) {
        setIsOpen();
        CallToast("Settings updated successfully", false, false, "v2style");
      } else {
        CallToast("Something went wrong", false, false, "v2style");
      }
    });
  }

  return (
    <Modal
      animation={false}
      show={isOpen}
      dialogClassName="modal-50w"
      className="qty-asset-modal integrationModal updatecompanymodal modalresetaction"
      aria-labelledby="asset-modal"
      centered
      scrollable
      onHide={() => {}}
    >
      <Modal.Body>
        <Row className="">
          <Col xs={12} className="font-bold-14">
            Reset Settings?
          </Col>

          <Col xs={12} className="clrgry mt-2">
            Your settings will be reset to their default configuration and
            cannot be undone. Are you sure you want to proceed?
          </Col>
        </Row>
      </Modal.Body>

      <Modal.Footer>
        <Row className="m-0 ">
          <Col xs={12} className="p-0 d-flex justify-content-end">
            <Button
              variant="primary"
              type="submit"
              className="float-right action_cancel_btn mr-2"
              onClick={setIsOpen}
            >
              Cancel
            </Button>
            <Button
              variant="primary"
              type="submit"
              className="float-right securityBtnmodal"
              onClick={ResetSettings}
              disabled={loading}
            >
              {loading ? (
                <span>
                  <WhiteLoader width={12} />
                  Resetting...
                </span>
              ) : (
                "Reset Settings"
              )}
            </Button>
          </Col>
        </Row>
      </Modal.Footer>
    </Modal>
  );
}

export default ResetModal;
