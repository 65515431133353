import { Modal, Button } from "react-bootstrap";
import React from "react";
import "./rewards.scss";
import { useAuth } from "../../context/Auth";
import { RightSideToast } from "../../PriceCalculator/modals/CustomToast";
import { updateAccessKeyInLocalStorage } from "../../../Utils/CommonFunctions";
const SharePostModal = ({
  show,
  hideModal,
  postData,
  rewardId,
  refreshData,
}) => {
  const { authTokens } = useAuth();
  async function ShareReport() {
    const accessKey = localStorage.getItem("user_MessageObj")
      ? JSON.parse(localStorage.getItem("user_MessageObj"))
      : "null";
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    myHeaders.append("Authorization", `Bearer ${authTokens}`);

    var inputs = new URLSearchParams();
    inputs.append("RewardId", rewardId);
    inputs.append("RewardType", "Share Post");

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: inputs,
      redirect: "follow",
    };

    await fetch(
      `${process.env.REACT_APP_API_BASE_URL}/CC_API/CompleteReward?accessKey=${accessKey}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        if (data?.mr?.Success) {
          updateAccessKeyInLocalStorage(data?.mr?.Message)
         
          hideModal();
          setTimeout(() => {
            RightSideToast(
              data.RewardAmount,
              `Thanks for sharing post, we have credited to your Pro Services account.`
            );
          }, 500);

          refreshData();
        }
      });
  }
  return (
    <Modal
      animation={false}
      // show={true}
      show={show}
      dialogClassName="share-post-modal"
      className=" "
      aria-labelledby="share-post-modal"
      centered
      onHide={hideModal}
      //   scrollable
    >
      <Modal.Header className="text-center">
        <div className="w-100 mb-0 font-bold-14 title">Share Post</div>

        <span className="cursor-pointer" onClick={hideModal}>
          <img src="/images/icons/close.svg" alt="" />
        </span>
      </Modal.Header>
      <Modal.Body className="d-flex flex-column align-content-center p-4">
        <div
          style={{
            width: "100%",
            background: "#f7f7f77a",
            borderRadius: "8px",
            height: "270px",
          }}
        >
          <img
            alt=""
            src="/images/settings-v2/sharepost-banner.svg"
            style={{
              width: "100%",
              objectFit: "contain",
            }}
          />
        </div>
        <div className="desc mt-3">
          I've just completed a Cyber Risk Assessment with HighGround
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="subtitle mb-3">Share on</div>
        <div className="d-flex justify-content-between align-items-center">
          <Button
            onClick={ShareReport}
            className="share-btn"
            target="_blank"
            href={
              "https://www.linkedin.com/shareArticle?mini=true&url=" +
              window.location.origin +
              "/signup" +
              "&title=" +
              postData.Name +
              "&summary=I've just completed a Cyber Risk Assessment with CyberCare Vision&source=LinkedIn"
            }
          >
            <img
              alt=""
              src="/images/settings-v2/linkedin.svg"
              className="mr-2"
            />
            <div>LinkedIn</div>
          </Button>
          <Button
            onClick={ShareReport}
            className="share-btn"
            target="_blank"
            href={
              "https://twitter.com/intent/tweet?url=" +
              window.location.origin +
              "/sign-up" +
              "&text=" +
              postData.Name
            }
          >
            <img
              alt=""
              src="/images/settings-v2/twitter.svg"
              className="mr-2"
            />
            <div>Twitter</div>
          </Button>
          <Button
            onClick={ShareReport}
            target="_blank"
            className="share-btn"
            href={
              "https://www.facebook.com/share.php?u=https://cybercare-ui-dev.azurewebsites.net/login" +
              window.location.origin +
              "/signup" +
              "&quote=" +
              postData.Name
            }
          >
            <img
              alt=""
              src="/images/settings-v2/facebook.svg"
              className="mr-2"
            />
            <div>Facebook</div>
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default SharePostModal;
