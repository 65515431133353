import React, { useState, useEffect } from "react";

import "./email.scss";
import "../common.scss";
import CompanySettingsDropdown from "../../settingsV2/companySettings/companysettingsdrodown/CompanySettingsDropdown";
import { useAuth } from "../../context/Auth";

import { AttackSurfaceGetApi, getAPIData } from "../../../Utils/commonApi";
import { CallToast } from "../../PriceCalculator/modals/CustomToast";
import AssetsListMainPageLoader from "../AttackSurfaceLoaders/AssetsListMainPageLoader";

import { cleanText } from "../Index";
import { AttackSurfaceAccessUI } from "../../supplyChainMonitoring/SupplyCompany/SupplyCompany";
const Email = (props) => {
  const { goTo, setAssetId } = props;

  const [questionList, setQuestionList] = useState([]);
  const [mainPageLoading, setMainPageLoading] = useState(false);

  const [data, setData] = useState([]);
  const { authTokens } = useAuth();
  const [questionsHeader, setQuestionsHeader] = useState("General");

  useEffect(() => {
    setMainPageLoading(true);
    AttackSurfaceGetApi(`AttackSurfaceAPI/LandingPage?`, authTokens, {
      start: props.pageParams.startRange,
      end: props.pageParams.endRange,
      loadOrgObjId: props.supplierId,
      accessLevel: props.accessLevel,
      ASEComponentSecEnum: props?.pageParams?.ASEComponentSecEnum,
    }).then((response) => {
      if (props?.pageParams?.questionEnum) {
        getPageData(props?.pageParams?.questionEnum);
      } else {
        setQuestionList([]);
        setMainPageLoading(false);
      }

      if (response?.Success) {
        setData(response?.landingPageResponses);
      } else {
        CallToast("Something went wrong", false, false, "v2style");
      }
    });
  }, [props.pageParams]);

  function getPageData(questionnaireEnum, success = false) {
    getAPIData(questionnaireEnum, authTokens, {
      optionEmun1: 0,
      optionEmun2: props.accessLevel,
      optionEmun3: props.supplierId,
    }).then((response) => {
      setMainPageLoading(false);
      if (response?.mr?.Success) {
        setQuestionsHeader(response?.QuestionnaireSection_List?.[0]?.["Title"]);

        if (success) {
          let updated_data = [...questionList].map((item, index) => {
            return {
              ...item,
              AnswerId:
                response?.QuestionnaireSection_List?.[0]?.Question_List[index]
                  .AnswerId,
            };
          });

          setQuestionList(updated_data);
        } else {
          if (!response?.QuestionnaireSection_List) return;
          let updated_data = [
            ...response?.QuestionnaireSection_List?.[0]?.Question_List,
          ].map((item) => {
            return {
              ...item,
              selectedDropdownValue: item?.AnswerText
                ? item?.QuestionDropdownListMembers.map((listItem, index) => {
                    return {
                      id: index + 1,
                      category: listItem,
                    };
                  }).filter((filteritem) => {
                    return filteritem.category == item?.AnswerText;
                  })[0]
                : null,
              dropDowndata: item?.QuestionDropdownListMembers
                ? item?.QuestionDropdownListMembers.map((listItem, index) => {
                    return {
                      id: index + 1,
                      category: listItem,
                    };
                  })
                : [],
              loading: false,
            };
          });

          setQuestionList(updated_data);
        }
      }
    });
  }

  return (
    <div className="">
      {mainPageLoading ? (
        <AssetsListMainPageLoader />
      ) : (
        <>
          {/* Main Components */}

          <div className="attack-networks-wrapper">
            <AttackSurfaceAccessUI />
            {/* General */}
            {questionList?.length > 0 && (
              <div className="font-weight-bold mt-3 mb-2">
                {/* {questionsHeader} */}
                General
              </div>
            )}
            <div className="bg-grey radius-8 border-parent">
              {questionList?.map((question, questionIndex) => {
                return (
                  <div
                    className="d-flex flex-column border-item"
                    onClick={() => {
                      // goTo(vulnerbility.name);
                    }}
                  >
                    <div className="d-flex align-items-center justify-content-between w-100 pl-3 pr-4  py-3">
                      <div
                        className="d-flex align-align-items-baseline "
                        style={{ width: "75%" }}
                      >
                        <div>
                          <img
                            alt=""
                            src="/images/attack-surface/yellow-dot.svg"
                            className="mr-2"
                            style={{
                              visibility: `${
                                "hidden"
                                // question?.AnswerId === 0 ? "visible" : "hidden"
                              }`,
                            }}
                          />
                        </div>
                        <p className="f-500 m-0">{question?.QuestionText}</p>
                        {/* <p className="f-12 f-darkgrey"> {service.noemptyStatus} </p> */}
                      </div>
                      <div
                        className="disabled-question-dropdown"
                        style={{
                          pointerEvents: "none",
                        }}
                      >
                        <CompanySettingsDropdown
                          fieldId={"id"}
                          fieldValue={"category"}
                          selected={question.selectedDropdownValue}
                          placeholder="Not answered"
                          onClick={(id, val) => {}}
                          data={question?.dropDowndata}
                        />
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>

            <div className="font-weight-bold mt-4 mb-2">
              {props?.activePage}
              {/* Assets */}
            </div>
            <div className="bg-grey radius-8 border-parent">
              {data.map((asset, assetIndex) => {
                return (
                  <div
                    className="d-flex flex-column border-item"
                    onClick={() => {
                      setAssetId(asset?.ObjectDefID);
                      goTo(cleanText(asset?.ObjectName));
                    }}
                    style={{
                      pointerEvents: `${
                        props.accessLevel === 1 ? "none" : "all"
                      }`,
                    }}
                  >
                    <div className="d-flex align-items-center justify-content-between w-100 pointer pl-3 pr-4  py-3 bg-hover-row">
                      <div
                        className="d-flex flex-column "
                        style={{ width: "75%" }}
                      >
                        <div className="d-flex align-items-baseline ">
                          <img
                            alt=""
                            src="/images/attack-surface/yellow-dot.svg"
                            className="mr-1"
                            style={{
                              visibility: `${
                                "hidden"
                                // asset?.ObjectCount > 0 || asset?.Disabled
                                //   ? "hidden"
                                //   : "visible"
                              }`,
                            }}
                          />

                          <p className="f-500 m-0 f-black">
                            {cleanText(asset?.ObjectName)}
                          </p>
                        </div>
                        <p
                          className="f-12 f-darkgrey "
                          style={{ marginLeft: "0.83rem" }}
                        >
                          {asset?.Disabled ? (
                            <>
                              You have attested that you don't have any{" "}
                              {cleanText(asset?.ObjectName)}
                            </>
                          ) : (
                            <>
                              {asset?.ObjectCount === 0 ? (
                                <>
                                  {" "}
                                  You don't have any{" "}
                                  {cleanText(asset?.ObjectName)} yet{" "}
                                </>
                              ) : (
                                <>
                                  {asset?.ObjectCount}&nbsp;
                                  {cleanText(asset?.ObjectName)}
                                </>
                              )}
                            </>
                          )}
                        </p>
                      </div>
                      <div>
                        {props.accessLevel === 1 ? (
                          <img
                            alt=""
                            src="/images/attack-surface/small-lock.svg"
                          />
                        ) : (
                          <img
                            alt=""
                            src="/images/attack-surface/chevron-right.svg"
                          />
                        )}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Email;
