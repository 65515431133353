import {
  MSP_SPEND_MODAL_DATE_PICKER_STATE,
  MSP_DASHBOARD_DATE_PICKER_STATE,
} from "./constants";
import { endOfDay, startOfMonth, subMonths } from "date-fns";
const defineds = {
  endOfToday: endOfDay(new Date()),

  startOfLastTwelveMonth: startOfMonth(subMonths(new Date(), 12)),
};

export const initState = {
  mspClientDashboardDatePickerState: {
    startDate: defineds.startOfLastTwelveMonth,
    endDate: defineds.endOfToday,
    key: "selection",
  },
  mspClientSpendModalDatePickerState: {
    startDate: null, // new Date(),
    endDate: null, //new Date(),
    key: "selection",
  },
};

const MspClientReducer = (state = initState, action) => {
  switch (action.type) {
    case MSP_SPEND_MODAL_DATE_PICKER_STATE:
      return { ...state, mspClientSpendModalDatePickerState: action.data };
    case MSP_DASHBOARD_DATE_PICKER_STATE:
      return { ...state, mspClientDashboardDatePickerState: action.data };

    default:
      return state;
  }
};

export default MspClientReducer;
