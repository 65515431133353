import React from "react";
import ContentLoader from "react-content-loader";

const AttackSurfaceMainPageLoader = () => {
  return (
    <ContentLoader
      speed={2}
      width={"100%"}
      height={"100%"}
      viewBox="0 0 1500 900"
      backgroundColor="#F6F8FB"
      foregroundColor="#ecebeb"
    >
      <rect x="0" y="30" rx="3" ry="3" width="9%" height="20" />
      <rect x="0" y="95" rx="3" ry="3" width="14%" height="33" />
      <rect x="0" y="135" rx="3" ry="3" width="14%" height="33" />
      <rect x="0" y="175" rx="3" ry="3" width="14%" height="33" />
      <rect x="0" y="215" rx="3" ry="3" width="14%" height="33" />
      <rect x="0" y="255" rx="3" ry="3" width="14%" height="33" />
      <rect x="0" y="295" rx="3" ry="3" width="14%" height="33" />
      <rect x="0" y="335" rx="3" ry="3" width="14%" height="33" />
      <rect x="0" y="375" rx="3" ry="3" width="14%" height="33" />
      <rect x="0" y="415" rx="3" ry="3" width="14%" height="33" />
      <rect x="0" y="455" rx="3" ry="3" width="14%" height="33" />
      <rect x="0" y="495" rx="3" ry="3" width="14%" height="33" />
      <rect x="0" y="535" rx="3" ry="3" width="14%" height="33" />
      {/* side bar line */}
      <rect x="15%" y="0" rx="3" ry="3" width="2" height="100%" />

      <rect x="17%" y="29" rx="3" ry="3" width="10%" height="22" />
      <rect x="80%" y="3%" rx="3" ry="3" width="18%" height="40" />
      {/* Horizontal Line */}
      <rect x="15%" y="123" rx="3" ry="3" width="100%" height="2" />
      {/* Homepage */}
      <rect x="16%" y="145" rx="5" ry="5" width="41%" height="57%" />
      <rect x="59%" y="145" rx="5" ry="5" width="41%" height="57%" />
      {/* Insights */}

      <rect x="16%" y="78%" rx="5" ry="5" width="84%" height="35" />

      <rect x="16%" y="740" rx="5" ry="5" width="84%" height="55" />

      <rect x="16%" y="797" rx="5" ry="5" width="84%" height="55" />
      <rect x="16%" y="853" rx="5" ry="5" width="84%" height="55" />
    </ContentLoader>
  );
};

export default AttackSurfaceMainPageLoader;
