import React from "react";
import { Button } from "react-bootstrap";
import AttackSurfaceTooltip from "../AttackSurface/AssetsList/AttackSurfaceTooltip";
import { NO_ACCESS_TEXT } from "../App/App";

const DisabledButtonWithToolTip = ({ noAccessText, buttonText }) => {
  return (
    <div>
    <AttackSurfaceTooltip
      bottomLeft={true}
      topLeft={false}
      content={<span>{noAccessText}</span>}
    >
        <div>

      <Button className="disabled-button d-flex align-items-center">
        <img
          width={14}
          height={14}
          alt=""
          src="/images/attack-surface/small-lock.svg"
          className="mr-2"
          />

        {buttonText}
      </Button>
          </div>
    </AttackSurfaceTooltip>
    </div>
  );
};

export default DisabledButtonWithToolTip;


export const DisabledLockWithToolTip = ({ noAccessText, buttonText }) => {
    return (
      <div>
      <AttackSurfaceTooltip
        bottomLeft={true}
        topLeft={false}
        content={<span>{NO_ACCESS_TEXT ??noAccessText   }</span>}
      >
          <div>
  
       
          <img
            width={14}
            height={14}
            alt=""
            src="/images/attack-surface/small-lock.svg"
            className="mr-2"
            />
  
           
            </div>
      </AttackSurfaceTooltip>
      </div>
    );
  };
