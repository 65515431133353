import React, { useState } from "react";
import ImageWithInitials from "../../../Utils/ImageWithInitials";
import "../supplier-profiler.scss";

const ProviderBlock = ({ title, setShowProviderModal }) => {
  // const [updateState, setUpdateState] =useState(false);

  return (
    <div
      className="d-flex justify-content-center"
      style={{ width: "1024px", padding: "24px" }}
    >
      <div className="d-flex flex-column justify-content-center align-items-center w-100">
        <div
          className="d-flex flex-row w-100 justify-space-between header mb-2"
          style={{ justifyContent: "space-between" }}
        >
         <div className="title">
            <div className="f-16">{title.Title}</div>
            <div className="f-darkgrey">{title.Description}</div>
          </div>
        </div>

        <div className=" w-100 grid-container">
          {/* <div className="flex-row file-blocks f-darkgrey" onClick={()=>{setShowProviderModal(true); setUpdateState(!updateState)}}>
                    <img
                          src={"/images/supply-chain/darkgrey-add-base-icon.svg"}
                          className="mr-2 text-icon "
                          alt=""
                        />
                        <div className="">Add Provider</div>
                      </div>  */}
          {title.ManagedServiceProvider_List?.map((f, ind) => {
            return (
              <div className="flex-row  provider-block">
                <div
                  className="d-flex flex-row"
                  style={{ gap: " 8px", flex: " 1 0 0" }}
                >
                  <ImageWithInitials
                    initials={f.ManagedProviderName[0]}
                    width={40}
                    background={"purple"}
                  />

                  <div className="d-flex flex-column">
                    <div className="">{f.ManagedProviderName}</div>
                    <div className="d-flex flex-row file-block-info f-darkgrey">
                      <div className="">{f.RegisteredAddress}</div>
                      {f.CompanyName && <span className="grey-dot" />}
                      <div className="">{f.CompanyName}</div>
                      {f.Website && <span className="grey-dot" />}
                      <div className="">{f.Website}</div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default ProviderBlock;
