import { useRef } from 'react';
import './side-bar.styles.scss';
import useToggleRow from '../../../../hooks/toggle-row.hook';
import SelectArrowIcon from '../../../icons/select-arrow.icon';

const Item = ({ item }) => {
  const onClick = () => {
    if (!item.url) return;
    window.open(item.url, '_blank');
  }

  return (
    <div className='item' onClick={onClick}>
      <div className='icon-container'>
        <img 
          src={item.Icon}
          className='mr-2'
        />
      </div>
      <span className={`title f-grey ${item.isStrikethrough ? 'name-strikethrough ' : ''}`}>
        {item.Name}
      </span>
    </div>
  )
}

const Section = ({ section, iconWidth }) => {
  const pointRef = useRef(null);
  const panelRef = useRef(null);

  const { toggleRow, isOpen } = useToggleRow({ multiple: true, defaultOpen: true, rowRef: pointRef });

  return (
    <div ref={pointRef} className="point section" style={{ overflow: 'hidden', maxWidth: '100%' }}>
      <div onClick={toggleRow} className='cursor-pointer row'>
        <div className='header'>
          <span className='title f-grey mr-2'>{section.Title}</span>
          {section?.Percentage && (
            <span className='percentage'>
              <span>{section.Percentage}</span>
            </span>
          )}
        </div>
        <div className={`select-arrow ${isOpen ? 'open' : ''}`}>
          <SelectArrowIcon width="9px" height="6px" />
        </div>
      </div>
      <div ref={panelRef} className="tool-info panel">
        <div className='list'>
          {section?.SectionItems?.map((item, index) => (
            <Item key={index} item={item} />
          ))}
        </div>
      </div>
    </div>
  )
}

const DashboardSideBar = ({ sidebarData }) => {
  return (
    <div className='dashboard-right-side-bar'>
      {sidebarData?.map((section, index) => (
        <Section 
          key={index} 
          section={section}
          iconWidth={index === 0 ? "24px" : "13px"}
        />
      ))}
    </div>
  );
}

export default DashboardSideBar;
