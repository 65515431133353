import React, { useMemo, useState, useEffect, useRef } from "react";
import { Button, Dropdown, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useHistory, useParams } from "react-router-dom";
import "../../integrations/mspIntegrations.scss";
import "./mspPriceCalculator.scss";
import moment from "moment";
import MspRadar from "./MspRadar";
import CyberComplianceGauge from "./mspPriceCalculatorComponents/CyberComplianceGauge";
import CyberScoreGauge from "./mspPriceCalculatorComponents/CyberScoreGauge";
import MspAddEditServiceModal from "./MspAddEditServiceModal";
import { getAPIData, postData } from "../../../../Utils/commonApi";
import { useAuth } from "../../../context/Auth";
import { CallToast } from "../../../PriceCalculator/modals/CustomToast";
import ContentLoader from "react-content-loader";
import { TrimString } from "../../../../Utils/StringTrimmer";
import CompanySettingsDropdown from "../../../settingsV2/companySettings/companysettingsdrodown/CompanySettingsDropdown";
import MspServiceModalToolTip from "./mspCalculatorDropdownAndModals/MspServiceModalToolTip";
import AttackSurfaceTooltip from "../../../AttackSurface/AssetsList/AttackSurfaceTooltip";
import {
  numberWithCommas,
  renderPriceText,
} from "../../mspClientPriceCalculator/MspClientPriceCalculator";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import { removeWhiteSpace } from "../../../../Utils/CommonFunctions";
import {
  AddEditTaxRateModal,
  CurrencyDropdown,
  TaxListDropdown,
  TaxRateDropdown,
  deleteTaxRate,
} from "../../MspAttackSurface.js/addItemInSpendModal/AddItemInSpendModal";
import MspAddEditProductModal from "./MspAddEditProductModal";
import MspAddEditSeriesModal from "./MspAddEditSeriesModal";
import { CustomDeleteModal } from "../../../DeleteModal/DeleteModal";
import MspScoreModal from "./mspCalculatorDropdownAndModals/MspScoreModal";
import { isEqual } from "lodash";
import ExploreMappingModal from "../../../SecurityAssessments/ExploreMappingModal";
import ImageWithInitials from "../../../../Utils/ImageWithInitials";

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import MspFeaturesModal from "./mspCalculatorDropdownAndModals/MspFeaturesModal";
import MspFeatures from "./mspCalculatorDropdownAndModals/MspFeatures";
import MspComponents from "./mspCalculatorDropdownAndModals/MspComponents";

const MspPriceCalcultor = ({ CustomRadar }) => {
  const location = useLocation();
  const state = location?.state;
  const allPackagesList = state?.allPackagesList;
  const isFirst = state?.isFirst;
  const { authTokens } = useAuth();
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const [packageName, setPackageName] = useState("");
  const [showServiceModal, setShowServiceModal] = useState(false);
  const [dataset, setDataset] = useState([0, 0, 0, 0, 0]); //[12, 24, 26, 14, 6]
  const [changed, setChanged] = useState(false);
  const [pageData, setPageData] = useState({
    response: {},
    service_list_data: [],
    service_dropdown_list: [],
  });
  const [serviceList, setServiceList] = useState([]);
  const [packagesList, setPackagesList] = useState([]);
  const [saving, setSaving] = useState(false);
  const [showDropdownVisibilityArray, setShowDropdownVisibilityArray] = useState(false);
  //3938209 for services
  //3540633 for services
  //3955192 for table
  const { packageId } = useParams();
  const [scenarioId, setScenarioId] = useState(packageId);
  const [selectedServiceId, setSelectedServiceId] = useState(0);
  const [nistPillarType, setnistPillarType] = useState();

  const [billedByStatus, setBilledByStatus] = useState();
  const [packageBillingPeriod, setPackageBillingPeriod] = useState();
  const [errors, setErrors] = useState({
    packageNameError: false,
    packageNameExistError: false,
  });
  const [seriesModalData, setSeriesModalData] = useState({
    show: false,
  });
  const [userSelectedCurrency, setUserSelectedCurrency] = useState({});
  const [defaultCurrency, setDefaultCurrency] = useState({});
  const [currencyList, setCurrencyList] = useState([]);
  const [taxList, setTaxList] = useState([]);
  const [selectedTax, setSelectedTax] = useState();
  const [exchangeRateData, setExchangeRateData] = useState({});
  const [updatedExchangeRateData, setUpdateExchangeRateData] = useState({});
  const [packageData, setPackageData] = useState({});
  const [clientId, setClientId] = useState(0);
  const [editState, setEditState] = useState(false);
  const [nestedProductModalState, setNestedProductModalState] = useState({
    show: false,
  });
  const [warningModalConfig, setWarningModalConfig] = useState({
    showWarningModal: false,
  });
  const [isSaveClicked, setIsSaveClicked] = useState(false);

  const [newPackageName, setNewPackageName] = useState("");
  const [isNameUnique, setIsNameUnique] = useState(true);
  const [isAddonService, setIsAddOnService] = useState(false);

  const [selectedServices, setSelectedServices] = useState([]);
  const [servicesLoader, setServicesLoader] = useState(false);
  const [oldServices, setOldServices] = useState([]);
  const [nistIndex, setNistIndex] = useState(0);
  const [isCustom, setIsCustom] = useState(null);
  const [tableLoader, setTableLoader] = useState(false);
  const [isHovered, setIsHovered] = useState(0);
  const [serviceSearchString, setServiceSearchString] = useState('');
  const [componentsData, setComponentsData] = useState([]);

  function addEditServiceByModal(_serviceId) {
    setSelectedServiceId(_serviceId);
    setShowServiceModal(true);
    if (_serviceId === 0) {
      setOldServices(pageData?.service_dropdown_list);
    }
  }

  useEffect(() => {
    setOldServices(pageData?.service_dropdown_list[nistIndex]);
  }, [nistIndex]);

  useEffect(() => {
    setClientId(state?.clientId);
    setIsCustom(state?.isCustom);
  }, [state]);

  useEffect(() => {
    setPackageData(pageData?.response);
  }, [pageData]);

  useEffect(() => {
    if (oldServices?.length) {
      const addedService = findAddedObject(
        oldServices,
        pageData?.service_dropdown_list
      );
      updateSelectedServices(addedService, nistIndex);
      setTableLoader(true);
      if (addedService) {
        setChanged(true);
        add_or_delete_service_addOn(addedService?.objId_SecurityPack, 0, true)
          .then(() => {
            getPackageDetails(
              Number(scenarioId),
              clientId,
              userAndDeviceCountChanged
            );
            // getDropdownDataForService(nistIndex);
          })
          .catch(() => {
            // getDropdownDataForService(nistIndex);
          });
        setOldServices([]);
        setNistIndex(0);
      }
    }
    // setChanged(false);
  }, [pageData]);

  function findAddedObject(arr1, arr2) {
    // Filter out objects from arr2 that are not present in arr1
    let addedObjects = arr2.filter(
      (obj2) => !arr1.find((obj1) => isEqual(obj1, obj2))
    );

    // If there is at least one added object, return the first one
    return addedObjects.length > 0 ? addedObjects[0] : null;
  }

  useEffect(() => {
    const deletedId = window.localStorage.getItem("packageId");
    if (deletedId) {
      deleteData(deletedId);
      window.localStorage.removeItem("packageId");
    }
    if (Number(packageId) === 0 && packagesList.length) {
      const defaultServiceName = "Security Package 1";
      let incrementedName = defaultServiceName;
      let counter = 1;

      while (packagesList?.some((service) => service.Name == incrementedName)) {
        counter++;
        incrementedName = `Security Package ${counter}`;
      }

      setNewPackageName(incrementedName);
      createPackage(state?.clientId ?? 0, incrementedName);
    }
    if (Number(packageId) > 0) {
      setScenarioId(packageId);
      // getPackageDetails(Number(packageId));
      // fillDataInDropdown();
    }
  }, [packagesList]);

  useEffect(() => {
    if (Number(packageId) === 0 && isFirst) {
      const defaultServiceName = "Security Package 1";
      let incrementedName = defaultServiceName;
      let counter = 1;

      while (packagesList?.some((service) => service.Name == incrementedName)) {
        counter++;
        incrementedName = `Security Package ${counter}`;
      }

      setNewPackageName(incrementedName);
      createPackage(state?.clientId ?? 0, incrementedName);
    }
    if (Number(packageId) > 0) {
      setScenarioId(packageId);
      // getPackageDetails(Number(packageId));
      // fillDataInDropdown();
    }
  }, [isFirst]);

  useEffect(() => {
    //   // setChanged(false);
    setLoading(true);
    // getServiceList(0);
    // setIsCustom(false);
    // setClientId(0);
  }, [packageId]);

  console.log('packageId', packageId)

  const [isChangeUserAbdDeviceCount, setIsChangeUserAbdDeviceCount] =
    useState(true);
  const [defaultUserAndDeviceCount, setDefaultUserAndDeviceCount] = useState();
  useEffect(() => {
    if (Number(packageId) === 0 && isChangeUserAbdDeviceCount) {
      let updated_data = { ...pageData };
      if (
        pageData.response?.ScenarioHeader?.UserAndDeviceCount &&
        Object.keys(pageData.response.ScenarioHeader).length !== 0
      ) {
        updated_data.response.ScenarioHeader.UserAndDeviceCount.NoOfUsers = "0";
        updated_data.response.ScenarioHeader.UserAndDeviceCount.NoOfWorkstation =
          "0";
        updated_data.response.ScenarioHeader.UserAndDeviceCount.NoOfMobileDevices =
          "0";
        updated_data.response.ScenarioHeader.UserAndDeviceCount.NoOfServices =
          "0";
        updated_data.response.ScenarioHeader.UserAndDeviceCount.NoOfRouters =
          "0";
        updated_data.response.ScenarioHeader.UserAndDeviceCount.NoOfNetworkDevices =
          "0";
        updated_data.response.ScenarioHeader.UserAndDeviceCount.NoOfDomains =
          "0";
        setPageData(updated_data);
        setIsChangeUserAbdDeviceCount(false);
      }
    }
  }, [pageData?.response?.ScenarioHeader]);
  const [isFirstOpen, setIsFirstOpen] = useState(true); // Set initial state to true to open accordion by default
  const [isPreDataState, setIsPreDataState] = useState(true);
  const [userAndDeviceCountChanged, setUserAndDeviceCountChanged] =
    useState(false);

  function ToggleAccordion(e, work = true) {
    if (!work) return;
    var parent = e.target.closest(".point-row");
    var panel = parent.querySelector(".panel-opened");
    if (panel?.style?.maxHeight) {
      panel.style.maxHeight = null;
    } else {
      panel.style.maxHeight = panel.scrollHeight + "px";
    }
    setIsFirstOpen(false);
  }



  const [isFirstOpenBlocks, setIsFirstOpenBlocks] = useState([
    true,
    true,
    true,
    true,
    true,
    true,
    true,
  ]);
  function ToggleAccordionBlocks(e, blockId, work = true) {
    if (!work) return;
    var parent = e.target.closest(`.point-row-${blockId}`);
    console.log("e.targeta", e.targeta);
    var panel = parent.querySelector(`.panel-opened`);
    var chevron = parent.querySelector("#chevron");
    if (panel?.style?.maxHeight) {
      chevron.classList.toggle("rotate180");
      panel.style.maxHeight = null;
    } else {
      chevron.classList.toggle("rotate180");
      panel.style.maxHeight = panel.scrollHeight + "px";
    }
    let updData = isFirstOpenBlocks;
    updData[blockId] = !updData[blockId];
    setIsFirstOpenBlocks(updData);
  }
  // Function to Create Package
  async function createPackage(objId_Customer, packageName) {
    await postData(authTokens, "CreatePackage", {
      ObjectId: objId_Customer ?? 0,
      PackageName: packageName,
    })
      .then((response) => {
        if (response?.Success) {
          setScenarioId(response?.EntityId);
          setClientId(objId_Customer);
          getPackageDetails(
            Number(response?.EntityId),
            clientId,
            userAndDeviceCountChanged
          );
          getCustomerDevicesCount();
          // fillDataInDropdown();
          setIsCustom(objId_Customer !== 0 ? true : false);
        } else {
          CallToast("Something went wrong", false, false, "v2style");
          if (state?.backUrl) {
            history.push(state.backUrl, {
              packageFilterSave: state?.packageFilter,
              serviceFilterSave: state?.serviceFilter,
              productFilterSave: state?.pproductFilter,
            });
          } else {
            history.push("/mspv2/sell/packages", {
              packageFilterSave: state?.packageFilter,
              serviceFilterSave: state?.serviceFilter,
              productFilterSave: state?.pproductFilter,
            });
          }
        }
      })
      .catch((err) => {
        console.log(err);
        CallToast("Something went wrong", false, false, "v2style");
      });
  }
  // Function to Update scenario Name
  async function updatePackageName(_packageName) {
    await postData(authTokens, "UpdateScenarioName", {
      objectId: scenarioId,
      scenarioName: newPackageName !== "" ? newPackageName : _packageName,
    })
      .then((response) => {
        if (response?.Success) {
        } else {
          CallToast("Something went wrong", false, false, "v2style");
        }
      })
      .catch((err) => {
        console.log(err);
        CallToast("Something went wrong", false, false, "v2style");
      });
  }

  const data = useMemo(() => {
    console.log("data rendeers");

    return {
      labels: ["Identify", "Protect", "Detect", "Respond", "Recover"],

      datasets: [
        {
          data: dataset, //[25, 20, 17, 30, 31],
          datalabels: {
            display: false,
          },
          backgroundColor: `${"#5244B3"}00`,
          borderColor: `${"#5244B3"}`, // "rgba(0, 143, 212, 1)",
          pointBackgroundColor: "rgba(0, 143, 212, 1)",
          borderWidth: 2,
          pointLabelFontSize: 0,
          scaleShowLabels: false,
          label: "Current Posture",
        },
      ],
    };
  }, [dataset]);
  const _options = useMemo(() => {
    console.log("option function executes");
    const dataValuePlugin = {
      afterDraw: function (chart) {
        const ctx = chart.ctx;
        const xAxis = chart.scales["r"];

        // Set blue color for data values
        ctx.fillStyle = "blue";
        ctx.font = "bold 12px Arial";

        // Loop through each dataset
        chart.data.datasets.forEach(function (dataset, datasetIndex) {
          const meta = chart.getDatasetMeta(datasetIndex);
          meta.data.forEach(function (element, index) {
            const value = dataset.data[index];

            // Calculate position for data value
            const xPos = element.x;
            const yPos = element.y + 20;

            // Draw data value below the label
            ctx.fillText(value, xPos, yPos);
          });
        });
      },
    };
    return {
      responsive: true,
      maintainAspectRatio: false,
      animation: {
        duration: 1, // 4000, // Set the duration of the transition in milliseconds
      },
      legend: {
        display: false,
      },

      tooltips: {
        enabled: true,
      },
      scale: {
        // display: true,
        pointLabels: {
          display: false,
          fontSize: 16,
          fontFamily: "Archivo, sans-serif",
          fontColor: "#101010",
        },
        ticks: {
          beginAtZero: false,
          stepSize: /*5*/10,
          max: /*35*/40,
          min: 0,
          display: false,
          backdropColor: "rgba(0, 0, 0, 0)",
        },
      },
      elements: {
        point: {
          radius: 2,
          hoverRadius: 4,
        },
      },
      plugins: {
        afterDraw: [dataValuePlugin], // Add the plugin to the chart
      },
    };
    // return opts;
  }, []);
  const __options = (activeTab) => {
    // let dataLabelsValue = [];

    // if (activeTab != "allscenario") {
    //   if (typeof datasets[activeTab] != "undefined") {
    //     dataLabelsValue = datasets[activeTab]["data"];
    //   } else {
    //     dataLabelsValue = datasets[0]["data"];
    //   }
    // } else {
    //   dataLabelsValue = datasets[0]["data"];
    // }
    // if (
    //   dataLabelsValue.length > 0

    // ) {
    //   dataLabelsValue.forEach((val, i) => {
    //     data.labels[i] = data.labels[i] + ` - ${val}`;
    //   });
    // }

    let opts = {
      responsive: true,
      maintainAspectRatio: false,
      legend: {
        display: false,
      },

      tooltips: {
        enabled: true,
      },
      scale: {
        display: true,
        pointLabels: {
          fontSize: 16,
          fontFamily: "Archivo, sans-serif",
          fontColor: "#101010",
        },
        ticks: {
          beginAtZero: false,
          stepSize: 5,
          max: 35,
          min: 0,
          display: false,
          backdropColor: "rgba(0, 0, 0, 0)",
          // callback: function () {
          //   return "";
          // },
        },
      },
      elements: {
        point: {
          radius: 2,
          hoverRadius: 4,
        },
      },
    };
    return opts;
  };

  useEffect(() => {
    if (Number(packageId) !== 0) {
      if (isCustom !== null) {
        if (isCustom === true) {
          if (clientId !== undefined && clientId !== 0) {
            getPackageDetails(
              Number(packageId),
              clientId,
              userAndDeviceCountChanged
            );
            getCustomerDevicesCount();
          }
        } else {
          getPackageDetails(Number(packageId), 0, userAndDeviceCountChanged);
        }
      }
    } else {
      // if (currencyList?.length < 0) {
      //   updateExchangeRateData();
      // }
    }
  }, [clientId, isCustom]);

  window.addEventListener('beforeunload', function (event) {
    if (Number(packageId) === 0 && !isSaveClicked) {
      window.localStorage.setItem("packageId", pageData?.response?.ScenarioHeader?.objId_Scenario);
    }
  });

  const [firstLoad, setfirstLoad] = useState(true);
  async function getPackageDetails(objId, clientId, userAndDeviceCountChanged) {
    updateSeatsIncludes(userAndDeviceCountChanged);
    return new Promise((resolve, reject) => {
      getAPIData(619, authTokens, {
        optionEnum1: objId,
        optionEnum2: state?.clientId ?? clientId ?? 0,
      })
        .then((response) => {
          resolve(response);
          if (response?.mr?.Success) {
            if (firstLoad) {
              setPackageName(response?.ScenarioHeader?.Name);
              setNewPackageName(response?.ScenarioHeader?.Name);
              setPackageBillingPeriod(
                response?.ScenarioHeader?.BillingPeriod_Selected ?? null
              );
              setAssetSeatValues(response);
              setPackageData(response);
              // Set Currency and Tax Part
              setCurrencyList(response?.CurrencyCode_List);

              setDefaultCurrency(
                response?.CurrencyCode_List?.find((f) => f.IsDefault)
              );
              console.log(
                "check curremcy---",
                defaultCurrency,
                response?.CurrencyCode_List?.find((f) => f.IsDefault)
              );
              setUserSelectedCurrency(
                response?.CurrencyCode_Selected ??
                (response?.CurrencyCode_List &&
                  response?.CurrencyCode_List?.find((f) => f.IsDefault))
              );
              setTaxList(response?.TaxRates_List);
              setSelectedTax(
                response?.TaxRates_Selected?.TaxName !== ""
                  ? response?.TaxRates_Selected
                  : {
                    ObjectId: 0,
                    TaxCode: "Not Taxable",
                    TaxName: "Not Taxable",
                    TaxRate: 0,
                    IsDefault: true,
                    IntegrationId: 0,
                  }
              );
            }
            setfirstLoad(false);
            setComponentsData(response?.Nist_All?.flatMap(obj => 
              Array.isArray(obj?.ScoreContributionSelected_List) && obj.ScoreContributionSelected_List?.length > 0 
                ? obj.ScoreContributionSelected_List 
                : []
            ));
            setExchangeRateData({
              Date: response?.CreatedDate,
              FromCurrency: response?.CurrencyCode_Selected?.CurrencyName,
              Rate: response?.ForeignCurrencyRate,
              ToCurrency: response?.CurrencyCode_List?.find((f) => f.IsDefault),
            });
            updateExchangeRateData(
              response?.CurrencyCode_Selected?.CurrencyName ??
              response?.CurrencyCode_List?.find((f) => f.IsDefault)
                ?.CurrencyName ??
              "GBP"
            );

            let updated_data = response;
            updated_data.MSPPackagePrice.SellOverridden = addZerows((updated_data?.MSPPackagePrice?.SellOverridden).toString())
            setPageData((prevData) => ({
              ...prevData,
              response: updated_data,
              service_list_data: /*[
                response?.Nist_Identify?.length > 0
                  ? [...response?.Nist_Identify]
                  : [],
                response?.Nist_Protect?.length > 0
                  ? [...response?.Nist_Protect]
                  : [],
                response?.Nist_Detect?.length > 0
                  ? [...response?.Nist_Detect]
                  : [],
                response?.Nist_Respond?.length > 0
                  ? [...response?.Nist_Respond]
                  : [],
                response?.Nist_Recover?.length > 0
                  ? [...response?.Nist_Recover]*/
                response?.Nist_All?.length > 0 ? response?.Nist_All : [],
              /*],*/
            }));
            setDataset([
              response?.ScenarioHeader?.InspectScore,
              response?.ScenarioHeader?.ProtectScore,
              response?.ScenarioHeader?.DetectScore,
              response?.ScenarioHeader?.RespondScore,
              response?.ScenarioHeader?.RecoverScore,
            ]);
            setPackageData(response);
            setTableLoader(false);

            // Set Billing Period

            setLoading(false);
          } else {
            CallToast("Something went wrong", false, false, "v2style");
          }
        })
        .catch((err) => {
          console.log(err);
          reject(err);
          CallToast("Something went wrong", false, false, "v2style");
        });
    });
  }

  function updateSnapToUnitAssetCounts(_userDeviceDetails) {
    return new Promise((resolve, reject) => {
      postData(authTokens, "UpdateSnapToUnitAssetCounts", {
        AccessKey: JSON.parse(localStorage.getItem("user_MessageObj")),
        UpdateTarget: 3,
        objId_Customer: clientId ?? 0,
        DefaultPackageAsset_List: JSON.stringify(_userDeviceDetails),
      })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  // function UpdateSnapToUnitAssetCounts() {
  //   postData(authTokens, "UpdateSnapToUnitAssetCounts", {
  //     AccessKey: JSON.parse(localStorage.getItem("user_MessageObj")),
  //     UpdateTarget: 3,
  //     objId_Customer: clientId ?? 0,
  //     DefaultPackageAsset_List: [], //! Change
  //   })
  //     .then((response) => {
  //       // setProcessing(false);
  //       if (response?.Success) {
  //         // hideModal();
  //         // if (refreshData) refreshData();
  //       } else {
  //         CallToast("Something went wrong", false, false, "v2style");
  //       }
  //     })
  //     .catch((err) => {
  //       CallToast("Something went wrong", false, false, "v2style");
  //       // setProcessing(false);
  //       console.log(err);
  //     });
  // }

  async function fillDataInDropdown() {
    // let updated_data_loader = [true, true, true, true, true];
    setServicesLoader(true);//(updated_data_loader);
    return Promise.all([
      getDropdownDataForService(),
      // getDropdownDataForService(1),
      // getDropdownDataForService(2),
      // getDropdownDataForService(3),
      // getDropdownDataForService(4),
    ]);
  }
  async function getServiceList(passedOptionEnum1) {
    // setDataLoading(true);
    await getAPIData(615, authTokens, {
      optionEnum1: passedOptionEnum1,
      optionEnum2: 0,
      optionEnum3: 0,
      optionEnum4: 0,
    }).then((response) => {
      // setDataLoading(false);
      if (response?.mr?.Success) {
        if (passedOptionEnum1 === 1) {
          setServiceList(response?.PackageServiceProduct_List);
        } else {
          setPackagesList(
            response?.PackageServiceProduct_List?.filter(
              (obj) => obj?.ObjectId !== Number(packageId)
            )
          );
          setClientId(
            response?.PackageServiceProduct_List?.find(
              (obj) => obj?.ObjectId === Number(packageId)
            )?.ObjId_Customer ?? 0
          );
          setIsCustom(
            response?.PackageServiceProduct_List?.find(
              (obj) => obj?.ObjectId === Number(packageId)
            )?.IsCustom
          );
        }
        // setLoading(false);
      } else {
      }
    });
  }
  async function getDropdownDataForService(/*_serviceEnum = 0*/) {
    await getAPIData(675, authTokens, {
      optionEnum1: /*_serviceEnum*/-1,
      optionEnum2: 14464,
      optionEnum3: scenarioId,
    })
      .then((response) => {
        let updated_page_data = { ...pageData };
        updated_page_data.service_dropdown_list/*[_serviceEnum]*/ = response;
        setPageData((prevData) => ({
          ...prevData,
          service_dropdown_list: updated_page_data.service_dropdown_list,
        }));
        // let updated_data = servicesLoader;
        // updated_data/*[_serviceEnum]*/ = false;
        // console.log('pageData -----------> ', pageData)
        setServicesLoader(false);//(updated_data);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  async function getCustomerDevicesCount() {
    await getAPIData(692, authTokens, {
      optionEnum1: clientId,
    })
      .then((response) => {
        setDefaultUserDeviceDetails(response);
        setTempUserDetailsData(response);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  async function add_or_delete_service_addOn(
    addID = 0,
    deleteID = 0,
    stopLoadingNewPackage = false
  ) {
    console.log('addID = ', addID)
    return new Promise((resolve, reject) => {
      setTableLoader(true);
      getAPIData(618, authTokens, {
        optionEnum1: scenarioId,
        optionEnum2: addID,
        optionEnum3: deleteID,
        optionEnum5: clientId ?? 0,
      })
        .then((response) => {
          resolve(response);
          if (stopLoadingNewPackage === false) {
            getPackageDetails(
              Number(scenarioId),
              clientId,
              userAndDeviceCountChanged
            );
          }
          if (response?.mr?.Success) {
          } else {
            CallToast("Something went wrong", false, false, "v2style");
          }
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  }

  function setAssetSeatValues(response) {
    // for Seats Setup
    if (response?.ScenarioHeader?.PackageType_Enum === 0) {
      setBilledByStatus({
        DDLId: 0,
        DDLText: "Seats",
      });
    }
    if (response?.ScenarioHeader?.PackageType_Enum === 1) {
      setBilledByStatus({
        DDLId: 1,
        DDLText: "Assets",
      });
    }
  }

  function updatePackage(
    billingPeriod,
    billedStatus,
    currencySelected,
    ForeignCurrencyRate
  ) {
    setUserAndDeviceCountChanged(false);
    return new Promise((resolve, reject) => {
      postData(authTokens, "UpdateMSPPackage", {
        ObjId_Scenario: scenarioId,
        scenarioName: newPackageName !== "" ? newPackageName : packageName,
        accessKey: JSON.parse(localStorage.getItem("user_MessageObj")),
        BilledBy: billedStatus ? billedStatus : billedByStatus?.DDLId,
        BillingPeriod: billingPeriod
          ? billingPeriod
          : packageBillingPeriod?.DDLId,
        // NoOfUsers:
        //   pageData?.response?.MSPPackagePrice?.PackagePerSeat_List[0]?.PerSeat,
        // NoOfDevices:
        //   pageData?.response?.MSPPackagePrice?.PackagePerSeat_List[3]?.PerSeat,
        // NoOfServers:
        //   pageData?.response?.MSPPackagePrice?.PackagePerSeat_List[6]?.PerSeat,
        // NoOfMobileDevices:
        //   pageData?.response?.MSPPackagePrice?.PackagePerSeat_List[2]?.PerSeat,
        // NoOfDomains:
        //   pageData?.response?.MSPPackagePrice?.PackagePerSeat_List[1]?.PerSeat,
        // NoOfRouters:
        //   pageData?.response?.MSPPackagePrice?.PackagePerSeat_List[5]?.PerSeat,
        // NoOfNetworkDevices:
        //   pageData?.response?.MSPPackagePrice?.PackagePerSeat_List[4]?.PerSeat,
        DefaultPackagePerSeat_List: JSON.stringify(
          pageData?.response?.DefaultPackagePerSeat_List
        ),
        TaxRates_Selected: JSON.stringify(selectedTax),
        CurrencyCode_Selected: currencySelected
          ? JSON.stringify(currencySelected)
          : JSON.stringify(userSelectedCurrency),
        ForeignCurrencyAmount: 0,
        ForeignCurrencyRate: ForeignCurrencyRate
          ? ForeignCurrencyRate
          : updatedExchangeRateData?.Rate ?? 1,
        Description: pageData?.response?.ScenarioHeader?.Description,
        SellPriceOverridden:
          pageData?.response?.MSPPackagePrice?.SellOverridden,
        ObjId_Customer: clientId ?? 0,
      })
        .then((response) => {
          setSaving(false);
          setUserAndDeviceCountChanged(false);
          if (!billingPeriod) {
            if (state?.backUrl) {
              history.push(state.backUrl, {
                packageFilterSave: state?.packageFilter,
                serviceFilterSave: state?.serviceFilter,
                productFilterSave: state?.productFilter,
              });
            } else {
              history.push("/mspv2/sell/packages", {
                packageFilterSave: state?.packageFilter,
                serviceFilterSave: state?.serviceFilter,
                productFilterSave: state?.productFilter,
              });
            }
          }
          resolve(response);
        })
        .catch((err) => {
          setSaving(false);

          reject(err);
        });
    });
  }

  async function updateExchangeRateData(_currency = "GBP") {
    let tempdata = {};
    await postData(authTokens, "GetCurrencyConverionRate", {
      // FromCurrency: _currency,
      // ToCurrency: defaultCurrency?.CurrencyName ?? "GBP",
      FromCurrency: defaultCurrency?.CurrencyName ?? "GBP",
      ToCurrency: _currency,
      accessKey: JSON.parse(localStorage.getItem("user_MessageObj")),
    })
      .then((response) => {
        setUpdateExchangeRateData(response);
        tempdata = response;
      })
      .catch((err) => {
        console.log(err);
      });
    return tempdata;
  }

  function updatePackageExchangeRateData(_currency = 0, packageId) {
    return new Promise((resolve, reject) => {
      postData(authTokens, "UpdatePackageForeignCurrencyRate", {
        PackageId: packageId,
        ForeignCurrencyRate: _currency,
        accessKey: JSON.parse(localStorage.getItem("user_MessageObj")),
      })
        .then((response) => {
          resolve(response);
          getPackageDetails(
            Number(scenarioId),
            clientId,
            userAndDeviceCountChanged
          );
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  }
  useEffect(() => {
    if (pageData?.response?.CurrencyCode_Selected?.CurrencyName != userSelectedCurrency?.CurrencyName) {
      if (userSelectedCurrency?.CurrencyName || defaultCurrency?.CurrencyName) {
        updateExchangeRateData(
          userSelectedCurrency?.CurrencyName ??
          defaultCurrency?.CurrencyName ??
          "GBP"
        );
      }
    }
  }, [userSelectedCurrency]);

  useEffect(() => {
    if (currencyList?.length <= 0) {
      updateExchangeRateData();
    }
  }, [])

  let totalTax =
    packageData?.MSPPackagePrice?.Subtotal *
    packageData?.MSPPackagePrice?.Tax *
    0.01;
  let totalCost = (
    packageData?.MSPPackagePrice?.Subtotal -
    packageData?.MSPPackagePrice?.Discount +
    totalTax
  ).toFixed(2);

  useEffect(() => {
    // Check if the default service name already exists
    if (Number(packageId) === 0) {
      const defaultServiceName = "Security Package 1";
      let incrementedName = defaultServiceName;
      let counter = 1;

      while (packagesList?.some((service) => service.Name == incrementedName)) {
        counter++;
        incrementedName = `Security Package ${counter}`;
      }

      // Set the default service name (incremented if necessary)
      setNewPackageName(incrementedName);
    } else {
    }
  }, [packagesList, packageId]);

  const handleInputChange = (e) => {
    setErrors((prev) => ({
      ...prev,
      packageNameError: false,
      packageNameExistError: false,
    }));
    const newName = e?.target?.value;
    setNewPackageName(newName);

    // Check if the entered name is unique
    // setIsNameUnique(
    //   !packagesList?.some((service) => service.Name === newName?.trim())
    // );
  };

  // const handleInputBlur = (name) => {
  //   // Check uniqueness and set the service name in state
  //   if ((name ? name?.trim() : newPackageName?.trim()) === "") {
  //     // Optionally handle the case when the name is empty
  //     setErrors((prev) => ({
  //       ...prev,
  //       packageNameError: false,
  //       packageNameExistError: true,
  //     }));
  //     // setNewPackageName(packageName)
  //   } else if (isNameUnique === true) {
  //     setChanged(name !== packageName ? true : false);
  //     // Set the service name in state only if it's unique
  //     // updatePackageName(packageName);
  //     setErrors((prev) => ({
  //       ...prev,
  //       packageNameError: false,
  //       packageNameExistError: false,
  //     }));
  //   } else {
  //     // Optionally handle the case when the name is not unique
  //     setErrors((prev) => ({
  //       ...prev,
  //       packageNameError: true,
  //       packageNameExistError: false,
  //     }));
  //   }
  // };


  async function handleInputBlur(name) {
    console.log('packageName --- ', packageName)
    if (packageName != name) {

      if (name !== "") {
        setErrors((prev) => ({
          ...prev,
          packageNameError: false,
          packageNameExistError: false,
        }));
        await getAPIData(989, authTokens, {
          optionStrEnum1: name
        })
          .then((response) => {
            // setIsNameUnique(response?.Success);
            if (response?.Success) {
              setErrors((prev) => ({
                ...prev,
                packageNameError: true,
                packageNameExistError: false,
              }));
            } else {
              setChanged(name !== packageName ? true : false);
              setErrors((prev) => ({
                ...prev,
                packageNameError: false,
                packageNameExistError: false,
              }));
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        setErrors((prev) => ({
          ...prev,
          packageNameError: true,
          packageNameExistError: false,
        }));
      }
    }
  }

  const [inputSize, setInputSize] = useState(6.8);

  useEffect(() => {
    if (
      pageData?.response?.MSPPackagePrice?.SellOverridden &&
      pageData?.response?.MSPPackagePrice?.SellOverridden !== 0
    ) {
      let size =
        formatNumberWithTwoDecimalPlaces(
          pageData?.response?.MSPPackagePrice?.SellOverridden
        )?.toString()?.length +
          2.4 >
          6.4
          ? formatNumberWithTwoDecimalPlaces(
            pageData?.response?.MSPPackagePrice?.SellOverridden
          )?.toString()?.length + 2.4
          : 6.4;
      setInputSize(size);
    }
  }, [pageData?.response?.MSPPackagePrice?.SellOverridden]);

  function formatNumberWithTwoDecimalPlaces(number) {
    // Convert the number to a string with two decimal places
    let formattedNumber = Number(number)?.toFixed(2);

    // If the number has only one decimal place, add a trailing zero
    if (formattedNumber.indexOf(".") === formattedNumber.length - 2) {
      formattedNumber += "0";
    }

    return formattedNumber;
  }

  useEffect(() => {
    if (changed) {
      updatePackage(packageBillingPeriod?.DDLId).then(() => {
        getPackageDetails(Number(scenarioId), clientId, false);
      });
    }
  }, [selectedTax]);

  const [showMspScoreModal, setShowMspScoreModal] = useState(false);
  const [showMspFeaturesModal, setShowMspFeaturesModal] = useState(false);
  const [showExploreMappingModal, setShowExploreMappingModal] = useState(false);
  const [showRadar, setShowRadar] = useState(false);
  const [serviceModalData, setServiceModalData] = useState({});

  async function updateRadarChart() {
    setLoading(true);
    await getAPIData(611, authTokens, {}).then((response) => {
      setShowRadar(response?.ShowNistGraph);
      setServiceModalData(response);
      setLoading(false);
    });
  }

  const [showAddEditTaxRateModal, setShowAddEditTaxRateModal] = useState(false);
  const [taxRateData, setTaxRateData] = useState({});

  function getTaxRateList() {
    return new Promise((resolve, reject) => {
      getAPIData("774", authTokens, {
        // ObjId_Customer: clientId,
        accessKey: JSON.parse(localStorage.getItem("user_MessageObj")),
      })
        .then((response) => {
          resolve(response);
          setTaxList(response?.TaxRates_List ?? []);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  }

  function updateTaxList(stoploading = false) {
    if (!stoploading) {
      // setLoading(true);
    }
    getTaxRateList()
      .then((response) => {
        setTaxList(response?.TaxRates_List);
        // setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        // setLoading(false);
      });
  }

  useEffect(() => {
    setSelectedServices(pageData?.service_list_data);
  }, [pageData]);
  function updateSelectedServices(service) {
    let found = false;
    // Check if any object in the selectedServices array matches passedId
    const foundIndex = selectedServices.findIndex(
      (obj) => obj?.objId_SecurityPack === service?.objId_SecurityPack
    );
    if (foundIndex !== -1) {
      // Remove the object if found
      selectedServices.splice(foundIndex, 1);
      found = true;
    }

    // If not found, add it to the array at index NistPillar
    if (!found) {
      selectedServices.push(service);
    }
    setSelectedServices(selectedServices);
    return selectedServices;
  }

  const [margin, setMargin] = useState(0);

  useEffect(() => {
    setMargin(
      (
        pageData?.response?.MSPPackagePrice?.SellOverridden -
        pageData?.response?.MSPPackagePrice?.Cost
      ).toFixed(2)
    );
  }, [pageData]);

  const inputRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (inputRef.current && !inputRef.current.contains(event.target)) {
        setShowDropdownVisibilityArray(false);//([false, false, false, false, false]);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const [seatsIncludesError, setSeatsIncludesError] = useState(false);

  function updateSeatsIncludes(userAndDeviceCountChanged) {
    if (userAndDeviceCountChanged) {
      setChanged(true);
      if (
        pageData?.response?.DefaultPackagePerSeat_List?.some(
          (obj) => obj?.PerSeat != 0
        )
      ) {
        setTableLoader(true);
        setSeatsIncludesError(false);
        updatePackage(packageBillingPeriod?.DDLId).then(() => { });
      } else {
        setSeatsIncludesError(true);
      }
      setUserAndDeviceCountChanged(false);
    }
  }
  //---------------------------------------------------------------------------------------

  const [draggingId, setDraggingId] = useState(null);

  // const reorder = (list, startIndex, endIndex) => {
  //     const result = Array.from(list);
  //     const [removed] = result.splice(startIndex, 1);
  //     result.splice(endIndex, 0, removed);
  //     return result;
  // };

  // const onDragEndShown = (result) => {
  //     if (!result.destination) return;

  //     const items = reorder(
  //         selectedServices,
  //         result.source.index,
  //         result.destination.index
  //     );

  //     setSelectedServices(
  //         items.map((item, index) => ({
  //             ...item,
  //             SortOrder: index + 1,
  //         }))
  //     );
  // };

  const onDragEnd = (result) => {
    const { destination, source } = result;

    // Dropped outside the list
    if (!destination) return;

    // Reorder the array
    const updatedServices = Array.from(selectedServices);
    const [removed] = updatedServices.splice(source.index, 1);
    updatedServices.splice(destination.index, 0, removed);

    // Update state with reordered items
    setSelectedServices(updatedServices);
  };

  const [defaultUserDeviceDetails, setDefaultUserDeviceDetails] = useState([]);
  const [tempUserDetailsData, setTempUserDetailsData] = useState([]);

  // const [defaultUserDeviceDetails, setDefaultUserDeviceDetails] = useState({
  //   NoOfUsers: 1,
  //   NoOfWorkstation: 1,
  //   NoOfServices: 1,
  //   NoOfMobileDevices: 1,
  //   NoOfDomains: 1,
  //   NoOfRouters: 1,
  //   NoOfNetworkDevices: 1,
  // });
  // const [tempUserDetailsData, setTempUserDetailsData] = useState({
  //   NoOfUsers: 1,
  //   NoOfWorkstation: 1,
  //   NoOfServices: 1,
  //   NoOfMobileDevices: 1,
  //   NoOfDomains: 1,
  //   NoOfRouters: 1,
  //   NoOfNetworkDevices: 1,
  // });

  // const preDataState = Object.values(defaultUserDeviceDetails).every(
  //   (value) => value === 0
  // );
  const preDataState =
    defaultUserDeviceDetails.length !== 0 &&
    defaultUserDeviceDetails?.every((item) => item?.Qty === 0);

  const [savingAssets, setSavingAssets] = useState(false);

  function saveAssets() {
    setSavingAssets(true);
    setTableLoader(true);
    // let userDetails = { ...tempUserDetailsData };

    let userDetails = [...tempUserDetailsData];

    userDetails.forEach((item) => {
      if (item.Qty === "") {
        item.Qty = 0;
      }
    });

    updateSnapToUnitAssetCounts(userDetails)
      .then((res) => {
        document.getElementById("quantyAssetOnDashboard").click();
        setSavingAssets(false);
        getCustomerDevicesCount();
        getPackageDetails(
          Number(scenarioId),
          clientId,
          userAndDeviceCountChanged
        );
        // CallToast("Updated successfully", false, false, "v2style");
      })
      .catch((err) => {
        setSavingAssets(false);
        console.log(err);
        CallToast("Something went wrong", false, false, "v2style");
      });
  }

  // function updateQuantityOfAssets(_userDeviceDetails) {
  //   return new Promise((resolve, reject) => {
  //     postData(authTokens, "UpdateUserSettingsAssetCounts", {
  //       NoOfDomains: _userDeviceDetails.NoOfDomains,
  //       NoOfWorkstation: _userDeviceDetails.NoOfWorkstation,
  //       NoOfUsers: _userDeviceDetails.NoOfUsers,
  //       NoOfServers: _userDeviceDetails.NoOfServices,
  //       NoOfMobileDevices: _userDeviceDetails.NoOfMobileDevices,
  //       accessKey: JSON.parse(localStorage.getItem("user_MessageObj")),
  //       UpdateTarget: 3,
  //       NoOfRouters: _userDeviceDetails.NoOfRouters,
  //       NoOfNetworkDevices: _userDeviceDetails.NoOfNetworkDevices,
  //       objId_Customer: clientId,
  //     })
  //       .then((response) => {
  //         resolve(response);
  //       })
  //       .catch((error) => {
  //         reject(error);
  //       });
  //   });
  // }

  // console.log('DefaultUserDeviceDetails ---------------------------- ', defaultUserDeviceDetails)

  function replaceEmptyWithZero(obj) {
    for (let key in obj) {
      if (obj.hasOwnProperty(key)) {
        if (obj[key] === "") {
          obj[key] = 0;
        }
      }
    }
    return obj;
  }

  useEffect(() => {
    setTempUserDetailsData(defaultUserDeviceDetails);
  }, [defaultUserDeviceDetails]);

  function deleteData(objId) {
    return new Promise((resolve, reject) => {
      getAPIData(621, authTokens, {
        optionEnum1: objId,
      })
        .then((response) => {
          if (response?.Success) {
            // CallToast("Deleted successfully", false, false, "v2style");
          }
        })
        .catch((err) => {
          console.log(err);
          reject(err);
          CallToast("Something went wrong", false, false, "v2style");
        });
    });
  }
  async function sendReoderServices(packJson, nist) {
    await postData(authTokens, "SecurityPackSortOrder", {
      ObjId_Secnario: scenarioId,
      SecurityPack_Json: JSON.stringify(packJson),
      NistPillar: nist,
    })
      .then((response) => { })
      .catch((err) => {
        console.log(err);
        CallToast("Something went wrong", false, false, "v2style");
      });
  }

  const [selectedTab, setSelectedTab] = useState(0);

  return (
    <div className="integration-header">
      <div
        className="w-100 bg-black d-flex align-items-center justify-content-between"
        style={{
          position: "sticky",
          top: "56px",
          height: "51px",
          borderTop: "1px solid rgba(255, 255, 255, 0.08)",
          zIndex: 1,
        }}
      >
        <div
          className="d-flex align-items-center f-white f-500 ml-3 pointer"
          onClick={() => {
            if (changed) {
              setWarningModalConfig((prev) => ({
                ...prev,
                showWarningModal: true,
              }));
            } else {
              console.log(
                "test teste tstetet====",
                state?.packageFilter,
                state
              );
              if (state?.backUrl) {
                history.push(state.backUrl, {
                  packageFilterSave: state?.packageFilter,
                  serviceFilterSave: state?.serviceFilter,
                  productFilterSave: state?.productFilter,
                });
              } else {
                history.push("/mspv2/sell/packages", {
                  packageFilterSave: state?.packageFilter,
                  serviceFilterSave: state?.serviceFilter,
                  productFilterSave: state?.productFilter,
                });
              }
              if (Number(packageId) === 0) {
                deleteData(pageData?.response?.ScenarioHeader?.objId_Scenario);
              }
            }
          }}
        >
          <img
            alt=""
            src="/images/msp/msp-white-cross.svg"
            className="mr-3 pointer"
          />
          {Number(packageId) === 0 ? "Add" : "Edit"} Security Package
        </div>
        {/* Mapping Tabs List */}
        <div className="d-flex align-items-center"></div>

        <div className="mr-3">
          <Button
            className="bg-transparent border-none mr-2"
            onClick={() => {
              if (changed) {
                setWarningModalConfig((prev) => ({
                  ...prev,
                  showWarningModal: true,
                }));
              } else {
                if (state?.backUrl) {
                  history.push(state.backUrl, {
                    packageFilterSave: state?.packageFilter,
                    serviceFilterSave: state?.serviceFilter,
                    productFilterSave: state?.productFilter,
                  });
                } else {
                  history.push("/mspv2/sell/packages", {
                    packageFilterSave: state?.packageFilter,
                    serviceFilterSave: state?.serviceFilter,
                    productFilterSave: state?.productFilter,
                  });
                }
                if (Number(packageId) === 0) {
                  deleteData(
                    pageData?.response?.ScenarioHeader?.objId_Scenario
                  );
                }
              }
            }}
          >
            Cancel
          </Button>
          <Button
            disabled={
              saving ||
              errors.packageNameError ||
              errors.packageNameExistError ||
              seatsIncludesError
            }
            className="hg-blue-btn"
            onClick={() => {
              if (billedByStatus?.DDLText === "Seats") {
                if (
                  pageData?.response?.DefaultPackagePerSeat_List?.some(
                    (obj) => obj?.PerSeat != 0
                  )
                ) {
                  setSeatsIncludesError(false);
                  if (selectedServices/*.some((arr) => arr*/.length !== 0/*)*/) {
                    setSaving(true);
                    updatePackage();
                  } else {
                    setIsSaveClicked(true);
                    setWarningModalConfig((prev) => ({
                      ...prev,
                      showWarningModal: true,
                    }));
                  }
                } else {
                  setSeatsIncludesError(true);
                }
              } else {
                if (selectedServices/*.some((arr) => arr*/.length !== 0/*)*/) {
                  setSaving(true);
                  updatePackage();
                } else {
                  setIsSaveClicked(true);
                  setWarningModalConfig((prev) => ({
                    ...prev,
                    showWarningModal: true,
                  }));
                }
              }
            }}
          >
            {saving ? "Saving.." : "Save"}
          </Button>
        </div>
      </div>

      {/* Whole UI fro the MSP Pricing Calculator */}
      <div className="msp-price-calculator justify-content-around">
        {loading ? (
          <MspPriceCalcultorLoader />
        ) : (
          <>
            {/* Left SIDE */}
            <div
              className="px-4"
              style={{
                minWidth: "560px",
                maxWidth: "560px",
                marginBottom: "50px",
              }}
            >
              <div className="f-14 f-600 f-black py-3">
                Security Package Details
              </div>

              <div
                className={`highlight-input-with-grey-border   w-100  d-flex align-items-center p-2 radius-4 mt-2   ${errors?.packageNameError ? "input-error" : ""
                  } `}
              >
                <div className="f-darkgrey">Name:</div>
                <input
                  tabIndex={0}
                  type="text"
                  placeholder="Package name"
                  className="flex-grow-1 pl-1 pr-2"
                  value={
                    Number(packageId) !== 0 ? newPackageName : newPackageName
                  }
                  onChange={(e) => {
                    handleInputChange(e);
                  }}
                  onBlur={(e) => {
                    handleInputChange(e);
                    handleInputBlur(e.target.value);
                  }}
                />
              </div>
              {errors?.packageNameExistError && (
                <div className="f-danger f-12 p-1">
                  Service name cannot be empty
                </div>
              )}
              {errors?.packageNameError && (
                <div className="f-danger f-12 p-1">
                  Service name already exists
                </div>
              )}

              <div
                className={`highlight-input-with-grey-border   w-100  d-flex align-items-center p-2 radius-4 mt-2`}
              >
                <div className="f-darkgrey">Description:</div>
                <input
                  tabIndex={0}
                  type="text"
                  placeholder="Description"
                  className="flex-grow-1 pl-1 pr-2"
                  // value={
                  //   pageData?.response?.ScenarioHeader?.IsCustomPackage ?
                  //     `Improved Detection Package for ${pageData?.response?.ScenarioHeader?.ClientName}`
                  //   :
                  //     'Improved Detection Package for all'
                  // }
                  value={pageData?.response?.ScenarioHeader?.Description}
                  onChange={(e) => {
                    let value = e.target.value;
                    setChanged(true);
                    setPageData((prevPageData) => ({
                      ...prevPageData,
                      response: {
                        ...prevPageData.response,
                        ScenarioHeader: {
                          ...prevPageData.response.ScenarioHeader,
                          Description: value,
                        },
                      },
                    }));
                  }}
                  onBlur={() => { }}
                />
              </div>

              <div className="mt-3 d-flex align-content-center align-items-center">
                <div className="f-darkgrey mr-3">Type</div>
                {/* {pageData?.response?.ScenarioHeader?.IsCustomPackage ? ( */}
                {isCustom ? (
                  <div className="d-flex align-content-center align-items-center">
                    <div className="f-500 f-black mr-2">Custom Package for</div>
                    {pageData?.response?.ScenarioHeader?.ClientLogo ? (
                      <img
                        alt=""
                        className="rounded-sm"
                        width={24}
                        height={24}
                        src={pageData?.response?.ScenarioHeader?.ClientLogo}
                      />
                    ) : (
                      <ImageWithInitials
                        initials={pageData?.response?.ScenarioHeader?.ClientName?.charAt(
                          0
                        )}
                        width={24}
                        background={"purple"}
                        borderRadius={"15"}
                      />
                    )}
                    <div className="f-500 ml-2 f-black">
                      {pageData?.response?.ScenarioHeader?.ClientName}
                    </div>
                  </div>
                ) : (
                  <div className="d-flex align-content-center align-items-center">
                    <img
                      alt=""
                      className="mr-2 rounded-sm"
                      width={24}
                      height={24}
                      src={pageData?.response?.ScenarioHeader?.ClientLogo}
                    />
                    <div className="f-500 f-black">MSP Standard Package</div>
                  </div>
                )}
              </div>

              {/* Seats and Assets Setup */}
              <div className="mt-4">
                <div className="h-fit-content w-fit-content d-flex align-items-center bg-grey radius-4 dropdown-grey-theme">
                  <div className="f-500 f-grey pl-2">Billed By:</div>
                  <div className="">
                    <CompanySettingsDropdown
                      fieldId={"DDLId"}
                      fieldValue={"DDLText"}
                      selected={billedByStatus}
                      placeholder="Choose..."
                      //  showid={true}
                      onClick={(id, val) => {
                        if (billedByStatus?.DDLId != id) {
                          setSeatsIncludesError(false);
                          setBilledByStatus({
                            DDLId: id,
                            DDLText: val,
                          });
                          updatePackage(packageBillingPeriod?.DDLId, id).then(
                            () => {
                              setChanged(true);
                              getPackageDetails(
                                Number(scenarioId),
                                clientId,
                                false
                              );
                            }
                          );
                        }
                      }}
                      data={[
                        {
                          DDLId: 1,
                          DDLText: "Assets",
                        },
                        {
                          DDLId: 0,
                          DDLText: "Seats",
                        },
                      ]}
                    />
                  </div>
                </div>

                <div className="mt-4">
                  <div className="f-500 f-14 f-black">
                    {billedByStatus?.DDLText === "Seats" ? "Seats" : "Assets"}
                  </div>
                  <div className="f-darkgrey mt-1">
                    {billedByStatus?.DDLText === "Seats"
                      ? "Enables you to keep billing simple whilst controlling your cost and sell prices. Define what is included in a ‘seat’ and HighGround will do the calculations for you and recommend the number of seats your client needs."
                      : "Enables you to keep billing more transparent for your clients and maintain tighter control over what you charge for."}
                  </div>
                  <div className="f-14 txt-blue pointer learn-more-underline mt-1 mb-3">
                    Learn more
                  </div>
                </div>

                {billedByStatus?.DDLText === "Seats" && (
                  <div className="">
                    <div className="f-12 f-darkgrey">Seats includes</div>
                    {/* <div className="f-darkgrey">
                        Set what is included within a ‘seat’
                      </div> */}

                    {/* <div className="f-12 f-darkgrey mt-4 mb-2">Qty.</div> */}
                    {seatsIncludesError && (
                      <div className="f-danger f-12 p-1">
                        At least one value should not be 0
                      </div>
                    )}
                    <div className="d-flex flex-wrap   msp_assets_inputs_wrapper">
                      {pageData?.response?.DefaultPackagePerSeat_List?.map(
                        (item, index) => {
                          return (
                            <div
                              className={`highlight-input-with-grey-border d-flex align-items-center       p-2 radius-4 mt-2   ${seatsIncludesError ? "input-error" : ""
                                } `}
                            >
                              <div className="f-darkgrey flex-shrink-0">
                                {TrimString(
                                  item?.Name?.replace("Per ", ""),
                                  13
                                )}
                                :
                              </div>
                              <input
                                tabIndex={0}
                                type="number"
                                placeholder="Type"
                                className=" pl-1 pr-2"
                                value={item?.PerSeat ?? ""}
                                onChange={(e) => {
                                  setChanged(true);
                                  setUserAndDeviceCountChanged(true);
                                  let newData = e.target.value;
                                  let updated_data = { ...pageData };
                                  updated_data.response.DefaultPackagePerSeat_List[
                                    index
                                  ].PerSeat = newData;
                                  updated_data.response.DefaultPackagePerSeat_List[
                                    index
                                  ].PerSeat = newData;
                                  setPageData(updated_data);
                                }}
                                onClick={() => {
                                  if (
                                    pageData?.response
                                      ?.DefaultPackagePerSeat_List[index]
                                      ?.PerSeat === "0" ||
                                    pageData?.response
                                      ?.DefaultPackagePerSeat_List[index]
                                      ?.PerSeat === 0
                                  ) {
                                    let updated_data = { ...pageData };
                                    updated_data.response.DefaultPackagePerSeat_List[
                                      index
                                    ].PerSeat = "";
                                    updated_data.response.DefaultPackagePerSeat_List[
                                      index
                                    ].PerSeat = "";
                                    setPageData(updated_data);
                                  }
                                }}
                                onBlur={() => {
                                  if (
                                    pageData?.response
                                      ?.DefaultPackagePerSeat_List[index]
                                      ?.PerSeat === ""
                                  ) {
                                    let updated_data = { ...pageData };
                                    updated_data.response.DefaultPackagePerSeat_List[
                                      index
                                    ].PerSeat = "0";
                                    updated_data.response.DefaultPackagePerSeat_List[
                                      index
                                    ].PerSeat = "0";
                                    setPageData(updated_data);
                                  }
                                  if (
                                    pageData?.response?.DefaultPackagePerSeat_List?.some(
                                      (obj) => obj?.PerSeat != 0
                                    )
                                  ) {
                                    setSeatsIncludesError(false);
                                  } else {
                                    setSeatsIncludesError(true);
                                  }
                                  setTableLoader(true);
                                  updatePackage(packageBillingPeriod?.DDLId).then(() => {
                                    getPackageDetails(Number(scenarioId), clientId, false);
                                  });
                                }}
                              />
                            </div>
                          );
                        }
                      )}
                    </div>
                  </div>
                )}

                {/* Pricing block */}

                <div>
                  {billedByStatus && (
                    <>
                      <div className="f-12 f-500 f-darkgrey mb-2 mt-3 d-flex align-items-center">
                        Pricing
                        {billedByStatus?.DDLText === "Seats" && tableLoader ? (
                          <div className="msp-service-update-loader ml-2 mr-1"></div>
                        ) : (
                          <></>
                        )}
                      </div>
                      <div className="d-flex align-items-center w-100 mb-3">
                        <div className="w-50">
                          <div className="d-flex f-500">
                            Billing period{" "}
                            <div className="msp-service-tool-tip-parent w-fit-content">
                              <img
                                className="ml-2 pointer"
                                src="/images/msp/ques-info-icon.svg"
                                alt=""
                              />
                              <MspServiceModalToolTip
                                data={{
                                  PackageHelpToolTip_Enum: 4,
                                  Name: "Billing Period",
                                  Description:
                                    "This is the billing period that this package is billed to your client. Options include monthly, quarterly and annually.",
                                  Image: "Billing_Period",
                                }}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="w-50 ">
                          <div className="w-fit-content dropdown-grey-theme">
                            <CompanySettingsDropdown
                              fieldId={"DDLSort"}
                              fieldValue={"DDLText"}
                              selected={packageBillingPeriod}
                              rightAlign={true}
                              placeholder="Choose..."
                              //  showid={true}
                              onClick={(id, val, e, obj) => {
                                if (
                                  pageData?.response?.ScenarioHeader
                                    ?.BillingPeriod_Selected?.DDLSort !=
                                  obj.DDLSort
                                ) {
                                  setTableLoader(true);
                                  setPackageBillingPeriod({
                                    ObjId: id,
                                    DDLText: val,
                                    DDLId: obj.DDLId,
                                    DDLSort: obj.DDLSort,
                                  });
                                  // updateSeatsIncludes(userAndDeviceCountChanged, userAndDeviceChanged);
                                  updatePackage(obj.DDLId).then(() => {
                                    setChanged(true);
                                    getPackageDetails(
                                      Number(scenarioId),
                                      clientId,
                                      false
                                    );
                                  });
                                }
                              }}
                              data={
                                pageData?.response?.BillingPeriod_List ?? []
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </>
                  )}

                  {/* Currency and Tax UI for Packages */}
                  <div className="w-100 py-2 d-flex align-items-center f-black">
                    <div className="w-50">Currency</div>
                    <div className="w-50 d-flex align-items-center currency-dropdown-container">
                      <div className="">
                        <CurrencyDropdown
                          currencyList={currencyList}
                          menuClassName={
                            "right-align upload-only-template-drop"
                          }
                          onChange={(data) => {
                            setUserSelectedCurrency(data);
                            updateExchangeRateData(
                              data?.CurrencyName ??
                              defaultCurrency?.CurrencyName
                            ).then((response) => {
                              updatePackage(
                                packageBillingPeriod?.DDLId,
                                billedByStatus?.DDLId,
                                data,
                                response?.Rate
                              ).then(() => {
                                getPackageDetails(
                                  Number(scenarioId),
                                  clientId,
                                  false
                                );
                              });
                            });

                            setChanged(true);
                          }}
                          selected={userSelectedCurrency}
                        />
                      </div>
                    </div>
                  </div>

                  {billedByStatus?.DDLText === "Seats" && (
                    <div className="w-100">
                      <div className="w-100 d-flex align-items-center my-2 mb-3">
                        <div className="f-500 w-50">Seat Cost</div>
                        <div className="f-500 w-50">
                          {/* <div></div> */}
                          <div>
                            {" "}
                            {pageData?.response?.MSPPackagePrice?.Cost_Text && (
                              <>{userSelectedCurrency?.CurrencySymbol}</>
                            )}
                            {numberWithCommas(
                              (
                                pageData?.response?.MSPPackagePrice?.Cost *
                                (exchangeRateData?.Rate ?? 1)
                              )?.toFixed(2)
                            )}
                            {" "}/
                            {
                              pageData?.response?.ScenarioHeader
                                ?.BillingPeriod_Selected?.DDLColour
                            }
                          </div>
                          {userSelectedCurrency?.CurrencySymbol !==
                            defaultCurrency?.CurrencySymbol && (
                              <div className="f-darkgrey">
                                <img
                                  alt=""
                                  width={13}
                                  height={13}
                                  src="/images/msp/exchange-rate-icon.svg"
                                  className="mr-2"
                                />
                                {pageData?.response?.MSPPackagePrice
                                  ?.Cost_Text && (
                                    <>
                                      {/* {userSelectedCurrency?.CurrencySymbol} */}
                                      {defaultCurrency?.CurrencySymbol ?? "£"}
                                    </>
                                  )}
                                {numberWithCommas(
                                  pageData?.response?.MSPPackagePrice?.Cost_Text
                                ) ??
                                  renderPriceText(
                                    pageData?.response?.ScenarioHeader
                                      ?.BillingPeriod_Selected?.DDLSort,
                                    0,
                                    userSelectedCurrency?.CurrencySymbol
                                  )}
                              </div>
                            )}

                          {/* {renderPriceText(
                            pageData?.response?.ScenarioHeader?.BillingPeriod,
                            pageData?.response?.MSPPackagePrice?.Cost,
                            userSelectedCurrency?.CurrencySymbol
                          )} */}
                        </div>
                      </div>

                      <div className="w-100 d-flex align-items-center my-2 mb-3">
                        <div className="d-flex align-items-center w-50">
                          <div className="f-500">Seat RRP</div>
                          <AttackSurfaceTooltip
                            content={
                              "Best practice is to achieve minimum 70% net margin on managed cyber security services"
                            }
                          >
                            <img
                              className="ml-2 pointer"
                              src="/images/msp/ques-info-icon.svg"
                              alt=""
                            />
                          </AttackSurfaceTooltip>
                        </div>
                        {/* </div> */}
                        <div className="f-500 w-50">
                          {pageData?.response?.MSPPackagePrice?.RRP_Text && (
                            <>{userSelectedCurrency?.CurrencySymbol}</>
                          )}
                          {numberWithCommas(
                            (
                              pageData?.response?.MSPPackagePrice?.RRP *
                              (exchangeRateData?.Rate ?? 1)
                            )?.toFixed(2)
                          )}
                          {" "}/
                          {
                            pageData?.response?.ScenarioHeader
                              ?.BillingPeriod_Selected?.DDLColour
                          }
                          {/* {numberWithCommas(
                            pageData?.response?.MSPPackagePrice?.RRP_Text?.replace(
                              /\//g,
                              " /"
                            )
                          ) ??
                            renderPriceText(
                              pageData?.response?.ScenarioHeader
                                ?.BillingPeriod_Selected?.DDLSort,
                              0,
                              userSelectedCurrency?.CurrencySymbol
                            )} */}
                        </div>
                      </div>

                      <div className="w-100 d-flex align-items-center my-2 mb-3">
                        <div className="f-500 w-50">Seat Price</div>
                        <div className="w-50 ">
                          <div className="w-100 d-flex align-items-center justify-content-between">
                            <div className="f-500 d-flex align-items-center">
                              <div
                                className="d-flex align-items-center radius-4 highlight-input-with-darker-grey-border"
                                style={{
                                  width: `${inputSize + 1}ch`,
                                  height: "34px",
                                }}
                              >
                                <div className="ml-1 mr-2">
                                  {userSelectedCurrency?.CurrencySymbol}
                                </div>
                                <input
                                  type="number"
                                  style={{
                                    width: `${inputSize + 1}ch`,
                                  }}
                                  className="bg-transparent  border-none w-100"
                                  placeholder="0.00"
                                  // value={(
                                  //   pageData?.response?.MSPPackagePrice
                                  //     ?.SellOverridden *
                                  //   (exchangeRateData?.Rate ?? 1)
                                  // )?.toFixed(2)}
                                  value={
                                    pageData?.response?.MSPPackagePrice
                                      ?.SellOverridden
                                  }
                                  onChange={(e) => {
                                    setChanged(true);
                                    let value = e.target.value;
                                    const regex = /^-?\d*\.?\d{0,2}$/;

                                    if (regex.test(value)) {
                                      let updated_data = { ...pageData };
                                      updated_data.response.MSPPackagePrice.SellOverridden =
                                        e.target.value;
                                      setPageData(updated_data);
                                    } else {
                                      value = value.slice(0, -1);
                                      let updated_data = { ...pageData };
                                      updated_data.response.MSPPackagePrice.SellOverridden =
                                        e.target.value;
                                      setPageData(updated_data);
                                    }
                                    // let updated_data = { ...pageData };
                                    // updated_data.response.MSPPackagePrice.SellOverridden =
                                    //   e.target.value;
                                    // setPageData(updated_data);
                                  }}
                                  onBlur={(e) => {
                                    setTableLoader(true);
                                    // updateSeatsIncludes(userAndDeviceCountChanged, userAndDeviceChanged);
                                    const value = e.target.value;
                                    if (!isNaN(value)) {
                                      if (value.includes(".")) {
                                        const decimalPartLength =
                                          value.split(".")[1].length;
                                        if (decimalPartLength === 1) {
                                          let updated_data = { ...pageData };
                                          updated_data.response.MSPPackagePrice.SellOverridden =
                                            value + "0";
                                          setPageData(updated_data);
                                        } else if (decimalPartLength > 2) {
                                          let updated_data = { ...pageData };
                                          updated_data.response.MSPPackagePrice.SellOverridden =
                                            Number(value).toFixed(2).toString();
                                          setPageData(updated_data);
                                        }
                                      } else {
                                        let updated_data = { ...pageData };
                                        updated_data.response.MSPPackagePrice.SellOverridden =
                                          value + ".00";
                                        setPageData(updated_data);
                                      }
                                    }
                                    updatePackage(
                                      packageBillingPeriod?.DDLId
                                    ).then(() => {
                                      getPackageDetails(
                                        Number(scenarioId),
                                        clientId,
                                        false
                                      );
                                    });
                                  }}
                                />
                              </div>
                              <div className="ml-1">
                                /
                                {pageData?.response?.ScenarioHeader
                                  ?.BillingPeriod_Selected?.DDLSort === 2
                                  ? "year"
                                  : pageData?.response?.ScenarioHeader
                                    ?.BillingPeriod_Selected?.DDLColour}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="w-100 py-2 d-flex align-items-center f-black">
                        <div className="w-50">Seat Margin</div>
                        <div
                          className={`w-50 
                            ${margin < 0 && " redText "}
                      `}
                        >
                          {userSelectedCurrency?.CurrencySymbol}
                          {numberWithCommas(
                            (margin * (exchangeRateData?.Rate ?? 1))?.toFixed(2)
                          )}
                          {" "}/
                          {
                            pageData?.response?.ScenarioHeader
                              ?.BillingPeriod_Selected?.DDLColour
                          }
                          {/* {renderPriceText(
                            pageData?.response?.ScenarioHeader
                              ?.BillingPeriod_Selected?.DDLSort,
                            margin,
                            userSelectedCurrency?.CurrencySymbol
                          )} */}
                        </div>
                      </div>
                    </div>
                  )}

                  <div className="w-100 py-2 d-flex align-items-center f-black">
                    <div className="w-50">Tax Rate</div>
                    <div className="w-50 d-flex align-items-center justify-content-between">
                      <div className="tax-rate-dropdown-container">
                        <TaxRateDropdown
                          deleteTaxRate={deleteTaxRate}
                          updateTaxList={updateTaxList}
                          onChange={(data) => {
                            setChanged(true);
                            setTableLoader(true);
                            setSelectedTax(data);
                          }}
                          taxList={taxList}
                          selected={selectedTax}
                          onClickEditTaxRate={(data) => {
                            setTaxRateData(data);
                            setShowAddEditTaxRateModal(true);
                          }}
                          onClickAddTaxRate={() => {
                            setTaxRateData({
                              ObjectId: 0,
                              TaxCode: "",
                              TaxName: "",
                              TaxRate: "",
                            });
                            setShowAddEditTaxRateModal(true);
                          }}
                        />
                      </div>
                      <div
                        className={`add-edit-tax-rate-modal-wrapper ${showAddEditTaxRateModal ? "show mt-187px" : ""
                          }`}
                      >
                        <AddEditTaxRateModal
                          show={showAddEditTaxRateModal}
                          hideModal={() => {
                            setShowAddEditTaxRateModal(false);
                          }}
                          taxRateData={taxRateData}
                          updateTaxList={updateTaxList}
                          deleteTaxRate={deleteTaxRate}
                        />
                      </div>
                      {/* <div className="d-flex align-items-center radius-4 f-grey  p-2  mr-1">
                        {null ?? 0}% - {userSelectedCurrency?.CurrencySymbol}{" "}
                        {Number(
                          ((null ?? 0) * (null ?? 0)) / 100 ?? 0
                        )?.toFixed(2)}
                      
                      </div> */}
                    </div>
                  </div>
                </div>
                {/* {exchangeRateData?.FromCurrency &&
                  exchangeRateData?.FromCurrency !== "GBP" && (
                    <div className="w-100 text-center f-12 f-400 f-darkgrey mb-3">
                      <img
                        alt=""
                        width={13}
                        height={13}
                        src="/images/msp/exchange-rate-icon.svg"
                        className="mr-2"
                      />{" "}
                      The exchange rate on{" "}
                      {moment(exchangeRateData?.Date ?? "")?.format(
                        "DD MMM YYYY"
                      )}{" "}
                      is 1 {exchangeRateData?.FromCurrency} ={" "}
                      {exchangeRateData?.Rate} GBP
                    </div>
                  )} */}
              </div>

              {/* ---------------Seats and Assets------------------- */}

              <hr />
              <div className="f-black f-15 mt-1 mb-2 f-500">
                Services
              </div>

              <>
                <div className="w-100 bg-grey border-parent radius-8">
                  <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="services-list">
                      {(provided) => (
                        <ul {...provided.droppableProps} ref={provided.innerRef}>
                          {selectedServices.map((serviceItem, serviceItemIndex) => {
                            const draggableId = `draggable-${serviceItem.objId_SecurityPack}`;

                            return (
                              <Draggable
                                id={`draggable-${serviceItem.objId_SecurityPack}`}
                                key={serviceItem.objId_SecurityPack}
                                draggableId={draggableId}
                                index={serviceItemIndex}
                              >
                                {(provided, snapshot) => (
                                  <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    className="border-item d-flex align-items-center justify-content-between p-3 pointer bg-hover-dark-grey hover-on-show-parent"
                                  >
                                    <div
                                      className="d-flex align-items-center w-100"
                                      onClick={() =>
                                        addEditServiceByModal(serviceItem.objId_SecurityPack)
                                      }
                                    >
                                      <img
                                        alt=""
                                        className="mr-2"
                                        width={18}
                                        src={
                                          isHovered ==
                                            serviceItem?.objId_SecurityPack ||
                                            snapshot.isDragging
                                            ? "/images/supply-chain/drag-n-drop.svg" :
                                            serviceItem?.IsAddOn
                                              ? "/images/msp/msp-add-on-icon.svg"
                                              : "/images/msp/msp-package-icon.svg"
                                        }
                                      />
                                      <div className="d-flex flex-column">
                                        <div className="f-14 f-500 f-black">
                                          {serviceItem.Headline}
                                        </div>
                                        <div className="f-12 f-darkgrey">
                                          {serviceItem.IsAddOn ? "Add-on" : "Service"} • Cost •{" "}
                                          {serviceItem.AnnualPrice_Text} • Sell •{" "}
                                          {serviceItem.MonthlyPrice_Text}
                                          <span className="f-12 txt-blue">
                                            {serviceItem.IsOnDemand ? " • POD" : ""}
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className="d-flex align-items-center justify-content-center hover-on-show-child pointer"
                                      style={{ width: "26px", height: "26px" }}
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        setChanged(true);
                                        updateSelectedServices(serviceItem);
                                        let updated_page_data = { ...pageData };
                                        setPageData(updated_page_data);
                                        add_or_delete_service_addOn(
                                          0,
                                          serviceItem.objId_SecurityPack
                                        )
                                          .then(() => {
                                            setServicesLoader(true);
                                            getDropdownDataForService();
                                          })
                                          .catch(() => { });
                                      }}
                                    >
                                      <img
                                        alt=""
                                        className=""
                                        width={12}
                                        height={12}
                                        src="/images/attack-surface/cross-icon.svg"
                                      />
                                    </div>
                                  </div>
                                )}
                              </Draggable>
                            );
                          })}
                          {provided.placeholder}
                        </ul>
                      )}
                    </Droppable>
                  </DragDropContext>

                  {showDropdownVisibilityArray/*[serviceDataIndex]*/ ===
                    true && (
                      <ServiceDropdown
                        serviceSearchString={serviceSearchString}
                        setServiceSearchString={setServiceSearchString}
                        data={
                          pageData?.service_dropdown_list/*[
                                      serviceDataIndex
                                    ]*/
                        }
                        _updateSelectedServices={
                          updateSelectedServices
                        }
                        selectedData={
                          selectedServices/*[serviceDataIndex]*/
                        }
                        // serviceEnum={serviceDataIndex}  
                        _setSelectedServices={setSelectedServices}
                        _setIsAddonService={setIsAddOnService}
                        _setnistPillarType={setnistPillarType}
                        _isToggleShows={
                          showDropdownVisibilityArray/*[
                                      serviceDataIndex
                                    ]*/
                        }
                        isLoading={servicesLoader}
                        _inputRef={inputRef}
                        onToggleItem={(childIndex) => {
                          setChanged(true);
                          setShowDropdownVisibilityArray(false)

                          // let updated_page_data = { ...pageData };
                          // let clicked_item =
                          //   updated_page_data.service_dropdown_list[childIndex];
                          // updated_page_data.service_dropdown_list[childIndex].loading = true;

                          let updated_page_data = { ...pageData };
                          let filtered_service_dropdown_list = updated_page_data?.service_dropdown_list?.filter(item =>
                            item?.Headline?.toLowerCase()?.includes(serviceSearchString?.toLowerCase())
                          );

                          let clicked_item = filtered_service_dropdown_list[childIndex];
                          if (clicked_item) {

                            let originalIndex = updated_page_data?.service_dropdown_list?.findIndex(item =>
                              item?.objId_SecurityPack === clicked_item?.objId_SecurityPack
                            );

                            if (originalIndex !== -1) {
                              updated_page_data.service_dropdown_list[originalIndex].loading = true;
                            }
                          }
                          if (clicked_item.Selected) {
                            add_or_delete_service_addOn(0, clicked_item.objId_SecurityPack, true)
                              .then(() => {
                                setTableLoader(true);
                                getPackageDetails(
                                  Number(scenarioId),
                                  clientId,
                                  userAndDeviceCountChanged
                                );
                              })
                              .catch(() => {
                              });
                          } else {
                            console.log('clicked_item --- > ', clicked_item)
                            console.log('clicked_item.objId_SecurityPack -- ', clicked_item.objId_SecurityPack)
                            add_or_delete_service_addOn(clicked_item.objId_SecurityPack, 0, true)
                              .then(() => {
                                setTableLoader(true);
                                getPackageDetails(
                                  Number(scenarioId),
                                  clientId,
                                  userAndDeviceCountChanged
                                );
                              })
                              .catch(() => {
                              });
                          }
                          setPageData(updated_page_data);
                        }}
                        onClickAdd={(/*serviceEnum*/) => {
                          // addEditServiceByModal(
                          //   0,
                          //   services_enums_selected[serviceDataIndex]   
                          // );
                          // let updated_data = [
                          //   ...showDropdownVisibilityArray,
                          // ];
                          // updated_data[serviceDataIndex] = false;

                          setShowDropdownVisibilityArray(false);/*(
                                      updated_data
                                    );*/

                          // let updated_page_data = { ...pageData };

                          // getPackageDetails(Number(scenarioId)).then(
                          //   () => {
                          //     getDropdownDataForService(
                          //       serviceDataIndex
                          //     );
                          //   }
                          // );
                          // setPageData(updated_page_data);
                        }}
                        onClose={() => {
                          // let updated_data = [
                          // ...showDropdownVisibilityArray,
                          // ];
                          // updated_data[serviceDataIndex] = false;

                          setShowDropdownVisibilityArray(false)/*(
                                      updated_data
                                    );*/
                        }}
                      />
                    )}
                </div>


                <div className="mt-3 ">
                  {showDropdownVisibilityArray/*[serviceDataIndex]*/ !==
                    true && (
                      <AddButtonForPackage
                        onClick={() => {
                          // let updated_data = [
                          //   false,
                          //   false,
                          //   false,
                          //   false,
                          //   false,
                          //   // ...showDropdownVisibilityArray,
                          // ];
                          // let updated_data_loader = servicesLoader;
                          // updated_data[serviceDataIndex] = true;
                          // updated_data_loader[serviceDataIndex] = true;
                          setServicesLoader(true);//(updated_data_loader);
                          getDropdownDataForService();//(serviceDataIndex);

                          setShowDropdownVisibilityArray(true);//(updated_data);
                          getServiceList(1);
                        }}
                      />
                    )}
                </div>
              </>
            </div>

            {/* Right SIDE */}
            <div
              className="px-5 w-100 "
              style={{
                minWidth: "600px",
                maxWidth: "600px",
              }}
            >
              <div
                className=" m-auto w-fit-content mt-4"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <div
                  style={{
                    width: "458px",
                  }}
                >
                  <MspRadar data={data} options={_options} />
                </div>
              </div>

              <div
                className="d-flex flex-row mb-3"
                style={{ justifyContent: "center" }}
              >
                <div
                  className="bg-grey-imp greyText2 p-2 pointer mr-1"
                  onClick={() => {
                    setShowMspScoreModal(true);
                  }}
                >
                  View Alignment to NIST CSF
                </div>
                <div
                  className="bg-grey-imp greyText2 p-2 pointer"
                  onClick={() => {
                    setShowMspFeaturesModal(true);
                  }}
                >
                  View Features
                </div>
              </div>

              {pageData?.response?.ScenarioHeader && (
                <div className="w-100 point-row">
                  <div
                    className="w-100 d-flex align-items-center justify-content-between pointer py-2"
                  // onClick={(e) => {
                  //   ToggleAccordion(e);
                  // }}
                  // onLoad={(e) => {
                  //   ToggleAccordion(e);
                  // }}
                  >
                    <div className="d-flex align-items-center f-500 f-15">
                      <div
                        className="rounded-full mx-2"
                        style={{
                          width: "16px",
                          height: "16px",
                          backgroundColor: "#008fd4",
                        }}
                      />
                      {newPackageName}
                    </div>
                    <img alt="" className="" src="/images/chevron-down.svg" />
                  </div>

                  <div className="">
                    <div className="f-darkgrey f-12 f-500 pl-4 py-2">
                      Predicted scores
                    </div>
                    <div className="d-flex">
                      <CyberComplianceGauge
                        value={
                          pageData?.response?.ScenarioHeader?.CyberCompliance
                        }
                      />

                      <CyberScoreGauge
                        value={pageData?.response?.ScenarioHeader?.CyberScore}
                      />
                    </div>

                    {/* Quantity of Assets */}
                    {/* {pageData?.response?.ScenarioHeader?.IsCustomPackage ? ( */}
                    {isCustom ? (
                      <div className="bg-grey radius-8 point-row p-3 mt-4">
                        <div
                          className="d-flex align-items-center justify-content-between pointer"
                          onClick={ToggleAccordion}
                          // onClick={(e) => ToggleAccordion(e)}
                          id="quantyAssetOnDashboard"
                        >
                          <div>
                            <div className="f-500f-14 f-black">
                              Quantity of Assets
                            </div>
                            <div className="f-14 f-darkgrey">
                              {preDataState
                                ? `Provide us with how many users, workstations/laptops and servers
              you have in your organisation.`
                                : TrimString(
                                  tempUserDetailsData
                                    ?.map(
                                      (item) =>
                                        `${item.Name?.replace("Per ", "")}: ${item?.Qty
                                        }`
                                    )
                                    ?.join(", "),
                                  65
                                )}
                            </div>
                          </div>
                          <div>
                            <img
                              alt=""
                              src="/images/calculator/edit.svg"
                              className="ml-3 mr-2"
                            />
                          </div>
                        </div>
                        <div
                          className="panel-opened"
                          style={{
                            maxHeight: isFirstOpen && "400px",
                          }}
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                        >
                          <div
                            className="d-flex flex-wrap   msp_assets_inputs_wrapper"
                            style={{
                              pointerEvents: "auto",
                            }}
                          >
                            {tempUserDetailsData?.map((item) => {
                              return (
                                <div
                                  className={`highlight-input-with-grey-border d-flex align-items-center       p-2 radius-4 mt-2   ${false ? "input-error" : ""
                                    } `}
                                >
                                  <div className="f-darkgrey flex-shrink-0">
                                    {TrimString(
                                      item?.Name?.replace("Per ", ""),
                                      13
                                    )}
                                    :
                                  </div>

                                  <input
                                    tabIndex={0}
                                    type="number"
                                    placeholder="0"
                                    className=" pl-1 pr-2"
                                    value={item?.Qty}
                                    onChange={(e) => {
                                      const updatedData =
                                        tempUserDetailsData.map((dataItem) => {
                                          if (
                                            dataItem.ObjectId === item.ObjectId
                                          ) {
                                            return {
                                              ...dataItem,
                                              Qty: e.target.value?.trim(),
                                            };
                                          }
                                          return dataItem;
                                        });
                                      setTempUserDetailsData(updatedData);
                                    }}
                                  />
                                </div>
                              );
                            })}
                          </div>
                          <div className="d-flex align-items-center mt-2">
                            <Button
                              className="hg-blue-btn mr-2"
                              onClick={(e) => {
                                saveAssets(e);
                              }}
                            >
                              {savingAssets ? "Saving.." : "Save"}
                            </Button>
                            <Button
                              className="hg-cancel-btn"
                              onClick={(e) => {
                                ToggleAccordion(e);
                                setTempUserDetailsData(
                                  defaultUserDeviceDetails
                                );
                              }}
                            >
                              Cancel
                            </Button>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}

                    {/* {billedByStatus?.DDLText === "Seats" && (
                      <div>

                        {isCustom ? (
                          <div>
                            <div className="point-row-4">
                              <div
                                className="d-flex align-items-center justify-content-between pointer py-2"
                                style={{ height: "30px" }}
                                onClick={(e) => {
                                  ToggleAccordionBlocks(e, 4);
                                }}
                              >
                                <div className="f-12 f-darkgrey d-flex align-items-center mt-2">
                                  Price Breakdown
                                  {tableLoader ? (
                                    <div className="msp-service-update-loader ml-2 mr-1"></div>
                                  ) : (
                                    <></>
                                  )}
                                </div>
                                <img
                                  alt=""
                                  id="chevron"
                                  className="chevron ml-2"
                                  src="/images/attack-surface/chevron-up.svg"
                                />
                              </div>

                              <div
                                className="panel-opened"
                                style={{
                                  maxHeight:
                                    isFirstOpenBlocks[4] === true && "800px",
                                }}
                                onClick={(e) => {
                                  e.stopPropagation();
                                }}
                              >

                                <div
                                  className="d-flex align-items-center justify-content-between mb-2  bg-grey radius-8"
                                  style={{
                                    height: "46px",
                                  }}
                                >
                                  <div className="f-14 f-600 pl-3">
                                    Req. Seats
                                  </div>
                                  <div className="pr-5">
                                    {
                                      pageData?.response?.MSPPackagePrice
                                        ?.RequiredSeatsOverriden
                                    }{" "}
                                  </div>
                                </div>

                                <div className="border-parent  radius-8">
                                  <div className="per_asset_period-breakdown_table border-item f-grey">
                                    <div className="f-12 f-500 f-darkgrey  pl-2">
                                      Asset Type
                                    </div>
                                    <div className="f-12 f-500 f-darkgrey">
                                      Inc. per Seat
                                    </div>
                                    <div className="f-12 f-500 f-darkgrey">
                                      Client Qty
                                    </div>
                                    <div className="f-12 f-500 f-darkgrey">
                                      Seats Req
                                    </div>
                                  </div>
                                  {pageData?.response?.MSPPackagePrice?.PackagePerSeat_List?.map(
                                    (data, dataIndex) => {
                                      return (
                                        <div className="per_asset_period-breakdown_table  border-item">
                                          <div className="f-500  pl-2">
                                            {data.Name}{" "}
                                          </div>
                                          <div className="f-500 f-grey">
                                            {data.PerSeat}{" "}
                                          </div>
                                          <div className="f-500 f-grey">
                                            {data.ClientQty}{" "}
                                          </div>
                                          <div className="f-600 f-grey">
                                            {data.SeatRequired}{" "}
                                          </div>
                                        </div>
                                      );
                                    }
                                  )}
                                </div>
                              </div>
                            </div>

                            <div className="point-row-5">
                              <div
                                className="d-flex align-items-center justify-content-between pointer "
                                onClick={(e) => {
                                  ToggleAccordionBlocks(e, 5);
                                }}
                              >
                                <div className=" f-12 f-500 f-darkgrey my-2 mb-3 d-flex align-items-center">
                                  Unit Pricing
                                  {tableLoader ? (
                                    <div className="msp-service-update-loader ml-2 mr-1"></div>
                                  ) : (
                                    <></>
                                  )}
                                </div>
                                <img
                                  alt=""
                                  id="chevron"
                                  className="chevron ml-2"
                                  src="/images/attack-surface/chevron-up.svg"
                                />
                              </div>

                              <div
                                className="panel-opened"
                                style={{
                                  maxHeight:
                                    isFirstOpenBlocks[5] === true && "102px",
                                }}
                                onClick={(e) => {
                                  e.stopPropagation();
                                }}
                              >
                                <div className="d-flex w-100 mb-3">
                                  <div className="w-50">
                                    <div className="d-flex f-500">
                                      Billing period
                                      <div className="msp-service-tool-tip-parent w-fit-content">
                                        <img
                                          className="ml-2 pointer"
                                          src="/images/msp/ques-info-icon.svg"
                                          alt=""
                                        />
                                      </div>
                                    </div>
                                  </div>

                                  <div className="w-50 " style={{}}>
                                    <div className="w-fit-content f-darkgrey">
                                      {
                                        pageData?.response?.ScenarioHeader
                                          ?.BillingPeriod_Selected?.DDLText
                                      }
                                    </div>
                                  </div>
                                </div>

                                <div className="d-flex w-100 mb-3">
                                  <div className="w-50">
                                    <div className="d-flex f-500">
                                      Seats Required
                                    </div>
                                  </div>

                                  <div className="w-50 " style={{}}>
                                    <div className="w-fit-content">
                                      {
                                        pageData?.response?.MSPPackagePrice
                                          ?.RequiredSeatsOverriden
                                      }
                                    </div>
                                  </div>
                                </div>

                                <div className="d-flex w-100 mb-3">
                                  <div className="w-50">
                                    <div className="d-flex f-500">
                                      Seat Price
                                    </div>
                                  </div>

                                  <div
                                    className="w-50 d-flex align-items-center justify-content-between"
                                    style={{}}
                                  >
                                    <div className="pr-5">
                                      {renderPriceText(
                                        pageData?.response?.ScenarioHeader
                                          ?.BillingPeriod_Selected?.DDLSort,
                                        pageData?.response?.MSPPackagePrice?.TotalSell?.toFixed(
                                          2
                                        ),
                                        pageData?.response
                                          ?.CurrencyCode_Selected
                                          ?.CurrencySymbol ??
                                        defaultCurrency?.CurrencySymbol ??
                                        "£"
                                      )}{" "}
                                    </div>

                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="point-row-6">
                              <div
                                className="d-flex align-items-center justify-content-between pointer"
                                onClick={(e) => {
                                  ToggleAccordionBlocks(e, 6);
                                }}
                              >
                                <div className="my-2 f-12 f-darkgrey d-flex align-items-center">
                                  Summary
                                  {tableLoader ? (
                                    <div className="msp-service-update-loader ml-2 mr-1"></div>
                                  ) : (
                                    <></>
                                  )}
                                </div>
                                <img
                                  alt=""
                                  id="chevron"
                                  className="chevron ml-2"
                                  src="/images/attack-surface/chevron-up.svg"
                                />
                              </div>

                              <div
                                className="border-parent  radius-8 panel-opened"
                                style={{
                                  maxHeight:
                                    isFirstOpenBlocks[6] === true && "126px",
                                }}
                                onClick={(e) => {
                                  e.stopPropagation();
                                }}
                              >
                                <div className="per_asset_total_pricing_table-updated border-item">
                                  <div className="f-500  pl-2">
                                    Package Price
                                  </div>
                                  <div></div>
                                  <div className="f-500 f-grey">
                                    {pageData?.response?.CurrencyCode_Selected
                                      ?.CurrencySymbol ??
                                      defaultCurrency?.CurrencySymbol ??
                                      "£"}
                                    {numberWithCommas(
                                      pageData?.response?.MSPPackagePrice?.Subtotal?.toFixed(
                                        2
                                      )
                                    )}
                                  </div>
                                </div>

                                <div className="per_asset_total_pricing_table-updated  border-item">
                                  <div className="f-500 pl-2">
                                    {`
                                  Tax @ ${pageData?.response?.TaxRates_Selected?.TaxRate}% (${pageData?.response?.TaxName})
                                ` ?? "Tax @ 0% (No Taxable)"}
                                  </div>
                                  <div className="f-500 f-grey">
                                    {pageData?.response?.CurrencyCode_Selected
                                      ?.CurrencySymbol ??
                                      defaultCurrency?.CurrencySymbol ??
                                      "£"}
                                    {pageData?.response?.MSPPackagePrice?.Tax?.toFixed(
                                      2
                                    )}
                                  </div>

                                  <div className="f-500 f-grey">
                                    {pageData?.response?.CurrencyCode_Selected
                                      ?.CurrencySymbol ?? "£"}
                                    {numberWithCommas(
                                      (
                                        pageData?.response?.MSPPackagePrice
                                          ?.Subtotal +
                                        pageData?.response?.MSPPackagePrice?.Tax
                                      ).toFixed(2)
                                    )}
                                  </div>
                                </div>

                                <div className="per_asset_total_pricing_table-updated  border-item">
                                  <div className="f-600  pl-2">Total</div>
                                  <div></div>
                                  <div className="f-600 f-grey">
                                    {renderPriceText(
                                      pageData?.response?.ScenarioHeader
                                        ?.BillingPeriod_Selected?.DDLSort,
                                      totalCost,
                                      pageData?.response?.CurrencyCode_Selected
                                        ?.CurrencySymbol ??
                                      defaultCurrency?.CurrencySymbol ??
                                      "£"
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <></>
                        )}

                        <div className="point-row-3">
                          <div
                            className="d-flex align-items-center justify-content-between pointer"
                            onClick={(e) => {
                              ToggleAccordionBlocks(e, 3);
                            }}
                          >
                            <div
                              className={`my-2 f-12 f-darkgrey d-flex align-items-center ${
                                // !pageData?.response?.ScenarioHeader
                                //   ?.IsCustomPackage && "mt-4"
                                !isCustom && "mt-4"
                                }`}
                            >
                              Breakdown by period
                              {tableLoader ? (
                                <div className="msp-service-update-loader ml-2 mr-1"></div>
                              ) : (
                                <></>
                              )}
                            </div>
                            <img
                              alt=""
                              id="chevron"
                              className="chevron"
                              src="/images/attack-surface/chevron-up.svg"
                            />
                          </div>
                          <div
                            className="border-parent  radius-8 panel-opened"
                            style={{
                              maxHeight:
                                isFirstOpenBlocks[3] === true && "236px",
                            }}
                            onClick={(e) => {
                              e.stopPropagation();
                            }}
                          >
                            <div className="per_asset_period-breakdown_table border-item f-grey">
                              <div className="  pl-2">Period</div>
                              <div>Cost</div>
                              <div>Sell</div>
                              <div>Margin</div>
                            </div>
                            {packageData?.MSPPackagePrice?.PackagePeriodBreakdown_List?.map(
                              (data, index) => {
                                return (
                                  <div className="per_asset_period-breakdown_table  border-item">
                                    <div className="f-500  pl-2">
                                      {data?.Name}
                                    </div>
                                    <OverlayTrigger
                                      placement="right"
                                      trigger={
                                        exchangeRateData?.FromCurrency &&
                                          exchangeRateData?.FromCurrency !==
                                          defaultCurrency?.CurrencyName
                                          ? ["hover", "focus"]
                                          : []
                                      }
                                      delay={{ show: 200, hide: 250 }}
                                      overlay={
                                        <Tooltip
                                          id={`tooltip-1365`}
                                          className="custom_tooltip_access"
                                        >
                                          <div>
                                            {defaultCurrency?.CurrencySymbol ??
                                              "£"}{" "}
                                            {data.Cost?.toFixed(2)}
                                          </div>
                                          <div className="f-darkgrey">
                                            <img
                                              alt=""
                                              width={13}
                                              height={13}
                                              src="/images/msp/exchange-rate-icon.svg"
                                              className="mr-2"
                                            />
                                            {
                                              userSelectedCurrency?.CurrencySymbol
                                            }{" "}
                                            {(
                                              data.Cost * exchangeRateData?.Rate
                                            )?.toFixed(2)}
                                          </div>
                                        </Tooltip>
                                      }
                                    >
                                      <div className="f-500 f-grey">
                                        {renderPriceText(
                                          99,
                                          (
                                            data?.Cost *
                                            (exchangeRateData?.Rate ?? 1)
                                          )?.toFixed(2),
                                          userSelectedCurrency?.CurrencySymbol
                                        )}
                                      </div>
                                    </OverlayTrigger>
                                    <OverlayTrigger
                                      placement="right"
                                      trigger={
                                        exchangeRateData?.FromCurrency &&
                                          exchangeRateData?.FromCurrency !==
                                          defaultCurrency?.CurrencyName
                                          ? ["hover", "focus"]
                                          : []
                                      }
                                      delay={{ show: 200, hide: 250 }}
                                      overlay={
                                        <Tooltip
                                          id={`tooltip-1365`}
                                          className="custom_tooltip_access"
                                        >
                                          <div>
                                            {defaultCurrency?.CurrencySymbol ??
                                              "£"}{" "}
                                            {data.Sell?.toFixed(2)}
                                          </div>
                                          <div className="f-darkgrey">
                                            <img
                                              alt=""
                                              width={13}
                                              height={13}
                                              src="/images/msp/exchange-rate-icon.svg"
                                              className="mr-2"
                                            />

                                            {
                                              userSelectedCurrency?.CurrencySymbol
                                            }{" "}
                                            {(
                                              data.Sell * exchangeRateData?.Rate
                                            )?.toFixed(2)}
                                          </div>
                                        </Tooltip>
                                      }
                                    >
                                      <div className="f-500 f-grey">
                                        {renderPriceText(
                                          99,
                                          (
                                            data?.Sell *
                                            (exchangeRateData?.Rate ?? 1)
                                          )?.toFixed(2),
                                          userSelectedCurrency?.CurrencySymbol
                                        )}
                                      </div>
                                    </OverlayTrigger>
                                    <OverlayTrigger
                                      placement="right"
                                      trigger={
                                        exchangeRateData?.FromCurrency &&
                                          exchangeRateData?.FromCurrency !==
                                          defaultCurrency?.CurrencyName
                                          ? ["hover", "focus"]
                                          : []
                                      }
                                      delay={{ show: 200, hide: 250 }}
                                      overlay={
                                        <Tooltip
                                          id={`tooltip-1365`}
                                          className="custom_tooltip_access"
                                        >
                                          <div>
                                            {defaultCurrency?.CurrencySymbol ??
                                              "£"}{" "}
                                            {data.Margin?.toFixed(2)}
                                          </div>
                                          <div className="f-darkgrey">
                                            <img
                                              alt=""
                                              width={13}
                                              height={13}
                                              src="/images/msp/exchange-rate-icon.svg"
                                              className="mr-2"
                                            />
                                            {
                                              userSelectedCurrency?.CurrencySymbol
                                            }{" "}
                                            {(
                                              data.Margin *
                                              exchangeRateData?.Rate
                                            )?.toFixed(2)}
                                          </div>
                                        </Tooltip>
                                      }
                                    >
                                      <div className="f-500 f-grey">
                                        {renderPriceText(
                                          99,
                                          (
                                            data?.Margin *
                                            (exchangeRateData?.Rate ?? 1)
                                          )?.toFixed(2),
                                          userSelectedCurrency?.CurrencySymbol
                                        )}
                                      </div>
                                    </OverlayTrigger>
                                  </div>
                                );
                              }
                            )}
                            {
                              selectedServices?.some(item => item?.IsOnDemand === true) ?
                                <div className="px-2 py-3">
                                  <div className="d-flex align-items-center">
                                    <img
                                      alt=""
                                      width={13}
                                      height={13}
                                      src="/images/info-blue.svg"
                                      className="mr-2"
                                    />
                                    <div className="f-500 f-14 txt-blue">Price On-Demand</div>
                                  </div>
                                  <div className="f-400 f-12 f-darkgrey">This package includes services which are priced on demand</div>
                                </div> : <></>
                            }
                          </div>
                        </div>
                        <div
                          className="mt-2"
                          style={{
                            display: "grid",
                            gridTemplateColumns: "83% 10%",
                          }}
                        >
                          <div>
                            {exchangeRateData?.FromCurrency &&
                              exchangeRateData?.FromCurrency !==
                              defaultCurrency?.CurrencyName && (
                                <div className="w-100 text-center f-12 f-400 f-darkgrey mb-3">
                                  <img
                                    alt=""
                                    width={13}
                                    height={13}
                                    src="/images/msp/exchange-rate-icon.svg"
                                    className="mr-2"
                                  />{" "}
                                  The exchange rate on{" "}
                                  {moment(
                                    updatedExchangeRateData?.Date ?? ""
                                  )?.format("DD MMM YYYY")}{" "}
                                  is 1 {updatedExchangeRateData?.FromCurrency} ={" "}
                                  {updatedExchangeRateData?.Rate}{" "}
                                  {defaultCurrency?.CurrencyName}
                                </div>
                              )}
                            {exchangeRateData?.FromCurrency &&
                              exchangeRateData?.FromCurrency !==
                              defaultCurrency?.CurrencyName && (
                                <div className="w-100 text-center f-12 f-400 f-darkgrey mb-3">
                                  <img
                                    alt=""
                                    width={13}
                                    height={13}
                                    src="/images/msp/exchange-rate-icon.svg"
                                    className="mr-2"
                                  />{" "}
                                  The exchange rate when this package was
                                  created (
                                  {moment(exchangeRateData?.Date ?? "")?.format(
                                    "DD MMM YYYY"
                                  )}
                                  ) was 1 {exchangeRateData?.FromCurrency} ={" "}
                                  {exchangeRateData?.Rate}{" "}
                                  {defaultCurrency?.CurrencyName}
                                </div>
                              )}
                          </div>

                          {exchangeRateData?.FromCurrency &&
                            exchangeRateData?.FromCurrency !==
                            defaultCurrency?.CurrencyName && (
                              <div
                                className="f-blue pointer"
                                onClick={() => {
                                  updatePackageExchangeRateData(
                                    exchangeRateData?.Rate,
                                    scenarioId
                                  );
                                }}
                              >
                                Update
                              </div>
                            )}
                        </div>
                      </div>
                    )} */}

                    <div className="d-flex align-items-center w-100 hg-border-bottom mt-3">
                      <div
                        className={`f-500 f-14 px-3 py-2 mt-2 ${selectedTab == 0 ? 'f-blue hg-border-bottom-blue' : 'f-grey pointer'}`}
                        onClick={() => {
                          setSelectedTab(0);
                        }}
                      >
                        Pricing
                      </div>
                      <div
                        className={`f-500 f-14 px-3 py-2 mt-2 ${selectedTab == 1 ? 'f-blue hg-border-bottom-blue' : 'f-grey pointer'}`}
                        onClick={() => {
                          setSelectedTab(1);
                        }}
                      >
                        Features
                      </div>
                      <div
                        className={`f-500 f-14 px-3 py-2 mt-2 ${selectedTab == 2 ? 'f-blue hg-border-bottom-blue' : 'f-grey pointer'}`}
                        onClick={() => {
                          setSelectedTab(2);
                        }}
                      >
                        Components
                      </div>
                    </div>

                    {
                      selectedTab == 0 ? <div>
                        {billedByStatus?.DDLText === "Assets" && (
                          <div>
                            {/* Price breakdown table */}
                            <div className="point-row-0">
                              <div
                                className="w-100 d-flex align-items-center justify-content-between pointer p-2 bg-hov er-grey radius-4 mt-2"
                                onClick={(e) => {
                                  ToggleAccordionBlocks(e, 0);
                                }}
                              >
                                <div className="d-flex align-items-center f-500 f-12 f-darkgrey">
                                  Per Asset Breakdown
                                  {tableLoader ? (
                                    <div className="msp-service-update-loader ml-2 mr-1"></div>
                                  ) : (
                                    <></>
                                  )}
                                </div>
                                <img
                                  alt=""
                                  id="chevron"
                                  className="chevron ml-2"
                                  src="/images/attack-surface/chevron-up.svg"
                                />
                              </div>
                              <div
                                className="border-parent  radius-8 panel-opened"
                                style={{
                                  maxHeight:
                                    isFirstOpenBlocks[0] === true && "1500px",
                                }}
                                onClick={(e) => {
                                  e.stopPropagation();
                                }}
                              >
                                <div
                                  className={`border-item f-12 f-500 f-darkgrey p-2 ${
                                    // pageData?.response?.ScenarioHeader
                                    //   ?.IsCustomPackage
                                    isCustom
                                      ? " per_asset_price_breakdown_table "
                                      : " standard_per_asset_price_breakdown_table "
                                    }`}
                                >
                                  <div>Asset Type</div>
                                  <div>Qty </div>
                                  <div>Unit Cost</div>
                                  <div>Unit RRP</div>
                                  <div>Unit Sell</div>
                                  {/* {pageData?.response?.ScenarioHeader
                                    ?.IsCustomPackage && <div>Line Total</div>} */}
                                  {isCustom && <div>Line Total</div>}
                                </div>
                                {packageData?.MSPPackagePrice?.PackagePerAsset_List?.filter(
                                  (item) => item.UnitCost !== 0
                                )?.map((data, dataIndex) => {
                                  return (
                                    <div
                                      className={`border-item f-grey p-2 ${
                                        // pageData?.response?.ScenarioHeader
                                        //   ?.IsCustomPackage
                                        isCustom
                                          ? " per_asset_price_breakdown_table "
                                          : " standard_per_asset_price_breakdown_table "
                                        }`}
                                    >
                                      <div className="f-black f-500">
                                        {data.Name}
                                      </div>
                                      <div>
                                        {editState && false ? (
                                          <>
                                            <div className="highlight-input-with-grey-border p-2 mr-3 overflow-hidden">
                                              <input
                                                type="number"
                                                className="border-none bg-transparent overflow-hidden"
                                                value={data.Qty}
                                                onChange={(e) => {
                                                  // setChanged(true);
                                                  let updated_data = {
                                                    ...packageData,
                                                  };
                                                  updated_data.MSPPackagePrice.PackagePerAsset_List[
                                                    dataIndex
                                                  ].IsEditedQty = true;
                                                  updated_data.MSPPackagePrice.PackagePerAsset_List[
                                                    dataIndex
                                                  ].Qty = e.target.value;
                                                  setPackageData(updated_data);
                                                }}
                                              />
                                            </div>
                                          </>
                                        ) : (
                                          <>
                                            {data.IsEditedQty ? (
                                              <div className="f-blue position-relative">
                                                <span className="blue-dot" />
                                                {data.Qty}
                                              </div>
                                            ) : (
                                              <div>{data.Qty} </div>
                                            )}
                                          </>
                                        )}
                                      </div>
                                      <div>
                                        <OverlayTrigger
                                          placement="right"
                                          trigger={
                                            exchangeRateData?.FromCurrency &&
                                              exchangeRateData?.FromCurrency !==
                                              defaultCurrency?.CurrencyName
                                              ? ["hover", "focus"]
                                              : []
                                          }
                                          delay={{ show: 200, hide: 250 }}
                                          overlay={
                                            <Tooltip
                                              id={`tooltip-1365`}
                                              className="custom_tooltip_access"
                                            >
                                              <div>
                                                {defaultCurrency?.CurrencySymbol ??
                                                  "£"}{" "}
                                                {data.UnitCost?.toFixed(2)}
                                              </div>
                                              <div className="f-darkgrey">
                                                <img
                                                  alt=""
                                                  width={13}
                                                  height={13}
                                                  src="/images/msp/exchange-rate-icon.svg"
                                                  className="mr-2"
                                                />
                                                {/* {
                                                        userSelectedCurrency?.CurrencySymbol
                                                      } */}
                                                {
                                                  userSelectedCurrency?.CurrencySymbol
                                                }{" "}
                                                {(
                                                  data.UnitCost *
                                                  exchangeRateData?.Rate
                                                )?.toFixed(2)}
                                              </div>
                                            </Tooltip>
                                          }
                                        >
                                          <div>
                                            {userSelectedCurrency?.CurrencySymbol}
                                            {(
                                              data?.UnitCost *
                                              (exchangeRateData?.Rate ?? 1)
                                            )?.toFixed(2)}
                                          </div>
                                        </OverlayTrigger>
                                      </div>
                                      <div>
                                        <OverlayTrigger
                                          placement="right"
                                          trigger={
                                            exchangeRateData?.FromCurrency &&
                                              exchangeRateData?.FromCurrency !==
                                              defaultCurrency?.CurrencyName
                                              ? ["hover", "focus"]
                                              : []
                                          }
                                          delay={{ show: 200, hide: 250 }}
                                          overlay={
                                            <Tooltip
                                              id={`tooltip-1365`}
                                              className="custom_tooltip_access"
                                            >
                                              <div>
                                                {defaultCurrency?.CurrencySymbol ??
                                                  "£"}{" "}
                                                {data.UnitRRP?.toFixed(2)}
                                              </div>
                                              <div className="f-darkgrey">
                                                <img
                                                  alt=""
                                                  width={13}
                                                  height={13}
                                                  src="/images/msp/exchange-rate-icon.svg"
                                                  className="mr-2"
                                                />
                                                {
                                                  userSelectedCurrency?.CurrencySymbol
                                                }{" "}
                                                {(
                                                  data?.UnitRRP *
                                                  (exchangeRateData?.Rate ?? 1)
                                                )?.toFixed(2)}
                                              </div>
                                            </Tooltip>
                                          }
                                        >
                                          <div>
                                            {userSelectedCurrency?.CurrencySymbol}
                                            {(
                                              data?.UnitRRP *
                                              (exchangeRateData?.Rate ?? 1)
                                            )?.toFixed(2)}{" "}
                                          </div>
                                        </OverlayTrigger>
                                      </div>
                                      <div>
                                        {editState ? (
                                          <>
                                            <div className="highlight-input-with-grey-border p-2 mr-3 overflow-hidden">
                                              <input
                                                type="number"
                                                className="border-none bg-transparent overflow-hidden"
                                                value={data.UnitSell}
                                                onChange={(e) => {
                                                  let updated_data = {
                                                    ...packageData,
                                                  };
                                                  updated_data.MSPPackagePrice.PackagePerAsset_List[
                                                    dataIndex
                                                  ].IsEditedUnitSell = true;
                                                  updated_data.MSPPackagePrice.PackagePerAsset_List[
                                                    dataIndex
                                                  ].UnitSell = e.target.value;
                                                  setPackageData(updated_data);
                                                }}
                                              />
                                            </div>
                                          </>
                                        ) : (
                                          <>
                                            {data.IsEditedUnitSell ? (
                                              <OverlayTrigger
                                                placement="right"
                                                trigger={
                                                  exchangeRateData?.FromCurrency &&
                                                    exchangeRateData?.FromCurrency !==
                                                    defaultCurrency?.CurrencyName
                                                    ? ["hover", "focus"]
                                                    : []
                                                }
                                                delay={{ show: 200, hide: 250 }}
                                                overlay={
                                                  <Tooltip
                                                    id={`tooltip-1365`}
                                                    className="custom_tooltip_access"
                                                  >
                                                    <div>
                                                      {defaultCurrency?.CurrencySymbol ??
                                                        "£"}{" "}
                                                      {data.UnitSell?.toFixed(2)}
                                                    </div>
                                                    <div className="f-darkgrey">
                                                      <img
                                                        alt=""
                                                        width={13}
                                                        height={13}
                                                        src="/images/msp/exchange-rate-icon.svg"
                                                        className="mr-2"
                                                      />
                                                      {
                                                        userSelectedCurrency?.CurrencySymbol
                                                      }{" "}
                                                      {(
                                                        data.UnitSell *
                                                        exchangeRateData?.Rate
                                                      )?.toFixed(2)}
                                                    </div>
                                                  </Tooltip>
                                                }
                                              >
                                                <div className="f-blue position-relative">
                                                  <span className="blue-dot" />
                                                  {
                                                    userSelectedCurrency?.CurrencySymbol
                                                  }
                                                  {(
                                                    data.UnitSell *
                                                    exchangeRateData?.Rate
                                                  )?.toFixed(2)}{" "}
                                                </div>
                                              </OverlayTrigger>
                                            ) : (
                                              <OverlayTrigger
                                                placement="right"
                                                trigger={
                                                  exchangeRateData?.FromCurrency &&
                                                    exchangeRateData?.FromCurrency !==
                                                    defaultCurrency?.CurrencyName
                                                    ? ["hover", "focus"]
                                                    : []
                                                }
                                                delay={{ show: 200, hide: 250 }}
                                                overlay={
                                                  <Tooltip
                                                    id={`tooltip-1365`}
                                                    className="custom_tooltip_access"
                                                  >
                                                    <div>
                                                      {defaultCurrency?.CurrencySymbol ??
                                                        "£"}{" "}
                                                      {data.UnitSell?.toFixed(2)}
                                                    </div>
                                                    <div className="f-darkgrey">
                                                      <img
                                                        alt=""
                                                        width={13}
                                                        height={13}
                                                        src="/images/msp/exchange-rate-icon.svg"
                                                        className="mr-2"
                                                      />
                                                      {
                                                        userSelectedCurrency?.CurrencySymbol
                                                      }{" "}
                                                      {(
                                                        data.UnitSell *
                                                        exchangeRateData?.Rate
                                                      )?.toFixed(2)}
                                                    </div>
                                                  </Tooltip>
                                                }
                                              >
                                                <div>
                                                  {
                                                    userSelectedCurrency?.CurrencySymbol
                                                  }
                                                  {(
                                                    data.UnitSell *
                                                    exchangeRateData?.Rate
                                                  )?.toFixed(2)}{" "}
                                                </div>
                                              </OverlayTrigger>
                                            )}
                                          </>
                                        )}
                                      </div>
                                      {/* {pageData?.response?.ScenarioHeader
                                        ?.IsCustomPackage && ( */}
                                      {isCustom && (
                                        // <OverlayTrigger
                                        //   placement="right"
                                        //   trigger={
                                        //     exchangeRateData?.FromCurrency &&
                                        //     exchangeRateData?.FromCurrency !==
                                        //       defaultCurrency?.CurrencyName
                                        //       ? ["hover", "focus"]
                                        //       : []
                                        //   }
                                        //   delay={{ show: 200, hide: 250 }}
                                        //   overlay={
                                        //     <Tooltip
                                        //       id={`tooltip-1365`}
                                        //       className="custom_tooltip_access"
                                        //     >
                                        //       <div>
                                        //         {
                                        //           userSelectedCurrency?.CurrencySymbol
                                        //         }
                                        //         {data.SellTotal?.toFixed(2)}
                                        //       </div>
                                        //       <div className="f-darkgrey">
                                        //         <img
                                        //           alt=""
                                        //           width={13}
                                        //           height={13}
                                        //           src="/images/msp/exchange-rate-icon.svg"
                                        //           className="mr-2"
                                        //         />
                                        //         {/* {
                                        //                 userSelectedCurrency?.CurrencySymbol
                                        //               } */}
                                        //         {defaultCurrency?.CurrencySymbol ??
                                        //           "£"}{" "}
                                        //         {(
                                        //           data.SellTotal *
                                        //           exchangeRateData?.Rate
                                        //         )?.toFixed(2)}
                                        //       </div>
                                        //     </Tooltip>
                                        //   }
                                        // >
                                        //   <div>
                                        //     {" "}
                                        //     {userSelectedCurrency?.CurrencySymbol}
                                        //     {data.SellTotal?.toFixed(2)}
                                        //   </div>
                                        // </OverlayTrigger>
                                        <OverlayTrigger
                                          placement="right"
                                          trigger={
                                            exchangeRateData?.FromCurrency &&
                                              exchangeRateData?.FromCurrency !==
                                              defaultCurrency?.CurrencyName
                                              ? ["hover", "focus"]
                                              : []
                                          }
                                          delay={{ show: 200, hide: 250 }}
                                          overlay={
                                            <Tooltip
                                              id={`tooltip-1365`}
                                              className="custom_tooltip_access"
                                            >
                                              <div>
                                                {defaultCurrency?.CurrencySymbol ??
                                                  "£"}{" "}
                                                {data.SellTotal?.toFixed(2)}
                                              </div>
                                              <div className="f-darkgrey">
                                                <img
                                                  alt=""
                                                  width={13}
                                                  height={13}
                                                  src="/images/msp/exchange-rate-icon.svg"
                                                  className="mr-2"
                                                />
                                                {
                                                  userSelectedCurrency?.CurrencySymbol
                                                }{" "}
                                                {(
                                                  data.SellTotal *
                                                  exchangeRateData?.Rate
                                                )?.toFixed(2)}
                                              </div>
                                            </Tooltip>
                                          }
                                        >
                                          <div className="f-500 f-grey">
                                            {renderPriceText(
                                              99,
                                              (
                                                data?.SellTotal *
                                                (exchangeRateData?.Rate ?? 1)
                                              )?.toFixed(2),
                                              userSelectedCurrency?.CurrencySymbol
                                            )}
                                          </div>
                                        </OverlayTrigger>
                                      )}
                                    </div>
                                  );
                                })}
                              </div>
                            </div>

                            <div className="my-2 point-row-1">
                              <div
                                className="w-100 d-flex align-items-center justify-content-between p-2 radius-4 pointer"
                                onClick={(e) => {
                                  ToggleAccordionBlocks(e, 1);
                                }}
                              >
                                <div className="d-flex align-items-center f-500 f-12 f-darkgrey">
                                  {/* <div
                                      className="rounded-full mx-2"
                                      style={{
                                        width: "16px",
                                        height: "16px",
                                        backgroundColor: data.circleColour,
                                      }}
                                    /> */}
                                  Not included asset types
                                  {tableLoader ? (
                                    <div className="msp-service-update-loader ml-2 mr-1"></div>
                                  ) : (
                                    <></>
                                  )}
                                </div>
                                <img
                                  alt=""
                                  id="chevron"
                                  className="chevron"
                                  src="/images/attack-surface/chevron-up.svg"
                                />
                              </div>

                              <div
                                className="panel-opened"
                                style={{
                                  maxHeight:
                                    isFirstOpenBlocks[1] === true && "1500px",
                                }}
                                onClick={(e) => {
                                  e.stopPropagation();
                                }}
                              >
                                <div className="border-parent radius-4">
                                  {/* <div className="border-item f-12 f-500 p-2 f-darkgrey">
                                    Asset Type
                                  </div> */}
                                  {packageData?.MSPPackagePrice?.PackagePerAsset_List?.filter(
                                    (item) => item.UnitCost == 0
                                  )?.map((data, dataIndex) => {
                                    return (
                                      <div className="border-item p-2 f-darkgrey">
                                        {data.Name}
                                      </div>
                                    );
                                  })}
                                </div>
                              </div>
                            </div>

                            {/* Period Break down Table */}
                            <div className="point-row-2">
                              <div
                                className="d-flex align-items-center p-2 justify-content-between pointer"
                                onClick={(e) => {
                                  ToggleAccordionBlocks(e, 2);
                                }}
                              >
                                <div className=" f-12 f-darkgrey d-flex align-items-center">
                                  Breakdown by period
                                  {tableLoader ? (
                                    <div className="msp-service-update-loader ml-2 mr-1"></div>
                                  ) : (
                                    <></>
                                  )}
                                </div>
                                <img
                                  alt=""
                                  id="chevron"
                                  className="chevron"
                                  src="/images/attack-surface/chevron-up.svg"
                                />
                              </div>
                              <div
                                className="border-parent  radius-8 panel-opened"
                                style={{
                                  maxHeight:
                                    isFirstOpenBlocks[2] === true && "1500px",
                                }}
                                onClick={(e) => {
                                  e.stopPropagation();
                                }}
                              >
                                <div className="per_asset_period-breakdown_table border-item f-grey">
                                  <div className="  pl-2">Period</div>
                                  <div>Cost</div>
                                  <div>Sell</div>
                                  <div>Margin</div>
                                </div>

                                {packageData?.MSPPackagePrice?.PackagePeriodBreakdown_List?.map(
                                  (data, index) => {
                                    return (
                                      <div className="per_asset_period-breakdown_table  border-item">
                                        <div className="f-500  pl-2">
                                          {data?.Name}{" "}
                                        </div>
                                        <OverlayTrigger
                                          placement="right"
                                          trigger={
                                            exchangeRateData?.FromCurrency &&
                                              exchangeRateData?.FromCurrency !==
                                              defaultCurrency?.CurrencyName
                                              ? ["hover", "focus"]
                                              : []
                                          }
                                          delay={{ show: 200, hide: 250 }}
                                          overlay={
                                            <Tooltip
                                              id={`tooltip-1365`}
                                              className="custom_tooltip_access"
                                            >
                                              <div>
                                                {defaultCurrency?.CurrencySymbol ??
                                                  "£"}{" "}
                                                {data.Cost?.toFixed(2)}
                                              </div>
                                              <div className="f-darkgrey">
                                                <img
                                                  alt=""
                                                  width={13}
                                                  height={13}
                                                  src="/images/msp/exchange-rate-icon.svg"
                                                  className="mr-2"
                                                />
                                                {/* {
                                                          userSelectedCurrency?.CurrencySymbol
                                                        } */}
                                                {
                                                  userSelectedCurrency?.CurrencySymbol
                                                }{" "}
                                                {(
                                                  data.Cost * exchangeRateData?.Rate
                                                )?.toFixed(2)}
                                              </div>
                                            </Tooltip>
                                          }
                                        >
                                          <div className="f-500 f-grey">
                                            {renderPriceText(
                                              99,
                                              (
                                                data?.Cost *
                                                (exchangeRateData?.Rate ?? 1)
                                              )?.toFixed(2),
                                              userSelectedCurrency?.CurrencySymbol
                                            )}
                                          </div>
                                        </OverlayTrigger>
                                        <OverlayTrigger
                                          placement="right"
                                          trigger={
                                            exchangeRateData?.FromCurrency &&
                                              exchangeRateData?.FromCurrency !==
                                              defaultCurrency?.CurrencyName
                                              ? ["hover", "focus"]
                                              : []
                                          }
                                          delay={{ show: 200, hide: 250 }}
                                          overlay={
                                            <Tooltip
                                              id={`tooltip-1365`}
                                              className="custom_tooltip_access"
                                            >
                                              <div>
                                                {defaultCurrency?.CurrencySymbol ??
                                                  "£"}{" "}
                                                {data.Sell?.toFixed(2)}
                                              </div>
                                              <div className="f-darkgrey">
                                                <img
                                                  alt=""
                                                  width={13}
                                                  height={13}
                                                  src="/images/msp/exchange-rate-icon.svg"
                                                  className="mr-2"
                                                />
                                                {/* {
                                                          userSelectedCurrency?.CurrencySymbol
                                                        } */}
                                                {
                                                  userSelectedCurrency?.CurrencySymbol
                                                }{" "}
                                                {(
                                                  data.Sell * exchangeRateData?.Rate
                                                )?.toFixed(2)}
                                              </div>
                                            </Tooltip>
                                          }
                                        >
                                          <div className="f-500 f-grey">
                                            {renderPriceText(
                                              99,
                                              (
                                                data?.Sell *
                                                (exchangeRateData?.Rate ?? 1)
                                              )?.toFixed(2),
                                              userSelectedCurrency?.CurrencySymbol
                                            )}
                                          </div>
                                        </OverlayTrigger>
                                        <OverlayTrigger
                                          placement="right"
                                          trigger={
                                            exchangeRateData?.FromCurrency &&
                                              exchangeRateData?.FromCurrency !==
                                              defaultCurrency?.CurrencyName
                                              ? ["hover", "focus"]
                                              : []
                                          }
                                          delay={{ show: 200, hide: 250 }}
                                          overlay={
                                            <Tooltip
                                              id={`tooltip-1365`}
                                              className="custom_tooltip_access"
                                            >
                                              <div>
                                                {defaultCurrency?.CurrencySymbol ??
                                                  "£"}{" "}
                                                {data.Margin?.toFixed(2)}
                                              </div>
                                              <div className="f-darkgrey">
                                                <img
                                                  alt=""
                                                  width={13}
                                                  height={13}
                                                  src="/images/msp/exchange-rate-icon.svg"
                                                  className="mr-2"
                                                />
                                                {/* {
                                                          userSelectedCurrency?.CurrencySymbol
                                                        } */}
                                                {
                                                  userSelectedCurrency?.CurrencySymbol
                                                }{" "}
                                                {(
                                                  data.Margin *
                                                  exchangeRateData?.Rate
                                                )?.toFixed(2)}
                                              </div>
                                            </Tooltip>
                                          }
                                        >
                                          <div className="f-500 f-grey">
                                            {renderPriceText(
                                              99,
                                              (
                                                data?.Margin *
                                                (exchangeRateData?.Rate ?? 1)
                                              )?.toFixed(2),
                                              userSelectedCurrency?.CurrencySymbol
                                            )}
                                          </div>
                                        </OverlayTrigger>
                                      </div>
                                    );
                                  }
                                )}
                                {
                                  selectedServices?.some(item => item?.IsOnDemand === true) ?
                                    <div className="px-2 py-3">
                                      <div className="d-flex align-items-center">
                                        <img
                                          alt=""
                                          width={13}
                                          height={13}
                                          src="/images/info-blue.svg"
                                          className="mr-2"
                                        />
                                        <div className="f-500 f-14 txt-blue">Price On-Demand</div>
                                      </div>
                                      <div className="f-400 f-12 f-darkgrey">This package includes services which are priced on demand</div>
                                    </div> : <></>
                                }
                              </div>
                            </div>
                            <div
                              className="mt-2"
                              style={{
                                display: "grid",
                                gridTemplateColumns: "83% 10%",
                              }}
                            >
                              <div>
                                {exchangeRateData?.FromCurrency &&
                                  exchangeRateData?.FromCurrency !==
                                  defaultCurrency?.CurrencyName && (
                                    <div className="w-100 text-center f-12 f-400 f-darkgrey mb-3">
                                      <img
                                        alt=""
                                        width={13}
                                        height={13}
                                        src="/images/msp/exchange-rate-icon.svg"
                                        className="mr-2"
                                      />{" "}
                                      The exchange rate on{" "}
                                      {moment(
                                        updatedExchangeRateData?.Date ?? ""
                                      )?.format("DD MMM YYYY")}{" "}
                                      is 1 {updatedExchangeRateData?.FromCurrency} ={" "}
                                      {updatedExchangeRateData?.Rate}{" "}
                                      {defaultCurrency?.CurrencyName}
                                    </div>
                                  )}
                                {exchangeRateData?.FromCurrency &&
                                  exchangeRateData?.FromCurrency !==
                                  defaultCurrency?.CurrencyName && (
                                    <div className="w-100 text-center f-12 f-400 f-darkgrey mb-3">
                                      <img
                                        alt=""
                                        width={13}
                                        height={13}
                                        src="/images/msp/exchange-rate-icon.svg"
                                        className="mr-2"
                                      />{" "}
                                      The exchange rate when this package was
                                      created (
                                      {moment(exchangeRateData?.Date ?? "")?.format(
                                        "DD MMM YYYY"
                                      )}
                                      ) was 1 {exchangeRateData?.FromCurrency} ={" "}
                                      {exchangeRateData?.Rate}{" "}
                                      {defaultCurrency?.CurrencyName}
                                    </div>
                                  )}
                              </div>

                              {exchangeRateData?.FromCurrency &&
                                exchangeRateData?.FromCurrency !==
                                defaultCurrency?.CurrencyName && (
                                  <div
                                    className="f-blue pointer"
                                    onClick={() => {
                                      updatePackageExchangeRateData(
                                        exchangeRateData?.Rate,
                                        scenarioId
                                      );
                                    }}
                                  >
                                    Update
                                  </div>
                                )}
                            </div>
                          </div>
                        )}
                        {billedByStatus?.DDLText === "Seats" && (
                          <div>
                            {/* Price Breakdown */}
                            {/* {pageData?.response?.ScenarioHeader?.IsCustomPackage ? ( */}
                            {isCustom ? (
                              <div>
                                <div className="point-row-4">
                                  <div
                                    className="d-flex align-items-center justify-content-between pointer py-2"
                                    style={{ height: "30px" }}
                                    onClick={(e) => {
                                      ToggleAccordionBlocks(e, 4);
                                    }}
                                  >
                                    <div className="f-12 f-darkgrey d-flex align-items-center mt-2">
                                      Price Breakdown
                                      {tableLoader ? (
                                        <div className="msp-service-update-loader ml-2 mr-1"></div>
                                      ) : (
                                        <></>
                                      )}
                                    </div>
                                    <img
                                      alt=""
                                      id="chevron"
                                      className="chevron ml-2"
                                      src="/images/attack-surface/chevron-up.svg"
                                    />
                                  </div>

                                  <div
                                    className="panel-opened"
                                    style={{
                                      maxHeight:
                                        isFirstOpenBlocks[4] === true && "800px",
                                    }}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                    }}
                                  >
                                    {/* Req seats header */}
                                    <div
                                      className="d-flex align-items-center justify-content-between mb-2  bg-grey radius-8"
                                      style={{
                                        height: "46px",
                                      }}
                                    >
                                      <div className="f-14 f-600 pl-3">
                                        Req. Seats
                                      </div>
                                      <div className="pr-5">
                                        {
                                          pageData?.response?.MSPPackagePrice
                                            ?.RequiredSeatsOverriden
                                        }{" "}
                                      </div>
                                    </div>

                                    {/* Req seat table */}
                                    <div className="border-parent  radius-8">
                                      <div className="per_asset_period-breakdown_table border-item f-grey">
                                        <div className="f-12 f-500 f-darkgrey  pl-2">
                                          Asset Type
                                        </div>
                                        <div className="f-12 f-500 f-darkgrey">
                                          Inc. per Seat
                                        </div>
                                        <div className="f-12 f-500 f-darkgrey">
                                          Client Qty
                                        </div>
                                        <div className="f-12 f-500 f-darkgrey">
                                          Seats Req
                                        </div>
                                      </div>
                                      {pageData?.response?.MSPPackagePrice?.PackagePerSeat_List?.map(
                                        (data, dataIndex) => {
                                          return (
                                            <div className="per_asset_period-breakdown_table  border-item">
                                              <div className="f-500  pl-2">
                                                {data.Name}{" "}
                                              </div>
                                              <div className="f-500 f-grey">
                                                {data.PerSeat}{" "}
                                              </div>
                                              <div className="f-500 f-grey">
                                                {data.ClientQty}{" "}
                                              </div>
                                              <div className="f-600 f-grey">
                                                {data.SeatRequired}{" "}
                                              </div>
                                            </div>
                                          );
                                        }
                                      )}
                                    </div>
                                  </div>
                                </div>

                                {/* Unit Pricing Table */}

                                <div className="point-row-5">
                                  <div
                                    className="d-flex align-items-center justify-content-between pointer "
                                    onClick={(e) => {
                                      ToggleAccordionBlocks(e, 5);
                                    }}
                                  >
                                    <div className=" f-12 f-500 f-darkgrey my-2 mb-3 d-flex align-items-center">
                                      Unit Pricing
                                      {tableLoader ? (
                                        <div className="msp-service-update-loader ml-2 mr-1"></div>
                                      ) : (
                                        <></>
                                      )}
                                    </div>
                                    <img
                                      alt=""
                                      id="chevron"
                                      className="chevron ml-2"
                                      src="/images/attack-surface/chevron-up.svg"
                                    />
                                  </div>

                                  <div
                                    className="panel-opened"
                                    style={{
                                      maxHeight:
                                        isFirstOpenBlocks[5] === true && "102px",
                                    }}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                    }}
                                  >
                                    <div className="d-flex w-100 mb-3">
                                      <div className="w-50">
                                        <div className="d-flex f-500">
                                          Billing period
                                          <div className="msp-service-tool-tip-parent w-fit-content">
                                            <img
                                              className="ml-2 pointer"
                                              src="/images/msp/ques-info-icon.svg"
                                              alt=""
                                            />
                                          </div>
                                        </div>
                                      </div>

                                      <div className="w-50 " style={{}}>
                                        <div className="w-fit-content f-darkgrey">
                                          {
                                            pageData?.response?.ScenarioHeader
                                              ?.BillingPeriod_Selected?.DDLText
                                          }
                                        </div>
                                      </div>
                                    </div>

                                    <div className="d-flex w-100 mb-3">
                                      <div className="w-50">
                                        <div className="d-flex f-500">
                                          Seats Required
                                        </div>
                                      </div>

                                      <div className="w-50 " style={{}}>
                                        <div className="w-fit-content">
                                          {
                                            pageData?.response?.MSPPackagePrice
                                              ?.RequiredSeatsOverriden
                                          }
                                        </div>
                                      </div>
                                    </div>

                                    {/* <div className="d-flex w-100 mb-3">
                                <div className="w-50">
                                  <div className="d-flex f-500">Cost</div>
                                </div>

                                <div className="w-50 " style={{}}>
                                  <div className="w-fit-content f-darkgrey">
                                    {renderPriceText(
                                      pageData?.response?.ScenarioHeader?.BillingPeriod_Selected
                                        ?.DDLSort,
                                      pageData?.response?.MSPPackagePrice?.Cost?.toFixed(2),
                                      pageData?.response?.CurrencyCode_Selected?.CurrencySymbol ?? "£"
                                    )}
                                  </div>
                                </div>
                              </div> */}

                                    <div className="d-flex w-100 mb-3">
                                      <div className="w-50">
                                        <div className="d-flex f-500">
                                          Seat Price
                                        </div>
                                      </div>

                                      <div
                                        className="w-50 d-flex align-items-center justify-content-between"
                                        style={{}}
                                      >
                                        <div className="pr-5">
                                          {renderPriceText(
                                            pageData?.response?.ScenarioHeader
                                              ?.BillingPeriod_Selected?.DDLSort,
                                            pageData?.response?.MSPPackagePrice?.TotalSell?.toFixed(
                                              2
                                            ),
                                            pageData?.response
                                              ?.CurrencyCode_Selected
                                              ?.CurrencySymbol ??
                                            defaultCurrency?.CurrencySymbol ??
                                            "£"
                                          )}{" "}
                                        </div>

                                        {/* <div className="f-darkgrey">
                                    RRP:{" "}
                                    {renderPriceText(
                                      pageData?.response?.ScenarioHeader?.BillingPeriod_Selected
                                        ?.DDLSort,
                                      pageData?.response?.MSPPackagePrice?.RRP?.toFixed(2),
                                      pageData?.response?.CurrencyCode_Selected?.CurrencySymbol ?? "£"
                                    )}
                                  </div> */}
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                {/* Tota pricing Table */}
                                <div className="point-row-6">
                                  <div
                                    className="d-flex align-items-center justify-content-between pointer"
                                    onClick={(e) => {
                                      ToggleAccordionBlocks(e, 6);
                                    }}
                                  >
                                    <div className="my-2 f-12 f-darkgrey d-flex align-items-center">
                                      Summary
                                      {tableLoader ? (
                                        <div className="msp-service-update-loader ml-2 mr-1"></div>
                                      ) : (
                                        <></>
                                      )}
                                    </div>
                                    <img
                                      alt=""
                                      id="chevron"
                                      className="chevron ml-2"
                                      src="/images/attack-surface/chevron-up.svg"
                                    />
                                  </div>

                                  <div
                                    className="border-parent  radius-8 panel-opened"
                                    style={{
                                      maxHeight:
                                        isFirstOpenBlocks[6] === true && "126px",
                                    }}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                    }}
                                  >
                                    <div className="per_asset_total_pricing_table-updated border-item">
                                      <div className="f-500  pl-2">
                                        Package Price
                                      </div>
                                      <div></div>
                                      <div className="f-500 f-grey">
                                        {pageData?.response?.CurrencyCode_Selected
                                          ?.CurrencySymbol ??
                                          defaultCurrency?.CurrencySymbol ??
                                          "£"}
                                        {numberWithCommas(
                                          pageData?.response?.MSPPackagePrice?.Subtotal?.toFixed(
                                            2
                                          )
                                        )}
                                      </div>
                                    </div>

                                    <div className="per_asset_total_pricing_table-updated  border-item">
                                      <div className="f-500 pl-2">
                                        {`
                                  Tax @ ${pageData?.response?.TaxRates_Selected?.TaxRate}% (${pageData?.response?.TaxName})
                                ` ?? "Tax @ 0% (No Taxable)"}
                                      </div>
                                      <div className="f-500 f-grey">
                                        {pageData?.response?.CurrencyCode_Selected
                                          ?.CurrencySymbol ??
                                          defaultCurrency?.CurrencySymbol ??
                                          "£"}
                                        {pageData?.response?.MSPPackagePrice?.Tax?.toFixed(
                                          2
                                        )}
                                      </div>

                                      <div className="f-500 f-grey">
                                        {pageData?.response?.CurrencyCode_Selected
                                          ?.CurrencySymbol ?? "£"}
                                        {numberWithCommas(
                                          (
                                            pageData?.response?.MSPPackagePrice
                                              ?.Subtotal +
                                            pageData?.response?.MSPPackagePrice?.Tax
                                          ).toFixed(2)
                                        )}
                                      </div>
                                    </div>

                                    <div className="per_asset_total_pricing_table-updated  border-item">
                                      <div className="f-600  pl-2">Total</div>
                                      <div></div>
                                      <div className="f-600 f-grey">
                                        {renderPriceText(
                                          pageData?.response?.ScenarioHeader
                                            ?.BillingPeriod_Selected?.DDLSort,
                                          totalCost,
                                          pageData?.response?.CurrencyCode_Selected
                                            ?.CurrencySymbol ??
                                          defaultCurrency?.CurrencySymbol ??
                                          "£"
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ) : (
                              <></>
                            )}

                            {/* Period Break down Table */}
                            <div className="point-row-3">
                              <div
                                className="d-flex align-items-center justify-content-between pointer"
                                onClick={(e) => {
                                  ToggleAccordionBlocks(e, 3);
                                }}
                              >
                                <div
                                  className={`my-2 f-12 f-darkgrey d-flex align-items-center ${
                                    // !pageData?.response?.ScenarioHeader
                                    //   ?.IsCustomPackage && "mt-4"
                                    !isCustom && "mt-4"
                                    }`}
                                >
                                  Breakdown by period
                                  {tableLoader ? (
                                    <div className="msp-service-update-loader ml-2 mr-1"></div>
                                  ) : (
                                    <></>
                                  )}
                                </div>
                                <img
                                  alt=""
                                  id="chevron"
                                  className="chevron"
                                  src="/images/attack-surface/chevron-up.svg"
                                />
                              </div>
                              <div
                                className="border-parent  radius-8 panel-opened"
                                style={{
                                  maxHeight:
                                    isFirstOpenBlocks[3] === true && "236px",
                                }}
                                onClick={(e) => {
                                  e.stopPropagation();
                                }}
                              >
                                <div className="per_asset_period-breakdown_table border-item f-grey">
                                  <div className="  pl-2">Period</div>
                                  <div>Cost</div>
                                  <div>Sell</div>
                                  <div>Margin</div>
                                </div>
                                {packageData?.MSPPackagePrice?.PackagePeriodBreakdown_List?.map(
                                  (data, index) => {
                                    return (
                                      <div className="per_asset_period-breakdown_table  border-item">
                                        <div className="f-500  pl-2">
                                          {data?.Name}
                                        </div>
                                        <OverlayTrigger
                                          placement="right"
                                          trigger={
                                            exchangeRateData?.FromCurrency &&
                                              exchangeRateData?.FromCurrency !==
                                              defaultCurrency?.CurrencyName
                                              ? ["hover", "focus"]
                                              : []
                                          }
                                          delay={{ show: 200, hide: 250 }}
                                          overlay={
                                            <Tooltip
                                              id={`tooltip-1365`}
                                              className="custom_tooltip_access"
                                            >
                                              <div>
                                                {defaultCurrency?.CurrencySymbol ??
                                                  "£"}{" "}
                                                {data.Cost?.toFixed(2)}
                                              </div>
                                              <div className="f-darkgrey">
                                                <img
                                                  alt=""
                                                  width={13}
                                                  height={13}
                                                  src="/images/msp/exchange-rate-icon.svg"
                                                  className="mr-2"
                                                />
                                                {/* {
                                                    userSelectedCurrency?.CurrencySymbol
                                                  } */}
                                                {
                                                  userSelectedCurrency?.CurrencySymbol
                                                }{" "}
                                                {(
                                                  data.Cost * exchangeRateData?.Rate
                                                )?.toFixed(2)}
                                              </div>
                                            </Tooltip>
                                          }
                                        >
                                          <div className="f-500 f-grey">
                                            {renderPriceText(
                                              99,
                                              (
                                                data?.Cost *
                                                (exchangeRateData?.Rate ?? 1)
                                              )?.toFixed(2),
                                              userSelectedCurrency?.CurrencySymbol
                                            )}
                                          </div>
                                        </OverlayTrigger>
                                        <OverlayTrigger
                                          placement="right"
                                          trigger={
                                            exchangeRateData?.FromCurrency &&
                                              exchangeRateData?.FromCurrency !==
                                              defaultCurrency?.CurrencyName
                                              ? ["hover", "focus"]
                                              : []
                                          }
                                          delay={{ show: 200, hide: 250 }}
                                          overlay={
                                            <Tooltip
                                              id={`tooltip-1365`}
                                              className="custom_tooltip_access"
                                            >
                                              <div>
                                                {defaultCurrency?.CurrencySymbol ??
                                                  "£"}{" "}
                                                {data.Sell?.toFixed(2)}
                                              </div>
                                              <div className="f-darkgrey">
                                                <img
                                                  alt=""
                                                  width={13}
                                                  height={13}
                                                  src="/images/msp/exchange-rate-icon.svg"
                                                  className="mr-2"
                                                />
                                                {/* {
                                                    userSelectedCurrency?.CurrencySymbol
                                                  } */}
                                                {
                                                  userSelectedCurrency?.CurrencySymbol
                                                }{" "}
                                                {(
                                                  data.Sell * exchangeRateData?.Rate
                                                )?.toFixed(2)}
                                              </div>
                                            </Tooltip>
                                          }
                                        >
                                          <div className="f-500 f-grey">
                                            {renderPriceText(
                                              99,
                                              (
                                                data?.Sell *
                                                (exchangeRateData?.Rate ?? 1)
                                              )?.toFixed(2),
                                              userSelectedCurrency?.CurrencySymbol
                                            )}
                                          </div>
                                        </OverlayTrigger>
                                        <OverlayTrigger
                                          placement="right"
                                          trigger={
                                            exchangeRateData?.FromCurrency &&
                                              exchangeRateData?.FromCurrency !==
                                              defaultCurrency?.CurrencyName
                                              ? ["hover", "focus"]
                                              : []
                                          }
                                          delay={{ show: 200, hide: 250 }}
                                          overlay={
                                            <Tooltip
                                              id={`tooltip-1365`}
                                              className="custom_tooltip_access"
                                            >
                                              <div>
                                                {defaultCurrency?.CurrencySymbol ??
                                                  "£"}{" "}
                                                {data.Margin?.toFixed(2)}
                                              </div>
                                              <div className="f-darkgrey">
                                                <img
                                                  alt=""
                                                  width={13}
                                                  height={13}
                                                  src="/images/msp/exchange-rate-icon.svg"
                                                  className="mr-2"
                                                />
                                                {/* {
                                                    userSelectedCurrency?.CurrencySymbol
                                                  } */}
                                                {
                                                  userSelectedCurrency?.CurrencySymbol
                                                }{" "}
                                                {(
                                                  data.Margin *
                                                  exchangeRateData?.Rate
                                                )?.toFixed(2)}
                                              </div>
                                            </Tooltip>
                                          }
                                        >
                                          <div className="f-500 f-grey">
                                            {renderPriceText(
                                              99,
                                              (
                                                data?.Margin *
                                                (exchangeRateData?.Rate ?? 1)
                                              )?.toFixed(2),
                                              userSelectedCurrency?.CurrencySymbol
                                            )}
                                          </div>
                                        </OverlayTrigger>
                                      </div>
                                    );
                                  }
                                )}
                                {
                                  selectedServices?.some(item => item?.IsOnDemand === true) ?
                                    <div className="px-2 py-3">
                                      <div className="d-flex align-items-center">
                                        <img
                                          alt=""
                                          width={13}
                                          height={13}
                                          src="/images/info-blue.svg"
                                          className="mr-2"
                                        />
                                        <div className="f-500 f-14 txt-blue">Price On-Demand</div>
                                      </div>
                                      <div className="f-400 f-12 f-darkgrey">This package includes services which are priced on demand</div>
                                    </div> : <></>
                                }
                              </div>
                            </div>
                            <div
                              className="mt-2"
                              style={{
                                display: "grid",
                                gridTemplateColumns: "83% 10%",
                              }}
                            >
                              <div>
                                {exchangeRateData?.FromCurrency &&
                                  exchangeRateData?.FromCurrency !==
                                  defaultCurrency?.CurrencyName && (
                                    <div className="w-100 text-center f-12 f-400 f-darkgrey mb-3">
                                      <img
                                        alt=""
                                        width={13}
                                        height={13}
                                        src="/images/msp/exchange-rate-icon.svg"
                                        className="mr-2"
                                      />{" "}
                                      The exchange rate on{" "}
                                      {moment(
                                        updatedExchangeRateData?.Date ?? ""
                                      )?.format("DD MMM YYYY")}{" "}
                                      is 1 {updatedExchangeRateData?.FromCurrency} ={" "}
                                      {updatedExchangeRateData?.Rate}{" "}
                                      {defaultCurrency?.CurrencyName}
                                    </div>
                                  )}
                                {exchangeRateData?.FromCurrency &&
                                  exchangeRateData?.FromCurrency !==
                                  defaultCurrency?.CurrencyName && (
                                    <div className="w-100 text-center f-12 f-400 f-darkgrey mb-3">
                                      <img
                                        alt=""
                                        width={13}
                                        height={13}
                                        src="/images/msp/exchange-rate-icon.svg"
                                        className="mr-2"
                                      />{" "}
                                      The exchange rate when this package was
                                      created (
                                      {moment(exchangeRateData?.Date ?? "")?.format(
                                        "DD MMM YYYY"
                                      )}
                                      ) was 1 {exchangeRateData?.FromCurrency} ={" "}
                                      {exchangeRateData?.Rate}{" "}
                                      {defaultCurrency?.CurrencyName}
                                    </div>
                                  )}
                              </div>

                              {exchangeRateData?.FromCurrency &&
                                exchangeRateData?.FromCurrency !==
                                defaultCurrency?.CurrencyName && (
                                  <div
                                    className="f-blue pointer"
                                    onClick={() => {
                                      updatePackageExchangeRateData(
                                        exchangeRateData?.Rate,
                                        scenarioId
                                      );
                                    }}
                                  >
                                    Update
                                  </div>
                                )}
                            </div>
                          </div>
                        )}
                      </div> : selectedTab == 1 ? <MspFeatures
                        identify={selectedServices?.filter(item => item?.NistPillar === 0)}
                        protect={selectedServices?.filter(item => item?.NistPillar === 1)}
                        detect={selectedServices?.filter(item => item?.NistPillar === 2)}
                        recover={selectedServices?.filter(item => item?.NistPillar === 3)}
                        respond={selectedServices?.filter(item => item?.NistPillar === 4)}
                      /> : <div>
                        <MspComponents 
                          componentsData={componentsData}
                        />
                      </div>
                    }

                    {/* {billedByStatus?.DDLText === "Assets" && (
                      <div>
                        <div className="point-row-0">
                          <div
                            className="w-100 d-flex align-items-center justify-content-between pointer p-2 bg-hov er-grey radius-4 mt-2"
                            onClick={(e) => {
                              ToggleAccordionBlocks(e, 0);
                            }}
                          >
                            <div className="d-flex align-items-center f-500 f-12 f-darkgrey">
                              Per Asset Breakdown
                              {tableLoader ? (
                                <div className="msp-service-update-loader ml-2 mr-1"></div>
                              ) : (
                                <></>
                              )}
                            </div>
                            <img
                              alt=""
                              id="chevron"
                              className="chevron ml-2"
                              src="/images/attack-surface/chevron-up.svg"
                            />
                          </div>
                          <div
                            className="border-parent  radius-8 panel-opened"
                            style={{
                              maxHeight:
                                isFirstOpenBlocks[0] === true && "1500px",
                            }}
                            onClick={(e) => {
                              e.stopPropagation();
                            }}
                          >
                            <div
                              className={`border-item f-12 f-500 f-darkgrey p-2 ${
                                // pageData?.response?.ScenarioHeader
                                //   ?.IsCustomPackage
                                isCustom
                                  ? " per_asset_price_breakdown_table "
                                  : " standard_per_asset_price_breakdown_table "
                                }`}
                            >
                              <div>Asset Type</div>
                              <div>Qty </div>
                              <div>Unit Cost</div>
                              <div>Unit RRP</div>
                              <div>Unit Sell</div>

                              {isCustom && <div>Line Total</div>}
                            </div>
                            {packageData?.MSPPackagePrice?.PackagePerAsset_List?.filter(
                              (item) => item.UnitCost !== 0
                            )?.map((data, dataIndex) => {
                              return (
                                <div
                                  className={`border-item f-grey p-2 ${
                                    // pageData?.response?.ScenarioHeader
                                    //   ?.IsCustomPackage
                                    isCustom
                                      ? " per_asset_price_breakdown_table "
                                      : " standard_per_asset_price_breakdown_table "
                                    }`}
                                >
                                  <div className="f-black f-500">
                                    {data.Name}
                                  </div>
                                  <div>
                                    {editState && false ? (
                                      <>
                                        <div className="highlight-input-with-grey-border p-2 mr-3 overflow-hidden">
                                          <input
                                            type="number"
                                            className="border-none bg-transparent overflow-hidden"
                                            value={data.Qty}
                                            onChange={(e) => {
                                              // setChanged(true);
                                              let updated_data = {
                                                ...packageData,
                                              };
                                              updated_data.MSPPackagePrice.PackagePerAsset_List[
                                                dataIndex
                                              ].IsEditedQty = true;
                                              updated_data.MSPPackagePrice.PackagePerAsset_List[
                                                dataIndex
                                              ].Qty = e.target.value;
                                              setPackageData(updated_data);
                                            }}
                                          />
                                        </div>
                                      </>
                                    ) : (
                                      <>
                                        {data.IsEditedQty ? (
                                          <div className="f-blue position-relative">
                                            <span className="blue-dot" />
                                            {data.Qty}
                                          </div>
                                        ) : (
                                          <div>{data.Qty} </div>
                                        )}
                                      </>
                                    )}
                                  </div>
                                  <div>
                                    <OverlayTrigger
                                      placement="right"
                                      trigger={
                                        exchangeRateData?.FromCurrency &&
                                          exchangeRateData?.FromCurrency !==
                                          defaultCurrency?.CurrencyName
                                          ? ["hover", "focus"]
                                          : []
                                      }
                                      delay={{ show: 200, hide: 250 }}
                                      overlay={
                                        <Tooltip
                                          id={`tooltip-1365`}
                                          className="custom_tooltip_access"
                                        >
                                          <div>
                                            {defaultCurrency?.CurrencySymbol ??
                                              "£"}{" "}
                                            {data.UnitCost?.toFixed(2)}
                                          </div>
                                          <div className="f-darkgrey">
                                            <img
                                              alt=""
                                              width={13}
                                              height={13}
                                              src="/images/msp/exchange-rate-icon.svg"
                                              className="mr-2"
                                            />

                                            
                                            {
                                              userSelectedCurrency?.CurrencySymbol
                                            }{" "}
                                            {(
                                              data.UnitCost *
                                              exchangeRateData?.Rate
                                            )?.toFixed(2)}
                                          </div>
                                        </Tooltip>
                                      }
                                    >
                                      <div>
                                        {userSelectedCurrency?.CurrencySymbol}
                                        {(
                                          data?.UnitCost *
                                          (exchangeRateData?.Rate ?? 1)
                                        )?.toFixed(2)}
                                      </div>
                                    </OverlayTrigger>
                                  </div>
                                  <div>
                                    <OverlayTrigger
                                      placement="right"
                                      trigger={
                                        exchangeRateData?.FromCurrency &&
                                          exchangeRateData?.FromCurrency !==
                                          defaultCurrency?.CurrencyName
                                          ? ["hover", "focus"]
                                          : []
                                      }
                                      delay={{ show: 200, hide: 250 }}
                                      overlay={
                                        <Tooltip
                                          id={`tooltip-1365`}
                                          className="custom_tooltip_access"
                                        >
                                          <div>
                                            {defaultCurrency?.CurrencySymbol ??
                                              "£"}{" "}
                                            {data.UnitRRP?.toFixed(2)}
                                          </div>
                                          <div className="f-darkgrey">
                                            <img
                                              alt=""
                                              width={13}
                                              height={13}
                                              src="/images/msp/exchange-rate-icon.svg"
                                              className="mr-2"
                                            />
                                            {
                                              userSelectedCurrency?.CurrencySymbol
                                            }{" "}
                                            {(
                                              data?.UnitRRP *
                                              (exchangeRateData?.Rate ?? 1)
                                            )?.toFixed(2)}
                                          </div>
                                        </Tooltip>
                                      }
                                    >
                                      <div>
                                        {userSelectedCurrency?.CurrencySymbol}
                                        {(
                                          data?.UnitRRP *
                                          (exchangeRateData?.Rate ?? 1)
                                        )?.toFixed(2)}{" "}
                                      </div>
                                    </OverlayTrigger>
                                  </div>
                                  <div>
                                    {editState ? (
                                      <>
                                        <div className="highlight-input-with-grey-border p-2 mr-3 overflow-hidden">
                                          <input
                                            type="number"
                                            className="border-none bg-transparent overflow-hidden"
                                            value={data.UnitSell}
                                            onChange={(e) => {
                                              let updated_data = {
                                                ...packageData,
                                              };
                                              updated_data.MSPPackagePrice.PackagePerAsset_List[
                                                dataIndex
                                              ].IsEditedUnitSell = true;
                                              updated_data.MSPPackagePrice.PackagePerAsset_List[
                                                dataIndex
                                              ].UnitSell = e.target.value;
                                              setPackageData(updated_data);
                                            }}
                                          />
                                        </div>
                                      </>
                                    ) : (
                                      <>
                                        {data.IsEditedUnitSell ? (
                                          <OverlayTrigger
                                            placement="right"
                                            trigger={
                                              exchangeRateData?.FromCurrency &&
                                                exchangeRateData?.FromCurrency !==
                                                defaultCurrency?.CurrencyName
                                                ? ["hover", "focus"]
                                                : []
                                            }
                                            delay={{ show: 200, hide: 250 }}
                                            overlay={
                                              <Tooltip
                                                id={`tooltip-1365`}
                                                className="custom_tooltip_access"
                                              >
                                                <div>
                                                  {defaultCurrency?.CurrencySymbol ??
                                                    "£"}{" "}
                                                  {data.UnitSell?.toFixed(2)}
                                                </div>
                                                <div className="f-darkgrey">
                                                  <img
                                                    alt=""
                                                    width={13}
                                                    height={13}
                                                    src="/images/msp/exchange-rate-icon.svg"
                                                    className="mr-2"
                                                  />
                                                  {
                                                    userSelectedCurrency?.CurrencySymbol
                                                  }{" "}
                                                  {(
                                                    data.UnitSell *
                                                    exchangeRateData?.Rate
                                                  )?.toFixed(2)}
                                                </div>
                                              </Tooltip>
                                            }
                                          >
                                            <div className="f-blue position-relative">
                                              <span className="blue-dot" />
                                              {
                                                userSelectedCurrency?.CurrencySymbol
                                              }
                                              {(
                                                data.UnitSell *
                                                exchangeRateData?.Rate
                                              )?.toFixed(2)}{" "}
                                            </div>
                                          </OverlayTrigger>
                                        ) : (
                                          <OverlayTrigger
                                            placement="right"
                                            trigger={
                                              exchangeRateData?.FromCurrency &&
                                                exchangeRateData?.FromCurrency !==
                                                defaultCurrency?.CurrencyName
                                                ? ["hover", "focus"]
                                                : []
                                            }
                                            delay={{ show: 200, hide: 250 }}
                                            overlay={
                                              <Tooltip
                                                id={`tooltip-1365`}
                                                className="custom_tooltip_access"
                                              >
                                                <div>
                                                  {defaultCurrency?.CurrencySymbol ??
                                                    "£"}{" "}
                                                  {data.UnitSell?.toFixed(2)}
                                                </div>
                                                <div className="f-darkgrey">
                                                  <img
                                                    alt=""
                                                    width={13}
                                                    height={13}
                                                    src="/images/msp/exchange-rate-icon.svg"
                                                    className="mr-2"
                                                  />
                                                  {
                                                    userSelectedCurrency?.CurrencySymbol
                                                  }{" "}
                                                  {(
                                                    data.UnitSell *
                                                    exchangeRateData?.Rate
                                                  )?.toFixed(2)}
                                                </div>
                                              </Tooltip>
                                            }
                                          >
                                            <div>
                                              {
                                                userSelectedCurrency?.CurrencySymbol
                                              }
                                              {(
                                                data.UnitSell *
                                                exchangeRateData?.Rate
                                              )?.toFixed(2)}{" "}
                                            </div>
                                          </OverlayTrigger>
                                        )}
                                      </>
                                    )}
                                  </div>

                                  {isCustom && (
                                    // <OverlayTrigger
                                    //   placement="right"
                                    //   trigger={
                                    //     exchangeRateData?.FromCurrency &&
                                    //     exchangeRateData?.FromCurrency !==
                                    //       defaultCurrency?.CurrencyName
                                    //       ? ["hover", "focus"]
                                    //       : []
                                    //   }
                                    //   delay={{ show: 200, hide: 250 }}
                                    //   overlay={
                                    //     <Tooltip
                                    //       id={`tooltip-1365`}
                                    //       className="custom_tooltip_access"
                                    //     >
                                    //       <div>
                                    //         {
                                    //           userSelectedCurrency?.CurrencySymbol
                                    //         }
                                    //         {data.SellTotal?.toFixed(2)}
                                    //       </div>
                                    //       <div className="f-darkgrey">
                                    //         <img
                                    //           alt=""
                                    //           width={13}
                                    //           height={13}
                                    //           src="/images/msp/exchange-rate-icon.svg"
                                    //           className="mr-2"
                                    //         />

                                    
                                    //         {defaultCurrency?.CurrencySymbol ??
                                    //           "£"}{" "}
                                    //         {(
                                    //           data.SellTotal *
                                    //           exchangeRateData?.Rate
                                    //         )?.toFixed(2)}
                                    //       </div>
                                    //     </Tooltip>
                                    //   }
                                    // >
                                    //   <div>
                                    //     {" "}
                                    //     {userSelectedCurrency?.CurrencySymbol}
                                    //     {data.SellTotal?.toFixed(2)}
                                    //   </div>
                                    // </OverlayTrigger>
                                    <OverlayTrigger
                                      placement="right"
                                      trigger={
                                        exchangeRateData?.FromCurrency &&
                                          exchangeRateData?.FromCurrency !==
                                          defaultCurrency?.CurrencyName
                                          ? ["hover", "focus"]
                                          : []
                                      }
                                      delay={{ show: 200, hide: 250 }}
                                      overlay={
                                        <Tooltip
                                          id={`tooltip-1365`}
                                          className="custom_tooltip_access"
                                        >
                                          <div>
                                            {defaultCurrency?.CurrencySymbol ??
                                              "£"}{" "}
                                            {data.SellTotal?.toFixed(2)}
                                          </div>
                                          <div className="f-darkgrey">
                                            <img
                                              alt=""
                                              width={13}
                                              height={13}
                                              src="/images/msp/exchange-rate-icon.svg"
                                              className="mr-2"
                                            />
                                            {
                                              userSelectedCurrency?.CurrencySymbol
                                            }{" "}
                                            {(
                                              data.SellTotal *
                                              exchangeRateData?.Rate
                                            )?.toFixed(2)}
                                          </div>
                                        </Tooltip>
                                      }
                                    >
                                      <div className="f-500 f-grey">
                                        {renderPriceText(
                                          99,
                                          (
                                            data?.SellTotal *
                                            (exchangeRateData?.Rate ?? 1)
                                          )?.toFixed(2),
                                          userSelectedCurrency?.CurrencySymbol
                                        )}
                                      </div>
                                    </OverlayTrigger>
                                  )}
                                </div>
                              );
                            })}
                          </div>
                        </div>

                        <div className="my-2 point-row-1">
                          <div
                            className="w-100 d-flex align-items-center justify-content-between p-2 radius-4 pointer"
                            onClick={(e) => {
                              ToggleAccordionBlocks(e, 1);
                            }}
                          >
                            <div className="d-flex align-items-center f-500 f-12 f-darkgrey">

                              Not included asset types
                              {tableLoader ? (
                                <div className="msp-service-update-loader ml-2 mr-1"></div>
                              ) : (
                                <></>
                              )}
                            </div>
                            <img
                              alt=""
                              id="chevron"
                              className="chevron"
                              src="/images/attack-surface/chevron-up.svg"
                            />
                          </div>

                          <div
                            className="panel-opened"
                            style={{
                              maxHeight:
                                isFirstOpenBlocks[1] === true && "1500px",
                            }}
                            onClick={(e) => {
                              e.stopPropagation();
                            }}
                          >
                            <div className="border-parent radius-4">

                              {packageData?.MSPPackagePrice?.PackagePerAsset_List?.filter(
                                (item) => item.UnitCost == 0
                              )?.map((data, dataIndex) => {
                                return (
                                  <div className="border-item p-2 f-darkgrey">
                                    {data.Name}
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        </div>

                        <div className="point-row-2">
                          <div
                            className="d-flex align-items-center p-2 justify-content-between pointer"
                            onClick={(e) => {
                              ToggleAccordionBlocks(e, 2);
                            }}
                          >
                            <div className=" f-12 f-darkgrey d-flex align-items-center">
                              Breakdown by period
                              {tableLoader ? (
                                <div className="msp-service-update-loader ml-2 mr-1"></div>
                              ) : (
                                <></>
                              )}
                            </div>
                            <img
                              alt=""
                              id="chevron"
                              className="chevron"
                              src="/images/attack-surface/chevron-up.svg"
                            />
                          </div>
                          <div
                            className="border-parent  radius-8 panel-opened"
                            style={{
                              maxHeight:
                                isFirstOpenBlocks[2] === true && "1500px",
                            }}
                            onClick={(e) => {
                              e.stopPropagation();
                            }}
                          >
                            <div className="per_asset_period-breakdown_table border-item f-grey">
                              <div className="  pl-2">Period</div>
                              <div>Cost</div>
                              <div>Sell</div>
                              <div>Margin</div>
                            </div>

                            {packageData?.MSPPackagePrice?.PackagePeriodBreakdown_List?.map(
                              (data, index) => {
                                return (
                                  <div className="per_asset_period-breakdown_table  border-item">
                                    <div className="f-500  pl-2">
                                      {data?.Name}{" "}
                                    </div>
                                    <OverlayTrigger
                                      placement="right"
                                      trigger={
                                        exchangeRateData?.FromCurrency &&
                                          exchangeRateData?.FromCurrency !==
                                          defaultCurrency?.CurrencyName
                                          ? ["hover", "focus"]
                                          : []
                                      }
                                      delay={{ show: 200, hide: 250 }}
                                      overlay={
                                        <Tooltip
                                          id={`tooltip-1365`}
                                          className="custom_tooltip_access"
                                        >
                                          <div>
                                            {defaultCurrency?.CurrencySymbol ??
                                              "£"}{" "}
                                            {data.Cost?.toFixed(2)}
                                          </div>
                                          <div className="f-darkgrey">
                                            <img
                                              alt=""
                                              width={13}
                                              height={13}
                                              src="/images/msp/exchange-rate-icon.svg"
                                              className="mr-2"
                                            />

                                            
                                            {
                                              userSelectedCurrency?.CurrencySymbol
                                            }{" "}
                                            {(
                                              data.Cost * exchangeRateData?.Rate
                                            )?.toFixed(2)}
                                          </div>
                                        </Tooltip>
                                      }
                                    >
                                      <div className="f-500 f-grey">
                                        {renderPriceText(
                                          99,
                                          (
                                            data?.Cost *
                                            (exchangeRateData?.Rate ?? 1)
                                          )?.toFixed(2),
                                          userSelectedCurrency?.CurrencySymbol
                                        )}
                                      </div>
                                    </OverlayTrigger>
                                    <OverlayTrigger
                                      placement="right"
                                      trigger={
                                        exchangeRateData?.FromCurrency &&
                                          exchangeRateData?.FromCurrency !==
                                          defaultCurrency?.CurrencyName
                                          ? ["hover", "focus"]
                                          : []
                                      }
                                      delay={{ show: 200, hide: 250 }}
                                      overlay={
                                        <Tooltip
                                          id={`tooltip-1365`}
                                          className="custom_tooltip_access"
                                        >
                                          <div>
                                            {defaultCurrency?.CurrencySymbol ??
                                              "£"}{" "}
                                            {data.Sell?.toFixed(2)}
                                          </div>
                                          <div className="f-darkgrey">
                                            <img
                                              alt=""
                                              width={13}
                                              height={13}
                                              src="/images/msp/exchange-rate-icon.svg"
                                              className="mr-2"
                                            />

                                            {
                                              userSelectedCurrency?.CurrencySymbol
                                            }{" "}
                                            {(
                                              data.Sell * exchangeRateData?.Rate
                                            )?.toFixed(2)}
                                          </div>
                                        </Tooltip>
                                      }
                                    >
                                      <div className="f-500 f-grey">
                                        {renderPriceText(
                                          99,
                                          (
                                            data?.Sell *
                                            (exchangeRateData?.Rate ?? 1)
                                          )?.toFixed(2),
                                          userSelectedCurrency?.CurrencySymbol
                                        )}
                                      </div>
                                    </OverlayTrigger>
                                    <OverlayTrigger
                                      placement="right"
                                      trigger={
                                        exchangeRateData?.FromCurrency &&
                                          exchangeRateData?.FromCurrency !==
                                          defaultCurrency?.CurrencyName
                                          ? ["hover", "focus"]
                                          : []
                                      }
                                      delay={{ show: 200, hide: 250 }}
                                      overlay={
                                        <Tooltip
                                          id={`tooltip-1365`}
                                          className="custom_tooltip_access"
                                        >
                                          <div>
                                            {defaultCurrency?.CurrencySymbol ??
                                              "£"}{" "}
                                            {data.Margin?.toFixed(2)}
                                          </div>
                                          <div className="f-darkgrey">
                                            <img
                                              alt=""
                                              width={13}
                                              height={13}
                                              src="/images/msp/exchange-rate-icon.svg"
                                              className="mr-2"
                                            />

                                            
                                            {
                                              userSelectedCurrency?.CurrencySymbol
                                            }{" "}
                                            {(
                                              data.Margin *
                                              exchangeRateData?.Rate
                                            )?.toFixed(2)}
                                          </div>
                                        </Tooltip>
                                      }
                                    >
                                      <div className="f-500 f-grey">
                                        {renderPriceText(
                                          99,
                                          (
                                            data?.Margin *
                                            (exchangeRateData?.Rate ?? 1)
                                          )?.toFixed(2),
                                          userSelectedCurrency?.CurrencySymbol
                                        )}
                                      </div>
                                    </OverlayTrigger>
                                  </div>
                                );
                              }
                            )}
                            {
                              selectedServices?.some(item => item?.IsOnDemand === true) ?
                                <div className="px-2 py-3">
                                  <div className="d-flex align-items-center">
                                    <img
                                      alt=""
                                      width={13}
                                      height={13}
                                      src="/images/info-blue.svg"
                                      className="mr-2"
                                    />
                                    <div className="f-500 f-14 txt-blue">Price On-Demand</div>
                                  </div>
                                  <div className="f-400 f-12 f-darkgrey">This package includes services which are priced on demand</div>
                                </div> : <></>
                            }
                          </div>
                        </div>
                        <div
                          className="mt-2"
                          style={{
                            display: "grid",
                            gridTemplateColumns: "83% 10%",
                          }}
                        >
                          <div>
                            {exchangeRateData?.FromCurrency &&
                              exchangeRateData?.FromCurrency !==
                              defaultCurrency?.CurrencyName && (
                                <div className="w-100 text-center f-12 f-400 f-darkgrey mb-3">
                                  <img
                                    alt=""
                                    width={13}
                                    height={13}
                                    src="/images/msp/exchange-rate-icon.svg"
                                    className="mr-2"
                                  />{" "}
                                  The exchange rate on{" "}
                                  {moment(
                                    updatedExchangeRateData?.Date ?? ""
                                  )?.format("DD MMM YYYY")}{" "}
                                  is 1 {updatedExchangeRateData?.FromCurrency} ={" "}
                                  {updatedExchangeRateData?.Rate}{" "}
                                  {defaultCurrency?.CurrencyName}
                                </div>
                              )}
                            {exchangeRateData?.FromCurrency &&
                              exchangeRateData?.FromCurrency !==
                              defaultCurrency?.CurrencyName && (
                                <div className="w-100 text-center f-12 f-400 f-darkgrey mb-3">
                                  <img
                                    alt=""
                                    width={13}
                                    height={13}
                                    src="/images/msp/exchange-rate-icon.svg"
                                    className="mr-2"
                                  />{" "}
                                  The exchange rate when this package was
                                  created (
                                  {moment(exchangeRateData?.Date ?? "")?.format(
                                    "DD MMM YYYY"
                                  )}
                                  ) was 1 {exchangeRateData?.FromCurrency} ={" "}
                                  {exchangeRateData?.Rate}{" "}
                                  {defaultCurrency?.CurrencyName}
                                </div>
                              )}
                          </div>

                          {exchangeRateData?.FromCurrency &&
                            exchangeRateData?.FromCurrency !==
                            defaultCurrency?.CurrencyName && (
                              <div
                                className="f-blue pointer"
                                onClick={() => {
                                  updatePackageExchangeRateData(
                                    exchangeRateData?.Rate,
                                    scenarioId
                                  );
                                }}
                              >
                                Update
                              </div>
                            )}
                        </div>
                      </div>
                    )} */}
                  </div>
                </div>
              )}

              <div className="p-4"></div>
            </div>
          </>
        )}
      </div>
      <MspScoreModal
        isForPackageDetail={true}
        packageId={Number(scenarioId)}
        show={showMspScoreModal}
        updateRadarChart={updateRadarChart}
        hideModal={() => {
          setShowMspScoreModal(false);
        }}
        radarData={data?.datasets[0]?.data}
        serviceId={selectedServiceId}
        selectedNistPiller={Object.values(services_titles)[0]}
        HideMspSecopsLink={true}
        hideServiceModal={() => { }}
        showExploreMappingModal={showExploreMappingModal}
        setShowExploreMappingModal={setShowExploreMappingModal}
        existServices={[
          selectedServices?.filter(item => item?.NistPillar === 0)?.length,
          selectedServices?.filter(item => item?.NistPillar === 1)?.length,
          selectedServices?.filter(item => item?.NistPillar === 2)?.length,
          selectedServices?.filter(item => item?.NistPillar === 3)?.length,
          selectedServices?.filter(item => item?.NistPillar === 4)?.length
          // selectedServices[0]?.length,
          // selectedServices[1]?.length,
          // selectedServices[2]?.length,
          // selectedServices[3]?.length,
          // selectedServices[4]?.length,
        ]}
      />
      <MspFeaturesModal
        packageId={Number(scenarioId)}
        show={showMspFeaturesModal}
        hideModal={() => {
          setShowMspFeaturesModal(false);
        }}
        identify={selectedServices?.filter(item => item?.NistPillar === 0)}
        protect={selectedServices?.filter(item => item?.NistPillar === 1)}
        detect={selectedServices?.filter(item => item?.NistPillar === 2)}
        recover={selectedServices?.filter(item => item?.NistPillar === 3)}
        respond={selectedServices?.filter(item => item?.NistPillar === 4)}
      // identify={selectedServices[0]}
      // protect={selectedServices[1]}
      // detect={selectedServices[2]}
      // recover={selectedServices[3]}
      // respond={selectedServices[4]}
      />
      <ExploreMappingModal
        show={showExploreMappingModal}
        hideModal={() => {
          setShowExploreMappingModal(false);
        }}
      />
      <MspAddEditServiceModal
        setNestedProductModalState={setNestedProductModalState}
        nestedProductModalState={nestedProductModalState}
        seriesModalData={seriesModalData}
        setSeriesModalData={setSeriesModalData}
        show={showServiceModal}
        _selectedServiceId={selectedServiceId}
        hideModal={() => {
          if (showServiceModal) {
            setShowServiceModal(false);
            setIsAddOnService(false);
            // setLoading(true);
            fillDataInDropdown();
          }
        }}
        nistPillar={nistPillarType}      //!!!!!!!
        _setNistIndex={setNistIndex}
        refreshData={() => {
          setShowServiceModal(false);
          // setLoading(true);
          fillDataInDropdown();
        }}
        parentID="msp-main-screen-for-integration-modal"
        isAddonService={isAddonService}
        serviceList={serviceList}
      />

      <MspAddEditSeriesModal
        show={seriesModalData?.show}
        seriesModalData={seriesModalData}
        setSeriesModalData={setSeriesModalData}
        _selectedServiceId={selectedServiceId}
        hideModal={() => {
          setSeriesModalData((prev) => ({
            ...prev,
            show: false,
          }));
        }}
        refreshData={() => { }}
        parentID="msp-main-screen-for-integration-modal"
      />
      <MspAddEditProductModal
        modalID="dshkvsdq"
        className="nested-product-modal-in-service"
        // deleteProduct={deleteData}
        show={nestedProductModalState?.show}
        selectedProductId={nestedProductModalState?.selectedProductId}
        hideModal={() => {
          setNestedProductModalState((prev) => ({
            ...prev,
            show: false,
          }));
        }}
        mapNestedModalData={(data) => { }}
        refreshData={() => {
          setNestedProductModalState((prev) => ({
            ...prev,
            update: true,
          }));
        }}
        parentID="msp-main-screen-for-integration-modal"
      />

      <CustomDeleteModal
        show={warningModalConfig?.showWarningModal}
        deleteHeaderIcon={"/images/msp/yellow-exclammation.svg"}
        hideModal={() => {
          setWarningModalConfig((prev) => ({
            ...prev,
            showWarningModal: false,
          }));
          setIsSaveClicked(false);
        }}
        deleteButtonClass={"hg-blue-btn"}
        deleteButtonText={isSaveClicked ? "Save" : "Exit"}
        deleteHeaderText={"Warning"}
        deleteBodyText={
          isSaveClicked
            ? "This security package includes no services."
            : "You have unsaved changes."
        }
        deleteFooterText={
          isSaveClicked
            ? "Are you sure you want to save?"
            : "Are you sure you want to exit?"
        }
        deleteFunction={() => {
          if (!isSaveClicked) {
            if (state?.backUrl) {
              history.push(state.backUrl, {
                packageFilterSave: state?.packageFilter,
                serviceFilterSave: state?.serviceFilter,
                productFilterSave: state?.productFilter,
              });
            } else {
              history.push("/mspv2/sell/packages", {
                packageFilterSave: state?.packageFilter,
                serviceFilterSave: state?.serviceFilter,
                productFilterSave: state?.productFilter,
              });
            }
            if (Number(packageId) === 0) {
              deleteData(pageData?.response?.ScenarioHeader?.objId_Scenario);
            }
          } else {
            setSaving(true);
            updatePackage();
          }
        }}
        deleteParams={{}}
        deleteType={"warningClose"}
      />
    </div>
  );
};

export default MspPriceCalcultor;

export const AddButtonForPackage = (props) => {
  return (
    <div
      className={`d-flex align-items-center p-2 bg-grey w-fit-content radius-2 f-500 f-grey pointer ${props.className}`}
      onClick={(e) => {
        if (props.onClick) {
          props.onClick(e);
        }
      }}
    >
      <img src="/images/actioncenter/plusgrey.svg" alt="" className="mr-2" />
      Add
    </div>
  );
};

const MspPriceCalcultorLoader = () => {
  return (
    <ContentLoader
      speed={2}
      width={"100%"}
      height={"100%"}
      viewBox="0 0 1500 900"
      backgroundColor="#F6F8FB"
      foregroundColor="#ecebeb"
    >
      {/* Left side */}
      <rect x="0%" y="70" rx="8" ry="8" width="50%" height="60" />
      <rect x="0%" y="212" rx="8" ry="8" width="50%" height="90" />
      <rect x="0%" y="304" rx="8" ry="8" width="50%" height="90" />
      <rect x="0%" y="396" rx="8" ry="8" width="50%" height="90" />

      <rect x="0%" y="545" rx="8" ry="8" width="50%" height="60" />
      <rect x="0%" y="645" rx="8" ry="8" width="50%" height="90" />
      <rect x="0%" y="737" rx="8" ry="8" width="50%" height="90" />
      <rect x="0%" y="829" rx="8" ry="8" width="50%" height="90" />

      {/* Right Side */}
      <rect x="55%" y="10%" rx="16" ry="16" width="40%" height="500" />
      <rect x="55%" y="660" rx="8" ry="8" width="40%" height="45" />

      <rect x="55%" y="707" rx="8" ry="8" width="40%" height="45" />
      <rect x="55%" y="754" rx="8" ry="8" width="40%" height="45" />
      <rect x="55%" y="801" rx="8" ry="8" width="40%" height="45" />
    </ContentLoader>
  );
};

export const services_enums = {
  0: "Identify",
  1: "Protection",
  2: "Detection",
  3: "Incident Response",
  4: "Recover",
};
export const services_enums_selected = {
  0: {
    ObjId: 0,
    DDLId: 0,
    DDLText: "Identify",
    DDLSort: 0,
    DDLValue: 0,
    DDLColour: null,
    DDLImage: null,
    DDLTextColour: null,
    DDLAllowUserEdit: null,
  },
  1: {
    ObjId: 0,
    DDLId: 1,
    DDLText: "Protect",
    DDLSort: 1,
    DDLValue: 1,
    DDLColour: null,
    DDLImage: null,
    DDLTextColour: null,
    DDLAllowUserEdit: null,
  },
  2: {
    ObjId: 0,
    DDLId: 2,
    DDLText: "Detect",
    DDLSort: 2,
    DDLValue: 2,
    DDLColour: null,
    DDLImage: null,
    DDLTextColour: null,
    DDLAllowUserEdit: null,
  },
  3: {
    ObjId: 0,
    DDLId: 3,
    DDLText: "Respond",
    DDLSort: 3,
    DDLValue: 3,
    DDLColour: null,
    DDLImage: null,
    DDLTextColour: null,
    DDLAllowUserEdit: null,
  },
  4: {
    ObjId: 0,
    DDLId: 4,
    DDLText: "Recover",
    DDLSort: 4,
    DDLValue: 4,
    DDLColour: null,
    DDLImage: null,
    DDLTextColour: null,
    DDLAllowUserEdit: null,
  },
};

export const services_titles = {
  Identify: "Identify",
  Protection: "Protect",
  Detection: "Detect",
  Incident_Response: "Respond",
  Recover: "Recover",
};

export const services_titles_selected = {
  Identify: {
    ObjId: 0,
    DDLId: 0,
    DDLText: "Identify",
    DDLSort: 0,
    DDLValue: 0,
    DDLColour: null,
    DDLImage: null,
    DDLTextColour: null,
    DDLAllowUserEdit: null,
  },
  Protection: {
    ObjId: 0,
    DDLId: 1,
    DDLText: "Protect",
    DDLSort: 1,
    DDLValue: 1,
    DDLColour: null,
    DDLImage: null,
    DDLTextColour: null,
    DDLAllowUserEdit: null,
  },
  Detection: {
    ObjId: 0,
    DDLId: 2,
    DDLText: "Detect",
    DDLSort: 2,
    DDLValue: 2,
    DDLColour: null,
    DDLImage: null,
    DDLTextColour: null,
    DDLAllowUserEdit: null,
  },
  Incident_Response: {
    ObjId: 0,
    DDLId: 3,
    DDLText: "Respond",
    DDLSort: 3,
    DDLValue: 3,
    DDLColour: null,
    DDLImage: null,
    DDLTextColour: null,
    DDLAllowUserEdit: null,
  },
  Recover: {
    ObjId: 0,
    DDLId: 4,
    DDLText: "Recover",
    DDLSort: 4,
    DDLValue: 4,
    DDLColour: null,
    DDLImage: null,
    DDLTextColour: null,
    DDLAllowUserEdit: null,
  },
};

export const servicesIndex = {
  Identify: 0,
  Protect: 1,
  Detect: 2,
  Respond: 3,
  Recover: 4,
};

// OnToggleItem(parentIndex:int    ,   childIndex :int)
export const ServiceDropdown = ({
  data,
  selectedData,
  serviceEnum,
  onToggleItem,
  onClickAdd,
  onClose,
  _updateSelectedServices,
  _setIsAddonService,
  _setnistPillarType,
  _inputRef,
  _isToggleShows,
  isLoading,
  serviceSearchString,
  setServiceSearchString = () => { }
}) => {
  useEffect(() => {
    setIsToggleShows(_isToggleShows);
  }, [_isToggleShows]);
  const [dropdownOpen, setDropdownOpen] = useState(true);
  const [isToggleShows, setIsToggleShows] = useState(false);

  const handleToggle = () => {
    // setDropdownOpen(!dropdownOpen);
    // setIsToggleShows(!isToggleShows)
  };


  return (
    <div
      className="w-100 d-flex align-items-center border-item p-3 "
      ref={_inputRef}
    >
      <Dropdown
        show={dropdownOpen}
        onToggle={handleToggle}
        className="msp-multiselect-package-selection-dropdown w-100"
      >
        <Dropdown.Toggle className="w-100">
          <div className="highlight-input-with-grey-border p-2 radius-4 d-flex align-items-center">
            <img
              src="/images/attack-surface/search-icon.svg"
              alt=""
              className="mr-2"
            />
            <input
              autoFocus={true}
              className="border-none bg-transparent flex-grow-1"
              type="text"
              value={serviceSearchString}
              onChange={(e) => {
                setServiceSearchString(e?.target?.value)
              }}
            />
          </div>
        </Dropdown.Toggle>

        <Dropdown.Menu className="w-100">
          {data?.length !== 0 ? (
            <>
            </>
          ) : (
            <>
              <div className="f-400 f-darkgrey ml-3 my-2">
                No Services or Add-ons yet
              </div>
            </>
          )}

          <div className=" ml-3" /*key={services_enums[serviceEnum]}*/>
            <div className="f-12 f-darkgrey my-2">
              Services
            </div>

            <div
              className="f-blue f-500 mt-1 d-flex align-items-center pointer mb-1"
              onClick={(e) => {
                _setIsAddonService(false);
                onClickAdd();
                // _setnistPillarType(
                //   Object.values(services_titles_selected)[serviceEnum]   ///!!!
                // );
              }}
            >
              <img
                alt=""
                src="/images/msp/blue-add-icon.svg"
                className="mr-2"
              />
              <div className="pt-1">
                Add service
              </div>
            </div>

            {!data && isLoading ? (
              <div className="d-flex align-items-center justify-content-center">
                <div
                  className="msp-service-update-loader ml-2 mr-1 my-2"
                  style={{
                    height: "20px",
                    width: "20px",
                  }}
                ></div>
              </div>
            ) : (
              <></>
            )}

            {data?.filter(item => item?.Headline?.toLowerCase()?.includes(serviceSearchString?.toLowerCase()))?.map((item, itemIndex) => {
              if (!item?.IsAddOn) {
                return (
                  <div
                    key={itemIndex}
                    className={`
                          d-flex align-items-center justify-content-between mb-3 
                          ${item?.IsChildServiceDisabled
                        ? " curs-default "
                        : " pointer "
                      }
                          `}
                    onClick={() => {
                      if (!item?.IsChildServiceDisabled) {
                        _updateSelectedServices(item);
                        onToggleItem(itemIndex);
                        setDropdownOpen(false);
                      }
                    }}
                  >
                    <div
                      className={`${item.Selected
                        ? " f-blue "
                        : item?.IsChildServiceDisabled
                          ? " f-darkgrey "
                          : " greyText2 "
                        } d-flex align-items-center f-400
                      `}
                    >
                      <img
                        alt=""
                        className={`
                          ${item?.Selected
                            ? " blue-icon "
                            : item?.IsChildServiceDisabled
                              ? " lightgray-icon "
                              : " darkgray-icon "
                          } mr-2
                        `}
                        style={{
                          height: "16px",
                        }}
                        src="/images/msp/dollar-icon.svg"
                      />
                      {item.Headline}
                    </div>
                    <div>
                      {item?.loading ? (
                        <div className="msp-service-update-loader mr-3"></div>
                      ) : (
                        item.Selected && (
                          <img
                            alt=""
                            className=" mr-3"
                            src="/images/msp/blue-tick.svg"
                          />
                        )
                      )}
                    </div>
                  </div>
                );
              }
            })}
          </div>

          <div className=" ml-3" /*key={services_enums[serviceEnum]}*/>
            <div className="f-12 f-darkgrey my-2">
              {services_enums[serviceEnum]} add-ons
            </div>

            <div
              className="f-blue f-500 mt-1 d-flex align-items-center pointer mb-1"
              onClick={(e) => {
                // setShowServiceModal(true);
                _setIsAddonService(true);
                onClickAdd();
                // _setnistPillarType(
                //   Object.values(services_titles_selected)[serviceEnum]   ///!!!!!
                // );
              }}
            >
              <img
                alt=""
                src="/images/msp/blue-add-icon.svg"
                className="mr-2"
              />
              <div className="pt-1">
                Add add-ons
              </div>
            </div>

            {!data && isLoading ? (
              <div className="d-flex align-items-center justify-content-center">
                <div
                  className="msp-service-update-loader ml-2 mr-1 my-2"
                  style={{
                    height: "20px",
                    width: "20px",
                  }}
                ></div>
              </div>
            ) : (
              <></>
            )}

            {data?.filter(item => item?.Headline?.toLowerCase()?.includes(serviceSearchString?.toLowerCase()))?.map((item, itemIndex) => {
              if (item?.IsAddOn) {
                return (
                  <div
                    key={itemIndex}
                    className={`
                          d-flex align-items-center justify-content-between mb-3 
                            ${item?.IsChildServiceDisabled
                        ? " curs-default "
                        : " pointer "
                      }
                          `}
                    onClick={() => {
                      if (!item?.IsChildServiceDisabled) {
                        _updateSelectedServices(item/*, serviceEnum*/);
                        onToggleItem(itemIndex);
                        setDropdownOpen(false);
                      }
                    }}
                  >
                    <div
                      className={`${item.Selected
                        ? " f-blue "
                        : item?.IsChildServiceDisabled
                          ? " f-darkgrey "
                          : " greyText2 "
                        } d-flex align-items-center f-400
                      `}
                    >
                      <img
                        alt=""
                        className={`
                          ${item?.Selected
                            ? " blue-icon "
                            : item?.IsChildServiceDisabled
                              ? " lightgray-icon "
                              : " darkgray-icon "
                          } mr-2
                        `}
                        style={{
                          height: "16px",
                        }}
                        src="/images/msp/dollar-icon.svg"
                      />
                      {item.Headline}
                    </div>
                    <div>
                      {item?.loading ? (
                        <div className="msp-service-update-loader mr-3"></div>
                      ) : (
                        item.Selected && (
                          <img
                            alt=""
                            className=" mr-3"
                            src="/images/msp/blue-tick.svg"
                          />
                        )
                      )}
                    </div>
                  </div>
                );
              }
            })}
          </div>
        </Dropdown.Menu>
      </Dropdown>

      <img
        alt=""
        className="mr-1 ml-3 pointer"
        width={12}
        height={12}
        src="/images/attack-surface/cross-icon.svg"
        onClick={() => {
          if (onClose) onClose();
        }}
      />
    </div>
  );
};


export function addZerows(number) {
  if (!isNaN(number)) {
    if (number?.includes(".")) {
      const decimalPartLength =
        number?.split(".")[1]?.length;
      if (decimalPartLength === 1) {
        return (number + "0")
      } else if (decimalPartLength > 2) {
        return (Number(number)?.toFixed(2)?.toString())
      }
    } else {
      return (number + ".00")
    }
  }
  return number;
}