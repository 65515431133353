import React, { useEffect, useState } from "react";
import { Button, Modal, Nav, Tab } from "react-bootstrap";
import { useAuth } from "../../../context/Auth";
import Loader from "../../../Common/loader";
import axios from "axios";
import { CallToast } from "../../../PriceCalculator/modals/CustomToast";
import { getRequestOptions } from "../../../../Utils/commonApi";

const AddResourceModal = ({
    show,
    hideModal,
    questionId,
    selectedFileOrLinkId = 0,
    selectedFileOrLink = null,
}) => {
    const uploadRef = React.useRef();
    const [saving, setSaving] = useState(false);
    const { authTokens } = useAuth();
    const [selectedTab, setSelectedTab] = useState(0);
    const [errorData, setErrorData] = useState({
        fileError: false,
        linkError: false,
        labelError: false
    });
    const [label, setLabel] = useState('')
    const [loading, setLoading] = useState(false);
    const [file, setFile] = useState(null);
    const [link, setLink] = useState(null);

    useEffect(() => {
        if (selectedFileOrLink != null && selectedFileOrLink?.ObjectId) {
            if (selectedFileOrLink?.IsLink) {
                setLink(selectedFileOrLink?.FileLabel)
            } else {
                setFile({
                    name: selectedFileOrLink?.FileLabel
                })
            }
            setLabel(selectedFileOrLink?.FileNameOrLink)
        }
    }, [selectedFileOrLink])

    const handleFileUpload = async (event) => {
        event.preventDefault();

        // const allowedFileTypes = [
        //   "application/pdf",
        //   "application/msword",
        //   "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        //   "text/plain",
        // ];

        if (file != null) {
            const reader = new FileReader();

            reader.onloadend = async () => {
                const base64Data = reader.result;
                const formData = new FormData();
                formData.append("File", base64Data);
                formData.append("FileName", file?.name);
                formData.append(
                    "accessKey",
                    JSON.parse(localStorage.getItem("user_MessageObj"))
                );
                try {
                    const response = await axios.post(
                        `${process.env.REACT_APP_API_BASE_URL}/CC_API/UploadBaselineQuestionFile`,
                        formData,
                        {
                            headers: {
                                Authorization: `Bearer ${authTokens}`,
                            },
                        }
                    );
                    if (response?.data?.FileURL) {
                        handleSave(response?.data)
                    }
                } catch (error) {
                    console.error("Upload failed:", error);
                }
            };

            reader.readAsDataURL(file);
        } else {
            handleSave(null);
        }
        // setErrorData({
        //     fileError: false,
        //     linkError: false,
        //     labelError: false
        // });
        // setLabel('');
        // setFile(null);
        // setLink(null);
        // hideModal();
    };

    async function handleSave(data = null, isUpdate = false) {
        let resData = await fetch(
            `${process.env.REACT_APP_API_BASE_URL}/CC_API/CreateBaselineQuestionFile`,
            getRequestOptions(authTokens, "POST", data == null ? {
                ObjectId: selectedFileOrLinkId !== 0 ? selectedFileOrLinkId : 0,
                FileLabel: label,
                // FileName: ,
                AccessKey: JSON.parse(localStorage.getItem("user_MessageObj")),
                objId_BaselineQuestion: questionId,
                FileLink:link,
                // FileURL: undefined,
            } : {
                ObjectId: selectedFileOrLinkId !== 0 ? selectedFileOrLinkId : 0,
                FileLabel: label,
                FileName: isUpdate ? data?.FileLabel : data?.FileNameOrLink,
                AccessKey: JSON.parse(localStorage.getItem("user_MessageObj")),
                objId_BaselineQuestion: questionId,
                // FileLink: data?.FileURL,
                FileURL: data?.FileURL,
            })
        )
            .then((response) => response.json())
            .then((result) => {
                if (result?.Success) {
                    CallToast("Uploaded successfully", false, false, "v2style");
                } else {
                    CallToast("Something went wrong", false, false, "v2style");
                }
                setSaving(false);
                setErrorData({
                    fileError: false,
                    linkError: false,
                    labelError: false
                });
                setLabel('');
                setFile(null);
                setLink(null);
                hideModal();

            });
    }

    async function handleValidations() {
        let errCount = 0;

        if ((!file || file == '' || file == null) && (link == '' || link == null || link == 'null') == true) {
            setErrorData({
                ...errorData,
                fileError: true,
                linkError: true
            });
            errCount += 1;
            CallToast("You need to upload file or link", false, false, "v2style");
            setSaving(false);
            return errCount;
        } else {
            if (link != null && link != 'null' && link != '') {
                if (link?.startsWith("https://")) {
                    setErrorData({
                        ...errorData,
                        linkError: false
                    });
                } else {
                    CallToast("You'r link must start with 'https://'", false, false, "v2style");
                    setErrorData({
                        ...errorData,
                        linkError: true
                    });
                    errCount += 1;
                }
            }
        }

        if (label != null && label != 'null' && label != '') {
            setErrorData({
                ...errorData,
                labelError: false
            });
        } else {
            setErrorData({
                ...errorData,
                labelError: true
            });
            errCount += 1;
            CallToast("Label can not be empty", false, false, "v2style");
        }

        return errCount;
    };

    return (
        <Modal
            animation={false}
            show={show}
            dialogClassName="GandRUpdateFileModal "
            className="GandRUpdateFileModal my-auto"
            aria-labelledby=" "
            onHide={() => {
                setErrorData({
                    fileError: false,
                    linkError: false,
                    labelError: false
                });
                setLabel('');
                setFile(null);
                setLink(null);
                hideModal();
            }}
            centered
            scrollable
        >
            <Modal.Header className="text-center">
                <div
                    className="w-100 mb-0 font-bold-14 title"
                    style={{ fontWeight: "600" }}
                >
                    {
                        selectedFileOrLinkId != 0 ? 'Edit' : 'Add'
                    }
                    {" "}Resource
                </div>

                <span
                    className="cursor-pointer"
                    onClick={() => {
                        setErrorData({
                            fileError: false,
                            linkError: false,
                            labelError: false
                        });
                        setLabel('');
                        setFile(null);
                        setLink(null);
                        hideModal();
                    }}
                >
                    <img width={15} src="/images/attack-surface/cross-icon.svg" alt="" />
                </span>
            </Modal.Header>
            <Modal.Body
                className="transparent-scroller"
                style={{
                    paddingRight: "24px",
                    paddingLeft: "24px",
                    paddingTop: "16px",
                }}
            >
                {loading ? (
                    <div className="d-flex align-items-center justify-content-center w-100 h-100">
                        <Loader />
                    </div>
                ) : (
                    <>
                        <div className={`radius-4 highlight-input-with-grey-border mt-24   d-flex align-items-center p-2 radius-4
                        ${errorData?.labelError ? 'input-date-error' : ''
                            }
                        `}>
                            <div className="f-darkgrey">Label:</div>
                            <input
                                tabIndex={0}
                                type="text"
                                placeholder={`Type`}
                                className={`flex-grow-1 ml-2 ${label ? 'f-black' : 'f-darkgrey'}`}
                                value={label}
                                onChange={(e) => {
                                    setLabel(e?.target?.value)
                                    setErrorData({
                                        ...errorData,
                                        labelError: false
                                    })
                                }}
                            />
                        </div>

                        <Tab.Container
                            onSelect={(v) => {
                                setSelectedTab(Number(v));
                            }}
                            id="left-tabs-example"
                            defaultActiveKey={selectedTab}
                        >
                            <div
                                className="scenarioName bg-white d-flex align-items-start    pt-2  "
                                style={{ top: "101px", zIndex: 2 }}
                                id="navHeader"
                            >
                                <Nav.Item key={0} className={"  mt-2"}>
                                    <Nav.Link
                                        eventKey={0}
                                        className={`${selectedTab === 0 && "active"} mt-`}
                                        onClick={() => { }}
                                    >
                                        File
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item key={1} className={"  mt-2"}>
                                    <Nav.Link
                                        eventKey={1}
                                        className={`${selectedTab === 1 && "active"} mt-`}
                                        onClick={() => { }}
                                    >
                                        Link
                                    </Nav.Link>
                                </Nav.Item>
                            </div>

                            <Tab.Content className="mt-4">
                                <Tab.Pane eventKey={0}>
                                    {
                                        file != null && file?.name ? <div className="radius-4 bg-grey p-2  d-flex align-items-center justify-content-between mb-2">
                                            <div className="d-flex align-items-center">
                                                <img
                                                    src="/images/msp/upload-icon-placeholder.svg"
                                                    alt=""
                                                    className="mr-2"
                                                />
                                                <div>
                                                    <div className="f-500">
                                                        {file?.name}{" "}
                                                    </div>
                                                    {/* <div className="f-darkgrey">
                                                        {uploadedFile?.fileSize}
                                                    </div> */}
                                                </div>
                                            </div>
                                            <div className="d-flex align-items-center">
                                                <div
                                                    className="pointer d-flex align-item-center greyText2 f-12 f-500 mr-2"
                                                    onClick={() => {
                                                        setFile(null);
                                                    }}
                                                >
                                                    Delete
                                                </div>
                                                <div
                                                    className="hg-blue-btn-small f-12 f-500 radius-4 pointer"
                                                    onClick={() => {
                                                        uploadRef.current.click();
                                                    }}
                                                >
                                                    Update
                                                </div>
                                            </div>
                                        </div> :
                                            <div className={`radius-4 ${errorData?.fileError ? 'border-red' : 'border-dashed'} d-flex flex-column align-items-center justify-content-center w-100 p-3 mt-3 mb-3`}>
                                                <>
                                                    <img
                                                        width={20}
                                                        src="/images/msp/framework-upload-icon.svg"
                                                        alt=""
                                                    />
                                                    <div className="f-grey mt-2 w-fit-content">
                                                        Upload file or browse file on your computer
                                                    </div>
                                                    <div className="f-darkgrey my-2 w-fit-content">
                                                        Format pdf, doc, dox, txt
                                                    </div>
                                                    <Button
                                                        className="hg-cancel-btn"
                                                        onClick={() => {
                                                            uploadRef.current.click();
                                                        }}
                                                    >
                                                        Browse file
                                                    </Button>
                                                </>
                                            </div>
                                    }
                                </Tab.Pane>
                                <Tab.Pane eventKey={1}>
                                    <div className={`d-flex align-items-center radius-4 highlight-input-with-grey-border p-2 flex-grow-1 mr-1 mb-2 ${true}
                                    ${errorData?.linkError ? 'input-with-error-border' : 'highlight-input-with-grey-border'}
                                    `}>
                                        <div className="f-darkgrey">Link:</div>
                                        <input
                                            className={`w-100 ml-1 ${link ? 'f-black' : 'f-darkgray'}`}
                                            type="text"
                                            value={link}
                                            placeholder="Enter or paste link here"
                                            onChange={(e) => {
                                                setLink(e?.target?.value)
                                                setErrorData({
                                                    ...errorData,
                                                    linkError: false,
                                                    fileError: false
                                                })
                                            }}
                                        />
                                    </div>
                                </Tab.Pane>
                            </Tab.Content>
                        </Tab.Container>
                    </>
                )}

                <input
                    type="file"
                    accept=".pdf,.doc,.dox"
                    onChange={(e) => {
                        setFile(e?.target?.files[0])
                        setErrorData({
                            ...errorData,
                            linkError: false,
                            fileError: false
                        })
                    }}
                    className="d-none"
                    ref={uploadRef}
                    multiple
                />
            </Modal.Body>

            <Modal.Footer className="p-2 d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center justify-content-end w-100">
                    <Button
                        className="hg-cancel-btn mr-2"
                        onClick={() => {
                            setErrorData({
                                fileError: false,
                                linkError: false,
                                labelError: false
                            });
                            setLabel('');
                            setFile(null);
                            setLink(null);
                            hideModal();
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        className="hg-blue-btn"
                        onClick={async (e) => {
                            setSaving(true);
                            let errorCount = await handleValidations();

                            if (errorCount > 0) {
                                setSaving(false)
                                return;
                            } else {
                                if (selectedFileOrLinkId != 0 && !file?.lastModified) {
                                    if (selectedFileOrLink?.IsLink) {
                                        handleSave(null)
                                    } else {
                                        handleSave(selectedFileOrLink, true)
                                    }
                                } else {
                                    handleFileUpload(e)
                                }
                                // setErrorData({
                                //     fileError: false,
                                //     linkError: false,
                                //     labelError: false
                                // });
                                // setLabel('');
                                // setFile(null);
                                // setLink(null);
                                // hideModal();
                            }
                        }}

                    >
                        {saving ? "Uploading.." : "Upload"}
                    </Button>
                </div>
            </Modal.Footer>
        </Modal>
    );
};

export default AddResourceModal;
