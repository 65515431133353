import { MENU_COLLAPSE } from "./constants";

/**
 * Increments in calculator value
 *
 * @param {Boolean} data
 */
export const menuToggle = (data) => {
  return { type: MENU_COLLAPSE, data };
};
