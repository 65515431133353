import { Spinner } from "react-bootstrap";

const IntegrationItem = ({ integrationName, integrationId, isLoading, onAdd }) => {
  return (
    <div className="d-flex align-items-center justify-content-between w-100">
      <div className="d-flex align-items-center">
        <img
          alt=""
          style={{
            width: "16px",
            height: "16px",
            borderRadius: "50%",
            marginRight: "5px",
          }}
          src={`/images/attack-surface/integrations/${integrationId}.webp`}
          // className="ml-3"
          onError={({ currentTarget }) => {
            currentTarget.onerror = null;
            currentTarget.src =
              "/images/attack-surface/generic2.svg";
          }}
        />
        <div className="f-grey">{integrationName}</div>
      </div>
      <div className="pr-2">
        {isLoading ? (
          <Spinner
            animation="border"
            variant="secondary"
            size="sm"
            className="mx-2"
            style={{
              width: "12px",
              height: "12px",
            }}
          />
        ) : (
          <button className="integration-add select-tool-btn" onClick={onAdd}>
            <img
              alt=""
              src="/images/attack-surface/add-tool-icon.svg"
              className="mr-2"
              width={11}
              height={11}
              style={{ top: '-1px', position: 'relative' }}
            />
            <span className="f-grey">Add</span>
          </button>
        )}
      </div>
    </div>
  )
}

export default IntegrationItem;
