import React, { useState, useEffect, Fragment } from "react";
import { Link } from "react-router-dom";
import { useAuth } from "../../context/Auth";
import {
  Button,
  Modal,
  Row,
  Col,
  Tabs,
  Tab,
  InputGroup,
  Spinner,
  FormControl,
  Form,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { FiChevronLeft, FiCheck, FiX } from "react-icons/fi";
import LoadingSpinner from "../../Spinner/Spinner";
import { getAPIData, getRequestOptions } from "../../../Utils/commonApi";
import { useDb } from "../../context/db";
import { ToastContainer, toast } from "react-toastify";
import CircularProgressBar from "../../CircularProgressBar";
import { TrackUser } from "../../../Utils/SegmentFunctions";

const EnquiryModal = (props) => {
  const { authTokens } = useAuth();
  const [error, setError] = useState(false);
  const { dbObj } = useDb();
  const [phoneNo, setPhoneNo] = useState();
  const [formDescription, setformDescription] = useState("");
  const message = error ? error : "";
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);

  const renderTooltip = (message) => (
    <Tooltip className="custom_tooltip">
      <div className="custom_tooltip_wrapper">
        <div className="tooltip_header border-bottom-0">
          <span className="tooltip_subtitle" style={{ color: "#F36060" }}>
            {message}
          </span>
        </div>
      </div>
    </Tooltip>
  );

  const handleValidations = () => {
    var pattern = /^\+?([0-9]{0,2})\)?[-. ]?([0-9]{10,16})$/;

    if (!formDescription && !phoneNo) {
      setError("All fields are required");
      return true;
    }
    if (!phoneNo) {
      setError("Phone No. is required");
      return true;
    }
    if (phoneNo) {
      if (!pattern.test(phoneNo)) {
        setError("Please enter a valid phone number (min 10 digits)");
        return true;
      }
    }
    if (!formDescription) {
      setError("Description is required");
      return true;
    }
    return false;
  };

  async function onEnquireHandler(event) {
    event.preventDefault();
    if (handleValidations()) {
      return;
    } else {
      setError(false);
      setIsLoading(true);
      var message = JSON.parse(localStorage.getItem("user_MessageObj"));
      const enquireData = {
        TicketDataJSON: JSON.stringify({
          TicketDetails: {
            ContactName: dbObj.UserDetail.FullName,
            ContactEmail: dbObj.UserDetail.UserName,
            ContactPhone: phoneNo,
          },
          TicketNoteDetails: {
            Description: formDescription,
            ProServices: "Enterprise",
          },
          TicketType: 2,
        }),
      };
      const data = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/CC_API/CreateConnectwiseTicket`,
        getRequestOptions(authTokens, "POST", enquireData)
      ).then((response) => response.json());
      if (data.Success == true) {
        setIsLoading(false);
        setPhoneNo("");
        setformDescription("");
        TrackUser("Pro Services Enquire Sent");
      } else {
        setIsLoading(false);
        setError(data.Message);
      }
    }
    setIsSubmit(true);
  }

  return (
    <Modal
      animation={false}
      show={props.show}
      className="right modal-medium rightModal headerBtnModal  userflow-issue"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={props.closeModal}
    >
      {isLoading && (
        <div className="loader_wrapper loader_enquiry">
          <div className="loader_enquiry_inner">
            <div className="loader">
              <div className="text-center">
                <div className="loader_circle" />
                <p className="mt-3">Sending...</p>
              </div>
            </div>
          </div>
        </div>
      )}

      <Modal.Header
        style={{ display: "inline-block", padding: "36px 24px 0px 28px" }}
      >
        <div className="d-flex">
          <span
            className="cursor-pointer align-self-center"
            onClick={() => {
              setIsSubmit(false);
              props.closeModal(false);
            }}
          >
            <img src="/images/icons/close.svg" alt="" />
          </span>
          <div className="heading4 align-self-center ml16px">Enquire</div>
        </div>
        {!isSubmit && (
          <>
            <div className="greyText mb8px mt-5">Ask about:</div>
            <div class="heading4">Enterprise</div>
          </>
        )}
      </Modal.Header>
      <Modal.Body
        className="p24px"
        style={{
          maxHeight: "calc(100vh - 210px)",
          overflowY: "auto",
        }}
      >
        {isSubmit ? (
          <Fragment>
            <div className="position-relative h-100">
              <div className="center-container text-center">
                <div className="circle-green">
                  <FiCheck color="#ffffff" size="36" />
                </div>
                <div className="heading4 mt40px">
                  Your enquiry has been submitted successfully
                </div>
                <div className="greyText mt16px">
                  A member of our Professional Services Team will be in contact
                  shortly
                </div>
              </div>
            </div>{" "}
          </Fragment>
        ) : (
          <Fragment>
            <p
              className="greyText mb24px"
              // style={{ opacity: enquireSubmit == 1 ? 0.2 : "" }}
            >
              The question will be send from:
              <br />
              Name: <b className="text-dark">{dbObj.UserDetail.FullName}</b>
              <br />
              Company: <b className="text-dark">{dbObj.UserDetail.UserName}</b>
            </p>
            <hr />
            <div className="greyText">Details</div>
            <Form>
              <Form.Group>
                <InputGroup className="grey-input">
                  <InputGroup.Prepend>
                    <InputGroup.Text className="greyText">
                      Phone:
                    </InputGroup.Text>
                  </InputGroup.Prepend>
                  <FormControl
                    name="IntegrationName"
                    value={phoneNo}
                    onChange={(event) =>
                      setPhoneNo(
                        event.target.value
                          .toString()
                          .replace(/<\/?[^>]+>/gi, "")
                      )
                    }
                  />
                </InputGroup>
              </Form.Group>
              <Form.Group className="mt-16px">
                <Form.Control
                  name="IntegrationComment"
                  placeholder="Your question..."
                  as="textarea"
                  value={formDescription}
                  onChange={(event) =>
                    setformDescription(
                      event.target.value.replace(/<\/?[^>]+>/gi, "")
                    )
                  }
                  rows={5}
                />
              </Form.Group>
            </Form>
            {error != false && (
              <Form.Control.Feedback type="invalid" className="d-block pt-3">
                <OverlayTrigger
                  placement="bottom"
                  delay={{ show: 200, hide: 250 }}
                  overlay={renderTooltip(message)}
                >
                  <span>
                    <img alt="" src="/images/icons/error.svg" /> Enquire attempt
                    failed
                  </span>
                </OverlayTrigger>
              </Form.Control.Feedback>
            )}
          </Fragment>
        )}
      </Modal.Body>
      <Modal.Footer style={{ border: "0px" }}>
        <Button
          className={`w-100 
            btn-blue p16px w-100 ${isSubmit ? "green-btn" : "submit-btn"}`}
          onClick={(event) => {
            if (isSubmit) {
              setIsSubmit(false);
            } else {
              onEnquireHandler(event);
            }
          }}
          style={{ bottom: 0 }}
        >
          {isSubmit ? "Okay, Go Back" : "Enquire Now"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default EnquiryModal;
