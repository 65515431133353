import React, { useEffect, useState } from "react";
import { Route, Redirect } from "react-router-dom";
import { useAuth } from "../context/Auth";
import { FullScreenLoader } from "../Common/loader";
import { useLocation } from "react-router-dom/cjs/react-router-dom";

function PrivateRoute({ component: Component, render, ...rest }) {
  const { auth, isAuthenticated, loading } = useAuth();
  const location = useLocation();

  function allowedURL() {
    if (location.pathname === "/login") {
      return true;
    }
    if (location.pathname === "/signup") {
      return true;
    }
    if (location.pathname.includes("login")) {
      return true;
    }
    if (location.pathname.includes("signup")) {
      return true;
    }

    return false;
  }

  if (loading) {
    return <FullScreenLoader />;
  }

  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated ||
        auth ||
        (isAuthenticated === false && allowedURL()) ? (
          // <Component {...props} />
          Component ? (
            <Component {...props} />
          ) : render ? (
            render(props)
          ) : null
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: {
                // redirectUser: allowedURL() ? false : true,
                // redirectUrl: allowedURL()
                //   ? ""
                //   : `${window.location.pathname}${window.location.search}`,
              },
            }}
          />
        )
      }
    />
  );
}

export default PrivateRoute;
