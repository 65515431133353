import React, { useState, useEffect } from "react";
import {
  Button,
  Modal,
  Row,
  Col,
  InputGroup,
  Form,
  OverlayTrigger,
  FormControl,
  Tooltip,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import "./index.scss";

const PaymentResponse = (props) => {
  const { data, isError, service, bulletPoints } = props;
  return (
    <div className="w-100 mt-5">
      <div className="d24" />
      <div className="d24" />
      <div className="d24" />

      <>
        <div className="text-center">
          {data && data.ResultIcon && data.ResultIcon.search("RedCircle") > 0 && (
            <img src="/images/rfail.svg" alt="" />
          )}

          {data && data.ResultIcon && data.ResultIcon.search("GreenCircle") > 0 && (
            <img src="/images/rsuccess.svg" alt="" />
          )}

          {data && data.status && data.status == "succeeded" &&  <img src="/images/rsuccess.svg" alt="" />}
          {isError &&  <img src="/images/rfail.svg" alt="" />}

          <p
            className="txtfail mt-4"
            style={{
              color: `${data && data.ResultColour ? data.ResultColour : ""}`,
            }}
          >
            {data && data.ResultText && data.ResultText}
          </p>
        
        </div>
        <div className="responseList mt-4">
          <p>
            {data && data.DescriptionText && data.DescriptionText}
            {data && data.message}
            {data && data.Message && data.Message }

          
          </p>
          {data && data.status == "succeeded" && (
            <p>Thanks for buying {service && service.Title}</p>
          )}

      

          <Button onClick={() => {
            if(data && data.ButtonText == "Try Again"){
              props.isClose()
            }else{
              props.isClose()
              props.closeModal()
            }
          }} className="w-100 blubtn mt-4">
            {data && data.ButtonText}
          </Button>
          <div className="d24" />
          <div className="d24" />
          <div className="d24" />
          <div className="d24" />
        </div>
      </>

    </div>
  );
};

export default PaymentResponse;
