import React, { useEffect, useRef, useState, lazy, Suspense } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import {
  Button,
  Col,
  Dropdown,
  OverlayTrigger,
  Row,
  Tooltip,
} from "react-bootstrap";
import "../supplierProfiler/supplier-profiler.scss";

import Assessment from "./Assessments";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom";
import OptionsDropdown from "./ProfilerDropdowns/OptionsDropdown";
import SupplierProfilerNotes from "./Notes/SupplierProfilerNotes";
import SupplierProfilerFiles from "./Files/SupplierProfilerFiles";
import SupplierProfilerSecurityRatings from "./securityRatings/SupplierProfilerSecurityRatings";
import RevokeContributorsDropdown from "./ProfilerDropdowns/RevokeContributorsDropdown";
import ImportSuppliersModal from "./ImportSuppliersModal";
// import EditSupplierModal from "../AttackSurface/Suppliers/EditSupplierModal";
import { getAPIData, postData } from "../../Utils/commonApi";
import { CallToast } from "../PriceCalculator/modals/CustomToast";
import { useAuth } from "../context/Auth";
import ChooseSupplierModal from "./ChooseSupplierModal";
import { DeleteModal } from "../DeleteModal/DeleteModal";
import EditPotentialSupplierModal from "../AttackSurface/Suppliers/EditPotentialSupplierModal";

const EditSupplierModal = lazy(() =>
  import("../AttackSurface/Suppliers/EditSupplierModal")
);

const menuTabs = ["Assessment", "Security Ratings", "Recon", "Files", "Notes"];

const editSupplierData = {
  PotentialSupplierName: "Request to M3 Networks ltd.",
  supplierName: "M3 Networks ltd.",
  supplierEmail: "m3@r.com",
  supplierWebsite: "www.f.com",
  supplierServicesProvided: [],
  nameError: "",
  emailError: "",
  serviceError: "",
};
const ProfilingModalContent = ({
  hideModal,
  isTemplate,
  setSelectedTemplate,
  selectedTemplate,
  setShowSupplierModal,
  showSupplierModal,
  setTitleTemplate,
  selectedSupplier,
}) => {
  const history = useHistory();
  const { state } = useLocation();
  const { authTokens } = useAuth();

  const [saving, setSaving] = useState(false);
  // Conditional States

  const [pageStatus, setPageStatus] = useState("Assessment");
  const [profilingBlocks, setProfilingBlocks] = useState([]);
  const [importantSuppliers, setImportantSuppliers] = useState(false);
  const [showEditSupplier, setShowEditSupplier] = useState(false);
  const [loading, setLoading] = useState(false);
  const [AllProfilingObj, setAllProfilingObjj] = useState({});
  const [templates, setTemplates] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteSentQuestionnaireId, setDeleteSentQuestionnaireId] =
    useState("");
  const [selectedTemplateObject, setSelectedTemplateObject] = useState({});
  const [defaultTemplate, setDefaultTemplate] = useState({});
  const [securityRatingsData, setSecurityRatingsData] = useState({});

  // const [selectedTemplate, setSelectedTemplate] = useState([]);
  // const [showSupplierModal, setShowSupplierModal] = useState(false);

  //compilance modal

  const [questionId, setQuestionId] = useState(); //ObjId_SendFormQuestionnaire

  useEffect(() => {
    setLoading(true);
    getTemplates();
  }, []);
  useEffect(() => {
    setLoading(true);
    if (questionId) getProfilingTemplatesWithAnswers();
  }, [questionId]);

  async function getProfilingTemplatesWithAnswers() {
    setLoading(true);
    await getAPIData(754, authTokens, {
      optionEnum1: questionId,
      optionEnum2: 0,
      optionEnum3: 0,
    }).then((response) => {
      if (response?.mr?.Success) {
        setLoading(false);
        // console.log(response);
        setAllProfilingObjj(response);
        setProfilingBlocks(response.AssesmentVm?.QuestionnaireSection_List);
        setSecurityRatingsData(response.SecurityRatings);
        // let temp = [];
        // let newObj = response.AssesmentVm.QuestionnaireSection_List.map(b => {
        //   temp.push({ ObjectId: b.ObjectId, show: false });
        // });
        // setShowBlocks(temp)
      } else {
        CallToast("Something went wrong", false, false, "v2style");
        setLoading(false);
      }
    });
  }
  async function getTemplates() {
    // setLoading(true);
    await getAPIData(753, authTokens, {
      optionEnum1: 1,
    }).then((response) => {
      if (response?.mr?.Success) {
        // setLoading(false);
        console.log(response);
        setTemplates(response.SCMAllFormTemplate_List);
        setQuestionId(response.SCMAllFormTemplate_List[0]?.ObjectId);
        setSelectedTemplate(response.SCMAllFormTemplate_List[0]?.ObjectId);
        setSelectedTemplateObject(response.SCMAllFormTemplate_List[0]);
        setTitleTemplate(response.SCMAllFormTemplate_List[0]?.Title);
        let defTem = response.SCMAllFormTemplate_List.find(
          (t) => !t.IsUserDefined
        );
        setDefaultTemplate(defTem);
      } else {
        CallToast("Something went wrong", false, false, "v2style");
        // setLoading(false);
      }
    });
  }
  async function duplicateTemplate(template, modeTemplate, isModal) {
    // setLoading(true);
    await getAPIData(757, authTokens, {
      optionEnum1: template ? template.ObjectId : questionId,
      optionEnum2: modeTemplate ? modeTemplate : 0,
    }).then(async (response) => {
      if (response?.Success) {
        // setLoading(false);
        console.log(response);
        setSelectedTemplate(response.EntityId);
        if (selectedSupplier) {
          await postData(authTokens, "SendQuestionnaireForProfiling", {
            Suppliers: JSON.stringify([selectedSupplier]),
            Title: selectedTemplateObject?.Title ?? "",
            FormTemplateId: selectedTemplate, //supp?.objId_Customer, //supplier id
          }).then(async (response) => {
            if (response?.Success) {
              history.push("/supplier-profiler/profiling", {
                backUrl: `${window.location.pathname}?${new URLSearchParams(
                  window.location.search
                ).toString()}`,
                isContributorView: false,
                isTemplate: false,
                questionId: response.EntityId,
              });
            }
          });
        } else if (isModal) {
          setShowSupplierModal(true);
        } else {
          history.push("/supplier-profiler/profiling", {
            backUrl: `${window.location.pathname}?${new URLSearchParams(
              window.location.search
            ).toString()}`,
            isContributorView: false,
            isTemplate: true,
            isTemplateEdit: true,
            questionId: response.EntityId,
          });
        }
      } else {
        CallToast("Something went wrong", false, false, "v2style");
        // setLoading(false);
      }
    });
  }
  async function deleteTemplate(id) {
    // setLoading(true);
    await getAPIData(776, authTokens, {
      optionEnum1: id,
    }).then((response) => {
      if (response?.Success) {
        // setLoading(false);
        console.log(response);
        getTemplates();
      } else {
        CallToast("Something went wrong", false, false, "v2style");
        // setLoading(false);
      }
    });
  }

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  function onDragEndShown(result) {
    if (!result.destination) {
      return;
    }
    // console.log(result.source.index);
    const items = reorder(
      profilingBlocks,
      result.source.index,
      result.destination.index
    );
    setProfilingBlocks(
      items.map((item, index) => {
        return {
          ...item,
          SortOrder: index + 1,
        };
      })
    );
  }

  return (
    <div
      className="supplier-profiler profilingModal d-flex"
      id="modal-profiling"
    >
      <div
        className="w-22 top-0 hide-scrollbar height_block_scroll_left"
        style={{
          padding: "16px",
          overflowY: "scroll",
          position: "relative",
        }}
      >
        <div
          className="d-flex align-items-center f-500 f-black bg-white"
          style={{ paddingBottom: "8px" }}
        >
          Templates
        </div>
        <div
          className={`d-flex align-items-center radius-4 highlight-input-with-grey-border py-2 flex-grow-1 mr-1 ${true} cursor-pointer`}
        >
          {/* <input
              type="text"
              className="bg-transparent w-100 border-none pl-2"
              placeholder="Add template"
              value={""}
              onChange={(e) => {
                // setEmployeeData({
                //   ...employeeData,
                //   memberName: e.target.value,
                // });
              }}
            /> */}
          <img src={`/images/supply-chain/add-base-icon.svg`} alt="" />
          <div
            className="bg-transparent w-100 border-none pl-2 f-grey"
            onClick={() => {
              duplicateTemplate(defaultTemplate, 2);
            }}
          >
            <div>Add template</div>
          </div>
        </div>
        <div className="f-12 f-darkgrey pt-2">All templates</div>
        <div>
          {templates.map((template, i) => {
            return (
              <div
                className={`cursor-pointer w-100 f-grey thread ${
                  selectedTemplateObject.ObjectId == template.ObjectId
                    ? "thread-selected"
                    : ""
                }`}
                style={{
                  display: "grid",
                  gridTemplateColumns: "10% 90%",
                  padding: "8px",
                }}
                onClick={() => {
                  setQuestionId(template.ObjectId);
                  setSelectedTemplate(template.ObjectId);
                  setSelectedTemplateObject(template);
                }}
              >
                {/* <img alt="" src={template.Url} className="mr-2" /> */}
                <img
                  alt=""
                  src={"/images/supply-chain/profiling-icon.svg"}
                  className="mr-2"
                />
                <div className="w-100">{template?.Title}</div>
              </div>
            );
          })}
        </div>
      </div>
      <div className="w-78">
        <div
          className="w-100 position-sticky d-flex align-items-center justify-content-between border-bottom p-2 px-4 bg-white border-left "
          style={{
            zIndex: "3",
          }}
        >
          <div className="d-flex align-items-center f-500 f-black bg-white">
            {/* Title of Template */}
            {selectedTemplateObject?.Title}
          </div>
          <div className="d-flex align-items-center">
            <Button
              className="hg-blue-btn"
              onClick={() => {
                hideModal();
                duplicateTemplate(null, 0, true);
              }}
            >
              Use this Template
            </Button>
            <div className="">
              {/* <Dropdown className="device-dropdown"> */}
              <Dropdown className="hg-white-dropdown user-detail-dropdown supplier-menu">
                <Dropdown.Toggle className="custom-dropdown-toggle ">
                  <img alt="" src="/images/horizontal-dots.svg" />
                </Dropdown.Toggle>
                <Dropdown.Menu className=" mt-1 ">
                  <div
                    className="drop-item p-2 pl-3 my-1 pointer f-grey"
                    onClick={(e) => {
                      if (selectedTemplateObject.IsUserDefined) {
                        history.push("/supplier-profiler/profiling", {
                          backUrl: `${
                            window.location.pathname
                          }?${new URLSearchParams(
                            window.location.search
                          ).toString()}`,
                          isContributorView: false,
                          isTemplate: true,
                          isTemplateEdit: true,
                          questionId: questionId,
                        });
                      } else {
                        CallToast(
                          "Cannot edit default template.",
                          false,
                          false,
                          "v2style"
                        );
                      }

                      e.target.closest(".hg-white-dropdown").click();
                    }}
                  >
                    Edit
                  </div>

                  <div
                    className="drop-item p-2 pl-3 my-1 pointer f-grey"
                    onClick={(e) => {
                      duplicateTemplate(null, 1);
                      e.target.closest(".hg-white-dropdown").click();
                    }}
                  >
                    Duplicate
                  </div>

                  <div
                    className="d-flex align-items-center justify-content-between drop-item f-danger p-2 pl-3 my-1 pointer"
                    onClick={(e) => {
                      if (selectedTemplateObject.IsUserDefined) {
                        setShowDeleteModal(true);
                        setDeleteSentQuestionnaireId(questionId);
                      } else {
                        CallToast(
                          "Cannot delete default template.",
                          false,
                          false,
                          "v2style"
                        );
                      }
                      e.target.closest(".hg-white-dropdown").click();
                    }}
                  >
                    Delete
                  </div>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
        </div>

        <div className="d-flex justify-content-between ">
          {/* <div className="w-28  h-100 top-0" style={{ padding: "16px" }}>
          <div
            className={`d-flex align-items-center radius-4 highlight-input-with-grey-border py-2 flex-grow-1 mr-1 ${true} cursor-pointer`}
          >
            <img src={`/images/supply-chain/add-base-icon.svg`} alt="" />
            <div
              className="bg-transparent w-100 border-none pl-2 f-grey"
              onClick={() => {
                duplicateTemplate(defaultTemplate);
              }}
            >
              <div>Add template</div>
            </div>
          </div>
          <div className="f-12 f-darkgrey pt-2">All templates</div>
          <div>
            {templates.map((template, i) => {
              return (
                <div
                  className={`cursor-pointer w-100 f-grey thread ${
                    selectedTemplateObject.ObjectId == template.ObjectId
                      ? "thread-selected"
                      : ""
                  }`}
                  style={{
                    display: "grid",
                    gridTemplateColumns: "10% 90%",
                    padding: "8px",
                  }}
                  onClick={() => {
                    setQuestionId(template.ObjectId);
                    setSelectedTemplate(template.ObjectId);
                    setSelectedTemplateObject(template);
                  }}
                >
                  <img
                    alt=""
                    src={"/images/supply-chain/profiling-icon.svg"}
                    className="mr-2"
                  />
                  <div className="w-100">{template?.Title}</div>
                </div>
              );
            })}
          </div>
        </div> */}
          <div
            className={`${
              pageStatus === "Assessment" ? "w-72 border-right" : "w-83"
            } main-block  border-left height_block_scroll hide-scrollbar`}
            style={{
              overflowY: "scroll",
            }}
          >
            <div className="btn-block d-flex flex-row">
              {menuTabs.map((tab) => {
                if (tab === pageStatus) {
                  return (
                    <Button
                      key={tab}
                      className="btn-blue "
                      onClick={() => {
                        setPageStatus(tab);
                      }}
                    >
                      {pageStatus}
                    </Button>
                  );
                } else {
                  return (
                    <Button
                      key={tab}
                      className="hg-cancel-btn"
                      onClick={() => {
                        setPageStatus(tab);
                      }}
                    >
                      {tab}
                    </Button>
                  );
                }
              })}
            </div>
            {pageStatus === "Assessment" && (
              <Assessment
                blockItems={profilingBlocks}
                setProfilingBlocks={setProfilingBlocks}
                //   refreshData={refreshData}
                loading={loading}
                questionId={questionId}
                setSaving={setSaving}
                AllProfilingObj={AllProfilingObj}
                isTemplate={isTemplate}
                // isTemplateEdit={isTemplateEdit}
              />
            )}

            {pageStatus === "Notes" && (
              <SupplierProfilerNotes
                questionId={questionId}
                isTemplate={isTemplate}
              />
            )}
            {pageStatus === "Files" && (
              <SupplierProfilerFiles
                questionId={questionId}
                isTemplate={isTemplate}
              />
            )}
            {pageStatus === "Recon" && (
              <div className="w-100">
                <div className="w-100 d-flex justify-content-center">
                  <img
                    src="/images/msp/recon-welcome-pic.svg"
                    alt=""
                    className="mt-4"
                  />
                </div>
              </div>
            )}
            {pageStatus === "Security Ratings" && (
              <SupplierProfilerSecurityRatings
                questionId={questionId}
                isTemplate={isTemplate}
                securityRatingsData={securityRatingsData}
              />
            )}
          </div>

          {pageStatus === "Assessment" && (
            <div
              className="hide-scrollbar top-0 bg-white w-28 right-0 p-2 height_block_scroll"
              style={{
                overflowY: "scroll",
                overflowX: "scroll",
                //   top: "48px",
                position: "relative",
              }}
            >
              {!loading && (
                <div>
                  <div className="f-12 f-darkgrey py-20px">
                    {" "}
                    Profiling Process
                  </div>
                  <DragDropContext onDragEnd={onDragEndShown}>
                    <Droppable
                      direction="vertical"
                      droppableId="droppableSection"
                    >
                      {(provided, snapshot) => (
                        <div
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                        >
                          <div className="info-timeline">
                            <ul>
                              {profilingBlocks &&
                                profilingBlocks.map((section, i) => (
                                  // <Draggable
                                  //   key={section?.Title}
                                  //   draggableId={section?.ObjectId.toString()}
                                  //   index={i}
                                  //   // isDragDisabled
                                  // >
                                  //   {(provided, snapshot) => (
                                  <li>
                                    <div
                                      ref={provided.innerRef}
                                      // {...provided.draggableProps}
                                      // {...provided.dragHandleProps}
                                      className=" d-flex flex-row thread pl-2"
                                      key={i}
                                      index={i}
                                    >
                                      <span className="timeline-circle"></span>
                                      <div
                                        className="d-flex flex-row justify-content-between ml-2 py-6px w-100"
                                        onClick={() => {
                                          let dropDown_toggle =
                                            document.getElementsByClassName(
                                              section.ObjectId
                                            )[0];

                                          dropDown_toggle.scrollIntoView({
                                            behavior: "smooth",
                                            block: "center",
                                            inline: "nearest",
                                          });
                                        }}
                                      >
                                        <div className="">{section?.Title}</div>
                                        <img
                                          alt=""
                                          src="/images/supply-chain/drag-n-drop.svg"
                                          className="mr-2 hover-visible"
                                        />
                                      </div>
                                    </div>
                                  </li>
                                  //   )}
                                  // </Draggable>
                                ))}
                              <li>
                                <div className=" d-flex flex-row thread pl-2 addSection">
                                  <span className="timeline-circle">+</span>
                                  <div
                                    className="d-flex flex-row justify-content-between ml-2 py-6px w-100"
                                    onClick={() => {
                                      let dropDown_toggle =
                                        document.getElementsByClassName(
                                          "add-section"
                                        )[0];

                                      dropDown_toggle.scrollIntoView({
                                        behavior: "smooth",
                                        block: "center",
                                        inline: "nearest",
                                      });
                                    }}
                                  >
                                    <div className="">Add section</div>
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </div>
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                  <div className="d-flex flex-row ml-2"></div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>

      {/* <ImportSuppliersModal
        show={importantSuppliers}
        hideModal={() => setImportantSuppliers(false)}
        complianceProfileId={3688580}
      /> */}
      <DeleteModal
        show={showDeleteModal}
        hideModal={() => setShowDeleteModal(false)}
        deleteMessage={"You are about to delete a template."}
        deleteType={"deleteSentQuestionnaire"}
        deleteSentQuestionnaire={() => {
          deleteTemplate(deleteSentQuestionnaireId);
        }}
      />
      <Suspense fallback={<div>Loading...</div>}>
        <EditPotentialSupplierModal
          show={showEditSupplier}
          hideModal={() => setShowEditSupplier(false)}
          editSupplierData={editSupplierData}
          mode={"addasasupplier"}
        />
      </Suspense>
    </div>
  );
};

export default ProfilingModalContent;

// const Tooltip = ({ text, position }) => {
//   const style = {
//     position: 'absolute',
//     left: position.x,
//     top: position.y,
//     // Additional styling for the tooltip
//   };

//   return (
//     <div className="tooltip" style={style}>
//       {text}
//     </div>
//   );
// };
