import React from "react";
import { Button, Modal } from "react-bootstrap";
import { getAPIData } from "../../../Utils/commonApi";
import { useAuth } from "../../context/Auth";
import './mspClientPriceCalculator.scss';
import { CallToast } from "../../PriceCalculator/modals/CustomToast";

export const UpdateCyberKPIModal = ({
    show,
    clientId,
    packageId,
    hideModal
}) => {
    const { authTokens } = useAuth();
    function updateCyberKPI() {
        return new Promise((resolve, reject) => {
            getAPIData(974, authTokens, {
                optionEnum1: packageId,
                optionEnum2: clientId,
            })
                .then((response) => {
                    if (response?.Success) {
                        CallToast("Cyber KPI changed successfully", false, false, "v2style");
                        hideModal();
                    }
                    // resolve(response);
                })
                .catch((error) => {
                    CallToast("Something went wrong", false, false, "v2style");
                    hideModal();
                    // reject(error);
                });
        });
    }

    return (
        <Modal
            animation={false}
            show={show}
            dialogClassName="update-cyber-kpi-modal"
            className="update-cyber-kpi-modal"
            aria-labelledby="update-cyber-kpi-modal"
            centered
            scrollable
            onHide={() => {
                hideModal();
            }}
        >
            <Modal.Header className="">
                <div className="f-500 f-16 f-black line-height-28 letter-space-2">
                    Remove security attestations?
                </div>
            </Modal.Header>
            <Modal.Body>
                <div className="f-400 f-14 f-black line-height-22 letter-space-2">
                    This client has security attestations which are not included in this package. Would you like to keep them or remove them?
                </div>
            </Modal.Body>
            <Modal.Footer>
                <div>
                    <Button
                        className="hg-blue-btn w-100 mb-2"
                        onClick={() => {
                            updateCyberKPI();
                        }}
                    >
                        Keep
                    </Button>
                    <Button
                        className="hg-warning-btn w-100"
                        onClick={() => {
                            hideModal();
                        }}
                    >
                        Remove
                    </Button>
                </div>
            </Modal.Footer>
        </Modal>
    );
};

export default UpdateCyberKPIModal;
