import React, { useEffect } from "react";
import "./new-custom.scss";
const SenstivitySlider = ({
  min,
  max,
  step,
  id,
  defaultValue,
  onChange,
  value,
  onMouseUp,
  labelData,
}) => {
  //   useEffect(() => {
  //     let value = ((defaultValue - min) / (max - min)) * 100;

  //     const slider = document.getElementById(id);

  //     slider.style.background =
  //       "linear-gradient(to right, #f6f8fb 0%, #f6f8fb " +
  //       value +
  //       "%, #fff " +
  //       value +
  //       "%, white 100%)";
  //   }, [defaultValue]);

  //   function progressColor(rangeValue) {
  //     let value = ((rangeValue - min) / (max - min)) * 100;

  //     const slider = document.getElementById(id);

  //     slider.style.background =
  //       "linear-gradient(to right, #f6f8fb 0%, #f6f8fb " +
  //       value +
  //       "%, #fff " +
  //       value +
  //       "%, white 100%)";
  //   }

  const color = (index, labelData, value) => {
    if (
      Math.ceil((index / (labelData.length - 1)) * 100) ===
        Math.ceil((value / max) * 100) &&
      value === 0
    ) {
      return "red";
    } else if (
      Math.ceil((index / (labelData.length - 1)) * 100) ===
        Math.ceil((value / max) * 100) &&
      value === 25
    ) {
      return "red";
    } else if (
      Math.ceil((index / (labelData.length - 1)) * 100) ===
        Math.ceil((value / max) * 100) &&
      value === 50
    ) {
      return "medium";
    } else if (
      Math.ceil((index / (labelData.length - 1)) * 100) ===
        Math.ceil((value / max) * 100) &&
      value === 75
    ) {
      return "green";
    } else if (
      Math.ceil((index / (labelData.length - 1)) * 100) ===
        Math.ceil((value / max) * 100) &&
      value === 75
    ) {
      return "green";
    } else {
      return "normal";
    }
  };

  return (
    <div
      className={`senstivity-slider
      
      
      ${
        true
        // value === 0 || value === 25
        //   ? "low"
        //   : value === 50
        //   ? "medium"
        //   : value === 75 || value === 100
        //   ? "high"
        //   : ""
      }
      
      
      ${value == 0 ? "low" : ""}

      ${value == 25 ? "low" : ""}
      ${value == 50 ? "medium" : ""}
      ${value == 75 ? "high" : ""}
      ${value == 100 ? "high" : ""}
      
      
      
      
      `}
    >
      <div className="d-flex justify-content-between ml-2 mb-3 mt-2">
        {/* {labelData &&
          labelData.map((label, index) => {
            return (
              <div
                key={index}
                className={` ${
                  Math.ceil((index / (labelData.length - 1)) * 100) ===
                    Math.ceil((value / max) * 100) && value === 0
                    ? "red"
                    : Math.ceil((index / (labelData.length - 1)) * 100) ===
                        Math.ceil((value / max) * 100) && value === 25
                    ? "red"
                    : Math.ceil((index / (labelData.length - 1)) * 100) ===
                        Math.ceil((value / max) * 100) && value === 50
                    ? "medium"
                    : Math.ceil((index / (labelData.length - 1)) * 100) ===
                        Math.ceil((value / max) * 100) && value === 75
                    ? "green"
                    : Math.ceil((index / (labelData.length - 1)) * 100) ===
                        Math.ceil((value / max) * 100) && value === 100
                    ? "green"
                    : "normal"
                }`}
              >
                {label}
              </div>
            );
          })} */}

        <div className={`${value == 0 ? "red" : "normal"}`}>Disable</div>
        <div className={`${value == 25 ? "red" : "normal"}`}>Low</div>
        <div className={`${value == 50 ? "medium" : "normal"}`}>Normal</div>
        <div className={`${value == 75 ? "green" : "normal"}`}> High</div>
        <div className={`${value == 100 ? "green" : "normal"}`}>Immediate</div>
      </div>

      <input
        type="range"
        min={min}
        max={max}
        step={step}
        value={value}
        onMouseUp={onMouseUp}
        defaultValue={defaultValue}
        onChange={(e) => {
          //   progressColor(e.target.value);
          onChange(e);
        }}
        className="slider"
        id={id}
      />
    </div>
  );
};

export default SenstivitySlider;
