import React, { useState } from "react";
import { getAPIData } from "../../../Utils/commonApi";
import { useAuth } from "../../context/Auth";
import { CallToast } from "../../PriceCalculator/modals/CustomToast";
import SecurityRatingsDatePicker from "../../test/SecurityRatingsDatePicker";
import { GreyLoader } from "../../Theme/APILoaders";
import "../supplier-profiler.scss";
import "../securityRatings/security-ratings.scss";
import moment from "moment";

const SecurityRatingBlock = ({ title, questionId }) => {
  const [historyRating, setHistoryRatings] = useState({});
  const [fetchingHistory, setFetchingHistory] = useState({});
  const [isHovered, setIsHovered] = useState(0);
  const { authTokens } = useAuth();

  async function getHistory(itemId) {
    setIsHovered(itemId);
    await getAPIData(`${777}`, authTokens, {
      optionEnum1: questionId,
      optionEnum2: itemId,
    })
      .then((response) => {
        if (response?.mr?.Success) {
          setHistoryRatings(response?.securityRatingHistory_List);
        } else {
          CallToast("Something went wrong", false, false, "v2style");
        }

        setFetchingHistory(false);
      })
      .catch((error) => {
        setFetchingHistory(false);
        console.log(error);
        setIsHovered(0);
      });
  }
  return (
    <div
      className="d-flex justify-content-center"
      style={{ width: "1024px", padding: "24px" }}
    >
      <div className="d-flex flex-column justify-content-center align-items-center w-100">
        <div
          className="d-flex flex-row w-100 justify-space-between mb-2"
          style={{ justifyContent: "space-between" }}
        >
          <div className="title">
            <div className="f-16">{title.Title}</div>
            <div className="f-darkgrey">{title.Description}</div>
          </div>
        </div>

        <div className=" w-100 security-ratings-wrapper">
          {title?.Question_List?.map((question, questionIndex) => {
            return (
              <div className="mb-3 table-grid align-items-center parent-hovering">
                <div className="f-grey">{question.QuestionText}</div>

                <div>
                  <input
                    type="text"
                    className="border-none bg-transparent"
                    placeholder="Type"
                    disabled
                    value={question?.AnswerText}
                  />
                </div>
                <div>
                  <div
                    className="dropdown-history"
                    style={{
                      width: "200px",
                    }}
                    onMouseEnter={(event) => {
                      setFetchingHistory(true);
                      getHistory(question.QuestionId);

                      // event.target.click();
                    }}
                    onMouseLeave={(event) => {
                      setIsHovered(0);
                      // event.target.click();
                    }}
                  >
                    <SecurityRatingsDatePicker
                      showHistory={false}
                      hideCaret
                      disabled
                      disablePreviousDates={true}
                      selected={renderDate(question?.AnswerDate)}
                      placeholder={"Date "}
                      icon={
                        renderDate(question?.AnswerDate)?.split("/").length > 2
                          ? "/images/msp/dark-date-icon.svg"
                          : "/images/msp/light-date-icon.svg"
                      }
                      onChange={(e) => {}}
                      id={"bhfgirrwmv" + questionIndex}
                      isError={false}
                    />
                    <div
                      className="dropdown-revoke history"
                      style={{
                        width: " 280px",
                        display:
                          isHovered == question.QuestionId ? "flex" : "none",
                      }}
                    >
                      <div
                        className="f-darkgrey f-12"
                        style={{ padding: "4px 12px" }}
                      >
                        History
                      </div>
                      {fetchingHistory ? (
                        <span className="d-inline-flex align-items-center justify-content-center h-100 w-100">
                          <GreyLoader width={10} />
                          Loading...
                        </span>
                      ) : (
                        <div className="d-flex flex-column w-100">
                          {historyRating?.map((hist) => {
                            return (
                              <div
                                className="d-flex flex-row justify-content-between w-100"
                                style={{ padding: "4px 12px" }}
                              >
                                <div className="d-flex f-darkgrey">
                                  <img
                                    src={"/images/empty-clock.svg"}
                                    className="mr-2"
                                    alt=""
                                  />
                                  {hist.AnswerDate_Text}
                                </div>
                                <div className="d-flex ">{hist.Answer}</div>
                              </div>
                            );
                          })}
                          {historyRating?.length == 0 && (
                            <div
                              className="d-flex f-darkgrey"
                              style={{ padding: "4px 12px" }}
                            >
                              History is empty
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default SecurityRatingBlock;

function renderDate(_inputDate) {
  if (_inputDate?.split("/").length > 2) {
    return _inputDate;
  } else if (
    _inputDate === "" ||
    _inputDate === null ||
    _inputDate === undefined
  ) {
    return "";
  } else {
    return moment(_inputDate).format("DD/MM/YYYY");
  }
}
