import React, {
  useState,
  useEffect,
  Fragment,
  useCallback,
  Component,
} from "react";
import "./InvestmentDetails.css";
import { Col, Row, Container, Button } from "react-bootstrap";
import "../../main.scss";
import LoadingSpinner from "../Spinner/Spinner";
import { useAuth } from "../context/Auth";
import TechTable from "../TechTable/TechTable";
import {
  getAPIData,
  getInvestmentDDL,
  PostInvestment,
} from "../../Utils/commonApi";
import AddInvestmentForm from "./AddInvestmentForm/AddInvestmentForm";
import { HiOutlineTrash } from "react-icons/hi";
import { AiOutlineClose } from "react-icons/ai";
import ContentLoader from "react-content-loader";


const InvestmentDetails = (props) => {
  const [displayForm, setDisplayForm] = useState(props.displayForm);
  const { authTokens, setAuthTokens } = useAuth();
  const [data, setData] = useState("");
  const [techTableData, settechTableData] = useState("");
  const [emptyArray, setEmptyArray] = useState(true);
  const [totalInvestment, settotalInvestment] = useState(0);
  const [rowClicked, setRowClicked] = useState();
  const [objectIds, setObjectIds] = useState([]);
  const [formLoading, setFormLoading] = useState(0);
  const [dropdownRows, setDropdownRows] = useState([]);

  async function getDataFromAPI() {
    let result = await getAPIData("19", authTokens, {
      optionEnum1: props.clientId ?? 0
    });
    if (result.authToken !== undefined) {
      setAuthTokens(result.authToken);
      setData();
      result = await getAPIData("19", result.authToken, {
        optionEnum1: props.clientId ?? 0
      });
    } else {
      settotalInvestment(result.InvestmentTotalFormatted);

      if (result.dataRows.length > 0) {
        var header = [
          { text: "Purchase Item" },
          { text: "Date" },
          { text: "Description" },
          { text: "Type" },
          { text: "Billing" },
          { text: "Investment" },
        ];

        var outerList = [];
        var newOuterList = [];

        result.dataRows.map((element) => {
          outerList.push([
            { text: element.Purchase },
            { text: element.Vendor },
            { text: element.Description },
            { text: element.SavingTypeText },
            { text: element.BillingTypeText },
            { text: element.BillingTermText },
            { text: element.InvestmentFormatted },
          ]);
          newOuterList.push([
            {
              text: [
                { text: element.Purchase },
                { text: element.Vendor, color: "#B6B6BF" },
              ],
            },
            { text: element.Date },
            { text: element.Description },
            { text: element.SavingTypeText },
            { text: element.BillingTypeText },
            { text: element.InvestmentFormatted },
          ]);
          objectIds.push(element.ObjectId);
        });
        setEmptyArray(false);
        setData(
          Object.assign(
            {},
            {
              0: {
                header: header,
              },
              1: {
                data: outerList,
              },
            }
          )
        );
        let newObj = {
          0: {
            header: header,
          },
          1: {
            data: newOuterList,
          },
        };
        setTimeout(() => {
          settechTableData();
        }, 100);
        setTimeout(() => {
          settechTableData(newObj);
        }, 100);
      }
    }
  }

  async function initaliseDropdowns() {
    const result = await getInvestmentDDL(authTokens, "19");
    for (let i = 0; i < result.DDLList.length; i++) {
      const element = result.DDLList[i];
      dropdownRows.push({ list: element.DDLMemberList });
    }
  }

  async function deleteRow() {
    if (rowClicked === undefined) return setDisplayForm(false);

    let objectId = objectIds[rowClicked];
    await PostInvestment(authTokens, undefined, objectId, "delete");
    getDataFromAPI();
  }

  useEffect(() => {
    initaliseDropdowns();
    getDataFromAPI();
  }, []);
  useEffect(() => {
    if (rowClicked !== undefined) {
      console.log("active row is ", rowClicked, objectIds[rowClicked]);
      document.querySelector(".modal-content").scrollTo(0, 0);
      if (!displayForm) setDisplayForm(true);
      else {
        setTimeout(() => setDisplayForm(false), 10);
        setTimeout(() => setDisplayForm(true), 10);
      }
    }
  }, [rowClicked]);

  if (data != "" && techTableData && !emptyArray) {
    return (
      <div className="csd_background fullHeight">
        {displayForm ? (
          <div style={{ padding: "3.5em 1.2em" }}>
            <span style={{ opacity: formLoading == 0 ? 1 : 0.5 }}>
              <h2 style={{ display: "inline-block" }}>
                {rowClicked !== undefined ? "Update" : "Add"} Investment
              </h2>
              <span style={{ float: "right", display: "flex" }}>
                <span style={{ marginRight: "1em" }} onClick={deleteRow}>
                  <HiOutlineTrash size={25} color={"#F36060"} />
                </span>
                <span onClick={() => setDisplayForm(false)}>
                  <AiOutlineClose size={25} />
                </span>
              </span>
            </span>
            <AddInvestmentForm
              objectId={rowClicked !== undefined ? objectIds[rowClicked] : 0}
              objectData={
                rowClicked !== undefined ? data[1].data[rowClicked] : undefined
              }
              onClickCallback={getDataFromAPI}
              additionalHook={setFormLoading}
              dropdownLists={dropdownRows}
            />
          </div>
        ) : (
          ""
        )}
        <Row>
          <Col xs={12} style={{ padding: "1em 2em 0em 2em" }}>
            <TechTable
              bgColor={"#008FD4"}
              hdTextColor={"white"}
              data={techTableData}
              editIconHover={true}
              onClickCallback={setRowClicked}
              verticalAlign={true}
            />
          </Col>
        </Row>
        <Row style={{ paddingTop: "1em" }}>
          <Col xs={12} style={{ padding: "0 2em 2em 2em" }}>
            <Col className="bottomCont">
              <div
                style={{ paddingTop: "1.5em", width: "15%" }}
                className="float-right text-center"
              >
                <span className="totalFont">Total: </span>
                <span>{totalInvestment}</span>
              </div>
            </Col>
          </Col>
        </Row>
      </div>
    );
  } else {
    return (
      <div className="collapContainer collapContainer50">
        <ContentLoader
          speed={2}
          width={900}
          height={500}
          viewBox="0 0 900 500"
          backgroundColor="#f3f3f3"
          foregroundColor="#ecebeb"
          {...props}
        >
          <rect x="13" y="16" rx="0" ry="0" width="900" height="52" />
          <rect x="13" y="90" rx="0" ry="0" width="900" height="52" />
          <rect x="13" y="164" rx="0" ry="0" width="900" height="52" />
          <rect x="13" y="238" rx="0" ry="0" width="900" height="52" />
          <rect x="13" y="312" rx="0" ry="0" width="900" height="52" />
        </ContentLoader>
      </div>
    );
  }
};

export default InvestmentDetails;
