import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { Col, Row, Container, Modal, Dropdown, Table } from "react-bootstrap";

import TechTable from "../TechTable/TechTable";
import "./WebFilteringBoxes.css";
import { useAuth } from "../context/Auth";

const WebFilteringBoxes = (props) => {
  const [text] = useState(props.mainText || "");
  const [icon] = useState(props.icon || "");
  const [bodyText] = useState(props.bText || "");
  const [percentText] = useState(props.pText || "");
  const [linkText] = useState(props.linkText || "");
  const [linkRoute] = useState(props.linkRoute || "");
  const ALIGN = props.align || "right";
  const UNDERLINE = props.underline || false;

  const TextColumn = <Col xs={12} md={6} lg={6} className="mx-auto my-auto" style={{ paddingLeft: "2em" }}>
    <div>
      <h1 className="emailMainText">{text}</h1>
      {linkText ?
        <p className="link_textCol"><Link to={linkRoute}>{linkText} {ALIGN ? ">" : ""}</Link></p>

        :

        ""}
    </div>
  </Col>;
  const DataColumn = <Col md={6}>
    <div
      className="mx-auto my-auto"
      style={{ height: "100%", padding: "0.5em 0" }}
    >
      <Col md={12} className="webfiltering_background">
        <div>
          <span style={{ paddingBottom: "0.5em" }}> <img src={icon}></img></span>
          <span style={{ paddingBottom: "0.5em" }}><h3 className="drilldownSideText">{bodyText}</h3></span>
          {UNDERLINE ? <span style={{ paddingBottom: "0.5em" }}> <img src="/images/cs_line.svg"></img></span> : ""}
          <span> <h3 className="percentText">{percentText}</h3></span>

        </div>

      </Col>
    </div>
  </Col>;
  return (

        <Row className="webFilteringBox">
          {
            ALIGN === "left" ? 
              <React.Fragment>
                {DataColumn}            
                {TextColumn}
              </React.Fragment>
            :
              <React.Fragment>
                {TextColumn}
                {DataColumn}            
              </React.Fragment>  
          }
        </Row>
    
  );
};

export default WebFilteringBoxes;
