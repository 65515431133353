import React, { useEffect, useRef, useState } from "react";
import { Button, Dropdown, Nav, Tab } from "react-bootstrap";
import moment from "moment";
import SlidingModal from "../../../CustomModal/SlidingModal";
import CreateCategorySpendModal, {
  ColorDropdown,
} from "../CreateCategorySpendModal";
import "./technologySpendModal.scss";
import MspDonutChart from "../MspDonutChart";
import TechnologySpendFilter from "../technologySpendFilter/TechnologySpendFilter";
import AddSubcategoryModal from "../AddSubcategoryModal";
import HighgroundDatePicker from "../../../test/TestComponent";
import AddItemInSpendModal, {
  AddEditTaxRateModal,
  CurrencyDropdown,
  TaxRateDropdown,
  deleteTaxRate,
} from "../addItemInSpendModal/AddItemInSpendModal";
import Loader from "../../../Common/loader";
import { getAPIData, postData } from "../../../../Utils/commonApi";
import { useAuth } from "../../../context/Auth";
import { CallToast } from "../../../PriceCalculator/modals/CustomToast";
import { TrimString } from "../../../../Utils/StringTrimmer";
import { GreyLoader, WhiteLoader } from "../../../Theme/APILoaders";
import {
  all_integration_data,
  removeWhiteSpace,
  validateEmail,
  validateNumber,
} from "../../../../Utils/CommonFunctions";
import AttackSurfaceTooltip from "../../../AttackSurface/AssetsList/AttackSurfaceTooltip";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { mspClientDashboardDatePickerState } from "../../../../redux/mspClientStates/actions";
import { endOfDay, startOfMonth, subMonths } from "date-fns";
import {
  CustomDeleteModal,
  DeleteModal,
} from "../../../DeleteModal/DeleteModal";
import AddSupplierModal from "../../../AttackSurface/Suppliers/AddSupplierModal";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import MSPCatSubcatSortFilters from "../../clients/MSPCatSubcatSortFilters";
import { CustomHGDropdown } from "../../clients/createClientScreens/CyberComplianceForGroupModal";
import GenerateInvoiceModal, {
  DeleteTechSpendSubscription,
} from "./GenerateInvoiceModal";

const defineds = {
  endOfToday: endOfDay(new Date()),
  startOfLastTwelveMonth: startOfMonth(subMonths(new Date(), 12)),
};
const TechnologySpendModalV2 = ({
  show,
  hideModal,
  parentID,
  categoryData,
  refreshData = () => {},
  defaultCurrencyData,
  isDraggableParent,
  isSaas,
  listViewInvoiceData,
  subscriptionViewInvoiceData,
}) => {
  const dispatch = useDispatch();
  const categoryGraphRef = useRef(null);
  const history = useHistory();
  const clientId = window.location.pathname.split("/")[4];
  const { authTokens } = useAuth();
  const [loading, setLoading] = useState(true);
  const [defaultSelectedTab, setDefaultSelectedTab] = useState(
    isSaas ? "manualSpend" : "automaticSpend"
  );
  const [showCategoryDropdown, setShowCategoryDropdown] = useState(false);
  const [showSubcategoryDropdown, setShowSubcategoryDropdown] = useState(false);
  const [showGenerateInvoiceModal, setShowGenerateInvoiceModal] =
    useState(false);

  const [subCategoriesLoading, setSubCategoriesLoading] = useState(false);
  const [sortingSubcategoryList, setSortingSubcategoryList] = useState([]);
  const [deleting, setDeleting] = useState(false);
  const [saving, setSaving] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteID, setDeleteID] = useState({});
  const [deleteMessage, setDeleteMessage] = useState("");
  const [
    showAddByInvoiceSupplierDropdown,
    setShowAddByInvoiceSupplierDropdown,
  ] = useState(false);
  const [showAddByAmountSupplierDropdown, setShowAddByAmountSupplierDropdown] =
    useState(false);
  const [showAddSupplierModal, setShowAddSupplierModal] = useState(false);
  const [potentialSupplierSearch, setPotentialSupplierSearch] = useState("");
  const [deleteType, setDeleteType] = useState("");
  const [linkedCatDeleteModal, setLinkedCatDeleteModal] = useState(false);
  const [changesInInvoice, setChangesInInvoice] = useState(0);
  const [switchInvoiceTabModalData, setSwitchInvoiceTabModalData] = useState({
    showModal: false,
    deleteType: "switchInvoicesTabInMspTechSpend",
    deleteParams: "tab name",
  });
  // Pages Data
  const [categoryPageData, setCategoryPageData] = useState({});
  const [subCategoryPageData, setSubCategoryPageData] = useState({});
  const [invoicePageData, setInvoicePageData] = useState({});
  const [selectedInvoiceItemData, setSelectedInvoiceItemData] = useState({});
  const [InvoiceItemIndex, setInvoiceItemIndex] = useState(-1);
  const [invoiceEditMode, setInvoiceEditMode] = useState(true);
  const [showCreateSubcategoryModal, setShowCreateSubcategoryModal] =
    useState(false);
  const [showCreateCategoryModal, setShowCreateCategoryModal] = useState(false);
  const [showDeleteSubscriptionModal, setShowDeleteSubscriptionModal] =
    useState(false);
  const [showAddItemSpendModal, setShowAddItemSpendModal] = useState(false);
  const [showAddEditTaxRateModal, setShowAddEditTaxRateModal] = useState(false);
  const [taxRateData, setTaxRateData] = useState({});
  const [addSpendSelectedTab, setAddSpendSelectedTab] =
    useState("addByInvoice");
  const [
    generatedInoiceListBySubscription,
    setGeneratedInoiceListBySubscription,
  ] = useState([]);
  const [unlinkRelinkModalData, setUnlinkRelinkModalData] = useState({
    show: false,
    linkButtonText: "Unlink",
    headerText: "Unlink INV-0001 from HaloPSA",
    bodyText: "Are you sure you would like to unlink this",
    deleteParams: {},
  });
  //Unlink INV-0001 from HaloPSA
  //Are you sure you would like to unlink this invoice #INV-0001 from HaloPSA?

  //Relink INV-0001 to HaloPSA
  // All edits you have made to this invoice in HighGround will be lost.Are you sure you would like to relink #INV-0001 to HaloPSA?
  const [pages, setPages] = useState([
    {
      type: "home",
      name: "Technology Spend Modal",
      data: {},
    },
  ]);

  // Filters Setup
  const [activeFilters, setActiveFilters] = useState([]);
  const [invoiceFilterSearch, setInvoiceFilterSearch] = useState("");
  const [applyingFilters, setApplyingFilters] = useState(false);

  const selectedDateFilter = useSelector((state) => {
    let dashboard_date = {
      ...state?.mspclient?.mspClientDashboardDatePickerState,
    };

    dashboard_date.startDate = dashboard_date.startDate
      ? new Date(dashboard_date.startDate)
      : null;

    dashboard_date.endDate = dashboard_date.endDate
      ? new Date(dashboard_date.endDate)
      : null;
    dashboard_date.key = "selection";
    return dashboard_date;
  });
  const [firstLoad, setFirstLoad] = useState(false);
  useEffect(() => {
    if (
      !firstLoad ||
      activeFilters.filter((item) => item?.filterType === "date").length > 0
    ) {
      setFirstLoad(true);
      setActiveFilters([
        {
          filterType: "date",
          selectedDateRange: {
            startDate: selectedDateFilter.startDate,
            endDate: selectedDateFilter.endDate,
            key: "selection",
          },
        },
      ]);
    } else {
      setActiveFilters([]);
    }
    setInvoiceFilterSearch("");
    setApplyingFilters(false);
  }, [pages]);

  function updateSortSubCategoryList(_subcatList) {
    let updated_data = [..._subcatList]
      ?.sort((a, b) => a.CategorySortOrder - b.CategorySortOrder)
      .map((item) => ({
        ...item,
        Visibility: item?.CategoryVisibility,
        FieldKey: "C" + item?.objId_Category,
        FieldName: item?.CategoryName,
        SortOrder: item?.CategorySortOrder,
      }));
    setSortingSubcategoryList(updated_data);
  }

  function UpdatelinkingRelinking(kwargs = {}) {
    postData(authTokens, "LinkingRelinkingSpend", {
      CustomerId: clientId,
      linkStatus: kwargs.linkStatus,
      objId_invoice: kwargs.objId_invoice,
    }).then(() => {});
  }

  function getFilters(_filters) {
    let filterData = {
      Amount: "",
      fromDate: "",
      toDate: "",
      subCategoryIds: [],
    };
    let updatedActiveFilters = [];
    if (_filters) {
      updatedActiveFilters = [..._filters];
    } else {
      updatedActiveFilters = [...activeFilters];
    }
    if (updatedActiveFilters?.length > 0) {
      for (let index = 0; index < updatedActiveFilters.length; index++) {
        if (
          updatedActiveFilters[index].filterType === "date" &&
          updatedActiveFilters[index].selectedDateRange.startDate
        ) {
          filterData["fromDate"] = moment(
            updatedActiveFilters[index].selectedDateRange.startDate
          ).format("DD/MM/YYYY");
          filterData["toDate"] = moment(
            updatedActiveFilters[index].selectedDateRange.endDate
          ).format("DD/MM/YYYY");
        }
        if (
          updatedActiveFilters[index].filterType === "amount" &&
          updatedActiveFilters[index].filterAmount
        ) {
          filterData["Amount"] = updatedActiveFilters[index].filterAmount;
        }
        if (
          updatedActiveFilters[index].filterType === "subcategory" &&
          updatedActiveFilters[index].filterList?.length > 0
        ) {
          filterData["subCategoryIds"] =
            updatedActiveFilters[index].filterList?.map(
              (item) => item?.objId_Category
            ) ?? [];
        }
      }
      return filterData;
    } else {
      return filterData;
    }
  }

  const activePage = pages[pages.length - 1];

  /**
   *
   * @param {"Modal Header Name"} pageName
   * @param {"Type of Page"} type
   * @param {"Data for Page"} data
   *
   * @returns void
   */
  function goTo(pageName, type = "", data = {}) {
    if (pageName == null || pageName === "") {
      console.log("go to page is Null");
      return;
    }
    setPages([...pages, { name: pageName, type: type, data: data }]);
  }

  function goBack() {
    let updated_pages = [...pages];
    if (updated_pages.length > 1) {
      let future_page = updated_pages[updated_pages.length - 2];
      if (future_page.type === Pages.HOME) {
        setLoading(true);
        getCategoryDetail(categoryPageData.ID);
      }
      if (future_page.type === Pages.SUBCATEGORY) {
        setLoading(true);
        getSubCategoryDetail(subCategoryPageData.ID);
      }
      if (future_page.type === Pages.ADD_SPEND) {
        setLoading(true);
        //  const Data={
        //     invoiceId:"",
        //     categoryId:"",
        //     subCategoryId:"",
        //     invoiceType:"",
        //   }
        if (future_page.data.invoiceType) {
          setAddSpendSelectedTab(future_page.data.invoiceType);
        }
        getInvoiceDetails(
          future_page.data.invoiceId,
          future_page.data.subCategoryId > 0
            ? future_page.data.subCategoryId
            : future_page.data.categoryId
        );
      }

      updated_pages.pop();
      setPages(updated_pages);
    } else {
      hideModal();
    }
  }

  const Pages = {
    HOME: "home",
    INVOICE_DATA: "invoice_data",
    ADD_SPEND: "add_spend",
    SUBCATEGORY: "subcategory",
  };

  useEffect(() => {
    if (show) {
      setLoading(true);
      getTaxRateList();
      // For Adding and opening List View Data
      if (listViewInvoiceData) {
        if (listViewInvoiceData.newInvoice) {
          setLoading(true);
          setAddSpendSelectedTab("addByInvoice");
          setInvoiceEditMode(true);
          // goTo("Add Spend", Pages.ADD_SPEND, {});
          setPages([
            {
              type: Pages.ADD_SPEND,
              name: "Add Spend",
              data: {},
            },
          ]);

          getInvoiceDetails(0, 0);
        } else {
          setLoading(true);
          // setInvoiceEditMode(
          //   invoiceItem?.Editable
          // );
          if (listViewInvoiceData?.isDirectAmout) {
            setAddSpendSelectedTab("addByAmount");
          } else {
            setAddSpendSelectedTab("addByInvoice");
          }
          setPages([
            {
              type: Pages.ADD_SPEND,
              name: (
                <div className="d-flex align-items-center justify-content-center">
                  <div className="position-relative w-fit-content h-fit-content">
                    <img
                      alt=""
                      className="mr-2"
                      src="/images/msp/msp-invoice-icon.svg"
                    />
                    {listViewInvoiceData?.IntegrationID > 0 && (
                      <img
                        src={`/images/attack-surface/integrations/${listViewInvoiceData?.IntegrationID}.webp`}
                        alt=""
                        className="position-absolute rounded-full"
                        width={13}
                        height={13}
                        style={{
                          top: "44%",
                          right: "4px",
                        }}
                      />
                    )}
                  </div>
                  {TrimString(listViewInvoiceData?.SpendTitle, 30)}
                </div>
              ),
              data: {},
            },
          ]);

          getInvoiceDetails(
            listViewInvoiceData?.InvoiceId,
            listViewInvoiceData?.objId_SubCategory > 0
              ? listViewInvoiceData?.objId_SubCategory
              : listViewInvoiceData?.objId_Category
          );
        }

        return;
      }

      // For Opening Susbcription Data
      if (subscriptionViewInvoiceData) {
        if (subscriptionViewInvoiceData.newInvoice) {
          setLoading(true);
          setAddSpendSelectedTab("addBySubscription");
          setInvoiceEditMode(true);
          // goTo("Add Spend", Pages.ADD_SPEND, {});
          setPages([
            {
              type: Pages.ADD_SPEND,
              name: "Add Subscription",
              data: {},
            },
          ]);

          getInvoiceDetails(0, 0);
        } else {
          setLoading(true);
          // setInvoiceEditMode(
          //   invoiceItem?.Editable
          // );
          // if (listViewInvoiceData?.isDirectAmout) {
          //   setAddSpendSelectedTab(
          //     "addByAmount"
          //   );
          // } else {
          //   setAddSpendSelectedTab(
          //     "addByInvoice"
          //   );
          // }
          setAddSpendSelectedTab("addBySubscription");
          setPages([
            {
              type: Pages.ADD_SPEND,
              name: (
                <div className="d-flex align-items-center justify-content-center">
                  <div className="position-relative w-fit-content h-fit-content">
                    <img
                      alt=""
                      className="mr-2"
                      src="/images/msp/msp-invoice-icon.svg"
                    />
                    {/* {listViewInvoiceData?.IntegrationID >
                  0 && (
                  <img
                    src={`/images/attack-surface/integrations/${listViewInvoiceData?.IntegrationID}.webp`}
                    alt=""
                    className="position-absolute rounded-full"
                    width={13}
                    height={13}
                    style={{
                      top: "44%",
                      right: "4px",
                    }}
                  />
                )} */}
                  </div>
                  {TrimString(
                    subscriptionViewInvoiceData?.SubscriptionTitle,
                    30
                  )}
                </div>
              ),
              data: {
                invoiceId: subscriptionViewInvoiceData?.ObjId_Invoice,
                categoryId: subscriptionViewInvoiceData?.ObjId_Category,
                subCategoryId: subscriptionViewInvoiceData?.ObjId_SubCategory,
                invoiceType: "addBySubscription",
              },
            },
          ]);

          getInvoiceDetails(
            subscriptionViewInvoiceData?.ObjId_Invoice,
            subscriptionViewInvoiceData?.ObjId_SubCategory > 0
              ? subscriptionViewInvoiceData?.ObjId_SubCategory
              : subscriptionViewInvoiceData?.ObjId_Category
          );
        }

        return;
      }

      if (categoryData?.CategoryId > 0) {
        setPages([
          {
            type: "home",
            name: (
              <>
                <div
                  style={{
                    width: "8px",
                    height: "8px",
                    background: categoryData?.CategoryColourHexCode,
                  }}
                  alt=""
                  className="mr-2 rounded-full d-inline-block"
                />
                {TrimString(categoryData?.CategoryName, 30)}
              </>
            ),
            data: {},
          },
        ]);

        getCategoryDetail(
          categoryData?.CategoryId,
          {
            Amount: "",
            fromDate: moment(selectedDateFilter.startDate).format("DD/MM/YYYY"),
            toDate: moment(selectedDateFilter.endDate).format("DD/MM/YYYY"),
            subCategoryIds: [],
          },
          false
        );
      }
    } else {
      setCategoryPageData({});

      setSubCategoryPageData({});
      setInvoicePageData({});
      setFirstLoad(false);
    }
  }, [show]);

  /**
   *
   * @param {*} _categoryId
   *
   * @param kwargs {
   *    amount:"",
   *    fromDate:"",
   *    toDate:"",
   *   subCategoryIds: []
   * }
   */

  async function getCategoryDetail(_categoryId, kwargs, editData = true) {
    await postData(authTokens, "GetCategoryDetail", {
      CategoryId: _categoryId,
      ObjId_Customer: clientId,
      LoadInvoice: true,
      accessKey: JSON.parse(localStorage.getItem("user_MessageObj")),
      Amount: kwargs?.Amount ?? "",
      FromDate:
        kwargs?.fromDate ??
        moment(selectedDateFilter.startDate).format("DD/MM/YYYY"),
      ToDate:
        kwargs?.toDate ??
        moment(selectedDateFilter.endDate).format("DD/MM/YYYY"),
      SubCategoryId_List: JSON.stringify(kwargs?.subCategoryIds ?? []),
      isSaas,
    })
      .then((response) => {
        setApplyingFilters(false);
        updateSortSubCategoryList(response?.SubCategory_List);
        if (response?.mr?.Success) {
          if (kwargs && editData) {
            setCategoryPageData({
              ID: _categoryId,
              ...categoryPageData,
              InvoiceItemVM_List: response?.InvoiceItemVM_List,
              SubCategory_List: response?.SubCategory_List?.sort(
                (a, b) => a.CategorySortOrder - b.CategorySortOrder
              )?.filter((item) => item?.CategoryVisibility === true),
            });
          } else {
            setCategoryPageData({
              ID: _categoryId,
              ...response,
              SubCategory_List: response?.SubCategory_List?.sort(
                (a, b) => a.CategorySortOrder - b.CategorySortOrder
              )?.filter((item) => item?.CategoryVisibility === true),
            });
          }
        } else {
          CallToast("Something went wrong", false, false, "v2style");
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        setApplyingFilters(false);
        CallToast("Something went wrong", false, false, "v2style");
      });
  }

  function updateSubCategoryList() {
    postData(authTokens, "GetCategoryDetail", {
      CategoryId: categoryPageData.ID,
      ObjId_Customer: clientId,
      LoadInvoice: true,
      accessKey: JSON.parse(localStorage.getItem("user_MessageObj")),
      Amount: "",
      FromDate: moment(selectedDateFilter.startDate).format("DD/MM/YYYY"),
      ToDate: moment(selectedDateFilter.endDate).format("DD/MM/YYYY"),
      SubCategoryId_List: JSON.stringify([]),
      isSaas,
    })
      .then((response) => {
        setSubCategoriesLoading(false);
        updateSortSubCategoryList(response?.SubCategory_List);
        if (response?.mr?.Success) {
          let updated_data = { ...categoryPageData };

          setCategoryPageData({
            ...updated_data,
            SubCategory_List: response?.SubCategory_List?.sort(
              (a, b) => a.CategorySortOrder - b.CategorySortOrder
            )?.filter((item) => item?.CategoryVisibility === true),
          });
        } else {
        }
      })
      .catch((error) => {
        setSubCategoriesLoading(false);
      });
  }

  function updateSubCategorySorting(e) {
    setSubCategoriesLoading(true);
    let data = { ...categoryPageData?.CategorySettings[0] };
    postData(authTokens, "UpdatePSACategorySettings", {
      CategorySettings: JSON.stringify([
        {
          ...data,
          SubCatSettings: e,
        },
      ]),
      CustomerId: clientId,
      isSubcat: true,
      isSaas,
    }).then(() => {
      updateSubCategoryList();
    });
  }

  /**
   *
   * @param {*} _subCategoryId
   *
   * @param kwargs {
   *    amount:"",
   *    fromDate:"",
   *    toDate:""
   * }
   */

  async function getSubCategoryDetail(_subCategoryId, kwargs) {
    await postData(authTokens, "GetCategoryDetail", {
      CategoryId: _subCategoryId,
      ObjId_Customer: clientId,
      LoadInvoice: true,
      accessKey: JSON.parse(localStorage.getItem("user_MessageObj")),
      Amount: kwargs?.Amount ?? "",
      FromDate:
        kwargs?.fromDate ??
        moment(selectedDateFilter.startDate).format("DD/MM/YYYY"),
      ToDate:
        kwargs?.toDate ??
        moment(selectedDateFilter.endDate).format("DD/MM/YYYY"),
      isSaas,
    })
      .then((response) => {
        setApplyingFilters(false);
        if (response?.mr?.Success) {
          if (kwargs) {
            setSubCategoryPageData({
              ID: _subCategoryId,
              ...subCategoryPageData,
              InvoiceItemVM_List: response?.InvoiceItemVM_List,
            });
          } else {
            setSubCategoryPageData({ ID: _subCategoryId, ...response });
          }
        } else {
          CallToast("Something went wrong", false, false, "v2style");
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        setApplyingFilters(false);
        CallToast("Something went wrong", false, false, "v2style");
      });
  }

  const [taxList, setTaxList] = useState([]);
  const [currencyList, setCurrencyList] = useState([]);
  const [exchangeRateData, setExchangeRateData] = useState({});
  const [userSelectedCurrency, setUserSelectedCurrency] = useState({});

  function getTaxRateList() {
    return new Promise((resolve, reject) => {
      getAPIData("632", authTokens, {
        ObjId_Customer: clientId,
        accessKey: JSON.parse(localStorage.getItem("user_MessageObj")),
        isSaas,
      })
        .then((response) => {
          resolve(response);
          setTaxList(response?.TaxRates_List ?? []);
          setCurrencyList(response?.CurrencyCode_List ?? []);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  }

  //  kwargs ={
  //   stoploading:true,
  //   created:true
  //  }
  function updateTaxList(kwargs = {}) {
    if (!kwargs?.stoploading) {
      // setLoading(true)
    }
    getTaxRateList()
      .then((response) => {
        setTaxList(response?.TaxRates_List);
        setLoading(false);
        if (kwargs?.created > 0) {
          let newly_created = response?.TaxRates_List.find(
            (item) => item?.ObjectId === kwargs?.created
          );
          setInvoicePageData((prev) => ({
            ...prev,
            TaxRates_Selected: newly_created ?? response?.TaxRates_List?.[0],
          }));
          return;
        }
        // If Selected Tax rate is deleted
        if (
          kwargs?.deletedId === invoicePageData?.TaxRates_Selected?.ObjectId
        ) {
          setInvoicePageData((prev) => ({
            ...prev,
            TaxRates_Selected: response?.TaxRates_List?.[0],
          }));
          return;
        }

        if (!invoicePageData?.TaxRates_Selected) {
          setInvoicePageData((prev) => ({
            ...prev,
            TaxRates_Selected: response?.TaxRates_List?.[0],
          }));
        } else {
          let selected_tax = response?.TaxRates_List.find(
            (item) =>
              item?.ObjectId === invoicePageData?.TaxRates_Selected?.ObjectId
          );
          setInvoicePageData((prev) => ({
            ...prev,
            TaxRates_Selected: selected_tax,
          }));
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }
  const [updatingExchangeRate, setUpdatingExchangeRate] = useState(false);
  function updateExchangeRateData(kwargs) {
    setUpdatingExchangeRate(true);
    // if (updatingExchangeRate) {
    //   return;
    // }
    return new Promise((resolve, reject) => {
      postData(authTokens, "GetCurrencyConverionRate", {
        FromCurrency: kwargs?._currency ?? exchangeRateData?.FromCurrency,
        ToCurrency: defaultCurrencyData?.CurrencyName,
        accessKey: JSON.parse(localStorage.getItem("user_MessageObj")),
        Date:
          kwargs?._date ??
          moment(exchangeRateData?.Date ?? "").format("DD/MM/YYYY"),
        isSaas,
      })
        .then((response) => {
          resolve(response);
          setExchangeRateData(response);
          setUpdatingExchangeRate(false);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
          setUpdatingExchangeRate(false);
        });
    });
  }

  console.log(invoicePageData, "invoice page data");
  function getInvoiceDetails(_invoiceId, _catSubcatID, kwargs = {}) {
    let filter_invoiceItem_id = kwargs?.invoiceItemId;
    getAPIData(`${632}`, authTokens, {
      optionEnum1: _invoiceId,
      optionEnum2: clientId,
      optionEnum3: _catSubcatID ?? 0,
      isSaas,
    })
      .then((response) => {
        setErrors({});
        setChangesInInvoice(0);
        setInvoicePageData({
          ID: _invoiceId,
          ...response,
          InvoiceItem_List: filter_invoiceItem_id
            ? response?.InvoiceItem_List.filter(
                (item) => item.objId_Invoice === filter_invoiceItem_id
              )
            : response?.InvoiceItem_List,
        });
        // setLoading(false);
        if (
          addSpendSelectedTab === "addBySubscription" &&
          response?.Category_Selected?.objId_Category
        ) {
          getSubCategoryList(response?.Category_Selected?.objId_Category);
        }

        updateExchangeRateData({
          _currency: response?.CurrencyCode_Selected
            ? response?.CurrencyCode_Selected?.CurrencyName
            : defaultCurrencyData?.CurrencyName,
          _date:
            response?.InvoiceDate === "0001-01-01T00:00:00"
              ? moment().format("DD/MM/YYYY")
              : moment(response?.InvoiceDate).format("DD/MM/YYYY"),
        }).then(() => {
          setLoading(false);
          setUserSelectedCurrency(
            response?.CurrencyCode_Selected ?? defaultCurrencyData
          );
        });
      })
      .catch((err) => {
        setLoading(false);
        setChangesInInvoice(0);
        CallToast("Something went wrong", false, false, "v2style");
      });
  }

  function getSpendDataForGraph() {
    if (
      categoryPageData?.SubCategory_List?.length > 0 ||
      categoryPageData?.TotalUnassignedAmount > 0
    ) {
      const dummyColors = ["#FFCA78", "#DF6844", "#EB9E87", "#F36060"];
      let data =
        // [...dummy]
        [...categoryPageData?.SubCategory_List]
          .filter((item) => item?.TotalAmount > 0)
          .map((item, itemIndex) => ({
            label: item?.CategoryName,
            value: item?.TotalAmount,
            color:
              item?.CategoryColourHexCode ??
              dummyColors[itemIndex % dummyColors.length],
            colorId: item?.objId_Category,
          }));
      if (categoryPageData?.TotalUnassignedAmount > 0) {
        data.push({
          label: categoryPageData?.CategoryName,
          value: categoryPageData?.TotalUnassignedAmount,
          color: categoryPageData?.CategoryColourHexCode ?? "#DF6844",
          colorId: 99999,
        });
      }
      console.log(categoryPageData, "categoryPageData");
      console.log(data, "data");

      return data;
    } else {
      return [];
    }
  }

  async function AddEditInvoice() {
    setSaving(true);
    await postData(authTokens, "CreateMSPTechnologyInvoice", {
      objId_Invoice: invoicePageData?.ID,
      objId_Customer: clientId,
      Discount: invoicePageData?.Discount ? invoicePageData?.Discount : 0,
      Description: invoicePageData?.Description ?? "",
      InvoiceRef: invoicePageData?.InvoiceRef,
      ContactEmail: invoicePageData?.ContactEmail ?? "",
      SupplierId:
        invoicePageData?.PotentialSupplierSelected?.PotentialSupplierId,
      InvoiceDate: convertDateFormat(invoicePageData?.InvoiceDate),
      InvoiceItem_List: JSON.stringify(invoicePageData?.InvoiceItem_List),
      SupplierName:
        invoicePageData?.PotentialSupplierSelected?.PotentialSupplierName,
      TaxRates_Selected: JSON.stringify(
        invoicePageData?.TaxRates_Selected ?? {}
      ),
      CurrencyCode_Selected: JSON.stringify(userSelectedCurrency ?? {}),
      accessKey: JSON.parse(localStorage.getItem("user_MessageObj")),
      IsDirectAmount: addSpendSelectedTab === "addByAmount" ? true : false,
      CategoryId:
        addSpendSelectedTab === "addBySubscription"
          ? invoicePageData?.Category_Selected?.objId_Category
          : categoryPageData.ID,
      SubCategoryId:
        addSpendSelectedTab === "addBySubscription"
          ? invoicePageData?.SubCategory_Selected?.objId_Category
          : subCategoryPageData.ID,
      TotalAmount:
        addSpendSelectedTab === "addByAmount"
          ? Number(invoicePageData?.TotalAmount)
          : Number(getTotalAmountForInvoice() ?? 0),
      ForeignCurrencyRate: exchangeRateData?.Rate ?? 1,
      ForeignCurrencyAmount:
        addSpendSelectedTab === "addByAmount"
          ? Number(invoicePageData?.TotalAmount) -
            Number(invoicePageData?.Discount)
          : Number(getTotalAmountForInvoice() ?? 0),
      // SUBSCRIPTION VARIABLES
      AutoRenewalTerms: JSON.stringify(invoicePageData?.AutoRenewalTerms),
      SubscriptionStatus: JSON.stringify(
        invoicePageData?.SubscriptionStatus ??
          invoicePageData?.SubscriptionMeta?.SubscriptionStatusOptions?.[0]
      ),
      BillingTenure: JSON.stringify(invoicePageData?.BillingTenure),
      CancellationTerms: JSON.stringify(invoicePageData?.CancellationTerms),
      SubscriptionStartDate: invoicePageData?.SubscriptionStartDate,
      SubscriptionEndDate: invoicePageData?.SubscriptionEndDate,
      isSubscription:
        addSpendSelectedTab === "addBySubscription" ? true : false,
      isSaas: isSaas,
      BaseAmount:
        addSpendSelectedTab === "addBySubscription"
          ? invoicePageData?.TotalAmount
          : null,
    })
      .then((response) => {
        setSaving(false);
        if (response?.Success) {
          if (
            addSpendSelectedTab === "addBySubscription" &&
            response?.ObjectTemplateList?.length > 0
          ) {
            setInvoicePageData((prev) => ({
              ...prev,
              ID: response?.EntityId,
            }));
            setGeneratedInoiceListBySubscription(response?.ObjectTemplateList);
            setShowGenerateInvoiceModal(true);
          } else {
            refreshData();
            CallToast("Updated Successfully", false, false, "v2style");
            goBack();
          }
        } else {
          CallToast("Something went wrong", false, false, "v2style");
        }
      })
      .catch((err) => {
        setSaving(false);
        console.log(err);
        CallToast("Something went wrong", false, false, "v2style");
      });
  }

  function deleteInvoice(_invoiceId, isSubscription = false) {
    return new Promise((resolve, reject) => {
      getAPIData(`${628}`, authTokens, {
        optionEnum1: _invoiceId,
        optionEnum2: 0,
        IsSubscriptionInvoice: false,
        isSaas,
        ArchiveAutoGenInvoice: !isSubscription,
      })
        .then((response) => {
          if (response?.Success) {
            refreshData();
            CallToast("Updated Successfully", false, false, "v2style");
            goBack();
            resolve(response);
          } else {
            CallToast("Something went wrong", false, false, "v2style");
            reject("Something went wrong");
          }
        })
        .catch((err) => {
          console.log(err);
          CallToast("Something went wrong", false, false, "v2style");
          reject("Something went wrong");
        });
    });
  }

  async function deleteInvoiceItem(_invoiceItemId) {
    // setDeleting(true);
    await getAPIData(`${629}`, authTokens, {
      optionEnum1: _invoiceItemId,
      optionEnum2: 0,
      isSaas,
    })
      .then((response) => {
        refreshData();
        // setDeleting(false);
        if (response?.Success) {
          // CallToast("Updated Successfully", false, false, "v2style");
        } else {
          // CallToast("Something went wrong", false, false, "v2style");
        }
      })
      .catch((err) => {
        // setDeleting(false);
        console.log(err);
        // CallToast("Something went wrong", false, false, "v2style");
      });
  }

  function deleteCategorySubCategory(_catSubcatID) {
    // setDeleting(true);
    return new Promise((resolve, reject) => {
      getAPIData(`${630}`, authTokens, {
        optionEnum1: _catSubcatID,
        optionEnum2: 0,
        isSaas,
      })
        .then((response) => {
          setDeleting(false);
          if (response?.Success) {
            refreshData();
            CallToast("Updated Successfully", false, false, "v2style");
            goBack();
            resolve(response);
          } else {
            CallToast("Something went wrong", false, false, "v2style");
            reject(new Error("Update failed"));
          }
        })
        .catch((err) => {
          setDeleting(false);
          console.log(err);
          CallToast("Something went wrong", false, false, "v2style");
          reject(err);
        });
    });
  }

  // Updating Data Setup
  const [errors, setErrors] = useState({});

  // Category Page
  async function checkCategroyPageErrors() {
    let errors = 0;
    if (!categoryPageData?.CategoryName) {
      errors += 1;
      setErrors((prev) => ({ ...prev, categoryNameError: true }));
    }

    return errors;
  }

  async function updateCategory() {
    setSaving(true);
    postData(authTokens, "CreateMSPTechnologyCategory", {
      objId_Category: categoryPageData?.objId_Category,
      objId_Customer: clientId,
      CategoryName: categoryPageData?.CategoryName,
      CategoryColour: categoryPageData?.CategoryColour,
      ParentCategoryId: 0,
      isSaas,
      // SubCategory_List: JSON.stringify(sub_category_list),
      accessKey: JSON.parse(localStorage.getItem("user_MessageObj")),
    })
      .then((response) => {
        if (response?.Success) {
          hideModal();
          refreshData();
          CallToast("Updated successfully", false, false, "v2style");
        } else {
          CallToast("Something went wrong", false, false, "v2style");
        }

        setSaving(false);
      })
      .catch((err) => {
        console.log(err);
        CallToast("Something went wrong", false, false, "v2style");

        setSaving(false);
      });
  }

  // Sub Category
  async function checkSubCategoryPageErrors() {
    let errors = 0;
    if (!subCategoryPageData?.CategoryName) {
      errors += 1;
      setErrors((prev) => ({ ...prev, subCategoryNameError: true }));
    }

    return errors;
  }

  async function updateSubCategory() {
    setSaving(true);
    postData(authTokens, "CreateMSPTechnologyCategory", {
      objId_Category: subCategoryPageData?.objId_Category,
      objId_Customer: clientId,
      CategoryName: subCategoryPageData?.CategoryName,
      CategoryColour: subCategoryPageData?.CategoryColour,
      ParentCategoryId: categoryPageData?.objId_Category,
      isSaas,
      // SubCategory_List: JSON.stringify(sub_category_list),
      accessKey: JSON.parse(localStorage.getItem("user_MessageObj")),
    })
      .then((response) => {
        if (response?.Success) {
          hideModal();
          refreshData();
          CallToast("Updated successfully", false, false, "v2style");
        } else {
          CallToast("Something went wrong", false, false, "v2style");
        }

        setSaving(false);
      })
      .catch((err) => {
        console.log(err);
        CallToast("Something went wrong", false, false, "v2style");

        setSaving(false);
      });
  }

  // Invoice Page errors
  async function checkAddSpendPageErrors(tabType) {
    let errors = 0;
    // For handle Add by Invoice Error
    if (tabType === "addByInvoice") {
      if (!invoicePageData?.InvoiceRef) {
        errors += 1;
        setErrors((prev) => ({ ...prev, invoiceRefError: true }));
      }

      if (
        !invoicePageData?.InvoiceDate ||
        invoicePageData.InvoiceDate === "0001-01-01T00:00:00"
      ) {
        errors += 1;
        setErrors((prev) => ({ ...prev, invoiceDateError: true }));
      }
      // if (!validateEmail(invoicePageData?.ContactEmail)) {
      //   errors += 1;
      //   setErrors((prev) => ({ ...prev, invoicePageEmailError: true }));
      // }

      if (!(invoicePageData?.InvoiceItem_List?.length > 0)) {
        errors += 1;
        setErrors((prev) => ({
          ...prev,
          invoiceItemsError:
            "Invoice must contain a miniumum of 1 line item. Please add 1 or more line items to this invoice to proceed",
        }));
      }
    }

    if (tabType === "addByAmount") {
      if (!invoicePageData?.InvoiceRef) {
        errors += 1;
        setErrors((prev) => ({ ...prev, invoiceRefError: true }));
      }
      // if (!validateEmail(invoicePageData?.ContactEmail)) {
      //   errors += 1;
      //   setErrors((prev) => ({ ...prev, invoicePageEmailError: true }));
      // }
      if (
        !invoicePageData?.InvoiceDate ||
        invoicePageData.InvoiceDate === "0001-01-01T00:00:00"
      ) {
        errors += 1;
        setErrors((prev) => ({ ...prev, invoiceDateError: true }));
      }
      if (!invoicePageData?.TotalAmount) {
        errors += 1;
        setErrors((prev) => ({ ...prev, totalAmountError: true }));
      }
    }
    if (tabType === "addBySubscription") {
      if (!invoicePageData?.InvoiceRef) {
        errors += 1;
        setErrors((prev) => ({ ...prev, invoiceRefError: true }));
      }
      if (
        invoicePageData?.ContactEmail &&
        !validateEmail(invoicePageData?.ContactEmail)
      ) {
        errors += 1;
        setErrors((prev) => ({ ...prev, invoicePageEmailError: true }));
      }
      if (!invoicePageData?.Category_Selected) {
        errors += 1;
        setErrors((prev) => ({ ...prev, selectedCategoryError: true }));
      }
      // if (!invoicePageData?.SubCategory_Selected) {
      //   errors += 1;
      //   setErrors((prev) => ({ ...prev, selectedSubCategoryError: true }));
      // }
      if (!invoicePageData?.BillingTenure) {
        errors += 1;
        setErrors((prev) => ({ ...prev, billingTenureError: true }));
      }
      if (!invoicePageData?.SubscriptionStartDate) {
        errors += 1;
        setErrors((prev) => ({ ...prev, subscriptionStartDateError: true }));
      }
      if (!invoicePageData?.SubscriptionEndDate) {
        errors += 1;
        setErrors((prev) => ({ ...prev, subscriptionEndDateError: true }));
      }
      if (!invoicePageData?.AutoRenewalTerms) {
        errors += 1;
        setErrors((prev) => ({ ...prev, autoRenewalTermsError: true }));
      }
      if (!invoicePageData?.CancellationTerms) {
        errors += 1;
        setErrors((prev) => ({ ...prev, cancellationTermsError: true }));
      }

      if (
        !invoicePageData?.InvoiceDate ||
        invoicePageData.InvoiceDate === "0001-01-01T00:00:00"
      ) {
        errors += 1;
        setErrors((prev) => ({ ...prev, invoiceDateError: true }));
      }
      if (!invoicePageData?.TotalAmount) {
        errors += 1;
        setErrors((prev) => ({ ...prev, totalAmountError: true }));
      }
    }

    return errors;
  }

  function getTotalAmountForInvoice() {
    if (addSpendSelectedTab === "addByInvoice") {
      const totalAmount = invoicePageData?.InvoiceItem_List?.reduce(
        (total, item) =>
          total +
          Number(
            item.ItemValue * item.Quantity +
              item.ItemValue *
                item.Quantity *
                ((item?.TaxRates_Selected?.TaxRate ?? 0) / 100)
          ),
        0
      );

      return isNaN(totalAmount)
        ? 0
        : totalAmount - Number(invoicePageData?.Discount);
    } else if (addSpendSelectedTab === "addByAmount") {
      const totalAmount =
        Number(
          (((invoicePageData.TotalAmount ?? 0) - invoicePageData?.Discount ??
            0) *
            (invoicePageData?.TaxRates_Selected?.TaxRate ?? 0)) /
            100 ?? 0
        ) +
        Number(invoicePageData?.TotalAmount ?? 0) -
        Number(invoicePageData?.Discount ?? 0);

      return isNaN(totalAmount) ? 0 : totalAmount;
    } else if (addSpendSelectedTab === "addBySubscription") {
      const totalAmount =
        Number(
          (((invoicePageData.TotalAmount ?? 0) - invoicePageData?.Discount ??
            0) *
            (invoicePageData?.TaxRates_Selected?.TaxRate ?? 0)) /
            100 ?? 0
        ) +
        Number(invoicePageData?.TotalAmount ?? 0) -
        Number(invoicePageData?.Discount ?? 0);

      return isNaN(totalAmount) ? 0 : totalAmount;
    } else {
      return 0;
    }
  }

  function getSubTotalAmountForInvoice() {
    const subTotalAmount = invoicePageData?.InvoiceItem_List?.reduce(
      (total, item) =>
        total +
        Number(
          item.ItemValue * item.Quantity +
            item.ItemValue * item.Quantity * (0 / 100)
        ),
      0
    );

    return subTotalAmount - Number(invoicePageData?.Discount ?? 0);
  }

  function getTaxForInvoice() {
    const totalAmount = invoicePageData?.InvoiceItem_List?.reduce(
      (total, item) =>
        total +
        Number(
          item.ItemValue *
            item.Quantity *
            ((item?.TaxRates_Selected?.TaxRate ?? 0) / 100)
        ),
      0
    );

    return isNaN(totalAmount) ? 0 : totalAmount;
  }

  function highlightedInvoice(title, searchValue) {
    if (searchValue !== "") {
      const highlightedText = title.replace(
        new RegExp(searchValue, "gi"),
        (match) => `<span class="list-highlight-class">${match}</span>`
      );

      return <div dangerouslySetInnerHTML={{ __html: highlightedText }} />;
    } else {
      return <span>{title}</span>;
    }
  }

  function executeInvoiceSwitchingModal(e) {
    setInvoicePageData((prev) => ({
      ...prev,
      InvoiceRef: "",
      Description: "",
      ContactEmail: "",
      PotentialSupplierSelected: {
        PotentialSupplierName: "",
        PotentialSupplierId: 0,
      },
      InvoiceDate: "",
      InvoiceItem_List: [],
      Discount: 0,
      TotalAmount: 0,
      SpendCategory_Name: "",
      Category_Selected: null,
      SubCategory_Selected: null,
      // BillingTenure: null,
      SubscriptionStartDate: "",
      SubscriptionEndDate: "",
      AutoRenewalTerms: null,
      CancellationTerms: null,
    }));
    setErrors({});
    setPotentialSupplierSearch("");
    setAddSpendSelectedTab(e);
    setChangesInInvoice(0);
    setUserSelectedCurrency(defaultCurrencyData);
  }
  const [subCategoryLoading, setsubCategoryLoading] = useState(false);
  const [subCategoryList, setsubCategoryList] = useState([]);
  function getSubCategoryList(_categoryId) {
    setsubCategoryLoading(true);

    postData(authTokens, "GetCategoryDetail", {
      CategoryId: _categoryId,
      ObjId_Customer: clientId,
      LoadInvoice: true,
      accessKey: JSON.parse(localStorage.getItem("user_MessageObj")),
      Amount: "",
      FromDate: moment(selectedDateFilter.startDate).format("DD/MM/YYYY"),
      ToDate: moment(selectedDateFilter.endDate).format("DD/MM/YYYY"),
      SubCategoryId_List: JSON.stringify([]),
      isSaas,
    }).then((response) => {
      setsubCategoryLoading(false);
      setsubCategoryList(response?.SubCategory_List);
    });
  }

  return (
    <SlidingModal
      show={show}
      parentID={parentID}
      id="0016sqxw"
      width={"31%"}
      onHide={hideModal}
      isDraggableParent={isDraggableParent}
    >
      <SlidingModal.Header className="text-center d-flex p-3 border-bottom">
        <span className="cursor-pointer">
          {pages.length > 1 && (
            <img
              src="/images/supply-chain/chevron-left.svg"
              onClick={() => {
                goBack();
              }}
              alt=""
            />
          )}
        </span>

        <div
          className="w-100 mb-0 font-bold-14 title"
          style={{ fontWeight: "600" }}
          title={activePage?.name}
        >
          {activePage?.name}
        </div>

        <span className="cursor-pointer" onClick={hideModal}>
          <img src="/images/attack-surface/cross-icon.svg" alt="" />
        </span>
      </SlidingModal.Header>
      <SlidingModal.Body className="technology-spend-modal">
        {loading ? (
          <div
            className="d-flex align-items-center justify-content-center w-100 "
            style={{
              height: "85vh",
            }}
          >
            <Loader />
          </div>
        ) : (
          <>
            {activePage?.type === Pages.HOME && (
              <div className="p-2">
                <div
                  className="d-flex align-items-center"
                  style={{
                    height: "37px",

                    cursor: `${
                      categoryPageData?.IsDefault === true ? "not-allowed" : ""
                    }`,
                  }}
                >
                  {/* Input */}
                  <div
                    className={`d-flex align-items-center radius-4 grey-border p-2 flex-grow-1 mr-1 ${
                      errors?.categoryNameError ? "input-error" : ""
                    }`}
                    style={{
                      pointerEvents: `${
                        categoryPageData?.IsDefault === true ? "none" : "all"
                      }`,
                    }}
                  >
                    <div className="f-darkgrey">Name:</div>

                    <input
                      type="text"
                      className="bg-transparent w-100 border-none pl-2"
                      placeholder="Type"
                      value={categoryPageData?.CategoryName}
                      onChange={(e) => {
                        let value = e.target.value;
                        setCategoryPageData((prev) => ({
                          ...prev,
                          CategoryName: value,
                        }));

                        setErrors((prev) => ({
                          ...prev,
                          categoryNameError: false,
                        }));
                      }}
                    />
                  </div>

                  <div
                    className="bg -grey h-1 00 d-flex align-items-center"
                    style={
                      {
                        // pointerEvents: `${
                        //   categoryPageData?.IsDefault === true ? "none" : "all"
                        // }`,
                      }
                    }
                  >
                    <ColorDropdown
                      height={37}
                      colorListWithAvailability={
                        categoryPageData?.CategorySpendColor_List ?? []
                      }
                      itemCircleWidth={14}
                      selectedCircleWidth={17}
                      data={categoryPageData?.CategorySpendColor_List}
                      selected={{
                        ColorCode: categoryPageData?.CategoryColourHexCode,
                        ColorName: categoryPageData?.CategoryColour,
                      }}
                      onChange={(_color) => {
                        let colorCode = _color.ColorCode;
                        let colorName = _color.ColorName;
                        setCategoryPageData((prev) => ({
                          ...prev,
                          CategoryColour: colorName,
                          CategoryColourHexCode: colorCode,
                        }));
                      }}
                    />
                  </div>
                </div>

                {categoryPageData?.SyncStatus?.ShowBanner && !isSaas && (
                  <div
                    className={
                      "bg-grey radius-4 d-flex align-items-center justify-content-between mt-2 p-2"
                    }
                  >
                    <div className=" d-flex align-items-center">
                      <img
                        alt=""
                        src="/images/supply-chain/blue-plug.svg"
                        className="mr-2"
                      />
                      <div className="f-black">
                        Integrate your PSA tools to automatically import your
                        clients spend
                      </div>
                    </div>

                    <div className="f-blue d-flex align-items-center">
                      <div className="pointer" onClick={() => {}}>
                        Integrations
                      </div>
                      <img
                        src="/images/supply-chain/blue-redirect.svg"
                        alt=""
                        className="mx-2 pointer"
                        onClick={() => {
                          hideModal();
                          setTimeout(() => {
                            history.push(
                              "/mspv2/integrations?type=connectedIntegrations"
                            );
                          }, 500);
                        }}
                      />
                      {/* <img
                     src="/images/small-cross-icon.svg"
                     alt=""
                     className="mx-2 pointer"
                     onClick={() => { }}
                   /> */}
                    </div>
                  </div>
                )}

                {categoryPageData?.SyncStatus?.IsSyncing === true && !isSaas ? (
                  <div className="d-flex align-items-center f-darkgrey f-500 ml-1 mt-2">
                    <img
                      src="/images/msp/integration-sync-icon.svg"
                      alt=""
                      className="mr-2"
                    />
                    Syncing...
                  </div>
                ) : (
                  <>
                    {categoryPageData?.SyncStatus?.MessageAvatarURL && (
                      <div
                        className="ml-1 d-flex align-items-center mt-2"
                        style={{
                          color: categoryPageData?.SyncStatus?.MessageColour,
                        }}
                      >
                        <AttackSurfaceTooltip
                          content={
                            <>
                              <div className="f-12 f-white"> Updated by:</div>
                              <div className="d-flex align-items-center">
                                <img
                                  src={
                                    categoryPageData?.SyncStatus
                                      ?.MessageAvatarURL
                                  }
                                  alt=""
                                  width={18}
                                  height={18}
                                  className="mr-2 rounded-full"
                                  onError={({ currentTarget }) => {
                                    currentTarget.onerror = null; // prevents looping
                                    currentTarget.src = "/images/user-logo.svg";
                                  }}
                                />{" "}
                                <div className="f-white">
                                  {" "}
                                  {categoryPageData?.SyncStatus?.Source}{" "}
                                </div>
                              </div>
                            </>
                          }
                        >
                          <img
                            src={categoryPageData?.SyncStatus?.MessageAvatarURL}
                            alt=""
                            width={18}
                            height={18}
                            className="mr-2 rounded-full"
                            onError={({ currentTarget }) => {
                              currentTarget.onerror = null; // prevents looping
                              currentTarget.src = "/images/user-logo.svg";
                            }}
                          />
                        </AttackSurfaceTooltip>
                        <div> {categoryPageData?.SyncStatus?.Message}</div>
                      </div>
                    )}
                  </>
                )}

                <div className="f-500 f-grey p-3">
                  {categoryPageData?.CategoryName} spend
                </div>
                <div className="f-18 f-500 f-black pl-3">
                  {categoryPageData?.TotalAmount_Text}
                </div>
                <div className="chart">
                  <MspDonutChart
                    height={100}
                    thickness={15}
                    graphData={getSpendDataForGraph()}
                    forwardedRef={categoryGraphRef}
                  />
                </div>
                {/* SUB_CATEGORIES BLOCK */}
                <div className="">
                  <div className="d-flex align-items-center justify-content-between my-2">
                    <div className="f-500 f-grey pl-2">Subcategories</div>
                    <div className="d-flex align-items-center">
                      <div
                        className="p-2 d-flex align-items-center bg-grey f-500 f-grey pointer"
                        onClick={() => {
                          setShowCreateSubcategoryModal(true);
                        }}
                      >
                        <img
                          alt=""
                          className="mr-2"
                          src="/images/actioncenter/plusgrey.svg"
                        />
                        Add
                      </div>
                      {sortingSubcategoryList?.length > 0 && (
                        <div className="ml-1 position-relative zoom-independent">
                          <MSPCatSubcatSortFilters
                            loading={loading}
                            NoDataStateText={"No subcategories !"}
                            id="cremica"
                            headersList={sortingSubcategoryList}
                            updatedHeaderList={(e) => {
                              let updated_data = e.map((item) => ({
                                CategoryColor: item?.CategoryColor,
                                CategoryID: item?.objId_Category,
                                CategoryName: item?.CategoryName,
                                CategorySortOrder: item?.SortOrder,
                                CategoryVisibility: item?.Visibility,
                                IsEditable: item?.IsEditable,
                                SubCatSettings: null,
                              }));
                              updateSubCategorySorting(updated_data);
                            }}
                          >
                            <div className="bg-grey py-2 px-3 radius-4">
                              <img
                                src="/images/settings-v2/horizontal-dots.svg"
                                className=""
                                alt=""
                              />
                            </div>
                          </MSPCatSubcatSortFilters>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="w-100 position-relative">
                    {subCategoriesLoading && (
                      <div
                        className="position-absolute"
                        style={{
                          top: "0px",
                          bottom: "0px",
                          left: "0px",
                          right: "0px",
                          opacity: "0.5",
                          background: "white",
                          zIndex: 1,
                        }}
                      ></div>
                    )}

                    {categoryPageData?.SyncStatus?.isFirstRun === true &&
                    !isSaas ? (
                      <div className="d-flex align-items-center f-darkgrey py-2 pl-2">
                        <img
                          src="/images/msp/syncing-icon.svg"
                          alt=""
                          className="mr-2"
                        />
                        Data is syncing...
                      </div>
                    ) : (
                      <>
                        {categoryPageData?.SubCategory_List?.length === 0 && (
                          <div className=" my-3 f-darkgrey pl-2">
                            Nothing to show yet
                          </div>
                        )}

                        {categoryPageData?.SubCategory_List?.map(
                          (subCategory, subCategoryIndex) => {
                            return (
                              <div
                                className="bg-hover-grey radius-4 d-flex align-items-center justify-content-between pointer p-2"
                                onMouseEnter={() => {
                                  categoryGraphRef.current.expandSpecificSegement(
                                    subCategory.objId_Category
                                  );
                                }}
                                onMouseLeave={() => {
                                  categoryGraphRef.current.expandSpecificSegement(
                                    null
                                  );
                                }}
                                onClick={() => {
                                  setLoading(true);
                                  goTo(
                                    <>
                                      <div
                                        style={{
                                          width: "8px",
                                          height: "8px",
                                          background:
                                            subCategory?.CategoryColourHexCode,
                                        }}
                                        alt=""
                                        className="mr-2 rounded-full d-inline-block"
                                      />
                                      {TrimString(
                                        subCategory?.CategoryName,
                                        30
                                      )}
                                    </>,
                                    Pages.SUBCATEGORY,
                                    {}
                                  );
                                  getSubCategoryDetail(
                                    subCategory?.objId_Category
                                  );
                                }}
                              >
                                <div
                                  className="d-flex align-items-center"
                                  title={subCategory?.CategoryName}
                                >
                                  <div
                                    className="rounded-full mr-2"
                                    style={{
                                      width: "7px",
                                      height: "7px",
                                      background:
                                        subCategory?.CategoryColourHexCode,
                                    }}
                                  />
                                  {TrimString(subCategory?.CategoryName, 30)}
                                </div>
                                <div title={subCategory?.TotalAmount_Text}>
                                  {TrimString(
                                    subCategory?.TotalAmount_Text,
                                    20
                                  )}
                                </div>
                              </div>
                            );
                          }
                        )}
                      </>
                    )}
                  </div>
                  {false && (
                    <div
                      className="d-flex align-items-center f-grey w-fit-content pointer mt-2"
                      onClick={() => {
                        setShowCreateCategoryModal(true);
                      }}
                    >
                      <img
                        alt=""
                        className="mx-2"
                        src="/images/msp/arrow-down.svg"
                      />
                      Show more
                    </div>
                  )}
                </div>

                <hr />

                {/* Automatic / Manual Spend Tabs */}
                <div className="f-500 f-grey mb-1 pl-2">Spend</div>

                <div className="my-2">
                  <TechnologySpendFilter
                    subCategoryList={categoryPageData?.SubCategory_List ?? []}
                    hideFilters={
                      categoryPageData?.SubCategory_List?.length > 0
                        ? []
                        : ["Subcategory"]
                    }
                    applyingFilters={applyingFilters}
                    invoiceFilterSearch={invoiceFilterSearch}
                    setInvoiceFilterSearch={(value) => {
                      setInvoiceFilterSearch(value);
                    }}
                    activeFilters={activeFilters}
                    setActiveFilters={setActiveFilters}
                    onClickAdd={() => {
                      setLoading(true);
                      setAddSpendSelectedTab("addByInvoice");
                      setInvoiceEditMode(true);
                      goTo("Add Spend", Pages.ADD_SPEND, {});
                      getInvoiceDetails(0, categoryPageData?.ID);
                    }}
                    onApply={(_filters) => {
                      const filterData = getFilters(_filters);
                      console.log(_filters, "_filters= onApply");
                      if (filterData || true) {
                        getCategoryDetail(categoryPageData.ID, filterData);
                        setApplyingFilters(true);
                        if (
                          activeFilters.filter(
                            (item) => item?.filterType === "date"
                          ).length > 0
                        ) {
                          dispatch(
                            mspClientDashboardDatePickerState({
                              startDate: moment(
                                filterData?.fromDate
                                  ? filterData?.fromDate
                                  : defineds.startOfLastTwelveMonth,
                                "DD/MM/YYYY"
                              ).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
                              endDate: moment(
                                filterData?.toDate
                                  ? filterData?.toDate
                                  : defineds.endOfToday,
                                "DD/MM/YYYY"
                              ).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
                              key: "selection",
                            })
                          );
                        } else {
                          dispatch(
                            mspClientDashboardDatePickerState({
                              startDate: defineds.startOfLastTwelveMonth,
                              endDate: defineds.endOfToday,
                              key: "selection",
                            })
                          );
                        }
                      }
                    }}
                    onReset={() => {
                      // getCategoryDetail(categoryPageData.ID);
                      dispatch(
                        mspClientDashboardDatePickerState({
                          startDate: defineds.startOfLastTwelveMonth,
                          endDate: defineds.endOfToday,
                          key: "selection",
                        })
                      );
                    }}
                  />
                </div>

                {applyingFilters ? (
                  <div className="d-flex align-items-center justify-content-center w-100 mt-5 mb-5">
                    <GreyLoader width={18} />
                  </div>
                ) : (
                  // <> {categoryPageData?.InvoiceItemVM_List?.length === 0 ? (
                  //   <div className="my-5 d-flex flex-column align-items-center justify-content-center">
                  //     <div className="w-fit-content">
                  //       <img
                  //         src="/images/msp/msp-spend-invoice-icon.svg"
                  //         alt=""
                  //         className=""
                  //       />
                  //     </div>
                  //     <div className="w-fit-content f-500 mt-2 d-grey">
                  //       No spend yet
                  //     </div>
                  //     <div className="w-fit-content f-darkgrey">Add spend</div>
                  //   </div>
                  //  ) :

                  <div className="mt-2">
                    <Tab.Container
                      id="left-tabs-example"
                      defaultActiveKey={defaultSelectedTab}
                    >
                      <div>
                        <div
                          sm={12}
                          className="tab-header d-flex justify-content-between align-items-center ml- p-0"
                        >
                          <div className={`${true}`}>
                            <Nav
                              variant="pills"
                              className="flex-row"
                              onSelect={(e) => setDefaultSelectedTab(e)}
                            >
                              {!isSaas && (
                                <Nav.Item>
                                  <Nav.Link eventKey="automaticSpend">
                                    Automatic Spend
                                  </Nav.Link>
                                </Nav.Item>
                              )}
                              <Nav.Item id="">
                                <Nav.Link eventKey="manualSpend">
                                  Manual Spend
                                </Nav.Link>
                              </Nav.Item>
                              <Nav.Item id="">
                                <Nav.Link eventKey="subscriptionSpend">
                                  Subscription Spend
                                </Nav.Link>
                              </Nav.Item>
                            </Nav>
                          </div>
                        </div>
                        <div sm={12} className="ml-1 p-0">
                          <Tab.Content>
                            <Tab.Pane eventKey="automaticSpend">
                              {categoryPageData?.InvoiceItemVM_List?.filter(
                                (item) =>
                                  searchSpendInvoices(
                                    item?.InvoiceItem_List,
                                    "Title",
                                    invoiceFilterSearch,
                                    true
                                  )
                              )?.map((invoice, invoiceIndex) => {
                                return (
                                  <div>
                                    <div className="f-12 f-500 f-darkgrey my-2 w-100 d-flex align-items-center  justify-content-center">
                                      {invoice?.GroupInvoiceDate}
                                    </div>
                                    {invoice?.InvoiceItem_List?.filter((item) =>
                                      item?.Title?.includes(invoiceFilterSearch)
                                    )
                                      ?.filter(
                                        (_item) => _item["IsImported"] === true
                                      )
                                      ?.map((invoiceItem, invoiceItemIndex) => {
                                        return (
                                          <div
                                            className="invoice-row p-2 bg-hover-grey radius-4 pointer"
                                            onClick={() => {
                                              setLoading(true);
                                              setInvoiceEditMode(
                                                invoiceItem?.Editable
                                              );
                                              if (invoiceItem?.IsDirectAmount) {
                                                setAddSpendSelectedTab(
                                                  "addByAmount"
                                                );
                                              } else {
                                                setAddSpendSelectedTab(
                                                  "addByInvoice"
                                                );
                                              }
                                              goTo(
                                                <div className="d-flex align-items-center justify-content-center">
                                                  <div className="position-relative w-fit-content h-fit-content">
                                                    <img
                                                      alt=""
                                                      className="mr-2"
                                                      src="/images/msp/msp-invoice-icon.svg"
                                                    />
                                                    {invoiceItem?.objId_Integration >
                                                      0 && (
                                                      <img
                                                        src={`/images/attack-surface/integrations/${invoiceItem?.objId_Integration}.webp`}
                                                        alt=""
                                                        className="position-absolute rounded-full"
                                                        width={13}
                                                        height={13}
                                                        style={{
                                                          top: "44%",
                                                          right: "4px",
                                                        }}
                                                      />
                                                    )}
                                                  </div>
                                                  {TrimString(
                                                    invoiceItem?.Title,
                                                    30
                                                  )}
                                                </div>,

                                                Pages.ADD_SPEND,
                                                {}
                                              );
                                              getInvoiceDetails(
                                                invoiceItem?.objId_Invoice,
                                                categoryPageData?.ID
                                              );
                                            }}
                                          >
                                            <div className="d-flex align-items-center">
                                              <div className="position-relative w-fit-content h-fit-content">
                                                <img
                                                  alt=""
                                                  className="mr-2"
                                                  src="/images/msp/msp-invoice-icon.svg"
                                                />
                                                {invoiceItem?.objId_Integration >
                                                  0 && (
                                                  <img
                                                    src={`/images/attack-surface/integrations/${invoiceItem?.objId_Integration}.webp`}
                                                    alt=""
                                                    className="position-absolute rounded-full"
                                                    width={13}
                                                    height={13}
                                                    style={{
                                                      top: "44%",
                                                      right: "4px",
                                                    }}
                                                  />
                                                )}
                                              </div>
                                              {highlightedInvoice(
                                                invoiceItem?.Title,
                                                invoiceFilterSearch
                                              )}
                                            </div>
                                            <div>
                                              {invoiceItem?.subCategory ? (
                                                <div className=" d-flex align-items-center f-12 f-500 f-grey rounded-full w-fit-content bg-greyish px-2">
                                                  <div
                                                    className="rounded-full mr-2"
                                                    style={{
                                                      width: "9px",
                                                      height: "9px",
                                                      background:
                                                        invoiceItem?.subCategory
                                                          ?.color,
                                                    }}
                                                  />

                                                  {
                                                    invoiceItem?.subCategory
                                                      ?.name
                                                  }
                                                </div>
                                              ) : (
                                                ""
                                              )}
                                            </div>
                                            <div className="f-black f-500 d-flex align-items-center justify-content-end">
                                              <AttackSurfaceTooltip
                                                topLeft={`invoiceIndex ===
                                                  categoryPageData
                                                    ?.InvoiceItemVM_List
                                                    ?.length -
                                                    1`}
                                                content={
                                                  categoryPageData?.SpendInvoiceToolTip
                                                }
                                              >
                                                <span>
                                                  {invoiceItem?.ItemValue_Text?.replaceAll(
                                                    " ",
                                                    ""
                                                  )}
                                                </span>
                                              </AttackSurfaceTooltip>
                                              <AttackSurfaceTooltip
                                                bottomLeft={
                                                  invoiceIndex <
                                                  categoryPageData
                                                    ?.InvoiceItemVM_List
                                                    ?.length -
                                                    1
                                                }
                                                topLeft={
                                                  invoiceIndex ===
                                                  categoryPageData
                                                    ?.InvoiceItemVM_List
                                                    ?.length -
                                                    1
                                                }
                                                content={
                                                  categoryPageData?.TotalSpendInvoiceToolTip
                                                }
                                              >
                                                <span
                                                  className="f-darkgrey"
                                                  style={{
                                                    whiteSpace: "nowrap",
                                                  }}
                                                >
                                                  &nbsp;/&nbsp;
                                                  {invoiceItem?.TotalItemValue_Text?.replaceAll(
                                                    " ",
                                                    ""
                                                  )}
                                                </span>
                                              </AttackSurfaceTooltip>
                                            </div>
                                          </div>
                                        );
                                      })}
                                  </div>
                                );
                              })}

                              {categoryPageData?.InvoiceItemVM_List?.filter(
                                (item) =>
                                  searchSpendInvoices(
                                    item?.InvoiceItem_List,
                                    "Title",
                                    invoiceFilterSearch,
                                    true
                                  )
                              )?.length === 0 &&
                                invoiceFilterSearch !== "" && (
                                  <NoSpendFoundUI />
                                )}

                              {categoryPageData?.InvoiceItemVM_List?.filter(
                                (item) =>
                                  searchSpendInvoices(
                                    item?.InvoiceItem_List,
                                    "Title",
                                    invoiceFilterSearch,
                                    true
                                  )
                              )?.length === 0 &&
                                invoiceFilterSearch === "" && (
                                  <>
                                    {" "}
                                    {categoryPageData?.SyncStatus
                                      ?.isFirstRun === true ? (
                                      <AutomaticSpendSyncingUI />
                                    ) : (
                                      <AutomaticNoSpendFoundUI />
                                    )}
                                  </>
                                )}
                            </Tab.Pane>
                            <Tab.Pane eventKey="manualSpend">
                              {categoryPageData?.InvoiceItemVM_List?.filter(
                                (item) =>
                                  searchSpendInvoices(
                                    item?.InvoiceItem_List,
                                    "Title",
                                    invoiceFilterSearch,
                                    false
                                  )
                              )?.map((invoice, invoiceIndex) => {
                                return (
                                  <div>
                                    <div className="f-12 f-500 f-darkgrey my-2 w-100 d-flex align-items-center  justify-content-center">
                                      {invoice?.GroupInvoiceDate}
                                    </div>
                                    {invoice?.InvoiceItem_List?.filter((item) =>
                                      item?.Title?.includes(invoiceFilterSearch)
                                    )
                                      ?.filter(
                                        (_item) => _item["IsImported"] === false
                                      )
                                      ?.map((invoiceItem, invoiceItemIndex) => {
                                        return (
                                          <div
                                            className="invoice-row p-2 bg-hover-grey radius-4 pointer"
                                            onClick={() => {
                                              setLoading(true);
                                              setInvoiceEditMode(
                                                invoiceItem?.Editable
                                              );
                                              if (invoiceItem?.IsDirectAmount) {
                                                setAddSpendSelectedTab(
                                                  "addByAmount"
                                                );
                                              } else {
                                                setAddSpendSelectedTab(
                                                  "addByInvoice"
                                                );
                                              }
                                              goTo(
                                                <>
                                                  <img
                                                    src="/images/msp/msp-invoice-icon.svg"
                                                    alt=""
                                                    className="mr-2 d-inline-block"
                                                  />
                                                  {TrimString(
                                                    invoiceItem?.Title,
                                                    30
                                                  )}
                                                </>,
                                                Pages.ADD_SPEND,
                                                {}
                                              );
                                              getInvoiceDetails(
                                                invoiceItem?.objId_Invoice,
                                                categoryPageData?.ID
                                              );
                                            }}
                                          >
                                            <div className="d-flex align-items-center">
                                              {" "}
                                              <img
                                                alt=""
                                                className="mr-2"
                                                src="/images/msp/msp-invoice-icon.svg"
                                              />{" "}
                                              {highlightedInvoice(
                                                invoiceItem?.Title,
                                                invoiceFilterSearch
                                              )}
                                            </div>
                                            <div>
                                              {invoiceItem?.subCategory ? (
                                                <div className=" d-flex align-items-center f-12 f-500 f-grey rounded-full w-fit-content bg-greyish px-2">
                                                  <div
                                                    className="rounded-full mr-2"
                                                    style={{
                                                      width: "9px",
                                                      height: "9px",
                                                      background:
                                                        invoiceItem?.subCategory
                                                          ?.color,
                                                    }}
                                                  />

                                                  {
                                                    invoiceItem?.subCategory
                                                      ?.name
                                                  }
                                                </div>
                                              ) : (
                                                ""
                                              )}
                                            </div>
                                            <div className="f-black f-500 d-flex align-items-center justify-content-end">
                                              <AttackSurfaceTooltip
                                                // bottomLeft={
                                                //   invoiceIndex <
                                                //   categoryPageData
                                                //     ?.InvoiceItemVM_List
                                                //     ?.length -
                                                //     1
                                                // }
                                                topLeft={`invoiceIndex ===
                                                  categoryPageData
                                                    ?.InvoiceItemVM_List
                                                    ?.length -
                                                    1`}
                                                content={
                                                  categoryPageData?.SpendInvoiceToolTip
                                                }
                                              >
                                                <span>
                                                  {invoiceItem?.ItemValue_Text?.replaceAll(
                                                    " ",
                                                    ""
                                                  )}
                                                </span>
                                              </AttackSurfaceTooltip>
                                              <AttackSurfaceTooltip
                                                bottomLeft={
                                                  invoiceIndex <
                                                  categoryPageData
                                                    ?.InvoiceItemVM_List
                                                    ?.length -
                                                    1
                                                }
                                                topLeft={
                                                  invoiceIndex ===
                                                  categoryPageData
                                                    ?.InvoiceItemVM_List
                                                    ?.length -
                                                    1
                                                }
                                                content={
                                                  categoryPageData?.TotalSpendInvoiceToolTip
                                                }
                                              >
                                                <span
                                                  className="f-darkgrey"
                                                  style={{
                                                    whiteSpace: "nowrap",
                                                  }}
                                                >
                                                  &nbsp;/&nbsp;
                                                  {invoiceItem?.TotalItemValue_Text?.replaceAll(
                                                    " ",
                                                    ""
                                                  )}
                                                </span>
                                              </AttackSurfaceTooltip>
                                            </div>
                                          </div>
                                        );
                                      })}
                                  </div>
                                );
                              })}

                              {categoryPageData?.InvoiceItemVM_List?.filter(
                                (item) =>
                                  searchSpendInvoices(
                                    item?.InvoiceItem_List,
                                    "Title",
                                    invoiceFilterSearch,
                                    false
                                  )
                              )?.length === 0 && (
                                <ManualNoSpendFoundUI
                                  onClickAdd={(e) => {
                                    setLoading(true);
                                    setAddSpendSelectedTab("addByInvoice");
                                    setInvoiceEditMode(true);
                                    goTo("Add Spend", Pages.ADD_SPEND, {});
                                    getInvoiceDetails(0, categoryPageData?.ID);
                                  }}
                                />
                              )}
                            </Tab.Pane>
                            <Tab.Pane eventKey="subscriptionSpend">
                              <div className="f-12 f-500 f-darkgrey my-2">
                                Invoices
                              </div>
                              {categoryPageData?.InvoiceItemVM_List?.filter(
                                (item) =>
                                  searchSpendInvoices(
                                    item?.InvoiceItem_List,
                                    "Title",
                                    invoiceFilterSearch,
                                    false,
                                    true
                                  )
                              )?.map((invoice, invoiceIndex) => {
                                return (
                                  <div>
                                    {/* <div className="f-12 f-500 f-darkgrey my-2 w-100 d-flex align-items-center  justify-content-center">
                                      {invoice?.GroupInvoiceDate}
                                    </div> */}
                                    {invoice?.InvoiceItem_List?.filter((item) =>
                                      item?.Title?.includes(invoiceFilterSearch)
                                    )
                                      ?.filter(
                                        (_item) => _item["IsImported"] === false
                                      )
                                      ?.map((invoiceItem, invoiceItemIndex) => {
                                        return (
                                          <div
                                            className="invoice-row p-2 bg-hover-grey radius-4 pointer"
                                            onClick={() => {
                                              setLoading(true);
                                              setInvoiceEditMode(
                                                invoiceItem?.Editable
                                              );
                                              // if (invoiceItem?.IsDirectAmount) {
                                              //   setAddSpendSelectedTab(
                                              //     "addByAmount"
                                              //   );
                                              // } else {
                                              //   setAddSpendSelectedTab(
                                              //     "addByInvoice"
                                              //   );
                                              // }
                                              setAddSpendSelectedTab(
                                                "addBySubscription"
                                              );
                                              goTo(
                                                <>
                                                  <img
                                                    src="/images/msp/msp-invoice-icon.svg"
                                                    alt=""
                                                    className="mr-2 d-inline-block"
                                                  />
                                                  {TrimString(
                                                    invoiceItem?.Title,
                                                    30
                                                  )}
                                                </>,
                                                Pages.ADD_SPEND,
                                                {
                                                  invoiceId:
                                                    invoiceItem?.objId_Invoice,
                                                  categoryId:
                                                    categoryPageData?.ID,
                                                  subCategoryId: 0,
                                                  invoiceType:
                                                    "addBySubscription",
                                                }
                                              );
                                              getInvoiceDetails(
                                                invoiceItem?.objId_Invoice,
                                                categoryPageData?.ID
                                              );
                                            }}
                                          >
                                            <div className="d-flex align-items-center">
                                              {" "}
                                              <img
                                                alt=""
                                                className="mr-2"
                                                src="/images/msp/msp-invoice-icon.svg"
                                              />{" "}
                                              {highlightedInvoice(
                                                invoiceItem?.Title,
                                                invoiceFilterSearch
                                              )}
                                            </div>
                                            <div>
                                              {invoiceItem?.subCategory ? (
                                                <div className=" d-flex align-items-center f-12 f-500 f-grey rounded-full w-fit-content bg-greyish px-2">
                                                  <div
                                                    className="rounded-full mr-2"
                                                    style={{
                                                      width: "9px",
                                                      height: "9px",
                                                      background:
                                                        invoiceItem?.subCategory
                                                          ?.color,
                                                    }}
                                                  />

                                                  {
                                                    invoiceItem?.subCategory
                                                      ?.name
                                                  }
                                                </div>
                                              ) : (
                                                ""
                                              )}
                                            </div>
                                            <div className="f-black f-500 d-flex align-items-center justify-content-end">
                                              <AttackSurfaceTooltip
                                                // bottomLeft={
                                                //   invoiceIndex <
                                                //   categoryPageData
                                                //     ?.InvoiceItemVM_List
                                                //     ?.length -
                                                //     1
                                                // }
                                                topLeft={`invoiceIndex ===
                                                  categoryPageData
                                                    ?.InvoiceItemVM_List
                                                    ?.length -
                                                    1`}
                                                content={
                                                  categoryPageData?.SpendInvoiceToolTip
                                                }
                                              >
                                                <span>
                                                  {invoiceItem?.ItemValue_Text?.replaceAll(
                                                    " ",
                                                    ""
                                                  )}
                                                </span>
                                              </AttackSurfaceTooltip>
                                              <AttackSurfaceTooltip
                                                bottomLeft={
                                                  invoiceIndex <
                                                  categoryPageData
                                                    ?.InvoiceItemVM_List
                                                    ?.length -
                                                    1
                                                }
                                                topLeft={
                                                  invoiceIndex ===
                                                  categoryPageData
                                                    ?.InvoiceItemVM_List
                                                    ?.length -
                                                    1
                                                }
                                                content={
                                                  categoryPageData?.TotalSpendInvoiceToolTip
                                                }
                                              >
                                                <span
                                                  className="f-darkgrey"
                                                  style={{
                                                    whiteSpace: "nowrap",
                                                  }}
                                                >
                                                  &nbsp;/&nbsp;
                                                  {invoiceItem?.TotalItemValue_Text?.replaceAll(
                                                    " ",
                                                    ""
                                                  )}
                                                </span>
                                              </AttackSurfaceTooltip>
                                            </div>
                                          </div>
                                        );
                                      })}
                                  </div>
                                );
                              })}

                              {categoryPageData?.InvoiceItemVM_List?.filter(
                                (item) =>
                                  searchSpendInvoices(
                                    item?.InvoiceItem_List,
                                    "Title",
                                    invoiceFilterSearch,
                                    false,
                                    true
                                  )
                              )?.length === 0 && (
                                <NoSubscriptionSpendFoundUI
                                  onClickAdd={(e) => {
                                    setLoading(true);
                                    setAddSpendSelectedTab("addBySubscription");
                                    setInvoiceEditMode(true);
                                    goTo("Add Spend", Pages.ADD_SPEND, {});
                                    getInvoiceDetails(0, categoryPageData?.ID);
                                  }}
                                />
                              )}
                            </Tab.Pane>
                          </Tab.Content>
                        </div>
                      </div>
                    </Tab.Container>
                  </div>
                )}

                {/* </>} */}
              </div>
            )}

            {activePage?.type === Pages.ADD_SPEND && (
              <>
                <Tab.Container
                  id="left-tabs-example"
                  defaultActiveKey={addSpendSelectedTab}
                  activeKey={addSpendSelectedTab}
                  onSelect={(e) => {
                    let tabName = e;
                    if (changesInInvoice > 0) {
                      setSwitchInvoiceTabModalData((prev) => ({
                        ...prev,
                        deleteParams: tabName,
                        showModal: true,
                      }));
                    } else {
                      executeInvoiceSwitchingModal(e);
                    }
                  }}
                >
                  <div>
                    {invoicePageData?.objId_Invoice === 0 && (
                      <div
                        sm={12}
                        className="tab-header d-flex justify-content-between align-items-center ml- p-0"
                      >
                        <div className={`${true}`}>
                          <Nav
                            variant="pills"
                            className="flex-row"
                            onSelect={(e) => {
                              let tabName = e;
                              if (changesInInvoice > 0) {
                                setSwitchInvoiceTabModalData((prev) => ({
                                  ...prev,
                                  deleteParams: tabName,
                                  showModal: true,
                                }));
                              } else {
                                executeInvoiceSwitchingModal(e);
                              }
                            }}
                          >
                            <Nav.Item>
                              <Nav.Link eventKey="addByInvoice">
                                Add Invoice
                              </Nav.Link>
                            </Nav.Item>
                            <Nav.Item id="">
                              <Nav.Link eventKey="addByAmount">
                                Add Amount
                              </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                              <Nav.Link eventKey="addBySubscription">
                                Add subscription
                              </Nav.Link>
                            </Nav.Item>
                          </Nav>
                        </div>
                      </div>
                    )}
                    <div sm={12} className="ml-1 p-0">
                      <Tab.Content>
                        <Tab.Pane eventKey="addByInvoice">
                          <div className="p-3">
                            <div
                              className="w-100"
                              style={{
                                pointerEvents: invoiceEditMode ? "all" : "none",
                              }}
                            >
                              {invoicePageData?.IntegrationId > 0 ? (
                                <div className="w-100 py-2 d-flex align-items-center f-black">
                                  <div className="w-50 f-500">Invoice # </div>
                                  <div className="w-50 f-500">
                                    {invoicePageData?.InvoiceRef ?? "N/A"}
                                  </div>
                                </div>
                              ) : (
                                <div
                                  tabIndex={-1}
                                  className={`  ${
                                    errors?.invoiceRefError ? "input-error" : ""
                                  }   d-flex align-items-center radius-4 grey-border p-2 flex-grow-1 mr-1 highlight-input-with-grey-border`}
                                >
                                  <div className="f-darkgrey f-500">
                                    Invoice #{" "}
                                  </div>
                                  <input
                                    tabIndex={0}
                                    type="text"
                                    className="bg-transparent flex-grow-1 border-none pl-2 "
                                    placeholder="Type"
                                    value={invoicePageData?.InvoiceRef ?? ""}
                                    onChange={(e) => {
                                      setChangesInInvoice(changesInInvoice + 1);
                                      let value = e.target.value;
                                      setInvoicePageData((prev) => ({
                                        ...prev,
                                        InvoiceRef: removeWhiteSpace(value),
                                      }));
                                      setErrors((prev) => ({
                                        ...prev,
                                        invoiceRefError: false,
                                      }));
                                    }}
                                  />
                                </div>
                              )}
                            </div>

                            {invoicePageData?.IsImported === false &&
                              invoicePageData?.IntegrationId > 0 && (
                                <>
                                  <div className="d-flex align-items-center my-1">
                                    <div className="f-12 f-darkgrey">
                                      Invoice was unlinked from{" "}
                                      {
                                        all_integration_data[
                                          invoicePageData?.IntegrationId
                                        ]
                                      }{" "}
                                      &nbsp;{invoicePageData?.UnlinkTime}&nbsp;
                                    </div>
                                    <div
                                      className="pointer f-blue f-500"
                                      onClick={() => {
                                        setUnlinkRelinkModalData((prev) => ({
                                          ...prev,
                                          show: true,
                                          linkButtonText: "Relink",
                                          headerText: `${`Relink ${
                                            invoicePageData.InvoiceRef
                                          } to ${
                                            all_integration_data[
                                              invoicePageData?.IntegrationId
                                            ]
                                          }`}`,
                                          bodyText: `${`All edits you have made to this invoice in HighGround will be lost.\n\nAre you sure you would like to relink ${
                                            invoicePageData.InvoiceRef
                                          } to ${
                                            all_integration_data[
                                              invoicePageData?.IntegrationId
                                            ]
                                          }?`}`,
                                          deleteParams: {
                                            linkStatus: true,
                                            objId_invoice:
                                              invoicePageData?.objId_Invoice,
                                          },
                                        }));
                                      }}
                                    >
                                      Relink
                                    </div>
                                  </div>
                                </>
                              )}
                            <div
                              className="w-100"
                              style={{
                                pointerEvents: invoiceEditMode ? "all" : "none",
                              }}
                            >
                              {/* Description */}
                              {invoicePageData?.IntegrationId > 0 ? (
                                <div className="w-100 py-2 d-flex align-items-center f-black">
                                  <div className="w-50 f-500">Description</div>
                                  <div className="w-50">
                                    {invoicePageData?.Description
                                      ? invoicePageData?.Description
                                      : "N/A"}
                                  </div>
                                </div>
                              ) : (
                                <div
                                  tabIndex={-1}
                                  className={`  ${
                                    errors?.descriptionError
                                      ? "input-error"
                                      : ""
                                  }   d-flex align-items-center radius-4 grey-border p-2 flex-grow-1 mr-1 mt-2 highlight-input-with-grey-border`}
                                >
                                  <textarea
                                    tabIndex={0}
                                    rows={4}
                                    style={{ resize: "none" }}
                                    className="bg-transparent flex-grow-1 border-none  hide-scrollbar"
                                    placeholder="Description"
                                    value={invoicePageData?.Description ?? ""}
                                    onChange={(e) => {
                                      setChangesInInvoice(changesInInvoice + 1);
                                      let value = e.target.value;
                                      setInvoicePageData((prev) => ({
                                        ...prev,
                                        Description: removeWhiteSpace(value),
                                      }));
                                      setErrors((prev) => ({
                                        ...prev,
                                        descriptionError: false,
                                      }));
                                    }}
                                  />
                                </div>
                              )}

                              <hr tabIndex={-1} />
                              <div
                                className="f-12 f-500 f-darkgrey mb-2"
                                tabIndex={-1}
                              >
                                Details
                              </div>
                              <div
                                className="w-100  d-flex align-items-center f-black"
                                tabIndex={-1}
                              >
                                <div className="w-50 f-500">Supplier</div>
                                <div className="w-50">
                                  {invoicePageData?.IntegrationId > 0 ? (
                                    <>
                                      {invoicePageData?.PotentialSupplierDetail_List?.filter(
                                        (pt) => pt?.IsMSP === true
                                      )?.map((item, index) => {
                                        return (
                                          <div className="d-flex align-items-center justify-content-between px-2 py-1 pointer">
                                            <div className="d-flex align-items-center f-black">
                                              <img
                                                src={
                                                  item?.PotentialSupplierIcon
                                                }
                                                onError={({
                                                  currentTarget,
                                                }) => {
                                                  currentTarget.onerror = null; // prevents looping
                                                  currentTarget.src =
                                                    "/images/user-logo.svg";
                                                }}
                                                width={18}
                                                height={18}
                                                className="mr-2 rounded-full"
                                                alt=""
                                              />
                                              {item?.PotentialSupplierName}
                                            </div>
                                            <div className="f-12 f-darkgrey">
                                              You
                                            </div>
                                          </div>
                                        );
                                      })}
                                    </>
                                  ) : (
                                    <Dropdown
                                      show={showAddByInvoiceSupplierDropdown}
                                      onToggle={(isOpen) =>
                                        setShowAddByInvoiceSupplierDropdown(
                                          isOpen
                                        )
                                      }
                                      className="add-item-category-dropdown w-100"
                                    >
                                      <Dropdown.Toggle className="w-100">
                                        <div className="highlight-input p-2 radius-4 d-flex align-items-center">
                                          {invoicePageData
                                            ?.PotentialSupplierSelected
                                            ?.IsMSP === true ? (
                                            <div
                                              className="d-flex align-items-center justify-content-between  w-100 pointer"
                                              style={{
                                                height: "15px",
                                              }}
                                            >
                                              <div className="d-flex align-items-center f-black">
                                                <img
                                                  src={
                                                    invoicePageData
                                                      ?.PotentialSupplierSelected
                                                      ?.PotentialSupplierIcon
                                                  }
                                                  onError={({
                                                    currentTarget,
                                                  }) => {
                                                    currentTarget.onerror =
                                                      null; // prevents looping
                                                    currentTarget.src =
                                                      "/images/user-logo.svg";
                                                  }}
                                                  width={14}
                                                  height={14}
                                                  className="mr-2 rounded-full"
                                                  alt=""
                                                />
                                                {
                                                  invoicePageData
                                                    ?.PotentialSupplierSelected
                                                    ?.PotentialSupplierName
                                                }
                                              </div>
                                              <div className="f-12 f-darkgrey">
                                                You
                                              </div>
                                            </div>
                                          ) : (
                                            <input
                                              tabIndex={0}
                                              className="border-none bg-transparent flex-grow-1"
                                              type="text"
                                              placeholder="Optional"
                                              value={
                                                invoicePageData
                                                  ?.PotentialSupplierSelected
                                                  ?.PotentialSupplierName
                                              }
                                              onChange={(e) => {
                                                setChangesInInvoice(
                                                  changesInInvoice + 1
                                                );
                                                setShowAddByAmountSupplierDropdown(
                                                  true
                                                );
                                                let value = e.target.value;
                                                setPotentialSupplierSearch(
                                                  e.target.value
                                                );
                                                let SelectedSupplier = {
                                                  PotentialSupplierName: value,
                                                  PotentialSupplierId: 0,
                                                };
                                                let updated_data = {
                                                  ...invoicePageData,
                                                };
                                                updated_data.PotentialSupplierSelected =
                                                  SelectedSupplier;

                                                setInvoicePageData(
                                                  updated_data
                                                );
                                              }}
                                            />
                                          )}
                                        </div>
                                      </Dropdown.Toggle>

                                      <Dropdown.Menu
                                        className="w-100 transparent-scroller mx-1"
                                        style={{
                                          maxHeight: "200px",
                                          overflowY: "auto",
                                        }}
                                      >
                                        {invoicePageData?.PotentialSupplierDetail_List?.filter(
                                          (pt) => pt?.IsMSP === true
                                        )?.map((item, index) => {
                                          return (
                                            <div
                                              className="d-flex align-items-center justify-content-between px-2 py-1 pointer"
                                              onClick={(e) => {
                                                setChangesInInvoice(
                                                  changesInInvoice + 1
                                                );
                                                let updated_data = {
                                                  ...invoicePageData,
                                                };
                                                updated_data.PotentialSupplierSelected =
                                                  item;
                                                setInvoicePageData(
                                                  updated_data
                                                );
                                                setPotentialSupplierSearch("");
                                                e.target
                                                  .closest(
                                                    ".add-item-category-dropdown"
                                                  )
                                                  .click();
                                              }}
                                            >
                                              <div className="d-flex align-items-center f-grey">
                                                <img
                                                  src={
                                                    item?.PotentialSupplierIcon
                                                  }
                                                  onError={({
                                                    currentTarget,
                                                  }) => {
                                                    currentTarget.onerror =
                                                      null; // prevents looping
                                                    currentTarget.src =
                                                      "/images/user-logo.svg";
                                                  }}
                                                  width={18}
                                                  height={18}
                                                  className="mr-2 rounded-full"
                                                  alt=""
                                                />
                                                {item?.PotentialSupplierName}
                                              </div>
                                              <div className="f-12 f-darkgrey">
                                                You
                                              </div>
                                            </div>
                                          );
                                        })}

                                        <div className={` f-grey f-12 pl-2`}>
                                          Last used
                                        </div>
                                        {invoicePageData?.PotentialSupplierDetail_List?.filter(
                                          (pk) =>
                                            pk?.PotentialSupplierName?.length >
                                            0
                                        )
                                          ?.filter(
                                            (pt) =>
                                              pt?.PotentialSupplierName?.toLowerCase()?.includes(
                                                potentialSupplierSearch?.toLowerCase()
                                              ) && pt?.IsMSP === false
                                          )
                                          ?.map((item, itemIndex) => {
                                            return (
                                              <div
                                                className="d-flex align-items-center justify-content-between my-2 pointer pl-2"
                                                onClick={(e) => {
                                                  let updated_data = {
                                                    ...invoicePageData,
                                                  };
                                                  updated_data.PotentialSupplierSelected =
                                                    item;
                                                  setInvoicePageData(
                                                    updated_data
                                                  );
                                                  setPotentialSupplierSearch(
                                                    ""
                                                  );
                                                  e.target
                                                    .closest(
                                                      ".add-item-category-dropdown"
                                                    )
                                                    .click();
                                                }}
                                              >
                                                <div
                                                  className={`${
                                                    item?.PotentialSupplierId ===
                                                    invoicePageData
                                                      ?.PotentialSupplierSelected
                                                      ?.PotentialSupplierId
                                                      ? "f-blue "
                                                      : "f-black"
                                                  }`}
                                                >
                                                  <img
                                                    alt=""
                                                    className={`${
                                                      item.selected
                                                        ? "blue-icon"
                                                        : ""
                                                    } mr-2 d-none`}
                                                    src={item.image}
                                                  />
                                                  {item?.PotentialSupplierName}
                                                </div>
                                                <div>
                                                  {item?.PotentialSupplierId ===
                                                    invoicePageData
                                                      ?.PotentialSupplierSelected
                                                      ?.PotentialSupplierId && (
                                                    <img
                                                      alt=""
                                                      className=" mr-3"
                                                      src="/images/msp/blue-tick.svg"
                                                    />
                                                  )}
                                                </div>
                                              </div>
                                            );
                                          })}

                                        {potentialSupplierSearch !== "" && (
                                          <div
                                            className={
                                              "f-500  f-blue pointer pl-2"
                                            }
                                            onClick={(e) => {
                                              let SelectedSupplier = {
                                                PotentialSupplierName:
                                                  potentialSupplierSearch,
                                                PotentialSupplierId: 0,
                                              };
                                              let updated_data = {
                                                ...invoicePageData,
                                              };
                                              updated_data.PotentialSupplierSelected =
                                                SelectedSupplier;

                                              setInvoicePageData(updated_data);
                                              setPotentialSupplierSearch("");

                                              e.target
                                                .closest(
                                                  ".add-item-category-dropdown"
                                                )
                                                .click();
                                            }}
                                          >
                                            Add "{potentialSupplierSearch}"
                                            supplier
                                          </div>
                                        )}
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  )}
                                </div>
                              </div>

                              <div className="w-100 py-2 d-flex align-items-center f-black">
                                <div className="w-50 f-500">Contact Email</div>
                                <div className="w-50">
                                  <div
                                    className={` ${
                                      errors?.invoicePageEmailError
                                        ? "input-error"
                                        : ""
                                    }   d-flex align-items-center radius-4 highlight-input  p-2 flex-grow-1 mr-1`}
                                  >
                                    <input
                                      type="text"
                                      className={` ${""} bg-transparent flex-grow-1 border-none f-500`}
                                      placeholder={
                                        invoicePageData?.IntegrationId > 0
                                          ? "N/A"
                                          : "Optional"
                                      }
                                      value={invoicePageData?.ContactEmail}
                                      onChange={(e) => {
                                        setChangesInInvoice(
                                          changesInInvoice + 1
                                        );
                                        let value = e.target.value;
                                        setInvoicePageData((prev) => ({
                                          ...prev,
                                          ContactEmail: value,
                                        }));
                                        setErrors((prev) => ({
                                          ...prev,
                                          invoicePageEmailError: false,
                                        }));
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>

                              <div className="w-100  d-flex align-items-center f-black">
                                <div className="w-50 f-500">Invoice Date</div>
                                <div className="w-50 msp-date-picker f-500">
                                  <HighgroundDatePicker
                                    disableFutureDates
                                    isError={errors?.invoiceDateError}
                                    selected={
                                      // moment(invoicePageData?.InvoiceDate).format("MM/DD/YYYY")

                                      // invoicePageData?.InvoiceDate === "0001-01-01T00:00:00"
                                      //   ? ""
                                      //   : invoicePageData?.InvoiceDate
                                      handleDateForInvoice(
                                        invoicePageData?.InvoiceDate
                                      )

                                      // invoicePageData?.InvoiceDate === "0001-01-01T00:00:00"
                                      //   ? ""
                                      //   : moment(invoicePageData?.InvoiceDate).format("MM/DD/YYYY")
                                    }
                                    icon={
                                      "/images/attack-surface/date-icon.svg"
                                    }
                                    onChange={(val) => {
                                      setChangesInInvoice(changesInInvoice + 1);
                                      setInvoicePageData((prev) => ({
                                        ...prev,
                                        InvoiceDate: val,
                                      }));
                                      setErrors((prev) => ({
                                        ...prev,
                                        invoiceDateError: false,
                                      }));

                                      if (
                                        exchangeRateData?.FromCurrency !==
                                        defaultCurrencyData?.CurrencyName
                                      ) {
                                        updateExchangeRateData({
                                          _date: val,
                                        });
                                      }
                                    }}
                                    id={"dvdsgvzdgdj"}
                                  />
                                  {errors?.invoiceDateError &&
                                    errors?.invoiceDateError?.length > 5 && (
                                      <div className="f-danger">
                                        {errors?.invoiceDateError}
                                      </div>
                                    )}
                                </div>
                              </div>
                              <div className="w-100  d-flex align-items-center f-black">
                                <div className="w-50 f-500">Currency</div>
                                <div className="w-50   f-500">
                                  <div className="w-fit-content bg-grey radius-4 p-2 mt-3">
                                    <CurrencyDropdown
                                      showFullCurrencyName
                                      menuClassName={
                                        "add-amount-currency-dropdown"
                                      }
                                      width={"200px"}
                                      inputWidth={"144px"}
                                      currencyList={currencyList}
                                      onChange={(data) => {
                                        setUserSelectedCurrency(data);
                                        updateExchangeRateData({
                                          _currency: data?.CurrencyName,
                                          _date:
                                            invoicePageData?.InvoiceDate ===
                                            "0001-01-01T00:00:00"
                                              ? moment().format("DD/MM/YYYY")
                                              : handleDateForInvoice(
                                                  invoicePageData?.InvoiceDate
                                                ),
                                        });
                                      }}
                                      selected={userSelectedCurrency}
                                    />
                                  </div>
                                </div>
                              </div>
                              <hr />
                              <div className="invoice-summary-table mb-2">
                                <div className="f-12 f-500 f-darkgrey">
                                  Item
                                </div>
                                <div className="f-12 f-500 f-darkgrey">
                                  Unit Price
                                </div>
                                <div className="f-12 f-500 f-darkgrey">
                                  Qty.
                                </div>
                                <div
                                  className="f-12 f-500 f-darkgrey text-right"
                                  style={{
                                    paddingRight: "2rem",
                                  }}
                                >
                                  Line Total
                                </div>
                              </div>

                              {invoicePageData?.InvoiceItem_List?.map(
                                (invoiceItem, invoiceItemIndex) => {
                                  return (
                                    <div
                                      className={`p-2 mb-2 invoice-summary-table radius-4 bg-hover-grey pointer align-items-center${
                                        invoiceItem?.objId_Invoice === 0
                                          ? ""
                                          : "pointer"
                                      }`}
                                      style={{
                                        background:
                                          invoiceItem?.IsLinkedToCategory ===
                                          true
                                            ? "#EBF6FC"
                                            : "",
                                      }}
                                      onClick={() => {
                                        // if (invoiceItem?.objId_Invoice === 0) return;
                                        setInvoiceItemIndex(invoiceItemIndex);
                                        setSelectedInvoiceItemData(invoiceItem);
                                        setShowAddItemSpendModal(true);
                                      }}
                                    >
                                      <div
                                        className="w-90"
                                        title={invoiceItem?.Title}
                                      >
                                        <div className="f-500">
                                          {TrimString(invoiceItem?.Title, 500)}
                                        </div>
                                        <div className="f-12 f-darkgrey">
                                          {invoiceItem?.Category_Selected
                                            ?.CategoryName && (
                                            <>
                                              {TrimString(
                                                invoiceItem?.Category_Selected
                                                  ?.CategoryName,
                                                200
                                              )}{" "}
                                              {invoiceItem?.SubCategory_Selected
                                                ?.CategoryName && "> "}
                                              {TrimString(
                                                invoiceItem
                                                  ?.SubCategory_Selected
                                                  ?.CategoryName,
                                                250
                                              )}
                                            </>
                                          )}
                                        </div>
                                      </div>
                                      <div className="f-500">
                                        {}
                                        {/* {userSelectedCurrency?.CurrencySymbol}{" "} */}
                                        {TrimString(
                                          convertToCurrencyFormat(
                                            invoiceItem?.ItemValue,
                                            userSelectedCurrency?.CurrencySymbol
                                          ),
                                          100
                                        )}
                                      </div>
                                      <div className="f-500">
                                        x{" "}
                                        {TrimString(invoiceItem?.Quantity, 10)}
                                      </div>
                                      <div className="f-500 f-black d-flex">
                                        <div className="w-100 text-right">
                                          <div className="f-500">
                                            {TrimString(
                                              convertToCurrencyFormat(
                                                invoiceItem?.ItemValue *
                                                  invoiceItem?.Quantity +
                                                  (0 *
                                                    invoiceItem?.ItemValue *
                                                    invoiceItem?.Quantity *
                                                    invoiceItem
                                                      ?.TaxRates_Selected
                                                      ?.TaxRate) /
                                                    100,
                                                userSelectedCurrency?.CurrencySymbol
                                              ),
                                              15
                                            )}
                                          </div>
                                          <div
                                            className={`f-12 f-darkgrey ${
                                              userSelectedCurrency?.CurrencySymbol ===
                                              defaultCurrencyData?.CurrencySymbol
                                                ? "d-none"
                                                : ""
                                            }`}
                                          >
                                            <AttackSurfaceTooltip
                                              bottomLeft={true}
                                              topLeft={false}
                                              content={
                                                <span>
                                                  <img
                                                    alt=""
                                                    width={13}
                                                    height={13}
                                                    src="/images/msp/white-exchange-rate-icon.svg"
                                                    className="mr-1"
                                                  />{" "}
                                                  The exchange rate on{" "}
                                                  {moment(
                                                    exchangeRateData?.Date ?? ""
                                                  )?.format("DD MMM YYYY")}{" "}
                                                  was 1{" "}
                                                  {
                                                    exchangeRateData?.FromCurrency
                                                  }{" "}
                                                  = {exchangeRateData?.Rate}{" "}
                                                  {
                                                    defaultCurrencyData?.CurrencyName
                                                  }
                                                </span>
                                              }
                                            >
                                              <img
                                                alt=""
                                                width={13}
                                                height={13}
                                                src="/images/msp/exchange-rate-icon.svg"
                                                className="mr-1"
                                              />
                                            </AttackSurfaceTooltip>
                                            {
                                              defaultCurrencyData?.CurrencySymbol
                                            }
                                            {TrimString(
                                              Number(
                                                exchangeRateData?.Rate *
                                                  (invoiceItem?.ItemValue *
                                                    invoiceItem?.Quantity) +
                                                  (0 *
                                                    invoiceItem?.ItemValue *
                                                    invoiceItem?.Quantity *
                                                    invoiceItem
                                                      ?.TaxRates_Selected
                                                      ?.TaxRate) /
                                                    100
                                              ).toFixed(2),
                                              15
                                            )}
                                          </div>
                                        </div>
                                        <div className="d-flex align-items-center justify-content-center mr-1">
                                          <img
                                            alt=""
                                            className="mx-1 pointer"
                                            style={{
                                              visibility:
                                                invoiceEditMode === true
                                                  ? "visible"
                                                  : "hidden",
                                            }}
                                            src="/images/attack-surface/cross-icon.svg"
                                            onClick={(e) => {
                                              e.stopPropagation();
                                              let updated_data = {
                                                ...invoicePageData,
                                              };
                                              updated_data.InvoiceItem_List.splice(
                                                invoiceItemIndex,
                                                1
                                              );
                                              setInvoicePageData(updated_data);
                                              // if (
                                              //   invoiceItem?.objId_Invoice > 0
                                              // ) {
                                              //   deleteInvoiceItem(
                                              //     invoiceItem?.Id
                                              //   );
                                              // }
                                            }}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  );
                                }
                              )}
                              {invoiceEditMode === true && (
                                <div
                                  className="p-2 d-flex align-items-center w-fit-content f-500 f-grey pointer"
                                  onClick={() => {
                                    if (
                                      !invoicePageData?.InvoiceDate ||
                                      invoicePageData.InvoiceDate ===
                                        "0001-01-01T00:00:00"
                                    ) {
                                      setErrors((prev) => ({
                                        ...prev,
                                        invoiceDateError:
                                          "Invoice date must be selected before adding invoice line item",
                                      }));
                                      return;
                                    }
                                    setChangesInInvoice(changesInInvoice + 1);
                                    setInvoiceItemIndex(-1);
                                    setSelectedInvoiceItemData({});
                                    setShowAddItemSpendModal(true);
                                    setErrors((prev) => ({
                                      ...prev,
                                      invoiceItemsError: false,
                                    }));
                                  }}
                                >
                                  <img
                                    alt=""
                                    className="mr-2"
                                    src="/images/actioncenter/plusgrey.svg"
                                  />
                                  Add
                                </div>
                              )}

                              {errors?.invoiceItemsError && (
                                <div className="f-danger">
                                  {errors.invoiceItemsError}
                                </div>
                              )}
                              <div className="f-12 f-500 f-darkgrey mt-2 mb-1">
                                Total
                              </div>
                              <div className="w-100 py-1 d-flex align-items-center f-black justify-content-between">
                                <div className="w-50 f-500">Discount</div>
                                <div className=" text-right w-20">
                                  {invoicePageData?.IntegrationId > 0 ? (
                                    <span className="mr-3">
                                      {" "}
                                      {convertToCurrencyFormat(
                                        invoicePageData?.Discount ?? 0,
                                        userSelectedCurrency?.CurrencySymbol
                                      )}
                                    </span>
                                  ) : (
                                    <div className="d-flex align-items-center radius-4 highlight-input-with-grey-border  p-2 mr-1">
                                      <div className="mr-1">
                                        {" "}
                                        {
                                          userSelectedCurrency?.CurrencySymbol
                                        }{" "}
                                      </div>

                                      <input
                                        type="number"
                                        style={{
                                          width: "27px",
                                        }}
                                        className="bg-transparent  border-none w-100"
                                        placeholder="0.00"
                                        value={invoicePageData?.Discount ?? 0}
                                        onChange={(e) => {
                                          setChangesInInvoice(
                                            changesInInvoice + 1
                                          );
                                          let value = e.target.value;
                                          setInvoicePageData((prev) => ({
                                            ...prev,
                                            Discount: validateNumber(value),
                                          }));
                                        }}
                                        onFocus={(e) => {
                                          if (!invoicePageData?.Discount) {
                                            setInvoicePageData((prev) => ({
                                              ...prev,
                                              Discount: "",
                                            }));
                                          }
                                        }}
                                        onBlur={() => {
                                          if (!invoicePageData?.Discount) {
                                            setInvoicePageData((prev) => ({
                                              ...prev,
                                              Discount: 0,
                                            }));
                                          }
                                        }}
                                      />
                                    </div>
                                  )}
                                </div>
                              </div>

                              <div className="w-100  d-flex align-items-center justify-content-between f-black h-32">
                                <div className="w-30 f-500">Subtotal</div>
                                <div className="w-70">
                                  <div className="d-flex align-items-center justify-content-end radius-4 highlight-input  p-2 flex-grow-1 mr-1">
                                    {
                                      // invoicePageData?.Tax ?? <div className="f-darkgrey">£ 0.00</div>

                                      getSubTotalAmountForInvoice() === 0 ? (
                                        <div className="f-black">
                                          {userSelectedCurrency?.CurrencySymbol}{" "}
                                          0.00
                                        </div>
                                      ) : (
                                        <div className="f-black">
                                          {convertToCurrencyFormat(
                                            getSubTotalAmountForInvoice() ?? 0,
                                            userSelectedCurrency?.CurrencySymbol
                                          )}
                                        </div>
                                      )
                                    }
                                  </div>
                                </div>
                              </div>

                              <div className="w-100  d-flex align-items-center justify-content-between f-black h-32">
                                <div className="w-30 f-500">Tax</div>
                                <div className="w-70">
                                  <div className="d-flex align-items-center justify-content-end radius-4 highlight-input  p-2 flex-grow-1 mr-1">
                                    {
                                      // invoicePageData?.Tax ?? <div className="f-darkgrey">£ 0.00</div>

                                      getTaxForInvoice() === 0 ? (
                                        <div
                                          className={` ${
                                            invoicePageData?.ID !== 0
                                              ? "textBlackBold"
                                              : "f-black"
                                          } `}
                                        >
                                          {userSelectedCurrency?.CurrencySymbol}{" "}
                                          0.00
                                        </div>
                                      ) : (
                                        <div className="f-black">
                                          {convertToCurrencyFormat(
                                            getTaxForInvoice() ?? 0,
                                            userSelectedCurrency?.CurrencySymbol
                                          )}
                                        </div>
                                      )
                                    }
                                  </div>
                                </div>
                              </div>

                              <div className="w-100  d-flex align-items-center justify-content-between f-black h-32">
                                <div className="w-30 f-600">Total</div>
                                <div className="w-50">
                                  <div
                                    className="d-flex align-items-center justify-content-end radius-4 highlight-input  p-2 flex-grow-1 mr-1 f-600"
                                    style={{
                                      pointerEvents: "none",
                                    }}
                                  >
                                    {getTotalAmountForInvoice() === 0 ||
                                    userSelectedCurrency?.CurrencySymbol ===
                                      defaultCurrencyData?.CurrencySymbol ? (
                                      <>
                                        {getTotalAmountForInvoice() === 0 ? (
                                          <div className="f-600 f-black">
                                            {
                                              userSelectedCurrency?.CurrencySymbol
                                            }{" "}
                                            0.00
                                          </div>
                                        ) : (
                                          <div className="f-600 f-black">
                                            {convertToCurrencyFormat(
                                              getTotalAmountForInvoice() ?? 0,
                                              userSelectedCurrency?.CurrencySymbol
                                            )}
                                          </div>
                                        )}
                                      </>
                                    ) : (
                                      <div className="d-flex align-items-center justify-content-between w-100">
                                        <div>
                                          {convertToCurrencyFormat(
                                            getTotalAmountForInvoice() ?? 0,
                                            userSelectedCurrency?.CurrencySymbol
                                          )}
                                        </div>
                                        <img
                                          width={15}
                                          height={15}
                                          src="/images/msp/exchange-rate-icon.svg"
                                          alt=""
                                        />
                                        <div className="d-flex">
                                          {convertToCurrencyFormat(
                                            exchangeRateData?.Rate *
                                              Number(
                                                getTotalAmountForInvoice() ?? 0
                                              ),
                                            defaultCurrencyData?.CurrencySymbol
                                          )}
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>

                              <div
                                className={`add-item-spend-modal-wrapper    ${
                                  showAddItemSpendModal ? "show" : ""
                                }  `}
                              >
                                <AddItemInSpendModal
                                  isDraggableParent
                                  showSelectedSubcategory={
                                    pages.some(
                                      (item) => item.type === "subcategory"
                                    )
                                      ? true
                                      : false
                                  }
                                  updateExchangeRateData={
                                    updateExchangeRateData
                                  }
                                  invoicePageData={invoicePageData}
                                  exchangeRateData={exchangeRateData}
                                  defaultCurrencyData={defaultCurrencyData}
                                  setUserSelectedCurrency={
                                    setUserSelectedCurrency
                                  }
                                  userSelectedCurrency={userSelectedCurrency}
                                  currencyList={currencyList}
                                  getTaxRateList={getTaxRateList}
                                  InvoiceItemIndex={InvoiceItemIndex}
                                  selectedInvoiceItemData={
                                    selectedInvoiceItemData
                                  }
                                  show={showAddItemSpendModal}
                                  categoryList={
                                    invoicePageData?.SpendCategoryVM_List
                                  }
                                  currentCategory={categoryData}
                                  currentSubcategory={subCategoryPageData}
                                  categoryData={categoryData}
                                  categoryPageData={categoryPageData}
                                  hideModal={() =>
                                    setShowAddItemSpendModal(false)
                                  }
                                  class="add-item-in-spend-modal" // only to remeember it
                                  onCreate={(_index, createdInvoiceItem) => {
                                    let updated_data = { ...invoicePageData };

                                    if (_index > -1) {
                                      updated_data.InvoiceItem_List[_index] =
                                        createdInvoiceItem;
                                    } else {
                                      updated_data.InvoiceItem_List = [
                                        ...updated_data.InvoiceItem_List,
                                        createdInvoiceItem,
                                      ];
                                    }

                                    setInvoicePageData(updated_data);
                                  }}
                                  onUpdate={(_index, updated_Item) => {
                                    let updated_data = { ...invoicePageData };

                                    updated_data.InvoiceItem_List[_index] =
                                      updated_Item;

                                    setInvoicePageData(updated_data);
                                  }}
                                  onDelete={(_index, _ItemId) => {
                                    let updated_data = { ...invoicePageData };

                                    if (_index > -1) {
                                      updated_data.InvoiceItem_List.splice(
                                        _index,
                                        1
                                      );
                                    } else {
                                    }
                                    setInvoicePageData(updated_data);
                                    // refreshData()
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="addByAmount">
                          <div
                            className="p-3"
                            style={{
                              pointerEvents: invoiceEditMode ? "all" : "none",
                            }}
                          >
                            <div
                              className={`  ${
                                errors?.invoiceRefError ? "input-error" : ""
                              }   d-flex align-items-center radius-4 grey-border p-2 flex-grow-1 mr-1 highlight-input-with-grey-border`}
                            >
                              <div className="f-darkgrey">Title:</div>
                              <input
                                type="text"
                                className="bg-transparent flex-grow-1 border-none pl-2"
                                placeholder=" Type"
                                value={invoicePageData?.InvoiceRef ?? ""}
                                onChange={(e) => {
                                  let value = e.target.value;
                                  setChangesInInvoice(changesInInvoice + 1);
                                  setInvoicePageData((prev) => ({
                                    ...prev,
                                    InvoiceRef: removeWhiteSpace(value),
                                  }));
                                  setErrors((prev) => ({
                                    ...prev,
                                    invoiceRefError: false,
                                  }));
                                }}
                              />
                            </div>

                            {/* Description */}
                            <div
                              className={`  ${
                                errors?.descriptionError ? "input-error" : ""
                              }   d-flex align-items-center radius-4 grey-border flex-grow-1 mr-1 mt-2 highlight-input-with-grey-border`}
                            >
                              <textarea
                                rows={4}
                                className="bg-transparent flex-grow-1 border-none  hide-scrollbar p-2"
                                placeholder="Description"
                                value={invoicePageData?.Description ?? ""}
                                onChange={(e) => {
                                  let value = e.target.value;
                                  setChangesInInvoice(changesInInvoice + 1);
                                  setInvoicePageData((prev) => ({
                                    ...prev,
                                    Description: removeWhiteSpace(value),
                                  }));
                                  setErrors((prev) => ({
                                    ...prev,
                                    descriptionError: false,
                                  }));
                                }}
                              />
                            </div>

                            <hr />
                            <div className="f-12 f-500 f-darkgrey mb-2">
                              Details
                            </div>
                            <div className="w-100  d-flex align-items-center f-black">
                              <div className="w-50">Supplier</div>
                              <div className="w-50">
                                <Dropdown
                                  show={showAddByAmountSupplierDropdown}
                                  onToggle={(isOpen) =>
                                    setShowAddByAmountSupplierDropdown(isOpen)
                                  }
                                  className="add-item-category-dropdown w-100"
                                >
                                  <Dropdown.Toggle className="w-100">
                                    <div className="highlight-input p-2 radius-4 d-flex align-items-center">
                                      {invoicePageData
                                        ?.PotentialSupplierSelected?.IsMSP ===
                                      true ? (
                                        <div
                                          className="d-flex align-items-center justify-content-between  w-100 pointer"
                                          style={{
                                            height: "15px",
                                          }}
                                        >
                                          <div className="d-flex align-items-center f-black">
                                            <img
                                              src={
                                                invoicePageData
                                                  ?.PotentialSupplierSelected
                                                  ?.PotentialSupplierIcon
                                              }
                                              onError={({ currentTarget }) => {
                                                currentTarget.onerror = null; // prevents looping
                                                currentTarget.src =
                                                  "/images/user-logo.svg";
                                              }}
                                              width={14}
                                              height={14}
                                              className="mr-2 rounded-full"
                                              alt=""
                                            />
                                            {
                                              invoicePageData
                                                ?.PotentialSupplierSelected
                                                ?.PotentialSupplierName
                                            }
                                          </div>
                                          <div className="f-12 f-darkgrey">
                                            You
                                          </div>
                                        </div>
                                      ) : (
                                        <input
                                          className="border-none bg-transparent flex-grow-1"
                                          type="text"
                                          placeholder="Optional"
                                          value={
                                            invoicePageData
                                              ?.PotentialSupplierSelected
                                              ?.PotentialSupplierName
                                          }
                                          onChange={(e) => {
                                            setChangesInInvoice(
                                              changesInInvoice + 1
                                            );
                                            setShowAddByAmountSupplierDropdown(
                                              true
                                            );
                                            let value = e.target.value;
                                            setPotentialSupplierSearch(
                                              e.target.value
                                            );
                                            let SelectedSupplier = {
                                              PotentialSupplierName: value,
                                              PotentialSupplierId: 0,
                                            };
                                            let updated_data = {
                                              ...invoicePageData,
                                            };
                                            updated_data.PotentialSupplierSelected =
                                              SelectedSupplier;

                                            setInvoicePageData(updated_data);
                                          }}
                                        />
                                      )}
                                    </div>
                                  </Dropdown.Toggle>

                                  <Dropdown.Menu
                                    className="w-100 transparent-scroller mx-1"
                                    style={{
                                      maxHeight: "200px",
                                      overflowY: "auto",
                                    }}
                                  >
                                    {invoicePageData?.PotentialSupplierDetail_List?.filter(
                                      (pt) => pt?.IsMSP === true
                                    )?.map((item, index) => {
                                      return (
                                        <div
                                          className="d-flex align-items-center justify-content-between px-2 py-1 pointer"
                                          onClick={(e) => {
                                            setChangesInInvoice(
                                              changesInInvoice + 1
                                            );
                                            let updated_data = {
                                              ...invoicePageData,
                                            };
                                            updated_data.PotentialSupplierSelected =
                                              item;
                                            setInvoicePageData(updated_data);
                                            setPotentialSupplierSearch("");
                                            e.target
                                              .closest(
                                                ".add-item-category-dropdown"
                                              )
                                              .click();
                                          }}
                                        >
                                          <div className="d-flex align-items-center f-grey">
                                            <img
                                              src={item?.PotentialSupplierIcon}
                                              onError={({ currentTarget }) => {
                                                currentTarget.onerror = null; // prevents looping
                                                currentTarget.src =
                                                  "/images/user-logo.svg";
                                              }}
                                              width={18}
                                              height={18}
                                              className="mr-2 rounded-full"
                                              alt=""
                                            />
                                            {item?.PotentialSupplierName}
                                          </div>
                                          <div className="f-12 f-darkgrey">
                                            You
                                          </div>
                                        </div>
                                      );
                                    })}
                                    <div className={`f-grey f-12 pl-2`}>
                                      Last used
                                    </div>
                                    {invoicePageData?.PotentialSupplierDetail_List?.filter(
                                      (pk) =>
                                        pk?.PotentialSupplierName?.length > 0
                                    )
                                      ?.filter(
                                        (pt) =>
                                          pt?.PotentialSupplierName?.toLowerCase()?.includes(
                                            potentialSupplierSearch?.toLowerCase()
                                          ) && pt?.IsMSP === false
                                      )
                                      ?.map((item, itemIndex) => {
                                        return (
                                          <div
                                            className="d-flex align-items-center justify-content-between pointer pl-2 my-2"
                                            onClick={(e) => {
                                              setChangesInInvoice(
                                                changesInInvoice + 1
                                              );
                                              let updated_data = {
                                                ...invoicePageData,
                                              };
                                              updated_data.PotentialSupplierSelected =
                                                item;
                                              setInvoicePageData(updated_data);
                                              setPotentialSupplierSearch("");
                                              e.target
                                                .closest(
                                                  ".add-item-category-dropdown"
                                                )
                                                .click();
                                            }}
                                          >
                                            <div
                                              className={`${
                                                item?.PotentialSupplierId ===
                                                invoicePageData
                                                  ?.PotentialSupplierSelected
                                                  ?.PotentialSupplierId
                                                  ? "f-blue  "
                                                  : " f-black"
                                              }`}
                                            >
                                              {item?.PotentialSupplierName}
                                            </div>
                                            <div>
                                              {item?.PotentialSupplierId ===
                                                invoicePageData
                                                  ?.PotentialSupplierSelected
                                                  ?.PotentialSupplierId && (
                                                <img
                                                  alt=""
                                                  className=" mr-2"
                                                  src="/images/msp/blue-tick.svg"
                                                />
                                              )}
                                            </div>
                                          </div>
                                        );
                                      })}
                                    {potentialSupplierSearch !== "" && (
                                      <div
                                        className={"f-500  f-blue pointer pl-2"}
                                        onClick={(e) => {
                                          let SelectedSupplier = {
                                            PotentialSupplierName:
                                              potentialSupplierSearch,
                                            PotentialSupplierId: 0,
                                          };
                                          let updated_data = {
                                            ...invoicePageData,
                                          };
                                          updated_data.PotentialSupplierSelected =
                                            SelectedSupplier;

                                          setInvoicePageData(updated_data);
                                          setPotentialSupplierSearch("");

                                          e.target
                                            .closest(
                                              ".add-item-category-dropdown"
                                            )
                                            .click();
                                        }}
                                      >
                                        Add "{potentialSupplierSearch}" supplier
                                      </div>
                                    )}
                                  </Dropdown.Menu>
                                </Dropdown>
                              </div>
                            </div>

                            <div className="w-100 py-2 d-flex align-items-center f-black">
                              <div className="w-50">Contact Email</div>
                              <div className="w-50">
                                <div
                                  className={` ${
                                    errors?.invoicePageEmailError
                                      ? "input-error"
                                      : ""
                                  }   d-flex align-items-center radius-4 highlight-input  p-2 flex-grow-1 mr-1`}
                                >
                                  <input
                                    type="text"
                                    className="bg-transparent flex-grow-1 border-none"
                                    placeholder="Optional"
                                    value={invoicePageData?.ContactEmail ?? ""}
                                    onChange={(e) => {
                                      let value = e.target.value;
                                      setChangesInInvoice(changesInInvoice + 1);
                                      setInvoicePageData((prev) => ({
                                        ...prev,
                                        ContactEmail: value,
                                      }));
                                      setErrors((prev) => ({
                                        ...prev,
                                        invoicePageEmailError: false,
                                      }));
                                    }}
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="w-100  d-flex align-items-center f-black">
                              <div className="w-50">Spend Date</div>
                              <div className="w-50 msp-date-picker">
                                <HighgroundDatePicker
                                  disableFutureDates
                                  isError={errors?.invoiceDateError}
                                  selected={handleDateForInvoice(
                                    invoicePageData?.InvoiceDate
                                  )}
                                  icon={"/images/attack-surface/date-icon.svg"}
                                  onChange={(val) => {
                                    setChangesInInvoice(changesInInvoice + 1);
                                    setInvoicePageData((prev) => ({
                                      ...prev,
                                      InvoiceDate: val,
                                    }));
                                    setErrors((prev) => ({
                                      ...prev,
                                      invoiceDateError: false,
                                    }));
                                    updateExchangeRateData({
                                      _date: val,
                                    });
                                  }}
                                  id={"dvdsgwvzdgdj"}
                                />
                              </div>
                            </div>
                            <div className="w-100  d-flex align-items-center f-black">
                              <div className="w-50 f-500">Currency</div>
                              <div className="w-50   f-500">
                                <div className="w-fit-content bg-grey radius-4 p-2 mt-3">
                                  <CurrencyDropdown
                                    showFullCurrencyName
                                    menuClassName={
                                      "add-amount-currency-dropdown"
                                    }
                                    width={"200px"}
                                    inputWidth={"144px"}
                                    currencyList={currencyList}
                                    onChange={(data) => {
                                      setUserSelectedCurrency(data);
                                      updateExchangeRateData({
                                        _currency: data?.CurrencyName,
                                        _date:
                                          invoicePageData?.InvoiceDate ===
                                          "0001-01-01T00:00:00"
                                            ? moment().format("DD/MM/YYYY")
                                            : handleDateForInvoice(
                                                invoicePageData?.InvoiceDate
                                              ),
                                      });
                                    }}
                                    selected={userSelectedCurrency}
                                  />
                                </div>
                              </div>
                            </div>
                            <hr />

                            <div className="f-12 f-500 f-darkgrey mt-2">
                              Total
                            </div>
                            {/* Discount */}
                            <div className="w-100 py-1 d-flex align-items-center f-black justify-content-between h-32 mb-1">
                              <div className="w-50">Discount</div>
                              <div className="w-50">
                                <div className="d-flex align-items-center radius-4 highlight-input  p-2 w-60 mr-1">
                                  <div className="mr-1">
                                    {" "}
                                    {userSelectedCurrency?.CurrencySymbol}{" "}
                                  </div>

                                  <input
                                    type="number"
                                    className="bg-transparent flex-grow-1 border-none "
                                    placeholder="Type"
                                    value={invoicePageData?.Discount ?? ""}
                                    onChange={(e) => {
                                      let value = e.target.value;
                                      setInvoicePageData((prev) => ({
                                        ...prev,
                                        Discount: validateNumber(value),
                                      }));
                                    }}
                                    onFocus={() => {
                                      if (!invoicePageData?.Discount) {
                                        setInvoicePageData((prev) => ({
                                          ...prev,
                                          Discount: "",
                                        }));
                                      }
                                    }}
                                    onBlur={() => {
                                      if (!invoicePageData?.Discount) {
                                        setInvoicePageData((prev) => ({
                                          ...prev,
                                          Discount: 0,
                                        }));
                                      }
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                            {/* Amount */}
                            <div className="w-100   d-flex align-items-center f-black mb-1">
                              <div className="w-50">Amount</div>
                              <div className=" d-flex align-items-center justify-content-between w-50">
                                <div
                                  className={`${
                                    errors?.totalAmountError
                                      ? "input-error"
                                      : ""
                                  } d-flex align-items-center radius-4 highlight-input  p-2  w-60`}
                                >
                                  <div className="mr-1">
                                    {" "}
                                    {userSelectedCurrency?.CurrencySymbol}{" "}
                                  </div>
                                  <input
                                    type="number"
                                    className="bg-transparent  border-none w-100"
                                    placeholder="Type"
                                    value={invoicePageData?.TotalAmount ?? ""}
                                    onChange={(e) => {
                                      let value = e.target.value;
                                      setChangesInInvoice(changesInInvoice + 1);
                                      if (value?.toString().length > 10) {
                                        return;
                                      }
                                      setInvoicePageData((prev) => ({
                                        ...prev,
                                        TotalAmount: validateNumber(value),
                                      }));
                                      setErrors((prev) => ({
                                        ...prev,
                                        totalAmountError: false,
                                      }));
                                    }}
                                    onFocus={() => {
                                      if (!invoicePageData?.TotalAmount) {
                                        setInvoicePageData((prev) => ({
                                          ...prev,
                                          TotalAmount: "",
                                        }));
                                      }
                                    }}
                                    onBlur={() => {
                                      if (!invoicePageData?.TotalAmount) {
                                        setInvoicePageData((prev) => ({
                                          ...prev,
                                          TotalAmount: 0,
                                        }));
                                      }
                                    }}
                                  />
                                </div>
                                <div className=" ">
                                  {/* <CurrencyDropdown
                                    showFullCurrencyName
                                    menuClassName={
                                      "add-amount-currency-dropdown"
                                    }
                                    width={"200px"}
                                    inputWidth={"144px"}
                                    currencyList={currencyList}
                                    onChange={(data) => {
                                      setUserSelectedCurrency(data);
                                      updateExchangeRateData({
                                        _currency: data?.CurrencyName,
                                        _date:
                                          invoicePageData?.InvoiceDate ===
                                          "0001-01-01T00:00:00"
                                            ? moment().format("DD/MM/YYYY")
                                            : handleDateForInvoice(
                                                invoicePageData?.InvoiceDate
                                              ),
                                      });
                                    }}
                                    selected={userSelectedCurrency}
                                  /> */}
                                </div>
                              </div>
                            </div>
                            {/* Tax Rate */}
                            <div className="w-100   d-flex align-items-center f-black mb-1">
                              <div className="w-50">Tax Rate</div>
                              <div className="w-50 d-flex align-items-center justify-content-between">
                                <div>
                                  <div
                                    className="flex-1 radius-4 p-2"
                                    style={{
                                      backgroundColor: "#f6f8fb",
                                    }}
                                  >
                                    <TaxRateDropdown
                                      deleteTaxRate={deleteTaxRate}
                                      updateTaxList={updateTaxList}
                                      onChange={(data) => {
                                        setInvoicePageData((prev) => ({
                                          ...prev,
                                          TaxRates_Selected: data,
                                        }));
                                      }}
                                      taxList={taxList}
                                      selected={
                                        invoicePageData?.TaxRates_Selected
                                      }
                                      onClickEditTaxRate={(data) => {
                                        setTaxRateData(data);
                                        setShowAddEditTaxRateModal(true);
                                      }}
                                      onClickAddTaxRate={() => {
                                        setTaxRateData({
                                          ObjectId: 0,
                                          TaxCode: "",
                                          TaxName: "",
                                          TaxRate: "",
                                        });
                                        setShowAddEditTaxRateModal(true);
                                      }}
                                    />
                                  </div>
                                </div>
                                <div className="d-flex align-items-center radius-4 f-grey  p-2  mr-1">
                                  {invoicePageData?.TaxRates_Selected
                                    ?.TaxRate ?? 0}
                                  % ={" "}
                                  {convertToCurrencyFormat(
                                    Number(
                                      (((invoicePageData.TotalAmount ?? 0) -
                                        invoicePageData?.Discount ?? 0) *
                                        (invoicePageData?.TaxRates_Selected
                                          ?.TaxRate ?? 0)) /
                                        100 ?? 0
                                    ),
                                    userSelectedCurrency?.CurrencySymbol
                                  )}
                                </div>
                              </div>
                            </div>

                            <div className="w-100   d-flex align-items-center f-black">
                              <div className="w-50 f-600">Total</div>
                              <div className="w-50 pl-2">
                                <div
                                  className="d-flex align-items-center radius-4 py-2 pr-2 flex-grow-1 mr-1 f-600"
                                  style={{
                                    pointerEvents: "none",
                                  }}
                                >
                                  {invoicePageData?.TotalAmount === 0 ||
                                  userSelectedCurrency?.CurrencySymbol ===
                                    defaultCurrencyData?.CurrencySymbol ? (
                                    <>
                                      {invoicePageData?.TotalAmount === 0 ? (
                                        <div className="f-600 f-black">
                                          {userSelectedCurrency?.CurrencySymbol}{" "}
                                          0.00
                                        </div>
                                      ) : (
                                        <div className="f-600 f-black">
                                          {convertToCurrencyFormat(
                                            getTotalAmountForInvoice(),

                                            defaultCurrencyData?.CurrencySymbol
                                          )}
                                        </div>
                                      )}
                                    </>
                                  ) : (
                                    <div className="d-flex align-items-center justify-content-between w-100">
                                      <div>
                                        {convertToCurrencyFormat(
                                          getTotalAmountForInvoice(),
                                          userSelectedCurrency?.CurrencySymbol
                                        )}
                                      </div>
                                      <img
                                        width={15}
                                        height={15}
                                        src="/images/msp/exchange-rate-icon.svg"
                                        alt=""
                                      />
                                      <div>
                                        {convertToCurrencyFormat(
                                          exchangeRateData?.Rate *
                                            getTotalAmountForInvoice(),
                                          defaultCurrencyData?.CurrencySymbol
                                        )}
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            style={{
                              padding: "0rem",
                            }}
                          />
                        </Tab.Pane>
                        <Tab.Pane eventKey="addBySubscription">
                          <div
                            className="p-3"
                            style={{
                              pointerEvents: invoiceEditMode ? "all" : "none",
                            }}
                          >
                            <div
                              className={`  ${
                                errors?.invoiceRefError ? "input-error" : ""
                              }   d-flex align-items-center radius-4 grey-border p-2 flex-grow-1 mr-1 highlight-input-with-grey-border`}
                            >
                              <div className="f-darkgrey">Title:</div>
                              <input
                                type="text"
                                className="bg-transparent flex-grow-1 border-none pl-2"
                                placeholder=" Type"
                                value={invoicePageData?.InvoiceRef ?? ""}
                                onChange={(e) => {
                                  let value = e.target.value;
                                  setChangesInInvoice(changesInInvoice + 1);
                                  setInvoicePageData((prev) => ({
                                    ...prev,
                                    InvoiceRef: removeWhiteSpace(value),
                                  }));
                                  setErrors((prev) => ({
                                    ...prev,
                                    invoiceRefError: false,
                                  }));
                                }}
                              />
                            </div>

                            {/* Description */}
                            <div
                              className={`  ${
                                errors?.descriptionError ? "input-error" : ""
                              }   d-flex align-items-center radius-4 grey-border flex-grow-1 mr-1 mt-2 highlight-input-with-grey-border`}
                            >
                              <textarea
                                rows={4}
                                className="bg-transparent flex-grow-1 border-none  hide-scrollbar p-2"
                                placeholder="Description"
                                value={invoicePageData?.Description ?? ""}
                                onChange={(e) => {
                                  let value = e.target.value;
                                  setChangesInInvoice(changesInInvoice + 1);
                                  setInvoicePageData((prev) => ({
                                    ...prev,
                                    Description: removeWhiteSpace(value),
                                  }));
                                  setErrors((prev) => ({
                                    ...prev,
                                    descriptionError: false,
                                  }));
                                }}
                              />
                            </div>

                            <div className="f-12 f-grey f-500 mt-2 mb-1">
                              Category
                            </div>
                            <div
                              className={`mb-2 w-100 border1 radius-4 ${
                                errors?.selectedCategoryError
                                  ? "input-error"
                                  : ""
                              }`}
                            >
                              <CustomHGDropdown
                                putMenuAtRight={true}
                                dropDownMenuClass={"w-100"}
                                dropDownToggleClass={"w-100"}
                                greyBackground={false}
                                prefix={"Category"}
                                fieldId={"objId_Category"}
                                fieldValue={"CategoryName"}
                                selected={invoicePageData?.Category_Selected}
                                placeholder="Select category"
                                showSearch={true}
                                maxContentWidth={"w-100"}
                                contentwidth={"w-100"}
                                placeDropDownAtTopRight={true}
                                onClick={(id, val, item) => {
                                  setChangesInInvoice(changesInInvoice + 1);

                                  setInvoicePageData((prev) => ({
                                    ...prev,
                                    Category_Selected: item,
                                  }));
                                  setErrors((prev) => ({
                                    ...prev,
                                    selectedCategoryError: false,
                                  }));
                                  getSubCategoryList(id);
                                }}
                                data={invoicePageData?.SpendCategoryVM_List}
                              />
                            </div>
                            <div
                              className={` mb-2 w-100 border1 radius-4 ${
                                errors?.selectedSubCategoryError
                                  ? "input-error"
                                  : ""
                              }`}
                            >
                              <CustomHGDropdown
                                dataLoading={subCategoryLoading}
                                putMenuAtRight={true}
                                dropDownMenuClass={"w-100"}
                                dropDownToggleClass={"w-100"}
                                greyBackground={false}
                                prefix={"Subcategory"}
                                fieldId={"objId_Category"}
                                fieldValue={"CategoryName"}
                                selected={invoicePageData?.SubCategory_Selected}
                                placeholder="Select subcategory"
                                showSearch={true}
                                maxContentWidth={"w-100"}
                                contentwidth={"w-100"}
                                placeDropDownAtTopRight={true}
                                onClick={(id, val, item) => {
                                  setChangesInInvoice(changesInInvoice + 1);

                                  setInvoicePageData((prev) => ({
                                    ...prev,
                                    SubCategory_Selected: item,
                                  }));
                                  setErrors((prev) => ({
                                    ...prev,
                                    selectedSubCategoryError: false,
                                  }));
                                }}
                                data={subCategoryList}
                              />
                            </div>

                            <hr />
                            <div
                              className="f-12 f-500 f-darkgrey mb-2"
                              onClick={() => {
                                setShowGenerateInvoiceModal(true);
                              }}
                            >
                              Details
                            </div>
                            <div className="w-100  d-flex align-items-center f-black">
                              <div className="w-50">Supplier</div>
                              <div className="w-50">
                                <Dropdown
                                  show={showAddByAmountSupplierDropdown}
                                  onToggle={(isOpen) =>
                                    setShowAddByAmountSupplierDropdown(isOpen)
                                  }
                                  className="add-item-category-dropdown w-100"
                                >
                                  <Dropdown.Toggle className="w-100">
                                    <div className="highlight-input p-2 radius-4 d-flex align-items-center">
                                      {invoicePageData
                                        ?.PotentialSupplierSelected?.IsMSP ===
                                      true ? (
                                        <div
                                          className="d-flex align-items-center justify-content-between  w-100 pointer"
                                          style={{
                                            height: "15px",
                                          }}
                                        >
                                          <div className="d-flex align-items-center f-black">
                                            <img
                                              src={
                                                invoicePageData
                                                  ?.PotentialSupplierSelected
                                                  ?.PotentialSupplierIcon
                                              }
                                              onError={({ currentTarget }) => {
                                                currentTarget.onerror = null; // prevents looping
                                                currentTarget.src =
                                                  "/images/user-logo.svg";
                                              }}
                                              width={14}
                                              height={14}
                                              className="mr-2 rounded-full"
                                              alt=""
                                            />
                                            {
                                              invoicePageData
                                                ?.PotentialSupplierSelected
                                                ?.PotentialSupplierName
                                            }
                                          </div>
                                          <div className="f-12 f-darkgrey">
                                            You
                                          </div>
                                        </div>
                                      ) : (
                                        <input
                                          className="border-none bg-transparent flex-grow-1"
                                          type="text"
                                          placeholder="Optional"
                                          value={
                                            invoicePageData
                                              ?.PotentialSupplierSelected
                                              ?.PotentialSupplierName
                                          }
                                          onChange={(e) => {
                                            setChangesInInvoice(
                                              changesInInvoice + 1
                                            );
                                            setShowAddByAmountSupplierDropdown(
                                              true
                                            );
                                            let value = e.target.value;
                                            setPotentialSupplierSearch(
                                              e.target.value
                                            );
                                            let SelectedSupplier = {
                                              PotentialSupplierName: value,
                                              PotentialSupplierId: 0,
                                            };
                                            let updated_data = {
                                              ...invoicePageData,
                                            };
                                            updated_data.PotentialSupplierSelected =
                                              SelectedSupplier;

                                            setInvoicePageData(updated_data);
                                          }}
                                        />
                                      )}
                                    </div>
                                  </Dropdown.Toggle>

                                  <Dropdown.Menu
                                    className="w-100 transparent-scroller mx-1"
                                    style={{
                                      maxHeight: "200px",
                                      overflowY: "auto",
                                    }}
                                  >
                                    {invoicePageData?.PotentialSupplierDetail_List?.filter(
                                      (pt) => pt?.IsMSP === true
                                    )?.map((item, index) => {
                                      return (
                                        <div
                                          className="d-flex align-items-center justify-content-between px-2 py-1 pointer"
                                          onClick={(e) => {
                                            setChangesInInvoice(
                                              changesInInvoice + 1
                                            );
                                            let updated_data = {
                                              ...invoicePageData,
                                            };
                                            updated_data.PotentialSupplierSelected =
                                              item;
                                            setInvoicePageData(updated_data);
                                            setPotentialSupplierSearch("");
                                            e.target
                                              .closest(
                                                ".add-item-category-dropdown"
                                              )
                                              .click();
                                          }}
                                        >
                                          <div className="d-flex align-items-center f-grey">
                                            <img
                                              src={item?.PotentialSupplierIcon}
                                              onError={({ currentTarget }) => {
                                                currentTarget.onerror = null; // prevents looping
                                                currentTarget.src =
                                                  "/images/user-logo.svg";
                                              }}
                                              width={18}
                                              height={18}
                                              className="mr-2 rounded-full"
                                              alt=""
                                            />
                                            {item?.PotentialSupplierName}
                                          </div>
                                          <div className="f-12 f-darkgrey">
                                            You
                                          </div>
                                        </div>
                                      );
                                    })}
                                    <div className={`f-grey f-12 pl-2`}>
                                      Last used
                                    </div>
                                    {invoicePageData?.PotentialSupplierDetail_List?.filter(
                                      (pk) =>
                                        pk?.PotentialSupplierName?.length > 0
                                    )
                                      ?.filter(
                                        (pt) =>
                                          pt?.PotentialSupplierName?.toLowerCase()?.includes(
                                            potentialSupplierSearch?.toLowerCase()
                                          ) && pt?.IsMSP === false
                                      )
                                      ?.map((item, itemIndex) => {
                                        return (
                                          <div
                                            className="d-flex align-items-center justify-content-between pointer pl-2 my-2"
                                            onClick={(e) => {
                                              setChangesInInvoice(
                                                changesInInvoice + 1
                                              );
                                              let updated_data = {
                                                ...invoicePageData,
                                              };
                                              updated_data.PotentialSupplierSelected =
                                                item;
                                              setInvoicePageData(updated_data);
                                              setPotentialSupplierSearch("");
                                              e.target
                                                .closest(
                                                  ".add-item-category-dropdown"
                                                )
                                                .click();
                                            }}
                                          >
                                            <div
                                              className={`${
                                                item?.PotentialSupplierId ===
                                                invoicePageData
                                                  ?.PotentialSupplierSelected
                                                  ?.PotentialSupplierId
                                                  ? "f-blue  "
                                                  : " f-black"
                                              }`}
                                            >
                                              {item?.PotentialSupplierName}
                                            </div>
                                            <div>
                                              {item?.PotentialSupplierId ===
                                                invoicePageData
                                                  ?.PotentialSupplierSelected
                                                  ?.PotentialSupplierId && (
                                                <img
                                                  alt=""
                                                  className=" mr-2"
                                                  src="/images/msp/blue-tick.svg"
                                                />
                                              )}
                                            </div>
                                          </div>
                                        );
                                      })}
                                    {potentialSupplierSearch !== "" && (
                                      <div
                                        className={"f-500  f-blue pointer pl-2"}
                                        onClick={(e) => {
                                          let SelectedSupplier = {
                                            PotentialSupplierName:
                                              potentialSupplierSearch,
                                            PotentialSupplierId: 0,
                                          };
                                          let updated_data = {
                                            ...invoicePageData,
                                          };
                                          updated_data.PotentialSupplierSelected =
                                            SelectedSupplier;

                                          setInvoicePageData(updated_data);
                                          setPotentialSupplierSearch("");

                                          e.target
                                            .closest(
                                              ".add-item-category-dropdown"
                                            )
                                            .click();
                                        }}
                                      >
                                        Add "{potentialSupplierSearch}" supplier
                                      </div>
                                    )}
                                  </Dropdown.Menu>
                                </Dropdown>
                              </div>
                            </div>

                            <div className="w-100 py-2 d-flex align-items-center f-black">
                              <div className="w-50">Contact Email</div>
                              <div className="w-50">
                                <div
                                  className={` ${
                                    errors?.invoicePageEmailError
                                      ? "input-error"
                                      : ""
                                  }   d-flex align-items-center radius-4 highlight-input  p-2 flex-grow-1 mr-1`}
                                >
                                  <input
                                    type="text"
                                    className="bg-transparent flex-grow-1 border-none"
                                    placeholder="Optional"
                                    value={invoicePageData?.ContactEmail ?? ""}
                                    onChange={(e) => {
                                      let value = e.target.value;
                                      setInvoicePageData((prev) => ({
                                        ...prev,
                                        ContactEmail: value,
                                      }));
                                      setChangesInInvoice(changesInInvoice + 1);
                                      setErrors((prev) => ({
                                        ...prev,
                                        invoicePageEmailError: false,
                                      }));
                                    }}
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="w-100 py-2 d-flex align-items-center f-black">
                              <div className="w-50">Recurring period</div>
                              <div className="w-50">
                                <CustomHGDropdown
                                  redBorder={errors?.billingTenureError}
                                  greyBackground={true}
                                  fieldId={"Id"}
                                  fieldValue={"OptionText"}
                                  selected={invoicePageData?.BillingTenure}
                                  placeholder="Select"
                                  onClick={(id, val, item) => {
                                    setInvoicePageData((prev) => ({
                                      ...prev,
                                      BillingTenure: item,
                                    }));
                                    setChangesInInvoice(changesInInvoice + 1);
                                    setErrors((prev) => ({
                                      ...prev,
                                      billingTenureError: false,
                                    }));
                                  }}
                                  data={
                                    invoicePageData?.SubscriptionMeta
                                      ?.RecurringPeriodOptions
                                  }
                                />
                              </div>
                            </div>

                            <div className="w-100  d-flex align-items-center f-black mb-2">
                              <div className="w-50">Start Date</div>
                              <div className="w-50 msp-date-picker">
                                <HighgroundDatePicker
                                  // disableFutureDates
                                  isError={errors?.subscriptionStartDateError}
                                  selected={handleDateForInvoice(
                                    invoicePageData?.SubscriptionStartDate
                                  )}
                                  icon={"/images/attack-surface/date-icon.svg"}
                                  onChange={(val) => {
                                    setChangesInInvoice(changesInInvoice + 1);
                                    setInvoicePageData((prev) => ({
                                      ...prev,
                                      SubscriptionStartDate: val,
                                    }));
                                    setErrors((prev) => ({
                                      ...prev,
                                      subscriptionStartDateError: false,
                                    }));
                                    updateExchangeRateData({
                                      _date: val,
                                    });
                                  }}
                                  id={"dvdsgffwvzdgdj"}
                                />
                              </div>
                            </div>
                            <div className="w-100  d-flex align-items-center f-black">
                              <div className="w-50">End Date</div>
                              <div className="w-50 msp-date-picker">
                                <HighgroundDatePicker
                                  // disableFutureDates
                                  isError={errors?.subscriptionEndDateError}
                                  selected={handleDateForInvoice(
                                    invoicePageData?.SubscriptionEndDate
                                  )}
                                  icon={"/images/attack-surface/date-icon.svg"}
                                  onChange={(val) => {
                                    setInvoicePageData((prev) => ({
                                      ...prev,
                                      SubscriptionEndDate: val,
                                    }));
                                    setChangesInInvoice(changesInInvoice + 1);
                                    setErrors((prev) => ({
                                      ...prev,
                                      subscriptionEndDateError: false,
                                    }));
                                    updateExchangeRateData({
                                      _date: val,
                                    });
                                  }}
                                  id={"dvdsgweernddatedgdj"}
                                />
                              </div>
                            </div>
                            <div className="w-100 py-2 d-flex align-items-center f-black">
                              <div className="w-50">Auto renewal terms</div>
                              <div className="w-50">
                                <CustomHGDropdown
                                  redBorder={errors?.autoRenewalTermsError}
                                  greyBackground={true}
                                  fieldId={"Id"}
                                  fieldValue={"OptionText"}
                                  selected={invoicePageData?.AutoRenewalTerms}
                                  placeholder="Select"
                                  onClick={(id, val, item) => {
                                    setInvoicePageData((prev) => ({
                                      ...prev,
                                      AutoRenewalTerms: item,
                                    }));
                                    setChangesInInvoice(changesInInvoice + 1);
                                    setErrors((prev) => ({
                                      ...prev,
                                      autoRenewalTermsError: false,
                                    }));
                                  }}
                                  data={
                                    invoicePageData?.SubscriptionMeta
                                      ?.AutoRenewalTermsOptions
                                  }
                                />
                              </div>
                            </div>
                            <div className="w-100 py-2 d-flex align-items-center f-black">
                              <div className="w-50">Cancellation terms</div>
                              <div className="w-50">
                                <CustomHGDropdown
                                  redBorder={errors?.cancellationTermsError}
                                  greyBackground={true}
                                  fieldId={"Id"}
                                  fieldValue={"OptionText"}
                                  selected={invoicePageData?.CancellationTerms}
                                  placeholder="Select"
                                  onClick={(id, val, item) => {
                                    setInvoicePageData((prev) => ({
                                      ...prev,
                                      CancellationTerms: item,
                                    }));
                                    setChangesInInvoice(changesInInvoice + 1);
                                    setErrors((prev) => ({
                                      ...prev,
                                      cancellationTermsError: false,
                                    }));
                                  }}
                                  data={
                                    invoicePageData?.SubscriptionMeta
                                      ?.CancellationTermsOptions
                                  }
                                />
                              </div>
                            </div>
                            <div className="w-100  d-flex align-items-center f-black mb-2">
                              <div className="w-50">Billing Date</div>
                              <div className="w-50 msp-date-picker">
                                <HighgroundDatePicker
                                  // disableFutureDates
                                  isError={errors?.invoiceDateError}
                                  selected={handleDateForInvoice(
                                    invoicePageData?.InvoiceDate
                                  )}
                                  icon={"/images/attack-surface/date-icon.svg"}
                                  onChange={(val) => {
                                    setChangesInInvoice(changesInInvoice + 1);
                                    setInvoicePageData((prev) => ({
                                      ...prev,
                                      InvoiceDate: val,
                                    }));
                                    setErrors((prev) => ({
                                      ...prev,
                                      invoiceDateError: false,
                                    }));
                                    updateExchangeRateData({
                                      _date: val,
                                    });
                                  }}
                                  id={"dvdsgwebilnpatedgdj"}
                                />
                                {/* <CustomHGDropdown
                                  redBorder={errors?.cancellationTermsError}
                                  greyBackground={true}
                                  fieldId={"Id"}
                                  fieldValue={"OptionText"}
                                  selected={invoicePageData?.CancellationTerms}
                                  placeholder="Select"
                                  onClick={(id, val, item) => {
                                    setChangesInInvoice(changesInInvoice + 1);
                                    setInvoicePageData((prev) => ({
                                      ...prev,
                                      InvoiceDate: item,
                                    }));
                                    setErrors((prev) => ({
                                      ...prev,
                                      invoiceDateError: false,
                                    }));
                                    let today = new Date()
                                    
                                    updateExchangeRateData({
                                      _date: formatDateDDMMYYY(today),
                                    });
                                  }}
                                  data={
                                    [
                                      { Id: 1, OptionText: '1st of month' },
                                      { Id: 2, OptionText: '2nd of month' },
                                      { Id: 3, OptionText: '3rd of month' },
                                      { Id: 4, OptionText: '4th of month' },
                                      { Id: 5, OptionText: '5th of month' },
                                      { Id: 6, OptionText: '6th of month' },
                                      { Id: 7, OptionText: '7th of month' },
                                      { Id: 8, OptionText: '8th of month' },
                                      { Id: 9, OptionText: '9th of month' },
                                      { Id: 10, OptionText: '10th of month' },
                                      { Id: 11, OptionText: '11th of month' },
                                      { Id: 12, OptionText: '12th of month' },
                                      { Id: 13, OptionText: '13th of month' },
                                      { Id: 14, OptionText: '14th of month' },
                                      { Id: 15, OptionText: '15th of month' },
                                      { Id: 16, OptionText: '16th of month' },
                                      { Id: 17, OptionText: '17th of month' },
                                      { Id: 18, OptionText: '18th of month' },
                                      { Id: 19, OptionText: '19th of month' },
                                      { Id: 20, OptionText: '20th of month' },
                                      { Id: 21, OptionText: '21st of month' },
                                      { Id: 22, OptionText: '22nd of month' },
                                      { Id: 23, OptionText: '23rd of month' },
                                      { Id: 24, OptionText: '24th of month' },
                                      { Id: 25, OptionText: '25th of month' },
                                      { Id: 26, OptionText: '26th of month' },
                                      { Id: 27, OptionText: '27th of month' },
                                      { Id: 28, OptionText: '28th of month' },
                                      { Id: 29, OptionText: '29th of month' },
                                      { Id: 30, OptionText: '30th of month' },
                                      { Id: 31, OptionText: '31st of month' }
                                    ]
                                    
                                  }
                                /> */}
                              </div>
                            </div>
                            <div className="w-100  d-flex align-items-center f-black">
                              <div className="w-50 f-500">Currency</div>
                              <div className="w-50   f-500">
                                <div className="w-fit-content bg-grey radius-4 p-2 ">
                                  <CurrencyDropdown
                                    showFullCurrencyName
                                    menuClassName={
                                      "add-amount-currency-dropdown"
                                    }
                                    width={"200px"}
                                    inputWidth={"144px"}
                                    currencyList={currencyList}
                                    onChange={(data) => {
                                      setUserSelectedCurrency(data);
                                      let today = new Date();

                                      updateExchangeRateData({
                                        _currency: data?.CurrencyName,
                                        _date:
                                          // formatDateDDMMYYY(today)
                                          invoicePageData?.InvoiceDate ===
                                          "0001-01-01T00:00:00"
                                            ? moment().format("DD/MM/YYYY")
                                            : handleDateForInvoice(
                                                invoicePageData?.InvoiceDate
                                              ),
                                      });
                                    }}
                                    selected={userSelectedCurrency}
                                  />
                                </div>
                              </div>
                            </div>
                            <hr />

                            <div className="f-12 f-500 f-darkgrey mt-2">
                              Total
                            </div>
                            {/* Discount */}
                            <div className="w-100 py-1 d-flex align-items-center f-black justify-content-between h-32 mb-1">
                              <div className="w-50">Discount</div>
                              <div className="w-50">
                                <div className="d-flex align-items-center radius-4 highlight-input  p-2 w-60 mr-1">
                                  <div className="mr-1">
                                    {" "}
                                    {userSelectedCurrency?.CurrencySymbol}{" "}
                                  </div>

                                  <input
                                    type="number"
                                    className="bg-transparent flex-grow-1 border-none "
                                    placeholder="Type"
                                    value={invoicePageData?.Discount ?? ""}
                                    onChange={(e) => {
                                      let value = e.target.value;
                                      setInvoicePageData((prev) => ({
                                        ...prev,
                                        Discount: validateNumber(value),
                                      }));
                                    }}
                                    onFocus={() => {
                                      if (!invoicePageData?.Discount) {
                                        setInvoicePageData((prev) => ({
                                          ...prev,
                                          Discount: "",
                                        }));
                                      }
                                    }}
                                    onBlur={() => {
                                      if (!invoicePageData?.Discount) {
                                        setInvoicePageData((prev) => ({
                                          ...prev,
                                          Discount: 0,
                                        }));
                                      }
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                            {/* Amount */}
                            <div className="w-100   d-flex align-items-center f-black mb-1">
                              <div className="w-50">Amount</div>
                              <div className=" d-flex align-items-center justify-content-between w-50">
                                <div
                                  className={`${
                                    errors?.totalAmountError
                                      ? "input-error"
                                      : ""
                                  } d-flex align-items-center radius-4 highlight-input  p-2  w-60`}
                                >
                                  <div className="mr-1">
                                    {" "}
                                    {userSelectedCurrency?.CurrencySymbol}{" "}
                                  </div>
                                  <input
                                    type="number"
                                    className="bg-transparent  border-none w-100"
                                    placeholder="Type"
                                    value={invoicePageData?.TotalAmount ?? ""}
                                    onChange={(e) => {
                                      let value = e.target.value;
                                      setChangesInInvoice(changesInInvoice + 1);
                                      if (value?.toString().length > 10) {
                                        return;
                                      }
                                      setInvoicePageData((prev) => ({
                                        ...prev,
                                        TotalAmount: validateNumber(value),
                                      }));
                                      setErrors((prev) => ({
                                        ...prev,
                                        totalAmountError: false,
                                      }));
                                    }}
                                    onFocus={() => {
                                      if (!invoicePageData?.TotalAmount) {
                                        setInvoicePageData((prev) => ({
                                          ...prev,
                                          TotalAmount: "",
                                        }));
                                      }
                                    }}
                                    onBlur={() => {
                                      if (!invoicePageData?.TotalAmount) {
                                        setInvoicePageData((prev) => ({
                                          ...prev,
                                          TotalAmount: 0,
                                        }));
                                      }
                                    }}
                                  />
                                </div>
                                <div className=" ">
                                  {/* <CurrencyDropdown
                                    showFullCurrencyName
                                    menuClassName={
                                      "add-amount-currency-dropdown"
                                    }
                                    width={"200px"}
                                    inputWidth={"144px"}
                                    currencyList={currencyList}
                                    onChange={(data) => {
                                      setUserSelectedCurrency(data);
                                      updateExchangeRateData({
                                        _currency: data?.CurrencyName,
                                        _date:
                                          invoicePageData?.InvoiceDate ===
                                          "0001-01-01T00:00:00"
                                            ? moment().format("DD/MM/YYYY")
                                            : handleDateForInvoice(
                                                invoicePageData?.InvoiceDate
                                              ),
                                      });
                                    }}
                                    selected={userSelectedCurrency}
                                  /> */}
                                </div>
                              </div>
                            </div>
                            {/* Tax Rate */}
                            <div className="w-100   d-flex align-items-center f-black mb-1">
                              <div className="w-50">Tax Rate</div>
                              <div className="w-50 d-flex align-items-center justify-content-between">
                                <div>
                                  <div
                                    className="flex-1 radius-4 p-2"
                                    style={{
                                      backgroundColor: "#f6f8fb",
                                    }}
                                  >
                                    <TaxRateDropdown
                                      deleteTaxRate={deleteTaxRate}
                                      updateTaxList={updateTaxList}
                                      onChange={(data) => {
                                        setInvoicePageData((prev) => ({
                                          ...prev,
                                          TaxRates_Selected: data,
                                        }));
                                      }}
                                      taxList={taxList}
                                      selected={
                                        invoicePageData?.TaxRates_Selected
                                      }
                                      onClickEditTaxRate={(data) => {
                                        setTaxRateData(data);
                                        setShowAddEditTaxRateModal(true);
                                      }}
                                      onClickAddTaxRate={() => {
                                        setTaxRateData({
                                          ObjectId: 0,
                                          TaxCode: "",
                                          TaxName: "",
                                          TaxRate: "",
                                        });
                                        setShowAddEditTaxRateModal(true);
                                      }}
                                    />
                                  </div>
                                </div>
                                <div className="d-flex align-items-center radius-4 f-grey  p-2  mr-1">
                                  {invoicePageData?.TaxRates_Selected
                                    ?.TaxRate ?? 0}
                                  % ={" "}
                                  {convertToCurrencyFormat(
                                    Number(
                                      (((invoicePageData.TotalAmount ?? 0) -
                                        invoicePageData?.Discount ?? 0) *
                                        (invoicePageData?.TaxRates_Selected
                                          ?.TaxRate ?? 0)) /
                                        100 ?? 0
                                    ),
                                    userSelectedCurrency?.CurrencySymbol
                                  )}
                                </div>
                              </div>
                            </div>

                            <div className="w-100   d-flex align-items-center f-black">
                              <div className="w-50 f-600">Total</div>
                              <div className="w-50 pl-2">
                                <div
                                  className="d-flex align-items-center radius-4 py-2 pr-2 flex-grow-1 mr-1 f-600"
                                  style={{
                                    pointerEvents: "none",
                                  }}
                                >
                                  {invoicePageData?.TotalAmount === 0 ||
                                  userSelectedCurrency?.CurrencySymbol ===
                                    defaultCurrencyData?.CurrencySymbol ? (
                                    <>
                                      {invoicePageData?.TotalAmount === 0 ? (
                                        <div className="f-600 f-black">
                                          {userSelectedCurrency?.CurrencySymbol}{" "}
                                          0.00
                                        </div>
                                      ) : (
                                        <div className="f-600 f-black">
                                          {convertToCurrencyFormat(
                                            getTotalAmountForInvoice(),

                                            defaultCurrencyData?.CurrencySymbol
                                          )}
                                        </div>
                                      )}
                                    </>
                                  ) : (
                                    <div className="d-flex align-items-center justify-content-between w-100">
                                      <div>
                                        {convertToCurrencyFormat(
                                          getTotalAmountForInvoice(),
                                          userSelectedCurrency?.CurrencySymbol
                                        )}
                                      </div>
                                      <img
                                        width={15}
                                        height={15}
                                        src="/images/msp/exchange-rate-icon.svg"
                                        alt=""
                                      />
                                      <div>
                                        {convertToCurrencyFormat(
                                          exchangeRateData?.Rate *
                                            getTotalAmountForInvoice(),
                                          defaultCurrencyData?.CurrencySymbol
                                        )}
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                            {invoicePageData?.objId_Invoice > 0 && (
                              <>
                                <div className="border-bottom w-100 my-3" />
                                <div className="f-12 f-500 f-darkgrey mb-2">
                                  {" "}
                                  Status
                                </div>

                                {/* Subscription Status */}
                                <Dropdown className="transparennt-wrapper-dropdown ">
                                  <Dropdown.Toggle className="p-0 w-100">
                                    <div
                                      className="w-100 p-2 f-white radius-4 d-flex align-items-center justify-content-center"
                                      style={{
                                        background:
                                          invoicePageData?.SubscriptionStatus
                                            ?.OptionColor,
                                      }}
                                    >
                                      <div>
                                        {
                                          invoicePageData?.SubscriptionStatus
                                            ?.OptionText
                                        }
                                      </div>
                                      <img
                                        src="/images/msp/white-chevron-down.svg"
                                        alt=""
                                        className="ml-2"
                                      />
                                    </div>
                                  </Dropdown.Toggle>
                                  {invoicePageData?.SubscriptionMeta
                                    ?.SubscriptionStatusOptions?.length > 0 && (
                                    <Dropdown.Menu
                                      className="p-2 menu-inset-right-corner"
                                      style={{
                                        width: "100%",
                                      }}
                                    >
                                      {invoicePageData?.SubscriptionMeta?.SubscriptionStatusOptions?.map(
                                        (elem) => {
                                          return (
                                            <div
                                              className="d-flex align-items-center mb-2 pointer"
                                              onClick={(e) => {
                                                e.target
                                                  .closest(
                                                    ".transparennt-wrapper-dropdown"
                                                  )
                                                  .click();
                                                setInvoicePageData((prev) => ({
                                                  ...prev,
                                                  SubscriptionStatus: elem,
                                                }));
                                              }}
                                            >
                                              <img
                                                src="/images/msp/dropdown-grey-tick.svg"
                                                alt=""
                                                className={"mr-2"}
                                                style={{
                                                  visibility:
                                                    elem?.Id ===
                                                    invoicePageData
                                                      ?.SubscriptionStatus?.Id
                                                      ? "visible"
                                                      : "hidden",
                                                }}
                                              />
                                              <div className="">
                                                <div className="f-grey f-500">
                                                  {elem?.OptionText}{" "}
                                                </div>
                                                <div className="f-12 f-darkgrey">
                                                  {elem?.OptionDescription}{" "}
                                                </div>
                                              </div>
                                            </div>
                                          );
                                        }
                                      )}
                                    </Dropdown.Menu>
                                  )}
                                </Dropdown>

                                <div className="f-12 f-500 f-darkgrey my-2">
                                  {" "}
                                  Invoices
                                </div>

                                {invoicePageData?.InvoiceItem_List?.length ===
                                  0 && <NoAutomaticGeneratedInvoiceUI />}
                                {invoicePageData?.InvoiceItem_List?.map(
                                  (_invoice) => {
                                    return (
                                      <div
                                        className="subscription-invoice-row p-2 bg-hover-grey radius-4 pointer"
                                        onClick={() => {
                                          setLoading(true);
                                          setInvoiceEditMode(true);

                                          if (_invoice?.IsDirectAmount) {
                                            setAddSpendSelectedTab(
                                              "addByAmount"
                                            );
                                          } else {
                                            setAddSpendSelectedTab(
                                              "addByInvoice"
                                            );
                                          }

                                          goTo(
                                            <>
                                              <img
                                                src="/images/msp/msp-invoice-icon.svg"
                                                alt=""
                                                className="mr-2 d-inline-block"
                                              />
                                              {TrimString(
                                                _invoice?.InvoiceRef,
                                                30
                                              )}
                                            </>,
                                            Pages.ADD_SPEND,
                                            {
                                              invoiceId:
                                                _invoice?.objId_Invoice,
                                              categoryId:
                                                categoryPageData?.ID ?? 0,
                                              subCategoryId:
                                                subCategoryPageData?.ID ?? 0,
                                              invoiceType: "addBySubscription",
                                            }
                                          );
                                          getInvoiceDetails(
                                            _invoice?.objId_Invoice,
                                            0,
                                            {
                                              invoiceItemId:
                                                _invoice?.objId_Invoice,
                                            }
                                          );
                                        }}
                                      >
                                        <div className="d-flex align-items-center">
                                          {" "}
                                          <img
                                            alt=""
                                            className="mr-2"
                                            src="/images/msp/msp-invoice-icon.svg"
                                          />{" "}
                                          {_invoice?.InvoiceRef}
                                        </div>
                                        <div>
                                          {moment(_invoice?.InvoiceDate).format(
                                            "ll"
                                          )}
                                        </div>
                                        <div className="f-black f-500 d-flex align-items-center justify-content-end">
                                          {_invoice?.TotalItemValue_Text}
                                        </div>
                                      </div>
                                    );
                                  }
                                )}

                                <div className="p-3" />
                              </>
                            )}
                          </div>

                          <div
                            style={{
                              padding: "0rem",
                            }}
                          />
                        </Tab.Pane>
                      </Tab.Content>
                    </div>
                  </div>

                  {exchangeRateData?.FromCurrency !==
                    defaultCurrencyData?.CurrencyName && (
                    <div className="w-100 text-center f-12 f-400 f-darkgrey mb-3">
                      <img
                        alt=""
                        width={13}
                        height={13}
                        src="/images/msp/exchange-rate-icon.svg"
                        className="mr-2"
                      />{" "}
                      The exchange rate on{" "}
                      {moment(exchangeRateData?.Date ?? "")?.format(
                        "DD MMM YYYY"
                      )}{" "}
                      was 1 {exchangeRateData?.FromCurrency} ={" "}
                      {exchangeRateData?.Rate}{" "}
                      {defaultCurrencyData?.CurrencyName}
                    </div>
                  )}
                </Tab.Container>
              </>
            )}
            {activePage?.type === Pages.SUBCATEGORY && (
              <div className="p-2">
                <div
                  className="d-flex align-items-center"
                  style={{ height: "37px" }}
                >
                  {/* Input */}
                  <div
                    className={`d-flex align-items-center radius-4 grey-border p-2 flex-grow-1 mr-1 ${
                      errors?.subCategoryNameError ? "input-error" : ""
                    }`}
                  >
                    <div className="f-darkgrey">Name:</div>

                    <input
                      type="text"
                      className="bg-transparent w-100 border-none pl-2"
                      placeholder="Type"
                      value={subCategoryPageData?.CategoryName}
                      onChange={(e) => {
                        let value = e.target.value;
                        setSubCategoryPageData((prev) => ({
                          ...prev,
                          CategoryName: value,
                        }));
                        setErrors((prev) => ({
                          ...prev,
                          subCategoryNameError: false,
                        }));
                      }}
                    />
                  </div>

                  <div className="bg -grey h-1 00 d-flex align-items-center">
                    <ColorDropdown
                      colorListWithAvailability={
                        categoryPageData?.CategorySpendColor_List ?? []
                      }
                      height={37}
                      itemCircleWidth={14}
                      selectedCircleWidth={17}
                      data={subCategoryPageData?.CategorySpendColor_List}
                      selected={{
                        ColorCode: subCategoryPageData?.CategoryColourHexCode,
                        ColorName: subCategoryPageData?.CategoryColour,
                      }}
                      onChange={(_color) => {
                        let colorCode = _color.ColorCode;
                        let colorName = _color.ColorName;
                        setSubCategoryPageData((prev) => ({
                          ...prev,
                          CategoryColour: colorName,
                          CategoryColourHexCode: colorCode,
                        }));
                      }}
                    />
                  </div>
                </div>
                {subCategoryPageData?.SyncStatus?.ShowBanner && !isSaas && (
                  <div
                    className={
                      "bg-grey radius-4 d-flex align-items-center justify-content-between mt-2 p-2"
                    }
                  >
                    <div className=" d-flex align-items-center">
                      <img
                        alt=""
                        src="/images/supply-chain/blue-plug.svg"
                        className="mr-2"
                      />
                      <div className="f-black">
                        Integrate your PSA tools to automatically import your
                        clients spend
                      </div>
                    </div>

                    <div className="f-blue d-flex align-items-center">
                      <div className="pointer" onClick={() => {}}>
                        Integrations
                      </div>
                      <img
                        src="/images/supply-chain/blue-redirect.svg"
                        alt=""
                        className="mx-2 pointer"
                        onClick={() => {
                          hideModal();
                          setTimeout(() => {
                            history.push(
                              "/mspv2/integrations?type=connectedIntegrations"
                            );
                          }, 500);
                        }}
                      />
                      {/* <img
                     src="/images/small-cross-icon.svg"
                     alt=""
                     className="mx-2 pointer"
                     onClick={() => { }}
                   /> */}
                    </div>
                  </div>
                )}

                {subCategoryPageData?.SyncStatus?.IsSyncing === true &&
                !isSaas ? (
                  <div className="d-flex align-items-center f-darkgrey f-500 ml-1 mt-2">
                    <img
                      src="/images/msp/integration-sync-icon.svg"
                      alt=""
                      className="mr-2"
                    />
                    Syncing...
                  </div>
                ) : (
                  <>
                    {subCategoryPageData?.SyncStatus?.MessageAvatarURL && (
                      <div
                        className="ml-1 d-flex align-items-center mt-2"
                        style={{
                          color: subCategoryPageData?.SyncStatus?.MessageColour,
                        }}
                      >
                        <AttackSurfaceTooltip
                          content={
                            <>
                              <div className="f-12 f-white"> Updated by:</div>
                              <div className="d-flex align-items-center">
                                <img
                                  src={
                                    subCategoryPageData?.SyncStatus
                                      ?.MessageAvatarURL
                                  }
                                  alt=""
                                  width={18}
                                  height={18}
                                  className="mr-2 rounded-full"
                                  onError={({ currentTarget }) => {
                                    currentTarget.onerror = null; // prevents looping
                                    currentTarget.src = "/images/user-logo.svg";
                                  }}
                                />{" "}
                                <div className="f-white">
                                  {" "}
                                  {subCategoryPageData?.SyncStatus?.Source}{" "}
                                </div>
                              </div>
                            </>
                          }
                        >
                          <img
                            src={
                              subCategoryPageData?.SyncStatus?.MessageAvatarURL
                            }
                            alt=""
                            width={18}
                            height={18}
                            className="mr-2 rounded-full"
                            onError={({ currentTarget }) => {
                              currentTarget.onerror = null; // prevents looping
                              currentTarget.src = "/images/user-logo.svg";
                            }}
                          />
                        </AttackSurfaceTooltip>
                        <div>{subCategoryPageData?.SyncStatus?.Message}</div>
                      </div>
                    )}
                  </>
                )}
                <div className="ml-2 mt-1">
                  <div className="f-grey f-500 mb-2 mt-2">
                    {subCategoryPageData?.CategoryName} spend
                  </div>
                  <div className="f-black f-18 f-500">
                    {subCategoryPageData?.TotalAmount_Text}
                  </div>
                </div>

                {/* <div className="chart">
                    <MspDonutChart />
                  </div> */}
                <hr />

                <div className="f-500 f-grey mb-1 pl-2">Spend</div>
                <div className="my-2">
                  <TechnologySpendFilter
                    hideFilters={["Subcategory"]}
                    applyingFilters={applyingFilters}
                    invoiceFilterSearch={invoiceFilterSearch}
                    setInvoiceFilterSearch={(value) => {
                      setInvoiceFilterSearch(value);
                    }}
                    activeFilters={activeFilters}
                    setActiveFilters={setActiveFilters}
                    onClickAdd={() => {
                      setLoading(true);
                      setAddSpendSelectedTab("addByInvoice");
                      setInvoiceEditMode(true);
                      goTo("Add Spend", Pages.ADD_SPEND, {});
                      getInvoiceDetails(0, subCategoryPageData?.ID);
                    }}
                    onApply={(updated_filters) => {
                      const filterData = getFilters(updated_filters);
                      if (filterData) {
                        getSubCategoryDetail(
                          subCategoryPageData.ID,
                          filterData
                        );
                        setApplyingFilters(true);
                      }
                    }}
                    onReset={() => {
                      getSubCategoryDetail(subCategoryPageData.ID);
                    }}
                  />
                </div>

                {/* Automatic / Manual Spend Tabs */}

                {applyingFilters ? (
                  <div className="d-flex align-items-center justify-content-center w-100 mt-5 mb-5">
                    <GreyLoader width={18} />
                  </div>
                ) : (
                  // <>{subCategoryPageData?.InvoiceItemVM_List?.length === 0 ? (
                  //   <div className="my-5 d-flex flex-column align-items-center justify-content-center">
                  //     <div className="w-fit-content">
                  //       <img
                  //         src="/images/msp/msp-spend-invoice-icon.svg"
                  //         alt=""
                  //         className=""
                  //       />
                  //     </div>
                  //     <div className="w-fit-content f-500 mt-2 d-grey">
                  //       No spend yet
                  //     </div>
                  //     <div className="w-fit-content f-darkgrey">Add spend</div>
                  //   </div>
                  // ) :

                  <div className="mt-2">
                    <Tab.Container
                      id="left-tabs-example"
                      defaultActiveKey={defaultSelectedTab}
                    >
                      <div>
                        <div
                          sm={12}
                          className="tab-header d-flex justify-content-between align-items-center ml- p-0"
                        >
                          <div className={`${true}`}>
                            <Nav
                              variant="pills"
                              className="flex-row"
                              onSelect={(e) => setDefaultSelectedTab(e)}
                            >
                              {!isSaas && (
                                <Nav.Item>
                                  <Nav.Link eventKey="automaticSpend">
                                    Automatic Spend
                                  </Nav.Link>
                                </Nav.Item>
                              )}
                              <Nav.Item id="">
                                <Nav.Link eventKey="manualSpend">
                                  Manual Spend
                                </Nav.Link>
                              </Nav.Item>
                              <Nav.Item id="">
                                <Nav.Link eventKey="subscriptionSpend">
                                  Subscription Spend
                                </Nav.Link>
                              </Nav.Item>
                            </Nav>
                          </div>
                        </div>
                        <div sm={12} className="ml-1 p-0">
                          <Tab.Content>
                            <Tab.Pane eventKey="automaticSpend">
                              {subCategoryPageData?.InvoiceItemVM_List?.filter(
                                (item) =>
                                  searchSpendInvoices(
                                    item?.InvoiceItem_List,
                                    "Title",
                                    invoiceFilterSearch,
                                    true
                                  )
                              )?.map((invoice, invoiceIndex) => {
                                return (
                                  <div>
                                    <div className="f-12 f-500 f-darkgrey my-2 w-100 d-flex align-items-center  justify-content-center">
                                      {invoice?.GroupInvoiceDate}
                                    </div>
                                    {invoice?.InvoiceItem_List?.filter((item) =>
                                      item?.Title?.includes(invoiceFilterSearch)
                                    )
                                      ?.filter(
                                        (_item) => _item["IsImported"] === true
                                      )
                                      ?.map((invoiceItem, invoiceItemIndex) => {
                                        return (
                                          <div
                                            className="invoice-row p-2 bg-hover-grey radius-4 pointer"
                                            onClick={() => {
                                              setLoading(true);
                                              setInvoiceEditMode(
                                                invoiceItem?.Editable
                                              );
                                              if (invoiceItem?.IsDirectAmount) {
                                                setAddSpendSelectedTab(
                                                  "addByAmount"
                                                );
                                              } else {
                                                setAddSpendSelectedTab(
                                                  "addByInvoice"
                                                );
                                              }
                                              goTo(
                                                // <>
                                                //   <img
                                                //     src="/images/msp/msp-invoice-icon.svg"
                                                //     alt=""
                                                //     className="mr-2 d-inline-block"
                                                //   />
                                                //   {TrimString(
                                                //     invoiceItem?.Title,
                                                //     30
                                                //   )}
                                                // </>

                                                <div className="d-flex align-items-center justify-content-center">
                                                  <div className="position-relative w-fit-content h-fit-content">
                                                    <img
                                                      alt=""
                                                      className="mr-2"
                                                      src="/images/msp/msp-invoice-icon.svg"
                                                    />
                                                    {invoiceItem?.objId_Integration >
                                                      0 && (
                                                      <img
                                                        src={`/images/attack-surface/integrations/${invoiceItem?.objId_Integration}.webp`}
                                                        alt=""
                                                        className="position-absolute rounded-full"
                                                        width={13}
                                                        height={13}
                                                        style={{
                                                          top: "44%",
                                                          right: "4px",
                                                        }}
                                                      />
                                                    )}
                                                  </div>
                                                  {TrimString(
                                                    invoiceItem?.Title,
                                                    30
                                                  )}
                                                </div>,

                                                Pages.ADD_SPEND,
                                                {}
                                              );
                                              getInvoiceDetails(
                                                invoiceItem?.objId_Invoice,
                                                subCategoryPageData?.ID
                                              );
                                            }}
                                          >
                                            <div className="d-flex align-items-center">
                                              <div className="position-relative w-fit-content h-fit-content">
                                                <img
                                                  alt=""
                                                  className="mr-2"
                                                  src="/images/msp/msp-invoice-icon.svg"
                                                />
                                                {invoiceItem?.objId_Integration >
                                                  0 && (
                                                  <img
                                                    src={`/images/attack-surface/integrations/${invoiceItem?.objId_Integration}.webp`}
                                                    alt=""
                                                    className="position-absolute rounded-full"
                                                    width={13}
                                                    height={13}
                                                    style={{
                                                      top: "44%",
                                                      right: "4px",
                                                    }}
                                                  />
                                                )}
                                              </div>
                                              {highlightedInvoice(
                                                invoiceItem?.Title,
                                                invoiceFilterSearch
                                              )}
                                            </div>
                                            <div>
                                              {invoiceItem?.subCategory ? (
                                                <div className=" d-flex align-items-center f-12 f-500 f-grey rounded-full w-fit-content bg-greyish px-2">
                                                  <div
                                                    className="rounded-full mr-2"
                                                    style={{
                                                      width: "9px",
                                                      height: "9px",
                                                      background:
                                                        invoiceItem?.subCategory
                                                          ?.color,
                                                    }}
                                                  />

                                                  {
                                                    invoiceItem?.subCategory
                                                      ?.name
                                                  }
                                                </div>
                                              ) : (
                                                ""
                                              )}
                                            </div>
                                            <div className="f-black f-500 d-flex align-items-center justify-content-end">
                                              <AttackSurfaceTooltip
                                                // bottomLeft={
                                                //   invoiceIndex <
                                                //   subCategoryPageData
                                                //     ?.InvoiceItemVM_List
                                                //     ?.length -
                                                //     1
                                                // }
                                                topLeft={`invoiceIndex ===
                                                  subCategoryPageData
                                                    ?.InvoiceItemVM_List
                                                    ?.length -
                                                    1`}
                                                content={
                                                  subCategoryPageData?.SpendInvoiceToolTip
                                                }
                                              >
                                                <span>
                                                  {invoiceItem?.ItemValue_Text?.replaceAll(
                                                    " ",
                                                    ""
                                                  )}
                                                </span>
                                              </AttackSurfaceTooltip>
                                              <AttackSurfaceTooltip
                                                bottomLeft={
                                                  invoiceIndex <
                                                  subCategoryPageData
                                                    ?.InvoiceItemVM_List
                                                    ?.length -
                                                    1
                                                }
                                                topLeft={
                                                  invoiceIndex ===
                                                  subCategoryPageData
                                                    ?.InvoiceItemVM_List
                                                    ?.length -
                                                    1
                                                }
                                                content={
                                                  subCategoryPageData?.TotalSpendInvoiceToolTip
                                                }
                                              >
                                                <span
                                                  className="f-darkgrey"
                                                  style={{
                                                    whiteSpace: "nowrap",
                                                  }}
                                                >
                                                  &nbsp;/&nbsp;
                                                  {invoiceItem?.TotalItemValue_Text?.replaceAll(
                                                    " ",
                                                    ""
                                                  )}
                                                </span>
                                              </AttackSurfaceTooltip>
                                            </div>
                                          </div>
                                        );
                                      })}
                                  </div>
                                );
                              })}

                              {subCategoryPageData?.InvoiceItemVM_List?.filter(
                                (item) =>
                                  searchSpendInvoices(
                                    item?.InvoiceItem_List,
                                    "Title",
                                    invoiceFilterSearch,
                                    true
                                  )
                              )?.length === 0 &&
                                invoiceFilterSearch !== "" && (
                                  <NoSpendFoundUI />
                                )}

                              {subCategoryPageData?.InvoiceItemVM_List?.filter(
                                (item) =>
                                  searchSpendInvoices(
                                    item?.InvoiceItem_List,
                                    "Title",
                                    invoiceFilterSearch,
                                    true
                                  )
                              )?.length === 0 &&
                                invoiceFilterSearch === "" && (
                                  <>
                                    {" "}
                                    {subCategoryPageData?.SyncStatus
                                      ?.isFirstRun === true ? (
                                      <AutomaticSpendSyncingUI />
                                    ) : (
                                      <AutomaticNoSpendFoundUI
                                        type={"subcategory"}
                                      />
                                    )}
                                  </>
                                )}
                            </Tab.Pane>
                            <Tab.Pane eventKey="manualSpend">
                              {subCategoryPageData?.InvoiceItemVM_List?.filter(
                                (item) =>
                                  searchSpendInvoices(
                                    item?.InvoiceItem_List,
                                    "Title",
                                    invoiceFilterSearch,
                                    false
                                  )
                              )?.map((invoice, invoiceIndex) => {
                                return (
                                  <div>
                                    <div className="f-12 f-500 f-darkgrey my-2 w-100 d-flex align-items-center  justify-content-center">
                                      {invoice?.GroupInvoiceDate}
                                    </div>
                                    {invoice?.InvoiceItem_List?.filter((item) =>
                                      item?.Title?.includes(invoiceFilterSearch)
                                    )
                                      ?.filter(
                                        (_item) => _item["IsImported"] === false
                                      )
                                      ?.map((invoiceItem, invoiceItemIndex) => {
                                        return (
                                          <div
                                            className="invoice-row p-2 bg-hover-grey radius-4 pointer"
                                            onClick={() => {
                                              setLoading(true);
                                              setInvoiceEditMode(
                                                invoiceItem?.Editable
                                              );
                                              if (invoiceItem?.IsDirectAmount) {
                                                setAddSpendSelectedTab(
                                                  "addByAmount"
                                                );
                                              } else {
                                                setAddSpendSelectedTab(
                                                  "addByInvoice"
                                                );
                                              }
                                              goTo(
                                                <>
                                                  <img
                                                    src="/images/msp/msp-invoice-icon.svg"
                                                    alt=""
                                                    className="mr-2 d-inline-block"
                                                  />
                                                  {TrimString(
                                                    invoiceItem?.Title,
                                                    30
                                                  )}
                                                </>,
                                                Pages.ADD_SPEND,
                                                {}
                                              );
                                              getInvoiceDetails(
                                                invoiceItem?.objId_Invoice,
                                                subCategoryPageData?.ID
                                              );
                                            }}
                                          >
                                            <div className="d-flex align-items-center">
                                              <img
                                                alt=""
                                                className="mr-2"
                                                src="/images/msp/msp-invoice-icon.svg"
                                              />
                                              {highlightedInvoice(
                                                invoiceItem?.Title,
                                                invoiceFilterSearch
                                              )}
                                            </div>
                                            <div>
                                              {invoiceItem?.subCategory ? (
                                                <div className=" d-flex align-items-center f-12 f-500 f-grey rounded-full w-fit-content bg-greyish px-2">
                                                  <div
                                                    className="rounded-full mr-2"
                                                    style={{
                                                      width: "9px",
                                                      height: "9px",
                                                      background:
                                                        invoiceItem?.subCategory
                                                          ?.color,
                                                    }}
                                                  />

                                                  {
                                                    invoiceItem?.subCategory
                                                      ?.name
                                                  }
                                                </div>
                                              ) : (
                                                ""
                                              )}
                                            </div>
                                            <div className="f-black f-500 d-flex align-items-center justify-content-end">
                                              <AttackSurfaceTooltip
                                                // bottomLeft={
                                                //   invoiceIndex <
                                                //   subCategoryPageData
                                                //     ?.InvoiceItemVM_List
                                                //     ?.length -
                                                //     1
                                                // }
                                                topLeft={`invoiceIndex ===
                                                  subCategoryPageData
                                                    ?.InvoiceItemVM_List
                                                    ?.length -
                                                    1`}
                                                content={
                                                  subCategoryPageData?.SpendInvoiceToolTip
                                                }
                                              >
                                                <span>
                                                  {invoiceItem?.ItemValue_Text?.replaceAll(
                                                    " ",
                                                    ""
                                                  )}
                                                </span>
                                              </AttackSurfaceTooltip>
                                              <AttackSurfaceTooltip
                                                bottomLeft={
                                                  invoiceIndex <
                                                  subCategoryPageData
                                                    ?.InvoiceItemVM_List
                                                    ?.length -
                                                    1
                                                }
                                                topLeft={
                                                  invoiceIndex ===
                                                  subCategoryPageData
                                                    ?.InvoiceItemVM_List
                                                    ?.length -
                                                    1
                                                }
                                                content={
                                                  subCategoryPageData?.TotalSpendInvoiceToolTip
                                                }
                                              >
                                                <span
                                                  className="f-darkgrey"
                                                  style={{
                                                    whiteSpace: "nowrap",
                                                  }}
                                                >
                                                  &nbsp;/&nbsp;
                                                  {invoiceItem?.TotalItemValue_Text?.replaceAll(
                                                    " ",
                                                    ""
                                                  )}
                                                </span>
                                              </AttackSurfaceTooltip>
                                            </div>
                                          </div>
                                        );
                                      })}
                                  </div>
                                );
                              })}

                              {subCategoryPageData?.InvoiceItemVM_List?.filter(
                                (item) =>
                                  searchSpendInvoices(
                                    item?.InvoiceItem_List,
                                    "Title",
                                    invoiceFilterSearch,
                                    false
                                  )
                              )?.length === 0 && (
                                <ManualNoSpendFoundUI
                                  onClickAdd={() => {
                                    setLoading(true);
                                    setAddSpendSelectedTab("addByInvoice");
                                    setInvoiceEditMode(true);
                                    goTo("Add Spend", Pages.ADD_SPEND, {});
                                    getInvoiceDetails(
                                      0,
                                      subCategoryPageData?.ID
                                    );
                                  }}
                                />
                              )}
                            </Tab.Pane>
                            <Tab.Pane eventKey="subscriptionSpend">
                              <div className="f-12 f-500 f-darkgrey my-2">
                                Invoices
                              </div>

                              {subCategoryPageData?.InvoiceItemVM_List?.filter(
                                (item) =>
                                  searchSpendInvoices(
                                    item?.InvoiceItem_List,
                                    "Title",
                                    invoiceFilterSearch,
                                    false,
                                    true
                                  )
                              )?.map((invoice, invoiceIndex) => {
                                return (
                                  <div>
                                    {/* <div className="f-12 f-500 f-darkgrey my-2 w-100 d-flex align-items-center  justify-content-center">
                                      {invoice?.GroupInvoiceDate}
                                    </div> */}
                                    {invoice?.InvoiceItem_List?.filter((item) =>
                                      item?.Title?.includes(invoiceFilterSearch)
                                    )
                                      ?.filter(
                                        (_item) => _item["IsImported"] === false
                                      )
                                      ?.map((invoiceItem, invoiceItemIndex) => {
                                        return (
                                          <div
                                            className="invoice-row p-2 bg-hover-grey radius-4 pointer"
                                            onClick={() => {
                                              setLoading(true);
                                              setInvoiceEditMode(
                                                invoiceItem?.Editable
                                              );
                                              // if (invoiceItem?.IsDirectAmount) {
                                              //   setAddSpendSelectedTab(
                                              //     "addByAmount"
                                              //   );
                                              // } else {
                                              //   setAddSpendSelectedTab(
                                              //     "addByInvoice"
                                              //   );
                                              // }
                                              setAddSpendSelectedTab(
                                                "addBySubscription"
                                              );
                                              goTo(
                                                <>
                                                  <img
                                                    src="/images/msp/msp-invoice-icon.svg"
                                                    alt=""
                                                    className="mr-2 d-inline-block"
                                                  />
                                                  {TrimString(
                                                    invoiceItem?.Title,
                                                    30
                                                  )}
                                                </>,
                                                Pages.ADD_SPEND,
                                                {
                                                  invoiceId:
                                                    invoiceItem?.objId_Invoice,
                                                  categoryId:
                                                    categoryPageData?.ID,
                                                  subCategoryId:
                                                    subCategoryPageData?.ID,
                                                  invoiceType:
                                                    "addBySubscription",
                                                }
                                              );
                                              getInvoiceDetails(
                                                invoiceItem?.objId_Invoice,
                                                subCategoryPageData?.ID
                                              );
                                            }}
                                          >
                                            <div className="d-flex align-items-center">
                                              <img
                                                alt=""
                                                className="mr-2"
                                                src="/images/msp/msp-invoice-icon.svg"
                                              />
                                              {highlightedInvoice(
                                                invoiceItem?.Title,
                                                invoiceFilterSearch
                                              )}
                                            </div>
                                            <div>
                                              {invoiceItem?.subCategory ? (
                                                <div className=" d-flex align-items-center f-12 f-500 f-grey rounded-full w-fit-content bg-greyish px-2">
                                                  <div
                                                    className="rounded-full mr-2"
                                                    style={{
                                                      width: "9px",
                                                      height: "9px",
                                                      background:
                                                        invoiceItem?.subCategory
                                                          ?.color,
                                                    }}
                                                  />

                                                  {
                                                    invoiceItem?.subCategory
                                                      ?.name
                                                  }
                                                </div>
                                              ) : (
                                                ""
                                              )}
                                            </div>
                                            <div className="f-black f-500 d-flex align-items-center justify-content-end">
                                              <AttackSurfaceTooltip
                                                // bottomLeft={
                                                //   invoiceIndex <
                                                //   subCategoryPageData
                                                //     ?.InvoiceItemVM_List
                                                //     ?.length -
                                                //     1
                                                // }
                                                topLeft={`invoiceIndex ===
                                                  subCategoryPageData
                                                    ?.InvoiceItemVM_List
                                                    ?.length -
                                                    1`}
                                                content={
                                                  subCategoryPageData?.SpendInvoiceToolTip
                                                }
                                              >
                                                <span>
                                                  {invoiceItem?.ItemValue_Text?.replaceAll(
                                                    " ",
                                                    ""
                                                  )}
                                                </span>
                                              </AttackSurfaceTooltip>
                                              <AttackSurfaceTooltip
                                                bottomLeft={
                                                  invoiceIndex <
                                                  subCategoryPageData
                                                    ?.InvoiceItemVM_List
                                                    ?.length -
                                                    1
                                                }
                                                topLeft={
                                                  invoiceIndex ===
                                                  subCategoryPageData
                                                    ?.InvoiceItemVM_List
                                                    ?.length -
                                                    1
                                                }
                                                content={
                                                  subCategoryPageData?.TotalSpendInvoiceToolTip
                                                }
                                              >
                                                <span
                                                  className="f-darkgrey"
                                                  style={{
                                                    whiteSpace: "nowrap",
                                                  }}
                                                >
                                                  &nbsp;/&nbsp;
                                                  {invoiceItem?.TotalItemValue_Text?.replaceAll(
                                                    " ",
                                                    ""
                                                  )}
                                                </span>
                                              </AttackSurfaceTooltip>
                                            </div>
                                          </div>
                                        );
                                      })}
                                  </div>
                                );
                              })}

                              {subCategoryPageData?.InvoiceItemVM_List?.filter(
                                (item) =>
                                  searchSpendInvoices(
                                    item?.InvoiceItem_List,
                                    "Title",
                                    invoiceFilterSearch,
                                    false,
                                    true
                                  )
                              )?.length === 0 && (
                                <NoSubscriptionSpendFoundUI
                                  onClickAdd={(e) => {
                                    setLoading(true);
                                    setAddSpendSelectedTab("addBySubscription");
                                    setInvoiceEditMode(true);
                                    goTo("Add Spend", Pages.ADD_SPEND, {});
                                    getInvoiceDetails(0, categoryPageData?.ID);
                                  }}
                                />
                              )}
                            </Tab.Pane>
                          </Tab.Content>
                        </div>
                      </div>
                    </Tab.Container>
                  </div>
                )}

                {/* </>} */}
              </div>
            )}
          </>
        )}

        <div
          className={`add-edit-tax-rate-modal-wrapper ${
            showAddEditTaxRateModal ? "show" : ""
          }`}
        >
          <AddEditTaxRateModal
            show={showAddEditTaxRateModal}
            hideModal={() => {
              setShowAddEditTaxRateModal(false);
            }}
            taxRateData={taxRateData}
            updateTaxList={updateTaxList}
            deleteTaxRate={deleteTaxRate}
          />
        </div>
      </SlidingModal.Body>

      <SlidingModal.Footer className="p-3 position-sticky w-100 border-top">
        <div
          className="d-flex align-items-center justify-content-between"
          style={{
            visibility: loading ? "hidden" : "visible",
          }}
        >
          {activePage?.type === Pages.HOME && (
            <>
              <div
                className={`f-500 f-grey pointer `}
                style={{
                  visibility:
                    categoryPageData?.IsDefault || loading
                      ? "hidden"
                      : "visible",
                }}
                onClick={() => {
                  setDeleteID(categoryPageData?.ID);
                  setDeleteMessage(
                    "Are you sure you want to delete this category?"
                  );
                  setDeleteType("categoryOrSubcategory");
                  if (categoryPageData?.SubCategory_List?.length > 0) {
                    setLinkedCatDeleteModal(true);
                  } else {
                    setShowDeleteModal(true);
                  }
                }}
              >
                <img
                  alt=""
                  className="mx-2"
                  src="/images/actioncenter/delete.svg"
                />
                {deleting ? "Deleting..." : "Delete"}
              </div>
            </>
          )}
          {activePage?.type === Pages.INVOICE_DATA && (
            <div className="f-500 f-grey">
              {/* <img
                alt=""
                className="mx-2"
                src="/images/actioncenter/delete.svg"
              />
              {deleting ? "Deleting" : "Delete"} */}
            </div>
          )}

          {activePage?.type === Pages.ADD_SPEND &&
            invoicePageData?.IntegrationId === 0 && (
              <div
                className={`f-500 f-grey pointer   `}
                style={{
                  visibility:
                    invoicePageData?.ID > 0 && !loading ? "visible" : "hidden",
                }}
                onClick={() => {
                  if (addSpendSelectedTab === "addBySubscription") {
                    setShowDeleteSubscriptionModal(true);
                  } else {
                    setDeleteID(invoicePageData?.ID);
                    setDeleteType("deleteInvoice");
                    setDeleteMessage(
                      "Are you sure you want to delete this Invoice?"
                    );
                    setShowDeleteModal(true);
                  }
                }}
              >
                <img
                  alt=""
                  className="mx-2"
                  src="/images/actioncenter/delete.svg"
                />
                {deleting ? "Deleting..." : "Delete"}
              </div>
            )}

          {activePage?.type === Pages.ADD_SPEND &&
            invoicePageData?.IntegrationId > 0 && (
              <AttackSurfaceTooltip
                top={true}
                content={`This spend has been synced from ${
                  all_integration_data[invoicePageData?.IntegrationId]
                }`}
              >
                <div className="d-flex align-items-center f-grey">
                  <img
                    src={`/images/attack-surface/integrations/${invoicePageData?.IntegrationId}.webp`}
                    alt=""
                    className="mr-2 rounded-full"
                    width={20}
                    height={20}
                  />
                  {all_integration_data[invoicePageData?.IntegrationId]}
                </div>
              </AttackSurfaceTooltip>
            )}

          {activePage?.type === Pages.SUBCATEGORY && (
            <div
              className={`f-500 f-grey pointer  `}
              style={{
                visibility:
                  loading || subCategoryPageData?.IsDefault === true
                    ? "hidden"
                    : "visible",
              }}
              onClick={() => {
                setDeleteID(subCategoryPageData?.ID);
                setDeleteType("categoryOrSubcategory");
                setDeleteMessage(
                  "Are you sure you want to delete this subcategory?"
                );
                setShowDeleteModal(true);
              }}
            >
              <img
                alt=""
                className="mx-2"
                src="/images/actioncenter/delete.svg"
              />
              {deleting ? "Deleting..." : "Delete"}
            </div>
          )}

          {activePage?.type === Pages.ADD_SPEND &&
          invoicePageData?.IntegrationId > 0 ? (
            <div>
              {invoicePageData?.IsImported === true &&
                invoiceEditMode === false && (
                  <Button
                    className="hg-cancel-btn"
                    onClick={() => {
                      setUnlinkRelinkModalData((prev) => ({
                        ...prev,
                        show: true,
                        linkButtonText: "Unlink",
                        headerText: `${`Unlink ${
                          invoicePageData.InvoiceRef
                        } from ${
                          all_integration_data[invoicePageData?.IntegrationId]
                        }`}`,
                        bodyText: `${`Are you sure you would like to unlink this invoice ${
                          invoicePageData.InvoiceRef
                        } from ${
                          all_integration_data[invoicePageData?.IntegrationId]
                        }?`}`,
                        deleteParams: {
                          linkStatus: false,
                          objId_invoice: invoicePageData?.objId_Invoice,
                        },
                      }));
                    }}
                  >
                    <img
                      src="/images/msp/unlink-icon.svg"
                      alt=""
                      className="mr-1"
                    />{" "}
                    Unlink
                  </Button>
                )}
            </div>
          ) : (
            <div className="d-flex align-items-center justify-content-end">
              <Button className="hg-cancel-btn mr-2" onClick={hideModal}>
                Cancel
              </Button>
              <Button
                className="hg-blue-btn"
                disabled={updatingExchangeRate}
                onClick={async () => {
                  if (activePage?.type === Pages.HOME) {
                    let errors = await checkCategroyPageErrors();

                    if (errors > 0) {
                      let _modal = document.querySelector(
                        ".technology-spend-modal"
                      );
                      let top_error =
                        _modal.getElementsByClassName("input-error")[0];
                      if (top_error) {
                        top_error.scrollIntoView({
                          behavior: "smooth",
                          block: "center",
                          inline: "nearest",
                        });
                      }
                    } else {
                      updateCategory();
                    }
                  }
                  if (activePage?.type === Pages.INVOICE_DATA) {
                  }
                  if (activePage?.type === Pages.ADD_SPEND) {
                    let errors = await checkAddSpendPageErrors(
                      addSpendSelectedTab
                    );

                    if (errors > 0) {
                      let _modal = document.querySelector(
                        ".technology-spend-modal"
                      );
                      let top_error =
                        _modal.getElementsByClassName("input-error")[0];
                      if (top_error) {
                        top_error.scrollIntoView({
                          behavior: "smooth",
                          block: "center",
                          inline: "nearest",
                        });
                      }
                    } else {
                      AddEditInvoice();
                    }
                  }
                  if (activePage?.type === Pages.SUBCATEGORY) {
                    let errors = await checkSubCategoryPageErrors();

                    if (errors > 0) {
                      let _modal = document.querySelector(
                        ".technology-spend-modal"
                      );
                      let top_error =
                        _modal.getElementsByClassName("input-error")[0];
                      if (top_error) {
                        top_error.scrollIntoView({
                          behavior: "smooth",
                          block: "center",
                          inline: "nearest",
                        });
                      }
                    } else {
                      updateSubCategory();
                    }
                  }
                }}
              >
                {saving ? (
                  <>
                    <WhiteLoader width={13} />
                    Saving..
                  </>
                ) : (
                  "Save"
                )}
              </Button>
            </div>
          )}
        </div>
      </SlidingModal.Footer>
      <CustomDeleteModal
        show={unlinkRelinkModalData.show}
        hideModal={() => {
          setUnlinkRelinkModalData((prev) => ({
            ...prev,
            show: false,
          }));
        }}
        deleteButtonClass={"hg-blue-btn"}
        deleteButtonText={unlinkRelinkModalData.linkButtonText}
        deleteHeaderText={unlinkRelinkModalData.headerText}
        deleteBodyText={unlinkRelinkModalData.bodyText}
        deleteFunction={(data) => {
          UpdatelinkingRelinking({
            linkStatus: data.linkStatus,
            objId_invoice: data.objId_invoice,
          });

          setInvoicePageData((prev) => ({
            ...prev,
            IsImported: data.linkStatus,
            UnlinkTime:moment().format("lll")
          }));
        }}
        deleteParams={unlinkRelinkModalData?.deleteParams}
        deleteType={"switchInvoicesTabInMspTechSpend"}
      />
      <AddSubcategoryModal
        show={showCreateSubcategoryModal}
        hideModal={() => setShowCreateSubcategoryModal(false)}
        parentCategoryId={categoryData?.CategoryId}
        colorList={categoryPageData?.SubCategorySpendColor_List}
        refreshData={() => {
          getCategoryDetail(categoryData?.CategoryId);
        }}
      />
      <CreateCategorySpendModal
        show={showCreateCategoryModal}
        hideModal={() => setShowCreateCategoryModal(false)}
      />
      <DeleteModal
        show={showDeleteModal}
        hideModal={() => setShowDeleteModal(false)}
        deleteMessage={deleteMessage}
        deleteType={deleteType}
        deleteCategorySubCategory={() => {
          deleteCategorySubCategory(deleteID);
        }}
        deletePromise={deleteInvoice}
        ID={deleteID}
      />
      <CustomDeleteModal
        show={linkedCatDeleteModal}
        deleteHeaderText={"Delete Confirmation"}
        hideModal={() => {
          setLinkedCatDeleteModal(false);
        }}
        deleteType={"deleteLinkedCategory"}
        deleteFunction={deleteCategorySubCategory}
        deleteParams={{ deleteId: deleteID }}
        customBody={
          <>
            <div className="f-500 my-2">
              This category is linked to other Subcategories. If you delete this
              category then these subcategories and the spend in all categories
              and subcategories will be deleted.
            </div>
            <div className="f-darkgrey mb-2">Linked Subcategories:</div>
            {categoryPageData?.SubCategory_List?.map((subCategory) => {
              return (
                <div className="d-flex align-items-center justify-content-between mb-1">
                  <div className="f-500">
                    {" "}
                    {TrimString(subCategory?.CategoryName, 30)}
                  </div>
                  <div className="f-grey f-500">
                    {" "}
                    {TrimString(subCategory?.TotalAmount_Text, 20)}
                  </div>
                </div>
              );
            })}
          </>
        }
      />
      <CustomDeleteModal
        show={switchInvoiceTabModalData.showModal}
        // deleteHeaderText={"Are you Sure"}
        hideModal={() => {
          setSwitchInvoiceTabModalData((prev) => ({
            ...prev,
            showModal: false,
          }));
        }}
        deleteType={switchInvoiceTabModalData.deleteType}
        deleteFunction={(e) => {
          executeInvoiceSwitchingModal(e);
        }}
        deleteBodyText={"Any unsaved changes will be lost."}
        deleteButtonText={"Proceed"}
        deleteParams={switchInvoiceTabModalData.deleteParams}
        // customBody={<>
        //   <div className="f-500 my-2">
        //     This category is linked to other Subcategories. If you delete this category then these subcategories and the spend in all categories and subcategories will be deleted.
        //   </div>
        //   <div className="f-darkgrey mb-2">
        //     Linked Subcategories:
        //   </div>
        //   {
        //     categoryPageData?.SubCategory_List?.map((subCategory) => {
        //       return (
        //         <div className="d-flex align-items-center justify-content-between mb-1">
        //           <div className="f-500"> {TrimString(subCategory?.CategoryName, 30)}</div>
        //           <div className="f-grey f-500"> {TrimString(subCategory?.TotalAmount_Text, 20)}</div>
        //         </div>
        //       )
        //     })
        //   }

        // </>}
      />
      <AddSupplierModal
        _supplierName={potentialSupplierSearch}
        show={showAddSupplierModal}
        hideModal={() => setShowAddSupplierModal(false)}
        refreshData={(_supplierId) => {
          let SelectedSupplier = {
            PotentialSupplierName: potentialSupplierSearch,
            PotentialSupplierId: _supplierId,
          };
          let updated_data = {
            ...invoicePageData,
          };
          updated_data.PotentialSupplierSelected = SelectedSupplier;
          updated_data.PotentialSupplierDetail_List.unshift(SelectedSupplier);
          setInvoicePageData(updated_data);
          setPotentialSupplierSearch("");
        }}
      />

      <GenerateInvoiceModal
        show={showGenerateInvoiceModal}
        invoicesList={generatedInoiceListBySubscription}
        clientId={clientId}
        invoiceId={invoicePageData?.ID}
        hideModal={() => {
          setShowGenerateInvoiceModal(false);
          goBack();
          refreshData();
        }}
      />

      <DeleteTechSpendSubscription
        show={showDeleteSubscriptionModal}
        hideModal={() => {
          setShowDeleteSubscriptionModal(false);
        }}
        isSaas={isSaas}
        invoiceId={invoicePageData?.ID}
        deleteSuccess={() => {
          goBack();
          refreshData();
        }}
      />
    </SlidingModal>
  );
};

export default TechnologySpendModalV2;

//msp-syncing-icon.svg

function searchSpendInvoices(
  arr,
  key = "",
  searchString = "",
  IsAutomatic,
  subscriptionInvoice = false
) {
  let length_of_filtered_array = arr;
  length_of_filtered_array = arr
    ?.filter((_item) => _item["IsImported"] === IsAutomatic)
    ?.filter((item) => item[key].includes(searchString))
    ?.filter((__item) =>
      subscriptionInvoice ? __item.IsSubscription : !__item.IsSubscription
    );
  if (length_of_filtered_array?.length > 0) {
    return true;
  } else {
    return false;
  }
}

export function handleDateForInvoice(_date) {
  if (_date === "0001-01-01T00:00:00") {
    return "";
  } else if (_date?.split("/")?.length === 3) {
    return _date;
  } else if (moment(_date).format("MM/DD/YYYY")?.split("/")?.length > 2) {
    // let value = moment(_date).format("MM/DD/YYYY")?.split("/");
    // let month = value[0];
    // let date = value[1];
    // let year = value[2];
    // let newDate = `${date}/${month}/${year}`;
    // if (date > 12) {
    //   return newDate;
    // }
    return moment(_date).format("DD/MM/YYYY");
  } else {
    return _date;
  }
}

const NoSpendFoundUI = () => {
  return (
    <div className="my-5 d-flex flex-column align-items-center justify-content-center">
      <div className="w-fit-content">
        <img src="/images/msp/msp-spend-invoice-icon.svg" alt="" className="" />
      </div>
      <div className="w-fit-content f-500 mt-2 d-grey">No spend found</div>
    </div>
  );
};
const ManualNoSpendFoundUI = ({ onClickAdd }) => {
  return (
    <div className="my-5 d-flex flex-column align-items-center justify-content-center">
      <div className="w-fit-content">
        <img src="/images/msp/msp-spend-invoice-icon.svg" alt="" className="" />
      </div>
      <div className="w-fit-content f-500 mt-2 d-grey">No spend found</div>
      <div
        className="w-fit-content f-500 mt-2 f-blue pointer"
        onClick={onClickAdd}
      >
        Add Spend
      </div>
    </div>
  );
};
const NoSubscriptionSpendFoundUI = ({ onClickAdd }) => {
  return (
    <div className="my-5 d-flex flex-column align-items-center justify-content-center">
      <div className="w-fit-content">
        <img src="/images/msp/msp-spend-invoice-icon.svg" alt="" className="" />
      </div>
      <div className="w-fit-content f-500 mt-2 d-grey">
        {/* No spend found */}
        No invoices yet
      </div>
      <div
        // className="w-fit-content f-500 mt-2 f-blue pointer"
        className="w-fit-content mt-2 f-darkgrey pointer"
        // onClick={onClickAdd}
      >
        {/* Add Spend */}
        There are no autogenerated invoices yet
      </div>
    </div>
  );
};

const AutomaticNoSpendFoundUI = ({ type = "category" }) => {
  return (
    <div className="my-5 d-flex flex-column align-items-center justify-content-center">
      <div className="w-fit-content">
        <img src="/images/msp/msp-spend-invoice-icon.svg" alt="" className="" />
      </div>
      <div className="w-fit-content f-500 mt-2 f-black">No spend found</div>
      <div className="w-fit-content f-500 mt-2 f-darkgrey text-center">
        No spend has been found in this <br /> {type} in your PSA
      </div>
    </div>
  );
};
const NoAutomaticGeneratedInvoiceUI = () => {
  return (
    <div className="my-5 d-flex flex-column align-items-center justify-content-center">
      <div className="w-fit-content">
        <img src="/images/msp/msp-spend-invoice-icon.svg" alt="" className="" />
      </div>
      <div className="w-fit-content f-500 mt-2 f-black">No invoices yet</div>
      <div className="w-fit-content f-500 mt-2 f-darkgrey text-center">
        There are no autogenerated invoices yet
      </div>
    </div>
  );
};
const AutomaticSpendSyncingUI = () => {
  return (
    <div className="my-5 d-flex flex-column align-items-center justify-content-center">
      <div className="w-fit-content">
        <img src="/images/msp/syncing-icon.svg" alt="" className="" />
      </div>
      <div className="w-fit-content f-500 mt-2 f-grey f-500">
        Data is syncing
      </div>
      <div className="w-fit-content f-500 mt-2 f-darkgrey text-center">
        Please come back later
      </div>
    </div>
  );
};

function convertDateFormat(inputDate) {
  return inputDate;
  // Split the input date string into components
  const dateComponents = inputDate.split("/");

  // Ensure the input date has three components (month, day, year)
  if (dateComponents.length !== 3) {
    return handleDateForInvoice(inputDate);
  }

  // Extract day, month, and year from the components
  const month = dateComponents[0];
  const day = dateComponents[1];
  const year = dateComponents[2];

  // Create a new date string in the DD/MM/YYYY format
  const formattedDate = `${day}/${month}/${year}`;

  return formattedDate;
}

function convertToCurrencyFormat(value, currencySymbol) {
  // Check if the value is negative
  const isNegative = value < 0;

  // Convert value to absolute value
  const absoluteValue = Math.abs(value);

  // Create an Intl.NumberFormat instance for currency formatting
  const formatter = new Intl.NumberFormat("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  // Format the absolute value with currency symbol and group separators
  const formattedValue = `${currencySymbol} ${formatter.format(absoluteValue)}`;

  // Prepend '-' if the value was negative
  return isNegative ? `-${formattedValue}` : formattedValue;
}

export function _DeleteInvoiceItem(_invoiceItemId) {
  return new Promise((resolve, reject) => {
    const authTokens = JSON.parse(localStorage.getItem("tokens"));

    getAPIData(`${629}`, authTokens, {
      optionEnum1: _invoiceItemId,
      optionEnum2: 0,
    })
      .then((response) => {
        if (response?.Success) {
          resolve(response);
        } else {
          reject("Something went wrong");
        }
      })
      .catch((err) => {
        console.log(err);
        reject("Something went wrong");
      });
  });
}

function formatDateDDMMYYY(date) {
  // Get day, month, and year
  let day = date.getDate();
  let month = date.getMonth() + 1; // Months are zero indexed
  let year = date.getFullYear();

  // Add leading zeros if necessary
  if (day < 10) {
    day = "0" + day;
  }
  if (month < 10) {
    month = "0" + month;
  }

  // Return formatted date
  return `${day}/${month}/${year}`;
}
