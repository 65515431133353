const AddToolBtn = ({ isAdding, onClick, value }) => {
  return (
    <button disabled={isAdding} className="select-tool-btn result-add-btn" onClick={onClick}>
      <img
        alt=""
        src="/images/attack-surface/add-tool-icon-blue.svg"
        className="mr-2"
        width={11}
        height={11}
        style={{ top: '-1px', position: 'relative', fill: 'red' }}
      />
      <span>
        {`Add "${value}"`}
      </span>
    </button>
  );
}

export default AddToolBtn;
