import React, { Component, Fragment } from "react";
import {
  Form,
  FormGroup,
  Button,
  Container,
  Row,
  Col,
  Alert,
  InputGroup,
  Spinner,
} from "react-bootstrap";
import { Link, Redirect } from "react-router-dom";
import "../Login/Login.css";
import { AlertDialog } from "../Alert/Alert";

class ForgottenPasswordForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      emailSent: false,
      email: 0,
      error: false,
      isRequestSend: false,
    };
    this.sendPasswordResetEmail = this.sendPasswordResetEmail.bind(this);
  }

  async sendPasswordResetEmail(event) {
    event.preventDefault();

    var urlencoded = new URLSearchParams();
    urlencoded.append("emailAddress", this.state.email);
    urlencoded.append("baseCallingUrl", "http://localhost:3000");

    var requestOptions = {
      method: "POST",
      body: urlencoded,
      redirect: "follow",
    };

    this.setState({ isRequestSend: true });

    const data = await fetch(
      `${process.env.REACT_APP_API_BASE_URL}/ObjectraAPI/ResetPassword`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        this.setState({ isRequestSend: false });
        if (data.error) {
          this.setState({
            error: {
              type: "danger",
              heading: "Error with sending email",
              body: "An error occured when sending an email, please try again",
            },
          });
        } else {
          if (!this.state.emailSent) {
            if (data.Success) {
              this.setState({ emailSent: true });
              // this.setState({error: <AlertDialog type="success" heading="Reset Password Succesful" body="An email has been sent to your account. To reset the password click on the link provided in the email and follow the instructions!" />});
              this.setState({
                error: {
                  type: "success",
                  heading: "Reset Password Succesful",
                  body: "An email has been sent to your account. To reset the password click on the link provided in the email and follow the instructions!",
                },
              });
            } else {
              this.setState({
                error: {
                  type: "danger",
                  heading: "Reset Password Failed",
                  body: data.ExMessage,
                },
              });
            }
          }
        }
      })
      .catch((ex) => {
        this.setState({
          error: {
            type: "danger",
            heading: "Error with sending email",
            body: "An error occured when sending an email, please try again",
          },
        });
      });
  }
  render() {
    // if(this.state.emailSent){
    //     return(
    //         <Redirect to="/Login" />
    //     )
    // }else{
    return (
      <div className="loginBg">
        {this.state.error != false ? (
          <div className="alertDialog">
            <Alert
              variant={this.state.error.type}
              onClose={() => this.setState({ error: false })}
              dismissible
            >
              <Alert.Heading>{this.state.error.heading}</Alert.Heading>
              <p>{this.state.error.body}</p>
            </Alert>
          </div>
        ) : (
          ""
        )}
        <Row className="innerLoginWrapper">
          <Col md={{ span: 4, offset: 1 }}>
            <div className="wrapperContainer paddingTopWrap">
              <Row
                className="align-items-center"
                style={{ paddingTop: "40px" }}
              >
                <Col md={12} className="text-center">
                  <div>
                    <img src="/images/logo-large.svg" className="icon"></img>
                    {/* <p style={{ paddingTop: "4em" }} className="pt-3 makingcyber">Making Cyber Simple</p> */}
                  </div>
                </Col>
              </Row>
              <Row style={{ paddingTop: "1.5em" }}>
                <Col md={12} className="text-center pt-5 pb-4">
                  <div>
                    <h3 className="lgn_HeaderText2">Recovery Password</h3>
                    <p className="lgnGreyText pt-3">
                      Please enter an email from your account. <br></br>We will
                      send you password recovery instructions
                    </p>
                  </div>
                </Col>
              </Row>
              <Row
                className="align-items-center"
                style={{ paddingTop: "0.1em" }}
              >
                <Col md={10} className="mx-auto">
                  <Form
                    className="userLoginForm"
                    onSubmit={this.sendPasswordResetEmail}
                    autoComplete="none"
                  >
                    <Form.Group controlId="loginEmail">
                      <InputGroup className="mr-sm-2">
                        <InputGroup.Prepend>
                          <InputGroup.Text>Email: </InputGroup.Text>
                        </InputGroup.Prepend>
                        <Form.Control
                          className="inputControl"
                          size="lg"
                          type="email"
                          placeholder="Enter Email Here"
                          onChange={(event) =>
                            this.setState({ email: event.target.value })
                          }
                        />
                      </InputGroup>
                    </Form.Group>
                    {/* <Form.Group controlId="submitBtn"> */}
                    <div className="mt-4">
                      <Button
                        className="form-control btnControl"
                        size="lg"
                        variant="blue"
                        type="submit"
                      >
                        {this.state.isRequestSend ? (
                          <span>
                            <Spinner
                              animation="border"
                              variant="light"
                              size="sm"
                            />{" "}
                            Resetting password
                          </span>
                        ) : (
                          "Reset password"
                        )}
                      </Button>
                    </div>
                    {/* </Form.Group> */}

                    <div className="text-center pt-4">
                      <Link
                        to="/login"
                        className="lgnGreyText"
                        style={{ "align-self": "center" }}
                      >
                        Back
                      </Link>
                    </div>
                  </Form>
                </Col>
              </Row>

              <Row className="paddingTop">
                <Col md={12} className="pt-5 text-center">
                  {/* <div>
                    <p className="bytext">
                      by
                      <img
                        height="25px"
                        className="pl-2 "
                        src="/images/small.svg"
                      ></img>
                    </p>
                  </div> */}
                </Col>
              </Row>

              <Row className="responsivePositon">
                <Col md={10} className="pt-4 pb-4 mx-auto ">
                  <div>
                    <p className="lgnGreyText terms">
                      By clicking “Continue” above, you acknowledge that you
                      have read and understood, and agree to M3 Networks Limited
                      &nbsp;
                      <a
                        className="lgnLinks"
                        href="https://www.highground.io/highground-terms-and-conditions/"
                        target="_blank"
                      >
                        Terms & Conditions
                      </a>
                      &nbsp; and
                      <a
                        href="https://www.highground.io/privacy_policy/"
                        target="_blank"
                        className="lgnLinks"
                      >
                        &nbsp; Privacy Policy
                      </a>
                      .
                    </p>{" "}
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
          <Col md={7} className="imageLoginWrapper">
            <img src="/images/login.svg" width="100%" />
          </Col>
        </Row>
      </div>
    );
    // }
  }
}
export default ForgottenPasswordForm;
