// import { fromJS } from "immutable";

import { MENU_COLLAPSE } from "./constants";

export const initState = {
  menuCollapse: false,
};

const GlobalReducer = (state = initState, action) => {
  switch (action.type) {
    case MENU_COLLAPSE:
      return { ...state, menuCollapse: action.data };

    default:
      return state;
  }
};

export default GlobalReducer;
