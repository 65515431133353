import React, { useState, useEffect, Fragment } from "react";
import { Row, Col, Button } from "react-bootstrap";
import { getAPIData, postRequest } from "../../../../Utils/commonApi";
import { useAuth } from "../../../context/Auth";
import { CallToast } from "../../../PriceCalculator/modals/CustomToast";

import "../actionCentreModals.scss";
import Loader from "../../../Common/loader";
import CompanySettingsDropdown from "../../../settingsV2/companySettings/companysettingsdrodown/CompanySettingsDropdown";
const Connection = ({ setModalTab, closeModal, mappingType }) => {
  const { authTokens } = useAuth();

  const [loading, setLoading] = useState(false);

  const [modalData, setModalData] = useState({});
  const [boardList, setBoardList] = useState([]);
  const [dataToPost, setDataToPost] = useState();
  const [saving, setSaving] = useState(false);
  const [loadingStatus, setLoadingStatus] = useState(false);

  const [selectedBoard, setSelectedBoard] = useState({
    Item1: 1236549879,
    Item2: "Choose...",
  });

  useEffect(() => {
    setLoading(true);
    getAPIData("255", authTokens, { optionStrEnum1: "0" }).then((response) => {
      if (response?.mr?.Success) {
        setLoading(false);
        setBoardList(response);
        if (response?.MappingTypeVMs?.[0]?.ExternalField_Selected) {
          setSelectedBoard(
            response?.MappingTypeVMs?.[0]?.ExternalField_Selected
          );
          getStatusList(
            response?.MappingTypeVMs?.[0]?.ExternalField_Selected?.Item1
          );
        } else {
          setLoading(false);
        }
      } else {
        CallToast("Something went wrong", false, false, "v2style");
      }
    });
  }, []);

  function getStatusList(statusId) {
    setLoadingStatus(true);
    getAPIData("255", authTokens, {
      optionStrEnum1: statusId?.toString(),
    }).then((response) => {
      setLoadingStatus(false);
      setLoading(false);
      if (response?.mr?.Success) {
        setModalData(response);
      } else {
        CallToast("Something went wrong", false, false, "v2style");
      }
    });
  }

  function SubmitData(data) {
    setSaving(true);
    postRequest(authTokens, "CreateActionMapping", {
      Mapping: data,
      accessKey: JSON.parse(localStorage.getItem("user_MessageObj")),
    })
      .then((response) => {
        setSaving(false);
        if (response?.Success) {
          closeModal();
          CallToast("Updated successfully", false, false, "v2style");
        } else {
          CallToast("Something went wrong", false, false, "v2style");
        }
      })
      .catch((err) => {
        setSaving(false);
      });
  }

  return (
    <Row
      className="m-0 h-100"
      style={{
        overflowY: "hidden",
      }}
    >
      {loading && (
        <Col
          md={12}
          className="d-flex align-items-center justify-content-center"
          style={{
            position: "absolute",
            top: "40%",
            left: 0,
            right: 0,
            margin: "auto",
          }}
        >
          <Loader />
        </Col>
      )}
<div className="w-100 hide-scrollbar"

style={{height:"35.09rem"}}>


      <Col
        md={12}
        className={`p-0 mt-1  ${loading ? "d-none" : ""} `}
        style={{
          height: "9%",
        }}
      >
        <div className="mt-2 bg-white position-relative"
        style={{zIndex:5}}>
          {/* <div className="d-flex align-items-center justify-content-between mb-3">
            <div className="d-flex align-items-center justify-content-between w-50">
              <div className="">
                <span className="titletextgrey ml-2">Board</span>
              </div>
            </div>

            <div className="w-50 titletextgrey pl-4">
              
            </div>
          </div> */}

          <Fragment>
            <div className="d-flex align-items-center justify-content-between mb-3">
              <div className="d-flex align-items-center justify-content-between w-50">
                <div className="">
                  <span className="titletext ml-2">Select Project/Board</span>
                </div>

                <div className="d-flex align-items-center crsrpoint">
                  <img
                    alt=""
                    src="/images/actioncenter/arrow2.svg"
                    height="14"
                    className="ml-2"
                  />
                </div>
              </div>

              <div className="w-50 search_dropdown pl-4">
                <CompanySettingsDropdown
                  fieldId={"Item1"}
                  fieldValue={"Item2"}
                  selected={selectedBoard}
                  placeholder="N/A"
                  //  showid={true}
                  onClick={(id, val) => {
                    setSelectedBoard({ Item1: id, Item2: val });
                    getStatusList(id);
                  }}
                  data={boardList?.MappingTypeVMs?.[0]?.ExternalField_List}
                />
              </div>
            </div>
            <hr className="" />
          </Fragment>
        </div>
      </Col>
      {loadingStatus ? (
        <div className="loader d-flex align-items-center justify-content-center h-75 w-100">
          <Loader />
        </div>
      ) : (
        <Col
          md={12}
          className={`p-0 mt-1 hide-scrollbar  ${loading ? "d-none" : ""}  ${
            modalData?.MappingTypeVMs?.length > 0 ? "" : "d-none"
          } `}
          style={{
            overflowY: "scroll",
            maxHeight: "520px",
            height: "88%",
          }}
        >
          <div className="mt-3">
            <div className="f-500 ml-1">Select Status</div>
            <div className="d-flex align-items-center justify-content-between mb-3">
              <div className="d-flex align-items-center justify-content-between w-50">
                <div className="">
                  <span className="titletextgrey ml-2">HighGround</span>
                </div>
              </div>

              <div className="w-50 titletextgrey pl-4">
                {" "}
                {modalData?.Title}{" "}
              </div>
            </div>

            {modalData?.MappingTypeVMs?.map((val, i) => (
              <Fragment key={i}>
                <div className="d-flex align-items-center justify-content-between mb-3">
                  <div className="d-flex align-items-center justify-content-between w-50">
                    <div className="">
                      <span className="titletext ml-2">
                        {val?.HighgroundField?.Item2}
                      </span>
                    </div>

                    <div className="d-flex align-items-center crsrpoint">
                      <img
                        alt=""
                        src="/images/actioncenter/arrow2.svg"
                        height="14"
                        className="ml-2"
                      />
                    </div>
                  </div>

                  <div className="w-50 search_dropdown pl-4">
                    <CompanySettingsDropdown
                      fieldId={"Item1"}
                      fieldValue={"Item2"}
                      selected={val?.ExternalField_Selected}
                      placeholder="N/A"
                      //  showid={true}
                      onClick={(id, val) => {
                        let updated_data = { ...modalData };
                        updated_data.MappingTypeVMs[i].ExternalField_Selected =
                          {
                            Item1: id,
                            Item2: val,
                          };
                        setModalData(updated_data);
                        // setPost({ id, category: val });

                        let dataToPost = {
                          Title: updated_data.Title,
                          ActionMappingType: [
                            {
                              Name: "Setting",
                              ExternalField: {
                                Item1: selectedBoard?.Item1,
                                Item2: selectedBoard?.Item2,
                              },
                            },
                            ...updated_data?.MappingTypeVMs?.map((item) => {
                              return {
                                Name: item?.Name,
                                HighgroundField: item?.HighgroundField,
                                ExternalField: item?.ExternalField_Selected,
                              };
                            }),
                          ],
                        };

                        setDataToPost(dataToPost);
                      }}
                      data={val?.ExternalField_List}
                    />
                  </div>
                </div>
                <hr className="m-0 mb-3" />
              </Fragment>
            ))}
          </div>
        </Col>
      )}


      <div
        style={{
          padding: "0px",
        }}
      />
      </div>
      <div className="modal_custom_footer bg-white">
        <div
          className="crsrpoint mt-2"
          style={{
            visibility: "hidden",
          }}
        >
          <img alt="" src="/images/actioncenter/reset.svg" height="15" />
          <span style={{ color: "#616778" }}>&nbsp; Delete Integration</span>
        </div>
        <div>
          <Button
            variant="primary"
            type="submit"
            className="float-right securityBtnmodal"
            onClick={() => {
              SubmitData(JSON.stringify(dataToPost));
            }}
            disabled={modalData?.MappingTypeVMs?.length > 0 ? false : true}
          >
            {saving ? "Saving.." : "Save"}
          </Button>
          <Button
            variant="primary"
            type="submit"
            className="float-right action_cancel_btn mr-2"
            onClick={closeModal}
          >
            Cancel
          </Button>
        </div>
      </div>
    </Row>
  );
};

export default Connection;
 