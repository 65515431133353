import React, { useEffect, useState } from "react";
import { Form, Button, Spinner } from "react-bootstrap";
import { CommentAction } from "semantic-ui-react";
import "semantic-ui-css/semantic.min.css";
import { getAPIData, getRequestOptions } from "../../../Utils/commonApi";
import { useAuth } from "../../context/Auth";
import { BsCheck } from "react-icons/bs";

const ResetComplianceForm = () => {
  const { authTokens } = useAuth();
  const [data, setData] = useState({
    resetAll: false,
    cyberEssentials: false,
    CEPlan: false,
    CEAudits: false,
    incidentResponsePlan: false,
    IRPlan: false,
    IRReviews: false,
    InfoSecPolicies: false,
    RiskAssessments: false,
    AnnualCyberReviews: false,
    TestRecoveries: false,
  });
  const [formState, setFormState] = useState("default");

  function onChange(action) {
    const id = action.target.id;
    let temp = data;
    let result;

    if (id === "cyberEssentials") {
      result = !temp["cyberEssentials"];
      if (temp["CEPlan"] !== result) document.getElementById("CEPlan").click();
      if (temp["CEAudits"] !== result)
        document.getElementById("CEAudits").click();
      temp["CEPlan"] = result;
      temp["CEAudits"] = result;
      temp["cyberEssentials"] = result;
    } else if (id === "incidentResponsePlan") {
      result = !temp["incidentResponsePlan"];
      if (temp["IRReviews"] !== result)
        document.getElementById("IRReviews").click();
      if (temp["IRPlan"] !== result) document.getElementById("IRPlan").click();
      temp["IRPlan"] = result;
      temp["IRReviews"] = result;
      temp["incidentResponsePlan"] = result;
    } else if (id === "resetAll") {
      var outcome = !temp["resetAll"];
      Object.keys(temp).map((element) => {
        if (data[element] != outcome) {
          document.getElementById(element).click();
        }
        data[element] = outcome;
      });
    } else {
      // console.log(temp["CEPlan"], temp["CEAudits"], temp["cyberEssentials"])
      // if ((temp["CEPlan"] === true && temp["CEAudits"] === true && temp["cyberEssentials"] == false) ) {
      //     document.getElementById("cyberEssentials").click();
      // }
      // if ((temp["IRReviews"] === true && temp["IRPlan"] === true && !temp["incidentResponsePlan"] == false)) {
      //     document.getElementById("incidentResponsePlan").click();

      // }
      temp[id] = !temp[id];

      if (
        temp["CEPlan"] == true &&
        temp["CEAudits"] == true &&
        temp["cyberEssentials"] == false
      )
        document.getElementById("cyberEssentials").click();
      if (
        temp["IRReviews"] == true &&
        temp["IRPlan"] == true &&
        temp["incidentResponsePlan"] == false
      ) {
        document.getElementById("incidentResponsePlan").click();
      }
    }

    console.log(temp);
    setData(temp);
  }

  async function postForm() {
    let tempData = data;
    let newData = {};
    Object.keys(tempData)
      .filter(
        (key) =>
          key != "resetAll" &&
          key != "cyberEssentials" &&
          key != "incidentResponsePlan"
      )
      .map((element) => (newData[element] = tempData[element]));
    setFormState("loading");
    const result = await fetch(
      `${process.env.REACT_APP_API_BASE_URL}/CC_API/ResetDemoData`,
      getRequestOptions(authTokens, "POST", newData)
    ).then((response) => response.json());

    setTimeout(() => {
      setFormState("complete");
      setTimeout(() => {
        setFormState("default");
      }, 500);
    }, 300);
  }

  return (
    <div
      className="companyContainer"
      style={{ position: "relative", overflow: "hidden" }}
    >
      <div className="collapsabileContainer companyModal">
        <Form style={{ padding: "1em" }}>
          <Form.Group>
            <Form.Check
              type="switch"
              id="resetAll"
              label={"Reset All Data"}
              onChange={onChange}
            />
            <div style={{ padding: ".2em .7em" }} className="checkboxContainer">
              <Form.Check
                type="switch"
                id="cyberEssentials"
                label={"Cyber Essentials"}
                onChange={onChange}
              />
              <div style={{ padding: "0 .7em" }}>
                <Form.Check
                  type="switch"
                  id="CEPlan"
                  label={"CE Plan"}
                  defaultChecked={data["CEPlan"]}
                  onChange={onChange}
                />
                <Form.Check
                  type="switch"
                  id="CEAudits"
                  label={"CE Audits"}
                  defaultChecked={data["CEAudit"]}
                  onChange={onChange}
                />
              </div>
              <hr />

              <Form.Check
                type="switch"
                id="incidentResponsePlan"
                label={"Incident Response Plan"}
                onChange={onChange}
                defaultChecked={data["incidentResponsePlan"]}
              />
              <div style={{ padding: "0 .7em" }}>
                <Form.Check
                  type="switch"
                  id="IRPlan"
                  label={"IR Plan"}
                  onChange={onChange}
                  defaultChecked={data["IRPlan"]}
                />
                <Form.Check
                  type="switch"
                  id="IRReviews"
                  label={"IR Reviews"}
                  onChange={onChange}
                  defaultChecked={data["IRReviews"]}
                />
              </div>
              <hr />

              <Form.Check
                type="switch"
                id="InfoSecPolicies"
                label={"InfoSec Policies"}
                onChange={onChange}
              />
              <hr />

              <Form.Check
                type="switch"
                id="RiskAssessments"
                label={"Risk Assessments"}
                onChange={onChange}
              />
              <hr />

              <Form.Check
                type="switch"
                id="AnnualCyberReviews"
                label={"Annual Cyber Reviews"}
                onChange={onChange}
              />
              <hr />

              <Form.Check
                type="switch"
                id="TestRecoveries"
                label={"Test Recoveries"}
                onChange={onChange}
              />
            </div>
          </Form.Group>
          <Button
            style={{
              width: "100%",
              backgroundColor: "#008FD4",
              padding: ".5em 0",
              marginTop: "1.5em",
            }}
            onClick={postForm}
          >
            Reset
          </Button>
        </Form>
      </div>
      {formState !== "default" ? (
        <div
          style={{
            position: "absolute",
            width: "calc(100% - 1em)",
            height: "calc(98.9% - 1em)",
            background: "rgba(0,0,0,0.1)",
            top: "0.6em",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            {formState === "loading" ? (
              <div
                style={{
                  background: "#F6F8FB",
                  padding: "2em",
                  borderRadius: "16px",
                  borderStyle: "solid",
                  borderColor: "#EBECF1",
                  borderWidth: "1px",
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                  width: "170px",
                  height: "160px",
                }}
              >
                <Spinner animation="border" variant="primary" />
                <p
                  style={{
                    color: "#101010",
                    marginTop: "2em",
                    textAlign: "center",
                  }}
                >
                  Data is reseting...
                </p>
              </div>
            ) : (
              <div
                style={{
                  background: "#40D88E",
                  padding: "2em",
                  borderRadius: "16px",
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                  width: "170px",
                  height: "160px",
                }}
              >
                <BsCheck size={50} color="white" />
                <p
                  style={{
                    color: "#FFFFFF",
                    marginTop: "1em",
                    textAlign: "center",
                  }}
                >
                  Data was reset
                  <br />
                  successfully
                </p>
              </div>
            )}
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default ResetComplianceForm;
