import React, { useEffect, useRef, useState } from "react";
import { Button, Dropdown, Form, Modal, Nav, Tab } from "react-bootstrap";
// import "../secOps.scss";
import "../MSPv2/secOps/secOps.scss";
import HighgroundDatePicker from "../test/TestComponent";
// import {
//   getAPIData,
//   postData,
//   postJsonData,
//   postRequest,
// } from "../../../../Utils/commonApi";
// import { useAuth } from "../../../context/Auth";
// import { CallToast } from "../PriceCalculator/modals/CustomToast";
import Loader from "../Common/loader";
import axios from "axios";
import { UploadFileCircularProgress } from "../MSPv2/secOps/SecOps";
import moment from "moment";
import {
  ContributorGetAPIData,
  ContributorPostData,
  getAPIData,
  postData,
} from "../../Utils/commonApi";
import { useAuth } from "../context/Auth";
import { CallToast } from "../PriceCalculator/modals/CustomToast";
import CompanySettingsDropdown from "../settingsV2/companySettings/companysettingsdrodown/CompanySettingsDropdown";
import { GreyLoader } from "../Theme/APILoaders";
const AddFrameworkModal = ({
  show,
  hideModal,
  refreshData,
  frameworkId,
  objId,
  frameworkItem,
  headerName,
  questionId,
  status,
  AddOrEdit,
  isContributorView,
}) => {
  const uploadRef = React.useRef();
  const { authTokens } = useAuth();
  const [saving, setSaving] = useState(false);

  const [loading, setLoading] = useState(false);
  // const [auditLoading, setAuditLoading] = useState(false);
  const [auditDetails, setAuditDetails] = useState();
  const [frameworksData, setFrameworksData] = useState([]);
  const [selectedFrameworkItem, setSelectedFrameworkItem] = useState();
  const [CyberFrameworkAuditId, setCyberFrameworkAuditId] = useState();

  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploadingFileList, setUploadingFileList] = useState([]);

  // const [uploadedFile, setUploadedFile] = useState(null);

  // const [dropdownLoader, setDropdownLoader] = useState({
  //   typeLoader: false,
  //   bodyLoader: false,
  // });
  const [updateMode, setUpdateMode] = useState(false);

  async function refreshModalData() {
    // setSelectedFrameworkItem({});
    setAuditDetails(null);

    setUploadingFileList([]);
  }
  useEffect(() => {
    if (show) {
      setSelectedFrameworkItem({});
      setAuditDetails(null);
      setLoading(true);
      getFrameworksData();
      if (frameworkItem) {
        setSelectedFrameworkItem(frameworkItem);
        setCyberFrameworkAuditId(frameworkItem.CyberFrameworkAudit?.objId);
        getAuditDetails(frameworkItem);
      }
      console.log("check", selectedFrameworkItem, frameworkItem);
    } else {
      setSelectedFrameworkItem({});
    }
  }, [show]);

  useEffect(() => {
    if (show && selectedFrameworkItem?.FrameworkId) {
      refreshModalData();
      // getFrameworksData();
      if (selectedFrameworkItem?.FrameworkId) {
        // setAuditDetails();
        if (selectedFrameworkItem?.CyberFrameworkAudit?.objId) {
          // setAuditLoading(true);
          getAuditDetails(selectedFrameworkItem);
          setUpdateMode(false);
        } else {
          setUpdateMode(true);
          updateAuditDetails(true, true);
        }
      }
    }
  }, [show, selectedFrameworkItem]);

  async function checkValidation() {
    
    let errors = 0;
    let updated_data = { ...auditDetails };
    try {
      if (!auditDetails || selectedFrameworkItem.Abbreviation.trim() == "") {
        errors = errors + 1;

        updated_data.AbbreviationError = true;
      }

      setAuditDetails(updated_data);
    } catch (err) {
      console.log(err, "errrrr");
    }

    return errors;
  }

  async function getFrameworksData(frameworkMode = 0) {
    frameworkMode =
      status == "Cyber Security" ? 1 : status == "Data Privacy" ? 2 : 0;
    if (isContributorView) {
      await ContributorGetAPIData("710", authTokens, {
        optionEnum1: 0,
        optionEnum2: questionId ?? 0,
        optionEnum3: frameworkMode,
        optionEnum4: AddOrEdit == "Add" ? 2 : AddOrEdit == "Edit" ? 1 : 0,
      })
        .then((response) => {
          if (response?.mr?.Success) {
            setFrameworksData(response?.FrameworkGroupList);
          } else {
            CallToast("Something went wrong", false, false, "v2style");
          }
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          CallToast("Something went wrong", false, false, "v2style");
          setLoading(false);
        });
    } else {
      await getAPIData("710", authTokens, {
        optionEnum1: 0,
        optionEnum2: questionId ?? 0,
        optionEnum3: frameworkMode,
        optionEnum4: AddOrEdit == "Add" ? 2 : AddOrEdit == "Edit" ? 1 : 0,
      })
        .then((response) => {
          if (response?.mr?.Success) {
            setFrameworksData(response?.FrameworkGroupList);
          } else {
            CallToast("Something went wrong", false, false, "v2style");
          }
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          CallToast("Something went wrong", false, false, "v2style");
          setLoading(false);
        });
    }
  }

  async function getAuditDetails(_selectedFrameworkItem) {
    if (isContributorView) {
      await ContributorGetAPIData("711", authTokens, {
        optionEnum1: _selectedFrameworkItem?.CyberFrameworkAudit?.objId ?? 0,
        optionEnum2: _selectedFrameworkItem?.FrameworkId ?? 0,
        optionEnum3: questionId ?? 0,
      })
        .then((response) => {
          if (response?.mr?.Success) {
            setAuditDetails(response);
          } else {
            CallToast("Something went wrong", false, false, "v2style");
          }
        })
        .catch((err) => {
          console.log(err);
          CallToast("Something went wrong", false, false, "v2style");
        });
    } else {
      await getAPIData("711", authTokens, {
        optionEnum1: _selectedFrameworkItem?.CyberFrameworkAudit?.objId ?? 0,
        optionEnum2: _selectedFrameworkItem?.FrameworkId ?? 0,
        optionEnum3: questionId ?? 0,
      })
        .then((response) => {
          if (response?.mr?.Success) {
            setAuditDetails(response);
          } else {
            CallToast("Something went wrong", false, false, "v2style");
          }
        })
        .catch((err) => {
          console.log(err);
          CallToast("Something went wrong", false, false, "v2style");
        });
    }
  }

  async function updateAuditDetails(_enabled = true, updateMo = true) {
    setSaving(true);
    if (isContributorView) {
      await ContributorPostData(authTokens, "CreateUpdateAudit", {
        accessKey: JSON.parse(localStorage.getItem("user_MessageObj")),
        FrameworkId: selectedFrameworkItem?.FrameworkId,
        objId: auditDetails?.objId ?? 0,
        DoYouPerformAudits: JSON.stringify(
          auditDetails?.DoYouPerformAudits ?? {}
        ),
        AuditType: JSON.stringify(auditDetails?.AuditType ?? {}),
        isEnable: _enabled,
        IsInProgress: auditDetails?.IsInProgress ? 1 : 0,
        ExpectedCompletionDate: auditDetails?.ExpectedCompletionDate,
        OverView: auditDetails?.OverView ?? "",
        LastAudit: auditDetails?.LastAudit,
        NextAudit: auditDetails?.NextAudit,
        AuditBy: auditDetails?.AuditBy?.trim(),
        objId_SendFormQuestionnaire: questionId ?? 0,
      })
        .then((response) => {
          CallToast("Updated successfully", false, false, "v2style");

          if (updateMo) {
            // getFrameworksData();
            getAuditDetails({
              CyberFrameworkAudit: {
                objId: response.EntityId,
              },
              FrameworkId: selectedFrameworkItem?.FrameworkId,
            });
            setCyberFrameworkAuditId(response.EntityId);
          } else {
            hideModal();
            if (refreshData) {
              refreshData();
            }
          }
          setSaving(false);
          refreshModalData();
        })
        .catch((Err) => {
          console.log(Err);
          CallToast("Something went wrong", false, false, "v2style");
        });
    } else {
      await postData(authTokens, "CreateUpdateAudit", {
        accessKey: JSON.parse(localStorage.getItem("user_MessageObj")),
        FrameworkId: selectedFrameworkItem?.FrameworkId,
        objId: auditDetails?.objId ?? 0,
        DoYouPerformAudits: JSON.stringify(
          auditDetails?.DoYouPerformAudits ?? {}
        ),
        AuditType: JSON.stringify(auditDetails?.AuditType ?? {}),
        isEnable: _enabled,
        IsInProgress: auditDetails?.IsInProgress ? 1 : 0,
        ExpectedCompletionDate: auditDetails?.ExpectedCompletionDate,
        OverView: auditDetails?.OverView ?? "",
        LastAudit: auditDetails?.LastAudit,
        NextAudit: auditDetails?.NextAudit,
        AuditBy: auditDetails?.AuditBy?.trim(),
        objId_SendFormQuestionnaire: questionId ?? 0,
      })
        .then((response) => {
          CallToast("Updated successfully", false, false, "v2style");

          if (updateMo) {
            // getFrameworksData();
            getAuditDetails({
              CyberFrameworkAudit: {
                objId: response.EntityId,
              },
              FrameworkId: selectedFrameworkItem?.FrameworkId,
            });
            setCyberFrameworkAuditId(response.EntityId);
          } else {
            hideModal();
            if (refreshData) {
              refreshData();
            }
          }
          setSaving(false);
          refreshModalData();
        })
        .catch((Err) => {
          console.log(Err);
          CallToast("Something went wrong", false, false, "v2style");
        });
    }
  }

  const handleUpload = async (event) => {
    event.preventDefault();

    const file = event.target.files[0];
    console.log(file);
    setUploadingFileList(event.target.files);

    const allowedFileTypes = [
      "application/pdf",
      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      "text/plain",
    ];

    if (file && allowedFileTypes.includes(file.type)) {
      const reader = new FileReader();

      reader.onloadend = async () => {
        const base64Data = reader.result;
        const formData = new FormData();
        formData.append("File", base64Data);
        formData.append("optionEnum1", 9);
        formData.append("FileName", file?.name);
        formData.append("FrameworkAuditId", auditDetails?.objId);
        formData.append("objId_SendFormQuestionnaire", questionId ?? 0);
     

        try {
          let response = null;
          if (isContributorView) {
            formData.append(
              "key",
              JSON.parse(localStorage.getItem("user_MessageObj"))
            );
            response = await axios.post(
              `${process.env.REACT_APP_API_BASE_URL}/HGIR/SecOps_FileUpload`,
              formData,
              {
                headers: {
                  Authorization: `Bearer ${authTokens}`,
                },
                onUploadProgress: (progressEvent) => {
                  const percentCompleted = Math.round(
                    (progressEvent.loaded * 100) / progressEvent.total
                  );
                  setUploadProgress(percentCompleted);
                },
              }
            );
          } else {
            response = await axios.post(
              `${process.env.REACT_APP_API_BASE_URL}/CC_API/SecOps_FileUpload`,
              formData,
              {
                headers: {
                  Authorization: `Bearer ${authTokens}`,
                },
                onUploadProgress: (progressEvent) => {
                  const percentCompleted = Math.round(
                    (progressEvent.loaded * 100) / progressEvent.total
                  );
                  setUploadProgress(percentCompleted);
                },
              }
            );
          }

          CallToast("Uploaded successfully", false, false, "v2style");
          updateUploadList(selectedFrameworkItem);
          setUploadProgress(0);
        } catch (error) {
          console.error("Upload failed:", error);
        }
      };

      reader.readAsDataURL(file);
    } else {
      console.error(
        "Invalid file type. Please upload a PDF, DOC, DOX, or TXT file."
      );
    }
  };

  function formatFileSize(bytes) {
    const megabytes = bytes / (1024 * 1024);

    const formattedValue = megabytes.toFixed(3) + " MB";

    return formattedValue;
  }

  function downloadFile(url, filename) {
    const link = document.createElement("a");
    link.href = url;
    link.download = filename;

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
  async function deleteUploadFile(_fileName, _auditId, _attributeLinkId) {
    if (isContributorView) {
      return new Promise((resolve, reject) => {
        ContributorPostData(authTokens, "DeleteFile", {
          accessKey: JSON.parse(localStorage.getItem("user_MessageObj")),
          optionEnum1: 9,
          FileName: _fileName,
          FrameworkAuditId: _auditId,
          AttributeLinkId: _attributeLinkId,
        })
          .then((response) => {
            if (response?.Success) {
              if (selectedFrameworkItem) {
                updateUploadList(selectedFrameworkItem);
              }
            } else {
              CallToast("Something went wrong", false, false, "v2style");
            }
            // setAuditLoading(false);
          })
          .catch((err) => {
            console.log(err);
            // setAuditLoading(false);
            CallToast("Something went wrong", false, false, "v2style");
          });
      });
    } else {
      return new Promise((resolve, reject) => {
        postData(authTokens, "DeleteFile", {
          accessKey: JSON.parse(localStorage.getItem("user_MessageObj")),
          optionEnum1: 9,
          FileName: _fileName,
          FrameworkAuditId: _auditId,
          AttributeLinkId: _attributeLinkId,
        })
          .then((response) => {
            if (response?.Success) {
              if (selectedFrameworkItem) {
                updateUploadList(selectedFrameworkItem);
              }
            } else {
              CallToast("Something went wrong", false, false, "v2style");
            }
            // setAuditLoading(false);
          })
          .catch((err) => {
            console.log(err);
            // setAuditLoading(false);
            CallToast("Something went wrong", false, false, "v2style");
          });
      });
    }
  }
  async function updateUploadList(_selectedFrameworkItem) {
    if (isContributorView) {
      return new Promise((resolve, reject) => {
        ContributorGetAPIData("711", authTokens, {
          optionEnum1: CyberFrameworkAuditId ?? 0,
          optionEnum2: _selectedFrameworkItem?.FrameworkId ?? 0,
          optionEnum3: questionId ?? 0,
        })
          .then((response) => {
            if (response?.mr?.Success) {
              if (auditDetails) {
                setAuditDetails({
                  ...auditDetails,
                  MediaList: response?.MediaList,
                });
                resolve(response);
              }
            } else {
              reject(response);
              CallToast("Something went wrong", false, false, "v2style");
            }
            // setAuditLoading(false);
          })
          .catch((err) => {
            console.log(err);
            // setAuditLoading(false);
            reject(err);
            CallToast("Something went wrong", false, false, "v2style");
          });
      });
    } else {
      return new Promise((resolve, reject) => {
        getAPIData("711", authTokens, {
          optionEnum1: CyberFrameworkAuditId ?? 0,
          optionEnum2: _selectedFrameworkItem?.FrameworkId ?? 0,
          optionEnum3: questionId ?? 0,
        })
          .then((response) => {
            if (response?.mr?.Success) {
              if (auditDetails) {
                setAuditDetails({
                  ...auditDetails,
                  MediaList: response?.MediaList,
                });
                resolve(response);
              }
            } else {
              reject(response);
              CallToast("Something went wrong", false, false, "v2style");
            }
            // setAuditLoading(false);
          })
          .catch((err) => {
            console.log(err);
            // setAuditLoading(false);
            reject(err);
            CallToast("Something went wrong", false, false, "v2style");
          });
      });
    }
  }

  function thisDateNextYear(dateString) {
    const [day, month, year] = dateString.split("/").map(Number);
    const date = new Date(year + 1, month - 1, day);
    return date.getFullYear() === year &&
      date.getMonth() === month - 1 &&
      date.getDate() === day
      ? `${date.getDate()}/${String(date.getMonth() + 1).padStart(
          2,
          "0"
        )}/${date.getFullYear()}`
      : (() => {
          let nextDate = new Date(year + 1, month - 1, day);
          while (isNaN(nextDate.getTime())) {
            day++;
            nextDate = new Date(year + 1, month - 1, day);
          }
          return `${nextDate.getDate()}/${String(
            nextDate.getMonth() + 1
          ).padStart(2, "0")}/${nextDate.getFullYear()}`;
        })();
  }

  return (
    <Modal
      animation={false}
      show={show}
      dialogClassName="secops-cert-modal "
      className="   "
      aria-labelledby=" "
      onHide={hideModal}
      centered
      scrollable
    >
      <Modal.Header className="text-center">
        <div
          className="w-100 mb-0 font-bold-14 title"
          style={{ fontWeight: "600" }}
        >
          {headerName}
        </div>

        <span
          className="cursor-pointer"
          onClick={() => {
            hideModal();
            refreshModalData();
          }}
        >
          <img width={15} src="/images/attack-surface/cross-icon.svg" alt="" />
        </span>
      </Modal.Header>
      <Modal.Body
        className=" transparent-scroller d-flex flex-column"
        style={{ padding: "16px 24px", gap: "8px" }}
      >
        {loading ? (
          <div className="w-45 mx-auto mt-4">
            <Loader />
          </div>
        ) : (
          <>
            {AddOrEdit == "Add" ? (
              <Dropdown className="transparennt-wrapper-dropdown">
                <Dropdown.Toggle className="w-100">
                  <div
                    className={`${
                      auditDetails?.AbbreviationError ? "input-error" : ""
                    } d-flex align-items-center radius-4 grey-border p-2  flex-grow-1 mr-1 ${"highlight-input-with-grey-border"}`}
                  >
                    <div className="f-darkgrey">Type: </div>

                    <input
                      type="text"
                      className="bg-transparent w-100 border-none pl-2"
                      placeholder="Enter or choose framework type..."
                      value={selectedFrameworkItem?.Abbreviation}
                      onChange={(e) => {
                        if (AddOrEdit == "Add") {
                          setSelectedFrameworkItem({
                            ...selectedFrameworkItem,
                            Abbreviation: e.target.value,
                            AbbreviationError: false,
                          });
                          console.log(selectedFrameworkItem);
                          // setAccreditationData({
                          //   ...accreditationData,
                          //   accreditationType: null,
                          //   accreditationTypeError: false,
                          // });
                        }
                      }}
                    />
                    {selectedFrameworkItem &&
                      selectedFrameworkItem?.Abbreviation && (
                        <img
                          alt="/images/attack-surface/cross-icon.svg"
                          src="/images/attack-surface/cross-icon.svg"
                          className="pointer"
                          onClick={() => {
                            setSelectedFrameworkItem({});
                            // setAccreditationData({
                            //   ...accreditationData,
                            //   accreditationType: null,
                            //   accreditationTypeError: false,
                            // });
                          }}
                        />
                      )}
                  </div>
                </Dropdown.Toggle>
                <Dropdown.Menu
                  className="w-100 p-2 transparent-scroller"
                  style={{
                    maxHeight: "200px",
                    overflowY: "auto",
                  }}
                >
                  {/* <div className="f-12 f-darkgrey ml-2">Vendor Neutral</div> */}

                  {AddOrEdit == "Add" &&
                    frameworksData
                      ?.filter((f) => {
                        return f.Name === status;
                      })
                      .map((framework, frameworkIndex) => {
                        return (
                          <>
                            <div className="f-12 f-600 f-darkgrey mb-2">
                              {framework?.Name} frameworks
                            </div>
                            <div className=" radius-8">
                              {framework?.FrameworkList?.map(
                                (frameworkItem, frameworkItemIndex) => {
                                  return (
                                    <>
                                      {(frameworkItem?.Visibility === false &&
                                        framework?.showVisibleList) ||
                                      frameworkItem?.Visibility === true ? (
                                        <div
                                          className={`d-flex flex-column border-item pointer radius-8 `}
                                          onClick={() => {
                                            // setShowSidePanel(true);
                                            console.log(frameworkItem);
                                            if (AddOrEdit == "Add") {
                                              setSelectedFrameworkItem(
                                                frameworkItem
                                              );
                                            }
                                          }}
                                        >
                                          <div className="d-flex align-items-center justify-content-between w-100 pointer   py-3 ">
                                            <div
                                              className="d-flex flex-column "
                                              style={{ width: "75%" }}
                                            >
                                              <div className="d-flex align-items-baseline ">
                                                <p
                                                  className={`f-500 m-0  ${
                                                    frameworkItem?.Visibility
                                                      ? "f-black"
                                                      : "f-grey"
                                                  }`}
                                                >
                                                  {frameworkItem?.Abbreviation}
                                                </p>
                                              </div>
                                              <p className="f-12 f-darkgrey ">
                                                {frameworkItem?.Name}
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                      ) : (
                                        <></>
                                      )}
                                    </>
                                  );
                                }
                              )}
                            </div>
                          </>
                        );
                      })}

                  {/* {certificateName && (
                  <div
                    className="d-flex align-items-center justify-content-between f-blue my-2 pointer"
                    onClick={() => {
                      setDropdownLoader({
                        ...dropdownLoader,
                        typeLoader: true,
                      });
                    }}
                  >
                    <div className="d-flex align-items-center">
                      <img
                        src="/images/msp/blue-plus.svg"
                        alt=""
                        className="mr-2"
                      />
                      Add "{certificateName}"
                    </div>
                    <div>
                      {dropdownLoader.typeLoader && (
                        <div className="msp-service-update-loader mr-1"></div>
                      )}
                    </div>
                  </div>
                )} */}
                </Dropdown.Menu>
              </Dropdown>
            ) : (
              <div
                className={`${
                  auditDetails?.AbbreviationError ? "input-error" : ""
                } d-flex align-items-center radius-4 grey-border p-2  flex-grow-1 mr-1 ${"highlight-input-with-grey-border"}`}
              >
                <div className="f-darkgrey">Type: </div>

                <input
                  type="text"
                  className="bg-transparent w-100 border-none pl-2"
                  placeholder="Enter or choose framework type..."
                  value={selectedFrameworkItem?.Abbreviation}
                  disabled
                  onChange={(e) => {
                    if (AddOrEdit == "Add") {
                      setSelectedFrameworkItem({
                        ...selectedFrameworkItem,
                        Abbreviation: e.target.value,
                        AbbreviationError: false,
                      });
                      console.log(selectedFrameworkItem);
                      // setAccreditationData({
                      //   ...accreditationData,
                      //   accreditationType: null,
                      //   accreditationTypeError: false,
                      // });
                    }
                  }}
                />
              </div>
            )}

            <div className="f-darkgrey f-500 my-2 mt-4">Details</div>
            <div className="w-100 radius-4 grey-border p-2 ">
              <textarea
                className="w-100 radius-4 border-none"
                placeholder="Explain why you are aligned to this framework and what actions you take to ensure compliance..."
                rows={3}
                id="message"
                name="message"
                value={auditDetails?.OverView ?? ""}
                onChange={(e) => {
                  setAuditDetails({
                    ...auditDetails,
                    OverView: e.target.value,
                  });
                }}
              />
            </div>
            <div>
              <div className="f-darkgrey f-500 my-2 mt-4">Audit details</div>

              <div className="d-flex align-items-center my-2 w-100">
                <div className="w-30 f-500 f-grey"> In Progress</div>
                <div className="w-50 pl-3-5rem">
                  <Form.Group controlId={"check" + "" + 99} className="">
                    <Form.Check
                      tabIndex={-1}
                      type="switch"
                      id={"switch-ques1" + "" + 99 + Math.random() * 1000000}
                      style={{
                        transform: "scale(1.2)",
                        paddingLeft: "0.75rem",
                      }}
                      className={`m-0 scale-switch switch-shadow-none ${true}  `}
                      checked={auditDetails?.IsInProgress ?? false}
                      label=""
                      disabled={false}
                      onChange={(e) => {
                        setAuditDetails({
                          ...auditDetails,
                          IsInProgress: e.target.checked,
                        });
                      }}
                      onClick={(e) => {}}
                    />
                  </Form.Group>
                </div>
              </div>

              {auditDetails?.IsInProgress && (
                <div className="d-flex align-items-center my-2 w-100">
                  <div className="w-30 f-500 f-grey">
                    Expected <br /> Completion Date
                  </div>
                  <div className="w-70  ">
                    <HighgroundDatePicker
                      selected={
                        auditDetails?.ExpectedCompletionDate?.split(" ")?.[0] ??
                        ""
                      }
                      placeholder={"Choose "}
                      icon={"/images/attack-surface/date-icon.svg"}
                      onChange={(e) => {
                        setAuditDetails({
                          ...auditDetails,
                          ExpectedCompletionDate: e,
                        });
                      }}
                      id={"bhfgiibvwmv"}
                      isError={auditDetails?.expectedCompletionDateError}
                    />
                  </div>
                </div>
              )}

              {!auditDetails?.IsInProgress && (
                <>
                  <div className="d-flex align-items-center my-2 w-100">
                    <div className="w-30 f-500 f-grey">
                      Do you perform audits
                    </div>
                    <div className="w-fit-content dropdown-grey-theme score-question-dropdow n ml-3 w-70">
                      <CompanySettingsDropdown
                        rightAlign={true}
                        fieldId={"Id"}
                        fieldValue={"ObjDefDDLText"}
                        selected={auditDetails?.DoYouPerformAudits}
                        placeholder="Select"
                        //  showid={true}
                        onClick={(id, val, e, value) => {
                          setAuditDetails({
                            ...auditDetails,
                            DoYouPerformAudits: value,
                          });
                        }}
                        data={auditDetails?.DoYouPerformAuditsOptionsList}
                      />
                    </div>
                  </div>
                  <div className="d-flex align-items-center my-2 w-100">
                    <div className="w-30 f-500 f-grey">Audit type</div>
                    <div className="w-fit-content dropdown-grey-theme score-question-dropdow n ml-3 w-70">
                      <CompanySettingsDropdown
                        rightAlign={true}
                        fieldId={"Id"}
                        fieldValue={"ObjDefDDLText"}
                        selected={auditDetails?.AuditType}
                        placeholder="Select"
                        //  showid={true}
                        onClick={(id, val, e, value) => {
                          setAuditDetails({
                            ...auditDetails,
                            AuditType: value,
                          });
                        }}
                        data={auditDetails?.AuditTypeOptionsList}
                      />
                    </div>
                  </div>

                  <div className="d-flex align-items-center my-2 w-100">
                    <div className="w-30 f-500 f-grey">Last Audit</div>
                    <div className="w-70  ">
                      <HighgroundDatePicker
                        selected={
                          auditDetails?.LastAudit?.split(" ")?.[0] ?? ""
                        }
                        placeholder={"Choose date"}
                        icon={"/images/attack-surface/date-icon.svg"}
                        onChange={(e) => {
                          setAuditDetails({
                            ...auditDetails,
                            LastAudit: e,
                            NextAudit: thisDateNextYear(e),
                          });
                        }}
                        id={"bhfgvwmv"}
                        isError={false}
                      />
                    </div>
                  </div>

                  <div className="d-flex align-items-center my-2 w-100">
                    <div className="w-30 f-500 f-grey">Last Audited by</div>
                    <div className="w-70  ">
                      <div className="w-fit-content ml-3 w-70 highlight-input p-1">
                        <input
                          type="text"
                          placeholder="Enter name"
                          className="w-100 border-none bg-transparent"
                          value={auditDetails?.AuditBy}
                          onChange={(e) => {
                            setAuditDetails({
                              ...auditDetails,
                              AuditBy: e.target.value,
                            });
                          }}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="d-flex align-items-center my-2 w-100">
                    <div className="w-30 f-500 f-grey">Next Audit</div>
                    <div className="w-70  ">
                      <HighgroundDatePicker
                        selected={
                          auditDetails?.NextAudit?.split(" ")?.[0] ?? ""
                        }
                        placeholder={"Choose date"}
                        icon={"/images/attack-surface/date-icon.svg"}
                        onChange={(e) => {
                          setAuditDetails({
                            ...auditDetails,
                            NextAudit: e,
                          });
                        }}
                        id={"bhfgevwmtv"}
                        isError={false}
                      />
                    </div>
                  </div>

                  {/* <Tab.Container
                    onSelect={(v) => {
                      setSelectedTab(Number(v));
                    }}
                    id="left-tabs-example"
                    defaultActiveKey={selectedTab}
                  >
                    <div
                      className="scenarioName bg-white d-flex align-items-start    pt-2  "
                      style={{ top: "101px", zIndex: 2 }}
                      id="navHeader"
                    >
                      <Nav.Item key={0} className={"  mt-2"}>
                        <Nav.Link
                          eventKey={0}
                          className={`${selectedTab === 0 && "active"} mt-`}
                          onClick={() => {}}
                        >
                          Evidence
                        </Nav.Link>
                      </Nav.Item>
                    </div>

                    <Tab.Content className="mt-4">
                      <Tab.Pane eventKey={0}>
                        {uploadedFile ? (
                          <div className="radius-4 bg-grey p-3  d-flex align-items-center justify-content-between">
                            <div className="d-flex align-items-center">
                              <img
                                src="/images/msp/upload-icon-placeholder.svg"
                                alt=""
                                className="mr-2"
                              />
                              <div>
                                <div className="f-500">
                                  {uploadedFile?.fileName}{" "}
                                </div>
                                <div className="f-darkgrey">
                                  {uploadedFile?.fileSize}
                                </div>
                              </div>
                            </div>
                            <div className="d-flex align-item-center">
                              <img
                                src="/images/msp/policy-download-icon.svg"
                                alt=""
                                className="mx-2 pointer"
                                onClick={() => {
                                  downloadFile(
                                    uploadedFile?.fullPath,
                                    uploadedFile?.fileName
                                  );
                                }}
                              />
                              <img
                                src="/images/actioncenter/delete.svg"
                                alt=""
                                className="mx-2 pointer"
                                onClick={() => {
                                  setUploadedFile(null);
                                }}
                              />
                            </div>
                          </div>
                        ) : (
                          <div className="radius-4 border-dashed d-flex flex-column align-items-center justify-content-center w-100 p-3 mb-3">
                            {uploadProgress > 0 && uploadProgress < 100 ? (
                              <>
                                <UploadFileCircularProgress
                                  progress={100 - uploadProgress}
                                />

                                <div className="f-grey my-2 w-fit-content">
                                  {uploadingFileList?.[0]?.name}{" "}
                                </div>

                                <div className="f-darkgrey my-2 w-fit-content">
                                  Uploading... {uploadProgress}%
                                </div>

                                <Button
                                  className="hg-cancel-btn"
                                  onClick={() => {
                                    uploadRef.current.click();
                                  }}
                                >
                                  Cancel
                                </Button>
                              </>
                            ) : (
                              <>
                                <img
                                  width={20}
                                  src="/images/msp/framework-upload-icon.svg"
                                  alt=""
                                />
                                <div className="f-grey mt-2 w-fit-content">
                                  Upload file or browse file on your computer
                                </div>
                                <div className="f-darkgrey my-2 w-fit-content">
                                  Format pdf, doc, dox, txt
                                </div>
                                <Button
                                  className="hg-cancel-btn"
                                  onClick={() => {
                                    uploadRef.current.click();
                                  }}
                                >
                                  Browse file
                                </Button>
                              </>
                            )}
                          </div>
                        )}
                      </Tab.Pane>
                      <Tab.Pane eventKey={1}>
                        <div
                          className={`d-flex align-items-center radius-4 highlight-input-with-grey-border p-2 flex-grow-1 mr-1 ${true}`}
                        >
                          <div className="f-darkgrey">Link:</div>

                          <input
                            type="text"
                            placeholder="Type"
                            value={accreditationData?.linkUrl}
                            onChange={(e) => {
                              setAccreditationData({
                                ...accreditationData,
                                linkUrl: e.target.value,
                              });
                            }}
                            className="pl-2 bg-transparent border-none flex-grow-1"
                          />
                        </div>
                      </Tab.Pane>
                    </Tab.Content>
                  </Tab.Container> */}
                  {selectedFrameworkItem?.CyberFrameworkAudit ? (
                    <>
                      <div className="f-500 f-darkgrey mt-3 mb-3">Evidence</div>
                      <div className="radius-4 border-dashed d-flex flex-column align-items-center justify-content-center w-100 p-3 mb-3">
                        {uploadProgress > 0 && uploadProgress < 100 ? (
                          <>
                            <UploadFileCircularProgress
                              progress={100 - uploadProgress}
                            />

                            <div className="f-grey my-2 w-fit-content">
                              {uploadingFileList?.[0]?.name}{" "}
                            </div>

                            {/* {uploadingFileList?.map((file) => {
                                return (
                                  <div className="f-grey my-2 w-fit-content">
                                    {file.name}{" "}
                                  </div>
                                );
                              })} */}
                            <div className="f-darkgrey my-2 w-fit-content">
                              Uploading... {uploadProgress}%
                            </div>

                            <Button
                              className="hg-cancel-btn"
                              onClick={() => {
                                uploadRef.current.click();
                              }}
                            >
                              Cancel
                            </Button>
                          </>
                        ) : (
                          <>
                            <img
                              width={20}
                              src="/images/msp/framework-upload-icon.svg"
                              alt=""
                            />
                            <div className="f-grey mt-2 w-fit-content">
                              Upload file or browse file on your computer
                            </div>
                            <div className="f-darkgrey my-2 w-fit-content">
                              Format pdf, doc, dox, txt
                            </div>
                            <Button
                              className="hg-cancel-btn"
                              onClick={() => {
                                uploadRef.current.click();
                              }}
                            >
                              Browse file
                            </Button>
                          </>
                        )}
                      </div>
                      <input
                        type="file"
                        accept=".pdf,.doc,.dox,.txt"
                        onChange={(e) => handleUpload(e, auditDetails?.objId)}
                        className="d-none"
                        ref={uploadRef}
                        multiple
                      />
                    </>
                  ) : (
                    <></>
                  )}

                  {/* Uploaded Files */}
                  <div className="border-parent bg-grey radius-8">
                    {auditDetails?.MediaList?.map((item, index) => {
                      return (
                        <div className="border-item p-3  d-flex align-items-center justify-content-between">
                          <div className="d-flex align-items-center">
                            <img
                              src="/images/msp/upload-icon-placeholder.svg"
                              alt=""
                              className="mr-2"
                            />
                            <div>
                              <div className="f-500">{item?.FileName}</div>
                              <div className="f-darkgrey">
                                {formatFileSize(item?.FileSize)}
                              </div>
                            </div>
                          </div>
                          <div className="d-flex align-item-center">
                            <img
                              src="/images/msp/policy-download-icon.svg"
                              alt=""
                              className="mx-2 pointer"
                              onClick={() => {
                                downloadFile(item?.FileUrl, item?.FileName);
                              }}
                            />

                            {item.loading ? (
                              <GreyLoader width={12} />
                            ) : (
                              <img
                                src="/images/actioncenter/delete.svg"
                                alt=""
                                className="mx-2 pointer"
                                onClick={() => {
                                  let updated_data = { ...auditDetails };
                                  updated_data.MediaList[index].loading = true;
                                  setAuditDetails(updated_data);
                                  deleteUploadFile(
                                    item?.FileName,
                                    auditDetails?.objId,
                                    item?.BizObjAttributeLinkId
                                  );
                                }}
                              />
                            )}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </>
              )}
            </div>
          </>
        )}
        <div className="p-5" />
        {/* <input
          type="file"
          accept=".pdf,.doc,.dox,.txt"
          onChange={(e) => handleUpload(e)}
          className="d-none"
          ref={uploadRef}
          multiple
        /> */}
      </Modal.Body>

      <Modal.Footer className="p-2 d-flex align-items-center justify-content-end">
        <Button
          className="hg-cancel-btn mr-2"
          onClick={() => {
            setSelectedFrameworkItem({});
            hideModal();
            refreshModalData();
          }}
        >
          Cancel
        </Button>
        <Button
          className="hg-blue-btn"
          onClick={async () => {
            let errors = await checkValidation();

            if (errors > 0) {
              let error_element =
                document.getElementsByClassName(`input-error`);
              if (error_element?.length > 0) {
                error_element[0].scrollIntoView({
                  behavior: "smooth",
                  block: "center",
                  inline: "nearest",
                });
              }

              return;
            } else {
              updateAuditDetails(true, false);
              setUpdateMode(false);
            }
            // let errors = await checkValidation();
            // if (errors > 0) {
            //   let error_element =
            //     document.getElementsByClassName(`input-error`);
            //   if (error_element?.length > 0) {
            //     error_element[0].scrollIntoView({
            //       behavior: "smooth",
            //       block: "center",
            //       inline: "nearest",
            //     });
            //   }
            //   return;
            // } else {
            //   handleSave();
            // }
          }}
        >
          {saving ? "Saving.." : "Save"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddFrameworkModal;

export function convertDateFormat(dateString) {
  const parts = dateString.split("/");
  if (parts.length > 2) {
    const day = parts[0];
    const month = parts[1];
    const year = parts[2];

    // Rearrange the parts in the desired format
    const formattedDate = `${year}-${month}-${day}`;

    return formattedDate;
  } else {
    return "";
  }
}

function reverseDateFormat(dateString) {
  const parts = dateString.split("-");
  const day = parts[2];
  const month = parts[1];
  const year = parts[0];

  // Rearrange the parts in the desired format
  const formattedDate = `${day}/${month}/${year}`;

  return formattedDate;
}
