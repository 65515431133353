import React, { useState, useEffect, useRef } from "react";
import { Button, Dropdown } from "react-bootstrap";
import "./overview.scss";
import "../common.scss";
import { useAuth } from "../../context/Auth";
import { getPageDataApi } from "../../../Utils/commonApi";
import Loader from "../../Common/loader";
import DeviceIssuesTable from "../device-issues-table/device-issues.table";
import DeviceIssuesFilter from "./device-issues-filter/device-issues-filter.component";
import UserIssuesTable from "../device-issues-table/user-issues.table";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import useQueryParams from "../../../hooks/query-params.hook";

const mapDeviceIsses = (deviceIssues) => (
  deviceIssues.map((item) => ({
    DeviceLogo: "/images/msp/" + item?.DeviceOS?.toLowerCase() + ".svg", 
    DeviceName: item?.DeviceName,
    OperatingSystem: item?.DeviceOS,
    EndOfLifeDate: item?.DeviceEndOfLife
  }))
);

const Overview = ({ progressData}) => {
  const [overviewPageData, setOverviewPageData] = useState({})
  const [pageLoading, setPageLoading] = useState(false)
  //MFA Table
  // EOL Table
  const [tableType, setTableType] = useState("MFA Table")
  useEffect(() => {
    setPageLoading(true)
    getPageData()
  }, [])

  const { getParam } = useQueryParams();
  const section = getParam('section');

  useEffect(() => {
    if (!section) return;
    if (section === 'MFA') setTableType('MFA Table');
    if (section === 'EOL') setTableType('EOL Table');
  }, [section]);
  

 const { authTokens } = useAuth();

 function getPageData(){
  return new Promise((resolve, reject) => {
    getPageDataApi("GetASEOverview", authTokens, {
      objId_SystemOwner: 4653142,
objId_Customer: 7283912
    }).then((response)=>{
      setOverviewPageData(response?.detail)
      setPageLoading(false)
      resolve(response)
    }).catch((err)=>{
      reject(err)
      setPageLoading(false)

    })})
 }

 function updateMfaStatus(kwargs ={}) {
  return new Promise((resolve, reject) => {
    getPageDataApi("UpdateAzureUserMFAStatus", authTokens, {
      // objId_Customer: 7283912,
      
      MFAState: kwargs?.MFAState ?? 0,
      UserId: kwargs?.UserId ?? "",
      UserRecordId: kwargs?.UserRecordId ?? "",
    }).then((response)=>{
      getPageData()
      resolve(response)
    }).catch((err)=>{
      reject(err)
    })})
 }
 
 
 

  

  function ToggleAccordion(e) {
    var parent = e.target.closest(".insight-drop");

    var panel = parent.querySelector(".panel");

    if (panel?.style?.maxHeight) {
      panel.style.maxHeight = null;
    } else {
      panel.style.maxHeight = panel.scrollHeight + "px";
    }
  }

  const InsightsData = [
    {
      name: "3 users without MFA enabled",
      subtitle: "You have 3 users in Office 365 who do not have MFA enabled",
      list: [
        {
          name: "Brooklyn Simmons",
          subtitle: "MFA disabled",
          image: "/images/attack-surface/bs-initials.svg",
        },
        {
          name: "Esther Howard",
          subtitle: "MFA disabled",
          image: "/images/attack-surface/bs-initials.svg",
        },
        {
          name: "Bessie Cooper",
          subtitle: "MFA disabled",
          image: "/images/attack-surface/bs-initials.svg",
        },
      ],
    },
    {
      name: "Firewall's license has expired on 2 devices",
      subtitle:
        "You're Firewall's license has expired and is no longer performing Intrusion Detection",
      list: [
        {
          name: "HG-AUTOID_SUPER-Firewall-2",
          subtitle: "License expired on 10/07/22",
          image: null,
        },
        {
          name: "HG-AUTOID_SUPER-Firewall-1",
          subtitle: "License expired on 10/07/22",
          image: null,
        },
      ],
    },
    {
      name: "6 devices do not have Endpoint Protection",
      subtitle: "",
      list: [
        {
          name: "Brooklyn Simmons",
          subtitle: "MFA disabled",
          image: "/images/attack-surface/bs-initials.svg",
        },
        {
          name: "Esther Howard",
          subtitle: "MFA disabled",
          image: "/images/attack-surface/bs-initials.svg",
        },
        {
          name: "Bessie Cooper",
          subtitle: "MFA disabled",
          image: "/images/attack-surface/bs-initials.svg",
        },
      ],
    },
    
  ];

    // Search Input Animation
    const SearchInput = useRef();
    const [searchString, setSearchString] = useState("");
    const [searchInput, setSearchInput] = useState(false);

  return (<>
  {
  pageLoading

  ?

  <div
  className="d-flex align-items-center justify-content-center w-100 "
  style={{
    height: "85vh",
  }}
>
  <Loader />
</div>



  :
  
  
  
  
  
  <div className="attack-overview-wrapper w-100" style={{ padding: '24px' }}>
      <div className="overview-banner bg-grey radius-8 w-100 d-flex">
        <div
          className="w-50 p-4"
          style={{
            borderRight: "1px solid #EBEDF1",
          }}
        >
          <div className="f-500 f-grey mb-3">Attack surface analysis</div>

          <div className="all-progresses d-flex flex-column">
            {progressData?.slice(1).map((progress, index) => {
              return (
                <div className=" attack-progress mb-2">
                  <div className="f-grey">{progress?.Title} </div>
                  <div className="d-flex align-items-center">
                    <progress
                      min="0"
                      value={progress?.CompletionScore}
                      max="100"
                    />
                  </div>
                  <div className="f-darkgrey d-flex align-items-center justify-content-center">
                    {progress?.CompletionScore}%
                  </div>
                </div>
              );
            })}
          </div>
          <div className="" style={{}}></div>
        </div>
        <div className="w-50  d-flex flex-column">
        

          <div className="asset-data d-flex flex-column w-100">
            <div className="w-100 d-flex">
              <div className="w-100 p-3">
                <div className="f-grey f-500 mb-2">Users</div>
                <div className="f-18 f-black f-500">
                  {overviewPageData?.Users}{" "}
                </div>
              </div>
              <div
                className="w-100 p-3"
                style={{ borderLeft: "1px solid #EBEDF1" }}
              >
                <div className="f-grey f-500 mb-2 ">Locations</div>
                <div className="f-18 f-black f-500">
                  {overviewPageData?.Locations}
                </div>
              </div>
            </div>

            <div className="w-100 d-flex">
              <div
                className="w-100 p-3"
                style={{
                  borderTop: "1px solid #EBEDF1",
                  borderRight: "1px solid #EBEDF1",
                }}
              >
                <div className="f-grey f-500 mb-2">Assets</div>
                <div className="f-18 f-black f-500">
                  {overviewPageData?.Assets}
                </div>
              </div>
              <div
                className="w-100 p-3"
                style={{ borderTop: "1px solid #EBEDF1" }}
              >
                <div className="f-grey f-500 mb-2">Cloud Services</div>
                <div className="f-18 f-black f-500">
                  {overviewPageData?.cloudServices}
                </div>
              </div>
            </div>
            <div className="w-100 d-flex">
              <div
                className="w-100 p-3"
                style={{
                  borderTop: "1px solid #EBEDF1",
                  borderRight: "1px solid #EBEDF1",
                  borderBottom: "1px solid #EBEDF1",
                }}
              >
                <div className="f-grey f-500 mb-2">Disabled MFA</div>
                <div className="f-18 f-black f-500">
                  {overviewPageData?.DisabledMFA}
                </div>
              </div>
              <div
                className="w-100 p-3"
                style={{
                  borderTop: "1px solid #EBEDF1",

                  borderBottom: "1px solid #EBEDF1",
                }}
              >
                <div className="f-grey f-500 mb-2">Life End Devices</div>
                <div className="f-18 f-black f-500">
                  {overviewPageData?.EndOfLifeDevices}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className=" mt-5">
        <div className="d-flex align-items-center justify-content-between mb-3">
          <div className="d-flex align-items-center">
            <div
              className={`mr-3 f-500 pointer ${
                tableType === "MFA Table" ? "f-blue" : "f-grey"
              }`}
              onClick={() => {
                setTableType("MFA Table");
              }}
            >
              User Issues ({overviewPageData?.MFAStatusList?.length})
            </div>
            <div
              className={`pointer f-500  ${
                tableType === "EOL Table" ? "f-blue" : "f-grey"
              }`}
              onClick={() => {
                setTableType("EOL Table");
              }}
            >
              Device Issues (
              {overviewPageData?.EndOfLifeDevicesList?.length})
            </div>
          </div>
          <div className="d-flex align-items-center" style={{ gap: '8px' }}>
            {tableType === "EOL Table" && (
              <DeviceIssuesFilter />
            )}
            <div className="mx-2 d-flex align-items-center">
              <img
                alt=""
                src="/images/attack-surface/search-icon.svg"
                className="pointer"
                onClick={() => {
                  setSearchInput(true);
                  SearchInput.current.focus();
                }}
              />
              <input
                type="text"
                placeholder="Search.."
                ref={SearchInput}
                value={searchString}
                onChange={(e) => {
                  // handleInputChange(e);
                  setSearchString(e.target.value);
                }}
                onBlur={() => {}}
                className={`${
                  searchInput
                    ? "animated-search-input-open"
                    : "animated-search-input-close"
                } pl-2`}
                autoFocus
              />
              <img
                alt=""
                style={{
                  visibility: searchString !== "" ? "visible" : "hidden",
                }}
                src="/images/settings-v2/header-search-cross.svg"
                className="pointer"
                onClick={() => {
                  // handleInputChange({ target: { value: "" } });
                  setSearchString("");
                }}
              />
            </div>
          </div>
        </div>

        {tableType === "MFA Table" && (
          <>
            {overviewPageData?.MFAStatusList?.filter((item) =>
              item?.Name?.toLowerCase()?.includes(searchString?.toLowerCase())
            )?.length === 0 ? (
              <div className="mt-5 mx-auto w-50 d-flex flex-column justify-content-center align-items-center">
                <img
                  src="/images/msp/no-mfa-item-icon.svg"
                  alt=""
                  className=""
                />
                <div className="f-600 f-grey">
                  This tenant has not been authorised yet
                </div>
                <div className="f-darkgrey w-50 my-2 mb-3 text-center">
                  Go to the Microsoft 365 Lighthouse Integration and authorise
                  this tenant to get started
                </div>
                <Button className="hg-blue-btn radius-2">
                  Open Microsoft 365 Lighthouse Integration
                </Button>
              </div>
            ) : (
              <UserIssuesTable 
                userIssues={
                  overviewPageData?.MFAStatusList?.filter((item) =>
                  item?.Name?.toLowerCase()?.includes(
                    searchString?.toLowerCase()
                  )
                )}
                overviewPageData={overviewPageData}
                setOverviewPageData={setOverviewPageData}
                updateMfaStatus={updateMfaStatus}
                searchString={searchString}
              />
            )}
          </>
        )}
        {tableType === "EOL Table" && (<>
{
  
  overviewPageData?.EndOfLifeDevicesList?.filter((item) =>
    item?.DeviceName?.toLowerCase()?.includes(
      searchString?.toLowerCase()
    )
  )?.length === 0 ? (
    <div className="mt-5 mx-auto w-50 d-flex flex-column justify-content-center align-items-center">
      <img
        src="/images/msp/no-eol-item-icon.svg"
        alt=""
        className="mb-3"
      />
      <div className="f-600 f-grey">
      No End of Life Devices
      </div>
      <div className="f-darkgrey w-50 my-2 mb-3 text-center">
      End of Life Devices will be shown here
      </div>
      
    </div>
  ) :
    <DeviceIssuesTable 
      deviceIssues={mapDeviceIsses(overviewPageData?.EndOfLifeDevicesList?.filter((item) =>
        item?.DeviceName?.toLowerCase()?.includes(
          searchString?.toLowerCase()
        )
      ))}
      className="ase-device-issues-table"
      searchString={searchString}
      noDataComponent={(
        <div />
      )}
    />
}
          </>
        )}
      </div>

      {false && (
        <div className="insights-wrapper w-100">
          <div className="f-600 f-black mt-4">Insights</div>

          <div
            className="bg-grey radius-8 border-parent position-relative"
            style={{ pointerEvents: "none" }}
          >
            <div
              className="position-absolute right-0 bottom-0 top-0 left-0"
              style={{
                background: "#ffffff70",
                zIndex: 2,
              }}
            >
              <div
                className="p-0 d-flex flex-column bg-white radius-4 border1"
                style={{
                  width: "270px",

                  top: "50%",
                  position: "absolute",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                }}
              >
                {/* <img
                              src="/images/insights-coming-soon-image.svg"
                              alt=""
                              className=""
                            /> */}
                <img
                  src="/images/welcome-coming-soon-icon.svg"
                  alt=""
                  width={100}
                  className="m-2"
                />
                <div className="f-14 f-600 f-black ml-2">
                  Security Insights are coming soon!
                </div>
                <div className="f-14 f-grey m-2" style={{ textWrap: "pretty" }}>
                  Don’t fall foul to simple oversights and misconfigurations
                  that attackers find and use to take you down! Soon you’ll be
                  able to build, share and action quick, simple and effective
                  security insights to keep you safe.
                </div>
              </div>
            </div>

            <div className="border-item d-flex align-items-center p-2">
              <div className="f-blue f-500 mr-3">
                Active
                <span className="p-1 bg-darkgrey rounded-circle f-12 ml-1">
                  8
                </span>
              </div>
              <div className="f-grey f-500 mr-3">
                Suppressed
                <span className="p-1 bg-darkgrey rounded-circle f-12 ml-1">
                  6
                </span>
              </div>
              <div className="f-grey f-500 mr-3">Closed</div>
            </div>
            {/* Data */}

            {InsightsData.map((item, index) => {
              return (
                <div
                  className="border-item insight-drop"
                  onClick={ToggleAccordion}
                >
                  <div className="d-flex align-items-center justify-content-between w-100 pointer px-4  py-3 bg-hover-row">
                    <div
                      className="d-flex flex-column "
                      style={{ width: "75%" }}
                    >
                      <div className="d-flex align-items-baseline ">
                        <img
                          alt=""
                          src="/images/attack-surface/blue-dot.svg"
                          className="mr-3"
                        />

                        <p className="f-500 m-0">{item?.name} </p>
                      </div>
                      <p className="f-12 f-darkgrey ml-2">{item?.subtitle}</p>
                    </div>
                    <div
                      className="d-flex align-items-center"
                      onClick={(e) => e.stopPropagation()}
                    >
                      <div
                        className="insights-notification-dropdown"
                        style={{
                          display: `${index == 0 ? "block" : "none"}`,
                        }}
                      >
                        <Dropdown onToggle={() => {}}>
                          <Dropdown.Toggle className="dropdown-bg-transparent">
                            <img
                              alt=""
                              src="/images/attack-surface/notification-icon.svg"
                            />
                          </Dropdown.Toggle>

                          <Dropdown.Menu>
                            <Dropdown.Item
                              className="onactive-bg-transparent"
                              onClick={() => {}}
                            >
                              1 Day
                            </Dropdown.Item>
                            <Dropdown.Item
                              className="onactive-bg-transparent"
                              type="button"
                              onClick={(event) => {}}
                            >
                              2 Days
                            </Dropdown.Item>
                            <Dropdown.Item
                              className="onactive-bg-transparent"
                              type="button"
                              onClick={(event) => {}}
                            >
                              3 Days
                            </Dropdown.Item>
                            <Dropdown.Item
                              className="onactive-bg-transparent"
                              type="button"
                              onClick={(event) => {}}
                            >
                              1 Week
                            </Dropdown.Item>
                            <Dropdown.Item
                              className="onactive-bg-transparent"
                              type="button"
                              onClick={(event) => {}}
                            >
                              2 Weeks
                            </Dropdown.Item>

                            <Dropdown.Item
                              className="onactive-bg-transparent"
                              onClick={() => {}}
                            >
                              1 Month
                            </Dropdown.Item>
                            <hr className="mt-1 m-0 mb-1" />
                            <Dropdown.Item
                              className="f-danger-imp onactive-bg-transparent"
                              onClick={() => {}}
                            >
                              Forever
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                      <img
                        alt=""
                        src="/images/attack-surface/chevron-down.svg"
                      />
                    </div>
                  </div>
                  <div className="pane l" onClick={(e) => e.stopPropagation()}>
                    {item?.list?.map((listItem, index) => {
                      return (
                        <div className="d-flex align-items-center justify-content-between w-auto pointer p-3 ml-4 mr-3 insights-hover-effect">
                          <div
                            className="d-flex align-items-center"
                            style={{ width: "75%" }}
                          >
                            {listItem?.image && (
                              <img
                                alt=""
                                src={listItem?.image}
                                className="mr-2"
                              />
                            )}
                            <div className="d-flex flex-column ">
                              <p className="f-500 m-0">{listItem?.name}</p>

                              <p className="f-12 f-darkgrey ml-2">
                                {listItem?.subtitle}
                              </p>
                            </div>
                          </div>
                          <div
                            className=""
                            onClick={(e) => e.stopPropagation()}
                          >
                            <img
                              alt=""
                              src="/images/attack-surface/arrow-right.svg"
                              className="arrow"
                            />
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              );
            })}
            <div className="border-item d-flex align-items-center p-2 f-grey f-12">
              <img
                alt=""
                src="/images/attack-surface/info-icon.svg"
                className="mr-2 ml-3"
              />
              Insights automatically update every 24 hours. The next update will
              be in 4h 20m
            </div>
          </div>
        </div>
      )}
    </div>}
    </>
  );
};

export default Overview;
