import React from "react";
import { Button } from "react-bootstrap";
import "./labelledInputNumber.scss";

function LabelledInputNumber({
  placeholder,
  PrefixComponent,
  SuffixComponent,
  onChange,
  value,
  error,
  disabled,
  onSubmit,
}) {
  return (
    <div
      className={`labelled-input-number ${
        error ? "error-labelled-input" : ""
      } ${disabled ? "disabled-labelled-input" : ""} `}
    >
      <div className="prefix-component px-1">{PrefixComponent}</div>

      <input
        className="input-field"
        type="number"
        onChange={onChange}
        value={value}
        placeholder={placeholder}
        disabled={disabled}
      />
      <div className="suffix-component">
        <Button
          className={`${disabled ? "submit-btn-disabled" : ""}`}
          disabled={disabled}
          onClick={onSubmit}
        >
          Label
        </Button>
      </div>
    </div>
  );
}

export default LabelledInputNumber;

export const PrefixComponent = () => {
  return <img className="pr-1 pl-1" src="/images/labelledinputprefix.svg" />;
  //   return <div>Label:</div>;
};

export const SuffixComponent = () => {
  return <Button>Label</Button>;
};
