import React from "react";

const MspMappingDropdownSearchInput = ({ placeholder, value, onChange ,loading}) => {
  return (
    <div className="bg-grey radius-4 mx-2 w-100 p-2 search-box-with-border d-flex align-items-center">
      <input
        type="text"
        className="border-none bg-transparent flex-1"
        placeholder={placeholder}
        value={value}
        onChange={onChange}
      />
                                                     
                                                     
      {loading && <div className="msp-service-update-loader mr-1"></div> }
    </div>
  );
};

export default MspMappingDropdownSearchInput;
