import React, { useState, useEffect } from "react";
import {
  Button,
  Modal,
  Row,
  Col,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import "./index.scss";
import { getAPIData, postData } from "../../../../Utils/commonApi";
import { useAuth } from "../../../context/Auth";
import Loader from "../../../Common/loader";

const DowngradeModal = (props) => {
  const { authTokens } = useAuth();
  const [downgradePlans, setDowngradePlans] = useState([]);
  const { data } = props;
  const { setData } = props;
  const { price, planName } = data;
  const [isLoading, setIsLoading] = useState(true);
  const [isDowngrade, setIsDowngrade] = useState(false);
  useEffect(() => {
    if (data.show) {
      getDowngradePlans();
    }
  }, [data]);

  async function getDowngradePlans() {
    const result = await getAPIData("194", authTokens, {
      optionEnum1: price,
      optionEnum2: planName ? 1 : 0,
    });
    setDowngradePlans(result);
    setIsLoading(false);
  }

  async function DowngradePlan() {
    setIsLoading(true);
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${authTokens}`);
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
    };

    const result = await fetch(
      `${process.env.REACT_APP_API_BASE_URL}/CC_API/DowngradeSubscription?priceId=${price}&frequency=${
        downgradePlans.IsMonthly ? 1 : 0
      }`,
      requestOptions
    ).then((response) => response.json());
    if (result.mr.Success) {
      if (result.AccessKey !== undefined) {
        localStorage.setItem(
          "user_MessageObj",
          JSON.stringify(result.AccessKey)
        );
      }
      setIsDowngrade(true);
      if (props.integration) {
        props.integration();
      }
    } else {
      setIsDowngrade(false);
      props.showToast(true);
      setTimeout(() => {
        props.showToast(false);
      }, 3000);
    }
    setIsLoading(false);
  }
  return (
    <Modal
      animation={false}
      show={data.show}
      dialogClassName="modal-50w"
      className="integrationModal centerModal dashboardModal downgradeModal"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      scrollable
    >
      {isLoading && <Loader />}
      <Modal.Header className="text-center ">
        <h1 className="w-100 mb-0 mt-0">Downgrade</h1>
        <span className="cursor-pointer">
          <img
            onClick={() => {
              setData((prevState) => ({ ...prevState, show: false }));
              setIsDowngrade(false);
            }}
            src="/images/icons/close.svg"
            alt=""
          />
        </span>
      </Modal.Header>
      <Modal.Body>
        <div className="margin_center">
          <h1>{downgradePlans?.Title}</h1>
          <div className="premium_center">
            <p className="premium_txt">{downgradePlans.PackageName}</p>
            <p className="price_txt">{downgradePlans.Cost}</p>
          </div>
          <div className="premium_wrap">
            {downgradePlans.Description}
            {isDowngrade ? (
              <p className="d-flex align-items-start flex-row mt-4">
                <img
                  className="mt-1"
                  src={"/images/subscription/correct.svg"}
                  alt=""
                />
                <p className="ml-2" style={{ color: "#40D88E" }}>
                  Downgrade Successfully!
                </p>
              </p>
            ) : (
              <p className="err  d-flex align-items-start flex-row">
                <img className="mt-1" src={downgradePlans.WarningIcon} alt="" />
                <p className="ml-2">{downgradePlans.WarningMessage}</p>
              </p>
            )}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Row className="m-0 align-items-center justify-content-end">
          <div
            className="mr-4 crsrpoint"
            onClick={() => {
              setData((prevState) => ({ ...prevState, show: false }));
              setIsDowngrade(false);
            }}
          >
            Cancel
          </div>
          <Button
            variant="primary"
            type="submit"
            className="float-right redClr"
            onClick={() => DowngradePlan()}
          >
            Downgrade
          </Button>
        </Row>
      </Modal.Footer>
    </Modal>
  );
};

export default DowngradeModal;
