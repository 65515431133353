import React, { useEffect, useState, lazy, Suspense } from "react";
import "../secOps.scss";

import CreateTaskModal from "../../../PriceCalculator/modals/CreateTaskModal";
import { setactioncentredata } from "../../../../redux/actionCentre/actions";
import { useDispatch } from "react-redux";
import { Button, Dropdown, Form, Nav, Tab } from "react-bootstrap";
import HighgroundDatePicker from "../../../test/TestComponent";
// import AddEditAccreditationModal from "./AddEditAccreditationModal";
import ContentLoader from "react-content-loader";
import {
  getAPIData,
  postData,
  postJsonData,
} from "../../../../Utils/commonApi";
import { CallToast } from "../../../PriceCalculator/modals/CustomToast";
import { useAuth } from "../../../context/Auth";
import Loader from "../../../Common/loader";
import moment from "moment";
import { GreyLoader } from "../../../Theme/APILoaders";
import { useRef } from "react";
import {
  convertDateFormat,
  fixLinkText,
} from "../../../../Utils/CommonFunctions";
import { NO_ACCESS_TEXT, accessStateValue } from "../../../App/App";
import AttackSurfaceTooltip from "../../../AttackSurface/AssetsList/AttackSurfaceTooltip";
import { UploadFileCircularProgress } from "../SecOps";
import axios from "axios";
import { auditUpdate } from "../audit/AuditHeader";

const AddEditAccreditationModal = lazy(() =>
  import("./AddEditAccreditationModal")
);

const Accreditations = ({
  setSideBarOpen = () => {},
  setShowSidePanelPassed = () => {},
  isAudit = false, showSidePanelPassed = false,
}) => {
  const [showSidePanel, setShowSidePanel] = useState(false);
  const [loading, setLoading] = useState(false);
  const [accreditationList, setAccreditationList] = useState([]);
  const [accreditationId, setAccreditationId] = useState(0);
  const [accreditationLoading, setAccreditationLoading] = useState(false);
  const [accreditationDetails, setAccreditationDetails] = useState();
  const dispatch = useDispatch();
  const { authTokens } = useAuth();

  const [selectedAccreditationItem, setSelectedAccreditationItem] = useState();
  const [deletingAccreditation, setDeletingAccreditation] = useState(false);

  const [accreditationModalState, setAccreditationModalState] = useState(
    {
      showModal: false,
      selectedId: 0,
      headerName: "Add Accreditation"
    }
  )

  useEffect(() => {
    setShowSidePanel(showSidePanelPassed);
  }, [showSidePanelPassed])

  useEffect(() => {
    if (showSidePanel === false) {
      setSideBarOpen(true);
    } else {
      setSideBarOpen(false);
    }
    setShowSidePanelPassed(showSidePanel);
  }, [showSidePanel]);

  const [selectedTab, setSelectedTab] = useState(0);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [errorData, setErrorData] = useState({
    errMessage: "",
    errIcon: "",
  });
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploadingFileList, setUploadingFileList] = useState([]);
  const uploadRef = React.useRef();

  const [certificateName, setCertificateName] = useState("");
  const [modalData, setModalData] = useState({});

  const [placeholder, setPlaceholder] = useState("Enter your link here...");

  const [inputValue, setInputValue] = useState("");

  const [errors, setErrors] = useState({
    accreditationTypeEmpty: false,
    accreditationCertificateNoEmpty: false,
    accreditationCertificationBodyEmpty: false,
    accreditationAuditorEmpty: false,
    accreditationScopeEmpty: false,
    accreditationRegionEmpty: false,
    accreditationIssueDateEmpty: false,
    accreditationExpiredDateEmpty: false,
    accreditationExpectedCompletionDateEmpty: false,
  });

  async function checkValidation() {
    let errors = 0;
    try {
      if (!accreditationDetails.accreditationType) {
        errors = errors + 1;

        setErrors((prev) => ({
          ...prev,
          accreditationTypeEmpty: true,
        }));
      }

      if (accreditationDetails.isInProgress) {
        if (
          !accreditationDetails.expectedCompletionDate &&
          accreditationDetails.isInProgress
        ) {
          errors = errors + 1;

          setErrors((prev) => ({
            ...prev,
            accreditationExpectedCompletionDateEmpty: true,
          }));

          return errors;
        } else {
          return errors;
        }
      }

      if (
        !accreditationDetails.certificationBody ||
        accreditationDetails.certificationBody.trim() == ""
      ) {
        errors = errors + 1;
        setErrors((prev) => ({
          ...prev,
          accreditationCertificationBodyEmpty: true,
        }));
      }

      if (!accreditationDetails.issueDate) {
        errors = errors + 1;
        setErrors((prev) => ({
          ...prev,
          accreditationIssueDateEmpty: true,
        }));
      }
      if (!accreditationDetails.expiryDate) {
        errors = errors + 1;
        setErrors((prev) => ({
          ...prev,
          accreditationExpiredDateEmpty: true,
        }));
      }

      if (
        !accreditationDetails.certificateNo ||
        accreditationDetails.certificateNo.trim() == ""
      ) {
        errors = errors + 1;

        setErrors((prev) => ({
          ...prev,
          accreditationCertificateNoEmpty: true,
        }));
      }
      if (
        !accreditationDetails.scope ||
        accreditationDetails.scope.trim() == ""
      ) {
        errors = errors + 1;

        setErrors((prev) => ({
          ...prev,
          accreditationScopeEmpty: true,
        }));
      }
      if (
        !accreditationDetails.region ||
        accreditationDetails.region.trim() == ""
      ) {
        errors = errors + 1;

        setErrors((prev) => ({
          ...prev,
          accreditationRegionEmpty: true,
        }));
      }
      if (
        !accreditationDetails.auditor ||
        accreditationDetails.auditor.trim() == ""
      ) {
        errors = errors + 1;

        setErrors((prev) => ({
          ...prev,
          accreditationAuditorEmpty: true,
        }));
      }
    } catch (err) {
      console.log(err, "errrrr");
    }

    return errors;
  }
  const [dropdownLoader, setDropdownLoader] = useState({
    typeLoader: false,
    bodyLoader: false,
  });

  useEffect(() => {
    setLoading(true);
    getAccreditationList();
  }, []);

  useEffect(() => {
    if (selectedAccreditationItem?.objId_Accreditation) {
      setAccreditationLoading(true);
      getAccreditationDetails(selectedAccreditationItem?.objId_Accreditation);
    }
  }, [selectedAccreditationItem]);

  async function getAccreditationList() {
    await getAPIData("801", authTokens, { optionEnum1: 0 })
      .then((response) => {
        if (response?.mr) {
          setAccreditationList(response?.items);
        } else {
          CallToast("Something went wrong", false, false, "v2style");
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        CallToast("Something went wrong", false, false, "v2style");
        setLoading(false);
      });
  }
  const [types, setTypes] = useState([]);

  async function getAccreditationDetails(_accreditationId) {
    await getAPIData("802", authTokens, { optionEnum1: _accreditationId })
      .then((response) => {
        if (response?.mr?.Success) {
          setAccreditationDetails(response?.detail);
          setUploadedFiles(response?.detail?.files);
          setPlaceholder(response?.detail?.accreditationLinkUrl);
          setTypes(response?.types);
          setDropdownLoader({
            typeLoader: false,
            bodyLoader: false,
          });
          setAccreditationId(response?.detail?.objId_Accreditation ?? 0);
        } else {
          CallToast("Something went wrong", false, false, "v2style");
        }
        setAccreditationLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setAccreditationLoading(false);
        CallToast("Something went wrong", false, false, "v2style");
      });
  }

  async function updateLinkUrl(_accreditationId, link) {
    await getAPIData("812", authTokens, {
      optionEnum1: _accreditationId,
      optionStrEnum1: link ?? "",
    })
      .then((response) => {
        if (response?.Success) {
          setPlaceholder(response?.detail?.accreditationLinkUrl);
          CallToast("Uploaded successfully", false, false, "v2style");
          if (isAudit) {
            auditUpdate(
              authTokens,
              _accreditationId,
              0,
              _accreditationId == 0 ? 0 : 1,
              _accreditationId == 0 ? 0 : 1
            );
          }
        } else {
          CallToast("Something went wrong", false, false, "v2style");
        }
        setAccreditationLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setAccreditationLoading(false);
        CallToast("Something went wrong", false, false, "v2style");
      });
  }

  function downloadFile(url, filename) {
    const link = document.createElement("a");
    link.href = url;
    link.download = filename;

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  async function deleteAccreditation(_accreditationId) {
    setDeletingAccreditation(true);
    await postData(authTokens, "ArchiveOrDeleteAccreditation", {
      objId_Accreditation: _accreditationId,
    })
      .then((response) => {
        if (response?.Success) {
          CallToast(
            "Accreditation deleted successfully",
            true,
            false,
            "v2style"
          );
          getAccreditationList();
          setShowSidePanel(false);
        } else {
          CallToast("Something went wrong", false, false, "v2style");
        }
        setDeletingAccreditation(false);
      })
      .catch((err) => {
        console.log(err);
        setDeletingAccreditation(false);
        CallToast("Something went wrong", false, false, "v2style");
      });
  }

  function isAtTop() {
    if (accreditationList.length === 0) return;
    let all_list = accreditationList.reduce((accumulator, currentValue) => {
      return accumulator.concat(currentValue);
    }, []);
    let index = all_list.findIndex(
      (item) =>
        item?.objId_Accreditation ===
        selectedAccreditationItem?.objId_Accreditation
    );

    if (index === 0) {
      return true;
    } else {
      return false;
    }
  }
  function isAtBottom() {
    if (accreditationList.length === 0) return;
    let all_list = accreditationList.reduce((accumulator, currentValue) => {
      return accumulator.concat(currentValue);
    }, []);
    let index = all_list.findIndex(
      (item) =>
        item?.objId_Accreditation ===
        selectedAccreditationItem?.objId_Accreditation
    );

    if (index === all_list.length - 1) {
      return true;
    } else {
      return false;
    }
  }

  async function moveNext() {
    if (accreditationList.length === 0) return;
    let all_list = accreditationList?.reduce((accumulator, currentValue) => {
      return accumulator.concat(currentValue);
    }, []);
    let index = all_list.findIndex(
      (item) =>
        item?.objId_Accreditation ===
        selectedAccreditationItem?.objId_Accreditation
    );

    if (index > -1 && index < all_list.length - 1) {
      setSelectedAccreditationItem(all_list[index + 1]);
      setAccreditationId(all_list[index + 1]?.objId_Accreditation);
    }
  }

  async function movePrev() {
    if (accreditationList.length === 0) return;
    let all_list = accreditationList?.reduce((accumulator, currentValue) => {
      return accumulator.concat(currentValue);
    }, []);
    let index = all_list.findIndex(
      (item) =>
        item?.objId_Accreditation ===
        selectedAccreditationItem?.objId_Accreditation
    );

    if (index > 0) {
      setSelectedAccreditationItem(all_list[index - 1]);
      setAccreditationId(all_list[index - 1]?.objId_Accreditation);
    }
  }

  const [saving, setSaving] = useState(false);

  async function handleSave(_accreditationId, fileId) {
    setSaving(true);
    await postJsonData(authTokens, "UpsertAccreditation", {
      objId_Accreditation: _accreditationId,
      isInProgress: accreditationDetails?.isInProgress ?? false,
      issueDate: convertDateFormat(
        formatDate(accreditationDetails?.issueDate) ?? ""
      ),
      expiryDate: convertDateFormat(
        formatDate(accreditationDetails?.expiryDate) ?? ""
      ),
      expectedCompletionDate: convertDateFormat(
        formatDate(accreditationDetails?.expectedCompletionDate) ?? ""
      ),
      certificateNo: accreditationDetails.certificateNo,
      scope: accreditationDetails.scope,
      region: accreditationDetails?.region,
      accreditationLinkUrl:
        accreditationDetails?.accreditationLinkUrl === ""
          ? null
          : accreditationDetails?.accreditationLinkUrl,
      accreditationTypeID: accreditationDetails?.accreditationType?.id,
      certificationBody: accreditationDetails?.certificationBody,
      objIds_FileUpload:
        uploadedFiles?.map((obj) => obj.objId_FileUpload).join(",") ?? 0,
      auditor: accreditationDetails?.auditor ?? "",
      objId_SendFormQuestionnaire: 0,
    })
      .then((response) => {
        if (response?.Success) {
          CallToast(
            `${_accreditationId === 0 ? "Added" : "Updated"} successfully`,
            false,
            false,
            "v2style"
          );
          // setShowSidePanel(false);
          setInputValue("");
        } else {
          CallToast("Something went wrong", false, false, "v2style");
        }

        if (isAudit) {
          auditUpdate(
            authTokens,
            _accreditationId == 0 ? response?.EntityId : _accreditationId,
            0,
            _accreditationId == 0 ? 0 : 1,
            _accreditationId == 0 ? 0 : 1
          );
        }

        setSaving(false);
        setErrors({
          accreditationTypeEmpty: false,
          accreditationCertificateNoEmpty: false,
          accreditationCertificationBodyEmpty: false,
          accreditationAuditorEmpty: false,
          accreditationScopeEmpty: false,
          accreditationRegionEmpty: false,
          accreditationIssueDateEmpty: false,
          accreditationExpiredDateEmpty: false,
          accreditationExpectedCompletionDateEmpty: false,
        });
      })
      .catch((err) => {
        console.log(err);
        CallToast("Something went wrong", false, false, "v2style");
        setSaving(false);
      });
  }

  const handleUpload = async (event) => {
    event.preventDefault();

    const file = event.target.files[0];

    const fileSizeInMB = file?.size / (1024 * 1024);
    if (fileSizeInMB > 10) {
      setErrorData({
        errMessage: "File size should be less than 10 MB",
        errIcon: "/images/msp/uploading-error-icon.svg",
      });
      return;
    }

    setUploadingFileList(event.target.files);
    const allowedFileTypes = [
      "application/pdf",
      "image/jpeg",
      "image/bmp",
      "image/png",
    ];

    if (file && allowedFileTypes.includes(file.type)) {
      const reader = new FileReader();

      reader.onloadend = async () => {
        const formData = new FormData();
        formData.append("UploadFile", file);
        formData.append("fileType", 0);
        formData.append(
          "accessKey",
          JSON.parse(localStorage.getItem("user_MessageObj"))
        );
        formData.append("objId_SendFormQuestionnaire", 0);
        formData.append("calledFrom", accreditationId === 0 ? 0 : 1);
        if (accreditationId > 0) {
          formData.append("objId", accreditationId);
        }

        try {
          let response = null;

          response = await axios.post(
            `${process.env.REACT_APP_API_BASE_URL}/CC_API/SecOps_FileUpload`,
            formData,
            {
              headers: {
                Authorization: `Bearer ${authTokens}`,
              },
              onUploadProgress: (progressEvent) => {
                const percentCompleted = Math.round(
                  (progressEvent.loaded * 100) / progressEvent.total
                );
                setUploadProgress(percentCompleted);
              },
            }
          );

          if (response?.data?.mr?.Success) {
            CallToast("Uploaded successfully", false, false, "v2style");
            if (accreditationId > 0) {
              setUploadedFiles(response?.data?.files);
            } else {
              if (uploadedFiles && uploadedFiles[0]) {
                const updData = uploadedFiles;
                updData.push(response?.data?.files[0]);
                setUploadedFiles(updData);
              } else {
                setUploadedFiles(response?.data?.files);
              }
              if (isAudit) {
                auditUpdate(
                  authTokens,
                  accreditationId,
                  0,
                  accreditationId == 0 ? 0 : 1,
                  accreditationId == 0 ? 0 : 1
                );
              }
            }
            setErrorData({ ...errorData, errMessage: "" });
          } else {
            console.error("Something went wrong");
          }

          setUploadProgress(0);
        } catch (error) {
          console.error("Upload failed:", error);
          if (error?.code === "ERR_NETWORK") {
            setErrorData({
              errMessage:
                "There was a connection error during uploading. Please try again.",
              errIcon: "/images/msp/wifi-error.svg",
            });
          }
        }
      };

      reader.readAsDataURL(file);
    } else {
      console.error(
        "Invalid file type. Please upload a PDF, JPG, BMP, or PNG file."
      );

      setErrorData({
        errMessage:
          "Invalid file type. Please upload a PDF, JPG, BMP, or PNG file.",
        errIcon: "/images/msp/uploading-error-icon.svg",
      });
    }
  };

  const handleDeleteFile = async (fileId) => {
    const formData = new FormData();
    formData.append(
      "accessKey",
      JSON.parse(localStorage.getItem("user_MessageObj"))
    );
    formData.append("objId_SendFormQuestionnaire", 0);
    formData.append("fileType", 0);
    formData.append("objId_FileUpload", fileId);
    formData.append("objId", accreditationId);

    try {
      let response = null;

      response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/CC_API/SecOps_DeleteFile`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${authTokens}`,
          },
        }
      );

      if (isAudit) {
        auditUpdate(
          authTokens,
          accreditationId,
          0,
          accreditationId == 0 ? 0 : 1,
          accreditationId == 0 ? 0 : 1
        );
      }
      if (response?.Success) {
        CallToast("Deleted successfully", false, false, "v2style");
        setErrorData({ ...errorData, errMessage: "" });

        ///
      } else {
        console.error("Something went wrong");
      }

      setUploadProgress(0);
    } catch (error) {
      console.error("Upload failed:", error);
      if (error?.code === "ERR_NETWORK") {
        setErrorData({
          errMessage:
            "There was a connection error during uploading. Please try again.",
          errIcon: "/images/msp/wifi-error.svg",
        });
      }
    }
  };

  function formatDate(inputDate) {
    if (inputDate && inputDate !== "") {
      if (/^\d{1,2}\/\d{2}\/\d{4}$/.test(inputDate)) {
        return inputDate;
      } else {
        const parts = inputDate?.split("T");
        const datePart = parts[0];
        const [year, month, day] = datePart?.split("-");

        return `${day}/${month}/${year}`;
      }
    } else {
      return inputDate;
    }
  }

  function thisDateNextYear(dateString) {
    const [day, month, year] = dateString.split("/").map(Number);
    const date = new Date(year + 1, month - 1, day);
    return date.getFullYear() === year &&
      date.getMonth() === month - 1 &&
      date.getDate() === day
      ? `${date.getDate()}/${String(date.getMonth() + 1).padStart(
          2,
          "0"
        )}/${date.getFullYear()}`
      : (() => {
          let nextDate = new Date(year + 1, month - 1, day);
          while (isNaN(nextDate.getTime())) {
            day++;
            nextDate = new Date(year + 1, month - 1, day);
          }
          return `${nextDate.getDate()}/${String(
            nextDate.getMonth() + 1
          ).padStart(2, "0")}/${nextDate.getFullYear()}`;
        })();
  }

  async function createUpdateTypeorBody(_type, _value) {
    let accreditationType = _type;
    let accreditationValue = _value;
    await postData(authTokens, "AddAccreditationMember", {
      addType: _type,
      value: _value,
    })
      .then((response) => {
        if (response?.Success) {
          CallToast("Saved successfully", false, false, "v2style");
          getAccreditationDetails(
            selectedAccreditationItem?.objId_Accreditation
          );
          setDropdownLoader({
            typeLoader: false,
            bodyLoader: false,
          });
          if (accreditationType === "body") {
            setAccreditationDetails({
              ...accreditationDetails,
              certificationBody: {
                id: response?.EntityId,
                name: accreditationValue,
              },
            });
          } else {
            setAccreditationDetails({
              ...accreditationDetails,
              accreditationType: {
                id: response?.EntityId,
                name: accreditationValue,
              },
            });
          }
          setCertificateName(null);
        } else {
          CallToast("Something went wrong", false, false, "v2style");
        }
      })
      .catch((err) => {
        setDropdownLoader({
          typeLoader: false,
          bodyLoader: false,
        });
        console.log(err);
        CallToast("Something went wrong", false, false, "v2style");
      });
  }

  return (
    <div className="w-100">
      {/* Page Body */}

      {loading ? (
        <div className="w-45 mx-auto mt-4">
          <AccreditationsLoader />
        </div>
      ) : (
        <div className="slidable-body-new position-relative">
          <div
            className={`main-body hide-scrollbar ${
              showSidePanel || accreditationModalState.showModal
                ? "panel-open"
                : "panel-closed"
            }`}
          >
            <div className="p-3" />

            <div className="d-flex align-items-center justify-content-between">
              <div>
                <div className="f-16">Accreditations</div>
                <div className="f-12 f-darkgrey">
                  Define what security accreditations you already have or are
                  working towards
                </div>
              </div>
              <AttackSurfaceTooltip
                disabled={accessStateValue("AccessState_SecOps") !== 1}
                bottomLeft={true}
                topLeft={false}
                content={<span>{NO_ACCESS_TEXT}</span>}
              >
                <Button
                  className={`hg-blue-btn
                ${
                  accessStateValue("AccessState_SecOps") === 1
                    ? " btn-disable"
                    : ""
                }
                
                `}
                  onClick={() => {
                    setAccreditationId(0);
                    setSelectedAccreditationItem(null);
                    setCertificateName("");
                    setAccreditationDetails({});
                    setUploadedFiles([]);
                    setInputValue("");
                    setShowSidePanel(true);
                    setAccreditationLoading(true);
                    getAccreditationDetails(0);
                  }}
                >
                  {accessStateValue("AccessState_SecOps") === 1 ? (
                    <img
                      height={16}
                      width={16}
                      alt=""
                      src="/images/attack-surface/small-lock.svg"
                      className="mr-2 mb-1"
                    />
                  ) : (
                    <img
                      src="/images/white-plus-icon.svg"
                      alt=""
                      className="mr-2"
                    />
                  )}
                  Add Accreditation
                </Button>
              </AttackSurfaceTooltip>
            </div>

            {accreditationList?.length === 0 && (
              <div className="d-flex flex-column justify-content-center mx-auto w-fit-content mt-5">
                <img
                  src="/images/msp/file-icon.svg"
                  alt=""
                  className="mx-auto"
                  width={40}
                  height={40}
                />

                <div className="f-600 f-grey mt-4 w-fit-content">
                  Add Accreditations
                </div>
                <div className=" f-darkgrey mt-2  w-fit-content">
                  No accreditations yet
                </div>

                <div
                  className="d-flex align-items-center f-blue pointer mt-3  w-fit-content"
                  onClick={() => {
                    setAccreditationId(0);
                    setSelectedAccreditationItem(null);
                    setCertificateName("");
                    setAccreditationDetails({});
                    setUploadedFiles([]);
                    setInputValue("");
                    setShowSidePanel(true);
                    setAccreditationLoading(true);
                    getAccreditationDetails(0);
                  }}
                >
                  <img
                    src="/images/msp/blue-add-icon.svg"
                    alt=""
                    className="mr-2"
                  />
                  Add Accreditation
                </div>
              </div>
            )}

            <div className="border-parent radius-8 mt-4">
              {accreditationList?.map(
                (accreditationItem, accreditationItemIndex) => {
                  return (
                    <div
                      className={`  ${
                        selectedAccreditationItem?.objId_Accreditation ===
                        accreditationItem?.objId_Accreditation
                          ? "bg-lightblue-imp"
                          : ""
                      }   border-item d-flex align-items-center justify-content-between p-3 pointer bg-hover-row`}
                      onClick={() => {
                        setSelectedAccreditationItem(accreditationItem);
                        setShowSidePanel(true);
                      }}
                    >
                      <div className="d-flex align-items-center">
                        <img
                          width={33}
                          src={`/images/msp/${accreditationItem?.icon}`}
                          alt=""
                          className="mr-3 rounded-full"
                        />
                        <div>
                          <div>
                            <span className="f-500 f-black">
                              {accreditationItem?.name}
                            </span>
                            &nbsp; &nbsp;
                            {accreditationItem?.status && (
                              <span
                                style={{
                                  color: accreditationItem?.status?.foreColor,
                                }}
                              >
                                {accreditationItem?.status?.text}
                              </span>
                            )}
                          </div>
                          <div className="f-darkgrey">
                            {accreditationItem?.points?.map((item, idx) => (
                              <span>
                                {idx > 0 ? " • " : ""} {item}
                              </span>
                            ))}{" "}
                          </div>
                        </div>
                      </div>
                      <div className="d-flex align-items-center">
                        {accreditationItem?.linkUrl && (
                          <div className="d-flex align-items-center f-12 f-darkgrey mr-2">
                            <img
                              src={"/images/msp/msp-cert-link-icon.svg"}
                              alt=""
                              className="mr-2  "
                            />
                            Link
                          </div>
                        )}
                        {accreditationItem?.fileUrls[0] && (
                          <div className="d-flex align-items-center f-12 f-darkgrey mr-2">
                            <img
                              src={"/images/msp/msp-cert-file-icon.svg"}
                              alt=""
                              className="mr-2  "
                            />
                            File
                          </div>
                        )}
                      </div>
                    </div>
                  );
                }
              )}
            </div>

            <div className="p-4" />
          </div>

          <div
            className={`sidepanel  ${
              showSidePanel ? "panel-open" : "panel-closed"
            } `}
          >
            {selectedAccreditationItem === null ? (
              <div className="panel-header d-flex align-items-center p-3">
                <div
                  className="w-100 mb-0 font-bold-14 title d-flex justify-content-center"
                  style={{ fontWeight: "600" }}
                >
                  Add Accreditation
                </div>
                <div className="d-flex align-items-center">
                  <div
                    className="pl-2 border-left pointer"
                    onClick={() => {
                      setShowSidePanel(false);
                      setAccreditationId(0);
                      setSelectedAccreditationItem(null);
                      setAccreditationDetails({});
                      setUploadedFiles([]);
                      setCertificateName("");
                      setInputValue("");
                      getAccreditationList();
                    }}
                  >
                    <img
                      width={13}
                      src="/images/attack-surface/cross-icon.svg"
                      alt=""
                      className=" "
                    />
                  </div>
                </div>
              </div>
            ) : (
              <div className="panel-header d-flex align-items-center justify-content-between p-3">
                <div className="d-flex align-items-center">
                  <div
                    className={`mx-2 pointer ${
                      isAtTop() ? "pointer-events-none opacity-40" : ""
                    }`}
                    onClick={() => {
                      movePrev();
                    }}
                  >
                    <img
                      src="/images/msp/secops-chevron-down.svg"
                      className="rotate180"
                      alt=""
                    />
                  </div>
                  <div
                    className={`mx-2 pointer ${
                      isAtBottom() ? "pointer-events-none opacity-40" : ""
                    }`}
                    onClick={() => {
                      moveNext();
                    }}
                  >
                    <img
                      src="/images/msp/secops-chevron-down.svg"
                      className=""
                      alt=""
                    />
                  </div>
                </div>
                <div className="d-flex align-items-center">
                  <div
                    className="f-grey f-500 mr-2 pointer"
                    onClick={() => {
                      if (accessStateValue("AccessState_ActionCentre") > 3) {
                      dispatch(
                        setactioncentredata({
                          refreshCreateTaskModalModule: true,
                        })
                      );
                      const drag = document.getElementById("create-task-modal");
                      drag.style.display = "block";
                      }
                    }}
                  >
                    {accessStateValue("AccessState_ActionCentre") < 3 ? (
                      <img
                        src="/images/msp/lock-icon.svg"
                        alt=""
                        className="mr-3"
                      />
                    ) : (
                      <img
                        src="/images/msp/secops-create-task-icon.svg"
                        alt=""
                        className="mr-3"
                      />
                    )}
                    Create task
                  </div>
                  {accessStateValue("AccessState_SecOps") !== 1 && (
                    <div className="mr-2">
                      {deletingAccreditation ? (
                        <GreyLoader width={13} />
                      ) : (
                        <img
                          src="/images/actioncenter/delete.svg"
                          alt=""
                          className="mx-2 pointer"
                          onClick={() => {
                            deleteAccreditation(
                              selectedAccreditationItem?.objId_Accreditation
                            );
                          }}
                        />
                      )}
                    </div>
                  )}
                  <div
                    className="pl-2 border-left pointer"
                    onClick={() => {
                      setShowSidePanel(false);
                      setAccreditationId(0);
                      setSelectedAccreditationItem(null);
                      setAccreditationDetails({});
                      setUploadedFiles([]);
                      setCertificateName("");
                      setInputValue("");
                      getAccreditationList();
                    }}
                  >
                    <img
                      width={13}
                      src="/images/attack-surface/cross-icon.svg"
                      alt=""
                      className=" "
                    />
                  </div>
                </div>
              </div>
            )}

            {accreditationLoading ? (
              <div className="d-flex align-items-center justify-content-center w-100 h-100">
                <Loader />
              </div>
            ) : (
              <div className="panel-body transparent-scroller">
                <div className="px-4 mt-4">
                  <>
                    <Dropdown className="transparennt-wrapper-dropdown">
                      <Dropdown.Toggle
                        className="w-100"
                        disabled={accessStateValue("AccessState_SecOps") === 1}
                      >
                        <div
                          className={`${
                            errors.accreditationTypeEmpty ? "input-error" : ""
                          } d-flex align-items-center radius-4 grey-border p-2  flex-grow-1 mr-1 ${"highlight-input-with-grey-border"}`}
                        >
                          <div className="f-darkgrey">Type:</div>

                          <input
                            disabled={
                              accessStateValue("AccessState_SecOps") === 1
                            }
                            type="text"
                            className="bg-transparent w-100 border-none pl-2"
                            placeholder="Enter or choose accreditation type..."
                            value={
                              accreditationDetails?.accreditationType?.name ??
                              certificateName
                            }
                            onChange={(e) => {
                              if (
                                accessStateValue("AccessState_SecOps") === 1
                              ) {
                                return;
                              }
                              setCertificateName(e.target.value);
                              setAccreditationDetails({
                                ...accreditationDetails,
                                accreditationType: null,
                                accreditationTypeError: false,
                              });
                            }}
                          />
                          {accreditationDetails?.accreditationType && (
                            <img
                              alt="/images/attack-surface/cross-icon.svg"
                              src="/images/attack-surface/cross-icon.svg"
                              className="pointer"
                              onClick={() => {
                                setCertificateName("");
                                setAccreditationDetails({
                                  ...accreditationDetails,
                                  accreditationType: null,
                                  accreditationTypeError: false,
                                });
                              }}
                            />
                          )}
                        </div>
                      </Dropdown.Toggle>
                      <Dropdown.Menu
                        className="w-100 p-2 transparent-scroller"
                        style={{
                          maxHeight: "200px",
                          overflowY: "auto",
                        }}
                      >
                        {types
                          ?.filter((item) =>
                            item?.name
                              ?.toLowerCase()
                              .includes(certificateName?.toLowerCase() ?? "")
                          )
                          .map((item, index) => {
                            return (
                              <div
                                className="d-flex align-items-center my-1 pointer"
                                onClick={(e) => {
                                  e.target
                                    .closest(".transparennt-wrapper-dropdown")
                                    .click();
                                  setAccreditationDetails({
                                    ...accreditationDetails,
                                    accreditationType: item,
                                  });
                                  setErrors((prev) => ({
                                    ...prev,
                                    accreditationTypeEmpty: false,
                                  }));
                                  setCertificateName(null);
                                }}
                              >
                                <div>
                                  <div className="f-500 f-grey mb-2">
                                    {item?.name}{" "}
                                  </div>
                                </div>
                              </div>
                            );
                          })}

                        {certificateName && (
                          <div
                            className="d-flex align-items-center justify-content-between f-blue my-2 pointer"
                            onClick={() => {
                              createUpdateTypeorBody("type", certificateName);
                              setDropdownLoader({
                                ...dropdownLoader,
                                typeLoader: true,
                              });
                            }}
                          >
                            <div className="d-flex align-items-center">
                              <img
                                src="/images/msp/blue-plus.svg"
                                alt=""
                                className="mr-2"
                              />
                              Add "{certificateName}"
                            </div>
                            <div>
                              {dropdownLoader.typeLoader && (
                                <div className="msp-service-update-loader mr-1"></div>
                              )}
                            </div>
                          </div>
                        )}
                      </Dropdown.Menu>
                    </Dropdown>
                    <div className="f-500 f-darkgrey my-2 mt-4">Details</div>

                    <div className="d-flex align-items-center my-2 w-100">
                      <div className="w-30 f-500 f-grey">
                        Certification In Progress
                      </div>
                      <div className="w-50 pl-3-5rem">
                        <Form.Group controlId={"check" + "" + 99} className="">
                          <Form.Check
                            tabIndex={-1}
                            type="switch"
                            id={
                              "switch-ques1" + "" + 99 + Math.random() * 1000000
                            }
                            style={{
                              transform: "scale(1.2)",
                              paddingLeft: "0.75rem",
                            }}
                            className={`m-0 scale-switch switch-shadow-none ${true} ${
                              accessStateValue("AccessState_SecOps") === 1 &&
                              "cursor-default"
                            } `}
                            checked={
                              accreditationDetails?.isInProgress ?? false
                            }
                            label=""
                            disabled={
                              accessStateValue("AccessState_SecOps") === 1
                            }
                            onChange={(e) => {
                              setAccreditationDetails({
                                ...accreditationDetails,
                                isInProgress: e.target.checked,
                              });
                            }}
                            onClick={(e) => {}}
                          />
                        </Form.Group>
                      </div>
                    </div>

                    {accreditationDetails?.isInProgress ? (
                      <>
                        <div className="d-flex align-items-center w-100 my-2">
                          <div className="f-500 f-grey w-30">
                            Expected Completion Date
                          </div>
                          <div className="w-fit-content dropdown-grey-theme score-question-dropdow n ml-3 w-70">
                            {accessStateValue("AccessState_SecOps") === 1 ? (
                              <div className="f-grey f-500 mx-3 important-width cursor-default">
                                {formatDate(
                                  accreditationDetails?.expectedCompletionDate
                                )
                                  ? formatDate(
                                      accreditationDetails?.expectedCompletionDate
                                    )
                                  : "No Selected"}
                              </div>
                            ) : (
                              <HighgroundDatePicker
                                selected={
                                  formatDate(
                                    accreditationDetails?.expectedCompletionDate
                                  ) ?? ""
                                }
                                placeholder={"Choose date"}
                                icon={"/images/attack-surface/date-icon.svg"}
                                onChange={(e) => {
                                  setAccreditationDetails({
                                    ...accreditationDetails,
                                    expectedCompletionDate: e,
                                  });
                                  setErrors((prev) => ({
                                    ...prev,
                                    accreditationExpectedCompletionDateEmpty: false,
                                  }));
                                }}
                                id={"bhfgevwmtv"}
                                isError={
                                  errors?.accreditationExpectedCompletionDateEmpty
                                }
                              />
                            )}
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="d-flex align-items-center w-100 my-2">
                          <div className="f-500 f-grey w-30">
                            Certificate No.
                          </div>
                          <div
                            className={`${
                              errors.accreditationCertificateNoEmpty
                                ? "input-error radius-4"
                                : ""
                            }  w-fit-content ml-3 w-70 highlight-input p-1`}
                          >
                            <input
                              disabled={
                                accessStateValue("AccessState_SecOps") === 1
                              }
                              type="text"
                              placeholder="Enter certificate No."
                              className="w-100 border-none bg-transparent"
                              value={accreditationDetails?.certificateNo ?? ""}
                              onChange={(e) => {
                                setAccreditationDetails({
                                  ...accreditationDetails,
                                  certificateNo: e.target.value,
                                });
                                setErrors((prev) => ({
                                  ...prev,
                                  accreditationCertificateNoEmpty: false,
                                }));
                              }}
                            />
                          </div>
                        </div>

                        <div className="d-flex align-items-center w-100 my-2">
                          <div className="f-500 f-grey w-30">
                            Certification Body
                          </div>
                          <div
                            className={`${
                              errors.accreditationCertificationBodyEmpty
                                ? "input-error radius-4"
                                : ""
                            }  w-fit-content ml-3 w-70 highlight-input p-1`}
                          >
                            <input
                              disabled={
                                accessStateValue("AccessState_SecOps") === 1
                              }
                              type="text"
                              placeholder="Enter certification body"
                              className="w-100 border-none bg-transparent"
                              value={
                                accreditationDetails?.certificationBody ?? ""
                              }
                              onChange={(e) => {
                                setAccreditationDetails({
                                  ...accreditationDetails,
                                  certificationBody: e.target.value,
                                });
                                setErrors((prev) => ({
                                  ...prev,
                                  accreditationCertificationBodyEmpty: false,
                                }));
                              }}
                            />
                          </div>
                        </div>

                        <div className="d-flex align-items-center w-100 my-2">
                          <div className="f-500 f-grey w-30">Auditor</div>
                          <div
                            className={` ${
                              errors.accreditationAuditorEmpty
                                ? "input-error radius-4"
                                : ""
                            }  w-fit-content ml-3 w-70 highlight-input p-1`}
                          >
                            <input
                              disabled={
                                accessStateValue("AccessState_SecOps") === 1
                              }
                              type="text"
                              placeholder="Enter auditor"
                              className="w-100 border-none bg-transparent"
                              value={accreditationDetails?.auditor ?? ""}
                              onChange={(e) => {
                                setAccreditationDetails({
                                  ...accreditationDetails,
                                  auditor: e.target.value,
                                });
                                setErrors((prev) => ({
                                  ...prev,
                                  accreditationAuditorEmpty: false,
                                }));
                              }}
                            />
                          </div>
                        </div>

                        <div className="d-flex align-items-center w-100 my-2">
                          <div className="f-500 f-grey w-30">Scope</div>
                          <div
                            className={` ${
                              errors.accreditationScopeEmpty
                                ? "input-error radius-4"
                                : ""
                            } w-fit-content ml-3 w-70 highlight-input p-1`}
                          >
                            <input
                              disabled={
                                accessStateValue("AccessState_SecOps") === 1
                              }
                              type="text"
                              placeholder="Enter score"
                              className="w-100 border-none bg-transparent"
                              value={accreditationDetails?.scope ?? ""}
                              onChange={(e) => {
                                setAccreditationDetails({
                                  ...accreditationDetails,
                                  scope: e.target.value,
                                });
                                setErrors((prev) => ({
                                  ...prev,
                                  accreditationScopeEmpty: false,
                                }));
                              }}
                            />
                          </div>
                        </div>

                        <div className="d-flex align-items-center w-100 my-2">
                          <div className="f-500 f-grey w-30">Region</div>
                          <div
                            className={` ${
                              errors.accreditationRegionEmpty
                                ? "input-error radius-4"
                                : ""
                            } w-fit-content ml-3 w-70 highlight-input p-1`}
                          >
                            <input
                              disabled={
                                accessStateValue("AccessState_SecOps") === 1
                              }
                              type="text"
                              placeholder="Enter region"
                              className="w-100 border-none bg-transparent"
                              value={accreditationDetails?.region ?? ""}
                              onChange={(e) => {
                                setAccreditationDetails({
                                  ...accreditationDetails,
                                  region: e.target.value,
                                });
                                setErrors((prev) => ({
                                  ...prev,
                                  accreditationRegionEmpty: false,
                                }));
                              }}
                            />
                          </div>
                        </div>

                        <div className="d-flex align-items-center w-100 my-2">
                          <div className="f-500 f-grey w-30">Issue Date</div>
                          <div className="w-fit-content dropdown-grey-theme score-question-dropdow n ml-3 w-70">
                            {accessStateValue("AccessState_SecOps") === 1 ? (
                              <div className="f-grey f-500 mx-3 important-width cursor-default">
                                {formatDate(accreditationDetails?.issueDate)
                                  ? formatDate(accreditationDetails?.issueDate)
                                  : "No Selected"}
                              </div>
                            ) : (
                              <HighgroundDatePicker
                                selected={
                                  formatDate(accreditationDetails?.issueDate) ??
                                  ""
                                }
                                placeholder={"Choose date"}
                                icon={"/images/attack-surface/date-icon.svg"}
                                onChange={(e) => {
                                  if (e !== "") {
                                    setAccreditationDetails({
                                      ...accreditationDetails,
                                      issueDate: e,
                                      expiryDate: thisDateNextYear(e),
                                    });
                                    setErrors((prev) => ({
                                      ...prev,
                                      accreditationIssueDateEmpty: false,
                                      accreditationExpiredDateEmpty: false,
                                    }));
                                  } else {
                                    setAccreditationDetails({
                                      ...accreditationDetails,
                                      issueDate: e,
                                    });
                                  }
                                }}
                                id={"bhfgevwmtv"}
                                isError={errors?.accreditationIssueDateEmpty}
                              />
                            )}
                          </div>
                        </div>

                        <div className="d-flex align-items-center w-100 my-2">
                          <div className="f-500 f-grey w-30">Expired Date</div>
                          <div className="w-fit-content dropdown-grey-theme score-question-dropdow n ml-3 w-70">
                            {accessStateValue("AccessState_SecOps") === 1 ? (
                              <div className="f-grey f-500 mx-3 important-width cursor-default">
                                {formatDate(accreditationDetails?.expiryDate)
                                  ? formatDate(accreditationDetails?.expiryDate)
                                  : "No Selected"}
                              </div>
                            ) : (
                              <HighgroundDatePicker
                                selected={
                                  formatDate(
                                    accreditationDetails?.expiryDate
                                  ) ?? ""
                                }
                                placeholder={"Choose date"}
                                icon={"/images/attack-surface/date-icon.svg"}
                                onChange={(e) => {
                                  setAccreditationDetails({
                                    ...accreditationDetails,
                                    expiryDate: e,
                                  });
                                  setErrors((prev) => ({
                                    ...prev,
                                    accreditationExpiredDateEmpty: false,
                                  }));
                                }}
                                id={"bhfgevwmtv"}
                                isError={errors?.accreditationExpiredDateEmpty}
                              />
                            )}
                          </div>
                        </div>
                      </>
                    )}

                    <div className="d-flex flex-end w-100 justify-content-end">
                      <AttackSurfaceTooltip
                        disabled={accessStateValue("AccessState_SecOps") !== 1}
                        bottomLeft={true}
                        topLeft={false}
                        content={<span>{NO_ACCESS_TEXT}</span>}
                      >
                        <div>
                          <Button
                            className={`hg-blue-btn
                             ${
                               accessStateValue("AccessState_SecOps") === 1
                                 ? " btn-disable"
                                 : ""
                             }
                             
                             `}
                            onClick={async () => {
                              setSaving(true);
                              let errors = await checkValidation();

                              if (errors > 0) {
                                return;
                              } else {
                                handleSave(accreditationId).then(() => {
                                  getAccreditationList();
                                });
                              }
                            }}
                          >
                            {accessStateValue("AccessState_SecOps") === 1 ? (
                              <img
                                height={16}
                                width={16}
                                alt=""
                                src="/images/attack-surface/small-lock.svg"
                                className="mr-2 mb-1"
                              />
                            ) : (
                              <></>
                            )}{" "}
                            {saving ? "Saving.." : "Save"}
                          </Button>
                        </div>
                      </AttackSurfaceTooltip>
                    </div>

                    {accreditationDetails?.isInProgress ? (
                      <></>
                    ) : (
                      <>
                        <Tab.Container
                          onSelect={(v) => {
                            setSelectedTab(Number(v));
                          }}
                          id="left-tabs-example"
                          defaultActiveKey={selectedTab}
                        >
                          <div
                            className="scenarioName bg-white d-flex align-items-start    pt-2 links "
                            style={{ top: "101px", zIndex: 2 }}
                            id="navHeader"
                          >
                            <Nav.Item key={0} className={"  mt-2"}>
                              <Nav.Link
                                eventKey={0}
                                className={`${
                                  selectedTab === 0 && "active"
                                } mt-`}
                                onClick={() => {}}
                              >
                                Upload File
                              </Nav.Link>
                            </Nav.Item>
                            <Nav.Item key={1} className={"  mt-2"}>
                              <Nav.Link
                                eventKey={1}
                                className={`${
                                  selectedTab === 1 && "active"
                                } mt-`}
                                onClick={() => {}}
                              >
                                Link
                              </Nav.Link>
                            </Nav.Item>
                          </div>

                          <Tab.Content className="mt-4">
                            <Tab.Pane eventKey={0}>
                              {errorData && errorData?.errMessage !== "" && (
                                <div
                                  onClick={() => {}}
                                  className="d-flex align-items-center justify-content-between bg-warning-light p-3 radius-8 mb-2 "
                                >
                                  <div className="d-flex align-items-center f-black f-500 mr-2">
                                    <img
                                      alt=""
                                      src={errorData?.errIcon}
                                      className="mr-2"
                                    />

                                    {errorData && errorData?.errMessage}
                                  </div>
                                  <div
                                    className="d-flex align-items-center f-black f-500 pointer mr-2"
                                    onClick={() => {
                                      setErrorData({
                                        ...errorData,
                                        errMessage: "",
                                      });
                                    }}
                                  >
                                    Dismiss
                                  </div>
                                </div>
                              )}

                              {uploadedFiles &&
                                uploadedFiles[0] &&
                                uploadedFiles?.map((uploadedFile) => {
                                  return (
                                    <div className="radius-4 bg-grey p-3  d-flex align-items-center justify-content-between mb-2">
                                      <div className="d-flex align-items-center">
                                        <img
                                          src="/images/msp/upload-icon-placeholder.svg"
                                          alt=""
                                          className="mr-2"
                                        />
                                        <div>
                                          <div className="f-500">
                                            {uploadedFile?.fileName}{" "}
                                          </div>
                                          <div className="f-darkgrey">
                                            {uploadedFile?.fileSize}
                                          </div>
                                        </div>
                                      </div>
                                      <div className="d-flex align-item-center">
                                        <img
                                          src="/images/msp/policy-download-icon.svg"
                                          alt=""
                                          className="mx-2 pointer"
                                          onClick={() => {
                                            downloadFile(
                                              uploadedFile?.fullPath,
                                              uploadedFile?.fileName
                                            );
                                          }}
                                        />
                                        {accessStateValue(
                                          "AccessState_SecOps"
                                        ) === 1 ? (
                                          <img
                                            height={16}
                                            width={16}
                                            alt=""
                                            src="/images/attack-surface/small-lock.svg"
                                            className="mr-2 mb-1"
                                          />
                                        ) : (
                                          <img
                                            src="/images/actioncenter/delete.svg"
                                            alt=""
                                            className="mx-2 pointer"
                                            onClick={() => {
                                              if (
                                                accessStateValue(
                                                  "AccessState_SecOps"
                                                ) === 1
                                              ) {
                                                return;
                                              }
                                              let updData = uploadedFiles;
                                              updData = updData.filter(
                                                (obj) =>
                                                  obj.objId_FileUpload !==
                                                  uploadedFile?.objId_FileUpload
                                              );
                                              setUploadedFiles(updData);
                                              setAccreditationDetails({
                                                ...accreditationDetails,
                                                files: updData,
                                              });
                                              handleDeleteFile(
                                                uploadedFile?.objId_FileUpload
                                              );
                                            }}
                                          />
                                        )}
                                      </div>
                                    </div>
                                  );
                                })}
                              {/* {accessStateValue("AccessState_SecOps") !== 1 && ( */}
                              <div className="radius-4 border-dashed d-flex flex-column align-items-center justify-content-center w-100 p-3 mb-3 mt-3">
                                {uploadProgress > 0 && uploadProgress < 100 ? (
                                  <>
                                    <UploadFileCircularProgress
                                      progress={100 - uploadProgress}
                                    />

                                    <div className="f-grey my-2 w-fit-content">
                                      {uploadingFileList?.[0]?.name}{" "}
                                    </div>

                                    <div className="f-darkgrey my-2 w-fit-content">
                                      Uploading... {uploadProgress}%
                                    </div>

                                    <Button
                                      className="hg-cancel-btn"
                                      onClick={() => {
                                        uploadRef.current.click();
                                      }}
                                    >
                                      Cancel
                                    </Button>
                                  </>
                                ) : (
                                  <>
                                    <img
                                      width={20}
                                      src="/images/msp/framework-upload-icon.svg"
                                      alt=""
                                    />
                                    <div className="f-grey mt-2 w-fit-content">
                                      Upload file or browse file on your
                                      computer
                                    </div>
                                    <div className="f-darkgrey my-2 w-fit-content">
                                      Format pdf, jpg, bmp, png
                                    </div>
                                    <Button
                                      className={`hg-cancel-btn ${
                                        accessStateValue(
                                          "AccessState_SecOps"
                                        ) === 1 && "cursor-default"
                                      }`}
                                      onClick={() => {
                                        if (
                                          accessStateValue(
                                            "AccessState_SecOps"
                                          ) === 1
                                        ) {
                                          return;
                                        }
                                        uploadRef.current.click();
                                      }}
                                    >
                                      {accessStateValue(
                                        "AccessState_SecOps"
                                      ) === 1 && (
                                        <img
                                          height={16}
                                          width={16}
                                          alt=""
                                          src="/images/attack-surface/small-lock.svg"
                                          className="mr-2 mb-1"
                                        />
                                      )}
                                      Browse file
                                    </Button>
                                  </>
                                )}
                              </div>
                              {/* )} */}
                            </Tab.Pane>
                            <Tab.Pane eventKey={1}>
                              {accreditationDetails?.accreditationLinkUrl ? (
                                <div className="d-flex align-items-center justify-content-between bg-grey radius-4 p-3">
                                  <div className="d-flex align-items-center">
                                    <img
                                      width={33}
                                      src="/images/msp/link-icon.svg"
                                      alt=""
                                      className="mr-3 rounded-full"
                                    />
                                    <div>
                                      <div className="f-500">
                                        {
                                          accreditationDetails?.accreditationLinkUrl
                                        }
                                      </div>
                                    </div>
                                  </div>
                                  <div className="ml-3 d-flex align-items-center">
                                    <div
                                      onClick={() => {
                                        window.open(
                                          fixLinkText(
                                            accreditationDetails?.accreditationLinkUrl
                                          ),
                                          "_blank"
                                        );
                                      }}
                                    >
                                      <img
                                        src="/images/supply-chain/redirect-icon.svg"
                                        alt=""
                                        className="mr-2 pointer"
                                      />
                                    </div>
                                    <div
                                      onClick={() => {
                                        if (
                                          accessStateValue(
                                            "AccessState_SecOps"
                                          ) === 1
                                        ) {
                                          return;
                                        }
                                        setInputValue("");
                                        setAccreditationDetails({
                                          ...accreditationDetails,
                                          accreditationLinkUrl: "",
                                        });
                                        if (accreditationId > 0) {
                                          updateLinkUrl(accreditationId);
                                        }
                                      }}
                                    >
                                      {accessStateValue(
                                        "AccessState_SecOps"
                                      ) === 1 ? (
                                        <img
                                          height={16}
                                          width={16}
                                          alt=""
                                          src="/images/attack-surface/small-lock.svg"
                                          className="mr-2 mb-1"
                                        />
                                      ) : (
                                        <img
                                          src="/images/actioncenter/delete.svg"
                                          alt=""
                                          className="mx-2 pointer"
                                        />
                                      )}
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                <div
                                  className={`d-flex align-items-center radius-4 p-2 flex-grow-1 mr-1 ${true} ${
                                    accessStateValue("AccessState_SecOps") == 1
                                      ? "no-highlight-input-with-grey-border cursor-default"
                                      : "highlight-input-with-grey-border"
                                  }`}
                                >
                                  <div className="f-darkgrey">Link:</div>
                                  <input
                                    disabled={
                                      accessStateValue("AccessState_SecOps") ==
                                      1
                                    }
                                    type="text"
                                    value={inputValue || ""}
                                    onChange={(e) => {
                                      if (
                                        accessStateValue(
                                          "AccessState_SecOps"
                                        ) === 1
                                      ) {
                                        return;
                                      }
                                      setInputValue(
                                        e.target.value.trim() ?? ""
                                      );
                                    }}
                                    style={{ width: "390px" }}
                                    className={`pl-2 bg-transparent border-none flex-grow-1 ${
                                      inputValue !== ""
                                        ? "textBlack"
                                        : "f-darkgrey"
                                    }`}
                                    placeholder={
                                      inputValue !== "" &&
                                      accreditationDetails?.accreditationLinkUrl ===
                                        ""
                                        ? "Enter your link here..."
                                        : ""
                                    }
                                    onBlur={(e) => {
                                      if (
                                        accessStateValue(
                                          "AccessState_SecOps"
                                        ) === 1
                                      ) {
                                        return;
                                      }
                                      if (inputValue !== "") {
                                        if (accreditationId > 0) {
                                          updateLinkUrl(
                                            accreditationId,
                                            inputValue
                                          );
                                        }
                                        setAccreditationDetails({
                                          ...accreditationDetails,
                                          accreditationLinkUrl: inputValue,
                                        });
                                      }
                                    }}
                                  />
                                  {accessStateValue("AccessState_SecOps") ===
                                    1 && (
                                    <img
                                      height={16}
                                      width={16}
                                      alt=""
                                      src="/images/attack-surface/small-lock.svg"
                                      className="mr-2 mb-1"
                                    />
                                  )}
                                </div>
                              )}
                            </Tab.Pane>
                          </Tab.Content>
                        </Tab.Container>
                      </>
                    )}
                  </>

                  <input
                    type="file"
                    accept=".pdf,.jpg,.bmp,.png"
                    onChange={(e) => handleUpload(e)}
                    className="d-none"
                    ref={uploadRef}
                    multiple
                  />
                  <div className="p-2" />
                </div>
              </div>
            )}
          </div>
        </div>
      )}
      <CreateTaskModal />
      {/* <AddEditCertificateModal
        show={showCertificateModal}
        hideModal={() => setShowCertificateModal(false)}
      /> */}
      <Suspense fallback={<div>Loading...</div>}>
        {/* <AddEditAccreditationModal
          show={accreditationModalState.showModal}
          hideModal={() => {
            setAccreditationModalState({
              showModal: false,
            });
          }}
          refreshData={() => {
            getAccreditationList();
            if (
              selectedAccreditationItem?.objId_Accreditation &&
              accreditationModalState.selectedId > 0
            ) {
              setAccreditationLoading(true);
              getAccreditationDetails(
                selectedAccreditationItem?.objId_Accreditation
              );
            }
          }}
          accreditationId={accreditationModalState.selectedId}
          headerName={accreditationModalState.headerName}
        /> */}
      </Suspense>
    </div>
  );
};

export default Accreditations;

const WrapperDropdwon = ({ children, header = "Explanation", data }) => {
  return (
    <Dropdown className="transparennt-wrapper-dropdown">
      <Dropdown.Toggle>{children}</Dropdown.Toggle>
      <Dropdown.Menu className="p-0">
        <header className="d-flex align-items-center bg-grey p-2 justify-content-between">
          <div className="f-grey">
            <img
              alt=""
              src="/images/attack-surface/info-icon.svg"
              className="mr-2 pointer"
            />
            {header}
          </div>
          <div className="d-flex align-items-center pointer">
            <img
              alt=""
              src="/images/attack-surface/expand-icon.svg"
              className="mx-2"
            />
            <img
              alt=""
              src="/images/attack-surface/cross-icon.svg"
              className="mx-2 mr-2"
              onClick={(e) => {
                e.stopPropagation();
                e.target.closest(".transparennt-wrapper-dropdown").click();
              }}
            />
          </div>
        </header>
        <div
          className="f-black p-2"
          style={{
            width: "300px",
          }}
        >
          Attackers are scanning all of the time and getting more sophisticated
          with the use of AI and bots. If you are attracting attention (be it
          scatter gun or targetted), you need to know about it, and proactively
          be alerted to this as well as blocking malicious activity before it
          turns into something more serious. Regular routers cannot perform this
          function, whereas a suitably spec'd firewall can.
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};

const AccreditationsLoader = () => {
  return (
    <ContentLoader
      speed={2}
      width={"100%"}
      height={"100%"}
      viewBox="0 0 1500 900"
      backgroundColor="#F6F8FB"
      foregroundColor="#ecebeb"
    >
      <rect x="0%" y="70" rx="8" ry="8" width="100%" height="140" />
      <rect x="0%" y="212" rx="8" ry="8" width="100%" height="140" />
      <rect x="0%" y="354" rx="8" ry="8" width="100%" height="140" />
      <rect x="0%" y="496" rx="8" ry="8" width="100%" height="140" />
      <rect x="0%" y="638" rx="8" ry="8" width="100%" height="140" />
      <rect x="0%" y="780" rx="8" ry="8" width="100%" height="140" />
      <rect x="0%" y="922" rx="8" ry="8" width="100%" height="140" />
    </ContentLoader>
  );
};
