import React, { useState } from "react";
import { Form, InputGroup } from "react-bootstrap";
import "./inputpassword.scss";
import { BsEyeFill, BsEyeSlashFill } from "react-icons/bs";

function InputPassword(props) {
  const [passwordVisible, setpasswordVisible] = useState(false);

  const changeVisibility = () => setpasswordVisible(!passwordVisible);
  return (
    <div className=" align-items-center form-password">
      <div className="password-label">Password:</div>
      <input
        value={props?.value}
        name={props?.name}
        placeholder={props?.placeholder}
        disabled={props.disabled ? true : false}
        id={props?.id}
        onChange={props.onChange}
        className="input-password-field"
        type={`${passwordVisible ? "text" : "password"}`}
        autoComplete="off"
      />
      {!passwordVisible ? (
        <div>
          <BsEyeFill onClick={changeVisibility} className="mr-2" />
        </div>
      ) : (
        <div>
          <BsEyeSlashFill onClick={changeVisibility} className="mr-2" />
        </div>
      )}
      {props.children}
    </div>
  );
}

export default InputPassword;
