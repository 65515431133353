import React, { useState } from "react";
import "../supplier-profiler.scss";
import { Form } from "react-bootstrap";
import { useEffect } from "react";

const QuestionBlock = ({
  title,
  status,
  selectedAll,
  setSelectAll,
  cancelAll,
  setCancelAll,
  addQuestionList,
  questionList,
}) => {
  const [updateState, setUpdateState] = useState(false);
  const [tempList, setTempList] = useState(title.Question_List);
  const [checkedList, setCheckedList] = useState(questionList);
  // const [checkedList, setCheckedList] = useState(questionList);

  // status = open, ask
  useEffect(() => {
    if (selectedAll) {
      setCheckedList(questionList);
    } else if (cancelAll) {
      setCheckedList([]);
    }
  }, [questionList]);
  console.log("cancl all", cancelAll, selectedAll);
  useEffect(() => {
    if (selectedAll) {
      let tList = tempList;

      tList.map((t) => {
        t.checked = true;
      });
      setTempList(tList);
      let temp = tempList.map((obj) => obj.QuestionId);
      addQuestionList(temp);
      setCheckedList(temp);
    } else if (cancelAll) {
      setCheckedList([]);
      let tList = tempList;

      tList.map((t) => {
        t.checked = false;
      });
      setTempList(tList);
      if (addQuestionList) addQuestionList([]);
    } else {
      setCheckedList(questionList);
      if(setCancelAll) setCancelAll(false);
    }
  }, [selectedAll, cancelAll, questionList]);

  return (
    <div
      className="d-flex justify-content-center"
      style={{ width: "1024px", padding: "24px" }}
    >
      <div className="d-flex flex-column justify-content-center align-items-center w-100">
        <div
          className="d-flex flex-row w-100 justify-space-between header mb-2"
          style={{ justifyContent: "space-between" }}
        >
          <div className="title">
            <div className="f-16">{title.Title}</div>
            <div className="f-darkgrey">{title.Description}</div>
          </div>
        </div>
        <div className=" w-100">
          {/* {title.Question_List.map((f, ind) => { */}
          {tempList?.map((f, ind) => {
            let data = [];
            let obj = f.QuestionDropdownListMembers?.map((dat, index) => {
              data.push({ id: index, value: dat });
            });

            return (
              <div className="flex-row item-question ">
                <div className="d-flex">
                  {status !== "open" && (
                    <Form.Group
                      controlId="checkEleven"
                      className="square-checkbox grey-checkboxs"
                    >
                      <Form.Check
                        type="checkbox"
                        id="switch5"
                        checked={selectedAll ? selectedAll : f.checked}
                        label=""
                        style={{
                          marginRight: "12px",
                          width: "16px",
                          height: "16px",
                        }}
                        onClick={() => {
                          // if (selectedAll) {
                          //   let temp = []; // Create a copy of the array
                          //   temp.push(f.QuestionId);
                          //   setCheckedList(temp);
                          //   addQuestionList(temp);
                          setSelectAll(false);
                          setCancelAll(false);
                          // } else {
                          // setSelectAll(false);
                          let tList = tempList;

                          tList.map((t) => {
                            if (t.QuestionId === f.QuestionId) {
                              t.checked = !f.checked;
                            }
                          });
                          setTempList(tList);

                          if (f.checked) {
                            let temp = [...checkedList]; // Create a copy of the array
                            temp.push(f.QuestionId);
                            setCheckedList(temp);
                            addQuestionList(temp);
                          } else {
                            let temp = checkedList.filter(
                              (id) => id !== f.QuestionId
                            ); // Create a new array without the specified ID
                            addQuestionList(temp);
                            setCheckedList(temp);
                          }
                          // }

                          setUpdateState(!updateState);
                          console.log(selectedAll);
                        }}
                      />
                    </Form.Group>
                  )}
                  <div className="f-grey">{f.QuestionText}</div>
                </div>
                {f.QuestionTypeText === "Select" && (
                  <div>{f.AnswerText ? f.AnswerText : "Choose.."}</div>
                )}
                {f.QuestionTypeText === "MultiSelectDropdown" && (
                  <div
                    className={`${
                      f.AnswerText ? "" : "f-darkgrey"
                    } w-100 bg-white`}
                  >
                    <div
                      className="d-flex flex-row align-items-center w-100"
                      style={{ gap: "4px", flexWrap: "wrap" }}
                    >
                      {f.AnswerArrayText?.map((op) => {
                        return <div className="chip-item">{op}</div>;
                      })}
                      <div className="edit-task bg-white f-darkgrey">Add</div>
                    </div>
                  </div>
                )}
                {f.QuestionTypeText === "YesOrNo" && (
                  <div>
                    {f.AnswerText ? (
                      <div>{f.AnswerText}</div>
                    ) : (
                      <div className={`f-darkgrey bg-white`}>Choose..</div>
                    )}
                  </div>
                )}
                {f.QuestionTypeText === "Text" && (
                  <div
                    className={`${
                      f.AnswerText ? "" : "f-darkgrey"
                    } w-100 bg-white`}
                  >
                    <textarea
                      type="text"
                      rows={
                        f.AnswerText ? Math.ceil(f.AnswerText.length / 70) : 1
                      }
                      className="edit-task w-100 bg-white"
                      value={f.AnswerText}
                      disabled={true}
                      placeholder={"Type"}
                    />
                  </div>
                )}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default QuestionBlock;
