import React, { lazy, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import "./OldLoginStyling.scss";
import {
  Form,
  Spinner,
  Button,
  Row,
  Col,
  InputGroup,
} from "react-bootstrap";
import {   Redirect } from "react-router-dom/cjs/react-router-dom";
import { BsEyeFill, BsEyeSlashFill } from "react-icons/bs";
import { useAuth } from "../context/Auth";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
 
import { useDb } from "../context/db";
import { CallToast } from "../PriceCalculator/modals/CustomToast";
import { AlertDialog } from "../Alert/Alert";
import { getAPIData } from "../../Utils/commonApi";
import { TrackUser } from "../../Utils/SegmentFunctions";
import CryptoJS from 'crypto-js';
import { setuser } from "../../redux/userState/actions";
import { useDispatch } from "react-redux";
import { updateAccessKeyInLocalStorage } from "../../Utils/CommonFunctions";  
import {FullScreenLoader} from "../Common/loader";
import { IP_API_URL } from "../../constants/apis.constants";

const Loginv2 = (props) => {
  const [loading, setLoading] = useState(false);
  const [showUserPassfields, setShowUserPassfields] = useState(false)
  const [showSSOMethod, setShowSSOMethod] = useState(false);
  const { setdbObject } = useDb();
  const dispatch = useDispatch();
  const [passwordShow, setPasswordShow] = useState(false);
const [hmac, setHmac] = useState('')
  const [loggedIn, setLoggedIn] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [totp, setTotp] = useState("123456");
 const [pageLoading, setPageLoading] = useState( false)
  const [accessToken, setAccessToken] = useState(false);

  const [error, setError] = useState(false);
  const [totpError, setTotpError] = useState(false);
 
  const [count, setCount] = useState(0);
  const [disabledBtn, setDisabledBtn] = useState(true);
  const [showQR, setShowQR] = useState(false);
  const CURRENT_URL = useLocation().pathname;
 
  const location = useLocation();
  const { state: loginInformation } = location;

  // const [isRequestSend, setIsRequestSend] = useState(false);
  const {
    authTokens,
    qrCode,
    auth,
    userType,
    setUserType,

    setAuthTokens,
    setQrCode,
    setAuth,
    setMessageEncrypt,
  } = useAuth();

 
  const [formState, setFormState] = useState(0); // default 0
  const [mobileView, setMobileView] = useState(false);

  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState(""); 
  const message = error ? error.description : "";
  const history = useHistory();
 
  const urlParams = (user) => {
    const query = new URLSearchParams(window.location.search);
    const page = query.get("route");
    const tab = query.get("tab");
    let role = "ceo";

    if (user == "cto") {
      role = "cto";
    }

    if (user == "cfo") {
      role = "cfo";
    }

    let urParams = "";
    if (page == "system_settings") {
      if (tab == "billing") {
        localStorage.setItem("redirectTab", "billing");
        urParams +=
          "/" + role + "/dashboard/redirect?route=system_settings&tab=billing";
      } else {
        urParams +=
          "/" +
          role +
          "/dashboard/redirect?route=system_settings&tab=subscriptions";
      }
    } else if (page == "pro_services") {
      urParams += "/" + role + "/dashboard/redirect?route=pro_services";
    } else if (page == "integrations") {
      if (tab == "Patch_Management") {
        localStorage.setItem("redirectTab", "Patch Management");
        urParams +=
          "/" +
          role +
          "/dashboard/redirect?route=integrations&tab=Patch_Management";
      } else if (tab == "Endpoint_Protection") {
        localStorage.setItem("redirectTab", "Endpoint Protection");
        urParams +=
          "/" +
          role +
          "/dashboard/redirect?route=integrations&tab=Endpoint_Protection";
      } else if (tab == "Email_Filtering") {
        localStorage.setItem("redirectTab", "Email Filtering");
        urParams +=
          "/" +
          role +
          "/dashboard/redirect?route=integrations&tab=Email_Filtering";
      } else if (tab == "SIEM_IDS") {
        localStorage.setItem("redirectTab", "SIEM IDS");
        urParams +=
          "/" + role + "/dashboard/redirect?route=integrations&tab=SIEM_IDS";
      } else if (tab == "Web_Filtering") {
        localStorage.setItem("redirectTab", "Web Filtering");
        urParams +=
          "/" +
          role +
          "/dashboard/redirect?route=integrations&tab=Web_Filtering";
      } else if (tab == "Dark_Web_Monitoring") {
        localStorage.setItem("redirectTab", "Dark Web Monitoring");
        urParams +=
          "/" +
          role +
          "/dashboard/redirect?route=integrations&tab=Dark_Web_Monitoring";
      } else if (tab == "Phishing_Training") {
        localStorage.setItem("redirectTab", "Phishing Training");
        urParams +=
          "/" +
          role +
          "/dashboard/redirect?route=integrations&tab=Phishing_Training";
      } else if (tab == "Website_Scan") {
        localStorage.setItem("redirectTab", "Website Scan");
        urParams +=
          "/" +
          role +
          "/dashboard/redirect?route=integrations&tab=Website_Scan";
      } else if (tab == "External_Network_Scan") {
        localStorage.setItem("redirectTab", "External Network Scan");
        urParams +=
          "/" +
          role +
          "/dashboard/redirect?route=integrations&tab=External_Network_Scan";
      } else if (tab == "Incident_Response") {
        localStorage.setItem("redirectTab", "Incident Response");
        urParams +=
          "/" +
          role +
          "/dashboard/redirect?route=integrations&tab=Incident_Response";
      } else {
        urParams += "/" + role + "/dashboard/redirect?route=integrations";
      }
    } else if (page == "Cyber_Essential_Status") {
      urParams +=
        "/" + role + "/dashboard/redirect?route=Cyber_Essential_Status";
    } else if (page == "IR_Plan_Status") {
      urParams += "/" + role + "/dashboard/redirect?route=IR_Plan_Status";
    } else if (page == "Infosec_Policies") {
      urParams += "/" + role + "/dashboard/redirect?route=Infosec_Policies";
    } else if (page == "Risk_Assessment") {
      urParams += "/" + role + "/dashboard/redirect?route=Risk_Assessment";
    } else if (page == "Annual_Cyber_Review") {
      urParams += "/" + role + "/dashboard/redirect?route=Annual_Cyber_Review";
    } else if (page == "Test_Recovery") {
      urParams += "/" + role + "/dashboard/redirect?route=Test_Recovery";
    } else {
      urParams += "/" + role + "/dashboard";
    }
    return urParams;
  };
  async function loginUser(event) {
    if (loading) return;
    event.preventDefault();
    setdbObject();
    setLoading(true);
    if (email == "") {
      setEmailError("Email can't be empty");
      setLoading(false);

      return false;
    }
    if (password == "") {
      setPasswordError("Password is required.");
      setLoading(false);

      return false;
    }
    setTotpError(false);
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    const response = await fetch(IP_API_URL, {
      method: "GET",
    })
      .then((response) => response)
      .catch((err) => {});

    var urlencoded = new URLSearchParams();
    urlencoded.append("grant_type", "password");
    urlencoded.append("username", email);
    urlencoded.append("password", password);
    if (response) {
      const data = await response.json();
      const ipV4Address = data.ip;
      urlencoded.append("ipaddress", ipV4Address);
    } else {
      urlencoded.append("ipaddress", "127.0.0.1");
    }

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
      redirect: "follow",
    };

    const data = await fetch(
      `${process.env.REACT_APP_API_BASE_URL}/ObjectraAPI/Token`,
      requestOptions
    )
      .then((response) => response.json())
      .then(async (data) => {
        if (data.error) {
          setLoading(false);

          setError({
            type: "danger",
            heading: "Login Attempt Failed",
            body: "The email or password is incorrect, please try again",
            description: data.error_description,
          });
          if (
            data.error_description ==
            "The email address provided has not been confirmed."
          ) {
            setFormState(4);
          }
        } else {
          setAccessToken(data.access_token); //Set Token to Local Storage
          setAuthTokens(data.access_token);
          localStorage.setItem("refresh_token", data.refresh_token);
          setLoggedIn(true);
          if (loggedIn) {
            setLoggedIn(false);
            setTimeout(() => {
              setLoggedIn(true);
            }, 10);
          }
          setError(false);
          // setLoading(false);
        }
      })
      .catch((error) => {
        CallToast("500 - Internal Server Error", false, false, "v2style");
        console.log("login error");
        setLoading(false);

        setError(
          <AlertDialog
            type="danger"
            heading="Login Attempt Failed"
            body="The email or password is incorrect, please try again"
          />
        );
      });
  }
  useEffect(() => {
   


    //  Remove opend modal and backdrop
    let _sliding_modal = document.querySelector(".hg-sliding-modal.open");
    let _backdrop = document.getElementById("sliding-modal-backdrop");
    if (_backdrop) {
      _backdrop.remove();
    }
    if (_sliding_modal) {
      _sliding_modal.remove();
    }
  }, []);

  useEffect(() => {
    if (loggedIn) {
      getQRData();
      
    }
  }, [loggedIn]);

  useEffect(() => {
    // Check If Its Microsoft LoGin
    const urlParams = new URLSearchParams(window.location.search);
    // loginByMicrosoft("stanley.tobias@highground.io",`ssoPwd-dd2acc63-2cd9-4401-8a68-a2e2872eb298-0.AQwAAx4APQLTKkiTz78ZwVDCmGPMKt3ZLAFEimii4ocuspiWADU.AgABBAIAAAApTwJmzXqdR4BN2miheQMYAgDs_wUA9P-72RmjPmRQ9BJuWnEmssmbO_7IFtimj0fyVPcIBnYjQv23a4pGJ1tZ2D6tN2IRxj0iJ8N738L9eeJ3U7gzPiEQ8ZrB6As1SCUv0NfVamAeyHlkAmJWp1abaw8kfHI4D1mDkKc8hYmc5VncIi-DLrI_rSBFkExYnY0D-IihG0YGsdS0WhfTg7z_Y2A-Zqk_1YJ-HqyBwE3lPvRFnDdeoU-g1cKMYhJtbHcc-zUtM62n_jtpZ-HsjoPubf9Uwv7f2OflfsCITeNXSsuk_z1Hr-nEjGeOBB2U6W3HCds-ZbEiewbM1XF4BeKwmGKAf3pjSh8-OpVmfgAXdSH8Z4MEvAffXZZ3ybjx5Bml6o-BZZ9cT7PRNlMw8vhSDnBN3qRkO1xqQqhA_6syLEOCKTVRLZwS7UPDW_6yw440qCn98QF9h-cTGzNWYmMtAQrPiwkjayyC-g3xekxkJSOk74MxyxlJE4t7S4CsXKU7NCWSETfO9RzBuB86Jjc9TyPpQ8Ef_qkvoxg3G6UVxjcV1dLh5lREDFBJQ2EkA9BCmhElrFWnk_y-BUYBgxfL3iuLdBe08-Y-EmiZek4stDMt_hRctuIfIcyi0rk6O-kpGGiqhkGAEyxqvUqDhpmoOAD0nN7FOJ3k0a8U7MBbbbSIm1UJaP64HDc0OWAh8QaYZ8DY61BDgAO0qfksekRWUOoSfQ`)

    const oldVersion = urlParams.get("method");
    if(oldVersion  == 'sso'){
      // setShowUserPassfields(true)
      setShowSSOMethod(true);
    }





    const microsoftCode = urlParams.get("code");
    const session_state = urlParams.get("session_state");
    const microsoftLogonEmail = urlParams.get("microsoftLogonEmail");
    if (microsoftCode) {
      setPageLoading(true);
      //1.Coming from Sign up Screen
      if ( microsoftLogonEmail) {
        loginByMicrosoft(
     microsoftLogonEmail,
          `ssoPwd-dd2acc63-2cd9-4401-8a68-a2e2872eb298-${microsoftCode}`
        );
        return;
      }

      getUserDataFromAuthCode(microsoftCode)
        .then((response) => {
          if (!response) {
            setPageLoading(false);
            return
          }

          if (response?.existingHgUser === true) {
            loginByMicrosoft(
              response?.userLogon ?? microsoftLogonEmail,
              `ssoPwd-dd2acc63-2cd9-4401-8a68-a2e2872eb298-${microsoftCode}`
            );
          } else {
            history.push(
              `/signup?code=${microsoftCode}&microsoftLogonEmail=${response?.userLogon}&userGivenName=${response?.userGivenName}`
            );
          }
        })
        .catch(() => {
          setPageLoading(false);
        });
      return;
    }







    let user_token = localStorage.getItem("tokens");
    let user_data = localStorage.getItem("object_Data");
    let existingAuth = localStorage.getItem("userAuthenticated")
      ? Boolean(JSON.parse(localStorage.getItem("userAuthenticated")))
      : null;
    let userAccesKey = localStorage.getItem("user_MessageObj")
      ? JSON.parse(localStorage.getItem("user_MessageObj"))
      : null;

    if (user_token && user_data && existingAuth && userAccesKey) {
      let _data = JSON.parse(user_data);
      if (!CURRENT_URL.includes("redirect")) {
        // Chcek if the redirect word is not included in URL

        if (_data?.UserDetail?.Role?.toLowerCase() === "msp") {
          // If Role is ""MSP"""
          if(window.screen.width < 700){
            history.push("/mspv2/integrations?type=allIntegrations")
          }else{
            history.push("/mspv2/clients");

          }
          return;
        }
        history.push(
          `/${_data?.UserDetail?.Role?.toLowerCase() ?? "ceo"}/dashboard`
        );
     
      }
    } else {
      // setdbObject();
      localStorage.setItem("dasboardLoader", true);
      // window.localStorage.clear()
      // console.log("storage cleared-")
    }
  }, []);

  useEffect(() => {
    if (location?.state?.newEmail) {
      setEmail(location?.state?.newEmail);
      setPassword(location?.state?.newPassword);
    }
  }, []);

  async function getQRData() {
    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${authTokens}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    //
    const data = await fetch(
      `${process.env.REACT_APP_API_BASE_URL}/CC_API/CheckGoogleAuth`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        setHmac(data?.HMAC)
        window.localStorage.setItem(
          "token_time",
          JSON.stringify(Math.floor(Date.now() / 60000))
        );
        if (data?.ByPass2FA === true) {
          submit2FACode();
          return false;
        }
        if (data.mr.Success === false) {
          setQrCode(data.QRImage);
          setShowQR(true);
          setLoading(false);
          setFormState(1);
          // return;
        } else {
          setShowQR(false);
          setLoading(false);
        }
        if (data.error) {
          setLoading(false);

          setError({
            type: "danger",
            heading: "Google Auth Check Failed",
            body: "Google Authentication check has failed. Please contact administrator",
          });
        } else {
          if (data.mr.Message === "Google Auth PIN code is required.") {
            setFormState(1);
            setLoading(false);

            return "totp";
          } else {
            setFormState(1);

            setLoading(false);
          }
        }
      })
      .catch((error) => {
        setLoading(false);
        CallToast("500 - Internal Server Error", false, false, "v2style");
        console.log("qr error");

        setError(
          <AlertDialog
            type="danger"
            heading="Login Attempt Failed"
            body="The email or password is incorrect, please try again"
          />
        );
      });
  }

  // async function SubmitQR(event) {
  //   event.preventDefault();

  //   var myHeaders = new Headers();
  //   myHeaders.append("Authorization", `Bearer ${authTokens}`);

  //   var requestOptions = {
  //     method: "GET",
  //     headers: myHeaders,
  //     redirect: "follow",
  //   };
  //   setIsRequestSend(true);
  //   //
  //   const data = await fetch(
  //     `${process.env.REACT_APP_API_BASE_URL}/ConfirmQRScan`,
  //     requestOptions
  //   )
  //     .then((response) => response.json())
  //     .then((data) => {
  //       setIsRequestSend(false);
  //       if (data.Success == true) {
  //         setLoading(false);

  //         setFormState(1);
  //       } else {
  //         setQrConfirmed(true);
  //       }
  //       if (data.error) {
  //         setError({
  //           type: "danger",
  //           heading: "Login Attempt Failed",
  //           body: "The email or password is incorrect, please try again",
  //         });
  //       } else {
  //       }
  //     })
  //     .catch((error) => {
  //       setLoading(false);
  //       CallToast("500 - Internal Server Error", false, false, "v2style");
  //       setError(
  //         <AlertDialog
  //           type="danger"
  //           heading="Login Attempt Failed"
  //           body="The email or password is incorrect, please try again"
  //         />
  //       );
  //     });
  // }

  async function submit2FACode(event) {
    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${authTokens}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    //
    const data = await fetch(
      `${process.env.REACT_APP_API_BASE_URL}/CC_API/ConfirmGooglePin?pin=${totp}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.ExThrown) {
          CallToast("Something went wrong");
          //Exception - Dont Allow User to Login (Incorrect Credentials)
          setLoading(false);

          setTotpError({
            type: "danger",
            heading: "MFA Attempt Failed",
            body: data.Message,
          });
        } else {
          setCount(count + 1);
          if (data?.Success) {
            let responseData = { ...data };

            getAPIData(0, authTokens, { accessKey: data?.Message })
              .then((res) => {
                TrackUser("User Signed In", {
                  email: res?.UserDetail?.UserName,
                  UserFullName: res?.UserDetail?.FullName,
                });
                if (res?.isTempAccessSession === true) {
                  window.localStorage.setItem("showTempAccessBanner", "true");
                } else {
                  window.localStorage.removeItem("showTempAccessBanner");
                }
                localStorage.setItem("InActivityTimer",0)
                dispatch(
                  setuser({
                    ...res?.UserDetail,
                    CommonComponents: res?.CommonComponents,
                    ScoreAttestation_Icon:
                      res?.CommonComponents?.ScoreAttestation_Icon,
        
                    cyberScoreValue: res?.CommonComponents?.CyberScoreNumber,
                    cyberThreatValue: res?.CommonComponents?.CyberThreatNumber,
                    AccessObjList: res?.mr?.AccessObjList,
                  })
                );

                const secretKey = 'Tb-6uVDaCiUgox2snXRxbhQN6yWFj0Mwq-k4P14j'; // Your secret key
                // const userIdentifier = res?.UserDetail?.UserName; // The email for your user
                const userIdentifier = res?.UserDetail?.UserName; // The email for your user
              
                // Creating HMAC using SHA256 algorithm
                const hash = CryptoJS.HmacSHA256(userIdentifier, secretKey).toString(CryptoJS.enc.Hex);
              
 
                // Intercomm
                window.Intercom('boot', {
                  app_id: 'jaa5e0c1', 
                  email:  res?.UserDetail?.UserName,
                  name: res?.UserDetail?.FirstName  ,
                
                  user_hash:hash
               });

                setUserType(responseData?.EntityId);
                setLoading(false);
                localStorage.setItem("object_Data", JSON.stringify(data));
                /* SET USERS DETAILS AS THEY ARE REQUIRED FOR THE DASHBOARD TO LOAD CORRECTLY */
                console.log("settiing access key");
                updateAccessKeyInLocalStorage(responseData?.Message)
               
                setMessageEncrypt(responseData?.Message); // Setting Access key
                setdbObject(res);
                setAuth(true);
                setFormState(100);
              })
              .catch((err) => {
                setLoading(false);
              });

            if (mobileView) {
              // Set if user on mobile device
              setFormState(5);
            } else {
            }
          } else {
            setTotpError({
              type: "danger",
              heading: "MFA Attempt Failed",
              body: data.Message,
            });
            setLoading(false);

            setLoggedIn(false);
            setAccessToken(false);
          }
        }
      })
      .catch((error) => {
        setLoading(false);
        CallToast("500 - Internal Server Error", false, false, "v2style");
      });
  }
  const authHandler = (err, data) => {
    console.log("err", err);
    console.log("data", data);
  };
  const token = localStorage.getItem("tokens")
    ? JSON.parse(localStorage.getItem("tokens"))
    : null;
  const userAccessKey = localStorage.getItem("user_MessageObj")
    ? JSON.parse(localStorage.getItem("user_MessageObj"))
    : null;

async function loginByMicrosoft(_email,_password){
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
  const response = null
  
  // await fetch("https://geolocation-db.com/json/", {
  //   method: "GET",
  // })
  //   .then((response) => response)
  //   .catch((err) => {});

    var urlencoded = new URLSearchParams();
    urlencoded.append("grant_type", "password");
    urlencoded.append("username",   _email);
    urlencoded.append("password", _password);
    if (response) {
      const ip = await response.json();
      urlencoded.append("ipaddress", ip.IPv4);
    } else {
      urlencoded.append("ipaddress", "127.0.0.1");
    }  

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
      redirect: "follow",
    };
    const data = await fetch(
      `${process.env.REACT_APP_API_BASE_URL}/ObjectraAPI/Token`,
      requestOptions
    ).then((response) => response.json())
    .catch((err) => {}); 
if(data.error){
  // STOP LOADING ITS ERROR
setPageLoading(false)
return
}else{
  setAccessToken(data.access_token); //Set Token to Local Storage
  setAuthTokens(data.access_token);
  localStorage.setItem("refresh_token", data.refresh_token)
}

// Code To ByPass 2 MFA
var MFA_ByPass_myHeaders = new Headers();
MFA_ByPass_myHeaders.append("Authorization", `Bearer ${data.access_token}`);

var MFA_ByPass_requestOptions = {
  method: "GET",
  headers: MFA_ByPass_myHeaders,
  redirect: "follow",
};


const MFA_ByPass_data = await fetch(
  `${process.env.REACT_APP_API_BASE_URL}/CC_API/ConfirmGooglePin?pin=${"999666"}`,
  MFA_ByPass_requestOptions
).then((response) => response.json())
.catch((err) => {});

if(MFA_ByPass_data.ExThrown){
  // STOP LOADING ITS ERROR
  setPageLoading(false)
return
}else{
  let responseData = { ...MFA_ByPass_data };

  getAPIData(0, data.access_token, { accessKey: MFA_ByPass_data?.Message })
    .then((res) => {
      TrackUser("User Signed In", {
        email: res?.UserDetail?.UserName,
        UserFullName: res?.UserDetail?.FullName,
      });
      localStorage.setItem("InActivityTimer",0)
      dispatch(
        setuser({
          ...res?.UserDetail,
          CommonComponents: res?.CommonComponents,
          ScoreAttestation_Icon:
            res?.CommonComponents?.ScoreAttestation_Icon,

          cyberScoreValue: res?.CommonComponents?.CyberScoreNumber,
          cyberThreatValue: res?.CommonComponents?.CyberThreatNumber,
          AccessObjList: res?.mr?.AccessObjList,
        })
      );

      const secretKey = 'Tb-6uVDaCiUgox2snXRxbhQN6yWFj0Mwq-k4P14j'; // Your secret key
      // const userIdentifier = res?.UserDetail?.UserName; // The email for your user
      const userIdentifier = res?.UserDetail?.UserName; // The email for your user
    
      // Creating HMAC using SHA256 algorithm
      const hash = CryptoJS.HmacSHA256(userIdentifier, secretKey).toString(CryptoJS.enc.Hex);
    

      // Intercomm
      window.Intercom('boot', {
        app_id: 'jaa5e0c1', 
        email:  res?.UserDetail?.UserName,
        name: res?.UserDetail?.FirstName  ,
      
        user_hash:hash
     });

      setUserType(responseData?.EntityId);
      setLoading(false);
      localStorage.setItem("object_Data", JSON.stringify(data));
      /* SET USERS DETAILS AS THEY ARE REQUIRED FOR THE DASHBOARD TO LOAD CORRECTLY */
      console.log("settiing access key");
      updateAccessKeyInLocalStorage(responseData?.Message)
     
      setMessageEncrypt(responseData?.Message); // Setting Access key
      setdbObject(res);
      setAuth(true);
      setFormState(100);
    })
    .catch((err) => {
      setLoading(false);
    });
}








}










  if (auth && token && userAccessKey) {
    console.log("ENTERING AUTH");
    const RESTRICTED_URLS = [
      "logout",
      "no-access-to-highground",
      "no-access-to-msp",
      "session_expiry",
    ];

    if (
      loginInformation?.redirectUser &&
      !RESTRICTED_URLS.includes(loginInformation?.redirectUrl?.split("/")?.[1])
    ) {
      console.log("user-redirected");
      history.push(loginInformation?.redirectUrl);

      return <></>;
    }
    // let element = document.getElementById("root").firstChild;
    // element.classList.add("fadeIn");
    // setTimeout(() => {
    //   element.classList.remove("fadeIn");
    // }, 500);

    if (userType === "333333")
      return (
        <Redirect
          to={{
            pathname: `${urlParams("cfo")}`,
            state: { isLogin: true, ...loginInformation },
          }}
        />
      );
    else if (userType == "222222")
      return (
        <Redirect
          to={{
            pathname: `${urlParams("cto")}`,
            state: { isLogin: true, ...loginInformation },
          }} //"/cto/dashboard"
        />
      );
    else if (userType == "111111" || userType == "999999") {
      return (
        <Redirect
          to={{
            pathname: `${urlParams("ceo")}`,
            state: { isLogin: true, ...loginInformation },
          }}
        />
      );
    } else if (userType == "555555")
      return (
        <Redirect
          to={{ pathname: "/mspv2/clients", state: { isLogin: true } }}
        />
      );
  }

  return (<>
 {pageLoading?
  <div
  className="d-flex align-items-center justify-content-center w-100 "
  style={{
    height: "100vh",
  }}
>
  <FullScreenLoader
   />
</div>
 
 
 :<div className="d-flex w-100 h-100 p-4 login-screen">
      <div className="radius-24 border1 bg-grey w-25 p-4 d-flex flex-column overflow-y-auto hide-scrollbar login-screen-side-banner">
        <img alt="" src="/images/mark-lamb.svg" className="w-60 mx-auto mb-5" />
        <div className="f-16 f-500">{<LeftSideWelcomeBannerText />}</div>
        <br />
        <div>
          <div className="f-black f-16 f-700 highground-royal-font">
            Mark Lamb
          </div>
          <div className="f-darkgrey">Founder</div>
        </div>

        {/* MSP Edition  Tag */}
        {false && (
          <div
            className="bg-black radius-8 d-flex align-items-center justify-content-between mt-auto px-2 py-3 pointer"
            onClick={() => {
              window.open(
                "http://25948034.hs-sites-eu1.com/highground.io-msp-ed-elevate-your-security-sales-empower-clients"
              );
            }}
          >
            <div className="d-flex align-items-center">
              <img src="/images/white-theme-logo.svg" alt="" className="mr-3" />
              <div>
                <div className="f-white f-18 f-500 mb-2">MSP Edition</div>
                <div className="f-white-light">
                  If you are an MSP click here
                </div>
              </div>
            </div>
            <img src="/images/white-right-chevron.svg" alt="" />
          </div>
        )}
      </div>
      <div className="w-75 d-flex flex-column login-screen-content">
        {
          // activeScreen === LOGIN_SCREEN.LOGIN
          formState === 0 && (
            <div className="m-auto w-50 login-screen-inputs">
              <div>
                <Row
                  className="align-items-center slideLeft m-0"
                  style={{ paddingTop: "36px" }}
                >
                  <Col md={12} className="text-center pt-5 pb-4">
                    <div className="d-flex align-items-center justify-content-center">
                      <img
                        className="mb-5"
                        alt="HighGround Logo"
                        src="/images/logo/highground/regular.svg"
                      />
                    </div>
                    <div>
                      <h3
                        className="lgn_HeaderText2"
                        style={{
                          fontFamily: "Syne",
                        }}
                      >
                        Login
                      </h3>
                      <p className="f-darkgrey">Log in to your HighGround account</p>
                    </div>
                  </Col>
                  <Col
                    md={10}
                    className="mx-auto"
                    style={{ paddingTop: "1em" }}
                  >
                    
                    {
                      showSSOMethod ?
                      <div className="d-flex align-items-center justify-content-center mt-3">

                      {/* <MicrosoftLogin clientId={"dd2acc63-2cd9-4401-8a68-a2e2872eb298"} authCallback={authHandler}  
                      
                      /> */}

                      <div className="border1 radius-4 d-flex align-items-center justify-content-center f-black f-500 w-100 pointer" style={{padding:"0.65rem"}}
                      
                      onClick={()=>{
                        window.location.href=`https://login.microsoftonline.com/3d001e03-d302-482a-93cf-bf19c150c298/oauth2/v2.0/authorize?client_id=dd2acc63-2cd9-4401-8a68-a2e2872eb298&response_type=code&redirect_uri=${window.location.origin}/login&response_mode=query&scope=User.Read api://dd2acc63-2cd9-4401-8a68-a2e2872eb298/Access.Highground`
                      }}
                      
                      
                      >

                        <img
                        src="/images/msp/microsoft-icon.svg"
                        alt=""
                        className="mr-2"
                        
                        />Sign in with Microsoft

                      </div>
                      </div> :
                      <Form
                      className="userLoginForm"
                      onSubmit={loginUser}
                      autoComplete="none"
                    >
                      <div className="label-sign-up">Email</div>
                      <Form.Group
                        controlId="loginEmail"
                        className={error || emailError ? "border-danger" : "highlight-input-with-grey-border"}
                        // style={{ border: "1px solid #EBEDF1" }}
                      >
                        <InputGroup className="mr-sm-2">
                          <Form.Control
                            className="inputControl"
                            size="lg"
                            type="email"
                            placeholder="Enter Email Here"
                            onChange={(event) => {
                              setEmail(
                                window.sanitizeHGtext(event.target.value)
                              );
                              setEmailError("");
                              setError("");
                            }}
                            value={email}
                          />
                        </InputGroup>
                      </Form.Group>
                      {(emailError ||
                        (message &&
                          message !==
                            "The user name or password is incorrect.")) && (
                        <span className="error-text">
                          {emailError || message}
                        </span>
                      )}
                      <div className="d-flex align-items-center justify-content-between mb-1 mt-3">
                        <div className="f-500 f-black">Password</div>

                        <div
                          className="f-500 pointer f-blue "
                          onClick={() => {
                            history.push("/login/forgot-password");
                          }}
                        >
                          Forgot password?
                        </div>
                      </div>
                      <Form.Group
                        controlId="loginPassword"
                        className={`mt- ${
                          error || passwordError ? "border-danger" : "highlight-input-with-grey-border"
                        }`}
                        // style={{ border: "1px solid #EBEDF1" }}
                      >
                        <InputGroup className="mr-sm-2">
                          <Form.Control
                            className="inputControl"
                            size="lg"
                            placeholder="Enter Password Here"
                            type={passwordShow ? "text" : "password"}
                            value={password}
                            onChange={(event) => {
                              setPassword(
                                window.sanitizeHGtext(event.target.value)
                              );
                              setPasswordError("");
                            }}
                          />
                          <InputGroup.Append>
                            <InputGroup.Text
                              className="passwordIcon gray-eye"
                              onClick={() => setPasswordShow(!passwordShow)}
                            >
                              {passwordShow ? (
                                <BsEyeSlashFill />
                              ) : (
                                <BsEyeFill />
                              )}
                            </InputGroup.Text>
                          </InputGroup.Append>
                        </InputGroup>
                      </Form.Group>
                      {(passwordError || message) && (
                        <span className="error-text">
                          {passwordError || message}
                        </span>
                      )}

                      <div className="mt-4">
                        <Button
                          // disabled={loading}
                          size="lg"
                          variant="blue"
                          className={`form-control btnControl   `}
                          type="submit"
                        >
                          {loading ? (
                            <span>
                              <Spinner
                                size="sm"
                                className="mr8px"
                                animation="border"
                              />{" "}
                              Authenticating
                            </span>
                          ) : (
                            "Continue"
                          )}
                        </Button>
                      </div>
                    </Form>
                    }
                  </Col>
                </Row>
              </div>
              <Row className="m-0">
                <Col md={10} className="pt-4 pb-5 mx-auto ">
                  <div className="text-center d-flex justify-content-center">
                    <div className="f-black d-flex">
                      Don't have an account yet? &nbsp;
                      <div
                        className="f-blue f-500 pointer"
                        onClick={() => {
                          history.push("/signup");
                        }}
                      >
                        Create an account
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          )
        }

        {formState === 1 && (
          // activeScreen === LOGIN_SCREEN.SCAN_QR

          <div className="w-50 m-auto login-screen-inputs">
            <div>
              <Row
                className="align-items-center m-0 "
                style={{ paddingTop: "40px" }}
              >
                <Col className="text-center mx-auto">
                  <div>
                    <img
                      alt=""
                      src="/images/logo-large.svg"
                      className="icon"
                    ></img>
                  </div>
                </Col>
              </Row>

              <Row
                className="align-items-center slideLeft m-0"
                style={{ paddingTop: "2.5%" }}
              >
                <Col md={10} className="text-center pt-5 pb-4 mx-auto">
                  {/* <div> */}
                  <h3 className="lgn_HeaderText2">
                  Enter MFA code
                  </h3>
                  <div className="text-center f-darkgrey">
                    {/* Log in to HighGround account */}
                  </div>
                  <p className="f-darkgrey pt-2">
                    Open{" "}
                    <a
                      target="_blank"
                      className="blueText"
                      href="https://www.google.com/landing/2step/"
                    >
                      Google Authenticator App
                    </a>{" "}
                    and{" "}
                    {showQR
                      ? "scan the QR code below."
                      : "enter the code below."}
                  </p>
                  {/* </div> */}
                </Col>
                {showQR && (
                  <Col md={12}>
                    <div className="qr_code_wrapper d-flex justify-content-center">
                      <img
                        alt=""
                        src={qrCode}
                        className="icon"
                        width="124px"
                        height="124px"
                      ></img>
                    </div>
                  </Col>
                )}
                <Col md={10} className="mx-auto">
                  <Form
                    className="user2FAForm"
                    onSubmit={(e) => {
                      e.preventDefault();
                      submit2FACode();
                    }}
                    autoComplete="none"
                  >
                    <Form.Group
                      controlId="loginTotp"
                      style={{ border: "none" }}
                    >
                      <div className="f-500 my-1">MFA Code</div>
                      <InputGroup className="mr-sm-2">
                        <Form.Control
                          style={{
                            // background: "#F6F8FB",
                            // border: "none",
                            height: "40px",
                          }}
                          type="number"
                          size="lg"
                          autoFocus
                          className="inputControl autofill-gray highlight-input-with-grey-border"
                          placeholder="Enter MFA Code"
                          onChange={(event) => {
                            setTotp(window.sanitizeHGtext(event.target.value));
                            setTotpError("");
                            if (event.target.value.length === 6) {
                              setDisabledBtn(false);
                            } else {
                              setDisabledBtn(true);
                            }
                          }}
                        />
                      </InputGroup>
                    </Form.Group>

                    {totpError && (
                      <span className="error-text">{totpError.body} </span>
                    )}
                    {/* {totpError !== false && false ? (
                      <Form.Control.Feedback type="invalid" className="d-block">
                        <OverlayTrigger
                          placement="bottom"
                          delay={{ show: 200, hide: 250 }}
                          overlay={renderTooltip(totpError.body)}
                        >
                          <span>
                            <img alt="" src="/images/icons/error.svg" />{" "}
                            {totpError.heading}
                          </span>
                        </OverlayTrigger>
                      </Form.Control.Feedback>
                    ) : (
                      ""
                    )} */}

                    <div className="mt-4">
                      <Button
                        className={`form-control btnControl ${
                          disabledBtn ? "disabled-continue-btn" : ""
                        }`}
                        variant="blue"
                        size="lg"
                        type="submit"
                        disabled={disabledBtn}
                      >
                        {loading ? (
                          <span>
                            <Spinner
                              size="sm"
                              className="mr8px"
                              animation="border"
                            />{" "}
                            Authenticating
                          </span>
                        ) : (
                          "Confirm"
                        )}
                      </Button>
                    </div>
                  </Form>
                </Col>
              </Row>
              <div className="d-flex justify-content-center mt-4"></div>

              <Row className="paddingTop- pt-5">
                <Col md={12} className="pt-4 text-center"></Col>
              </Row>
            </div>
          </div>
        )}

        <div className="f-12 f-500 f-darkgrey mt-auto mx-auto">
          © 2024 HighGround. &nbsp; &nbsp;
          <u
            className="pointer"
            onClick={() => {
              window.open(
                "https://www.highground.io/privacy_policy/",
                "_blank"
              );
            }}
          >
            Privacy Policy
          </u>
          &nbsp; &nbsp;
          <u
            className="pointer"
            onClick={() => {
              window.open(
                "https://www.highground.io/highground-terms-and-conditions/",
                "_blank"
              );
            }}
          >
            Terms & Conditions
          </u>
        </div>
      </div>
    </div>}
    </>
  );
};

export default Loginv2;

export const LeftSideWelcomeBannerText = () => {
  return (
    <span
      style={{
        fontSize: "18px",
        fontWeight: "500",
      }}
    >
      {/* I founded's{" "}
      <a
        href="https://www.highground.io/"
        target="_blank"
        rel="noopener noreferrer"
      >
        HighGround.io
      </a>{" "}
      to simplify cybersecurity for SMEs and those working with them. Our
      platform is designed for IT, Risk/Compliance, and service providers, and I
      like to call ourselves your 'cyber–Swiss Army Knife'.
      <br />
      <br /> We provide you with the tools you need, when you need them, to help
      you understand and reduce your risk - without breaking the bank!
      <br /> */}
      I'm an Infrastructure engineer to trade and an MSP owner in scotland. I
      know how difficult it is to get your clients and c-suite to understand
      cyber security and what it is you actually do around here!
      <br/>
      <br/>
      So i'm building
      highground to help make it easier, enjoy :)
    </span>
  );
};





export async function getUserDataFromAuthCode(_authCode) {
  const urlParams = new URLSearchParams(window.location.search);
  const role = urlParams.get("role");
  const url = `${process.env.REACT_APP_API_BASE_URL}/CC_API/SSO?authCode=${_authCode}&callingUrl=${window.location.origin+window.location.pathname}${role ?  `?role=${role}`  :"" }`;
  
  try {
      const response = await fetch(url, {
          method: 'GET',
          headers: {
              'Content-Type': 'application/json'
          }
      });

      if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      console.log(data.toLowerCase())
      if (data.toLowerCase().includes("error")) {
        console.log(data.toLowerCase())
        return null
       
      }
      console.log(parseData(data));
      return parseData(data);
  } catch (error) {
      console.error('Error making API call:', error);
  }
}

function parseData(_data) {
  try {
    return JSON.parse(_data)
  } catch (error) {
    return null
  }
  
}