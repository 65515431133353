import React, { useState } from "react";
import { Button, Form, InputGroup, Modal } from "react-bootstrap";
import LabelledInputPassword from "../../../Theme/LabelledInputPassword";
import "./usermodal.scss";
function UserDetailModal() {
  const [firstNameError, setfirstNameError] = useState(false);
  const [lastNameError, setlastNameError] = useState(false);
  const [firstNameErrorText, setfirstNameErrorText] = useState("");
  const [lastNameErrorText, setlastNameErrorText] = useState("");
  return (
    <Modal
      animation={false}
      show={false}
      dialogClassName="user-details-modal"
      className=""
      aria-labelledby="user-details-modal-title-vcenter"
      centered
      scrollable
    >
      <Modal.Header className="header text-center ">
        <img src="/images/leftarrow.png" alt="" />
        <h1 className="w-100 mb-0 mt-0" style={{ fontSize: "16px" }}>
          Change name
        </h1>
        <span className="cursor-pointer" onClick={{}}>
          <img src="/images/user-detail-close.svg" alt="" />
        </span>
      </Modal.Header>

      <Modal.Body>
        {/* For first name and Last name */}
        {false && (
          <>
            <div className="mt-2">
              <p className="label-input">First Name</p>
              <InputGroup>
                <Form.Control
                  className={`user-name ${firstNameError ? "input-error" : ""}`}
                  id=""
                  type="text"
                  onChange={{}}
                />
              </InputGroup>
              {firstNameError && (
                <span className="error-text">{firstNameErrorText}</span>
              )}
            </div>
            <div className="mt-3">
              <p className="label-input">Last Name</p>
              <InputGroup>
                <Form.Control
                  className={`user-name ${lastNameError ? "input-error" : ""}`}
                  id=""
                  type="text"
                  onChange={{}}
                />
              </InputGroup>
              {lastNameError && (
                <span className="error-text">{lastNameErrorText}</span>
              )}
            </div>
            <div className="empty-div"></div>
          </>
        )}

        {/* For Email Updation */}

        {false && (
          <div>
            <p className="mt-2">
              Your current email is &nbsp;
              <span className="current-email">m.mail@highground.io</span>
            </p>
            <p className="label-input">Please enter your password</p>
            <LabelledInputPassword
              // PrefixComponent={<PrefixPasswordInput />}
              // SuffixComponent={<SuffixComponent />}
              placeholder="Password"
              // error={true}
              // disabled={true}
            />

            <p className="mt-3">
              Please enter a new email and we will send you a verification code
            </p>
            <InputGroup>
              <Form.Control
                className={`user-name new-email`}
                placeholder="New email"
                id=""
                type="email"
                onChange={{}}
              />
            </InputGroup>
          </div>
        )}

        {
          <div>
            <p className="mt-2">
              We just sent you a verification code to &nbsp;
              <span className="current-email">m.mail@highground.io</span>
            </p>
            <InputGroup>
              <Form.Control
                className={`user-name new-email`}
                placeholder="Code"
                id=""
                type="text"
                onChange={{}}
              />
            </InputGroup>
          </div>
        }
      </Modal.Body>

      <Modal.Footer>
        <div className="footer d-flex justify-content-end">
          <Button className="cancel-btn">Cancel</Button>
          <Button className="submit-btn"> Save</Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}

export default UserDetailModal;
