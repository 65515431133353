import React, { useState, useEffect } from "react";
import { Row, Col, Modal, Form, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { CallToast } from "../../../PriceCalculator/modals/CustomToast";
import { WhiteLoader } from "../../../Theme/APILoaders";

import "./companySettingsModals.scss";

export const UpdateRoleModal = ({ show, hideModal, changeRole, setRole }) => {
  const [otherRole, setOtherRole] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const UpdateRole = () => {
    if (otherRole.length == 0) {
      setError("Role is required");
      return false;
    }
    setLoading(true);
    changeRole("Other", otherRole)
      .then((response) => {
        setLoading(false);
        if (response.Success) {
          setRole({ id: 3, category: "Other" });
          hideModal();
          CallToast("Role updated successfully", false, false, "v2style");
        } else {
          CallToast("Something went wrong", false, false, "v2style");
        }
      })
      .catch((err) => {
        console.log(err);
        CallToast("Something went wrong", false, false, "v2style");
      });
  };
  useEffect(() => {
    setError("");
    setOtherRole("");
  }, [show]);

  return (
    <Modal
      animation={false}
      // show={true}
      show={show}
      dialogClassName=" company-settings-name-modal"
      className=" "
      aria-labelledby=" company-settings-name-modal"
      centered
      scrollable
      onHide={hideModal}
    >
      <Modal.Header className="text-center">
        <div className="w-100 mb-0 font-bold-14">Change Role</div>
        <span className="cursor-pointer" onClick={hideModal}>
          <img src="/images/icons/close.svg" alt="" className="close-icon" />
        </span>
      </Modal.Header>
      <Modal.Body>
        <Row>
          {/* Company Name update*/}
          <Col xs={12}>
            <div className="mt-3">
              <p className="label-heading m-0 mb-1">Specify your role</p>
              <Form.Group controlId="formBasicInput">
                <Form.Control
                  className={`${error ? "input-error" : ""}`}
                  as="input"
                  type="text"
                  placeholder="Enter your role"
                  value={otherRole}
                  onChange={(e) => {
                    setError("");
                    setOtherRole(e.target.value);
                  }}
                ></Form.Control>
              </Form.Group>
              {error && <span className="error-text">{error}</span>}
            </div>
          </Col>
        </Row>
      </Modal.Body>

      <Modal.Footer>
        <div className="d-flex justify-content-end">
          <Button className="cancel-btn" onClick={hideModal}>
            Cancel
          </Button>
          <Button className="save-btn" onClick={UpdateRole}>
            {loading ? (
              <>
                <WhiteLoader /> Saving..
              </>
            ) : (
              "Save"
            )}
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};
