import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import "./supplycompany.scss";
import CompanySettingsDropdown from "../../settingsV2/companySettings/companysettingsdrodown/CompanySettingsDropdown";

import { Line } from "react-chartjs-2";
import TechnicalDrillDownCyberComplianceModal from "../../AttackSurface/TechnicalDrillDownCyberComplianceModal";
import { useHistory } from "react-router-dom";
import { GreyLoader } from "../../Theme/APILoaders";
import TechnicalDrillDownCyberScoreModal from "../../AttackSurface/TechnicalDrillDownCyberScoreModal";
import TechnicalDrillDownCyberThreatLevelModal from "../../AttackSurface/TechnicalDrillDownCyberThreatLevelModal";
import { BlueComingSoonTag } from "../Index";
import { ComplianceBoxPageEnum } from "./supplyComplianceManager/SupplyComplianceManager";

const SupplyOverview = (props) => {
  const {
    setSelectedChartFilter,
    chartFilterList,
    selectedChartFilter,
    complianceBoxList,
    chartBoxList,
    filterLoading,
    setFilterLoading,
    getPageData,
    supplierId,
    openComplianceManager,
    goToAttackSurfaceTab,
    // Setup to view Compliance manager drilldown
    
    
    goTo,
    complianceAccessLevel,
    
    accessCE,
    accessIRP,
    accessISP,
    accessRA,
    accesssACR,
    accessBCDR,
    setComplianceSectionPageEnumData,
  } = props;
  const history = useHistory();

  const [
    showTechnicalCyberComplianceModal,
    setShowTechnicalCyberComplianceModal,
  ] = useState(false);

  const [
    showTechnicalDrillDowncyberScoreModal,
    setShowTechnicalDrillDowncyberScoreModal,
  ] = useState(false);

  const [
    showTechnicalDrillDownCyberThreatLevel,
    setShowTechnicalDrillDownCyberThreatLevel,
  ] = useState(false);

  function average(ctx) {
    console.log("ctx", ctx);
    const values = ctx.chart.data.datasets[0].data;
    console.log("values", values);
    return values.reduce((a, b) => a + b, 0) / values.length;
  }
  const _annotation = {
    type: "line",
    borderColor: "black",
    borderDash: [6, 6],
    borderDashOffset: 0,
    borderWidth: 3,
    label: {
      enabled: true,
      content: (ctx) => "Average: " + average(ctx).toFixed(2),
      position: "end",
    },
    scaleID: "y",
    value: (ctx) => {
      console.log(ctx);
      return average(ctx);
    },
  };
  const data = {
    labels: ["1", "2", "3", "4", "5", "6", "7"],
    datasets: [
      {
        data: [0, 0, 0, 0, 0, 0, 0],
        label: "Africa",
        borderColor: "#008fd4",
        backgroundColor: "#008fd409",
        fill: true,
        pointRadius: 0,
        borderWidth: 2,
      },
    ],
  };
  function renderGraphByPoints(_points) {
    let labelList = [..._points].map((item) => item?.Label);
    let valueList = [..._points].map((item) => item?.Value);

    return {
      labels: labelList,
      datasets: [
        {
          data: valueList,
          label: "",
          borderColor: "#008fd4",
          backgroundColor: "#008fd409",
          fill: true,
          pointRadius: 0,
          borderWidth: 2,
        },
      ],
    };
  }

  const options = {
    annotation: {
      annotations: [
        {
          type: "line",
          borderColor: "red",
          borderDash: [1, 1],
          borderDashOffset: 0,
          borderWidth: 1,
          label: {
            enabled: true,
            content: (ctx) => "Average: " + average(ctx).toFixed(2),
            // position: "end",
          },
          scaleID: "x",
          value: (ctx) => {
            console.log(ctx);
            return average(ctx);
          },
        },
      ],
    },
    datalabels: {
      display: false,
    },
    style: {
      strokewidth: 1,
    },
    scales: {
      xAxes: [
        {
          gridLines: {
            display: false,
          },
        },
      ],
      yAxes: [
        {
          gridLines: {
            display: false,
          },
          ticks: {
            display: false,
          },
        },
      ],
    },
    title: {
      display: false,
      //   text: "World population per region (in millions)",
    },
    legend: {
      display: false,
    },
    // tooltips: {
    //   callbacks: {
    //     label: function (tooltipItem) {
    //       return "xcc";
    //     },
    //   },
    // },
    plugins: {
      autocolors: true,
      annotation: {
        annotations: {
          _annotation,
          // line1: {
          //   type: "line",
          //   yMin: 60,
          //   yMax: 60,
          //   borderColor: "rgb(255, 99, 132)",
          //   borderWidth: 2,
          // },
        },
      },
      //   zoom: {
      //     zoom: {
      //       wheel: {
      //         enabled: true,
      //       },
      //       pinch: {
      //         enabled: true,
      //       },
      //       mode: "y",
      //     },
      //   },
      datalabels: {
        backgroundColor: "transparent",
        // backgroundColor: function (context) {
        //   return context.dataset.backgroundColor;
        // },
        // borderRadius: 4,
        color: "transparent",
        // font: {
        //   weight: "bold",
        // },
        // formatter: Math.round,
        // padding: 6,
      },
    },
  };

  return (
    <div className="d-flex flex-column">
      <div className="d-flex flex-column pr-3">
        {/* kpi overview header */}
        <div className="d-flex align-items-center justify-content-between my-3 mt-4">
          <div className="f-16 f-600">KPI's Overview</div>
          <div className="compliance-filter-dropdown mr-2 d-flex align-items-center">
            {filterLoading && <GreyLoader width={12} />}
            <CompanySettingsDropdown
              fieldId={"Value"}
              fieldValue={"Text"}
              selected={selectedChartFilter}
              placeholder="Filters"
              //  showid={true}
              onClick={(id, val) => {
                setSelectedChartFilter({ Value: id, Text: val });
                setFilterLoading(true);
                getPageData(id);
              }}
              data={chartFilterList}
            />
          </div>
        </div>

        {/* graphs */}

        <div className="bg-grey radius-8">
          <Row className="m-0 border-bottom border-parent-right">
            {[...chartBoxList].slice(0, 4).map((chartBox, chartBoxIndex) => {
              return (
                <Col
                  className={`${"border-item"} p-2
                  
                  
                  ${
                    chartBox?.BoxTitle === "CyberCompliance" &&
                    chartBox?.BoxEnabled
                      ? "pointer"
                      : ""
                  }

                  ${
                    chartBox?.BoxTitle === "CyberScore" && chartBox?.BoxEnabled
                      ? "pointer"
                      : ""
                  }

                  ${
                    chartBox?.BoxTitle === "CyberThreat Level" &&
                    chartBox?.BoxEnabled
                      ? "pointer"
                      : ""
                  }
                  `}
                  style={{
                    minHeight: "200px",
                  }}
                  onClick={() => {
                    if (
                      chartBox?.BoxTitle === "CyberCompliance" &&
                      chartBox?.BoxEnabled
                    ) {
                      setShowTechnicalCyberComplianceModal(true);
                    }

                    if (
                      chartBox?.BoxTitle === "CyberScore" &&
                      chartBox?.BoxEnabled
                    ) {
                      setShowTechnicalDrillDowncyberScoreModal(true);
                    }

                    if (
                      chartBox?.BoxTitle === "CyberThreat Level" &&
                      chartBox?.BoxEnabled
                    ) {
                      setShowTechnicalDrillDownCyberThreatLevel(true);
                    }
                  }}
                >
                  <div
                    className={`d-flex align-items-center justify-content-between
                  
                  
                  ${
                    chartBox?.BoxTitle === "CyberCompliance" &&
                    chartBox?.BoxEnabled
                      ? "pointer"
                      : ""
                  }
                  
                  
                  `}
                  >
                    <div className="f-grey">{chartBox?.BoxTitle}</div>
                    <div>
                      {chartBox?.BoxEnabled ? (
                        <img
                          width={14}
                          height={14}
                          src={chartBox?.BoxIcon}
                          alt=""
                        />
                      ) : (
                        <img
                          width={14}
                          height={14}
                          alt=""
                          src="/images/attack-surface/small-lock.svg"
                          // className="ml-2"
                        />
                      )}
                    </div>
                  </div>
                  {chartBox?.BoxEnabled ? (
                    <>
                      <div className="f-18 f-500 my-2">
                        {    chartBoxIndex===0 ?    <BlueComingSoonTag/>   :                chartBox?.BoxScore}
                      </div>
                      <div>
                        {/* <canvas id="line-chart" width="150" height="70"></canvas> */}
                        <Line
                          redraw={true}
                          data={
                            chartBox?.ChartData_Points?.Points?.length > 0
                              ? renderGraphByPoints(
                                  chartBox?.ChartData_Points?.Points
                                )
                              : data
                          }
                          options={options}
                          // plugins={annotation}
                        />
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="f-12 f-darkgrey pt-2">
                        You dont have acces to this data. Update your access
                        level for this supplier
                      </div>
                    </>
                  )}
                </Col>
              );
            })}
          </Row>
          <Row className="m-0  border-parent-right">
            {[...chartBoxList].slice(4, 8).map((chartBox, chartBoxIndex) => {
              return (
                <Col
                  className={`${"border-item"} p-2`}
                  style={{
                    minHeight: "200px",
                  }}
                >
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="f-grey">{chartBox?.BoxTitle}</div>
                    <div>
                      {chartBox?.BoxEnabled ? (
                        <img
                          width={14}
                          height={14}
                          src={chartBox?.BoxIcon}
                          alt=""
                        />
                      ) : (
                        <img
                          width={14}
                          height={14}
                          alt=""
                          src="/images/attack-surface/small-lock.svg"
                          // className="ml-2"
                        />
                      )}
                    </div>
                  </div>
                  {chartBox?.BoxEnabled ? (
                    <>
                      <div className="f-18 f-500 my-2">
                        {chartBoxIndex === 0 && chartBox?.CurrencySymbol}{" "}
                        {chartBox?.BoxScore}
                      </div>
                      <div>
                        {/* <canvas id="line-chart" width="150" height="70"></canvas> */}
                        <Line
                          redraw={true}
                          data={
                            chartBox?.ChartData_Points?.Points?.length > 0
                              ? renderGraphByPoints(
                                  chartBox?.ChartData_Points?.Points
                                )
                              : data
                          }
                          options={options}
                          // plugins={[
                          //   {
                          //     annotation: {
                          //       annotations: { annotation: _annotation },
                          //     },
                          //   },
                          // ]}
                        />
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="f-12 f-darkgrey pt-2">
                        You dont have access to this data. Update your access
                        level for this supplier
                      </div>
                    </>
                  )}
                </Col>
              );
            })}
          </Row>
        </div>
      </div>
      <div className="d-flex flex-column pr-3">
        <div className="d-flex align-items-center justify-content-between mt-5 my-3">
          <div className="f-16 f-600">Compliance Overview</div>
          <div
            className="d-flex align-items-center f-grey pointer"
            onClick={() => {
              // history.push("/Compliance_Manager");
              openComplianceManager();
            }}
          >
            View Compliance Manager
            <img
              alt=""
              src="/images/supply-chain/supply-chevron-right.svg"
              className="mx-2"
            />
          </div>
        </div>
        {/* Compliance options */}
        <div
          className="bg-grey radius-8 d-flex flex-column pointer"
          onClick={() => {}}
        >
          <Row className="m-0 border-bottom border-parent-right">
            {[...complianceBoxList].slice(0, 3).map((Box, boxIndex) => {
              return (
                <Col
                  className="p-3 border-item "
                  style={{
                    minHeight: "95px",
                  }}
                  onClick={()=>{
                    if(!Box?.BoxEnabled)return;
                    if(!Box?.AllowDrillDown) return;
                    setComplianceSectionPageEnumData({
                      pageEnum: ComplianceBoxPageEnum[Box?.BoxTitle],
                      supplierId: supplierId,
                      accessCE: accessCE,
                      accessIRP: accessIRP,
                      accessISP: accessISP,
                      accessRA: accessRA,
                      accesssACR: accesssACR,
                      accessBCDR: accessBCDR,
                    });
                    goTo(Box?.BoxTitle);
                  }}
                >
                  <div className="d-flex align-items-center justify-content-between mb-2">
                    <div
                      className={`f-16 f-500 ${
                        Box?.BoxEnabled ? "f-black" : "f-grey"
                      }`}
                    >
                      {Box?.BoxTitle}
                    </div>
                    <div>
                      <img
                        className="rounded-full-"
                        width={14}
                        height={14}
                        src={
                          Box?.BoxEnabled
                            ? Box?.BoxIcon
                            : "/images/attack-surface/small-lock.svg"
                        }
                        alt=""
                      />
                    </div>
                  </div>

                  {Box?.BoxEnabled ? (
                    <>
                      <div className="d-flex align-items-center justify-content-between ">
                        <div className="f-12 f-grey">{Box?.Row1Label} </div>
                        <div
                          className="f-12 "
                          style={{
                            color: `${Box?.Row1TextColour}`,
                          }}
                        >
                          {Box?.Row1Text}
                        </div>
                      </div>
                      <div className="d-flex align-items-center justify-content-between ">
                        <div className="f-12 f-grey">{Box?.Row2Label}</div>
                        <div
                          className="f-12 "
                          style={{
                            color: `${Box?.Row2TextColour}`,
                          }}
                        >
                          {Box?.Row2Text}
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="f-12 f-darkgrey">
                        You dont have acces to this data. Update your access
                        level for this supplier
                      </div>
                    </>
                  )}
                </Col>
              );
            })}
          </Row>
          {/* Second row */}
          <Row className="m-0 border-parent-right">
            {[...complianceBoxList].slice(3, 6).map((Box, boxIndex) => {
              return (
                <Col
                  className="p-3 border-item "
                  style={{
                    minHeight: "95px",
                  }}
                  onClick={()=>{
                    if(!Box?.BoxEnabled)return;
                    if(!Box?.AllowDrillDown) return;
                    setComplianceSectionPageEnumData({
                      pageEnum: ComplianceBoxPageEnum[Box?.BoxTitle],
                      supplierId: supplierId,
                      accessCE: accessCE,
                      accessIRP: accessIRP,
                      accessISP: accessISP,
                      accessRA: accessRA,
                      accesssACR: accesssACR,
                      accessBCDR: accessBCDR,
                    });
                    goTo(Box?.BoxTitle);
                  }}
                >
                  <div className="d-flex align-items-center justify-content-between mb-2">
                    <div
                      className={`f-16 f-500 ${
                        Box?.BoxEnabled ? "f-black" : "f-grey"
                      }`}
                    >
                      {Box?.BoxTitle}
                    </div>
                    <div>
                      <img
                        className="rounded-full-"
                        width={14}
                        height={14}
                        src={
                          Box?.BoxEnabled
                            ? Box?.BoxIcon
                            : "/images/attack-surface/small-lock.svg"
                        }
                        alt=""
                      />
                    </div>
                  </div>

                  {Box?.BoxEnabled ? (
                    <>
                      <div className="d-flex align-items-center justify-content-between ">
                        <div className="f-12 f-grey">{Box?.Row1Label} </div>
                        <div
                          className="f-12 "
                          style={{
                            color: `${Box?.Row1TextColour}`,
                          }}
                        >
                          {Box?.Row1Text}
                        </div>
                      </div>
                      <div className="d-flex align-items-center justify-content-between ">
                        <div className="f-12 f-grey">{Box?.Row2Label}</div>
                        <div
                          className="f-12 "
                          style={{
                            color: `${Box?.Row2TextColour}`,
                          }}
                        >
                          {Box?.Row2Text}
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="f-12 f-darkgrey">
                        You dont have access to this data. Update your access
                        level for this supplier
                      </div>
                    </>
                  )}
                </Col>
              );
            })}
          </Row>
        </div>
      </div>
      <div style={{ padding: "50px" }}></div>
      <TechnicalDrillDownCyberComplianceModal
        show={showTechnicalCyberComplianceModal}
        hideModal={() => setShowTechnicalCyberComplianceModal(false)}
        supplierId={supplierId}
      />
      <TechnicalDrillDownCyberScoreModal
        show={showTechnicalDrillDowncyberScoreModal}
        hideModal={() => setShowTechnicalDrillDowncyberScoreModal(false)}
        supplierId={supplierId}
        IsSupplier
        goToAttackSurfaceTab={goToAttackSurfaceTab}
      />
      <TechnicalDrillDownCyberThreatLevelModal
        show={showTechnicalDrillDownCyberThreatLevel}
        hideModal={() => setShowTechnicalDrillDownCyberThreatLevel(false)}
        supplierId={supplierId}
        IsSupplier
        goToAttackSurfaceTab={goToAttackSurfaceTab}
      />
    </div>
  );
};

export default SupplyOverview;
