import React, { useState, useEffect } from "react";
import {
  Button,
  Modal,
  Row,
  Col,
  InputGroup,
  Form,
  OverlayTrigger,
  FormControl,
  Tooltip,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import "./index.scss";

const PaymentResponse = (props) => {
  const {
    data,
    isError,
    service,
    bulletPoints,
    isIntegrationPack,
    setPaymentSuccessData,
  } = props;
  useEffect(() => {
    setPaymentSuccessData(data);
  }, [data]);

  return (
    <div className="w-100 mt-5">
      <div className="d24" />
      <div className="d24" />
      <div className="d24" />

      <>
        <div className="text-center">
          {data && data.ResultIcon && <img src="/images/rfail.svg" alt="" />}
          {isError && <img src="/images/rfail.svg" alt="" />}
          {data &&
            (data.status == "succeeded" ||
              (data && data.status == "active")) && (
              <img src="/images/rsuccess.svg" alt="" />
            )}

          <p
            className="txtfail mt-4"
            style={{
              color: `${data && data.ResultColour ? data.ResultColour : ""}`,
            }}
          >
            {data && data.ResultText && data.ResultText}
          </p>
          {isError && (
            <p className="txtfail mt-4" style={{ color: "#F36060" }}>
              Oops Something went wrong!
            </p>
          )}
          {data &&
            (data.status == "succeeded" ||
              (data && data.status == "active")) && (
              <p className="txtfail mt-4" style={{ color: "#40d88e" }}>
                Payment Successful
              </p>
            )}
        </div>
        <div className="responseList mt-4">
          <p>
            {data && data.DescriptionText && data.DescriptionText}
            {isError && data.message}

            {/* <Link to="/create_support_ticket">
                <span className="txt-blue"></span>.
              </Link> */}
          </p>
          {data && data.status == "succeeded" && (
            <p>Thanks for buying {service && service.Title}</p>
          )}

          {data && data.status == "active" && !isIntegrationPack && (
            <p>
              Thank you for upgrading to a Premium Subscription. You can now
              enjoy access to:
            </p>
          )}

          {data &&
            !isIntegrationPack &&
            data.status == "active" &&
            bulletPoints &&
            bulletPoints.map((dat, index) => (
              <div key={index}>
                <p className=" d-flex align-items-center flex-row">
                  {" "}
                  <img
                    src="/images/rsuccess.svg"
                    className="mr-2"
                    alt=""
                  />{" "}
                  {dat}
                </p>
              </div>
            ))}
          {data && isIntegrationPack && data.status == "active" && (
            <p className=" d-flex align-items-center flex-row">
              Thank you for purchasing an Integration Pack.
            </p>
          )}

          <Button
            onClick={() => {
              if (data && data.ButtonText == "Try Again") {
                props.isClose();
              } else {
                props.isClose();
                props.closeModal();
              }
            }}
            className="w-100 blubtn mt-4"
          >
            {data && data.ButtonText
              ? data.ButtonText
              : data && (data.status == "succeeded" || data.status == "active")
              ? "Close"
              : "Try Again"}
          </Button>
          <div className="d24" />
          <div className="d24" />
          <div className="d24" />
          <div className="d24" />
        </div>
      </>
    </div>
  );
};

export default PaymentResponse;
