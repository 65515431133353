import React, { useState, useEffect, useRef } from "react";
import {
  Button,
  Modal,
  Row,
  Col,
  InputGroup,
  Form,
  OverlayTrigger,
  FormControl,
  Tooltip,
} from "react-bootstrap";
import "./index.scss";
import CardDetails from "./CardDetails";
import Loader from "./loader";
import { useAuth } from "../context/Auth";
import { postRequest } from "../../Utils/commonApi";
import { TrackUser } from "../../Utils/SegmentFunctions";

const AddCardForm = (props) => {
  const [saveMethod, setSaveMethod] = useState(false);
  const [isSelectedMethod, setIsSelectedMethod] = useState("");
  const [applyCredit, setApplyCredit] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [showErr, setShowErr] = useState(false);
  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState(
    props.stripeApi.isPlanMonthly ? "Monthly" : "Annual"
  );
  const [selectedPack, setSelectedPack] = useState(
    props.stripeApi.isPackMonthly ? "Monthly" : "Annual"
  );
  const [planName, setPlanName] = useState(false);
  const { authTokens, setAuthTokens } = useAuth();
  const [billingDetails, setBillingDetails] = useState({
    cname: "",
    caddress: "",
    ccountry: "GB",
    creg: "",
    cvat: "",
  });
  const [cardDetails, setcardDetails] = useState({
    cardnumber: "",
    monthyear: "",
    cvc: "",
    cardname: "",
  });
  const { isResponse, isLoading, data, stripeApi, isPackage } = props;
  const [invalidyear, setInvalidyear] = useState(false);
  const rowElement = useRef(null);

  // useEffect(() => {
  //   console.log(isSelectedMethod);
  // }, [isSelectedMethod]);

  const maskDate = (value) => {
    let v = value.replace(/(\w)\//g, function (str, match) {
      return match[0];
    });
    if (v.length >= 3) {
      return `${v.slice(0, 2)}/${v.slice(2)}`;
    }
    return value;
  };

  const submit = (priceId) => {
    rowElement.current.scrollIntoView();
    const billingErr = handleError(billingDetails);
    const cardErr = handleError(cardDetails);

    if (isSelectedMethod) {
      stripeApi.confirm_payment(
        data.CustomerId,
        priceId,
        isSelectedMethod.StripeId,
        data.TaxRateId
      );
    } else {
      if (!billingErr && !cardErr) {
        stripeApi.addCard(
          billingDetails,
          cardDetails,
          data.CustomerId,
          priceId,
          data.TaxRateId
        );
      }
    }
  };

  const handleError = (fields) => {
    setIsSubmit(true);
    let err = 0;
    for (var key in fields) {
      if (fields.hasOwnProperty(key)) {
        if (fields[key] == "") {
          err = err + 1;
        }
      }

      if (key == "cardnumber") {
        if (fields[key].length != 16) {
          err = err + 1;
        }
      }
      if (key == "cvc") {
        if (fields[key].length != 3) {
          err = err + 1;
        }
      }
      if (key == "monthyear") {
        if (fields[key].length != "") {
          const currentMonth = Number(new Date().getMonth() + 1);
          const last2 = new Date().getFullYear().toString().slice(-2);
          const currentYear = Number(last2);
          const inputMonth = Number(fields[key].split("/")[0]);
          const inputYear = Number(fields[key].split("/")[1]);

          if (
            (inputMonth < currentMonth && inputYear <= currentYear) ||
            inputYear < currentYear
          ) {
            err = err + 1;
            setInvalidyear(true);
          } else {
            setInvalidyear(false);
          }
        }
      }
    }

    if (!err) {
      setShowErr(true);
      setIsSubmit(false);
    } else {
      setShowErr(false);
    }
    return err;
  };

  const handleChange = (value) => {
    var detail = value.replace(/\-[^.]+$/, "");
    if (detail == "Monthly ") {
      setPlanName(true);
    } else {
      setPlanName(false);
    }
  };

  const renderTooltip = (message) => (
    <Tooltip className="custom_tooltip proService_tooltip">
      <p className="redText">{message ? message : "Required Field"}</p>
    </Tooltip>
  );

  const applyCreditAmount = async () => {
    const intentId = data.PaymentIntentId;
    setApplyCredit(!applyCredit);
    const res = await postRequest(
      authTokens,
      `TogglePaymentIntentDiscount?PaymentIntentId=${intentId}&Discount=${!applyCredit}`,
      ""
    );
  };

  const handleDropdown = (plan, isPackage) => {
    //  stripeApi.isPackMonthly
    if (isPackage) {
      if (plan == "Monthly") {
        props.packMonthly(true);
      } else {
        props.packMonthly(false);
      }
    } else {
      if (plan == "Monthly") {
        props.planMonthly(true);
      } else {
        props.planMonthly(false);
      }
    }
  };

  return (
    <>
      {/* <Loader /> */}
      <Col md={7} className="p-0 annual_wrapper">
        <div className="mt40" />
        <div className="d-flex align-items-center" ref={rowElement}>
          {data && data.Image && (
            <img
              width="48px"
              src={
                data
                  ? `/images/ProServices/${data && data.Image}`
                  : "/images/ProServices/CyberEssentials_SmallIcon.svg"
              }
            />
          )}

          <h1 className={`m-0 ${data.Image && "ml-3"}`}>
            {data && data.Title}
          </h1>
        </div>
        <div className="d24" />

        {data && (data.Description || data.BulletPoints) && (
          <div className="list_wrapper">
            <p className="listtxt">{data.Description}</p>
            {data.BulletPoints &&
              data.BulletPoints.map((dat, index) => (
                <p
                  key={index}
                  className="listtxt d-flex align-items-center flex-row"
                >
                  {" "}
                  <img
                    src="/images/prem_check.svg"
                    className="mr-2"
                    alt=""
                  />{" "}
                  {dat}
                </p>
              ))}
            <hr />
          </div>
        )}

        {data && data.PaymentService_List && (
          <div className="list_wrapper">
            <p>Services</p>

            {data.PaymentService_List.map((val, i) => (
              <div key={i} className="order_r mb-2">
                <div>{val.Name}</div>
                <div>{val.Price}</div>
              </div>
            ))}

            {/* <div className="order_r">
            <div>Preperation + Submission</div>
            <div>£995</div>
          </div> */}
            <div className="d24" />
            <hr />
          </div>
        )}
        <div className="bill_wrapper">
          <div className="d24" />

          <div>
            <p>Billing Interval</p>
            {/* {
                stripeApi.isPackMonthly ? data && data.MonthlyForm.PriceId : data && data.AnnualForm && data.AnnualForm.PriceId
              } */}
            {isPackage ? (
              <InputGroup className={`grey-select`}>
                <Form.Control
                  as="select"
                  name=""
                  className="bgNone pt-1 pb-1 h32"
                  defaultValue={
                    stripeApi.isPackMonthly && data ? "Monthly" : "Annual"
                  }
                  onChange={(e) => handleDropdown(e.target.value, isPackage)}
                >
                  {data &&
                    data.BillingIntervalList &&
                    data.BillingIntervalList.map((element) => (
                      <option
                        selected={
                          element.Name == selectedPack ? element.Name : false
                        }
                        value={element.Name}
                      >
                        {element.Name + " - " + element.Price}
                      </option>
                    ))}
                </Form.Control>
              </InputGroup>
            ) : (
              <InputGroup className={`grey-select`}>
                <Form.Control
                  as="select"
                  name=""
                  className="bgNone pt-1 pb-1 h32"
                  onChange={(e) => handleDropdown(e.target.value)}
                >
                  {data &&
                    data.BillingIntervalList &&
                    data.BillingIntervalList.map((element) => (
                      <option
                        selected={
                          element.Name == selectedPlan ? element.Name : false
                        }
                        value={element.Name}
                      >
                        {element.Name + " - " + element.Price}
                      </option>
                    ))}
                </Form.Control>
              </InputGroup>
            )}

            <div className="d24" />
            <hr />
            <div className="d24" />
          </div>

          <p className="m-0">Payment Method</p>
          <div className="d24" />
          {/* style={{ height: "100px", overflowY: "scroll" }} */}
          <div className="payment_method_wrapper flex-wrap">
            <div
              onClick={() => setIsSelectedMethod(false)}
              className={`mb-3 greyBorderCont mr8px position-relative flex-fill w30 crsrpoint ${
                !isSelectedMethod && "brdBlu"
              } `}
            >
              <Form.Group
                controlId={"check"}
                className="round-checkbox blue-checkbox"
              >
                <Form.Check
                  type="radio"
                  name="stripeId"
                  label=""
                  value={isSelectedMethod ? true : false}
                  checked={!isSelectedMethod ? true : false}
                />
              </Form.Group>
              <div className="center-container text-center">
                <div className="heading3">
                  <img
                    src="/images/subscription/addcard.svg"
                    className=""
                    alt=""
                  />
                </div>
                <div className="heading3 txt-blue txtcard">Add Card</div>
              </div>
            </div>

            {data &&
              data.PaymentMethod_List &&
              data.PaymentMethod_List.map((val, i) => (
                <div
                  key={i}
                  onClick={() => setIsSelectedMethod(val)}
                  className={`mb-3 crsrpoint greyBorderCont mr8px position-relative  flex-fill w30 ${
                    val.Id == isSelectedMethod.Id && "brdBlu"
                  }`}
                >
                  <Form.Group
                    controlId={`chec2${i}`}
                    className="round-checkbox blue-checkbox"
                  >
                    <Form.Check
                      name="stripeId"
                      type="radio"
                      value={val.Id == isSelectedMethod.Id ? true : false}
                      label=""
                      checked={isSelectedMethod.Id == val.Id ? true : false}
                    />
                  </Form.Group>
                  <div className="center-container text-center">
                    <img
                      src={`/images/${val.Icon ? val.Icon : "visa.svg"}`}
                      width="30"
                      alt=""
                    />
                    <div className="heading3  pb-1">
                      <div className="heading3 txtcardspace textUpper">
                        {val.CardType}
                      </div>
                    </div>
                    <span className="gryClr">{val.CardNumber}</span>
                  </div>
                </div>
              ))}
          </div>
          <div className="d24" />

          {/* payment method div */}
          {!isSelectedMethod && (
            <div>
              <hr />
              <div className="d24" />
              <p className="m-0">Billing Address</p>
              {/* <CardDetails /> */}
              <div className="d24" />
              <Form.Group>
                <InputGroup className="grey-input">
                  <FormControl
                    name="name"
                    value={billingDetails.cname}
                    onChange={(e) =>
                      setBillingDetails({
                        ...billingDetails,
                        cname: e.target.value,
                      })
                    }
                    className={`${
                      isSubmit && !showErr && !billingDetails.cname && "bgred"
                    }`}
                    placeholder="Company name"
                  />
                  {isSubmit && !showErr && !billingDetails.cname && (
                    <OverlayTrigger
                      placement="bottom"
                      overlay={renderTooltip()}
                    >
                      <InputGroup.Text
                        style={{ height: "32px" }}
                        className="bgred"
                      >
                        <img src="/images/card_error.svg" />
                      </InputGroup.Text>
                    </OverlayTrigger>
                  )}
                </InputGroup>
              </Form.Group>
              <Form.Group>
                <InputGroup className="grey-input mt-10px">
                  <FormControl
                    name="name"
                    value={billingDetails.caddress}
                    onChange={(e) =>
                      setBillingDetails({
                        ...billingDetails,
                        caddress: e.target.value,
                      })
                    }
                    className={`${
                      isSubmit &&
                      !showErr &&
                      !billingDetails.caddress &&
                      "bgred"
                    }`}
                    placeholder="Company address"
                  />
                  {isSubmit && !showErr && !billingDetails.caddress && (
                    <OverlayTrigger
                      placement="bottom"
                      overlay={renderTooltip()}
                    >
                      <InputGroup.Text
                        style={{ height: "32px" }}
                        className="bgred"
                      >
                        <img src="/images/card_error.svg" />
                      </InputGroup.Text>
                    </OverlayTrigger>
                  )}
                </InputGroup>
              </Form.Group>
              {data.CountryList && (
                <Form.Group>
                  <InputGroup className="mt-10px grey-select customerStyle">
                    <Form.Control
                      style={{ height: "32px" }}
                      as="select"
                      className={"pt-0 pb-0"}
                      defaultValue={"GB"}
                      onChange={(e) =>
                        setBillingDetails({
                          ...billingDetails,
                          ccountry: e.target.value,
                        })
                      }
                    >
                      {data.CountryList.map((val, i) => (
                        <option key={i} value={val.code}>
                          {val.country}
                        </option>
                      ))}
                    </Form.Control>
                  </InputGroup>
                </Form.Group>
              )}
              {/* <Form.Group>
                <InputGroup className="grey-input mt-10px">
                  <FormControl
                    name="name"
                    value={billingDetails.ccountry}
                    onChange={(e) =>
                      setBillingDetails({
                        ...billingDetails,
                        ccountry: e.target.value,
                      })
                    }
                    className={`${
                      isSubmit &&
                      !showErr &&
                      !billingDetails.ccountry &&
                      "bgred"
                    }`}
                    placeholder="Country"
                  />
                  {isSubmit && !showErr && !billingDetails.ccountry && (
                    <OverlayTrigger
                      placement="bottom"
                      overlay={renderTooltip()}
                    >
                      <InputGroup.Text
                        style={{ height: "32px" }}
                        className="bgred"
                      >
                        <img src="/images/card_error.svg" />
                      </InputGroup.Text>
                    </OverlayTrigger>
                  )}
                </InputGroup>
              </Form.Group> */}
              <Form.Group>
                <InputGroup className="grey-input mt-10px">
                  <FormControl
                    name="name"
                    value={billingDetails.creg}
                    onChange={(e) =>
                      setBillingDetails({
                        ...billingDetails,
                        creg: e.target.value,
                      })
                    }
                    className={`${
                      isSubmit && !showErr && !billingDetails.creg && "bgred"
                    }`}
                    placeholder="Company registration"
                  />
                  {isSubmit && !showErr && !billingDetails.creg && (
                    <OverlayTrigger
                      placement="bottom"
                      overlay={renderTooltip()}
                    >
                      <InputGroup.Text
                        style={{ height: "32px" }}
                        className="bgred"
                      >
                        <img src="/images/card_error.svg" />
                      </InputGroup.Text>
                    </OverlayTrigger>
                  )}
                </InputGroup>
              </Form.Group>
              <Form.Group>
                <InputGroup className="grey-input mt-10px">
                  <FormControl
                    name="name"
                    value={billingDetails.cvat}
                    onChange={(e) =>
                      setBillingDetails({
                        ...billingDetails,
                        cvat: e.target.value,
                      })
                    }
                    className={`${
                      isSubmit && !showErr && !billingDetails.cvat && "bgred"
                    }`}
                    placeholder="VAT"
                  />
                  {isSubmit && !showErr && !billingDetails.cvat && (
                    <OverlayTrigger
                      placement="bottom"
                      overlay={renderTooltip()}
                    >
                      <InputGroup.Text
                        style={{ height: "32px" }}
                        className="bgred"
                      >
                        <img src="/images/card_error.svg" />
                      </InputGroup.Text>
                    </OverlayTrigger>
                  )}
                </InputGroup>
              </Form.Group>

              <div className="d24" />
              <hr />
              <div className="d24" />
              <p className="m-0">Payment Information</p>
              <div className="d24" />
              <Form.Group className="card_number_wrapper">
                <InputGroup className="grey-input cardnumber">
                  <FormControl
                    name="name"
                    value={cardDetails.cardnumber}
                    type="number"
                    onBlur={() => {
                      TrackUser("Payment Info Entered");
                    }}
                    onChange={(e) => {
                      if (e.target.value.length == 17) return false;

                      setcardDetails({
                        ...cardDetails,
                        cardnumber: e.target.value,
                      });
                    }}
                    className={`${
                      isSubmit &&
                      !showErr &&
                      (!cardDetails.cardnumber ||
                        cardDetails.cardnumber.length !== 16)
                        ? "carderr1"
                        : "cardicon"
                    }`}
                    placeholder="Card number"
                  />
                  {isSubmit &&
                    !showErr &&
                    (!cardDetails.cardnumber ||
                      cardDetails.cardnumber.length !== 16) && (
                      <OverlayTrigger
                        placement="bottom"
                        overlay={renderTooltip(
                          !cardDetails.cardnumber
                            ? "Required Field"
                            : cardDetails.cardnumber.length !== 16
                            ? "Invalid Card Number"
                            : ""
                        )}
                      >
                        <InputGroup.Text className="bgred toltip2">
                          <img src="/images/card_error.svg" />
                        </InputGroup.Text>
                      </OverlayTrigger>
                    )}
                </InputGroup>
                <InputGroup className="grey-input datemonth">
                  <FormControl
                    name="name"
                    maxLength="5"
                    value={cardDetails.monthyear}
                    onChange={(e) => {
                      if (e.target.value.length == 6) return false;
                      e.target.value = e.target.value
                        .replace(
                          /[^0-9]/g,
                          "" // To allow only numbers
                        )
                        .replace(
                          /^([2-9])$/g,
                          "0$1" // To handle 3 > 03
                        )
                        .replace(
                          /^(1{1})([3-9]{1})$/g,
                          "0$1/$2" // 13 > 01/3
                        )
                        .replace(
                          /^0{1,}/g,
                          "0" // To handle 00 > 0
                        )
                        .replace(
                          /^([0-1]{1}[0-9]{1})([0-9]{1,2}).*/g,
                          "$1/$2" // To handle 113 > 11/3
                        );
                      setcardDetails({
                        ...cardDetails,
                        monthyear: maskDate(e.target.value),
                      });
                      setInvalidyear(false);
                    }}
                    className={`${
                      isSubmit &&
                      !showErr &&
                      (!cardDetails.monthyear ||
                        cardDetails.monthyear.length !== 5 ||
                        invalidyear)
                        ? "carderr1"
                        : "cardicon2"
                    }`}
                    placeholder="MM/YY"
                  />
                  {isSubmit &&
                    !showErr &&
                    (!cardDetails.monthyear ||
                      cardDetails.monthyear.length !== 5 ||
                      invalidyear) && (
                      <OverlayTrigger
                        placement="bottom"
                        overlay={renderTooltip(
                          !cardDetails.monthyear
                            ? "Required Field"
                            : cardDetails.monthyear.length !== 5 || invalidyear
                            ? "Invalid Date"
                            : ""
                        )}
                      >
                        <InputGroup.Text className="bgred toltip2">
                          <img src="/images/card_error.svg" />
                        </InputGroup.Text>
                      </OverlayTrigger>
                    )}
                </InputGroup>
                <InputGroup className="grey-input csvnumber">
                  <FormControl
                    name="name"
                    type="number"
                    value={cardDetails.cvc}
                    onChange={(e) => {
                      if (e.target.value.length == 4) return false;
                      setcardDetails({
                        ...cardDetails,
                        cvc: e.target.value,
                      });
                    }}
                    className={`${
                      isSubmit &&
                      !showErr &&
                      (!cardDetails.cvc || cardDetails.cvc.length !== 3) &&
                      "carderr1"
                    }`}
                    placeholder="CVC"
                  />
                  {isSubmit &&
                    !showErr &&
                    (!cardDetails.cvc || cardDetails.cvc.length !== 3) && (
                      <OverlayTrigger
                        placement="bottom"
                        overlay={renderTooltip(
                          !cardDetails.cvc
                            ? "Required Field"
                            : cardDetails.cvc.length !== 3
                            ? "Invalid CVC"
                            : ""
                        )}
                      >
                        <InputGroup.Text className="bgred toltip2">
                          <img src="/images/card_error.svg" />
                        </InputGroup.Text>
                      </OverlayTrigger>
                    )}
                </InputGroup>
              </Form.Group>
              <Form.Group>
                <InputGroup className="grey-input">
                  <FormControl
                    name="name"
                    value={cardDetails.cardname}
                    onChange={(e) =>
                      setcardDetails({
                        ...cardDetails,
                        cardname: e.target.value,
                      })
                    }
                    className={`${
                      isSubmit && !showErr && !cardDetails.cardname && "bgred"
                    }`}
                    placeholder="Name on card"
                  />
                  {isSubmit && !showErr && !cardDetails.cardname && (
                    <OverlayTrigger
                      placement="bottom"
                      overlay={renderTooltip()}
                    >
                      <InputGroup.Text
                        style={{ height: "32px" }}
                        className="bgred"
                      >
                        <img src="/images/card_error.svg" />
                      </InputGroup.Text>
                    </OverlayTrigger>
                  )}
                </InputGroup>
              </Form.Group>

              <div className="d24" />
              <hr />
              <div className="d24" />
              <div className="d-flex flex-direction-row justify-content-start">
                {/* <Form.Check
                  type="switch"
                  id="switchpayment"
                  checked={saveMethod}
                  className="m-0"
                  onClick={() => setSaveMethod(!saveMethod)}
                /> */}
                <p>Your card details will be saved for the subscription</p>
              </div>
            </div>
          )}

          <div className="mt40" />
        </div>
      </Col>

      <Col md={5} className={`pr ${isPackage ? "" : "d-none"}  `}>
        <div className="mt40" />
        <h1>Order Summary</h1>
        <div className="order_summary">
          <div className="order_r">
            <div>
              {isPackage && stripeApi.isPackMonthly && data && data.MonthlyForm
                ? data.MonthlyForm.OrderSummaryName
                : data && data.AnnualForm
                ? data.AnnualForm.OrderSummaryName
                : ""}
            </div>
            <div>
              {isPackage && stripeApi.isPackMonthly
                ? data && data.MonthlyForm && data.MonthlyForm.Total_Text
                : data && data.AnnualForm && data.AnnualForm.Total_Text}
            </div>
          </div>

          <div className="gtxt">
            {/* {props.data.package && props.data.package
                ? `${props.data.package.MonthlyCost}`
                : "£4 275/year"}{" "}
              Billed Yearly{" "} */}

            {isPackage && stripeApi.isPackMonthly && data && data.MonthlyForm
              ? data.MonthlyForm.Total_Text + "/month Billed Monthly"
              : !stripeApi.isPackMonthly && data && data.AnnualForm
              ? data.AnnualForm.Total_Text + "/year Billed Yearly"
              : ""}
          </div>
        </div>
        <div className="d24" />
        <hr />
        <div className="mt16" />
        <div className="order_calc">
          <div className="order_r">
            <div className="greytxt">Subtotal</div>
            <div>
              {isPackage && stripeApi.isPackMonthly
                ? data && data.MonthlyForm && data.MonthlyForm.SubTotal_Text
                : data && data.AnnualForm && data.AnnualForm.SubTotal_Text}
            </div>
          </div>
          <div className="order_r">
            <div className="greytxt">Tax</div>
            <div>
              {stripeApi.isPackMonthly && isPackage
                ? data && data.MonthlyForm && data.MonthlyForm.VatTotal_Text
                : data && data.AnnualForm && data.AnnualForm.VatTotal_Text}
            </div>
          </div>
          {/* <div className="order_r">
            <div className="greytxt">Discount</div>
            <div className="txt-blue">
              {stripeApi.isPackMonthly && isPackage
                ? data && data.MonthlyForm && data.MonthlyForm.Discount
                : data && data.AnnualForm && data.AnnualForm.Discount}
            </div>
          </div> */}
        </div>
        <hr />
        <div className="mt16" />
        <div className="order_r">
          <div className="gryClr">Total</div>
          <div>
            {stripeApi.isPackMonthly && isPackage
              ? data && data.MonthlyForm && data.MonthlyForm.Total_Text
              : data && data.AnnualForm && data.AnnualForm.Total_Text}
          </div>
        </div>
        <div className="mt40" />
        <Button
          onClick={() =>
            submit(
              stripeApi.isPackMonthly
                ? data && data.MonthlyForm.PriceId
                : data && data.AnnualForm && data.AnnualForm.PriceId
            )
          }
          className="w-100 blubtn"
        >
          {props.data.package && props.data.package
            ? `Buy ${props.data.package.Name}`
            : "Upgrade to Premium"}
        </Button>
      </Col>

      <Col md={5} className={`pr ${!isPackage ? "" : "d-none"}  `}>
        <div className="mt40" />
        <h1>Order Summary</h1>
        <div className="order_summary">
          <div className="order_r">
            <div>
              {stripeApi.isPlanMonthly && data && data.MonthlyForm
                ? data.MonthlyForm.OrderSummaryName
                : data && data.AnnualForm
                ? data.AnnualForm.OrderSummaryName
                : ""}
            </div>
            <div>
              {stripeApi.isPlanMonthly
                ? data && data.MonthlyForm && data.MonthlyForm.Total_Text
                : data && data.AnnualForm && data.AnnualForm.Total_Text}
            </div>
          </div>

          <div className="gtxt">
            {/* {props.data.package && props.data.package
                ? `${props.data.package.MonthlyCost}`
                : "£4 275/year"}{" "}
              Billed Yearly{" "} */}
            {stripeApi.isPlanMonthly && data && data.MonthlyForm
              ? data.MonthlyForm.Total_Text + "/month Billed Monthly"
              : !stripeApi.isPlanMonthly && data && data.AnnualForm
              ? data.AnnualForm.Total_Text + "/year Billed Yearly"
              : ""}
          </div>
        </div>
        <div className="d24" />
        <hr />
        <div className="mt16" />
        <div className="order_calc">
          <div className="order_r">
            <div className="greytxt">Subtotal</div>
            <div>
              {stripeApi.isPlanMonthly
                ? data && data.MonthlyForm && data.MonthlyForm.SubTotal_Text
                : data && data.AnnualForm && data.AnnualForm.SubTotal_Text}
            </div>
          </div>
          <div className="order_r">
            <div className="greytxt">Tax</div>
            <div>
              {stripeApi.isPlanMonthly
                ? data && data.MonthlyForm && data.MonthlyForm.VatTotal_Text
                : data && data.AnnualForm && data.AnnualForm.VatTotal_Text}
            </div>
          </div>
          {/* <div className="order_r">
            <div className="greytxt">Discount</div>
            <div className="txt-blue">
              {stripeApi.isPlanMonthly
                ? data && data.MonthlyForm && data.MonthlyForm.Discount
                : data && data.AnnualForm && data.AnnualForm.Discount}
            </div>
          </div> */}
        </div>
        <hr />
        <div className="mt16" />
        <div className="order_r">
          <div className="gryClr">Total</div>
          <div>
            {stripeApi.isPlanMonthly
              ? data && data.MonthlyForm && data.MonthlyForm.Total_Text
              : data && data.AnnualForm && data.AnnualForm.Total_Text}
          </div>
        </div>
        <div className="mt40" />
        <Button
          onClick={() => {
            if (!(props.data.package && props.data.package)) {
              TrackUser("Upgrade to Premium button clicked");
            }

            submit(
              stripeApi.isPlanMonthly
                ? data && data.MonthlyForm.PriceId
                : data && data.AnnualForm && data.AnnualForm.PriceId
            );
          }}
          className="w-100 blubtn"
        >
          {props.data.package && props.data.package
            ? `Buy ${props.data.package.Name}`
            : "Upgrade to Premium"}
        </Button>
      </Col>
    </>
  );
};

export default AddCardForm;
