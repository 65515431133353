import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import { FiChevronUp, FiChevronDown } from "react-icons/fi";
import "./CollapsabileModalContainer.css";

//This component encapslates content - passed via props - within a collapsabile container (pill design)
const CollapsabileModalContainer = (props) => {
  const [containerTitle, setContainerTitle] = useState(props.title || "");
  const [containerContent, setContainerContent] = useState(props.content || "");
  const [containerCollapsed, setContainerCollapsed] = useState(
    props.collapsed || false
  );
  const [containerHeaderContent, setContainerHeaderContent] = useState(
    props.hdrContent || ""
  );
  const [containerIcon, setcontainerIcon] = useState(props.icon || "");
  const [contentCount, setContentCount] = useState(props.displayCount || 0); //Determines if the content count is display when container is collapsed.
  const [count, setCount] = useState(props.count || 0);

  return (
    <Col xs={12} className="collapsabileModalContainer pl-0 pr-0">
      {containerCollapsed ? (
        <React.Fragment>
          <div
            className="containerHeaders cursor-pointer"
            onClick={() => setContainerCollapsed(false)}
          >
            <span>
              <span className="circle">
                <img
                  src={containerIcon}
                  style={{
                    background: "#F3606020",
                  }}
                ></img>
              </span>
              <span className="scoreText" style={{ paddingLeft: "0.5em" }}>
                {containerTitle}
              </span>
              <span className="scoreGrey" style={{ paddingLeft: "0.5em" }}>
                {contentCount}
              </span>
            </span>
            {/* <h1>{containerTitle}</h1> */}
            <span>
              <FiChevronDown />
            </span>
          </div>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <div
            className="containerHeaders cursor-pointer"
            onClick={() => setContainerCollapsed(true)}
          >
            <span>
              <span className="circle">
                <img
                  src={containerIcon}
                  style={{
                    background: "#F3606020",
                  }}
                ></img>
              </span>
              <span className=" scoreText" style={{ paddingLeft: "0.5em" }}>
                {containerTitle}
              </span>
              <span className="scoreGrey" style={{ paddingLeft: "0.5em" }}>
                {contentCount}
              </span>
            </span>
            {/* <h1>{containerTitle}</h1> */}
            <span>
              <FiChevronUp />
            </span>
          </div>
          <div className="containerContent">{containerContent}</div>
        </React.Fragment>
      )}
    </Col>
  );
};

export default CollapsabileModalContainer;
