import { Container, Modal } from "react-bootstrap";
import React, { useState, useEffect, Fragment } from "react";
import $ from "jquery";
import "../../main.scss";
import "./ModalDynamic.css";
 
import LoadingSpinner from "../Spinner/Spinner";
import { useDb } from "../context/db";
import { useSelector, useDispatch } from "react-redux";
import { TrackUser } from "../../Utils/SegmentFunctions";
import { setsegment } from "../../redux/segmentEventStatus/actions";

const ParentChildModal = (props) => {
  //Component State - 1 = Parent, 2 = Child, 3 = ParentsChild, 4 = ChildsChild
  const [CurrentState, setCurrentState] = useState(props.createTicket || 1);
  const [ParentComponent, setParentComponent] = useState(props.ParentComponent);
  const [ChildComponent, setChildComponent] = useState(props.ChildComponent);

  //Default Modal Dynamic States
  const [showModal, setshowModal] = useState(props.setShow || false);
  const [modalData, setmodalData] = useState(props.modalData || "");
  const [modalHeader, setmodalHeader] = useState(props.modalHeader || "");
  const [tempHeader, settempHeader] = useState("");

  const [childHeader, setchildHeader] = useState(props.childHeader);
  const [modalSize, setmodalSize] = useState(props.modalSize || "md");
  const [slideHideClass, setSlideHideClass] = useState("");
  const onClickClose = props.onClose || undefined;
  const showButton = props.showButton;
  const buttonText = props.buttonText;
  const [refresh, setrefresh] = useState(false);
  const { dbObj } = useDb();
  const dispatch = useDispatch();
  const onceTicketCreated = useSelector(
    (state) => state.segment.onceTicketCreated
  );
  const insideSupportTicket = useSelector(
    (state) => state.segment?.insideSupportTicket
  );
  useEffect(() => {
    if (slideHideClass) {
      setTimeout(() => {
        setshowModal(false);
      }, 300);
    }
    if (dbObj.PostLoadAction == 1) {
      setCurrentState(2);
    }
  }, [slideHideClass]);

  function HideModal(clickAction = false) {
    if (props.createTicket) {
      $(".fade.bigModal").click();
      setSlideHideClass("slideHide");
      onClickClose();
      return false;
    }
    if (CurrentState === 1) {
      if (clickAction === true) $(".fade.bigModal").click();
      setSlideHideClass("slideHide");
      console.log("onClickClose", onClickClose);
      if (onClickClose) onClickClose();
    } else {
      ChangeModalState(1);
    }
    if (dbObj.PostLoadAction == 1) {
      setshowModal(false);
    }
  }

  function ChangeModalState(newState, newHeader = undefined) {
    if (newHeader) {
      settempHeader(modalHeader);
      setmodalHeader(newHeader);
    } else if (tempHeader != "") {
      setmodalHeader(tempHeader);
      settempHeader("");
    }

    //Refresh inner components if required
    if (
      (newState == 1 && CurrentState == 3) ||
      (newState == 2 && CurrentState == 4)
    ) {
      setrefresh(true);
    }
    setCurrentState(newState);
    setTimeout(() => {
      setrefresh(false);
    }, 10);
  }

  return (
    <Fragment>
      <Modal
        animation={false}
        size={modalSize}
        show={showModal}
        dialogClassName="modal-100w modal-100h"
        className={`right bigModal ${slideHideClass} ${
          modalSize == "lg" ? "modal-large" : "modal-medium"
        }`}
        onHide={HideModal}
        aria-labelledby="large-modal-score"
      >
        <Modal.Header className="d-flex">
          <span className="align-self-center d-flex">
            <span className="cursor-pointer" onClick={() => HideModal(true)}>
              {dbObj.PostLoadAction == 1 ? (
                <img src="/images/icons/close.svg" alt="" />
              ) : props.createTicket ? (
                <img src="/images/icons/close.svg" alt="" />
              ) : CurrentState > 1 ? (
                <img
                  src="/images/icons/arrowLeft.svg"
                  alt=""
                  onClick={() => {
                    console.log("out", childHeader);
                    if (
                      CurrentState > 1 &&
                      childHeader == "Create Ticket" &&
                      !insideSupportTicket
                    ) {
                      if (onceTicketCreated === false) {
                        TrackUser("Support Ticket Not Completed");
                      }
                      setTimeout(
                        () =>
                          dispatch(setsegment({ onceTicketCreated: false })),
                        700
                      );
                    }
                    setTimeout(
                      () =>
                        dispatch(
                          setsegment({
                            insideSupportTicket: false,
                          })
                        ),
                      700
                    );
                  }}
                />
              ) : (
                <img src="/images/icons/close.svg" alt="" />
              )}
            </span>
            <span className="w-100 ml-4 modal-heading align-self-center">
              {childHeader !== undefined
                ? CurrentState === 1 || CurrentState === 3
                  ? modalHeader
                  : childHeader
                : modalHeader}
            </span>
          </span>
          {showButton === true && CurrentState < 2 ? (
            <span
              style={{ float: "right" }}
              className="light-blue-btn"
              onClick={() => setCurrentState(2)}
            >
              {buttonText}
            </span>
          ) : (
            ""
          )}
        </Modal.Header>
        <Modal.Body className="px-0">
          <Container className="pl8px pr8px">
            {refresh ? (
              <LoadingSpinner />
            ) : CurrentState === 1 || CurrentState === 3 ? (
              React.cloneElement(ParentComponent, {
                CallbackMethod: ChangeModalState,
              })
            ) : (
              ChildComponent
            )}
          </Container>
        </Modal.Body>
      </Modal>
    </Fragment>
  );
};

export default ParentChildModal;
