import React, { useState, useEffect } from "react";
import {
  Col,
  Row,
  Tab,
  Nav,
  Dropdown,
  Button,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import ContentLoader from "react-content-loader";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { getAPIData, postData } from "../../../Utils/commonApi";
import { TrimString } from "../../../Utils/StringTrimmer";
import TechnicalDrillDownCyberComplianceModal from "../../AttackSurface/TechnicalDrillDownCyberComplianceModal";
import { useAuth } from "../../context/Auth";
import Header from "../../Header";
import {
  CallToast,
  CustomRightSideToastForReport,
} from "../../PriceCalculator/modals/CustomToast";
import SupplySmallChatModal from "../../settingsV2/supportTicketV2/SupplySmallChatModal";
import CompanyDetailsModal from "../modals/CompanyDetailsModal";
import SuspendRemoveCompanyModal from "../modals/SuspendRemoveCompany";
import ReceivedQuestionAnswers from "../QuestionnaireTab/ReceivedQuestionAnswers";
import ChangeAccessLevelModal from "../RequestsModal/ChangeAccessLevelModal";
import "../supplyChainMonitoring.scss";
import AnnualCyberReview from "../SupplyComplianceManagerComponents/AnnualCyberReview";
import ComplianceQuestionnaire from "../SupplyComplianceManagerComponents/ComplianceQuestionnaire";
import CyberEssentialScreen from "../SupplyComplianceManagerComponents/CyberEssentialScreen";
import InfoSecPoliciesScreen from "../SupplyComplianceManagerComponents/InfoSecPoliciesScreen";
import SupplyRiskAssessment from "../SupplyComplianceManagerComponents/SupplyRiskAssessment";
import QuestionnaireTable from "./QuestionnaireTable";
import SupplyComplianceManager from "./supplyComplianceManager/SupplyComplianceManager";
import SupplyIntegrations from "./SupplyIntegrations";
import SupplyOverview from "./SupplyOverview";
import SupplyTechnicalDrilldown from "./SupplyTechnicalDrilldown";
import SupplyTrendData from "./SupplyTrendData";
import SupplierAttackSurface from "../../Supplier Attack Surface/Index";
import { NoQuestionnaireStateInReceived } from "../QuestionnaireTab/QuestionnaireTab";
import { viewOnlyAccess } from "../../App/App";
import ComingSoonDropdown from "../ComingSoonDropdown";

const SupplyCompany = () => {
  const history = useHistory();
  const { authTokens } = useAuth();
  const isMenuCollapse = useSelector((state) => state?.global?.menuCollapse);
  const [suspendRemoveModal, setSuspendRemoveModal] = useState(false);
  const [suspendRemoveType, setSuspendRemoveType] = useState("");
  const [companyDetailsModal, setCompanyDetailsModal] = useState(false);
  const [breadCrumb, setBreadCrumb] = useState([]);
  const [loading, setLoading] = useState(false);
  const activePage = breadCrumb[breadCrumb.length - 1];
  const [smallChatModal, setSmallChatModal] = useState(false);
  const [supplierQuestionnaireLoading, setSupplierQuestionnaireLoading] =
    useState(false);
  const [pageData, setPageData] = useState({});
  const [printingReport, setPrintingReport] = useState(false);
  const userDetails = useSelector((state) => state.user?.userDetail);
  const [AttackSurfaceAccessLevels, setAttackSurfaceAccessLevels] = useState({
    CDSA: 4,
    "Assets List": 4,
    Networks: 4,
    "Directory Services": 4,
    Email: 4,
    "File Sharing": 4,
    "Cloud Services": 4,
    Communications: 4,
    "Data Protection": 4,
    Organisation: 4,
    "Corporate Social": 4,
    Authentication: 4,
    "Web Services": 4,
    Suppliers: 4,
  });
  const [supplierContextMenu, setSupplierContextMenu] = useState([]);
  function openComplianceManager() {
    let ComplianceManagerTab = document.querySelector("#compliance-tabMenu a");
    if (ComplianceManagerTab) ComplianceManagerTab.click();
  }
  function goToAttackSurfaceTab() {
    let QuestionnaireTab = document.querySelector(
      "#supplierAttackSurfaceTab a"
    );
    if (QuestionnaireTab) QuestionnaireTab.click();
  }
  function setAccessLevelsForAttackSurface(response) {
    setAttackSurfaceAccessLevels({
      CDSA: response?.AccessLevel_CDSA,
      "Assets List": response?.AccessLevel_AssestLists,
      Networks: response?.AccessLevel_Networks,
      "Directory Services": response?.AccessLevel_DirectoryServices,
      Email: response?.AccessLevel_Email,
      "File Sharing": response?.AccessLevel_FileSharing,
      "Cloud Services": response?.AccessLevel_CloudServices,
      Communications: response?.AccessLevel_Communications,
      "Data Protection": response?.AccessLevel_DataProtection,
      Organisation: response?.AccessLevel_Organisation,
      "Corporate Social": response?.AccessLevel_CorporateSocial,
      Authentication: response?.AccessLevel_Authentication,
      "Web Services": response?.AccessLevel_WebServices,
      Suppliers: response?.AccessLevel_Suppliers,
    });
  }

  // for questionanire Testing- 3277336
  // For integrations Testing- 3336609

  const parameters = useParams();
  const supId = parameters?.Id;

  const [supplierId] = useState(supId);

  // Page Data
  const [supplierName, setSupplierName] = useState("");
  const [supplierLogo, setSupplierLogo] = useState("");
  const [supplierChainId, setSupplierChainId] = useState("");
  const [supplierDetails, setSupplierDetails] = useState("");
  const [isCompliant, setIsCompliant] = useState(false);

  const [showChangeAccessLevelModal, setShowChangeAccessLevelModal] =
    useState(false);

  // Overview Tab Data
  const [chartFilterList, setChartFilterList] = useState();
  const [selectedChartFilter, setSelectedChartFilter] = useState();
  const [complianceBoxList, setComplianceBoxList] = useState([]);
  const [chartBoxList, setChartBoxList] = useState([]);
  const [filterLoading, setFilterLoading] = useState(false);

  // Compliance section
  const [complianceAccessLevel, setComplianceAccessLevel] = useState(null);
  const [accessCE, setAccessCE] = useState(null);
  const [accessIRP, setAccessIRP] = useState(null);
  const [accessISP, setAccessISP] = useState(null);
  const [accessRA, setAccessRA] = useState(null);
  const [accesssACR, setAccesssACR] = useState(null);
  const [accessBCDR, setAccessBCDR] = useState(null);
  const [complianceSectionPageEnumData, setComplianceSectionPageEnumData] =
    useState({});

  // Cyber Trend Section
  const [cyberTrendAccessLevel, setCyberTrendAccessLevel] = useState(null);

  // Technical Drilldown section
  const [technicalDrilldownAccessLevel, setTechnicalDrilldownAccessLevel] =
    useState(null);

  const [triggerQuestionnaireAPI, setTriggerQuestionnaireAPI] = useState(false);

  // Integrations Section

  const [integrationsAccessLevel, setIntegrationsAccessLevel] = useState(null);

  // For Questionnaire Section
  const [dataForTable, setDataForTable] = useState([]);
  const [columnSortingLoading, setColumnSortingLoading] = useState(false);
  const [sortItem, setSortItem] = useState({
    type: "Title",
    order: 1,
  });
  const [defaultSelectedTab, setDefaultSelectedTab] = useState("overview");

  const [filterParameters, setFilterParameters] = useState([
    { TaskFilter: "created", FilterText_List: [] },
    { TaskFilter: "Title", FilterText_List: [] },
    { TaskFilter: "Due_Date", FilterText_List: [] },
    { TaskFilter: "LastUpdated", FilterText_List: [] },
    { TaskFilter: "Supplier", FilterText_List: [] },
  ]);

  function SortQuestionaireColumn(type) {
    setColumnSortingLoading(true);
    setSortItem((prevSort) => ({
      type: type,
      order: Number(!prevSort.order),
    }));
  }
  function refreshQuestionnaire() {
    setTriggerQuestionnaireAPI(!triggerQuestionnaireAPI);
  }
  useEffect(() => {
    if (!supplierId) return;
    setSupplierQuestionnaireLoading(true);
    postData(authTokens, "GetSCMQUestionnaireList", {
      QuestionnaireNumber: 0,
      IsArchived: false,
      SCMQuestionnaireFilter_List: JSON.stringify(filterParameters),
      SCMQuestionnaireSort: sortItem.type,
      SCMQuestionnaireSortOrder: sortItem.order,
      // accessKey: JSON.parse(localStorage.getItem("user_MessageObj")),
      IsSent: false,
      ObjId_Supplier: supplierId,
      IsLoadOnlyClientQuestionnaire: true,
    })
      .then((response) => {
        setSupplierQuestionnaireLoading(false);
        setColumnSortingLoading(false);

        if (response?.mr?.Success) {
          setDataForTable(response?.SCMQuestionnaires_List);
        } else {
          CallToast("Something went wrong", false, false, "v2style");
        }
      })
      .catch((err) => {
        setSupplierQuestionnaireLoading(false);
        setColumnSortingLoading(false);
        console.log(err);
        CallToast("Something went wrong", false, false, "v2style");
      });
  }, [supplierId, sortItem, filterParameters, triggerQuestionnaireAPI]);

  useEffect(() => {
    setLoading(true);
    if (supplierId) {
      getPageData(0);
    }
  }, [supplierId]);

  async function getPageData(filterValue) {
    await getAPIData(`${347}`, authTokens, {
      optionEnum1: supplierId,
      optionEnum2: filterValue,
    }).then((response) => {
      setFilterLoading(false);
      setLoading(false);
      if (response?.DonotShowSupplierDetail) {
        history.push("/supply");
        return;
      }
      if (response?.mr?.Success) {
        setPageData(response);
        setSupplierContextMenu(response?.ContextMenu?.ContextMenuItems);

        setAccessLevelsForAttackSurface(response);
        // Set access level for compliance Section:
        setAccessCE(response?.AccessLevel_CE);
        setAccessIRP(response?.AccessLevel_IRP);
        setAccessISP(response?.AccessLevel_ISP);
        setAccessRA(response?.AccessLevel_RA);
        setAccesssACR(response?.AccessLevel_ACR);
        setAccessBCDR(response?.AccessLevel_BCDR);
        setCyberTrendAccessLevel(response?.AccessLevel_CyberTrend);
        setTechnicalDrilldownAccessLevel(response?.AccessLevel_TechDrillDown);
        setIntegrationsAccessLevel(response?.AccessLevel_Integrations);

        // Setting Access Levels

        setComplianceAccessLevel(response?.AccessLevel_ComplianceMgr);

        setSupplierName(response?.SupplierName);
        setSupplierLogo(response?.SupplierIcon);
        setSupplierChainId(response?.SupplierScId);

        if (response?.SupplierDetail) {
          let details = response?.SupplierDetail?.split(",");
          let details_array = [];
          for (let index = 0; index < details?.length; index++) {
            details_array.push(`#${TrimString(details[index]?.trim(), 100)}`);
          }

          setSupplierDetails(details_array.join(", "));
        } else {
          setSupplierDetails(response?.SupplierDetail);
        }

        setIsCompliant(response?.SupplierCompliant);
        setChartFilterList(response?.ChartPeriod_DDLOption_List);
        setComplianceBoxList(response?.ComplianceBoxList);
        setChartBoxList(response?.KpiBox_List);
        if (breadCrumb?.length === 0) {
          setBreadCrumb([{ id: 0, page: response?.SupplierName, type: "" }]);
        }
      }
    });
  }

  function goTo(pageName, type = "", data = {}) {
    if (pageName == null || pageName === "") {
      console.log("go to page is Null");
      return;
    }

    let updated_data = [...breadCrumb];
    updated_data.push({
      id: breadCrumb.length,
      page: pageName,
      type: type,
      data: data,
    });
    setBreadCrumb(updated_data);
  }

  async function printSupplierReport(event) {
    setPrintingReport(true);
    // CallToast("Report will be downloaded shortly.", false, false, "v2style");
    CustomRightSideToastForReport(
      "Report is being created and will be sent to you by email shortly."
    );
    await getAPIData(`${454}`, authTokens, {
      optionEnum1: supplierId,
    })
      .then((response) => {
        setPrintingReport(false);
        if (response?.mr?.Success) {
          CallToast(
            "Report has been sent successfully",
            false,
            false,
            "v2style"
          );
          // downloadPDF(response?.FileURL);
          // event.target.closest(".device-dropdown").click();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }
  async function updateSupplierStatus(supplierID, status) {
    await getAPIData("345", authTokens, {
      optionEnum1: supplierID,
      optionEnum2: status,
    })
      .then((response) => {
        if (response?.Success) {
          CallToast("Updated successfully", false, false, "v2style");
        } else {
          CallToast("Something went wrong", false, false, "v2style");
        }
      })
      .catch(() => {
        CallToast("Something went wrong", false, false, "v2style");
      });
  }

  return (
    <Row className="supplyChainWrapper">
      <Col xs={12}>
        <div>
          <header
            className="d-flex justify-content-between navbar sticky-top pb-0"
            style={{
              paddingTop: ` ${
                 "1em"
              } `,
              background: "#FFFFFF",
              position: "fixed",
              width: `${isMenuCollapse ? "92.25%" : "83.25%"}`,
              zIndex: 6,
              // marginLeft: "-0.9rem",
              paddingLeft: "2rem",
              // borderBottom: `1px solid #EBEDF1`,
              paddingBottom: "8px",
            }}
          >
            <h3 className="f-16 f-600">Supply Chain Monitoring</h3>

            <Header />
          </header>
        </div>
        <div style={{ paddingTop: "5%" }}></div>

        <Row>
          <Col
            sm={12}
            className="tab-header d-flex justify-content-between align-items-center "
          >
            {/* Home screen left part */}
            <div className={``}>
              {breadCrumb.length === 1 && (
                <div className="d-flex align-items-center">
                  <div
                    className="d-flex align-items-center pointer"
                    onClick={() => {
                      history.push("/supply");
                    }}
                  >
                    <img
                      alt=""
                      src="/images/icons/arrowLeft.svg"
                      className="pointer ml-3"
                    />
                    <div className="f-grey f-500 ml-3 pointer">Back</div>
                  </div>

                  <div className="ml-4 f-16 f-500 f-black">
                    {breadCrumb[0].page}
                  </div>
                </div>
              )}
              {breadCrumb.length > 1 && (
                <div className="d-flex align-items-center">
                  <div
                    className="f-grey d-flex align-items-center pointer ml-3"
                    onClick={() => {
                      let updated_data = [...breadCrumb];
                      updated_data.pop();
                      setBreadCrumb(updated_data);
                    }}
                  >
                    <img
                      alt=""
                      src="/images/attack-surface/back-icon.svg"
                      className="mr-2"
                    />
                    Back
                  </div>
                  <div className="d-flex align-items-center ml-4">
                    {breadCrumb.map((item, itemIndex) => {
                      return (
                        <div className="bread-parent d-flex align-items-center">
                          <div className="bread-child">{item.page} </div>
                          {breadCrumb.length - 1 !== itemIndex && (
                            <div className="f-grey mx-3">/</div>
                          )}
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            </div>

            {/* Right side items at top bar */}
            <div className="d-flex align-items-center mr-3">
              <div
                // data-count={}
                className="message-dot position-relative pointer "
                onClick={() => {
                  // setSmallChatModal(true);
                }}
              >
                {/* <img
                  alt=""
                  src="/images/supply-chain/grey-plane.svg"
                  className="mr-3 "
                /> */}
                <ComingSoonDropdown />
              </div>
              <OverlayTrigger
                placement="bottom"
                trigger={
                  viewOnlyAccess("AccessState_SCM") ? ["hover", "focus"] : []
                }
                delay={{
                  show: 200,
                  hide: 250,
                }}
                overlay={
                  <Tooltip
                    id={`tooltip-1365`}
                    className="custom_tooltip_access"
                  >
                    This feature is managed by your service provider
                  </Tooltip>
                }
              >
                <div
                  className="f-14 f-500 f-grey mr-3 pointer "
                  onClick={() => {
                    // setSmallChatModal(true);
                  }}
                >
                  {viewOnlyAccess("AccessState_SCM") ? (
                    <img
                      alt=""
                      src="/images/attack-surface/small-lock.svg"
                      className="mr-2"
                    />
                  ) : (
                    <></>
                  )}
                  Send a message
                </div>
              </OverlayTrigger>
              <OverlayTrigger
                placement="bottom"
                trigger={
                  viewOnlyAccess("AccessState_SCM") ? ["hover", "focus"] : []
                }
                delay={{
                  show: 200,
                  hide: 250,
                }}
                overlay={
                  <Tooltip
                    id={`tooltip-1365`}
                    className="custom_tooltip_access"
                  >
                    This feature is managed by your service provider
                  </Tooltip>
                }
              >
                <div className="f-500 mr-1  position-relative pointer">
                  <img
                    alt=""
                    src="/images/supply-chain/big-info-icon.svg"
                    style={{
                      pointerEvents: viewOnlyAccess("AccessState_SCM")
                        ? "none"
                        : "all",
                    }}
                    onClick={() => {
                      setCompanyDetailsModal(true);
                    }}
                  />
                </div>
              </OverlayTrigger>
              <div className="menu-dropdown header-menu-dropdown">
                <Dropdown className="device-dropdown">
                  <Dropdown.Toggle>
                    {supplierContextMenu?.length > 0 && (
                      <img alt="" src="/images/horizontal-dots.svg" />
                    )}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <div>
                      <Row>
                        {supplierContextMenu?.map((menuItem, menuItemIndex) => {
                          return (
                            <OverlayTrigger
                              placement="left"
                              trigger={
                                !menuItem.isEnabled ? ["hover", "focus"] : []
                              }
                              delay={{
                                show: 200,
                                hide: 250,
                              }}
                              overlay={
                                <Tooltip
                                  id={`tooltip-1365`}
                                  className="custom_tooltip_access"
                                >
                                  This feature is managed by your service
                                  provider
                                </Tooltip>
                              }
                            >
                              <div>
                                <Col
                                  xs={12}
                                  className=" pointer"
                                  onClick={() => {}}
                                >
                                  <div
                                    style={{
                                      pointerEvents: !menuItem.isEnabled
                                        ? "none"
                                        : "all",
                                    }}
                                    className="drop-item"
                                    onClick={(e) => {
                                      e.target
                                        .closest(".device-dropdown")
                                        .click();
                                      if (menuItem.isEnabled) {
                                        if (menuItem?.callPageEnum === 454) {
                                          printSupplierReport();

                                          return;
                                        } else if (
                                          menuItem?.newRequestStatus_Id === 9
                                        ) {
                                          setShowChangeAccessLevelModal(true);

                                          return;
                                        } else if (
                                          menuItem?.newRequestStatus_Id === 5
                                        ) {
                                          setSuspendRemoveType("Suspend");
                                          setSuspendRemoveModal(true);

                                          return;
                                        } else if (
                                          menuItem?.newRequestStatus_Id === 3
                                        ) {
                                          setSuspendRemoveType("Remove");
                                          setSuspendRemoveModal(true);

                                          return;
                                        } else {
                                          updateSupplierStatus(
                                            supplierId,
                                            menuItem?.newRequestStatus_Id
                                          );
                                        }
                                      }
                                    }}
                                  >
                                    {!menuItem?.isEnabled && (
                                      <img
                                        alt=""
                                        src="/images/attack-surface/small-lock.svg"
                                        className="mr-2"
                                      />
                                    )}
                                    {menuItem?.menuActionLabel}
                                  </div>
                                </Col>
                              </div>
                            </OverlayTrigger>
                          );
                        })}
                        {/* <Col xs={12} className=" pointer">
                          <div
                            className="drop-item"
                            onClick={(e) => {
                              e.target.closest(".device-dropdown").click();
                            }}
                          >
                            Profile supplier
                          </div>
                        </Col>
                        <div
                          className="w-80 mx-auto my-2"
                          style={{ borderBottom: "1px solid #FFFFFF3D" }}
                        />
                        <Col xs={12} className="  pointer">
                          <div
                            className="drop-item"
                            onClick={(e) => {
                              setShowChangeAccessLevelModal(true);
                              e.target.closest(".device-dropdown").click();
                            }}
                          >
                            Change Access Level
                          </div>
                        </Col>
                        <Col xs={12} className=" pointer  ">
                          <div
                            className="drop-item"
                            onClick={(e) => {
                              e.target.closest(".device-dropdown").click();
                            }}
                          >
                            Change Compliance Profile
                          </div>
                        </Col>
                        <div
                          className="w-80 mx-auto my-2"
                          style={{ borderBottom: "1px solid #FFFFFF3D" }}
                        />

                        <Col xs={12} className=" pointer">
                          <div
                            className="drop-item"
                            onClick={(e) => {
                              e.target.closest(".device-dropdown").click();
                            }}
                          >
                            Print Profiling Report
                          </div>
                        </Col>
                        <Col xs={12} className=" pointer">
                          <div
                            className="drop-item"
                            onClick={(e) => {
                              printSupplierReport(e);
                              e.target.closest(".device-dropdown").click();
                            }}
                          >
                            Print Supplier Report
                          </div>
                        </Col>

                        <div
                          className="w-80 mx-auto my-2"
                          style={{ borderBottom: "1px solid #FFFFFF3D" }}
                        />
                        <Col xs={12} className=" pointer">
                          <div
                            className="drop-item"
                            onClick={(e) => {
                              e.target.closest(".device-dropdown").click();
                              setSuspendRemoveType("Suspend");
                              setSuspendRemoveModal(true);
                            }}
                          >
                            Suspend
                          </div>
                        </Col>
                        <Col xs={12} className=" pointer">
                          <div
                            className="drop-item"
                            onClick={(e) => {
                              e.target.closest(".device-dropdown").click();
                              setSuspendRemoveType("Remove");
                              setSuspendRemoveModal(true);
                            }}
                          >
                            Remove
                          </div>
                        </Col> */}
                      </Row>
                    </div>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          </Col>
          {/* 
          Company 
          name 
          and 
          detail */}
          {(breadCrumb.length === 0 || breadCrumb.length === 1) && (
            <>
              {loading ? (
                <Col xs={12} className="px-4">
                  <SupplyCompanyHomePageLoader />
                </Col>
              ) : (
                <>
                  <div className="d-flex align-items-center justify-content-between p-4 w-100 mx-4">
                    <div className="d-flex align-items-center">
                      <img
                        className="mr-3 rounded-full"
                        width={64}
                        height={64}
                        alt=""
                        src={supplierLogo}
                      />
                      <div
                        className="d-flex flex-column"
                        style={{ maxWidth: "80%" }}
                      >
                        <div className="d-flex align-items-center">
                          <div className="f-18 f-600">{supplierName} </div>
                          {isCompliant ? (
                            <div
                              className="f-green radius-2 px-2 py-1 ml-2"
                              style={{
                                background: "#E0F9ED",
                                width: "fit-content",
                              }}
                            >
                              {/* //small-red-cross.svg */}
                              <img
                                className="mr-2"
                                alt=""
                                src="/images/supply-chain/green-yes.svg"
                              />
                              Compliant
                            </div>
                          ) : (
                            <div
                              className="f-danger radius-2 px-2 py-1 ml-2"
                              style={{
                                background: "#FDE6E6",
                                width: "fit-content",
                              }}
                            >
                              <img
                                className="mr-2"
                                alt=""
                                src="/images/supply-chain/small-red-cross.svg"
                              />
                              Not Compliant
                            </div>
                          )}
                        </div>

                        <OverlayTrigger
                          placement="bottom"
                          trigger={
                            viewOnlyAccess("AccessState_SCM")
                              ? ["hover", "focus"]
                              : []
                          }
                          delay={{
                            show: 200,
                            hide: 250,
                          }}
                          overlay={
                            <Tooltip
                              id={`tooltip-1365`}
                              className="custom_tooltip_access"
                            >
                              This feature is managed by your service provider
                            </Tooltip>
                          }
                        >
                          <div className="d-flex mt-2">
                            <div className="f-darkgrey">
                              {supplierDetails}{" "}
                              {viewOnlyAccess("AccessState_SCM") && (
                                <img
                                  alt=""
                                  src="/images/attack-surface/small-lock.svg"
                                  className=" ml-1"
                                />
                              )}
                              <span
                                className="f-blue ml-2 pointer"
                                style={{
                                  pointerEvents: viewOnlyAccess(
                                    "AccessState_SCM"
                                  )
                                    ? "none"
                                    : "all",
                                }}
                                onClick={() => {
                                  setCompanyDetailsModal(true);
                                }}
                              >
                                {" "}
                                Details
                              </span>
                            </div>
                          </div>
                        </OverlayTrigger>
                      </div>
                    </div>

                    <div className="d-flex align-items-center justify-content-end w-20">
                      <div className="d-flex flex-column align-items-center mr-3">
                        <p className="f-darkgrey f-12 mb-2">Supply chain ID</p>
                        <p className="f-black f-14 f-500 m-0">
                          {supplierChainId}
                        </p>
                      </div>

                      <div className="bg-grey radius-4 p-2">
                        <div className="f-darkgrey mb-2">
                          Supplier profiling
                        </div>
                        {true ? (
                          <Button className="hg-red-btn">Critical</Button>
                        ) : (
                          <div className="border1 d-flex align-items-center f-12 f-500 f-grey radius-2 px-2">
                            <img
                              className="mr-2"
                              src="/images/actioncenter/plusgrey.svg"
                              alt=""
                              width={10}
                              height={10}
                            />{" "}
                            Profile supplier
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  {/* Company All Tabs */}
                  <div className="tab-data-company mx-4 w-100">
                    <Tab.Container
                      id="left-tabs-example"
                      defaultActiveKey={defaultSelectedTab}
                    >
                      <div>
                        <div
                          sm={12}
                          className="tab-header d-flex justify-content-between align-items-center ml-5 p-0"
                        >
                          <div className={`${true}`}>
                            <Nav
                              variant="pills"
                              className="flex-row"
                              onSelect={(e) => setDefaultSelectedTab(e)}
                            >
                              <Nav.Item>
                                <Nav.Link eventKey="overview">
                                  Overview
                                </Nav.Link>
                              </Nav.Item>
                              <Nav.Item id="supplierAttackSurfaceTab">
                                <Nav.Link eventKey="attackSurface">
                                  {pageData?.IsLocked_AttackSurface && (
                                    <img
                                      alt=""
                                      width={12}
                                      height={12}
                                      className="mr-2"
                                      src="/images/supply-chain/supply-lock.svg"
                                    />
                                  )}
                                  Attack Surface
                                </Nav.Link>
                              </Nav.Item>
                              <Nav.Item id="compliance-tabMenu">
                                <Nav.Link eventKey="complianceManager">
                                  {pageData?.IsLocked_ComplianceMgr && (
                                    <img
                                      alt=""
                                      width={12}
                                      height={12}
                                      className="mr-2"
                                      src="/images/supply-chain/supply-lock.svg"
                                    />
                                  )}
                                  Compliance Manager
                                </Nav.Link>
                              </Nav.Item>
                              <Nav.Item>
                                <Nav.Link eventKey="cyberTrendData">
                                  {pageData?.IsLocked_CyberTrend && (
                                    <img
                                      alt=""
                                      width={12}
                                      height={12}
                                      className="mr-2"
                                      src="/images/supply-chain/supply-lock.svg"
                                    />
                                  )}{" "}
                                  Cyber Trend Data
                                </Nav.Link>
                              </Nav.Item>
                              <Nav.Item>
                                <Nav.Link eventKey="technicalDrilldown">
                                  {pageData?.IsLocked_TechDrillDown && (
                                    <img
                                      alt=""
                                      width={12}
                                      height={12}
                                      className="mr-2"
                                      src="/images/supply-chain/supply-lock.svg"
                                    />
                                  )}{" "}
                                  Technical Drilldown
                                </Nav.Link>
                              </Nav.Item>
                              <Nav.Item>
                                <Nav.Link eventKey="integrations">
                                  {pageData?.IsLocked_Integrations && (
                                    <img
                                      alt=""
                                      width={12}
                                      height={12}
                                      className="mr-2"
                                      src="/images/supply-chain/supply-lock.svg"
                                    />
                                  )}{" "}
                                  Integrations
                                </Nav.Link>
                              </Nav.Item>
                              <Nav.Item>
                                <Nav.Link eventKey="questionnaires">
                                  Questionnaires
                                </Nav.Link>
                              </Nav.Item>
                            </Nav>
                          </div>
                        </div>
                        <div sm={12} className="ml-5 p-0">
                          <Tab.Content>
                            <Tab.Pane eventKey="overview">
                              <SupplyOverview
                                chartFilterList={chartFilterList}
                                selectedChartFilter={selectedChartFilter}
                                setSelectedChartFilter={setSelectedChartFilter}
                                complianceBoxList={complianceBoxList}
                                chartBoxList={chartBoxList}
                                filterLoading={filterLoading}
                                setFilterLoading={setFilterLoading}
                                getPageData={getPageData}
                                supplierId={supplierId}
                                openComplianceManager={openComplianceManager}
                                goToAttackSurfaceTab={goToAttackSurfaceTab}
                                // Setup to open Complinace Drilldown from Overview Section
                                goTo={goTo}
                                complianceAccessLevel={complianceAccessLevel}
                                accessCE={accessCE}
                                accessIRP={accessIRP}
                                accessISP={accessISP}
                                accessRA={accessRA}
                                accesssACR={accesssACR}
                                accessBCDR={accessBCDR}
                                setComplianceSectionPageEnumData={
                                  setComplianceSectionPageEnumData
                                }
                              />
                            </Tab.Pane>
                            <Tab.Pane eventKey="attackSurface">
                              {pageData?.IsLocked_AttackSurface ? (
                                <NoAccessContainer type={"Attack Surface"} />
                              ) : (
                                <>
                                  {defaultSelectedTab === "attackSurface" && (
                                    <SupplierAttackSurface
                                      supplierId={supplierId}
                                      accessLevel={2}
                                      attackSurfaceAccessLevels={
                                        AttackSurfaceAccessLevels
                                      }
                                    />
                                  )}
                                </>
                              )}
                            </Tab.Pane>
                            <Tab.Pane eventKey="complianceManager">
                              {pageData?.IsLocked_ComplianceMgr ? (
                                <NoAccessContainer
                                  type={"Compliance Manager"}
                                />
                              ) : (
                                <>
                                  {defaultSelectedTab ===
                                    "complianceManager" && (
                                    <SupplyComplianceManager
                                      goTo={goTo}
                                      complianceAccessLevel={
                                        complianceAccessLevel
                                      }
                                      supplierId={supplierId}
                                      accessCE={accessCE}
                                      accessIRP={accessIRP}
                                      accessISP={accessISP}
                                      accessRA={accessRA}
                                      accesssACR={accesssACR}
                                      accessBCDR={accessBCDR}
                                      setComplianceSectionPageEnumData={
                                        setComplianceSectionPageEnumData
                                      }
                                    />
                                  )}
                                </>
                              )}
                            </Tab.Pane>
                            <Tab.Pane eventKey="cyberTrendData">
                              {pageData?.IsLocked_CyberTrend ? (
                                <NoAccessContainer type={"Cyber Trend Data"} />
                              ) : (
                                <SupplyTrendData
                                  accessLevel={cyberTrendAccessLevel}
                                  supplierId={supplierId}
                                />
                              )}
                            </Tab.Pane>
                            <Tab.Pane eventKey="technicalDrilldown">
                              {pageData?.IsLocked_TechDrillDown ? (
                                <NoAccessContainer
                                  type={"Technical Drilldown"}
                                />
                              ) : (
                                <SupplyTechnicalDrilldown
                                  accessLevel={technicalDrilldownAccessLevel}
                                  supplierId={supplierId}
                                />
                              )}
                            </Tab.Pane>
                            <Tab.Pane eventKey="integrations">
                              {pageData?.IsLocked_Integrations ? (
                                <NoAccessContainer type={"Integrations"} />
                              ) : (
                                <SupplyIntegrations
                                  accessLevel={integrationsAccessLevel}
                                  supplierId={supplierId}
                                />
                              )}
                            </Tab.Pane>
                            <Tab.Pane eventKey="questionnaires">
                              <QuestionnaireTable
                                isSupplierPage
                                clientsQuestions
                                restrictOperations
                                data={dataForTable}
                                SortQuestionaireColumn={SortQuestionaireColumn}
                                columnSortingLoading={columnSortingLoading}
                                sortItem={sortItem}
                                setPage={goTo}
                                setFilterParameters={setFilterParameters}
                                NoQuestionnaireState={
                                  NoQuestionnaireStateInReceived
                                }
                                refreshData={refreshQuestionnaire}
                                selectedSupplierId={supplierId}
                                loading={supplierQuestionnaireLoading}
                              />
                            </Tab.Pane>
                          </Tab.Content>
                        </div>
                      </div>
                    </Tab.Container>
                  </div>
                </>
              )}
            </>
          )}
          {activePage?.page === "Cyber Essentials" && (
            <CyberEssentialScreen
              goTo={goTo}
              complianceSectionPageEnumData={complianceSectionPageEnumData}
            />
          )}
          {activePage?.page === "Incident Response Plan" && (
            <CyberEssentialScreen
              goTo={goTo}
              complianceSectionPageEnumData={complianceSectionPageEnumData}
            />
          )}
          {activePage?.page === "Risk Assessment" && (
            <SupplyRiskAssessment
              goTo={goTo}
              complianceSectionPageEnumData={complianceSectionPageEnumData}
            />
          )}
          {activePage?.page === "Annual Cyber Review" && (
            <AnnualCyberReview
              goTo={goTo}
              complianceSectionPageEnumData={complianceSectionPageEnumData}
            />
          )}
          {activePage?.page === "BCDR Plan" && (
            <CyberEssentialScreen
              goTo={goTo}
              complianceSectionPageEnumData={complianceSectionPageEnumData}
            />
          )}
          {activePage?.page === "Information Security Policies" && (
            <InfoSecPoliciesScreen
              goTo={goTo}
              complianceSectionPageEnumData={complianceSectionPageEnumData}
            />
          )}
          {activePage?.type === "CMQuestion" && (
            <ComplianceQuestionnaire
              complianceSectionPageEnumData={complianceSectionPageEnumData}
              activePage={activePage}
            />
          )}

          {activePage?.type === "questionAnswers" && (
            <ReceivedQuestionAnswers
              // setPage={setPage}
              pageData={activePage?.data}
            />
          )}
        </Row>
      </Col>
      <SuspendRemoveCompanyModal
        show={suspendRemoveModal}
        hideModal={() => setSuspendRemoveModal(false)}
        type={suspendRemoveType}
        supplierName={supplierName}
        supplierLogo={supplierLogo}
        supplierId={supplierId}
        supplierChainId={supplierChainId}
      />
      <CompanyDetailsModal
        show={companyDetailsModal}
        hideModal={() => setCompanyDetailsModal(false)}
        supplierId={supplierId}
        refreshData={() => getPageData(0)}
      />
      <ChangeAccessLevelModal
        show={showChangeAccessLevelModal}
        hideModal={() => setShowChangeAccessLevelModal(false)}
        supplyChainId={supplierChainId}
        supplierName={supplierName}
        supplierLogo={supplierLogo}
        supplierId={supplierId}
      />

      <SupplySmallChatModal
        show={smallChatModal}
        hideModal={() => setSmallChatModal(false)}
      />
    </Row>
  );
};

export default SupplyCompany;

const SupplyCompanyHomePageLoader = () => {
  return (
    <ContentLoader
      speed={2}
      width={"98%"}
      height={"100%"}
      viewBox="-30 -30 1500 900"
      backgroundColor="#F6F8FB"
      foregroundColor="#ecebeb"
    >
      <rect x="0" y="0" rx="80" ry="80" width="80" height="80" />
      <rect x="7%" y="12" rx="4" ry="4" width="15%" height="30" />
      <rect x="7%" y="50" rx="4" ry="4" width="30%" height="20" />
      <rect x="90%" y="23" rx="4" ry="4" width="9%" height="45" />
      <rect x="3%" y="110" rx="4" ry="4" width="85%" height="35" />

      <rect x="1%" y="185" rx="4" ry="4" width="9%" height="25" />
      <rect x="90%" y="185" rx="4" ry="4" width="7%" height="35" />

      <rect x="1%" y="230" rx="4" ry="4" width="97%" height="200" />
      <rect x="1%" y="435" rx="4" ry="4" width="97%" height="200" />

      <rect x="1%" y="700" rx="4" ry="4" width="97%" height="145" />
    </ContentLoader>
  );
};

export const NoAccessContainer = ({ type }) => {
  return (
    <div
      className="d-flex flex-column align-items-center justify-content-center"
      style={{ marginTop: "9rem" }}
    >
      <img alt="" src="/images/supply-chain/supply-lock.svg" className="mb-2" />
      <div className="f-500 f-grey mb-2">You dont have access to this data</div>
      <div className="f-12 f-darkgrey">To view {type}, update</div>
      <div className="f-12 f-darkgrey"> your access for this Supplier</div>
    </div>
  );
};

export const AttackSurfaceAccessUI = () => {
  return (
    <div
      className="d-flex align-items-center radius-8 p-3"
      style={{
        background: "#FFF8EE",
      }}
    >
      <img
        alt=""
        src="/images/supply-chain/attack-access.svg"
        className="mr-2"
      />
      <div className="f-black d-flex align-items-center justify-content-center">
        You are viewing this data from the suppliers view, as per your approved
        access level
      </div>
    </div>
  );
};

export async function downloadPDF(_url) {
  try {
    const response = await fetch(_url);
    const data = await response.blob();
    const a = document.createElement("a");
    a.style.display = "none";
    document.body.appendChild(a);
    const url = window.URL.createObjectURL(data);
    a.href = url;
    // a.target = "_blank";
    a.download = "file.pdf";
    a.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  } catch (error) {
    console.error(error);
  }
}
