import React, { useState, useEffect, Fragment } from "react";
import {
  Button,
  Modal,
  Row,
  Form,
  Col,
  OverlayTrigger,
  InputGroup,
  Tooltip,
} from "react-bootstrap";
import moment from "moment/moment";
import { FiChevronLeft } from "react-icons/fi";

import "./style.scss";

const QuestionnaireModal = (props) => {
  const [activeCount, setActiveCount] = useState(1);
  const [prwidth, setPrwidth] = useState(20);
  const [data, setData] = useState([
    {
      header: "Identify",
      content: [
        {
          title:
            "1. Does the business have to meet any Compliance Standards or is governed by Industry Bodies? E.G. the FCA.",
          type: 1,
          answer: "none",
          date: '',
        },
        {
          title:
            "2. Does the business have to meet any Compliance Standards or is governed by Industry Bodies? E.G. the FCA.",
          type: 2,
          answer: "none",
          date: '',
        },
        {
          title:
            "3. Does the business have to meet any Compliance Standards or is governed by Industry Bodies? E.G. the FCA.",
          type: 2,
          answer: "none",
          date: '',
        },
        {
          title:
            "4. Does the business have to meet any Compliance Standards or is governed by Industry Bodies? E.G. the FCA.",
          type: 2,
          answer: "none",
          date: '',
        },
        {
          title:
            "5. Does the business have to meet any Compliance Standards or is governed by Industry Bodies? E.G. the FCA.",
          type: 1,
          answer: "none",
          date: '',
        },
        {
          title:
            "6. Does the business have to meet any Compliance Standards or is governed by Industry Bodies? E.G. the FCA.",
          type: 2,
          answer: "none",
          date: '',
        },
        {
          title:
            "7. Does the business have to meet any Compliance Standards or is governed by Industry Bodies? E.G. the FCA.",
          type: 1,
          answer: "none",
          date: '',
        },
        {
          title:
            "8. Does the business have to meet any Compliance Standards or is governed by Industry Bodies? E.G. the FCA.",
          type: 1,
          answer: "none",
          date: '',
        },
      ],
    },
    {
      header: "Protection",
      content: [
        {
          title:
            "1. Does the business have to meet any Compliance Standards or is governed by Industry Bodies? E.G. the FCA.",
          type: 1,
          answer: "none",
          date: '',
        },
        {
          title:
            "2. Does the business have to meet any Compliance Standards or is governed by Industry Bodies? E.G. the FCA.",
          type: 2,
          answer: "none",
          date: '',
        },
        {
          title:
            "3. Does the business have to meet any Compliance Standards or is governed by Industry Bodies? E.G. the FCA.",
          type: 2,
          answer: "none",
          date: '',
        },
        {
          title:
            "4. Does the business have to meet any Compliance Standards or is governed by Industry Bodies? E.G. the FCA.",
          type: 2,
          answer: "none",
          date: '',
        },
        {
          title:
            "5. Does the business have to meet any Compliance Standards or is governed by Industry Bodies? E.G. the FCA.",
          type: 1,
          answer: "none",
          date: '',
        },
        {
          title:
            "6. Does the business have to meet any Compliance Standards or is governed by Industry Bodies? E.G. the FCA.",
          type: 2,
          answer: "none",
          date: '',
        },
        {
          title:
            "7. Does the business have to meet any Compliance Standards or is governed by Industry Bodies? E.G. the FCA.",
          type: 1,
          answer: "none",
          date: '',
        },
        {
          title:
            "8. Does the business have to meet any Compliance Standards or is governed by Industry Bodies? E.G. the FCA.",
          type: 1,
          answer: "none",
          date: '',
        },
      ],
    },
    {
      header: "Detection",
      content: [
        {
          title:
            "1. Does the business have to meet any Compliance Standards or is governed by Industry Bodies? E.G. the FCA.",
          type: 1,
          answer: "none",
          date: '',
        },
        {
          title:
            "2. Does the business have to meet any Compliance Standards or is governed by Industry Bodies? E.G. the FCA.",
          type: 2,
          answer: "none",
          date: '',
        },
        {
          title:
            "3. Does the business have to meet any Compliance Standards or is governed by Industry Bodies? E.G. the FCA.",
          type: 2,
          answer: "none",
          date: '',
        },
        {
          title:
            "4. Does the business have to meet any Compliance Standards or is governed by Industry Bodies? E.G. the FCA.",
          type: 2,
          answer: "none",
          date: '',
        },
        {
          title:
            "5. Does the business have to meet any Compliance Standards or is governed by Industry Bodies? E.G. the FCA.",
          type: 1,
          answer: "none",
          date: '',
        },
        {
          title:
            "6. Does the business have to meet any Compliance Standards or is governed by Industry Bodies? E.G. the FCA.",
          type: 2,
          answer: "none",
          date: '',
        },
        {
          title:
            "7. Does the business have to meet any Compliance Standards or is governed by Industry Bodies? E.G. the FCA.",
          type: 1,
          answer: "none",
          date: '',
        },
        {
          title:
            "8. Does the business have to meet any Compliance Standards or is governed by Industry Bodies? E.G. the FCA.",
          type: 1,
          answer: "none",
          date: '',
        },
      ],
    },
    {
      header: "Incident Response",
      content: [
        {
          title:
            "1. Does the business have to meet any Compliance Standards or is governed by Industry Bodies? E.G. the FCA.",
          type: 1,
          answer: "none",
          date: '',
        },
        {
          title:
            "2. Does the business have to meet any Compliance Standards or is governed by Industry Bodies? E.G. the FCA.",
          type: 2,
          answer: "none",
          date: '',
        },
        {
          title:
            "3. Does the business have to meet any Compliance Standards or is governed by Industry Bodies? E.G. the FCA.",
          type: 2,
          answer: "none",
          date: '',
        },
        {
          title:
            "4. Does the business have to meet any Compliance Standards or is governed by Industry Bodies? E.G. the FCA.",
          type: 2,
          answer: "none",
          date: '',
        },
        {
          title:
            "5. Does the business have to meet any Compliance Standards or is governed by Industry Bodies? E.G. the FCA.",
          type: 1,
          answer: "none",
          date: '',
        },
        {
          title:
            "6. Does the business have to meet any Compliance Standards or is governed by Industry Bodies? E.G. the FCA.",
          type: 2,
          answer: "none",
          date: '',
        },
        {
          title:
            "7. Does the business have to meet any Compliance Standards or is governed by Industry Bodies? E.G. the FCA.",
          type: 1,
          answer: "none",
          date: '',
        },
        {
          title:
            "8. Does the business have to meet any Compliance Standards or is governed by Industry Bodies? E.G. the FCA.",
          type: 1,
          answer: "none",
          date: '',
        },
      ],
    },
    {
      header: "Backup & Disaster Recovery",
      content: [
        {
          title:
            "1. Does the business have to meet any Compliance Standards or is governed by Industry Bodies? E.G. the FCA.",
          type: 1,
          answer: "none",
          date: '',
        },
        {
          title:
            "2. Does the business have to meet any Compliance Standards or is governed by Industry Bodies? E.G. the FCA.",
          type: 2,
          answer: "none",
          date: '',
        },
        {
          title:
            "3. Does the business have to meet any Compliance Standards or is governed by Industry Bodies? E.G. the FCA.",
          type: 2,
          answer: "none",
          date: '',
        },
        {
          title:
            "4. Does the business have to meet any Compliance Standards or is governed by Industry Bodies? E.G. the FCA.",
          type: 2,
          answer: "none",
          date: '',
        },
        {
          title:
            "5. Does the business have to meet any Compliance Standards or is governed by Industry Bodies? E.G. the FCA.",
          type: 1,
          answer: "none",
          date: '',
        },
        {
          title:
            "6. Does the business have to meet any Compliance Standards or is governed by Industry Bodies? E.G. the FCA.",
          type: 2,
          answer: "none",
          date: '',
        },
        {
          title:
            "7. Does the business have to meet any Compliance Standards or is governed by Industry Bodies? E.G. the FCA.",
          type: 1,
          answer: "none",
          date: '',
        },
        {
          title:
            "8. Does the business have to meet any Compliance Standards or is governed by Industry Bodies? E.G. the FCA.",
          type: 1,
          answer: "none",
          date: '',
        },
      ],
    },
  ]);

  const dummy = [
    {
      header: "Identify",
      content: [
        {
          title:
            "1. Does the business have to meet any Compliance Standards or is governed by Industry Bodies? E.G. the FCA.",
          type: 1,
          answer: "none",
          date: '',
        },
        {
          title:
            "2. Does the business have to meet any Compliance Standards or is governed by Industry Bodies? E.G. the FCA.",
          type: 2,
          answer: "none",
          date: '',
        },
        {
          title:
            "3. Does the business have to meet any Compliance Standards or is governed by Industry Bodies? E.G. the FCA.",
          type: 2,
          answer: "none",
          date: '',
        },
        {
          title:
            "4. Does the business have to meet any Compliance Standards or is governed by Industry Bodies? E.G. the FCA.",
          type: 2,
          answer: "none",
          date: '',
        },
        {
          title:
            "5. Does the business have to meet any Compliance Standards or is governed by Industry Bodies? E.G. the FCA.",
          type: 1,
          answer: "none",
          date: '',
        },
        {
          title:
            "6. Does the business have to meet any Compliance Standards or is governed by Industry Bodies? E.G. the FCA.",
          type: 2,
          answer: "none",
          date: '',
        },
        {
          title:
            "7. Does the business have to meet any Compliance Standards or is governed by Industry Bodies? E.G. the FCA.",
          type: 1,
          answer: "none",
          date: '',
        },
        {
          title:
            "8. Does the business have to meet any Compliance Standards or is governed by Industry Bodies? E.G. the FCA.",
          type: 1,
          answer: "none",
          date: '',
        },
      ],
    },
    {
      header: "Protection",
      content: [
        {
          title:
            "1. Does the business have to meet any Compliance Standards or is governed by Industry Bodies? E.G. the FCA.",
          type: 1,
          answer: "none",
          date: '',
        },
        {
          title:
            "2. Does the business have to meet any Compliance Standards or is governed by Industry Bodies? E.G. the FCA.",
          type: 2,
          answer: "none",
          date: '',
        },
        {
          title:
            "3. Does the business have to meet any Compliance Standards or is governed by Industry Bodies? E.G. the FCA.",
          type: 2,
          answer: "none",
          date: '',
        },
        {
          title:
            "4. Does the business have to meet any Compliance Standards or is governed by Industry Bodies? E.G. the FCA.",
          type: 2,
          answer: "none",
          date: '',
        },
        {
          title:
            "5. Does the business have to meet any Compliance Standards or is governed by Industry Bodies? E.G. the FCA.",
          type: 1,
          answer: "none",
          date: '',
        },
        {
          title:
            "6. Does the business have to meet any Compliance Standards or is governed by Industry Bodies? E.G. the FCA.",
          type: 2,
          answer: "none",
          date: '',
        },
        {
          title:
            "7. Does the business have to meet any Compliance Standards or is governed by Industry Bodies? E.G. the FCA.",
          type: 1,
          answer: "none",
          date: '',
        },
        {
          title:
            "8. Does the business have to meet any Compliance Standards or is governed by Industry Bodies? E.G. the FCA.",
          type: 1,
          answer: "none",
          date: '',
        },
      ],
    },
    {
      header: "Detection",
      content: [
        {
          title:
            "1. Does the business have to meet any Compliance Standards or is governed by Industry Bodies? E.G. the FCA.",
          type: 1,
          answer: "none",
          date: '',
        },
        {
          title:
            "2. Does the business have to meet any Compliance Standards or is governed by Industry Bodies? E.G. the FCA.",
          type: 2,
          answer: "none",
          date: '',
        },
        {
          title:
            "3. Does the business have to meet any Compliance Standards or is governed by Industry Bodies? E.G. the FCA.",
          type: 2,
          answer: "none",
          date: '',
        },
        {
          title:
            "4. Does the business have to meet any Compliance Standards or is governed by Industry Bodies? E.G. the FCA.",
          type: 2,
          answer: "none",
          date: '',
        },
        {
          title:
            "5. Does the business have to meet any Compliance Standards or is governed by Industry Bodies? E.G. the FCA.",
          type: 1,
          answer: "none",
          date: '',
        },
        {
          title:
            "6. Does the business have to meet any Compliance Standards or is governed by Industry Bodies? E.G. the FCA.",
          type: 2,
          answer: "none",
          date: '',
        },
        {
          title:
            "7. Does the business have to meet any Compliance Standards or is governed by Industry Bodies? E.G. the FCA.",
          type: 1,
          answer: "none",
          date: '',
        },
        {
          title:
            "8. Does the business have to meet any Compliance Standards or is governed by Industry Bodies? E.G. the FCA.",
          type: 1,
          answer: "none",
          date: '',
        },
      ],
    },
    {
      header: "Incident Response",
      content: [
        {
          title:
            "1. Does the business have to meet any Compliance Standards or is governed by Industry Bodies? E.G. the FCA.",
          type: 1,
          answer: "none",
          date: '',
        },
        {
          title:
            "2. Does the business have to meet any Compliance Standards or is governed by Industry Bodies? E.G. the FCA.",
          type: 2,
          answer: "none",
          date: '',
        },
        {
          title:
            "3. Does the business have to meet any Compliance Standards or is governed by Industry Bodies? E.G. the FCA.",
          type: 2,
          answer: "none",
          date: '',
        },
        {
          title:
            "4. Does the business have to meet any Compliance Standards or is governed by Industry Bodies? E.G. the FCA.",
          type: 2,
          answer: "none",
          date: '',
        },
        {
          title:
            "5. Does the business have to meet any Compliance Standards or is governed by Industry Bodies? E.G. the FCA.",
          type: 1,
          answer: "none",
          date: '',
        },
        {
          title:
            "6. Does the business have to meet any Compliance Standards or is governed by Industry Bodies? E.G. the FCA.",
          type: 2,
          answer: "none",
          date: '',
        },
        {
          title:
            "7. Does the business have to meet any Compliance Standards or is governed by Industry Bodies? E.G. the FCA.",
          type: 1,
          answer: "none",
          date: '',
        },
        {
          title:
            "8. Does the business have to meet any Compliance Standards or is governed by Industry Bodies? E.G. the FCA.",
          type: 1,
          answer: "none",
          date: '',
        },
      ],
    },
    {
      header: "Backup & Disaster Recovery",
      content: [
        {
          title:
            "1. Does the business have to meet any Compliance Standards or is governed by Industry Bodies? E.G. the FCA.",
          type: 1,
          answer: "none",
          date: '',
        },
        {
          title:
            "2. Does the business have to meet any Compliance Standards or is governed by Industry Bodies? E.G. the FCA.",
          type: 2,
          answer: "none",
          date: '',
        },
        {
          title:
            "3. Does the business have to meet any Compliance Standards or is governed by Industry Bodies? E.G. the FCA.",
          type: 2,
          answer: "none",
          date: '',
        },
        {
          title:
            "4. Does the business have to meet any Compliance Standards or is governed by Industry Bodies? E.G. the FCA.",
          type: 2,
          answer: "none",
          date: '',
        },
        {
          title:
            "5. Does the business have to meet any Compliance Standards or is governed by Industry Bodies? E.G. the FCA.",
          type: 1,
          answer: "none",
          date: '',
        },
        {
          title:
            "6. Does the business have to meet any Compliance Standards or is governed by Industry Bodies? E.G. the FCA.",
          type: 2,
          answer: "none",
          date: '',
        },
        {
          title:
            "7. Does the business have to meet any Compliance Standards or is governed by Industry Bodies? E.G. the FCA.",
          type: 1,
          answer: "none",
          date: '',
        },
        {
          title:
            "8. Does the business have to meet any Compliance Standards or is governed by Industry Bodies? E.G. the FCA.",
          type: 1,
          answer: "none",
          date: '',
        },
      ],
    },
  ];
  const Next = () => {
    const incr = activeCount + 1;
    if (incr <= data.length) {
      setActiveCount(incr);
    }
    const width = prwidth + 20;
    setPrwidth(width);
  };

  const Back = () => {
    const incr = activeCount - 1;
    if (incr >= 1) {
      setActiveCount(incr);
      const width = prwidth - 20;
      setPrwidth(width);
    }
  };

  const isCloseModal = () => {
    props.isClose();
    setActiveCount(1);
    setData(dummy);
    setPrwidth(20);
  };

  return (
    <Modal
      animation={false}
      show={props.isOpen}
      dialogClassName="modal-50w"
      className="integrationModal  centerModal dashboardModal"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      scrollable
    >
      <Modal.Header className="text-center ">
        {activeCount > 1 && (
          <FiChevronLeft
            className="cursor-pointer"
            onClick={() => Back()}
            size="25"
          />
        )}

        <h1 className="w-100 mb-0 mt-0"> {data[activeCount - 1].header}</h1>

        <span className="cursor-pointer" onClick={() => isCloseModal()}>
          <img src="/images/icons/close.svg" alt="" />
        </span>
      </Modal.Header>
      <Modal.Body>
        <Row className="m-0">
          <div
            style={{
              position: "absolute",
              left: "0px",
              background: "#008fd4",
              height: "1px",
              width: `${prwidth}%`,
            }}
          ></div>
          <Col md={12} className="p-0">
            {data[activeCount - 1]["content"] &&
              data[activeCount - 1]["content"].map((val, i) => (
                <Fragment key={i}>
                  <p className="mt-5">{val.title}</p>
                  {val.type == 2 ? (
                    <InputGroup className="mt-16px grey-input mb8px inptDate">
                      <InputGroup.Prepend>
                        <InputGroup.Text className="greyText">
                          Date
                        </InputGroup.Text>
                      </InputGroup.Prepend>
                      <Form.Control
                        type="date"
                        defaultValue={moment().format("YYYY-MM-DD")}
                        value={val.date}
                        onChange={(e) => {
                        const arr = [...data];
                         arr[activeCount - 1]["content"][i]['date'] = e.target.value;
                         setData(arr)
                        }}
                      />
                    </InputGroup>
                  ) : (
                    <div className="mb16px mt16px">
                      {/* lassName={`"btn-blue" : "btn-grey" */}
                      <Button
                        className={` mr16px ${
                          val.answer == 'none' ? "btn-grey" :  val.answer == true ? "btn-primary" : "btn-grey"
                        }`}
                        onClick={(e) => {
                         const arr = [...data];
                         arr[activeCount - 1]["content"][i]['answer'] = true;
                         setData(arr)
                        }}
                      >
                        Yes
                      </Button>
                      <Button
                        className={`${val.answer == "none" ? "btn-grey" : val.answer == false ? "btn-primary" : "btn-grey"} `}
                       onClick={(e) => {
                         const arr = [...data];
                         arr[activeCount - 1]["content"][i]['answer'] = false;
                         setData(arr)
                        }}
                      >
                        No
                      </Button>
                    </div>
                  )}
                </Fragment>
              ))}
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Row className="m-0" style={{ justifyContent: "space-between" }}>
          <Col
            md={4}
            className="crsrpoint p-0 d-flex align-items-center justify-content-left"
          ></Col>
          <Col
            md={4}
            className="p-0 text-center d-flex align-items-center justify-content-center"
          >
            {activeCount}/{data.length}
          </Col>
          <Col md={4} className="p-0 text-right">
            <Button
              variant="primary"
              type="submit"
              style={{ height: "47px", padding: "13px 23px" }}
              className="float-right"
              onClick={
                activeCount == data.length ? () => isCloseModal() : () => Next()
              }
            >
              {activeCount == data.length ? "Plot my Posture" : "Next"}
            </Button>
          </Col>
        </Row>
      </Modal.Footer>
    </Modal>
  );
};

export default QuestionnaireModal;
