import React, { useState } from "react";
import { Button, Dropdown, Form } from "react-bootstrap"; 
import { DateRangePicker, createStaticRanges } from "react-date-range";
import moment from "moment";
import {
  addDays,
  endOfDay,
  startOfDay,
  startOfMonth,
  endOfMonth,
  addMonths,
  startOfWeek,
  endOfWeek,
  startOfYear,
  endOfYear,
  addYears,
  startOfQuarter,
  endOfQuarter,
  subQuarters,
  subMonths,
  subYears,
} from "date-fns";
const HomeScreenSubscriptionFilters = ({
  activeFilters,
  setActiveFilters,  
  onApply = () => {}, 
  subscriptionViewData,
}) => {
  const [isDateDropdownOpen, setIsDateDropdownOpen] = useState(false);
   
  const [isSubcategoryDropdownOpen, setIsSubcategoryDropdownOpen] =
    useState(false); 
  const [isSupplierDropdownOpen, setIsSupplierDropdownOpen] = useState(false);
  const [supplierSearchString, setSupplierSearchString] = useState("");
 const [isStatusDropdownOpen, setIsStatusDropdownOpen] = useState(false)
const [statusSearchString, setStatusSearchString] = useState('')
  const defineds = {
    startOfWeek: startOfWeek(new Date()),
    endOfWeek: endOfWeek(new Date()),
    startOfLastWeek: startOfWeek(addDays(new Date(), -7)),
    endOfLastWeek: endOfWeek(addDays(new Date(), -7)),
    startOfToday: startOfDay(new Date()),
    startOfLastSevenDay: startOfDay(addDays(new Date(), -7)),
    startOfLastThirtyDay: startOfDay(addDays(new Date(), -30)),
    startOfLastNintyDay: startOfDay(addDays(new Date(), -90)),
    endOfToday: endOfDay(new Date()),
    startOfYesterday: startOfDay(addDays(new Date(), -1)),
    endOfYesterday: endOfDay(addDays(new Date(), -1)),
    startOfMonth: startOfMonth(new Date()),
    endOfMonth: endOfMonth(new Date()),
    startOfLastMonth: startOfMonth(addMonths(new Date(), -1)),
    endOfLastMonth: endOfMonth(addMonths(new Date(), -1)),
    startOfYear: startOfYear(new Date()),
    endOfYear: endOfYear(new Date()),
    startOflastYear: startOfYear(addYears(new Date(), -1)),
    endOflastYear: endOfYear(addYears(new Date(), -1)),
    startOfLastQuarter: startOfQuarter(subQuarters(new Date(), 1)),
    endOfLastQuarter: endOfQuarter(subQuarters(new Date(), 1)),
    startOfLastSixMonth: startOfMonth(subMonths(new Date(), 6)),
    startOfLastTwelveMonth: startOfMonth(subMonths(new Date(), 12)),
    startOfCurrentYear: startOfYear(new Date()),
    endOfPreviousYear: endOfYear(subYears(new Date(), 1)),
  };

  const sideBarOptions = () => {
    const customDateObjects = [
      {
        label: "Current Year",
        range: () => ({
          startDate: defineds.startOfCurrentYear,
          endDate: defineds.endOfToday,
        }),
      },
      {
        label: "Previous Year",
        range: () => ({
          startDate: defineds.startOflastYear,
          endDate: defineds.endOfPreviousYear,
        }),
      },
      {
        label: "Last 12 Months",
        range: () => ({
          startDate: defineds.startOfLastTwelveMonth,
          endDate: defineds.endOfToday,
        }),
      },
      {
        label: "Last 6 Months",
        range: () => ({
          startDate: defineds.startOfLastSixMonth,
          endDate: defineds.endOfToday,
        }),
      },
      {
        label: "Last 90 Days",
        range: () => ({
          startDate: defineds.startOfLastNintyDay,
          endDate: defineds.endOfToday,
        }),
      },
      {
        label: "Last 30 Days",
        range: () => ({
          startDate: defineds.startOfLastThirtyDay,
          endDate: defineds.endOfToday,
        }),
      },
    ];

    return customDateObjects;
  };

  const sideBar = sideBarOptions();

  const staticRanges = [
    // ...defaultStaticRanges,
    ...createStaticRanges(sideBar),
  ];

 


 

 


  
  return (
    <div>
      {activeFilters?.length > 0 && (
        <div className="d-flex align-items-center justify-content-between filter-box-X">
          <div className="d-flex flex-wrap">
            {activeFilters?.map((activeFilter, activeFiterIndex) => {
              let selectedfromAmount = activeFilter?.fromAmount;
              let selectedtoAmount = activeFilter?.toAmount;

              let selectedSubCategories = activeFilter?.filterList;
              let selectedCategories = activeFilter?.filterList;
              let selectedSuppliers = activeFilter?.filterList;
              let selectedStatuses= activeFilter?.filterList;
              let selectedDateRange = activeFilter?.selectedDateRange ?? {};
              let dateRangeSelected =
                (Object.keys(selectedDateRange).length > 0 &&
                  selectedDateRange?.startDate &&
                  selectedDateRange?.endDate) ??
                false;

              return (
                <div className="">
                  {activeFilter?.filterType === "amount" && (
                    <>
                      <Dropdown
                        className="amount-filter-dropdown m-1"
                        id={`${activeFilter?.filterType}_filterX`}
                      >
                        <Dropdown.Toggle className="p-0">
                          <div
                            className={`d-flex align-items-center px-2 py-1 rounded-full ${
                              selectedfromAmount ? "bg-blue" : "bg-grey"
                            }`}
                          >
                            <div
                              className={`${
                                selectedfromAmount ? "f-white" : "f-grey"
                              } f-500`}
                            >
                              Amount
                            </div>
                            {selectedfromAmount && (
                              <div>
                                : £ {selectedfromAmount}{" "}
                                {selectedtoAmount
                                  ? ` - ${selectedtoAmount}`
                                  : ""}
                              </div>
                            )}
                            <img
                              src="/images/attack-surface/cross-icon.svg"
                              alt=""
                              className={`ml-2 pointer   ${
                                selectedfromAmount ? "white-filter" : ""
                              }`}
                              onClick={() => {
                                let updated_active_filters = [...activeFilters];
                                // if (updated_active_filters?.length === 1) {
                                //   onReset();
                                // }
                                updated_active_filters.splice(
                                  activeFiterIndex,
                                  1
                                );
                                onApply(updated_active_filters);
                                setActiveFilters(updated_active_filters);
                              }}
                            />
                          </div>
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="py-0 px-2">
                          <div className="f-12 f-500 f-darkgrey my-2 ml-2">
                            Amount
                          </div>
                          <div className="d-flex align-items-center ">
                            <div className="p-1 bg-grey radius-4 highlight-input-with-grey-border d-flex align-items-center">
                              <div className="f-darkgrey f-500 flex-grow-1 mt-1px">
                                £&nbsp;
                              </div>
                              <input
                                type="number"
                                className="border-none bg-transparent w-100 flex-grow-1"
                                placeholder="From"
                                value={activeFilter?.fromAmount ?? ""}
                                onChange={(e) => {
                                  let updated_active_filters = [
                                    ...activeFilters,
                                  ];
                                  updated_active_filters[
                                    activeFiterIndex
                                  ].fromAmount = e.target.value;
                                  setActiveFilters(updated_active_filters);
                                }}
                                onBlur={(e) => {
                                  return;
                                  if (e.target.value === "") {
                                    let updated_active_filters = [
                                      ...activeFilters,
                                    ];
                                    updated_active_filters.splice(
                                      activeFiterIndex,
                                      1
                                    );
                                    onApply(updated_active_filters);
                                    setActiveFilters(updated_active_filters);
                                  } else {
                                    let updated_active_filters = [
                                      ...activeFilters,
                                    ];
                                    updated_active_filters[
                                      activeFiterIndex
                                    ].fromAmount = e.target.value;
                                    onApply(updated_active_filters);
                                  }
                                }}
                                onKeyDown={(e) => {
                                  if (e.key === "Enter") {
                                    document
                                      .getElementById(
                                        `${activeFilter?.filterType}_filterX`
                                      )
                                      .click();
                                  }
                                }}
                                autoFocus
                              />
                              {/* <Button className="btn-blue-imp px-2 p-0">
                            <img
                              alt=""
                              width={11}
                              className=" pointer"
                              src="/images/settings-v2/yes-tick-task.svg"
                              onClick={() => {
                                document
                                  .getElementById(
                                    `${activeFilter?.filterType}_filterX`
                                  )
                                  .click();
                              }}
                            />
                          </Button> */}
                            </div>
                            <div className="mx-1">-</div>
                            <div className="p-1 bg-grey radius-4 highlight-input-with-grey-border d-flex align-items-center">
                              <div className="f-darkgrey f-500 flex-grow-1 mt-1px">
                                £&nbsp;
                              </div>
                              <input
                                type="number"
                                className="border-none bg-transparent w-100 flex-grow-1"
                                placeholder="To"
                                value={activeFilter?.toAmount ?? ""}
                                onChange={(e) => {
                                  let updated_active_filters = [
                                    ...activeFilters,
                                  ];
                                  updated_active_filters[
                                    activeFiterIndex
                                  ].toAmount = e.target.value;
                                  setActiveFilters(updated_active_filters);
                                }}
                                onBlur={(e) => {
                                  return;
                                  if (e.target.value === "") {
                                    let updated_active_filters = [
                                      ...activeFilters,
                                    ];
                                    updated_active_filters.splice(
                                      activeFiterIndex,
                                      1
                                    );
                                    onApply(updated_active_filters);
                                    setActiveFilters(updated_active_filters);
                                  } else {
                                    let updated_active_filters = [
                                      ...activeFilters,
                                    ];
                                    updated_active_filters[
                                      activeFiterIndex
                                    ].toAmount = e.target.value;
                                    onApply(updated_active_filters);
                                  }
                                }}
                                onKeyDown={(e) => {
                                  if (e.key === "Enter") {
                                    document
                                      .getElementById(
                                        `${activeFilter?.filterType}_filterX`
                                      )
                                      .click();
                                  }
                                }}
                                 
                              />
                              <Button className="btn-blue-imp px-2 p-0">
                            <img
                              alt=""
                              width={11}
                              className=" pointer"
                              src="/images/settings-v2/yes-tick-task.svg"
                              onClick={() => {
                                let updated_active_filters = [
                                  ...activeFilters,
                                ];
                                
                                if(`updated_active_filters[
                                  activeFiterIndex
                                ].toAmount` &&
                                updated_active_filters[
                                  activeFiterIndex
                                ].fromAmount){
onApply(updated_active_filters)
                                }else{
                                  return
                                }




                                document
                                  .getElementById(
                                    `${activeFilter?.filterType}_filterX`
                                  )
                                  .click();
                              }}
                            />
                          </Button>
                            </div>
                          </div>

                          <div className="p-2" />
                        </Dropdown.Menu>
                      </Dropdown>
                    </>
                  )}
                  {activeFilter?.filterType === "supplier" && (
                    <>
                      <Dropdown
                        className="subcategory-filter-dropdown m-1"
                        id={`${activeFilter?.filterType}_filterX`}
                        show={isSupplierDropdownOpen}
                        onToggle={(isOpen) => {
                          setIsSupplierDropdownOpen(isOpen);
                          setSupplierSearchString("");
                        }}
                      >
                        <Dropdown.Toggle className="p-0">
                          <div
                            className={`d-flex align-items-center px-2 py-1 rounded-full ${
                              selectedSuppliers?.length > 0
                                ? "bg-blue"
                                : "bg-grey"
                            }`}
                          >
                            <div
                              className={`${
                                selectedSuppliers?.length > 0
                                  ? "f-white"
                                  : "f-grey"
                              } f-500`}
                            >
                              Supplier
                            </div>
                            {selectedSuppliers?.length > 0 && (
                              <div className="f-500">
                                : {selectedSuppliers[0].PotentialSupplierName}{" "}
                                {selectedSuppliers?.length > 1
                                  ? `+${selectedSuppliers?.length - 1}`
                                  : ""}
                              </div>
                            )}
                            <img
                              src="/images/attack-surface/cross-icon.svg"
                              alt=""
                              className={`ml-2 pointer   ${
                                selectedSuppliers?.length > 0
                                  ? "white-filter"
                                  : ""
                              }`}
                              onClick={() => {
                                let updated_active_filters = [...activeFilters];
                                // if (updated_active_filters?.length === 1) {
                                //   onReset();
                                // }
                                updated_active_filters.splice(
                                  activeFiterIndex,
                                  1
                                );
                                onApply(updated_active_filters);
                                setActiveFilters(updated_active_filters);
                                setTimeout(() => {
                                  setIsSubcategoryDropdownOpen(false);
                                }, 100);
                              }}
                            />
                          </div>
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="py-0 px-2">
                          <div className="d-flex align-items-center mx-2 mt-2 mb-2">
                            <img
                              src="/images/attack-surface/search-icon.svg"
                              className="mr-1"
                              alt=""
                            />
                            <input
                              type="text"
                              className="flex-1 bg-transparent border-none pl-1"
                              placeholder="Search"
                              value={supplierSearchString}
                              onChange={(e) => {
                                setSupplierSearchString(e.target.value);
                              }}
                              autoFocus
                            />
                            <img
                              src="/images/settings-v2/header-search-cross.svg"
                              alt=""
                              className="mx-1 pointer"
                              style={{
                                visibility: supplierSearchString
                                  ? "visible"
                                  : "hidden",
                              }}
                              onClick={() => {
                                setSupplierSearchString("");
                              }}
                            />
                          </div>
                          <div className="f-12 f-500 f-darkgrey mt-2 ml-2">
                            Supplier
                          </div>
                          <div className="p-1 ">
                            {subscriptionViewData?.SupplierList?.filter((el) =>
                              el.PotentialSupplierName.toLowerCase().includes(
                                supplierSearchString.toLowerCase()
                              )
                            )?.map((supplier, supplierIndex) => {
                              let supplierIndexSelected =
                                activeFilter?.filterList?.findIndex(
                                  (item) => Number(item?.PotentialSupplierId) === supplier?.PotentialSupplierId
                                );
                              return (
                                <div className="d-flex align-items-center">
                                  <div>
                                    <Form.Group
                                      controlId={`card-supplier-${supplierIndex}`}
                                      className={`round-checkbox blue-checkbox checkboxstyle mb-3 ${"radio-shadow"}`}
                                    >
                                      <Form.Check
                                        type={"checkbox"}
                                        checked={
                                          supplierIndexSelected > -1
                                            ? true
                                            : false
                                        }
                                        name={
                                          `check-supplier ` + supplierIndex + ""
                                        }
                                        label=""
                                        disabled={false}
                                        onChange={() => {}}
                                        onClick={(e) => {
                                          let updated_active_filters = [
                                            ...activeFilters,
                                          ];
                                          if (supplierIndexSelected > -1) {
                                            updated_active_filters[
                                              activeFiterIndex
                                            ].filterList.splice(
                                              supplierIndexSelected,
                                              1
                                            );
                                          } else {
                                            updated_active_filters[
                                              activeFiterIndex
                                            ].filterList = [
                                              ...updated_active_filters[
                                                activeFiterIndex
                                              ].filterList,
                                              supplier,
                                            ];
                                          }
                                          onApply(updated_active_filters);
                                          setActiveFilters(
                                            updated_active_filters
                                          );
                                        }}
                                      />
                                    </Form.Group>
                                  </div>

                                  <div className="f-500 pl-3 pt-2">
                                    {supplier?.PotentialSupplierName}
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                          <div className="p-2" />
                        </Dropdown.Menu>
                      </Dropdown>
                    </>
                  )}
                  {activeFilter?.filterType === "expires" && (
                    <>
                      <Dropdown
                        className="date-filter-dropdown m-1 technologySpendModelDatePicker"
                        id={`${activeFilter?.filterType}_filterX`}
                        show={isDateDropdownOpen}
                        onToggle={(isOpen) => setIsDateDropdownOpen(isOpen)}
                      >
                        <Dropdown.Toggle className="p-0">
                          <div
                            className={`d-flex align-items-center px-2 py-1 rounded-full ${
                              dateRangeSelected ? "bg-blue" : "bg-grey"
                            }`}
                          >
                            <div
                              className={`${
                                dateRangeSelected ? "f-white" : "f-grey"
                              } f-500`}
                            >
                              Date
                            </div>
                            {dateRangeSelected && (
                              <div className="f-500">
                                :{" "}
                                {selectedDateRange?.startDate
                                  ? moment(selectedDateRange?.startDate).format(
                                      "MMM D, YYYY"
                                    )
                                  : ""}
                                {selectedDateRange?.endDate
                                  ? ` - ${moment(
                                      selectedDateRange?.endDate
                                    ).format("MMM D, YYYY")}`
                                  : ""}
                              </div>
                            )}

                            <img
                              src="/images/attack-surface/cross-icon.svg"
                              alt=""
                              className={`ml-2 pointer   ${
                                dateRangeSelected ? "white-filter" : ""
                              }`}
                              onClick={() => {
                                let updated_active_filters = [...activeFilters];
                                if (updated_active_filters?.length === 1) {
                                  setActiveFilters([]);
                                  // onReset();
                                  onApply([])
                                }
                                updated_active_filters.splice(
                                  activeFiterIndex,
                                  1
                                );
                                onApply(updated_active_filters);
                                setActiveFilters(updated_active_filters);
                                setTimeout(() => {
                                  setIsDateDropdownOpen(false);
                                }, 100);
                              }}
                            />
                          </div>
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="p-0">
                          <DateRangePicker
                            editableDateInputs={true}
                            startDatePlaceholder="From"
                            endDatePlaceholder="To"
                            ranges={[activeFilter?.selectedDateRange]}
                            staticRanges={staticRanges}
                            onChange={(ranges) => {
                              //
                              let updated_active_filters = [...activeFilters];
                              updated_active_filters[
                                activeFiterIndex
                              ].selectedDateRange = ranges.selection;
                              onApply(updated_active_filters);
                              setActiveFilters(updated_active_filters);
                              // document.querySelector('.technologySpendModelDatePicker').click()
                            }}
                          />
                          <div
                            className="f-12 pointer clearBlockHeight "
                            onClick={() => {
                              let updated_active_filters = [...activeFilters];
                              updated_active_filters[
                                activeFiterIndex
                              ].selectedDateRange = {
                                startDate: null, // new Date(),
                                endDate: null, // new Date(),
                                key: "selection",
                              };
                              onApply(updated_active_filters);
                              setActiveFilters(updated_active_filters);
                            }}
                            style={{
                              padding: "0.7rem",
                            }}
                          >
                            Clear
                          </div>
                        </Dropdown.Menu>
                      </Dropdown>
                    </>
                  )}
                    {activeFilter?.filterType === "status" && (
                    <>
                      <Dropdown
                        className="subcategory-filter-dropdown m-1"
                        id={`${activeFilter?.filterType}_filterX`}
                        show={isStatusDropdownOpen}
                        onToggle={(isOpen) => {
                          setIsStatusDropdownOpen(isOpen);
                          setStatusSearchString("");
                        }}
                      >
                        <Dropdown.Toggle className="p-0">
                          <div
                            className={`d-flex align-items-center px-2 py-1 rounded-full ${
                              selectedStatuses?.length > 0
                                ? "bg-blue"
                                : "bg-grey"
                            }`}
                          >
                            <div
                              className={`${
                                selectedStatuses?.length > 0
                                  ? "f-white"
                                  : "f-grey"
                              } f-500`}
                            >
                              Status
                            </div>
                            {selectedStatuses?.length > 0 && (
                              <div className="f-500">
                                : {selectedStatuses[0].Status}{" "}
                                {selectedStatuses?.length > 1
                                  ? `+${selectedStatuses?.length - 1}`
                                  : ""}
                              </div>
                            )}
                            <img
                              src="/images/attack-surface/cross-icon.svg"
                              alt=""
                              className={`ml-2 pointer   ${
                                selectedStatuses?.length > 0
                                  ? "white-filter"
                                  : ""
                              }`}
                              onClick={() => {
                                let updated_active_filters = [...activeFilters];
                                // if (updated_active_filters?.length === 1) {
                                //   onReset();
                                // }
                                updated_active_filters.splice(
                                  activeFiterIndex,
                                  1
                                );
                                onApply(updated_active_filters);
                                setActiveFilters(updated_active_filters);
                                setTimeout(() => {
                                  setIsStatusDropdownOpen(false);
                                }, 100);
                              }}
                            />
                          </div>
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="py-0 px-2">
                          <div className="d-fle x d-none align-items-center mx-2 mt-2 mb-2">
                            <img
                              src="/images/attack-surface/search-icon.svg"
                              className="mr-1"
                              alt=""
                            />
                            <input
                              type="text"
                              className="flex-1 bg-transparent border-none pl-1"
                              placeholder="Search"
                              value={statusSearchString}
                              onChange={(e) => {
                                setStatusSearchString(e.target.value);
                              }}
                              autoFocus
                            />
                            <img
                              src="/images/settings-v2/header-search-cross.svg"
                              alt=""
                              className="mx-1 pointer"
                              style={{
                                visibility: statusSearchString
                                  ? "visible"
                                  : "hidden",
                              }}
                              onClick={() => {
                                setStatusSearchString("");
                              }}
                            />
                          </div>
                          <div className="f-12 f-500 f-darkgrey mt-2 ml-2">
                            Status
                          </div>
                          <div className="p-1 ">
                            {subscriptionViewData?.StatusList?.filter((el) =>
                              el.Status.toLowerCase().includes(
                                statusSearchString.toLowerCase()
                              )
                            )?.map((status, statusIndex) => {
                              let statusIndexSelected =
                                activeFilter?.filterList?.findIndex(
                                  (item) => Number(item?.Id) === status?.Id
                                );
                              return (
                                <div className="d-flex align-items-center">
                                  <div>
                                    <Form.Group
                                      controlId={`card-status-${statusIndex}`}
                                      className={`round-checkbox blue-checkbox checkboxstyle mb-3 ${"radio-shadow"}`}
                                    >
                                      <Form.Check
                                        type={"checkbox"}
                                        checked={
                                          statusIndexSelected > -1
                                            ? true
                                            : false
                                        }
                                        name={
                                          `check-status ` + statusIndex + ""
                                        }
                                        label=""
                                        disabled={false}
                                        onChange={() => {}}
                                        onClick={(e) => {
                                          let updated_active_filters = [
                                            ...activeFilters,
                                          ];
                                          if (statusIndexSelected > -1) {
                                            updated_active_filters[
                                              activeFiterIndex
                                            ].filterList.splice(
                                              statusIndexSelected,
                                              1
                                            );
                                          } else {
                                            updated_active_filters[
                                              activeFiterIndex
                                            ].filterList = [
                                              ...updated_active_filters[
                                                activeFiterIndex
                                              ].filterList,
                                              status,
                                            ];
                                          }
                                          onApply(updated_active_filters);
                                          setActiveFilters(
                                            updated_active_filters
                                          );
                                        }}
                                      />
                                    </Form.Group>
                                  </div>

                                  <div className="f-500 pl-3 pt-2">
                                    {status?.Status}
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                          <div className="p-2" />
                        </Dropdown.Menu>
                      </Dropdown>
                    </>
                  )}
                  
 
                </div>
              );
            })}
          </div>

          <div
            className="d-flex align-items-center mr-4"
            style={{
              visibility: "visible",
            }}
          >
            {/* <div
              className="f-blue f-12 f-500 mr-2 pointer"
              disabled={applyingFilters}
              onClick={onApply}
            >
              {applyingFilters ? "Applying.." : "Apply"}
            </div> */}
            <div
              className="f-grey f-14 f-500 pointer"
              onClick={() => {
                setActiveFilters([]);
                // onReset();
                onApply([])
              }}
            >
              Reset
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default HomeScreenSubscriptionFilters;
