import React, { useEffect, useState } from "react";
import { Button, Dropdown, OverlayTrigger, Tooltip } from "react-bootstrap";
import { getAPIData, postRequest } from '../../../Utils/commonApi';
import { useAuth } from "../../context/Auth";
import "../membersAccess/membersAccess.scss"
import { CallToast } from "../../PriceCalculator/modals/CustomToast";
import MembersPageLoader from "../../settingsV2/members/MembersPageLoader";
import MemberAccessRoleModal from "./MemberAccessRoleModal";
import AddMemberModal from "./AddMemberModal";
import ImageWithInitials from "../../../Utils/ImageWithInitials";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { GreyLoader } from "../../Theme/APILoaders";
import ClientMspAddMemberModal from "../../MSPv2/secOps/trainingCertificates/ClientMspAddMemberModal";
import { viewOnlyAccess } from "../../App/App";


const MembersAccess = () => {
  const { authTokens } = useAuth();
  const [members, setMembers] = useState([]);
  const [roles, setRoles] = useState([]);
  const [sort, setSort] = useState(1)
  const [accessLevelId, setAccessLevelId] = useState(0);
  const [showAddMemberModal, setShowAddMemberModal] = useState(false);
  const [showAddClientMemberModal, setShowAddClientMemberModal] = useState(false);
  const [pageStatus, setPageStatus] = useState("Members");
  const [mainPageLoading, setMainPageLoading] = useState(false);
  const [showAccessLevelModal, setShowAccessLevelModal] = useState(false);
  const [IsDropdownOpen, setIsDropdownOpen] = useState({id: 0, state: false})
  const [showRoleDropdown, setShowRoleDropdown] = useState(false)
  const [duplicateRoleLoading, setDuplicateRoleLoading] = useState({isDuplicating: false, roleId: null})  //set false
  const [pageNumber, setPageNumber] = useState(1);
  const [isAdminConfirmed, setIsAdminConfirmed] = useState(false);
  const [tooltipMessage, setTooltipMessage] = useState(null);
  const history = useHistory();

  const accessData = window.localStorage.getItem("object_Data")
    ? JSON.parse(window.localStorage.getItem("object_Data"))?.accessData : null;

    const userDetail = window.localStorage.getItem("object_Data")
    ? JSON.parse(window.localStorage.getItem("object_Data"))?.UserDetail : null;

    const mspDetail = window.localStorage.getItem("object_Data")
    ? JSON.parse(window.localStorage.getItem("object_Data"))?.mspDetail : null;


  useEffect(() => {
    setMainPageLoading(true);
    getMembersDetails();
    getAccessRolesData();
  }, []);

  useEffect(() => {
    showAccessLevelModal === false && getAccessRolesData();
  }, [showAccessLevelModal]);

  async function getMembersDetails(pageNumber, openTeamModal = null) {
    await getAPIData("553", authTokens, {
      optionEnum1: pageNumber ? pageNumber : 1,
      optionEnum2: 20,
      optionEnum3: sort,
      optionEnum99: 42
    }).then((response) => {
      setMainPageLoading(false);
      if (response?.mr?.Success) {
        setMembers(response?.users);
        setIsAdminConfirmed(response?.isAdminConfirmed)
        setTooltipMessage(response?.notConfirmedMessage)
      } else {
        CallToast("Something went wrong", false, false, "v2style");
      }
    });
  }

  async function getAccessRolesData(){
    await getAPIData("551", authTokens, {
      optionEnum99: 42
    }).then((response) => {
      setMainPageLoading(false);
      if (response.mr?.Success) {
        setRoles(response.roles);
      }
    });
  }

  async function duplicateRole(roleId){
    setDuplicateRoleLoading({isDuplicating: true, roleId: roleId});
    await getAPIData(577, authTokens, {
        accessKey: JSON.parse(localStorage.getItem("user_MessageObj")),
        optionEnum1: roleId,
        optionEnum99: 42
    }).then((response) => {
      setDuplicateRoleLoading({isDuplicating: false, roleId: null});
      setMainPageLoading(false);
      if (response.Success) {
        getAccessRolesData();
      }
  });
  }

  async function deleteRole(roleID){
    await getAPIData(578, authTokens, {
        accessKey: JSON.parse(localStorage.getItem("user_MessageObj")),
        optionEnum1: roleID,
        optionEnum99: 42
    }).then((response) => {
      setMainPageLoading(false);
      if (response.Success) {
        getAccessRolesData();
      }else {
        if (response?.EntityId == -1) {
          CallToast(response?.Message, false, false, "v2style");
        } else {
          CallToast("Something went wrong", false, false, "v2style");
        }
      }
  });
  }

  async function changeMemberRole(roleId, userId, roleAction) {
    await postRequest(authTokens, "UpdateUserAccessRole", {
          objId_AccessRole: roleId,
          accessKey: JSON.parse(localStorage.getItem("user_MessageObj")),
          userId: userId,
          action: roleAction,
          optionEnum99: 42
    })
      .then((response) => {
        if (response?.Success) {
          CallToast("Access role changed successfully", false, false, "v2style");
          getMembersDetails();
          getAccessRolesData();
        } else {
          CallToast("Something went wrong", false, false, "v2style");
        }
      })
      .catch(() => {
        CallToast("Something went wrong", false, false, "v2style");
      });
  }

  async function handleDeleteUser(userSettingsId) {
    await getAPIData("109", authTokens, {
      optionEnum1: userSettingsId,
      accessKey: JSON.parse(localStorage.getItem("user_MessageObj")),
      optionEnum99: 42
    }).then((response) => {
      if (response.Success) {
        getMembersDetails();
        CallToast("Member deleted successfully", false, false, "v2style");
      } else {
        CallToast("Something went wrong", false, false, "v2style");
      }
    });
  }

  return (
    <div id={"msp-main-screen-for-integration-modal"}>
      {mainPageLoading && <MembersPageLoader />}
      <div className={`${mainPageLoading ? "d-none" : ""}`}>
        <div className="membersAccess-wrapper bottomMarginUserRoleBlocks">
          <div className="heading mb-24px">Members</div>
          <div className="d-flex justify-content-between ">
            <div className="align-items-center d-flex pointer" style={{'flexDirection': 'row', 'gap': '12px'}}>
              <div className={pageStatus === "Members" ?"txt-blue" : "f-grey"} onClick={()=>{setPageStatus('Members')}}>Members</div>  
              <div className={`px-2 rounded-full d-flex align-items-center f-grey bg-darkgrey f-8 f-500 pointer`}>
                {members.length}
              </div>
              <OverlayTrigger
                placement="bottom"
                trigger={(mspDetail !== null) ? ["hover", "focus"] : []}
                delay={{ show: 200, hide: 250 }}
                overlay={
                  <Tooltip id={`tooltip-1365`}>
                    {
                        mspDetail !== null && "This feature is managed by your service provider" 
                    }
                  </Tooltip>
                }
              >
                <div 
                  disabled={(mspDetail !== null) && true}
                  className={`${pageStatus === "Members" ? " f-grey " : " txt-blue "}  ${(mspDetail !== null) && ' not-allowed '} `} 
                  onClick={()=>{
                    if (mspDetail === null) {
                      setPageStatus('Access Role')
                    }
                  }}
                >
                  Access Role
                </div>
              </OverlayTrigger>
            </div>
            <div>
            <OverlayTrigger
                placement="bottom"
                trigger={(
                  (tooltipMessage !== null && true) ||
                    (mspDetail !== null && true) ||
                      (accessData?.Override === true && accessData.AccessState_UserManagement < 3) ||
                        viewOnlyAccess("AccessState_SystemSettings")
                  ) ? ["hover", "focus"] : []
                }
                delay={{ show: 200, hide: 250 }}
                overlay={
                  <Tooltip id={`tooltip-1365`}>
                    {
                      tooltipMessage !== null ? tooltipMessage :
                        mspDetail !== null ? "This feature is managed by your service provider" :
                          (accessData?.Override === true && accessData.AccessState_UserManagement < 3) || viewOnlyAccess("AccessState_SystemSettings") && "This feature is managed by your administrator"
                    }
                  </Tooltip>
                }
              >
                <Button
                  id={`add-team-btn`}
                  className={`d-flex align-items-center  ${(
                    tooltipMessage !== null ||
                      mspDetail !== null ||
                        ((accessData?.Override === true && accessData.AccessState_UserManagement < 3) || viewOnlyAccess("AccessState_SystemSettings"))
                      ) ? '  ' : 'add-member-btn'} 
                    `}
                  style={{
                    backgroundColor: tooltipMessage !== null && '#ebecf1',
                    border: tooltipMessage !== null && '1px solid #ebecf1',
                    color: tooltipMessage !== null && '#b6b6bf',
                    cursor: tooltipMessage !== null && 'default'
                  }}
                  disabled={
                    (isAdminConfirmed === false && true) ||
                      (mspDetail !== null && true) ||
                        (accessData?.Override === true && (accessData.AccessState_UserManagement < 3) ? true : false ) ||
                          (viewOnlyAccess("AccessState_SystemSettings") ? true : false)
                    }
                  onClick={() => {
                    getMembersDetails();
                    if(pageStatus === 'Members'){
                      mspDetail !== null ? setShowAddClientMemberModal(false) : setShowAddMemberModal(false);
                      setTimeout(() => {
                        mspDetail !== null ? setShowAddClientMemberModal(true) : setShowAddMemberModal(true);
                      }, 100);
                    }else {
                      setAccessLevelId(0);
                      setShowAccessLevelModal(true);
                      getAccessRolesData();
                    }
                  }}
                >
                  {
                    (viewOnlyAccess("AccessState_SystemSettings") || 
                      (accessData?.Override === true && accessData.AccessState_UserManagement < 3) || 
                      (mspDetail !== null) || (isAdminConfirmed === false)) ?
                    <img
                      alt=''
                      className='lockerDarkGreyImage mr-2 mb-1'
                      src='/images/settings-v2/locker-grey.svg'
                    />
                    :
                    <img
                      alt=""
                      src="/images/settings-v2/plus-icon.svg"
                      className="mr-2"
                    />
                  }
                  <div>
                    Add {pageStatus} 
                  </div>
                </Button>
              </OverlayTrigger>
            </div>
          </div>

        {pageStatus == "Members" ?
          <table className="w-100">
            <tr className="py-4px pointer grid-container">
              <th>
                <span className="ml-3">
                  Name
                  {
                    sort === 1 ? 
                    <img
                    alt=""
                    src="/images/actioncenter/arrowdown.svg"
                    className="ml-2"
                    onClick={() => {
                      setSort(2);
                      getMembersDetails();
                    }}
                  /> : 
                  <img
                    alt=""
                    src="/images/actioncenter/arrowup.svg"
                    className="ml-2"
                    onClick={() => {
                      setSort(1)
                      getMembersDetails();
                    }}
                  />
                  }
                </span>
              </th>
              <th>
                <span className="ml-3">Access Role</span>
              </th>
              <th></th>
            </tr>
            <div
              className="border-parent"
              style={{
                borderRadius: "8px",
              }}
            >
              {members.map(memb=>{
                return (
                  <div key={memb.initials} className="table-data border-item">
                  <tr className="grid-container">
                    <td 
                        className={`d-flex align-items-center ${viewOnlyAccess("AccessState_SystemSettings") ? 'cursor-default' : (memb.status === 'active' || memb.status === 'inactive') && ' pointer'}`}
                        onClick={() => {
                          if (!viewOnlyAccess("AccessState_SystemSettings")) {
                            (memb.status === 'active' || memb.status === 'inactive') && (
                              userDetail?.objId_UserSettings !== memb?.objId_UserSetting ? (
                              history.push(
                                `${window.location.pathname}/${memb?.objId_UserSetting}`,
                                {   
                                  selectedUserId: memb?.userId,
                                  isSelectedUserLoggedIn: memb?.IsLoggedInUser
                                }
                              )
                              ) : (
                                history.push(
                                  `/settings/v2/my_profile`
                                )
                              )
                              )
                            }
                          }}
                    >
                      {memb.showInitials ? ( 
                          <ImageWithInitials
                            initials={memb.initials}
                            width={30}
                            background={"purple"}
                            backgroundColor={memb.status === 'pending' ? "#D4D6DD" : undefined}
                            textColor={memb.status === 'pending'  ? "#616778" : undefined}
                            border={memb.status === 'pending' ? "dashed 1px #9499AA" : undefined}
                          />
                        ) : (
                          <img
                            alt=""
                            src={memb.Avatar}
                            className=""
                            style={{
                            width: "30px",
                            borderRadius: "50%",
                            objectFit: "contain",
                          }}
                        />
                    )
                      }

                      <div className="ml-2">
                        {
                          memb.status === 'pending' ? (
                            <>
                              <p 
                                className="user-name m-0"
                                style={{color: "#616778"}}
                              >
                                {memb.userName}
                              </p>
                              <div className="d-flex align-content-center">
                                <img
                                  src="/images/msp/dummy-clock-light-gray.svg"
                                  alt=""
                                  className="inviteClock mr-2"
                                />
                                <p 
                                  className="user-email  m-0"
                                  style={{color: '#9499AA'}}  
                                >
                                  Invite sent
                                </p>
                              </div>
                            </>
                          ) : (
                            <>
                              <p 
                               className="user-name m-0"
                              >
                               {memb.fullName}
                             </p>
                              <p className="user-email  m-0 ">
                                {memb.userName}
                              </p>
                            </>
                          )
                        }
                      </div>
                    </td>
                    <td className="d-flex align-items-center member-drop">
                    { 
                      viewOnlyAccess("AccessState_SystemSettings") ? 
                      <div className="d-flex align-items-center f-400">
                        <div className="greyText2">{
                          memb?.roleName ? <>{memb?.roleName?.length < 30 ? memb?.roleName : `${memb?.roleName?.slice(0,30)}...`}</> : 'Select'
                        }</div>
                        <img
                          alt=''
                          className='lockerDarkGreyImage mx-2 mb-1'
                          src='/images/settings-v2/locker-grey.svg'
                        />
                      </div>
                      :
                       <Dropdown
                       className="hg-white-dropdown user-detail-dropdown"
                       onToggle={(e) => {
                         setIsDropdownOpen({id: memb.roleName, state: e})
                        }}
                        >
                          <OverlayTrigger
                            placement="bottom"
                            trigger={(mspDetail !== null) ? ["hover", "focus"] : []}
                            delay={{ show: 200, hide: 250 }}
                            overlay={
                              <Tooltip id={`tooltip-1365`}>
                                {
                                    mspDetail !== null && "This feature is managed by your service provider" 
                                }
                              </Tooltip>
                            }
                          >
                        <Dropdown.Toggle 
                        className={`custom-dropdown-toggle f-gray-imp ${
                          IsDropdownOpen.id == memb?.roleName && IsDropdownOpen.state ?  "opened"  :""
                          }  ${(mspDetail !== null) && ' not-allowed '} `}
                        disabled={
                            (mspDetail !== null && true) ||
                              (memb.SO_Admin === true && true) ||
                                ((accessData?.Override === true && (accessData.AccessState_UserManagement < 3) || viewOnlyAccess("AccessState_SystemSettings")) ? true : false)
                          }
                        >
                            {
                              memb?.roleName ? <>{memb?.roleName?.length < 30 ? memb?.roleName : `${memb?.roleName?.slice(0,30)}...`}</> : 'Select'
                            }
                        </Dropdown.Toggle>
                         </OverlayTrigger>
                        <Dropdown.Menu>
                        <div
                              className="p-2 pl-3 my-1 pointer f-darkgrey"
                              style={{ 'display': 'grid','grid-template-columns': '10% 90%'}}
                              onClick={(e) => {
                                setAccessLevelId(0);
                                setShowAccessLevelModal(true);
                                e.target.closest(".hg-white-dropdown").click();
                              }}
                            >
                              <img
                                alt=""
                                src="/images/settings-v2/lightgrey-plus-icon.svg"
                                className="mr-2"
                              />
                              <div>Create Access Role</div>
                            </div>
                            <div
                              style={{
                                maxHeight: '200px',
                                overflowY: 'auto',
                              }}
                            >
                          {roles.map(el=>{
                                 return (<div
                                 key={el.RoleName + memb.userName}
                              className="p-2 pl-3 my-1 pointer f-grey"
                              style={{ 'display': 'grid','grid-template-columns': '10% 90%'}}
                              onClick={(e) => {
                                const memberRolesArray = memb?.objId_AccessRole?.split(',')?.map(Number);
                                let action;

                                if (memberRolesArray){
                                  const isRoleHere = memberRolesArray?.find(
                                    (item) => item === el.objId_AccessRole
                                  );
                                  action = isRoleHere === el.objId_AccessRole ? 0 : 1;
                                } else {
                                  action = 1;
                                }

                                if (memb?.IsLoggedInUser === true && memb?.SO_Admin === true) {
                                  if ( memberRolesArray.length === 1 && action === 0) {
                                    CallToast("Member role can not be empty", false, false, "v2style");
                                  }
                                } else {
                                  changeMemberRole(el.objId_AccessRole, memb.userId, action);
                                }
                                
                                e.target.closest(".hg-white-dropdown").click();
                              }}
                            >
                              <div>
                                {(memb?.objId_AccessRole?.split(',')?.map(Number))?.map((membRoleId )=> (
                                  membRoleId === el?.objId_AccessRole ? 
                                    <img
                                      alt=""
                                      src="/images/settings-v2/check-icon.svg"
                                      className="mr-2"
                                    /> : <></>
                                  ))
                                }
                              </div>
                              
                              <div>
                                {el.RoleName}
                              <div className="smallGreyText text-ellipsis f-darkgrey">{el.RoleDescription}</div>
                              </div>
                              
                            </div>)
                          })}
                            </div>
                            <hr
                              style={{
                              width: "80%",
                              margin: "auto",
                              marginTop: "12px",
                              marginBottom: "8px",
                              height: "2px"
                              }}
                            />
                            <div  className="ml-4 p-2 pl-3 my-1 pointer f-danger"
                              onClick={(e) => {
                                handleDeleteUser(memb.objId_UserSetting);
                                e.target.closest(".hg-white-dropdown").click();
                              }}>
                                Delete User
                            </div>
                        </Dropdown.Menu>
                      </Dropdown>
                      }
                    </td>
                  </tr>
                </div>
                );
              })}
            </div>
            {members.length == 0 && <div className="mt-5 f-grey d-flex justify-content-center align-items-center">No members exist</div>}
          </table>
          :  
          <table className="w-100">
            <div
              className="border-parent"
              style={{
                borderRadius: "8px",
              }}
            >
              {roles.map(list=>{
                return (
                  <>
                  <div key={list.RoleName} className="table-data border-item" >
                  <tr className="grid-container">
                    <td className="d-flex align-items-center">
                      <div className="ml-2">
                        <p className="user-name m-0 ">
                          {list.RoleName}
                        </p>
                        <p className="user-email  m-0 ">
                          {list.RoleDescription}
                        </p>
                      </div>
                    </td>

                    <td className="d-flex align-items-center self-end">
                    {
                        list.IsDefault === 1 && (
                            <div className="">
                              <p className="defaultRole">Pre-Defined</p>
                            </div>
                        )
                    }
                    {
                        list.menu !== null && (
                            <>
                            <Dropdown
                            className="hg-white-dropdown roleMenuDropdown"
                            onToggle={() => {
                              setShowRoleDropdown(true)
                            }}
                          >
                            <Dropdown.Toggle 
                              className='m-0 roleMenuDropdownToggle'
                            >
                              <div className="">
                                  <button className="horisontalDotsButton">
                                      <img alt='' src='/images/settings-v2/horizontal-dots.svg'/>
                                  </button>
                              </div>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                           <div>
                            {list.menu.map((item) => (
                                <div
                                  className={`${item.isEnabled === true || !viewOnlyAccess("AccessState_SystemSettings") && 'pointer'} roleDropdownItem`}
                                  onClick={(e) => {
                                    if (!viewOnlyAccess("AccessState_SystemSettings")) {
                                      if (item.isEnabled === true && mspDetail === null){
                                        if (item.pageEnum === 577) {
                                          duplicateRole(list?.objId_AccessRole);
                                        }
                                        if (item.pageEnum === 578) {
                                          deleteRole(list?.objId_AccessRole);
                                        }
                                        if (item.pageEnum === 552) {
                                          setAccessLevelId(list.objId_AccessRole)
                                          setShowAccessLevelModal(true);
                                        }
                                        setShowRoleDropdown(false);
                                        e.target.closest(".hg-white-dropdown").click();
                                      }
                                    }
                                  }}
                                  >
                                    {
                                      item.isEnabled === false || viewOnlyAccess("AccessState_SystemSettings") && 
                                       <img 
                                       alt='' 
                                       className='lockerImage' 
                                       src='/images/settings-v2/locker.svg'
                                     />
                                    }
                                    <p className={`${(item.isEnabled === false || mspDetail !== null) && 'textGray'}`}>{item.name}</p>
                                </div>
                            ))}
                           </div>
                            </Dropdown.Menu>
                          </Dropdown>
                          </>
                            )
                        }                  
                    </td>
                  </tr>
                </div>
                {
                  (duplicateRoleLoading.isDuplicating === true && duplicateRoleLoading?.roleId === list?.objId_AccessRole) ? (
                        <div key={duplicateRoleLoading.roleId} className="table-data border-item duplicatingRoleBlock">
                        <tr className="">
                        <td className="d-flex align-items-center">
                          <div className="ml-2 roleDuplicateContainer">
                            <div >
                              <img alt='' className="imgLoader" src='/images/settings-v2/loader.svg'/>
                            </div>
                            <div>
                              <p className="user-name m-0 ">
                                {list.RoleName} copy
                              </p>
                              <p className="user-email  m-0 ">
                                Duplicating...
                              </p>
                            </div>
                          </div>
                        </td>
                        </tr>
                </div>
                      ) : <></>
                    }
                </>
                );
              })}
            </div>
        {roles.length == 0 && <div className="mt-5 f-grey d-flex justify-content-center align-items-center">No access roles available</div>}
          </table> }
          {
            (members.length === 20 && pageStatus === 'Members') ? (
              <div className="paginationButtonContainer">
                {
                  pageNumber !== 1 ? (
                    <Button
                      className={`hg-cancel-btn ml-3 mt-2 mb-5`}
                      onClick={() => {
                        setMainPageLoading(true);
                        getMembersDetails(pageNumber -1);
                        setPageNumber(pageNumber - 1);
                      }}
                    >
                      {mainPageLoading ? (
                        <>
                          <GreyLoader width={13} /> Loading..
                        </>
                      ) : (
                        "Show previous"
                      )}
                    </Button> 
                  ) : (
                    <div></div>
                  )
                }
                <Button
                  className={`hg-cancel-btn ml-3 mt-2 mb-5`}
                  onClick={() => {
                    setMainPageLoading(true);
                    getMembersDetails(pageNumber + 1);
                    setPageNumber(pageNumber + 1);
                  }}
                >
                  {mainPageLoading ? (
                    <>
                      <GreyLoader width={13} /> Loading..
                    </>
                  ) : (
                    "Show next"
                  )}
                </Button>
              </div>
            ) : (
              <div>
                {
                  (members.length < 20 && pageStatus === 'Members' && pageNumber !== 1) && (
                    <Button
                      className={`hg-cancel-btn ml-3 mt-2 mb-5`}
                      onClick={() => {
                        setMainPageLoading(true);
                        getMembersDetails(pageNumber -1);
                        setPageNumber(pageNumber - 1);
                      }}
                    >
                      {mainPageLoading ? (
                        <>
                          <GreyLoader width={13} /> Loading..
                        </>
                      ) : (
                        "Show previous"
                      )}
                    </Button> 
                  ) 
                }
              </div>
            )
          }
        </div>
      </div>
        <AddMemberModal
        show={showAddMemberModal}
        hideModal={() => {
          setShowAddMemberModal(false);
        }}
        refreshData={() => {
          setMainPageLoading(true);
          getMembersDetails();
        }}
        />
         <ClientMspAddMemberModal
        show={showAddClientMemberModal}
        hideModal={() => {
          setShowAddClientMemberModal(false);
        }}
        refreshData={() => {
          setMainPageLoading(true);
          getMembersDetails();
        }}
        passedOptionEnum99={42}
      />
         <MemberAccessRoleModal
        accessLevelId={accessLevelId}
        show={showAccessLevelModal}
        hideModal={() => {
          setShowAccessLevelModal(false);
        }}
        parentID="mainBody"
        refreshData={() => {
          setMainPageLoading(true);
          getAccessRolesData();
          getMembersDetails();
        }}
      />
    </div>
  );
};

export default MembersAccess;
