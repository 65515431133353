import { getAccessStateValue } from "../../App/App";
import ImageLoader from "../../Common/ImageLoader";

const ConnectedToolItem = ({ tool }) => {
  return (
    <div
      className="d-flex align-items-center connected-tool"
    >
      <ImageLoader 
        alt=""
        width={16}
        height={16}
        className="rounded-full"
        style={{ marginLeft: '3px' }}
        src={`/images/attack-surface/integrations/${tool?.obj_IntegrationId}.webp`}
        noImageComponent={(
          <img
            alt=""
            width={16}
            height={16}
            className="rounded-full"
            style={{ marginLeft: '3px'}}
            src={ "/images/attack-surface/generic2-dark.svg"}
          />
        )}
      />
      <div className="d-flex align-items-center mx-2 f-12 f-500">
        {tool?.ToolName}
      </div>
      {getAccessStateValue(
        "AccessState_MSP_Security_Stack"
      ) >= 3 && (
        <div className="d-flex align-items-center f-12 f-500  ">
          {/* {!connectedTool?.IsConnected && (
            <div
              className="f-blue mr-2"
              onClick={(e) => {
                e.stopPropagation();
                setSelectedIntegrationId(
                  connectedTool?.obj_IntegrationId
                );
                setShowMspConnectModal(
                  true
                );
              }}
            >
              Connect{" "}
            </div>
          )} */}
          {/* <div
            className="f-darkgrey"
            onClick={
              (e) => {}
              // ToggleAccordion(e, true)
            }
          >
            Change
          </div> */}
        </div>
      )}
    </div>
  );
}

export default ConnectedToolItem;
