import React, { useEffect, useRef, useState, lazy, Suspense } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { Button, Dropdown, OverlayTrigger, Tooltip } from "react-bootstrap";
import "../supplierProfiler/supplier-profiler.scss";
import Assessment from "./Assessments";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom";
import OptionsDropdown from "./ProfilerDropdowns/OptionsDropdown";
import SupplierProfilerNotes from "./Notes/SupplierProfilerNotes";
import SupplierProfilerFiles from "./Files/SupplierProfilerFiles";
import SupplierProfilerSecurityRatings from "./securityRatings/SupplierProfilerSecurityRatings";
import RevokeContributorsDropdown from "./ProfilerDropdowns/RevokeContributorsDropdown";
import ImportSuppliersModal from "./ImportSuppliersModal";
import {
  ContributorGetAPIData,
  ContributorPostData,
  getAPIData,
  postData,
  postRequest,
} from "../../Utils/commonApi";
import { CallToast } from "../PriceCalculator/modals/CustomToast";
import { useAuth } from "../context/Auth";
import ImageWithInitials from "../../Utils/ImageWithInitials";
import { getHTMLfromRawState } from "../ActionCenter/PostEditor/postEditor";
import { viewOnlyAccess } from "../App/App";
import { DeleteModal } from "../DeleteModal/DeleteModal";

const EditSupplierModal = lazy(() =>
  import("../AttackSurface/Suppliers/EditSupplierModal")
);

const menuTabs = ["Assessment", "Security Ratings", "Recon", "Files", "Notes"];
///////test

//test222
//test33
//test4
const editSupplierData = {
  PotentialSupplierName: "Request to M3 Networks ltd.",
  supplierName: "M3 Networks ltd.",
  supplierEmail: "m3@r.com",
  supplierWebsite: "www.f.com",
  supplierServicesProvided: [],
  nameError: "",
  emailError: "",
  serviceError: "",
};
const SupplierProfilerHomeScreen = () => {
  const history = useHistory();
  const { state } = useLocation();
  const { authTokens } = useAuth();

  const [saving, setSaving] = useState(false);
  // Conditional States
  const [showAnswerDetails, setShowAnswerDetails] = useState(false);
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);

  // Example: Get the 'id' parameter
  const id = urlParams.get("id");

  const [questionId, setQuestionId] = useState(id ? id : state?.questionId);
  const backUrl = state?.backUrl;
  const isContributorView = state?.isContributorView ?? false;
  const accessToken = state?.accessToken;
  const accessKey = state?.accessKey;
  const isSent = state?.isSent ?? false;
  // const questionId = id ? id : state?.questionId;
  const isTemplate = state?.isTemplate;
  const isTemplateEdit = state?.isTemplateEdit;
  const viewAccess = viewOnlyAccess("AccessState_SCM");
  // const viewAccess = true;

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [pageStatus, setPageStatus] = useState("Assessment");
  const [profilingBlocks, setProfilingBlocks] = useState([]);
  const [riskCriticalObj, setRiskCriticalObj] = useState({
    likelihood: { name: "", id: "" },
    impact: { name: "", id: "" },
    risk: { name: "", color: "" },
  });
  const [riskRating, setRiskrating] = useState({ name: "", color: "" });
  const [importantSuppliers, setImportantSuppliers] = useState(false);
  const [showEditSupplier, setShowEditSupplier] = useState(false);
  const [loading, setLoading] = useState(false);
  const [compilanceLoading, setCompilanceLoading] = useState(false);
  const [AllProfilingObj, setAllProfilingObjj] = useState({});
  const [rawBlocks, setRawBlocks] = useState([]);
  const [criticalRisk, setCriticalRisk] = useState();
  const [compilanceId, setCompilanceId] = useState();
  const [editTemplateName, setEditTemplateName] = useState(false);
  const [activityTab, setActivityTab] = useState(false);
  const [activityData, setActivityData] = useState(false);
  const [securityRatingsData, setSecurityRatingsData] = useState({});
  const [mentionList, setMentionList] = useState([]);

  const [ContributorSummary, setContributorSummary] = useState({});
  //compilance modal

  // const [questionId, setQuestionId] = useState(4019582); //ObjId_SendFormQuestionnaire

  useEffect(() => {
    setLoading(true);
    if (isContributorView) {
      getPageDataForContributor();
    } else {
      getProfilingTemplatesWithAnswers();
      getRiskRating();
      getActivity();
      // getRawData();
      getAllMentionsList();
    }
  }, []);

  useEffect(() => {
    if (riskCriticalObj.likelihood.id && riskCriticalObj.impact.id) {
      updateRiskRating();
    }
  }, [riskCriticalObj]);

  async function getProfilingTemplatesWithAnswers() {
    setLoading(true);
    if (isContributorView) {
      // await ContributorGetAPIData(754, authTokens, {
      //   optionEnum1: isTemplate ? questionId : 0,
      //   optionEnum2: 0,
      //   optionEnum3: isTemplate ? 0 : questionId,
      // })
      var myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${accessToken}`);

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };
      await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/HGIR/InformationRequestDetail?key=${accessKey}`,
        requestOptions
      )
        .then((response) => response.json())
        .then((response) => {
          if (response?.mr?.Success) {
            setLoading(false);
            setAllProfilingObjj(response);
            setProfilingBlocks(response.AssesmentVm?.QuestionnaireSection_List);
            setSecurityRatingsData(response.SecurityRatings);
            setRawBlocks(response.AssesmentVm?.QuestionnaireSection_List);
            setCompilanceId(response.ComplainceProfileId);
            getCompilance();
            setRiskrating({
              name: response.RiskLevelSelected?.riskLevel?.levelName,
              color: response.RiskLevelSelected?.riskLevel?.levelColour,
            });
            setRiskCriticalObj({
              risk: {
                name: response.RiskLevelSelected?.riskLevel?.levelName,
                color: response.RiskLevelSelected?.riskLevel?.levelColour,
              },
              impact: {
                id: response.RiskMatrixSelected?.RiskColumn?.colOrder,
                name: response.RiskMatrixSelected?.RiskColumn?.colName,
              },
              likelihood: {
                id: response.RiskMatrixSelected?.RiskRow?.rowOrder,
                name: response.RiskMatrixSelected?.RiskRow?.rowName,
              },
            });
            // getProfilingContributors();
          } else {
            CallToast("Something went wrong", false, false, "v2style");
            setLoading(false);
          }
        });
    } else {
      await getAPIData(754, authTokens, {
        optionEnum1: isTemplate ? questionId : 0,
        optionEnum2: 0,
        optionEnum3: isTemplate ? 0 : questionId,
      }).then((response) => {
        if (response?.mr?.Success) {
          setLoading(false);
          // console.log(response);
          setAllProfilingObjj(response);
          setProfilingBlocks(response.AssesmentVm?.QuestionnaireSection_List);
          setSecurityRatingsData(response.SecurityRatings);
          setRawBlocks(response.AssesmentVm?.QuestionnaireSection_List);
          setCompilanceId(response.ComplainceProfileId);
          getCompilance();
          setRiskrating({
            name: response.RiskLevelSelected?.riskLevel?.levelName,
            color: response.RiskLevelSelected?.riskLevel?.levelColour,
          });
          setRiskCriticalObj({
            risk: {
              name: response.RiskLevelSelected?.riskLevel?.levelName,
              color: response.RiskLevelSelected?.riskLevel?.levelColour,
            },
            impact: {
              id: response.RiskMatrixSelected?.RiskColumn?.colOrder,
              name: response.RiskMatrixSelected?.RiskColumn?.colName,
            },
            likelihood: {
              id: response.RiskMatrixSelected?.RiskRow?.rowOrder,
              name: response.RiskMatrixSelected?.RiskRow?.rowName,
            },
          });
          // getProfilingContributors();

          // let temp = [];
          // let newObj = response.AssesmentVm.QuestionnaireSection_List.map(b => {
          //   temp.push({ ObjectId: b.ObjectId, show: false });
          // });
          // setShowBlocks(temp)
        } else {
          CallToast("Something went wrong", false, false, "v2style");
          setLoading(false);
        }
      });
    }
  }
  async function getProfilingContributors() {
    setSaving(true);
    if (isContributorView) {
      await ContributorGetAPIData(779, authTokens, {
        optionEnum1: questionId,
      }).then((response) => {
        if (response) {
          setSaving(false);
          setAllProfilingObjj((prevState) => ({
            ...prevState,
            ActivityTrackingContributorVM: {
              ...prevState.ActivityTrackingContributorVM,
              ActivityTrackingContributor_List:
                response.ActivityTrackingContributor_List,
            },
          }));
        } else {
          CallToast("Something went wrong", false, false, "v2style");
          setSaving(false);
        }
      });
    } else {
      await getAPIData(779, authTokens, {
        optionEnum1: questionId,
      }).then((response) => {
        if (response) {
          setSaving(false);
          setAllProfilingObjj((prevState) => ({
            ...prevState,
            ActivityTrackingContributorVM: {
              ...prevState.ActivityTrackingContributorVM,
              ActivityTrackingContributor_List:
                response.ActivityTrackingContributor_List,
            },
          }));
          console.log("contrib profiling check", AllProfilingObj);
        } else {
          CallToast("Something went wrong", false, false, "v2style");
          setSaving(false);
        }
      });
    }
  }
  async function getPageDataForContributor() {
    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${accessToken}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    await fetch(
      `${process.env.REACT_APP_API_BASE_URL}/HGIR/InformationRequestDetail?key=${accessKey}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((response) => {
        console.log("dddd", response);
        if (response?.mr?.Success) {
          setLoading(false);
          // console.log(response);
          setQuestionId(response.ObjId_SendQuestionaire);
          setAllProfilingObjj(response);
          setProfilingBlocks(response.AssesmentVm?.QuestionnaireSection_List);
          setSecurityRatingsData(response.SecurityRatings);
          setRawBlocks(response.AssesmentVm?.QuestionnaireSection_List);
          setCompilanceId(response.ComplainceProfileId);
          setContributorSummary(response.ContributorSummary);

          setRiskrating({
            name: response.RiskLevelSelected?.riskLevel?.levelName,
            color: response.RiskLevelSelected?.riskLevel?.levelColour,
          });
          setRiskCriticalObj({
            risk: {
              name: response.RiskLevelSelected?.riskLevel?.levelName,
              color: response.RiskLevelSelected?.riskLevel?.levelColour,
            },
            impact: {
              id: response.RiskMatrixSelected?.RiskColumn?.colOrder,
              name: response.RiskMatrixSelected?.RiskColumn?.colName,
            },
            likelihood: {
              id: response.RiskMatrixSelected?.RiskRow?.rowOrder,
              name: response.RiskMatrixSelected?.RiskRow?.rowName,
            },
          });
          // getCompilance();
          // getRiskRating();
          getActivity();
          getAllMentionsList(response.ObjId_SendQuestionaire);
          // getProfilingContributors();
        } else {
          CallToast("Something went wrong", false, false, "v2style");
          setLoading(false);
        }
      })
      .catch((ex) => {});
  }
  async function getRawData() {
    let tempCheck = {};
    // setSaving(true);
    if (isContributorView) {
      // await ContributorGetAPIData(754, authTokens, {
      //   optionEnum1: isTemplate ? questionId : 0,
      //   optionEnum2: 0,
      //   optionEnum3: isTemplate ? 0 : questionId,
      // })
      var myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${accessToken}`);

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };
      await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/HGIR/InformationRequestDetail?key=${accessKey}`,
        requestOptions
      )
        .then((response) => response.json())
        .then((response) => {
          if (response?.mr?.Success) {
            setRawBlocks(response.AssesmentVm?.QuestionnaireSection_List);
            tempCheck = response.AssesmentVm?.QuestionnaireSection_List;
            return tempCheck;
          } else {
            CallToast("Something went wrong", false, false, "v2style");
          }
        });
    } else {
      await getAPIData(754, authTokens, {
        optionEnum1: isTemplate ? questionId : 0,
        optionEnum2: 0,
        optionEnum3: isTemplate ? 0 : questionId,
      }).then((response) => {
        if (response?.mr?.Success) {
          setRawBlocks(response.AssesmentVm?.QuestionnaireSection_List);
          tempCheck = response.AssesmentVm?.QuestionnaireSection_List;
          return tempCheck;
        } else {
          CallToast("Something went wrong", false, false, "v2style");
        }
      });
    }
    setSaving(false);
    console.log("check----------------------", rawBlocks);
    return tempCheck;
  }

  async function sendReminder(questionnaireId, supplierId) {
    getAPIData(820, authTokens, {
      optionEnum1: questionnaireId,
      optionEnum2: supplierId,
    })
      .then((response) => {
        if (response?.Success) {
          CallToast("Reminder sent successfully", false, false, "v2style");
        } else {
          CallToast("Something went wrong", false, false, "v2style");
        }
      })
      .catch((err) => {
        console.log(err);
        CallToast("Something went wrong", false, false, "v2style");
      });
  }
  async function ArchiveOrDeleteQuestions(objectId) {
    await getAPIData(`${772}`, authTokens, {
      optionEnum1: objectId,
      optionEnum2: 0,
    })
      .then((response) => {
        if (response?.Success) {
          CallToast("Archived Successfully", false, false, "v2style");
          if (!isContributorView && backUrl) {
            history.push(backUrl, { isSent: isSent });
          }
        }
      })
      .catch((err) => {
        console.log(err);
        CallToast("Something went wrong", false, false, "v2style");
      });
  }

  async function getRiskRating() {
    if (isContributorView) {
      await ContributorGetAPIData(
        717,
        authTokens
        //   , {
        //   optionEnum1: 0,
        //   optionEnum2: 0,
        //   optionEnum3: questionId,
        // }
      ).then((response) => {
        if (response) {
          setCriticalRisk(response);
        } else {
          CallToast("Something went wrong", false, false, "v2style");
        }
      });
    } else {
      await getAPIData(
        717,
        authTokens
        //   , {
        //   optionEnum1: 0,
        //   optionEnum2: 0,
        //   optionEnum3: questionId,
        // }
      ).then((response) => {
        if (response) {
          setCriticalRisk(response);
        } else {
          CallToast("Something went wrong", false, false, "v2style");
        }
      });
    }
  }

  async function getAllMentionsList(id) {
    let queryString = window.location.search;
    let parameters = new URLSearchParams(queryString);
    let sentReceivedQuestionnaireId = parameters.get(
      "sentReceivedQuestionnaireId"
    );
    if (isContributorView) {
      await ContributorGetAPIData("335", authTokens, {
        optionEnum1: id,
        optionEnum2: 1,
      })
        .then((response) => {
          // if (response?.mr?.Success) {
          let mentions = response?.UserList?.map(
            ({ OwnerName: name, ...rest }) => ({
              name,
              ...rest,
            })
          );
          setMentionList(mentions ?? []);
          // }
        })
        .catch(() => {});
    } else {
      await getAPIData("335", authTokens, {
        optionEnum1: sentReceivedQuestionnaireId ?? questionId,
        optionEnum2: 1,
      })
        .then((response) => {
          // if (response?.mr?.Success) {
          let mentions = response?.UserList?.map(
            ({ OwnerName: name, ...rest }) => ({
              name,
              ...rest,
            })
          );
          setMentionList(mentions ?? []);
          // }
        })
        .catch(() => {});
    }
  }

  async function updateRiskRating() {
    setSaving(true);
    if (isContributorView) {
      await ContributorGetAPIData(719, authTokens, {
        optionEnum1: riskCriticalObj.likelihood.id,
        optionEnum2: riskCriticalObj.impact.id,
      }).then((response) => {
        if (response) {
          setRiskrating({
            name: response.riskLevel.levelName,
            color: response.riskLevel.levelColour,
          });
        } else {
          CallToast("Something went wrong", false, false, "v2style");
        }
        setSaving(false);
      });
      await ContributorGetAPIData(718, authTokens, {
        optionEnum1: questionId,
        optionEnum2: riskCriticalObj.likelihood.id,
        optionEnum3: riskCriticalObj.impact.id,
      }).then((response) => {
        if (response) {
        } else {
          CallToast("Something went wrong", false, false, "v2style");
        }
        setSaving(false);
      });
    } else {
      await getAPIData(719, authTokens, {
        optionEnum1: riskCriticalObj.likelihood.id,
        optionEnum2: riskCriticalObj.impact.id,
      }).then((response) => {
        if (response) {
          setRiskrating({
            name: response.riskLevel.levelName,
            color: response.riskLevel.levelColour,
          });
        } else {
          CallToast("Something went wrong", false, false, "v2style");
        }
        setSaving(false);
      });
      await getAPIData(718, authTokens, {
        optionEnum1: questionId,
        optionEnum2: riskCriticalObj.likelihood.id,
        optionEnum3: riskCriticalObj.impact.id,
      }).then((response) => {
        if (response) {
        } else {
          CallToast("Something went wrong", false, false, "v2style");
        }
        setSaving(false);
      });
    }
  }

  async function updateProfilingStatus(id) {
    setSaving(true);
    if (isContributorView) {
      await ContributorGetAPIData(763, authTokens, {
        optionEnum1: questionId,
        optionEnum2: id,
      }).then((response) => {
        if (response?.Success) {
          CallToast(
            "Profiling Status updated successfully",
            false,
            false,
            "v2style"
          );
          setLoading(false);
          // getProfilingTemplatesWithAnswers();
        } else {
          CallToast("Something went wrong", false, false, "v2style");
        }
        setSaving(false);
      });
    } else {
      await getAPIData(763, authTokens, {
        optionEnum1: questionId,
        optionEnum2: id,
      }).then((response) => {
        if (response?.Success) {
          CallToast(
            "Profiling Status updated successfully",
            false,
            false,
            "v2style"
          );
          setLoading(false);
          // getProfilingTemplatesWithAnswers();
        } else {
          CallToast("Something went wrong", false, false, "v2style");
        }
        setSaving(false);
      });
    }
  }
  async function getCompilance() {
    setCompilanceLoading(true);
    if (isContributorView) {
      await ContributorGetAPIData(306, authTokens, {
        optionEnum1: compilanceId ?? 0,
      }).then((response) => {
        setCompilanceLoading(false);
      });
    } else {
      await getAPIData(306, authTokens, {
        optionEnum1: compilanceId ?? 0,
      }).then((response) => {
        if (response?.mr?.Success) {
          setCompilanceLoading(false);
        } else {
          CallToast("Something went wrong", false, false, "v2style");
        }
      });
    }
  }
  async function getActivity() {
    if (isContributorView) {
      await ContributorGetAPIData(771, authTokens, {
        optionEnum1: questionId ?? 0,
      }).then((response) => {
        console.log("activity data", response);
        setActivityData(response.activityTracking);
      });
    } else {
      await getAPIData(771, authTokens, {
        optionEnum1: questionId ?? 0,
      }).then((response) => {
        if (response?.mr?.Success) {
          console.log("activity data", response);
          setActivityData(response.activityTracking);
        } else {
          CallToast("Something went wrong", false, false, "v2style");
        }
      });
    }
  }
  async function renameProfilingName(newName) {
    setSaving(true);
    if (isContributorView) {
      await ContributorPostData(authTokens, "RenameSupplierProfilingName", {
        ObjectId: AllProfilingObj.ObjId_SupplierProfile,
        SupplierProfilingName: newName,
      })
        .then((response) => {
          setSaving(false);
          console.log(response);
          if (response?.Success) {
            CallToast(
              "Profiling updated successfully",
              false,
              false,
              "v2style"
            );
            // getProfilingTemplatesWithAnswers();
          } else {
            CallToast("Something went wrong", false, false, "v2style");
          }
        })
        .catch(() => {
          setSaving(false);
          CallToast("Something went wrong", false, false, "v2style");
        });
    } else {
      await postData(authTokens, "RenameSupplierProfilingName", {
        ObjectId: AllProfilingObj.ObjId_SupplierProfile,
        SupplierProfilingName: newName,
      })
        .then((response) => {
          setSaving(false);
          console.log(response);
          if (response?.Success) {
            CallToast(
              "Profiling updated successfully",
              false,
              false,
              "v2style"
            );
            // getProfilingTemplatesWithAnswers();
          } else {
            CallToast("Something went wrong", false, false, "v2style");
          }
        })
        .catch(() => {
          setSaving(false);
          CallToast("Something went wrong", false, false, "v2style");
        });
    }
  }

  async function duplicateTemplate(template, modeTemplate) {
    // setLoading(true);
    await getAPIData(757, authTokens, {
      optionEnum1: AllProfilingObj?.ObjId_SupplierProfile,
      optionEnum2: 2,
    }).then(async (response) => {
      if (response?.Success) {
        // setLoading(false);
        CallToast("New template is created", false, false, "v2style");

        console.log(response);
      } else {
        CallToast("Something went wrong", false, false, "v2style");
        // setLoading(false);
      }
    });
  }
  function refreshData() {
    getProfilingTemplatesWithAnswers();
    getActivity();
  }

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  function onDragEndShown(result) {
    if (!result.destination) {
      return;
    }
    // console.log(result.source.index);
    const items = reorder(
      profilingBlocks,
      result.source.index,
      result.destination.index
    );
    setProfilingBlocks(
      items.map((item, index) => {
        return {
          ...item,
          SortOrder: index + 1,
        };
      })
    );
  }
  const renderTooltip = (message) => (
    <Tooltip className="custom_tooltip custom_tooltip_risk" id="tooltip">
      <div className="custom_tooltip_wrapper">
        <div
          className="tooltip_header border-bottom-0"
          style={{ padding: "2px" }}
        >
          <span
            className="tooltip_subtitle"
            style={{ color: "#FFF", padding: "2px" }}
          >
            {message}
          </span>
        </div>
      </div>
    </Tooltip>
  );

  return (
    <div className="supplier-profiler">
      <div
        className="w-100 position-sticky top-0 d-flex align-items-center justify-content-between border-bottom p-2 px-4 bg-white"
        style={{ zIndex: "1002" }}
      >
        <div className="d-flex align-items-center f-500 f-black bg-white w-80">
          <div
            className="pointer"
            onClick={() => {
              if (!isContributorView && backUrl) {
                history.push(backUrl, { isSent: isSent });
              }
            }}
          >
            {isContributorView ? (
              <img
                src="/images/logo/highground/short.svg"
                alt=""
                className="mr-3"
                width={25}
              />
            ) : (
              <img
                src="/images/attack-surface/cross-icon.svg"
                alt=""
                width={12}
                className="mr-2 pointer"
              />
            )}
          </div>
          {editTemplateName ? (
            <div className="f-grey d-flex w-100">
              <input
                type="text"
                className={`edit-task edit-title w-100 d-flex`}
                value={AllProfilingObj.ProfilingName}
                placeholder={"Question"}
                onBlur={(e) => {
                  let tempTitle = e.target.value;
                  setAllProfilingObjj({
                    ...AllProfilingObj,
                    ProfilingName: e.target.value,
                  });
                  renameProfilingName(e.target.value);

                  // let newField = block.Question_List?.map((item) => {
                  //   if (item === row) {
                  //     item.QuestionText = e.target.value;
                  //     item.QuestionStatusText = "Answered";
                  //     item.QuestionStatus = 3;
                  //     setData_to_send([item]);
                  //     setSaveTemplateStatus(true);
                  //     createUpdateSection(block);
                  //   }
                  //   return item;
                  // });
                  setEditTemplateName(false);
                }}
                onChange={(e) => {
                  let tempTitle = e.target.value;
                  setAllProfilingObjj({
                    ...AllProfilingObj,
                    ProfilingName: e.target.value,
                  });
                }}
              />
            </div>
          ) : (
            // <div className={`f-grey`}>{row.QuestionText}</div>
            <div
              onClick={() => {
                setEditTemplateName(true);
              }}
            >
              {AllProfilingObj.ProfilingName}
            </div>
          )}
          {/* Supplier Profiling */}
        </div>
        <div className="d-flex align-items-center">
          {saving ? (
            <div className="d-flex mr-4">
              <img
                src="/images/supply-chain/saving-icon.svg"
                alt=""
                className="mr-2"
              />
              <div className="f-grey">Saving...</div>
            </div>
          ) : (
            <div className="d-flex mr-4">
              <img
                src="/images/supply-chain/saved-icon.svg"
                alt=""
                className="mr-2"
              />
              <div className="f-grey">Saved</div>
            </div>
          )}

          <div className="secops_training_certificate_overlap_parent mr-3">
            {AllProfilingObj.ActivityTrackingContributorVM
              ?.ActivityTrackingContributor_List?.length > 0 && (
              <RevokeContributorsDropdown
                revokers={
                  AllProfilingObj.ActivityTrackingContributorVM
                    ?.ActivityTrackingContributor_List
                }
                countFlag={false}
                questionId={0}
                questionnaireId={questionId}
                refreshData={refreshData}
              />
            )}
          </div>
          {/* {!isContributorView && ( */}
          {(!isTemplate || isTemplate === undefined) && (
            <div
              className="d-flex align-items-center f-grey f-500 pointer mr-3"
              onClick={() => {
                setActivityTab(true);
                getActivity();
              }}
            >
              <img src="/images/msp/dummy-clock.svg" alt="" className="mr-2" />
              Activity
            </div>
          )}
          {(!isTemplate || isTemplate === undefined) && (
            <div className="secops_training_certificate_overlap_parent f-grey">
              {!isContributorView && (
                <OverlayTrigger
                  placement="bottom"
                  trigger={
                    viewOnlyAccess("AccessState_SCM") ? ["hover", "focus"] : []
                  }
                  delay={{
                    show: 200,
                    hide: 250,
                  }}
                  overlay={
                    <Tooltip
                      id={`tooltip-1365`}
                      className="custom_tooltip_access_assessmentmenu"
                    >
                      This feature is managed by your service provider
                    </Tooltip>
                  }
                >
                  <div>
                    <div
                      style={{
                        pointerEvents: viewOnlyAccess("AccessState_SCM")
                          ? "none"
                          : "all",
                        margin: "0 -17px",
                      }}
                    >
                      <Dropdown
                        className="hg-white-dropdown user-detail-dropdown supplier-menu"
                        onToggle={() => {}}
                      >
                        <Dropdown.Toggle className=" d-flex custom-dropdown-toggle f-gray-imp">
                          <img
                            src={"/images/supply-chain/base-more-dots.svg"}
                            className="mr-2 ml-2"
                            alt=""
                          />
                        </Dropdown.Toggle>
                        <Dropdown.Menu style={{ width: "200px" }}>
                          <div
                            className="p-2 pl-3 my-1 pointer f-darkgrey add-quest-btn"
                            onClick={(e) => {
                              duplicateTemplate();
                              e.target.closest(".hg-white-dropdown").click();
                            }}
                          >
                            <div>Save as Template</div>
                          </div>
                          <div
                            className="p-2 pl-3 my-1 pointer f-darkgrey"
                            onClick={(e) => {
                              // setRenameTitle(block.Title);
                              // setShowAccessLevelModal(true);
                              sendReminder(
                                questionId,
                                AllProfilingObj?.SupplierInfo?.objId_Customer
                              );
                              e.target.closest(".hg-white-dropdown").click();
                            }}
                          >
                            <div>Send reminder</div>
                          </div>

                          <div
                            className="p-2 pl-3 my-1 pointer f-danger"
                            onClick={(e) => {
                              setShowDeleteModal(true);
                              e.target.closest(".hg-white-dropdown").click();
                            }}
                          >
                            Delete
                          </div>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </div>
                </OverlayTrigger>
              )}
            </div>
          )}

          {/* <Button
            className="hg-blue-btn"
            onClick={() => {
              saveTemplate();
            }}
          >
            Save
          </Button> */}
        </div>
      </div>

      <div className="d-flex align-items-center justify-content-between ">
        <div className="w-100 main-block">
          {!isContributorView && (
            <div className="btn-block d-flex flex-row">
              {menuTabs.map((tab) => {
                if (tab === pageStatus) {
                  return (
                    <Button
                      key={tab}
                      className="btn-blue "
                      onClick={() => {
                        setPageStatus(tab);
                      }}
                    >
                      {pageStatus}
                    </Button>
                  );
                } else {
                  return (
                    <Button
                      key={tab}
                      className="hg-cancel-btn"
                      onClick={() => {
                        setPageStatus(tab);
                        setActivityTab(false);
                      }}
                    >
                      {tab}
                    </Button>
                  );
                }
              })}
            </div>
          )}
          {isContributorView && (
            <div className="btn-block d-flex flex-row">
              {menuTabs.slice(0, 2).map((tab) => {
                if (tab === pageStatus) {
                  return (
                    <Button
                      key={tab}
                      className="btn-blue "
                      onClick={() => {
                        setPageStatus(tab);
                        // setActivityTab(false);
                      }}
                    >
                      {pageStatus}
                    </Button>
                  );
                } else {
                  return (
                    <Button
                      key={tab}
                      className="hg-cancel-btn"
                      onClick={() => {
                        setPageStatus(tab);
                        setActivityTab(false);
                      }}
                    >
                      {tab}
                    </Button>
                  );
                }
              })}
            </div>
          )}

          {pageStatus === "Assessment" && (
            <div className="w-80" style={{ alignSelf: "flex-start" }}>
              <Assessment
                blockItems={profilingBlocks}
                setProfilingBlocks={setProfilingBlocks}
                refreshData={refreshData}
                loading={loading}
                questionId={questionId}
                setSaving={setSaving}
                AllProfilingObj={AllProfilingObj}
                isTemplate={isTemplate}
                viewAccess={viewAccess}
                isTemplateEdit={isTemplateEdit}
                rawBlocks={rawBlocks}
                updateRawData={() => getRawData()}
                isContributorView={isContributorView}
                isSent={isSent}
                mentionList={mentionList}
              />
            </div>
          )}

          <div className="w-100">
            {pageStatus === "Notes" && (
              <SupplierProfilerNotes
                questionId={questionId}
                isTemplate={isTemplate}
                viewAccess={viewAccess}
                isContributorView={isContributorView}
                isSent={isSent}
              />
            )}
            {pageStatus === "Files" && (
              <SupplierProfilerFiles
                questionId={questionId}
                isTemplate={isTemplate}
                viewAccess={viewAccess}
                isContributorView={isContributorView}
                isSent={isSent}
              />
            )}
            {pageStatus === "Recon" && (
              <div className="w-100">
                <div className="w-100 d-flex justify-content-center">
                  <img
                    src="/images/msp/recon-welcome-pic.svg"
                    alt=""
                    className="mt-4"
                  />
                </div>
              </div>
            )}
            {pageStatus === "Security Ratings" && (
              <SupplierProfilerSecurityRatings
                updateRawData={() => getRawData()}
                questionId={questionId}
                isTemplate={isTemplate}
                isTemplateEdit={isTemplateEdit}
                viewAccess={viewAccess}
                securityRatingsData={securityRatingsData}
                isContributorView={isContributorView}
                isSent={isSent}
                refreshData={refreshData}
                setSaving={setSaving}
                mentionList={mentionList}
              />
            )}
          </div>
        </div>

        {pageStatus === "Assessment" ? (
          <div
            className="border-left hide-scrollbar  bg-white w-20 right-0 p-2 position-fixed"
            style={{
              overflowY: "scroll",
              overflowX: "scroll",
              height: "96%",
              top: "48px",
              position: "relative",
            }}
          >
            {(!isTemplate || isTemplate === undefined) && !activityTab && (
              <>
                {isContributorView ? (
                  <div>
                    <div className="d-flex align-items-center f-16 f-600">
                      <img
                        src={AllProfilingObj?.SupplierInfo?.SupplierLogo}
                        alt=""
                        className="mr-2"
                      />
                      {AllProfilingObj?.SupplierInfo?.SupplierName}
                    </div>

                    <div className="d-flex align-items-center mt-2 mb-4">
                      <div className="flex align-items-center hg-progress-wrapper w-100 ">
                        <progress min="0" value={50} max="100" />
                      </div>
                      <div className="f-500 f-darkgrey ml-2">{50}%</div>
                    </div>

                    <div className="f-darkgrey f-500 mb-2">Details</div>

                    <div className="d-flex align-items-center w-100 justify-content-between mb-2">
                      <div className="w-50 f-grey">Client</div>
                      <div className="w-50 f-black">
                        {" "}
                        <div className="d-flex align-items-center  f-14 f-black">
                          {ContributorSummary?.client?.showInitials ? (
                            <ImageWithInitials
                              initials={ContributorSummary?.client?.initials}
                              width={16}
                              background={"purple"}
                            />
                          ) : (
                            <img
                              src={ContributorSummary?.client?.avatar}
                              alt=""
                              className="mr-2 rounded-full"
                              width={16}
                              height={16}
                            />
                          )}
                          {ContributorSummary?.client?.fullName}
                        </div>
                      </div>
                    </div>
                    {/* Profiling Status */}
                    <div className="d-flex align-items-center w-100 justify-content-between mb-2">
                      <div className="w-50  f-grey">Asked by</div>
                      <div className="w-50">
                        {" "}
                        <div className="d-flex align-items-center f-14 f-black">
                          {ContributorSummary?.askedBy?.showInitials ? (
                            <ImageWithInitials
                              initials={ContributorSummary?.askedBy?.initials}
                              width={16}
                              background={"purple"}
                            />
                          ) : (
                            <img
                              src={ContributorSummary?.askedBy?.avatar}
                              alt=""
                              className="mr-2 rounded-full"
                              width={16}
                              height={16}
                            />
                          )}

                          {ContributorSummary?.askedBy?.fullName}
                        </div>
                      </div>
                    </div>

                    {/* Complinace Supplier */}

                    <div className="d-flex align-items-center w-100 justify-content-between mb-2">
                      <div className="w-50  f-grey">Due Date</div>
                      <div className="w-50">{ContributorSummary?.dueDate}</div>
                    </div>
                  </div>
                ) : activityTab ? (
                  <>
                    <div
                      className="d-flex align-items-center f-grey f-500 pointer mr-3 p-2 border-bottom justify-content-between"
                      style={{
                        position: "fixed",
                        zIndex: 2,
                        background: "#fff",
                        width: "18%",
                        top: "45px",
                      }}
                      onClick={() => {
                        // setActivityTab(true);
                      }}
                    >
                      <div>
                        <img
                          src="/images/msp/dummy-clock.svg"
                          alt=""
                          className="mr-2"
                        />
                        Activity
                      </div>

                      <span
                        className="cursor-pointer"
                        onClick={() => {
                          setActivityTab(false);
                        }}
                      >
                        <img
                          src="/images/attack-surface/cross-icon.svg"
                          alt=""
                        />
                      </span>
                    </div>
                  </>
                ) : (
                  <div className="bg-grey radius-4 p-2">
                    <div className="d-flex align-items-center justify-content-between mb-2">
                      <div className="d-flex align-items-center f-16 f-600">
                        <img
                          src={AllProfilingObj?.SupplierInfo?.SupplierLogo}
                          alt=""
                          className="mr-2"
                        />
                        {AllProfilingObj?.SupplierInfo?.SupplierName}
                      </div>
                    </div>
                    {/* Status */}
                    <div className="d-flex align-items-center w-100 mb-2">
                      <div
                        className="f-grey"
                        style={{ width: "104px", marginRight: "8px" }}
                      >
                        Supplier Status
                      </div>
                      <div className="f-black">
                        {AllProfilingObj.SupplierInfo?.RequestStatusText}
                      </div>
                    </div>
                    {/* Profiling Status */}

                    <div className="d-flex align-items-center w-100 mb-2">
                      <div
                        className="f-grey text-ellipsis"
                        style={{ width: "104px", marginRight: "8px" }}
                      >
                        Profiling Status
                      </div>
                      {viewAccess ? (
                        <div className=" f-black supplier-dropdown-custom">
                          {AllProfilingObj.ProfilingStatus_Text}
                        </div>
                      ) : (
                        <div className=" f-black supplier-dropdown-custom">
                          {AllProfilingObj.ProfilingStatus_List && (
                            <OptionsDropdown
                              fieldId={"DDLId"}
                              fieldValue={"DDLText"}
                              selected={AllProfilingObj.ProfilingStatus_Text}
                              placeholder="Choose..."
                              onClick={(id, val) => {
                                let temp = AllProfilingObj;
                                temp.ProfilingStatus_Text = val;
                                updateProfilingStatus(id);
                                // setAllProfilingObjj(temp);
                              }}
                              data={AllProfilingObj.ProfilingStatus_List}
                            />
                          )}
                        </div>
                      )}
                    </div>
                    <div className="d-flex align-items-center w-100 mb-2">
                      <div
                        className="f-grey  text-ellipsis"
                        style={{ width: "104px", marginRight: "8px" }}
                      >
                        Compliance profile
                      </div>
                      {viewAccess ? (
                        <div
                          className="selected-option"
                          style={{ marginRight: "0.3rem" }}
                        >
                          {AllProfilingObj.ComplainceProfile_Text}
                        </div>
                      ) : (
                        <>
                          {AllProfilingObj.SupplierInfo?.RequestStatusText ===
                            "Managed" && (
                            <div
                              className="f-black d-flex justify-content-between"
                              style={{ marginRight: "8px" }}
                              onClick={() => {
                                setImportantSuppliers(true);
                              }}
                            >
                              <div
                                className="selected-option"
                                style={{ marginRight: "0.3rem" }}
                              >
                                {AllProfilingObj.ComplainceProfile_Text}
                              </div>
                              <img
                                alt=""
                                className=""
                                src="/images/big-chevron-down.svg"
                              />
                            </div>
                          )}
                        </>
                      )}
                    </div>
                    <div className="d-flex align-items-center w-100 justify-content-between mb-2">
                      <div className="w-50 f-darkgrey f-12 ">
                        Supplier Risk Rating
                      </div>
                    </div>

                    {/* Complinace Supplier */}
                    {viewOnlyAccess("AccessState_SCM") ? (
                      <>
                        <OverlayTrigger
                          placement="bottom"
                          trigger={
                            viewOnlyAccess("AccessState_SCM")
                              ? ["hover", "focus"]
                              : []
                          }
                          delay={{
                            show: 200,
                            hide: 250,
                          }}
                          overlay={
                            <Tooltip
                              id={`tooltip-1365`}
                              className="custom_tooltip_access"
                            >
                              This feature is managed by your service provider
                            </Tooltip>
                          }
                        >
                          <div className="d-flex align-items-center w-100 justify-content-between mb-2">
                            <div
                              className={`w-100 bg-darkgrey d-flex align-items-center justify-content-center risk-drop`}
                              style={
                                riskRating.color
                                  ? { backgroundColor: riskRating.color }
                                  : { backgroundColor: "#EBEDF1" }
                              }
                            >
                              <div
                                className={`hg-white-dropdown user-detail-dropdown risk-drop `}
                              >
                                <div
                                  className="d-flex custom-dropdown-toggle f-gray-imp"
                                  style={{ padding: 0, alignItems: "center" }}
                                >
                                  <img
                                    alt=""
                                    src="/images/attack-surface/small-lock.svg"
                                    className="ml-2 mr-2"
                                  />
                                  {riskRating && riskRating.name ? (
                                    <div
                                      className="w-100 f-grey"
                                      style={{
                                        backgroundColor: riskRating.color,
                                        color: "#fff",
                                      }}
                                    >
                                      {riskRating?.name}
                                    </div>
                                  ) : (
                                    <div className="w-100 f-grey  ">
                                      Supplier Risk Rating
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </OverlayTrigger>
                      </>
                    ) : (
                      <>
                        <div className="d-flex align-items-center w-100 justify-content-between mb-2">
                          <div
                            className={`w-100 bg-darkgrey d-flex align-items-center justify-content-center risk-drop`}
                            style={
                              riskRating.color
                                ? { backgroundColor: riskRating.color }
                                : { backgroundColor: "#EBEDF1" }
                            }
                          >
                            <Dropdown
                              className={`hg-white-dropdown user-detail-dropdown risk-drop
                       ${riskRating.name ? "white-arr" : "grey-arr"}
                      `}
                              // style={{    inset: "auto -24px auto auto !important"}}
                              onToggle={() => {}}
                            >
                              <Dropdown.Toggle
                                className="d-flex custom-dropdown-toggle f-gray-imp"
                                style={{ padding: 0, alignItems: "center" }}
                              >
                                {riskRating && riskRating.name ? (
                                  <div
                                    className="w-100 f-grey"
                                    style={{
                                      backgroundColor: riskRating.color,
                                      color: "#fff",
                                    }}
                                  >
                                    {riskRating?.name}
                                  </div>
                                ) : (
                                  <div className="w-100 f-grey  ">
                                    Supplier Risk Rating
                                  </div>
                                )}
                                {/* <div className="w-100 f-grey  ">Supplier Risk Rating</div> */}
                              </Dropdown.Toggle>
                              <Dropdown.Menu
                                className="menu"
                                style={{ width: " 272px" }}
                              >
                                <div className="d-flex flex-column w-100">
                                  <div
                                    className="d-flex flex-row"
                                    style={{
                                      padding: "4px 12px",
                                      alignItems: "center",
                                      gap: "8px",
                                    }}
                                  >
                                    <div className="f-darkgrey f-12 p-2 w-50">
                                      Likelihood
                                    </div>
                                    <div
                                      className="f-darkgrey f-12 p-2 w-50 d-flex"
                                      style={{ justifyContent: "flex-end" }}
                                    >
                                      <Dropdown
                                        className="hg-white-dropdown user-detail-dropdown grey-arr"
                                        onToggle={() => {}}
                                      >
                                        <Dropdown.Toggle
                                          className="custom-dropdown-toggle sidetoggle-dropdown-custom f-gray-imp d-flex "
                                          style={{
                                            padding: "0",
                                            alignItems: "center",
                                          }}
                                        >
                                          {riskCriticalObj &&
                                          riskCriticalObj.likelihood.name ? (
                                            <div>
                                              {riskCriticalObj.likelihood?.name}
                                            </div>
                                          ) : (
                                            <div>Select</div>
                                          )}
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu
                                          className="submenu"
                                          style={{ width: " 272px" }}
                                        >
                                          <div className="f-darkgrey f-12 p-2 w-50">
                                            Choose Likelihood
                                          </div>

                                          {criticalRisk?.riskRows?.map(
                                            (el, i) => {
                                              return (
                                                <OverlayTrigger
                                                  key={i + el?.levelToolTip}
                                                  placement="left"
                                                  delay={{
                                                    show: 200,
                                                    hide: 250,
                                                  }}
                                                  overlay={renderTooltip(
                                                    el?.levelToolTip
                                                  )}
                                                >
                                                  <div
                                                    className="p-2 pl-3 my-1 pointer f-grey risk-item"
                                                    style={{
                                                      display: "grid",
                                                      "grid-template-columns":
                                                        "10% 90%",
                                                    }}
                                                    onClick={(e) => {
                                                      setRiskCriticalObj(
                                                        (prevObj) => {
                                                          const newObj = {
                                                            ...prevObj,
                                                          };
                                                          newObj.likelihood = {
                                                            name: el.rowName,
                                                            id: el.rowOrder,
                                                          };

                                                          return newObj;
                                                        }
                                                      );
                                                      e.target
                                                        .closest(
                                                          ".hg-white-dropdown"
                                                        )
                                                        .click();
                                                    }}
                                                  >
                                                    <div>
                                                      {riskCriticalObj
                                                        .likelihood.name ===
                                                      el.rowName ? (
                                                        <img
                                                          alt=""
                                                          src="/images/settings-v2/check-icon.svg"
                                                          className="mr-2"
                                                        />
                                                      ) : (
                                                        <></>
                                                      )}
                                                    </div>

                                                    <div>{el.rowName}</div>
                                                  </div>
                                                </OverlayTrigger>
                                              );
                                            }
                                          )}
                                        </Dropdown.Menu>
                                      </Dropdown>
                                    </div>
                                  </div>
                                  <div
                                    className="d-flex flex-row"
                                    style={{
                                      padding: "4px 12px",
                                      alignItems: "center",
                                      gap: "8px",
                                    }}
                                  >
                                    <div className="f-darkgrey f-12 p-2 w-50">
                                      Impact
                                    </div>
                                    <div
                                      className="f-darkgrey f-12 p-2 w-50 d-flex"
                                      style={{ justifyContent: "flex-end" }}
                                    >
                                      <Dropdown
                                        className="hg-white-dropdown user-detail-dropdown grey-arr"
                                        onToggle={() => {}}
                                      >
                                        <Dropdown.Toggle
                                          className="custom-dropdown-toggle sidetoggle-dropdown-custom f-gray-imp d-flex"
                                          style={{
                                            padding: 0,
                                            alignItems: "center",
                                          }}
                                        >
                                          {riskCriticalObj &&
                                          riskCriticalObj.impact.name ? (
                                            <div>
                                              {riskCriticalObj.impact?.name}
                                            </div>
                                          ) : (
                                            <div>Select</div>
                                          )}
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu
                                          className=" impact"
                                          style={{ width: " 272px" }}
                                        >
                                          <div className="f-darkgrey f-12 p-2 w-50">
                                            Choose Impact
                                          </div>
                                          {criticalRisk?.riskColumns?.map(
                                            (el, i) => {
                                              return (
                                                <OverlayTrigger
                                                  key={i + el?.levelToolTip}
                                                  placement="left"
                                                  delay={{
                                                    show: 200,
                                                    hide: 250,
                                                  }}
                                                  overlay={renderTooltip(
                                                    el?.levelToolTip
                                                  )}
                                                >
                                                  <div
                                                    className="p-2 pl-3 my-1 pointer f-grey risk-item"
                                                    style={{
                                                      display: "grid",
                                                      "grid-template-columns":
                                                        "10% 90%",
                                                    }}
                                                    onClick={(e) => {
                                                      setRiskCriticalObj(
                                                        (prevObj) => {
                                                          const newObj = {
                                                            ...prevObj,
                                                          };
                                                          newObj.impact = {
                                                            name: el.colName,
                                                            id: el.colOrder,
                                                          };

                                                          return newObj;
                                                        }
                                                      );

                                                      e.target
                                                        .closest(
                                                          ".hg-white-dropdown"
                                                        )
                                                        .click();
                                                    }}
                                                  >
                                                    <div>
                                                      {riskCriticalObj.impact
                                                        .name === el.colName ? (
                                                        <img
                                                          alt=""
                                                          src="/images/settings-v2/check-icon.svg"
                                                          className="mr-2"
                                                        />
                                                      ) : (
                                                        <></>
                                                      )}
                                                    </div>

                                                    <div>
                                                      {el.colName}
                                                      {/* <div className="smallGreyText text-ellipsis f-darkgrey">{el.subtitle}</div> */}
                                                    </div>
                                                  </div>
                                                </OverlayTrigger>
                                              );
                                            }
                                          )}
                                        </Dropdown.Menu>
                                      </Dropdown>
                                    </div>
                                  </div>
                                  {riskRating && riskRating.name && (
                                    <>
                                      <hr
                                        style={{
                                          width: "90%",
                                          alignSelf: "center",
                                          margin: 0,
                                          padding: "4px 0px",
                                          alignItems: "center",
                                          gap: "8px",
                                        }}
                                      />
                                      <div
                                        className="d-flex flex-row justify-content-between"
                                        style={{
                                          padding: "4px 12px",
                                          alignItems: "center",
                                          gap: "8px",
                                        }}
                                      >
                                        <div className="f-darkgrey">
                                          Supplier Risk Rating
                                        </div>
                                        <Button
                                          style={{
                                            backgroundColor: riskRating.color,
                                            borderColor: riskRating.color,
                                            color: "#fff",
                                          }}
                                        >
                                          {riskRating.name}
                                        </Button>
                                      </div>
                                    </>
                                  )}
                                </div>
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                        </div>
                      </>
                    )}

                    {AllProfilingObj.SupplierInfo?.RequestStatusText !==
                      "Managed" && (
                      <div
                        className="w-100 bg-greyish d-flex align-items-center justify-content-center risk-drop f-grey pointer"
                        onClick={() => {
                          setShowEditSupplier(true);
                        }}
                      >
                        <img
                          alt=""
                          src="/images/msp/manage-supplier-folder-icon.svg"
                          className="mr-1"
                        ></img>
                        Manage Now
                      </div>
                    )}
                  </div>
                )}
              </>
            )}
            {(!isTemplate || isTemplate === undefined) && (
              <>
                {activityTab ? (
                  <>
                    <div
                      className="d-flex align-items-center f-grey f-500 pointer mr-3 p-2 border-bottom justify-content-between"
                      style={{
                        position: "fixed",
                        zIndex: 2,
                        background: "#fff",
                        width: "18%",
                        top: "45px",
                      }}
                      onClick={() => {
                        // setActivityTab(true);
                      }}
                    >
                      <div>
                        <img
                          src="/images/msp/dummy-clock.svg"
                          alt=""
                          className="mr-2"
                        />
                        Activity
                      </div>

                      <span
                        className="cursor-pointer"
                        onClick={() => {
                          setActivityTab(false);
                        }}
                      >
                        <img
                          src="/images/attack-surface/cross-icon.svg"
                          alt=""
                        />
                      </span>
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </>
            )}

            {!loading &&
              (activityTab ? (
                <div className="p-2 " style={{ marginTop: "30px" }}>
                  {activityData?.map((activity) => {
                    return (
                      <div className="d-flex flex-row p-1">
                        <div className="mr-2">
                          {activity.ContributorLogo ? (
                            <img
                              alt=""
                              width={16}
                              height={16}
                              src={activity.ContributorLogo}
                              style={{ borderRadius: "9999px" }}
                            />
                          ) : (
                            <ImageWithInitials
                              initials={activity.ContributorName[0]}
                              width={16}
                              background={"purple"}
                            />
                          )}
                        </div>
                        <div
                          className="d-flex flex-column w-90"
                          style={{ gap: "8px" }}
                        >
                          <div>
                            <span className="f-600 mr-1">
                              {activity.ContributorName}
                            </span>
                            {activity.ActionPerformed}
                            <span className="f-600 ml-1">
                              {activity.Section}
                            </span>
                            <div className="f-darkgrey f-12">
                              {activity.Date}
                            </div>
                          </div>

                          <div>
                            <div className="f-darkgrey f-12">
                              {activity.Type}
                            </div>

                            <div
                              className="f-darkgrey f-14 flex-wrap"
                              style={{
                                wordWrap: "break-word",
                                textDecoration: "line-through",
                              }}
                            >
                              {activity.PreviousValue}
                            </div>
                            {activity.Type == "Profiling Comment" ||
                            activity.Type == "Questionnaire Comment" ? (
                              <>
                                {activity.Value ? (
                                  <span
                                    dangerouslySetInnerHTML={{
                                      __html: getHTMLfromRawState(
                                        activity.Value,
                                        // mentionList
                                        []
                                      ),
                                    }}
                                  />
                                ) : (
                                  <></>
                                )}
                              </>
                            ) : (
                              <div
                                className="f-14 flex-wrap"
                                style={{ wordWrap: "break-word" }}
                              >
                                {activity.Value}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              ) : (
                <div>
                  <div className="f-12 f-darkgrey py-20px">
                    {" "}
                    Profiling Process
                  </div>
                  <DragDropContext onDragEnd={onDragEndShown}>
                    <Droppable
                      direction="vertical"
                      droppableId="droppableSection"
                    >
                      {(provided, snapshot) => (
                        <div
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                        >
                          <div className="info-timeline">
                            <ul>
                              {profilingBlocks &&
                                profilingBlocks.map((section, i) => (
                                  <Draggable
                                    key={section?.Title}
                                    draggableId={section?.ObjectId.toString()}
                                    index={i}
                                    // isDragDisabled
                                  >
                                    {(provided, snapshot) => (
                                      <li>
                                        <div
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}
                                          className=" d-flex flex-row thread pl-2"
                                          key={i}
                                          index={i}
                                        >
                                          <span className="timeline-circle"></span>
                                          <div
                                            className="d-flex flex-row justify-content-between ml-2 py-6px w-100"
                                            onClick={() => {
                                              let dropDown_toggle =
                                                document.getElementsByClassName(
                                                  section.ObjectId
                                                )[0];

                                              dropDown_toggle.scrollIntoView({
                                                behavior: "smooth",
                                                block: "center",
                                                inline: "nearest",
                                              });
                                            }}
                                          >
                                            <div className="">
                                              {section?.Title}
                                            </div>
                                            <img
                                              alt=""
                                              src="/images/supply-chain/drag-n-drop.svg"
                                              className="mr-2 hover-visible"
                                            />
                                          </div>
                                        </div>
                                      </li>
                                    )}
                                  </Draggable>
                                ))}
                            </ul>
                          </div>
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                  <div className="info-timeline">
                    <ul>
                      <li></li>
                      {!isContributorView && isSent && (
                        <li>
                          <div className=" d-flex flex-row thread pl-2">
                            <span className="timeline-circle">+</span>
                            <div
                              className="d-flex flex-row justify-content-between ml-2 py-6px w-100"
                              onClick={() => {
                                let dropDown_toggle =
                                  document.getElementsByClassName(
                                    "add-section"
                                  )[0];

                                dropDown_toggle.scrollIntoView({
                                  behavior: "smooth",
                                  block: "center",
                                  inline: "nearest",
                                });
                              }}
                            >
                              <div className="">Add section</div>
                              <img
                                alt=""
                                src="/images/supply-chain/drag-n-drop.svg"
                                className="mr-2 hover-visible"
                              />
                            </div>
                          </div>
                        </li>
                      )}
                    </ul>
                  </div>
                  <div className="d-flex flex-row ml-2"></div>
                </div>
              ))}
          </div>
        ) : activityTab ? (
          <div
            className="border-left hide-scrollbar  bg-white w-20 right-0 p-2 position-fixed"
            style={{
              overflowY: "scroll",
              overflowX: "scroll",
              height: "96%",
              top: "48px",
              position: "relative",
            }}
          >
            {(!isTemplate || isTemplate === undefined) && (
              <>
                {activityTab ? (
                  <>
                    <div
                      className="d-flex align-items-center f-grey f-500 pointer mr-3 p-2 border-bottom justify-content-between"
                      style={{
                        position: "fixed",
                        zIndex: 2,
                        background: "#fff",
                        width: "18%",
                        top: "45px",
                      }}
                      onClick={() => {
                        // setActivityTab(true);
                      }}
                    >
                      <div>
                        <img
                          src="/images/msp/dummy-clock.svg"
                          alt=""
                          className="mr-2"
                        />
                        Activity
                      </div>

                      <span
                        className="cursor-pointer"
                        onClick={() => {
                          setActivityTab(false);
                        }}
                      >
                        <img
                          src="/images/attack-surface/cross-icon.svg"
                          alt=""
                        />
                      </span>
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </>
            )}

            {!loading &&
              (activityTab ? (
                <div className="p-2 " style={{ marginTop: "30px" }}>
                  {activityData?.map((activity) => {
                    return (
                      <div className="d-flex flex-row p-1">
                        <div className="mr-2">
                          {activity.ContributorLogo ? (
                            <img
                              alt=""
                              width={16}
                              height={16}
                              src={activity.ContributorLogo}
                              style={{ borderRadius: "9999px" }}
                            />
                          ) : (
                            <ImageWithInitials
                              initials={activity.ContributorName[0]}
                              width={16}
                              background={"purple"}
                            />
                          )}
                        </div>
                        <div
                          className="d-flex flex-column w-90"
                          style={{ gap: "8px" }}
                        >
                          <div>
                            <span className="f-600 mr-1">
                              {activity.ContributorName}
                            </span>
                            {activity.ActionPerformed}
                            <span className="f-600 ml-1">
                              {activity.Section}
                            </span>
                            <div className="f-darkgrey f-12">
                              {activity.Date}
                            </div>
                          </div>

                          <div>
                            <div className="f-darkgrey f-12">
                              {activity.Type}
                            </div>

                            <div
                              className="f-darkgrey f-14 flex-wrap"
                              style={{
                                wordWrap: "break-word",
                                textDecoration: "line-through",
                              }}
                            >
                              {activity.PreviousValue}
                            </div>
                            {activity.Type == "Profiling Comment" ||
                            activity.Type == "Questionnaire Comment" ? (
                              <>
                                {activity.Value ? (
                                  <span
                                    dangerouslySetInnerHTML={{
                                      __html: getHTMLfromRawState(
                                        activity.Value,
                                        // mentionList
                                        []
                                      ),
                                    }}
                                  />
                                ) : (
                                  <></>
                                )}
                              </>
                            ) : (
                              <div
                                className="f-14 flex-wrap"
                                style={{ wordWrap: "break-word" }}
                              >
                                {activity.Value}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              ) : (
                <div></div>
              ))}
          </div>
        ) : (
          <></>
        )}
      </div>

      <ImportSuppliersModal
        show={importantSuppliers}
        hideModal={() => setImportantSuppliers(false)}
        complianceProfileId={compilanceId}
      />
      <DeleteModal
        show={showDeleteModal}
        hideModal={() => setShowDeleteModal(false)}
        deleteMessage={"You are about to delete a profiling."}
        deleteType={"deleteSentQuestionnaire"}
        deleteSentQuestionnaire={() => {
          ArchiveOrDeleteQuestions(questionId);
        }}
      />
      <Suspense fallback={<div>Loading...</div>}>
        <EditSupplierModal
          show={showEditSupplier}
          hideModal={() => setShowEditSupplier(false)}
          editSupplierData={editSupplierData}
          mode={"addasasupplier"}
        />
      </Suspense>
    </div>
  );
};

export default SupplierProfilerHomeScreen;

// const Tooltip = ({ text, position }) => {
//   const style = {
//     position: 'absolute',
//     left: position.x,
//     top: position.y,
//     // Additional styling for the tooltip
//   };

//   return (
//     <div className="tooltip" style={style}>
//       {text}
//     </div>
//   );
// };
