import React from "react";
import { Col, Row } from "react-bootstrap";
import InsightsBodyHeader from "./InsightsBodyHeader";
import InsightsSidebar from "./InsightsSidebar";

const AttackSurfaceInsights = () => {
  return (
    <Row className="m-0 p-0">
      <Col className="p-0" xs={2}>
        <InsightsSidebar />
      </Col>
      <Col className="p-0" xs={10}>
        <InsightsBodyHeader />
      </Col>
    </Row>
  );
};

export default AttackSurfaceInsights;
