import React from "react";
import ContentLoader from "react-content-loader";

const CommentsLoader = () => {
  return (
    <ContentLoader
      speed={2}
      width={"100%"}
      //   height={"100%"}
      viewBox="0 0 1500 1500"
      backgroundColor="#F6F8FB"
      foregroundColor="#ecebeb"
    >
      <rect x="25%" y="47" rx="4" ry="4" width="50%" height="45" />
      <rect x="45%" y="120" rx="4" ry="4" width="10%" height="60" />
      <rect x="20%" y="199" rx="4" ry="4" width="60%" height="45" />

      {/* First Comment */}
      <rect x="0" y="285" rx="40" ry="40" width="80" height="80" />
      <rect x="100" y="295" rx="4" ry="4" width="290" height="50" />
      <rect x="1380" y="290" rx="6" ry="6" width="80" height="80" />

      <rect x="100" y="400" rx="4" ry="4" width="85%" height="50" />
      <rect x="100" y="470" rx="4" ry="4" width="75%" height="50" />
      <rect x="100" y="540" rx="4" ry="4" width="30%" height="50" />

      {/* Second Comment */}

      <rect x="0" y="680" rx="40" ry="40" width="80" height="80" />
      <rect x="100" y="690" rx="4" ry="4" width="290" height="50" />
      <rect x="1380" y="680" rx="6" ry="6" width="80" height="80" />

      <rect x="100" y="790" rx="4" ry="4" width="85%" height="50" />
      <rect x="100" y="860" rx="4" ry="4" width="75%" height="50" />
      <rect x="100" y="930" rx="4" ry="4" width="30%" height="50" />
    </ContentLoader>
  );
};

export default CommentsLoader;
