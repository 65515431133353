import React, { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import "./clientComplianceDropdown.scss";
import { TrimString } from "../../../../../Utils/StringTrimmer";

const SecurityPoliciesDropdown = (props) => {
  // home, calender
  const [page, setPage] = useState("home");
  
  const [searchPolicyString, setSearchPolicyString] = useState('')
  const [showChildDropdown, setShowChildDropdown] = useState(false)

   

  useEffect(() => {
    setPage("home");
  }, []);

  return (
    <Dropdown className="security-policy-dropdown mr-2" onToggle={(e) => {}}>
      <Dropdown.Toggle className="parent-dropdown-toggle pr-2">
        <div className="d-darkgrey f-500 d-flex align-items-center">
          {(props.question?.AnswserSelectedDropdownItems?.length >0 &&
          props.question?.AnswerBool )
            ? `${
                props.question?.AnswserSelectedDropdownItems?.length === 1
                  ? `${props.question?.AnswserSelectedDropdownItems?.length} policy`
                  : `${props.question?.AnswserSelectedDropdownItems?.length} policies`
              } `
            : props.question?.AnswerBool === false
            ? "No"
            : "Select"}
          <img src="/images/big-chevron-down.svg" alt="" className="ml-2" />
        </div>
      </Dropdown.Toggle>

      <Dropdown.Menu alignRight={true} className="parent-dropdown-menu set-dropdown-right-bottom">
        <div className="px-2 py-1 pointer">
          <div
            className="d-flex align-items-center my-2"
            onClick={(e) => {
              props.onChange([], false);
              e.target.closest(".security-policy-dropdown").click()
            }}
          >
            <img
              className="mr-2"
              src="/images/msp/grey-tick.svg"
              alt=""
              style={{
                visibility: `${
                  props.question.AnswerBool === false ? "visible" : "hidden"
                }`,
              }}
            />
            <div className="f-grey">No</div>
          </div>

          <div
            className="d-flex align-items-center my-2"
            onClick={() => {
              props.onChange([], true);
            }}
          >
            <div className="d-flex align-items-center">
              <img
                className="mr-2"
                src="/images/msp/grey-tick.svg"
                alt=""
                style={{
                  visibility: `${
                    props.question.AnswerBool === true ? "visible" : "hidden"
                  }`,
                }}
              />
              <div className="f-grey">Yes</div>
            </div>
          </div>
          {props.question.AnswerBool === true && (
            <>
              <hr className="mb-2" />
              <Dropdown className="security-child-dropdown"
              show={showChildDropdown}
              onToggle={(e)=>{
                setShowChildDropdown(e)
              }}
              
              >
                <Dropdown.Toggle className="w-100">
                  <div className="d-flex align-items-center bg-grey radius-4 p-2 w-100 highlight-input">
                    <img
                      alt=""
                      src="/images/attack-surface/search-icon.svg"
                      className="mr-2"
                    />
                    <input
                      type="text"
                      placeholder="Find or add policies"
                      className="bg-transparent border-none flex-grow-1"
                      value={searchPolicyString}
                      onChange={(e) => {
                        setSearchPolicyString(e.target.value);
                        setShowChildDropdown(true)
                      }}
                    />
                    {searchPolicyString !== "" && (
                      <img
                        src="/images/settings-v2/header-search-cross.svg"
                        alt=""
                        className="pointer "
                        onClick={() => {
                          setSearchPolicyString("");
                        }}
                      />
                    )}
                  </div>
                </Dropdown.Toggle>
                <Dropdown.Menu className="p-2 w-100 mt-2 set-dropdown-right-bottom">
                  <div className="f-12 f-darkgrey pl-2">Results</div>
                  {
                    props.question.QuestionDropdownListMembers?.filter?.(
                      (item) =>
                        item
                          ?.toLowerCase()
                          ?.includes(searchPolicyString?.toLowerCase())
                    )?.length===0 && <div className="w-100 f-grey f-500 p-2 px-5">No Results found !!</div>
                  }
                  <div className="transparent-scroller" style={{
                    maxHeight:"264px",
                    overflowY:"auto"
                  }}>
                  {props.question.QuestionDropdownListMembers?.filter?.(
                    (item) =>
                      item
                        ?.toLowerCase()
                        ?.includes(searchPolicyString?.toLowerCase())
                  )?.map?.((availableItem, availableItemIndex) => {
                    let updated_list = props.question
                    ?.AnswserSelectedDropdownItems
                    ? [...props.question?.AnswserSelectedDropdownItems]
                    : [];
                    return (
                      <div
                        className="my-2 pl-2 text-ellipsis"
                        onClick={() => {
                      
                          if (updated_list.includes(availableItem)) {
                          } else {
                            updated_list.push(availableItem);

                            props.onChange(updated_list, true);
                          }
                        }}
                      >
                        {" "}
                       
                       <img src="/images/msp/dropdown-grey-tick.svg"
                       className="mr-2"
                       style={{
                        visibility:updated_list.includes(availableItem)?  "visible" :"hidden"
                       }}
                       
                       alt=""
                       
                       />
                       
                       
                        {availableItem}{" "}
                      </div>
                    );
                  })}
                  </div>


                  {searchPolicyString !== "" && (
                    <div className="f-blue f-500 my-2">
                      Add {`"${TrimString(searchPolicyString, 12)}"`}{" "}
                    </div>
                  )}
                </Dropdown.Menu>
              </Dropdown>

              {/* Selected Policies */}
              {(props.question?.AnswserSelectedDropdownItems?.length === 0 ||
                props.question?.AnswserSelectedDropdownItems === null) && (
                <div className="f-darkgrey mb-2 pl-2 mt-4 w-100 d-flex justify-content-center">
                  No Policies Added !!
                </div>
              )}

              {props.question?.AnswserSelectedDropdownItems?.length > 0 && (
                <div className="f-darkgrey mb-2 pl-2 mt-3">Added Policies</div>
              )}

              {props.question?.AnswserSelectedDropdownItems?.map?.(
                (selectedItem, selectedItemIndex) => {
                  return (
                    <div className="my-2 pl-2 d-flex align-items-center justify-content-between">
                      <div className="text-ellipsis mr-2" title={selectedItem}>
                        {TrimString(selectedItem, 150)}
                      </div>
                      <img
                        alt=""
                        className="pointer mr-2 ml-3"
                        src="/images/msp/cancel-icon.svg"
                        onClick={() => {
                          let updated_list = [
                            ...props.question?.AnswserSelectedDropdownItems,
                          ];
                          updated_list.splice(selectedItemIndex, 1);
                          props.onChange(updated_list, true);
                        }}
                      />
                    </div>
                  );
                }
              )}
            </>
          )}
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default SecurityPoliciesDropdown;
