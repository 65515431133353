import React, { useEffect, useState } from "react";
import { Button, Nav, Tab } from "react-bootstrap";
import ContentLoader from "react-content-loader";
import { getAPIData } from "../../../Utils/commonApi";
import { useAuth } from "../../context/Auth";
import { CallToast } from "../../PriceCalculator/modals/CustomToast";
import InfoSecTable from "./InfoSecTable";

const InfoSecPoliciesScreen = ({ complianceSectionPageEnumData, goTo }) => {
  const { authTokens } = useAuth();
  const [pageData, setPageData] = useState({});
  const [loading, setLoading] = useState(false);

  // Policies category list
  const [recommendedList, setRecommendedList] = useState([]);
  const [cyberEssList, setCyberEssList] = useState([]);
  const [myList, setMyList] = useState([]);

  useEffect(() => {
    setLoading(true);
    getPageData(complianceSectionPageEnumData, 0);
    getPageData(complianceSectionPageEnumData, 1);
    getPageData(complianceSectionPageEnumData, 2);
  }, [complianceSectionPageEnumData]);

  const pageEnumMapping = {
    400: "accessCE",
    401: "accessIRP",
    402: "accessRA",
    403: "accesssACR",
    404: "accessISP",
    405: "accessBCDR",
  };

  async function getPageData(complianceSectionPageEnumData, option) {
    await getAPIData(`${complianceSectionPageEnumData?.pageEnum}`, authTokens, {
      optionEnum1: option,
      optionEnum2: complianceSectionPageEnumData?.supplierId,
      optionEnum3:
        complianceSectionPageEnumData?.[
          pageEnumMapping[complianceSectionPageEnumData?.pageEnum]
        ],
    })
      .then((response) => {
        setLoading(false);
        if (response?.mr?.Success) {
          if (option === 0) setRecommendedList(response?.DataList ?? []);

          if (option === 2) setCyberEssList(response?.DataList ?? []);

          if (option === 1) setMyList(response?.DataList ?? []);
        } else {
          CallToast("Something went wrong", false, false, "v2style");
        }
      })
      .catch(() => {
        CallToast("Something went wrong", false, false, "v2style");
      });
  }
  return (
    <div className="w-100 pt-4 mr-5">
      {loading ? (
        <ScreenLoader />
      ) : (
        <>
          {recommendedList?.length === 0 &&
            cyberEssList?.length === 0 &&
            myList?.length === 0 && (
              <div className="d-flex flex-column align-items-center justify-content-center mt-5">
                <div className="f-15 f-500 f-black">No Policies to show...</div>
                <div className="f-grey my-3">
                  Upload your first Policy to get started
                </div>
                <div>
                  <Button className="hg-cancel-btn">Upload Policy</Button>
                </div>
              </div>
            )}
          {(recommendedList?.length > 0 ||
            cyberEssList?.length > 0 ||
            myList?.length > 0) && (
            <Tab.Container
              id="left-tabs-example"
              defaultActiveKey="recommended"
            >
              <div>
                <div
                  sm={12}
                  className="tab-header d-flex justify-content-between align-items-center ml-5 p-0"
                >
                  <div className={`${true}`}>
                    <Nav variant="pills" className="flex-row">
                      <Nav.Item>
                        <Nav.Link eventKey="recommended">Recommended</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="cyberEssentials">
                          Cyber Essentials
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="myPolicies">My Policies</Nav.Link>
                      </Nav.Item>
                    </Nav>
                  </div>
                </div>
                <div sm={12} className="ml-5 p-0">
                  <Tab.Content>
                    <Tab.Pane eventKey="recommended">
                      <div className="f-500 f-black mb-3 mt-5 ml-2">
                        Recommended Policies
                      </div>
                      <InfoSecTable
                        data={recommendedList}
                        goTo={goTo}
                        accessLevel={
                          complianceSectionPageEnumData?.[
                            pageEnumMapping[
                              complianceSectionPageEnumData?.pageEnum
                            ]
                          ]
                        }
                      />
                    </Tab.Pane>
                    <Tab.Pane eventKey="cyberEssentials">
                      <div className="f-500 f-black mb-3 mt-5 ml-2">
                        Cyber Essentials
                      </div>
                      <InfoSecTable
                        data={cyberEssList}
                        goTo={goTo}
                        accessLevel={
                          complianceSectionPageEnumData?.[
                            pageEnumMapping[
                              complianceSectionPageEnumData?.pageEnum
                            ]
                          ]
                        }
                      />
                    </Tab.Pane>
                    <Tab.Pane eventKey="myPolicies">
                      <div className="f-500 f-black mb-3 mt-5 ml-2">
                        My Policies
                      </div>
                      <InfoSecTable
                        data={myList}
                        goTo={goTo}
                        accessLevel={
                          complianceSectionPageEnumData?.[
                            pageEnumMapping[
                              complianceSectionPageEnumData?.pageEnum
                            ]
                          ]
                        }
                      />
                    </Tab.Pane>
                  </Tab.Content>
                </div>
              </div>
            </Tab.Container>
          )}{" "}
        </>
      )}
    </div>
  );
};

export default InfoSecPoliciesScreen;

const ScreenLoader = () => {
  return (
    <ContentLoader
      speed={2}
      width={"100%"}
      height={"100%"}
      viewBox="0 -80 1500 2000"
      backgroundColor="#F6F8FB"
      foregroundColor="#ecebeb"
    >
      <rect x="5%" y="15" rx="16" ry="16" width="90%" height="100" />
      <rect x="5%" y="120" rx="16" ry="16" width="90%" height="100" />
      <rect x="5%" y="225" rx="16" ry="16" width="90%" height="100" />
      <rect x="5%" y="330" rx="16" ry="16" width="90%" height="100" />
      <rect x="5%" y="435" rx="16" ry="16" width="90%" height="100" />
    </ContentLoader>
  );
};
