import React from "react";
import ContentLoader from "react-content-loader";

const SubscriptionPageLoader = () => {
  return (
    <ContentLoader
      speed={2}
      width={"100%"}
      height={"100%"}
      viewBox="0 160 1500 900"
      backgroundColor="#F6F8FB"
      foregroundColor="#ecebeb"
    >
      <rect x="17%" y="210" rx="3" ry="3" width="10%" height="26" />
      <rect x="62%" y="210" rx="3" ry="3" width="16%" height="30" />
      <rect x="17%" y="310" rx="6" ry="6" width="60%" height="15" />
      <rect x="17%" y="340" rx="6" ry="6" width="18%" height="15" />

      <rect x="17%" y="370" rx="4" ry="4" width="21%" height="500" />
      <rect x="40%" y="370" rx="4" ry="4" width="21%" height="500" />
      <rect x="63%" y="370" rx="4" ry="4" width="21%" height="500" />

      {/* <rect x="18%" y="509" rx="4" ry="4" width="60%" height="60" /> */}
      {/* <rect x="18%" y="572" rx="4" ry="4" width="60%" height="60" /> */}
      {/* <rect x="18%" y="635" rx="4" ry="4" width="60%" height="60" /> */}
      {/* <rect x="18%" y="698" rx="4" ry="4" width="60%" height="60" /> */}
      {/* <rect x="18%" y="761" rx="4" ry="4" width="60%" height="60" /> */}
      {/* <rect x="18%" y="824" rx="4" ry="4" width="60%" height="60" /> */}
    </ContentLoader>
  );
};

export default SubscriptionPageLoader;
