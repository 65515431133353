import React, { useState, useEffect } from "react";

import $ from "jquery";
import "../../main.scss";
import { FiChevronRight } from "react-icons/fi";
import ModalDynamic from "../ModalDynamic/ModalDynamic";
import CollapsabileTable from "../CollapsabileTable/CollapsabileTable";
import { useDb } from "../context/db";
import TechnicalDrillDownCyberThreatLevelModal from "../AttackSurface/TechnicalDrillDownCyberThreatLevelModal";
import { setuser } from "../../redux/userState/actions";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import MSPTechnicalDrillDownCyberThreatLevelModal from "../AttackSurface/MSPTechnicalDrillDownCyberThreatLevelModal";
import CyberGroupModal from "../AttackSurface/CyberGroupModal";

const ThreatLevel = (props) => {
  const uniqueNum = props.id ? props.id : new Date().getUTCMilliseconds();
  const dispatch = useDispatch();
  const [threatNumber, setThreatNumber] = useState(props.threatNumber || 0);
  const [threatHeight, setThreatHeight] = useState(
    props.threatHeight || "275px"
  );
  const [threatWidth, setThreatWidth] = useState(props.threatWidth || false);
  const [removeBottomPart] = useState(props.removeBottom || false);
  const [threatText] = useState(props.threatText || "");
  const [padding, setPadding] = useState(props.padding == false ? false : true);
  const [largeModal, setlargeModal] = useState(false);
  const [removeText, setremoveText] = useState(props.removeText || false);
  const displayModal = props.displayModal;
  const { dbObj, setdbObject, impersinatingDetails } = useDb();
  const [
    showTechnicalDrillDownCyberThreatLevel,
    setShowTechnicalDrillDownCyberThreatLevel,
  ] = useState(false);
  const [
    showMspTechnicalDrillDownCyberThreatLevel,
    setShowMspTechnicalDrillDownCyberThreatLevel,
  ] = useState(false);
  const [showRipple, setShowRipple] = useState(false);
  const accessState = window.localStorage.getItem("object_Data")
    ? JSON.parse(window.localStorage.getItem("object_Data"))?.accessData
    : null;

  function showRippleEffect() {
    setShowRipple(true);
    setTimeout(() => {
      setShowRipple(false);
    }, 5000);
  }
  const newCyberScoreupdated = useSelector(
    (state) => state?.user?.userDetail?.newCyberScoreupdated
  );
  useEffect(() => {
    if (newCyberScoreupdated) {
      showRippleEffect();
      setTimeout(() => {
        dispatch(
          setuser({
            newCyberScoreupdated: false,
          })
        );
      }, 1000);
    }
  }, [newCyberScoreupdated]);

  var classes = "";
  if (removeBottomPart) {
    var classes = "mx-auto my-auto";
  }
  useEffect(() => {
    setThreatNumber(props.threatNumber);
  }, [props.threatNumber]);

  useEffect(() => {
    function animateColor(remove, add) {
      remove.removeClass("color");
      add.addClass("color");
    }
    function refreshColorsFromAll() {
      $(".low").removeClass("color");
      $(".moderate").removeClass("color");
      $(".none").removeClass("color");
      $(".high").removeClass("color");
      $(".critical").removeClass("color");
    }
    var bar = $(`#bar__inner${uniqueNum}`);
    // var barPercent = bar.attr("data-percent");

    bar.animate(
      {
        height: 100 - props.threatNumber + "%",
      },
      {
        easing: "linear",
        duration: 600,
        step: function (currentPercent) {
          if (currentPercent <= 100 && currentPercent > 95) {
            refreshColorsFromAll();
            animateColor($(".none"), $(".none"));
            // $("#bar__status").css("color", "#40D88E").text("None");
          } else if (currentPercent <= 95 && currentPercent > 50) {
            refreshColorsFromAll();
            animateColor($(".none"), $(".low"));
            // $("#bar__status").css("color", "#ffd11d").text("Low");
          } else if (currentPercent <= 50 && currentPercent > 25) {
            refreshColorsFromAll();
            animateColor($(".low"), $(".moderate"));
            // $("#bar__status").css("color", "#FFAC2C").text("Moderate");
          } else if (currentPercent <= 25 && currentPercent > 0) {
            refreshColorsFromAll();
            animateColor($(".moderate"), $(".high"));
            // $("#bar__status").css("color", "#F36060").text("High");
          } else if (currentPercent == 0) {
            refreshColorsFromAll();
            animateColor($(".high"), $(".critical"));
            // $("#bar__status").css("color", "red").text("Critical");
          }
        },
      }
    );

    // $({ barChartCount: 0 }).animate(
    //   { barChartCount: barPercent },
    //   {
    //     easing: "swing",
    //     duration: 800,
    //     step: function () {
    //       $(`#bar-value${uniqueNum}`).text(Math.ceil(this.barChartCount));
    //     },
    //     complete: function () {},
    //   }
    // );

    // function animateColor(remove, add) {
    //   remove.removeClass("color");
    //   add.addClass("color");
    // }
  }, [props.threatNumber]);

  return (
    <div
      id={`${padding ? `threat_level ` + classes : classes}${uniqueNum}`}
      className={padding ? `threat_level ` + classes : classes}
      onClick={() => {}}
    >
      {largeModal ? (
        <ModalDynamic
          setShow={true}
          modalSize="lg"
          modalHeader={largeModal ? largeModal : ""}
          modalData={
            <CollapsabileTable pageType={largeModal ? largeModal : ""} />
          }
        />
      ) : (
        ""
      )}
      <div
        id={`bar__wrapper${uniqueNum}`}
        className={`bar__wrapper    ${
          (props.showDrillDownModal &&
          accessState?.Override === true &&
          (accessState?.AccessState_Dashboard === 2 ))||
          accessState?.AccessState_Dashboard === 3
            ? "cursor-default"
            : "pointer"
        }  `}
        onClick={() => {
          if (props.showDrillDownModal) {
            if (
              accessState?.Override === true &&
              accessState?.AccessState_Dashboard !== 2
            ) {
              setShowTechnicalDrillDownCyberThreatLevel(true);
            }
          }

          // displayModal
          //   ? () => {
          //       setlargeModal(false);
          //       // setTimeout(
          //       //   () => setlargeModal("Technical Drilldown CyberThreat Level"),
          //       //   10
          //       // );
          //       setShowTechnicalDrillDownCyberThreatLevel(true);
          //     }
          //   : ""
        }}
      >
        {/* <h1 className="bar__title">Cyber Threat Level {threatText}</h1> */}
        <div id={`bar__container${uniqueNum}`} className="bar__container">
          <div
            id={`bar${uniqueNum}`}
            className="bar"
            style={{
              height: threatHeight,
              width: threatWidth ? threatWidth : "",
            }}
          >
            <div
              id={`bar__inner${uniqueNum}`}
              className="bar__inner"
              data-percent={
                props.threatNumber
                // threatNumber /
              }
            ></div>
          </div>

          {removeText ? (
            ""
          ) : (
            <div id={`bar__scale${uniqueNum}`} className="bar__scale">
              <span id={`critical${uniqueNum}`} className="critical">
                Critical
              </span>
              <span id={`high${uniqueNum}`} className="high">
                High
              </span>
              <span id={`moderate${uniqueNum}`} className="moderate">
                Moderate
              </span>
              <span id={`low${uniqueNum}`} className="low">
                Low
              </span>
              <span
                id={`none${uniqueNum}`}
                className={
                  dbObj.SubscriptionDetail &&
                  dbObj.SubscriptionDetail.PreDataView
                    ? "none"
                    : "color"
                }
              >
                None
              </span>
            </div>
          )}
        </div>
        {removeBottomPart ? (
          ""
        ) : (
          <div
            className={`mt-4 ${
              accessState?.Override === true &&
              accessState?.AccessState_Dashboard === 2
                ? "cursor-default"
                : "pointer"
            }`}
            onClick={() => {
              if (
                accessState?.Override === true &&
                accessState?.AccessState_Dashboard !== 2
              ) {
                if (props.isMspClient) {
                  setShowMspTechnicalDrillDownCyberThreatLevel(true);
                } else {
                  setShowTechnicalDrillDownCyberThreatLevel(true);
                }
              }
            }}
          >
            <p style={{ fontWeight: "500", fontSize: "18px" }}>
              Your CyberThreat Level :
              <span className="position-relative">
                <span
                  className={`${
                    showRipple ? "request-loader- test-shock-threat" : ""
                  }`}
                >
                  {threatNumber}
                </span>
              </span>
              {/* {dbObj.CommonComponents.CyberThreatNumber} */}
            </p>
            <p
              className={` ${
                accessState?.Override === true &&
                accessState?.AccessState_Dashboard === 2
                  ? "linkGreyText cursor-default"
                  : "greyLinkText"
              }`}
              onClick={() => {
                if (
                  accessState?.Override === true &&
                  accessState?.AccessState_Dashboard !== 2
                ) {
                  if (props.isMspClient) {
                    setShowMspTechnicalDrillDownCyberThreatLevel(true);
                  } else {
                    setShowTechnicalDrillDownCyberThreatLevel(true);
                  }
                }
              }}
            >
              Threat Details
              {accessState?.Override === true &&
              accessState?.AccessState_Dashboard === 2 ? (
                <img
                  alt=""
                  className="lockerDarkGreyImage mx-2 mb-1"
                  src="/images/settings-v2/locker-grey.svg"
                />
              ) : (
                <FiChevronRight />
              )}
            </p>
          </div>
        )}
      </div>
      {/* <TechnicalDrillDownCyberThreatLevelModal
        show={showTechnicalDrillDownCyberThreatLevel}
        hideModal={() => setShowTechnicalDrillDownCyberThreatLevel(false)}
      /> */}
      {/* <MSPTechnicalDrillDownCyberThreatLevelModal
        show={showMspTechnicalDrillDownCyberThreatLevel}
        hideModal={() => setShowMspTechnicalDrillDownCyberThreatLevel(false)}
      /> */}
      <CyberGroupModal
        // isSaas={true}
        show={showTechnicalDrillDownCyberThreatLevel}
        hideModal={() => {
          setShowTechnicalDrillDownCyberThreatLevel(false);

          if (props.refreshData) {
            props.refreshData();
          }
        }}
        clientId={props.clientId ?? 0}
        parentID={"mainBody"}
        defaultTab={"cyberThreatLevel"}
      />
    </div>
  );
};

export default ThreatLevel;
