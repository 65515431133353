import React, { useState, useEffect } from "react";
import { Row, Col, Modal, Form, Button } from "react-bootstrap";
import { getAPIData, postData, postRequest } from "../../../Utils/commonApi";
import { useAuth } from "../../context/Auth";
import { CallToast } from "../../PriceCalculator/modals/CustomToast";
import DropdownField from "../../Theme/dropdown2";
import CustomSlider from "./CustomSlider";
import Loader from "../../Common/loader";

const CyberScoreNotificationModal = ({ show, hideModal, type }) => {
  const { authTokens } = useAuth();
  const [senstivity, setSenstivity] = useState(0);
  const [threshold, setThreshold] = useState(0);
  const [isVisible, setIsVisible] = useState(true);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState("home");
  useEffect(() => {
    if (show) {
      setLoading(true);
      setPage("home");
      if (type !== "actioncenter") {
        getNotificationSettings();
      } else {
        LoadActionCentrePageData();
      }
    }
  }, [show]);

  const [selected1, setSelected1] = useState({
    id: 5,
    category: "Disabled",
  });
  const [selected2, setSelected2] = useState({
    id: 5,
    category: "Disabled",
  });
  const [selected3, setSelected3] = useState({
    id: 5,
    category: "Disabled",
  });
  const [selected4, setSelected4] = useState({
    id: 5,
    category: "Disabled",
  });
  const ModalData = {
    cyberscore: {
      mainHeader: "CyberScore Notifications",
      headerSens: "CyberScore Notifications Sensitivity",
      headerThres: "CyberScore Notifications Thresholds",
      variable: "CyberScore",
      dropdown1: "CyberScore is increased",
      dropdown2: "CyberScore is decreased",
      senstivity_handle: "Sensitivity_CyberScore",
      threshold_handle: "Threshold_CyberScore",
      senstivity_reset: "Sensitivity_CyberScore_Reset",
      threshold_reset: "Threshold_CyberScore_Reset",
      increase_var: "UScoreIncreaseNotification",
      decrease_var: "UScoreDescreaseNotification",
      setNotification: "CyberScoreNotification",
    },
    cyberthreat: {
      mainHeader: "CyberThreat Level Notifications",
      headerSens: "CyberThreat Level Notifications Sensitivity",
      headerThres: "CyberThreat Level Notifications Thresholds",
      variable: "CyberThreat Level",
      dropdown1: "CyberThreat Level is increased",
      dropdown2: "CyberThreat Level is decreased",
      senstivity_handle: "Sensitivity_CyberThreat",
      threshold_handle: "Threshold_CyberThreat",
      senstivity_reset: "Sensitivity_CyberThreat_Reset",
      threshold_reset: "Threshold_CyberThreat_Reset",
      increase_var: "UThreatIncreaseNotification",
      decrease_var: "UThreatDecreaseNotification",
      setNotification: "CyberThreatNotification",
    },
    cybercompliance: {
      mainHeader: "CyberCompliance Notifications",
      headerSens: "CyberCompliance Notifications Sensitivity",
      headerThres: "CyberCompliance Notifications Thresholds",
      variable: "CyberCompliance",
      dropdown1: "CyberCompliance is increased",
      dropdown2: "CyberCompliance is decreased",
      senstivity_handle: "Sensitivity_CyberCompliance",
      threshold_handle: "Threshold_CyberCompliance",
      senstivity_reset: "Sensitivity_CyberCompliance_Reset",
      threshold_reset: "Threshold_CyberCompliance_Reset",
      increase_var: "UComplianceIncreaseNotification",
      decrease_var: "UComplianceDecreaseNotification",
      setNotification: "CyberComplianceNotification",
    },
    actioncenter: {
      mainHeader: "Action Centre Notifications",
      headerSens: "CyberCompliance Notifications Sensitivity",
      headerThres: "CyberCompliance Notifications Thresholds",
      variable: "action centre",
      dropdown1: "New Tasks",
      dropdown2: "Tasks overdue",
      dropdown3: "Task status change",
      dropdown4: "Task closed",
    },
  };

  const [ownerType, setOwnerType] = useState(0);
  const [notifSetting, setNotifSetting] = useState({});

  const [settingOwner, setSettingOwner] = useState("");

  const DropDownData = [
    { id: 0, category: "Disabled" },
    { id: 1, category: "Mobile App" },
    { id: 2, category: "Email" },
    { id: 3, category: "App and Email" },
  ];

  async function getNotificationSettings() {
    await getAPIData(103, authTokens).then((response) => {
      setLoading(false);
      if (response.mr.Success) {
        if (response.Notification_Settings || response.NotificationSettings) {
          const setting =
            response.Notification_Settings || response.NotificationSettings;

          setOwnerType(setting?.OwnerType);

          setSettingOwner(setting?.SettingOwner);
        }

        if (type == "cyberscore") {
          setIsVisible(response?.NotificationSettings?.NotificationCyberScore);
          setSenstivity(response.NotificationSettings.Sensitivity_CyberScore);
          setThreshold(response.NotificationSettings.Threshold_CyberScore);
          setSelected1(
            DropDownData.filter(
              (item) =>
                item.id ==
                response.NotificationSettings.NotificationIncreasing_CyberScore
            )[0]
          );
          setSelected2(
            DropDownData.filter(
              (item) =>
                item.id ==
                response.NotificationSettings.NotificationDecreasing_CyberScore
            )[0]
          );
        }
        if (type == "cybercompliance") {
          setIsVisible(
            response?.NotificationSettings?.NotificationCyberCompliance
          );
          setSenstivity(
            response.NotificationSettings.Sensitivity_CyberCompliance
          );
          setThreshold(response.NotificationSettings.Threshold_CyberCompliance);
          setSelected1(
            DropDownData.filter(
              (item) =>
                item.id ==
                response.NotificationSettings
                  .NotificationIncreasing_CyberCompliance
            )[0]
          );
          setSelected2(
            DropDownData.filter(
              (item) =>
                item.id ==
                response.NotificationSettings
                  .NotificationDecreasing_CyberCompliance
            )[0]
          );
        }
        if (type == "cyberthreat") {
          setIsVisible(response?.NotificationSettings?.NotificationCyberThreat);
          setSenstivity(response.NotificationSettings.Sensitivity_CyberThreat);
          setThreshold(response.NotificationSettings.Threshold_CyberThreat);
          setSelected1(
            DropDownData.filter(
              (item) =>
                item.id ==
                response.NotificationSettings.NotificationIncreasing_CyberThreat
            )[0]
          );
          setSelected2(
            DropDownData.filter(
              (item) =>
                item.id ==
                response.NotificationSettings.NotificationDecreasing_CyberThreat
            )[0]
          );
        }
      }
    });
  }

  const inputs = {
    SettingOwner: settingOwner,
    OwnerType: ownerType,
  };

  async function handleSave(notifName, notifValue) {
    await postData(authTokens, "SubmitNotificationSettings", {
      ...inputs,
      NotificationName: `U${notifName}`,
      NotificationValue: notifValue,
    })
      .then((response) => {
        if (response.Success) {
          CallToast("Updated successfully", false, false, "v2style");
        } else {
          CallToast("Something went wrong", false, false, "v2style");
        }
      })
      .catch((err) => {});
  }

  async function handleSave2(notifName, notifValue) {
    await postData(authTokens, "SubmitNotificationSettings", {
      SettingOwner: 0,
      OwnerType: 1,
      NotificationName: notifName,
      NotificationValue: notifValue,
    })
      .then((response) => {
        if (response.Success) {
          CallToast("Updated successfully", false, false, "v2style");
        } else {
          CallToast("Something went wrong", false, false, "v2style");
        }
      })
      .catch((err) => {});
  }

  // Action centre notification settings

  const DropdownOptions = [
    { id: 1, category: "Disabled" },
    { id: 2, category: "Mobile App" },
    { id: 3, category: "Email" },
    { id: 4, category: "App and Email" },
  ];

  function LoadActionCentrePageData() {
    getAPIData("241", authTokens).then((response) => {
      setIsVisible(!response[0]?.Hidden);
      setLoading(false);
      setIsVisible(response[0]?.SettingStatus);
      if (response?.length && response[0]?.ActionCenterSubSettings.length) {
        setSelected1(
          DropdownOptions.filter(
            (item) =>
              item.category ==
              response[0]?.ActionCenterSubSettings[0].SettingValue
          )[0]
        );

        setSelected2(
          DropdownOptions.filter(
            (item) =>
              item.category ==
              response[0]?.ActionCenterSubSettings[1].SettingValue
          )[0]
        );

        setSelected3(
          DropdownOptions.filter(
            (item) =>
              item.category ==
              response[0]?.ActionCenterSubSettings[2].SettingValue
          )[0]
        );

        setSelected4(
          DropdownOptions.filter(
            (item) =>
              item.category ==
              response[0]?.ActionCenterSubSettings[3].SettingValue
          )[0]
        );
      }
    });
  }

  function ResetActionCentreNotificationSettings() {
    getAPIData("242", authTokens).then((response) => {
      if (response.Success) {
        LoadActionCentrePageData();
        // CallToast("Updated successfully", false, false, "v2style");
      } else {
        CallToast("Something went wrong", false, false, "v2style");
      }
    });
  }

  function ResetUserNotificationSettings(num) {
    getAPIData("199", authTokens, { optionEnum1: num }).then((response) => {
      if (response.Success) {
        getNotificationSettings();
        // CallToast("Updated successfully", false, false, "v2style");
      } else {
        CallToast("Something went wrong", false, false, "v2style");
      }
    });
  }

  function handleIncreaseDecreaseValue(notificationName, notificationValue) {
    postRequest(authTokens, "SubmitNotificationSettings", {
      SettingOwner: 0,
      OwnerType: 1,
      NotificationName: notificationName,
      NotificationValue: notificationValue,
      accessKey: JSON.parse(localStorage.getItem("user_MessageObj")),
    }).then((response) => {
      if (response.Success) {
      } else {
        CallToast("Something went wrong", false, false, "v2style");
      }
    });
  }

  const handleActionCentreNotification = (name, value) => {
    postRequest(authTokens, "SubmitNotificationSettings", {
      SettingOwner: 0,
      OwnerType: 1,
      NotificationName: `${name}`,
      NotificationValue: value,
      accessKey: JSON.parse(localStorage.getItem("user_MessageObj")),
    })
      .then((response) => {
        if (response.Success) {
          CallToast("Updated successfully", false, false, "v2style");
        } else {
          CallToast("Something went wrong", false, false, "v2style");
        }
      })
      .catch((err) => {
        console.log(err);
        CallToast("Something went wrong", false, false, "v2style");
      });
  };

  return (
    <Modal
      animation={false}
      // show={true}
      show={show}
      dialogClassName="cyberscore-notification-modal"
      className=" "
      aria-labelledby="cyberscore-notification-modal"
      centered
      scrollable
      onHide={hideModal}
    >
      <Modal.Header className="text-center ">
        {page !== "home" && (
          <span
            className="cursor-pointer"
            onClick={() => {
              setPage("home");
            }}
          >
            <img src="/images/leftarrow.png" alt="" />
          </span>
        )}

        <div className="w-100 mb-0 font-bold-14" style={{ fontWeight: "600" }}>
          {page == "home" && ModalData[type]?.mainHeader}
          {page == "senstivity" && ModalData[type]?.headerSens}
          {page == "threshold" && ModalData[type]?.headerThres}
        </div>

        <span className="cursor-pointer" onClick={hideModal}>
          <img src="/images/icons/close.svg" alt="" />
        </span>
      </Modal.Header>
      <Modal.Body className="p-4">
        {loading && (
          <div
            style={{
              position: "absolute",
              top: "40%",
              left: 0,
              right: 0,
              margin: "auto",
            }}
          >
            <Loader />
          </div>
        )}
        {!loading && (
          <>
            {" "}
            {page == "home" && (
              <>
                <div className="title">
                  Choose how you would like to receive notifications about
                  your&nbsp;
                  {ModalData[type]?.variable}
                </div>
                <div className="d-flex justify-content-between mt-3 mb-2">
                  <div>
                    Allow notification about&nbsp;
                    {ModalData[type]?.variable}
                  </div>
                  <div>
                    <Form.Group
                      controlId={"cyberscore-notification-check"}
                      className=""
                    >
                      <Form.Check
                        type="switch"
                        id="cyberscore-notification-toggle"
                        className="m-0 scale-switch switch-shadow-none"
                        checked={isVisible}
                        onChange={(e) => {
                          setIsVisible(e.target.checked);
                          if (type == "actioncenter") {
                            handleSave2(
                              "NotificationActionCenter",
                              Number(e.target.checked)
                            );
                          } else {
                            handleSave2(
                              ModalData[type]?.setNotification,
                              Number(e.target.checked)
                            );
                          }
                        }}
                      />
                    </Form.Group>
                  </div>
                </div>

                {isVisible && (
                  <>
                    <div className="d-flex justify-content-between mt-3 mb-2">
                      <div className="title">{ModalData[type]?.dropdown1}</div>
                      <div className="score-modal-dropdown">
                        <DropdownField
                          fieldId={"id"}
                          fieldValue={"category"}
                          selected={selected1}
                          placeholder="App and Email"
                          //  showid={true}
                          onClick={(id, val) => {
                            if (type !== "actioncenter") {
                              handleIncreaseDecreaseValue(
                                ModalData[type]?.increase_var,
                                id
                              );
                            } else {
                              handleActionCentreNotification("NewTasks", val);
                            }

                            setSelected1({ id, category: val });
                          }}
                          data={[
                            { id: 0, category: "Disabled" },
                            { id: 1, category: "Mobile App" },
                            { id: 2, category: "Email" },
                            { id: 3, category: "App and Email" },
                          ]}
                        />
                      </div>
                    </div>
                    <div className="d-flex justify-content-between mt-3 mb-2">
                      <div className="title">{ModalData[type]?.dropdown2}</div>
                      <div className="score-modal-dropdown">
                        <DropdownField
                          fieldId={"id"}
                          fieldValue={"category"}
                          selected={selected2}
                          placeholder="App and Email"
                          //  showid={true}
                          onClick={(id, val) => {
                            if (type !== "actioncenter") {
                              handleIncreaseDecreaseValue(
                                ModalData[type]?.decrease_var,
                                id
                              );
                            } else {
                              handleActionCentreNotification(
                                "TaskOverdue",
                                val
                              );
                            }

                            setSelected2({ id, category: val });
                          }}
                          data={[
                            { id: 0, category: "Disabled" },
                            { id: 1, category: "Mobile App" },
                            { id: 2, category: "Email" },
                            { id: 3, category: "App and Email" },
                          ]}
                        />
                      </div>
                    </div>

                    {type == "actioncenter" && (
                      <>
                        <div className="d-flex justify-content-between mt-3 mb-2">
                          <div className="title">
                            {ModalData[type]?.dropdown3}
                          </div>
                          <div className="score-modal-dropdown">
                            <DropdownField
                              fieldId={"id"}
                              fieldValue={"category"}
                              selected={selected3}
                              placeholder="App and Email"
                              //  showid={true}
                              onClick={(id, val) => {
                                handleActionCentreNotification(
                                  "TaskStatusChange",
                                  val
                                );

                                setSelected3({ id, category: val });
                              }}
                              data={[
                                { id: 5, category: "Disabled" },
                                { id: 6, category: "Mobile App" },
                                { id: 7, category: "Email" },
                                { id: 8, category: "App and Email" },
                              ]}
                            />
                          </div>
                        </div>
                        <div className="d-flex justify-content-between mt-3 mb-2">
                          <div className="title">
                            {ModalData[type]?.dropdown4}
                          </div>
                          <div className="score-modal-dropdown">
                            <DropdownField
                              fieldId={"id"}
                              fieldValue={"category"}
                              selected={selected4}
                              placeholder="App and Email"
                              //  showid={true}
                              onClick={(id, val) => {
                                handleActionCentreNotification(
                                  "TaskClosed",
                                  val
                                );
                                setSelected4({ id, category: val });
                              }}
                              data={[
                                { id: 5, category: "Disabled" },
                                { id: 6, category: "Mobile App" },
                                { id: 7, category: "Email" },
                                { id: 8, category: "App and Email" },
                              ]}
                            />
                          </div>
                        </div>
                      </>
                    )}

                    {type !== "actioncenter" && (
                      <>
                        <div className="option-wrapper mt-3">
                          <Row className="m-0">
                            <Col
                              xs={12}
                              className="pointer"
                              onClick={() => setPage("senstivity")}
                            >
                              <div className="d-flex justify-content-between align-items-center p-3">
                                <div>
                                  <p className="title mb-2">Sensitivity</p>
                                  <p className="subtitle">
                                    Configure the notification sensitivity to
                                    control how much..
                                    {/* change occurs before you receive notifications about your
                  score increasing or decreasing. */}
                                  </p>
                                </div>
                                <img alt=""
                                  src="/images/chevron-right.svg"
                                  className="cursor-pointer"
                                />
                              </div>
                            </Col>
                            <hr className="m-0" />
                            <Col
                              xs={12}
                              className="pointer"
                              onClick={() => setPage("threshold")}
                            >
                              <div className="d-flex justify-content-between align-items-center p-3">
                                <div>
                                  <p className="title mb-2">Thresholds</p>
                                  <p className="subtitle">
                                    Configure your minimum notification
                                    thresholds for your...
                                    {/* CyberScore. If your scores drop below these threshold
                    values, you will receive a notification. */}
                                  </p>
                                </div>
                                <img alt=""
                                  src="/images/chevron-right.svg"
                                  className="cursor-pointer"
                                />
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </>
                    )}
                  </>
                )}
              </>
            )}
            {/* -----------Sensitivity------------------ */}
            {page == "senstivity" && (
              <>
                <div className="title mb-3 mt-3">
                  Your {ModalData[type]?.variable} change continually. Configure
                  the notification sensitivity to control how much change occurs
                  before you receive notifications about your score increasing
                  or decreasing.
                </div>
                <div>
                  <div className="d-flex justify-content-between meter-label mb-2">
                    <div style={{ width: "23%" }}>Low</div>
                    <div style={{ width: "27%" }}> Normal</div>
                    <div style={{ width: "18%" }}> High</div>
                    <div style={{ width: "12%" }}>Immediate</div>
                  </div>
                  <CustomSlider
                    min={1}
                    max={4}
                    step={1}
                    id="cyber-score-notification-slider"
                    onChange={(e) => {
                      setSenstivity(e.target.value);
                      console.log(e.target.value);
                    }}
                    defaultValue={senstivity}
                    onMouseUp={() => {
                      handleSave(
                        ModalData[type]["senstivity_handle"],
                        senstivity
                      );
                    }}
                  />
                </div>
              </>
            )}
            {/* Threshold slider */}
            {page == "threshold" && (
              <>
                <div className="title mb-3 mt-3">
                  Configure your minimum notification thresholds for your &nbsp;
                  {ModalData[type]?.variable}. If your scores drop below these
                  threshold values, you will receive a notification.
                </div>
                <div>
                  <div className="d-flex justify-content-between meter-label mb-2">
                    <div style={{ width: "3%" }}>0</div>
                    <div style={{ width: "4%" }}> 10</div>
                    <div> 20</div>
                    <div>30</div>
                    <div>40</div>
                    <div>50</div>
                    <div>60</div>
                    <div>70</div>
                    <div>80</div>
                    <div>90</div>
                    <div>100</div>
                  </div>
                  <CustomSlider
                    min={0}
                    max={100}
                    step={1}
                    id="cyber-score-notification-slider"
                    onChange={(e) => {
                      setThreshold(e.target.value);
                      console.log(e.target.value);
                    }}
                    defaultValue={threshold}
                    onMouseUp={() => {
                      handleSave(
                        ModalData[type]["threshold_handle"],
                        threshold
                      );
                    }}
                  />
                </div>
              </>
            )}
          </>
        )}
      </Modal.Body>

      <Modal.Footer>
        <div className="d-flex justify-content-between align-items-center">
          {isVisible && (
            <div
              className="reset-to-default pointer"
              onClick={() => {
                if (type == "actioncenter") {
                  ResetActionCentreNotificationSettings();
                } else {
                  if (page == "home") {
                    if (type == "cyberscore") {
                      ResetUserNotificationSettings(0);
                    }
                    if (type == "cyberthreat") {
                      ResetUserNotificationSettings(1);
                    }
                    if (type == "cybercompliance") {
                      ResetUserNotificationSettings(2);
                    }
                  }
                  if (page == "senstivity") {
                    handleSave(ModalData[type]?.["senstivity_reset"], 1);
                  }

                  if (page == "threshold") {
                    handleSave(ModalData[type]?.["threshold_reset"], 1);
                  }
                }
              }}
            >
              <img src="/images/refresh.png" className="mr-2" />
              <span>Reset</span>
            </div>
          )}
          <div>
            <Button className="cancel-btn d-none" onClick={hideModal}>
              Cancel
            </Button>
            <Button className="update-btn d-none" onClick={hideModal}>
              Update
            </Button>
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default CyberScoreNotificationModal;
