import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import ExternalLinkIcon from '../../../icons/external-link.icon';
import styles from './client-details.styles.module.scss';

const sections = [
  { title: 'User Issues', fieldKey: 'User_Issues' },
  { title: 'Device Issues', fieldKey: 'Device_Issues' },
  { title: 'License', fieldKey: 'License' },
  { title: 'Security Package', fieldKey: 'Security_Package' },
]

const ClientDetails = ({ clientDetails, links }) => {
  const history = useHistory();

  const onLinkClick = (link) => {
    if (!link) return;
    history.push(link);
  }

  return (
    <div className={`d-flex justify-content-between ${styles['client-details']}`}>
      {sections.map(({ title, fieldKey }) => (
        <div className={styles['section']} key={fieldKey}>
          <div className='d-flex align-items-center justify-content-between'>
            <h3 className={`${styles['title']} f-grey mb-0`}>
              {title}
            </h3>
            <div 
              className={styles['link-icon']}
              onClick={() => onLinkClick(links?.[fieldKey])}
            >
              <ExternalLinkIcon width="12px" height="12px" fill="#9499AA" />
            </div>
          </div>
          <p className={`${styles['value']} f-black`}>
            {clientDetails?.[fieldKey]}
          </p>
        </div>
      ))}
    </div>
  );
};

export default ClientDetails;
