import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Button, Dropdown } from "react-bootstrap";
import { getAPIData, postData, postJsonData } from "../../../../Utils/commonApi";
import { useAuth } from "../../../context/Auth";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import AccreditationsTrainingFrameworks from "./AccreditationsTrainingFrameworks";
import OperationalSecurityAssetsScreen from "../operationalSecurity/OperationalSecurityAssetsScreen";
import SecurityProcesses from "../securityProcesses/SecurityProcesses";
import AuditSummary from "./AuditSummary";
import { CallToast } from "../../../PriceCalculator/modals/CustomToast";


import "./audit.scss"
import { CustomDeleteModal, DeleteModal } from "../../../DeleteModal/DeleteModal";

const AuditHeader = () => {
    const history = useHistory();
    const location = useLocation();
    const { authTokens } = useAuth();
    const Type = new URLSearchParams(window.location.search).get("type")?.split('?')[0];

    const [processing, setProcessing] = useState(false);
    const [saving, setSaving] = useState(false);
    const [selectedHeaderTab, setSelectedHeaderTab] = useState({
        title: "Accreditations, Training & Frameworks",
        titleId: 1,
        titleUrl: 'AccreditationsTrainingFrameworks'
    });
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [addedUrlKey, setAddedUrlKey] = useState('');
    const [menuDropdown, setMenuDropdown] = useState(false)
    const [auditUpdateData, setAuditUpdateData] = useState({});
    const [printLoading, setPrintLoading] = useState(false)

    useEffect(() => {
        setSelectedHeaderTab(headersArr?.find((item) => item?.titleUrl === Type))
    }, [Type])

    async function deleteAudit(objectId) {
        await getAPIData(`${784}`, authTokens, {
            optionEnum1: objectId,
            optionEnum2: 0,
        })
            .then((response) => {
                if (response?.Success) {
                    CallToast("Deleted Successfully", false, false, "v2style");
                }
            })
            .catch((err) => {
                console.log(err);
                CallToast("Something went wrong", false, false, "v2style");
            });
    }

    async function printAudit() {
        await getAPIData(`${691}`, authTokens, {
            optionEnum1: Number(localStorage.getItem("auditId"))
        })
            .then((response) => {
                if (response?.mr?.Success) {
                    window.open(response?.FileURL);
                    setPrintLoading(false)
                }
            })
            .catch((err) => {
                console.log(err);
                CallToast("Something went wrong", false, false, "v2style");
            });
    }


    function formatDate(inputDate) {
        const date = new Date(inputDate);
        const day = date.getDate();
        const month = date.getMonth() + 1; // January is 0
        const year = date.getFullYear();

        // Ensure leading zero if day or month is single digit
        const formattedDay = day < 10 ? '0' + day : day;
        const formattedMonth = month < 10 ? '0' + month : month;

        return `${formattedDay}/${formattedMonth}/${year}`;
    }


    async function CompleteAudit() {
        return new Promise((resolve, reject) => {
            postData(authTokens, "CreateSecOpsAudit", {
                ObjectId: Number(localStorage.getItem("auditId")),
                AuditSummary: auditUpdateData?.auditSummary,
                StartDate: formatDate(auditUpdateData?.startDate),
                CompletionDate: formatDate(auditUpdateData?.completionDate),
                NextAuditDate: formatDate(auditUpdateData?.nextAuditDate),
                AccessKey: JSON.parse(localStorage.getItem("user_MessageObj"))
            })
                .then((response) => {
                    CallToast("Audit completed successfully", false, false, "v2style");
                })
                .catch((err) => {
                    console.log(err, "error in PSAGlobalConfig");
                    reject(false);
                });
        });
    }
    const [warningModalConfig, setWarningModalConfig] = useState(false);


    return (
        <div className="integration-header">
            <div
                className="w-100 bg-black d-flex align-items-center justify-content-between"
                style={{
                    position: "sticky",
                    top: "56px",
                    height: "50px",
                    borderTop: "1px solid rgba(255, 255, 255, 0.08)",
                    zIndex: 6,
                }}
            >
                <div
                    className="d-flex align-items-center f-white f-500 ml-3 pointer"
                    onClick={() => {
                        setWarningModalConfig(true);
                        // history.push("/mspv2/secops");
                    }}
                >
                    <img
                        alt=""
                        src="/images/msp/msp-white-cross.svg"
                        className="mr-3 pointer"
                    />
                    Audit
                </div>
                {/* Mapping Tabs List */}
                <div className="d-flex align-items-center">
                    {headersArr?.map((headerItem, headerItemIndex) => {
                        return (
                            <>
                                <div
                                    className={`mx-2 p-2 menuItem radius-2 ${selectedHeaderTab?.titleId != headerItem?.titleId ? "pointer" : "cursor-default"}   
                                        ${Type === headerItem?.titleUrl?.split("?")[0] ? "selectedMenuItem" : ""}   
                                    `}
                                    onClick={() => {
                                        if (selectedHeaderTab?.titleId != headerItem?.titleId) {
                                            setSelectedHeaderTab(headerItem);
                                            history.push(
                                                `/mspv2/audit?type=${headerItem?.titleUrl}`
                                            );
                                        }
                                    }}
                                >
                                    <span
                                        style={{ background: "#ffffff16" }}
                                        className="mr-2 f-12 f-500 p-2 rounded-full"
                                    >
                                        {headerItemIndex + 1}
                                    </span>
                                    {
                                        headerItem?.title
                                    }
                                </div>
                                {headerItemIndex < headersArr?.length - 1 && (
                                    <img
                                        alt=""
                                        className="mx-1"
                                        src="/images/msp/msp-greater-sign.svg"
                                    />
                                )}
                            </>
                        );
                    })}
                </div>

                <div className="mr-3">
                    {Type !== "Summary" && (
                        <Button
                            className="hg-transparent-btn mr-2"
                            onClick={() => {
                                history.push(
                                    `/mspv2/audit?type=${headersArr[selectedHeaderTab?.titleId]?.titleUrl}`
                                );
                            }}
                        >
                            {saving ? "Please wait..." : "Skip"}
                        </Button>
                    )}
                    {
                        Type !== "Summary" ?
                            <Button
                                disabled={processing}
                                className="hg-blue-btn"
                                onClick={() => {
                                    history.push(
                                        `/mspv2/audit?type=${headersArr[selectedHeaderTab?.titleId]?.titleUrl}`
                                    );
                                }}
                            >
                                {processing
                                    ? "Please wait.."
                                    : "Continue"}
                            </Button>
                            :
                            <div className="d-flex justify-content-end ">
                                <Button
                                    disabled={processing}
                                    className="hg-blue-btn"
                                    onClick={() => {
                                        CompleteAudit();
                                        history.push("/mspv2/secops");
                                    }}
                                >
                                    Complete
                                </Button>
                                <div className="dotsMenu">
                                    <Dropdown
                                        className="hg-this-dropdown"
                                        onToggle={() => {
                                            setMenuDropdown(true)
                                        }}
                                    >
                                        <Dropdown.Toggle className="test-userflow">
                                            <img
                                                alt=""
                                                className="mx-2 pointer"
                                                src="/images/msp/white-horizontal-dots.svg"
                                            />
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <div className="ml-3 py-1 pointer"
                                                style={{
                                                    color: "#616778"
                                                }}
                                                onClick={(e) => {
                                                    e.target.closest(".hg-this-dropdown").click();
                                                    setPrintLoading(true)
                                                    printAudit();
                                                }}
                                            >
                                                <div className="d-flex align-items-center">
                                                    {
                                                        printLoading ? <div className="msp-service-update-loader ml-2 mr-1"></div> : <></>
                                                    }
                                                    Print
                                                </div>
                                            </div>
                                            <div className="ml-3 py-1 pointer"
                                                style={{
                                                    color: "#616778"
                                                }}
                                                onClick={() => {
                                                    history.push("/mspv2/secops")
                                                }}
                                            >
                                                Save and exit
                                            </div>
                                            <div className="ml-3 py-1 pointer"
                                                style={{
                                                    color: "#F36060"
                                                }}
                                                onClick={() => {
                                                    setShowDeleteModal(true);
                                                }}
                                            >
                                                Delete
                                            </div>

                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                            </div>
                    }
                </div>
            </div>


            {Type === "AccreditationsTrainingFrameworks" && (
                <AccreditationsTrainingFrameworks
                    isAudit={true}
                />
            )}
            {Type === "OperationalSecurity" && (
                <OperationalSecurityAssetsScreen
                    isAudit={true}
                    setAddedUrlKey={setAddedUrlKey}
                />
            )}
            {Type === "SecurityProcessesProcedures" && (
                <SecurityProcesses
                    isAudit={true}
                    setAddedUrlKey={setAddedUrlKey}
                />
            )}
            {Type === "Summary" && (
                <AuditSummary
                    auditId={Number(localStorage.getItem("auditId"))}
                    setAuditUpdateData={setAuditUpdateData}
                    auditUpdateData={auditUpdateData}

                />
            )}
            <DeleteModal
                show={showDeleteModal}
                hideModal={() => setShowDeleteModal(false)}
                deleteMessage={"You are about to delete an audit."}
                deleteType={"deleteSentQuestionnaire"}
                deleteSentQuestionnaire={() => {
                    deleteAudit(Number(localStorage.getItem("auditId")));
                    history.push("/mspv2/secops");
                }}
            />
            <CustomDeleteModal
                show={warningModalConfig}
                deleteHeaderIcon={"/images/msp/yellow-exclammation.svg"}
                hideModal={() => {
                    setWarningModalConfig(false);
                }}
                cancelButtonTitle={"Exit"}
                cancelButtonFunction={() => {
                    history.push("/mspv2/secops")
                }}
                deleteButtonClass={"hg-blue-btn"}
                deleteButtonText={'Save'}
                deleteHeaderText={"Warning"}
                deleteBodyText={
                    "You have unsaved changes."
                }
                deleteFooterText={
                    "Do you want to save your changes before exit?"
                }
                deleteFunction={() => {
                    // CompleteAudit();
                    history.push("/mspv2/secops")
                }}
                deleteParams={{}}
                deleteType={"warningClose"}
            />
        </div>
    );
};

export default AuditHeader;

const headersArr = [
    {
        title: "Accreditations, Training & Frameworks",
        titleId: 1,
        titleUrl: 'AccreditationsTrainingFrameworks'
    },
    {
        title: "MSP Operational Security",
        titleId: 2,
        titleUrl: 'OperationalSecurity?selectedSideBarTab=4010934'
        // titleUrl: 'OperationalSecurity'
    },
    {
        title: "MSP Security Processes & Procedures",
        titleId: 3,
        titleUrl: 'SecurityProcessesProcedures?selectedSideBarTab=4010858'
        // titleUrl: 'SecurityProcessesProcedures'
    },
    {
        title: "Summary",
        titleId: 4,
        titleUrl: 'Summary'
    },

]

export async function auditUpdate(authTokens, updatedObjId, updateAuditEnum, updateNewAuditStatusEnum, updateOldAuditStatusEnum, updatedAuditObjectId) {
    await postData(authTokens, "UpdateSecOpsAudit", {
        ObjId_Audit: Number(localStorage.getItem("auditId")),
        UpdatedObjectId: updatedObjId,
        MSPSecOpsUpdateAuditEnum: updateAuditEnum,
        MSPSecOpsUpdateNewAuditStatusEnum: updateNewAuditStatusEnum,
        MSPSecOpsUpdateOldAuditStatusEnum: updateOldAuditStatusEnum,
        AccessKey: JSON.parse(localStorage.getItem("user_MessageObj")),
        UpdatedAuditObjectId: updatedAuditObjectId
    })
        .then((response) => {
            if (response?.Success) {
                // CallToast('Audit updated successfully', false, false, "v2style");
            } else {
                CallToast("Something went wrong", false, false, "v2style");
            }
        })
        .catch((err) => {
            console.log(err);
            CallToast("Something went wrong", false, false, "v2style");
        });
}
