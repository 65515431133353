import React from "react";
import ContentLoader from "react-content-loader";

const RewardsDashboardDropdownLoader = () => {
  return (
    <ContentLoader
      speed={2}
      width={"100%"}
      height={"100%"}
      viewBox="0 0 1500 900"
      backgroundColor="#F6F8FB"
      foregroundColor="#ecebeb"
    >
      <rect x="0%" y="0" rx="4" ry="4" width="100%" height="100" />
      <rect x="0%" y="102" rx="4" ry="4" width="100%" height="100" />
      <rect x="0%" y="204" rx="4" ry="4" width="100%" height="100" />
      <rect x="0%" y="306" rx="4" ry="4" width="100%" height="100" />
      <rect x="0%" y="408" rx="4" ry="4" width="100%" height="100" />
      <rect x="0%" y="510" rx="4" ry="4" width="100%" height="100" />
      <rect x="0%" y="612" rx="4" ry="4" width="100%" height="100" />
      <rect x="0%" y="714" rx="4" ry="4" width="100%" height="100" />
    </ContentLoader>
  );
};

export default RewardsDashboardDropdownLoader;
