import React from "react";
import ContentLoader from "react-content-loader";

const NotificationsV2Loader = () => {
  return (
    <ContentLoader
      speed={2}
      width={"100%"}
      height={"100%"}
      viewBox="0 0 1500 900"
      backgroundColor="#F6F8FB"
      foregroundColor="#ecebeb"
    >
      <rect x="17%" y="40" rx="4" ry="4" width="10%" height="20" />
      <rect x="16%" y="80" rx="4" ry="4" width="67%" height="55" />
      <rect x="16%" y="137" rx="4" ry="4" width="67%" height="80" />
      <rect x="16%" y="219" rx="4" ry="4" width="67%" height="80" />
      <rect x="16%" y="301" rx="4" ry="4" width="67%" height="80" />
      <rect x="16%" y="383" rx="4" ry="4" width="67%" height="80" />
      <rect x="16%" y="465" rx="4" ry="4" width="67%" height="80" />
    </ContentLoader>
  );
};

export default NotificationsV2Loader;
