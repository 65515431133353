import React, { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { PackageListRadarUI } from "./MspClientPriceCalculator";
import { getAPIData } from "../../../Utils/commonApi";
import { useAuth } from "../../context/Auth";
import { CallToast } from "../../PriceCalculator/modals/CustomToast";

export const PrintPackageModal = ({
    show,
    pageData,
    clientId,
    hideModal
}) => {
    const { authTokens } = useAuth();
    const [selectedPackages, setSelectedPackages] = useState([])

    async function printPackage() {
        await getAPIData(683, authTokens, {
            optionEnum2: clientId,
            optionStrEnum1: selectedPackages.join(',')
        }).then((response) => {
            if (response?.mr?.Success) {
                window.open(response?.FileURL, '_blank');
            } else {
                CallToast("Something went wrong", false, false, "v2style");
            }
        });
    }

    const addOrRemoveFromArray = (itemId, isChecked) => {
        if (isChecked) {
            setSelectedPackages(prevState => [...prevState, itemId]);
        } else {
            setSelectedPackages(prevState => prevState.filter(item => item !== itemId));
        }
    };

    const graphColors = [
        "#008FD4",
        "#5244B3",
        "#40D88E",
        "#F36060",
        "#DF6844",
        "#99D2EE",
        "#BAB4E1",
        "#B3EFD2",
        "#FABFBF",
        "#F2C3B4",
    ];

    return (
        <Modal
            animation={false}
            show={show}

            dialogClassName="print-package-modal"
            className=" "
            aria-labelledby="print-package-modal"
            centered
            onHide={() => {
                hideModal();
                setSelectedPackages([])
            }}
        >
            <Modal.Header>
            </Modal.Header>
            <Modal.Body>
                <div>
                    <div className="">
                        <div className="f-darkgrey f-400 f-12">Print scenarios:</div>
                        <div className="scroll">

                            {pageData?.ScenarioHheaderList?.map((_package, _packageIndex) => {
                                return (
                                    <div
                                        className="content-block border-item d-flex align-items-center mr-1 justify-content-between bg-hover-dark-grey hover-on-show-parent"
                                    >
                                        <div className="d-flex align-items-center">
                                            <div
                                                className="msp-package-list-checkbox position-relative   w-fit-content mr-4"
                                                style={{ height: "20px" }}
                                            >
                                                <Form.Group
                                                    controlId={"check" + _package.objId_Scenario}
                                                    className="round-checkbox blue-checkbox checkboxstyle"
                                                >
                                                    <Form.Check
                                                        type="checkbox"
                                                        name={"check" + _package.objId_Scenario}
                                                        label=""
                                                        checked={selectedPackages.includes(_package.objId_Scenario)}
                                                        onChange={(e) => {
                                                            const isChecked = e.target.checked;
                                                            addOrRemoveFromArray(_package.objId_Scenario, isChecked);
                                                        }}
                                                    />
                                                </Form.Group>
                                            </div>
                                            <div className="d-flex align-items-center">
                                                <div
                                                    className="rounded-full mx-2"
                                                    style={{
                                                        width: "8px",
                                                        height: "8px",
                                                        backgroundColor: graphColors[_packageIndex % graphColors.length],
                                                    }}
                                                />
                                                <div>
                                                    <div className="f-500 f-14">{_package?.Name} </div>
                                                    <div
                                                        style={{
                                                            color: '#008FD4'
                                                        }}>{_package?.MonthlyPrice_Text}
                                                    </div>
                                                </div>

                                            </div>
                                        </div>

                                        {/* Radar Chart */}
                                        <div className="mr-2">
                                            <PackageListRadarUI
                                                dataList={[
                                                    _package?.InspectScore,
                                                    _package?.ProtectScore,
                                                    _package?.DetectScore,
                                                    _package?.RespondScore,
                                                    _package?.RecoverScore,
                                                ]}
                                            />
                                        </div>
                                    </div>
                                );
                            })
                            }
                        </div>
                    </div>
                </div>
            </Modal.Body>

            <Modal.Footer>
                <div className="d-flex align-items-center justify-content-end "
                >
                    <Button
                        className="hg-cancel-btn mr-2"
                        onClick={() => {
                            hideModal();
                            setSelectedPackages([])
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        className="hg-blue-btn"
                        onClick={() => {
                            printPackage()
                            hideModal();
                            setSelectedPackages([])
                        }}
                    >
                        Print
                    </Button>
                </div>
            </Modal.Footer>
        </Modal>
    );
};

export default PrintPackageModal;
