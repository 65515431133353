import React, { useState } from 'react';
import './risky-user.styles.scss';

const mockData = [
  { Title: 'Detection type', Value: 'Unfamiliar sign-in properties' },
  { Title: 'Detection time', Value: '6/7/2024 16:09:40' },
  { Title: 'Last updated', Value: '6/7/2024 16:09:40' },
  { Title: 'Risk state', Value: 'At risk' },
  { Title: 'IP Address', Value: '192.168.0.1' },
  { Title: 'Location', Value: 'Atlanta, GA' },
];

const RiskyUserTooltip = ({ children, item }) => {
  const [isHovered, setIsHovered] = useState(false);
  return (
    <div 
      className='risky-user-tooltip' 
      onMouseLeave={() => setIsHovered(false)}
    >
      <div 
        className='d-flex anchor'
        onMouseEnter={() => setIsHovered(true)}
        
      >
        {children}
      </div>
      {isHovered && (
        <div className='menu'>
          <div className='header'>
            <img
              src="/images/user-logo.svg"
              alt=""
              width={32}
              height={32}
            />
            <div className='name-email-container'>
              <h3 className='name m-0 f-black f-500'>
                {item.Name}
              </h3>
              <h4 className='email m-0 f-darkgrey f-500'>
                {item.Email}
              </h4>
            </div>
          </div>
          <div className='body'>
            <h3 className='f-12 f-darkgrey f-500 m-0'>Details</h3>
            <div className='list'>
              {mockData.map((data, index) => (
                <div key={index} className='item'>
                  <h4 className='title m-0 f-grey'>{data.Title}</h4>
                  <h3 className='value m-0 f-black'>{data.Value}</h3>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default RiskyUserTooltip;
