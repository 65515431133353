import React, { useState } from "react";
import ImageWithInitials from "../../../Utils/ImageWithInitials";
import "../supplier-profiler.scss";
import moment from "moment";


const SecurityFrameworksBlock = ({ title, setShowFrameworkModal, mode }) => {
  // const [updateState, setUpdateState] =useState(false);

  return (
    <div
      className="d-flex justify-content-center"
      style={{ width: "1024px", padding: "24px" }}
    >
      <div className="d-flex flex-column justify-content-center align-items-center w-100">
        <div
          className="d-flex flex-row w-100 justify-space-between header mb-2"
          style={{ justifyContent: "space-between" }}
        >
          <div className="title">
            <div className="f-16">{title.FieldName}</div>
            <div className="f-darkgrey">{title.FieldSubtitle}</div>
          </div>
        </div>

        <div className=" w-100 grid-container">
          {/* <div className="flex-row p-6px file-blocks f-darkgrey" onClick={()=>{setShowFrameworkModal(true); setUpdateState(!updateState)}}>
                      <img
                            src={"/images/supply-chain/darkgrey-add-base-icon.svg"}
                            className="mr-2 text-icon "
                            alt=""
                          />
                          <div className="">Add Framework</div>
                        </div>  */}
          {mode == 'Data Privacy' ? title.DataPrivacyFrameworks?.FrameworkList?.map((f, ind) => {
            return (
              <div className="flex-row p-6px framework-block">
                <div className="d-flex flex-column">
                  <div>{f.Abbreviation}</div>
                  <div className="f-darkgrey text-ellipsis">{f.Name}</div>
                </div>
                <div className="d-flex flex-column f-darkgrey">
                  <div className="">
                    Last Audit:{" "}
                    {f.CyberFrameworkAudit?.LastAudit
                      ? moment(
                          f?.CyberFrameworkAudit?.LastAudit?.split(
                            " "
                          )?.[0],
                          "DD/MM/YYYY"
                        ).format("DD MMM YYYY")
                      : "NA"}
                  </div>
                  <div className="">
                    Next Audit:{" "}
                    {f.CyberFrameworkAudit?.NextAudit
                      ? moment(
                          f?.CyberFrameworkAudit?.LastAudit?.split(
                            " "
                          )?.[0],
                          "DD/MM/YYYY"
                        ).format("DD MMM YYYY")
                      : "NA"}
                  </div>
                </div>
              </div>
            );
          }) : title.SecurityFrameworks?.FrameworkList?.map((f, ind) => {
            return (
              <div className="flex-row p-6px framework-block">
                <div className="d-flex flex-column">
                  <div>{f.Abbreviation}</div>
                  <div className="f-darkgrey text-ellipsis">{f.Name}</div>
                </div>
                <div className="d-flex flex-column f-darkgrey">
                  <div className="">
                    Last Audit:{" "}
                    {f.CyberFrameworkAudit?.LastAudit
                      ? moment(
                          f?.CyberFrameworkAudit?.LastAudit?.split(
                            " "
                          )?.[0],
                          "DD/MM/YYYY"
                        ).format("DD MMM YYYY")
                      : "NA"}
                  </div>
                  <div className="">
                    Next Audit:{" "}
                    {f.CyberFrameworkAudit?.NextAudit
                      ? moment(
                          f?.CyberFrameworkAudit?.LastAudit?.split(
                            " "
                          )?.[0],
                          "DD/MM/YYYY"
                        ).format("DD MMM YYYY")
                      : "NA"}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default SecurityFrameworksBlock;
