import React, { useState, useEffect } from "react";
import { Dropdown } from "react-bootstrap";
import { TrimString } from "../../../Utils/StringTrimmer";
import { getAPIData } from "../../../Utils/commonApi";
import { useAuth } from "../../context/Auth";
import { CallToast } from "../../PriceCalculator/modals/CustomToast";
import "./membersAccess.scss"

const MultiSelectDropdown = (props) => {
  const { locked, disabled, placeholder, placeholderIcon, textBlock, isFromMyProfile, passedOptionEnum98, passedOptionEnum99, mspClientId } = props;
  const [data, setData] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [selectedOptions, setSelectedOptions] = useState([]);
  const { authTokens, setAuthTokens } = useAuth();
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  useEffect(() => {
    if(textBlock && textBlock !== true) {
    getSaaSUsers(inputValue);
    }
  }, [inputValue]);

  async function getSaaSUsers(searchValue) {
       await getAPIData("554", authTokens, isFromMyProfile ? {
      optionStrEnum1: searchValue,
      optionEnum1: 20,
      accessKey: JSON.parse(localStorage.getItem("user_MessageObj"))
       } : passedOptionEnum98 && passedOptionEnum99 ? {
        optionStrEnum1: searchValue,
        optionEnum1: 20,
        optionEnum2: mspClientId,
        optionEnum98: passedOptionEnum98,
        optionEnum99: passedOptionEnum99
      } : {
      optionStrEnum1: searchValue,
      optionEnum99: 42,
      optionEnum1: 20,
    }).then((response) => {
      if (response.mr?.Success) {
        setData(response?.users);
      } else {
        // CallToast("Something went wrong", false, false, "v2style");
      }
    });
  }

useEffect(() => {
  if (inputValue !== '' && textBlock) {
      const re =
        /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
      if (!inputValue || !re.test(inputValue)) {
        setIsValidEmail(false);
      }
      else {
        setIsValidEmail(true);
      }
      if (inputValue && !re.test(inputValue)) {
        setIsValidEmail(false);
      } else {
        setIsValidEmail(true);
      }
    }
  }, [inputValue])

  useEffect(() => {
    setSelectedOptions(
      props.selected === null || props.selected === "" ? [] : props.selected
    );
    if (typeof props.selected === "object" && props.selected?.length === 0) {
      setInputValue("");
    }
  }, [props.selected]);

  return (
    <Dropdown
      className="members-multiselect-dropdown"
      disabled={locked}
      style={{
        pointerEvents: `${locked || disabled ? "none" : "all"}`,
      }}

    >
      <Dropdown.Toggle 
        className={`${props.isError ? "input-error" : ""}  `}
        onToggle={() => {setIsDropdownOpen(true);}}
      >
        <div className="d-flex flex-column">
        <div className="d-flex flex-wrap">

          {selectedOptions &&
            selectedOptions?.map((selected, selectedIndex) => {
              return (
                <div
                  className="f-12 f-500 f-grey d-flex align-items-center p-2 mr-1 mb-1  bg-grey"
                  title={selected?.fullName}
                >
                  <span className="d-inline-flex">
                    {selected?.fullName ? TrimString(selected?.fullName, 20) : TrimString(selected?.userName, 20)}
                  </span>

                  <img
                    alt=""
                    src="/images/attack-surface/grey-cross-icon.svg"
                    className={`ml-1 pointer ${locked ? "d-none" : ""}`}
                    onClick={(e) => {
                      e.stopPropagation();
                      let updated_data = [...selectedOptions];
                      let Index = updated_data.findIndex(
                        (item) =>
                          item?.userName === selected?.userName
                      );
                      updated_data.splice(Index, 1);
                      setSelectedOptions(updated_data);
                      props.onChange(updated_data);
                    }}
                  />
                </div>
              );
            })} 
          </div>
          <div className="d-flex flex-row align-items-center">
            {placeholderIcon}
          <input
            tabIndex={-1}
            placeholder={placeholder ? placeholder: "Add"}
            type="text"
            className={`border-none w-100 h-100 ${locked ? "d-none-" : ""} `}
            style={{
              pointerEvents: `${locked ? "none" : "all"}`,
            }}
            value={inputValue}
            onChange={(e) => {
              setInputValue(e.target.value);
              setIsDropdownOpen(true);
              e.stopPropagation();
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter" && inputValue.trim() !== "") {
                e.preventDefault();
                let selected_list = [...selectedOptions];
                selected_list.push({userName: inputValue, objId_PSAMember: 0, objId_PSAContact: 0});
                setSelectedOptions(selected_list);
                props.onChange(selected_list);
                setInputValue("")
              }
              e.stopPropagation();
            }}
          />
          </div>
        </div>
      </Dropdown.Toggle>
      <Dropdown.Menu
        className="hide-scrollbar"
        style={{ maxHeight: "300px", overflow: "auto" }}
      >
        {
            textBlock ? (
                inputValue !== '' && isValidEmail ? (
                <div 
                  className="addMemberAddEmail"
                  onClick={(e) => {
                    e.preventDefault();
                    let selected_list = [...selectedOptions];
                    selected_list.push({userName: inputValue, objId_PSAMember: 0, objId_PSAContact: 0});
                    setSelectedOptions(selected_list);
                    props.onChange(selected_list);
                    setInputValue("")
                    e.stopPropagation();
                  }}
                >
                    <div className="addEmailImgWrapper">
                        <img
                           alt=""
                           width={20}
                           className="mb-1 mr-2"
                           src="/images/supply-chain/add-base-icon.svg"
                        />
                    </div>
                    <div className="addEmailTextWrapper">
                        <p className="emailP">{inputValue}</p>
                        <p>Add this email</p>
                    </div>
                </div>
                ) : (
                <div
                    className="addMemberTextBlock"
                >
                     <img
                        alt=""
                        width={20}
                        className="mb-1 mr-2"
                        src="/images/info.svg"
                      />
                    <p>Enter valid email with dedicated business domain name.</p>
                </div>
                )
            ) :
        (
        <div className="px-2">
          { 
          inputValue !== '' && isValidEmail ? (
            data && data.length !== 0 ? (
            data
              ?.filter((filterItem) => {
                return filterItem?.fullName
                  ?.toLowerCase()
                  .includes(inputValue?.toLowerCase()) || filterItem?.userName
                  ?.toLowerCase()
                  .includes(inputValue?.toLowerCase());
              })
              ?.map((item, itemIndex) => {
                return (
                  <div
                    className={`${
                      selectedOptions?.filter(
                        (Item) => Item?.userName === item.userName
                      )?.length > 0
                        ? "f-black"
                        : "f-grey"
                    } 
                        
                        ${item.fullName === "" ? "d-none" : "d-flex"}
                        
                        f-500 f-15 pointer py-1 ml-2  align-items-center justify-content-between`}
                    onClick={(e) => {
                      if (
                        selectedOptions?.filter(
                          (Item) =>
                            Item?.userName === item.userName
                        )?.length === 0
                      ) {
                        let selected_list = [...selectedOptions];
                        selected_list.push(item);
                        setSelectedOptions(selected_list);
                        props.onChange(selected_list);
                        setInputValue("");
                      } else {
                      }
                    }}
                  >
                     <div className="ml-2">
                  <div className="f-500 f-grey">{item.fullName}</div>
                  <div className="f-12 f-darkgrey">{TrimString(item.userName,30)}</div>
                </div>
                    {selectedOptions?.filter(
                      (Item) => Item?.userName === item.userName
                    )?.length > 0 && (
                      <img
                        alt=""
                        width={11}
                        className="mb-1 mr-2"
                        src="/images/settings-v2/black-tick.svg"
                      />
                    )}
                  </div>
                );
              })) : (
                <div className="f-500 f-15 f-darkgrey">
                  <span>No result for the "{inputValue}" request</span>
                </div>
              )) : (
              <div className="f-500 f-15 f-darkgrey">
                <span>Enter user name or email to search</span>
              </div>)}
        </div>
        )}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default MultiSelectDropdown;
