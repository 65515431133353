import React, { useState, useEffect } from "react";
import { getAPIData, getTicketNotes } from "../../../Utils/commonApi";
import { TableElement } from "../../../Utils/utilityJsxElements";
import { useAuth } from "../../context/Auth";
import IRNotesChatroom from "../../IncidentResponse/IRNotesChatroom/IRNotesChatroom";

import ContentLoader from "react-content-loader";
import { BsFileEarmarkText } from "react-icons/bs";
import "./TicketsView.scss";
import { TrackUser } from "../../../Utils/SegmentFunctions";
import { useDispatch } from "react-redux";
import { setsegment } from "../../../redux/segmentEventStatus/actions";

const TicketsView = (props) => {
  //Singleton / Context Value(s)
  const { authTokens } = useAuth();
  const dispatch = useDispatch();
  //Callback Hooks
  const CallbackMethod = props.CallbackMethod;

  //States
  const [tickets, setTickets] = useState();
  const [activeTicket, setActiveTicket] = useState("All");

  //State 3 Variables
  const [ticketCommentsForm, setTicketCommentsForm] = useState();
  const [currentId, setCurrentId] = useState();

  //Constants
  const ticketTypes = ["All", "Open", "Closed"];
  const ticketColours = {
    Open: "#40D88E",
    Closed: "#B6B6BF",
    Link: "#008FD4",
  };

  useEffect(() => {
    initaliseTickets();
  }, []);

  /*
        id - EntityId
        status - incidentStatus
        handler - incidentHandler
        type - incidentType
        category - active/closed 
    */
  const ticketObj = (
    id,
    status,
    handler,
    type,
    name,
    commentsCount,
    date,
    bizObjectId
  ) => {
    return {
      bizObjectId: bizObjectId,
      id: id,
      status: status,
      handler: handler,
      type: type,
      name: name,
      commentsCount: commentsCount,
      date: date,
    };
  };

  async function initaliseTicketCommentsForm(id, bizObjectId, title) {
    const data = await getTicketNotes(authTokens, bizObjectId, id);
    // updateModalHeaderAndTitle(title, title)
    setCurrentId(id);
    setTicketCommentsForm(
      <IRNotesChatroom
        currentId={id}
        title={title}
        bizObjectId={bizObjectId}
        allTicketIds={[10, 12, 13, 14, id]}
        notes={data.cwTicketNotes}
      />
    );
  }

  useEffect(() => {
    if (ticketCommentsForm) CallbackMethod(3, `Support Tickets #${currentId}`);
    else CallbackMethod(1);
  }, [ticketCommentsForm]);

  async function initaliseTickets() {
    const tempTicketsResposne = await getAPIData("11", authTokens);

    const tempTicketsArr = [];
    if (tempTicketsResposne.mr.Success) {
      if (tempTicketsResposne.ActiveTableData != null) {
        for (const activeTicket of tempTicketsResposne.ActiveTableData) {
          if (activeTicket.EntityId) {
            tempTicketsArr.push(
              ticketObj(
                activeTicket.EntityId,
                "Open",
                activeTicket.Col3,
                activeTicket.Col2,
                `Support Ticket #${activeTicket.EntityId}`,
                activeTicket.Col6,
                activeTicket.Col5,
                activeTicket.Col7
              )
            );
          }
        }
      } else {
        tempTicketsArr.push(ticketObj(null, "Open"));
      }

      if (tempTicketsResposne.ClosedTableData != null) {
        for (const closedTicket of tempTicketsResposne.ClosedTableData) {
          if (closedTicket.EntityId) {
            tempTicketsArr.push(
              ticketObj(
                closedTicket.EntityId,
                "Closed",
                closedTicket.Col3,
                closedTicket.Col2,
                `Support Ticket #${closedTicket.EntityId}`,
                closedTicket.Col6,
                closedTicket.Col5,
                closedTicket.Col7
              )
            );
          }
        }
      } else {
        tempTicketsArr.push(ticketObj(null, "Closed"));
      }
    }
    setTickets(tempTicketsArr);
  }

  if (ticketCommentsForm) return ticketCommentsForm;
  return (
    <React.Fragment>
      <div>
        <div
          className="knowlegeBasePanel"
          onClick={() => {
            TrackUser("Knowledge Base clicked");
            window.open("https://kb.highground.io", "_blank");
          }}
        >
          <div className="d-flex justify-content-between">
            <h5>
              {" "}
              <span style={{ marginRight: "20px" }}>
                <BsFileEarmarkText />
              </span>
              Knowledgebase
            </h5>
            <img alt="" src="/images/dashboard/MenuCollapseActive.svg" />
          </div>
          <p style={{ fontWeight: "500" }}>
            Have you tried seaching our Knowledgebase yet? We have answers,
            guides and instructions which answer the most common questions our
            users have{" "}
          </p>
        </div>

        <div className="TicketsView mt-3">
          <div className="TicketOptions">
            {ticketTypes.map((element) => (
              <p
                className={`cursor-pointer ${
                  element === activeTicket ? "activeTicket" : ""
                }`}
                onClick={() => setActiveTicket(element)}
              >
                {element}
              </p>
            ))}
          </div>

          <div className="TicketCards">
            {tickets !== undefined && tickets.length > 0
              ? tickets
                  .filter(
                    (element) =>
                      element.status.toLowerCase() ==
                        activeTicket.toLowerCase() || activeTicket === "All"
                  )
                  .map((element) =>
                    (element.id == null &&
                      element.status == "Closed" &&
                      activeTicket == "Closed") ||
                    (element.id == null &&
                      element.status == "Open" &&
                      activeTicket == "All") ||
                    (element.id == null &&
                      element.status == "Open" &&
                      activeTicket == "Open")
                      ? "You have no Support Tickets to display"
                      : element.id !== null &&
                        (element.status == "Closed" ||
                          element.status == "Open" ||
                          element.status == "All") &&
                        (activeTicket == "Closed" ||
                          activeTicket == "Open" ||
                          activeTicket == "All") && (
                          <div
                            className="tableContainer"
                            style={{
                              cursor: "pointer",
                              pointerEvents:
                                element.bizObjectId == null && "none",
                            }}
                            onClick={() => {
                              TrackUser("Support ticket clicked");
                              dispatch(
                                setsegment({ insideSupportTicket: true })
                              );
                              initaliseTicketCommentsForm(
                                element.id,
                                element.bizObjectId,
                                element.id
                              );
                            }}
                          >
                            {TableElement(
                              element.date,
                              element.name,
                              undefined,
                              ticketColours["Link"]
                            )}
                            {TableElement("Support Handler", element.handler)}
                            {TableElement(
                              "Status",
                              element.status,
                              undefined,
                              ticketColours[element.status]
                            )}
                            <span className="tableMessageContainer">
                              <img alt="" src="/images/icons/Message.svg" />
                              <p>{element.commentsCount}</p>
                            </span>
                          </div>
                        )
                  )
              : [...Array(5)].map((elementInArray, index) => (
                  <div className="tableContainer">
                    <td>
                      <ContentLoader
                        speed={2}
                        width={88}
                        height={25}
                        viewBox="0 0 88 25"
                        // backgroundColor="#f3f3f3"
                        // foregroundColor="#ecebeb"
                        {...props}
                      >
                        <rect
                          x="24"
                          y="3"
                          rx="3"
                          ry="3"
                          width="88"
                          height="6"
                        />
                        <circle cx="11" cy="11" r="11" />
                        <rect
                          x="27"
                          y="17"
                          rx="3"
                          ry="3"
                          width="64"
                          height="4"
                        />
                      </ContentLoader>
                    </td>
                    <td>
                      <ContentLoader
                        speed={2}
                        width={88}
                        height={25}
                        viewBox="0 0 88 25"
                        // backgroundColor="#f3f3f3"
                        // foregroundColor="#ecebeb"
                        {...props}
                      >
                        <rect
                          x="24"
                          y="3"
                          rx="3"
                          ry="3"
                          width="84"
                          height="6"
                        />
                        {/* <circle cx="11" cy="11" r="11" /> */}
                        <rect
                          x="27"
                          y="17"
                          rx="3"
                          ry="3"
                          width="64"
                          height="4"
                        />
                      </ContentLoader>
                    </td>
                    <td>
                      <ContentLoader
                        speed={2}
                        width={88}
                        height={25}
                        viewBox="0 0 88 25"
                        // backgroundColor="#f3f3f3"
                        // foregroundColor="#ecebeb"
                        {...props}
                      >
                        <rect
                          x="24"
                          y="3"
                          rx="3"
                          ry="3"
                          width="88"
                          height="6"
                        />
                        {/* <circle cx="11" cy="11" r="11" /> */}
                        <rect
                          x="27"
                          y="17"
                          rx="3"
                          ry="3"
                          width="64"
                          height="4"
                        />
                      </ContentLoader>
                    </td>
                    <td>
                      <ContentLoader
                        speed={2}
                        width={88}
                        height={25}
                        viewBox="0 0 88 25"
                        // backgroundColor="#f3f3f3"
                        // foregroundColor="#ecebeb"
                        {...props}
                      >
                        <rect
                          x="24"
                          y="3"
                          rx="3"
                          ry="3"
                          width="88"
                          height="6"
                        />
                        {/* <circle cx="11" cy="11" r="11" /> */}
                        <rect
                          x="27"
                          y="17"
                          rx="3"
                          ry="3"
                          width="64"
                          height="4"
                        />
                      </ContentLoader>
                    </td>
                    <td>
                      <ContentLoader
                        speed={2}
                        width={88}
                        height={25}
                        viewBox="0 0 88 25"
                        // backgroundColor="#f3f3f3"
                        // foregroundColor="#ecebeb"
                        {...props}
                      >
                        <rect
                          x="24"
                          y="3"
                          rx="3"
                          ry="3"
                          width="88"
                          height="6"
                        />
                        {/* <circle cx="11" cy="11" r="11" /> */}
                        <rect
                          x="27"
                          y="17"
                          rx="3"
                          ry="3"
                          width="64"
                          height="4"
                        />
                      </ContentLoader>
                    </td>
                  </div>
                ))}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default TicketsView;
