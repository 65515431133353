import React, { useState, useEffect } from "react";
import { Modal, Button, Dropdown } from "react-bootstrap";
import { useSelector } from "react-redux";

import { getAPIData, postData } from "../../Utils/commonApi";
import ImageWithInitials from "../../Utils/ImageWithInitials";
import { TrackUser } from "../../Utils/SegmentFunctions";
import { TrimString } from "../../Utils/StringTrimmer";
import { useAuth } from "../context/Auth";
import { CallToast } from "../PriceCalculator/modals/CustomToast";
import "./integrationsv2.scss";
import { removeWhiteSpace } from "../../Utils/CommonFunctions";

const SuggestIntegrationModal = ({ show, hideModal }) => {
  const { authTokens } = useAuth();
  const [technologiesList, setTechnologiesList] = useState([]);
  const [selectedTechnology, setSelectedTechnology] = useState();
  const userDetails = useSelector((state) => state?.user?.userDetail);

  const [submitting, setSubmitting] = useState(false);
  const [toolName, setToolName] = useState({data:"",error:false});
  const [website, setWebsite] = useState({data:"",error:false});
  const [comment, setComment] = useState({data:"",error:false});

  // homepage
  // suggestPage
  // resultPage
  const [page, setPage] = useState("homepage");
  async function getSuggestIntegration(order) {
    await getAPIData("66", authTokens, {
      OptionEnum1: order,
    }).then((response) => {
      if (response?.mr?.Success) {
        setTechnologiesList(response?.DDLValues);
      }
    });
  }
  useEffect(() => {
    if (show) {
      getSuggestIntegration(0);
      setPage("homepage");
      setToolName("");
      setWebsite("");
      setComment("");
      setSelectedTechnology();
      setSubmitting(false);
    }
  }, [show]);

  const inputs = {
    TicketDetails: {
      ContactName: userDetails.FullName,
      ContactEmail: userDetails.UserName,
    },
    TicketNoteDetails: {
      Technology: selectedTechnology?.Text,
      TechnologyId: selectedTechnology?.Value,
      ToolName: toolName.data,
      LinkToSite: website.data,
      Description: comment.data,
    },
    TicketType: 3,
  };

  async function suggestIntegration() {
    setSubmitting(true);
    await postData(authTokens, "CreateConnectwiseTicket", {
      TicketDataJSON: JSON.stringify(inputs),
    })
      .then((response) => {
        setSubmitting(false);
        if (response?.Success) {
          setPage("resultPage");
          TrackUser("Integration tool suggestion submitted");
        } else {
          CallToast("Something went wrong", false, false, "v2style");
        }
      })
      .catch((err) => {
        CallToast("Something went wrong", false, false, "v2style");
      });
  }

  async function checkValidation(){
    let error=0;
    if(!toolName.data){
      error+=1
      setToolName({...toolName,error:true})
    }
    if(!selectedTechnology?.Text){
      setSelectedTechnology({...selectedTechnology,error:true})
      error+=1
    }
     return error
  }

  return (
    <Modal
      animation={false}
      show={show}
      dialogClassName="  suggest-integration-modal"
      className=""
      aria-labelledby="reset-settings-modal-title-vcenter"
      centered
      scrollable
      onHide={hideModal}
    >
      {page !== "homepage" && (
        <Modal.Header className="text-center">
          <div className="w-100 mb-0 font-bold-14">Suggest Integration</div>
          <span className="cursor-pointer" onClick={hideModal}>
            <img
              alt=""
              width={12}
              src="/images/icons/close.svg"
              className="close-icon"
            />
          </span>
        </Modal.Header>
      )}

      <Modal.Body className="p-3">
        {page === "homepage" && (
          <>
            <div className="d-flex flex-column align-items-center justify-content-center">
              <img
                alt=""
                className=""
                src="/images/attack-surface/suggest-integration-icon.svg"
              />
              <div className="text-center f-16 f-500 pt-2 pb-3">
                Suggest Integration
              </div>
              <div className="f-500 f-black pb-2 ">
                What tool would you like to integrate with your HighGround
                account?
              </div>
              <div className="f-grey  ">
                Can't see the tool you're looking for? Let us know by suggesting
                an integration below for our development team to review.
              </div>
              <br />
              <br />
              <div className=" ">
                <span className="f-600">Note: </span> We can perform custom integrations on special request but
                these will be chargeable.
              </div>
            </div>
          </>
        )}

        {page === "suggestPage" && (
          <>
            <div className="d-flex align-items-center f-grey mb-2">
              Suggestion will be published by:
            </div>

            <div className="d-flex align-items-center mb-3">
              {userDetails.Avatar === "No URL" ? (
                <ImageWithInitials
                  width={31}
                  background="purple"
                  initials={userDetails?.Initials}
                />
              ) : (
                <img
                  alt=""
                  src={userDetails?.Avatar}
                  className="thumbnail-pic rounded-full"
                />
              )}

              <div className="ml-2">
                <p className="title m-0">
                  {TrimString(userDetails.FullName, 30)}
                </p>
                <div className="f-grey f-12">
                  {TrimString(userDetails.UserName, 35)}
                </div>
              </div>
            </div>
            <div className="mx-2 mt-2">
              <div className={`d-flex align-items-center bg-grey radius-4 p-2 highlight-input mb-2 ${toolName?.error? "input-error" :""}`}>
                <div className="d-flex align-items-center f-darkgrey">
                  Tool name:
                </div>
                <input
                  type="text"
                  className="border-none bg-transparent px-2"
                  placeholder="Type..."
                  value={toolName.data}
                  onChange={(e) => {
                    setToolName({data:window.sanitizeHGtext(removeWhiteSpace(e.target.value)),error:false});
                  }}
                />
              </div>
              <div className={`d-flex align-items-center bg-grey radius-4 p-2 highlight-input mb-2 ${selectedTechnology?.error  ? "input-error" :""    }`}>
                <div className="d-flex align-items-center f-darkgrey">
                  Technology:
                </div>
                <Dropdown className="suggest-integration-dropdown w-100">
                  <Dropdown.Toggle
                    className={`p-0 position-relative w-100  ${""}`}
                  >
                    <div className="d-flex align-items-center justify-content-between w-100 pl-2">
                      {selectedTechnology ? (
                        <div className="">{selectedTechnology?.Text}</div>
                      ) : (
                        <div className="f-darkgrey">Choose..</div>
                      )}

                      <img
                        src="/images/theme/icons/dropdownarrow.svg"
                        alt=""
                        className=""
                      />
                    </div>
                  </Dropdown.Toggle>

                  <Dropdown.Menu className="hide-scrollbar">
                    {technologiesList?.map((technology, technologyIndex) => {
                      return (
                        <div
                          className={`pointer px-2 d-flex align-items-center justify-content-between drop-item
                        `}
                          onClick={(e) => {
                            setSelectedTechnology({...technology,error:false});
                            e.target
                              .closest(".suggest-integration-dropdown")
                              .click();
                          }}
                        >
                          <div>{technology?.Text}</div>
                          <div>
                            {technology?.Value ===
                              selectedTechnology?.Value && (
                              <img
                                alt=""
                                width={12}
                                src="/images/settings-v2/black-tick.svg"
                                className=""
                              />
                            )}
                          </div>
                        </div>
                      );
                    })}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              <div className="d-flex align-items-center bg-grey radius-4 p-2 highlight-input mb-2">
                <div className="d-flex align-items-center f-darkgrey">
                  Developer's site:
                </div>
                <input
                  type="text"
                  className="border-none bg-transparent px-2"
                  placeholder="https://example.com"
                  value={website}
                  onChange={(e) => {
                    setWebsite(window.sanitizeHGtext(e.target.value));
                  }}
                />
              </div>
              <textarea
                rows={6}
                className="bg-grey highlight-input radius-4 w-100 mt-2 p-2"
                placeholder="Type comment here.."
                value={comment}
                onChange={(e) => {
                  setComment(e.target.value);
                }}
              ></textarea>
            </div>
          </>
        )}

        {page === "resultPage" && (
          <>
            <div className="d-flex flex-column align-items-center pt-4">
              <img
                alt=""
                src="/images/attack-surface/suggestionSuccess.svg"
                className="mb-3"
              />
              <div className="f-16 f-500 mb-3">Suggestion submitted</div>
              <div className="f-grey text-center">
                Your suggestion submitted and will appear in voting list after
                moderation
              </div>
            </div>
          </>
        )}
      </Modal.Body>
      {/* suggestionSuccess.svg */}
      <Modal.Footer>
        <Button
          disabled={submitting}
          className={`${
            page === "resultPage" ? "hg-cancel-btn" : "hg-blue-btn"
          } w-100 f-500 `}
          onClick={async() => {
            if (page === "homepage") {
              setPage("suggestPage");
            }
            if (page === "suggestPage") {
              let errors= await checkValidation()

              if(errors>0){

              }else{

                suggestIntegration();
              }
              // setPage("resultPage");
            }
            if (page === "resultPage") hideModal();
          }}
        >
          {page === "homepage" && "Continue"}
          {page === "suggestPage" && (
            <>{submitting ? "Please wait..." : " Submit"}</>
          )}
          {page === "resultPage" && "Close"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default SuggestIntegrationModal;
