import React from "react";
import { useHistory } from "react-router-dom";
import "./kpiModal.scss";

const KPIpopup = () => {
  const history = useHistory();
  return (
    <div className="kpi-modal " id="kpi-modal">
      <header className="d-flex align-items-center bg-grey p-2 justify-content-between">
        <div className="f-grey">
          <img
            alt=""
            src="/images/attack-surface/info-icon.svg"
            className="mr-2 pointer"
          />
          KPI Attestation
        </div>
        <div className="d-flex align-items-center pointer">
          <img
            alt=""
            src="/images/attack-surface/expand-icon.svg"
            className="mx-2"
          />
          <img
            alt=""
            src="/images/attack-surface/cross-icon.svg"
            className="mx-2 mr-2"
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              let modal = document.getElementsByClassName("kpi-modal");
              for (let i = 0; i < modal.length; i++) {
                modal[i].style.display = "none";
              }

              // modal.style.display = "none";
            }}
          />
        </div>
      </header>
      <div className="bg-white">
        <section className="px-3 bg-white">
          <div className="d-flex align-items-start my-3">
            <img
              alt=""
              src="/images/attack-surface/verified.svg"
              className="mr-2"
            />
            <div>
              <p className="font-weight-bold m-0">Verified</p>
              <p className="f-black">
                KPIs are verified when they are powered from your own data by
                Integrating your security tools.
              </p>
            </div>
          </div>

          <div className="d-flex align-items-start my-3">
            <img
              alt=""
              src="/images/attack-surface/part-verified.svg"
              className="mr-2"
            />
            <div>
              <p className="font-weight-bold m-0">Part-Verified</p>
              <p className="f-black">
                KPIs are part-verified when they are powered by a combination of
                your own data(by integrating your tools) and self-defined data
                in the attack surface module.
              </p>
            </div>
          </div>

          <div className="d-flex align-items-start my-3">
            <img
              alt=""
              src="/images/attack-surface/unverified.svg"
              className="mr-2"
            />
            <div>
              <p className="font-weight-bold m-0">Unverified</p>
              <p className="f-black">
                KPIs are Unverified when they are powered exclusively from
                self-defined data in the attack surface module.
              </p>
            </div>
          </div>
        </section>
        <hr className="m-0 dividerx" />
        <footer className="p-2 px-4 bg-white">
          <div
            className="d-flex align-items-center pointer mt-2"
            onClick={(e) => {
              e.stopPropagation();
              history.push("/attack_surface");
            }}
          >
            <img
              alt=""
              src="/images/attack-surface/attackSurfaceIcon.svg"
              className="mr-2"
            />
            <div className="f-blue">Open Attack Surface</div>
          </div>
          <div
            className="d-flex align-items-center mt-2 pointer"
            onClick={(e) => {
              e.stopPropagation();
              window.open("https://kb.highground.io");
            }}
          >
            <img
              alt=""
              src="/images/settings-v2/knowledgebase.svg"
              className="mr-2"
            />
            <div className="f-grey">Learn more in our Knowlege base</div>
            <img
              alt=""
              src="/images/attack-surface/goto.svg"
              className="ml-2"
            />
          </div>
        </footer>
      </div>
    </div>
  );
};

export default KPIpopup;
