import React, { useState, useEffect } from "react";
import { Button, Col, Row, Dropdown, Form } from "react-bootstrap";
import { FiCheck } from "react-icons/fi";
import { toast, ToastContainer } from "react-toastify";
import { getAPIData } from "../../../Utils/commonApi";
import { TrimString } from "../../../Utils/StringTrimmer";
import CircularProgressBar from "../../CircularProgressBar";
import { useAuth } from "../../context/Auth";
import CompanySettingsDropdown from "../companySettings/companysettingsdrodown/CompanySettingsDropdown";
import AddPaymentModal from "./AddPaymentMethodModal";
import "./billing.scss";
import BillingPageLoader from "./BillingPageLoader";
import BillingSubscriptionLoader from "./BillingSubscriptionLoader";
import CancelSubscriptionModal from "./CancelSubscriptionModal";
import ChangePaymentModal from "./ChangePaymentMethodModal";
import EditPaymentModal from "./EditPaymentModal";
import RemovePaymentModal from "./RemovePaymentMethod";
import { viewOnlyAccess } from "../../App/App";
const BillingV2 = () => {
  const { authTokens } = useAuth();
  const [select1, setselect1] = useState({
    id: 0,
    category: "All subscriptions",
  });
  const [cancelSubscriptionModal, setCancelSubscriptionModal] = useState(false);
  const [addPaymentModal, setAddPaymentModal] = useState(false);
  const [cancelSubscriptionData, setCancelSubscriptionData] = useState({});
  const [editPaymentModal, setEditPaymentModal] = useState(false);
  const [removePaymentModal, setRemovePaymentModal] = useState(false);
  const [changePaymentModal, setChangePaymentModal] = useState(false);
  const [paymentMethodList, setPaymentMethodList] = useState([]);
  const [removePaymentId, setRemovePaymentId] = useState("");
  const [invoiceList, setInvoiceList] = useState([]);
  const [activeSubscriptions, setActiveSubscriptions] = useState([]);
  const [mainPageLoading, setMainPageLoading] = useState(false);
  const [changeSubcriptionId, setChangeSubcriptionId] = useState("");
  const [showToast, setShowToast] = useState(false);
  const [compData, setcompData] = useState(false);
  const [subData, setSubData] = useState({});
  const [subscriptionsLoader, setSubscriptionsLoader] = useState(false);
  // Show more button States
  const [showMoreActiveSubscriptions, setShowMoreActiveSubscriptions] =
    useState(5);
  const [showMorePaymentMethods, setShowMorePaymentMethods] = useState(5);
  const [showMoreInvoiceList, setShowMoreInvoiceList] = useState(5);

  useEffect(() => {
    // hang on msg after 5 sec if there is no response from  API
    var time = setInterval(() => {
      if (!compData) {
        setShowToast(true);
        toast.dark(
          <span className="d-flex">
            <FiCheck style={{ marginRight: "5px" }} />
            <div className="text-center">
              Hang on, it's taking longer than usual to process your request,
              please wait...
            </div>
            <span className="float-right">
              <CircularProgressBar durationInSec="30" />
            </span>
          </span>
        );
        return () => setShowToast(false), clearInterval(time);
      }
    }, 8000);
    if (compData) {
      return () => setShowToast(false), clearInterval(time);
    }
    return () => clearInterval(time);
  }, [compData]);

  async function getBillingDetails() {
    const response = await getAPIData("505", authTokens, {
      optionEnum1: 0,
    }).then((response) => {
      setMainPageLoading(false);
      setShowToast(false);

      setcompData(true);
      if (response.mr.Success == true) {
        setPaymentMethodList(response?.PaymentMethodList);
        setInvoiceList(response?.StripeInvoiceList);
        setActiveSubscriptions(response?.Services);

        // setcompData(true);
        // setResponseData(response);
        // setTitle(response.Title);
        // setBillingdata(response.StripeInvoiceList);
        // setPaymentMethodDetail(response.PaymentMethodList);
        // setSubPlan(response.SubPlan);
        // setIntegrationPlan(response.IntegrationPlan);
        // setCountryList(
        //   response.CountryList || [{ country: "United Kingdom", code: "GB" }]
        // );
      }
    });
  }
  useEffect(() => {
    setMainPageLoading(true);
    getBillingDetails();
  }, []);

  async function getSubscriptionList(option) {
    const response = await getAPIData("505", authTokens, {
      optionEnum1: option,
    }).then((response) => {
      setSubscriptionsLoader(false);
      if (response.mr.Success) {
        setActiveSubscriptions(response?.Services);
      }
    });
  }

  const hideEditPaymentModal = () => {
    setEditPaymentModal(false);
  };

  const hideRemovePaymentModal = () => {
    setRemovePaymentModal(false);
  };

  const hideChangePaymentModal = () => {
    setChangePaymentModal(false);
  };

  const hideAddPaymentModal = () => {
    setAddPaymentModal(false);
  };

  const showCancelSubscriptionModal = () => {
    setCancelSubscriptionModal(true);
  };
  const hideCancelSubscriptionModal = () => {
    setCancelSubscriptionModal(false);
  };
  return (
    <>
      {showToast && (
        <ToastContainer
          position="bottom-center"
          autoClose={3000}
          hideProgressBar
        />
      )}
      {mainPageLoading && <BillingPageLoader />}
      <div className={`billing-wrapper ${mainPageLoading ? "d-none" : ""} `}
      
     
      // style={{
      //   pointerEvents:viewOnlyAccess("AccessState_SystemSettings") ? "none"  :"all"
      // }}
      
      
      >
        <div className="heading mb-4">Billing</div>
        <div className="d-flex justify-content-between align-content-center mb-2 header-dropdown">
          <div className="title d-flex justify-content-center align-items-center">
            Active Subscriptions
          </div>
          <CompanySettingsDropdown
            fieldId={"id"}
            fieldValue={"category"}
            selected={select1}
            placeholder="Choose..."
            //  showid={true}
            onClick={(id, val) => {
              setSubscriptionsLoader(true);
              getSubscriptionList(id);
              setselect1({ id, category: val });
            }}
            data={[
              { id: 0, category: "All subscriptions" },
              { id: 1, category: "Subscription" },
              // { id: 2, category: "Integration Packs" },
              { id: 3, category: "Proservices" },
              { id: 4, category: "Managed Security Packs" },
            ]}
          />
        </div>

        <Row>
          {!subscriptionsLoader && (
            <div className="option-wrapper">
              {activeSubscriptions
                .slice(0, showMoreActiveSubscriptions)
                .map((subscription, index) => {
                  return (
                    <>
                      <Col xs={12}>
                        <div className="d-flex justify-content-between align-items-center padding-1">
                          <div className="d-flex align-items-center">
                            {subscription?.Icon ? (
                              <img alt=""
                                src={`/images/ProServices/${subscription?.Icon}`}
                                className="mr-2"
                                style={{
                                  width: "30px",
                                  objectFit: "contain",
                                  borderRadius: "50%",
                                }}
                              />
                            ) : (
                              <div
                                style={{
                                  paddingRight: "15px",
                                }}
                              ></div>
                            )}
                            <div>
                              <p className="title m-0">{subscription?.Name} </p>
                              <p className="subtitle">{subscription?.Cost}</p>
                            </div>
                          </div>

                          <div className="d-flex align-items-center">
                            <div className="card-info">
                              {/* Master Card *4392 */}
                            </div>
                            <div>
                              <Dropdown className="change-payment-method-dropdown">
                                <Dropdown.Toggle>
                                  <img alt='' src="/images/horizontal-dots.svg" />
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                  <div>
                                    <Row>
                                      <Col xs={12}>
                                        <div
                                          className={`drop-btn mb-2 ${viewOnlyAccess("AccessState_SystemSettings") ? 'cursor-default' : 'pointer bg-hover-grey-light'}`}
                                          onClick={() => {
                                            if (!viewOnlyAccess("AccessState_SystemSettings")) {
                                              setChangeSubcriptionId(
                                                subscription?.SubscriptionId
                                              );
                                              setSubData(subscription);
                                              setChangePaymentModal(true);
                                            }
                                          }}
                                        >
                                          {
                                            viewOnlyAccess("AccessState_SystemSettings") &&
                                            <img
                                              alt=''
                                              className='lockerDarkGreyImage mx-2 mb-1'
                                              src='/images/settings-v2/locker-grey.svg'
                                            />
                                          }
                                          Change payment method
                                        </div>
                                      </Col>
                                      <Col xs={12}>
                                        <div
                                          className={`drop-btn pointer ${viewOnlyAccess("AccessState_SystemSettings") ? 'cursor-default' : 'pointer bg-hover-grey-light'}`}
                                          onClick={() => {
                                            if (!viewOnlyAccess("AccessState_SystemSettings")) {
                                              setCancelSubscriptionData(
                                                subscription
                                              );
  
                                              setCancelSubscriptionModal(true);
                                            }
                                          }}
                                        >
                                          {/* <hr className="m-0 notification-divider" /> */}
                                          {
                                            viewOnlyAccess("AccessState_SystemSettings") &&
                                            <img
                                              alt=''
                                              className='lockerDarkGreyImage mx-2 mb-1'
                                              src='/images/settings-v2/locker-grey.svg'
                                            />
                                          }
                                          Cancel subscription
                                        </div>
                                      </Col>
                                    </Row>
                                  </div>
                                </Dropdown.Menu>
                              </Dropdown>
                            </div>
                          </div>
                        </div>
                      </Col>
                      {activeSubscriptions.slice(0, showMoreActiveSubscriptions)
                        .length -
                        1 !==
                        index && <hr className="m-0" />}
                    </>
                  );
                })}
            </div>
          )}

          {subscriptionsLoader && (
            <div style={{ height: "310px", width: "100%" }}>
              <BillingSubscriptionLoader />
            </div>
          )}

          {activeSubscriptions?.length === 0 && !subscriptionsLoader && (
            <div className="mt-2 w-50 f-500 my-2 ml-3 d-flex flex-column justify-content-center align-items-center mx-auto">
              <img alt="" src="/images/settings-v2/no-subscription.svg" />
              <div className="f-grey f-500 mt-2">
                There are no active subscriptions yet
              </div>
              <div className="f-darkgrey f-12 mt-1 w-75 text-center mb-4">
                Your subscriptions and pro services will be
                displayed here
              </div>
            </div>
          )}

          <div className={`mt-2 ${subscriptionsLoader ? "d-none" : ""}`}>
            {activeSubscriptions?.length > 0 && (
              <div
                className={`show-more-btn   ${
                  showMoreActiveSubscriptions > 5 ? "arrow-up" : "arrow-down"
                } `}
                onClick={() => {
                  if (showMoreActiveSubscriptions > 5) {
                    setShowMoreActiveSubscriptions(5);
                  } else {
                    setShowMoreActiveSubscriptions(5000);
                  }
                }}
              >
                {showMoreActiveSubscriptions == 5
                  ? `Show ${
                      activeSubscriptions?.length > 5
                        ? activeSubscriptions?.length - 5
                        : ""
                    } More`
                  : "Show Less"}
              </div>
            )}
          </div>
        </Row>
        <div className="d-flex justify-content-between align-content-center my-3">
          <div className="norm-text d-flex align-items-center">
            Payment Methods
          </div>
          <Button
            className={`${viewOnlyAccess("AccessState_SystemSettings") ? 'btn-disable' : 'add-payment-btn'}`}
            onClick={() => {
              if (!viewOnlyAccess("AccessState_SystemSettings")) {
                setAddPaymentModal(true)
              }
            }}
          >
            {
              viewOnlyAccess("AccessState_SystemSettings") ? 
              <img
                alt=''
                className='lockerDarkGreyImage mx-2 mb-1'
                src='/images/settings-v2/locker-grey.svg'
              />
              :
              <img alt="" src="/images/settings-v2/plus-icon.svg" className="mr-1" /> 
            }
            {" "}Add Payment method
          </Button>
        </div>
        {/* Payments Method */}

        <Row>
          <div className="option-wrapper">
            {paymentMethodList
              .slice(0, showMorePaymentMethods)
              .map((paymentMethod, index) => {
                return (
                  <>
                    <Col xs={12}>
                      <div className="d-flex justify-content-between align-items-center padding-1">
                        <div className="d-flex align-items-center">
                          <img
                            src={`/images/${paymentMethod?.Icon}`}
                            className="mr-2"
                            style={{
                              height: "24px",
                            }}
                          />
                          <div>
                            <p className="title m-0">
                              {paymentMethod?.CardType}&nbsp;
                              {paymentMethod?.CardNumber}
                            </p>
                            <p className="subtitle">
                              {/* Jane Cooper, Cyber Care ltd., United Kingdom, 2972
                          Westheimer Rd. Santa Ana, Illinois 85486... */}
                            </p>
                          </div>
                        </div>

                        <div className="d-flex align-items-center">
                          <Dropdown className="change-payment-method-dropdown">
                            <Dropdown.Toggle>
                              <img src="/images/horizontal-dots.svg" />
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              <div>
                                <Row>
                                  {/* <Col xs={12}>
                                    <div
                                      className="drop-btn mb-2 pointer"
                                      onClick={() => setEditPaymentModal(true)}
                                    >
                                      Edit Payment method
                                    </div>
                                  </Col> */}

                                  <Col xs={12}>
                                    {/* <hr
                                      className="my-1 mb-2 "
                                      color="#ebecf1"
                                    /> */}
                                    <div
                                      className={`drop-remove-btn ${viewOnlyAccess("AccessState_SystemSettings") ? 'cursor-default' : 'pointer bg-hover-grey-light'}`}
                                      style={{
                                        color: "rgba(255, 255, 255, 0.64)",
                                      }}
                                      onClick={() => {
                                        if (!viewOnlyAccess("AccessState_SystemSettings")) {
                                          setRemovePaymentId(paymentMethod);
                                          setRemovePaymentModal(true);
                                        }
                                      }}
                                    >
                                      {/* <hr className="m-0 notification-divider" /> */}
                                      {
                                        viewOnlyAccess("AccessState_SystemSettings") &&
                                        <img
                                          alt=''
                                          className='lockerDarkGreyImage mx-2 mb-1'
                                          src='/images/settings-v2/locker-grey.svg'
                                        />
                                      }
                                      Remove
                                    </div>
                                  </Col>
                                </Row>
                              </div>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      </div>
                    </Col>
                    {paymentMethodList.slice(0, showMorePaymentMethods).length -
                      1 !==
                      index && <hr className="m-0" />}
                  </>
                );
              })}
          </div>
          {/* show more button */}
          {paymentMethodList?.length == 0 && (
            <div className="mt-2 w-50 f-500 my-2 ml-3 d-flex flex-column justify-content-center align-items-center mx-auto">
              <img src="/images/settings-v2/no-payment.svg" />
              <div className="f-grey f-500 mt-2">
                There are no payment methods yet
              </div>
              <div className="f-darkgrey f-12 mt-1 mb-4">
                Your saved payment methods will be displayed here
              </div>
            </div>
          )}

          <div className="mt-2">
            {paymentMethodList?.length > 0 && (
              <div
                className={`show-more-btn   ${
                  showMorePaymentMethods > 5 ? "arrow-up" : "arrow-down"
                } `}
                onClick={() => {
                  if (showMorePaymentMethods > 5) {
                    setShowMorePaymentMethods(5);
                  } else {
                    setShowMorePaymentMethods(5000);
                  }
                }}
              >
                {showMorePaymentMethods == 5
                  ? `Show ${
                      paymentMethodList?.length > 5
                        ? paymentMethodList?.length - 5
                        : ""
                    } More`
                  : "Show Less"}
              </div>
            )}
          </div>
        </Row>

        {/* Billing History */}

        <div className="title  my-3">Billing history</div>

        <Row>
          <div className="option-wrapper">
            {invoiceList.slice(0, showMoreInvoiceList).map((invoice, index) => {
              return (
                <>
                  <Col xs={12}>
                    <div className={`d-flex justify-content-between align-items-center padding-1 py-4 ${
                      viewOnlyAccess("AccessState_SystemSettings") ? 'cursor-default' : 'pointer'
                    }`}>
                      <div className="title">{invoice?.Date}</div>
                      <div className="title">
                        {TrimString(invoice?.Name, 35)}
                      </div>
                      <div className="title d-flex align-items-center">
                        <div className="d-flex align-items-center mr-2">
                          {invoice?.Amount}
                        </div>
                        {
                          viewOnlyAccess("AccessState_SystemSettings") ? 
                          <img
                            src="/images/settings-v2/invoice.svg"
                            className="mb-1"
                          />
                          :
                          <a href={`${invoice?.PrintURL}`} target="_blank">
                            <img
                              src="/images/settings-v2/invoice.svg"
                              className="mb-1"
                            />
                          </a>
                        }
                      </div>
                    </div>
                  </Col>
                  {invoiceList.slice(0, showMoreInvoiceList).length - 1 !==
                    index && <hr className="m-0" />}
                </>
              );
            })}
          </div>

          {/* show more button */}

          {invoiceList?.length == 0 && (
            <div className="w-50 mt-2  f-500 my-2 ml-3 d-flex flex-column justify-content-center align-items-center mx-auto">
              <img src="/images/settings-v2/no-invoice.svg" />
              <div className="f-grey f-500 mt-2">There are no billings yet</div>
              <div className="f-darkgrey f-12 mt-1 mb-4">
                Your Billing history will be displayed here
              </div>
            </div>
          )}

          <div className="mt-2">
            {invoiceList?.length > 0 && (
              <div
                className={`show-more-btn   ${
                  showMoreInvoiceList > 5 ? "arrow-up" : "arrow-down"
                } `}
                onClick={() => {
                  if (showMoreInvoiceList > 5) {
                    setShowMoreInvoiceList(5);
                  } else {
                    setShowMoreInvoiceList(5000);
                  }
                }}
              >
                {showMoreInvoiceList == 5
                  ? `Show ${
                      invoiceList?.length > 5 ? invoiceList?.length - 5 : ""
                    } More`
                  : "Show Less"}
              </div>
            )}
          </div>
        </Row>
        <EditPaymentModal
          show={editPaymentModal}
          hideModal={hideEditPaymentModal}
        />
        <RemovePaymentModal
          show={removePaymentModal}
          hideModal={hideRemovePaymentModal}
          payment={removePaymentId}
          Refreshpage={getBillingDetails}
        />
        <AddPaymentModal
          show={addPaymentModal}
          hideModal={hideAddPaymentModal}
          Refreshpage={getBillingDetails}
        />
        <ChangePaymentModal
          show={changePaymentModal}
          hideModal={hideChangePaymentModal}
          changeSubcriptionId={changeSubcriptionId}
          subId={changeSubcriptionId}
          subscriptionData={subData}
        />
        <CancelSubscriptionModal
          show={cancelSubscriptionModal}
          hideModal={hideCancelSubscriptionModal}
          data={cancelSubscriptionData}
          Refreshpage={getBillingDetails}
        />
      </div>
    </>
  );
};

export default BillingV2;
