import { Dropdown } from "react-bootstrap";
import useDropdown from "../../../hooks/dropdown.hook";
import HorizontalDotsIcon from "../../icons/horizontal-dots.icon";

const IntegrationItemDropdown = ({ 
  integrationId, 
  onAddSubscription = () => { }, 
  onRemoveOption = () => { }, 
  onRenameOption = () => { },
  showAddSub, 
  showRename,
  drop = "down",
  setShowResetToolModal = () => { },
  setSelectedToolId = () => { }
}) => {
  const { isOpen, refBtn, refMenu, setIsOpen } = useDropdown({ disableClickOutside: false});

  return (
    <Dropdown drop={drop} onSelect={() => setIsOpen(false)} alignRight show={isOpen} className="w-100 transparennt-wrapper-dropdown">
      <Dropdown.Toggle ref={refBtn}>
        <div 
          className="pointer px-2" onClick={(e) => {
            e.stopPropagation();
            setIsOpen(prev => !prev)
          }}
        >
          <img
            alt=""
            className="pointer"
            src="/images/msp/horizontal-dots.svg"
          />
          <HorizontalDotsIcon fill="#616778" width="16" height="4" />
        </div>
      </Dropdown.Toggle>
      <Dropdown.Menu ref={refMenu} className="integration-actions-dropdown">
        {showAddSub && (
          <Dropdown.Item 
            className="integration-item"
            onClick={(e) => {
              e.stopPropagation();
              onAddSubscription(integrationId);
            }}
          >
            <span className="item py-1">Add Subscription</span>
          </Dropdown.Item>
        )}
        {showRename && (
          <Dropdown.Item 
            className="integration-item"
            onClick={(e) => {
              e.stopPropagation();
              onRenameOption(integrationId);
            }}
          >
            <span className="item py-1">Rename</span>
          </Dropdown.Item>
        )}
        <Dropdown.Item 
          className="integration-item" 
          onClick={(e) => {
            e.stopPropagation();
            setSelectedToolId(integrationId);
            setShowResetToolModal(true);
            onRemoveOption(integrationId);
          }}
        >
          <span className="delete py-1">Remove</span>
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  )
}

export default IntegrationItemDropdown;
