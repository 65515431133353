import React, { useEffect } from "react";
import { Dropdown } from "react-bootstrap";
import { getAPIData } from "../../../Utils/commonApi";
import ImageWithInitials from "../../../Utils/ImageWithInitials";
import { viewOnlyAccess } from "../../App/App";
import { useAuth } from "../../context/Auth";
import { CallToast } from "../../PriceCalculator/modals/CustomToast";
import "../supplier-profiler.scss";
import RevokeDropdown from "./RevokeDropdown";

const ContributorInfoDropdown = ({
  questionId,
  obj,
  refreshData,
  isContributorView,
}) => {
  const { authTokens } = useAuth();
  async function revoke() {
    await getAPIData(765, authTokens, {
      optionEnum1: questionId,
      // optionEnum2: obj.ObjectId,
      optionEnum2: obj.SupplierOrContributorId,
    })
      .then((response) => {
        if (response?.Success) {
          refreshData();
        } else {
          CallToast("Something went wrong", false, false, "v2style");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    console.log("obj.IsRevoked----", obj.IsRevoked);
  }, [obj.IsRevoked]);
  return (
    <Dropdown
      className=" user-detail-dropdown contributor-info w-100"
      // style={{ inset: "-35px auto auto -300px !important" }}
      onToggle={() => {}}
    >
      <Dropdown.Toggle
        className="d-flex custom-dropdown-toggle hide-after w-100"
        style={{ padding: 0 }}
      >
        <div className="item w-100">
          <div className="d-flex" style={{ gap: "8px", alignItems: "center" }}>
            {obj?.ContributorLogo ? (
              <div className="rounded-full w-fit-content h-fit-content position-relative overlap_child">
                <img
                  width={25}
                  src={`${obj?.ContributorLogo}`}
                  className="rounded-full"
                  alt=""
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null; // prevents looping
                    currentTarget.src = "/images/msp/default-cert-icon.svg";
                  }}
                />
              </div>
            ) : (
              <div className="rounded-full w-fit-content h-fit-content position-relative overlap_child">
                <ImageWithInitials
                  initials={obj?.ContributorName[0]}
                  width={25}
                  background={"purple"}
                />
              </div>
            )}
            <div className="d-flex flex-column">
              {obj.ContributorName}
              {/* <div className="f-darkgrey">
                {obj.IsLastEdited ? "Last edited" : ""}
              </div> */}
            </div>
          </div>
          {viewOnlyAccess("AccessState_SCM") ? (
            <></>
          ) : (
            <>
              {obj.IsRevoked ? (
                <div className="f-grey revoke">Is Revoked</div>
              ) : (
                <div
                  className="f-grey revoke"
                  onClick={() => {
                    // return ()
                    //revoke
                  }}
                >
                  <RevokeDropdown
                    obj={obj}
                    questionId={questionId}
                    refreshData={refreshData}
                    isContributorView={isContributorView}
                  />
                </div>
              )}
            </>
          )}
        </div>
      </Dropdown.Toggle>
      <Dropdown.Menu
        className="dropdown-menu-contributors dropdown-revoke"
        style={{
          width: " 296px",
        }}
      >
        <div className="item w-100" style={{ padding: "12px 12px 8px 12px" }}>
          <div className="d-flex" style={{ gap: "8px", alignItems: "center" }}>
            {obj?.ContributorLogo ? (
              <div className="rounded-full w-fit-content h-fit-content position-relative overlap_child">
                <img
                  width={32}
                  src={`${obj?.ContributorLogo}`}
                  className="rounded-full"
                  alt=""
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null; // prevents looping
                    currentTarget.src = "/images/msp/default-cert-icon.svg";
                  }}
                />
              </div>
            ) : (
              <div className="rounded-full w-fit-content h-fit-content position-relative overlap_child">
                <ImageWithInitials
                  initials={obj?.ContributorName[0]}
                  width={32}
                  background={"purple"}
                />
              </div>
            )}
            <div className="d-flex flex-column">
              {obj.ContributorName}
              <div className="f-darkgrey f-12">
                {obj.IsContributor ? "Contributor" : "Team member"}
              </div>
            </div>
          </div>
          {viewOnlyAccess("AccessState_SCM") ? (
            <></>
          ) : (
            <>
              {obj.IsRevoked ? (
                <div className="f-grey ">Is Revoked</div>
              ) : (
                <div className="f-red ">
                  <RevokeDropdown
                    obj={obj}
                    questionId={questionId}
                    refreshData={refreshData}
                    isContributorView={isContributorView}
                  />
                </div>
              )}
            </>
          )}
        </div>
        <div
          style={{
            borderTop: "1px solid #ebecf1",
            height: "1px",
            width: "107%",
            alignSelf: "center",
          }}
        ></div>
        <div
          className=""
          style={{
            display: "flex",
            padding: "8px 12px 12px 12px",
            flexDirection: "column",
            alignItems: "flex-start",
            gap: "12px",
            alignSelf: "stretch",
          }}
        >
          <div className="f-darkgrey f-12">Contacts</div>
          <div
            className="txt-blue fontBold w-100"
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            {obj.Email}
          </div>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default ContributorInfoDropdown;
