import React, { useEffect, useState } from "react";
import { Button, Form, Nav, Spinner, Tab } from "react-bootstrap";
import "./drilldownModal.scss";
import { IndependentGetAPIData, IndependentPostRequest, getAPIData, postRequest } from "../../Utils/commonApi";
import { CallToast } from "../PriceCalculator/modals/CustomToast";
import { useAuth } from "../context/Auth";
import { accessStateValue, viewOnlyAccess } from "../App/App";
import CompanySettingsDropdown from "../settingsV2/companySettings/companysettingsdrodown/CompanySettingsDropdown";
import { GreyLoader } from "../Theme/APILoaders";
import { removeWhiteSpace } from "../../Utils/CommonFunctions";
import { AttestationPopUp } from "./KPIpopup/KPIpopup";
import   {
  CreateClientComplianceLoader,
} from "../MSPv2/clients/createClientScreens/CyberComplianceForGroupModal";
import { Attestation_Icon_Mappings } from "../ComplianceManager/WebFilteringBoxes/WebFilteringBoxes";
import SearchInput from "../Common/search-input/search-input.component";
import IntegrationListItem from "./KPICyberScoreSection/integration-list-item.component";
import SelectToolDropdown from "./KPICyberScoreSection/select-tool/select-tool.dropdown";
import NoteSection from './KPICyberScoreSection/note-section/note-section.component';
import SearchTitle from '../Common/search-title.component';
import { BASE_API_PAGE_ENUMS } from "../../constants/apis.constants";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import KPISettingsDropdown from "./kpi-settings-dropdown/kpi-settings.dropdown";
import { useParams } from "react-router-dom/cjs/react-router-dom";
import { ROUTE_PATHS } from "../../constants/app.constants";

const CyberScoreForGroupModal = ({ 
  clientId, AccessStateLabel, passedOptionEnum99, showActivePackage = false, activePackageName, activePackageId,
  showAttestationIcons,
  setShowAttestationIcons
}) => {
  const [searchComponent, setSearchComponent] = useState('');
  const { authTokens } = useAuth();
  const history = useHistory();
  const [mainPageLoading, setMainPageLoading] = useState(false);

  const [toolData, setToolData] = useState([]);
  const [cyberScoreData, setCyberScoreData] = useState({}); 
  const [loading, setLoading] = useState(false)

  const [selectedIntegrationId, setSelectedIntegrationId] = useState();

  const [showMspConnectModal, setShowMspConnectModal] = useState(false);

  useEffect(() => {
    setMainPageLoading(true);
    getCyberScoreData();
  }, []);

  useEffect(() => {
    let main_wrapper = document.querySelector("#cyber-score-group-wrapperr");
    if (!main_wrapper) return;
    let all_rows = main_wrapper.querySelectorAll(".point");
    for (let i = 0; i < all_rows.length; i++) {
      let panel = all_rows[i].querySelector(".panel");
      if (panel?.style?.maxHeight) {
        panel.style.maxHeight = panel.scrollHeight + "px";
      }
    }
  }, [toolData]);

  /**
   * @param
   *
   * {
   * showUpdateToast: false,
   * showErrorToast:false,
   * keepToolsAtRow
   * }
   */

  function getCyberScoreData(kwargs = {}) {
    if (passedOptionEnum99) {
      IndependentGetAPIData(433, authTokens, {
        optionEnum1: window.location.pathname.split("/")[4] ?? clientId ?? 0,
        optionEnum2: 4,
        optionEnum99: passedOptionEnum99
      })
        .then((response) => {
          setMainPageLoading(false);
          setCyberScoreData(response);
          let tool_data = [...response?.AttestationRow_List];
  
          let updated_tool_data = tool_data?.map((toolInfo, toolIndex) => {
            return {
              ...toolInfo,
              IntegrationList:
                kwargs.keepToolsAtRow >= 0
                  ? [...toolData[toolIndex].IntegrationList]
                  : [],
              addNewTool: false,
              newToolName: "",
              addingNewTool: false,
              newToolSelected: false,
              loading: false,
              selected:
                toolInfo?.SelectedTool_List?.length > 0
                  ? { id: 1, category: "Yes" }
                  : toolInfo?.ShowNoCapability
                  ? { id: 0, category: "No" }
                  : null,
              dummy_SelectedTool_List:
                toolInfo?.SelectedTool_List?.length > 0
                  ? [...toolInfo?.SelectedTool_List]
                  : [],
            };
          });
  
          setToolData(updated_tool_data);
          if (kwargs?.showUpdateToast) {
            // CallToast("Updated successfully", false, false, "v2style");
          }
          if (kwargs?.showErrorToast) {
            CallToast("Something went wrong", false, false, "v2style");
          }
        })
        .catch((err) => {
          setMainPageLoading(false);
        });
    } else {
      getAPIData(433, authTokens, {
        optionEnum1: window.location.pathname.split("/")[4] ?? clientId ?? 0,
        optionEnum2: 4,
      })
        .then((response) => {
          setMainPageLoading(false);
          setCyberScoreData(response);
          let tool_data = [...response?.AttestationRow_List];
  
          let updated_tool_data = tool_data?.map((toolInfo, toolIndex) => {
            return {
              ...toolInfo,
              IntegrationList:
                kwargs.keepToolsAtRow >= 0
                  ? [...toolData[toolIndex].IntegrationList]
                  : [],
              addNewTool: false,
              newToolName: "",
              addingNewTool: false,
              newToolSelected: false,
              loading: false,
              selected:
                toolInfo?.SelectedTool_List?.length > 0
                  ? { id: 1, category: "Yes" }
                  : toolInfo?.ShowNoCapability
                  ? { id: 0, category: "No" }
                  : null,
              dummy_SelectedTool_List:
                toolInfo?.SelectedTool_List?.length > 0
                  ? [...toolInfo?.SelectedTool_List]
                  : [],
            };
          });
  
          setToolData(updated_tool_data);
          if (kwargs?.showUpdateToast) {
            // CallToast("Updated successfully", false, false, "v2style");
          }
          if (kwargs?.showErrorToast) {
            CallToast("Something went wrong", false, false, "v2style");
          }
        })
        .catch((err) => {
          setMainPageLoading(false);
        });
    }
  }

  function ToggleAccordion(e, work = true, tool = null) {
    if (!work) return;
    e.preventDefault();
    var parent = e.target.closest(".point");
    var panel = parent.querySelector(".panel");
    // Get all elements with class "point" and close their panels
    var allPoints = document.querySelectorAll(".point");
    allPoints.forEach(function (point) {
      let otherPanel = point.querySelector(".panel");
      let down_arrow_icon = point.querySelector(".rowDownArrow");
      if (otherPanel !== panel && otherPanel.style.maxHeight) {
        otherPanel.style.maxHeight = null;
        if (down_arrow_icon) {
          down_arrow_icon.classList.remove("rotate180");
        }
      }
    });
    let down_arrow = parent.querySelector(".rowDownArrow");

    if (panel?.style?.maxHeight) {
      panel.style.maxHeight = null;
      if (down_arrow) {
        down_arrow.classList.remove("rotate180");
      }
    } else {
      panel.style.maxHeight = panel.scrollHeight + "px";
      if (down_arrow) {
        down_arrow.classList.add("rotate180");
      }
    }
  }

  // kwargs={
  //   technologyEnum,
  //   IntegrationId,
  //   toolName,
  //   selected,
  //   rowIndex
  // }

  function addNewIntegration(kwargs = {}) {
    return new Promise((resolve, reject) => {
      if (passedOptionEnum99) {
        IndependentPostRequest(authTokens, "CreateIntegrationTechnologyTool", {
          Selected: kwargs.selected ?? true,
          IsMultipleIntegrationAllowed: true,
          ObjId_Customer: clientId,
          ToolName: kwargs.toolName,
          ObjectId: kwargs.IntegrationId,
          accessKey: JSON.parse(localStorage.getItem("user_MessageObj")),
          IntegrationTechnology: kwargs.technologyEnum,
          optionEnum99: 3
        })
          .then((response) => {
            if (response?.mr?.Success) {
              resolve();
            } else {
            }
          })
          .catch((err) => {
            getCyberScoreData({ showErrorToast: true });
          });
      } else {
        postRequest(authTokens, "CreateIntegrationTechnologyTool", {
          Selected: kwargs.selected ?? true,
          IsMultipleIntegrationAllowed: true,
          ObjId_Customer: clientId,
          ToolName: kwargs.toolName,
          ObjectId: kwargs.IntegrationId,
          accessKey: JSON.parse(localStorage.getItem("user_MessageObj")),
          IntegrationTechnology: kwargs.technologyEnum,
        })
          .then((response) => {
            if (response?.mr?.Success) {
              resolve();
            } else {
            }
          })
          .catch((err) => {
            getCyberScoreData({ showErrorToast: true });
          });
      }
    });
  }

  async function disconnectIntegration(_integrationId) {
    return new Promise((resolve, reject) => {
      if (passedOptionEnum99) {
        IndependentGetAPIData("626", authTokens, {
          OptionEnum1: _integrationId,
          // OptionEnum2: 6,
          optionEnum2: clientId,
          optionEnum99: passedOptionEnum99
        })
          .then((response) => {
            if (response?.Success) {
              resolve();
            } else {
            }
          })
          .catch((err) => {});
      } else {
        getAPIData("626", authTokens, {
          OptionEnum1: _integrationId,
          // OptionEnum2: 6,
          optionEnum2: clientId,
        })
          .then((response) => {
            if (response?.Success) {
              resolve();
            } else {
            }
          })
          .catch((err) => {});
        }
      });
  }

  async function renameIntegration(_integrationId, newName) {
    return new Promise((resolve, reject) => {
      IndependentGetAPIData(BASE_API_PAGE_ENUMS.RenameCustomIntegrationName, authTokens, {
        optionEnum1: _integrationId,
        optionStrEnum1: newName,
      })
        .finally(() => resolve())
      });
  } 

  // kwargs={
  //   technologyEnum,
  //   rowIndex
  // }
  async function removeAllIntegrations(kwargs = {}) {
    if (passedOptionEnum99) {
      await IndependentGetAPIData("261", authTokens, {
        OptionEnum1: kwargs.technologyEnum,
        // OptionEnum2: 6,
        optionEnum2: clientId,
        optionEnum99: passedOptionEnum99
      })
        .then((response) => {
        
          let updated_data = [...toolData];
          updated_data[kwargs.rowIndex].loading = false;
          updated_data[kwargs.rowIndex].IntegrationList = [];
          updated_data[kwargs.rowIndex].ShowSelectedTool = false;
          updated_data[kwargs.rowIndex].SelectedTool_List = [];
          setToolData(updated_data);
  setLoading(false)
          if (response?.mr?.Success) {
            // getCyberScoreData({ showUpdateToast: true });
          } else {
            // getCyberScoreData({ showErrorToast: true });
          }
          getCyberScoreData({
            keepToolsAtRow: 1,
          });
        })
        .catch((err) => {
          getCyberScoreData({ showErrorToast: true });
        });
    } else {
      await getAPIData("261", authTokens, {
        OptionEnum1: kwargs.technologyEnum,
        // OptionEnum2: 6,
        optionEnum2: clientId,
      })
        .then((response) => {
        
          let updated_data = [...toolData];
          updated_data[kwargs.rowIndex].loading = false;
          updated_data[kwargs.rowIndex].IntegrationList = [];
          updated_data[kwargs.rowIndex].ShowSelectedTool = false;
          updated_data[kwargs.rowIndex].SelectedTool_List = [];
          setToolData(updated_data);
  setLoading(false)
          if (response?.mr?.Success) {
            // getCyberScoreData({ showUpdateToast: true });
          } else {
            // getCyberScoreData({ showErrorToast: true });
          }
          getCyberScoreData({
            keepToolsAtRow: 1,
          });
        })
        .catch((err) => {
          getCyberScoreData({ showErrorToast: true });
        });
    }
  }

  function itemsLoading() {
    let isLoading = toolData?.some((toolRow) => {
      let integrationLoading = toolRow?.IntegrationList?.some(
        (integrationItem) => integrationItem?.loading === true
      );

      return integrationLoading || toolRow?.loading || toolRow?.addingNewTool;
    });

    return isLoading;
  }

  // kwargs={
  //   parentID,
  //   rowIndex,
  //   open,
  // }
  function ToggleIndexRow(kwargs = {}) {
    // let parent = document.getElementById(
    //   kwargs.parentID ?? "cyber-score-group-wrapperr"
    // );
    let parent = document.querySelector(
      `#${kwargs.parentID ?? "cyber-score-group-wrapperr"}`
    );
    console.log("parent",parent)
    let all_rows = parent.querySelectorAll(".point");
    console.log("all_rows",all_rows)
    let selected_row = all_rows[kwargs.rowIndex];
    console.log("selected_row",selected_row)
    let panel = selected_row.querySelector(".panel");
    console.log("panel",panel)
    let down_arrow_icon = selected_row.querySelector(".rowDownArrow");

    if (kwargs.open) {
      panel.style.maxHeight = panel.scrollHeight + "px";
      if (down_arrow_icon) {
        down_arrow_icon.classList.add("rotate180");
      }
    } else {
      panel.style.maxHeight = null;
      if (down_arrow_icon) {
        down_arrow_icon.classList.remove("rotate180");
      }
    }
  }

  // kwargs= {
  //   rowIndex,
  //   technologyEnum
  // }
  function getTools(kwargs = {}) {
    return new Promise((resolve, reject) => {
      if (passedOptionEnum99) {
        IndependentGetAPIData(262, authTokens, {
          optionEnum1: kwargs.technologyEnum,
          optionEnum2: clientId,
          optionEnum99: passedOptionEnum99
        })
          .then((response) => {
            let updated_data = [...toolData];
            updated_data[kwargs.rowIndex].IntegrationList = [
              ...response?.TechnologyTool_List,
            ];
            updated_data[kwargs.rowIndex].loading = false;
            setToolData(updated_data);
            resolve();
          })
          .catch((err) => {});
      } else {
        getAPIData(262, authTokens, {
          optionEnum1: kwargs.technologyEnum,
          optionEnum2: clientId,
        })
          .then((response) => {
            let updated_data = [...toolData];
            updated_data[kwargs.rowIndex].IntegrationList = [
              ...response?.TechnologyTool_List,
            ];
            updated_data[kwargs.rowIndex].loading = false;
            setToolData(updated_data);
            resolve();
          })
          .catch((err) => {});
      }
    });
  }

  function openTheDropDown(e) {
    let _row = e.target.closest(".border-item");
    console.log(_row, "_row");
    if (_row) {
      let _dropdown = _row.querySelector(`.custom_drop-down .dropdown-toggle`);
      console.log(_dropdown, "_dropdow");
      if (_dropdown) {
        _dropdown.click();
      }
    }
  }

  return (
    <div
      id="cyber-score-group-wrapperr"
      className="w-100 position-relative"
      style={{
        pointerEvents:
          accessStateValue("AccessState_MSP_Security_Stack") === 1
            ? "none"
            : "all",
      }}
    >
       {
            loading && <div className="position-absolute" style={{
              top:"0px",
              left:"0px",
              bottom:"0px",
              right:"0px",
              opacity:0.5,
              background:"#fff",
              zIndex:300
            }}></div>
          }
      {mainPageLoading ? (
        <CreateClientComplianceLoader />
      ) : (
        <>
          <div className="search-container">
            <SearchInput 
              placeholder="Search" 
              value={searchComponent} 
              onChange={(value) => setSearchComponent(value)} 
            />
            <KPISettingsDropdown 
              showAttestationIcons={showAttestationIcons} 
              setShowAttestationIcons={setShowAttestationIcons}
              switchId="showAttestationIcons"
            />
          </div>

          {
            showActivePackage && <div className="mb-2">
              {
                activePackageName && activePackageId ? <div className="bg-lightblue py-2 px-3 radius-4">
                  <div className="f-400 f-14 f-black line-height-22 letter-space-2">
                    Some of these components are covered by an active security package.
                  </div>
                  <div 
                    className="d-flex align-items-center pointer"
                    onClick={() => {
                      history.push(
                        `/mspv2/clients/clientsView/${clientId}/managed-security`, {
                          activePackageId: activePackageId
                        }
                      );
                    }}
                  >
                    <img
                      alt=""
                      height={13}
                      className="mr-2"
                      src="/images/msp/msp-package-icon.svg"
                    />
                    <div className="f-400 f-14 f-grey line-height-22 letter-space-2">
                      {activePackageName}
                    </div>
                  </div>
                  </div> : <div className="d-flex align-items-center bg-lightblue py-2 px-3 radius-4">
                  <img
                    alt=""
                    height={15}
                    className="mr-2"
                    src="/images/navIcons/calculator.svg"
                  />
                  <div className="f-400 f-14 f-black line-height-22 letter-space-2 mr-1">
                    Apply a security package in
                  </div>
                  <div 
                    className="f-500 f-14 f-blue line-height-22 letter-space-2 pointer"
                    onClick={() => {
                      history.push(
                        `/mspv2/clients/clientsView/${clientId}/managed-security`
                      );
                    }}
                  >
                    Managed Security
                  </div>
                </div>
              }
            </div>
          }

          <div
            className={`bg-grey radius-8 border-parent   ${itemsLoading() ? "stop-all-events" : ""
              }`}
            id="stack-wrapper"
          >
            <div className="border-item f-12 f-500 f-grey header-section cyber-score-table-row">
              <div>Component</div>
            </div>
            {toolData?.map((tool, toolIndex) => {
              const toolTitle = SearchTitle({ title: tool?.TechnologyTitle, searchValue: searchComponent });
              if (!toolTitle) return null;
              return (
                <div
                  className="point  border-item"
                  onClick={(e) => {
                    if (tool?.selected?.category === "Yes" && tool?.IntegrationList?.length === 0) {
                      setLoading(true);
                      getTools({
                        technologyEnum: tool?.TechnologyEnum,
                        rowIndex: toolIndex,
                      }).then(() => {
                        ToggleAccordion(e, true);
                        ToggleIndexRow({
                          open: true,
                          rowIndex: toolIndex,
                        });
                        setLoading(false)
                      });
                    } else if (tool?.selected?.category === "No") {
                      ToggleAccordion(e, true);
                    } else if (tool?.IntegrationList?.length > 0) {
                      ToggleAccordion(e, true);
                    } else {
                      openTheDropDown(e, "custom_drop-down");
                    }
                  }}
                >
                  {tool?.Visible && (
                    <div className={`d-flex flex-column align-items-center justify-content-between bg-hover-row w-100 row-section ${
                      viewOnlyAccess(AccessStateLabel ?? "AccessState_Dashboard") ? 'cursor-default' : 'pointer'
                    }`}>
                      <div className="d-flex align-items-center justify-content-between w-100 ">
                        <div className="d-flex align-items-center  w-85">
                          {showAttestationIcons && (
                            <div className="mr-2">
                              <AttestationPopUp>
                                <img
                                  alt=""
                                  src={
                                    Attestation_Icon_Mappings[
                                      tool?.ScoreAttestationRow?.ScoreAttestation
                                    ]
                                  }
                                  className=""
                                />
                              </AttestationPopUp>
                            </div>
                          )}
                          <div className="d-flex flex-column">
                            <div className="d-flex align-items-baseline ">
                              <p className="f-500 m-0 component-title">{toolTitle}</p>
                            </div>
                            <div className="f-12 f-darkgrey component-question">
                                {tool?.TechnologyQuestion}
                              </div>
                          </div>
                        </div>

                        <div
                          className="option-dropdown d-flex align-items-center"
                          onClick={(e) => e.stopPropagation()}
                        >
                          <div>{tool?.loading && false &&<GreyLoader />}</div>
                          {
                            viewOnlyAccess(AccessStateLabel ?? "AccessState_Dashboard") ?  
                            <div className="f-darkgrey">
                              {tool?.selected?.category ?? 'No Selected'}
                              </div>
                            :
                          <CompanySettingsDropdown
                            error={tool?.error}
                            transparentBackgroundToggle
                            fieldId={"id"}
                            fieldValue={"category"}
                            selected={tool.selected}
                            placeholder="Select"
                            //  showid={true}
                            onClick={(id, val, e) => {
                              if (tool?.selected?.category === val) return;
                              let updated_data = [...toolData];

                              updated_data[toolIndex].selected = {
                                id,
                                category: val,
                              };
                              updated_data[toolIndex].error = false;
                              updated_data[toolIndex].loading = true;

                              // var parent = e.target.closest(".point");

                              if (val === "Yes") {
                                // let panel = parent.querySelector(".panel");

                                // if (panel?.style?.maxHeight) {
                                //   // panel.style.maxHeight = null;
                                // } else {
                                //   panel.style.maxHeight =
                                //     panel.scrollHeight + "px";
                                // }
                                setLoading(true);
                                getTools({
                                  technologyEnum: tool?.TechnologyEnum,
                                  rowIndex: toolIndex,
                                }).then(() => {
                                  ToggleAccordion(e, true);
                                  ToggleIndexRow({
                                    open: true,
                                    rowIndex: toolIndex,
                                  });
                                  setLoading(false)
                                });
                              }

                              if (val === "No") {
                                updated_data[toolIndex].loading = true;
                                setLoading(true)
                                removeAllIntegrations({
                                  rowIndex: toolIndex,
                                  technologyEnum:
                                    updated_data[toolIndex]?.TechnologyEnum,
                                }).then(() => {
                                  ToggleIndexRow({
                                    open: true,
                                    rowIndex: toolIndex,
                                  });
                                });
                              }

                              setToolData(updated_data);
                            }}
                            data={[
                              { id: 1, category: "Yes" },
                              { id: 0, category: "No" },
                            ]}
                          />
                          }
                        </div>
                      </div>
                    </div>
                  )}
                  {!tool?.Visible && (
                    <div className="align-items-center  bg-hover-row w-100 pointer px-4  py-3 cyber-score-table-row w-100 row-section">
                      {/* first Column */}
                      <div className="d-flex align-items-center  ">
                        {showAttestationIcons && (
                          <div className="mr-2">
                            <AttestationPopUp>
                              <img
                                alt=""
                                src="/images/attack-surface/unverified.svg"
                                className=""
                              />
                            </AttestationPopUp>
                          </div>
                        )}
                        <div className="d-flex flex-column">
                          <div className="d-flex align-items-baseline ">
                            <p className="f-500 m-0 component-title">{toolTitle}</p>
                          </div>
                          {tool?.ShowSelectedTool ? (
                            <>
                              {tool?.SelectedTool_List?.map(
                                (connectedTool, connectedToolIndex) => {
                                  return (
                                    <div className="d-flex align-items-center ">
                                      <img
                                        alt=""
                                        width={16}
                                        height={16}
                                        className="rounded-full"
                                        src={`/images/attack-surface/integrations/${connectedTool?.obj_IntegrationId}.webp`}
                                        onError={({ currentTarget }) => {
                                          currentTarget.onerror = null; // prevents looping
                                          currentTarget.src =
                                            "/images/attack-surface/generic2.svg";
                                        }}
                                      />
                                      <div className="d-flex align-items-center mx-2 f-12 f-500 f-black">
                                        {connectedTool?.ToolName}
                                      </div>
                                      <div className="d-flex align-items-center f-12 f-500  ">
                                        {!connectedTool?.IsConnected && (
                                          <div
                                            className="f-blue mr-2"
                                            onClick={(e) => {
                                              e.stopPropagation();
                                              setSelectedIntegrationId(
                                                connectedTool?.obj_IntegrationId
                                              );
                                              setShowMspConnectModal(true);
                                            }}
                                          >
                                            {/* Connect{" "} */}
                                          </div>
                                        )}
                                        {/* <div
                                    className="f-darkgrey"
                                    onClick={
                                      (e) => {}
                                      // ToggleAccordion(e, true)
                                    }
                                  >
                                    Change
                                  </div> */}
                                      </div>
                                    </div>
                                  );
                                }
                              )}
                            </>
                          ) : (
                            <div className="f-12 f-darkgrey component-question">
                              {tool?.TechnologyQuestion}
                            </div>
                          )}
                        </div>
                      </div>

                      {/* Second column */}
                      <div className="f-black">
                        Endpoint Protection is Healthy
                      </div>

                      {/* Thhird column */}
                      <div className="text-align-end">
                        15
                        <img
                          src="/images/big-chevron-down.svg"
                          alt=""
                          className="rowDownArrow mr-2 ml-2"
                        />
                      </div>
                    </div>
                  )}
                  <div style={{ padding: '0 16px' }} className="panel" onClick={(e) => e.stopPropagation()}>
                    {tool?.selected?.category === "No" ? (
                      <NoteSection onResize={(target) => {
                        const parent = target.closest(".point");
                        let panel = parent.querySelector(".panel");
                        if (!panel.style.maxHeight) return;
                        panel.style.maxHeight = panel.scrollHeight + 20 + "px";
                      }} />
                    ) : tool?.IntegrationList?.length > 0  ? (
                      <div className="tool-listing-wrapper ml-4 d-flex flex-column" style={{ gap: '4px' }}>
                        {tool?.IntegrationList
                          ?.filter(option => option?.ToolSelected)
                          ?.map((option, optionIndex) => (
                            <IntegrationListItem 
                              key={`io-${option?.ToolName}-${optionIndex}`} 
                              option={option} 
                              integrationName={option?.ToolName}
                              integrationId={option?.obj_IntegrationId}
                              isLoading={option?.loading}
                              optionIndex={optionIndex}
                              showRename={option?.IsUserDefined}
                              style={{ marginLeft: '16px' }}
                              onRenameOption={(integrationId, newName) => {
                                let updated_data = [...toolData];
                                const optionIndex = tool?.IntegrationList
                                  ?.findIndex(item => item?.obj_IntegrationId === integrationId);
                                updated_data[toolIndex].IntegrationList[
                                  optionIndex
                                ].loading = true;
                                renameIntegration(integrationId, newName).then(() => {
                                  getTools({
                                    technologyEnum: tool?.TechnologyEnum,
                                    rowIndex: toolIndex,
                                  }).then(() => {
                                    getCyberScoreData({
                                      keepToolsAtRow: toolIndex,
                                    });
                                  });
                                });
                                setToolData(updated_data);
                              }}
                              onRemoveOption={(integrationId) => {
                                let updated_data = [...toolData];
                                const optionIndex = tool?.IntegrationList
                                  ?.findIndex(item => item?.obj_IntegrationId === integrationId);
                                updated_data[toolIndex].IntegrationList[
                                  optionIndex
                                ].loading = true;
                                disconnectIntegration(
                                  integrationId
                                ).then(() => {
                                  getTools({
                                    technologyEnum: tool?.TechnologyEnum,
                                    rowIndex: toolIndex,
                                  }).then(() => {
                                    getCyberScoreData({
                                      keepToolsAtRow: toolIndex,
                                    });
                                  });
                                });
                                setToolData(updated_data);
                              }}
                              onAddSubscription={(integrationId) => {
                                const integration = tool?.IntegrationList?.find(item => item?.obj_IntegrationId === integrationId);
                                if (!integration) return;
                                const query = `?integrationId=${integrationId}&integrationName=${integration.ToolName}`;
                                const url = `${ROUTE_PATHS.mspv2.clients.technologySpend(clientId)}${query}`;
                                history.push(url);
                              }}  
                            />
                          ))}

                        {!tool?.addNewTool ? (
                          <SelectToolDropdown 
                            integrationList={tool?.IntegrationList?.filter(option => !option?.ToolSelected)}
                            addingNewTool={toolData[toolIndex].addingNewTool}
                            onOptionAdd={(option) => {
                              return new Promise((resolve, reject) => {
                                let updated_data = [...toolData];
                                const optionIndex = tool?.IntegrationList
                                  ?.findIndex(item => item?.obj_IntegrationId === option?.obj_IntegrationId);
                                updated_data[toolIndex].IntegrationList[
                                  optionIndex
                                ].loading = true;
                                addNewIntegration({
                                  technologyEnum:
                                    updated_data[toolIndex].TechnologyEnum,
                                  IntegrationId: option?.obj_IntegrationId,
                                  toolName: option?.IntegrationName,
                                  selected: true,
                                  rowIndex: toolIndex,
                                }).then(() => {
                                  getTools({
                                    technologyEnum: tool?.TechnologyEnum,
                                    rowIndex: toolIndex,
                                  }).then(() => {
                                    getCyberScoreData({
                                      keepToolsAtRow: toolIndex,
                                    });
                                  });
                                })
                                .finally(() => resolve());
                                setToolData(updated_data);
                              });
                            }}
                            onNewOptionAdd={(optionName) => {
                              return new Promise((resolve, reject) => {
                                if (optionName === "") {
                                  resolve();
                                  return;
                                };
                                let updated_data = [...toolData];
                                updated_data[toolIndex].addingNewTool = true;
                                setToolData(updated_data);
                                addNewIntegration({
                                  technologyEnum:
                                    updated_data[toolIndex].TechnologyEnum,
                                  IntegrationId: 0,
                                  toolName: optionName,
                                  selected: true,
                                  rowIndex: toolIndex,
                                }).then(() => {
                                  let updated_data = [...toolData];
                                  updated_data[toolIndex].addNewTool = false;
                                  updated_data[toolIndex].newToolName = "";
                                  setToolData(updated_data);
                                  getTools({
                                    technologyEnum: tool?.TechnologyEnum,
                                    rowIndex: toolIndex,
                                  }).then(() => {
                                    getCyberScoreData({
                                      keepToolsAtRow: toolIndex,
                                    });
                                  });
                                }).finally(() => resolve());
                              })
                            }}
                          />
                        ) : (
                          <div className="d-flex align-items-center mb-3 mt-2">
                            <div className="checkbox-margin ml-3 mb-2">
                              <Form.Group
                                controlId={`card ${toolIndex}  `}
                                className={`round-checkbox blue-checkbox checkboxstyle mb-3 radio-shadow`}
                              >
                                <Form.Check
                                  type={"checkbox"}
                                  checked={true ?? tool?.newToolSelected}
                                  name={`check `}
                                  label=""
                                  // onChange={(e) => {
                                  //   let updated_data = [...toolData];
                                  //   updated_data[toolIndex].newToolSelected =
                                  //     e.target.checked;
                                  //   setToolData(updated_data);
                                  // }}
                                />
                              </Form.Group>
                            </div>
                            <img
                              alt=""
                              width={24}
                              height={24}
                              src="/images/attack-surface/generic2.svg"
                              className="ml-3 mr-2"
                            />
                            <div className="tool-creator-field d-flex align-items-center ">
                              <input
                                type="text"
                                value={tool?.newToolName}
                                onChange={(e) => {
                                  let updated_data = [...toolData];
                                  updated_data[toolIndex].newToolName =
                                    removeWhiteSpace(e.target.value);
                                  setToolData(updated_data);
                                }}
                                autoFocus
                              />
                              <Button
                                className=""
                                onClick={(e) => {
                                  if (tool?.newToolName === "") return;
                                  let updated_data = [...toolData];
                                  updated_data[toolIndex].addingNewTool = true;
                                  setToolData(updated_data);
                                  addNewIntegration({
                                    technologyEnum:
                                      updated_data[toolIndex].TechnologyEnum,
                                    IntegrationId: 0,
                                    toolName: tool?.newToolName,
                                    selected: true,
                                    rowIndex: toolIndex,
                                  }).then(() => {
                                    let updated_data = [...toolData];
                                    updated_data[toolIndex].addNewTool = false;
                                    updated_data[toolIndex].newToolName = "";

                                    setToolData(updated_data);
                                    getTools({
                                      technologyEnum: tool?.TechnologyEnum,
                                      rowIndex: toolIndex,
                                    }).then(() => {
                                      getCyberScoreData({
                                        keepToolsAtRow: toolIndex,
                                      });
                                    });
                                  });
                                }}
                              >
                                {tool?.addingNewTool ? (
                                  <span className="d-inline-flex align-items-center">
                                    <Spinner
                                      animation="border"
                                      variant="light"
                                      size="sm"
                                      className="mx-1"
                                      style={{
                                        width: "10px",
                                        height: "10px",
                                      }}
                                    />
                                    <span className="d-inline-flex align-items-center">
                                      Add
                                    </span>
                                  </span>
                                ) : (
                                  "Add"
                                )}
                              </Button>
                            </div>
                            <img
                              alt=""
                              src="/images/attack-surface/small-cross.svg"
                              className="ml-2 pointer"
                              onClick={() => {
                                let updated_data = [...toolData];
                                updated_data[toolIndex].addNewTool = false;
                                setToolData(updated_data);
                              }}
                            />
                          </div>
                        )}
                      </div>
                    ) : null}
                  </div>
                </div>
              );
            })}

            <div className="border-item f-12 f-500 f-grey p-2 text-align-end">
              <div className="p-2">
                <span className="f-grey">
                  Your {cyberScoreData?.ScoreText} is
                </span>
                <span className="f-black f-500 mx-2">
                  {cyberScoreData?.ScoreValue}{" "}
                </span>
                <span
                  className=""
                  style={{
                    color: cyberScoreData?.ScoreRatingColour,
                  }}
                >
                  {cyberScoreData?.ScoreRating}
                </span>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default CyberScoreForGroupModal;
