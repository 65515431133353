import { useRef, useEffect } from 'react';

const useResize = ({ onResize }) => {
  const textareaRef = useRef(null);

  useEffect(() => {
    const resizeObserver = new ResizeObserver(entries => {
      if (onResize) {
        for (let entry of entries) {
          onResize(textareaRef.current, entry.contentRect.width, entry.contentRect.height);
        }
      }
    });

    resizeObserver.observe(textareaRef.current);

    return () => {
      resizeObserver.disconnect();
    };
  }, [onResize]);

  return textareaRef;
}

export default useResize;
