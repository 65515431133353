import React, { useEffect, useRef, useState } from 'react'
import "./scrollableDivWithShadow.scss"
const ScrollableDivWithShadow = ({children,style,className}) => {
    const scrollRef = useRef(null);
    const [shadowTop, setShadowTop] = useState(false);
    const [shadowBottom, setShadowBottom] = useState(false);
  
    const handleScroll = () => {
      const div = scrollRef.current;
      if (!div) return;
  
      const isScrollable = div.scrollHeight > div.clientHeight;
      const scrollTop = div.scrollTop;
      const scrollHeight = div.scrollHeight;
      const clientHeight = div.clientHeight;
  
      if (isScrollable) {
        setShadowTop(scrollTop > 0);
        setShadowBottom(scrollTop < (scrollHeight - clientHeight-2));
        console.log(scrollTop ,"<", scrollHeight, "-", clientHeight);
      } else {
        setShadowTop(false);
        setShadowBottom(false);
      }
    };
    console.log(shadowTop,"shadowTop")
    console.log(shadowBottom,"shadowBottom")
  
    useEffect(() => {
      const div = scrollRef.current;
      if (div) {
        div.addEventListener('scroll', handleScroll);
        handleScroll();  // Initialize shadows based on initial scroll position
        return () => {
          div.removeEventListener('scroll', handleScroll);
        };
      }
    }, []);
  
    return (<div className='position-relative'> 
         {shadowTop && <div className='position-absolute top-0 shadow-top' style={{
            boxShadow:"0 4px 8px rgba(0, 0, 0, 0.1)"
        }}></div>}
      <div
        ref={scrollRef}
        className={`scrollable-hg-div ${shadowTop ? '' : ''} ${shadowBottom ? '' : ''} ${className} position-relative`}
        style={style}
      >
       
        {children}
      
      </div>
      {shadowBottom && <div className='position-absolute bottom-0 shadow-bottom '
        style={{
            boxShadow:"0 -4px 8px rgba(0, 0, 0, 0.1)"
        }}
        ></div>}
      </div>
    );
}

export default ScrollableDivWithShadow