import React, { useEffect, useState } from "react";
import {
  Modal,
  Form,
  Col,
  Row,
  InputGroup,
   
  Button,
} from "react-bootstrap";
import "./modals.scss";
import { useAuth } from "../../context/Auth";
import {
  
  getAPIData,
  postRequest,
} from "../../../Utils/commonApi";
import { CallToast } from "./CustomToast";

function AssetModal(props) {
  const { authTokens } = useAuth();
  const {
    showAssetModal,
    closeAssetModal,
    values,
    setValues,
    activeTab,
    newScenario,
    DrawGraph,
    updatesScenarioData
    // getScenarioListData,
  } = props;

  var { users, domains, servers, workstations, mobiledevices } =
    setValues.state.assetModalValues;
  const closeModal = () => closeAssetModal();
const [hideReset, setHideReset] = useState(true)
  useEffect(() => {
    setHideReset(true);
    setValues.setState({ test: true });

    if (
      activeTab !== 0 &&
      setValues.state.scenarioData?.ScenarioHheaderList?.length > 0
    ) {
      const data = { ...setValues.state.assetModalValues };
      data.users = values?.NoOfUsers;
      data.workstations = values?.NoOfWorkstation;
      data.servers = values?.NoOfServices;
      data.mobiledevices = values?.NoOfMobileDevices;
      data.domains = values?.NoOfDomains;

      setValues.setState({ assetModalValues: data });
    }
    if (
      activeTab == 0 &&
      setValues.state.scenarioData?.UserAndDeviceCount_Default
    ) {
      const data = { ...setValues.state.assetModalValues };
      data.users =
        setValues.state.scenarioData?.UserAndDeviceCount_Default?.NoOfUsers;
      data.workstations =
        setValues.state.scenarioData?.UserAndDeviceCount_Default?.NoOfWorkstation;
      data.servers =
        setValues.state.scenarioData?.UserAndDeviceCount_Default?.NoOfServices;
      data.mobiledevices =
        setValues.state.scenarioData?.UserAndDeviceCount_Default?.NoOfMobileDevices;
      data.domains =
        setValues.state.scenarioData?.UserAndDeviceCount_Default?.NoOfDomains;

      setValues.setState({ assetModalValues: data });
    }
  }, [showAssetModal]);

  const resetToDefault = () => {
    if (
      activeTab == 0 &&
      setValues.state.scenarioData?.UserAndDeviceCount_Default
    ) {
      const data = { ...setValues.state.assetModalValues };
      data.users =
        setValues.state.scenarioData?.UserAndDeviceCount_Default?.NoOfUsers;
      data.workstations =
        setValues.state.scenarioData?.UserAndDeviceCount_Default?.NoOfWorkstation;
      data.servers =
        setValues.state.scenarioData?.UserAndDeviceCount_Default?.NoOfServices;
      data.mobiledevices =
        setValues.state.scenarioData?.UserAndDeviceCount_Default?.NoOfMobileDevices;
      data.domains =
        setValues.state.scenarioData?.UserAndDeviceCount_Default?.NoOfDomains;

      setValues.setState({ assetModalValues: data });
    } else {
      const data = { ...setValues.state.assetModalValues };
      data.users = values?.NoOfUsers;
      data.workstations = values?.NoOfWorkstation;
      data.servers = values?.NoOfServices;
      data.mobiledevices = values?.NoOfMobileDevices;
      data.domains = values?.NoOfDomains;

      setValues.setState({ assetModalValues: data });
    }
    setHideReset(true);
  };

  const AddEditScenario = async (target) => {
    await postRequest(authTokens, "UpdateUserSettingsAssetCounts", {
      // ObjectId:
      //   setValues.state.tabs[activeTab]?.ScenarioHeader?.objId_Scenario || 0,
      // Name: setValues.state.tabs[activeTab]?.ScenarioHeader?.Name || "Untitled",
      UpdateTarget: target,
      NoOfUsers: users,
      NoOfDomains: domains,
      NoOfWorkstation: workstations,
      NoOfServers: servers,
      NoOfMobileDevices: mobiledevices,
      // BillingPeriod:
      //   setValues.state.tabs[activeTab]?.ScenarioHeader?.BillingPeriod || 0,
      accessKey: JSON.parse(localStorage.getItem("user_MessageObj")),
    }).then(async (response) => {
      if (response.Success === true) {
        if (target === 0) {
          newScenario();
        }
        closeModal();
        setValues.setState({
          updateScenarioModal: false,
          showAssetModal: false,
        });
        CallToast("Default quantity of assets was successfully saved.", false);

        // const response = await getAPIData(211, authTokens);

        // if (response?.mr?.Success) {
        //   setValues.setState({ scenarioData: response });
        // }
      } else {
        CallToast("Something went wrong");
        closeModal();
      }
    });
  };

  const EditAssetValues = async () => {
    await postRequest(authTokens, "AddEditScenario", {
      ObjectId: setValues.state.tabs[activeTab]?.ScenarioHeader?.objId_Scenario,
      Name: setValues.state.tabs[activeTab]?.ScenarioHeader?.Name || "Untitled",
      NoOfUsers: users,
      NoOfDomains: domains,
      NoOfWorkstation: workstations,
      NoOfServices: servers,
      NoOfMobileDevices: mobiledevices,
      BillingPeriod:
        Number(setValues.state.tabs[activeTab]?.ScenarioHeader?.BillingPeriod),
      accessKey: JSON.parse(localStorage.getItem("user_MessageObj")),
    }).then(async (response) => {
      if (response.Success === true) {
        let test_tabs = [...setValues.state.tabs];
        test_tabs[activeTab].ScenarioHeader.UserAndDeviceCount.NoOfUsers =
          users;
        test_tabs[activeTab].ScenarioHeader.UserAndDeviceCount.NoOfDomains =
          domains;
        test_tabs[activeTab].ScenarioHeader.UserAndDeviceCount.NoOfWorkstation =
          workstations;
        test_tabs[activeTab].ScenarioHeader.UserAndDeviceCount.NoOfServices =
          servers;
        test_tabs[
          activeTab
        ].ScenarioHeader.UserAndDeviceCount.NoOfMobileDevices = mobiledevices;

        closeModal();
        setValues.setState({
          updateScenarioModal: false,
          showAssetModal: false,
          tabs: test_tabs,
        });
if(updatesScenarioData){
  updatesScenarioData()
}
        const response = await getAPIData(211, authTokens);

        if (response?.mr?.Success) {
          setValues.setState({ scenarioData: response });
        }

        CallToast("Quantity of assets was successfuly saved for scenario");
      } else {
        CallToast("Something went wrong");
      }
    });
  };

  useEffect(() => {
    if (showAssetModal) {
      DrawGraph(false);
    } else {
      DrawGraph(true);
    }
  }, [showAssetModal]);

  return (
    <Modal
      animation={false}
      // show={true}
      show={showAssetModal}
      dialogClassName="modal-50w"
      className="qty-asset-modal integrationModal centerModal dashboardModal upgradePremium"
      aria-labelledby="asset-modal"
      centered
      scrollable
      onHide={() => {
        resetToDefault();
        closeModal();
      }}
    >
      <Modal.Header className="text-center">
        <div className="w-100 mb-0 font-bold-14">
          {activeTab !== 0
            ? "Quantity of Assets"
            : "Default Quantity of Assets"}
        </div>

        <span className="cursor-pointer">
          <img
            src="/images/icons/close.svg"
            alt=""
            onClick={() => closeModal()}
          />
        </span>
      </Modal.Header>
      <Modal.Body>
        <Row className="">
          <Col xs={12} className="font-bold-14">
            {activeTab !== 0
              ? "Qty Users & Devices"
              : "Default Qty Users & Devices"}
          </Col>
          <Col xs={12} className="font-subtitle">
            {activeTab !== 0
              ? "Please let us know how many users, workstations, servers, mobile devices and domains you have in your organisation."
              : "New pricing scenarios will be created based on this quantities.Existing pricing could be updated with this quantities if you wish."}
          </Col>

          <Col xs={12}>
            <Form.Group>
              <InputGroup className="mt-2 grey-select ">
                <InputGroup.Prepend>
                  <InputGroup.Text className="greyText inptLbl">
                    Users:
                  </InputGroup.Text>
                </InputGroup.Prepend>

                <Form.Control
                  as="input"
                  type="number"
                  placeholder="Ex.: 100"
                  value={users}
                  onChange={(e) => {
                    const data = { ...setValues.state.assetModalValues };
                    data.users = e.target.value;
                    setValues.setState({ assetModalValues: data });
                    setHideReset(false);
                  }}
                />
              </InputGroup>
            </Form.Group>

            <Form.Group>
              <InputGroup className="mt-2 grey-select ">
                <InputGroup.Prepend>
                  <InputGroup.Text className="greyText inptLbl">
                    Workstations:
                  </InputGroup.Text>
                </InputGroup.Prepend>

                <Form.Control
                  as="input"
                  type="number"
                  placeholder="Ex.: 100"
                  value={workstations}
                  onChange={(e) => {
                    const data = { ...setValues.state.assetModalValues };
                    data.workstations = e.target.value;
                    setValues.setState({ assetModalValues: data });
                    setHideReset(false);
                  }}
                />
              </InputGroup>
            </Form.Group>

            <Form.Group>
              <InputGroup className="mt-2 grey-select ">
                <InputGroup.Prepend>
                  <InputGroup.Text className="greyText inptLbl">
                    Servers:
                  </InputGroup.Text>
                </InputGroup.Prepend>

                <Form.Control
                  as="input"
                  type="number"
                  placeholder="Ex.: 100"
                  value={servers}
                  onChange={(e) => {
                    const data = { ...setValues.state.assetModalValues };
                    data.servers = e.target.value;
                    setValues.setState({ assetModalValues: data });
                    setHideReset(false);
                  }}
                />
              </InputGroup>
            </Form.Group>

            <Form.Group>
              <InputGroup className="mt-2 grey-select ">
                <InputGroup.Prepend>
                  <InputGroup.Text className="greyText inptLbl">
                    Mobile Devices:
                  </InputGroup.Text>
                </InputGroup.Prepend>

                <Form.Control
                  as="input"
                  type="number"
                  placeholder="Ex.: 100"
                  value={mobiledevices}
                  onChange={(e) => {
                    const data = { ...setValues.state.assetModalValues };
                    data.mobiledevices = e.target.value;
                    setValues.setState({ assetModalValues: data });
                    setHideReset(false);
                  }}
                />
              </InputGroup>
            </Form.Group>

            <Form.Group>
              <InputGroup className="mt-2 grey-select ">
                <InputGroup.Prepend>
                  <InputGroup.Text className="greyText inptLbl">
                    Domains:
                  </InputGroup.Text>
                </InputGroup.Prepend>

                <Form.Control
                  as="input"
                  type="number"
                  placeholder="Ex.: 100"
                  value={domains}
                  onChange={(e) => {
                    const data = { ...setValues.state.assetModalValues };
                    data.domains = e.target.value;
                    setValues.setState({ assetModalValues: data });
                    setHideReset(false);
                  }}
                />
              </InputGroup>
            </Form.Group>
          </Col>
        </Row>
      </Modal.Body>

      <Modal.Footer>
        <Row className="m-0 ">
          {(users !== 0 ||
            workstations !== 0 ||
            domains !== 0 ||
            servers !== 0 ||
            mobiledevices !== 0) && !hideReset&&(
            <Col xs={6}>
              <div className="reset-to-default" onClick={resetToDefault}>
                <img alt="" src="/images/refresh.png" />
                <span>Reset to Default</span>
              </div>
            </Col>
          )}

          <Col className="p-0 d-flex justify-content-end">
            <Button className="cancel-button" onClick={closeModal}>
              Cancel
            </Button>
            <Button
              className="save-button"
              onClick={() => {
                if (activeTab !== 0) {
                  // AddEditScenario();
                  EditAssetValues();
                } else if (
                  setValues.state.scenarioData?.ShowModals == true ||
                  setValues.state.scenarioData?.ScenarioHheaderList?.length == 0
                ) {
                  AddEditScenario(0);
                  closeModal();
                } else {
                  setValues.setState({ updateScenarioModal: true });
                }
              }}
            >
              {!setValues.state.scenarioData?.ShowModals ||
              setValues.state.scenarioData?.ScenarioHheaderList?.length > 0
                ? "Update"
                : "Save"}
            </Button>
          </Col>
        </Row>
      </Modal.Footer>
    </Modal>
  );
}

export default AssetModal;
