import React, { useEffect, useState } from 'react';

function ImageLoader(props) {
  const { noImageComponent, ...rest } = props;
  const [imageLoaded, setImageLoaded] = useState(true);
  const [curImage, setCurImage] = useState('');
  const handleImageError = () => {
    setImageLoaded(false);
  };

  useEffect(() => {
    if (curImage === props.src) return;
    setCurImage(props.src);
    setImageLoaded(true);
  }, [props.src]);

  if ((!rest.src || !imageLoaded) && props.noImageComponent) {
    return props.noImageComponent;
  }

  return (
    <img {...rest} src={rest.src || ''} onError={handleImageError} />
  );
}

export default ImageLoader;