import React, { useEffect, useState } from "react";
import "./security-ratings.scss";
import "../supplier-profiler.scss";
import SecurityRatingsDatePicker from "../../test/SecurityRatingsDatePicker";
import moment from "moment";
import { useAuth } from "../../context/Auth";
import {
  SubmitQuestionaire,
  getAPIData,
  postData,
} from "../../../Utils/commonApi";
import { CallToast } from "../../PriceCalculator/modals/CustomToast";
import ContentLoader from "react-content-loader";
import { GreyLoader } from "../../Theme/APILoaders";
import { DeleteModal } from "../../DeleteModal/DeleteModal";
import { viewOnlyAccess } from "../../App/App";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import RevokeContributorsDropdown from "../ProfilerDropdowns/RevokeContributorsDropdown";
import EmptyModal from "../EmptyModal";
import SecurityRatingBlock from "../AssessmentBlocks/SecurityRatingBlock";

const EmptyQuestion = {
  // QuestionId: 0,
  QuestionAllowEdit: true,
  QuestionOrder: 1,
  QuestionScore: 0,
  QuestionDescription: null,
  QuestionDropdownListMembers: [
    "6:00 am",
    "6:15 am",
    "6:30 am",
    "6:45 am",
    "7:00 am",
    "7:15 am",
    "7:30 am",
    "7:45 am",
    "8:00 am",
    "8:15 am",
    "8:30 am",
    "8:45 am",
    "9:00 am",
    "9:15 am",
    "9:30 am",
    "9:45 am",
    "10:00 am",
    "10:15 am",
    "10:30 am",
    "10:45 am",
    "11:00 am",
    "11:15 am",
    "11:30 am",
    "11:45 am",
    "12:00 pm",
    "12:15 pm",
    "12:30 pm",
    "12:45 pm",
    "1:00 pm",
    "1:15 pm",
    "1:30 pm",
    "1:45 pm",
    "2:00 pm",
    "2:15 pm",
    "2:30 pm",
    "2:45 pm",
    "3:00 pm",
    "3:15 pm",
    "3:30 pm",
    "3:45 pm",
    "4:00 pm",
    "4:15 pm",
    "4:30 pm",
    "4:45 pm",
    "5:00 pm",
    "5:15 pm",
    "5:30 pm",
    "5:45 pm",
    "6:00 pm",
    "6:15 pm",
    "6:30 pm",
    "6:45 pm",
    "7:00 pm",
    "7:15 pm",
    "7:30 pm",
    "7:45 pm",
    "8:00 pm",
    "8:15 pm",
    "8:30 pm",
    "8:45 pm",
    "9:00 pm",
    "9:15 pm",
    "9:30 pm",
    "9:45 pm",
    "10:00 pm",
    "10:15 pm",
    "10:30 pm",
    "10:45 pm",
    "11:00 pm",
    "11:15 pm",
    "11:30 pm",
    "11:45 pm",
    "0:00 am",
    "0:15 am",
    "0:30 am",
    "0:45 am",
    "1:00 am",
    "1:15 am",
    "1:30 am",
    "1:45 am",
    "2:00 am",
    "2:15 am",
    "2:30 am",
    "2:45 am",
    "3:00 am",
    "3:15 am",
    "3:30 am",
    "3:45 am",
    "4:00 am",
    "4:15 am",
    "4:30 am",
    "4:45 am",
    "5:00 am",
    "5:15 am",
    "5:30 am",
    "5:45 am",
  ],
  QuestionSectionNo: 1,
  QuestionStatus: 5,
  QuestionStatusText: "LoadedForEdit",
  QuestionText: "",
  QuestionTypeEnum: 7,
  QuestionTypeText: "DateAndTime",
  QuestionCheckBoxLabel: "na",
  QuestionUserList: null,
  AnswerId: 4738390,
  AnswerBool: null,
  AnswerDate: null,
  AnswerText: null,
  AnswserSelectedDropdownItems: null,
  AnswerSelectedQuestionDDLObjId: 0,
  AnswerSelectedUserNames: null,
  DateTimeAnswer: null,
  Label: null,
  QuestionParentId: 0,
  ChildQuestions: null,
  ResponseComment_List: [],
  NISTAssetClassification_List: null,
  NoteAnswerText: null,
  Explanation: null,
  Statement: null,
  AnswerArrayText: null,
};

const SupplierProfilerSecurityRatings = ({
  questionId,
  isTemplate,
  isTemplateEdit,
  securityRatingsData,
  refreshData,
  setSaving,
  isContributorView,
}) => {
  const [pageData, setPageData] = useState(securityRatingsData);
  const [rawData, setRawData] = useState(securityRatingsData);
  const [historyRating, setHistoryRatings] = useState({});
  const [fetchingHistory, setFetchingHistory] = useState({});
  const { authTokens } = useAuth();
  const [loading, setLoading] = useState(false);
  const [isHovered, setIsHovered] = useState(0);
  const [addQuestion, setAddQuestion] = useState({});
  const [errorInput, setErrorInput] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState({
    showModal: false,
    deleteSentQuestionnaire: () => {},
  });

  const [statusModal, setStatusModal] = useState("open");
  const [showBlockModal, setShowBlockModal] = useState({
    showModal: false,
    header: "Details",
    headerIcon: "",
    content: <></>,
    contributors: [],
    QuestionList: [],
    AllowAskForHelp: true,
  });

  useEffect(() => {
    console.log(pageData);
    getRawPageData();
  }, []);

  async function getPageData() {
    await getAPIData(`${782}`, authTokens, {
      optionEnum1: 0,
      optionEnum2: 0,
      optionEnum3: questionId,
    })
      .then((response) => {
        if (response?.mr?.Success) {
          console.log(response?.SecurityRatings);

          setPageData(response?.SecurityRatings);
          // setRawData(response?.SecurityRatings);
        } else {
          CallToast("Something went wrong", false, false, "v2style");
        }

        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async function getRawPageData() {
    await getAPIData(`${782}`, authTokens, {
      optionEnum1: 0,
      optionEnum2: 0,
      optionEnum3: questionId,
    })
      .then((response) => {
        if (response?.mr?.Success) {
          setRawData(response?.SecurityRatings);
        } else {
          CallToast("Something went wrong", false, false, "v2style");
        }

        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async function getHistory(itemId, close) {
    if (!close) {
      setIsHovered(itemId);
    } else {
      setIsHovered(0);
    }
    await getAPIData(`${777}`, authTokens, {
      optionEnum1: questionId,
      optionEnum2: itemId,
    })
      .then((response) => {
        if (response?.mr?.Success) {
          setHistoryRatings(response?.securityRatingHistory_List);
          console.log(isHovered);
        } else {
          CallToast("Something went wrong", false, false, "v2style");
        }

        setFetchingHistory(false);
      })
      .catch((error) => {
        setFetchingHistory(false);
        console.log(error);
        setIsHovered(0);
      });
  }

  async function CreateSecurityRatingHistory(id, answer, answerDate) {
    setSaving(true);

    await postData(authTokens, "CreateSecurityRatingHistory", {
      QuestionId: id ?? 0,
      SendFormQuestionnaireId: questionId,
      Answer: answer ?? "",
      AnswerDate: renderDate(answerDate) ?? null,
      accessKey: JSON.parse(localStorage.getItem("user_MessageObj")),
    }).then((response) => {
      if (response?.Success) {
        // CallToast("Updated successfully", false, false, "v2style");
      } else {
        CallToast("Something went wrong", false, false, "v2style");
      }
      setSaving(false);
    });
  }

  async function savePageData(questionData) {
    await SubmitQuestionaire(authTokens, "POST", {
      ObjectId: 0,
      ObjId_Supplier: 0,
      QuestionnaireTypeEnum: 28,
      QuestionList: JSON.stringify([questionData]),
      objId_SendFormQuestionnaire: questionId,
      objId_Service: 0,
      accesskey: JSON.parse(localStorage.getItem("user_MessageObj")),
    }).then((response) => {
      if (response?.Success) {
        CreateSecurityRatingHistory(
          questionData?.QuestionId,
          questionData.AnswerText,
          questionData.AnswerDate
        ).then(() => {
          getHistory(questionData?.QuestionId, true);
          getRawPageData();
        });
        console.log(response, questionData);
        CallToast("Updated Successfully", false, false, "v2style");
      } else {
        CallToast("Something went wrong", false, false, "v2style");
      }
    });
  }
  async function saveNewQuestion(data) {
    let newSection = { ...pageData };
    console.log("pageData", pageData.Question_List);
    let newQuestion = EmptyQuestion;
    newQuestion.QuestionText = addQuestion.QuestionText;
    newQuestion.QuestionTypeEnum = 7;
    newQuestion.QuestionTypeText = "DateAndTime";

    newSection.Question_List = [newQuestion];
    console.log("newSection", newSection);
    setLoading(true);
    setSaving(true);

    await postData(authTokens, "CreateSCMProfilingSection", {
      QuestionnaireSectionVM: JSON.stringify(newSection),
      objId_SendFormQuestionnaire: questionId,
    }).then((response) => {
      setAddQuestion({});
      if (response?.Success) {
        setAddQuestion({});
        CallToast("Updated successfully", false, false, "v2style");
        getPageData();
      } else {
        CallToast("Something went wrong", false, false, "v2style");
      }
      setLoading(false);
      setSaving(false);
    });
  }
  async function UpdateSecurityRatings() {
    let newSection = pageData;

    setSaving(true);

    await postData(authTokens, "CreateSCMProfilingSection", {
      QuestionnaireSectionVM: JSON.stringify(newSection),
      objId_SendFormQuestionnaire: questionId,
    }).then((response) => {
      if (response?.Success) {
        // CallToast("Updated successfully", false, false, "v2style");
      } else {
        CallToast("Something went wrong", false, false, "v2style");
      }
      setSaving(false);
    });
  }
  async function deleteQuestion(deleteQuestion) {
    setSaving(true);
    await getAPIData("664", authTokens, {
      optionEnum1: deleteQuestion,
      optionEnum2: questionId,
      optionEnum3: 0,
    })
      .then((response) => {
        if (response?.Success) {
          CallToast(
            "Security Rating deleted successfully",
            false,
            false,
            "v2style"
          );
          getPageData().then(() => {
            setSaving(false);
          });
        } else {
          CallToast("Something went wrong", false, false, "v2style");
          setSaving(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setSaving(false);
        CallToast("Something went wrong", false, false, "v2style");
      });
  }

  return (
    <div className="w-100 security-ratings-wrapper block supplier-assessment pl-4">
      {loading ? (
        <SecurityRatingsLoader />
      ) : (
        <>
          <div
            className="d-flex flex-row w-100 justify-space-between header mb-2 "
            style={{ justifyContent: "space-between" }}
          >
            <div className="title w-70 ">
              <div className="w-100  fontBold f-black f-16">
                Security Ratings
              </div>
              <div className="f-darkgrey">Ser supplier security ratings</div>
            </div>

            {!isTemplate || isTemplate === undefined || isTemplateEdit ? (
              <div className="secops_training_certificate_overlap_parent mr-3 f-grey">
                {!isTemplateEdit && (
                  <>
                    <div style={{ margin: "0 5px" }}>
                      <RevokeContributorsDropdown
                        questionnaireId={questionId}
                        revokers={
                          pageData?.ActivityTrackingContributorVM
                            ?.ActivityTrackingContributor_List
                        }
                        questionId={0}
                        countFlag={false}
                        refreshData={refreshData}
                        isContributorView={isContributorView}
                      />
                    </div>
                    <OverlayTrigger
                      placement="bottom"
                      trigger={
                        viewOnlyAccess("AccessState_SCM")
                          ? ["hover", "focus"]
                          : []
                      }
                      delay={{
                        show: 200,
                        hide: 250,
                      }}
                      overlay={
                        <Tooltip
                          id={`tooltip-1365`}
                          className="custom_tooltip_access_assessmentmenu"
                        >
                          This feature is managed by your service provider
                        </Tooltip>
                      }
                    >
                      <div>
                        {
                          // (block.Question_List.length > 0 ||
                          //   block.SectionTypeText == null ||
                          //   block.Title ===
                          //     "Data storage and processing")
                          true && (
                            <div
                              className=" open-block flex-row d-flex pointer"
                              style={{
                                pointerEvents: viewOnlyAccess("AccessState_SCM")
                                  ? "none"
                                  : "all",
                                zIndex: 0,
                              }}
                              onClick={() => {
                                console.log(showBlockModal);
                                let content = <></>;
                                setStatusModal("open");
                                content = (
                                  <SecurityRatingBlock
                                    title={pageData}
                                    questionId={questionId}
                                  />
                                );
                                let temp = pageData?.Question_List?.map(
                                  (obj) => obj.QuestionId
                                );
                                setShowBlockModal({
                                  showModal: true,
                                  header: "Security Ratings",
                                  headerIcon: "",
                                  content: content,
                                  contributors:
                                    pageData.ActivityTrackingContributorVM
                                      ?.ActivityTrackingContributor_List,
                                  QuestionList: temp,
                                  AllowAskForHelp: pageData.allowContributors,
                                  notQuestionBlock: false,
                                });
                              }}
                            >
                              {viewOnlyAccess("AccessState_SCM") ? (
                                <img
                                  alt=""
                                  src="/images/attack-surface/small-lock.svg"
                                  className="ml-2 mr-2"
                                />
                              ) : (
                                <img
                                  src={"/images/supply-chain/add-user-icon.svg"}
                                  className="mr-2 ml-2"
                                  alt=""
                                />
                              )}

                              <div> Ask for help</div>
                            </div>
                          )
                        }
                      </div>
                    </OverlayTrigger>
                  </>
                )}
              </div>
            ) : (
              <></>
            )}
          </div>
          {!pageData || pageData?.Question_List?.length === 0 ? (
            <div className="w-100 d-flex flex-column align-items-center justify-content-center">
              <img
                src="/images/msp/no-notes-icon.svg"
                alt="notes"
                className="mt-5"
              />
              <div className="f-600 f-grey mt-4 w-fit-content">
                No ratings yet
              </div>
              <div className=" f-darkgrey mt-2 w-fit-content">
                You don’t have ratings yet. Add your first security rating
              </div>
            </div>
          ) : (
            <>
              {pageData?.Question_List?.map((question, questionIndex) => {
                return (
                  <div
                    className={`mb-3 ${isTemplate ? 'table-grid-template': 'table-grid'} align-items-center parent-hovering`}
                    style={{
                      pointerEvents:
                        viewOnlyAccess("AccessState_SCM") || isTemplate
                          ? "none"
                          : "all",
                    }}
                  >
                    {/* {question.QuestionId &&
                    addQuestion.id == question.QuestionId ? ( */}

                    {/* // <div>
                      //   <input
                      //     type="text"
                      //     // className="border-none bg-transparent"
                      //     className={`edit-task   ${
                      //       errorInput ? "input-error" : ""
                      //     }`}
                      //     placeholder="Question Text"
                      //     value={question?.QuestionText}
                      //     style={{
                      //       borderRadius: "4px",
                      //       border:
                      //         "2px solid var(--primary-primary-10, #99D2EE)",
                      //       background: "#F6F8FB",
                      //     }}
                      //     onChange={(e) => {
                      //       let temp = { ...pageData };
                      //       temp.Question_List[questionIndex].QuestionText =
                      //         e.target.value;
                      //       temp.Question_List[
                      //         questionIndex
                      //       ].QuestionStatus = 3;
                      //       temp.Question_List[
                      //         questionIndex
                      //       ].QuestionStatusText = "Answered";
                      //       console.log(
                      //         "Setting page data 1  ",
                      //         pageData.Question_List
                      //       );
                      //       setPageData(temp);
                      //     }}
                      //     onBlur={(e) => {
                      //       console.log("newField");
                      //       UpdateSecurityRatings(pageData);
                      //       setAddQuestion({});
                      //     }}
                      //   />
                      // </div> */}
                    <div
                      className="f-grey d-flex"
                      style={{
                        pointerEvents: viewOnlyAccess("AccessState_SCM") || isTemplate
                          ? "none"
                          : "all",
                      }}
                    >
                      <textarea
                        type="text"
                        className={` edit-task edit-title ${
                          errorInput ? "input-error" : ""
                        }`}
                        style={{ width: "200px" }}
                        rows={
                          question.QuestionText
                            ? Math.ceil(question.QuestionText.length / 30)
                            : 1
                        }
                        value={question?.QuestionText}
                        placeholder={"Question Text"}
                        onBlur={(e) => {
                          console.log("newField");
                          if (
                            rawData.Question_List[questionIndex]
                              .QuestionText !==
                            pageData.Question_List[questionIndex].QuestionText
                          ) {
                            UpdateSecurityRatings(pageData);
                          }
                          setAddQuestion({});
                        }}
                        onChange={(e) => {
                          let temp = { ...pageData };
                          temp.Question_List[questionIndex].QuestionText =
                            e.target.value;
                          temp.Question_List[questionIndex].QuestionStatus = 3;
                          temp.Question_List[questionIndex].QuestionStatusText =
                            "Answered";
                          console.log(
                            "Setting page data 1  ",
                            pageData.Question_List
                          );
                          setPageData(temp);
                        }}
                      />
                    </div>

                    <div>
                      <input
                       style={{
                        pointerEvents: viewOnlyAccess("AccessState_SCM") || isTemplate
                          ? "none"
                          : "all",
                      }}
                        type="text"
                        className="border-none bg-transparent"
                        placeholder="Type"
                        value={question?.AnswerText}
                        onChange={(e) => {
                          let temp = { ...pageData };
                          temp.Question_List[questionIndex].AnswerText =
                            e.target.value;
                          setPageData(temp);
                          console.log(
                            "Setting page data 2  ",
                            pageData.Question_List
                          );
                        }}
                        onBlur={() => {
                          if (
                            !(
                              renderDate(question?.AnswerDate)?.split("/")
                                .length > 2
                            )
                          )
                            return;

                          let temp = { ...pageData };
                          if (
                            rawData.Question_List[questionIndex].AnswerText !==
                            pageData.Question_List[questionIndex].AnswerText
                          ) {
                            temp.Question_List[
                              questionIndex
                            ].QuestionStatus = 3;
                            temp.Question_List[
                              questionIndex
                            ].QuestionStatusText = "Answered";
                            savePageData(temp.Question_List[questionIndex]);
                            setPageData(temp);
                            console.log(
                              "Setting page data 3  ",
                              pageData.Question_List
                            );
                          }
                        }}
                      />
                    </div>
                    <div>
                      <div
                        className="dropdown-history"
                        style={{
                          width: "200px",
                        }}
                        onMouseEnter={(event) => {
                          setFetchingHistory(true);
                          getHistory(question.QuestionId);

                          // event.target.click();
                        }}
                        onMouseLeave={(event) => {
                          setIsHovered(0);
                          // event.target.click();
                        }}
                      >
                        <SecurityRatingsDatePicker
                          showHistory={false}
                          hideCaret
                          disablePreviousDates={true}
                          selected={renderDate(question?.AnswerDate)}
                          placeholder={"Date "}
                          icon={
                            renderDate(question?.AnswerDate)?.split("/")
                              .length > 2
                              ? "/images/msp/dark-date-icon.svg"
                              : "/images/msp/light-date-icon.svg"
                          }
                          onChange={(e) => {
                            let temp = { ...pageData };
                            let dateParts = e.split("/");
                            let day = parseInt(dateParts[0]);
                            let month = parseInt(dateParts[1]) - 1;
                            let year = parseInt(dateParts[2]);
                            let formattedDate = new Date(year, month, day);

                            let formattedDateString =
                              day.toString().padStart(2, "0") +
                              "/" +
                              ("0" + (formattedDate.getMonth() + 1)).slice(-2) +
                              "/" +
                              formattedDate.getFullYear();

                            temp.Question_List[questionIndex].AnswerDate =
                              formattedDateString;
                            temp.Question_List[
                              questionIndex
                            ].QuestionStatus = 3;
                            temp.Question_List[
                              questionIndex
                            ].QuestionStatusText = "Answered";

                            console.log({
                              ...temp.Question_List[questionIndex],
                              // AnswerDate: moment(e, "DD/MM/YYYY").format(),
                              AnswerDate: formattedDateString,
                            });
                            savePageData({
                              ...temp.Question_List[questionIndex],
                              // AnswerDate: moment(e, "DD/MM/YYYY").format(),
                              AnswerDate: formattedDateString,
                            });
                            setPageData(temp);
                          }}
                          id={"bhfgirrwmv" + questionIndex}
                          isError={false}
                        />
                        <div
                          className="dropdown-revoke history"
                          style={{
                            width: " 280px",
                            display:
                              isHovered == question.QuestionId
                                ? "flex"
                                : "none",
                          }}
                        >
                          <div
                            className="f-darkgrey f-12"
                            style={{ padding: "4px 12px" }}
                          >
                            History
                          </div>
                          {fetchingHistory ? (
                            <span className="d-inline-flex align-items-center justify-content-center h-100 w-100">
                              <GreyLoader width={10} />
                              Loading...
                            </span>
                          ) : (
                            <div className="d-flex flex-column w-100">
                              {historyRating?.map((hist) => {
                                return (
                                  <div
                                    className="d-flex flex-row justify-content-between w-100"
                                    style={{ padding: "4px 12px" }}
                                  >
                                    <div className="d-flex f-darkgrey">
                                      <img
                                        src={"/images/empty-clock.svg"}
                                        className="mr-2"
                                        alt=""
                                      />
                                      {hist.AnswerDate_Text}
                                    </div>
                                    <div className="d-flex ">{hist.Answer}</div>
                                  </div>
                                );
                              })}
                              {historyRating?.length == 0 && (
                                <div
                                  className="d-flex f-darkgrey"
                                  style={{ padding: "4px 12px" }}
                                >
                                  History is empty
                                </div>
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    {!isContributorView && (
                      <div
                        className="f-grey pointer"
                        onClick={() => {
                          setShowDeleteModal({
                            showModal: true,
                            deleteSentQuestionnaire: () =>
                              deleteQuestion(question.QuestionId),
                          });
                        }}
                      >
                        <img
                          className="visible-child"
                          src="/images/attack-surface/cross-icon.svg"
                          alt=""
                        />
                      </div>
                    )}
                  </div>
                );
              })}
            </>
          )}
          {!isTemplate && (
            <div
              className="d-flex align-items-center f-grey mt-3 pointer"
              onClick={() => {
                if (viewOnlyAccess("AccessState_SCM")) {
                } else {
                  let newAdd = { ...addQuestion };
                  newAdd.title = "rating";
                  setAddQuestion(newAdd);
                }
              }}
            >
              <OverlayTrigger
                placement="bottom"
                trigger={
                  viewOnlyAccess("AccessState_SCM") ? ["hover", "focus"] : []
                }
                delay={{
                  show: 200,
                  hide: 250,
                }}
                overlay={
                  <Tooltip
                    id={`tooltip-1365`}
                    className="custom_tooltip_access"
                  >
                    This feature is managed by your service provider
                  </Tooltip>
                }
              >
                <div>
                  {addQuestion?.title == "rating" ? (
                    <>
                      <input
                        type="text"
                        // className="border-none bg-transparent"
                        className={`edit-task  edit-title ${
                          errorInput ? "input-error" : ""
                        }`}
                        autoFocus
                        placeholder="Question Text"
                        value={addQuestion?.QuestionText}
                        style={{
                          pointerEvents: viewOnlyAccess("AccessState_SCM")
                            ? "none"
                            : "all",
                        }}
                        rows={
                          addQuestion.QuestionText
                            ? Math.ceil(addQuestion.QuestionText.length / 30)
                            : 1
                        }
                        onChange={(e) => {
                          let newAdd = { ...addQuestion };
                          newAdd.QuestionText = e.target.value;
                          setAddQuestion(newAdd);
                        }}
                        onBlur={() => {
                          if (
                            !addQuestion.QuestionText ||
                            addQuestion?.QuestionText?.trim() == ""
                          ) {
                            // setErrorInput(true);
                            let newAdd = { ...addQuestion };
                            newAdd.QuestionText = "";
                            // setAddQuestion(newAdd);
                            setAddQuestion({});
                          } else {
                            saveNewQuestion();
                            setAddQuestion({});
                          }
                        }}
                      />
                    </>
                  ) : (
                    <>
                      {viewOnlyAccess("AccessState_SCM") ? (
                        <img
                          alt=""
                          src="/images/attack-surface/small-lock.svg"
                          className="mr-2 text-icon"
                        />
                      ) : (
                        <img
                          src="/images/actioncenter/plusgrey.svg"
                          alt=""
                          className="mr-2"
                        />
                      )}
                      Add Security Rating
                    </>
                  )}
                </div>
              </OverlayTrigger>
            </div>
          )}
        </>
      )}
      <DeleteModal
        show={showDeleteModal.showModal}
        hideModal={() =>
          setShowDeleteModal({
            showModal: false,
          })
        }
        deleteMessage="You are about to delete a security rating."
        deleteType={"deleteSentQuestionnaire"}
        deleteSentQuestionnaire={() => {
          showDeleteModal.deleteSentQuestionnaire();
        }}
      />
      <EmptyModal
        show={showBlockModal.showModal}
        hideModal={() => {
          setStatusModal("open");
          setShowBlockModal({
            showModal: false,
          });
        }}
        refreshData={() => {
          refreshData();
        }}
        // refreshData={() => {
        // }}
        questionId={questionId}
        questionList={showBlockModal?.QuestionList}
        sectionType_List={{
          ObjectId: pageData?.ObjectId,
          sectionTypeEnum: 6,
          allowContributors: showBlockModal.AllowAskForHelp,
        }}
        // addQuestionList={setQuestionList}
        header={showBlockModal.header}
        headerIcon={showBlockModal.headerIcon}
        content={showBlockModal.content}
        contributors={showBlockModal.contributors}
        status={statusModal}
        setStatusModal={setStatusModal}
        isContributorView={isContributorView}
        AllowAskForHelp={showBlockModal.AllowAskForHelp}
      />
    </div>
  );
};

export default SupplierProfilerSecurityRatings;

const SecurityRatingsLoader = () => {
  return (
    <ContentLoader
      speed={2}
      width={"100%"}
      height={"100%"}
      viewBox="0 0 1500 900"
      backgroundColor="#F6F8FB"
      foregroundColor="#ecebeb"
    >
      <rect x="0%" y="10" rx="4" ry="4" width="18%" height="20" />
      <rect x="20%" y="10" rx="4" ry="4" width="14%" height="20" />
      <rect x="36%" y="10" rx="4" ry="4" width="15%" height="20" />

      <rect x="0%" y="40" rx="4" ry="4" width="18%" height="20" />
      <rect x="20%" y="40" rx="4" ry="4" width="14%" height="20" />
      <rect x="36%" y="40" rx="4" ry="4" width="15%" height="20" />

      <rect x="0%" y="70" rx="4" ry="4" width="18%" height="20" />
      <rect x="20%" y="70" rx="4" ry="4" width="14%" height="20" />
      <rect x="36%" y="70" rx="4" ry="4" width="15%" height="20" />

      <rect x="0%" y="100" rx="4" ry="4" width="18%" height="20" />
      <rect x="20%" y="100" rx="4" ry="4" width="14%" height="20" />
      <rect x="36%" y="100" rx="4" ry="4" width="15%" height="20" />

      <rect x="0%" y="130" rx="4" ry="4" width="18%" height="20" />
      <rect x="20%" y="130" rx="4" ry="4" width="14%" height="20" />
      <rect x="36%" y="130" rx="4" ry="4" width="15%" height="20" />

      <rect x="0%" y="160" rx="4" ry="4" width="18%" height="20" />
      <rect x="20%" y="160" rx="4" ry="4" width="14%" height="20" />
      <rect x="36%" y="160" rx="4" ry="4" width="15%" height="20" />

      <rect x="0%" y="190" rx="4" ry="4" width="18%" height="20" />
      <rect x="20%" y="190" rx="4" ry="4" width="14%" height="20" />
      <rect x="36%" y="190" rx="4" ry="4" width="15%" height="20" />

      <rect x="0%" y="220" rx="4" ry="4" width="18%" height="20" />
      <rect x="20%" y="220" rx="4" ry="4" width="14%" height="20" />
      <rect x="36%" y="220" rx="4" ry="4" width="15%" height="20" />

      <rect x="0%" y="250" rx="4" ry="4" width="18%" height="20" />
      <rect x="20%" y="250" rx="4" ry="4" width="14%" height="20" />
      <rect x="36%" y="250" rx="4" ry="4" width="15%" height="20" />

      <rect x="0%" y="280" rx="4" ry="4" width="18%" height="20" />
      <rect x="20%" y="280" rx="4" ry="4" width="14%" height="20" />
      <rect x="36%" y="280" rx="4" ry="4" width="15%" height="20" />
    </ContentLoader>
  );
};

function renderDate(_inputDate) {
  if (_inputDate?.split("/").length > 2) {
    return _inputDate;
  } else if (
    _inputDate === "" ||
    _inputDate === null ||
    _inputDate === undefined
  ) {
    return "";
  } else {
    return moment(_inputDate).format("DD/MM/YYYY");
  }

  // const inputDate = "2024-08-03T04:50:30.3212145+00:00";
  // const formattedDate = moment(inputDate).format("DD/MM/YYYY");
  // console.log(formattedDate); // Output: "03/08/2024"

  // const moment = require('moment');

  // const inputDate = "03/08/2024";
  // const formattedDate = moment(inputDate, "DD/MM/YYYY").format();
  // console.log(formattedDate); // Output: "2024-08-03T00:00:00.0000000+00:00"
}
