import React, { useCallback, useEffect, useState } from "react";
import "../secOps.scss";


import moment from "moment";
import axios from "axios";
import CompanySettingsDropdown from "../../../settingsV2/companySettings/companysettingsdrodown/CompanySettingsDropdown";
import CreateTaskModal from "../../../PriceCalculator/modals/CreateTaskModal";
import { setactioncentredata } from "../../../../redux/actionCentre/actions";
import { useDispatch } from "react-redux";
import { Button, Form, } from "react-bootstrap";
import HighgroundDatePicker from "../../../test/TestComponent";
import { getAPIData, postData } from "../../../../Utils/commonApi";
import { useAuth } from "../../../context/Auth";
import { CallToast } from "../../../PriceCalculator/modals/CustomToast";
import ContentLoader from "react-content-loader";
import Loader from "../../../Common/loader";


import { GreyLoader } from "../../../Theme/APILoaders";
import { UploadFileCircularProgress } from "../SecOps";
import { NO_ACCESS_TEXT, accessStateValue } from "../../../App/App";
import AttackSurfaceTooltip from "../../../AttackSurface/AssetsList/AttackSurfaceTooltip";
import { auditUpdate } from "../audit/AuditHeader";



const OperationalSecurityAssetsScreen = ({setSideBarOpen = () => {}, setShowSidePanelPassed = () => {}, isAudit = false, showSidePanelPassed = false}) => {
  const { authTokens } = useAuth();
  const uploadRef = React.useRef();
  const [loading, setLoading] = useState(false);
  const [auditLoading, setAuditLoading] = useState(false);
  const [showSidePanel, setShowSidePanel] = useState(false);

  const [frameworksData, setFrameworksData] = useState([]);
  const [selectedFrameworkItem, setSelectedFrameworkItem] = useState();
  const [uploadingFileList, setUploadingFileList] = useState([]);
  const [savingAudit, setSavingAudit] = useState(false);

  const [uploadProgress, setUploadProgress] = useState(0);
  const [objId, setObjId] = useState(0);

  const [auditDetails, setAuditDetails] = useState();

  const selectedBlock = frameworksData?.find(item => item?.FrameworkList?.some(subItem => subItem?.FrameworkId === auditDetails?.objId_Framework));
  const selectedItem = selectedBlock?.FrameworkList?.find(subItem => subItem?.FrameworkId === auditDetails?.objId_Framework);
  const selectedItemIndex = frameworksData?.findIndex(item => item?.FrameworkList?.some(subItem => subItem?.FrameworkId === auditDetails?.objId_Framework));

  useEffect(() => {
    setShowSidePanel(showSidePanelPassed);
  }, [showSidePanelPassed])

  useEffect(() => {
    if (showSidePanel === false) {
        setSideBarOpen(true);
    } else {
      setSideBarOpen(false);
    }
    setShowSidePanelPassed(showSidePanel);
}, [showSidePanel]);

  useEffect(() => {
    if (selectedFrameworkItem?.FrameworkId) {
      setShowAuditDetails(
        selectedFrameworkItem?.CyberFrameworkAudit?.objId ? true : false
      );
      setAuditDetails();
      if (selectedFrameworkItem?.CyberFrameworkAudit?.objId) {
        setAuditLoading(true);
        getAuditDetails(selectedFrameworkItem);
      }
    }
  }, [selectedFrameworkItem]);

  const dispatch = useDispatch();

  useEffect(() => {
    setLoading(true);
    getFrameworksData();
  }, []);

  const [showAuditDetails, setShowAuditDetails] = useState(false);

  async function getFrameworksData() {
    await getAPIData("710", authTokens, { optionEnum1: 0 })
      .then((response) => {
        if (response?.mr?.Success) {
          setFrameworksData(response?.FrameworkGroupList);
        } else {
          CallToast("Something went wrong", false, false, "v2style");
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        CallToast("Something went wrong", false, false, "v2style");
        setLoading(false);
      });
  }

  async function updateVisibility(_id, _visibility) {
    await postData(authTokens, "UpdateFrameworkVisibility", {
      accessKey: JSON.parse(localStorage.getItem("user_MessageObj")),
      optionEnum1: _id,
      visibility: _visibility,
    })
      .then((response) => { })
      .catch((err) => {
        console.log(err);
      });
  }

  async function getAuditDetails(_selectedFrameworkItem) {
    await getAPIData("711", authTokens, {
      optionEnum1: _selectedFrameworkItem?.CyberFrameworkAudit?.objId ?? 0,
      optionEnum2: _selectedFrameworkItem?.FrameworkId ?? 0,
    })
      .then((response) => {
        if (response?.mr?.Success) {
          setAuditDetails(response);
          setObjId(response?.objId)
        } else {
          CallToast("Something went wrong", false, false, "v2style");
        }
        setAuditLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setAuditLoading(false);
        CallToast("Something went wrong", false, false, "v2style");
      });
  }

  /**
   *
   *  kwargs = {
   * FrameworkId
   *
   * }
   *
   */

  async function updateAuditDetails(_enabled = true) {
    await postData(authTokens, "CreateUpdateAudit", {
      accessKey: JSON.parse(localStorage.getItem("user_MessageObj")),
      FrameworkId: selectedFrameworkItem?.FrameworkId,
      objId: auditDetails?.objId ?? 0,
      DoYouPerformAudits: JSON.stringify(
        auditDetails?.DoYouPerformAudits ?? {}
      ),
      AuditType: JSON.stringify(auditDetails?.AuditType ?? {}),
      isEnable: _enabled,
      OverView: auditDetails?.OverView ?? "",
      LastAudit: auditDetails?.LastAudit,
      NextAudit: auditDetails?.NextAudit,
    })
      .then((response) => {
        CallToast("Updated successfully", false, false, "v2style");
        if (isAudit) {
          auditUpdate(
            authTokens, 
            selectedFrameworkItem?.CyberFrameworkAudit?.objId == 0 ? response?.EntityId : selectedFrameworkItem?.CyberFrameworkAudit?.objId,
            2, 
            selectedFrameworkItem?.CyberFrameworkAudit?.objId == 0 ? 0 : 1, 
            selectedFrameworkItem?.CyberFrameworkAudit?.objId == 0 ? 0 : 1,
            selectedFrameworkItem?.CyberFrameworkAudit?.objId == 0 ? response?.EntityId : selectedFrameworkItem?.CyberFrameworkAudit?.objId
          )
        }
        setObjId(response?.EntityId);
        if (_enabled) {
          getAuditDetails({
            ...selectedFrameworkItem,
            CyberFrameworkAudit: {
              objId: response?.EntityId,
            },
          });
        }
        getFrameworksData();
        setSavingAudit(false);
      })
      .catch((Err) => {
        console.log(Err);
        setAuditLoading(false);
        CallToast("Something went wrong", false, false, "v2style");
        setSavingAudit(false);
      });
  }

  function formatFileSize(bytes) {
    const megabytes = bytes / (1024 * 1024);

    const formattedValue = megabytes.toFixed(3) + " MB";

    return formattedValue;
  }

  function downloadFile(url, filename) {
    const link = document.createElement("a");
    link.href = url;
    link.download = filename;

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  async function updateUploadList(_selectedFrameworkItem, _objId) {
    return new Promise((resolve, reject) => {
      getAPIData("711", authTokens, {
        optionEnum1: _objId ?? /*_selectedFrameworkItem?.CyberFrameworkAudit?.objId ??*/ 0,
        optionEnum2: _selectedFrameworkItem?.FrameworkId ?? 0,
      })
        .then((response) => {
          if (response?.mr?.Success) {
            if (auditDetails) {
              setAuditDetails({
                ...auditDetails,
                MediaList: response?.MediaList,
              });
              setObjId(response?.objId)
              resolve(response);
            }
          } else {
            reject(response);
            CallToast("Something went wrong", false, false, "v2style");
          }
          setAuditLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setAuditLoading(false);
          reject(err);
          CallToast("Something went wrong", false, false, "v2style");
        });
    });
  }

  async function deleteUploadFile(_fileName, _auditId, _attributeLinkId) {
    return new Promise((resolve, reject) => {
      postData(authTokens, "DeleteFile", {
        accessKey: JSON.parse(localStorage.getItem("user_MessageObj")),
        optionEnum1: 9,
        FileName: _fileName,
        FrameworkAuditId: _auditId,
        AttributeLinkId: _attributeLinkId,
      })
        .then((response) => {
          if (response?.Success) {
            if (selectedFrameworkItem) {
              updateUploadList(selectedFrameworkItem, objId)
              if (isAudit) {
                auditUpdate(
                  authTokens, 
                  selectedFrameworkItem?.CyberFrameworkAudit?.objId == 0 ? response?.EntityId : selectedFrameworkItem?.CyberFrameworkAudit?.objId,
                  2, 
                  selectedFrameworkItem?.CyberFrameworkAudit?.objId == 0 ? 0 : 1, 
                  selectedFrameworkItem?.CyberFrameworkAudit?.objId == 0 ? 0 : 1,
                  selectedFrameworkItem?.CyberFrameworkAudit?.objId == 0 ? response?.EntityId : selectedFrameworkItem?.CyberFrameworkAudit?.objId
                )
              }
            }
          } else {
            CallToast("Something went wrong", false, false, "v2style");
          }
          setAuditLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setAuditLoading(false);
          CallToast("Something went wrong", false, false, "v2style");
        });
    });
  }

  const handleUpload = async (event) => {
    event.preventDefault();

    const file = event.target.files[0];
    setUploadingFileList(event.target.files);

    const allowedFileTypes = [
      "application/pdf",
      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      "text/plain",
    ];

    if (file && allowedFileTypes.includes(file.type)) {
      const reader = new FileReader();

      reader.onloadend = async () => {
        const base64Data = reader.result;
        const formData = new FormData();
        formData.append("File", base64Data);
        formData.append("optionEnum1", 9);
        formData.append("FileName", file?.name);
        formData.append("FrameworkAuditId", auditDetails?.objId);

        try {
          const response = await axios.post(
            `${process.env.REACT_APP_API_BASE_URL}/CC_API/UploadFile`,
            formData,
            {
              headers: {
                Authorization: `Bearer ${authTokens}`,
              },
              onUploadProgress: (progressEvent) => {
                const percentCompleted = Math.round(
                  (progressEvent.loaded * 100) / progressEvent.total
                );
                setUploadProgress(percentCompleted);
              },
            }
          );


          CallToast("Uploaded successfully", false, false, "v2style");
          if (isAudit) {
            auditUpdate(
              authTokens, 
              selectedFrameworkItem?.CyberFrameworkAudit?.objId == 0 ? response?.EntityId : selectedFrameworkItem?.CyberFrameworkAudit?.objId,
              2, 
              selectedFrameworkItem?.CyberFrameworkAudit?.objId == 0 ? 0 : 1, 
              selectedFrameworkItem?.CyberFrameworkAudit?.objId == 0 ? 0 : 1,
              selectedFrameworkItem?.CyberFrameworkAudit?.objId == 0 ? response?.EntityId : selectedFrameworkItem?.CyberFrameworkAudit?.objId
            )
          }
          updateUploadList(selectedFrameworkItem, objId)
          setUploadProgress(0);
        } catch (error) {
          console.error("Upload failed:", error);
        }
      };

      reader.readAsDataURL(file);
    } else {
      console.error(
        "Invalid file type. Please upload a PDF, DOC, DOX, or TXT file."
      );
    }
  };





  function isAtTop() {
    if (frameworksData.length === 0) return;
    let all_list = frameworksData.reduce((accumulator, currentValue) => {
      return accumulator.concat(currentValue.FrameworkList);
    }, []);
    let index = all_list.findIndex(
      (item) => item?.FrameworkId === selectedFrameworkItem?.FrameworkId
    );

    if (index === 0) {
      return true;
    } else {
      return false;
    }
  }
  function isAtBottom() {
    if (frameworksData.length === 0) return;
    let all_list = frameworksData.reduce((accumulator, currentValue) => {
      return accumulator.concat(currentValue.FrameworkList);
    }, []);
    let index = all_list.findIndex(
      (item) => item?.FrameworkId === selectedFrameworkItem?.FrameworkId
    );

    if (index === all_list.length - 1) {
      return true;
    } else {
      return false;
    }

  }


  async function moveNext() {
    if (frameworksData.length === 0) return;
    let all_list = frameworksData?.reduce((accumulator, currentValue) => {
      return accumulator.concat(currentValue?.FrameworkList);
    }, []);
    let index = all_list.findIndex(
      (item) => item?.FrameworkId === selectedFrameworkItem?.FrameworkId
    );

    if (index > -1 && index < all_list.length - 1) {
      setSelectedFrameworkItem(all_list[index + 1]);
      setObjId(all_list[index + 1]?.CyberFrameworkAudit?.objId ?? 0)
    }
  }


  async function movePrev() {
    if (frameworksData.length === 0) return;
    let all_list = frameworksData?.reduce((accumulator, currentValue) => {
      return accumulator.concat(currentValue?.FrameworkList);
    }, []);
    let index = all_list.findIndex(
      (item) => item?.FrameworkId === selectedFrameworkItem?.FrameworkId
    );

    if (index > 0) {
      setSelectedFrameworkItem(all_list[index - 1]);
      setObjId(all_list[index - 1]?.CyberFrameworkAudit?.objId ?? 0)
    }

  }


  function thisDateNextYear(dateString) {
    const [day, month, year] = dateString.split('/').map(Number);
    const date = new Date(year + 1, month - 1, day);
    return (
      date.getFullYear() === year &&
      date.getMonth() === month - 1 &&
      date.getDate() === day
    ) ? `${date.getDate()}/${String(date.getMonth() + 1).padStart(2, '0')}/${date.getFullYear()}` :
      (() => {
        let nextDate = new Date(year + 1, month - 1, day);
        while (isNaN(nextDate.getTime())) {
          day++;
          nextDate = new Date(year + 1, month - 1, day);
        }
        return `${nextDate.getDate()}/${String(nextDate.getMonth() + 1).padStart(2, '0')}/${nextDate.getFullYear()}`;
      })();
  }




  return (
    <div className="w-100">
      {/* Page Body */}

      {loading ? (
        <div className="w-45 mx-auto mt-4">
          <FrameworksLoader />
        </div>
      ) : (
        <>
          <div className="slidable-body-new ">
            <div
              className={`main-body hide-scrollbar ${showSidePanel ? "panel-open" : "panel-closed"
                }`}
            >
              <div className="f-16 f-600 mt-4 mb-2">Security Frameworks </div>
              <div className="f-12 f-darkgrey">
                Define what cybersecurity, information security and data privacy
                frameworks your organisation is aligned to
              </div>
              {/*Frameworks Block */}

              {frameworksData?.map((framework, frameworkIndex) => {
                return (
                  <>
                    <div className="f-14 f-600 f-black mt-4 mb-2">
                      {framework?.Name} frameworks
                    </div>
                    <div className="border-parent radius-8">
                      {framework?.FrameworkList?.sort((a, b) => {
                        if (a.Visibility && !b.Visibility) {
                          return -1; // a comes before b
                        } else if (!a.Visibility && b.Visibility) {
                          return 1; // b comes before a
                        } else {
                          return 0; // preserve the order
                        }
                      }).map((frameworkItem, frameworkItemIndex) => {
                        return (
                          <>
                            {(frameworkItem?.Visibility === false &&
                              framework?.showVisibleList) ||
                              frameworkItem?.Visibility === true ? (
                              <div
                                className={`d-flex flex-column border-item pointer bg-hover-row ${selectedFrameworkItem?.FrameworkId ===
                                  frameworkItem?.FrameworkId
                                  ? "bg-lightblue-imp"
                                  : ""
                                  }`}
                                onClick={() => {
                                  setShowSidePanel(true);
                                  setSelectedFrameworkItem(frameworkItem);
                                  setObjId(frameworkItem?.CyberFrameworkAudit?.objId ?? 0);
                                }}
                              >
                                <div className="d-flex align-items-center justify-content-between w-100 pointer px-4  py-3 ">
                                  <div
                                    className="d-flex flex-column "
                                    style={{ width: "75%" }}
                                  >
                                    <div className="d-flex align-items-baseline ">
                                      <p
                                        className={`f-500 m-0  ${frameworkItem?.Visibility
                                          ? "f-black"
                                          : "f-grey"
                                          }`}
                                      >
                                        {frameworkItem?.Abbreviation}
                                      </p>
                                    </div>
                                    <p className="f-12 f-darkgrey ">
                                      {frameworkItem?.Name}
                                    </p>
                                  </div>
                                  {!frameworkItem?.CyberFrameworkAudit && (
                                    <div className="d-flex align-items-center">
                                      {frameworkItem?.Visibility ? (
                                        <>
                                          <div
                                            className="mr-4 p-2"
                                            onClick={(e) => {
                                              e.stopPropagation();
                                              let updated_data = [
                                                ...frameworksData,
                                              ];
                                              updated_data[
                                                frameworkIndex
                                              ].FrameworkList[
                                                frameworkItemIndex
                                              ].Visibility = false;
                                              setFrameworksData(updated_data);
                                              updateVisibility(
                                                frameworkItem?.FrameworkId,
                                                false
                                              );
                                            }}
                                          >
                                            <img
                                              src="/images/attack-surface/shown-icon.svg"
                                              alt=""
                                              className="pointer"
                                            />
                                          </div>
                                          <img
                                            alt=""
                                            src="/images/actioncenter/plusgrey.svg"
                                          />
                                        </>
                                      ) : (
                                        <>
                                          <div
                                            className="mr-4 p-2 f-grey f-12 f-500"
                                            onClick={(e) => {
                                              e.stopPropagation();
                                              let updated_data = [
                                                ...frameworksData,
                                              ];
                                              updated_data[
                                                frameworkIndex
                                              ].FrameworkList[
                                                frameworkItemIndex
                                              ].Visibility = true;
                                              setFrameworksData(updated_data);
                                              updateVisibility(
                                                frameworkItem?.FrameworkId,
                                                true
                                              );
                                            }}
                                          >
                                            <img
                                              src="/images/attack-surface/shown-icon.svg"
                                              alt=""
                                              className="pointer mr-2"
                                            />{" "}
                                            <span className="pt-3">Show</span>
                                          </div>
                                          <img
                                            alt=""
                                            src="/images/actioncenter/plusgrey.svg"
                                          />
                                        </>
                                      )}
                                    </div>
                                  )}
                                  {frameworkItem?.CyberFrameworkAudit && (
                                    <div className="d-flex align-items-center">
                                      <div className="f-12 f-darkgrey mr-3 w-max-content">
                                        <div>
                                          <>
                                            Last audit:{" "}
                                            {
                                              frameworkItem?.CyberFrameworkAudit
                                                ?.LastAudit !== "NA" ? frameworkItem?.CyberFrameworkAudit
                                                ?.LastAudit
                                                : "Not Selected"}
                                          </>
                                          {/* )} */}
                                        </div>
                                        <div>
                                          <>
                                            Next audit:{" "}
                                            {frameworkItem?.CyberFrameworkAudit
                                              ?.NextAudit !== 'NA'
                                              ? frameworkItem?.CyberFrameworkAudit
                                                ?.NextAudit
                                              : "Not Selected"}
                                          </>
                                          {/* )} */}
                                        </div>
                                      </div>
                                      <img
                                        src="/images/attack-surface/chevron-right.svg"
                                        alt=""
                                        className=""
                                      />
                                    </div>
                                  )}
                                </div>
                              </div>
                            ) : (
                              <></>
                            )}
                          </>
                        );
                      })}
                    </div>

                    {framework?.FrameworkList?.filter(
                      (item) => item.Visibility === false
                    ).length > 0 && (
                        <Button
                          className="hg-cancel-btn mt-3"
                          onClick={() => {
                            let updated_data = [...frameworksData];
                            updated_data[frameworkIndex].showVisibleList =
                              !framework?.showVisibleList;
                            setFrameworksData(updated_data);
                          }}
                        >
                          {framework?.showVisibleList ? (
                            <div>
                              Hide{" "}
                              {
                                framework?.FrameworkList?.filter(
                                  (item) => item.Visibility === false
                                ).length
                              }{" "}
                              hidden{" "}
                              <img
                                src="/images/complianceManager/upArrow.svg"
                                className=" "
                                alt=""
                              />
                            </div>
                          ) : (
                            <div>
                              Show{" "}
                              {
                                framework?.FrameworkList?.filter(
                                  (item) => item.Visibility === false
                                ).length
                              }{" "}
                              hidden{" "}
                              <img
                                src="/images/complianceManager/upArrow.svg"
                                className="rotate180"
                                alt=""
                              />
                            </div>
                          )}
                        </Button>
                      )}
                  </>
                );
              })}

              <div className="p-4" />
            </div>

            <div
              className={`sidepanel  ${showSidePanel ? "panel-open" : "panel-closed"
                } `}
            >
              <div className="panel-header d-flex align-items-center justify-content-between p-3">
                <div className="d-flex align-items-center">
                  <div
                    className={`mx-2 pointer ${isAtTop() ? "pointer-events-none opacity-40" : ""
                      }`}
                    onClick={() => {
                      movePrev();
                    }}
                  >
                    <img
                      src="/images/msp/secops-chevron-down.svg"
                      className="rotate180"
                      alt=""
                    />
                  </div>
                  <div
                    className={`mx-2 pointer ${isAtBottom() ? "pointer-events-none opacity-40" : ""
                      }`}
                    onClick={() => {
                      moveNext();
                    }}
                  >
                    <img
                      src="/images/msp/secops-chevron-down.svg"
                      className=""
                      alt=""
                    />
                  </div>
                </div>
                <div className="d-flex align-items-center  ">
                  {/* <WrapperDropdwon>
                    <div className="f-grey f-500 mr-4">
                      <img
                        src="/images/msp/secops-info-icon.svg"
                        alt=""
                        className="mr-2"
                      />
                      More info
                    </div>
                  </WrapperDropdwon> */}
                  <div
                    className="f-grey f-500 mr-2 pointer"
                    onClick={() => {
                      if (accessStateValue("AccessState_ActionCentre") > 3) {
                        dispatch(
                          setactioncentredata({
                            refreshCreateTaskModalModule: true,
                          })
                        );
                        const drag = document.getElementById("create-task-modal");
                        drag.style.display = "block";
                        }
                    }}
                  >
                    {accessStateValue("AccessState_ActionCentre") < 3 ? (
                      <img
                        src="/images/msp/lock-icon.svg"
                        alt=""
                        className="mr-3"
                      />
                    ) : (
                      <img
                        src="/images/msp/secops-create-task-icon.svg"
                        alt=""
                        className="mr-3"
                      />
                    )}
                    Create task
                  </div>
                  <div
                    className="pl-2 border-left pointer"
                    onClick={() => {
                      setShowSidePanel(false);
                      setSelectedFrameworkItem();
                      setObjId(0);
                      getFrameworksData();
                    }}
                  >
                    <img
                      width={13}
                      src="/images/attack-surface/cross-icon.svg"
                      alt=""
                      className=" "
                    />
                  </div>
                </div>
              </div>

              <div className="panel-body transparent-scroller">
                <div className="d-flex align-items-center justify-content-between p-4">
                  <div className="f-500 f-black">
                    {selectedFrameworkItem?.Abbreviation} Framework
                  </div>
                  <div>
                    {accessStateValue("AccessState_SecOps") != 1 && (
                      <Form.Group controlId={"check" + "" + 99} className="">
                        <Form.Check
                          tabIndex={-1}
                          type="switch"
                          id={
                            "switch-ques1" + "" + 99 + Math.random() * 1000000
                          }
                          style={{
                            transform: "scale(1.2)",
                            paddingLeft: "0.75rem",
                          }}
                          className={`m-0 scale-switch switch-shadow-none ${true}  `}
                          checked={showAuditDetails}
                          label=""
                          disabled={false}
                          onChange={(e) => {
                            setShowAuditDetails(e.target.checked);
                            updateAuditDetails(Boolean(e.target.checked));
                            setAuditLoading(true);
                            if (e.target.checked) {
                              setAuditLoading(true);
                            }
                            if (!e.target.checked) {
                              setObjId(0);
                            }
                          }}
                          onClick={(e) => { }}
                        />
                      </Form.Group>
                    )}
                  </div>
                </div>

                {/* Blue Coming Soon Banner */}
                <div
                  className="radius-8 d-flex align-items-center justify-content-between p-3 w-90 mx-auto mb-2"
                  style={{ background: "#EBF6FC" }}
                >
                  <div className="d-flex align-items-center mr-4">
                    <img
                      src="/images/msp/blue-clock.svg"
                      className="mr-2"
                      alt=""
                    />
                    Stay tuned for our new security and data privacy assessment
                    feature for all major frameworks.
                  </div>
                  <div
                    className="f-blue px-2 f-12 f-500 rounded-full flex-shrink-0 pointer"
                    style={{
                      background: "#D6EDF8",
                    }}
                  >
                    Coming soon
                  </div>
                </div>

                {!showAuditDetails ? (
                  <div className="w-50 d-flex flex-column align-items-center justify-content-center mx-auto mt-4">
                    <img
                      src="/images/msp/frameworks-toggle-icon.svg"
                      className="mb-3"
                      alt=""
                    />
                    <div className="f-600 f-grey text-center w-fit-content">
                      Are you aligned and/or in compliance with{" "}
                      {selectedFrameworkItem?.Abbreviation} Framework?
                    </div>
                    <div className="f-darkgrey text-center w-fit-content">
                      Toggle if you are using{" "}
                      {selectedFrameworkItem?.Abbreviation} {/* Framework */}
                    </div>
                  </div>
                ) : (
                  <>
                    {auditLoading ? (
                      <div className="d-flex align-items-center justify-content-center w-100 h-100">
                        <Loader />
                      </div>
                    ) : (
                      <div className="px-4">
                        <div className="f-500 f-darkgrey mb-2">Overview</div>
                        <textarea
                          disabled={accessStateValue("AccessState_SecOps") == 1 ? true : false}
                          className="w-100 radius-4 p-2 border1 mb-3"
                          rows={5}
                          placeholder="Explain why you are aligned to this framework and what actions you take to ensure compliance..."
                          value={auditDetails?.OverView ?? ""}
                          onChange={(e) => {
                            if (accessStateValue("AccessState_SecOps") != 1) {
                              setAuditDetails({
                                ...auditDetails,
                                OverView: e.target.value,
                              });
                            }
                          }}
                        />

                        <div className="f-500 f-darkgrey my-2">
                          Audit details
                        </div>

                        <div className="d-flex align-items-center w-100 my-2">
                          <div className="f-500 f-grey w-30">
                            Do you perform audits
                          </div>
                          <div className="w-fit-content dropdown-grey-theme score-question-dropdow n ml-3 w-70">
                            {
                              (accessStateValue("AccessState_SecOps") == 1) ?
                              <div className="f-500 ml-2 cursor-default"
                                style={{
                                  color: auditDetails?.DoYouPerformAudits?.ObjDefDDLText == "Select" ?  'rgb(170, 170, 170)' : '#616778',
                                }}
                              >
                                {auditDetails?.DoYouPerformAudits?.ObjDefDDLText == "Select" ?  'Not Selected' : auditDetails?.DoYouPerformAudits?.ObjDefDDLText}
                              </div>
                              :
                              <CompanySettingsDropdown
                                rightAlign={true}
                                fieldId={"Id"}
                                fieldValue={"ObjDefDDLText"}
                                selected={auditDetails?.DoYouPerformAudits}
                                placeholder={accessStateValue("AccessState_SecOps") == 1 ? "Not Selected" : "Select"}
                                //  showid={true}
                                onClick={(id, val, e, value) => {
                                  if (value?.ObjDefDDLText == 'No') {
                                    let updated_data = selectedItem;
                                    updated_data.CyberFrameworkAudit.LastAudit = "NA";
                                    updated_data.CyberFrameworkAudit.NextAudit = "NA";
                                    
                                    if (selectedItemIndex !== -1) {
                                      frameworksData[selectedItemIndex].FrameworkList.forEach((subItem, index) => {
                                          if (subItem.FrameworkId === auditDetails?.objId_Framework) {
                                              frameworksData[selectedItemIndex].FrameworkList.splice(index, 1, updated_data);
                                          }
                                      });
                                    }

                                    setAuditDetails({
                                      ...auditDetails,
                                      DoYouPerformAudits: value,
                                      OverView: null,
                                      AuditType: null,
                                      LastAudit: null,
                                      NextAudit: null,
                                      AuditBy: null
                                    });
                                  } else {
                                    setAuditDetails({
                                    ...auditDetails,
                                    DoYouPerformAudits: value,
                                  });
                                  }
                                  
                                }}
                                data={auditDetails?.DoYouPerformAuditsOptionsList}
                              />
                            }
                          </div>
                        </div>

                        <div className="d-flex align-items-center w-100 my-2">
                          <div className="f-500 f-grey w-30">Audit type</div>
                          <div className="w-fit-content dropdown-grey-theme score-question-dropdow n ml-3 w-70">
                            {
                              (accessStateValue("AccessState_SecOps") == 1) ?
                              <div className="f-500 ml-2 cursor-default"
                                style={{
                                  color: auditDetails?.AuditType?.ObjDefDDLText == "Select" ? 'rgb(170, 170, 170)' : '#616778',
                                }}
                              >
                                {auditDetails?.AuditType?.ObjDefDDLText == "Select" ? 'Not Selected' : auditDetails?.AuditType?.ObjDefDDLText}
                              </div>
                              :
                              <CompanySettingsDropdown
                                rightAlign={true}
                                fieldId={"Id"}
                                fieldValue={"ObjDefDDLText"}
                                selected={auditDetails?.AuditType}
                                placeholder={accessStateValue("AccessState_SecOps") == 1 ? "Not Selected" : "Select"}
                                //  showid={true}
                                onClick={(id, val, e, value) => {
                                  setAuditDetails({
                                    ...auditDetails,
                                    AuditType: value,
                                  });
                                }}
                                data={auditDetails?.AuditTypeOptionsList}
                              />
                              }
                          </div>
                        </div>

                        <div className="d-flex align-items-center w-100 my-2">
                          <div className="f-500 f-grey w-30">Last Audit</div>
                          <div className="w-fit-content dropdown-grey-theme score-question-dropdow n ml-3 w-70">
                            {
                              accessStateValue("AccessState_SecOps") === 1 ?
                              <div className="f-grey f-500 mx-1 important-width cursor-default">
                                {auditDetails?.LastAudit?.split(" ")?.[0] ? auditDetails?.LastAudit?.split(" ")?.[0] : "No Selected"}
                              </div>
                              :
                              <HighgroundDatePicker
                                selected={
                                  auditDetails?.LastAudit?.split(" ")?.[0] ?? ""
                                }
                                placeholder={accessStateValue("AccessState_SecOps") == 1 ? "Not Selected" : "Choose date"}
                                icon={"/images/attack-surface/date-icon.svg"}
                                onChange={(e) => {
                                  if (accessStateValue("AccessState_SecOps") != 1) {
                                    if (e == '' || e == null || !e) {
                                      let updated_data = selectedItem;
                                      updated_data.CyberFrameworkAudit.LastAudit = "NA";

                                      if (selectedItemIndex !== -1) {
                                        frameworksData[selectedItemIndex].FrameworkList.forEach((subItem, index) => {
                                            if (subItem?.FrameworkId === auditDetails?.objId_Framework) {
                                                frameworksData[selectedItemIndex].FrameworkList.splice(index, 1, updated_data);
                                            }
                                        });
                                      }
                                      
                                      setAuditDetails({
                                        ...auditDetails,
                                        LastAudit: e,
                                      });  

                                    } else {
                                      setAuditDetails({
                                        ...auditDetails,
                                        LastAudit: e,
                                        NextAudit: thisDateNextYear(e)
                                      });
                                    }
                                  }
                                }}
                                id={"bhfgvwmv"}
                                isError={false}
                              />
                            }
                          </div>
                        </div>

                        <div className="d-flex align-items-center w-100 my-2">
                          <div className="f-500 f-grey w-30">
                            Last Audited by
                          </div>
                          <div className="w-fit-content ml-3 w-70 highlight-input p-1">
                            <input
                              disabled={accessStateValue("AccessState_SecOps") == 1}
                              type="text"
                              placeholder={accessStateValue("AccessState_SecOps") == 1 ? 'Not Selected' : "Enter name"}
                              className="w-100 border-none bg-transparent"
                              value={auditDetails?.AuditBy}
                              onChange={(e) => {
                                if (accessStateValue("AccessState_SecOps") != 1) {
                                  setAuditDetails({
                                    ...auditDetails,
                                    AuditBy: e.target.value,
                                  });
                                }
                              }}
                            />
                          </div>
                        </div>

                        <div className="d-flex align-items-center w-100 my-2">
                          <div className="f-500 f-grey w-30">Next Audit</div>
                          <div className="w-fit-content dropdown-grey-theme score-question-dropdow n ml-3 w-70">
                            {
                              accessStateValue("AccessState_SecOps") === 1 ?
                              <div className="f-grey f-500 mx-1 important-width cursor-default">
                                {auditDetails?.NextAudit?.split(" ")?.[0] ? auditDetails?.NextAudit?.split(" ")?.[0] : "No Selected"}
                              </div>
                              :
                              <HighgroundDatePicker
                                selected={
                                  auditDetails?.NextAudit?.split(" ")?.[0] ?? ""
                                }
                                placeholder={accessStateValue("AccessState_SecOps") == 1 ? "Not Selected" : "Choose date"}
                                icon={"/images/attack-surface/date-icon.svg"}
                                onChange={(e) => {
                                  if (accessStateValue("AccessState_SecOps") != 1) {
                                    if (e == '' || e == null || !e) {
                                      let updated_data = selectedItem;
                                      updated_data.CyberFrameworkAudit.NextAudit = "NA";
                                      if (selectedItemIndex !== -1) {
                                        frameworksData[selectedItemIndex].FrameworkList.forEach((subItem, index) => {
                                          if (subItem.FrameworkId === auditDetails?.objId_Framework) {
                                            frameworksData[selectedItemIndex].FrameworkList.splice(index, 1, updated_data);
                                          }
                                        });
                                      }

                                      setAuditDetails({
                                        ...auditDetails,
                                        NextAudit: e,
                                      });
                                    } else {
                                      setAuditDetails({
                                        ...auditDetails,
                                        NextAudit: e,
                                      });
                                    }
                                  }
                                }}
                                id={"bhfgevwmtv"}
                                isError={false}
                              />
                            }
                          </div>
                        </div>

                        <div className="d-flex flex-end w-100 justify-content-end">
                          <AttackSurfaceTooltip
                            disabled={
                              accessStateValue("AccessState_SecOps") != 1
                            }
                            bottomLeft={true}
                            topLeft={false}
                            content={<span>{NO_ACCESS_TEXT}</span>}
                          >
                            <div>
                              <Button
                                className={`hg-blue-btn
                             ${accessStateValue("AccessState_SecOps") == 1
                                    ? " btn-disable"
                                    : ""
                                  }
                             
                             `}
                                onClick={() => {
                                  setSavingAudit(true);
                                  updateAuditDetails(true);
                                }}
                              >
                                {accessStateValue("AccessState_SecOps") ==
                                  1 ? (
                                  <img
                                    height={16}
                                    width={16}
                                    alt=""
                                    src="/images/attack-surface/small-lock.svg"
                                    className="mr-2"
                                  />
                                ) : (
                                  <></>
                                )}{" "}
                                {savingAudit ? "Saving.." : "Save"}
                              </Button>
                            </div>
                          </AttackSurfaceTooltip>
                        </div>
                        {/* {accessStateValue("AccessState_SecOps") != 1 && ( */}
                          <>
                            <div className="f-500 f-darkgrey mt-3 mb-3">
                              Evidence
                            </div>

                            {/* Uploaded Files */}
                            {/* <div className="border-parent radius-8"> */}
                            {auditDetails?.MediaList?.map((item, index) => {
                              return (
                                <div className="radius-4 bg-grey p-3  d-flex align-items-center justify-content-between mb-2">
                                  <div className="d-flex align-items-center">
                                    <img
                                      src="/images/msp/upload-icon-placeholder.svg"
                                      alt=""
                                      className="mr-2"
                                    />
                                    <div>
                                      <div className="f-500">
                                        {item?.FileName}
                                      </div>
                                      <div className="f-darkgrey">
                                        {formatFileSize(item?.FileSize)}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="d-flex align-item-center">
                                    <img
                                      src="/images/msp/policy-download-icon.svg"
                                      alt=""
                                      className="mx-2 pointer"
                                      onClick={() => {
                                        downloadFile(
                                          item?.FileUrl,
                                          item?.FileName
                                        );
                                      }}
                                    />

                                    {item.loading ? (
                                      <GreyLoader width={12} />
                                    ) : (
                                      <>
                                      {
                                        accessStateValue("AccessState_SecOps") === 1 ? 
                                        <img
                                          height={16}
                                          width={16}
                                          alt=""
                                          src="/images/attack-surface/small-lock.svg"
                                          className="mr-2 mb-1"
                                        />
                                        :
                                        <img
                                          src="/images/actioncenter/delete.svg"
                                          alt=""
                                          className="mx-2 pointer"
                                          onClick={() => {
                                            if (accessStateValue("AccessState_SecOps") === 1) {
                                              return
                                            }
                                            let updated_data = { ...auditDetails };
                                            updated_data.MediaList[
                                              index
                                            ].loading = true;
                                            setAuditDetails(updated_data);
                                            deleteUploadFile(
                                              item?.FileName,
                                              auditDetails?.objId,
                                              item?.BizObjAttributeLinkId
                                            );
                                          }}
                                        />
                                      }
                                      </>
                                    )}
                                  </div>
                                </div>
                              );
                            })}
                            

                       {/* { 
                       accessStateValue(
                                        "AccessState_SecOps"
                                      ) != 1 &&     */}
                                      <div className="radius-4 border-dashed d-flex flex-column align-items-center justify-content-center mt-4 w-100 p-3 mb-3">
                              {uploadProgress > 0 && uploadProgress < 100 ? (
                                <>
                                  <UploadFileCircularProgress
                                    progress={100 - uploadProgress}
                                  />

                                  <div className="f-grey my-2 w-fit-content">
                                    {uploadingFileList?.[0]?.name}{" "}
                                  </div>

                                  {/* {uploadingFileList?.map((file) => {
                                return (
                                  <div className="f-grey my-2 w-fit-content">
                                    {file.name}{" "}
                                  </div>
                                );
                              })} */}
                                  <div className="f-darkgrey my-2 w-fit-content">
                                    Uploading... {uploadProgress}%
                                  </div>

                                  <Button
                                    className="hg-cancel-btn"
                                    onClick={() => {
                                      uploadRef.current.click();
                                    }}
                                  >
                                    Cancel
                                  </Button>
                                </>
                              ) : (
                                <>
                                  <img
                                    width={20}
                                    src="/images/msp/framework-upload-icon.svg"
                                    alt=""
                                  />
                                  <div className="f-grey mt-2 w-fit-content">
                                    Upload file or browse file on your computer
                                  </div>
                                  <div className="f-darkgrey my-2 w-fit-content">
                                    Format pdf, doc, dox, txt
                                  </div>
                                  <Button
                                    className={`hg-cancel-btn ${accessStateValue("AccessState_SecOps") === 1 && 'cursor-default'}`}
                                    onClick={() => {
                                      if (accessStateValue("AccessState_SecOps") === 1) {
                                        return
                                      }
                                      uploadRef.current.click();
                                    }}
                                  >
                                    {
                                      accessStateValue("AccessState_SecOps") === 1 &&
                                      <img
                                        height={16}
                                        width={16}
                                        alt=""
                                        src="/images/attack-surface/small-lock.svg"
                                        className="mr-2 mb-1"
                                      />
                                    }
                                    Browse file
                                  </Button>
                                </>
                              )}
                            </div>
                            {/* } */}
                          </>
                         {/* )} */}
                        <input
                          type="file"
                          accept=".pdf,.doc,.dox,.txt"
                          onChange={(e) => handleUpload(e, auditDetails?.objId)}
                          className="d-none"
                          ref={uploadRef}
                          multiple
                        />
                        {/* Uploaded Files */}
                        {/* <div className="border-parent bg-grey radius-8">
                          {auditDetails?.MediaList?.map((item, index) => {
                            return (
                              <div className="border-item p-3  d-flex align-items-center justify-content-between">
                                <div className="d-flex align-items-center">
                                  <img
                                    src="/images/msp/upload-icon-placeholder.svg"
                                    alt=""
                                    className="mr-2"
                                  />
                                  <div>
                                    <div className="f-500">
                                      {item?.FileName}
                                    </div>
                                    <div className="f-darkgrey">
                                      {formatFileSize(item?.FileSize)}
                                    </div>
                                  </div>
                                </div>
                                <div className="d-flex align-item-center">
                                  <img
                                    src="/images/msp/policy-download-icon.svg"
                                    alt=""
                                    className="mx-2 pointer"
                                    onClick={() => {
                                      downloadFile(
                                        item?.FileUrl,
                                        item?.FileName
                                      );
                                    }}
                                  />

                                  {item.loading ? (
                                    <GreyLoader width={12} />
                                  ) : (
                                    <img
                                      src="/images/actioncenter/delete.svg"
                                      alt=""
                                      className="mx-2 pointer"
                                      onClick={() => {
                                        let updated_data = { ...auditDetails };
                                        updated_data.MediaList[
                                          index
                                        ].loading = true;
                                        setAuditDetails(updated_data);
                                        deleteUploadFile(
                                          item?.FileName,
                                          auditDetails?.objId,
                                          item?.BizObjAttributeLinkId
                                        );
                                      }}
                                    />
                                  )}
                                </div>
                              </div>
                            );
                          })}
                        </div> */}
                      </div>
                    )}

                    <div className="p-2" />
                  </>
                )}
              </div>
            </div>
          </div>
        </>
      )}
      <CreateTaskModal />
    </div>
  );
};

export default OperationalSecurityAssetsScreen;

export const previousResponses = [
  {
    type: "Internal Comment",
    answer: "Yes",
    date: "12/12/2020",
    question:
      "Ho umar de hisab naal doona ruthba Thoda nahiyo bahla hi khalaki challda  Akhan ch akheeti koyi sheh boldi Aive nai koyi duniya ton aaki challda ",
  },
  {
    type: "Internal Comment",
    answer: "Yes",
    date: "12/12/2020",
    question:
      "Ho umar de hisab naal doona ruthba Thoda nahiyo bahla hi khalaki challda  Akhan ch akheeti koyi sheh boldi Aive nai koyi duniya ton aaki challda ",
  },
  {
    type: "Internal Comment",
    answer: "Yes",
    date: "12/12/2020",
    question:
      "Ho umar de hisab naal doona ruthba Thoda nahiyo bahla hi khalaki challda  Akhan ch akheeti koyi sheh boldi Aive nai koyi duniya ton aaki challda ",
  },
  {
    type: "Internal Comment",
    answer: "Yes",
    date: "12/12/2020",
    question:
      "Ho umar de hisab naal doona ruthba Thoda nahiyo bahla hi khalaki challda  Akhan ch akheeti koyi sheh boldi Aive nai koyi duniya ton aaki challda ",
  },
  {
    type: "Internal Comment",
    answer: "Yes",
    date: "12/12/2020",
    question:
      "Ho umar de hisab naal doona ruthba Thoda nahiyo bahla hi khalaki challda  Akhan ch akheeti koyi sheh boldi Aive nai koyi duniya ton aaki challda ",
  },
  {
    type: "Internal Comment",
    answer: "Yes",
    date: "12/12/2020",
    question:
      "Ho umar de hisab naal doona ruthba Thoda nahiyo bahla hi khalaki challda  Akhan ch akheeti koyi sheh boldi Aive nai koyi duniya ton aaki challda ",
  },
  {
    type: "Internal Comment",
    answer: "Yes",
    date: "12/12/2020",
    question:
      "Ho umar de hisab naal doona ruthba Thoda nahiyo bahla hi khalaki challda  Akhan ch akheeti koyi sheh boldi Aive nai koyi duniya ton aaki challda ",
  },
];



const FrameworksLoader = () => {
  return (
    <ContentLoader
      speed={2}
      width={"100%"}
      height={"100%"}
      viewBox="0 0 1500 900"
      backgroundColor="#F6F8FB"
      foregroundColor="#ecebeb"
    >
      <rect x="0%" y="70" rx="8" ry="8" width="100%" height="140" />
      <rect x="0%" y="212" rx="8" ry="8" width="100%" height="140" />
      <rect x="0%" y="354" rx="8" ry="8" width="100%" height="140" />
      <rect x="0%" y="496" rx="8" ry="8" width="100%" height="140" />
      <rect x="0%" y="638" rx="8" ry="8" width="100%" height="140" />
      <rect x="0%" y="780" rx="8" ry="8" width="100%" height="140" />
      <rect x="0%" y="922" rx="8" ry="8" width="100%" height="140" />
    </ContentLoader>
  );
};
