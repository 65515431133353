import React from "react";
import ContentLoader from "react-content-loader";

const IncidentResponseLoader = () => {
  return (
    <ContentLoader
      speed={2}
      width={"100%"}
      height={"100%"}
      viewBox="0 0 1500 900"
      backgroundColor="#F6F8FB"
      foregroundColor="#ecebeb"
    >
      <rect x="15%" y="0" rx="8" ry="8" width="70%" height="90" />
      <rect x="15%" y="92" rx="8" ry="8" width="70%" height="90" />
      <rect x="15%" y="184" rx="8" ry="8" width="70%" height="90" />
      <rect x="15%" y="276" rx="8" ry="8" width="70%" height="90" />
      <rect x="15%" y="368" rx="8" ry="8" width="70%" height="90" />
      <rect x="15%" y="460" rx="8" ry="8" width="70%" height="90" />
      <rect x="15%" y="580" rx="4" ry="4" width="12%" height="30" />
    </ContentLoader>
  );
};

export default IncidentResponseLoader;
