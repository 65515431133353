import React, { useState, useEffect, Fragment } from "react";
import { useAuth } from "../context/Auth";
import { Row, Col, Button, Dropdown } from "react-bootstrap";
import {
  postData,
  getAPIData,
  confirmPaymentStripe,
  createTokenStripe,
} from "../../Utils/commonApi";
import "../../main.scss";
import TicketsView from "../Support/TicketsView/TicketsView";
import ParentChildModal from "../ModalDynamic/ParentChildModal";
import IncidentResponseForm from "../IncidentResponseForm/IncidentResponseForm";
import CreditModal from "./CreditModal";
import "../../main.scss";
import CyberEssentialsModal from "./CyberEssentialsModal/Purchase";
import Header from "../Header";
import { useDb } from "../context/db";
import ContentLoader from "react-content-loader";
import ToastMessage from "../ToastMessage";
import { FiChevronRight } from "react-icons/fi";

import Modal from "../Common/Modal";
import AddCardForm from "../Common/AddCardForm";
import PaymentResponse from "../Common/PaymentResponse";
import Loader from "../Common/loader";
import { TrackUser } from "../../Utils/SegmentFunctions";
import { useSelector } from "react-redux";
import { getMenuItemId } from "../SideNav/SideNav";
import { NoAccessUI, viewOnlyAccess } from "../App/App";

const ProServices = (props) => {
  const isMenuCollapse = useSelector((state) => state?.global?.menuCollapse);
  const { authTokens } = useAuth();
  const [proServices, setProServices] = useState(false);
  const [proService, setProService] = useState(false);
  const [supportModalOpen, setSupportModalOpen] = useState(false);
  const [supportCounter] = useState(0);
  const [showCreditModal, setShowCreditModal] = useState(false);
  const [showEssentialModal, setShowEssentialModal] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [reponseModal, setReponseModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedService, setSelectedService] = useState({});
  const [selectedService2, setSelectedService2] = useState({});
  const [packageModalData, setPackageModalData] = useState({});
  const [stripeError, setStripeError] = useState(false);
  const [proTotalCredit, setProTotalCredit] = useState(0);
  const [proTotalCredit2, setProTotalCredit2] = useState();
  const [resData, setResData] = useState(false);
  const [creditApply, setCreditApply] = useState(false);
  const [reqOptions, setReqoptions] = useState(false);
  const [toastMsg, setToastMsg] = useState({
    show: false,
    msg: false,
  });
  const [pageData, setPageData] = useState({});

  const menuItemId = getMenuItemId(window.location.pathname)
  const MenuItem_List = window.localStorage.getItem("object_Data")
    ? JSON.parse(window.localStorage.getItem("object_Data"))?.MenuVisbility?.MenuItem_List : null;
  const menuItemData = MenuItem_List.find(obj => obj?.MenuItemId === parseInt(menuItemId));
  const userPortalRole = window.localStorage.getItem("object_Data")
  ? JSON.parse(window.localStorage.getItem("object_Data"))?.UserDetail?.Role : null;
  const accessState = window.localStorage.getItem("object_Data")
  ? JSON.parse(window.localStorage.getItem("object_Data"))?.accessData : null;

  async function updateSubscription(subscritionId, paymentmethodId) {
    const response = await fetch(
      `${process.env.REACT_APP_API_BASE_URL}/CC_API/UpdateSubscription?StripeSubscriptionId=${subscritionId}&StripePaymentMethodID=${paymentmethodId}`,
      {
        method: "POST",
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${authTokens}`, // notice the Bearer before your token
        },
      }
    ).then((response) => response.json());
    if (response.AccessKey !== undefined) {
      localStorage.setItem(
        "user_MessageObj",
        JSON.stringify(response.AccessKey)
      );
    }
    return response;
  }

  async function submitInvoiceApi(
    savePaymentMethod,
    discount,
    invoiceId,
    PaymentMethodId,
    cardDetails = false
  ) {
    setIsLoading(true);
    let headers = {};
    if (cardDetails) {
      headers = {
        method: "POST",
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${authTokens}`, // notice the Bearer before your token
        },
        body: JSON.stringify(cardDetails),
      };
    } else {
      headers = {
        method: "POST",
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${authTokens}`, // notice the Bearer before your token
        },
      };
    }
    let paymentMethod = "";
    if (PaymentMethodId !== null) {
      paymentMethod = `&PaymentMethodId=${PaymentMethodId}`;
    }

    const response = await fetch(
      `${process.env.REACT_APP_API_BASE_URL}/CC_API/SubmitInvoice?SavePaymentMethod=${savePaymentMethod}&AppliedDiscount=${discount}&InvoiceId=${invoiceId}${paymentMethod}`,
      headers
    ).then((response) => response.json());
    if (response.AccessKey !== undefined) {
      localStorage.setItem(
        "user_MessageObj",
        JSON.stringify(response.AccessKey)
      );
    }
    if (!response.mr.Success) {
      setResData(response.error);
      setStripeError(true);
    } else {
      setResData(response);
      setProTotalCredit2(response.RemainingCredit_Text);
      setStripeError(false);
      setReponseModal(true);
      TrackUser("Compliance manager purchase done", {
        type: "Compliance manager purchase",
      });
    }
    setIsLoading(false);
  }

  async function confimrPayment(
    paymentIntent,
    paymentMethod,
    isAdd = false,
    subscriptionForm = false,
    subId
  ) {
    setIsLoading(true);

    if (subscriptionForm) {
      const data = await confirmPaymentStripe(paymentMethod, paymentIntent);
      if (data.error) {
        setResData(data.error);
        setStripeError(true);
      } else {
        await updateSubscription(subId, paymentMethod);
        setIsLoading(false);
        setResData(data);
      }
    }
    setIsLoading(false);
    setReponseModal(true);
  }

  // add card cyber care
  async function addCard(
    billingDetails,
    cardDetails,
    paymentIntentId,
    amt,
    saveCard,
    subscriptionForm,
    subId,
    invoiceId,
    isDiscount
  ) {
    setIsLoading(true);

    const month = cardDetails.monthyear.split("/");
    const data = {
      CardName: cardDetails.cardname,
      CardNumber: cardDetails.cardnumber,
      ExpMonth: month[0],
      ExpYear: month[1],
      Cvc: cardDetails.cvc,
      CompanyName: billingDetails.cname,
      CompanyAddress: billingDetails.caddress,
      Country: billingDetails.ccountry,
      CompanyRegistration: billingDetails.creg,
      VAT: billingDetails.cvat,
    };

    if (!subscriptionForm) {
      submitInvoiceApi(true, isDiscount, invoiceId, null, data);
    } else {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/CC_API/AddPaymentMethod`,
        {
          method: "POST",
          headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${authTokens}`, // notice the Bearer before your token
          },
          body: JSON.stringify(data),
        }
      )
        .then((response) => response.json())
        .then((res) => {
          if (res.mr.Success) {
            confimrPayment(
              paymentIntentId,
              res.PaymentMethodId,
              false,
              subscriptionForm,
              subId
            );
          } else {
            setResData(res);
            setReponseModal(true);
            setIsLoading(false);
          }
        });
    }
  }

  async function userCreditRequest() {
    const options = {};
    if (reqOptions.OptionEnum1) {
      options.OptionEnum1 = reqOptions.OptionEnum1;
    }

    if (reqOptions.OptionEnum3) {
      options.OptionEnum2 = reqOptions.OptionEnum3;
    }
    const response = await getAPIData("198", authTokens, options);
    if (!response.Success) {
      setShowToast(true);
    }
  }

  async function getPackageApi(priceid, paymentIntentId) {
    const response = await getAPIData("190", authTokens, {
      OptionEnum1: priceid,
    });
    if (response.mr.Success == true) {
      setPackageModalData(response);
      //  const { PaymentMethod_List } = response;
      //  const card = PaymentMethod_List[PaymentMethod_List.length - 1];
      //  confimrPayment(paymentIntentId, card.StripeId);
    } else {
      setShowToast(true);
    }
  }

  useEffect(() => {
    getProServices();
  }, []);

  useEffect(() => {
    if (showToast) {
      setTimeout(() => {
        setShowToast(false);
      }, 5000);
    }
  }, [showToast]);

  useEffect(() => {
    if (supportCounter > 1) {
      setSupportModalOpen(false);
      setInterval(() => {
        setSupportModalOpen(true);
      }, 100);
    }
  }, [supportCounter]);

  async function getProServices() {
    const response = await getAPIData("110", authTokens);
    if (response.mr.Success == true) {
      setProServices(response.ProService_List);
      setPageData(response);
     
    }
  }

  async function getPackage(
    priceid,
    pack = false,
    qunatity = false,
    pack2 = false
  ) {
    setIsLoading(true);
    let options = {};
    if (pack2) {
      if (qunatity) {
        options = {
          OptionEnum1: priceid,
          OptionEnum2: pack,
          OptionEnum3: null,
          OptionEnum4: null,
        };
      } else {
        options = {
          OptionEnum1: priceid,
          OptionEnum2: null,
          OptionEnum3: pack2,
          OptionEnum4: pack,
        };
      }
    } else if (pack && !qunatity) {
      if (!pack2) {
        options = {
          OptionEnum1: priceid,
          OptionEnum2: null,
          OptionEnum3: pack,
          OptionEnum4: null,
        };
      } else {
        options = {
          OptionEnum1: priceid,
          OptionEnum2: null,
          OptionEnum3: pack,
          OptionEnum4: null,
        };
      }
    } else if (qunatity) {
      options = {
        OptionEnum1: priceid[0],
        OptionEnum2: pack[0],
        OptionEnum3: priceid[1],
        OptionEnum4: pack[1],
      };
      Object.keys(options).map(function (key, index) {
        if (typeof options[key] == "undefined") {
          options[key] = null;
        }
      });
    } else {
      options = { OptionEnum1: priceid };
    }
    options.OptionEnum5 = 1;

    setReqoptions(options);
    const response = await getAPIData("190", authTokens, options);
    if (response.mr.Success == true) {
      setPackageModalData(response);
      setIsLoading(false);
    } else {
      // setShowToast(true);
      setReponseModal(true);
      setStripeError(true);
      setIsLoading(false);
    }
  }

  async function handleProserviceModal(id) {
    const response = await getAPIData("111", authTokens, { 
      OptionEnum1: id
    });
    if (response.mr.Success == true) {
      setProService(response);
      if (response.Prices.length == 1) {
        setSelectedService(response.Prices[0]);
      }
      setShowEssentialModal(true);
    }
  }

  function setSupportState(event) {
    event.preventDefault();
    setSupportModalOpen(true);
  }

  const handleCreditModal = () => {
    setShowCreditModal(true);
  };
  return (
    <>
    {
      menuItemData?.disableState || (accessState?.Override === true && accessState?.AccessState_ProServices === 0) ? <>
      {
        userPortalRole === 'MSP' ? 
          <NoAccessUI/> : <NoAccessUI type={userPortalRole}/>
      }
      </> : <>
        <React.Fragment>
          {(showToast || toastMsg.show) && (
            <ToastMessage
              message={toastMsg.msg ? toastMsg.msg : "Something went wrong"}
            />
          )}
          <Row className="fullHeight pro_service_screen">
            <Col xs={12} className="px-0">
              <div className="dashboard fullHeight pt-0">
                <header
                  className="d-flex justify-content-between navbar header-bottom-shadow"
                  style={{
                    paddingTop: "1em",
                    background: "#FFFFFF",
                    position: "fixed",
                    width: `${isMenuCollapse ? "92.25%" : "83.25%"}`, // "",
                    zIndex: 6,
                    marginLeft: "-0.9rem",
                    paddingLeft: "2rem",
                  }}
                >
                  <h1>Pro Services</h1>
                  {/* <div
                    className="supportBtn"
                    onClick={(event) => {
                      setSupportCounter(supportCounter + 1);
                      setSupportState(event);
                    }}
                  >
                    <img alt="support" src="/images/.svg"></img>
                  </div> */}
                  <Header />
                </header>
                <div
                  className="settings_container"
                  style={{ marginTop: "6rem", marginBottom: "24px" }}
                >
                  <div className="d-flex">
                    <img
                      alt=""
                      src="/images/icons/giftIcon.svg"
                      className="mr8px"
                    ></img>
                    <div className="txt-blue align-self-center heading3">
                      {/* Your credit balance:{" "}
                      {`${
                        proTotalCredit2 ? proTotalCredit2 : `£${proTotalCredit}`
                      }`} */}
                  Get help and support from the HighGround security team
                    </div>
                  </div>
                  {/* <p className="greyText mt8px ">
                    You can earn up to £500 of credit to spend on Pro Services from
                    HighGround.
                  </p> */}
                  {/* <p className="fontBold blackText">Ways to earn credit...</p> */}
                  {/* <div className="d-flex justify-content-between">
                    <div
                      className="small_container mr8px w-100 d-flex cursor-pointer"
                      onClick={handleCreditModal}
                    >
                      <div className="d-flex flex-column justify-content-between">
                        <div>
                          {" "}
                          <div className="">
                            <img
                              src="/images/proUserIcon.svg"
                              alt="Refer a Friend"
                            />
                          </div>
                          <div className="mt8px">#1 Refer Friend</div>
                        </div>
                        <div>
                          {" "}
                          <div className="mt8px">
                            <span className="txt-blue heading4">
                              £{pageData?.Refer_RewardValue || 5}
                            </span>
                            <span className="greyText"> per referral</span>
                          </div>
                        </div>
                      </div>
                      <FiChevronRight
                        className="align-self-center ml-auto"
                        color="#B6B6BF"
                        size="19"
                      />
                    </div>
                    <div
                      className="small_container mr8px w-100 d-flex cursor-pointer"
                      onClick={handleCreditModal}
                    >
                      <div className="d-flex flex-column justify-content-between">
                        <div>
                          {" "}
                          <div className="">
                            <img
                              src="/images/proLinkedinIcon.svg"
                              alt="Share on Social Media"
                            />
                          </div>
                          <div className="mt8px">#2 Share on Social Media</div>
                        </div>
                        <div>
                          {" "}
                          <div className="mt8px">
                            <span className="txt-blue heading4">
                              £{pageData?.Post_RewardValue || 5}
                            </span>
                            <span className="greyText"> per referral</span>
                          </div>
                        </div>
                      </div>
                      <FiChevronRight
                        className="align-self-center ml-auto"
                        color="#B6B6BF"
                        size="19"
                      />
                    </div>
                    <div
                      className="small_container w-100 mr8px d-flex cursor-pointer"
                      onClick={handleCreditModal}
                    >
                      <div className="d-flex flex-column justify-content-between">
                        <div>
                          <div className="">
                            <img
                              src="/images/proEmailIcon.svg"
                              alt="Send a Cyber Report"
                            />
                          </div>
                          <div className="mt8px">
                            #3 Send a Cyber Report to your CEO/CFO/COO
                          </div>
                        </div>

                        <div>
                          <div className="mt8px">
                            <span className="txt-blue heading4">
                              £{pageData?.Send_RewardValue || 15}
                            </span>
                            <span className="greyText"> per person</span>
                          </div>
                        </div>
                      </div>
                      <FiChevronRight
                        className="align-self-center ml-auto"
                        color="#B6B6BF"
                        size="19"
                      />
                    </div>
                    <div
                      className="small_container w-100 d-flex cursor-pointer"
                      onClick={handleCreditModal}
                    >
                      <div className="d-flex flex-column justify-content-between">
                        <div>
                          <div className="">
                            <img
                              src="/images/proTickIcon.svg"
                              alt="Complete Onboarding Checklist"
                            />
                          </div>
                          <div className="mt8px">
                            #4 Share your Cyber Improvements
                          </div>
                        </div>

                        <div>
                          <div className="mt8px">
                            <span className="txt-blue heading4">
                              £{pageData?.Share_RewardValue || 5}
                            </span>
                          </div>
                        </div>
                      </div>
                      <FiChevronRight
                        className="align-self-center ml-auto"
                        color="#B6B6BF"
                        size="19"
                      />
                    </div>
                  </div> */}
                </div>
                <Row>
                  {proServices.length > 0 &&
                    proServices.map((data) => (
                      <Col
                        md={4}
                        className={`mb16px ${viewOnlyAccess('AccessState_ProServices') ? 'cursor-default' : 'pointer'} `}
                        onClick={() => {
                          if (!viewOnlyAccess('AccessState_ProServices')) {
                            TrackUser(`Pro services product viewed`, {
                              type: data?.Title,
                            });
  
                            handleProserviceModal(data.Id);
                          }
                        }}
                      >
                        <div className="settings_container h-100 p16px">
                          <div className="d-flex">
                            {data.Id == 3046701 ? (
                              <div className="imgScale">
                                <img
                                  className={"imageScale"}
                                  src={`/images/ProServices/${data.Logo}`}
                                  alt=""
                                />
                              </div>
                            ) : (
                              <img
                                className={`border-grey mr-3 float-left`}
                                width="48"
                                src={`/images/ProServices/${data.Logo}`}
                                alt=""
                              />
                            )}

                            <div
                              className={`heading3 align-self-center ${
                                data.Id == 3046701 && "imgHeading"
                              }`}
                            >
                              {data.Title}
                            </div>
                          </div>
                          <div className="greyText mt16px">{data.Header}</div>
                          <Button
                            className={`mt16px float-right mb16px ${viewOnlyAccess('AccessState_ProServices') ? 'btn-disable' : 'btn-blue' }`}
                            style={{ padding: "8px 16px" }}
                            // onClick={() => handleProserviceModal(data.Id)}
                          >
                            {
                              viewOnlyAccess('AccessState_ProServices') &&
                              <img
                              alt=''
                              className='lockerDarkGreyImage mr-2 mb-1'
                              src='/images/settings-v2/locker-grey.svg'
                            />
                            }
                            {data.Price.replace("£ ", "£")}
                          </Button>
                        </div>
                      </Col>
                    ))}
                  {proServices == "" && (
                    <ContentLoader
                      speed={2}
                      width={1500}
                      height={500}
                      viewBox="0 0 1500 500"
                      backgroundColor="#F6F8FB"
                      foregroundColor="#ecebeb"
                      {...props}
                    >
                      <rect x="15" y="5" rx="12" ry="12" width="460" height="168" />
                      <rect
                        x="510"
                        y="5"
                        rx="12"
                        ry="12"
                        width="460"
                        height="168"
                      />
                      <rect
                        x="1010"
                        y="5"
                        rx="12"
                        ry="12"
                        width="460"
                        height="168"
                      />

                      <rect
                        x="15"
                        y="200"
                        rx="12"
                        ry="12"
                        width="460"
                        height="168"
                      />
                      <rect
                        x="510"
                        y="200"
                        rx="12"
                        ry="12"
                        width="460"
                        height="168"
                      />
                      <rect
                        x="1010"
                        y="200"
                        rx="12"
                        ry="12"
                        width="460"
                        height="168"
                      />
                    </ContentLoader>
                  )}
                </Row>
                <img
                  src={`/images/visa.svg`}
                  style={{ position: "absolute", opacity: 0 }}
                  alt=""
                />
              </div>
            </Col>
          </Row>
          <Modal
            isLoading={isLoading}
            isOpen={modalOpen}
            isClose={() => {
              setModalOpen(false);
              setReponseModal(false);
              setStripeError(false);
              setResData(false);
              setToastMsg({
                show: false,
                msg: "Something went wrong",
              });
              userCreditRequest();
            }}
          >
            {isLoading && <Loader />}

            {!reponseModal ? (
              <>
                <AddCardForm
                  isLoading={setIsLoading}
                  isResponse={setReponseModal}
                  data={packageModalData}
                  stripeApi={{
                    confirm_payment: confimrPayment,
                    addCard: addCard,
                    submitInvoice: submitInvoiceApi,
                  }}
                />
              </>
            ) : (
              <PaymentResponse
                service={proService}
                data={resData}
                isError={stripeError}
                closeModal={() => setModalOpen(false)}
                isClose={() => {
                  setReponseModal(false);
                  setStripeError(false);
                  setResData(false);
                }}
              />
            )}
          </Modal>

          {supportModalOpen ? (
            <ParentChildModal
              ParentComponent={<TicketsView />}
              ChildComponent={<IncidentResponseForm type={"support"} />}
              buttonText={"+ Create Ticket"}
              modalHeader={"Support Tickets"}
              childHeader={"Create Ticket"}
              setShow={true}
              showButton={true}
            />
          ) : (
            ""
          )}
          <CyberEssentialsModal
            show={showEssentialModal}
            closeModal={() => setShowEssentialModal(false)}
            data={proService}
            intergration={setModalOpen}
            setselectedPackage={setSelectedService}
            selectedPackage={selectedService}
            setselectedPackage2={setSelectedService2}
            selectedPackage2={selectedService2}
            requestPackage={getPackage}
            tostMsg={setToastMsg}
            creditApply={creditApply}
            proCredit={proTotalCredit2}
            setCreditApply={setCreditApply}
            
          />
          <CreditModal
            show={showCreditModal}
            closeModal={() => setShowCreditModal(false)}
            showToast={setShowToast}
            setProTotalCredit={setProTotalCredit}
            proCredit={proTotalCredit2}
            setProCredit={setProTotalCredit2}
            
          />
        </React.Fragment>
      </>
      }
    </>
  );
};
export default ProServices;
