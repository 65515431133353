import React from "react";
import { useHistory } from "react-router-dom";

import { Row, Col } from "react-bootstrap";
import { refreshAccessToken } from "../../Utils/commonApi";
import Header from "../Header";

import "../../main.scss";

const SessionPage = () => {
  const history = useHistory();

  const clickHandler = () => {
    refreshAccessToken();

    history.push("/");
  };

  return (
    <React.Fragment>
      <Row className="fullHeight">
        <Col xs={12} className="px-0">
          <div className="dashboard fullHeight pt-0">
            <header
              className="d-flex justify-content-between navbar sticky-top"
              style={{ paddingTop: "1em", background: "#FFFFFF" }}
            >
              <h1>Session Ending </h1>
              <Header />
            </header>

            <div className="settings_container globalError">
              <div className="text-center">
                <img src="/images/cross_icon.svg" alt="cross" />
                <h2 className="heading3" style={{ color: "#000000DE" }}>
                  Session is about to expire!
                </h2>
                <p className="greyText mt8px">
                  Your login session is going to end, please click here to
                  extend <br />
                </p>

                <p
                  className="txt-blue crsrpoint"
                  onClick={() => clickHandler()}
                >
                  <img src="/images/refresh.svg" alt="cross" /> &nbsp; Extend
                  Session
                </p>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default SessionPage;
