import React, { useEffect } from 'react'

const Handle3DS = () => {
    useEffect(() => {
        try {
          var parentWindow = window.opener;
    
          // send message to parent window
          parentWindow.postMessage("3DS-secure", "*");
          const urlParams = new URLSearchParams(window.location.search);
          const paymentIntent = urlParams.get("payment_intent");
          if (paymentIntent) {
            window.close();
          }
        } catch (err) {}
    
    
      
      }, []);
    
  return (
    <div></div>
  )
}

export default Handle3DS