import React from "react";
// import ReactDOM from "react-dom";
import "./index.css";
import App from "./Components/App/App";
import { PersistGate } from "redux-persist/integration/react";
import { BrowserRouter as Router } from "react-router-dom";
// import { composeWithDevTools } from "redux-devtools-extension";
import history from "./history";
import { Provider } from "react-redux";
// import rootReducer from "./redux/rootReducer";
// import { applyMiddleware, createStore } from "redux";
import { persistor, store } from "./redux/Store";
import { createRoot } from "react-dom/client";
import reportWebVitals from "./reportWebVitals";

// const storeEnhancers = composeWithDevTools({
//   name: "Cyber Care",
// })(applyMiddleware());

// const store = createStore(rootReducer, undefined, storeEnhancers);
const rootElement = document.getElementById("root");
const root = createRoot(rootElement);
root.render(
  <Router history={history}>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>
  </Router>
);
reportWebVitals();