import React, { useState, useEffect } from "react";
import { Dropdown } from "react-bootstrap";
import { AttackSurfacePostApi } from "../../../Utils/commonApi";
import { TrimString } from "../../../Utils/StringTrimmer";
import { useAuth } from "../../context/Auth";
// import { CallToast } from "../../PriceCalculator/modals/CustomToast";
import SmallWorkstationModal from "../AssetsList/WorkStations/SmallWorkstationModal";
import { cleanText } from "../Index";
import "./attackComponents.scss";

const AttackLocationDropdown = (props) => {
  // const { authTokens } = useAuth();
  const [showSmallModal, setShowSmallModal] = useState(false);
  const [newAssetId, setNewAssetId] = useState("");
  const [assetKey, setAssetKey] = useState("");
  useEffect(() => {
    if (props.data) {
      setData(props.data);
    }
  }, []);

  useEffect(() => {
    if (props.selected || true) {
      setInputValue(
        props.selected === "" ? props.selected : props.selected.AttributeText
      );
      setSelectedOption(props.selected ?? {});
      props.onChange(props.selected === "" ? {} : props.selected);
    }
  }, [props.selected]);

  const [data, setData] = useState({ 1: [] });
  const [inputValue, setInputValue] = useState("");
  const [selectedOption, setSelectedOption] = useState({});

  const [itemKey, setItemKey] = useState();
  function addValueToDropdownList(value, key = itemKey) {
    let updated_data = { ...data };
    updated_data[key].push({
      ...value,
    });
    setData(updated_data);

    // ////////To make the value selected
    props.onChange(value);
    setInputValue(value?.AttributeText);
    setSelectedOption(value);
  }

  return (
    <Dropdown
      className="attack-multi-select-dropdown"
      // tabIndex={-1}
    >
      <Dropdown.Toggle
        className={`${props.isError ? "input-error" : ""}`}
        // tabIndex={-1}
      >
        <div
          className="d-flex align-items-center hide-focus-outline"
          // tabIndex={0}
        >
          {props.icon && (
            <img alt="" width={14} height={14} className="" src={props.icon} />
          )}
          <input
            tabIndex={-1}
            placeholder={` ${cleanText(TrimString(props.placeholder, 20))}`}
            type="text"
            className="border-none w-100 h-100 pl-2 py-1"
            value={inputValue}
            onChange={(e) => {
              setInputValue(e.target.value);
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                if (inputValue !== "") {
                  // setSelectedOption(inputValue);
                  // setInputValue("");
                }
              }
            }}
          />
        </div>
      </Dropdown.Toggle>
      <Dropdown.Menu
        className="hide-scrollbar"
        style={{ maxHeight: "400px", overflow: "auto" }}
      >
        <div className="px-2">
          {data &&
            Object.keys(data)?.map((key, keyIndex) => {
              return (
                <>
                  <div className="f-12 f-500 f-darkgrey">
                    {TrimString(cleanText(key?.replaceAll(/\d+/g, "")), 20)}
                  </div>

                  {data[key].map((item, itemIndex) => {
                    return (
                      <div
                        className={`${
                          selectedOption?.AttributeId === item.AttributeId
                            ? "f-black"
                            : "f-grey"
                        }
                        
                        ${item.AttributeText === "" ? "d-none" : "d-flex"}
                        
                        
                        f-500 f-15 pointer py-1 ml-2 align-items-center justify-content-between`}
                        onClick={(e) => {
                          props.onChange(item);
                          setInputValue(item.AttributeText);
                          setSelectedOption(item);
                          e.target
                            .closest(".attack-multi-select-dropdown")
                            .click();
                        }}
                      >
                        <span className="d-inline-flex align-items-center">
                          {item.AttributeText}
                        </span>
                        {selectedOption?.AttributeId === item.AttributeId && (
                          <img
                            alt=""
                            width={11}
                            className="mb-1 mr-2"
                            src="/images/settings-v2/black-tick.svg"
                          />
                        )}
                      </div>
                    );
                  })}

                  {props.refId &&
                    props.refId?.length > 0 &&
                    props.refId[keyIndex]?.split("-")[0] > 0 && (
                      <div
                        className={`dropdown_item f-blue f-500 f-15 pointer py-1 ml-2`}
                        onClick={(e) => {
                          if (inputValue !== "" || true) {
                            setItemKey(key);
                            if (props.nestedModal) {
                              if (props.retainPreviousStateFunc) {
                                props.retainPreviousStateFunc();
                              }
                              if (props.onAddClick) {
                                if (
                                  props.refId &&
                                  props.refId?.length > 0 &&
                                  props.refId[keyIndex]?.split("-")[0] > 0
                                ) {
                                  props.onAddClick(
                                    props.refId[keyIndex]?.split("-")[0],
                                    props.refId[keyIndex]?.split("-")?.[2]
                                  );
                                }
                              }
                            } else {
                              if (
                                props.refId &&
                                props.refId?.length > 0 &&
                                props.refId[keyIndex]?.split("-")[0] > 0
                              ) {
                                setNewAssetId(
                                  props.refId[keyIndex]?.split("-")[0]
                                );
                                setAssetKey(
                                  props.refId[keyIndex]?.split("-")?.[2]
                                );
                                setShowSmallModal(true);
                              }
                            }
                          }
                        }}
                      >
                        Add{" "}
                        {TrimString(
                          cleanText(
                            changeVaribleToName(
                              props.refId[keyIndex]?.split("-")[1]
                            )
                          ),
                          130
                        )}
                      </div>
                    )}
                </>
              );
            })}
        </div>
      </Dropdown.Menu>
      <SmallWorkstationModal
        firstAssetId={newAssetId}
        hideModal={() => setShowSmallModal(false)}
        show={showSmallModal}
        onFinish={addValueToDropdownList}
        refreshMenuItems={props.refreshMenuItems}
        assetkey={assetKey}
      />
      {/* {console.log("props.refreshMenuItems", props.refreshMenuItems)} */}
    </Dropdown>
  );
};

export default AttackLocationDropdown;

export function changeVaribleToName(_str) {
  if (!_str) return "";

  let str = _str.replaceAll("_", " ");
  let str2 = str.charAt(0).toUpperCase() + str.slice(1);
  return str2;
}

// const AttackLocationDropdown = (props) => {
//   // const { authTokens } = useAuth();
//   const [showSmallModal, setShowSmallModal] = useState(false);
//   const [newAssetId, setNewAssetId] = useState("");
//   useEffect(() => {
//     if (props.data) {
//       setData(props.data);
//     }
//   }, []);

//   useEffect(() => {
//     if (props.selected) {
//       setInputValue(props.selected);
//       setSelectedOption(props.selected);
//     }
//   }, [props.selected]);

//   const [data, setData] = useState({ 1: [] });
//   const [inputValue, setInputValue] = useState("");
//   const [selectedOption, setSelectedOption] = useState("");
//   // async function createNewOption(objectId, value) {
//   //   await AttackSurfacePostApi(
//   //     authTokens,
//   //     `AttackSurfaceAPI/UploadASEData?AssetIdentifier=${objectId}`,
//   //     [{ location_name: value }]
//   //   ).then((response) => {
//   //     if (response.Success) {
//   //       CallToast("Updated successfully", false, false, "v2style");
//   //     }
//   //   });
//   // }
//   const [itemKey, setItemKey] = useState();
//   function addValueToDropdownList(value, key = itemKey) {
//     let updated_data = { ...data };
//     updated_data[key].push({
//       AttributeId: 0,
//       AttributeText: value,
//     });
//     setData(updated_data);
//     // createNewOption(props.objectID, inputValue);
//     // setInputValue("");
//     // ////////To make the value selected
//     props.onChange(value);
//     setInputValue(value);
//     setSelectedOption(value);
//   }

//   return (
//     <Dropdown
//       className="attack-multi-select-dropdown"
//       // tabIndex={-1}
//     >
//       <Dropdown.Toggle
//         className={``}
//         // tabIndex={-1}
//       >
//         <div
//           className="d-flex align-items-center hide-focus-outline"
//           // tabIndex={0}
//         >
//           {props.icon && (
//             <img alt="" width={14} height={14} className="" src={props.icon} />
//           )}
//           <input
//             tabIndex={-1}
//             placeholder={` ${TrimString(props.placeholder, 20)}`}
//             type="text"
//             className="border-none w-100 h-100 pl-2 py-1"
//             value={inputValue}
//             onChange={(e) => {
//               setInputValue(e.target.value);
//             }}
//             onKeyDown={(e) => {
//               if (e.key === "Enter") {
//                 if (inputValue !== "") {
//                   setSelectedOption(inputValue);
//                   setInputValue("");
//                 }
//               }
//             }}
//           />
//         </div>
//       </Dropdown.Toggle>
//       <Dropdown.Menu>
//         <div className="px-2">
//           {data &&
//             Object.keys(data)?.map((key, keyIndex) => {
//               return (
//                 <>
//                   <div className="f-12 f-500 f-darkgrey">
//                     {TrimString(allVariables[key], 20)}
//                   </div>

//                   {data[key].map((item, itemIndex) => {
//                     return (
//                       <div
//                         className={`${
//                           selectedOption === item.AttributeText
//                             ? "f-black"
//                             : "f-grey"
//                         } f-500 f-15 pointer py-1 ml-2 d-flex align-items-center justify-content-between`}
//                         onClick={(e) => {
//                           props.onChange(item.AttributeText);
//                           setInputValue(item.AttributeText);
//                           setSelectedOption(item.AttributeText);
//                         }}
//                       >
//                         <span className="d-inline-flex align-items-center">
//                           {item.AttributeText}
//                         </span>
//                         {selectedOption === item.AttributeText && (
//                           <img
//                             alt=""
//                             width={11}
//                             className="mb-1 mr-2"
//                             src="/images/settings-v2/black-tick.svg"
//                           />
//                         )}
//                       </div>
//                     );
//                   })}

//                   {props.refId && props.refId > 0 && (
//                     <div
//                       className={`dropdown_item f-blue f-500 f-15 pointer py-1 ml-2`}
//                       onClick={(e) => {
//                         if (inputValue !== "" || true) {
//                           // setData([...data, inputValue]);
//                           // setInputValue(inputValue);
//                           setItemKey(key);
//                           if (props.nestedModal) {
//                             if (props.onAddClick) {
//                               if (props.refId) {
//                                 props.onAddClick(props.refId);
//                               }
//                             }
//                           } else {
//                             if (props.refId && props.refId > 0) {
//                               setNewAssetId(props.refId);
//                               setShowSmallModal(true);
//                             }
//                           }
//                         }
//                       }}
//                     >
//                       Add {TrimString(allVariables[key], 130)}
//                     </div>
//                   )}
//                 </>
//               );
//             })}
//         </div>
//       </Dropdown.Menu>
//       <SmallWorkstationModal
//         firstAssetId={newAssetId}
//         hideModal={() => setShowSmallModal(false)}
//         show={showSmallModal}
//         onFinish={addValueToDropdownList}
//       />
//     </Dropdown>
//   );
// };
